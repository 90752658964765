const GH_IDENTIFIER = 'dashboard/'; // Used to differentiate our dashboard data from anything else on this domain (analytics, etc)
/**
 * Create a Storage class to manage client side storage in a single place.
 * Can switch out localStorage for localForage or sessionStorage if needed
 *
 * @class StorageClass
 */
class StorageClass {
    constructor() {}

    buildKey(key: string) {
        return `${GH_IDENTIFIER}${key}`;
    }

    get(key: string) {
        const value = localStorage.getItem(this.buildKey(key));
        if (value && value.startsWith('{') && value.endsWith('}')) {
            return JSON.parse(value);
        } else if (value && value.startsWith('[') && value.endsWith(']')) {
            return JSON.parse(value);
        } else if (value === 'true') {
            return true;
        } else if (value === 'false') {
            return false;
        }
        return value;
    }

    set(key: string, value: any) {
        if (typeof value === 'object' && value !== null) {
            localStorage.setItem(this.buildKey(key), JSON.stringify(value));
        } else if (Array.isArray(value)) {
            localStorage.setItem(this.buildKey(key), JSON.stringify(value));
        } else if (value !== undefined) {
            localStorage.setItem(this.buildKey(key), `${value}`);
        } else if (value === undefined || value === null) {
            this.remove(key);
        }
    }

    remove(key: string) {
        localStorage.removeItem(this.buildKey(key));
    }

    clear() {
        localStorage.clear();
    }
}

const Storage = new StorageClass();

export default Storage;
