import React from 'react';

import styles from './styles.module.scss';
import Icon from 'src/components/Icon';
import { MacraMeasure } from 'common/lib/model/macra/MacraYear';

export interface MeasureStatusCellProps {
    measure: MacraMeasure;
}

export function MeasureStatusCell({ measure }: MeasureStatusCellProps) {
    if (!measure) return <span></span>;

    const missingDataCount =
        measure.missingFields !== null && measure.missingFields !== undefined ? measure.missingFields.length : 0;
    if (measure.perfMet === 1) {
        return <Icon type="fa" name="check" className={styles.green} title="Performance Met" />;
    } else if (measure.perfNotMet === 1) {
        return (
            <div>
                <Icon type="fa" name="times" className={styles.red} title="Performance Not Met" />
                <span> ({missingDataCount})</span>
            </div>
        );
    } else if (measure.eligible === 1 && measure.perfMet === 0 && measure.perfNotMet === 0 && measure.exception === 0) {
        return (
            <div>
                <Icon type="fa" name="exclamation" className={styles.yellow} title="Data Completeness Not Met" />
                <span> ({missingDataCount})</span>
            </div>
        );
    } else if (measure.exception === 1) {
        return (
            <div>
                <Icon type="fa" name="asterisk" className={styles.green} title="Denominator Exception" />
                <span> ({missingDataCount})</span>
            </div>
        );
    } else if (measure.eligible === 0) {
        const missingDataLabel = missingDataCount > 0 ? `(${missingDataCount})` : '';
        return (
            <div>
                <Icon type="fa" name="minus" className={styles.grey} title="Ineligible" />
                <span> {missingDataLabel}</span>
            </div>
        );
    } else {
        return <span></span>;
    }
}
