import React, { useState, useContext } from 'react';
import styles from './styles.module.scss';
import DeviceStore, { Device } from 'src/stores/DeviceStore';
import SideModal from 'src/components/SideModal';
import ModalHeader from 'src/components/ModalHeader';
import { OptionTypes } from 'src/components/OptionDropDown';
import NemsisOptionDropDown from 'src/components/NemsisOptionDropDown';
import { getDropdownValue } from 'src/utils/nemsis';
import NemsisInput from 'src/components/NemsisInput';
import Flex from 'src/components/Flex';
import DatePicker from 'src/components/DatePicker';
import { format } from 'date-fns';
import { API_DATE_FORMAT } from 'src/utils/constants';
import ModalFooter from 'src/components/ModalFooter';
import FacilityDropDown from 'src/components/FacilityDropDown';
import { Label } from 'src/components/Input';
import { ToastStoreObject } from 'src/stores/ToastStore';
import * as utils from 'src/utils';
import OrgStore from 'src/stores/OrgStore';
import NemsisStore from 'src/stores/NemsisStore';

export interface DeviceModalProps {
    onClose: Function;
    onSave: Function;
    device?: Device;
}

const defaultDevice: Device = {
    deviceTypeCode: [],
    manufacturer: '',
    nameOrId: '',
    modelNumber: '',
    purchaseDate: null,
    serialNumber: '',
    facilityId: null,
};

export function DeviceModal(props: DeviceModalProps) {
    const [data, setData] = useState<Device>(props.device || utils.clone(defaultDevice));
    const isEdit = !!props.device;

    const deviceStore = useContext(DeviceStore);
    const nemsisStore = useContext(NemsisStore);

    function renderDropDown({
        key,
        label,
        type,
        isMulti,
    }: {
        key: keyof Device;
        label: string;
        type: OptionTypes;
        isMulti?: boolean;
    }) {
        return (
            <NemsisOptionDropDown
                nemsisLabel={label}
                isMulti={isMulti}
                selectedValue={data[key]}
                onChange={(d: any) => {
                    const val = getDropdownValue(d, isMulti ? [] : undefined);
                    return setData({ ...data, [key]: val });
                }}
                type={type}
            />
        );
    }

    const updateInput = (param: keyof Device) => (t: string) => setData({ ...data, [param]: t });

    function renderTopLevelInput({ key, label }: { key: keyof Device; label: string }) {
        return <NemsisInput nemsisLabel={label} value={data[key] as string} onChangeText={updateInput(key)} />;
    }

    async function handleSave() {
        try {
            if (isEdit) {
                await deviceStore.updateDevice(data);
            } else {
                await deviceStore.createDevice(data);
            }

            props.onSave();
            props.onClose();
            await nemsisStore.validateDemData(true);
        } catch (error) {
            ToastStoreObject.show(utils.parseError(error));
        }
    }

    return (
        <SideModal isOpen={true} onClose={() => props.onClose()}>
            <ModalHeader title={isEdit ? 'Edit Device' : 'Create Device'} onClose={props.onClose} />
            <div className={styles.modalContent}>
                <Flex direction="column" className={styles.form}>
                    <Flex direction="row" justify="between" className={styles.formRow}>
                        <Flex value={1}>{renderTopLevelInput({ key: 'nameOrId', label: 'Name or ID' })}</Flex>
                    </Flex>
                    <Flex direction="row" justify="between" className={styles.formRow}>
                        <Flex value={1}>
                            {renderDropDown({
                                key: 'deviceTypeCode',
                                label: 'Device Types',
                                isMulti: true,
                                type: OptionTypes.EMSMedicalDeviceType,
                            })}
                        </Flex>
                    </Flex>
                    <Flex direction="row" justify="between" className={styles.formRow}>
                        <Flex value={1}>{renderTopLevelInput({ key: 'manufacturer', label: 'Manufacturer' })}</Flex>
                        <Flex value={0.1} />
                        <Flex value={1}>{renderTopLevelInput({ key: 'modelNumber', label: 'Model Number' })}</Flex>
                        <Flex value={0.1} />
                        <Flex value={1}>{renderTopLevelInput({ key: 'serialNumber', label: 'Serial Number' })}</Flex>
                    </Flex>
                    <Flex direction="row" justify="between" className={styles.formRow}>
                        <Flex value={1}>
                            <DatePicker
                                label="Purchase Date"
                                onlyPast={true}
                                value={data.purchaseDate}
                                onChange={(date) => {
                                    if (date) {
                                        const value = format(date, API_DATE_FORMAT);
                                        setData({ ...data, purchaseDate: value });
                                    }
                                }}
                            />
                        </Flex>
                        <Flex value={0.1} />
                        <Flex value={1}>
                            <Label text="Facility" required={false} />
                            <FacilityDropDown
                                isDefaultEmpty={true}
                                allowEmpty={true}
                                selectedValue={data.facilityId || null}
                                onChange={(s: any) => {
                                    setData({ ...data, facilityId: s ? s.value : null });
                                }}
                            />
                        </Flex>
                    </Flex>
                </Flex>
            </div>
            <ModalFooter
                primaryText={isEdit ? 'Save' : 'Create'}
                primaryClick={handleSave}
                secondaryClick={() => {
                    setData(utils.clone(defaultDevice));
                    props.onClose();
                }}
            />
        </SideModal>
    );
}

export default DeviceModal;
