import React, { useEffect, useState, useContext } from 'react';
import styles from '../../styles.module.scss';
import CustomerStore from 'src/stores/CustomerStore';
import Flex from 'src/components/Flex';
import Button from 'src/components/Button';
import StatusBadge from 'src/components/StatusBadge';
import { ModalStoreObject, ModalTypes } from 'src/stores/ModalStore';
import { getStatusColor } from 'src/containers/Customers';

function CustomerInformation() {
    const customerStore = useContext(CustomerStore);

    function editCustomer() {
        ModalStoreObject.showModal(ModalTypes.CustomerModal, {
            onClose: () => ModalStoreObject.hideModal(),
            onSave: () => {
                customerStore.getCustomer(customerStore.customer?.customerId, true);
            },
            mode: 'edit',
            customer: customerStore.customer,
        });
    }

    function editCustomerStatus() {
        ModalStoreObject.showModal(ModalTypes.CustomerStatusModal, {
            onClose: () => ModalStoreObject.hideModal(),
            onSave: () => {
                customerStore.getCustomer(customerStore.customer?.customerId, true);
            },
            customer: customerStore.customer,
        });
    }

    return (
        <Flex direction="row" className={styles.customerInformation}>
            <div className={styles.section}>
                <Flex align="center">
                    <h2 className={styles.title}>Customer Information</h2>
                    <Button type="secondarySmall" text="Edit" onClick={editCustomer} />
                </Flex>
                <div className={styles.sectionContainer}>
                    <ul>
                        <li>
                            <h3>Customer Type</h3>
                            <div className={styles.sectionListContent}>{customerStore.readableCustomerType}</div>
                        </li>
                        <li>
                            <h3>Customer Address</h3>
                            <div className={styles.sectionListContent}>
                                {customerStore.customerAddress?.streetAddress1}
                                <br />
                                {customerStore.customerAddress?.streetAddress2
                                    ? customerStore.customerAddress.streetAddress2
                                    : ''}
                                {customerStore.customerAddress?.streetAddress2 && <br />}
                                {customerStore.customerAddress?.city}, {customerStore.customerAddress?.state}{' '}
                                {customerStore.customerAddress?.postalCode}
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div className={styles.section}>
                <Flex align="center">
                    <h2 className={styles.title}>Customer Status</h2>
                    <Button type="secondarySmall" text="Edit" onClick={editCustomerStatus} />
                </Flex>
                <div className={styles.sectionContainer}>
                    <ul>
                        <li className={styles.oneLine}>
                            <h3>Current Status</h3>
                            <div className={styles.sectionListContent}>
                                <StatusBadge
                                    text={customerStore.customerStatus}
                                    status={getStatusColor(customerStore.customerStatus)}
                                />
                            </div>
                        </li>
                        <li className={styles.oneLine}>
                            <h3>Initial Contract Date</h3>
                            <div className={styles.sectionListContent}>{customerStore.initialContractDate}</div>
                        </li>
                        <li className={styles.oneLine}>
                            <h3>Offboard Date</h3>
                            <div className={styles.sectionListContent}>{customerStore.offboardDate}</div>
                        </li>
                        <li className={styles.oneLine}>
                            <h3>Offboard Reason</h3>
                            <div className={styles.sectionListContent}>{customerStore.readableOffboardReason}</div>
                        </li>
                    </ul>
                </div>
            </div>
        </Flex>
    );
}

export default CustomerInformation;
