import { observable, action, makeObservable } from 'mobx';
import { createContext } from 'react';
import * as customerService from 'src/api/customer';
import {
    Customer,
    CustomerSubscriptionData,
    CustomerDocument,
    CustomerContact,
    CustomerStatus,
    CustomerType,
} from 'common/lib/entity/index/Customer';
import { FilterQueryParams } from 'src/utils/types';
import { Org } from 'common/lib/entity/index/Org';

class CustomerStore {
    @observable customers: Customer[] = [];
    @observable customer: Partial<Customer> = {};
    @observable customerOrgs: Partial<Org>[] = [];
    @observable customerFacilityOptions: { label: string; value: string }[] = [];
    @observable initialContractDate: CustomerSubscriptionData['initialContractDate'] = '';
    @observable customerType: CustomerSubscriptionData['customerType'] = CustomerType.AnesthesiaGroup;
    @observable readableCustomerType: string = '';
    @observable customerStatus: CustomerSubscriptionData['customerStatus'] = CustomerStatus.Active;
    @observable isTrialCustomer: CustomerSubscriptionData['isTrialCustomer'] = false;
    @observable isDemoCustomer: CustomerSubscriptionData['isDemoCustomer'] = true;
    @observable customerAddress: CustomerSubscriptionData['customerAddress'] = {
        streetAddress1: '',
        streetAddress2: '',
        city: '',
        state: '',
        postalCode: '',
    };
    @observable offboardDate: CustomerSubscriptionData['offboardDate'] = '';
    @observable offboardReason: CustomerSubscriptionData['offboardReason'];
    @observable readableOffboardReason: string = null;
    @observable otherOffboardReason: CustomerSubscriptionData['otherOffboardReason'] = null;
    @observable documents: CustomerDocument[] = [];
    @observable primaryContacts: CustomerContact[] = [];
    @observable technicalContacts: CustomerContact[] = [];
    @observable billingContacts: CustomerContact[] = [];
    @observable clinicalContacts: CustomerContact[] = [];
    @observable totalCustomerCount: number = 0;

    constructor() {
        makeObservable(this);
    }

    @action
    reset() {
        this.customers = [];
        this.totalCustomerCount = 0;
    }

    async getCustomers(params: FilterQueryParams = {}, force = false) {
        if (!force && this.customers.length > 0) {
            return;
        }

        const { result, count } = await customerService.getCustomers(params);
        this.setCustomers(result || [], count);
    }

    async updateCustomer(
        customerId: Customer['customerId'],
        customerName: Customer['customerName'],
        subscriptionData: Partial<CustomerSubscriptionData>,
    ) {
        return await customerService.updateCustomer(customerId, customerName, subscriptionData);
    }

    async createCustomer(customerName: Customer['customerName'], subscriptionData: Partial<CustomerSubscriptionData>) {
        return await customerService.createCustomer(customerName, subscriptionData);
    }

    async updateCustomerStatus(
        customerId: Customer['customerId'],
        subscriptionData: Partial<CustomerSubscriptionData>,
    ) {
        return await customerService.updateCustomerStatus(customerId, subscriptionData);
    }

    async addCustomerDocument(customerId: Customer['customerId'], document: Partial<CustomerDocument>) {
        return await customerService.addDocument(customerId, document);
    }

    async updateCustomerDocument(
        customerId: Customer['customerId'],
        documentGuid: CustomerDocument['documentGuid'],
        document: Partial<CustomerDocument>,
    ) {
        return await customerService.updateDocument(customerId, documentGuid, document);
    }

    async addCustomerContact(
        customerId: Customer['customerId'],
        contact: Partial<CustomerContact>,
        contactType: string,
    ) {
        return await customerService.addContact(customerId, contact, contactType);
    }

    async updateCustomerContact(
        customerId: Customer['customerId'],
        contactGuid: CustomerContact['contactGuid'],
        contact: Partial<CustomerContact>,
        contactType: string,
    ) {
        return await customerService.updateContact(customerId, contactGuid, contact, contactType);
    }

    async removeCustomerContact(
        customerId: Customer['customerId'],
        contactGuid: CustomerContact['contactGuid'],
        contactType: string,
    ) {
        return await customerService.removeContact(customerId, contactGuid, contactType);
    }

    @action
    setCustomers(customers: Customer[], total: number = 0) {
        this.customers = customers;
        this.totalCustomerCount = total;
    }

    async getCustomer(customerId: Customer['customerId'], force = false) {
        const cachedCustomer = this.customers.find((customer: Customer) => customer.customerId === customerId);
        if (cachedCustomer && !force) {
            this.setCustomer(cachedCustomer);
            return;
        }

        const customer = await customerService.getCustomer(customerId);
        this.setCustomer(customer);
    }

    async getCustomerOrgs(customerId: Customer['customerId'], force = false) {
        if (this.customerOrgs && this.customerOrgs.length && !force) {
            return;
        }

        const customerOrgs = await customerService.getCustomerOrgs(customerId);
        this.setCustomerOrgs(customerOrgs);
    }

    @action
    setCustomer(customer: Customer) {
        this.customer = customer;

        this.setInitialContractDate();
        this.setCustomerType();
        this.setCustomerStatus();
        this.setTrialStatus();
        this.setDemoCustomerStatus();
        this.setCustomerAddress();
        this.setOffboardDate();
        this.setOffboardReason();
        this.setDocuments();
        this.setContacts();
    }

    @action
    setCustomerOrgs(customerOrgs: Partial<Org>[]) {
        this.customerOrgs = customerOrgs;

        const facilityOptions: { label: string; value: string }[] = [];
        customerOrgs.map((org: any) => {
            org.facilities.forEach((facility: { facId: any; facNm: any }) =>
                facilityOptions.push({
                    value: `${org.orgNmIntrnl}-${facility.facId}`,
                    label: `${org.orgNm} - ${facility.facNm}`,
                }),
            );
        });
        this.customerFacilityOptions = facilityOptions;
    }

    @action
    setInitialContractDate() {
        this.initialContractDate = this.customer?.subscriptionData?.initialContractDate || 'N/A';
    }

    @action
    setCustomerType() {
        const type = this.customer?.subscriptionData?.customerType;

        this.customerType = type;

        switch (type) {
            case 'ANESTHESIA_GROUP':
                this.readableCustomerType = 'Anesthesia Group';
                break;
            case 'MULTI_SPECIALITY_GROUP':
                this.readableCustomerType = 'Multi Specialty Group';
                break;
            case 'AMBULATORY_SURGERY_CENTER':
                this.readableCustomerType = 'Ambulatory Surgery Center';
                break;
            case 'BILLING_COMPANY':
                this.readableCustomerType = 'Billing Company';
                break;
            case 'HOSPITAL':
                this.readableCustomerType = 'Hospital';
                break;
            case 'DENTAL_ANESTHESIA_PRACTICE':
                this.readableCustomerType = 'Dental Anesthesia Practice';
                break;
        }
    }

    @action
    setCustomerStatus() {
        this.customerStatus = this.customer?.subscriptionData?.customerStatus;
    }

    @action
    setTrialStatus() {
        this.isTrialCustomer = this.customer?.subscriptionData?.isTrialCustomer;
    }

    @action
    setDemoCustomerStatus() {
        this.isDemoCustomer = this.customer?.subscriptionData?.isDemoCustomer;
    }

    @action
    setCustomerAddress() {
        this.customerAddress = this.customer?.subscriptionData?.customerAddress;
    }

    @action
    setOffboardDate() {
        this.offboardDate = this.customer?.subscriptionData?.offboardDate || '-';
    }

    @action
    setOffboardReason() {
        this.offboardReason = this.customer?.subscriptionData?.offboardReason;
        this.otherOffboardReason = this.customer?.subscriptionData?.otherOffboardReason;

        switch (this.offboardReason) {
            case 'CONTRACT_TRANSFERRED':
                this.readableOffboardReason = 'Contract Transferred';
                break;
            case 'CUSTOMER_CANCELLED':
                this.readableOffboardReason = 'Customer Cancelled';
                break;
            case 'CUSTOMER_LOST_CONTRACT':
                this.readableOffboardReason = 'Customer Lost Contract';
                break;
            case 'FAILURE_TO_GO_LIVE':
                this.readableOffboardReason = 'Failure to Go Live';
                break;
            case 'FAILURE_TO_PAY':
                this.readableOffboardReason = 'Failure to Pay';
                break;
            case 'TRIAL_CANCELLED':
                this.readableOffboardReason = 'Trial Cancelled';
                break;
            case 'OTHER':
                this.readableOffboardReason = 'Other';
                break;
            default:
                this.readableOffboardReason = '-';
                break;
        }
    }

    @action
    setDocuments() {
        this.documents = this.customer?.subscriptionData?.documents || [];
    }

    @action
    setContacts() {
        this.primaryContacts = this.customer?.subscriptionData?.primaryContacts || [];
        this.technicalContacts = this.customer?.subscriptionData?.technicalContacts || [];
        this.billingContacts = this.customer?.subscriptionData?.billingContacts || [];
        this.clinicalContacts = this.customer?.subscriptionData?.clinicalContacts || [];
    }

    getReadableDocumentType(type: CustomerDocument['documentType']) {
        switch (type) {
            case 'BAA':
            case 'MSA':
            case 'NDA':
            case 'QCDR':
                return type;
            case 'ORDER_FORM':
                return 'Order Form';
            default:
                return 'N/A';
        }
    }
}

// Import this to any other store that needs to use a value from it
export const CustomerStoreObject = new CustomerStore();

export default createContext(CustomerStoreObject);
