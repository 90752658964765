import React, { useState, useEffect, useContext } from 'react';
import { observer } from 'mobx-react';
import { ImportBatch, ImportBatchRecord, ImportBatchTemplate, TargetDataModelItem } from 'src/utils/types';
import ModalFooter from 'src/components/ModalFooter';
import ModalHeader from 'src/components/ModalHeader';
import SideModal from 'src/components/SideModal';
import styles from './styles.module.scss';
import Input from 'src/components/Input';
import { ErrorStoreObject, ErrorTypes } from 'src/stores/ErrorStore';
import ImportBatchStore from 'src/stores/ImportBatchStore';
import { ToastStoreObject } from 'src/stores/ToastStore';
import * as utils from 'src/utils';
import Flex from 'src/components/Flex';
import LoadingIcon from 'src/components/LoadingIcon';

export interface ImportBatchRecordModalProps {
    isOpen: boolean;
    importBatch: ImportBatch;
    record: ImportBatchRecord;
    onClose: Function;
    onSave?: Function;
}

function ImportBatchRecordModal(props: ImportBatchRecordModalProps) {
    const [isLoading, setIsLoading] = useState(false);
    const [currentData, setCurrentData] = useState<any>({});
    const [templateData, setTemplateData] = useState<Partial<ImportBatchTemplate>>({});
    const importBatchStore = useContext(ImportBatchStore);

    async function retrieveData() {
        try {
            setIsLoading(true);
            await Promise.all([
                importBatchStore.getImportBatchTemplates(),
                importBatchStore.getImportBatchRecordStates(),
            ]);

            const template = importBatchStore.findBatchTemplate(props.importBatch.importBatchTemplateGuid);

            setTemplateData(template);
            setCurrentData(props.record.recordCurrentData);
            setIsLoading(false);
        } catch (e) {
            ErrorStoreObject.setError(ErrorTypes.Loading);
            setIsLoading(false);
        }
    }

    useEffect(() => {
        retrieveData();
    }, []);

    function handleValueChange(key: string, value: string) {
        setCurrentData(Object.assign({ ...currentData }, { [key]: value }));
    }

    async function handleSaveAndReprocess() {
        try {
            setIsLoading(true);
            const updatedRecord = await importBatchStore.updateImportBatchRecordByGuid(
                props.record.importBatchRecordGuid,
                currentData,
            );
            setCurrentData(updatedRecord);
        } catch (error) {
            ToastStoreObject.show(utils.parseError(error));
        } finally {
            setIsLoading(false);
            props.onSave();
            props.onClose();
        }
    }

    function getInputs() {
        if (isLoading) {
            return (
                <Flex align="center" justify="center">
                    <LoadingIcon />
                </Flex>
            );
        }

        return (
            templateData.targetDataModel &&
            templateData.targetDataModel.map((item: TargetDataModelItem, index: number) => (
                <Input
                    key={index}
                    label={item.label}
                    value={currentData && currentData[item.key]}
                    onChangeText={(value: string) => handleValueChange(item.key, value)}
                />
            ))
        );
    }

    return (
        <SideModal isOpen={true} onClose={props.onClose}>
            <ModalHeader title="Edit Record" onClose={props.onClose} />
            <div className={styles.contentWrap}>{getInputs()}</div>
            <ModalFooter
                primaryText="Save"
                primaryClick={handleSaveAndReprocess}
                secondaryClick={() => props.onClose()}
                isLoading={isLoading}
            />
        </SideModal>
    );
}

export default observer(ImportBatchRecordModal);
