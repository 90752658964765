import React, { useState, useEffect, useContext } from 'react';
import styles from './styles.module.scss';
import Select from 'react-select';
import classNames from 'classnames';
import ReferenceListStore, {
    RefListTypes,
    ReferenceListValue,
    getRefListDropdownValues,
    LocalOptions,
} from 'src/stores/ReferenceListStore';
import { observer } from 'mobx-react';
import * as utils from 'src/utils';
import useIsOptionsLoading from 'src/utils/hooks/useIsOptionsLoading';
import { Facility } from 'src/stores/FacilityStore';
import { List, CellMeasurer, CellMeasurerCache } from 'react-virtualized';

export const YES_NO_OPTIONS = [
    { value: 'y', label: 'Yes' },
    { value: 'n', label: 'No' },
];

export const ACCOUNT_OPTIONS = [
    { value: 'create', label: `Create a new account` },
    { value: 'login', label: 'Login to an existing account' },
];

export const TELEHEALTH_PRIORITY_OPTIONS = [
    { value: 'LOW', label: `Low` },
    { value: 'MEDIUM', label: `Medium` },
    { value: 'HIGH', label: `High` },
    { value: 'URGENT', label: `Urgent` },
];

function getPreviousYears() {
    const thisYear = new Date().getFullYear();
    const years = [];
    for (let i = 0; i < 10; i++) {
        years.push(thisYear - i);
    }
    return years.map((y) => ({ value: y, label: `${y}` }));
}

// This is a way to combine/extend enums
export const OptionTypes = { ...RefListTypes, ...LocalOptions };
export type OptionTypes = RefListTypes | LocalOptions;

export const OptionDropDownPlaceholders: { [key in OptionTypes]?: string } = {
    Race: 'Select Race',
    Ethnicity: 'Select Ethnicity',
    Gender: 'Select Gender',
    Language: 'Select Language',
    PersonPhoneNumberTypes: 'Type',
    PersonEmailAddressTypes: 'Type',
    PersonAddressTypes: 'Type',
    PatientNeedTypes: 'Type',
    PatientContactRelationship: 'Relationship',
    AppointmentCancelationReasonTypes: 'Select Reason',
    TaskCancelationReasonTypes: 'Select Reason',
    TaskTypes: 'Select Type',
    AppointmentTypes: 'Select Type',
    PatientNoteTypes: 'Type',
    MedicationUnitsOfMeasure: 'Select Unit',
    MedicationRouteTypes: 'Select Route',
    MedicationFrequencyTypes: 'Select Frequency',
    MedicationDirectedUsageTypes: 'Select Usage',
    CHPVisitTypes: 'Select Visit Type',
    ProviderTypes: 'Select Provider Type',
    yesNo: 'Select',
    AccountOptions: 'Select whether to create an account',
    TelehealthPriority: 'Choose a priority',
    MilitaryDischargeStatusCodes: 'Select discharge status',
    MilitaryServiceBranches: 'Select military branch',
    MilitaryServiceStatusCodes: 'Select service status',
};

export interface OptionDropDownProps {
    onChange: Function;
    selectedValue?: string | number | boolean | any[];
    allowEmpty?: boolean;
    type?: OptionTypes;
    className?: string;
    isMulti?: boolean;
    placeholder?: string;
    facId?: Facility['facId'];
    isDisabled?: boolean;
    virtualize?: boolean;
    refListFilter?: Function;
    options?: any;
    styles?: any;
}

function getOptions(type: OptionTypes, rlStore: any, facId?: Facility['facId'], refListFilter: Function = null) {
    if (type === 'yesNo') {
        return YES_NO_OPTIONS;
    }
    if (type === 'AccountOptions') {
        return ACCOUNT_OPTIONS;
    }
    if (type === 'TelehealthPriority') {
        return TELEHEALTH_PRIORITY_OPTIONS;
    }
    if (type === 'PreviousYears') {
        return getPreviousYears();
    }

    let options = refListFilter
        ? getRefListDropdownValues(refListFilter())
        : rlStore.referenceListDropdownValues[type] || [];

    // Check facility exclusions
    if (facId) {
        let refListValues = rlStore.referenceListValues[type] || [];
        refListValues = refListValues.filter(
            (o: ReferenceListValue) => !(o.facilityDisplayExcludeList || []).includes(facId),
        );
        // Only format and update the options if the lists are different lengths
        if (refListValues.length !== options.length) {
            options = getRefListDropdownValues(refListValues);
        }
    }
    return options;
}

function OptionDropDown(props: OptionDropDownProps) {
    const referenceListStore = useContext(ReferenceListStore);
    const [selected, setSelected] = useState(null);

    const options = props.options || getOptions(props.type, referenceListStore, props.facId, props.refListFilter);

    const isLoading = useIsOptionsLoading(options);

    useEffect(() => {
        let selectedVal;
        if (props.isMulti && utils.isArray(props.selectedValue)) {
            selectedVal = props.selectedValue.map((v: any) =>
                options.find((i: { value: any; label: any }) => i.value === v),
            );
            if (selectedVal) {
                setSelected(selectedVal);
            }
        } else {
            selectedVal = options.find((i: { value: any; label: any }) => {
                if (props.type === OptionTypes.yesNo) {
                    if (props.selectedValue === true && i.value === 'y') {
                        return true;
                    } else if (props.selectedValue === false && i.value === 'n') {
                        return true;
                    }
                    return false;
                }
                return i.value === props.selectedValue;
            });

            setSelected(selectedVal || null);
        }
    }, [
        props.type,
        props.selectedValue,
        referenceListStore.referenceListDropdownValues[props.type as RefListTypes],
        props.options,
    ]);

    useEffect(() => {
        if (props.type in RefListTypes) {
            referenceListStore.getData(props.type as RefListTypes);
        }
    }, [props.type]);

    const selectProps = {
        onChange: (s: any) => {
            setSelected(s);
            if (props.type === OptionTypes.yesNo) {
                props.onChange({ value: s.value === 'y' });
                return;
            }
            props.onChange(s || (props.isMulti ? [] : { value: null }));
        },
        isLoading: isLoading,
        className: classNames(styles.select, props.className),
        isClearable: props.allowEmpty,
        placeholder: props.placeholder || OptionDropDownPlaceholders[props.type],
        options: options,
        isMulti: props.isMulti,
        isDisabled: props.isDisabled,
        styles: props.styles
            ? Object.assign(utils.styleSelectComponent(), { ...props.styles })
            : utils.styleSelectComponent(),
    };

    if (props.type === OptionTypes.PersonPhoneNumberTypes || props.type === OptionTypes.PersonEmailAddressTypes) {
        return (
            <Select
                {...selectProps}
                value={selected ? { value: selected.value, label: selected.label.charAt(0) } : null}
            />
        );
    }

    return <Select {...selectProps} value={selected} {...(props.virtualize ? { components: { MenuList } } : {})} />;
}

const MenuList = (props: any) => {
    const rows = props.children;

    const _cache = new CellMeasurerCache({
        defaultHeight: 40,
        defaultWidth: 300,
        fixedWidth: true,
    });

    return (
        <List
            deferredMeasurementCache={_cache}
            style={{ width: '100%' }}
            width={300}
            height={rows.length ? Math.min(300, rows.length * _cache.defaultHeight) : 40}
            rowHeight={_cache.rowHeight}
            rowCount={rows.length || 0}
            rowRenderer={({ key, index, isScrolling, isVisible, style, parent }) => (
                <CellMeasurer cache={_cache} columnIndex={0} key={key} parent={parent} rowIndex={index}>
                    {({ measure, registerChild }) => (
                        <div onLoad={measure} ref={registerChild} key={key} style={style}>
                            {rows[index]}
                        </div>
                    )}
                </CellMeasurer>
            )}
            noRowsRenderer={() => <div className={styles.noRows}>No options</div>}
        />
    );
};

export default observer(OptionDropDown);
