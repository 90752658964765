import { Provider } from '../../entity/org/Provider';

export enum NaValues {
    NOT_APPLICABLE = 'NOT_APPLICABLE',
}

export interface IAgencyInformation {
    uniqueStateId: string;
    agencyNumber: string;
    agencyName: string;
    agencyStateCode: string;
    agencyServiceAreas: IAgencyServiceArea[];
    primaryServiceTypeCode: string;
    otherServiceTypeCodes: string[];
    levelOfServiceCode: string;
    organizationStatusCode: string;
    organizationTypeCode: string;
    taxStatusCode: string;
    annualStatistics: IAgencyAnnualStatistics[];
    agencyTimeZoneCode: string;
    agencyDaylightSavingsTimeUseCode: string;
    nationalProviderIds: string[];
    fireDepartmentIds: string[];
    notValues?: { [key in keyof IAgencyInformation]?: any };
}

export interface IAgencyAnnualStatistics {
    calendarYear: number;
    areaSize: number;
    areaPopulation: number;
    annualCallCenterVolume: number;
    annualDispatchVolume: number;
    annualPatientTransportVolume: number;
    annualPatientContactVolume: number;
    annualBillableCalls: number;
}

export interface IAgencyServiceArea {
    areaStateCode: string;
    areaCountyCodes: string[];
    censusTractCodes: string[];
    zipCodes: string[];
}

export interface IAgencyContact {
    contactId: string;
    contactTypeCode: string;
    lastName: string;
    firstName: string;
    middleInitial: string;
    address1: string;
    address2: string;
    city: string;
    stateCode: string;
    zipCode: string;
    countryCode: string;
    phoneNumbers: {
        phoneNumber: string;
        typeCode: string;
    }[];
    emailAddresses: {
        emailAddress: string;
        typeCode: string;
    }[];
    webAddress: string;
    medicalDirectorDegreeCode: string;
    medicalDirectorBoardCertificationTypeCodes: string[];
    medicalDirectorCompensationCode: string;
    medicalDirectorFellowshipTrainedStatusCode: string;
    notValues?: { [key in keyof IAgencyContact]?: any };
}
export type IAgencyContacts = IAgencyContact[];

export interface IAgencyConfiguration {
    certificationLicensureState: string;
    certificationLicensureLevels: string[];
    permittedProcedures: string[];
    permittedMedications: string[];
    permittedProtocols: string[];
    certificationLevelProcedures: IAgencyCertificationLevelProcedure[];
    certificationLevelMedications: IAgencyCertificationLevelMedication[];
    agencyProtocolCodes: string[];
    agencySpecialityServiceCapabilityCodes: string[];
    billingStatusCode: string;
    emdProvidedToAgencyCode: string;
    emdVendor: string[];
    patientMonitoringCapabilityCodes: string[];
    crewCallSigns: string[];
    dispatchCenterNameOrId: string[];
    notValues?: { [key in keyof IAgencyConfiguration]?: any };
}
export type IAgencyConfigurations = IAgencyConfiguration[];

export interface IAgencyCertificationLevelProcedure {
    certificationLevelCode: string;
    procedureCodes: string[];
}

export interface IAgencyCertificationLevelMedication {
    certificationLevelCode: string;
    medicationCodes: string[];
}

export interface IVehicleCertificationLevel {
    licensureLevelCode: string;
    personnelCountNormal911Ambulance: number;
    personnelCountNormal911NonTransport: number;
    personnelCountNormalMedicalNon911: number;
}

export interface IVehicleAnnualUsage {
    yearAccrued: number;
    vehicleHours: number;
    vehicleMilesKilometers: number;
    distanceUnit: string;
}

export interface ITransportFacilityPhone {
    phoneNumber: string;
    typeCode: string;
}

export interface IProviderPhone {
    typeCode: string;
    phoneNumber: string;
}

export interface IProviderEmail {
    typeCode: string;
    emailAddress: string;
}

export interface IProviderImmunization {
    statusCode: string;
    year: number;
}

export interface IProviderLicensure {
    stateCode: string;
    licensureId: string;
    licensureLevelCode: string;
    certificationDate: Date;
    initialStateLicensureIssueDate: Date;
    currentStateLicensureExpirationDate: Date;
}

export interface IProviderCertification {
    practiceLevelCode: string;
    dateOfCertification: Date;
}

export interface IProviderDetails {
    raceCodes?: string[];
    citizenshipCode?: string;
    highestEducationalDegreeCode?: string;
    fieldOfStudyCodes?: string[];
    motorVehicleLicenseTypeCodes?: string[];
    immunizations?: IProviderImmunization[];
    foreignLanguageAbilityCodes?: string[];
    licensures?: IProviderLicensure[];
    nationalRegistryNumber?: string;
    nationalRegistryCertificationLevelCode?: string;
    nationalRegistryExpirationDate?: Date;
    employmentStatusCode?: string;
    employmentStatusDate?: Date;
    hireDate?: Date;
    primaryEmsJobRoleCode?: string;
    otherJobResponsibilityCodes?: string[];
    yearsInService?: number;
    yearsInServiceDocumentedDate?: Date;
    certifications?: IProviderCertification[];
    notValues?: {
        provider?: { [key in keyof Provider]?: any };
        providerDetails?: { [key in keyof IProviderDetails]?: any };
    };
}

export interface IAgencyCustomConfiguration {
    customDataElementTitle: string;
    customDefinition: string;
    customDataType: string;
    customDataElementRecurrence: string;
    customDataElementUsage: string;
    customDataElementPotentialValues: string[];
    customDataElementPotentialNOTValues: object[];
    customDataElementPotentialPertinentNegativeValues: object[];
    customDataElementGroupingId: string;
}

export interface IAgencyCustomResult {
    customDataElementResult: any;
    customElementIdReferenced: string;
    correlationId?: string;
    notValue: string;
    metaData?: any;
}

export enum AgencyStateCode {
    AL = '01',
    AR = '05',
}
