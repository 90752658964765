import React, { ReactNode } from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import { withRouter, useLocation } from 'react-router-dom';

interface Auth0Props {
    history?: any;
    children?: ReactNode;
}

function Auth0ProviderWithHistory(props: Auth0Props) {
    function onRedirectCallback(appState: any) {
        props.history.push(appState && appState.returnTo ? appState.returnTo : window.location.pathname);
    }

    return (
        <Auth0Provider
            domain={import.meta.env.VITE_AUTH0_DOMAIN.toString()}
            clientId={import.meta.env.VITE_AUTH0_CLIENT_ID.toString()}
            audience={import.meta.env.VITE_AUTH0_AUDIENCE.toString()}
            redirectUri={window.location.origin}
            onRedirectCallback={(appState: any) => onRedirectCallback(appState)}
        >
            {props.children}
        </Auth0Provider>
    );
}

export default withRouter(Auth0ProviderWithHistory);
