import React, { useState } from 'react';
import { ToastStoreObject, ToastType } from 'src/stores/ToastStore';
import { parseError, styleSelectComponent } from 'src/utils';
import { ANALYTICS_NAMES } from 'src/utils/analytics';
import Flex from '../Flex';
import Input, { Label } from '../Input';
import LoadingIcon from '../LoadingIcon';
import ModalFooter from '../ModalFooter';
import ModalHeader from '../ModalHeader';
import SideModal from '../SideModal';
import Toggle from '../Toggle';
import { OrgType } from 'common/lib/model/OrgType';
import styles from './styles.module.scss';
import Select from 'react-select';
import * as organizationApi from 'src/api/organization';
import { Customer } from 'common/lib/entity/index/Customer';
import { ModalStoreObject, ModalTypes } from 'src/stores/ModalStore';

interface CreateOrganizationModalProps {
    customerId: Customer['customerId'];
    onClose?: Function;
    onSave?: Function;
}

function CreateOrganizationModal(props: CreateOrganizationModalProps) {
    const [isLoading, setIsLoading] = useState(false);
    const [orgNm, setOrgNm] = useState('');
    const [orgIdentifier, setOrgIdentifier] = useState('');
    const [orgType, setOrgType] = useState(OrgType.GraphiumHealth);
    const [enableVitals, setEnableVitals] = useState(false);
    const [enableAnalytics, setEnableAnalytics] = useState(false);
    const [enableTelehealth, setEnableTelehealth] = useState(false);

    const ORG_IDENTIFIER_REGEX = /^[a-z]{4,32}$/;
    const orgTypeOptions = [
        { label: OrgType.GraphiumHealth, value: OrgType.GraphiumHealth },
        { label: OrgType.FieldMed, value: OrgType.FieldMed },
    ];

    async function handleCreate() {
        if (orgNm === '') {
            ToastStoreObject.show(`Please provide an organization name`);
            return;
        }

        if (!ORG_IDENTIFIER_REGEX.test(orgIdentifier)) {
            ToastStoreObject.show(
                `Please provide a valid organization identifier. It should be 4-32 characters in length, with only alphabetical character (no spaces, dashes, or underscores)`,
            );
            return;
        }

        setIsLoading(true);
        try {
            await organizationApi.createOrganization({
                customerId: props.customerId,
                orgNm,
                orgIdentifier,
                orgType,
                enableAnalytics,
                enableTelehealth,
                enableVitals,
            });

            if (props.onSave) {
                props.onSave();
            }
            if (props.onClose) {
                props.onClose();
            }
            ToastStoreObject.show(`${orgNm} has been created`, ToastType.Success);
        } catch (e: any) {
            ToastStoreObject.show(parseError(e));
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <SideModal isOpen={true} onClose={props.onClose}>
            <ModalHeader title={`New Organization`} onClose={props.onClose} />
            {isLoading ? (
                <div className={styles.loadingContentWrap}>
                    <LoadingIcon />
                </div>
            ) : (
                <div className={styles.contentWrap}>
                    <Input
                        label="Organization Name"
                        value={orgNm}
                        infoState={orgNm.length === 0 ? 'error' : null}
                        onChangeText={(v: string) => setOrgNm(v)}
                        data-test-id={ANALYTICS_NAMES.CreateOrganizationModal_OrgName}
                    />
                    <Input
                        label="Organization Identifier (lowercase alphabetical letters only)"
                        value={orgIdentifier}
                        onChangeText={(v: string) => /^[a-z]{0,32}$/.test(v) && setOrgIdentifier(v)}
                        infoState={ORG_IDENTIFIER_REGEX.test(orgIdentifier) ? null : 'error'}
                        data-test-id={ANALYTICS_NAMES.CreateOrganizationModal_OrgIdentifier}
                    />
                    <Flex value={1}>
                        <Label text="Organization Type" className={styles.label} />
                        <Select
                            value={{ label: orgType, value: orgType }}
                            onChange={(s: any) => {
                                setOrgType(s.value);
                            }}
                            className={styles.select}
                            options={orgTypeOptions}
                            placeholder="Select Organization Type"
                            styles={styleSelectComponent()}
                        />
                    </Flex>
                    {orgType === OrgType.GraphiumHealth && (
                        <>
                            <Flex className={styles.inputWrap} align="center">
                                <Toggle
                                    toggled={enableAnalytics}
                                    onChange={(isToggled: boolean) => setEnableAnalytics(isToggled)}
                                />
                                <Label text="Enable Advanced Analytics?" className={styles.leftMargin} />
                            </Flex>
                            <Flex className={styles.inputWrap} align="center">
                                <Toggle
                                    toggled={enableVitals}
                                    onChange={(isToggled: boolean) => setEnableVitals(isToggled)}
                                />
                                <Label text="Enable Vitals?" className={styles.leftMargin} />
                            </Flex>
                        </>
                    )}
                    <Flex className={styles.inputWrap} align="center">
                        <Toggle
                            toggled={enableTelehealth}
                            onChange={(isToggled: boolean) => setEnableTelehealth(isToggled)}
                        />
                        <Label text="Enable Telehealth?" className={styles.leftMargin} />
                    </Flex>
                </div>
            )}
            <ModalFooter
                primaryText="Create"
                primaryClick={() => {
                    ModalStoreObject.showModal(ModalTypes.ConfirmationModal, {
                        title: `Are you sure you want to create this organization? This process cannot be undone, please double check the data you have entered is correct.`,
                        onConfirm: () => {
                            if (props.onClose) props.onClose();
                            handleCreate();
                            ModalStoreObject.hideModal();
                        },
                        onCancel: () => ModalStoreObject.hideModal(),
                        confirmButtonText: 'Create Organization',
                        isLoading,
                    });
                }}
                secondaryClick={() => props.onClose()}
                isLoading={isLoading}
            />
        </SideModal>
    );
}

export default CreateOrganizationModal;
