import { observable, action, makeObservable } from 'mobx';
import { createContext } from 'react';
import * as userService from 'src/api/user';
import * as orgService from 'src/api/organization';
import * as utils from 'src/utils';
import Storage from 'src/utils/storage';
import { ROUTES } from 'src/utils/constants';
import { Organization, OrgStoreObject } from './OrgStore';
import { AuthStoreObject } from './AuthStore';

export type OrgTypeOld = 'FieldMed' | 'GraphiumHealth';

export enum OrgType {
    FIELDMED = 'FieldMed',
    GRAPHIUM_HEALTH = 'GraphiumHealth',
}

export enum RoleTypes {
    GRAPHIUM_ADMIN = 'GRAPHIUM_ADMIN',
    ORG_ADMIN = 'ORG_ADMIN',
    FACILITY_ADMIN = 'FACILITY_ADMIN',
    BILLING_ADMIN = 'BILLING_ADMIN',
    BUSINESS_ANALYST = 'BUSINESS_ANALYST',
    PROVIDER = 'PROVIDER',
    CREDENTIALING_STAFF = 'CREDENTIALING_STAFF',
    PROVIDER_ADMIN = 'PROVIDER_ADMIN',
    SCHEDULER = 'SCHEDULER',
    DATA_ENTRY_ADMIN = 'DATA_ENTRY_ADMIN',
    DATA_ENTRY_SUPERVISOR = 'DATA_ENTRY_SUPERVISOR',
    DATA_ENTRY_CLERK = 'DATA_ENTRY_CLERK',
    ADVANCED_ANALYTICS_ADMIN = 'ADVANCED_ANALYTICS_ADMIN',
    ADVANCED_ANALYTICS_VIEWER = 'ADVANCED_ANALYTICS_VIEWER',
}
export interface UserData {
    indexUsrId?: number;
    usrNm?: string;
    frstNm?: string;
    lastNm?: string;
    orgUsrId?: number;
    orgEmailAddr?: string;
    indexEmailAddr?: string;
    selectedOrg?: Organization;
    selectedOrgType?: OrgType;
    selectedOrgTelehealthEnabled?: boolean;
    selectedOrgVitalsEnabled?: boolean;
    selectedOrgAnalyticsEnabled?: boolean;
    roleNames?: string[];
    adminIndicator?: boolean;
    employeeIndicator?: boolean;
}
export interface UserPermissions {
    hasBeenSet: boolean;
    canView: {
        patientEncounters?: boolean;
        patientMedications?: boolean;
        patientActivity?: boolean;
        patientTasks?: boolean;
        patientEvents?: boolean;
        patientNeeds?: boolean;
        patientNotes?: boolean;
        patientVitals?: boolean;
        patientEncounterForms?: boolean;
        patientLabs?: boolean;
        patientDetails?: boolean;
        tags?: boolean;
        users?: boolean;
        userTeams?: boolean;
        userFacilities?: boolean;
        referenceLists?: boolean;
        referenceListValues?: boolean;
        invitations?: boolean;
        teams?: boolean;
        providers?: boolean;
        patients?: boolean;
        reports?: boolean;
        facilities?: boolean;
        billing?: boolean;
        macra?: boolean;
        qcdrSettings?: boolean;
        tins?: boolean;
        valueSets?: boolean;
        valueSetDetails?: boolean;
        paymentRequests?: boolean;
        vitalsConnections?: boolean;
        automatedDispenseSystems?: boolean;
        advancedAnalytics?: boolean;
        advancedSurveys?: boolean;
        customers?: boolean;
        surveyDefinitions?: boolean;
        surveyResults?: boolean;
        surveyDefinitionDetails?: boolean;
        formDevelopment?: boolean;
    };
    canCreate: {
        facilities?: boolean;
        patients?: boolean;
        paymentRequests?: boolean;
        vitalsConnections?: boolean;
    };
    canEdit: {
        patientEncounters?: boolean;
        patientMedications?: boolean;
        patientTasks?: boolean;
        patientEvents?: boolean;
        patientNeeds?: boolean;
        patientNotes?: boolean;
        patientVitals?: boolean;
        patientLabs?: boolean;
        patientEncounterForms?: boolean;
        patientDetails?: boolean;
        tags?: boolean;
        users?: boolean;
        userTeams?: boolean;
        userFacilities?: boolean;
        referenceLists?: boolean;
        referenceListValues?: boolean;
        referenceListValuesExcludeFacilities?: boolean;
        invitations?: boolean;
        teams?: boolean;
        providers?: boolean;
        facilities?: boolean;
        events?: boolean;
        nemsis?: boolean;
        valueSetDetails?: boolean;
        vitalsConnections?: boolean;
        automatedDispenseSystems?: boolean;
    };
}

const defaultData: UserData = {
    indexUsrId: undefined,
    usrNm: '',
    frstNm: '',
    lastNm: '',
    orgUsrId: undefined,
    orgEmailAddr: '',
    indexEmailAddr: '',
    selectedOrg: {},
    selectedOrgType: import.meta.env.VITE_APP_BRAND === OrgType.FIELDMED ? OrgType.FIELDMED : OrgType.GRAPHIUM_HEALTH,
    selectedOrgTelehealthEnabled: false,
    selectedOrgVitalsEnabled: false,
    selectedOrgAnalyticsEnabled: false,
    roleNames: [],
    adminIndicator: false,
    employeeIndicator: false,
};

const defaultPermissions: UserPermissions = {
    hasBeenSet: false,
    canView: {
        patientEncounters: false,
        patientMedications: false,
        patientActivity: false,
        patientTasks: false,
        patientEvents: false,
        patientNeeds: false,
        patientNotes: false,
        patientVitals: false,
        patientEncounterForms: false,
        patientLabs: false,
        patientDetails: false,
        tags: false,
        users: false,
        userTeams: false,
        userFacilities: false,
        referenceLists: false,
        referenceListValues: true, // Everyone should be able to view values to populate dropdowns
        invitations: false,
        teams: false,
        providers: false,
        patients: true, // This is the default home page, all users should initially have access until their roles are set
        reports: false,
        facilities: false,
        billing: false,
        valueSets: true,
        valueSetDetails: true,
        paymentRequests: false,
        automatedDispenseSystems: false,
        advancedAnalytics: false,
        advancedSurveys: false,
        customers: false,
        formDevelopment: false,
    },
    canCreate: {
        facilities: false,
        patients: false,
        paymentRequests: false,
    },
    canEdit: {
        patientEncounters: false,
        patientMedications: false,
        patientTasks: false,
        patientEvents: false,
        patientNeeds: false,
        patientNotes: false,
        patientVitals: false,
        patientLabs: false,
        patientEncounterForms: false,
        patientDetails: false,
        tags: false,
        users: false,
        userTeams: false,
        userFacilities: false,
        referenceLists: false,
        referenceListValues: false,
        referenceListValuesExcludeFacilities: false,
        invitations: false,
        teams: false,
        providers: false,
        facilities: false,
        events: false,
        nemsis: false,
        valueSetDetails: false,
        automatedDispenseSystems: false,
    },
};

class UserStore {
    @observable indexUsrId: number = defaultData.indexUsrId;
    @observable usrNm: string = defaultData.usrNm;
    @observable frstNm: string = defaultData.frstNm;
    @observable lastNm: string = defaultData.lastNm;
    @observable orgUsrId: number = defaultData.orgUsrId;
    @observable orgEmailAddr: string = defaultData.orgEmailAddr;
    @observable indexEmailAddr: string = defaultData.indexEmailAddr;
    @observable selectedOrg: Organization = defaultData.selectedOrg;
    @observable selectedOrgType: OrgType = defaultData.selectedOrgType;
    @observable selectedOrgTelehealthEnabled: boolean = defaultData.selectedOrgTelehealthEnabled;
    @observable selectedOrgVitalsEnabled: boolean = defaultData.selectedOrgVitalsEnabled;
    @observable selectedOrgAnalyticsEnabled: boolean = defaultData.selectedOrgAnalyticsEnabled;
    @observable roleNames: string[] = defaultData.roleNames;
    @observable adminIndicator: boolean = defaultData.adminIndicator;
    @observable employeeIndicator: boolean = defaultData.employeeIndicator;
    @observable userPermissions: UserPermissions = defaultPermissions;

    constructor() {
        makeObservable(this);
        this.populateData();
        this.setPermissions(this.roleNames);
    }

    @action
    reset() {
        this.setUserData(defaultData);
        this.clearPermissions();
    }

    @action
    async getMyProfileUser() {
        const response = await orgService.getUserByUsername(this.usrNm);
        this.setOrgData(response.data);
    }

    @action
    setOrgType(orgType?: OrgType) {
        let selectedOrgType = orgType;
        if (!selectedOrgType || (selectedOrgType !== OrgType.FIELDMED && selectedOrgType !== OrgType.GRAPHIUM_HEALTH)) {
            selectedOrgType = defaultData.selectedOrgType;
        }
        this.setUserData({ selectedOrgType });
    }

    @action
    setOrgData(orgUser: any) {
        const roles = (orgUser.roles || []).map((r: any) => (r.details || {}).roleNm);
        if (this.adminIndicator) {
            roles.push(RoleTypes.GRAPHIUM_ADMIN);
        }
        this.setUserData({ orgUsrId: orgUser.usrId, orgEmailAddr: orgUser.emailAddr, roleNames: roles });
        this.setPermissions(roles);
    }

    @action
    clearOrgData() {
        this.orgUsrId = defaultData.orgUsrId;
        this.orgEmailAddr = defaultData.orgEmailAddr;
        this.roleNames = defaultData.roleNames;
        this.clearPermissions();
        Storage.set('orgUsrId', this.orgUsrId);
        Storage.set('orgEmailAddr', this.orgEmailAddr);
        Storage.set('roleNames', this.roleNames);
    }

    @action
    clearPermissions() {
        this.userPermissions = defaultPermissions;
    }

    @action
    setPermissions(roles: string[]) {
        if (roles.length === 0) return;

        this.userPermissions = {
            hasBeenSet: true,
            canView: {
                patientEncounters: roles.some((r: any) =>
                    [
                        RoleTypes.GRAPHIUM_ADMIN,
                        RoleTypes.ORG_ADMIN,
                        RoleTypes.BUSINESS_ANALYST,
                        RoleTypes.FACILITY_ADMIN,
                        RoleTypes.SCHEDULER,
                        RoleTypes.PROVIDER_ADMIN,
                        RoleTypes.PROVIDER,
                        RoleTypes.DATA_ENTRY_ADMIN,
                        RoleTypes.DATA_ENTRY_SUPERVISOR,
                        RoleTypes.DATA_ENTRY_CLERK,
                    ].includes(r),
                ),
                patientMedications: roles.some((r: any) =>
                    [
                        RoleTypes.GRAPHIUM_ADMIN,
                        RoleTypes.ORG_ADMIN,
                        RoleTypes.BUSINESS_ANALYST,
                        RoleTypes.FACILITY_ADMIN,
                        RoleTypes.PROVIDER_ADMIN,
                        RoleTypes.PROVIDER,
                    ].includes(r),
                ),
                patientActivity: roles.some((r: any) =>
                    [
                        RoleTypes.GRAPHIUM_ADMIN,
                        RoleTypes.ORG_ADMIN,
                        RoleTypes.FACILITY_ADMIN,
                        RoleTypes.SCHEDULER,
                        RoleTypes.PROVIDER_ADMIN,
                        RoleTypes.PROVIDER,

                        RoleTypes.BUSINESS_ANALYST,
                    ].includes(r),
                ),
                patientTasks: roles.some((r: any) =>
                    [
                        RoleTypes.GRAPHIUM_ADMIN,
                        RoleTypes.ORG_ADMIN,
                        RoleTypes.BUSINESS_ANALYST,
                        RoleTypes.FACILITY_ADMIN,
                        RoleTypes.PROVIDER_ADMIN,
                        RoleTypes.PROVIDER,
                    ].includes(r),
                ),
                patientEvents: roles.some((r: any) =>
                    [
                        RoleTypes.GRAPHIUM_ADMIN,
                        RoleTypes.ORG_ADMIN,
                        RoleTypes.FACILITY_ADMIN,
                        RoleTypes.SCHEDULER,
                        RoleTypes.PROVIDER_ADMIN,
                        RoleTypes.PROVIDER,
                        RoleTypes.BUSINESS_ANALYST,
                        RoleTypes.BILLING_ADMIN,
                    ].includes(r),
                ),
                patientNeeds: roles.some((r: any) =>
                    [
                        RoleTypes.GRAPHIUM_ADMIN,
                        RoleTypes.ORG_ADMIN,
                        RoleTypes.BUSINESS_ANALYST,
                        RoleTypes.FACILITY_ADMIN,
                        RoleTypes.PROVIDER_ADMIN,
                        RoleTypes.PROVIDER,
                    ].includes(r),
                ),
                patientNotes: roles.some((r: any) =>
                    [
                        RoleTypes.GRAPHIUM_ADMIN,
                        RoleTypes.ORG_ADMIN,
                        RoleTypes.BUSINESS_ANALYST,
                        RoleTypes.FACILITY_ADMIN,
                        RoleTypes.PROVIDER_ADMIN,
                        RoleTypes.PROVIDER,
                    ].includes(r),
                ),
                patientVitals: roles.some((r: any) =>
                    [
                        RoleTypes.GRAPHIUM_ADMIN,
                        RoleTypes.ORG_ADMIN,
                        RoleTypes.BUSINESS_ANALYST,
                        RoleTypes.FACILITY_ADMIN,
                        RoleTypes.PROVIDER_ADMIN,
                        RoleTypes.PROVIDER,
                    ].includes(r),
                ),
                patientLabs: roles.some((r: any) =>
                    [
                        RoleTypes.GRAPHIUM_ADMIN,
                        RoleTypes.ORG_ADMIN,
                        RoleTypes.BUSINESS_ANALYST,
                        RoleTypes.FACILITY_ADMIN,
                        RoleTypes.PROVIDER_ADMIN,
                        RoleTypes.PROVIDER,
                    ].includes(r),
                ),
                patientEncounterForms: roles.some((r: any) =>
                    [
                        RoleTypes.GRAPHIUM_ADMIN,
                        RoleTypes.ORG_ADMIN,
                        RoleTypes.FACILITY_ADMIN,
                        RoleTypes.SCHEDULER,
                        RoleTypes.PROVIDER_ADMIN,
                        RoleTypes.PROVIDER,
                        RoleTypes.DATA_ENTRY_ADMIN,
                        RoleTypes.DATA_ENTRY_SUPERVISOR,
                        RoleTypes.DATA_ENTRY_CLERK,
                        RoleTypes.BUSINESS_ANALYST,
                    ].includes(r),
                ),
                patientDetails: roles.some((r: any) =>
                    [
                        RoleTypes.GRAPHIUM_ADMIN,
                        RoleTypes.ORG_ADMIN,
                        RoleTypes.FACILITY_ADMIN,
                        RoleTypes.SCHEDULER,
                        RoleTypes.BUSINESS_ANALYST,
                        RoleTypes.PROVIDER_ADMIN,
                        RoleTypes.PROVIDER,
                        RoleTypes.DATA_ENTRY_ADMIN,
                        RoleTypes.DATA_ENTRY_SUPERVISOR,
                        RoleTypes.DATA_ENTRY_CLERK,
                    ].includes(r),
                ),
                tags: roles.some((r: any) => [RoleTypes.GRAPHIUM_ADMIN, RoleTypes.ORG_ADMIN].includes(r)),
                users: roles.some((r: any) =>
                    [
                        RoleTypes.GRAPHIUM_ADMIN,
                        RoleTypes.ORG_ADMIN,
                        RoleTypes.PROVIDER_ADMIN,
                        RoleTypes.FACILITY_ADMIN,
                    ].includes(r),
                ),
                userTeams: roles.some((r: any) =>
                    [
                        RoleTypes.GRAPHIUM_ADMIN,
                        RoleTypes.ORG_ADMIN,
                        RoleTypes.PROVIDER_ADMIN,
                        RoleTypes.FACILITY_ADMIN,
                    ].includes(r),
                ),
                userFacilities: roles.some((r: any) =>
                    [
                        RoleTypes.GRAPHIUM_ADMIN,
                        RoleTypes.ORG_ADMIN,
                        RoleTypes.PROVIDER_ADMIN,
                        RoleTypes.FACILITY_ADMIN,
                    ].includes(r),
                ),
                referenceLists: true,
                referenceListValues: true,
                invitations: roles.some((r: any) =>
                    [
                        RoleTypes.GRAPHIUM_ADMIN,
                        RoleTypes.ORG_ADMIN,
                        RoleTypes.FACILITY_ADMIN,
                        RoleTypes.PROVIDER_ADMIN,
                    ].includes(r),
                ),
                teams: roles.some((r: any) =>
                    [
                        RoleTypes.GRAPHIUM_ADMIN,
                        RoleTypes.ORG_ADMIN,
                        RoleTypes.FACILITY_ADMIN,
                        RoleTypes.PROVIDER_ADMIN,
                        RoleTypes.PROVIDER,
                        RoleTypes.BUSINESS_ANALYST,
                        RoleTypes.SCHEDULER,
                    ].includes(r),
                ),
                providers: roles.some((r: any) =>
                    [
                        RoleTypes.GRAPHIUM_ADMIN,
                        RoleTypes.ORG_ADMIN,
                        RoleTypes.FACILITY_ADMIN,
                        RoleTypes.PROVIDER_ADMIN,
                        RoleTypes.CREDENTIALING_STAFF,
                        RoleTypes.BUSINESS_ANALYST,
                    ].includes(r),
                ),
                patients: roles.some((r: any) =>
                    [
                        RoleTypes.GRAPHIUM_ADMIN,
                        RoleTypes.ORG_ADMIN,
                        RoleTypes.FACILITY_ADMIN,
                        RoleTypes.SCHEDULER,
                        RoleTypes.BUSINESS_ANALYST,
                        RoleTypes.PROVIDER_ADMIN,
                        RoleTypes.PROVIDER,
                        RoleTypes.DATA_ENTRY_ADMIN,
                        RoleTypes.DATA_ENTRY_SUPERVISOR,
                        RoleTypes.DATA_ENTRY_CLERK,
                    ].includes(r),
                ),
                reports: roles.some((r: any) =>
                    [
                        RoleTypes.GRAPHIUM_ADMIN,
                        RoleTypes.ORG_ADMIN,
                        RoleTypes.FACILITY_ADMIN,
                        RoleTypes.BUSINESS_ANALYST,
                    ].includes(r),
                ),
                facilities: roles.some((r: any) =>
                    [RoleTypes.GRAPHIUM_ADMIN, RoleTypes.ORG_ADMIN, RoleTypes.BILLING_ADMIN].includes(r),
                ),
                billing: roles.some((r: any) =>
                    [RoleTypes.GRAPHIUM_ADMIN, RoleTypes.ORG_ADMIN, RoleTypes.BILLING_ADMIN].includes(r),
                ),
                macra: roles.some((r: any) =>
                    [RoleTypes.GRAPHIUM_ADMIN, RoleTypes.ORG_ADMIN, RoleTypes.FACILITY_ADMIN].includes(r),
                ),
                qcdrSettings: roles.some((r: any) => [RoleTypes.GRAPHIUM_ADMIN, RoleTypes.ORG_ADMIN].includes(r)),
                tins: roles.some((r: any) => [RoleTypes.GRAPHIUM_ADMIN, RoleTypes.ORG_ADMIN].includes(r)),
                valueSets: true,
                valueSetDetails: true,
                paymentRequests: roles.some((r: any) => [RoleTypes.GRAPHIUM_ADMIN, RoleTypes.ORG_ADMIN].includes(r)),
                vitalsConnections: roles.some((r: any) =>
                    [
                        RoleTypes.GRAPHIUM_ADMIN,
                        RoleTypes.ORG_ADMIN,
                        RoleTypes.FACILITY_ADMIN,
                        RoleTypes.PROVIDER,
                    ].includes(r),
                ),
                automatedDispenseSystems: roles.some((r: any) =>
                    [RoleTypes.GRAPHIUM_ADMIN, RoleTypes.ORG_ADMIN, RoleTypes.FACILITY_ADMIN].includes(r),
                ),
                advancedAnalytics: roles.some((r: any) =>
                    [RoleTypes.ADVANCED_ANALYTICS_ADMIN, RoleTypes.ADVANCED_ANALYTICS_VIEWER].includes(r),
                ),
                customers: roles.some((r: any) => [RoleTypes.GRAPHIUM_ADMIN, RoleTypes.ORG_ADMIN].includes(r)),
                surveyDefinitions: roles.some((r: any) => [RoleTypes.GRAPHIUM_ADMIN, RoleTypes.ORG_ADMIN].includes(r)),
                surveyResults: roles.some((r: any) => [RoleTypes.GRAPHIUM_ADMIN, RoleTypes.ORG_ADMIN].includes(r)),
                surveyDefinitionDetails: roles.some((r: any) =>
                    [RoleTypes.GRAPHIUM_ADMIN, RoleTypes.ORG_ADMIN].includes(r),
                ),
                formDevelopment: roles.some((r: any) => [RoleTypes.GRAPHIUM_ADMIN].includes(r)),
            },
            canCreate: {
                facilities: roles.some((r: any) => [RoleTypes.GRAPHIUM_ADMIN, RoleTypes.ORG_ADMIN].includes(r)),
                patients: roles.some((r: any) =>
                    [
                        RoleTypes.GRAPHIUM_ADMIN,
                        RoleTypes.ORG_ADMIN,
                        RoleTypes.FACILITY_ADMIN,
                        RoleTypes.SCHEDULER,
                        RoleTypes.PROVIDER_ADMIN,
                        RoleTypes.PROVIDER,
                    ].includes(r),
                ),
                paymentRequests: roles.some((r: any) => [RoleTypes.GRAPHIUM_ADMIN, RoleTypes.ORG_ADMIN].includes(r)),
                vitalsConnections: roles.some((r: any) =>
                    [RoleTypes.GRAPHIUM_ADMIN, RoleTypes.ORG_ADMIN, RoleTypes.FACILITY_ADMIN].includes(r),
                ),
            },
            canEdit: {
                patientEncounters: roles.some((r: any) =>
                    [
                        RoleTypes.GRAPHIUM_ADMIN,
                        RoleTypes.ORG_ADMIN,
                        RoleTypes.FACILITY_ADMIN,
                        RoleTypes.SCHEDULER,
                        RoleTypes.PROVIDER_ADMIN,
                        RoleTypes.PROVIDER,
                        RoleTypes.DATA_ENTRY_ADMIN,
                        RoleTypes.DATA_ENTRY_SUPERVISOR,
                        RoleTypes.DATA_ENTRY_CLERK,
                    ].includes(r),
                ),
                patientMedications: roles.some((r: any) =>
                    [
                        RoleTypes.GRAPHIUM_ADMIN,
                        RoleTypes.ORG_ADMIN,
                        RoleTypes.FACILITY_ADMIN,
                        RoleTypes.PROVIDER_ADMIN,
                        RoleTypes.PROVIDER,
                    ].includes(r),
                ),
                patientTasks: roles.some((r: any) =>
                    [
                        RoleTypes.GRAPHIUM_ADMIN,
                        RoleTypes.ORG_ADMIN,
                        RoleTypes.FACILITY_ADMIN,
                        RoleTypes.PROVIDER_ADMIN,
                        RoleTypes.PROVIDER,
                    ].includes(r),
                ),
                patientEvents: roles.some((r: any) =>
                    [
                        RoleTypes.GRAPHIUM_ADMIN,
                        RoleTypes.ORG_ADMIN,
                        RoleTypes.FACILITY_ADMIN,
                        RoleTypes.SCHEDULER,
                        RoleTypes.PROVIDER_ADMIN,
                        RoleTypes.PROVIDER,
                    ].includes(r),
                ),
                patientNeeds: roles.some((r: any) =>
                    [
                        RoleTypes.GRAPHIUM_ADMIN,
                        RoleTypes.ORG_ADMIN,
                        RoleTypes.FACILITY_ADMIN,
                        RoleTypes.PROVIDER_ADMIN,
                        RoleTypes.PROVIDER,
                    ].includes(r),
                ),
                patientNotes: roles.some((r: any) =>
                    [
                        RoleTypes.GRAPHIUM_ADMIN,
                        RoleTypes.ORG_ADMIN,
                        RoleTypes.FACILITY_ADMIN,
                        RoleTypes.PROVIDER_ADMIN,
                        RoleTypes.PROVIDER,
                    ].includes(r),
                ),
                patientVitals: roles.some((r: any) =>
                    [
                        RoleTypes.GRAPHIUM_ADMIN,
                        RoleTypes.ORG_ADMIN,
                        RoleTypes.FACILITY_ADMIN,
                        RoleTypes.PROVIDER_ADMIN,
                        RoleTypes.PROVIDER,
                    ].includes(r),
                ),
                patientLabs: roles.some((r: any) =>
                    [
                        RoleTypes.GRAPHIUM_ADMIN,
                        RoleTypes.ORG_ADMIN,
                        RoleTypes.FACILITY_ADMIN,
                        RoleTypes.PROVIDER_ADMIN,
                        RoleTypes.PROVIDER,
                    ].includes(r),
                ),
                patientEncounterForms: roles.some((r: any) =>
                    [
                        RoleTypes.GRAPHIUM_ADMIN,
                        RoleTypes.ORG_ADMIN,
                        RoleTypes.FACILITY_ADMIN,
                        RoleTypes.PROVIDER_ADMIN,
                        RoleTypes.PROVIDER,
                        RoleTypes.DATA_ENTRY_ADMIN,
                        RoleTypes.DATA_ENTRY_SUPERVISOR,
                        RoleTypes.DATA_ENTRY_CLERK,
                    ].includes(r),
                ),
                patientDetails: roles.some((r: any) =>
                    [
                        RoleTypes.GRAPHIUM_ADMIN,
                        RoleTypes.ORG_ADMIN,
                        RoleTypes.FACILITY_ADMIN,
                        RoleTypes.SCHEDULER,
                        RoleTypes.PROVIDER_ADMIN,
                        RoleTypes.PROVIDER,
                    ].includes(r),
                ),
                tags: roles.some((r: any) => [RoleTypes.GRAPHIUM_ADMIN, RoleTypes.ORG_ADMIN].includes(r)),
                users: roles.some((r: any) =>
                    [
                        RoleTypes.GRAPHIUM_ADMIN,
                        RoleTypes.ORG_ADMIN,
                        RoleTypes.FACILITY_ADMIN,
                        RoleTypes.PROVIDER_ADMIN,
                    ].includes(r),
                ),
                userTeams: roles.some((r: any) =>
                    [
                        RoleTypes.GRAPHIUM_ADMIN,
                        RoleTypes.ORG_ADMIN,
                        RoleTypes.PROVIDER_ADMIN,
                        RoleTypes.FACILITY_ADMIN,
                    ].includes(r),
                ),
                userFacilities: roles.some((r: any) =>
                    [
                        RoleTypes.GRAPHIUM_ADMIN,
                        RoleTypes.ORG_ADMIN,
                        RoleTypes.PROVIDER_ADMIN,
                        RoleTypes.FACILITY_ADMIN,
                    ].includes(r),
                ),
                referenceLists: roles.some((r: any) => [RoleTypes.GRAPHIUM_ADMIN, RoleTypes.ORG_ADMIN].includes(r)),
                referenceListValues: roles.some((r: any) =>
                    [RoleTypes.GRAPHIUM_ADMIN, RoleTypes.ORG_ADMIN].includes(r),
                ),
                referenceListValuesExcludeFacilities: roles.some((r: any) =>
                    [RoleTypes.GRAPHIUM_ADMIN, RoleTypes.ORG_ADMIN, RoleTypes.FACILITY_ADMIN].includes(r),
                ),
                invitations: roles.some((r: any) =>
                    [
                        RoleTypes.GRAPHIUM_ADMIN,
                        RoleTypes.ORG_ADMIN,
                        RoleTypes.FACILITY_ADMIN,
                        RoleTypes.PROVIDER_ADMIN,
                    ].includes(r),
                ),
                teams: roles.some((r: any) =>
                    [
                        RoleTypes.GRAPHIUM_ADMIN,
                        RoleTypes.ORG_ADMIN,
                        RoleTypes.FACILITY_ADMIN,
                        RoleTypes.PROVIDER_ADMIN,
                    ].includes(r),
                ),
                providers: roles.some((r: any) =>
                    [
                        RoleTypes.GRAPHIUM_ADMIN,
                        RoleTypes.ORG_ADMIN,
                        RoleTypes.FACILITY_ADMIN,
                        RoleTypes.PROVIDER_ADMIN,
                        RoleTypes.CREDENTIALING_STAFF,
                    ].includes(r),
                ),
                facilities: roles.some((r: any) =>
                    [RoleTypes.GRAPHIUM_ADMIN, RoleTypes.ORG_ADMIN, RoleTypes.FACILITY_ADMIN].includes(r),
                ),
                events: roles.some((r: any) =>
                    [
                        RoleTypes.GRAPHIUM_ADMIN,
                        RoleTypes.ORG_ADMIN,
                        RoleTypes.FACILITY_ADMIN,
                        RoleTypes.SCHEDULER,
                        RoleTypes.PROVIDER_ADMIN,
                        RoleTypes.PROVIDER,
                    ].includes(r),
                ),
                nemsis: roles.some((r: any) =>
                    [RoleTypes.GRAPHIUM_ADMIN, RoleTypes.ORG_ADMIN, RoleTypes.FACILITY_ADMIN].includes(r),
                ),
                valueSetDetails: roles.some((r: any) =>
                    [RoleTypes.GRAPHIUM_ADMIN, RoleTypes.ORG_ADMIN, RoleTypes.FACILITY_ADMIN].includes(r),
                ),
                vitalsConnections: roles.some((r: any) =>
                    [RoleTypes.GRAPHIUM_ADMIN, RoleTypes.ORG_ADMIN, RoleTypes.FACILITY_ADMIN].includes(r),
                ),
                automatedDispenseSystems: roles.some((r: any) =>
                    [RoleTypes.GRAPHIUM_ADMIN, RoleTypes.ORG_ADMIN, RoleTypes.FACILITY_ADMIN].includes(r),
                ),
            },
        };
    }

    @action
    async updateUser(data: UserData) {
        const user = await userService.updateUser(this.orgUsrId, data); // Send up the org user id to update both the index and org db user data
        if (user.data) {
            const indexUserData = user.data.indexUser;
            const orgUserData = user.data.orgUser;
            this.setUserData({
                indexUsrId: indexUserData.usrId,
                usrNm: indexUserData.usrNm,
                frstNm: indexUserData.frstNm,
                lastNm: indexUserData.lastNm,
                orgUsrId: orgUserData.usrId,
                orgEmailAddr: orgUserData.emailAddr,
                indexEmailAddr: indexUserData.emailAddr,
                adminIndicator: indexUserData.graphiumAdminInd,
                employeeIndicator: indexUserData.graphiumEmployeeInd,
            });
        }
    }

    @action
    setUserData(userData: Partial<UserData>) {
        this.indexUsrId = userData.indexUsrId || this.indexUsrId;
        this.usrNm = userData.usrNm || this.usrNm;
        this.frstNm = userData.frstNm || this.frstNm;
        this.lastNm = userData.lastNm || this.lastNm;
        this.orgUsrId = userData.orgUsrId || this.orgUsrId;
        this.orgEmailAddr = userData.orgEmailAddr || this.orgEmailAddr || '';
        this.indexEmailAddr = userData.indexEmailAddr || this.indexEmailAddr || '';
        this.selectedOrg = userData.selectedOrg || this.selectedOrg;
        this.selectedOrgType = userData.selectedOrgType || this.selectedOrgType;
        this.selectedOrgTelehealthEnabled = utils.isBool(userData.selectedOrgTelehealthEnabled)
            ? userData.selectedOrgTelehealthEnabled
            : this.selectedOrgTelehealthEnabled;
        this.selectedOrgVitalsEnabled = utils.isBool(userData.selectedOrgVitalsEnabled)
            ? userData.selectedOrgVitalsEnabled
            : this.selectedOrgVitalsEnabled;
        this.selectedOrgAnalyticsEnabled = utils.isBool(userData.selectedOrgAnalyticsEnabled)
            ? userData.selectedOrgAnalyticsEnabled
            : this.selectedOrgVitalsEnabled;
        this.roleNames = userData.roleNames || this.roleNames;
        this.adminIndicator = utils.isBool(userData.adminIndicator) ? userData.adminIndicator : this.adminIndicator;
        this.employeeIndicator = utils.isBool(userData.employeeIndicator)
            ? userData.employeeIndicator
            : this.employeeIndicator;

        Storage.set('indexUsrId', this.indexUsrId);
        Storage.set('usrNm', this.usrNm);
        Storage.set('frstNm', this.frstNm);
        Storage.set('lastNm', this.lastNm);
        Storage.set('orgUsrId', this.orgUsrId);
        Storage.set('orgEmailAddr', this.orgEmailAddr);
        Storage.set('indexEmailAddr', this.indexEmailAddr);
        Storage.set('selectedOrg', this.selectedOrg);
        Storage.set('selectedOrgType', this.selectedOrgType);
        Storage.set('selectedOrgTelehealthEnabled', this.selectedOrgTelehealthEnabled);
        Storage.set('selectedOrgVitalsEnabled', this.selectedOrgVitalsEnabled);
        Storage.set('selectedOrgAnalyticsEnabled', this.selectedOrgAnalyticsEnabled);
        Storage.set('roleNames', this.roleNames);
        Storage.set('adminIndicator', this.adminIndicator);
        Storage.set('employeeIndicator', this.employeeIndicator);
    }

    populateData() {
        this.setUserData({
            indexUsrId: utils.getNumberOrNull(Storage.get('indexUsrId')) || this.indexUsrId,
            usrNm: Storage.get('usrNm') || this.usrNm,
            frstNm: Storage.get('frstNm') || this.frstNm,
            lastNm: Storage.get('lastNm') || this.lastNm,
            orgUsrId: utils.getNumberOrNull(Storage.get('orgUsrId')) || this.orgUsrId,
            orgEmailAddr: Storage.get('orgEmailAddr') || this.orgEmailAddr,
            indexEmailAddr: Storage.get('indexEmailAddr') || this.indexEmailAddr,
            roleNames: Storage.get('roleNames') || this.roleNames,
            selectedOrg: Storage.get('selectedOrg') || this.selectedOrg,
            selectedOrgType: Storage.get('selectedOrgType') || this.selectedOrgType,
            selectedOrgTelehealthEnabled: utils.isBool(Storage.get('selectedOrgTelehealthEnabled'))
                ? Storage.get('selectedOrgTelehealthEnabled')
                : this.selectedOrgTelehealthEnabled,
            selectedOrgVitalsEnabled: utils.isBool(Storage.get('selectedOrgVitalsEnabled'))
                ? Storage.get('selectedOrgVitalsEnabled')
                : this.selectedOrgVitalsEnabled,
            selectedOrgAnalyticsEnabled: utils.isBool(Storage.get('selectedOrgAnalyticsEnabled'))
                ? Storage.get('selectedOrgAnalyticsEnabled')
                : this.selectedOrgAnalyticsEnabled,
            adminIndicator: utils.isBool(Storage.get('adminIndicator'))
                ? Storage.get('adminIndicator')
                : this.adminIndicator,
            employeeIndicator: utils.isBool(Storage.get('employeeIndicator'))
                ? Storage.get('employeeIndicator')
                : this.employeeIndicator,
        });
    }

    @action
    changeOrg(org: any, history: any, fromUrl?: boolean) {
        // Need to reset a few fields before changing orgs
        this.clearOrgData();
        AuthStoreObject.resetAllStores(['user', 'org']);

        const newTeleOpt = OrgStoreObject.orgTelehealthOptions.some(
            (o) => o.orgId === org.orgId && o.optVal === 'true',
        );
        const newVitalsOpt = OrgStoreObject.orgVitalsOptions.some((o) => o.orgId === org.orgId && o.optVal === 'true');
        const newAnalyticsOpt = OrgStoreObject.orgAnalyticsOptions.some(
            (o) => o.orgId === org.orgId && o.optVal === 'true',
        );
        this.setUserData({
            selectedOrg: org,
            selectedOrgTelehealthEnabled: newTeleOpt,
            selectedOrgVitalsEnabled: newVitalsOpt,
            selectedOrgAnalyticsEnabled: newAnalyticsOpt,
        });
        const newOrgType = OrgStoreObject.orgTypes.find((o) => o.orgId === org.orgId);
        this.setOrgType((newOrgType || ({} as any)).optVal as OrgType);
        AuthStoreObject.setLoadingStartupData('orgswitch');

        // If we click on a url, dont automatically force the user to go home. This is handled from the privateRoute component.
        if (fromUrl) {
            return;
        } else {
            history.push(ROUTES.getString(ROUTES.Home));
        }
    }

    selectedOrgIsGraphiumHealthOrg(): boolean {
        return this.selectedOrgType === OrgType.GRAPHIUM_HEALTH;
    }

    isGraphiumAdmin(): boolean {
        return this.adminIndicator;
    }

    isGraphiumEmployee(): boolean {
        return this.employeeIndicator;
    }
}

// Import this to any other store that needs to use a value from it
export const UserStoreObject = new UserStore();

export default createContext(UserStoreObject);
