import { observable, action, makeObservable } from 'mobx';
import { createContext } from 'react';
import * as patientsAPI from 'src/api/patients';
import * as utils from 'src/utils';

export interface PatientState {
    patientStateId?: number;
    patientStateCode?: string;
    patientStateTitle?: string;
    patientStateDescription?: string;
    actvInd?: boolean;
}

class PatientStateStore {
    @observable patientStates: PatientState[] = [];
    @observable patientStatesDropdownValues: { value: string | number; label: string }[] = [];
    @observable activePatientStates: PatientState[] = [];
    @observable isLoading = false;

    constructor() {
        makeObservable(this);
    }

    @action
    reset() {
        this.patientStates = [];
        this.patientStatesDropdownValues = [];
        this.activePatientStates = [];
        this.isLoading = false;
    }

    @action
    async getPatientStates(force = false) {
        if (!force && this.patientStates.length > 0) {
            return;
        }
        if (this.isLoading) {
            return;
        }
        try {
            this.isLoading = true;
            const patientStates = await patientsAPI.fetchPatientStates();

            this.setPatientStates(patientStates.data || []);
        } finally {
            this.isLoading = false;
        }
    }

    @action
    setPatientStates(patientStates: PatientState[]) {
        this.patientStates = patientStates;
        const activePatientStates = patientStates.filter((es) => es.actvInd);
        this.activePatientStates = activePatientStates;
        this.patientStatesDropdownValues = utils.formatSelectOptions(activePatientStates, {
            valueKey: 'patientStateId',
            labelKey: 'patientStateTitle',
        });
    }
}

// Import this to any other store that needs to use a value from it
export const PatientStateStoreObject = new PatientStateStore();

export default createContext(PatientStateStoreObject);
