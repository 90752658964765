import { AutomatedDispenseSystem } from 'common/lib/entity/org/AutomatedDispenseSystem';
import { observer } from 'mobx-react';
import React, { useContext, useState } from 'react';
import AutomatedDispenseSystemStore from 'src/stores/AutomatedDispenseSystemStore';
import { ToastStoreObject, ToastType } from 'src/stores/ToastStore';
import { parseError } from 'src/utils';
import { ANALYTICS_NAMES } from 'src/utils/analytics';
import Input from '../Input';
import LoadingIcon from '../LoadingIcon';
import ModalFooter from '../ModalFooter';
import ModalHeader from '../ModalHeader';
import SideModal from '../SideModal';
import styles from './styles.module.scss';

interface AutomatedDispenseSystemModalProps {
    ads?: AutomatedDispenseSystem;
    mode?: string;
    onClose?: Function;
    onSave?: Function;
}

function AutomatedDispenseSystemModal(props: AutomatedDispenseSystemModalProps) {
    const adsStore = useContext(AutomatedDispenseSystemStore);
    const [isLoading, setIsLoading] = useState(false);
    const [ads, setAds] = useState<Partial<AutomatedDispenseSystem>>(props.ads ? props.ads : {});
    const [systemCode, setSystemCode] = useState<AutomatedDispenseSystem['systemCode']>(ads ? ads.systemCode : '');
    const [displayName, setDisplayName] = useState<AutomatedDispenseSystem['displayName']>(ads ? ads.displayName : '');

    async function handleSave() {
        setIsLoading(true);
        await adsStore.updateAutomatedDispenseSystem(props.ads.systemId, {
            displayName,
        });
        try {
            ToastStoreObject.show(`${displayName} has been updated`, ToastType.Success);
            if (props.onSave) {
                props.onSave();
            }
            if (props.onClose) {
                props.onClose();
            }
        } catch (e: any) {
            ToastStoreObject.show(parseError(e));
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <SideModal isOpen={true} onClose={props.onClose}>
            <ModalHeader title={'Edit Automated Dispense System'} onClose={props.onClose} />
            {isLoading ? (
                <div className={styles.loadingContentWrap}>
                    <LoadingIcon />
                </div>
            ) : (
                <div className={styles.contentWrap}>
                    <Input label="System Code" value={systemCode} disabled={true} />
                    <Input
                        label="Display Name"
                        value={displayName}
                        onChangeText={(v: string) => setDisplayName(v)}
                        data-test-id={ANALYTICS_NAMES.AutomatedDispenseSystemModal_DisplayName}
                    />
                </div>
            )}
            <ModalFooter
                primaryText={'Save'}
                primaryClick={handleSave}
                secondaryClick={() => props.onClose()}
                isLoading={isLoading}
            />
        </SideModal>
    );
}

export default observer(AutomatedDispenseSystemModal);
