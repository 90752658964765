import React, { useState, useEffect, useContext, ReactNode } from 'react';
import { format } from 'date-fns';
import styles from './styles.module.scss';
import SideModal from 'src/components/SideModal';
import * as utils from 'src/utils';
import Input, { Label } from 'src/components/Input';
import ModalFooter from 'src/components/ModalFooter';
import * as telehealthService from 'src/api/telehealth';
import ModalHeader from 'src/components/ModalHeader';
import Button from 'src/components/Button';
import EncounterStore from 'src/stores/EncounterStore';
import { Patient } from 'src/stores/PatientStore';
import DatePicker from '../DatePicker';
import TimePicker from '../TimePicker';
import FacilityStore from 'src/stores/FacilityStore';
import { Event, Encounter } from 'src/utils/types';
import { API_DATE_FORMAT } from 'src/utils/constants';
import FacilityDropDown from '../FacilityDropDown';
import PatientDropDown from '../PatientDropDown';
import Flex from '../Flex';
import OptionDropDown, { OptionTypes, TELEHEALTH_PRIORITY_OPTIONS } from '../OptionDropDown';
import { ToastStoreObject } from 'src/stores/ToastStore';
import PatientStateStore from 'src/stores/PatientStateStore';
import { FeatureBranding } from '../FeatureFlag';
import UserStore, { OrgType } from 'src/stores/UserStore';

export interface EncounterModalProps {
    isOpen: boolean;
    encounter?: Encounter;
    onClose: Function;
    onSave: Function;
    patient?: Patient;
    patientId?: number;
    eventId?: Event['eventId'];
    facId?: number;
    isStartingFromAppt?: boolean;
    isAdHoc?: boolean;
    isTelehealth?: boolean;
    title?: ReactNode;
}

function EncounterModal(props: EncounterModalProps) {
    const pEnc = props.encounter;
    const pPat = props.patient || {};
    const defaultEncounter: Encounter = {
        enctrNo: pEnc ? pEnc.enctrNo : null,
        patMrn: pEnc ? pEnc.patMrn : '',
        facId: pEnc ? pEnc.facId : pPat.facId ? pPat.facId : props.facId,
        patientId: pEnc ? pEnc.patientId : pPat.patientId,
        patLastNm: pEnc ? pEnc.patLastNm : pPat.patientLastName,
        patFrstNm: pEnc ? pEnc.patFrstNm : pPat.patientFirstName,
        patMidNm: pEnc ? pEnc.patMidNm : pPat.patientMiddleName,
        patSsn: pEnc ? pEnc.patSsn : pPat.patientSsn,
        patDob: pEnc ? pEnc.patDob : pPat.patientDob,
        patGenderCd: pEnc ? pEnc.patGenderCd : pPat.patientGenderCode,
        admitDt:
            props.isStartingFromAppt || props.isAdHoc || props.isTelehealth
                ? format(new Date(), API_DATE_FORMAT)
                : pEnc
                ? pEnc.admitDt
                : null,
        admitTm:
            props.isStartingFromAppt || props.isAdHoc || props.isTelehealth
                ? format(new Date(), 'HH:mm:00')
                : pEnc
                ? pEnc.admitTm
                : null,
        dischDt: pEnc ? pEnc.dischDt : null,
        dischTm: pEnc ? pEnc.dischTm : null,
    };
    const encounterStore = useContext(EncounterStore);
    const [encounter, setEncounter] = useState(defaultEncounter);
    const facilityStore = useContext(FacilityStore);
    const [isLoading, setIsLoading] = useState(false);
    const patientStateStore = useContext(PatientStateStore);
    const userStore = useContext(UserStore);

    const [adHocState, setAdHocState] = useState({ facId: null, patientId: null });
    const DEFAULT_TELEHEALTH_STATE = {
        telehealthSessionReason: '',
        priorityCode: TELEHEALTH_PRIORITY_OPTIONS[0].value,
        patientEmail: '',
        patientPhone: '',
    };
    const [telehealthState, setTelehealthState] = useState(DEFAULT_TELEHEALTH_STATE);
    const [isCreatingNewPatient, setIsCreatingNewPatient] = useState(false);
    const isEdit = !!props.encounter;

    useEffect(() => {
        if (facilityStore.facilities.length === 0) {
            facilityStore.getFacilities();
        }

        // Org type feature
        if (userStore.selectedOrgType === OrgType.GRAPHIUM_HEALTH) {
            setIsCreatingNewPatient(true);
        }
    }, []);

    useEffect(() => {
        if (props.patientId) {
            setEncounter({ ...encounter, patientId: props.patientId });
        }
        if (props.facId) {
            setEncounter({ ...encounter, facId: props.facId });
            setAdHocState({ ...adHocState, facId: props.facId });
        }
    }, [props.patientId, props.facId]);

    const updateInput = (param: string) => (t: string) => setEncounter({ ...encounter, [param]: t });

    async function createEncounter(newEncounter: Encounter, createNewPatient: boolean) {
        let createdEncounter;
        try {
            setIsLoading(true);
            if (props.patient) {
                createdEncounter = await encounterStore.createPatientEncounter(
                    props.patientId,
                    newEncounter,
                    props.eventId,
                );
            } else {
                if (createNewPatient) {
                    createdEncounter = await encounterStore.createEncounterWithPatient(newEncounter, props.eventId);
                } else {
                    createdEncounter = await encounterStore.createEncounter(newEncounter, props.eventId);
                }
            }
        } catch (e) {
            ToastStoreObject.show(utils.parseError(e));
        } finally {
            setIsLoading(false);
        }
        return createdEncounter;
    }

    async function updateEncounter(updateEncounterData: Encounter) {
        try {
            setIsLoading(true);
            await encounterStore.updateEncounter(updateEncounterData.enctrId, updateEncounterData);
        } finally {
            setIsLoading(false);
        }
    }

    async function save() {
        try {
            setIsLoading(true);
            if (isEdit) {
                const editableFieldsArr = Object.keys(defaultEncounter);
                const changedData = utils.onlyChangedFields(props.encounter, encounter, editableFieldsArr);
                await updateEncounter({ ...changedData, enctrId: props.encounter.enctrId });
            } else {
                let newEncounter: any = encounter;
                let newPatient: Patient;
                if (props.isAdHoc) {
                    newEncounter = { ...encounter, ...adHocState };
                    if (isCreatingNewPatient) {
                        newPatient = {
                            facId: adHocState.facId,
                            patientFirstName: encounter.patFrstNm,
                            patientLastName: encounter.patLastNm,
                            patientMiddleName: encounter.patMidNm,
                            patientSsn: encounter.patSsn,
                            patientStateId:
                                (
                                    patientStateStore.activePatientStates.find(
                                        (s) => s.patientStateCode === 'ACTIVE',
                                    ) || {}
                                ).patientStateId || 1,
                            patientGenderCode: encounter.patGenderCd,
                            patientDob: encounter.patDob,
                        };
                        delete newEncounter.patientId;
                    }
                }
                if (props.isTelehealth) {
                    let telehealthData: any = {
                        ...newEncounter,
                        priorityCode: telehealthState.priorityCode,
                        telehealthSessionReason: telehealthState.telehealthSessionReason,
                        patientEmail: telehealthState.patientEmail,
                        patientPhone: telehealthState.patientPhone,
                    };
                    if (newPatient) {
                        if (!newPatient.patientDob) {
                            ToastStoreObject.show('You must enter a Date of Birth for the patient');
                            setIsLoading(false);
                            return;
                        }
                        telehealthData = { ...telehealthData, ...newPatient };
                    }

                    await telehealthService.createSessionWithEncounter(telehealthData);
                } else {
                    if (newPatient) {
                        if (!newEncounter.patLastNm || !newEncounter.patFrstNm) {
                            ToastStoreObject.show(
                                `You must enter a ${!newEncounter.patFrstNm ? 'First' : ''}${
                                    !newEncounter.patFrstNm && !newEncounter.patLastNm ? ' & ' : ''
                                }${!newEncounter.patLastNm ? 'Last' : ''} Name for the patient`,
                            );
                            setIsLoading(false);
                            return;
                        }

                        newEncounter = { ...newEncounter, ...newPatient };
                    }
                    await createEncounter(newEncounter, !!newPatient);
                }
            }
            setIsLoading(false);
            props.onSave();
        } catch (error) {
            setIsLoading(false);
        }
    }
    return (
        <SideModal isOpen={props.isOpen} onClose={props.onClose}>
            <ModalHeader
                title={props.title ? props.title : isEdit ? 'Update Encounter' : 'New Encounter'}
                onClose={props.onClose}
            />
            <div className={styles.inviteModalContentWrap}>
                {/* Only show these fields if we're not coming from an appt */}
                {!props.isStartingFromAppt ? (
                    <>
                        {encounter.enctrId ? (
                            <Input
                                disabled={true}
                                className={styles.input}
                                label="Encounter #"
                                value={encounter.enctrNo}
                                onChangeText={updateInput('enctrId')}
                            />
                        ) : null}
                    </>
                ) : null}
                {isEdit && encounter.facId ? (
                    <div>
                        <Label text="Associate this Encounter with a Patient" />
                        <PatientDropDown
                            facId={encounter.facId}
                            selectedValue={encounter.patientId}
                            onChange={(patient: Patient) =>
                                setEncounter({ ...encounter, patientId: patient ? patient.patientId : undefined })
                            }
                        />
                    </div>
                ) : null}
                {props.isAdHoc || props.isTelehealth ? (
                    <>
                        {!props.isTelehealth ? (
                            <FacilityDropDown
                                onChange={(facility: any) => setAdHocState({ ...adHocState, facId: facility.value })}
                                placeholder="Select a Facility"
                            />
                        ) : null}
                        <FeatureBranding
                            fieldmedContent={
                                <Flex direction="row" className={styles.patientCreatorSelection}>
                                    <Button
                                        type={!isCreatingNewPatient ? 'primary' : 'outline'}
                                        text="Select Existing Patient"
                                        onClick={() => setIsCreatingNewPatient(false)}
                                        preventDouble={false}
                                    />
                                    <Button
                                        type={isCreatingNewPatient ? 'primary' : 'outline'}
                                        text="Create New Patient"
                                        preventDouble={false}
                                        className={styles.patientBtn}
                                        onClick={() => setIsCreatingNewPatient(true)}
                                    />
                                </Flex>
                            }
                            graphiumContent={null}
                        />
                        <FeatureBranding
                            fieldmedContent={null}
                            graphiumContent={
                                <Input
                                    className={styles.input}
                                    label="Encounter Number"
                                    value={encounter.enctrNo}
                                    onChangeText={updateInput('enctrNo')}
                                />
                            }
                        />
                        {isCreatingNewPatient ? (
                            <>
                                <Input
                                    className={styles.input}
                                    label="Patient MRN"
                                    value={encounter.patMrn}
                                    onChangeText={updateInput('patMrn')}
                                />
                                <Input
                                    className={styles.input}
                                    label="Patient First Name"
                                    value={encounter.patFrstNm}
                                    onChangeText={updateInput('patFrstNm')}
                                />
                                <Input
                                    className={styles.input}
                                    label="Patient Last Name"
                                    value={encounter.patLastNm}
                                    onChangeText={updateInput('patLastNm')}
                                />
                                <Input
                                    className={styles.input}
                                    label="Patient Middle Name"
                                    value={encounter.patMidNm}
                                    onChangeText={updateInput('patMidNm')}
                                />
                                <div>
                                    <Label text="Gender" />
                                    <OptionDropDown
                                        facId={adHocState.facId}
                                        type={OptionTypes.Gender}
                                        onChange={(s: any) => setEncounter({ ...encounter, patGenderCd: s.value })}
                                        selectedValue={encounter.patGenderCd}
                                    />
                                </div>
                                <Input
                                    className={styles.input}
                                    label="Patient SSN"
                                    value={encounter.patSsn}
                                    onChangeText={updateInput('patSsn')}
                                />
                                <DatePicker
                                    label="Patient DOB"
                                    required={props.isTelehealth}
                                    onlyPast={true}
                                    value={encounter.patDob}
                                    onChange={(date: any) => {
                                        if (date) {
                                            const value = format(new Date(date), API_DATE_FORMAT);
                                            setEncounter({ ...encounter, patDob: value });
                                        }
                                    }}
                                />
                            </>
                        ) : (
                            <PatientDropDown
                                isDisabled={!adHocState.facId}
                                facId={adHocState.facId}
                                selectedValue={adHocState.patientId}
                                onChange={(patient: Patient) => {
                                    setAdHocState({
                                        ...adHocState,
                                        patientId: patient ? patient.patientId : undefined,
                                    });
                                    if (patient) {
                                        const newTelehealthState = { ...telehealthState };
                                        if (patient.patientEmails[0]) {
                                            newTelehealthState.patientEmail = (patient.patientEmails[0] || {}).email;
                                        }
                                        if (patient.patientPhones) {
                                            newTelehealthState.patientPhone = utils.getPatientPhone(
                                                patient.patientPhones,
                                            );
                                        }
                                        setTelehealthState(newTelehealthState);
                                    } else {
                                        setTelehealthState(DEFAULT_TELEHEALTH_STATE);
                                    }
                                }}
                            />
                        )}
                    </>
                ) : null}
                {props.isTelehealth ? (
                    <>
                        <Input
                            className={styles.input}
                            label="Telehealth Visit Reason"
                            value={telehealthState.telehealthSessionReason}
                            onChangeText={(t: string) =>
                                setTelehealthState({ ...telehealthState, telehealthSessionReason: t })
                            }
                        />
                        <div>
                            <Label text="Telehealth Visit Priority" />
                            <OptionDropDown
                                facId={adHocState.facId}
                                type={OptionTypes.TelehealthPriority}
                                onChange={(s: any) => setTelehealthState({ ...telehealthState, priorityCode: s.value })}
                                selectedValue={telehealthState.priorityCode}
                            />
                        </div>
                        <Input
                            className={styles.input}
                            label="Patient Email to Notify"
                            value={telehealthState.patientEmail}
                            onChangeText={(t: string) => setTelehealthState({ ...telehealthState, patientEmail: t })}
                        />
                        <Input
                            className={styles.input}
                            label="Patient Phone Number to Notify"
                            value={telehealthState.patientPhone}
                            onChangeText={(t: string) => setTelehealthState({ ...telehealthState, patientPhone: t })}
                        />
                    </>
                ) : null}
                {/* Always show these fields for creating a visit from an appt and also from just creating/updating one */}
                {!props.isTelehealth ? (
                    <>
                        <DatePicker
                            label="Start Date"
                            value={encounter.admitDt}
                            onChange={(date: any) => {
                                if (date) {
                                    const value = format(new Date(date), API_DATE_FORMAT);
                                    setEncounter({ ...encounter, admitDt: value });
                                }
                            }}
                        />
                        <TimePicker onChange={updateInput('admitTm')} time={encounter.admitTm} />
                        <DatePicker
                            label="End Date"
                            value={encounter.dischDt}
                            onChange={(date: any) => {
                                if (date) {
                                    const value = format(new Date(date), API_DATE_FORMAT);
                                    setEncounter({ ...encounter, dischDt: value });
                                }
                            }}
                        />
                        <TimePicker onChange={updateInput('dischTm')} time={encounter.dischTm} />
                    </>
                ) : null}
            </div>
            <ModalFooter
                primaryText={isEdit ? 'Save' : 'Create'}
                primaryClick={save}
                secondaryClick={() => props.onClose()}
                isLoading={isLoading}
            />
        </SideModal>
    );
}

export default EncounterModal;
