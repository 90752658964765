import React from 'react';
import Flex from 'src/components/Flex';
import styles from 'src/containers/Reports/Macra/styles.module.scss';
import Icon from 'src/components/Icon';

export function IconLegendModal() {
    return (
        <div className={styles.legendModal}>
            <Flex direction="column">
                <Flex direction="row">
                    <Flex direction="column" value={1} align="end">
                        <p className={styles.wrapRight}>
                            <Icon type="fa" name="check" className={styles.green} title="Performance Met" />
                        </p>
                    </Flex>
                    <Flex value={10}>
                        <p>
                            <strong>Perfomance Met</strong>
                            <br />
                            Case is eligible for this measure (based on denominator criteria), and evaluation of
                            numerator criteria resulted in successful performance
                        </p>
                    </Flex>
                </Flex>
                <Flex direction="row">
                    <Flex direction="column" value={1} align="end">
                        <p className={styles.wrapRight}>
                            <Icon type="fa" name="times" className={styles.red} title="Performance Not Met" />
                        </p>
                    </Flex>
                    <Flex value={10}>
                        <p>
                            <strong>Perfomance Not Met</strong>
                            <br />
                            Case is eligible for this measure (based on denominator criteria), but evaluation of
                            numerator criteria resulted in failed performance
                        </p>
                    </Flex>
                </Flex>
                <Flex direction="row">
                    <Flex direction="column" value={1} align="end">
                        <p className={styles.wrapRight}>
                            <Icon
                                type="fa"
                                name="exclamation"
                                className={styles.yellow}
                                title="Data Completeness Not Met"
                            />
                        </p>
                    </Flex>
                    <Flex value={10}>
                        <p>
                            <strong>Data Completeness Not Met</strong>
                            <br />
                            Case is eligible for this measure (based on denominator criteria) but is missing data
                            required for numerator evaluation
                        </p>
                    </Flex>
                </Flex>
                <Flex direction="row">
                    <Flex direction="column" value={1} align="end">
                        <p className={styles.wrapRight}>
                            <Icon type="fa" name="minus" className={styles.grey} title="Ineligible" />
                        </p>
                    </Flex>
                    <Flex value={10}>
                        <p>
                            <strong>Ineligible</strong>
                            <br />
                            Case is ineligible for this measure due to Denominator Exclusion criteria or because of
                            missing fields. Denominator Exclusion criteria is specifically defined in each measure. For
                            example, an ASA Physical Status of 5 may mean a given measure does not apply to a given
                            case. "Performance Met" rate for this measure will not be affected by this case. Please
                            review the measure definition for further details.
                        </p>
                    </Flex>
                </Flex>
                <Flex direction="row">
                    <Flex direction="column" value={1} align="end">
                        <p className={styles.wrapRight}>
                            <Icon type="fa" name="asterisk" className={styles.green} title="Denominator Exception" />
                        </p>
                    </Flex>
                    <Flex value={10}>
                        <p>
                            <strong>Denominator Exception</strong>
                            <br />
                            Based on denominator criteria for this measure, case was eligible, but it was ultimately
                            excluded because it met certain additional criteria as defined by the measure. "Performance
                            Met" rate for this measure will not be affected by this case. Please review the measure
                            definition for further details.
                        </p>
                    </Flex>
                </Flex>
                <Flex direction="row">
                    <Flex direction="column" value={1} align="end"></Flex>
                    <Flex value={10}>
                        <p>
                            <strong>Admissible</strong>
                            <br />
                            Case is admissible to the QCDR. If a case is not admissible, see the Errors listed on the
                            case for a further explanation.
                        </p>
                    </Flex>
                </Flex>
            </Flex>
        </div>
    );
}
