import React, { useState, useEffect } from 'react';
import styles from './styles.module.scss';
import Input from 'src/components/Input';
import { Tag } from 'src/stores/TagStore';
import { observer } from 'mobx-react';

export interface TagModalProps {
    tag: Tag;
    onChange: Function;
}

function TagModal(props: TagModalProps) {
    const [tagNm, setTagName] = useState(props.tag.tagNm || '');
    const [tagDesc, setTagDescription] = useState(props.tag.tagDesc || '');
    const [catgNm, setTagCategory] = useState(props.tag.catgNm || '');

    useEffect(() => {
        props.onChange({
            tagId: props.tag.tagId,
            tagNm,
            tagDesc,
            catgNm,
        });
    }, [tagNm, tagDesc, catgNm]);

    return (
        <div className={styles.contentWrap}>
            <Input label="Tag Name" value={tagNm} onChangeText={(value: string) => setTagName(value)} />
            <Input label="Category" value={catgNm} onChangeText={(value: string) => setTagCategory(value)} />
            <Input label="Description" value={tagDesc} onChangeText={(value: string) => setTagDescription(value)} />
        </div>
    );
}

export default observer(TagModal);
