import React, { useContext, useEffect, useState } from 'react';
import styles from './styles.module.scss';
import Flex from 'src/components/Flex/index';
import { observer } from 'mobx-react';
import EncounterStore from 'src/stores/EncounterStore';
import Button from 'src/components/Button';
import Icon from 'src/components/Icon';
import { ICONS } from 'src/utils/constants';
import { toJS } from 'mobx';
import { Patient } from 'src/stores/PatientStore';
import { ModalTypes, ModalStoreObject } from 'src/stores/ModalStore';
import LoadingCover from 'src/components/LoadingCover';
import { ANALYTICS_NAMES } from 'src/utils/analytics';
import UserStore from 'src/stores/UserStore';
import Checkbox from 'src/components/Checkbox';
import { getPatientName } from 'src/utils';
import EncounterItem from 'src/components/EncounterItem';
import { Encounter } from 'src/utils/types';

interface VisitsProps {
    patientId: Patient['patientId'];
    patient: Patient;
}

function Visits(props: VisitsProps) {
    const encounterStore = useContext(EncounterStore);
    const userStore = useContext(UserStore);
    const [isLoading, setIsLoading] = useState(false);
    const [filters, setFilters] = useState({ type: [], status: [] });
    const patientEncounters = encounterStore.patientEncounters;
    const [isShowVoidedFormsChecked, setIsShowVoidedFormsChecked] = useState(false);

    async function loadEncounters() {
        try {
            setIsLoading(true);
            await encounterStore.getPatientEncounters(props.patientId, {
                filters,
            });
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        loadEncounters();
    }, [filters]);

    function openCreateEncounterModal() {
        ModalStoreObject.showModal(ModalTypes.Encounter, {
            onClose: () => {
                ModalStoreObject.hideModal();
            },
            title: `New Visit for ${getPatientName(props.patient)}`,
            isOpen: true,
            onSave: async () => {
                ModalStoreObject.hideModal();
                loadEncounters();
            },
            patientId: props.patientId,
            facId: props.patient.facId,
            patient: props.patient,
        });
    }

    return (
        <div className={styles.container}>
            <Flex self="stretch" align="center" justify="start" className={styles.headerWrap}>
                <Flex value={1} className={styles.filterWrap}>
                    <Checkbox
                        checked={isShowVoidedFormsChecked}
                        onChange={(e) => setIsShowVoidedFormsChecked(e.target.checked)}
                        label="Show Voided Forms"
                        className={styles.typeCheckbox}
                    />
                </Flex>
                <Flex value={2} justify="end">
                    <Button
                        leftIcon={<Icon className={styles.plusIcon} name={ICONS.PlusButton} />}
                        type="primary"
                        isLocked={!userStore.userPermissions.canEdit.patientEncounters}
                        onClick={() => openCreateEncounterModal()}
                        text="Create Encounter"
                        data-test-id={ANALYTICS_NAMES.PatientDetails_Visits_Create}
                    />
                </Flex>
            </Flex>
            <div style={{ position: 'relative', minHeight: 250 }}>
                {isLoading ? <LoadingCover /> : null}
                {toJS(patientEncounters).length === 0 ? (
                    <Flex>No Encounters for this Patient Yet</Flex>
                ) : (
                    toJS(patientEncounters).map((encounter: Encounter) => (
                        <EncounterItem
                            key={encounter.enctrId}
                            encounter={encounter}
                            patient={props.patient}
                            isShowVoidedFormsChecked={isShowVoidedFormsChecked}
                            onSave={loadEncounters}
                        />
                    ))
                )}
            </div>
        </div>
    );
}

export default observer(Visits);
