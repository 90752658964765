import { AbgQcdrMeasure, AbgMeasureCalculatedMean } from '../model/macra/Macra';
import { MacraCaseData } from '../model/macra/MacraYear';

export enum ValueMode {
    Projected = 'Projected',
    Actual = 'Actual',
}

export const measureDefinitions2022: AbgQcdrMeasure[] = [
    { name: 'ABG40', label: 'ABG40', displayOrder: 1, inverted: false },
    { name: 'ABG41', label: 'ABG41', displayOrder: 2, inverted: false },
    { name: 'ABG42', label: 'ABG42', displayOrder: 3, inverted: false },
    { name: 'ABG43', label: 'ABG43', displayOrder: 4, inverted: false },
    { name: 'AQI48A', label: 'AQI48A', displayOrder: 5, inverted: false },
    { name: 'AQI48B', label: 'AQI48B', displayOrder: 6, inverted: false },
    { name: 'AQI56', label: 'AQI56', displayOrder: 7, inverted: false },
    { name: 'AQI62', label: 'AQI62', displayOrder: 8, inverted: false },
    { name: 'AQI68', label: 'AQI68', displayOrder: 9, inverted: false },
    { name: 'QID404', label: 'QID404', displayOrder: 10, inverted: false },
    { name: 'QID424', label: 'QID424', displayOrder: 11, inverted: false },
    { name: 'QID430', label: 'QID430', displayOrder: 12, inverted: false },
    { name: 'QID477', label: 'QID477', displayOrder: 13, inverted: false },
    { name: 'MD54', label: 'MD54', displayOrder: 14, inverted: true },
];

export const measureDefinitions2023: AbgQcdrMeasure[] = [
    { name: 'ABG40', label: 'ABG40', displayOrder: 1, inverted: false },
    { name: 'ABG41', label: 'ABG41', displayOrder: 2, inverted: false },
    { name: 'ABG42', label: 'ABG42', displayOrder: 3, inverted: false },
    { name: 'ABG43', label: 'ABG43', displayOrder: 4, inverted: false },
    { name: 'ABG44', label: 'ABG44', displayOrder: 5, inverted: false },
    { name: 'AQI48', label: 'AQI48', displayOrder: 6, inverted: false },
    { name: 'AQI56', label: 'AQI56', displayOrder: 7, inverted: false },
    { name: 'AQI65', label: 'AQI65', displayOrder: 8, inverted: false },
    { name: 'AQI68', label: 'AQI68', displayOrder: 9, inverted: false },
    { name: 'AQI72', label: 'AQI72', displayOrder: 10, inverted: false },
    { name: 'AQI73', label: 'AQI73', displayOrder: 11, inverted: false },
    { name: 'QID404', label: 'QID404', displayOrder: 12, inverted: false },
    { name: 'QID424', label: 'QID424', displayOrder: 13, inverted: false },
    { name: 'QID430', label: 'QID430', displayOrder: 14, inverted: false },
    { name: 'QID477', label: 'QID477', displayOrder: 15, inverted: false },
    { name: 'MD54', label: 'MD54', displayOrder: 16, inverted: true },
];

export const measureDefinitions2024: AbgQcdrMeasure[] = [
    { name: 'ABG42', label: 'ABG42', displayOrder: 1, inverted: false },
    { name: 'ABG44', label: 'ABG44', displayOrder: 1, inverted: false },
    { name: 'AQI18', label: 'AQI18', displayOrder: 1, inverted: false },
    { name: 'AQI48', label: 'AQI48', displayOrder: 1, inverted: false },
    { name: 'AQI65', label: 'AQI65', displayOrder: 1, inverted: false },
    { name: 'AQI67', label: 'AQI67', displayOrder: 1, inverted: false },
    { name: 'AQI71A', label: 'AQI71A', displayOrder: 1, inverted: false },
    { name: 'AQI71B', label: 'AQI71B', displayOrder: 1, inverted: false },
    { name: 'AQI71C', label: 'AQI71C', displayOrder: 1, inverted: false },
    { name: 'AQI71D', label: 'AQI71D', displayOrder: 1, inverted: false },
    { name: 'AQI72', label: 'AQI72', displayOrder: 1, inverted: false },
    { name: 'QID404', label: 'QID404', displayOrder: 1, inverted: false },
    { name: 'QID424', label: 'QID424', displayOrder: 1, inverted: false },
    { name: 'QID430', label: 'QID430', displayOrder: 1, inverted: false },
    { name: 'QID463', label: 'QID463', displayOrder: 1, inverted: false },
    { name: 'QID477', label: 'QID477', displayOrder: 1, inverted: false },
];

export function calculateMeasureMeans(
    cases: MacraCaseData[],
    measureDefinitions: AbgQcdrMeasure[],
    valueMode: ValueMode,
): AbgMeasureCalculatedMean[] {
    return measureDefinitions.map((measureDefinition) => {
        const measureMeanData = cases.reduce(
            (aggregator, data, currentIndex, array) => {
                const qcdrEvalResult =
                    valueMode === ValueMode.Projected ? data.qcdrEvalResultProjected : data.qcdrEvalResult;

                if (qcdrEvalResult && qcdrEvalResult.measures) {
                    const result = qcdrEvalResult.measures.find(
                        (measureResult) => measureResult.name == measureDefinition.name,
                    );
                    if (result) {
                        if (aggregator.numerator == null) aggregator.numerator = 0;
                        if (aggregator.denominator == null) aggregator.denominator = 0;
                        aggregator.numerator += result.perfMet;
                        aggregator.denominator += result.perfMet + result.perfNotMet;
                    }
                }
                return aggregator;
            },
            { numerator: null, denominator: null },
        );

        let mean = null;
        let denominator = null;
        let numerator = null;

        if (measureMeanData.denominator !== null && measureMeanData.numerator !== null) {
            mean = measureMeanData.numerator / measureMeanData.denominator || 0;
            numerator = measureMeanData.numerator;
            denominator = measureMeanData.denominator;
        }

        const calculatedMean: AbgMeasureCalculatedMean = Object.assign(
            {},
            {
                measureDefinition,
                denominator,
                numerator,
                mean: mean !== null && measureDefinition.inverted ? 1 - mean : mean,
            },
        );

        return calculatedMean;
    });
}
