import React, { useState, useEffect, useContext } from 'react';
import styles from './styles.module.scss';
import Select from 'react-select';
import VitalsStore from 'src/stores/VitalsStore';
import { observer } from 'mobx-react';
import { formatSelectOptions, styleSelectComponent } from 'src/utils';
import useIsOptionsLoading from 'src/utils/hooks/useIsOptionsLoading';
import { VitalsMeasure } from 'common/lib/entity/framework/VitalsMeasure';

export interface VitalsMeasureDropDownProps {
    onChange: Function;
    measureCategoryId?: VitalsMeasure['measureCategoryId'];
    selectedValue?: number | string;
}

function VitalsMeasureDropdown(props: VitalsMeasureDropDownProps) {
    const vitalsStore = useContext(VitalsStore);
    const filteredMeasures = props.measureCategoryId
        ? vitalsStore.vitalsMeasures.filter(
              (measure: VitalsMeasure) => measure.measureCategoryId === props.measureCategoryId,
          )
        : vitalsStore.vitalsMeasures;
    const options = formatSelectOptions(filteredMeasures, {
        valueKey: 'measureCode',
        getLabel: (m: VitalsMeasure) =>
            m.measureUnits ? `${m.measureTitle} (${m.measureUnits})` : `${m.measureTitle}`,
    });
    const [selected, setSelected] = useState(options.find((i) => i.value === props.selectedValue) || null);
    const isLoading = useIsOptionsLoading(options);

    useEffect(() => {
        vitalsStore.getVitalsMeasures();
    }, []);

    useEffect(() => {
        const selectedVal = options.find((i) => i.value === props.selectedValue);
        if (selectedVal) {
            setSelected(selectedVal);
        }
    }, [props.selectedValue]);

    return (
        <Select
            value={selected}
            onChange={(s: any) => {
                setSelected(s);
                props.onChange(s || null);
            }}
            isLoading={isLoading}
            isClearable={true}
            className={styles.select}
            options={options}
            placeholder="Select Measure"
            styles={styleSelectComponent()}
        />
    );
}

export default observer(VitalsMeasureDropdown);
