import React, { useState, useEffect, useContext } from 'react';
import classNames from 'classnames';

import NavBar from 'src/containers/NavBar';
import PageContent from 'src/components/PageContent';
import PageContainer from 'src/components/PageContainer';
import Card from 'src/components/Card';
import Flex from 'src/components/Flex';
import Button from 'src/components/Button';
import Icon from 'src/components/Icon';
import Table from 'src/components/Table';
import styles from './styles.module.scss';
import { observer } from 'mobx-react';
import SideModal from 'src/components/SideModal';
import { CellInfo } from 'react-table';
import TagStore, { Tag } from 'src/stores/TagStore';
import * as tagService from 'src/api/tags';
import ModalFooter from 'src/components/ModalFooter';
import ModalHeader from 'src/components/ModalHeader';
import { ToastStoreObject } from 'src/stores/ToastStore';
import { ErrorStoreObject, ErrorTypes } from 'src/stores/ErrorStore';
import { parseError } from 'src/utils';
import { ICONS } from 'src/utils/constants';
import * as variables from 'src/styles/variables';
import TagModal from 'src/components/TagModal';
import { ModalStoreObject, ModalTypes } from 'src/stores/ModalStore';
import { ANALYTICS_NAMES } from 'src/utils/analytics';
import UserStore from 'src/stores/UserStore';

function Tags() {
    const tagStore = useContext(TagStore);
    const userStore = useContext(UserStore);
    const columns = [
        {
            Header: () => <span>TAG NAME</span>,
            accessor: 'tagNm',
            minWidth: 150,
        },
        {
            Header: 'CATEGORY',
            accessor: 'catgNm',
            minWidth: 250,
        },
        {
            Header: 'DESCRIPTION',
            accessor: 'tagDesc',
            minWidth: 250,
        },
        {
            Header: 'ACTIVE',
            accessor: 'actvInd',
            minWidth: 100,
            Cell: (props: CellInfo) => (
                <span className={classNames({ [styles.redText]: !props.value, [styles.greenText]: props.value })}>
                    {props.value ? 'ACTIVE' : 'INACTIVE'}
                </span>
            ),
        },
        {
            Header: 'ACTIONS',
            accessor: 'actvInd',
            minWidth: 300,
            style: { paddingVertical: 0, paddingHorizontal: 20 },
            Cell: (props: CellInfo) => (
                <Flex direction="row">
                    <Button
                        onClick={() => {
                            setModalType('edit');
                            setModalState(true);
                            setEditTagState(props.original);
                        }}
                        type="secondary"
                        isLocked={!userStore.userPermissions.canEdit.tags}
                        leftIcon={<Icon name={ICONS.Pencil2} color={variables.red} />}
                        data-test-id={ANALYTICS_NAMES.Tags_Table_Edit}
                    />
                    <Button
                        onClick={() => updateActiveStatus(props.original.actvInd, props.original.tagId)}
                        type={props.original.actvInd ? 'secondary' : 'primary'}
                        text={props.original.actvInd ? 'Deactivate' : 'Reactivate'}
                        className={styles.redOutline}
                        isLocked={!userStore.userPermissions.canEdit.tags}
                        data-test-id={
                            props.original.actvInd
                                ? ANALYTICS_NAMES.Tags_Table_Deactivate
                                : ANALYTICS_NAMES.Tags_Table_Reactivate
                        }
                    />
                </Flex>
            ),
        },
    ];

    const [modalState, setModalState] = useState(false);
    const [modalType, setModalType] = useState('create');
    const [tagModalData, setTagModalData] = useState<Tag>({});
    const [editTagState, setEditTagState] = useState<Tag>({});
    const [isLoading, setIsLoading] = useState(false);

    async function getTags() {
        try {
            setIsLoading(true);
            await tagStore.getTags();
            setIsLoading(false);
        } catch (e) {
            ErrorStoreObject.setError(ErrorTypes.Loading);
        }
    }

    useEffect(() => {
        getTags();
    }, []);

    const handleTagSubmit = () => {
        if (modalType === 'edit' && editTagState.tagNm !== tagModalData.tagNm) {
            ModalStoreObject.showModal(ModalTypes.ConfirmationModal, {
                title: 'Changing tag names will modify previously tagged items. Are you sure you want to do this?',
                onConfirm: () => handleTagChange(),
                onCancel: () => ModalStoreObject.hideModal(),
                confirmButtonText: 'Yes',
            });
        } else {
            handleTagChange();
        }
    };

    const handleTagChange = async () => {
        try {
            setIsLoading(true);

            if (modalType === 'create') {
                await tagService.createTag(tagModalData);
            } else if (modalType === 'edit') {
                await tagService.updateTag(tagModalData.tagId, tagModalData);
            }

            setModalState(false);
            ModalStoreObject.hideModal();
            getTags();
        } catch (e) {
            ToastStoreObject.show(parseError(e));
        } finally {
            setIsLoading(false);
        }
    };

    const updateActiveStatus = async (isActive: boolean, tagId: number) => {
        try {
            if (isActive) {
                handleConfirm(tagId);
            } else {
                await tagService.reactivateTag(tagId);
                getTags();
            }
        } catch (e) {
            ToastStoreObject.show(parseError(e));
        }
    };

    async function handleDeactivate(tagId: number) {
        await tagService.deactivateTag(tagId);
        getTags();
        ModalStoreObject.hideModal();
    }

    function handleConfirm(tagId: number) {
        ModalStoreObject.showModal(ModalTypes.ConfirmationModal, {
            title: 'Are you sure you would like to deactivate this Tag?',
            onConfirm: () => handleDeactivate(tagId),
            onCancel: () => ModalStoreObject.hideModal(),
            confirmButtonText: 'Deactivate',
        });
    }

    return (
        <PageContainer>
            <NavBar />
            <PageContent>
                <Flex self="stretch" align="center" justify="start">
                    <Flex value={1} className={styles.pageTitle}>
                        TAGS
                    </Flex>
                    <Button
                        leftIcon={<Icon className={styles.plusIcon} name={ICONS.PlusButton} />}
                        type="primary"
                        onClick={() => {
                            setModalType('create');
                            setModalState(true);
                        }}
                        isLocked={!userStore.userPermissions.canEdit.tags}
                        text="New Tag"
                        data-test-id={ANALYTICS_NAMES.Tags_Create}
                    />
                </Flex>
                <Card className={styles.cardWrap}>
                    <Table isLoading={isLoading} columns={columns} data={tagStore.tags} />
                </Card>
            </PageContent>
            <SideModal isOpen={modalState}>
                <ModalHeader
                    title={modalType === 'create' ? 'New Tag' : `Edit ${(editTagState && editTagState.tagNm) || 'Tag'}`}
                    onClose={() => setModalState(false)}
                />
                <TagModal
                    tag={modalType === 'edit' ? editTagState : {}}
                    onChange={(data: object) => setTagModalData(data)}
                />
                <ModalFooter
                    isLoading={isLoading}
                    primaryText={modalType === 'create' ? 'Create' : 'Save'}
                    primaryClick={handleTagSubmit}
                    secondaryClick={() => setModalState(false)}
                />
            </SideModal>
        </PageContainer>
    );
}
export default observer(Tags);
