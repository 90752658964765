import React, { useContext, useEffect, useState } from 'react';
import styles from './styles.module.scss';
import Flex from 'src/components/Flex';
import Button from 'src/components/Button';
import { observer } from 'mobx-react';
import PageContainer from 'src/components/PageContainer';
import NavBar from '../NavBar';
import PageContent from 'src/components/PageContent';
import Table from 'src/components/Table';
import DeviceStore, { Device } from 'src/stores/DeviceStore';
import { ModalStoreObject, ModalTypes } from 'src/stores/ModalStore';
import ReferenceListStore from 'src/stores/ReferenceListStore';
import { OptionTypes } from 'src/components/OptionDropDown';
import LoadingCover from 'src/components/LoadingCover';
import { ToastStoreObject } from 'src/stores/ToastStore';
import * as utils from 'src/utils';
import Icon from 'src/components/Icon';
import { ICONS } from 'src/utils/constants';
import * as variables from 'src/styles/variables';
import NemsisErrorBanner, { NemsisErrorBannerMode } from '../NemsisErrorBanner';
import NemsisStore from 'src/stores/NemsisStore';
import { parseError } from 'src/utils';
import TableActiveCol from 'src/components/TableActiveCol';

function SettingsDevices() {
    const deviceStore = useContext(DeviceStore);
    const refListStore = useContext(ReferenceListStore);
    const nemsisStore = useContext(NemsisStore);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        loadDeviceData({ initial: true });
    }, []);

    async function loadDeviceData(options: { initial?: boolean } = {}) {
        try {
            setIsLoading(true);
            await Promise.all([deviceStore.getDevices(), ...(options.initial ? [loadRefLists()] : [])]);
        } catch (error) {
            ToastStoreObject.show(utils.parseError(error));
        } finally {
            setIsLoading(false);
        }
    }

    async function loadRefLists() {
        try {
            await refListStore.getMultipleLists([OptionTypes.EMSMedicalDeviceType]);
        } catch (error) {
            ToastStoreObject.show(utils.parseError(error));
        }
    }

    function handleAdd() {
        showModal();
    }

    function handleEdit(device: Device) {
        showModal(device);
    }

    function showModal(device: Device = null) {
        ModalStoreObject.showModal(ModalTypes.DeviceModal, {
            onClose: () => ModalStoreObject.hideModal(),
            onSave: () => onSave(),
            device,
        });
    }

    async function toggleActive(device: Device) {
        try {
            await deviceStore.toggleActive(device);
            await nemsisStore.validateDemData(true);
        } catch (error: any) {
            ToastStoreObject.show(parseError(error));
        } finally {
            ModalStoreObject.hideModal();
            await loadDeviceData();
        }
    }

    async function onSave() {
        ModalStoreObject.hideModal();
        await loadDeviceData();
    }

    function handleConfirm(device: Device) {
        ModalStoreObject.showModal(ModalTypes.ConfirmationModal, {
            title: `Are you sure you would like to deactivate this device?`,
            onConfirm: () => toggleActive(device),
            onCancel: () => ModalStoreObject.hideModal(),
            confirmButtonText: 'Deactivate',
        });
    }

    function getDeviceTypeLabel(device: Device) {
        const refList = refListStore.referenceListValues[OptionTypes.EMSMedicalDeviceType];

        if (!refList) return null;

        const deviceTypeRefValues = refList.filter((value) => device.deviceTypeCode.includes(value.listValueCode));
        const labels = deviceTypeRefValues.flatMap((value) => value.listValueDisplayName).sort();

        return deviceTypeRefValues.length > 0 ? labels.join('\r\n') : null;
    }

    return (
        <PageContainer>
            <NavBar />
            <PageContent>
                <NemsisErrorBanner mode={NemsisErrorBannerMode.DEM} />
                <Flex self="stretch" align="center" justify="start">
                    <Flex value={1} className={styles.pageTitle}>
                        DEVICES
                    </Flex>
                    <Button
                        leftIcon={<Icon className={styles.plusIcon} name={ICONS.PlusButton} />}
                        type="primary"
                        text="New Device"
                        onClick={handleAdd}
                    />
                </Flex>

                <Table
                    className={styles.tableWrap}
                    columns={[
                        {
                            Header: 'Name or ID',
                            accessor: 'nameOrId',
                            minWidth: 160,
                            Cell: (cellProps) => {
                                const device = cellProps.original as Device;
                                return <span>{device.nameOrId || '-'}</span>;
                            },
                        },
                        {
                            Header: 'Type',
                            accessor: 'deviceTypeCode',
                            minWidth: 300,
                            style: { whiteSpace: 'pre' },
                            Cell: (cellProps) => {
                                const device = cellProps.original as Device;
                                return <div>{getDeviceTypeLabel(device) || '-'}</div>;
                            },
                        },
                        {
                            Header: 'Manufacturer',
                            accessor: 'manufacturer',
                            minWidth: 200,
                            Cell: (cellProps) => {
                                const device = cellProps.original as Device;
                                return <span>{device.manufacturer || '-'}</span>;
                            },
                        },
                        {
                            Header: 'Model Number',
                            accessor: 'modelNumber',
                            minWidth: 250,
                            Cell: (cellProps) => {
                                const device = cellProps.original as Device;
                                return <span>{device.modelNumber || '-'}</span>;
                            },
                        },
                        {
                            Header: 'Status',
                            accessor: 'active',
                            minWidth: 100,
                            Cell: (cellProps) => {
                                const device = cellProps.original as Device;
                                return <TableActiveCol isActive={device.active} />;
                            },
                        },
                        {
                            Header: 'ACTIONS',
                            accessor: 'actions',
                            minWidth: 200,
                            style: { paddingVertical: 0, paddingHorizontal: 20 },
                            Cell: (cellProps) => {
                                const device = cellProps.original as Device;
                                return (
                                    <Flex direction="row">
                                        <Button
                                            data-test-id='Devices_Table_Edit'
                                            onClick={() => {
                                                handleEdit(device);
                                            }}
                                            type="secondary"
                                            leftIcon={<Icon name={ICONS.Pencil2} color={variables.red} />}
                                        />
                                        {cellProps.original.active ? (
                                            <Button
                                                data-test-id='Devices_Table_Deactivate'
                                                onClick={() => handleConfirm(device)}
                                                type="secondary"
                                                text="Deactivate"
                                                className={styles.actionButtons}
                                            />
                                        ) : (
                                            <Button
                                                data-test-id='Devices_Table_Reactivate'
                                                onClick={() => toggleActive(device)}
                                                type="primary"
                                                text="Reactivate"
                                                className={styles.actionButtons}
                                            />
                                        )}
                                    </Flex>
                                );
                            },
                        },
                    ]}
                    data={deviceStore.devices || []}
                />
                {isLoading ? <LoadingCover style={{ top: 90 }} /> : null}
            </PageContent>
        </PageContainer>
    );
}

export default observer(SettingsDevices);
