import React from 'react';
import { observer } from 'mobx-react';
import { withAuth0 } from '@auth0/auth0-react';
import { parse } from 'query-string';
import * as H from 'history';
import { ROUTES } from 'src/utils/constants';
import { Redirect, useLocation } from 'react-router';

interface LoginProps {
    auth0: any;
}

interface LocationState {
    from?: {
        pathname: string;
        search: string;
    };
}

function Login(props: LoginProps) {
    const location = useLocation<LocationState>();
    const queryStrings = parse(location.search);

    const { isAuthenticated, loginWithRedirect } = props.auth0;

    if (isAuthenticated) {
        let from: H.History.LocationDescriptor<{}> = { pathname: ROUTES.Home, search: location.search };
        // If there is a redirect url coming back from Auth0 through the query params, redirect to that route
        if (queryStrings.login_redirect) {
            from = { pathname: queryStrings.login_redirect as string };
        } else if (location.state && location.state.from && location.state.from.pathname) {
            from = location.state.from;
        }
        return <Redirect to={from} />;
    }

    const defaultAuth0Params = {
        brand: import.meta.env.VITE_APP_BRAND,
        title: import.meta.env.VITE_APP_BRAND === 'FieldMed' ? 'FieldMed' : 'Graphium Health',
    };

    if (location.state && location.state.from && location.state.from.pathname) {
        loginWithRedirect({
            appState: { returnTo: `${location.state.from.pathname}${location.state.from.search}` },
            ...defaultAuth0Params,
        });
    } else {
        loginWithRedirect({ ...defaultAuth0Params });
    }

    return <></>;
}

export default withAuth0(observer(Login));
