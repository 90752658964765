import React from 'react';

const SvgNeed = (props) => (
    <svg width={33} height={33} {...props} viewBox="0 0 33 33">
        <g transform="translate(1 1)" fill="none" fillRule="evenodd">
            <circle stroke="#F1F0F0" fill="#F9F9F9" cx={15.5} cy={15.5} r={15.5} />
            <g fill="#DA1057" fillRule="nonzero">
                <path d="M22.23 9.125H20.28a.177.177 0 01-.177-.177v-1.24a.708.708 0 00-1.416 0v3.365a.531.531 0 11-1.063 0V9.479a.354.354 0 00-.354-.354h-4.427a.177.177 0 01-.177-.176v-1.24a.708.708 0 00-1.417 0v3.364a.531.531 0 01-1.063 0V9.479a.354.354 0 00-.354-.354H8.771c-.783 0-1.417.634-1.417 1.417v12.041c0 .783.634 1.417 1.417 1.417h13.458c.783 0 1.417-.634 1.417-1.417V10.542c0-.783-.634-1.417-1.417-1.417zm0 13.104a.354.354 0 01-.355.354H9.125a.354.354 0 01-.354-.354v-8.5c0-.195.158-.354.354-.354h12.75c.196 0 .354.159.354.354v8.5z" />
                <path d="M15.5 14.438a3.542 3.542 0 100 7.083 3.542 3.542 0 000-7.084zm2.004 2.833l-1.715 2.287a.975.975 0 01-1.47.103l-.885-.889a.531.531 0 11.752-.75l.814.814 1.653-2.203a.531.531 0 01.85.638z" />
            </g>
        </g>
    </svg>
);

export default SvgNeed;
