import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router';
import styles from './styles.module.scss';
import Flex from 'src/components/Flex';
import * as surveyService from 'src/api/survey';
import LoadingCover from 'src/components/LoadingCover';
import { ToastStoreObject } from 'src/stores/ToastStore';
import fieldMedLogo from 'src/assets/images/fieldmed-logo.svg';
import graphiumLogo from 'src/assets/images/graphium-logo.svg';
import * as utils from 'src/utils';
import { SurveyUnauth } from 'common/lib/model/surveys/Survey';
import { parse } from 'query-string';
import * as variables from 'src/styles/variables';
import { FeatureBranding } from 'src/components/FeatureFlag';
import Survey from 'src/components/Survey';

function PatientSurvey() {
    const location = useLocation();

    const queryStrings = parse(location.search);
    const patientSurveyRequestGuid = queryStrings.guid as string;

    const [isLoading, setIsLoading] = useState(false);
    const [survey, setSurvey] = useState<SurveyUnauth | null>(null);
    const [facility, setFacility] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        getSurvey();
    }, []);

    async function getFacility(surveyGuid: string) {
        try {
            const results = await surveyService.getSurveyFacilityForPatient(surveyGuid);
            setFacility((results.data || {}).facilityName);
        } catch (e) {
            ToastStoreObject.show(utils.parseError(e));
        }
    }

    async function getSurvey() {
        try {
            setIsLoading(true);
            const results = await surveyService.getSurveyForPatient(patientSurveyRequestGuid);
            setSurvey(results.data || {});
            await getFacility(results.data.surveyGuid);
            setIsLoading(false);
        } catch (e) {
            const errorStr = utils.parseError(e);
            ToastStoreObject.show(errorStr);
            setErrorMessage(errorStr);
            setIsLoading(false);
        }
    }

    return (
        <Flex direction="column" align="center" justify="center" className={styles.pageWrap}>
            <Flex className={styles.contentWrap} direction="column" align="center" justify="center">
                <Flex
                    direction="row"
                    align="center"
                    value={1}
                    self="stretch"
                    justify="center"
                    className={styles.logoHeader}
                >
                    <FeatureBranding
                        fieldmedContent={<img src={fieldMedLogo} className={styles.fieldMedLogo} />}
                        graphiumContent={<img src={graphiumLogo} className={styles.fieldMedLogo} />}
                    />
                </Flex>
                {isLoading ? (
                    <LoadingCover />
                ) : !survey ? (
                    <div className={styles.headerMessage}>{errorMessage || 'Oops, the survey did not load.'}</div>
                ) : (
                    <>
                        <div className={styles.headerMessage}>Fill out your survey for {facility}</div>
                        <Flex direction="row">
                            <Survey survey={survey} />
                        </Flex>
                    </>
                )}
            </Flex>
        </Flex>
    );
}
export default PatientSurvey;
