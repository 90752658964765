import React, { useState, useEffect, useRef } from 'react';
import Flex from 'src/components/Flex';
import Input from 'src/components/Input';
import NemsisInput from 'src/components/NemsisInput';
import Button from 'src/components/Button';
import { format } from 'date-fns';
import { API_DATE_FORMAT } from 'src/utils/constants';
import { NaValues } from 'common/lib/model/nemsis3_4/demographic';

import styles from '../../styles.module.scss';

interface DateProps {
    input: any;
    isValid: boolean;
    onChange?: Function;
    onNaChange?: Function;
    enableNotValues?: boolean;
    value?: any;
    notValue?: string;
}

const DATE_REGEX = /^\d{4}\-(0?[1-9]|1[012])\-(0?[1-9]|[12][0-9]|3[01])$/;

function DateInput(props: DateProps) {
    const firstValueRender = useRef(true);
    const firstNotValueRender = useRef(true);
    const [dateState, setDateState] = useState(props.value || null);
    const [notValue, setNotValue] = useState(props.notValue || '');

    useEffect(() => {
        if (dateState && dateState.match(DATE_REGEX) && !firstValueRender.current) {
            props.onChange(dateState);
        }
        firstValueRender.current = false;
    }, [dateState]);

    useEffect(() => {
        if (props.onNaChange && !firstNotValueRender.current) {
            props.onNaChange(notValue);
        }
        firstNotValueRender.current = false;
    }, [notValue]);

    const inputProps = {
        wrapClassName: styles.dateField,
        value: notValue === NaValues.NOT_APPLICABLE ? notValue : dateState,
        onChangeText: (value: string) =>
            setDateState(value.replace(/[^0-9]/g, '').replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3')),
        onBlur: () => (dateState && dateState !== '' ? setDateState(format(dateState, API_DATE_FORMAT)) : null),
        placeholder: API_DATE_FORMAT,
        ...(props.enableNotValues
            ? { onNaClick: () => setNotValue(notValue === NaValues.NOT_APPLICABLE ? '' : NaValues.NOT_APPLICABLE) }
            : null),
    };

    return (
        <div>
            <div className={styles.label}>{props.input.title ? props.input.title[0] : ''}</div>
            <Flex align="center">
                {props.enableNotValues ? (
                    <NemsisInput {...inputProps} infoState={props.isValid ? null : 'error'} />
                ) : (
                    <Input {...inputProps} infoState={props.isValid ? null : 'error'} />
                )}
                <Button
                    text="Now"
                    type="small"
                    onClick={() => {
                        setDateState(format(new Date(), API_DATE_FORMAT));
                    }}
                />
            </Flex>
        </div>
    );
}

export default DateInput;
