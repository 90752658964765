import React, { useContext } from 'react';

import { observer } from 'mobx-react';
import ModalStore, { ModalTypes } from 'src/stores/ModalStore';
import CreateEventModal from 'src/components/CreateEventModal';
import EncounterModal from 'src/components/EncounterModal';
import CenterModal from 'src/components/CenterModal';
import SideModal from 'src/components/SideModal';
import ModalHeader from 'src/components/ModalHeader';
import CreateNeedModal from 'src/components/CreateNeedModal';
import CreateNoteModal from 'src/components/CreateNoteModal';
import CreateAttachmentModal from 'src/components/CreateAttachmentModal';
import ConfirmationModal from 'src/components/ConfirmationModal';
import CreatePatientModal from 'src/components/CreatePatientModal';
import CreateMedicationModal from 'src/components/CreateMedicationModal';
import CreateScheduledJobModal from 'src/components/CreateScheduledJobModal';
import ViewContentModal from 'src/components/ViewContentModal';
import { DiscreteFormModal } from 'src/components/DiscreteFormModal';
import ResendTelehealthInviteModal from 'src/components/ResendTelehealthInviteModal';
import CancelReasonModal from 'src/components/CancelReasonModal';
import VideoModal from 'src/components/VideoModal';
import EncounterFormDetailsModal from 'src/components/EncounterFormDetailsModal';
import AdminFlowScriptTemplateModal from 'src/components/AdminFlowScriptTemplateModal';
import AdminImportBatchTemplateModal from 'src/components/AdminImportBatchTemplateModal';
import CreateImportModal from 'src/components/CreateImportModal';
import AgencyContactModal from '../SettingsNemsis/AgencyContactModal';
import DeviceModal from '../SettingsNemsis/DeviceModal';
import { VehicleModal } from '../SettingsNemsis/VehicleModal';
import ImportBatchRecordModal from 'src/components/ImportBatchRecordModal';
import TransportFacilityModal from '../SettingsNemsis/TransportFacilityModal';
import { AgencyConfigurationModal } from '../SettingsNemsis/AgencyConfigurationModal';
import ProviderModal from 'src/components/ProviderModal';
import FacilityModal from 'src/components/FacilityModal';
import SubmitToQcdrModal from 'src/containers/Reports/Macra/Modals/SubmitToQcdrModal';
import NemsisErrorsModal from 'src/components/NemsisErrorsModal';
import PayerModal from 'src/components/PayerModal';
import PayerCategoryModal from 'src/components/PayerCategoryModal';
import PaymentTypeModal from 'src/components/PaymentTypeModal';
import PaymentGatewayModal from 'src/components/PaymentGatewayModal';
import ResentPaymentRequestModal from 'src/components/ResendPaymentRequestModal';
import CollectPaymentModal from 'src/components/CollectPaymentModal';
import CancelPaymentModal from 'src/components/CancelPaymentModal';
import ChangePaymentAmountModal from 'src/components/ChangePaymentAmountModal';
import VitalsConnectionModal from 'src/components/VitalsConnectionModal';
import AdminAuth0UserStatusModal from 'src/components/AdminAuth0UserStatusModal';
import CustomerModal from 'src/components/CustomerModal';
import CustomerDocumentModal from 'src/components/CustomerDocumentModal';
import AutomatedDispenseSystemModal from 'src/components/AutomatedDispenseSystemModal';
import CustomerContactModal from 'src/components/CustomerContactModal/CustomerContactModal';
import CustomerStatusModal from 'src/components/CustomerStatusModal';
import FacilitySubscriptionDataModal from 'src/components/FacilitySubscriptionDataModal';
import CreateOrganizationModal from 'src/components/CreateOrganizationModal';
import MacraAQIExportModal from 'src/components/MacraAQIExportModal';
import FormModelModal from 'src/components/FormModelModal';
import FormCatalogModal from 'src/components/FormCatalogModal';
import FormAssetModal from 'src/components/FormAssetModal';
import FormCatalogPageModal from 'src/components/FormCatalogPageModal';
import FormCatalogVersionModal from 'src/components/FormCatalogVersionModal';
import FormFacilityMappingModal from 'src/components/FormFacilityMappingModal';

export function ModalHandler() {
    const modalStore = useContext(ModalStore);
    const { type, props } = modalStore.modalOptions;

    switch (type) {
        case ModalTypes.CreateEvent:
            return <CreateEventModal {...props} />;
        case ModalTypes.Encounter:
            return <EncounterModal {...props} />;
        case ModalTypes.AddEncounterForm:
            return (
                <SideModal isOpen={true} isWide={true} onClose={props.onClose}>
                    <ModalHeader title="Add Form" onClose={props.onClose} />
                    <DiscreteFormModal {...props} onClose={props.onClose} />
                </SideModal>
            );
        case ModalTypes.EditEncounterForm:
            return (
                <SideModal isOpen={true} isWide={true} onClose={props.onClose}>
                    <ModalHeader title="Edit Form" onClose={props.onClose} />
                    <DiscreteFormModal {...props} onClose={props.onClose} />
                </SideModal>
            );
        case ModalTypes.PatientNeed:
            return <CreateNeedModal {...props} />;
        case ModalTypes.PatientNote:
            return <CreateNoteModal {...props} />;
        case ModalTypes.PatientAttachment:
            return <CreateAttachmentModal {...props} />;
        case ModalTypes.ConfirmationModal:
            return <ConfirmationModal {...props} />;
        case ModalTypes.CreatePatient:
            return <CreatePatientModal {...props} />;
        case ModalTypes.PatientMedication:
            return <CreateMedicationModal {...props} />;
        case ModalTypes.CreateScheduledJob:
            return <CreateScheduledJobModal {...props} />;
        case ModalTypes.ViewContent:
            return <ViewContentModal {...props} />;
        case ModalTypes.ResendInvite:
            return <ResendTelehealthInviteModal {...props} />;
        case ModalTypes.CancelReason:
            return <CancelReasonModal {...props} />;
        case ModalTypes.Video:
            return <VideoModal {...props} />;
        case ModalTypes.EncounterFormRawDetails:
            return <EncounterFormDetailsModal {...props} />;
        case ModalTypes.CreateFlowScriptTemplate:
            return <AdminFlowScriptTemplateModal {...props} />;
        case ModalTypes.CreateImportBatchTemplate:
            return <AdminImportBatchTemplateModal {...props} />;
        case ModalTypes.CreateImportModal:
            return <CreateImportModal {...props} />;
        case ModalTypes.AgencyContactModal:
            return <AgencyContactModal {...props} />;
        case ModalTypes.AgencyConfigurationModal:
            return <AgencyConfigurationModal {...props} />;
        case ModalTypes.DeviceModal:
            return <DeviceModal {...props} />;
        case ModalTypes.VehicleModal:
            return <VehicleModal {...props} />;
        case ModalTypes.ImportBatchRecord:
            return <ImportBatchRecordModal {...props} />;
        case ModalTypes.TransportFacilityModal:
            return <TransportFacilityModal {...props} />;
        case ModalTypes.Provider:
            return <ProviderModal {...props} />;
        case ModalTypes.Facility:
            return <FacilityModal {...props} />;
        case ModalTypes.SubmitToQcdrModal:
            return <SubmitToQcdrModal {...props} />;
        case ModalTypes.NemsisErrorsModal:
            return <NemsisErrorsModal {...props} />;
        case ModalTypes.PayerModal:
            return <PayerModal {...props} />;
        case ModalTypes.PayerCategoryModal:
            return <PayerCategoryModal {...props} />;
        case ModalTypes.PaymentTypeModal:
            return <PaymentTypeModal {...props} />;
        case ModalTypes.PaymentGatewayModal:
            return <PaymentGatewayModal {...props} />;
        case ModalTypes.ResendPaymentRequestModal:
            return <ResentPaymentRequestModal {...props} />;
        case ModalTypes.CollectPaymentModal:
            return <CollectPaymentModal {...props} />;
        case ModalTypes.CancelPaymentModal:
            return <CancelPaymentModal {...props} />;
        case ModalTypes.ChangePaymentAmountModal:
            return <ChangePaymentAmountModal {...props} />;
        case ModalTypes.VitalsConnectionModal:
            return <VitalsConnectionModal {...props} />;
        case ModalTypes.AdminAuth0UserStatusModal:
            return <AdminAuth0UserStatusModal {...props} />;
        case ModalTypes.CustomerModal:
            return <CustomerModal {...props} />;
        case ModalTypes.CustomerDocumentModal:
            return <CustomerDocumentModal {...props} />;
        case ModalTypes.AutomatedDispenseSystemModal:
            return <AutomatedDispenseSystemModal {...props} />;
        case ModalTypes.CustomerContactModal:
            return <CustomerContactModal {...props} />;
        case ModalTypes.CustomerStatusModal:
            return <CustomerStatusModal {...props} />;
        case ModalTypes.FacilitySubscriptionDataModal:
            return <FacilitySubscriptionDataModal {...props} />;
        case ModalTypes.CreateOrganizationModal:
            return <CreateOrganizationModal {...props} />;
        case ModalTypes.MacraAQIExportModal:
            return <MacraAQIExportModal {...props} />;
        case ModalTypes.FormModelModal:
            return <FormModelModal {...props} />;
        case ModalTypes.FormCatalogModal:
            return <FormCatalogModal {...props} />;
        case ModalTypes.FormAssetModal:
            return <FormAssetModal {...props} />;
        case ModalTypes.FormCatalogPageModal:
            return <FormCatalogPageModal {...props} />;
        case ModalTypes.FormCatalogVersionModal:
            return <FormCatalogVersionModal {...props} />;
        case ModalTypes.FormFacilityMappingModal:
            return <FormFacilityMappingModal {...props} />;
        default:
            break;
    }
    return null;
}
export default observer(ModalHandler);
