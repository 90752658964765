import React, { useEffect, useState } from 'react';
import { ToastStoreObject, ToastType } from 'src/stores/ToastStore';
import { ANALYTICS_NAMES } from 'src/utils/analytics';
import Input, { Label } from '../Input';
import ModalFooter from '../ModalFooter';
import ModalHeader from '../ModalHeader';
import SideModal from '../SideModal';
import styles from './styles.module.scss';
import FormAssetTypeDropDown from '../FormAssetTypeDropdown';
import { useCreateFormAsset } from 'src/queries/useCreateFormAsset';
import Flex from '../Flex';
import CodeEditor from '../CodeEditor';
import FacilitiesByOrgDropDown from '../FacilitiesByOrgDropDown';
import OrgDropDown from '../OrgDropDown';
import Checkbox from '../Checkbox';

interface FormAssetModalProps {
    onClose?: Function;
    onSave?: Function;
}

function FormAssetModal(props: FormAssetModalProps) {
    const { onClose, onSave } = props;
    const [formAssetTypeTitle, setFormAssetTypeTitle] = useState('');
    const [formAssetTypeId, setFormAssetTypeId] = useState('');
    const [formAssetName, setFormAssetName] = useState('');
    const [formAssetDescription, setFormAssetDescription] = useState('');
    const [formAssetContent, setFormAssetContent] = useState('');
    const [fileName, setFileName] = useState('');
    const [isGlobal, setIsGlobal] = useState(true);
    const [orgNameInternal, setOrgNameInternal] = useState('');
    const [facilityId, setFacilityId] = useState<number>(null);
    const {
        mutate: createFormAsset,
        isLoading: isCreateFormAssetLoading,
        isSuccess: isCreateFormAssetSuccess,
        isError: isCreateFormAssetError,
        error: createFormAssetError,
    } = useCreateFormAsset();

    useEffect(() => {
        if (isCreateFormAssetSuccess) {
            ToastStoreObject.show('Form asset created successfully', ToastType.Success);
            onClose();
            onSave();
        }

        if (isCreateFormAssetError) {
            // @ts-ignore
            const errorMessage = createFormAssetError?.response?.data?.message || 'Failed to create form asset';
            ToastStoreObject.show(errorMessage, ToastType.Error);
        }
    }, [isCreateFormAssetSuccess, isCreateFormAssetError]);

    useEffect(() => {
        setFormAssetContent('');
        setFileName('');
    }, [formAssetTypeId]);

    async function handleCreate() {
        createFormAsset({
            formAssetTypeId,
            formAssetName,
            formAssetDescription,
            formAssetContent,
            isGlobal,
            orgNameInternal,
            facilityId,
        });
    }

    function getAssetContentNode() {
        let contentNode;
        switch (formAssetTypeTitle) {
            default:
                return null;
            case 'Image':
                contentNode = (
                    <Flex align="center" justify="start">
                        <input
                            type="file"
                            id="formAssetModalFileInput"
                            className={styles.hidden}
                            accept="image/png,image/jpeg,image/jpg"
                            onChange={(e) => {
                                const uploadedFile = e.target.files[0];
                                if (!uploadedFile) {
                                    return;
                                }

                                const reader = new FileReader();
                                reader.onload = (e) => {
                                    if (e.target?.result) {
                                        setFormAssetContent(e.target.result.toString());
                                    }
                                };
                                reader.readAsDataURL(uploadedFile);

                                setFileName(uploadedFile.name);
                            }}
                        />
                        <label htmlFor="formAssetModalFileInput" className={styles.fileInputButton}>
                            Choose a File
                        </label>
                        <span className={styles.fileName}>{`${fileName ? fileName : 'No File Chosen'}`}</span>
                    </Flex>
                );
                break;
            case 'Code':
                contentNode = (
                    <CodeEditor
                        mode="xml"
                        theme="xcode"
                        value={formAssetContent}
                        onChange={(v: string) => {
                            setFormAssetContent(v);
                        }}
                    />
                );
                break;
        }

        return (
            <>
                <Label text="Asset Content" className={styles.labelWrap} />
                {contentNode}
            </>
        );
    }

    return (
        <SideModal isOpen={true} onClose={onClose}>
            <ModalHeader title="New Form Asset" onClose={onClose} />
            <div className={styles.contentWrap}>
                <Input
                    label="Asset Name"
                    value={formAssetName}
                    onChangeText={(v: string) => setFormAssetName(v)}
                    data-test-id={ANALYTICS_NAMES.FormAssetModal_AssetName}
                />
                <Input
                    label="Asset Description"
                    value={formAssetDescription}
                    onChangeText={(v: string) => setFormAssetDescription(v)}
                    data-test-id={ANALYTICS_NAMES.FormAssetModal_AssetDescription}
                />
                <Flex direction="column" gap={16} className={styles.properties}>
                    <Checkbox
                        checked={isGlobal}
                        label="Is Global?"
                        onChange={(e) => {
                            setIsGlobal(e.target.checked);
                            if(e.target.checked) {
                                setOrgNameInternal(null);
                                setFacilityId(null);
                            }
                        }}
                        />
                </Flex>
                {!isGlobal ? (
                    <>
                        <Label text="Organization" />
                        <OrgDropDown
                            onChange={(o: { label: string; value: string }) => {
                                setOrgNameInternal(o.value)
                                setFacilityId(null);
                            }
                        }
                        selectedValue={orgNameInternal}
                        />
                        <Label text="Facility (Optional)" />
                        <FacilitiesByOrgDropDown
                            orgNameInternal={orgNameInternal}
                            selectedValue={facilityId}
                            isClearable={true}
                            onChange={(o: { label: string; value: number }) =>
                                setFacilityId(o.value)
                        }
                        />
                    </>
                ) : null}
                <Label text="Asset Type" />
                <FormAssetTypeDropDown
                    selectedValue={formAssetTypeId}
                    onChange={(v: { label: string; value: string }) => {
                        setFormAssetTypeTitle(v.label);
                        setFormAssetTypeId(v.value);
                    }}
                />
                {getAssetContentNode()}
            </div>
            <ModalFooter
                primaryText="Create"
                primaryClick={handleCreate}
                secondaryClick={() => onClose()}
                isLoading={isCreateFormAssetLoading}
            />
        </SideModal>
    );
}

export default FormAssetModal;
