import React from 'react';

const SvgBin = (props) => (
    <svg width={20} height={20} {...props} viewBox="0 0 20 20">
        <g
            stroke={props.color || '#FFF'}
            strokeWidth={1.2}
            fill="none"
            fillRule="evenodd"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path d="M1.41 3.864h17.18M11.84 1.41H8.16c-.679 0-1.228.549-1.228 1.226v1.228h6.136V2.636c0-.677-.55-1.227-1.227-1.227zM8.16 14.295V8.16M11.84 14.295V8.16M15.617 17.465a1.227 1.227 0 01-1.223 1.126H5.607c-.638 0-1.17-.49-1.223-1.126L3.25 3.864h13.5l-1.133 13.601z" />
        </g>
    </svg>
);

export default SvgBin;
