import {
    ImportBatch,
    ImportBatchTemplate,
    ImportBatchRecord,
    ImportBatchRecordState,
    FilterQueryParams,
} from 'src/utils/types';
import { AggregateRecordStates } from 'common/lib/model/import/Import';
import request from 'src/api/utils';
import * as ROUTES from 'src/api/routes';

export async function getImportBatches(filters: FilterQueryParams): Promise<{ result: any[]; count: number }> {
    const response = await request({ ...ROUTES.GET_IMPORT_BATCHES, params: filters });
    return { result: response.data.result || [], count: response.data.count || 0 };
}

export async function getImportBatchByGuid(guid: string): Promise<ImportBatch> {
    const response = await request({ ...ROUTES.GET_IMPORT_BATCH_BY_GUID, pathParams: { guid } });
    return response.data;
}

export async function getRecordStatesForAllBatches(): Promise<AggregateRecordStates[]> {
    const response = await request({ ...ROUTES.GET_RECORD_STATES_FOR_ALL_BATCHES });
    return response.data || {};
}

export async function getImportBatchRecordsByGuid(
    guid: string,
    filters: FilterQueryParams,
): Promise<{ result: any[]; count: number }> {
    const response = await request({
        ...ROUTES.GET_IMPORT_BATCH_RECORDS_BY_GUID,
        params: filters,
        pathParams: { guid },
    });
    return { result: response.data.result || [], count: response.data.count || 0 };
}

export async function getImportBatchRecordByGuid(guid: string): Promise<ImportBatchRecord> {
    const response = await request({ ...ROUTES.GET_IMPORT_BATCH_RECORD_BY_GUID, pathParams: { guid } });
    return response.data;
}

export async function updateImportBatchRecordByGuid(
    guid: string,
    data: ImportBatchRecord['recordCurrentData'],
): Promise<ImportBatchRecord> {
    const response = await request({
        ...ROUTES.UPDATE_IMPORT_BATCH_RECORD_BY_GUID,
        pathParams: { guid },
        data: { recordCurrentData: data },
    });
    return response.data;
}

export async function getImportBatchRecordStates(): Promise<ImportBatchRecordState[]> {
    const response = await request({ ...ROUTES.GET_IMPORT_BATCH_RECORD_STATES });
    return response.data;
}

export async function getImportBatchTemplates(onlyActive?: boolean): Promise<ImportBatchTemplate[]> {
    const response = await request({ ...ROUTES.GET_IMPORT_BATCH_TEMPLATES, params: { onlyActive } });
    return response.data || [];
}

export async function createImportBatch(data: any): Promise<ImportBatch> {
    const response = await request({ ...ROUTES.CREATE_IMPORT_BATCH, data });
    return response.data || [];
}

export async function reprocessImportBatchRecords(data: any): Promise<any> {
    return await request({ ...ROUTES.REPROCESS_IMPORT_BATCH_RECORDS, data });
}

export async function ignoreImportBatchRecords(data: any): Promise<any> {
    return await request({ ...ROUTES.IGNORE_IMPORT_BATCH_RECORDS, data });
}

export async function unignoreImportBatchRecords(data: any): Promise<any> {
    return await request({ ...ROUTES.UNIGNORE_IMPORT_BATCH_RECORDS, data });
}

export async function createImportBatchTemplate(data: any): Promise<any> {
    return await request({ ...ROUTES.CREATE_IMPORT_BATCH_TEMPLATE, data });
}

export async function updateImportBatchTemplate(importBatchTemplateGuid: string, data: any): Promise<any> {
    return await request({ ...ROUTES.UPDATE_IMPORT_BATCH_TEMPLATE, data, pathParams: { importBatchTemplateGuid } });
}
