import * as ROUTES from 'src/api/routes';
import request from 'src/api/utils';
import * as utils from 'src/utils';

export async function fetchTags(params = {}) {
    return await request({ ...ROUTES.GET_TAGS, params });
}

export async function updateTag(tagId: number, data: object) {
    const cleanedData = utils.removeNull(data);
    return await request({ ...ROUTES.UPDATE_TAG, pathParams: { tagId }, data: cleanedData });
}

export async function createTag(data: object) {
    const cleanedData = utils.removeNull(data);
    return await request({ ...ROUTES.CREATE_TAG, data: cleanedData });
}

export async function deactivateTag(tagId: number) {
    return await request({ ...ROUTES.DEACTIVATE_TAG, pathParams: { tagId } });
}

export async function reactivateTag(tagId: number) {
    return await request({ ...ROUTES.REACTIVATE_TAG, pathParams: { tagId } });
}
