import React from 'react';
import { useContext, useState } from 'react';
import PaymentsStore from 'src/stores/PaymentsStore';
import { PaymentRequest } from 'src/stores/PaymentsStore';
import { ToastStoreObject } from 'src/stores/ToastStore';
import { parseError } from 'src/utils';
import CenterModal from '../CenterModal';
import Flex from '../Flex';
import Input from '../Input';
import LoadingIcon from '../LoadingIcon';
import ModalFooter from '../ModalFooter';
import ModalHeader from '../ModalHeader';
import styles from './styles.module.scss';

interface ChangePaymentAmountModalProps {
    onSave: Function;
    onCancel: Function;
    paymentRequest: any;
}

const ChangePaymentAmountModal = (props: ChangePaymentAmountModalProps) => {
    const paymentsStore = useContext(PaymentsStore);
    const [isLoading, setIsLoading] = useState(false);
    const [newPaymentAmount, setNewPaymentAmount] = useState<PaymentRequest['paymentRequestAmount']>(
        props.paymentRequest && props.paymentRequest.paymentRequestAmount
            ? props.paymentRequest.paymentRequestAmount
            : props.paymentRequest.amount || 0,
    );

    async function handleChangeAmount() {
        setIsLoading(true);
        try {
            await paymentsStore.updatePaymentRequestAmount(props.paymentRequest.paymentRequestGuid, newPaymentAmount);
            props.onSave();
        } catch (e) {
            ToastStoreObject.show(parseError(e));
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <CenterModal isOpen={true} onClose={props.onCancel}>
            <div>
                <ModalHeader title="Change Request Amount" />
                {isLoading ? (
                    <Flex justify="center">
                        <LoadingIcon />
                    </Flex>
                ) : (
                    <Flex direction="column" className={styles.modalContent} align="start" self="stretch">
                        <Flex align="center" justify="center">
                            <div className={styles.newAmount}>
                                <Input
                                    type="number"
                                    label="Payment Request Amount"
                                    value={newPaymentAmount}
                                    onChangeText={(v: number) => setNewPaymentAmount(v)}
                                />
                            </div>
                        </Flex>
                    </Flex>
                )}
                <ModalFooter
                    primaryText="Update"
                    primaryClick={handleChangeAmount}
                    secondaryText="Cancel"
                    secondaryClick={props.onCancel}
                    primaryProps={{ disabled: newPaymentAmount <= 0 }}
                    isLoading={isLoading}
                />
            </div>
        </CenterModal>
    );
};

export default ChangePaymentAmountModal;
