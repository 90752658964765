import React, { useState, useContext, useEffect } from 'react';
import styles from './styles.module.scss';
import Flex from 'src/components/Flex/index';
import { observer } from 'mobx-react';
import { CellInfo } from 'react-table';
import Button from 'src/components/Button';
import EventStore from 'src/stores/EventStore';
import Icon from 'src/components/Icon';
import DateComponent from 'src/components/DateComponent';
import DetailsButton from 'src/components/DetailsButton';
import { ICONS } from 'src/utils/constants';
import Table from 'src/components/Table';
import { toJS } from 'mobx';
import EventDetailsModal from 'src/components/EventDetailsModal';
import { StyledCell } from '../Appointments';
import { ModalStoreObject, ModalTypes } from 'src/stores/ModalStore';
import { Patient } from 'src/stores/PatientStore';
import { ANALYTICS_NAMES } from 'src/utils/analytics';
import UserStore from 'src/stores/UserStore';
import { getPatientName } from 'src/utils';

interface TasksProps {
    patientId: Patient['patientId'];
    patient: Patient;
}

function Tasks(props: TasksProps) {
    const [isLoading, setIsLoading] = useState(false);
    const [patientEvents, setPatientEvents] = useState([]);
    const [eventModalOpen, setEventModalOpen] = useState(false);
    const [activeEvent, setActiveEvent] = useState(null);
    const eventStore = useContext(EventStore);
    const userStore = useContext(UserStore);
    const columns = [
        {
            Header: 'TASK NAME',
            accessor: 'eventName',
            minWidth: 150,
            Cell: StyledCell,
        },
        {
            Header: 'DESCRIPTION',
            accessor: 'eventDescription',
            minWidth: 150,
            Cell: StyledCell,
        },
        {
            Header: 'STATUS',
            accessor: 'eventState.eventStateTitle',
            minWidth: 100,
            Cell: StyledCell,
        },
        {
            Header: 'START DATE',
            accessor: 'eventStartDate',
            minWidth: 100,
            Cell: (props: CellInfo) => <DateComponent date={props.value} />,
        },
        {
            Header: 'END DATE',
            accessor: 'eventEndDate',
            minWidth: 100,
            Cell: (props: CellInfo) => <DateComponent date={props.value} />,
        },
        {
            Header: 'TIME',
            accessor: 'eventStartTime',
            minWidth: 125,
            Cell: (props: CellInfo) => (
                <Flex direction="row" align="center" className={styles.subtitle}>
                    <DateComponent date={props.original.eventStartTime} isTime={true} />
                    &nbsp;-&nbsp;
                    <DateComponent date={props.original.eventEndTime} isTime={true} />
                </Flex>
            ),
        },
        {
            Header: 'ACTIONS',
            accessor: 'eventStateId',
            minWidth: 100,
            style: { paddingVertical: 0, paddingHorizontal: 20 },
            Cell: (props: CellInfo) => {
                return (
                    <Flex direction="row">
                        <DetailsButton
                            onClick={() => {
                                setActiveEvent(props.original);
                                setEventModalOpen(true);
                            }}
                            isLocked={!userStore.userPermissions.canView.patientEvents}
                            data-test-id={ANALYTICS_NAMES.PatientDetails_Tasks_Table_Details}
                        />
                    </Flex>
                );
            },
        },
    ];

    async function loadTasks() {
        try {
            setIsLoading(true);
            await eventStore.getEvents({
                filters: { facId: props.patient.facId, patientId: props.patientId, type: 'TASK' },
            });
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        // get tasks for my patient
        loadTasks();
    }, []);

    useEffect(() => {
        setPatientEvents(eventStore.patientTasks);
    }, [eventStore.patientTasks]);

    return (
        <div className={styles.container}>
            <Flex self="stretch" align="center" justify="start" className={styles.headerWrap}>
                <Flex value={1} className={styles.filterWrap}>
                    {/* <FilterComponent
                        onUpdate={(filters: any) => handleFilterChange('type', filters)}
                        placeholder="Filter by Type"
                        options={referenceListStore.referenceListDropdownValues['PatientNeedTypes']}
                    /> */}
                </Flex>
                <Flex value={2} justify="end">
                    <Button
                        leftIcon={<Icon className={styles.plusIcon} name={ICONS.PlusButton} />}
                        type="primary"
                        onClick={() => {
                            setActiveEvent(null);
                            ModalStoreObject.showModal(ModalTypes.CreateEvent, {
                                type: 'task',
                                title: `New Task for ${getPatientName(props.patient)}`,
                                patientId: props.patientId,
                                patient: props.patient,
                                facilityId: props.patient.facId,
                                onClose: () => ModalStoreObject.hideModal(),
                                onSave: () => {
                                    ModalStoreObject.hideModal();
                                    loadTasks();
                                },
                            });
                        }}
                        isLocked={!userStore.userPermissions.canEdit.patientEvents}
                        text="Create Task"
                        data-test-id={ANALYTICS_NAMES.PatientDetails_Tasks_Create}
                    />
                </Flex>
            </Flex>
            <div className={styles.tableWrap}>
                <div className={styles.tableTitle}>Patient Tasks</div>
                <Table columns={columns} data={toJS(patientEvents)} isLoading={isLoading} />
            </div>
            {eventModalOpen ? (
                <EventDetailsModal
                    patient={props.patient}
                    event={activeEvent}
                    onClose={() => setEventModalOpen(false)}
                    onChangeComplete={() => loadTasks()}
                />
            ) : null}
        </div>
    );
}

export default observer(Tasks);
