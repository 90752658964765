import * as ROUTES from 'src/api/routes';
import request from 'src/api/utils';
import { TransportFacility } from 'src/stores/TransportFacilityStore';

export async function getTransportFacilities() {
    return await request({ ...ROUTES.GET_TRANSPORTATION_FACILITIES });
}

export async function createTransportFacility(data: TransportFacility) {
    return await request({ ...ROUTES.CREATE_TRANSPORTATION_FACILITY, data });
}

export async function updateTransportFacility(data: TransportFacility) {
    return await request({
        ...ROUTES.UPDATE_TRANSPORTATION_FACILITY,
        pathParams: { transportFacilityId: data.transportFacilityId },
        data,
    });
}
