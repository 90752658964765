import React, { useContext, ReactNode } from 'react';
import UserStore, { UserStoreObject } from 'src/stores/UserStore';

export function getBrandingString() {
    switch (UserStoreObject.selectedOrgType) {
        case 'FieldMed':
            return 'FieldMed';
        case 'GraphiumHealth':
            return 'Graphium Health';
        default:
            return 'Graphium Health';
    }
}

export function FeatureBranding({
    fieldmedContent,
    graphiumContent,
}: {
    fieldmedContent: ReactNode;
    graphiumContent: ReactNode;
}): any {
    const userStore = useContext(UserStore);
    const userOrgType = userStore.selectedOrgType;

    if (userOrgType === 'FieldMed') {
        return fieldmedContent;
    }
    return graphiumContent;
}

export function FeatureTelehealth({ content }: { content: JSX.Element }) {
    const userStore = useContext(UserStore);
    const isTelehealthEnabled = userStore.selectedOrgTelehealthEnabled;

    if (isTelehealthEnabled) {
        return content;
    }
    return null;
}

export function FeatureVitals({ content }: { content: JSX.Element }) {
    const userStore = useContext(UserStore);
    const isVitalsEnabled = userStore.selectedOrgVitalsEnabled;

    if (isVitalsEnabled) {
        return content;
    }
    return null;
}

export function FeatureAnalytics({ content }: { content: JSX.Element }) {
    const userStore = useContext(UserStore);
    const isAnalyticsEnabled = userStore.selectedOrgAnalyticsEnabled;

    if (isAnalyticsEnabled) {
        return content;
    }
    return null;
}
