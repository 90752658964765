import { FacilityType } from 'common/lib/model/facility/FacilitySubscriptionData';
import { observer } from 'mobx-react';
import React from 'react';
import { useState } from 'react';
import Select from 'react-select';
import { getFacilityTypeLabel } from 'src/containers/Customers/Detail/Tabs/OrgsAndFacilities';
import { styleSelectComponent } from 'src/utils';
import styles from '../styles.module.scss';

interface FacilityTypeDropdownProps {
    onChange: Function;
    selectedValue?: FacilityType;
}

function FacilityTypeDropdown(props: FacilityTypeDropdownProps) {
    const options = mapStatusOptions();
    const [selected, setSelected] = useState(options.find((i) => i.value === props.selectedValue) || null);

    function mapStatusOptions() {
        const types = Object.values(FacilityType);
        const typeOptions = types.map((value) => {
            const label = getFacilityTypeLabel(value);

            return { label, value };
        });

        return typeOptions.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
    }

    return (
        <Select
            value={selected}
            onChange={(s: any) => {
                setSelected(s);
                props.onChange(s || null);
            }}
            isClearable={true}
            className={styles.select}
            options={options}
            placeholder="Select Facility Type"
            styles={styleSelectComponent()}
        />
    );
}

export default observer(FacilityTypeDropdown);
