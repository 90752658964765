import React, { useState, useContext, useEffect } from 'react';
import { observer } from 'mobx-react';
import PageContainer from 'src/components/PageContainer';
import NavBar from '../../NavBar';
import MultiStep from 'src/components/MultiStep';
import Instructions from 'src/components/QcdrSettingsSteps/Instructions';
import TinSettingsStep from 'src/components/QcdrSettingsSteps/TinSettingsStep';
import ReportingSettingsStep from 'src/components/QcdrSettingsSteps/ReportingSettingsStep';
import ActivitySettingsStep from 'src/components/QcdrSettingsSteps/ActivitySettingsStep';
import Flex from 'src/components/Flex';
import Button from 'src/components/Button';
import QcdrSettingsStore from 'src/stores/QcdrSettingsStore';
import FeatureFlagStore from 'src/stores/FeatureFlagStore';
import styles from '../styles.module.scss';
import PageContent from 'src/components/PageContent';
import { useHistory } from 'react-router-dom';
import { ROUTES } from 'src/utils/constants';
import classNames from 'classnames';
import { ToastStoreObject } from 'src/stores/ToastStore';

const QCDR_YEAR = 2024;

function QcdrSettings() {
    const [activeIndexState, setActiveIndexState] = useState(0);
    const isLast = activeIndexState === 3;
    const qcdrSettingsStore = useContext(QcdrSettingsStore);
    const [dataDirty, setDataDirty] = useState(false);
    const history = useHistory();
    const flagStore = useContext(FeatureFlagStore);
    const canSaveQcdr = flagStore.isEnabled('macra-2024-enable-qcdr');

    async function saveAndContinue() {
        if (dataDirty && !canSaveQcdr) {
            ToastStoreObject.show('Saving QCDR Settings is currently disabled');
            return;
        }

        if ((activeIndexState > 0 && dataDirty) || (qcdrSettingsStore.newProvidersAdded && activeIndexState === 1)) {
            setDataDirty(false);
            await qcdrSettingsStore.saveQcdrSettings(activeIndexState, QCDR_YEAR);
            if (isLast) ToastStoreObject.show('QCDR Settings Completed and Saved.');
        }

        if (!isLast) setActiveIndexState(activeIndexState + 1);
    }

    function getNextButtonLabel() {
        if (isLast) return 'Save and Finish';
        if ((dataDirty && activeIndexState !== 0) || (qcdrSettingsStore.newProvidersAdded && activeIndexState === 1))
            return 'Save and Continue';
        else return 'Next';
    }

    function goBack() {
        setActiveIndexState(activeIndexState - 1);
    }

    function goToMacraCompliance() {
        history.push(ROUTES.getString(ROUTES.ReportsMacra));
    }

    function validateNextButton() {
        switch (activeIndexState) {
            case 0:
                return true;
            case 1:
                return qcdrSettingsStore.isTinSettingsValid;
            case 2:
                return true;
            case 3:
                return qcdrSettingsStore.isActivitySettingsValid && dataDirty;
        }
    }

    useEffect(() => {
        qcdrSettingsStore.getQcdrSettings(QCDR_YEAR);
    }, []);

    return (
        <PageContainer>
            <NavBar />
            <PageContent>
                <Flex direction="column" align="stretch" self="stretch">
                    <Flex direction="row" className={styles.contentWrap} self="stretch" align="center" justify="start">
                        <Flex value={1} className={styles.backWrap} onClick={goToMacraCompliance}>
                            <span className={styles.backArrow}>{`< `}</span>Go to MACRA Compliance
                        </Flex>

                        <Flex direction="row" justify="end" value={1}>
                            <Button
                                type="secondary"
                                text="Back"
                                disabled={activeIndexState === 0}
                                onClick={goBack}
                                className={styles.wrapRight}
                            />
                            <Button
                                type="primary"
                                text={getNextButtonLabel()}
                                disabled={!validateNextButton()}
                                onClick={saveAndContinue}
                                isLoading={qcdrSettingsStore.isLoading}
                            />
                        </Flex>
                    </Flex>

                    <Flex value={1} className={classNames(styles.pageTitle, styles.contentWrap)}>
                        {QCDR_YEAR} QCDR SETTINGS
                    </Flex>
                    <MultiStep
                        labels={[
                            'Instructions',
                            'Step 1: TIN Settings',
                            'Step 2: Reporting Settings',
                            'Step 3. IA Attestations',
                        ]}
                        activeIndex={activeIndexState}
                        steps={[
                            <Instructions key={1} year={QCDR_YEAR} />,
                            <TinSettingsStep
                                key={2}
                                settings={qcdrSettingsStore.tinSettings}
                                onSettingChange={(setting) => {
                                    setDataDirty(true);
                                    qcdrSettingsStore.updateTinSetting(setting);
                                }}
                            />,
                            <ReportingSettingsStep
                                key={3}
                                settings={qcdrSettingsStore.reportingSettings}
                                onSettingChange={(setting) => {
                                    setDataDirty(true);
                                    qcdrSettingsStore.updateReportingSetting(setting);
                                }}
                            />,
                            <ActivitySettingsStep
                                key={4}
                                year={QCDR_YEAR}
                                settings={qcdrSettingsStore.activitySettings}
                                onSettingChange={(setting) => {
                                    setDataDirty(true);
                                    qcdrSettingsStore.updateActivitySetting(setting);
                                }}
                            />,
                        ]}
                    />
                    <Flex direction="row" className={styles.contentWrap} self="stretch">
                        <Flex direction="row" justify="end" value={1}>
                            <Button
                                type="secondary"
                                text="Back"
                                disabled={activeIndexState === 0}
                                onClick={goBack}
                                className={styles.wrapRight}
                            />
                            <Button
                                type="primary"
                                text={getNextButtonLabel()}
                                disabled={!validateNextButton()}
                                onClick={saveAndContinue}
                                isLoading={qcdrSettingsStore.isLoading}
                            />
                        </Flex>
                    </Flex>
                </Flex>
            </PageContent>
        </PageContainer>
    );
}

export default observer(QcdrSettings);
