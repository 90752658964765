import React, { useContext } from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import AuthStore from 'src/stores/AuthStore';
import { validateRoute } from 'src/utils/roleValidation';
import { ROUTES } from 'src/utils/constants';
import { observer } from 'mobx-react';
import UserStore from 'src/stores/UserStore';
import OrgStore from 'src/stores/OrgStore';

const PrivateRoute = observer(({ component: Component, ...rest }: RouteProps) => {
    const authStore = useContext(AuthStore);
    const userStore = useContext(UserStore);
    const orgStore = useContext(OrgStore);
    // Need to render like this so that it will pick up changes from the stores
    if (authStore.isAuthenticated === true) {
        const orgNmIntrnl = userStore.selectedOrg.orgNmIntrnl;
        const orgs = orgStore.orgs;
        return (
            <Route
                {...rest}
                render={(props) => {
                    const { path, params } = props.match;
                    // The user is navigating to an org name that they are not currently in
                    if (params.orgnm && params.orgnm !== orgNmIntrnl) {
                        const matchingOrgUserHasAccessTo = orgs.find((o) => o.orgNmIntrnl === params.orgnm);
                        if (matchingOrgUserHasAccessTo) {
                            userStore.changeOrg(matchingOrgUserHasAccessTo, props.history, true);
                            // User is changing orgs which will reload the page and redirect
                            return null;
                        }
                        // org nm in url is not a valid org that the user has access to, redirect them to their current org
                        return <Redirect to={{ pathname: path.replace(params.orgnm, orgNmIntrnl) }} />;
                    }

                    return validateRoute(path) ? (
                        <Component {...props} />
                    ) : (
                        <Redirect to={{ pathname: ROUTES.Home, state: { from: props.location } }} />
                    );
                }}
            />
        );
    }
    return (
        <Route
            {...rest}
            render={(props) => <Redirect to={{ pathname: ROUTES.Login, state: { from: props.location } }} />}
        />
    );
});

export default PrivateRoute;
