import { FacilityOffboardReason } from 'common/lib/model/facility/FacilitySubscriptionData';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import Select from 'react-select';
import { styleSelectComponent } from 'src/utils';
import styles from '../styles.module.scss';

interface FacilityOffboardReasonDropdownProps {
    onChange: Function;
    selectedValue?: FacilityOffboardReason;
}

function FacilityOffboardReasonDropdown(props: FacilityOffboardReasonDropdownProps) {
    const options = mapOffboardReasonOptions();
    const [selected, setSelected] = useState(options.find((i) => i.value === props.selectedValue) || null);

    function mapOffboardReasonOptions() {
        const reasons = Object.values(FacilityOffboardReason);
        const reasonOptions = reasons.map((value) => {
            let label;
            switch (value) {
                case FacilityOffboardReason.CustomerCancelled:
                    label = 'Customer Cancelled';
                    break;
                case FacilityOffboardReason.CustomerLostContract:
                    label = 'Customer Lost Contract';
                    break;
                case FacilityOffboardReason.FacilityCreationError:
                    label = 'Creation Error';
                    break;
                case FacilityOffboardReason.FacilityMerged:
                    label = 'Facility Merged';
                    break;
                case FacilityOffboardReason.FacilityMigrated:
                    label = 'Facility Migrated';
                    break;
                case FacilityOffboardReason.FailureToGoLive:
                    label = 'Failure to Go Live';
                    break;
                case FacilityOffboardReason.FailureToPay:
                    label = 'Failure to Pay';
                    break;
                case FacilityOffboardReason.Other:
                    label = 'Other';
                    break;
                case FacilityOffboardReason.TrialCancelled:
                    label = 'Trial Cancelled';
                    break;
            }

            return { label, value };
        });

        return reasonOptions.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
    }

    return (
        <Select
            value={selected}
            onChange={(s: any) => {
                setSelected(s);
                props.onChange(s || null);
            }}
            isClearable={true}
            className={styles.select}
            options={options}
            placeholder="Select Offboard Reason"
            styles={styleSelectComponent()}
        />
    );
}

export default observer(FacilityOffboardReasonDropdown);
