import React, { useContext, ReactNode, useState } from 'react';
import Flex from 'src/components/Flex';
import Button from 'src/components/Button';
import Icon from 'src/components/Icon';
import EventStore from 'src/stores/EventStore';
import DateComponent from 'src/components/DateComponent';
import EventStateStore from 'src/stores/EventStateStore';
import Dot from 'src/components/Dot';
import MoreIcon from 'src/components/MoreIcon';
import ModalHeader from 'src/components/ModalHeader';
import ModalFooter from 'src/components/ModalFooter';
import { ViewProps, EventDetailViews } from '../index';
import styles from '../styles.module.scss';
import { ANALYTICS_NAMES } from 'src/utils/analytics';
import { ICONS, ROUTES } from 'src/utils/constants';
import * as variables from 'src/styles/variables';
import ReferenceListItemDisplay from 'src/components/ReferenceListItemDisplay';
import { useHistory } from 'react-router';
import { PatientTabs } from 'src/containers/PatientDetails';
import { ModalStoreObject, ModalTypes } from 'src/stores/ModalStore';
import { Link } from 'react-router-dom';
import TextTooltip from 'src/components/TextTooltip';
import { RefListTypes } from 'src/stores/ReferenceListStore';
import { getPatientName, parseError } from 'src/utils';
import { ToastStoreObject } from 'src/stores/ToastStore';

function EventDescription({ label, children }: { label: ReactNode; children: ReactNode }) {
    return (
        <>
            <div className={styles.label}>{label}</div>
            <div className={styles.itemContent}>{children}</div>
        </>
    );
}

export default function EventView(props: ViewProps) {
    const eventStore = useContext(EventStore);
    const [isLoading, setIsLoading] = useState(false);
    const { event } = props;
    const isTask = event.eventClassCode === 'TASK';
    const eventStateStore = useContext(EventStateStore);
    const history = useHistory();

    function handleEdit() {
        props.onChangeView(EventDetailViews.EDIT);
    }
    function handleDelete() {
        props.onChangeView(EventDetailViews.CANCEL);
    }
    function handleReschedule() {
        props.onChangeView(EventDetailViews.RESCHEDULE);
    }
    async function handleComplete() {
        try {
            setIsLoading(true);
            await eventStore.completeEvent(event.eventId);
            props.onClose();
            props.onChangeComplete();
            setIsLoading(false);
        } catch (error) {
            ToastStoreObject.show(parseError(error));
            setIsLoading(false);
        }
    }

    function handleStartVisit() {
        if (!event.patientId) {
            return;
        }
        const patientId = event.patientId;

        ModalStoreObject.showModal(ModalTypes.Encounter, {
            title: `New Visit for ${getPatientName(event.patient)}`,
            onClose: () => ModalStoreObject.hideModal(),
            isOpen: true,
            onSave: () => {
                ModalStoreObject.hideModal();
                props.onClose();
                history.push(ROUTES.getString(ROUTES.PatientDetails, patientId), {
                    startTab: PatientTabs.VISITS,
                });
            },
            eventId: event.eventId,
            patientId: patientId,
            facId: event.facId,
            patient: event.patient,
            isStartingFromAppt: true,
        });
    }

    const isCompleted = eventStateStore.isCompleted(event);
    const isOpen = eventStateStore.isOpen(event);
    const isRescheduled = eventStateStore.isRescheduled(event);
    const isCanceled = eventStateStore.isCanceled(event);
    return (
        <div className={styles.content}>
            <ModalHeader>
                <Flex value={1} direction="column" justify="center">
                    <div className={styles.title}>{event.eventName}</div>
                    <Flex direction="row" align="center" className={styles.subtitle}>
                        <DateComponent date={event.eventStartDate} format="eeee, MMM d" />
                        <Dot className={styles.dot} />
                        <DateComponent date={event.eventStartTime} isTime={true} />
                        {' - '}
                        <DateComponent date={event.eventEndTime} isTime={true} />
                    </Flex>
                </Flex>
                <Flex justify="end" align="center">
                    {isCanceled || isCompleted || isRescheduled ? null : (
                        <TextTooltip text="Edit">
                            <Button
                                data-test-id={ANALYTICS_NAMES.EventDetailsEditButton}
                                type="transparent"
                                leftIcon={<Icon name={ICONS.Pencil2} color={variables.black} />}
                                className={styles.actionButton}
                                onClick={handleEdit}
                            />
                        </TextTooltip>
                    )}
                    {isCanceled || isCompleted || isRescheduled ? null : (
                        <TextTooltip text="Cancel">
                            <Button
                                data-test-id={ANALYTICS_NAMES.EventDetailsDeleteButton}
                                type="transparent"
                                leftIcon={<Icon type="fa" name="calendar-times" style={{ color: variables.black }} />}
                                className={styles.actionButton}
                                onClick={handleDelete}
                            />
                        </TextTooltip>
                    )}
                    {isCanceled || isRescheduled || isCompleted ? null : (
                        <TextTooltip text="More">
                            <MoreIcon
                                data-test-id={ANALYTICS_NAMES.EventDetailsMoreButton}
                                options={[{ title: 'Reschedule', onClick: handleReschedule }]}
                            />
                        </TextTooltip>
                    )}
                    <TextTooltip text="Close">
                        <Button
                            data-test-id={ANALYTICS_NAMES.EventDetailsCloseButton}
                            type="transparent"
                            leftIcon={<Icon name={ICONS.Close} color={variables.black} />}
                            className={styles.actionButton}
                            onClick={() => props.onClose()}
                        />
                    </TextTooltip>
                </Flex>
            </ModalHeader>
            <div className={styles.mainContent}>
                {event.eventDescription ? (
                    <EventDescription label="Description">{event.eventDescription}</EventDescription>
                ) : null}
                {event.eventTypeCode ? (
                    <EventDescription label="Type">
                        <ReferenceListItemDisplay
                            type={isTask ? RefListTypes.TaskTypes : RefListTypes.AppointmentTypes}
                            value={event.eventTypeCode}
                        />
                    </EventDescription>
                ) : null}
                <EventDescription label="Status">
                    {isCompleted ? (
                        <div className={styles.completedText}>Completed</div>
                    ) : isOpen ? (
                        <Flex direction="row" align="center">
                            <Flex value={1} className={styles.incompleteText}>
                                Not Completed
                            </Flex>
                            <Button
                                type="secondary"
                                data-test-id={ANALYTICS_NAMES.EventDetailsMarkCompleteButton}
                                text={isTask ? 'MARK AS COMPLETED' : 'START A VISIT'}
                                className={styles.completeButton}
                                onClick={isTask ? handleComplete : handleStartVisit}
                            />
                        </Flex>
                    ) : (
                        eventStateStore.getEventState(event.eventStateId).eventStateTitle
                    )}
                </EventDescription>
                {isCanceled && event.cancelReasonType ? (
                    <EventDescription label="Cancellation Reason">
                        <Flex direction="column">
                            <ReferenceListItemDisplay
                                type={
                                    isTask
                                        ? RefListTypes.TaskCancelationReasonTypes
                                        : RefListTypes.AppointmentCancelationReasonTypes
                                }
                                value={event.cancelReasonType}
                            />
                            {event.cancelReasonText ? <div>{event.cancelReasonText}</div> : null}
                        </Flex>
                    </EventDescription>
                ) : null}
                {event.assigneeTeam ? (
                    <EventDescription label="Team">{event.assigneeTeam.teamName}</EventDescription>
                ) : null}
                {!isTask && event.eventLocation ? (
                    <EventDescription label="Location">{event.eventLocation}</EventDescription>
                ) : null}
                {event.assigneeUsr ? (
                    <EventDescription label="User">
                        {event.assigneeUsr.frstNm} {event.assigneeUsr.lastNm}
                    </EventDescription>
                ) : null}
                {event.patient ? (
                    <EventDescription label="Patient">
                        <Link
                            to={ROUTES.getString(ROUTES.PatientDetails, event.patientId)}
                            className={styles.patientLink}
                        >
                            {getPatientName(event.patient)}
                        </Link>
                    </EventDescription>
                ) : null}
                {event.encounter ? (
                    <EventDescription label="Patient Visit">
                        <Flex direction="row" align="center">
                            <Flex value={1} direction="column" className={styles.encounterDetails}>
                                <div>Visit #: {event.encounter.enctrNo || '-'}</div>
                                <div>
                                    Created on: <DateComponent date={event.encounter.insDttm} />
                                </div>
                                {event.encounter.admitDt ? (
                                    <div>
                                        Visit Date: <DateComponent date={event.encounter.admitDt} />
                                    </div>
                                ) : null}
                                {event.encounter.admitDt ? (
                                    <div>
                                        Visit Time: <DateComponent date={event.encounter.admitTm} isTime={true} />
                                    </div>
                                ) : null}
                            </Flex>
                            <Button
                                type="secondary"
                                text="GO TO VISIT"
                                data-test-id={ANALYTICS_NAMES.EventDetailsVisitButton}
                                className={styles.completeButton}
                                onClick={() =>
                                    history.push(ROUTES.getString(ROUTES.PatientDetails, event.patientId), {
                                        startTab: PatientTabs.VISITS,
                                    })
                                }
                            />
                        </Flex>
                    </EventDescription>
                ) : null}
            </div>
            <ModalFooter
                isLoading={isLoading}
                primaryText="Done"
                primaryProps={{ 'data-test-id': ANALYTICS_NAMES.EventDetailsCancelButton }}
                secondaryProps={{ 'data-test-id': ANALYTICS_NAMES.EventDetailsDoneButton }}
                secondaryClick={() => props.onClose()}
                primaryClick={() => props.onClose()}
            />
        </div>
    );
}
