import { UseMutationResult, useMutation, useQueryClient } from 'react-query';
import { createFormAsset } from 'src/api/formAssets';
import { FormAsset } from 'common/lib/entity/framework/FormAsset';
import { formAssetKey } from './useFormAsset';
import { formAssetsKey } from './useFormAssets';

export const createFormAssetKey = 'createFormAsset';

export interface UseCreateFormAssetInputVariables {
    formAssetTypeId: FormAsset['formAssetTypeId'];
    formAssetName: FormAsset['formAssetName'];
    formAssetDescription: FormAsset['formAssetDescription'];
    formAssetContent?: FormAsset['formAssetContent'];
    isGlobal: FormAsset['isGlobal'];
    orgNameInternal?: FormAsset['orgNameInternal'];
    facilityId?: FormAsset['facilityId'];
}

export const useCreateFormAsset = (): UseMutationResult<
    { formAssetId: FormAsset['formAssetId'] },
    Error,
    UseCreateFormAssetInputVariables,
    unknown
> => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationKey: [createFormAssetKey],
        mutationFn: async (variables) => {
            const { formAssetTypeId, formAssetName, formAssetDescription, formAssetContent, isGlobal, orgNameInternal, facilityId } = variables;

            if (!formAssetTypeId) {
                throw new Error('Form asset type id is required');
            }

            if (!formAssetName) {
                throw new Error('Form asset name is required');
            }

            if (!formAssetContent) {
                throw new Error('Form asset content is required');
            }

            if(isGlobal == null) {
                throw new Error('Form global indicator is required');
            }

            if(!isGlobal) {                
                if(orgNameInternal == null) {
                    throw new Error('Organization name internal is required when asset not global');
                }
            }
            else {
                if(orgNameInternal || facilityId != null) {
                    throw new Error('Can not specify org name or facility id when asset is global');
                }
            }

            return await createFormAsset({ formAssetTypeId, formAssetName, formAssetDescription, 
                formAssetContent, isGlobal, orgNameInternal, facilityId});
        },
        retry: false,
        onSuccess: async (data) => {
            queryClient.invalidateQueries({ queryKey: [formAssetKey] });
            queryClient.invalidateQueries({ queryKey: [formAssetsKey] });

            return data;
        },
    });
};
