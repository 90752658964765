import React, { useState, useEffect } from 'react';
import styles from './styles.module.scss';
import Select from 'react-select';
import * as utils from 'src/utils';
import { FormFacilityMapping } from 'common/lib/entity/framework/FormFacilityMapping';
import { useFormFacilityMappingFormDefinitions } from 'src/queries/useFormFacilityMappingFormDefinitions';
import { FormDefinition } from 'common/lib/entity/org/FormDefinition';
import { FormCatalog } from 'common/lib/entity/framework/FormCatalog';
import { useFormCatalog } from 'src/queries/useFormCatalog';
import { FormCatalogVersion } from 'common/lib/entity/framework/FormCatalogVersion';

export interface FormCatalogVersionDropDownProps {
    formCatalogId: FormCatalog['formCatalogId'];
    onChange: Function;
    selectedValue?: string | number;
    disabled?: boolean;
}

function FormCatalogVersionDropDown(props: FormCatalogVersionDropDownProps) {
    const { data: formCatalog, isFetching, refetch: getFormCatalog } = useFormCatalog(props.formCatalogId);
    const { formCatalogVersions } = formCatalog || null;
    const [options, setOptions] = useState([]);
    const [selected, setSelected] = useState(null);

    useEffect(() => {
        if (formCatalogVersions) {
            const opts = formCatalogVersions.map((formCatalogVersion: FormCatalogVersion) => ({
                label: formCatalogVersion.formVersionName,
                value: formCatalogVersion.formCatalogVersionId,
            }));
            setOptions(opts);
            setSelected(opts.find((i) => i.value === props.selectedValue) || null);
        }
    }, [formCatalogVersions]);

    useEffect(() => {
        const selectedVal = options.find((i) => i.value === props.selectedValue);
        if (selectedVal) {
            setSelected(selectedVal);
        }
    }, [props.selectedValue, options]);

    useEffect(() => {
        getFormCatalog();
    }, [props.formCatalogId]);

    return (
        <Select
            value={selected}
            onChange={(s: any) => {
                setSelected(s);
                props.onChange(s || null);
            }}
            isLoading={isFetching}
            className={styles.select}
            options={options}
            placeholder="Select Version"
            styles={utils.styleSelectComponent()}
            isDisabled={props.disabled === true || isFetching}
        />
    );
}

export default FormCatalogVersionDropDown;
