import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { parse } from 'query-string';
import { Formik, FormikProps, Field, FieldProps, Form } from 'formik';
import * as yup from 'yup';
import { RouteComponentProps, useLocation, useHistory } from 'react-router';

import PageContainer from 'src/components/PageContainer';
import Flex from 'src/components/Flex';
import Button from 'src/components/Button';
import * as passwordService from 'src/api/passwords';
import * as validation from 'src/utils/validation';

import styles from './styles.module.scss';
import Input from 'src/components/Input';
import { AxiosError } from 'axios';
import { ROUTES, APPLICATION_NAME } from 'src/utils/constants';
import { ANALYTICS_NAMES } from 'src/utils/analytics';
import { getBrandingString } from 'src/components/FeatureFlag';
import { showPasswordError, showPasswordConfirmError } from 'common/lib/util/PasswordUtil';

interface FormValues {
    [key: string]: string;
    password: string;
    confirmPassword: string;
}

function PasswordReset() {
    const location = useLocation();
    const history = useHistory();
    const queryStrings = parse(location.search);
    const [username, setUsername] = useState(queryStrings.un as string);
    const [resetPasswordKey, setResetPasswordKey] = useState(queryStrings.resetPasswordKey as string);
    const [serviceMsg, setServiceMsg] = useState('');
    const [requestSent, setRequestSent] = useState(false);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [submitting, setSubmitting] = useState(false);
    const [submitClicked, setSubmitClicked] = useState(false);

    function goToLogin() {
        history.push(ROUTES.Login);
    }

    const SuccessMessage = () => {
        return (
            <>
                <div className={styles.heading}>Password Reset Successfully</div>
                <div className={styles.subHeading}>
                    You can now log into the {getBrandingString()} Application or the {getBrandingString()} Dashboard
                    using your username and password.
                </div>
                <Flex self="stretch">
                    <Flex value={1} className={styles.rightButton}>
                        <Button
                            onClick={goToLogin}
                            type="primary"
                            text="Login"
                            className={styles.submitButton}
                            data-test-id={ANALYTICS_NAMES.PasswordReset_GoToLogin}
                        />
                    </Flex>
                </Flex>
            </>
        );
    };

    async function handleSubmit() {
        setSubmitting(true);
        try {
            // send request when confirm password matches
            if (confirmPassword && confirmPassword == password) {
                await passwordService.executePasswordReset(username, resetPasswordKey, password);
                setRequestSent(true);
            }
            setSubmitClicked(true);
        } catch (e: any) {
            setServiceMsg(e?.response?.data?.message);
        } finally {
            setSubmitting(false);
        }
    }

    return (
        <PageContainer allowScaling={true}>
            <div className={styles.header}></div>
            <Flex
                direction="column"
                self="stretch"
                value={1}
                align="center"
                justify="center"
                className={styles.loginWrap}
            >
                {!requestSent && (
                    <>
                        <div className={styles.heading}>Password Reset</div>

                        <div className={styles.subHeading}>
                            Please enter your new password for account <span className={styles.bold}>{username}</span>:
                        </div>
                        <div className={styles.serviceError}>{serviceMsg}</div>
                        <div className={styles.passwordExplanation}>
                            Password must contain atleast 10 characters, 1 number, 1 upper case letter, 1 lower case
                            letter, and a symbol.
                        </div>

                        <Input
                            name="password"
                            className={styles.input}
                            label="Password"
                            value={password}
                            onChangeText={(pw: string) => setPassword(pw)}
                            type="password"
                            data-test-id={ANALYTICS_NAMES.PasswordReset_NewPassword}
                            errorMessage={showPasswordError(password)}
                            infoState={showPasswordError(password) ? 'error' : null}
                        />
                        <Input
                            name="confirmPassword"
                            className={styles.input}
                            label="Confirm Password"
                            value={confirmPassword}
                            onChangeText={(pw: string) => setConfirmPassword(pw)}
                            type="password"
                            data-test-id={ANALYTICS_NAMES.PasswordReset_ConfirmPassword}
                            errorMessage={
                                submitClicked && !confirmPassword
                                    ? 'Confirm Password is required'
                                    : showPasswordConfirmError(password, confirmPassword)
                            }
                            infoState={
                                (submitClicked && !confirmPassword) ||
                                showPasswordConfirmError(password, confirmPassword)
                                    ? 'error'
                                    : null
                            }
                        />
                        <Flex self="stretch">
                            <Flex value={1} className={styles.rightButton}>
                                <Button
                                    onClick={handleSubmit}
                                    disabled={submitting}
                                    type="primary"
                                    text="Set Password"
                                    className={styles.submitButton}
                                    data-test-id={ANALYTICS_NAMES.PasswordReset_SetPassword}
                                />
                            </Flex>
                        </Flex>
                    </>
                )}
                {requestSent && <SuccessMessage />}
            </Flex>
        </PageContainer>
    );
}

export default observer(PasswordReset);
