import React, { useState, useEffect } from 'react';
import styles from './styles.module.scss';
import SideModal from 'src/components/SideModal';
import Input, { Label } from 'src/components/Input';
import ModalHeader from 'src/components/ModalHeader';
import * as utils from 'src/utils';
import ModalFooter from '../ModalFooter';
import { ToastStoreObject } from 'src/stores/ToastStore';
import { ImportBatchTemplate } from 'src/utils/types';
import Select from 'react-select';
import CodeEditor from 'src/components/CodeEditor';
import { ErrorStoreObject, ErrorTypes } from 'src/stores/ErrorStore';
import { createImportBatchTemplate, updateImportBatchTemplate } from 'src/api/importBatch';
import { getFlowScriptTemplates } from 'src/api/admin';
import { ANALYTICS_NAMES } from 'src/utils/analytics';

export interface AdminImportBatchTemplateProps {
    onClose: Function;
    onSave?: Function;
    isEdit?: boolean;
    importBatchTemplate?: ImportBatchTemplate;
}

export const REVIEW_OPTIONS = [
    {
        value: true,
        label: 'Yes',
    },
    {
        value: false,
        label: 'No',
    },
];

function AdminImportBatchTemplateModal(props: AdminImportBatchTemplateProps) {
    const [isLoading, setIsLoading] = useState(false);
    const [title, setTitle] = useState(
        (props.importBatchTemplate && props.importBatchTemplate.importBatchTemplateTitle) || '',
    );
    const [description, setDescription] = useState(
        (props.importBatchTemplate && props.importBatchTemplate.importBatchTemplateDescription) || '',
    );
    const [reviewValue, setReviewValue] = useState(
        props.importBatchTemplate ? props.importBatchTemplate.reviewBeforeProcess : null,
    );
    const [flowScriptTemplateOptions, setFlowScriptTemplateOptions] = useState<any[]>([]);
    const [flowScriptTemplate, setFlowScriptTemplate] = useState(null);
    const [targetDataModel, setTargetDataModel] = useState(
        (props.importBatchTemplate && JSON.stringify(props.importBatchTemplate.targetDataModel)) || '',
    );

    useEffect(() => {
        retrieveFlowScriptTemplates();
    }, []);

    async function retrieveFlowScriptTemplates() {
        try {
            setIsLoading(true);
            const templates = await getFlowScriptTemplates();
            setFlowScriptTemplateOptions(
                utils.formatSelectOptions(templates, {
                    valueKey: 'flowScriptTemplateGuid',
                    labelKey: 'flowScriptTemplateTitle',
                }),
            );
            setIsLoading(false);
        } catch (e) {
            ErrorStoreObject.setError(ErrorTypes.Loading);
        }
    }

    async function createTemplate() {
        try {
            setIsLoading(true);
            const parsedTargetDataModel = targetDataModel && JSON.parse(targetDataModel);
            await createImportBatchTemplate({
                flowScriptTemplateGuid: flowScriptTemplate,
                importBatchTemplateTitle: title,
                importBatchTemplateDescription: description,
                targetDataModel: parsedTargetDataModel,
                reviewBeforeProcess: reviewValue,
            });
            setIsLoading(false);

            if (props.onSave) {
                props.onSave();
            }
        } catch (error) {
            setIsLoading(false);
            ToastStoreObject.show(utils.parseError(error));
        }
    }

    async function updateTemplate() {
        try {
            setIsLoading(true);
            const parsedTargetDataModel = JSON.parse(targetDataModel);
            await updateImportBatchTemplate(props.importBatchTemplate.importBatchTemplateGuid, {
                importBatchTemplateTitle: title,
                importBatchTemplateDescription: description,
                targetDataModel: parsedTargetDataModel,
                reviewBeforeProcess: reviewValue,
            });
            setIsLoading(false);

            if (props.onSave) {
                props.onSave();
            }
        } catch (error) {
            setIsLoading(false);
            ToastStoreObject.show(utils.parseError(error));
        }
    }

    return (
        <SideModal isOpen={true} onClose={props.onClose}>
            <ModalHeader title={props.isEdit ? 'Edit Template' : 'Create Template'} onClose={props.onClose} />
            <div className={styles.contentWrap}>
                <Input
                    className={styles.input}
                    label="Title"
                    value={title}
                    onChangeText={(t: string) => setTitle(t)}
                    data-test-id={ANALYTICS_NAMES.ImportBatchTemplateModal_Title}
                />
                <Input
                    className={styles.input}
                    label="Description"
                    value={description}
                    onChangeText={(t: string) => setDescription(t)}
                    data-test-id={ANALYTICS_NAMES.ImportBatchTemplateModal_Description}
                />
                <div>
                    <Label text="Review Before Process?" />
                    <Select
                        value={REVIEW_OPTIONS.find((option: any) => option.value === reviewValue)}
                        className={styles.select}
                        options={REVIEW_OPTIONS}
                        onChange={(data: any) => setReviewValue(data.value)}
                        styles={utils.styleSelectComponent()}
                        data-test-id={ANALYTICS_NAMES.ImportBatchTemplateModal_Review}
                    />
                </div>
                {!props.isEdit ? (
                    <div>
                        <Label text="Flow Script Template" />
                        <Select
                            value={flowScriptTemplateOptions.find((option: any) => option.value === flowScriptTemplate)}
                            className={styles.select}
                            options={flowScriptTemplateOptions}
                            onChange={(data: any) => setFlowScriptTemplate(data.value)}
                            styles={utils.styleSelectComponent()}
                            data-test-id={ANALYTICS_NAMES.ImportBatchTemplateModal_FlowScriptTemplate}
                        />
                    </div>
                ) : null}
                <div>
                    <Label text="Target Data Model" />
                    <CodeEditor
                        value={targetDataModel}
                        onChange={(v: string) => setTargetDataModel(v)}
                        data-test-id={ANALYTICS_NAMES.ImportBatchTemplateModal_TargetDataModel}
                    />
                </div>
            </div>
            <ModalFooter
                primaryText={props.isEdit ? 'Save' : 'Create'}
                primaryClick={() => (props.isEdit ? updateTemplate() : createTemplate())}
                secondaryClick={() => {
                    props.onClose();
                }}
                isLoading={isLoading}
            />
        </SideModal>
    );
}

export default AdminImportBatchTemplateModal;
