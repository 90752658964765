import React, { useState, useEffect } from 'react';
import CreatableSelect from 'react-select/creatable';

import styles from './styles.module.scss';
import * as variables from 'src/styles/variables';
import * as validation from 'src/utils/validation';
import { ToastStoreObject } from 'src/stores/ToastStore';

interface Props {
    onChange: Function;
    selectedEmails: string[];
}

function Step1(props: Props) {
    const [input, setInput] = useState('');
    const [emails, setEmails] = useState([]);

    useEffect(() => {
        if (props.selectedEmails.length > 0) {
            setEmails(props.selectedEmails.map((e) => ({ label: e, value: e })));
        }
    }, [props.selectedEmails]);

    function handleKeyDown(e: any) {
        if (!input) return;
        switch (e.key) {
            case 'Enter':
            case 'Tab':
            case ',':
                handleValidateEmail();
                e.preventDefault();
                break;
            default:
                break;
        }
    }

    function handleValidateEmail() {
        if (input === '') {
            return;
        }
        if (!validation.emailRx.test(input)) {
            ToastStoreObject.show('You entered an invalid email address.');
        }
        // Ensure that the email is not a duplicate
        const findDuplicates = emails.some(element => {return element.value === input;});
        if (findDuplicates){
            ToastStoreObject.show('You entered a duplicate email address.');
        }
        setEmails([...emails, { label: input, value: input}]);
        props.onChange([...emails.map((e) => e.value), input]);
        setInput('');
    }

    const badEmailStyles = {
        multiValue: (styles: any, { data }: { data: any }) => {
            const duplicateArray = emails.filter((email: any) => email.value === data.value);

            if (duplicateArray?.length === 1 && validation.emailRx.test(data.value)){
                return {
                ...styles
                };
            }
            return {
                ...styles,
                backgroundColor: variables.lightRed,
            };
        },
    };

    return (
        <div className={styles.contentWrap}>
            <div className={styles.title}>Create new users</div>
            <div className={styles.subTitle}>
                Add a new user to your account with an email address. You can add multiple users at a time with a comma.
                Ex. 'john@smith.com, bill@smith.com'
            </div>
            <div className={styles.input}>
                <CreatableSelect
                    inputValue={input}
                    onBlur={handleValidateEmail}
                    isClearable={true}
                    isMulti={true}
                    menuIsOpen={false}
                    onChange={(t: any) => {
                        if (t) {
                            setEmails(t);
                            props.onChange(t.map((e: any) => e.value));
                        } else {
                            setEmails([]);
                            props.onChange([]);
                        }
                    }}
                    onInputChange={(t: string) => setInput(t)}
                    onKeyDown={handleKeyDown}
                    placeholder="Type an email address and press enter..."
                    value={emails}
                    styles={badEmailStyles}
                />
            </div>
        </div>
    );
}

export default Step1;
