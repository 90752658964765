import React from 'react';

const NewWindow = (props) => (
    <svg width={17} height={17} {...props} viewBox="0 0 17 17">
        <path
            d="M15 2v7.5h-1v-5.668l-9.334 9.334-0.707-0.707 9.459-9.459h-5.918v-1h7.5zM11 16h-10v-10h6.574v-1h-7.574v12h12v-7.714h-1v6.714z"
            fill={props.color || '#000'}
        />
    </svg>
);

export default NewWindow;
