import { observable, action, makeObservable } from 'mobx';
import { createContext } from 'react';
import * as teamsAPI from 'src/api/teams';
import { Facility, FacilityStoreObject } from './FacilityStore';
import * as utils from 'src/utils';
import * as variables from 'src/styles/variables';
import { OrgUser } from 'src/stores/OrgUserStore';

export interface Team {
    teamId?: number;
    teamName?: string;
    teamDescription?: string;
    actvInd?: boolean;
    facId?: number;
    facility?: Facility;
    users?: OrgUser[];
}
export interface TeamWithColor extends Team {
    color?: { active: string; inactive: string };
}
export const NO_TEAM_COLOR = { active: variables.grey, inactive: variables.lightGrey };

const TeamColors = [
    { active: variables.calendar1, inactive: variables.calendarInactive1 },
    { active: variables.calendar2, inactive: variables.calendarInactive2 },
    { active: variables.calendar3, inactive: variables.calendarInactive3 },
    { active: variables.calendar4, inactive: variables.calendarInactive4 },
    { active: variables.calendar5, inactive: variables.calendarInactive5 },
    { active: variables.calendar6, inactive: variables.calendarInactive6 },
    { active: variables.calendar7, inactive: variables.calendarInactive7 },
    { active: variables.calendar8, inactive: variables.calendarInactive8 },
    { active: variables.calendar9, inactive: variables.calendarInactive9 },
];

type FetchTeamsParams = { filters: { facId?: Facility['facId'] } };

class TeamStore {
    @observable teams: Team[] = [];
    @observable teamsWithColor: TeamWithColor[] = [];
    @observable teamDropDownValues: { value: string | number; label: string }[] = [];

    constructor() {
        makeObservable(this);
    }

    @action
    reset() {
        this.teams = [];
        this.teamsWithColor = [];
        this.teamDropDownValues = [];
    }

    @action
    async getTeams(params: FetchTeamsParams = { filters: {} }, force: boolean = false) {
        const queryParams = params;
        if (!queryParams.filters.facId && FacilityStoreObject.defaultFacilityId) {
            queryParams.filters.facId = FacilityStoreObject.defaultFacilityId;
        }
        // If we already have the teams for this facility, don't make the request again
        if (
            !force &&
            this.teams.length > 0 &&
            this.teams.filter((t) => t && t.facId === queryParams.filters.facId).length === this.teams.length
        ) {
            return this.teams;
        }
        const results = await teamsAPI.fetchTeams(queryParams);
        const data = results.data || [];
        this.setTeams(data);
        return data;
    }

    @action
    setTeams(teams: Team[]) {
        this.teams = teams;
        this.teamsWithColor = teams.map((t, i) => ({ ...t, color: TeamColors[i % TeamColors.length] }));
        this.teamDropDownValues = utils.formatSelectOptions(teams, { valueKey: 'teamId', labelKey: 'teamName' });
    }
}

// Import this to any other store that needs to use a value from it
export const TeamStoreObject = new TeamStore();

export default createContext(TeamStoreObject);
