import { MacraStoreObject } from 'src/stores/MacraStore';
import { format } from 'date-fns';
import * as variables from 'src/styles/variables';

export const BAR_COLORS = [
    variables.red,
    variables.blue,
    variables.lightGrey4,
    variables.lightBlue,
    variables.lightGrey,
];
export const BAR_COLORS2 = [
    variables.blue,
    variables.red,
    variables.lightGrey4,
    variables.lightBlue,
    variables.lightGrey,
];

export function getDailyCaseCountData() {
    return MacraStoreObject.dailyCaseCountData.dailyCounts.map((data, index) => {
        return {
            ...data,
            Admissible: data.admissible,
            'Non-Admissible': data.nonAdmissible,
            'Pending QCDR Calculation': data.pendingQcdrCalculation,
            date: format(new Date(data.date), 'MMM dd'),
        };
    });
}

export function getValidMacraYears() {
    return [2023, 2024];
}
