import React, { useState, useContext, useRef } from 'react';
import styles from './styles.module.scss';
import Input, { Label } from 'src/components/Input';
import { OrgUser } from 'src/stores/OrgUserStore';
import { useEffect } from 'react';
import RoleStore from 'src/stores/RoleStore';
import { observer } from 'mobx-react';
import RoleDropDown from 'src/components/RoleDropDown';
import Icon from 'src/components/Icon';
import Flex from '../Flex';
import Tooltip from '../Tooltip';

interface Props {
    user: OrgUser;
    onChange: Function;
}

function EditUser(props: Props) {
    const roleStore = useContext(RoleStore);

    const [firstName, setFirstName] = useState(props.user.frstNm || '');
    const [lastName, setLastName] = useState(props.user.lastNm || '');
    const [roles, setRoles] = useState(props.user.roles || []);
    const [selectedRoles, setSelectedRoles] = useState(props.user.roles.map((r) => r.roleId) || []);

    // Setup tooltip handling
    const loginRef = useRef(null);
    const [tooltipIsOpen, setTooltipIsOpen] = useState(false);
    const [tooltipText, setTooltipText] = useState('');
    const [tooltipRef, setTooltipRef] = useState(loginRef);

    useEffect(() => {
        props.onChange({
            frstNm: firstName,
            lastNm: lastName,
            roles,
        });
    }, [firstName, lastName, roles]);

    useEffect(() => {
        setSelectedRoles(props.user.roles.map((r) => r.roleId) || []);
    }, [props.user]);

    const handleRoleSelect = (updatedRoles: any[]) => {
        const newRoles = roleStore.roles.filter((role) =>
            updatedRoles && updatedRoles.length > 0
                ? updatedRoles.find((updatedRole) => updatedRole.value === role.roleId)
                : null,
        );
        setRoles(newRoles);
    };
    return (
        <div>
            <div className={styles.contentWrap}>
                <Input label="First Name" value={firstName} onChangeText={(value: string) => setFirstName(value)} />
                <Input label="Last Name" value={lastName} onChangeText={(value: string) => setLastName(value)} />
                <div className={styles.labelWrap}>
                    <Flex direction='row'>
                        <Label text="ROLES" />
                        <div ref={loginRef}
                            onMouseOver={() => {
                                setTooltipRef(loginRef);
                                setTooltipText('Select atleast one user role.');
                                setTooltipIsOpen(true);
                            }}
                            onMouseOut={() => setTooltipIsOpen(false)}>

                            <Icon type="fa" name="question-circle" className={styles.help} style={{ marginLeft: '10px' }} />
                        </div>
                    </Flex>
                    <RoleDropDown
                        selectedValue={selectedRoles}
                        isMulti={true}
                        onChange={(s: any) => handleRoleSelect(s)}
                        allowEmpty={false}
                    />
                </div>
            </div>
            <Tooltip element={tooltipRef} isOpen={tooltipIsOpen} direction="top">
                <div className={styles.tooltip}>{tooltipText}</div>
            </Tooltip>
        </div>
    );
}

export default observer(EditUser);
