import React, { useState, useContext, useEffect } from 'react';
import styles from './styles.module.scss';
import SideModal from 'src/components/SideModal';
import Flex from 'src/components/Flex';
import Button from 'src/components/Button';
import ModalHeader from 'src/components/ModalHeader';
import { OptionTypes } from 'src/components/OptionDropDown';
import * as utils from 'src/utils';
import ModalFooter from 'src/components/ModalFooter';
import { IAgencyContact, NaValues } from 'common/lib/model/nemsis3_4/demographic';
import OrgStore, { OrganizationOrgDB } from 'src/stores/OrgStore';
import NemsisInput from 'src/components/NemsisInput';
import NemsisOptionDropDown from 'src/components/NemsisOptionDropDown';
import { getDropdownValue } from 'src/utils/nemsis';
import * as organizationAPI from 'src/api/organization';
import { ToastStoreObject } from 'src/stores/ToastStore';
import ReferenceListStore, { SelectComponent } from 'src/stores/ReferenceListStore';
import { observer } from 'mobx-react';
import NemsisStore from 'src/stores/NemsisStore';

export interface AgencyContactModalProps {
    onClose: Function;
    onSave: Function;
    contact?: IAgencyContact;
}

const defaultAgencyContactEmail: IAgencyContact['emailAddresses'][0] = {
    emailAddress: '',
    typeCode: '',
};

const defaultAgencyContactPhone: IAgencyContact['phoneNumbers'][0] = {
    phoneNumber: '',
    typeCode: '',
};

const defaultContact: IAgencyContact = {
    contactId: '',
    contactTypeCode: '',
    lastName: '',
    firstName: '',
    middleInitial: '',
    address1: '',
    address2: '',
    city: '',
    stateCode: '',
    zipCode: '',
    countryCode: '',
    phoneNumbers: [],
    emailAddresses: [],
    medicalDirectorDegreeCode: '',
    medicalDirectorBoardCertificationTypeCodes: [],
    medicalDirectorCompensationCode: '',
    medicalDirectorFellowshipTrainedStatusCode: '',
    webAddress: '',
    notValues: {
        phoneNumbers: [{}],
        emailAddresses: [{}],
    },
};

function AgencyContactModal(props: AgencyContactModalProps) {
    const [data, setData] = useState<IAgencyContact>(props.contact || utils.clone(defaultContact));
    const referenceListStore = useContext(ReferenceListStore);
    const isEdit = !!props.contact;

    const orgStore = useContext(OrgStore);
    const nemsisStore = useContext(NemsisStore);

    useEffect(() => {
        if (data.stateCode == '') return;
        referenceListStore.getFilteredData(OptionTypes.ANSICityCodes, {
            filterName: 'stateAnsiCode',
            filterValue: data.stateCode,
        });
    }, [data.stateCode]);

    function handleNaChange(property: keyof IAgencyContact, subProperty?: string, index?: number) {
        const newData = { ...data };
        if (subProperty) {
            // Make sure everything is defaulted all the way down
            if (!data.notValues) {
                newData.notValues = { [property]: [] };
            }
            if (!data.notValues[property]) {
                newData.notValues = { ...data.notValues, [property]: [] };
            }
            if (!data.notValues[property][index]) {
                newData.notValues[property][index] = {};
            }

            newData.notValues[property][index][subProperty] = data.notValues[property][index][subProperty]
                ? undefined
                : NaValues.NOT_APPLICABLE;
        } else {
            newData.notValues[property] =
                data.notValues && data.notValues[property] ? undefined : NaValues.NOT_APPLICABLE;
        }
        setData(newData);
    }

    const updateInput = (param: keyof IAgencyContact) => (t: string) => setData({ ...data, [param]: t });

    function updateNestedInput({
        param,
        subParam,
        index,
        value,
    }: {
        param: keyof IAgencyContact;
        subParam: string;
        index: number;
        value: any;
    }) {
        const newData: any = { ...data };
        if (!newData[param]) {
            newData[param] = [];
        }
        if (!newData[param][index]) {
            newData[param][index] = {};
        }
        newData[param][index][subParam] = value;
        setData(newData);
    }

    function getNotValBool(parentProp: keyof IAgencyContact, childProp?: string, index?: number) {
        if (childProp) {
            return !!(
                data &&
                data.notValues &&
                data.notValues[parentProp] &&
                data.notValues[parentProp][index] &&
                data.notValues[parentProp][index][childProp]
            );
        }
        return !!(data && data.notValues && data.notValues[parentProp]);
    }

    function handleRemoveRow(parentProp: keyof IAgencyContact, index: number) {
        const currentData = { ...data };
        // @ts-ignore
        (currentData[parentProp] || []).splice(index, 1);
        // remove any not values
        (currentData.notValues[parentProp] || []).splice(index, 1);
        setData(currentData);
    }

    function handleAddRow(property: keyof IAgencyContact) {
        const currentData = { ...data };
        if (property === 'emailAddresses') {
            currentData[property].push({ ...defaultAgencyContactEmail });
            // add an empty object to track indexes of not values correctly
            currentData.notValues[property] = [...currentData.notValues[property], {}];
        }
        if (property === 'phoneNumbers') {
            currentData[property].push({ ...defaultAgencyContactPhone });
            // add an empty object to track indexes of not values correctly
            currentData.notValues[property] = [...currentData.notValues[property], {}];
        }
        setData(currentData);
    }

    function renderTopLevelInput({ key, label, isNa }: { key: keyof IAgencyContact; label: string; isNa?: boolean }) {
        return (
            <NemsisInput
                nemsisLabel={label}
                value={getNotValBool(key) ? NaValues.NOT_APPLICABLE : (data[key] as string)}
                onChangeText={updateInput(key)}
                {...(isNa
                    ? {
                          onNaClick: () => handleNaChange(key),
                          naSelected: getNotValBool(key),
                      }
                    : {})}
            />
        );
    }

    function renderDropDown({
        key,
        label,
        type,
        isMulti,
        isNa,
        nested,
        options,
        disabled,
    }: {
        key: keyof IAgencyContact;
        label: string;
        type: OptionTypes;
        isMulti?: boolean;
        isNa?: boolean;
        nested?: { index: number; row: any; key: string };
        options?: SelectComponent[];
        disabled?: boolean;
    }) {
        const value = nested ? nested.row[nested.key] : data[key];
        return (
            <NemsisOptionDropDown
                isDisabled={disabled}
                nemsisLabel={label}
                isMulti={isMulti}
                selectedValue={
                    getNotValBool(key, nested && nested.key, nested && nested.index) ? NaValues.NOT_APPLICABLE : value
                }
                onChange={(d: any) => {
                    const val = getDropdownValue(d, isMulti ? [] : undefined);
                    if (nested) {
                        return updateNestedInput({ param: key, subParam: nested.key, index: nested.index, value: val });
                    }
                    return setData({ ...data, [key]: val });
                }}
                {...(isNa
                    ? {
                          onNaClick: () => handleNaChange(key, nested && nested.key, nested && nested.index),
                          naSelected: getNotValBool(key, nested && nested.key, nested && nested.index),
                      }
                    : {})}
                type={type}
                options={options || null}
                virtualize={options ? options.length > 25 : false}
            />
        );
    }

    async function handleSave() {
        try {
            const agencyContact = data;
            const agencyContacts = (orgStore.currentOrgDbOrg || ({} as OrganizationOrgDB)).agencyContacts || [];
            if (isEdit) {
                const indexToUpdate = agencyContacts.findIndex((c) => c.contactId === agencyContact.contactId);
                if (indexToUpdate >= 0) {
                    agencyContacts[indexToUpdate] = agencyContact;
                } else {
                    agencyContacts.push(agencyContact);
                }
            } else {
                agencyContacts.push(agencyContact);
            }

            await organizationAPI.updateAgencyData({ agencyContacts });
            await nemsisStore.validateDemData(true);

            props.onSave();
            props.onClose();
        } catch (error) {
            ToastStoreObject.show(utils.parseError(error));
        }
    }

    return (
        <SideModal isOpen={true} onClose={() => props.onClose()}>
            <ModalHeader title={isEdit ? `Edit Agency Contact` : 'Create Agency Contact'} onClose={props.onClose} />
            <div className={styles.modalContent}>
                <Flex direction="column" className={styles.form}>
                    <Flex direction="row" justify="between" className={styles.formRow}>
                        <Flex value={1}>
                            {renderDropDown({
                                key: 'contactTypeCode',
                                label: 'Contact Type',
                                type: OptionTypes.EMSAgencyContactType,
                                isNa: true,
                            })}
                        </Flex>
                    </Flex>
                    <Flex direction="row" justify="between" className={styles.formRow}>
                        <Flex value={1}>
                            {renderTopLevelInput({ key: 'firstName', label: 'First Name', isNa: true })}
                        </Flex>
                        <Flex value={0.1} />
                        <Flex value={0.4}>{renderTopLevelInput({ key: 'middleInitial', label: 'Initial' })}</Flex>
                        <Flex value={0.1} />
                        <Flex value={1}>
                            {renderTopLevelInput({ key: 'lastName', label: 'Last Name', isNa: true })}
                        </Flex>
                    </Flex>
                    <Flex direction="row" justify="between" className={styles.formRow}>
                        <Flex value={1}>
                            {renderTopLevelInput({ key: 'address1', label: 'Street Address', isNa: true })}
                        </Flex>
                    </Flex>
                    <Flex direction="row" justify="between" className={styles.formRow}>
                        <Flex value={1}>
                            {renderTopLevelInput({ key: 'address2', label: 'Street Address 2', isNa: true })}
                        </Flex>
                    </Flex>
                    <Flex direction="row" justify="between" className={styles.formRow}>
                        <Flex value={0.7}>
                            {renderDropDown({
                                key: 'stateCode',
                                label: 'State',
                                type: OptionTypes.ANSIStateCodes,
                                isNa: true,
                            })}
                        </Flex>
                        <Flex value={0.1} />
                        <Flex value={1}>
                            {renderDropDown({
                                key: 'city',
                                label: 'City',
                                type: OptionTypes.ANSICityCodes,
                                isNa: true,
                                disabled: data.stateCode === '',
                                options:
                                    referenceListStore.filteredReferenceListDropdownValues.get(
                                        `${OptionTypes.ANSICityCodes}-${data.stateCode}`,
                                    ) || [],
                            })}
                        </Flex>
                        <Flex value={0.1} />
                        <Flex value={0.5}>
                            {renderTopLevelInput({ key: 'zipCode', label: 'Zip Code', isNa: true })}
                        </Flex>
                    </Flex>
                    <Flex direction="row" justify="between" className={styles.formRow}>
                        <Flex value={1}>
                            {renderDropDown({
                                key: 'countryCode',
                                label: 'Country',
                                type: OptionTypes.EMSANSICountryCodesCAMXUS,
                            })}
                        </Flex>
                    </Flex>
                    <Flex direction="row" justify="between" className={styles.formRow}>
                        <Flex value={1}>
                            {renderTopLevelInput({ key: 'webAddress', label: 'Web Address', isNa: true })}
                        </Flex>
                    </Flex>
                    <Flex direction="row" justify="between" className={styles.formRow}>
                        <Flex value={1} direction="column" className={styles.newRow}>
                            <span className={styles.heading}>Phone Number</span>
                            {(data.phoneNumbers || []).map((s, index) => (
                                <Flex
                                    key={`${index}`}
                                    direction="row"
                                    justify="between"
                                    align="center"
                                    className={styles.formRow}
                                >
                                    <Flex value={0.5}>
                                        {renderDropDown({
                                            key: 'phoneNumbers',
                                            label: 'Type',
                                            type: OptionTypes.EMSPhoneNumberType,
                                            nested: { row: s, key: 'typeCode', index },
                                            isNa: true,
                                        })}
                                    </Flex>
                                    <Flex value={0.1} />
                                    <Flex value={1}>
                                        <NemsisInput
                                            nemsisLabel="Phone Number"
                                            onNaClick={() => handleNaChange('phoneNumbers', 'phoneNumber', index)}
                                            naSelected={getNotValBool('phoneNumbers', 'phoneNumber', index)}
                                            value={
                                                getNotValBool('phoneNumbers', 'phoneNumber', index)
                                                    ? NaValues.NOT_APPLICABLE
                                                    : s.phoneNumber
                                            }
                                            onChangeText={(text: string) =>
                                                updateNestedInput({
                                                    param: 'phoneNumbers',
                                                    subParam: 'phoneNumber',
                                                    index,
                                                    value: text,
                                                })
                                            }
                                        />
                                    </Flex>
                                    <Flex value={0.7} align="end" justify="end">
                                        <Button
                                            type="primary"
                                            text="Remove"
                                            onClick={() => handleRemoveRow('phoneNumbers', index)}
                                        />
                                    </Flex>
                                </Flex>
                            ))}
                            <Button
                                type="transparent"
                                text="+ Add Phone Number"
                                onClick={() => handleAddRow('phoneNumbers')}
                                className={styles.addUnderline}
                            />
                        </Flex>
                    </Flex>
                    <Flex direction="row" justify="between" className={styles.formRow}>
                        <Flex value={1} direction="column" className={styles.newRow}>
                            <span className={styles.heading}>Email Address</span>
                            {(data.emailAddresses || []).map((s, index) => (
                                <Flex
                                    key={`${index}`}
                                    direction="row"
                                    justify="between"
                                    align="center"
                                    className={styles.formRow}
                                >
                                    <Flex value={0.5}>
                                        {renderDropDown({
                                            key: 'emailAddresses',
                                            label: 'Type',
                                            type: OptionTypes.EMSEmailAddressType,
                                            nested: { row: s, key: 'typeCode', index },
                                            isNa: true,
                                        })}
                                    </Flex>
                                    <Flex value={0.1} />
                                    <Flex value={1}>
                                        <NemsisInput
                                            nemsisLabel="Email Address"
                                            onNaClick={() => handleNaChange('emailAddresses', 'emailAddress', index)}
                                            naSelected={getNotValBool('emailAddresses', 'emailAddress', index)}
                                            value={
                                                getNotValBool('emailAddresses', 'emailAddress', index)
                                                    ? NaValues.NOT_APPLICABLE
                                                    : s.emailAddress
                                            }
                                            onChangeText={(text: string) =>
                                                updateNestedInput({
                                                    param: 'emailAddresses',
                                                    subParam: 'emailAddress',
                                                    index,
                                                    value: text,
                                                })
                                            }
                                        />
                                    </Flex>
                                    <Flex value={0.7} align="end" justify="end">
                                        <Button
                                            type="primary"
                                            text="Remove"
                                            onClick={() => handleRemoveRow('emailAddresses', index)}
                                        />
                                    </Flex>
                                </Flex>
                            ))}
                            <Button
                                type="transparent"
                                text="+ Add Email Address"
                                onClick={() => handleAddRow('emailAddresses')}
                                className={styles.addUnderline}
                            />
                        </Flex>
                    </Flex>
                    <Flex direction="row" justify="between" className={styles.formRow}>
                        <span className={styles.heading}>Medical Director</span>
                    </Flex>
                    <Flex direction="row" justify="between" className={styles.formRow}>
                        <Flex value={1}>
                            {renderDropDown({
                                key: 'medicalDirectorDegreeCode',
                                label: 'Degree',
                                type: OptionTypes.EMSAgencyMedicalDirectorDegree,
                                isNa: true,
                            })}
                        </Flex>
                        <Flex value={0.1} />
                        <Flex value={1}>
                            {renderDropDown({
                                key: 'medicalDirectorBoardCertificationTypeCodes',
                                label: 'Board Certification',
                                type: OptionTypes.EMSAgencyMedicalDirectorBoardCertificationType,
                                isMulti: true,
                                isNa: true,
                            })}
                        </Flex>
                    </Flex>
                    <Flex direction="row" justify="between" className={styles.formRow}>
                        <Flex value={1}>
                            {renderDropDown({
                                key: 'medicalDirectorCompensationCode',
                                label: 'Medical Director Compensation',
                                type: OptionTypes.EMSMedicalDirectorCompensation,
                            })}
                        </Flex>
                        <Flex value={0.1} />
                        <Flex value={1}>
                            {renderDropDown({
                                key: 'medicalDirectorFellowshipTrainedStatusCode',
                                label: 'Medical Director Fellowship Trained',
                                type: OptionTypes.EMSYesNoValues,
                            })}
                        </Flex>
                    </Flex>
                </Flex>
            </div>
            <ModalFooter
                primaryText={isEdit ? 'Save' : 'Create'}
                primaryClick={handleSave}
                secondaryClick={() => {
                    setData(utils.clone(defaultContact));
                    props.onClose();
                }}
            />
        </SideModal>
    );
}

export default observer(AgencyContactModal);
