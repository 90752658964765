import React, { useContext } from 'react';
import Portal from '../Portal';
import classNames from 'classnames';
import styles from './styles.module.scss';
import ToastStore, { ToastType } from 'src/stores/ToastStore';
import { observer } from 'mobx-react';
import Icon from 'src/components/Icon';
import { ICONS } from 'src/utils/constants';
import { ANALYTICS_NAMES } from 'src/utils/analytics';

function Toast() {
    const toastStore = useContext(ToastStore);

    return toastStore.visible ? (
        <Portal>
            <div
                className={classNames(styles.toastBar, {
                    [styles.success]: toastStore.toastType === ToastType.Success,
                    [styles.warn]: toastStore.toastType === ToastType.Warn,
                })}
            >
                <div className={styles.message}>{toastStore.message}</div>
                <div className={styles.close} data-test-id={ANALYTICS_NAMES.Toast_Close} onClick={toastStore.hide}>
                    <Icon name={ICONS.Close} />
                </div>
            </div>
        </Portal>
    ) : null;
}

export default observer(Toast);
