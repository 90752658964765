import React, { useState, useEffect, useRef, forwardRef } from 'react';
import ReactTable, { TableProps, Column } from 'react-table';
import styles from './styles.module.scss';
import Pagination from './Pagination';
import * as utils from '../../utils';
import './table.scss';

export interface CustomTableProps extends Partial<TableProps> {
    columns: Column<any>[];
    data: any[];
    isLoading?: boolean;
    globalFilter?: string;
}
const Table = forwardRef(
    (
        { columns, data, isLoading, defaultPageSize, showPagination, globalFilter, ...rest }: CustomTableProps,
        ref: React.Ref<ReactTable<any>>,
    ) => {
        const pageSize = defaultPageSize || 25;
        let [filteredData, setFilteredData] = useState([]);

        useEffect(() => {
            // This filter function expects the accessor property to be in function or string format.
            // If you need array syntax, you'll need to extend this function to handle that data type.
            setFilteredData(
                data.filter((row) => {
                    return columns.some((value: Column, index) => {
                        if (typeof value.accessor === 'function') {
                            return String(value.accessor(row))
                                .toLowerCase()
                                .includes(globalFilter ? globalFilter.toLowerCase() : null);
                        } else {
                            return String(row[value.accessor as string])
                                .toLowerCase()
                                .includes(globalFilter ? globalFilter.toLowerCase() : null);
                        }
                    });
                }),
            );
        }, [data, globalFilter]);

        function getData() {
            if (globalFilter && globalFilter.length > 0) return filteredData;
            else return data || [];
        }

        return (
            <ReactTable
                columns={columns}
                loading={isLoading}
                data={getData()}
                minRows={0}
                sortable={true}
                resizable={true}
                className={styles.table}
                PaginationComponent={Pagination}
                showPagination={utils.exists(showPagination) ? showPagination : (data || []).length > pageSize}
                showPaginationTop={false}
                showPaginationBottom={true}
                showPageSizeOptions={false}
                defaultPageSize={pageSize}
                showPageJump={false}
                {...rest}
                ref={ref}
            />
        );
    },
);
Table.displayName = 'Table';
export default Table;
