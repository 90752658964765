import React from 'react';

const SvgTask = (props) => (
    <svg width={33} height={33} {...props} viewBox="0 0 33 33">
        <g transform="translate(1 1)" fill="none" fillRule="evenodd">
            <circle stroke="#F1F0F0" fill="#F9F9F9" cx={15.5} cy={15.5} r={15.5} />
            <path
                d="M7 15.5c0 .587.476 1.063 1.063 1.063h6.197c.098 0 .178.079.178.177v6.198a1.063 1.063 0 002.124 0V16.74c0-.098.08-.177.178-.177h6.198a1.063 1.063 0 000-2.125H16.74a.177.177 0 01-.177-.178V8.063a1.062 1.062 0 10-2.125 0v6.197c0 .098-.08.178-.178.178H8.063C7.476 14.438 7 14.912 7 15.5z"
                fill="#DA1057"
                fillRule="nonzero"
            />
        </g>
    </svg>
);

export default SvgTask;
