import { observable, action, makeObservable } from 'mobx';
import { createContext } from 'react';
import * as patientsAPI from 'src/api/patients';
import * as utils from 'src/utils';

export interface NeedState {
    patientNeedStateId?: number;
    patientNeedStateCode?: string;
    patientNeedStateTitle?: string;
    patientNeedStateDescription?: string;
    actvInd?: boolean;
}

class NeedStateStore {
    @observable needStates: NeedState[] = [];
    @observable needStatesDropdownValues: { value: string | number; label: string }[] = [];
    @observable activeNeedStates: NeedState[] = [];

    constructor() {
        makeObservable(this);
    }

    @action
    reset() {
        this.needStates = [];
        this.needStatesDropdownValues = [];
        this.activeNeedStates = [];
    }

    @action
    async getNeedStates(force = false) {
        if (!force && this.needStates.length > 0) {
            return;
        }
        const needStates = await patientsAPI.fetchNeedStates();

        this.setNeedStates(needStates.data || []);
    }

    @action
    setNeedStates(needStates: NeedState[]) {
        this.needStates = needStates;
        const activeNeedStates = needStates.filter((es) => es.actvInd);
        this.activeNeedStates = activeNeedStates;
        this.needStatesDropdownValues = utils.formatSelectOptions(activeNeedStates, {
            valueKey: 'patientNeedStateId',
            labelKey: 'patientNeedStateTitle',
        });
    }
}

// Import this to any other store that needs to use a value from it
export const NeedStateStoreObject = new NeedStateStore();

export default createContext(NeedStateStoreObject);
