import { UseQueryResult, useQuery } from 'react-query';
import { getFormFacilityMappingById } from 'src/api/formFacilityMappings';
import { UserStoreObject } from 'src/stores/UserStore';
import { FormFacilityMapping } from 'common/lib/entity/framework/FormFacilityMapping';

export const formFacilityMappingKey = 'formFacilityMapping';

export const useFormFacilityMapping = (
    formFacilityMappingId: FormFacilityMapping['formFacilityMappingId'],
    getOrgFormDefinition: boolean = false
): UseQueryResult<{formFacilityMapping:FormFacilityMapping}> => {
    return useQuery({
        queryKey: [formFacilityMappingKey, { formFacilityMappingId }],
        queryFn: async () => {
            return await getFormFacilityMappingById(formFacilityMappingId, getOrgFormDefinition);
        },
        enabled: formFacilityMappingId && UserStoreObject.isGraphiumAdmin(),
        initialData: {},
    });
};
