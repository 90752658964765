import React, { useContext, useRef, useState } from 'react';
import classNames from 'classnames';
import Color from 'color';
import styles from './styles.module.scss';
import Flex from 'src/components/Flex';
import Icon from 'src/components/Icon';
import DateComponent from 'src/components/DateComponent';
import { Event, CalendarEventInterface } from 'src/utils/types';
import TeamStore, { NO_TEAM_COLOR } from 'src/stores/TeamStore';
import EventStateStore from 'src/stores/EventStateStore';
import Tooltip from 'src/components/Tooltip';
import Card from 'src/components/Card';
import { ICONS, DISPLAY_DATE_FORMAT, DISPLAY_TIME_FORMAT } from 'src/utils/constants';
import * as variables from 'src/styles/variables';
import { ANALYTICS_NAMES } from 'src/utils/analytics';
import { EventApi } from '@fullcalendar/core';
import OrgUserStore, { OrgUserWithColor } from 'src/stores/OrgUserStore';

interface CalendarEventProps {
    event: CalendarEventInterface | EventApi;
}

export const AppointmentIcon = ICONS.TimeClockCircle1Alternate;
export const TaskIcon = ICONS.NotesBookText;

const CalendarEvent = ({ event }: CalendarEventProps) => {
    const elRef = useRef(null);
    const [tooltip, setTooltip] = useState(false);
    const teams = useContext(TeamStore);
    const orgUserStore = useContext(OrgUserStore);
    const eventStateStore = useContext(EventStateStore);
    const ghEvent: Event = event.extendedProps.ghEvent || {};
    const isTask = ghEvent.eventClassCode === 'TASK';
    const style: React.CSSProperties = {};
    let textColor = variables.white;
    let backgroundColor;

    const isCanceled = eventStateStore.isCanceled(ghEvent);
    const isRescheduled = eventStateStore.isRescheduled(ghEvent);
    const isCompleted = eventStateStore.isCompleted(ghEvent);

    if (ghEvent.assigneeTeamId) {
        const team = teams.teamsWithColor.find((t) => t.teamId === ghEvent.assigneeTeamId);
        if (team && team.color) {
            if (isCanceled || isRescheduled || isCompleted) {
                backgroundColor = team.color.inactive;
            } else {
                backgroundColor = team.color.active;
            }
        }
    }

    if (ghEvent.assigneeUsrId) {
        const user = orgUserStore.orgUsersWithColor.find(
            (u: OrgUserWithColor) => u.usrId === parseInt(ghEvent.assigneeUsrId.toString()),
        );
        if (user && user.color) {
            if (isCanceled || isRescheduled || isCompleted) {
                backgroundColor = user.color.inactive;
            } else {
                backgroundColor = user.color.active;
            }
        }
    }

    if (!backgroundColor) {
        if (isCanceled || isRescheduled || isCompleted) {
            backgroundColor = NO_TEAM_COLOR.inactive;
        } else {
            backgroundColor = NO_TEAM_COLOR.active;
        }
    }

    if (isCanceled || isRescheduled || isCompleted) {
        textColor = Color(variables.grey)
            .alpha(0.9)
            .string();
        style.color = textColor;
    }
    style.backgroundColor = backgroundColor;

    const dateComponent = (
        <DateComponent
            date={event.start}
            format={event.allDay ? DISPLAY_DATE_FORMAT : DISPLAY_TIME_FORMAT}
            className={styles.date}
        />
    );
    return (
        <>
            <Flex
                ref={elRef}
                direction="row"
                align="start"
                className={classNames('fc-content', styles.content, {
                    [styles.canceled]: isCanceled,
                    [styles.completed]: isCompleted,
                    [styles.rescheduled]: isRescheduled,
                })}
                style={style}
                data-test-id={ANALYTICS_NAMES.CalendarEvent_Content}
                onMouseEnter={() => setTooltip(true)}
                onMouseLeave={() => setTooltip(false)}
                onClick={() => setTooltip(false)}
            >
                {isCompleted && isTask ? (
                    <Icon name={ICONS.Check1} className={styles.icon} color={variables.green} size={14} />
                ) : (
                    <Icon
                        name={isTask ? TaskIcon : AppointmentIcon}
                        className={styles.icon}
                        color={textColor}
                        size={17}
                    />
                )}
                <Flex
                    direction="column"
                    justify="center"
                    className={classNames('fc-title', styles.title)}
                    style={{ color: textColor }}
                >
                    <span>{event.title}</span>
                    {dateComponent}
                </Flex>
            </Flex>
            <Tooltip element={elRef} isOpen={tooltip} direction="top">
                <Card elevation={2} className={styles.tooltip}>
                    <Flex direction="row" align="center">
                        {event.title}&nbsp;-&nbsp;{dateComponent}
                    </Flex>
                </Card>
            </Tooltip>
        </>
    );
};

export default CalendarEvent;
