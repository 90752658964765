import { UseMutationResult, useMutation, useQueryClient } from 'react-query';
import { FormCatalog } from 'common/lib/entity/framework/FormCatalog';
import { FormCatalogOverrides, FormFacilityMapping } from 'common/lib/entity/framework/FormFacilityMapping';
import { createFormFacilityMapping } from 'src/api/formFacilityMappings';
import { formFacilityMappingsKey } from './useFormFacilityMappings';
import { FormDefinition } from 'common/lib/entity/org/FormDefinition';

export const createFormFacilityMappingKey = 'createFormFacilityMapping';

export interface UseCreateFormFacilityMappingInputVariables {
    orgNameInternal: FormFacilityMapping['orgNameInternal'];
    orgName: FormFacilityMapping['orgName'];
    facilityId: FormFacilityMapping['facilityId'];
    facilityName: FormFacilityMapping['facilityName'];
    formDefinitionId?: FormFacilityMapping['formDefinitionId'];
    formDefinitionName?: FormFacilityMapping['formDefinitionName'];
    formOverrides?: FormFacilityMapping['formOverrides'];
}

export const useCreateFormFacilityMapping = (
    formCatalogId: FormCatalog['formCatalogId'],
): UseMutationResult<
    { formFacilityMappingId: FormFacilityMapping['formFacilityMappingId'] },
    Error,
    UseCreateFormFacilityMappingInputVariables,
    unknown
> => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationKey: [createFormFacilityMappingKey],
        mutationFn: async (variables) => {
            const {
                orgNameInternal,
                orgName,
                facilityId,
                facilityName,
                formDefinitionId,
                formDefinitionName,
                formOverrides,
            } = variables;

            if (!formCatalogId) {
                throw new Error('Form catalog id is required');
            }

            if (!orgNameInternal) {
                throw new Error('Organization name is required');
            }

            if (!facilityId) {
                throw new Error('Facility id is required');
            }

            if (isNaN(facilityId)) {
                throw new Error('Facility id must be a number');
            }

            if (formDefinitionId && isNaN(formDefinitionId)) {
                throw new Error('Form definition id must be a number');
            }

            if (formDefinitionId != null && !formDefinitionName) {
                throw new Error('Form definition name is required when selecting a form definition');
            }

            if (formOverrides && !Array.isArray(formOverrides.pageOverrides)) {
                throw new Error('Overrides must be set and page overrides must be an array');
            }

            return await createFormFacilityMapping({
                formCatalogId,
                orgNameInternal,
                orgName,
                facilityId,
                facilityName,
                formDefinitionId,
                formDefinitionName,
                formOverrides,
            });
        },
        onSuccess: async (data) => {
            queryClient.invalidateQueries({ queryKey: [formFacilityMappingsKey] });
            return data;
        },
        retry: false,
    });
};
