import React from 'react';

const SvgPlusButton = (props) => (
    <svg width={12} height={12} {...props} viewBox="0 0 12 12">
        <path
            d="M1 6c0 .345.28.625.625.625h3.646c.057 0 .104.047.104.104v3.646a.625.625 0 101.25 0V6.729c0-.057.047-.104.104-.104h3.646a.625.625 0 100-1.25H6.729a.104.104 0 01-.104-.104V1.625a.625.625 0 10-1.25 0v3.646a.104.104 0 01-.104.104H1.625A.625.625 0 001 6z"
            fill={props.color || '#FFF'}
            fillRule="nonzero"
            stroke={props.color || '#FFF'}
        />
    </svg>
);

export default SvgPlusButton;
