import { observable, action, computed, reaction, IReactionDisposer, toJS, makeObservable } from 'mobx';
import { computedFn } from 'mobx-utils';
import { createContext } from 'react';
import {
    CalculatedScorecardMetric,
    ScorecardMetricSample,
    ScorecardMetricResults,
} from 'common/lib/model/Scorecard/Scorecard';
import * as scorecardAPI from 'src/api/scorecard';
import { IdMode } from 'common/lib/model/IdMode';
import { Facility, FacilityStoreObject } from './FacilityStore';
import { QcdrSettingsStoreObject } from './QcdrSettingsStore';
import {
    parseMetricsResults,
    calculateAgeGroups,
    calculateASACounts,
    calculateEmergentCounts,
    calculateGenderGroups,
    calculateInVsOutPatientGroups,
    calculateAdmissionTypeGroups,
} from 'src/utils/scorecard';

class ScorecardStore {
    @observable isLoading: boolean = false;
    @observable scorecardOrgMetrics: CalculatedScorecardMetric[];
    @observable scorecardOrgSamples: ScorecardMetricSample[];

    currentMode: IdMode = IdMode.Facility;

    constructor() {
        makeObservable(this);
    }

    @action
    reset() {
        this.isLoading = false;
        this.scorecardOrgMetrics = undefined;
        this.scorecardOrgSamples = undefined;
    }

    @action
    async getScorecardOrgMetrics(
        id: string | number,
        startDate: string,
        endDate: string,
        idMode: IdMode = IdMode.Facility,
    ) {
        if (this.isLoading) return;

        this.setIsLoading(true);
        const result = await scorecardAPI.getScorecardOrgMetrics({
            id: id,
            startDate: startDate,
            endDate: endDate,
            isTin: idMode === IdMode.TIN,
        });

        const scorecardResult: ScorecardMetricResults = result.data;
        this.setScorecardOrgMetrics(scorecardResult.metrics || []);
        this.setScorecardOrgSamples(scorecardResult.samples || []);
        this.setIsLoading(false);
    }

    @action
    setIsLoading(value: boolean) {
        this.isLoading = value;
    }

    @action
    setScorecardOrgMetrics(value: CalculatedScorecardMetric[]) {
        this.scorecardOrgMetrics = value;
    }

    @action
    setScorecardOrgSamples(value: ScorecardMetricSample[]) {
        this.scorecardOrgSamples = value;
    }

    qualityData = computedFn((id: string | number, idMode: IdMode) => {
        if (!this.scorecardOrgMetrics) return [];

        let currentFacilities: Facility[];

        if (idMode === IdMode.Facility) {
            currentFacilities = FacilityStoreObject.facilities.filter((facility: Facility) => {
                return facility.facId === id;
            });
        } else {
            currentFacilities = QcdrSettingsStoreObject.getFacIdsForTin(id);
        }

        return parseMetricsResults(this.scorecardOrgMetrics, currentFacilities, [], false) || [];
    });

    @computed
    get ageGroupData() {
        return calculateAgeGroups(this.scorecardOrgSamples);
    }

    @computed
    get asaCountData() {
        return calculateASACounts(this.scorecardOrgSamples);
    }

    @computed
    get emergentCountData() {
        return calculateEmergentCounts(this.scorecardOrgSamples);
    }

    @computed
    get generGroupData() {
        return calculateGenderGroups(this.scorecardOrgSamples);
    }

    @computed
    get inOutPatientGroupData() {
        return calculateInVsOutPatientGroups(this.scorecardOrgSamples);
    }

    @computed
    get admissionTypeGroupData() {
        return calculateAdmissionTypeGroups(this.scorecardOrgSamples);
    }
}

export const ScorecardStoreObject = new ScorecardStore();
export default createContext(ScorecardStoreObject);
