import React, { useState, useEffect } from 'react';
import styles from './styles.module.scss';
import Select from 'react-select';
import * as utils from 'src/utils';
import { useFormAssetTypes } from 'src/queries/useFormAssetTypes';
import { FormAssetType } from 'common/lib/entity/framework/FormAssetType';

export interface FormAssetTypeDropDownProps {
    onChange: Function;
    selectedValue?: string | number;
}

function FormAssetTypeDropDown(props: FormAssetTypeDropDownProps) {
    const { data: formAssetTypes, isFetching } = useFormAssetTypes();
    const [options, setOptions] = useState([]);
    const [selected, setSelected] = useState(null);

    useEffect(() => {
        if (formAssetTypes) {
            const opts = formAssetTypes.map((assetType: FormAssetType) => ({
                label: assetType.formAssetTypeTitle,
                value: assetType.formAssetTypeId,
            }));
            setOptions(opts);
            setSelected(opts.find((i) => i.value === props.selectedValue) || null);
        }
    }, [formAssetTypes]);

    useEffect(() => {
        const selectedVal = options.find((i) => i.value === props.selectedValue);
        if (selectedVal) {
            setSelected(selectedVal);
        }
    }, [props.selectedValue, options]);

    return (
        <Select
            value={selected}
            onChange={(s: any) => {
                setSelected(s);
                props.onChange(s || null);
            }}
            isLoading={isFetching}
            className={styles.select}
            options={options}
            placeholder="Select Asset Type"
            styles={utils.styleSelectComponent()}
        />
    );
}

export default FormAssetTypeDropDown;
