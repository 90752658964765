import React from 'react';

const SvgSingleManCircle = (props) => (
    <svg width={26} height={26} {...props} viewBox="0 0 26 26">
        <g
            transform="translate(1 1)"
            stroke={props.color || '#FFF'}
            strokeWidth={1.6}
            fill="none"
            fillRule="evenodd"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <circle cx={12} cy={12} r={11.5} />
            <path d="M8.338 6.592a7.062 7.062 0 007.873 1.583" />
            <circle cx={12} cy={8.75} r={4.25} />
            <path d="M18.317 18.5a6.988 6.988 0 00-12.634 0" />
        </g>
    </svg>
);

export default SvgSingleManCircle;
