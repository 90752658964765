import React from 'react';

const SvgDue = (props) => (
    <svg width={17} height={17} {...props} viewBox="0 0 17 17">
        <g fill={props.color || '#FFF'} fillRule="nonzero">
            <path d="M5.313 7.438h-.709a.708.708 0 100 1.416h.708a.708.708 0 000-1.416zM8.854 7.438h-.708a.708.708 0 000 1.416h.708a.708.708 0 000-1.416zM12.396 7.438h-.709a.708.708 0 000 1.416h.709a.708.708 0 100-1.416zM5.313 10.27h-.709a.708.708 0 000 1.418h.708a.708.708 0 100-1.417zM8.854 10.27h-.708a.708.708 0 000 1.418h.708a.708.708 0 100-1.417zM12.396 10.27h-.709a.708.708 0 000 1.418h.709a.708.708 0 100-1.417zM5.313 13.104h-.709a.708.708 0 100 1.417h.708a.708.708 0 100-1.417zM8.854 13.104h-.708a.708.708 0 100 1.417h.708a.708.708 0 100-1.417zM12.396 13.104h-.709a.708.708 0 100 1.417h.709a.708.708 0 100-1.417z" />
            <path d="M15.23 2.125H13.28a.177.177 0 01-.177-.177V.708a.708.708 0 00-1.416 0v3.365a.531.531 0 11-1.063 0V2.479a.354.354 0 00-.354-.354H5.844a.177.177 0 01-.177-.176V.709a.708.708 0 00-1.417 0v3.364a.531.531 0 01-1.063 0V2.479a.354.354 0 00-.354-.354H1.771c-.783 0-1.417.634-1.417 1.417v12.041C.354 16.366.988 17 1.771 17h13.458c.783 0 1.417-.634 1.417-1.417V3.542c0-.783-.634-1.417-1.417-1.417zm0 13.104a.354.354 0 01-.355.354H2.125a.354.354 0 01-.354-.354v-8.5c0-.195.158-.354.354-.354h12.75c.196 0 .354.159.354.354v8.5z" />
        </g>
    </svg>
);

export default SvgDue;
