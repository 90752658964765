import React, { forwardRef } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import Input, { InputProps, Label } from '../Input';
import Flex from '../Flex';
import OptionDropDown, { OptionTypes, OptionDropDownProps } from '../OptionDropDown';
import { ANALYTICS_NAMES } from 'src/utils/analytics';

export interface NemsisOptionDropDownProps extends OptionDropDownProps {
    onNaClick?: Function;
    naSelected?: boolean;
    nemsisLabel?: string;
    required?: boolean;
    selectedValue?: any;
    virtualize?: boolean;
    refListFilter?: Function;
    styles?: any;
}

function NemsisOptionDropDown(props: NemsisOptionDropDownProps) {
    function handleNaClick() {
        props.onNaClick && props.onNaClick();
    }
    return (
        <>
            {props.nemsisLabel ? <Label text={props.nemsisLabel} required={props.required} /> : null}

            <Flex direction="row" justify="between" align="center" className={styles.parentWrap}>
                <OptionDropDown
                    className={styles.inputWrap}
                    isDisabled={props.naSelected || props.isDisabled}
                    type={props.type}
                    onChange={(data: any) => props.onChange(data)}
                    selectedValue={props.selectedValue}
                    isMulti={props.isMulti}
                    virtualize={props.virtualize}
                    refListFilter={props.refListFilter}
                    options={props.options}
                    styles={props.styles}
                />
                {props.onNaClick && (
                    <div
                        onClick={handleNaClick}
                        className={classNames(styles.naWrap, { [styles.naActive]: props.naSelected })}
                        data-test-id={ANALYTICS_NAMES.Not_Value_Button}
                    >
                        N/A
                    </div>
                )}
            </Flex>
        </>
    );
}

export default NemsisOptionDropDown;
