import React, { useContext, useState } from 'react';
import Flex from 'src/components/Flex';
import Button from 'src/components/Button';
import styles from './styles.module.scss';
import Icon from 'src/components/Icon';
import OrgStore, { OrganizationOrgDB } from 'src/stores/OrgStore';
import Table from 'src/components/Table';
import { IAgencyContact } from 'common/lib/model/nemsis3_4/demographic';
import * as organizationAPI from 'src/api/organization';
import { ModalStoreObject, ModalTypes } from 'src/stores/ModalStore';
import ReferenceListStore from 'src/stores/ReferenceListStore';
import { OptionTypes } from 'src/components/OptionDropDown';
import { ICONS } from 'src/utils/constants';
import * as variables from 'src/styles/variables';
import NemsisErrorBanner, { NemsisErrorBannerMode } from '../NemsisErrorBanner';
import NemsisStore from 'src/stores/NemsisStore';

/**
 * - Set up table view with some basic data
 * - Set up side modal for edit/create using the nemsis inputs
 */
export function AgencyContacts(props: { onRefresh: Function }) {
    const orgStore = useContext(OrgStore);
    const nemsisStore = useContext(NemsisStore);
    const refListStore = useContext(ReferenceListStore);

    async function handleRemove(contact: IAgencyContact) {
        try {
            let agencyContacts = (orgStore.currentOrgDbOrg || ({} as OrganizationOrgDB)).agencyContacts || [];
            agencyContacts = agencyContacts.filter((c) => c.contactId !== contact.contactId);

            await organizationAPI.updateAgencyData({ agencyContacts });
            await nemsisStore.validateDemData(true);
            props.onRefresh();
        } catch (error) {
            console.log('error', error);
        } finally {
            ModalStoreObject.hideModal();
        }
    }

    async function handleAdd() {
        showModal();
    }

    function handleEdit(contact: IAgencyContact) {
        showModal(contact);
    }

    function showModal(contact: IAgencyContact = null) {
        ModalStoreObject.showModal(ModalTypes.AgencyContactModal, {
            onClose: () => ModalStoreObject.hideModal(),
            onSave: () => props.onRefresh(),
            contact,
        });
    }

    function handleConfirm(contact: IAgencyContact) {
        ModalStoreObject.showModal(ModalTypes.ConfirmationModal, {
            title: `Are you sure you would like to remove this contact?`,
            onConfirm: () => handleRemove(contact),
            onCancel: () => ModalStoreObject.hideModal(),
            confirmButtonText: 'Remove',
        });
    }

    function getContactTypeLabel(contact: IAgencyContact) {
        const refList = refListStore.referenceListValues[OptionTypes.EMSAgencyContactType];

        if (!refList) return null;

        const contactTypeRefValue = refList.find((value) => value.listValueCode === contact.contactTypeCode);

        return contactTypeRefValue ? contactTypeRefValue.listValueDisplayName : null;
    }

    return (
        <div className={styles.tabContent}>
            <NemsisErrorBanner mode={NemsisErrorBannerMode.DEM} />
            <Flex self="stretch" align="center" justify="start">
                <Flex value={1} className={styles.pageTitle}>
                    AGENCY CONTACTS
                </Flex>
                <Button
                    leftIcon={<Icon className={styles.plusIcon} name={ICONS.PlusButton} />}
                    type="primary"
                    text="New Contact"
                    onClick={handleAdd}
                />
            </Flex>

            <Table
                className={styles.tableWrap}
                columns={[
                    {
                        Header: 'Name',
                        accessor: 'firstName',
                        minWidth: 160,
                        Cell: (cellProps) => {
                            const contact = cellProps.original as IAgencyContact;
                            return <span>{[contact.lastName || '', contact.firstName || ''].join(', ')}</span>;
                        },
                    },
                    {
                        Header: 'Email',
                        accessor: 'emailAddresses',
                        minWidth: 250,
                        Cell: (cellProps) => {
                            const contact = cellProps.original as IAgencyContact;
                            // @ts-ignore
                            return <span>{((contact.emailAddresses || [])[0] || {}).emailAddress || '-'}</span>;
                        },
                    },
                    {
                        Header: 'Phone',
                        accessor: 'phoneNumbers',
                        minWidth: 200,
                        Cell: (cellProps) => {
                            const contact = cellProps.original as IAgencyContact;
                            // @ts-ignore
                            return <span>{((contact.phoneNumbers || [])[0] || {}).phoneNumber || '-'}</span>;
                        },
                    },
                    {
                        Header: 'Contact Type',
                        accessor: 'contactTypeCode',
                        minWidth: 250,
                        Cell: (cellProps) => {
                            const contact = cellProps.original as IAgencyContact;
                            return <span>{getContactTypeLabel(contact) || '-'}</span>;
                        },
                    },
                    {
                        Header: 'ACTIONS',
                        accessor: 'actions',
                        minWidth: 125,
                        style: { paddingVertical: 0, paddingHorizontal: 20 },
                        Cell: (cellProps) => {
                            const contact = cellProps.original as IAgencyContact;
                            return (
                                <Flex direction="row">
                                    <Button
                                        onClick={() => {
                                            handleEdit(contact);
                                        }}
                                        type="secondary"
                                        leftIcon={<Icon name={ICONS.Pencil2} color={variables.red} />}
                                    />

                                    <Button
                                        onClick={() => handleConfirm(contact)}
                                        type="secondary"
                                        text="Remove"
                                        className={styles.actionButtons}
                                    />
                                </Flex>
                            );
                        },
                    },
                ]}
                data={orgStore.currentOrgDbOrg.agencyContacts || []}
            />
        </div>
    );
}
