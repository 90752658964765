import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import PaymentsStore, {
    PaymentRequestContactState,
    PaymentRequestState,
    PaymentTransaction,
    PaymentTransactionState,
} from 'src/stores/PaymentsStore';
import styles from './styles.module.scss';

interface PaymentStatusProps {
    type: 'PaymentRequestContact' | 'PaymentRequest' | 'PaymentTransaction';
    status: string | number;
}

function PaymentStatus(props: PaymentStatusProps) {
    const paymentsStore = useContext(PaymentsStore);
    const [status, setStatus] = useState('green');
    const [statusText, setStatusText] = useState(null);

    useEffect(() => {
        retrieveStates();
    }, []);

    useEffect(() => {
        if (paymentsStore.paymentRequestStates && props.type === 'PaymentRequest') {
            const currentState = paymentsStore.paymentRequestStates.find(
                (prs: PaymentRequestState) => prs.paymentRequestStateId.toString() === props.status.toString(),
            );

            if (currentState) {
                setStatusText(currentState.paymentRequestStateTitle);

                switch (currentState.paymentRequestStateCode) {
                    case 'REQUEST_CREATED':
                    case 'REQUEST_PENDING':
                        setStatus('yellow');
                        break;
                    case 'REQUEST_COMPLETED':
                        setStatus('green');
                        break;
                    case 'REQUEST_CANCELLED':
                        setStatus('red');
                        break;
                }
            } else {
                setStatusText(props.status);
            }
        }
    }, [paymentsStore.paymentRequestStates, props.status]);

    useEffect(() => {
        if (paymentsStore.paymentRequestContactStates && props.type === 'PaymentRequestContact') {
            const currentState = paymentsStore.paymentRequestContactStates.find(
                (prcs: PaymentRequestContactState) =>
                    prcs.paymentRequestContactStateId.toString() === props.status.toString(),
            );

            if (currentState) {
                setStatusText(currentState.paymentRequestContactStateTitle);

                switch (currentState.paymentRequestContactStateCode) {
                    case 'CONTACT_CREATED':
                    case 'CONTACT_OPENED':
                        setStatus('yellow');
                        break;
                    case 'CONTACT_SENT':
                        setStatus('green');
                        break;
                    case 'CONTACT_BLOCKED':
                    case 'CONTACT_FAILED':
                    case 'CONTACT_UNSUBSCRIBED':
                    case 'CONTACT_CANCELLED':
                        setStatus('red');
                        break;
                }
            } else {
                setStatusText(props.status);
            }
        }
    }, [paymentsStore.paymentRequestContactStates, props.status]);

    useEffect(() => {
        if (paymentsStore.paymentTransactionStates && props.type === 'PaymentTransaction') {
            const currentState = paymentsStore.paymentTransactionStates.find(
                (pts: PaymentTransactionState) => pts.paymentTransactionStateId.toString() === props.status.toString(),
            );

            if (currentState) {
                setStatusText(currentState.paymentTransactionStateTitle);

                switch (currentState.paymentTransactionStateCode) {
                    case 'TRANSACTION_CREATED':
                    case 'TRANSACTION_STARTED':
                        setStatus('yellow');
                        break;
                    case 'TRANSACTION_COMPLETED':
                    case 'TRANSACTION_COMPLETED_MANUALLY':
                        setStatus('green');
                        break;
                    case 'TRANSACTION_FAILED':
                        setStatus('red');
                        break;
                }
            } else {
                setStatusText(props.status);
            }
        }
    }, [paymentsStore.paymentTransactionStates, props.status]);

    async function retrieveStates() {
        switch (props.type) {
            case 'PaymentRequestContact':
                await paymentsStore.getPaymentRequestContactStates();
                break;
            case 'PaymentRequest':
                await paymentsStore.getPaymentRequestStates();
                break;
            case 'PaymentTransaction':
                await paymentsStore.getPaymentTransactionStates();
                break;
        }
    }

    return (
        <div
            className={classNames(styles.statusBar, {
                [styles.red]: status === 'red',
                [styles.yellow]: status === 'yellow',
                [styles.green]: status === 'green',
            })}
        >
            {statusText}
        </div>
    );
}

export default observer(PaymentStatus);
