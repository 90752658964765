import * as ROUTES from 'src/api/routes';
import request from 'src/api/utils';

export async function loginRequest(userName: string, password: string) {
    return await request({ ...ROUTES.LOGIN, pathParams: { userName }, data: { hash: password } });
}

export async function getUserStartupData(data: { orgId?: string | number; orgNmIntrnl?: string } = {}) {
    return await request({ ...ROUTES.GET_STARTUP_DATA, params: { orgId: data.orgId, orgNmIntrnl: data.orgNmIntrnl } });
}
