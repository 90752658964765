import { useContext, useState, useEffect, useMemo, Dispatch, SetStateAction } from 'react';
import OrgStore from 'src/stores/OrgStore';
import { IAgencyCustomResult } from 'common/lib/model/nemsis3_4/demographic';
import * as organizationAPI from 'src/api/organization';

export function useAgencyCustomResults(): [
    IAgencyCustomResult[],
    Dispatch<SetStateAction<IAgencyCustomResult[]>>,
    Function,
] {
    const orgStore = useContext(OrgStore);
    const [isLoading, setIsLoading] = useState(false);
    const [customResults, setCustomResults] = useState<IAgencyCustomResult[]>(null);

    if (!isLoading) {
        loadData();
    }

    async function loadData() {
        setIsLoading(true);
        await orgStore.getOrgDbOrg();
    }

    async function saveCustomResults() {
        if (customResults) {
            await organizationAPI.updateAgencyData({
                agencyCustomResults: customResults,
            });
        }
    }

    useEffect(() => {
        if (orgStore.currentOrgDbOrg) setCustomResults(orgStore.currentOrgDbOrg.agencyCustomResults);
    }, [orgStore.currentOrgDbOrg]);

    return [customResults, setCustomResults, saveCustomResults];
}
