import React, { useState } from 'react';
import styles from './styles.module.scss';
import Input, { Label } from 'src/components/Input';
import Select from 'react-select';
import { useEffect } from 'react';
import { ReferenceListExtendedProperty } from 'src/stores/ReferenceListStore';
import { REFERENCE_LIST_PROPERTY_TYPES } from 'common/lib/util/constants';
import * as utils from 'src/utils';

interface Props {
    property?: ReferenceListExtendedProperty;
    onChange: Function;
}

const propertyTypeOptions = REFERENCE_LIST_PROPERTY_TYPES.map((type) => ({
    label: type.label,
    value: type.value,
}));

function ReferenceListPropertyModal(props: Props) {
    const initialPropertyType = REFERENCE_LIST_PROPERTY_TYPES.find(
        (type) => type.value === props.property.propertyType,
    );
    const [propertyType, setPropertyType] = useState((initialPropertyType && initialPropertyType.value) || '');
    const [propertyName, setPropertyName] = useState(props.property.propertyName || '');
    const [propertyDisplayName, setPropertyDisplayName] = useState(props.property.propertyDisplayName || '');
    const [propertyDescription, setPropertyDescription] = useState(props.property.propertyDescription || '');
    const [required, setRequired] = useState(props.property.required || false);
    const [active, setActive] = useState(props.property.active || true);

    useEffect(() => {
        props.onChange({
            propertyType,
            propertyName,
            propertyDisplayName,
            propertyDescription,
            required,
            active,
        });
    }, [propertyType, propertyName, propertyDisplayName, propertyDescription, required, active]);

    return (
        <div className={styles.contentWrap}>
            <div className={styles.labelWrap}>
                <Label text="TYPE" />
                <Select
                    value={initialPropertyType}
                    options={propertyTypeOptions}
                    onChange={(data: any) => setPropertyType(data.value)}
                    className={styles.select}
                    styles={utils.styleSelectComponent()}
                />
            </div>
            <Input label="Name" value={propertyName} onChangeText={(value: string) => setPropertyName(value)} />
            <Input
                label="Display Name"
                value={propertyDisplayName}
                onChangeText={(value: string) => setPropertyDisplayName(value)}
            />
            <Input
                label="Description"
                value={propertyDescription}
                onChangeText={(value: string) => setPropertyDescription(value)}
            />
        </div>
    );
}

export default ReferenceListPropertyModal;
