import React, { useContext, useState, useEffect } from 'react';
import styles from './styles.module.scss';
import Flex from 'src/components/Flex';
import Button from 'src/components/Button';
import { ModalStoreObject, ModalTypes } from 'src/stores/ModalStore';
import { observer } from 'mobx-react';
import NemsisStore from 'src/stores/NemsisStore';
import useFlag from 'src/utils/hooks/useFlag';

export enum NemsisErrorBannerMode {
    DEM = 'dem',
    EMS = 'ems',
}

interface NemsisErrorBannerProps {
    mode: NemsisErrorBannerMode;
    encounterFormId?: number;
    padding?: string;
}

function NemsisErrorBanner(props: NemsisErrorBannerProps) {
    const nemsisStore = useContext(NemsisStore);
    const nemsisEnabled = useFlag('nemsis');
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        getInitialData();
    }, [nemsisStore.demValidationData, nemsisStore.emsValidationData]);

    async function getInitialData() {
        if (props.mode === NemsisErrorBannerMode.EMS) {
            await nemsisStore.getEmsValidationData(props.encounterFormId);
        } else if (nemsisEnabled) {
            await nemsisStore.validateDemData();
        }

        const target =
            props.mode === NemsisErrorBannerMode.DEM
                ? nemsisStore.demValidationData
                : nemsisStore.emsValidationData.get(props.encounterFormId);

        setIsVisible(target && target.length > 0);
    }

    function showNemsisErrorsModal() {
        ModalStoreObject.showModal(ModalTypes.NemsisErrorsModal, {
            errors:
                props.mode === NemsisErrorBannerMode.DEM
                    ? nemsisStore.demValidationData
                    : nemsisStore.emsValidationData.get(props.encounterFormId),
        });
    }

    return isVisible ? (
        <Flex
            direction="row"
            self="stretch"
            align="center"
            justify="start"
            className={styles.wrap}
            style={{ padding: props.padding || 0 }}
        >
            <Flex className={styles.pageTitle}>NEMSIS Validation Errors detected.</Flex>
            <Flex value={1} justify="start">
                <Button type="transparent" text="View Errors" onClick={showNemsisErrorsModal} />
            </Flex>
        </Flex>
    ) : null;
}

export default observer(NemsisErrorBanner);
