import { Entity, PrimaryGeneratedColumn, Column, OneToMany, OneToOne } from 'typeorm';

export interface SurveyTemplateDefinition {
    fields: Field[];
}

export interface Field {
    id: string;
    name: string;
    type: string;
    title: string;
    options: FieldOption[];
    active: boolean;
}

export interface FieldOption {
    label: string;
    value: string;
}

export enum SurveyStatus {
    Published = 'PUBLISHED',
    Draft = 'DRAFT',
    Unpublished = 'UNPUBLISHED',
}

@Entity('survey_template', { schema: 'graphium' })
export class SurveyTemplate {
    @PrimaryGeneratedColumn('increment', {
        name: 'survey_template_version_id',
    })
    surveyTemplateVersionId: string;

    @Column('uuid', {
        name: 'survey_template_id',
    })
    surveyTemplateId: string;

    @Column('bigint', {
        name: 'survey_template_version',
    })
    surveyTemplateVersion: number;

    @Column('text', {
        nullable: false,
        name: 'survey_template_name',
    })
    surveyTemplateName: string;

    @Column('text', {
        nullable: true,
        name: 'survey_template_description',
    })
    surveyTemplateDescription?: string;

    @Column('jsonb', {
        nullable: false,
        default: "'{}'::jsonb",
        name: 'survey_template_definition',
    })
    surveyTemplateDefinition: {};

    @Column('jsonb', {
        nullable: false,
        default: "'{}'::jsonb",
        name: 'survey_template_configuration',
    })
    surveyTemplateConfiguration: {};

    @Column('text', {
        nullable: false,
        default: "DRAFT",
        name: 'survey_template_state_code',
    })
    surveyTemplateStateCode: string;

    @Column('text', {
        nullable: false,
        default: "Draft",
        name: 'survey_template_state_description',
    })
    surveyTemplateStateDescription: string;

    @Column('timestamp with time zone', {
        nullable: false,
        default: 'now()',
        name: 'created_at',
    })
    createdAt: Date;

    @Column('timestamp with time zone', {
        nullable: false,
        default: 'now()',
        name: 'updated_at',
    })
    updatedAt: Date;

    @Column('int4', {
        nullable: false,
        default: 1,
        name: 'audit_version',
    })
    auditVersion: number;
}

