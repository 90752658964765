import * as ROUTES from 'src/api/routes';
import request from 'src/api/utils';
import {
    ScheduledJob,
    FlowScript,
    FlowScriptHistory,
    JobSchedule,
    FlowScriptTemplate,
    FlowScriptTemplateHistory,
    FlowScriptType,
} from 'src/utils/types';

export async function getAllUsers(params: object = {}) {
    const response = await request({ ...ROUTES.ADMIN_GET_ALL_USERS, params });
    return {
        result: response.data.result || [],
        count: response.data.count || 0,
        blockedUsers: response.data.blockedUsers || [],
    };
}

export async function isUserBlocked(username: string) {
    const response = await request({ ...ROUTES.ADMIN_GET_USER_BLOCK_STATUS, pathParams: { username } });
    return response?.data?.userBlocks?.blocked_for?.length > 0;
}

export async function unblockUser(username: string) {
    return await request({ ...ROUTES.ADMIN_UNBLOCK_USER, pathParams: { username } });
}

export async function deactivateUser(indexUserId: number) {
    return await request({ ...ROUTES.ADMIN_DEACTIVATE_USER, pathParams: { userId: indexUserId } });
}

export async function reactivateUser(indexUserId: number) {
    return await request({ ...ROUTES.ADMIN_REACTIVATE_USER, pathParams: { userId: indexUserId } });
}

export async function getScheduledJobs(params: object = {}): Promise<{ result: ScheduledJob[]; count: number }> {
    const response = await request({ ...ROUTES.ADMIN_GET_SCHEDULED_JOBS, params });
    return { result: response.data.result || [], count: response.data.count || 0 };
}

export async function getScheduledJob(scheduledJobGuid: ScheduledJob['scheduledJobGuid']): Promise<ScheduledJob> {
    const response = await request({ ...ROUTES.ADMIN_GET_SCHEDULED_JOB, pathParams: { scheduledJobGuid } });
    return response.data;
}

export async function getJobSchedules(): Promise<JobSchedule[]> {
    const response = await request(ROUTES.ADMIN_GET_JOB_SCHEDULES);
    return response.data || [];
}

export async function getFlowScriptTemplates(): Promise<FlowScriptTemplate[]> {
    const response = await request(ROUTES.ADMIN_GET_FLOW_SCRIPT_TEMPLATES);
    return response.data || [];
}

export async function getFlowScriptTemplate(
    flowScriptTemplateGuid: FlowScriptTemplate['flowScriptTemplateGuid'],
): Promise<FlowScriptTemplate> {
    const response = await request({
        ...ROUTES.ADMIN_GET_FLOW_SCRIPT_TEMPLATE,
        pathParams: { flowScriptTemplateGuid },
    });
    return response.data || {};
}

export async function getFlowScriptTypes(): Promise<FlowScriptType[]> {
    const response = await request(ROUTES.ADMIN_GET_FLOW_SCRIPT_TYPES);
    return response.data || [];
}

export async function createScheduledJob(
    data: Partial<ScheduledJob & FlowScript & FlowScriptHistory>,
): Promise<ScheduledJob> {
    const response = await request({ ...ROUTES.ADMIN_CREATE_SCHEDULED_JOB, data });
    return response.data;
}

export async function createFlowScriptTemplate(
    data: Partial<FlowScriptTemplate & FlowScriptTemplateHistory>,
): Promise<FlowScriptTemplate> {
    const response = await request({ ...ROUTES.ADMIN_CREATE_FLOW_SCRIPT_TEMPLATE, data });
    return response.data;
}

export async function publishFlowScriptTemplateVersion(
    flowScriptTemplateGuid: FlowScriptTemplate['flowScriptTemplateGuid'],
    flowScriptTemplateHistoryVersion: FlowScriptTemplateHistory['flowScriptTemplateVersion'],
): Promise<FlowScriptTemplate> {
    const response = await request({
        ...ROUTES.ADMIN_PUBLISH_FLOW_SCRIPT_TEMPLATE,
        pathParams: { flowScriptTemplateGuid, flowScriptTemplateHistoryVersion },
    });
    return response.data;
}

export async function updateFlowScriptTemplateHistory(
    flowScriptTemplateGuid: FlowScriptTemplate['flowScriptTemplateGuid'],
    flowScriptTemplateHistoryId: FlowScriptTemplateHistory['flowScriptTemplateHistoryId'],
    data: Partial<FlowScriptTemplateHistory>,
): Promise<FlowScriptTemplateHistory> {
    const response = await request({
        ...ROUTES.ADMIN_UPDATE_FLOW_SCRIPT_TEMPLATE_HISTORY,
        pathParams: { flowScriptTemplateGuid, flowScriptTemplateHistoryId },
        data,
    });
    return response.data;
}

export async function createNewFlowScriptTemplateHistory(
    flowScriptTemplateGuid: FlowScriptTemplate['flowScriptTemplateGuid'],
): Promise<FlowScriptTemplateHistory> {
    const response = await request({
        ...ROUTES.ADMIN_CREATE_NEW_FLOW_SCRIPT_TEMPLATE_HISTORY,
        pathParams: { flowScriptTemplateGuid },
    });
    return response.data;
}

export async function updateScheduledJob(
    scheduledJobGuid: ScheduledJob['scheduledJobGuid'],
    data: Partial<ScheduledJob>,
) {
    const response = await request({ ...ROUTES.ADMIN_UPDATE_SCHEDULED_JOB, pathParams: { scheduledJobGuid }, data });
    return response.data;
}

export async function updateFlowScript(
    flowScriptGuid: FlowScript['flowScriptGuid'],
    data: Partial<FlowScript & FlowScriptHistory>,
) {
    const response = await request({ ...ROUTES.ADMIN_UPDATE_FLOW_SCRIPT, pathParams: { flowScriptGuid }, data });
    return response.data;
}

export async function updateFlowScriptTemplate(
    flowScriptTemplateGuid: FlowScriptTemplate['flowScriptTemplateGuid'],
    data: Partial<FlowScriptTemplate & FlowScriptTemplateHistory>,
) {
    const response = await request({
        ...ROUTES.ADMIN_UPDATE_FLOW_SCRIPT_TEMPLATE,
        pathParams: { flowScriptTemplateGuid },
        data,
    });
    return response.data;
}
