import React, { useState, useContext, useEffect, ReactNode } from 'react';
import styles from './styles.module.scss';
import SideModal from 'src/components/SideModal';
import Input, { Label } from 'src/components/Input';
import ModalHeader from 'src/components/ModalHeader';
import OptionDropDown, { OptionTypes } from '../OptionDropDown';
import PatientNeedStore, { PatientNeed } from 'src/stores/PatientNeedStore';
import NeedStateDropDown from '../NeedStateDropDown';
import * as utils from 'src/utils';
import ModalFooter from '../ModalFooter';
import { ToastStoreObject } from 'src/stores/ToastStore';

export interface CreateNeedProps {
    onClose: Function;
    onSave?: Function;
    need?: PatientNeed;
    patientId?: number;
    title?: ReactNode;
    facilityId?: number;
}
function CreateNeedModal(props: CreateNeedProps) {
    const patientNeedStore = useContext(PatientNeedStore);
    const defaultNeed: PatientNeed = {
        patientNeedDescription: '',
        patientNeedType: '',
        patientNeedStateId: 1,
    };
    const [need, setNeed] = useState<PatientNeed>(props.need || defaultNeed);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (props.need) {
            setNeed(props.need);
        }
    }, [props.need]);

    async function createNeed() {
        try {
            setIsLoading(true);
            await patientNeedStore.createPatientNeed({ patientId: props.patientId, facId: props.facilityId }, need);
        } catch (error) {
            ToastStoreObject.show(utils.parseError(error));
        } finally {
            setIsLoading(false);
            setNeed(defaultNeed);
            props.onSave();
        }
    }

    async function updateNeed() {
        const fieldsArr = ['patientNeedDescription', 'patientNeedType', 'patientNeedStateId'];
        if (!utils.haveFieldsChanged(props.need, need, fieldsArr)) {
            return;
        }
        const changedData = utils.onlyChangedFields(props.need, need, fieldsArr);
        try {
            setIsLoading(true);
            await patientNeedStore.updatePatientNeed(
                { patientId: props.patientId, facId: props.facilityId },
                need.patientNeedId,
                changedData,
            );
        } finally {
            setIsLoading(false);
            setNeed(defaultNeed);
            props.onSave();
        }
    }

    const updateInput = (param: string) => (t: string) => setNeed({ ...need, [param]: t });
    return (
        <SideModal isOpen={true} onClose={props.onClose}>
            <ModalHeader
                title={props.title ? props.title : props.need ? 'Edit Need' : 'Create a Need'}
                onClose={props.onClose}
            />
            <div className={styles.inviteModalContentWrap}>
                <div>
                    <Label text="Type" />
                    <OptionDropDown
                        facId={props.facilityId}
                        className={styles.dropDown}
                        type={OptionTypes.PatientNeedTypes}
                        onChange={(data: any) => setNeed({ ...need, patientNeedType: data.value })}
                        selectedValue={need.patientNeedType}
                    />
                </div>
                <Input
                    className={styles.input}
                    label="Patient Need Description"
                    value={need.patientNeedDescription}
                    onChangeText={updateInput('patientNeedDescription')}
                />
                <div>
                    <Label text="Status" />
                    <NeedStateDropDown
                        selectedValue={need.patientNeedStateId}
                        onChange={(s: any) => setNeed({ ...need, patientNeedStateId: s.value })}
                    />
                </div>
            </div>
            <ModalFooter
                primaryText={props.need ? 'Save' : 'Create'}
                primaryClick={props.need ? () => updateNeed() : () => createNeed()}
                secondaryClick={() => {
                    setNeed(defaultNeed);
                    props.onClose();
                }}
                isLoading={isLoading}
            />
        </SideModal>
    );
}

export default CreateNeedModal;
