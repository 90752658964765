import axios, { AxiosRequestConfig } from 'axios';
import { UserStoreObject } from 'src/stores/UserStore';
import { AuthStoreObject } from 'src/stores/AuthStore';

let emrServicesBaseUrl = 'http://localhost:3001/';
if (import.meta.env.VITE_APP_ENV === 'dev') {
    emrServicesBaseUrl = 'https://emr-services-dev.dev.graphiumemr.com/api/';
} else if (import.meta.env.VITE_APP_ENV === 'pro') {
    emrServicesBaseUrl = 'https://emr-services-pro.graphiumemr.com/api/';
}

const DEFAULT_HEADERS = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'App-Name': import.meta.env.VITE_APP_NAME,
    'App-Version': import.meta.env.PACKAGE_VERSION,
    'App-Origin': window.location.host,
};
type ParamObject = { [key: string]: any };

function replaceUrlParam(url: string, pathParams?: ParamObject): string {
    if (url.includes('{') && url.includes('}') && pathParams) {
        let tempUrl: string = url;

        for (const [key, value] of Object.entries(pathParams)) {
            // Match the route/{routeId}
            let param: string = (tempUrl.match(/\{[A-Za-z0-9]*\}/) || [])[0];
            // Remove the brackets from the matching result
            if (param) {
                param = param.replace(/[\{\}]/g, '');
                if (typeof value !== 'undefined') {
                    tempUrl = tempUrl.replace(`{${param}}`, value);
                }
            }
        }

        return tempUrl;
    }
    return url;
}

function buildParams(options: RequestObj) {
    if (options.anonymous) {
        return options.params;
    }

    return Object.assign({}, options.params, {
        un: UserStoreObject.usrNm,
        org: UserStoreObject.selectedOrg.orgNmIntrnl,
    });
}

export interface RequestObj {
    method: AxiosRequestConfig['method'];
    url: AxiosRequestConfig['url'];
    params?: ParamObject;
    pathParams?: ParamObject;
    data?: AxiosRequestConfig['data'];
    headers?: AxiosRequestConfig['headers'];
    anonymous?: boolean;
    external?: boolean;
}
export default function request(options: RequestObj) {
    const url = replaceUrlParam(options.url, options.pathParams);
    const authorizationHeaders = { Authorization: 'bearer ' + AuthStoreObject.getAccessToken() };
    const headers = !options.anonymous
        ? { ...DEFAULT_HEADERS, ...authorizationHeaders }
        : !options.external
        ? { ...DEFAULT_HEADERS }
        : {};
    const newObj: AxiosRequestConfig = {
        headers: options.headers ? { ...headers, ...options.headers } : headers,
        params: buildParams(options),
        method: options.method,
        url,
        baseURL: emrServicesBaseUrl,
    };
    if (options.method !== 'get') {
        newObj.data = options.data;
    }

    return axios(newObj);
}
