import React, { useContext, useEffect, useState } from 'react';
import styles from './styles.module.scss';
import Input, { Label } from 'src/components/Input';
import { ANALYTICS_NAMES } from 'src/utils/analytics';
import SideModal from '../SideModal';
import ModalHeader from '../ModalHeader';
import ModalFooter from '../ModalFooter';
import { ToastStoreObject } from 'src/stores/ToastStore';
import { parseError, styleSelectComponent } from 'src/utils';
import { Customer, CustomerDocument } from 'common/lib/entity/index/Customer';
import CustomerStore from 'src/stores/CustomerStore';
import Select from 'react-select';
import DatePicker from 'src/components/DatePicker';
import { format } from 'date-fns';

interface CustomerDocumentModalProps {
    customer: Customer;
    document?: Partial<CustomerDocument>;
    mode?: string;
    onClose?: Function;
    onSave?: Function;
}

function CustomerDocumentModal(props: CustomerDocumentModalProps) {
    const typeOptions = [
        {
            value: 'BAA',
            label: 'BAA',
        },
        {
            value: 'QCDR',
            label: 'QCDR',
        },
        {
            value: 'MSA',
            label: 'MSA',
        },
        {
            value: 'ORDER_FORM',
            label: 'Order Form',
        },
        {
            value: 'NDA',
            label: 'NDA',
        },
    ];

    const statusOptions = [
        {
            value: 'PENDING',
            label: 'Pending',
        },
        {
            value: 'COMPLETE',
            label: 'Complete',
        },
    ];

    const customerStore = useContext(CustomerStore);
    const [document, setDocument] = useState(props.document || {});
    const [selectedType, setSelectedType] = useState(
        typeOptions.find((option: any) => option.value === props.document?.documentType) || null,
    );
    const [selectedStatus, setSelectedStatus] = useState(
        statusOptions.find((option: any) => option.value === props.document?.documentStatus) || null,
    );
    const [documentTitle, setDocumentTitle] = useState(props.document?.documentTitle || '');
    const [documentUrl, setDocumentUrl] = useState(props.document?.documentUrl || '');
    const [dateCompleted, setDateCompleted] = useState(props.document?.dateCompleted || null);
    const [orgInternalName, setOrgInternalName] = useState(props.document?.orgInternalName || '');
    const [facilityId, setFacilityId] = useState(props.document?.facilityId || '');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setDocument(
            Object.assign(document, {
                documentType: selectedType?.value,
                documentStatus: selectedStatus?.value,
                documentTitle,
                documentUrl,
                dateCompleted,
                orgInternalName,
                facilityId,
            }),
        );
    }, [selectedType, selectedStatus, documentTitle, documentUrl, dateCompleted, orgInternalName, facilityId]);

    async function handleSave() {
        setIsLoading(true);

        if (!document) {
            return;
        }

        try {
            if (props.mode === 'edit') {
                await customerStore.updateCustomerDocument(
                    props.customer.customerId,
                    props.document.documentGuid,
                    document,
                );
            } else {
                await customerStore.addCustomerDocument(props.customer.customerId, document);
            }
        } catch (e) {
            ToastStoreObject.show(parseError(e));
        } finally {
            if (props.onSave) {
                props.onSave();
            }
            if (props.onClose) {
                props.onClose();
            }
            setIsLoading(false);
        }
    }

    return (
        <SideModal isOpen={true} onClose={props.onClose}>
            <ModalHeader
                title={`${props.mode === 'edit' ? `Edit Document` : 'New Document'}`}
                onClose={() => props.onClose()}
            />
            <div className={styles.contentWrap}>
                <>
                    <Label text="Type" />
                    <Select
                        value={selectedType}
                        onChange={(s: any) => {
                            setSelectedType(s);
                        }}
                        isLoading={isLoading}
                        className={styles.select}
                        options={typeOptions}
                        placeholder="Select Type"
                        styles={styleSelectComponent()}
                        data-test-id={ANALYTICS_NAMES.CustomerDocumentModal_Type}
                    />
                </>
                <Input
                    label="Title"
                    data-test-id={ANALYTICS_NAMES.CustomerDocumentModal_Title}
                    value={documentTitle}
                    onChangeText={(value: string) => setDocumentTitle(value)}
                />
                <Input
                    label="URL"
                    data-test-id={ANALYTICS_NAMES.CustomerDocumentModal_Url}
                    value={documentUrl}
                    onChangeText={(value: string) => setDocumentUrl(value)}
                />
                <>
                    <Label text="Status" />
                    <Select
                        value={selectedStatus}
                        onChange={(s: any) => {
                            setSelectedStatus(s);
                        }}
                        isLoading={isLoading}
                        className={styles.select}
                        options={statusOptions}
                        placeholder="Select Status"
                        styles={styleSelectComponent()}
                        data-test-id={ANALYTICS_NAMES.CustomerDocumentModal_Status}
                    />
                </>
                <>
                    <Label text="Date Completed" />
                    <DatePicker
                        format="yyyy-MM-dd"
                        value={dateCompleted}
                        onChange={(v: any) => setDateCompleted(format(v, 'yyyy-MM-dd'))}
                        data-test-id={ANALYTICS_NAMES.CustomerDocumentModal_DateCompleted}
                    />
                </>
                <>
                    <Label text="Facility" />
                    <Select
                        value={customerStore.customerFacilityOptions.find(
                            (option: any) => option.value === `${orgInternalName}-${facilityId}`,
                        )}
                        onChange={(s: any) => {
                            const splitValue = s.value.split('-');
                            setOrgInternalName(splitValue[0]);
                            setFacilityId(splitValue[1]);
                        }}
                        isLoading={isLoading}
                        className={styles.select}
                        options={customerStore.customerFacilityOptions}
                        placeholder="Select Facility"
                        styles={styleSelectComponent()}
                        data-test-id={ANALYTICS_NAMES.CustomerDocumentModal_Facility}
                    />
                </>
            </div>
            <ModalFooter
                primaryText={props.mode === 'edit' ? 'Save' : 'Create'}
                primaryClick={handleSave}
                secondaryClick={() => props.onClose()}
                isLoading={isLoading}
            />
        </SideModal>
    );
}

export default CustomerDocumentModal;
