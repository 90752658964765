import React, { useState, useEffect, useRef } from 'react';
import Flex from 'src/components/Flex';
import Button from 'src/components/Button';
import { format } from 'date-fns';
import { API_DATE_FORMAT } from 'src/utils/constants';
import styles from '../../styles.module.scss';
import Input from 'src/components/Input';
import { NaValues } from 'common/lib/model/nemsis3_4/demographic';
import { ANALYTICS_NAMES } from 'src/utils/analytics';

interface DateTimeProps {
    input: any;
    isValid: boolean;
    onChange?: Function;
    onNaChange?: Function;
    dateValue?: any;
    timeValue?: string;
    notValue?: string;
    enableNotValues?: boolean;
}

const TIME_FORMAT = 'HH:mm:ss';
const TIME_REGEX = /^(?:[01]\d|2[0123]):(?:[012345]\d):(?:[012345]\d)$/;
const DATE_REGEX = /^\d{4}\-(0?[1-9]|1[012])\-(0?[1-9]|[12][0-9]|3[01])$/;

function DateTime(props: DateTimeProps) {
    const firstValueRender = useRef(true);
    const firstNotValueRender = useRef(true);
    const [dateState, setDateState] = useState(props.dateValue || null);
    const [timeState, setTimeState] = useState(props.timeValue || '');
    const [notValue, setNotValue] = useState(props.notValue || '');

    useEffect(() => {
        if (
            dateState &&
            timeState &&
            dateState.match(DATE_REGEX) &&
            timeState.match(TIME_REGEX) &&
            !firstValueRender.current
        ) {
            props.onChange({
                date: dateState,
                time: timeState,
            });
        }
        firstValueRender.current = false;
    }, [dateState, timeState]);

    useEffect(() => {
        if (props.onNaChange && !firstNotValueRender.current) {
            props.onNaChange(notValue);
        }
        firstNotValueRender.current = false;
    }, [notValue]);

    return (
        <div>
            <div className={styles.label}>{props.input.title ? props.input.title[0] : ''}</div>
            <Flex align="center">
                <Input
                    infoState={props.isValid ? null : 'error'}
                    wrapClassName={styles.dateField}
                    value={notValue === NaValues.NOT_APPLICABLE ? notValue : dateState}
                    onChangeText={(value: string) =>
                        setDateState(value.replace(/[^0-9]/g, '').replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3'))
                    }
                    onBlur={() =>
                        dateState && dateState !== '' ? setDateState(format(dateState, API_DATE_FORMAT)) : null
                    }
                    placeholder={API_DATE_FORMAT}
                />
                <Input
                    infoState={props.isValid ? null : 'error'}
                    value={notValue === NaValues.NOT_APPLICABLE ? notValue : timeState}
                    onChangeText={(value: string) => {
                        setTimeState(value.replace(/[^0-9]/g, '').replace(/(\d{2})(\d{2})(\d{2})/, '$1:$2:$3'));
                    }}
                    onBlur={() => {
                        if (!timeState || timeState === '') return;

                        const splitTime = timeState.split(':');
                        const tempDate = new Date().setHours(
                            parseInt(splitTime[0]) || 1,
                            parseInt(splitTime[1]) || 0,
                            parseInt(splitTime[2]) || 0,
                        );
                        setTimeState(format(tempDate, TIME_FORMAT));
                    }}
                    maxLength={8}
                    placeholder="HH:MM:SS"
                />
                {props.enableNotValues ? (
                    <Button
                        text="N/A"
                        type="transparent"
                        data-test-id={ANALYTICS_NAMES.Not_Value_Button_Date_Time}
                        onClick={() => setNotValue(notValue === NaValues.NOT_APPLICABLE ? '' : NaValues.NOT_APPLICABLE)}
                    />
                ) : null}
                <Button
                    text="Now"
                    type="small"
                    onClick={() => {
                        setDateState(format(new Date(), API_DATE_FORMAT));
                        setTimeState(format(new Date(), TIME_FORMAT));
                    }}
                />
            </Flex>
        </div>
    );
}

export default DateTime;
