import { AutomatedDispenseSystem } from 'common/lib/entity/org/AutomatedDispenseSystem';
import React, { useContext, useEffect, useState } from 'react';
import { CellInfo } from 'react-table';
import ActionMenu from 'src/components/ActionMenu';
import Card from 'src/components/Card';
import FacilityDropDown from 'src/components/FacilityDropDown';
import Flex from 'src/components/Flex';
import Input from 'src/components/Input';
import PageContainer from 'src/components/PageContainer';
import PageContent from 'src/components/PageContent';
import Table from 'src/components/Table';
import TableActiveCol from 'src/components/TableActiveCol';
import NavBar from '../NavBar';
import UserStore from 'src/stores/UserStore';
import AutomatedDispenseSystemStore from 'src/stores/AutomatedDispenseSystemStore';
import { ToastStoreObject } from 'src/stores/ToastStore';
import { parseError } from 'src/utils';
import styles from './styles.module.scss';
import { observer } from 'mobx-react';
import useDebouncedCallback from 'src/utils/hooks/useDebouncedCallback';
import { ModalStoreObject, ModalTypes } from 'src/stores/ModalStore';

function AutomatedDispenseSystems() {
    const [isLoading, setIsLoading] = useState(false);
    const [searchFacilityId, setSearchFacilityId] = useState(null);
    const [searchValue, setSearchValue] = useState('');
    const [refreshSearchResults] = useDebouncedCallback(async (query: string, facilityId: number) => {
        setIsLoading(true);
        await getAutomatedDispenseSystems({ query, facilityId }, true);
        setIsLoading(false);
    }, 300);
    const adsStore = useContext(AutomatedDispenseSystemStore);
    const userStore = useContext(UserStore);

    useEffect(() => {
        getAutomatedDispenseSystems({}, true);
    }, []);

    async function getAutomatedDispenseSystems(
        options: { query?: string; facilityId?: number },
        force: boolean = false,
    ) {
        setIsLoading(true);

        try {
            await adsStore.getAutomatedDispenseSystems({ includeInactive: true, ...options }, force);
        } catch (e: any) {
            ToastStoreObject.show(parseError(e));
        } finally {
            setIsLoading(false);
        }
    }

    function handleSearchQueryChange(userInput: string) {
        setSearchValue(userInput);
        refreshSearchResults(userInput, searchFacilityId);
    }

    function handleSearchFacilityChange(facilityId: number) {
        setSearchFacilityId(facilityId);
        refreshSearchResults(searchValue, facilityId);
    }

    function handleActionClick(action: { id: string; label: string }, item: AutomatedDispenseSystem) {
        switch (action.id) {
            case 'edit':
                ModalStoreObject.showModal(ModalTypes.AutomatedDispenseSystemModal, {
                    onClose: () => ModalStoreObject.hideModal(),
                    onSave: () =>
                        getAutomatedDispenseSystems({ query: searchValue, facilityId: searchFacilityId }, true),
                    ads: item,
                    mode: 'edit',
                });
                break;
        }
    }

    const columns = [
        {
            Header: 'CODE',
            accessor: 'systemCode',
        },
        {
            Header: 'NAME',
            accessor: 'displayName',
        },
        {
            Header: 'FACILITY',
            id: 'facility',
            accessor: (row: AutomatedDispenseSystem) => row.facility?.facNm || '',
            minWidth: 100,
            Cell: (props: CellInfo) => <span className={styles.blackText}>{props.value}</span>,
        },
        {
            Header: 'ACTIVE',
            accessor: 'isActive',
            minWidth: 50,
            Cell: (props: CellInfo) => <TableActiveCol isActive={props.value && props.value.toString() === 'true'} />,
        },
        {
            Header: 'ACTIONS',
            accessor: 'actions',
            maxWidth: 125,
            Cell: (props: CellInfo) =>
                userStore.userPermissions.canEdit.automatedDispenseSystems ? (
                    <ActionMenu
                        entityType="automatedDispenseSystem"
                        entity={props.original}
                        onItemClick={(action: { id: string; label: string }) =>
                            handleActionClick(action, props.original)
                        }
                    />
                ) : null,
        },
    ];

    return (
        <PageContainer>
            <NavBar />
            <PageContent>
                <Flex self="stretch" align="center" justify="start">
                    <Flex value={1} className={styles.searchWrap}>
                        <Input
                            className={styles.input}
                            placeholder="Search..."
                            value={searchValue}
                            onChangeText={(userInput: string) => handleSearchQueryChange(userInput)}
                        />
                    </Flex>
                    <Flex value={1}>
                        <FacilityDropDown
                            onChange={(e: any) => handleSearchFacilityChange((e || {}).value)}
                            isMulti={false}
                            allowEmpty={true}
                            isDefaultEmpty={true}
                            placeholder={'Filter by facility'}
                        />
                    </Flex>
                    <Flex value={1}></Flex>
                </Flex>
                <Card className={styles.cardWrap}>
                    <div className={styles.tableTitle}>Automated Dispense Systems</div>
                    <Table isLoading={isLoading} columns={columns} data={adsStore.automatedDispenseSystems} />
                </Card>
            </PageContent>
        </PageContainer>
    );
}

export default observer(AutomatedDispenseSystems);
