import React, { useState, useContext, useEffect } from 'react';
import styles from './styles.module.scss';
import Flex from 'src/components/Flex';
import CenterModal from 'src/components/CenterModal';
import Button from 'src/components/Button';
import ModalHeader from 'src/components/ModalHeader';
import ModalFooter from 'src/components/ModalFooter';
import Checkbox from '../Checkbox';
import PaymentsStore, { PaymentRequest, PaymentRequestContact } from 'src/stores/PaymentsStore';
import { ToastStoreObject } from 'src/stores/ToastStore';
import { parseError } from 'src/utils';
import LoadingIcon from '../LoadingIcon';
import { copyTextToClipboard } from 'src/utils/dependentUtils';
import { ICONS, ROUTES } from 'src/utils/constants';
import Icon from 'src/components/Icon';
import * as variables from 'src/styles/variables';

interface ResendPaymentRequestModalProps {
    onSave: Function;
    onCancel: Function;
    paymentRequestGuid: PaymentRequest['paymentRequestGuid'];
}

const ResendPaymentRequestModal = (props: ResendPaymentRequestModalProps) => {
    const paymentsStore = useContext(PaymentsStore);
    const [isLoading, setIsLoading] = useState(false);
    const [contacts, setContacts] = useState<PaymentRequestContact[]>(null);
    const [selectedContacts, setSelectedContacts] = useState([]);

    useEffect(() => {
        retrievePaymentRequest();
    }, []);

    async function retrievePaymentRequest() {
        setIsLoading(true);
        try {
            const request = await paymentsStore.getPaymentRequest(props.paymentRequestGuid, { includeContacts: true });
            setContacts(request.paymentRequest.contacts);
        } catch (e: any) {
            ToastStoreObject.show(parseError(e));
        } finally {
            setIsLoading(false);
        }
    }

    async function handleSend() {
        setIsLoading(true);
        try {
            await paymentsStore.resendContacts(selectedContacts);
            props.onCancel();
        } catch (e: any) {
            ToastStoreObject.show(parseError(e));
        } finally {
            setIsLoading(false);
        }
    }

    function handleCopy(prc: PaymentRequestContact) {
        copyTextToClipboard(
            `${window.location.origin}${ROUTES.getString(ROUTES.PaymentCollection, prc.paymentRequestContactGuid)}`,
        );
    }

    function getContactInputs() {
        return (
            contacts &&
            contacts.map((prc: PaymentRequestContact, index: number) => (
                <div key={index}>
                    <Flex direction="row" align="center" self="stretch">
                        <Checkbox
                            checked={selectedContacts.includes(prc.paymentRequestContactGuid)}
                            onChange={() => selectContact(prc)}
                        />
                        <div className={styles.contactText}>{prc.contactInfo}</div>
                        <div className={styles.copyButton}>
                            <Icon name={ICONS.Link} onClick={() => handleCopy(prc)} color={variables.blue} />
                        </div>
                    </Flex>
                </div>
            ))
        );
    }

    function selectContact(contact: PaymentRequestContact) {
        if (selectedContacts.includes(contact.paymentRequestContactGuid)) {
            setSelectedContacts([
                ...selectedContacts.filter((guid: string) => guid !== contact.paymentRequestContactGuid),
            ]);
        } else {
            setSelectedContacts([...selectedContacts, contact.paymentRequestContactGuid]);
        }
    }

    return (
        <CenterModal isOpen={true} onClose={props.onCancel}>
            <div>
                <ModalHeader title="Resend Payment Request Link" />
                {isLoading ? (
                    <Flex justify="center">
                        <LoadingIcon />
                    </Flex>
                ) : (
                    <div className={styles.modalContent}>{getContactInputs()}</div>
                )}
                <ModalFooter
                    primaryText="Send"
                    primaryClick={handleSend}
                    secondaryText="Cancel"
                    secondaryClick={props.onCancel}
                    primaryProps={{ disabled: !selectedContacts.length }}
                    isLoading={isLoading}
                />
            </div>
        </CenterModal>
    );
};

export default ResendPaymentRequestModal;
