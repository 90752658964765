import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import { ResponsiveBar } from '@nivo/bar';
import classNames from 'classnames';
import styles from 'src/containers/Reports/Macra/styles.module.scss';
import Flex from 'src/components/Flex';
import LoadingIcon from 'src/components/LoadingIcon';
import Card from 'src/components/Card';
import ScorecardStore from 'src/stores/ScorecardStore';
import { BAR_COLORS } from 'src/containers/Reports/Macra/utils';

function PopulationTab() {
    const scorecardStore = useContext(ScorecardStore);

    function getPopHealthChartLabels(id: string | number) {
        switch (id) {
            case 'emergent':
                return 'Emergent';
            case 'nonEmergent':
                return 'Non-Emergent';
            default:
                return 'Total';
        }
    }

    function getBarChart(data: object[], keys: string[] = ['value']) {
        return (
            <div className={classNames(styles.view)}>
                <ResponsiveBar
                    data={data}
                    indexBy="label"
                    keys={keys}
                    colors={BAR_COLORS}
                    minValue={0}
                    margin={{ top: 50, right: 60, bottom: 50, left: 60 }}
                    padding={0.3}
                    axisTop={null}
                    axisRight={null}
                    axisBottom={{
                        tickSize: 10,
                        tickPadding: 5,
                        tickRotation: 0,
                    }}
                    axisLeft={{
                        tickSize: 10,
                        tickPadding: 5,
                        tickRotation: 0,
                    }}
                    label={(d) => `${d.value}`}
                    labelSkipWidth={12}
                    labelSkipHeight={1}
                    labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                    animate={true}
                    motionStiffness={90}
                    motionDamping={15}
                    tooltip={({ id, value, color, data }) => (
                        <div>
                            {data.label}
                            <br />
                            <strong style={{ color }}>
                                {getPopHealthChartLabels(id)}: {value}
                            </strong>
                        </div>
                    )}
                />
            </div>
        );
    }

    return scorecardStore.isLoading ? (
        <Flex direction="row" align="center" justify="center" className={styles.loading}>
            <LoadingIcon type="dot" />
        </Flex>
    ) : (
        <div className={styles.tabContent}>
            <Flex direction="row">
                <Card className={styles.cardWrapRight}>
                    <Flex value={1} align="center" direction="column">
                        <h3>Age</h3>
                        {getBarChart(scorecardStore.ageGroupData)}
                    </Flex>
                </Card>
                <Card className={styles.cardWrap}>
                    <Flex value={1} align="center" direction="column">
                        <h3>Male vs Female vs Unknown</h3>
                        {getBarChart(scorecardStore.generGroupData)}
                    </Flex>
                </Card>
            </Flex>
            <Flex direction="row">
                <Card className={styles.cardWrapRight}>
                    <Flex value={1} align="center" direction="column">
                        <h3>ASA</h3>
                        {getBarChart(scorecardStore.asaCountData, ['emergent', 'nonEmergent'])}
                    </Flex>
                </Card>
                <Card className={styles.cardWrap}>
                    <Flex value={1} align="center" direction="column">
                        <h3>Emergent vs Non-Emergent</h3>
                        {getBarChart(scorecardStore.emergentCountData)}
                    </Flex>
                </Card>
            </Flex>
            <Flex direction="row">
                <Card className={styles.cardWrapRight}>
                    <Flex value={1} align="center" direction="column">
                        <h3>Inpatient vs Outpatient</h3>
                        {getBarChart(scorecardStore.inOutPatientGroupData)}
                    </Flex>
                </Card>
                <Card className={styles.cardWrap}>
                    <Flex value={1} align="center" direction="column">
                        <h3>PACU vs ICU Disposition</h3>
                        {getBarChart(scorecardStore.admissionTypeGroupData)}
                    </Flex>
                </Card>
            </Flex>
        </div>
    );
}

export default observer(PopulationTab);