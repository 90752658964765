import React, { useState, useContext, ReactNode } from 'react';
import styles from './styles.module.scss';
import { format, parseISO } from 'date-fns';
import SideModal from 'src/components/SideModal';
import Input, { Label } from 'src/components/Input';
import ModalHeader from 'src/components/ModalHeader';
import OptionDropDown, { OptionTypes } from '../OptionDropDown';
import * as utils from 'src/utils';
import ModalFooter from '../ModalFooter';
import { ToastStoreObject } from 'src/stores/ToastStore';
import PatientNoteAttachmentStore, { PatientNote } from 'src/stores/PatientNoteAttachmentStore';
import DatePicker from '../DatePicker';
import Flex from '../Flex';
import TimePicker from '../TimePicker';
import { API_DATE_FORMAT } from 'src/utils/constants';
import classNames from 'classnames';

export interface CreateNoteProps {
    onClose: Function;
    onSave: Function;
    patientId: number;
    title?: ReactNode;
    facilityId: number;
    item?: PatientNote;
}
export function CreateNoteModal(props: CreateNoteProps) {
    const defaultNote = {
        patientNoteText: '',
        patientNoteType: '',
        patientNoteDate: format(new Date(), API_DATE_FORMAT),
        patientNoteTime: format(new Date(), 'HH:mm'),
    };
    const patientNoteAttachmentStore = useContext(PatientNoteAttachmentStore);
    const [note, setNote] = useState<PatientNote>(props.item || defaultNote);
    const [isLoading, setIsLoading] = useState(false);

    async function createNote() {
        try {
            setIsLoading(true);
            if (props.item) {
                await patientNoteAttachmentStore.updatePatientNote(
                    { patientId: props.patientId, facId: props.facilityId },
                    note,
                );
            } else {
                await patientNoteAttachmentStore.createPatientNote(
                    { patientId: props.patientId, facId: props.facilityId },
                    note,
                );
            }
            setNote(defaultNote);
            props.onSave();
        } catch (error) {
            ToastStoreObject.show(utils.parseError(error));
        } finally {
            setIsLoading(false);
        }
    }

    const updateInput = (param: string) => (t: string) => setNote({ ...note, [param]: t });
    return (
        <SideModal isOpen={true} onClose={() => props.onClose()}>
            <ModalHeader title={props.title ? props.title : 'Create a Note'} onClose={() => props.onClose()} />
            <div className={styles.inviteModalContentWrap}>
                <div>
                    <Label text="Type" />
                    <OptionDropDown
                        facId={props.facilityId}
                        className={styles.dropDown}
                        type={OptionTypes.PatientNoteTypes}
                        onChange={(data: any) => setNote({ ...note, patientNoteType: data.value })}
                        selectedValue={note.patientNoteType}
                    />
                </div>
                <Input
                    className={classNames(styles.multiline, styles.noteTextInput)}
                    multiLine={true}
                    autoSize={true}
                    label="Patient Note Text"
                    value={note.patientNoteText}
                    onChangeText={updateInput('patientNoteText')}
                />
                <Label text="Time/Date" />
                <DatePicker
                    value={note.patientNoteDate || note.insDttm}
                    onChange={(date) => {
                        if (date) {
                            const value = format(new Date(date), API_DATE_FORMAT);
                            setNote({ ...note, patientNoteDate: value });
                        }
                    }}
                />
                <Flex value={1}>
                    <TimePicker
                        onChange={(t: string) => setNote({ ...note, patientNoteTime: t })}
                        time={
                            note.patientNoteTime ||
                            (note.insDttm ? format(parseISO(note.insDttm.toString()), 'HH:mm') : '-')
                        }
                        minuteStep={1}
                    />
                </Flex>
            </div>
            <ModalFooter
                primaryText={props.item ? 'Save' : 'Create'}
                primaryClick={createNote}
                secondaryClick={() => {
                    setNote(defaultNote);
                    props.onClose();
                }}
                isLoading={isLoading}
            />
        </SideModal>
    );
}

export default CreateNoteModal;
