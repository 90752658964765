import React, { ReactNode, RefObject } from 'react';
import Portal from '../Portal';
import styles from './styles.module.scss';
import classNames from 'classnames';

export interface TooltipProps {
    isOpen: boolean;
    element: RefObject<HTMLElement>;
    children: ReactNode;
    direction?: 'left' | 'bottom' | 'right' | 'top';
}

// TODO: Pull in a tooltip library for better styling
// https://github.com/littlebits/react-popover
function Tooltip(props: TooltipProps) {
    if (!props.isOpen) {
        return null;
    }
    let style = {};
    if (props.element.current) {
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        const rect = props.element.current.getBoundingClientRect();
        if (!props.direction || props.direction === 'bottom') {
            style = { top: rect.bottom + scrollTop, left: rect.left };
        } else if (props.direction === 'top') {
            style = { top: rect.top + scrollTop, left: rect.left, marginTop: -40 };
        }
    }

    return (
        <Portal>
            <div className={classNames(styles.tooltip)} style={style}>
                {/* <div
                    className={classNames(styles.arrow, {
                        [styles.topArrow]: !props.direction || props.direction === 'bottom',
                        [styles.leftArrow]: props.direction === 'right',
                        [styles.rightArrow]: props.direction === 'left',
                    })}
                /> */}
                {props.children}
            </div>
        </Portal>
    );
}

export default Tooltip;
