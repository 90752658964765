import React, { useState, useEffect, useRef } from 'react';
import Input from 'src/components/Input';
import NemsisInput from 'src/components/NemsisInput';
import { NaValues } from 'common/lib/model/nemsis3_4/demographic';

interface TextInputProps {
    input: any;
    isValid: boolean;
    value?: string;
    notValue?: string;
    enableNotValues?: boolean;
    onChange?: Function;
    onNaChange?: Function;
}

function TextInput(props: TextInputProps) {
    const firstValueRender = useRef(true);
    const firstNotValueRender = useRef(true);
    const [value, setValue] = useState(props.value || '');
    const [notValue, setNotValue] = useState(props.notValue || '');

    useEffect(() => {
        if (props.onChange && !firstValueRender.current) {
            const maxLength = getMaxLength();
            props.onChange(maxLength && value ? value.slice(0, maxLength) : value);
        }
        firstValueRender.current = false;
    }, [value]);

    useEffect(() => {
        if (props.onNaChange && !firstNotValueRender.current) {
            props.onNaChange(notValue);
        }
        firstNotValueRender.current = false;
    }, [notValue]);

    useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    function getMaxLength() {
        const inputMethod = props.input.inputMethod[0].textInput[0].$;
        return inputMethod.maxChar ? inputMethod.maxChar : null;
    }

    const inputProps = {
        value: notValue === NaValues.NOT_APPLICABLE ? notValue : value,
        onChangeText: (updatedValue: string) => setValue(updatedValue),
        maxLength: getMaxLength(),
        ...(props.enableNotValues
            ? {
                  nemsisLabel: props.input.title ? props.input.title[0] : '',
                  onNaClick: () => setNotValue(notValue === NaValues.NOT_APPLICABLE ? '' : NaValues.NOT_APPLICABLE),
              }
            : { label: props.input.title ? props.input.title[0] : '' }),
    };

    return props.enableNotValues ? <NemsisInput {...inputProps} /> : <Input {...inputProps} />;
}

export default TextInput;
