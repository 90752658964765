import React, { useState } from 'react';
import styles from './styles.module.scss';
import { useEffect } from 'react';
import classNames from 'classnames';

export interface ToggleProps {
    toggled?: boolean;
    onChange: Function;
}

function Toggle(props: ToggleProps) {
    const [toggleState, setToggleState] = useState(props.toggled || false);

    useEffect(() => {
        props.onChange(toggleState);
    }, [toggleState]);

    function handleToggle() {
        setToggleState(!toggleState);
    }

    return (
        <div
            className={classNames([styles.container], {
                [styles.toggled]: toggleState,
            })}
            onClick={handleToggle}
        >
            <div
                className={classNames([styles.toggleButton], {
                    [styles.toggled]: toggleState,
                })}
            ></div>
        </div>
    );
}

export default Toggle;
