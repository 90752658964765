import React from 'react';

const SvgCheck = (props) => (
    <svg width={33} height={33} {...props} viewBox="0 0 33 33">
        <g transform="translate(1 1)" fill="none" fillRule="evenodd">
            <circle stroke="#F1F0F0" fill="#F9F9F9" cx={15.5} cy={15.5} r={15.5} />
            <path d="M9 16.867L14.444 22 23 11" stroke="#DA1057" strokeLinecap="round" strokeWidth={2.4} />
        </g>
    </svg>
);

export default SvgCheck;
