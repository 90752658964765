import React, { useState } from 'react';
import styles from './styles.module.scss';
import Table from 'src/components/Table';
import DateComponent from 'src/components/DateComponent';
import { CellInfo } from 'react-table';
import Toggle from 'src/components/Toggle';
import Card from 'src/components/Card';
import FormChart from './FormChart/index';
import { format as formatDateFns, parseISO } from 'date-fns';
import { DISPLAY_DATE_FORMAT, DISPLAY_TIME_FORMAT } from 'src/utils/constants';
import Icon from 'src/components/Icon';
import * as variables from 'src/styles/variables';
import { Patient } from 'src/stores/PatientStore';
import { useEffect } from 'react';
import * as patientService from 'src/api/patients';
import { kToLbs } from 'common/lib/util/ConversionUtil';

const DEFAULT_VITALS_KEYS = [
    {
        id: 'capturePosition',
        name: 'Position',
    },
    {
        id: 'sbp',
        name: 'Systolic',
        color: 'red',
    },
    {
        id: 'dbp',
        name: 'Diastolic',
        color: 'red',
    },
    {
        id: 'hr',
        name: 'HR',
        color: 'blue',
    },
    {
        id: 'rr',
        name: 'RR',
        color: 'blue',
    },
    {
        id: 'o2Source',
        name: 'Source',
    },
    {
        id: 'o2rate',
        name: 'O2%',
        color: 'red',
    },
    {
        id: 'etco2',
        name: 'ET CO2',
        color: 'red',
    },
    {
        id: 'dryWt',
        name: 'Dry Wgt',
        color: 'blue',
    },
    {
        id: 'tempSource',
        name: 'Source',
    },
    {
        id: 'wt',
        name: 'Weight',
        color: 'blue',
    },
    {
        id: 'temp',
        name: 'Temp',
        color: 'red',
    },
    {
        id: 'ht',
        name: 'Height',
        color: 'red',
    },
];

const DEFAULT_LABS_KEYS = [
    {
        id: 'na',
        name: 'Na+',
        color: 'red',
    },
    {
        id: 'k',
        name: 'K+',
        color: 'red',
    },
    {
        id: 'cl',
        name: 'CL',
        color: 'blue',
    },
    {
        id: 'co2',
        name: 'C02',
        color: 'blue',
    },
    {
        id: 'bun',
        name: 'Bun',
        color: 'red',
    },
    {
        id: 'cr',
        name: 'Cr',
        color: 'red',
    },
    {
        id: 'gluc',
        name: 'BGL',
        color: 'blue',
    },
    {
        id: 'ical',
        name: 'ICAL',
        color: 'blue',
    },
    {
        id: 'hct',
        name: 'Hct',
        color: 'red',
    },
    {
        id: 'inr',
        name: 'INR',
        color: 'red',
    },
    {
        id: 'plts',
        name: 'Plt',
        color: 'blue',
    },
    {
        id: 'hgb',
        name: 'HB',
        color: 'blue',
    },
    {
        id: 'wbc',
        name: 'WBC',
        color: 'red',
    },
];

const IGNORED_KEYS = ['captureDate', 'captureTime', 'capturePosition', 'tempSource', 'o2Source'];

interface FormData {
    capturedAt: number;
    entries: FormEntry;
}

interface FormEntry {
    [key: string]: FormEntryData;
}

interface FormEntryData {
    value: any;
    units: string;
    source?: string;
}

interface FormChartsProps {
    mode: string;
    patientId: Patient['patientId'];
    facId: Patient['facId'];
    patient: Patient;
}

interface EncounterData {
    encounterFormId: string;
    encounterId: string;
    facilityId: number;
    facilityName: string;
    sortKey: string;
    totalRows: string;
    vitals?: FormEntry[];
    labs?: FormEntry[];
}

function FormCharts(props: FormChartsProps) {
    const [pageState, setPageState] = useState('table');
    const [formData, setFormData] = useState<any[]>([]);
    const [displayPounds, setDisplayPounds] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (props.mode === 'vitals') {
            getVitals();
        } else {
            getLabs();
        }
    }, [props.mode]);

    async function getLabs() {
        setIsLoading(true);
        const labs = await patientService.getPatientLabs(props.patientId, props.facId);
        setFormData(parseFormData(labs.data || []));
        setIsLoading(false);
    }

    async function getVitals() {
        setIsLoading(true);
        const vitals = await patientService.getPatientVitals(props.patientId, props.facId);
        setFormData(parseFormData(vitals.data || []));
        setIsLoading(false);
    }

    function parseFormData(data: EncounterData[]) {
        // For now, we transform the returned data into the format we used to mock it out.
        // TODO: We should just use the data the way it is returned.
        const flattened = [].concat
            .apply(
                [],
                data.map((encounter: any) => (props.mode === 'vitals' ? encounter.vitals : encounter.labs)),
            )
            .filter((entry) => entry && entry.captureDate && entry.captureTime);
        const parsed = flattened.map((data: any) => {
            return {
                capturedAt: new Date(`${data.captureDate} ${data.captureTime}`).getTime(),
                entries: { ...data },
            };
        });
        return parsed;
    }

    function getHeaders(data: FormData[]) {
        const firstColumn = {
            Header: props.mode.charAt(0).toUpperCase() + props.mode.substring(1),
            accessor: 'name',
            sortable: false,
            maxWidth: 150,
            Cell: (props: CellInfo) => <span className={styles.blackText}>{props.value}</span>,
        };
        const columns = data.map((entry: any, index: number) => {
            return {
                Header: <DateComponent showDateTime={true} date={entry.capturedAt} className={styles.blackText} />,
                maxWidth: 175,
                id: entry.capturedAt,
                sortable: false,
                accessor: entry.capturedAt.toString(),
                Cell: (props: CellInfo) => <span className={styles.tableText}>{props.value}</span>,
            };
        });
        return [firstColumn, ...columns];
    }

    function getTableData(data: FormData[]) {
        const defaultKeys = props.mode === 'vitals' ? DEFAULT_VITALS_KEYS : DEFAULT_LABS_KEYS;
        let currentSource: string;

        // Create an entry for every key
        return defaultKeys.map((key: { id: string; name: string }) => {
            // Create an object for each form
            return Object.assign(
                {},
                { name: key.name },
                ...data.map((entry) => {
                    const entryKey = entry.entries[key.id];
                    const isPounds = (key.id === 'wt' || key.id === 'dryWt') && displayPounds;
                    const actualValue = isPounds ? kToLbs(entryKey && entryKey.value) : entryKey && entryKey.value;
                    let entryValue = entryKey ? `${actualValue} ${isPounds ? 'lb' : entryKey.units}` : '-';

                    // If we are on a source entry, rewrite the value
                    if (key.id.toLowerCase().includes('source')) {
                        entryValue = currentSource;
                    } else {
                        // If the entry has a source, store it
                        currentSource = entryKey && entryKey.source;
                    }

                    const entryIcon = entryKey ? (
                        <Icon name={entryKey.toString()} color={variables.blue} className={styles.positionIcon} />
                    ) : (
                        '-'
                    );
                    // Return the value + unit or '-'
                    return {
                        [entry.capturedAt]: key.id === 'capturePosition' ? entryIcon : entryValue,
                    };
                }),
            );
        });
    }

    function getChartData(key: string, data: FormData[]) {
        const formattedData = data.map((entry: any) => {
            if (IGNORED_KEYS.includes(key)) {
                return;
            }

            const isPounds = (key === 'wt' || key === 'dryWt') && displayPounds;
            const entryValue = entry.entries[key]
                ? entry.entries[key].value === '-'
                    ? null
                    : isPounds
                    ? kToLbs(entry.entries[key].value)
                    : entry.entries[key].value
                : null;

            if (!entryValue || !entry.capturedAt) return { x: null, y: null };

            return {
                x: formatDateFns(new Date(entry.capturedAt), `${DISPLAY_DATE_FORMAT} ${DISPLAY_TIME_FORMAT}`),
                y: entryValue,
            };
        });

        return [
            {
                id: key,
                data: formattedData,
            },
        ];
    }

    function getTable() {
        const sortedData = formData.sort((a: any, b: any) =>
            a.capturedAt < b.capturedAt ? -1 : a.capturedAt > b.capturedAt ? 1 : 0,
        );
        return <Table isLoading={isLoading} columns={getHeaders(sortedData)} data={getTableData(sortedData)} />;
    }

    function getCharts() {
        const chartKeys = props.mode === 'vitals' ? DEFAULT_VITALS_KEYS : DEFAULT_LABS_KEYS;

        return (
            <Card className={styles.cardWrap}>
                <div className={styles.chartWrap}>
                    {!isLoading
                        ? chartKeys.map((key, index) =>
                              !IGNORED_KEYS.includes(key.id) && getChartData(key.id, formData).length ? (
                                  <FormChart
                                      key={index}
                                      name={key.name.toString()}
                                      color={key.color ? key.color : 'red'}
                                      data={getChartData(key.id, formData)}
                                  />
                              ) : null,
                          )
                        : 'Loading...'}
                </div>
            </Card>
        );
    }

    return !isLoading && !formData.length ? (
        <div className={styles.emptyContainer}>{`No ${props.mode} data has been recorded for this patient`}</div>
    ) : (
        <div className={styles.container}>
            <div className={styles.optionsContainer}>
                <div className={styles.toggle}>
                    <div className={styles.toggleLabel}>{`View ${props.mode.charAt(0).toUpperCase() +
                        props.mode.substring(1)} Charts`}</div>
                    <Toggle onChange={(value: boolean) => setPageState(value ? 'charts' : 'table')} />
                </div>
                {props.mode === 'vitals' ? (
                    <div className={styles.toggle}>
                        <div className={styles.toggleLabel}>{`Show Pounds`}</div>
                        <Toggle onChange={(value: boolean) => setDisplayPounds(value)} />
                    </div>
                ) : null}
            </div>
            {pageState === 'table' ? getTable() : getCharts()}
        </div>
    );
}

export default FormCharts;
