import React, { useState, useContext, useEffect } from 'react';
import { format } from 'date-fns';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import styles from 'src/containers/Reports/Macra/styles.module.scss';
import Flex from 'src/components/Flex';
import Button from 'src/components/Button';
import UserStore from 'src/stores/UserStore';
import QcdrSettingsStore from 'src/stores/QcdrSettingsStore';
import FeatureFlagStore from 'src/stores/FeatureFlagStore';
import MacraStore from 'src/stores/MacraStore';
import { ToastStoreObject } from 'src/stores/ToastStore';
import { MacraSignature } from 'common/lib/model/macra/QcdrSettings';
import { DISPLAY_DATE_FORMAT, DISPLAY_TIME_FORMAT, ROUTES } from 'src/utils/constants';
import { ModalStoreObject, ModalTypes } from 'src/stores/ModalStore';
import { MacraAvailableYears } from 'common/lib/model/macra/Macra';

interface MacraTitleBarProps {
    year: MacraAvailableYears;
}

function MacraTitleBar(props: MacraTitleBarProps) {
    const [signature, setSignature] = useState<MacraSignature>(undefined);
    const userStore = useContext(UserStore);
    const qcdrSettingsStore = useContext(QcdrSettingsStore);
    const macraStore = useContext(MacraStore);
    const flagStore = useContext(FeatureFlagStore);
    const history = useHistory();

    // Feature flag for QCDR submission
    const canSubmitToQcdr =
        flagStore.isEnabled(`macra-${props.year}-submit-qcdr`) && props.year === macraStore.currentYear;
    const canSaveQcdr = flagStore.isEnabled(`macra-${props.year}-enable-qcdr`) && props.year === macraStore.currentYear;
    const canExportQcdr = flagStore.isEnabled(`macra-${props.year}-export-qcdr`);
    const canExportAqi = flagStore.isEnabled(`macra-export-aqi`);

    useEffect(() => {
        getMacraSignature();
    }, []);

    async function getMacraSignature() {
        try {
            const sig = await qcdrSettingsStore.getMacraSignature(props.year);
            setSignature(sig);
        } catch (error) {
            ToastStoreObject.show('There was an error loading the MACRA signature.');
        }
    }

    async function getQcdrExport() {
        try {
            const { startDate, endDate, selectedId, currentIdMode, currentYear } = macraStore;
            const { csvUrl } = await qcdrSettingsStore.getQcdrExport({
                startDate,
                endDate,
                facilityId: selectedId,
                currentIdMode,
                year: currentYear,
            });
            const csvData = await qcdrSettingsStore.downloadQcdrExport(csvUrl.replace('localstack', 'localhost'));
            const fileName = csvUrl
                .split('/')
                .pop()
                .split('#')[0]
                .split('?')[0];
            downloadFromBytes(csvData, fileName, 'text/csv');
        } catch (error) {
            ToastStoreObject.show(`There was an error generating your QCDR CSV file. ${error}`);
        }
    }

    function downloadFromBytes(bytes: Uint8Array, fileName: string, type: string) {
        const blob = new Blob([bytes], { type });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
    }

    function onSubmitToQcdrClick() {
        ModalStoreObject.showModal(ModalTypes.SubmitToQcdrModal, {
            year: props.year,
            onClose: () => ModalStoreObject.hideModal(),
            onSave: () => {
                ModalStoreObject.hideModal(),
                    getMacraSignature(),
                    ToastStoreObject.show('Data submission has been requested.');
            },
            title: `Submit to QCDR`,
        });
    }

    function onQcdrSettingsClick() {
        switch (props.year) {
            case 2023:
                history.push(ROUTES.getString(ROUTES.QcdrSettings2023));
                break;
            default:
                history.push(ROUTES.getString(ROUTES.QcdrSettings));
                break;
        }
    }

    function onQcdrExportClick() {
        getQcdrExport();
    }

    function onAqiExportClick() {
        ModalStoreObject.showModal(ModalTypes.MacraAQIExportModal, {
            onClose: () => ModalStoreObject.hideModal(),
            onSave: () => console.log('export'),
        });
    }

    return (
        <Flex direction="column">
            {signature && (
                <Flex direction="row" align="center">
                    Data Submission to CMS (via QCDR) Requested on {format(signature.dateSigned, DISPLAY_DATE_FORMAT)}{' '}
                    at {format(signature.dateSigned, DISPLAY_TIME_FORMAT)} by {signature.orgUserName}
                </Flex>
            )}
            <Flex direction="row" align="center">
                <Flex value={1}>
                    <h1>MACRA {props.year} Compliance</h1>
                </Flex>
                {userStore.userPermissions.canView.qcdrSettings && (
                    <div>
                        {canSaveQcdr && (
                            <Button
                                type="small"
                                className={styles.qcdrSettingsBtn}
                                text="QCDR Settings"
                                onClick={onQcdrSettingsClick}
                                data-test-id="QCDRSettingsButton"
                            />
                        )}
                        {canExportQcdr && (
                            <Button
                                type="small"
                                className={styles.qcdrSettingsBtn}
                                text="QCDR Export"
                                onClick={onQcdrExportClick}
                                data-test-id="QCDRExportButton"
                            />
                        )}
                        {canExportAqi && (
                            <Button
                                type="small"
                                className={styles.qcdrSettingsBtn}
                                text="AQI Export"
                                onClick={onAqiExportClick}
                                data-test-id="AQIExportButton"
                            />
                        )}
                        {canSubmitToQcdr && (
                            <Button
                                type="secondarySmall"
                                className={styles.qcdrSettingsBtn}
                                text="Submit to QCDR"
                                onClick={onSubmitToQcdrClick}
                                data-test-id="QCDRSettingsButton"
                                disabled={signature !== null}
                            />
                        )}
                    </div>
                )}
            </Flex>
        </Flex>
    );
}

export default observer(MacraTitleBar);
