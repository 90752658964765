import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import Input, { InputProps, Label } from '../Input';
import Flex from '../Flex';
import { ANALYTICS_NAMES } from 'src/utils/analytics';

export interface NemsisInputProps extends InputProps {
    onNaClick?: Function;
    naSelected?: boolean;
    nemsisLabel?: string;
    required?: boolean;
}

function NemsisInput({ nemsisLabel, required, naSelected, onNaClick, ...rest }: NemsisInputProps) {
    function handleNaClick() {
        onNaClick && onNaClick();
    }
    return (
        <>
            {nemsisLabel ? <Label text={nemsisLabel} required={required} /> : null}

            <Flex direction="row" justify="between" align="center" className={styles.parentWrap}>
                <Input {...rest} wrapClassName={styles.inputWrap} disabled={naSelected} />
                {onNaClick && (
                    <div
                        onClick={handleNaClick}
                        className={classNames(styles.naWrap, { [styles.naActive]: naSelected })}
                        data-test-id={ANALYTICS_NAMES.Not_Value_Button}
                    >
                        N/A
                    </div>
                )}
            </Flex>
        </>
    );
}

export default NemsisInput;
