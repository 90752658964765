import React, { useState, useEffect, useContext } from 'react';
import styles from './styles.module.scss';
import { observer } from 'mobx-react';
import Flex from 'src/components/Flex';
import PatientStore, { Patient } from 'src/stores/PatientStore';
import Address from 'src/components/Address';
import Input from 'src/components/Input';
import { AccordionItem } from '../Information/index';
import Accordion from 'src/components/Accordion';
import Button from 'src/components/Button';
import QuickAction from 'src/components/QuickAction';
import AddRows from 'src/components/AddRows';
import OptionDropDown, { OptionTypes } from 'src/components/OptionDropDown';
import FacilityStore from 'src/stores/FacilityStore';
import * as validation from 'src/utils/validation';
import * as yup from 'yup';
import useYup from 'src/utils/hooks/useYup';
import { ICONS } from 'src/utils/constants';
import { ANALYTICS_NAMES } from 'src/utils/analytics';
import UserStore from 'src/stores/UserStore';
import ReferenceListItemDisplay from 'src/components/ReferenceListItemDisplay';
import { RefListTypes } from 'src/stores/ReferenceListStore';

const updatePatientValidationScheme = yup.object().shape({
    emergencyContactPhone: validation.phoneValidationYup,
    primaryProviderPhone: validation.phoneValidationYup,
    powerOfAttorneyPhone: validation.phoneValidationYup,
});

export interface ContactInfoProps {
    patient: Patient;
    disableEdit?: boolean;
}
function ContactInfo(props: ContactInfoProps) {
    const [isEditing, setIsEditing] = useState(false);
    const [patient, setPatient] = useState<Patient>({});
    const patientStore = useContext(PatientStore);
    const userStore = useContext(UserStore);
    const facilityStore = useContext(FacilityStore);
    const { errors: updatePatientErrors } = useYup(patient, updatePatientValidationScheme, {
        validateOnChange: true,
    });
    useEffect(() => {
        resetPatient();
    }, [props.patient]);

    useEffect(() => {
        if (facilityStore.facilities.length === 0) {
            facilityStore.getFacilities();
        }
    }, []);

    function resetPatient() {
        setPatient({
            facId: props.patient.facId || null,
            patientAddresses: props.patient.patientAddresses || [],
            patientEmails: props.patient.patientEmails || [],
            patientPhones: props.patient.patientPhones || [],
            primaryProviderName: props.patient.primaryProviderName || '',
            primaryProviderAddress: props.patient.primaryProviderAddress || '',
            primaryProviderSpecialty: props.patient.primaryProviderSpecialty || '',
            primaryProviderPhone: props.patient.primaryProviderPhone || '',
            additionalProvidersNote: props.patient.additionalProvidersNote || '',
            powerOfAttorneyName: props.patient.powerOfAttorneyName || '',
            powerOfAttorneyPhone: props.patient.powerOfAttorneyPhone || '',
            emergencyContactName: props.patient.emergencyContactName || '',
            emergencyContactPhone: props.patient.emergencyContactPhone || '',
            emergencyContactRelationshipCode: props.patient.emergencyContactRelationshipCode || '',
        });
    }

    const updateInput = (param: string) => (t: string) => setPatient({ ...patient, [param]: t });

    return (
        <Accordion
            label="Contact Information"
            isEditing={isEditing}
            rightAction={
                isEditing ? (
                    <Flex direction="row">
                        <Button
                            onClick={() => {
                                setIsEditing(false);
                                resetPatient();
                            }}
                            className={styles.cancelBtn}
                            text="Cancel"
                            data-test-id={ANALYTICS_NAMES.PatientDetails_ContactInfo_Cancel}
                        />
                        <Button
                            onClick={async () => {
                                setIsEditing(false);
                                await patientStore.updatePatient(props.patient.patientId, patient);
                                await patientStore.getActivePatient(props.patient.patientId);
                            }}
                            className={styles.saveBtn}
                            text="Save"
                            data-test-id={ANALYTICS_NAMES.PatientDetails_ContactInfo_Save}
                        />
                    </Flex>
                ) : props.disableEdit ? null : (
                    <QuickAction
                        className={styles.editIcon}
                        iconName={ICONS.Edit}
                        onClick={() => {
                            setIsEditing(true);
                        }}
                        isLocked={!userStore.userPermissions.canEdit.patientDetails}
                        data-test-id={ANALYTICS_NAMES.PatientDetails_ContactInfo_Edit}
                    />
                )
            }
            bottomAction={
                isEditing ? (
                    <Flex direction="row" align="center" justify="end" value={1}>
                        <Button
                            onClick={() => {
                                setIsEditing(false);
                                resetPatient();
                            }}
                            className={styles.cancelBtn}
                            text="Cancel"
                            data-test-id={ANALYTICS_NAMES.PatientDetails_ContactInfo_Cancel}
                        />
                        <Button
                            onClick={async () => {
                                setIsEditing(false);
                                await patientStore.updatePatient(props.patient.patientId, patient);
                                await patientStore.getActivePatient(props.patient.patientId);
                            }}
                            className={styles.saveBtn}
                            text="Save"
                            data-test-id={ANALYTICS_NAMES.PatientDetails_ContactInfo_Save}
                        />
                    </Flex>
                ) : null
            }
        >
            <AccordionItem
                label="Facility ID"
                value={((facilityStore.facilities || []).find((i) => i.facId === patient.facId) || { facNm: '' }).facNm}
            />
            {isEditing ? (
                <AccordionItem
                    label="Address"
                    value={
                        <AddRows
                            facId={patient.facId}
                            type="address"
                            onChange={(addresses: any) => setPatient({ ...patient, patientAddresses: addresses })}
                            addresses={patient.patientAddresses}
                        />
                    }
                />
            ) : (
                (patient.patientAddresses || []).map((e, i) => (
                    <AccordionItem
                        key={`${e.city}${i}`}
                        label={`Address${i === 0 ? '' : ` ${i + 1}`}`}
                        value={<Address address={e} />}
                    />
                ))
            )}
            {isEditing ? (
                <AccordionItem
                    label="Email"
                    value={
                        <AddRows
                            type="email"
                            onChange={(emails: any) => setPatient({ ...patient, patientEmails: emails })}
                            emails={patient.patientEmails}
                        />
                    }
                />
            ) : (
                (patient.patientEmails || []).map((e, i) => (
                    <AccordionItem
                        key={`${e.email}${i}`}
                        label={`Email${i === 0 ? '' : ` ${i + 1}`}`}
                        value={
                            <Flex>
                                {e.type}: {e.email}
                            </Flex>
                        }
                    />
                ))
            )}
            {isEditing ? (
                <AccordionItem
                    label="Phone Number"
                    value={
                        <AddRows
                            type="phone"
                            onChange={(phones: any) => setPatient({ ...patient, patientPhones: phones })}
                            phoneNumbers={patient.patientPhones}
                        />
                    }
                />
            ) : (
                (patient.patientPhones || []).map((p, i) => (
                    <AccordionItem
                        key={`${p.phone}${i}`}
                        label={`Phone Number${i === 0 ? '' : ` ${i + 1}`}`}
                        value={
                            <Flex>
                                {p.type}: {p.phone}
                            </Flex>
                        }
                    />
                ))
            )}
            <AccordionItem
                label="Emergency Contact Name"
                value={
                    isEditing ? (
                        <Input
                            value={patient.emergencyContactName}
                            onChangeText={updateInput('emergencyContactName')}
                        />
                    ) : (
                        patient.emergencyContactName
                    )
                }
            />
            <AccordionItem
                label="Emergency Contact Relationship"
                value={
                    isEditing ? (
                        <OptionDropDown
                            facId={patient.facId}
                            type={OptionTypes.PatientContactRelationship}
                            onChange={(data: any) =>
                                setPatient({ ...patient, emergencyContactRelationshipCode: data.value })
                            }
                            selectedValue={patient.emergencyContactRelationshipCode}
                        />
                    ) : (
                        <ReferenceListItemDisplay
                            type={RefListTypes.PatientContactRelationship}
                            value={patient.emergencyContactRelationshipCode}
                        />
                    )
                }
            />
            <AccordionItem
                label="Emergency Contact Phone"
                value={
                    isEditing ? (
                        <Input
                            value={patient.emergencyContactPhone}
                            onChangeText={updateInput('emergencyContactPhone')}
                            errorMessage={patient.emergencyContactPhone && updatePatientErrors.emergencyContactPhone}
                        />
                    ) : (
                        patient.emergencyContactPhone
                    )
                }
            />
            <AccordionItem
                label="Primary Provider Name"
                value={
                    isEditing ? (
                        <Input value={patient.primaryProviderName} onChangeText={updateInput('primaryProviderName')} />
                    ) : (
                        patient.primaryProviderName
                    )
                }
            />
            <AccordionItem
                label="Primary Provider Address"
                value={
                    isEditing ? (
                        <Input
                            value={patient.primaryProviderAddress}
                            onChangeText={updateInput('primaryProviderAddress')}
                        />
                    ) : (
                        patient.primaryProviderAddress
                    )
                }
            />
            <AccordionItem
                label="Primary Provider Specialty"
                value={
                    isEditing ? (
                        <Input
                            value={patient.primaryProviderSpecialty}
                            onChangeText={updateInput('primaryProviderSpecialty')}
                        />
                    ) : (
                        patient.primaryProviderSpecialty
                    )
                }
            />
            <AccordionItem
                label="Primary Provider Phone Number"
                value={
                    isEditing ? (
                        <Input
                            value={patient.primaryProviderPhone}
                            onChangeText={updateInput('primaryProviderPhone')}
                            errorMessage={patient.primaryProviderPhone && updatePatientErrors.primaryProviderPhone}
                        />
                    ) : (
                        patient.primaryProviderPhone
                    )
                }
            />
            <AccordionItem
                label="Additional Provider's Note"
                value={
                    isEditing ? (
                        <Input
                            value={patient.additionalProvidersNote}
                            onChangeText={updateInput('additionalProvidersNote')}
                        />
                    ) : (
                        patient.additionalProvidersNote
                    )
                }
            />
            <AccordionItem
                label="Power of Attorney Name"
                value={
                    isEditing ? (
                        <Input value={patient.powerOfAttorneyName} onChangeText={updateInput('powerOfAttorneyName')} />
                    ) : (
                        patient.powerOfAttorneyName
                    )
                }
            />
            <AccordionItem
                label="Power of Attorney Phone"
                value={
                    isEditing ? (
                        <Input
                            value={patient.powerOfAttorneyPhone}
                            onChangeText={updateInput('powerOfAttorneyPhone')}
                            errorMessage={patient.powerOfAttorneyPhone && updatePatientErrors.powerOfAttorneyPhone}
                        />
                    ) : (
                        patient.powerOfAttorneyPhone
                    )
                }
            />
        </Accordion>
    );
}

export default observer(ContactInfo);
