const SYMBOLS: string = `~!@#$%^&*()-_=+[{]}\\|;:'\",<.>/?`;

function containsSymbol(password: string): string {
  const listOfSymbols: string[] = SYMBOLS.split("");
  return listOfSymbols.find(symbol => password.indexOf(symbol) > -1);
}

function containsNumber(password: string): boolean {
  const match: string[] = password.match(/\d/);
  return match && match.length > 0;
}

function containsUppercase(password: string): boolean {
  const match: string[] = password.match(/[A-Z]/);
  return match && match.length > 0;
}

function containsLowercase(password: string): boolean {
  const match: string[] = password.match(/[a-z]/);
  return match && match.length > 0;
}

export function validatePassword(password: string): boolean {
  if (!password || password === "" || password.indexOf(" ") > -1) {
    return false;
  }

  if (
    password.length < 10 ||
    !containsSymbol(password) ||
    !containsNumber(password) ||
    !containsUppercase(password) ||
    !containsLowercase(password)
  ) {
    return false;
  }

  return true;
}

export function showPasswordError(password: string) {
  if(!password){
    return;
  }

  let error = "";
  if (password.length < 10) {
    error = "Password must contain at least 10 characters";
  }
  else if(!containsUppercase(password)) {
    error = "Password must contain at least 1 uppder case letter";
  }
  else if(!containsLowercase(password)) {
    error = "Password must contain at least 1 lower case letter";
  }
  else if(!containsNumber(password)) {
    error = "Password must contain at least 1 number";
  }
  else if(!containsSymbol(password)) {
    error = "Password must contain a symbol";
  }
  return error;
}

export function showPasswordConfirmError(password: string, confirmPassword: string) {
  if(!confirmPassword) {
    return;
  }

  return confirmPassword != password ? "Passwords don't match" : "";
}