import { action, observable, makeObservable } from 'mobx';
import { createContext } from 'react';
import * as patientsAPI from 'src/api/patients';
import { Patient } from './PatientStore';

export interface PatientMedication {
    patientMedicationId?: number;
    patientId?: number;
    medicationName?: string;
    medicationBrandName?: string;
    medicationDoseAmount?: string;
    medicationDoseUnits?: string;
    medicationAdminRoute?: string;
    medicationFrequency?: string;
    directedUsageType?: string;
    medicationInstructions?: string;
    medicationTotalQty?: number;
    medicationStartDate?: Date;
    medicationEndDate?: Date;
    medicationPurpose?: string;
    prescribedBy?: string;
    opioidIndicator?: boolean;
    prescriptionDate?: Date;
    insDttm?: Date;
    updDttm?: Date;
    audVer?: number;
}

class PatientMedicationStore {
    @observable medications: PatientMedication[] = [];

    constructor() {
        makeObservable(this);
    }

    @action
    reset() {
        this.medications = [];
    }

    @action
    async getPatientMedications(patientId: number, params: {} = {}) {
        const medications = await patientsAPI.getPatientMedications(patientId, params);
        this.setPatientMedications(medications.data || []);
    }

    @action
    setPatientMedications(medications: PatientMedication[]) {
        this.medications = medications;
    }

    @action
    async createPatientMedication(patient: Patient, medication: PatientMedication) {
        await patientsAPI.createPatientMedication(patient, medication);
        this.getPatientMedications(patient.patientId);
    }

    @action
    async updatePatientMedication(patient: Patient, patientMedicationId: number, medication: PatientMedication) {
        await patientsAPI.updatePatientMedication(patient, patientMedicationId, medication);
        this.getPatientMedications(patient.patientId);
    }
}

// Import this to any other store that needs to use a value from it
export const PatientMedicationStoreObject = new PatientMedicationStore();

export default createContext(PatientMedicationStoreObject);
