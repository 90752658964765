import { observable, action, makeObservable } from 'mobx';
import { createContext } from 'react';
import * as eventsAPI from 'src/api/events';
import * as utils from 'src/utils';
import { Event } from 'src/utils/types';

export interface EventState {
    eventStateId?: number;
    eventStateCode?: string;
    eventStateTitle?: string;
    eventStateDescription?: string;
    actvInd?: boolean;
}

export enum EventStateCodes {
    OPEN = 'OPEN',
    CANCELED = 'CANCELED',
    RESCHEDULED = 'RESCHEDULED',
    COMPLETED = 'COMPLETED',
}

export interface EventStateObject {
    [EventStateCodes.OPEN]: EventState;
    [EventStateCodes.CANCELED]: EventState;
    [EventStateCodes.RESCHEDULED]: EventState;
    [EventStateCodes.COMPLETED]: EventState;
    [key: string]: EventState;
}

const defaultEventStatesObject = {
    [EventStateCodes.OPEN]: { eventStateId: 1 },
    [EventStateCodes.CANCELED]: { eventStateId: 2 },
    [EventStateCodes.RESCHEDULED]: { eventStateId: 3 },
    [EventStateCodes.COMPLETED]: { eventStateId: 4 },
};

class EventStateStore {
    @observable eventStates: EventState[] = [];
    @observable activeEventStates: EventState[] = [];
    @observable eventStatesDropdownValues: { value: string | number; label: string }[] = [];
    // Not observable because objects in mobx as observables apply additional wrappers that aren't needed
    eventStatesObject: EventStateObject = defaultEventStatesObject;

    constructor() {
        makeObservable(this);
    }

    @action
    reset() {
        this.eventStates = [];
        this.activeEventStates = [];
        this.eventStatesDropdownValues = [];
    }

    @action
    async getEventStates(force = false) {
        if (!force && this.eventStates.length > 0) {
            return;
        }
        const eventStates = await eventsAPI.fetchEventStates();
        this.setEventStates(eventStates.data || []);
    }

    @action
    getEventState(id: number | EventStateCodes): EventState {
        const value = this.eventStatesObject[id];
        if (!value) {
            return {
                eventStateId: typeof id === 'number' ? id : this.eventStatesObject[id].eventStateId,
                eventStateCode: '',
                eventStateTitle: '',
                eventStateDescription: '',
                actvInd: false,
            };
        }
        return value;
    }

    @action
    setEventStates(eventStates: EventState[]) {
        this.eventStates = eventStates;
        const activeEventStates = eventStates.filter((es) => es.actvInd);
        this.activeEventStates = activeEventStates;
        const esObject = eventStates.reduce((p: any, n) => {
            p[n.eventStateCode] = n;
            p[n.eventStateId] = n;
            return p;
        }, {});
        this.eventStatesObject = {
            ...defaultEventStatesObject,
            ...esObject,
        };
        this.eventStatesDropdownValues = utils.formatSelectOptions(activeEventStates, {
            valueKey: 'eventStateId',
            labelKey: 'eventStateTitle',
        });
    }

    checkState = (ev: Event, code: EventStateCodes) =>
        ev.eventState
            ? ev.eventState.eventStateCode === code
            : ev.eventStateId === this.eventStatesObject[code].eventStateId;

    @action
    isOpen = (ev: Event) => this.checkState(ev, EventStateCodes.OPEN);
    @action
    isCanceled = (ev: Event) => this.checkState(ev, EventStateCodes.CANCELED);
    @action
    isRescheduled = (ev: Event) => this.checkState(ev, EventStateCodes.RESCHEDULED);
    @action
    isCompleted = (ev: Event) => this.checkState(ev, EventStateCodes.COMPLETED);
}

// Import this to any other store that needs to use a value from it
export const EventStateStoreObject = new EventStateStore();

export default createContext(EventStateStoreObject);
