import { AxiosRequestConfig } from 'axios';

interface RouteData {
    method: AxiosRequestConfig['method'];
    url: AxiosRequestConfig['url'];
    anonymous?: boolean;
}
export const GET_INVITATIONS: RouteData = {
    method: 'get',
    url: 'organization/invites',
};
export const GET_FACILITIES: RouteData = {
    method: 'get',
    url: 'facility/facilities',
};
export const GET_FACILITIES_BY_ORG: RouteData = {
    method: 'get',
    url: 'facility/org/{org}',
};
export const GET_FACILITY: RouteData = {
    method: 'get',
    url: 'facility/{facilityId}',
};
export const CREATE_FACILITY: RouteData = {
    method: 'post',
    url: 'facility',
};
export const UPDATE_FACILITY: RouteData = {
    method: 'put',
    url: 'facility/{facilityId}',
};
export const UPDATE_FACILITY_SUBSCRIPTION_DATA: RouteData = {
    method: 'put',
    url: '/facility/subscription-data/{facilityId}',
};
export const DEACTIVATE_FACILITY: RouteData = {
    method: 'delete',
    url: 'facility/{facilityId}',
};
export const GET_ROLES: RouteData = {
    method: 'get',
    url: 'role/roles',
};
export const CREATE_ROLE: RouteData = {
    method: 'post',
    url: 'role',
};
export const UPDATE_ROLE: RouteData = {
    method: 'put',
    url: 'role/{roleId}',
};
export const CREATE_ORGANIZATION: RouteData = {
    method: 'post',
    url: 'organization',
};
export const GET_ALL_ACTIVE_ORGS: RouteData = {
    method: 'get',
    url: 'organization/all',
};
export const GET_ORG_USERS: RouteData = {
    method: 'get',
    url: 'organization/users',
};
export const GET_ORG_USER_BY_USERNAME: RouteData = {
    method: 'get',
    url: 'organization/username/{username}',
};
export const EDIT_ORG_USER: RouteData = {
    method: 'put',
    url: 'organization/users/{userId}',
};
export const DEACTIVATE_ORG_USER: RouteData = {
    method: 'put',
    url: 'organization/users/{userId}/deactivate',
};
export const REACTIVATE_ORG_USER: RouteData = {
    method: 'put',
    url: 'organization/users/{userId}/reactivate',
};
export const GET_ORG_USER_TEAMS: RouteData = {
    method: 'get',
    url: 'organization/users/{userId}/teams/{facilityId}',
};
export const UPDATE_ORG_USER_TEAMS: RouteData = {
    method: 'put',
    url: 'organization/users/{userId}/teams/{facilityId}',
};
export const GET_ORG_USER_FACILITIES: RouteData = {
    method: 'get',
    url: 'organization/users/{userId}/facilities',
};
export const UPDATE_ORG_USER_FACILITIES: RouteData = {
    method: 'put',
    url: 'organization/users/{userId}/facilities',
};
export const SEND_USER_INVITE: RouteData = {
    method: 'post',
    url: 'organization/invites',
};
export const RESEND_USER_INVITE: RouteData = {
    method: 'put',
    url: 'organization/invite/{inviteId}/resend',
};
export const CANCEL_USER_INVITE: RouteData = {
    method: 'delete',
    url: 'organization/invite/{inviteId}',
};
export const GET_PROVIDERS: RouteData = {
    method: 'get',
    url: 'provider',
};
export const CREATE_PROVIDER: RouteData = {
    method: 'post',
    url: 'provider',
};
export const EDIT_PROVIDER: RouteData = {
    method: 'put',
    url: 'provider/{providerId}/update',
};
export const DEACTIVATE_PROVIDER: RouteData = {
    method: 'put',
    url: 'provider/{providerId}/deactivate',
};
export const REACTIVATE_PROVIDER: RouteData = {
    method: 'put',
    url: 'provider/{providerId}/reactivate',
};
export const GET_EVENTS: RouteData = {
    method: 'get',
    url: 'event/events',
};
export const CREATE_EVENT: RouteData = {
    method: 'post',
    url: 'event',
};
export const UPDATE_EVENT: RouteData = {
    method: 'put',
    url: 'event/{eventId}',
};
export const RESCHEDULE_EVENT: RouteData = {
    method: 'put',
    url: 'event/{eventId}/reschedule',
};
export const GET_TEAMS: RouteData = {
    method: 'get',
    url: 'team/teams',
};
export const CREATE_TEAM: RouteData = {
    method: 'post',
    url: 'team',
};
export const UPDATE_TEAM: RouteData = {
    method: 'put',
    url: 'team/{teamId}',
};
export const DEACTIVATE_TEAM: RouteData = {
    method: 'put',
    url: 'team/{teamId}/deactivate',
};
export const REACTIVATE_TEAM: RouteData = {
    method: 'put',
    url: 'team/{teamId}/reactivate',
};
export const GET_TAGS: RouteData = {
    method: 'get',
    url: 'tag/tags',
};
export const CREATE_TAG: RouteData = {
    method: 'post',
    url: 'tag',
};
export const UPDATE_TAG: RouteData = {
    method: 'put',
    url: 'tag/{tagId}',
};
export const DEACTIVATE_TAG: RouteData = {
    method: 'put',
    url: 'tag/{tagId}/deactivate',
};
export const REACTIVATE_TAG: RouteData = {
    method: 'put',
    url: 'tag/{tagId}/reactivate',
};
export const GET_EVENTSTATES: RouteData = {
    method: 'get',
    url: 'event/eventstates',
};
export const REQUEST_PASSWORD_RESET_BY_USERNAME: RouteData = {
    method: 'put',
    url: 'user/name/{username}/password/reset',
    anonymous: true,
};
export const REQUEST_PASSWORD_RESET_BY_USERID: RouteData = {
    method: 'put',
    url: 'user/{userId}/password/reset',
};
export const EXECUTE_PASSWORD_RESET: RouteData = {
    method: 'put',
    url: 'user/name/{username}/password/reset/{resetPasswordKey}',
    anonymous: true,
};
export const GET_PATIENTS: RouteData = {
    method: 'get',
    url: 'patient/patients',
};
export const GET_PATIENT: RouteData = {
    method: 'get',
    url: 'patient/{patientId}',
};
export const CREATE_PATIENT: RouteData = {
    method: 'post',
    url: 'patient',
};
export const UPDATE_PATIENT: RouteData = {
    method: 'put',
    url: 'patient/{patientId}',
};
export const CREATE_REFERENCE_LIST: RouteData = {
    method: 'post',
    url: 'reference-list',
};
export const UPDATE_REFERENCE_LIST: RouteData = {
    method: 'put',
    url: 'reference-list/{referenceListId}',
};
export const DEACTIVATE_REFERENCE_LIST: RouteData = {
    method: 'put',
    url: 'reference-list/{referenceListId}/deactivate',
};
export const REACTIVATE_REFERENCE_LIST: RouteData = {
    method: 'put',
    url: 'reference-list/{referenceListId}/reactivate',
};
export const GET_REFERENCE_LISTS: RouteData = {
    method: 'get',
    url: 'reference-list',
};
export const GET_REFERENCE_LISTS_WITH_VALUES: RouteData = {
    method: 'get',
    url: 'reference-list/values',
};
export const GET_REFERENCE_LIST: RouteData = {
    method: 'get',
    url: 'reference-list/{referenceList}',
};
export const GET_REFERENCE_LIST_VALUES: RouteData = {
    method: 'get',
    url: 'reference-list/{referenceList}/values',
};
export const CREATE_REFERENCE_LIST_VALUE: RouteData = {
    method: 'post',
    url: 'reference-list/{referenceListId}/values',
};
export const UPDATE_REFERENCE_LIST_VALUE: RouteData = {
    method: 'put',
    url: 'reference-list/{referenceListId}/values/{referenceListValueId}',
};
export const DEACTIVATE_REFERENCE_LIST_VALUE: RouteData = {
    method: 'put',
    url: 'reference-list/{referenceListId}/values/{referenceListValueId}/deactivate',
};
export const REACTIVATE_REFERENCE_LIST_VALUE: RouteData = {
    method: 'put',
    url: 'reference-list/{referenceListId}/values/{referenceListValueId}/reactivate',
};
export const UPDATE_REFERENCE_LIST_VALUE_EXCLUDED_FACILITIES: RouteData = {
    method: 'put',
    url: 'reference-list/{referenceListId}/values/{referenceListValueId}/facilities',
};
export const UPDATE_REFERENCE_LIST_PROPERTY: RouteData = {
    method: 'put',
    url: 'reference-list/{referenceListId}/extendedProperty',
};
export const UPDATE_MY_USER: RouteData = {
    method: 'put',
    url: 'user/{userId}/update',
};
export const UPDATE_MY_USER_PASSWORD: RouteData = {
    method: 'put',
    url: 'user/name/{username}/password',
};
export const GET_PATIENT_NEEDS: RouteData = {
    method: 'get',
    url: 'patient/{patientId}/needs',
};
export const CREATE_PATIENT_NEED: RouteData = {
    method: 'post',
    url: 'patient/{patientId}/needs',
};
export const GET_PATIENT_NOTES: RouteData = {
    method: 'get',
    url: 'patient/{patientId}/notes',
};
export const CREATE_PATIENT_NOTE: RouteData = {
    method: 'post',
    url: 'patient/{patientId}/notes',
};
export const UPDATE_PATIENT_NOTE: RouteData = {
    method: 'put',
    url: 'patient/{patientId}/notes/{patientNoteId}',
};
export const DELETE_PATIENT_NOTE: RouteData = {
    method: 'delete',
    url: 'patient/{patientId}/notes/{patientNoteId}',
};
export const GET_PATIENT_ATTACHMENTS: RouteData = {
    method: 'get',
    url: 'patient/{patientId}/attachments',
};
export const CREATE_PATIENT_ATTACHMENT: RouteData = {
    method: 'post',
    url: 'patient/{patientId}/attachments',
};
export const UPDATE_PATIENT_ATTACHMENT: RouteData = {
    method: 'put',
    url: 'patient/{patientId}/attachments/{patientAttachmentId}',
};
export const DELETE_PATIENT_ATTACHMENT: RouteData = {
    method: 'delete',
    url: 'patient/{patientId}/attachments/{patientAttachmentId}',
};
export const GET_NEED_STATES: RouteData = {
    method: 'get',
    url: 'patient/needstates',
};
export const GET_PATIENT_STATES: RouteData = {
    method: 'get',
    url: 'patient/patientstates',
};
export const GET_PATIENT_NEED_STATES: RouteData = {
    method: 'get',
    url: 'patient/needstates',
};
export const UPDATE_PATIENT_NEED: RouteData = {
    method: 'put',
    url: 'patient/{patientId}/needs/{patientNeedId}',
};
export const GET_PATIENT_ACTIVITY: RouteData = {
    method: 'get',
    url: 'patient/{patientId}/activity',
};
export const GET_PATIENT_LABS: RouteData = {
    method: 'get',
    url: 'patient/{patientId}/labs',
};
export const GET_PATIENT_VITALS: RouteData = {
    method: 'get',
    url: 'patient/{patientId}/vitals',
};
export const GET_ORG_NAME_FROM_INVITE: RouteData = {
    method: 'get',
    url: 'organization/invite/key/{inviteKey}/organization/name',
    anonymous: true,
};
export const ACCEPT_INVITE_NEW_USER: RouteData = {
    method: 'post',
    url: 'organization/invite/key/{inviteKey}/accept',
    anonymous: true,
};
export const ACCEPT_INVITE_EXISTING_USER: RouteData = {
    method: 'post',
    url: 'organization/invite/key/{inviteKey}/accept/{userId}',
};
export const GET_PATIENT_ENCOUNTERS: RouteData = {
    method: 'get',
    url: 'encounter/encounters/{patientId}',
};
export const GET_ALL_ENCOUNTERS: RouteData = {
    method: 'get',
    url: 'encounter/encounters',
};
export const GET_ENCOUNTER: RouteData = {
    method: 'get',
    url: 'encounter/{encounterId}',
};
export const CREATE_ENCOUNTER: RouteData = {
    method: 'post',
    url: 'encounter',
};
export const CREATE_ENCOUNTER_WITH_PATIENT: RouteData = {
    method: 'post',
    url: 'encounter/patient',
};
export const UPDATE_ENCOUNTER: RouteData = {
    method: 'put',
    url: 'encounter/{encounterId}',
};
export const GET_ORGS_FOR_USER: RouteData = {
    method: 'get',
    url: 'organization/organizations',
};
export const ADMIN_GET_ALL_USERS: RouteData = {
    method: 'get',
    url: 'admin/users',
};
export const ADMIN_DEACTIVATE_USER: RouteData = {
    method: 'put',
    url: 'admin/users/{userId}/deactivate',
};
export const ADMIN_REACTIVATE_USER: RouteData = {
    method: 'put',
    url: 'admin/users/{userId}/reactivate',
};
export const ADMIN_GET_USER_BLOCK_STATUS: RouteData = {
    method: 'get',
    url: 'admin/users/{username}/block',
};
export const ADMIN_UNBLOCK_USER: RouteData = {
    method: 'put',
    url: 'admin/users/{username}/unblock',
};

export const ADMIN_GET_SCHEDULED_JOBS: RouteData = {
    method: 'get',
    url: 'admin/scheduled-jobs',
};
export const ADMIN_GET_SCHEDULED_JOB: RouteData = {
    method: 'get',
    url: 'admin/scheduled-jobs/{scheduledJobGuid}',
};
export const ADMIN_CREATE_SCHEDULED_JOB: RouteData = {
    method: 'post',
    url: 'admin/scheduled-jobs',
};
export const ADMIN_UPDATE_SCHEDULED_JOB: RouteData = {
    method: 'put',
    url: 'admin/scheduled-jobs/{scheduledJobGuid}',
};
export const ADMIN_UPDATE_FLOW_SCRIPT: RouteData = {
    method: 'put',
    url: 'admin/flow-scripts/{flowScriptGuid}',
};
export const ADMIN_GET_FLOW_SCRIPT_TEMPLATES: RouteData = {
    method: 'get',
    url: '/admin/flow-scripts-templates',
};
export const ADMIN_GET_FLOW_SCRIPT_TEMPLATE: RouteData = {
    method: 'get',
    url: '/admin/flow-scripts-templates/{flowScriptTemplateGuid}',
};
export const ADMIN_GET_FLOW_SCRIPT_TYPES: RouteData = {
    method: 'get',
    url: '/admin/flow-script-types',
};
export const ADMIN_CREATE_FLOW_SCRIPT_TEMPLATE: RouteData = {
    method: 'post',
    url: '/admin/flow-scripts-templates',
};
export const ADMIN_UPDATE_FLOW_SCRIPT_TEMPLATE: RouteData = {
    method: 'post',
    url: '/admin/flow-scripts-templates/{flowScriptTemplateGuid}',
};
export const ADMIN_PUBLISH_FLOW_SCRIPT_TEMPLATE: RouteData = {
    method: 'put',
    url: '/admin/flow-scripts-templates/{flowScriptTemplateGuid}/publish/{flowScriptTemplateHistoryVersion}',
};
export const ADMIN_UPDATE_FLOW_SCRIPT_TEMPLATE_HISTORY: RouteData = {
    method: 'put',
    url: '/admin/flow-scripts-templates/{flowScriptTemplateGuid}/{flowScriptTemplateHistoryId}',
};
export const ADMIN_CREATE_NEW_FLOW_SCRIPT_TEMPLATE_HISTORY: RouteData = {
    method: 'post',
    url: '/admin/flow-scripts-templates/{flowScriptTemplateGuid}/history',
};
export const ADMIN_GET_JOB_SCHEDULES: RouteData = {
    method: 'get',
    url: '/admin/job-schedules',
};
export const ADMIN_GET_SURVEY_TEMPLATES: RouteData = {
    method: 'get',
    url: '/admin/survey-templates',
};
export const GET_PATIENT_MEDICATIONS: RouteData = {
    method: 'get',
    url: 'patient/{patientId}/medications',
};
export const UPDATE_PATIENT_MEDICATION: RouteData = {
    method: 'put',
    url: 'patient/{patientId}/medications/{patientMedicationId}',
};
export const CREATE_PATIENT_MEDICATION: RouteData = {
    method: 'post',
    url: 'patient/{patientId}/medications',
};
export const GET_FORM_DEFINITIONS: RouteData = {
    method: 'get',
    url: 'encounter/form/definitions',
};
export const CREATE_ENCOUNTER_FORM: RouteData = {
    method: 'post',
    url: 'encounter/form',
};
export const UPDATE_ENCOUNTER_FORM: RouteData = {
    method: 'put',
    url: 'encounter/form/{encounterFormId}',
};
export const UPDATE_ENCOUNTER_FORM_MODEL: RouteData = {
    method: 'put',
    url: 'mobile/encounter/form/{encounterFormId}/model',
};
export const CREATE_FORM_PAGE: RouteData = {
    method: 'post',
    url: 'encounter/form/page',
};
export const GET_SIGNED_URL_FOR_ATTACHMENT: RouteData = {
    method: 'get',
    url: 'patient/{patientId}/attachments/upload-url',
};
export const GET_ATTACHMENT_VIEW_URL: RouteData = {
    method: 'get',
    url: 'patient/{patientId}/attachments/view-url',
};
export const GET_ENCOUNTER_FORM_PDF_DATA: RouteData = {
    method: 'get',
    url: 'encounter/form/{encounterFormId}/pdf',
};
export const GET_ENCOUNTER_FORM_PDF_LINK: RouteData = {
    method: 'get',
    url: 'pdf/form/{encounterFormId}/pdfLink',
};
export const GET_ENCOUNTER_FORM_PDF_LINK_LEGACY: RouteData = {
    method: 'get',
    url: 'pdf/form/{encounterFormId}/pdfLinkLegacy',
};
export const GET_ENCOUNTER_FORM_BLOB: RouteData = {
    method: 'get',
    url: 'encounter/form/{encounterFormId}/file/{blobId}',
};
export const GET_PAGE_CONTENT: RouteData = {
    method: 'get',
    url: 'encounter/form/page/{formDefnPageHistId}/pagecontent',
};
export const GET_ENCOUNTER_FORMS: RouteData = {
    method: 'get',
    url: 'encounter/forms',
};
export const GET_BULK_ENCOUNTER_FORMS: RouteData = {
    method: 'get',
    url: 'encounter/forms/all',
};
export const GET_REPORT: RouteData = {
    method: 'get',
    url: 'reports/{reportId}',
};
export const LOGIN: RouteData = {
    method: 'put',
    url: 'user/name/{userName}/authenticate',
    anonymous: true,
};
export const CREATE_TELEHEALTH_SESSION: RouteData = {
    method: 'post',
    url: 'telehealth/sessions',
};
export const CREATE_TELEHEALTH_SESSION_WITH_ENCOUNTER: RouteData = {
    method: 'post',
    url: 'telehealth/encounter/sessions',
};
export const GET_TELEHEALTH_SESSIONS: RouteData = {
    method: 'get',
    url: 'telehealth/sessions/queue',
};
export const GET_TELEHEALTH_ARCHIVE_URL: RouteData = {
    method: 'get',
    url: 'telehealth/sessions/{telehealthSessionId}/archive',
};
export const GET_FILTERED_TELEHEALTH_SESSIONS: RouteData = {
    method: 'get',
    url: 'telehealth/sessions',
};
export const GET_TELEHEALTH_SESSION: RouteData = {
    method: 'get',
    url: 'telehealth/sessions/{telehealthSessionId}',
};
export const GET_TELEHEALTH_SESSION_FOR_PATIENT: RouteData = {
    anonymous: true,
    method: 'get',
    url: 'telehealth/patient-sessions/{telehealthSessionGuid}',
};
export const GET_TELEHEALTH_SESSION_FACILITY_FOR_PATIENT: RouteData = {
    anonymous: true,
    method: 'get',
    url: 'telehealth/patient-sessions/{telehealthSessionGuid}/facility',
};
export const START_TELEHEALTH_SESSION: RouteData = {
    method: 'put',
    url: 'telehealth/sessions/{telehealthSessionId}/connection/join',
};
export const JOIN_TELEHEALTH_SESSION_FOR_PATIENT: RouteData = {
    anonymous: true,
    method: 'put',
    url: 'telehealth/patient-sessions/{telehealthSessionId}/connection/join',
};
export const CANCEL_TELEHEALTH_SESSION: RouteData = {
    method: 'put',
    url: 'telehealth/sessions/{telehealthSessionId}/cancel',
};
export const PING_TELEHEALTH_SESSION: RouteData = {
    method: 'put',
    url: 'telehealth/sessions/{telehealthSessionId}/connection/ping',
};
export const PING_TELEHEALTH_SESSION_FOR_PATIENT: RouteData = {
    anonymous: true,
    method: 'put',
    url: 'telehealth/patient-sessions/{telehealthSessionId}/connection/ping',
};
export const END_TELEHEALTH_SESSION: RouteData = {
    method: 'put',
    url: 'telehealth/sessions/{telehealthSessionId}/connection/end',
};
export const END_TELEHEALTH_SESSION_FOR_PATIENT: RouteData = {
    anonymous: true,
    method: 'put',
    url: 'telehealth/patient-sessions/{telehealthSessionId}/connection/end',
};
export const COMPLETE_TELEHEALTH_SESSION: RouteData = {
    method: 'put',
    url: 'telehealth/sessions/{telehealthSessionId}/complete',
};
export const SAVE_TELEHEALTH_SESSION_NOTE: RouteData = {
    method: 'put',
    url: 'telehealth/sessions/{telehealthSessionId}/note',
};
export const RESEND_TELEHEALTH_SESSION_INVITE: RouteData = {
    method: 'put',
    url: 'telehealth/sessions/{telehealthSessionId}/resend-invite',
};
export const MACRA_GET_DATA: RouteData = {
    method: 'get',
    url: 'macra/{year}',
};
export const MACRA_GET_PROVIDER_SURVEYS: RouteData = {
    method: 'post',
    url: 'macra/{year}/provider-surveys',
};
export const MACRA_GET_SETTINGS: RouteData = {
    method: 'get',
    url: 'macra/{year}/settings',
};
export const MACRA_SAVE_SETTINGS: RouteData = {
    method: 'post',
    url: 'macra/{year}/settings',
};
export const MACRA_GET_PROVIDERS: RouteData = {
    method: 'get',
    url: 'macra/{year}/providers',
};
export const MACRA_SAVE_SIGNATURE: RouteData = {
    method: 'post',
    url: 'macra/{year}/signature',
};
export const MACRA_GET_SIGNATURE: RouteData = {
    method: 'get',
    url: 'macra/{year}/signature',
};
export const MACRA_GET_AQI_OPTIONS: RouteData = {
    method: 'get',
    url: 'macra/aqi/options',
};
export const MACRA_GET_QCDR_EXPORT: RouteData = {
    method: 'get',
    url: 'macra/qcdr/export',
};
export const MACRA_GET_AQI_EXPORT: RouteData = {
    method: 'post',
    url: 'macra/aqi/export',
};
export const GET_STARTUP_DATA: RouteData = {
    method: 'get',
    url: 'user/startup',
};
export const GET_VALUE_SET: RouteData = {
    method: 'get',
    url: 'value-set',
};
export const GET_VALUE_SET_BY_ID: RouteData = {
    method: 'get',
    url: 'value-set/{id}',
};
export const DEACTIVATE_VALUE_SET_DETAIL: RouteData = {
    method: 'put',
    url: '/value-set/detail/{valueSetDetailId}/deactivate',
};
export const REACTIVATE_VALUE_SET_DETAIL: RouteData = {
    method: 'put',
    url: '/value-set/detail/{valueSetDetailId}/reactivate',
};
export const UPDATE_VALUE_SET_DETAIL: RouteData = {
    method: 'put',
    url: '/value-set/detail/{valueSetDetailId}/update',
};
export const CREATE_VALUE_SET_DETAIL: RouteData = {
    method: 'post',
    url: '/value-set/{valueSetId}/detail',
};
export const GET_VALUE_SET_DETAILS: RouteData = {
    method: 'get',
    url: '/value-set/{valueSetId}/details',
};
export const SCORECARD_GET_ORG_METRICS: RouteData = {
    method: 'get',
    url: 'scorecard/org/metrics',
};
export const GET_ENCOUNTER_FORM_SEARCH_FIELDS: RouteData = {
    method: 'get',
    url: 'encounter/form/searchfields',
};
export const GET_SURVEY_DEFINITIONS: RouteData = {
    method: 'get',
    url: '/survey-definitions',
};
export const GET_SURVEY_FOR_PATIENT: RouteData = {
    anonymous: true,
    method: 'get',
    url: 'survey/patient-surveys/{surveyRequestGuid}',
};
export const GET_SURVEY_BY_ENC_FORM_ID: RouteData = {
    method: 'get',
    url: 'survey/patient-surveys/encounter/{encounterFormId}',
};
export const GET_SURVEY_FACILITY_FOR_PATIENT: RouteData = {
    anonymous: true,
    method: 'get',
    url: 'survey/patient-surveys/{surveyGuid}/facility',
};
export const SUBMIT_SURVEY: RouteData = {
    anonymous: true,
    method: 'post',
    url: 'survey/patient-surveys/{surveyGuid}',
};
export const UPDATE_SURVEY: RouteData = {
    anonymous: true,
    method: 'put',
    url: 'survey/patient-surveys/{surveyGuid}',
};
export const UNSUBSCRIBE_SURVEY: RouteData = {
    anonymous: true,
    method: 'put',
    url: 'survey/patient-surveys/{surveyRequestGuid}/unsubscribe',
};
export const GET_SURVEY_REQUEST_AND_FACILITY: RouteData = {
    anonymous: true,
    method: 'get',
    url: 'survey/patient-surveys/request/{surveyRequestGuid}',
};
export const GET_IMPORT_BATCHES: RouteData = {
    method: 'get',
    url: 'import/batches',
};
export const GET_RECORD_STATES_FOR_ALL_BATCHES: RouteData = {
    method: 'get',
    url: 'import/batches/states',
};
export const GET_IMPORT_BATCH_BY_GUID: RouteData = {
    method: 'get',
    url: 'import/batch/{guid}',
};
export const GET_IMPORT_BATCH_RECORDS_BY_GUID: RouteData = {
    method: 'get',
    url: 'import/batch/{guid}/records',
};
export const GET_IMPORT_BATCH_RECORD_BY_GUID: RouteData = {
    method: 'get',
    url: 'import/record/{guid}',
};
export const UPDATE_IMPORT_BATCH_RECORD_BY_GUID: RouteData = {
    method: 'put',
    url: 'import/record/{guid}',
};
export const GET_IMPORT_BATCH_RECORD_STATES: RouteData = {
    method: 'get',
    url: 'import/record/states',
};
export const GET_IMPORT_BATCH_TEMPLATES: RouteData = {
    method: 'get',
    url: 'import/batch-templates',
};
export const CREATE_IMPORT_BATCH: RouteData = {
    method: 'post',
    url: 'import/batch',
};
export const REPROCESS_IMPORT_BATCH_RECORDS: RouteData = {
    method: 'post',
    url: 'import/batch/reprocess',
};
export const IGNORE_IMPORT_BATCH_RECORDS: RouteData = {
    method: 'put',
    url: 'import/batch/ignore',
};
export const UNIGNORE_IMPORT_BATCH_RECORDS: RouteData = {
    method: 'put',
    url: 'import/batch/unignore',
};
export const CREATE_IMPORT_BATCH_TEMPLATE: RouteData = {
    method: 'post',
    url: 'import/batch-templates',
};
export const UPDATE_IMPORT_BATCH_TEMPLATE: RouteData = {
    method: 'put',
    url: 'import/batch-templates/{importBatchTemplateGuid}',
};
export const GET_ORGDB_ORG: RouteData = {
    method: 'get',
    url: 'organization/organization',
};
export const UPDATE_AGENCY_DATA: RouteData = {
    method: 'put',
    url: 'organization/agency',
};
export const GET_DEVICES: RouteData = {
    method: 'get',
    url: '/organization/devices',
};
export const CREATE_DEVICE: RouteData = {
    method: 'post',
    url: '/organization/devices',
};
export const UPDATE_DEVICE: RouteData = {
    method: 'put',
    url: '/organization/devices/{deviceId}',
};
export const GET_VEHICLES: RouteData = {
    method: 'get',
    url: '/organization/vehicles',
};
export const CREATE_VEHICLE: RouteData = {
    method: 'post',
    url: '/organization/vehicles',
};
export const UPDATE_VEHICLE: RouteData = {
    method: 'put',
    url: '/organization/vehicles/{vehicleId}',
};
export const GET_TRANSPORTATION_FACILITIES: RouteData = {
    method: 'get',
    url: '/organization/transport-facilities',
};
export const CREATE_TRANSPORTATION_FACILITY: RouteData = {
    method: 'post',
    url: '/organization/transport-facilities',
};
export const UPDATE_TRANSPORTATION_FACILITY: RouteData = {
    method: 'put',
    url: '/organization/transport-facilities/{transportFacilityId}',
};
export const VALIDATE_NEMSIS_DEM_DATA: RouteData = {
    method: 'get',
    url: '/nemsis/dem/validate',
};
export const VALIDATE_NEMSIS_EMS_DATA: RouteData = {
    method: 'post',
    url: '/nemsis/ems/{encounterFormId}/validate',
};
export const GET_NEMSIS_EMS_VALIDATION: RouteData = {
    method: 'get',
    url: '/nemsis/ems/{encounterFormId}/validate',
};
export const SUBMIT_DEM_NEMSIS_DATA: RouteData = {
    method: 'get',
    url: '/nemsis/dem/submit',
};
export const SUBMIT_EMS_NEMSIS_DATA: RouteData = {
    method: 'get',
    url: '/nemsis/ems/submit',
};

export const SUBMIT_SINGLE_EMS_NEMSIS_DATA: RouteData = {
    method: 'get',
    url: '/nemsis/ems/{encounterFormId}/submit',
};

export const GET_PAYER_CATEGORIES: RouteData = {
    method: 'get',
    url: '/payment/payer-categories',
};

export const CREATE_PAYER_CATEGORY: RouteData = {
    method: 'post',
    url: '/payment/payer-category',
};

export const UPDATE_PAYER_CATEGORY: RouteData = {
    method: 'put',
    url: '/payment/payer-category/{payerCategoryId}',
};

export const CREATE_PAYMENT_INTENT: RouteData = {
    method: 'post',
    url: '/payment/request/{paymentRequestContactGuid}/intent',
};

export const CREATE_PAYMENT_TRANSACTION: RouteData = {
    method: 'post',
    url: '/payment/transaction/{paymentRequestGuid}',
};

export const SUBMIT_PAYMENT_IMPORT_ENCOUNTER_DATA: RouteData = {
    method: 'post',
    url: '/payment/uncategorized-payers',
};

export const SUBMIT_UPDATED_PAYER_CATEGORIES: RouteData = {
    method: 'post',
    url: '/payment/import-encounters',
};

export const SUBMIT_PAYMENT_REQUESTS: RouteData = {
    method: 'post',
    url: '/payment/payment-requests',
};

export const GET_ORG_PAYMENT_TYPES: RouteData = {
    method: 'get',
    url: '/payment/payment-types',
};

export const CREATE_PAYMENT_TYPE: RouteData = {
    method: 'post',
    url: '/payment/payment-type',
};

export const UPDATE_PAYMENT_TYPE: RouteData = {
    method: 'put',
    url: '/payment/payment-type/{paymentTypeId}',
};

export const UPDATE_PAYMENT_TYPE_EMAIL_TEXT: RouteData = {
    method: 'put',
    url: '/payment/payment-type/{paymentTypeId}/email',
};

export const UPDATE_PAYMENT_TYPE_SMS_TEXT: RouteData = {
    method: 'put',
    url: '/payment/payment-type/{paymentTypeId}/sms',
};

export const UPDATE_PAYMENT_TYPE_LANDING_PAGE_TEXT: RouteData = {
    method: 'put',
    url: '/payment/payment-type/{paymentTypeId}/landing-page',
};

export const GET_ORG_PAYMENT_GATEWAYS: RouteData = {
    method: 'get',
    url: '/payment/payment-gateways',
};

export const GET_PAYMENT_GATEWAY_TYPES: RouteData = {
    method: 'get',
    url: '/payment/gateway-types',
};

export const CREATE_PAYMENT_GATEWAY: RouteData = {
    method: 'post',
    url: '/payment/gateway',
};

export const UPDATE_PAYMENT_GATEWAY: RouteData = {
    method: 'put',
    url: '/payment/gateway/{paymentGatewayId}',
};

export const DEACTIVATE_PAYMENT_GATEWAY: RouteData = {
    method: 'put',
    url: '/payment/gateway/{paymentGatewayId}/deactivate',
};

export const REACTIVATE_PAYMENT_GATEWAY: RouteData = {
    method: 'put',
    url: '/payment/gateway/{paymentGatewayId}/reactivate',
};

export const DEACTIVATE_PAYMENT_TYPE: RouteData = {
    method: 'put',
    url: '/payment/payment-type/{paymentTypeId}/deactivate',
};

export const REACTIVATE_PAYMENT_TYPE: RouteData = {
    method: 'put',
    url: '/payment/payment-type/{paymentTypeId}/reactivate',
};

export const DEACTIVATE_PAYER_CATEGORY: RouteData = {
    method: 'put',
    url: '/payment/payer-category/{payerCategoryId}/deactivate',
};

export const REACTIVATE_PAYER_CATEGORY: RouteData = {
    method: 'put',
    url: '/payment/payer-category/{payerCategoryId}/reactivate',
};

export const GET_PAYMENT_REQUESTS: RouteData = {
    method: 'get',
    url: '/payment/payment-requests',
};

export const GET_PAYMENT_REQUEST: RouteData = {
    method: 'get',
    url: '/payment/payment-request/{paymentRequestGuid}',
};

export const CREATE_PAYMENT_REQUEST_CONTACT: RouteData = {
    method: 'post',
    url: '/payment/payment-request-contact/{paymentRequestGuid}',
};

export const RESEND_PAYMENT_CONTACTS: RouteData = {
    method: 'put',
    url: '/payment/payment-request-contacts/resend',
};

export const COLLECT_PAYMENT: RouteData = {
    method: 'post',
    url: '/payment/transaction/collect/{paymentRequestGuid}',
};

export const CANCEL_PAYMENT_REQUEST: RouteData = {
    method: 'put',
    url: '/payment/payment-request/{paymentRequestGuid}/cancel',
};

export const GET_PAYERS: RouteData = {
    method: 'get',
    url: '/payment/payers',
};

export const CREATE_PAYER: RouteData = {
    method: 'post',
    url: '/payment/payer',
};

export const UPDATE_PAYER: RouteData = {
    method: 'put',
    url: '/payment/payer/{payerId}',
};

export const GET_PAYMENT_REQUEST_STATES: RouteData = {
    method: 'get',
    url: '/payment/payment-request-states',
};

export const GET_PAYMENT_REQUEST_CONTACT_STATES: RouteData = {
    method: 'get',
    url: '/payment/payment-request-contact-states',
};

export const GET_PAYMENT_TRANSACTION_STATES: RouteData = {
    method: 'get',
    url: '/payment/payment-transaction-states',
};

export const GET_PAYMENT_REQUEST_CONTACT: RouteData = {
    method: 'get',
    url: '/payment/payment-request-contact/{paymentRequestContactGuid}',
};

export const UNSUBSCRIBE_PAYMENT_REQUEST_CONTACT: RouteData = {
    method: 'put',
    url: '/payment/payment-request-contact/{paymentRequestContactGuid}/unsubscribe',
};

export const UPDATE_PAYMENT_AMOUNT: RouteData = {
    method: 'put',
    url: '/payment/payment-request/{paymentRequestGuid}/amount',
};

export const GET_VITALS_CONNECTIONS: RouteData = {
    method: 'get',
    url: '/vitals/vitals-connections',
};

export const CREATE_VITALS_CONNECTION: RouteData = {
    method: 'post',
    url: '/vitals/vitals-connection',
};

export const GET_VITALS_CONNECTION: RouteData = {
    method: 'get',
    url: '/vitals/vitals-connection/{vitalsConnectionId}',
};

export const UPDATE_VITALS_CONNECTION: RouteData = {
    method: 'put',
    url: '/vitals/vitals-connection/{vitalsConnectionId}',
};

export const DEACTIVATE_VITALS_CONNECTION: RouteData = {
    method: 'put',
    url: '/vitals/vitals-connection/{vitalsConnectionId}/deactivate',
};

export const REACTIVATE_VITALS_CONNECTION: RouteData = {
    method: 'put',
    url: '/vitals/vitals-connection/{vitalsConnectionId}/reactivate',
};

export const GET_VITALS_MEASURES: RouteData = {
    method: 'get',
    url: '/vitals/vitals-measures',
};

export const GET_VITALS_MEASURE_CATEGORIES: RouteData = {
    method: 'get',
    url: '/vitals/vitals-measure-categories',
};

export const GET_CUSTOMERS: RouteData = {
    method: 'get',
    url: '/customer/customers',
};

export const GET_CUSTOMER: RouteData = {
    method: 'get',
    url: '/customer/{customerId}',
};

export const GET_CUSTOMER_ORGS: RouteData = {
    method: 'get',
    url: '/customer/{customerId}/orgs',
};

export const UPDATE_CUSTOMER: RouteData = {
    method: 'put',
    url: '/customer/{customerId}',
};

export const CREATE_CUSTOMER: RouteData = {
    method: 'post',
    url: '/customer',
};

export const UPDATE_CUSTOMER_STATUS: RouteData = {
    method: 'put',
    url: '/customer/{customerId}/status',
};

export const ADD_CUSTOMER_DOCUMENT: RouteData = {
    method: 'post',
    url: '/customer/{customerId}/document',
};

export const UPDATE_CUSTOMER_DOCUMENT: RouteData = {
    method: 'put',
    url: '/customer/{customerId}/document/{documentGuid}',
};

export const ADD_CUSTOMER_CONTACT: RouteData = {
    method: 'post',
    url: '/customer/{customerId}/contact',
};

export const UPDATE_CUSTOMER_CONTACT: RouteData = {
    method: 'put',
    url: '/customer/{customerId}/contact/{contactGuid}',
};

export const REMOVE_CUSTOMER_CONTACT: RouteData = {
    method: 'delete',
    url: '/customer/{customerId}/contact/{contactGuid}',
};

export const GET_ADS: RouteData = {
    method: 'get',
    url: '/ads',
};

export const UPDATE_ADS: RouteData = {
    method: 'put',
    url: '/ads/{systemId}',
};

/**
 * Form Catalogs
 */

export const GET_FORM_CATALOGS: RouteData = {
    method: 'get',
    url: '/form-catalogs',
};

export const GET_FORM_CATALOG_BY_ID: RouteData = {
    method: 'get',
    url: '/form-catalogs/{formCatalogId}',
};

export const CREATE_FORM_CATALOG: RouteData = {
    method: 'post',
    url: '/form-catalogs',
};

export const IMPORT_FORM_CATALOG: RouteData = {
    method: 'post',
    url: '/form-catalogs/import',
};

export const UPDATE_FORM_CATALOG: RouteData = {
    method: 'patch',
    url: '/form-catalogs/{formCatalogId}',
};

export const SAVE_FORM_CATALOG_AS_VERSION: RouteData = {
    method: 'patch',
    url: '/form-catalogs/{formCatalogId}/version',
};

export const ADD_FORM_CATALOG_PAGE: RouteData = {
    method: 'post',
    url: '/form-catalogs/{formCatalogId}/pages',
};

export const UPDATE_FORM_CATALOG_PAGE: RouteData = {
    method: 'patch',
    url: '/form-catalogs/{formCatalogId}/pages/{pageId}',
};

export const GET_FORM_CATALOG_PAGE_URL: RouteData = {
    method: 'get',
    url: '/form-catalogs/{formCatalogId}/pages/{pageId}/url',
};

/**
 * Form Models
 */

export const GET_FORM_MODELS: RouteData = {
    method: 'get',
    url: '/form-models',
};

export const GET_FORM_MODEL_BY_ID: RouteData = {
    method: 'get',
    url: '/form-models/{formModelId}',
};

export const CREATE_FORM_MODEL: RouteData = {
    method: 'post',
    url: '/form-models',
};

export const UPDATE_FORM_MODEL: RouteData = {
    method: 'patch',
    url: '/form-models/{formModelId}',
};

export const DELETE_FORM_MODEL: RouteData = {
    method: 'delete',
    url: '/form-models/{formModelId}',
};

/**
 * Form Facility Mappings
 */

export const GET_FORM_FACILITY_MAPPINGS: RouteData = {
    method: 'get',
    url: '/form-facility-mappings',
};

export const GET_FORM_FACILITY_MAPPING_BY_ID: RouteData = {
    method: 'get',
    url: '/form-facility-mappings/{formFacilityMappingId}',
};

export const CREATE_FORM_FACILITY_MAPPING: RouteData = {
    method: 'post',
    url: '/form-facility-mappings',
};

export const UPDATE_FORM_FACILITY_MAPPING: RouteData = {
    method: 'patch',
    url: '/form-facility-mappings/{formFacilityMappingId}',
};

export const PREPARE_FORM_FACILITY_MAPPING_FOR_DEPLOYMENT: RouteData = {
    method: 'put',
    url: '/form-facility-mappings/{formFacilityMappingId}/predeploy',
};

export const DEPLOY_FORM_FACILITY_MAPPING: RouteData = {
    method: 'put',
    url: '/form-facility-mappings/{formFacilityMappingId}/deploy',
};

export const GET_FORM_FACILITY_MAPPING_FORM_DEFINITIONS: RouteData = {
    method: 'get',
    url: '/form-facility-mappings/form-definitions',
};

/**
 * Form Assets
 */

export const GET_FORM_ASSETS: RouteData = {
    method: 'get',
    url: '/form-assets',
};

export const GET_FORM_ASSET_BY_ID: RouteData = {
    method: 'get',
    url: '/form-assets/{formAssetId}',
};

export const GET_FORM_ASSET_TYPES: RouteData = {
    method: 'get',
    url: '/form-assets/types',
};

export const CREATE_FORM_ASSET: RouteData = {
    method: 'post',
    url: '/form-assets',
};

export const UPDATE_FORM_ASSET: RouteData = {
    method: 'patch',
    url: '/form-assets/{formAssetId}',
};
