import { autorun, set, toJS } from 'mobx';

export function autoSave(_this: any, name: string, cacheList: string[]) {
    const storedJson = localStorage.getItem(name);
    if (storedJson) {
        set(_this, Object.assign(_this, JSON.parse(storedJson)));
    }
    autorun(() => {
        const value = toJS(_this);
        const pickedValues = Object.assign({}, ...cacheList.map(key => ({ [key]: value[key] })));
        localStorage.setItem(name, JSON.stringify(pickedValues));
    });
}