import React from 'react';

const SvgAttachment = (props) => (
    <svg width={21} height={19} {...props} viewBox="0 0 21 19">
        <path
            d="M7.094 12.476l6.933-6.933a1.631 1.631 0 112.307 2.307l-9.365 9.394a3.263 3.263 0 11-4.614-4.614l9.787-9.786a4.485 4.485 0 016.344 6.342l-6.932 6.934"
            stroke={props.color || '#FFF'}
            strokeWidth={1.2}
            fill="none"
            fillRule="evenodd"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default SvgAttachment;
