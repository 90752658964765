import React from 'react';

const SvgDetails = (props) => (
    <svg width={7} height={11} {...props} viewBox="0 0 7 11">
        <path
            d="M1.73 9.6l3.516-3.87a.195.195 0 00.047-.13.195.195 0 00-.047-.13L1.73 1.6"
            stroke={props.color || '#FFF'}
            strokeWidth={1.6}
            fill="none"
            fillRule="evenodd"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default SvgDetails;
