import * as ROUTES from 'src/api/routes';
import request from 'src/api/utils';
import * as utils from 'src/utils';

export async function fetchEvents(params = {}) {
    return await request({ ...ROUTES.GET_EVENTS, params });
}

export async function fetchEventStates() {
    return await request(ROUTES.GET_EVENTSTATES);
}

export async function updateEvent(eventId: number, data: object) {
    const cleanedData = utils.removeNull(data);
    return await request({ ...ROUTES.UPDATE_EVENT, pathParams: { eventId }, data: cleanedData });
}

export async function rescheduleEvent(eventId: number, data: object) {
    const cleanedData = utils.removeNull(data);
    return await request({ ...ROUTES.RESCHEDULE_EVENT, pathParams: { eventId }, data: cleanedData });
}

export async function createEvent(data: object) {
    const cleanedData = utils.removeNull(data);
    return await request({ ...ROUTES.CREATE_EVENT, data: cleanedData });
}
