import React, { useState, useEffect, useContext } from 'react';
import styles from './styles.module.scss';
import _ from 'lodash';
import Flex from 'src/components/Flex/index';
import { observer } from 'mobx-react';
import Card from 'src/components/Card';
import Button from 'src/components/Button';
import Icon from 'src/components/Icon';
import PatientNoteAttachmentStore, { PatientNote, PatientAttachment } from 'src/stores/PatientNoteAttachmentStore';
import { ICONS, DISPLAY_DATE_FORMAT, DISPLAY_TIME_FORMAT } from 'src/utils/constants';
import DateComponent from 'src/components/DateComponent';
import { RefListTypes } from 'src/stores/ReferenceListStore';
import { ModalStoreObject, ModalTypes } from 'src/stores/ModalStore';
import { Patient } from 'src/stores/PatientStore';
import * as variables from 'src/styles/variables';
import ReferenceListItemDisplay from 'src/components/ReferenceListItemDisplay';
import { ANALYTICS_NAMES } from 'src/utils/analytics';
import UserStore from 'src/stores/UserStore';
import { getPatientName } from 'src/utils';
import { format } from 'date-fns';

interface ListItemProps {
    item: any;
    onEdit: Function;
    onDelete: Function;
}
export function ListItem(props: ListItemProps) {
    const patientNoteAttachmentStore = useContext(PatientNoteAttachmentStore);
    const userStore = useContext(UserStore);
    const [isLoading, setIsLoading] = useState(false);
    const isNote = !!props.item.patientNoteId;
    const note: PatientNote = isNote ? props.item : null;
    const attachment: PatientAttachment = !isNote ? props.item : null;

    async function viewAttachment() {
        try {
            setIsLoading(true);
            const documentUrl = await patientNoteAttachmentStore.getAttachmentViewUrl(attachment);
            window.open(documentUrl.data.url);
        } finally {
            setIsLoading(false);
        }
    }

    function handleConfirm(isNote: boolean, item: PatientNote | PatientAttachment) {
        ModalStoreObject.showModal(ModalTypes.ConfirmationModal, {
            title: 'Are you sure you would like to delete this item?',
            onConfirm: () => props.onDelete(isNote, item),
            onCancel: () => ModalStoreObject.hideModal(),
            confirmButtonText: 'Confirm',
        });
    }

    function getFileName(filePath: string) {
        const afterSlash = (filePath || '').split('/')[1] || '';
        const fileName = afterSlash.split('_')[1] || '';
        return fileName;
    }

    return (
        <Card
            className={styles.cardWrap}
            label={
                <Flex className={styles.needType} direction="row">
                    <Flex direction="row">
                        {isNote && note.patientNoteType ? (
                            <ReferenceListItemDisplay
                                type={RefListTypes.PatientNoteTypes}
                                value={note.patientNoteType}
                            />
                        ) : isNote ? (
                            'Note'
                        ) : (
                            'Attachment'
                        )}
                        {props.item.patientNoteDate ? (
                            <span className={styles.dateHeader}>
                                {format(
                                    new Date(`${props.item.patientNoteDate}T${props.item.patientNoteTime}`),
                                    `${DISPLAY_DATE_FORMAT} ${DISPLAY_TIME_FORMAT}`,
                                )}
                            </span>
                        ) : (
                            <DateComponent
                                className={styles.dateHeader}
                                date={props.item.updDttm}
                                showDateTime={true}
                            />
                        )}
                    </Flex>
                    <Flex value={1} justify="end">
                        <Button
                            onClick={() => props.onEdit(isNote, isNote ? note : attachment)}
                            type="secondary"
                            isLocked={!userStore.userPermissions.canEdit.patientNotes}
                            leftIcon={<Icon name={ICONS.Pencil2} color={variables.red} />}
                            data-test-id={ANALYTICS_NAMES.PatientDetails_Notes_Edit}
                        />
                        <Button
                            onClick={() => handleConfirm(isNote, isNote ? note : attachment)}
                            type="secondary"
                            leftIcon={<Icon type="fa" name="trash" color={variables.red} />}
                            isLocked={!userStore.userPermissions.canEdit.patientNotes}
                            className={styles.deleteIcon}
                            data-test-id={ANALYTICS_NAMES.PatientDetails_Notes_Delete}
                        />
                    </Flex>
                </Flex>
            }
        >
            <Flex className={styles.cardContent} direction="row" align="center">
                {isNote ? (
                    <Flex value={1}>
                        <span className={styles.noteText}>{note.patientNoteText || ''}</span>
                    </Flex>
                ) : (
                    <>
                        <Flex value={1}>
                            <span>{attachment.patientAttachmentDescription || ''}</span>
                        </Flex>
                        <Flex value={2} direction="row" align="center" justify="end">
                            <span className={styles.filename}>{getFileName(attachment.patientAttachmentFilename)}</span>
                            <Button
                                type="primary"
                                isLoading={isLoading}
                                onClick={viewAttachment}
                                isLocked={!userStore.userPermissions.canView.patientNotes}
                                text="View"
                                data-test-id={ANALYTICS_NAMES.PatientDetails_Notes_ViewAttachment}
                            />
                        </Flex>
                    </>
                )}
            </Flex>
        </Card>
    );
}

interface NotesProps {
    patientId: number;
    patient: Patient;
}
function Notes(props: NotesProps) {
    const patientNoteAttachmentStore = useContext(PatientNoteAttachmentStore);
    const userStore = useContext(UserStore);
    const [combinedList, setCombinedList] = useState<(PatientNote | PatientAttachment)[]>([]);

    useEffect(() => {
        async function getNotesAndAttachments() {
            await Promise.all([
                patientNoteAttachmentStore.getPatientNotes(props.patientId),
                patientNoteAttachmentStore.getPatientAttachments(props.patientId),
            ]);
        }
        getNotesAndAttachments();
    }, []);

    useEffect(() => {
        combineNotesAndAttachments();
    }, [patientNoteAttachmentStore.patientNotes, patientNoteAttachmentStore.patientAttachments]);

    function combineNotesAndAttachments() {
        const combined = [].concat(
            patientNoteAttachmentStore.patientNotes,
            patientNoteAttachmentStore.patientAttachments,
        );
        const sortedCombined = _.orderBy(combined, ['updDttm'], ['desc']);
        setCombinedList(sortedCombined);
    }

    function handleCreateAttachment() {
        ModalStoreObject.showModal(ModalTypes.PatientAttachment, {
            title: `Create an Attachment for ${getPatientName(props.patient)}`,
            patientId: props.patientId,
            onClose: () => ModalStoreObject.hideModal(),
            onSave: () => ModalStoreObject.hideModal(),
        });
    }

    function handleCreateNote() {
        ModalStoreObject.showModal(ModalTypes.PatientNote, {
            title: `Create a Note for ${getPatientName(props.patient)}`,
            patientId: props.patientId,
            onClose: () => ModalStoreObject.hideModal(),
            onSave: () => ModalStoreObject.hideModal(),
        });
    }

    function handleEdit(isNote: boolean, item: PatientNote | PatientAttachment) {
        ModalStoreObject.showModal(isNote ? ModalTypes.PatientNote : ModalTypes.PatientAttachment, {
            title: `Edit ${isNote ? 'Note' : 'Attachment'}`,
            item,
            patientId: props.patientId,
            onClose: () => ModalStoreObject.hideModal(),
            onSave: () => ModalStoreObject.hideModal(),
        });
    }

    async function handleDelete(isNote: boolean, item: PatientNote | PatientAttachment) {
        if (isNote) {
            await patientNoteAttachmentStore.deletePatientNote({
                patientId: props.patientId,
                facilityId: props.patient.facId,
                item,
            });
        } else {
            await patientNoteAttachmentStore.deletePatientAttachment({
                patientId: props.patientId,
                facilityId: props.patient.facId,
                item,
            });
        }
        ModalStoreObject.hideModal();
    }

    return (
        <div className={styles.container}>
            <Flex self="stretch" align="center" justify="start" className={styles.headerWrap}>
                <Flex value={1} className={styles.pageTitle}></Flex>
                <Button
                    leftIcon={<Icon className={styles.plusIcon} name={ICONS.PlusButton} />}
                    type="primary"
                    onClick={handleCreateNote}
                    text="Create Note"
                    isLocked={!userStore.userPermissions.canEdit.patientNotes}
                    className={styles.createNoteButton}
                    data-test-id={ANALYTICS_NAMES.PatientDetails_Notes_Create}
                />
                <Button
                    leftIcon={<Icon className={styles.plusIcon} name={ICONS.PlusButton} />}
                    type="primary"
                    onClick={handleCreateAttachment}
                    isLocked={!userStore.userPermissions.canEdit.patientNotes}
                    text="Add Attachment"
                    data-test-id={ANALYTICS_NAMES.PatientDetails_Notes_CreateAttachment}
                />
            </Flex>
            {combinedList.length === 0 ? (
                <Flex className={styles.nullText}>No Notes or Attachments For this Patient</Flex>
            ) : (
                combinedList.map((n: PatientNote | PatientAttachment) => (
                    <ListItem key={`${n.insDttm}`} item={n} onEdit={handleEdit} onDelete={handleDelete} />
                ))
            )}
        </div>
    );
}

export default observer(Notes);
