import React, { useState, useContext } from 'react';
import classNames from 'classnames';
import NavBar from 'src/containers/NavBar';
import PageContent from 'src/components/PageContent';
import PageContainer from 'src/components/PageContainer';
import Card from 'src/components/Card';
import Flex from 'src/components/Flex';
import Table from 'src/components/Table';
import styles from './styles.module.scss';
import { observer } from 'mobx-react';
import SideModal from 'src/components/SideModal';
import { useEffect } from 'react';
import { OrgUser } from 'src/stores/OrgUserStore';
import * as adminService from 'src/api/admin';
import { CellInfo } from 'react-table';
import ModalFooter from 'src/components/ModalFooter';
import ModalHeader from 'src/components/ModalHeader';
import Input from 'src/components/Input';
import ActionMenu from 'src/components/ActionMenu';
import { ToastStoreObject } from 'src/stores/ToastStore';
import { ErrorStoreObject, ErrorTypes } from 'src/stores/ErrorStore';
import { parseError } from 'src/utils';
import FilterStore from 'src/stores/FilterStore';
import Pagination from 'src/components/Table/Pagination';
import useDebouncedCallback from 'src/utils/hooks/useDebouncedCallback';
import { ModalStoreObject, ModalTypes } from 'src/stores/ModalStore';
import CenterModal from 'src/components/CenterModal';

const FILTER_PAGE = 'adminUsersPage';

function AdminUsers() {
    const columns = [
        {
            Header: () => <span>FIRST NAME</span>,
            accessor: 'frstNm',
            minWidth: 150,
        },
        {
            Header: 'LAST NAME',
            accessor: 'lastNm',
            minWidth: 150,
        },
        {
            Header: 'USERNAME',
            accessor: 'usrNm',
            minWidth: 150,
        },
        {
            Header: 'EMAIL ADDRESS',
            accessor: 'emailAddr',
            minWidth: 300,
        },
        {
            Header: 'ACTIVE',
            accessor: 'actvInd',
            minWidth: 100,
            Cell: (props: CellInfo) => (
                <span className={classNames({ [styles.redText]: !props.value, [styles.greenText]: props.value })}>
                    {props.value ? 'ACTIVE' : 'INACTIVE'}
                </span>
            ),
        },
        {
            Header: 'ACTIONS',
            accessor: 'actions',
            style: { paddingVertical: 0, paddingHorizontal: 20 },
            Cell: (props: CellInfo) => (
                <ActionMenu
                    entityType="adminUser"
                    entity={props.original}
                    onItemClick={(action: { id: string; label: string }) => handleActionClick(action, props.original)}
                />
            ),
        },
    ];
    const orgColumns = [
        {
            Header: () => <span>ORG ID</span>,
            accessor: 'orgId',
            minWidth: 100,
        },
        {
            Header: 'ORG NAME',
            accessor: 'details.orgNm',
            minWidth: 250,
        },
        {
            Header: 'ORG NAME INTERNAL',
            accessor: 'details.orgNmIntrnl',
            minWidth: 150,
        },
    ];

    const [modalState, setModalState] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [editUserState, setEditUserState] = useState<OrgUser>({});
    const [userState, setUserState] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [isSearching, setIsSearching] = useState(false);
    const [searchUsers, setSearchUsers] = useState([]);
    const [usersTotal, setUsersTotal] = useState(0);
    const filterStore = useContext(FilterStore);

    const [refreshSearchResults] = useDebouncedCallback(async function (query: string) {
        if (!query) {
            setIsSearching(false);
            return;
        }
        setIsLoading(true);
        const { result } = await adminService.getAllUsers({ query });
        setIsSearching(true);
        setSearchUsers(result);
        setIsLoading(false);
    }, 300);

    async function getAllUsers() {
        try {
            setIsLoading(true);
            const { result, count } = await adminService.getAllUsers({
                filters: filterStore[FILTER_PAGE],
            });

            setUserState(result);
            setUsersTotal(count);
            setIsLoading(false);
        } catch (e) {
            ErrorStoreObject.setError(ErrorTypes.Loading);
        }
    }

    useEffect(() => {
        getAllUsers();
    }, [filterStore[FILTER_PAGE]]);

    const openEditModal = (user: OrgUser) => {
        setEditUserState(user);
        setModalState(true);
    };

    const updateUserActiveState = async (id: number, active: boolean) => {
        try {
            if (active) {
                handleConfirm(id);
            } else {
                setIsLoading(true);
                await adminService.reactivateUser(id);
                getAllUsers();
            }
        } catch (e) {
            ToastStoreObject.show(parseError(e));
        } finally {
            setIsLoading(false);
        }
    };

    const handleActionClick = (action: { id: string; label: string }, user: OrgUser) => {
        switch (action.id) {
            case 'view':
                openEditModal(user);
                break;
            case 'activate':
                updateUserActiveState(user.usrId, user.actvInd);
                break;
            case 'checkAuth0':
                ModalStoreObject.showModal(ModalTypes.AdminAuth0UserStatusModal, {
                    onClose: () => ModalStoreObject.hideModal(),
                    onSave: () => console.log('SAVED'),
                    user,
                });
                break;
        }
    };

    async function handleAuth0Block(username: string, forceUnblock?: boolean) {
        /*if (blockedUserNames.includes(username) || forceUnblock) {
            await adminService.unblockUser(username);
        } else {
            await adminService.blockUser(username);
        }
        getAllUsers();
        ModalStoreObject.hideModal();*/
    }

    function handleUpdateSearchQuery(t: string) {
        setSearchValue(t);
        refreshSearchResults(t);
    }

    async function handleDeactivate(id: number) {
        try {
            await adminService.deactivateUser(id);
            getAllUsers();
        }
        catch(error: any) {
            ToastStoreObject.show(parseError(error));
        }
        finally {
            ModalStoreObject.hideModal();
        }
    }
    const handlePaginationFilterChange = function (newPage: number) {
        const updatedFilters = { ...filterStore[FILTER_PAGE] };
        updatedFilters.pagination.page = newPage;
        filterStore.setFilters(FILTER_PAGE, updatedFilters);
    };

    function handleConfirm(id: number) {
        ModalStoreObject.showModal(ModalTypes.ConfirmationModal, {
            title: 'Are you sure you would like to deactivate this user across all organizations?',
            onConfirm: () => handleDeactivate(id),
            onCancel: () => ModalStoreObject.hideModal(),
            confirmButtonText: 'Deactivate',
        });
    }

    const editUserName =
        editUserState && editUserState.frstNm ? `${editUserState.frstNm} ${editUserState.lastNm}` : 'User';

    return (
        <PageContainer>
            <NavBar />
            <PageContent>
                <Flex self="stretch" align="center" justify="start">
                    <div className={styles.searchWrap}>
                        <Input
                            className={styles.input}
                            placeholder="Search..."
                            value={searchValue}
                            onChangeText={(t: string) => handleUpdateSearchQuery(t)}
                        />
                    </div>
                </Flex>
                <Card className={styles.cardWrap}>
                    <div className={styles.tableTitle}>Users</div>
                    <Table
                        isLoading={isLoading}
                        columns={columns}
                        data={isSearching ? searchUsers : userState}
                        showPagination={false}
                        pageSize={filterStore[FILTER_PAGE].pagination.limit}
                    />
                    {/* Custom Pagination for server side data */}
                    {isSearching ? null : (
                        <Pagination
                            pages={Math.ceil(usersTotal / filterStore[FILTER_PAGE].pagination.limit) || 1}
                            page={filterStore[FILTER_PAGE].pagination.page - 1}
                            onPageChange={(page: number) => handlePaginationFilterChange(page + 1)}
                            showing={userState.length}
                            totalRecords={usersTotal}
                        />
                    )}
                </Card>
            </PageContent>
            <SideModal isOpen={modalState} onClose={() => setModalState(false)}>
                <ModalHeader title={`View ${editUserName}`} onClose={() => setModalState(false)} />
                <div>
                    <div className={styles.contentWrap}>
                        <Input label="First Name" value={editUserState.frstNm} disabled={true} />
                        <Input label="Last Name" value={editUserState.lastNm} disabled={true} />
                        <Input label="Email Address" value={editUserState.emailAddr} disabled={true} />
                        <Input label="Username" value={editUserState.usrNm} disabled={true} />
                        <div>Organizations</div>
                        <Table columns={orgColumns} data={editUserState.orgs} />
                    </div>
                </div>
                <ModalFooter
                    primaryText="Okay"
                    primaryClick={() => setModalState(false)}
                    secondaryClick={() => setModalState(false)}
                />
            </SideModal>
        </PageContainer>
    );
}
export default observer(AdminUsers);
