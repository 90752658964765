import {
    FacilityOffboardReason,
    FacilityStatus,
    FacilitySubscriptionData,
} from 'common/lib/model/facility/FacilitySubscriptionData';
import { format } from 'date-fns';
import React, { useContext, useState } from 'react';
import Select from 'react-select';
import CustomerStore from 'src/stores/CustomerStore';
import FacilityStore, { Facility } from 'src/stores/FacilityStore';
import { ToastStoreObject, ToastType } from 'src/stores/ToastStore';
import { parseError, styleSelectComponent } from 'src/utils';
import { ANALYTICS_NAMES } from 'src/utils/analytics';
import { API_DATE_FORMAT } from 'src/utils/constants';
import Checkbox from '../Checkbox';
import DatePicker from '../DatePicker';
import Input, { Label } from '../Input';
import LoadingIcon from '../LoadingIcon';
import ModalFooter from '../ModalFooter';
import ModalHeader from '../ModalHeader';
import SideModal from '../SideModal';
import DataEntryServicerDropdown from './DataEntryServicerDropdown';
import FacilityOffboardReasonDropdown from './FacilityOffboardReasonDropdown';
import FacilityStatusDropdown from './FacilityStatusDropdown';
import FacilityTypeDropdown from './FacilityTypeDropdown';
import PrimaryCaseCaptureModeDropdown from './PrimaryCaseCaptureModeDropdown';
import PrimaryUseDropdown from './PrimaryUseDropdown';
import styles from './styles.module.scss';

interface FacilitySubscriptionDataModalProps {
    onClose?: Function;
    onSave?: Function;
    facility: Facility;
}

function FacilitySubscriptionDataModal(props: FacilitySubscriptionDataModalProps) {
    const facilityStore = useContext(FacilityStore);
    const customerStore = useContext(CustomerStore);
    const [isLoading, setIsLoading] = useState(false);
    const { facility } = props;
    const [orderDate, setOrderDate] = useState<FacilitySubscriptionData['orderDate']>(
        facility.subscriptionData?.orderDate,
    );
    const [goLiveDate, setGoLiveDate] = useState<FacilitySubscriptionData['goLiveDate']>(
        facility.subscriptionData?.goLiveDate,
    );
    const [offboardDate, setOffboardDate] = useState<FacilitySubscriptionData['offboardDate']>(
        facility.subscriptionData?.offboardDate,
    );
    const [facilityType, setFacilityType] = useState<FacilitySubscriptionData['facilityType']>(
        facility.subscriptionData?.facilityType,
    );
    const [facilityStatus, setFacilityStatus] = useState<FacilitySubscriptionData['facilityStatus']>(
        facility.subscriptionData?.facilityStatus,
    );
    const [offboardReason, setOffboardReason] = useState<FacilitySubscriptionData['offboardReason']>(
        facility.subscriptionData?.offboardReason,
    );
    const [otherOffboardReason, setOtherOffboardReason] = useState<FacilitySubscriptionData['otherOffboardReason']>(
        facility.subscriptionData?.otherOffboardReason,
    );
    const [offboardToOrgInternalName, setOffboardToOrgInternalName] = useState<
        FacilitySubscriptionData['offboardToOrgInternalName']
    >(facility.subscriptionData?.offboardToOrgInternalName);
    const [offboardToFacilityId, setOffboardToFacilityId] = useState<FacilitySubscriptionData['offboardToFacilityId']>(
        facility.subscriptionData?.offboardToFacilityId,
    );
    const [primaryUse, setPrimaryUse] = useState<FacilitySubscriptionData['primaryUse']>(
        facility.subscriptionData?.primaryUse,
    );
    const [primaryCaseCaptureMode, setPrimaryCaseCaptureMode] = useState<
        FacilitySubscriptionData['primaryCaseCaptureMode']
    >(facility.subscriptionData?.primaryCaseCaptureMode);
    const [isReportingMacra, setIsReportingMacra] = useState<FacilitySubscriptionData['isReportingMacra']>(
        facility.subscriptionData?.isReportingMacra,
    );
    const [macraFormDataEntry, setMacraFormDataEntry] = useState<FacilitySubscriptionData['macraFormDataEntry']>(
        facility.subscriptionData?.macraFormDataEntry,
    );
    const [macraFormDataEntryServicer, setMacraFormDataEntryServicer] = useState<
        FacilitySubscriptionData['macraFormDataEntryServicer']
    >(facility.subscriptionData?.macraFormDataEntryServicer);

    async function handleSave() {
        setIsLoading(true);
        try {
            await facilityStore.updateFacilitySubscriptionData(props.facility.facId, props.facility.orgId, {
                orderDate,
                goLiveDate,
                offboardDate,
                facilityType,
                facilityStatus,
                offboardReason,
                otherOffboardReason,
                offboardToOrgInternalName,
                offboardToFacilityId,
                primaryUse,
                primaryCaseCaptureMode,
                isReportingMacra,
                macraFormDataEntry,
                macraFormDataEntryServicer,
            });
            if (props.onSave) {
                props.onSave();
            }
            if (props.onClose) {
                props.onClose();
            }
            ToastStoreObject.show(`${facility.facNm} has been updated`, ToastType.Success);
        } catch (e: any) {
            ToastStoreObject.show(parseError(e));
        } finally {
            setIsLoading(false);
        }
    }

    function handleFacilityTypeChange(typeSelection: {
        value: FacilitySubscriptionData['facilityType'];
        label: string;
    }) {
        setFacilityType(typeSelection.value);
    }

    function handleFacilityStatusChange(statusSelection: {
        value: FacilitySubscriptionData['facilityStatus'];
        label: string;
    }) {
        setFacilityStatus(statusSelection.value);
    }

    function handlePrimaryUseChange(primaryUseSelection: {
        value: FacilitySubscriptionData['primaryUse'];
        label: string;
    }) {
        setPrimaryUse(primaryUseSelection.value);
    }

    function handlePrimaryCaseCaptureModeChange(primaryCaseCaptureModeSelection: {
        value: FacilitySubscriptionData['primaryCaseCaptureMode'];
        label: string;
    }) {
        setPrimaryCaseCaptureMode(primaryCaseCaptureModeSelection.value);
    }

    function handleDataEntryServicerChange(dataEntryServicerSelection: {
        value: FacilitySubscriptionData['macraFormDataEntryServicer'];
        label: string;
    }) {
        setMacraFormDataEntryServicer(dataEntryServicerSelection.value);
    }

    function handleOffboardReasonChange(offboardReasonSelection: {
        value: FacilitySubscriptionData['offboardReason'];
        label: string;
    }) {
        setOffboardReason(offboardReasonSelection.value);
    }

    function showOffboardingOptions(): boolean {
        return facilityStatus === FacilityStatus.Offboarded || facilityStatus === FacilityStatus.Offboarding;
    }

    return (
        <SideModal isOpen={true} onClose={props.onClose}>
            <ModalHeader title={'Edit Customer Facility'} onClose={props.onClose} />
            {isLoading ? (
                <div className={styles.loadingContentWrap}>
                    <LoadingIcon />
                </div>
            ) : (
                <div className={styles.contentWrap}>
                    <>
                        <Label text="Order Date" />
                        <DatePicker
                            value={orderDate}
                            onChange={(date) => {
                                if (date) {
                                    const value = format(date, API_DATE_FORMAT);
                                    setOrderDate(value);
                                }
                            }}
                            data-test-id={ANALYTICS_NAMES.FacilitySubscriptionDataModal_OrderDate}
                        />
                    </>
                    <>
                        <Label text="Go Live Date" />
                        <DatePicker
                            value={goLiveDate}
                            onChange={(date) => {
                                if (date) {
                                    const value = format(date, API_DATE_FORMAT);
                                    setGoLiveDate(value);
                                }
                            }}
                            data-test-id={ANALYTICS_NAMES.FacilitySubscriptionDataModal_GoLiveDate}
                        />
                    </>
                    <>
                        <Label text="Facility Type" />
                        <FacilityTypeDropdown
                            selectedValue={facilityType}
                            onChange={(e: any) => handleFacilityTypeChange(e)}
                            data-test-id={ANALYTICS_NAMES.FacilitySubscriptionDataModal_FacilityType}
                        />
                    </>
                    <>
                        <Label text="Facility Status" />
                        <FacilityStatusDropdown
                            selectedValue={facilityStatus}
                            onChange={(e: any) => handleFacilityStatusChange(e)}
                            data-test-id={ANALYTICS_NAMES.FacilitySubscriptionDataModal_FacilityStatus}
                        />
                    </>
                    {showOffboardingOptions() && (
                        <>
                            <>
                                <Label text="Offboard Reason" />
                                <FacilityOffboardReasonDropdown
                                    selectedValue={offboardReason}
                                    onChange={(e: any) => handleOffboardReasonChange(e)}
                                    data-test-id={ANALYTICS_NAMES.FacilitySubscriptionDataModal_OffboardReason}
                                />
                            </>
                            {offboardReason === FacilityOffboardReason.Other && (
                                <>
                                    <Input
                                        label="Other Offboard Reason"
                                        value={otherOffboardReason}
                                        onChangeText={(v: string) => setOtherOffboardReason(v)}
                                        data-test-id={ANALYTICS_NAMES.FacilitySubscriptionDataModal_OtherOffboardReason}
                                    />
                                </>
                            )}
                            {offboardReason === FacilityOffboardReason.FacilityMerged && (
                                <>
                                    <Label text="Facility Merged" />
                                    <Select
                                        value={customerStore.customerFacilityOptions.find(
                                            (option: any) =>
                                                option.value === `${offboardToOrgInternalName}-${offboardToFacilityId}`,
                                        )}
                                        onChange={(s: any) => {
                                            const splitValue = s.value.split('-');
                                            setOffboardToOrgInternalName(splitValue[0]);
                                            setOffboardToFacilityId(splitValue[1]);
                                        }}
                                        isLoading={isLoading}
                                        className={styles.select}
                                        options={customerStore.customerFacilityOptions}
                                        placeholder="Select Facility"
                                        styles={styleSelectComponent()}
                                        data-test-id={ANALYTICS_NAMES.FacilitySubscriptionDataModal_FacilityMerged}
                                    />
                                </>
                            )}
                            <>
                                <Label text="Offboard Date" />
                                <DatePicker
                                    value={offboardDate}
                                    onChange={(date) => {
                                        if (date) {
                                            const value = format(date, API_DATE_FORMAT);
                                            setOffboardDate(value);
                                        }
                                    }}
                                    data-test-id={ANALYTICS_NAMES.FacilitySubscriptionDataModal_OffboardDate}
                                />
                            </>
                        </>
                    )}
                    <>
                        <Label text="Primary Use Case" />
                        <PrimaryUseDropdown
                            selectedValue={primaryUse}
                            onChange={(e: any) => handlePrimaryUseChange(e)}
                            data-test-id={ANALYTICS_NAMES.FacilitySubscriptionDataModal_PrimaryUse}
                        />
                    </>
                    <>
                        <Label text="Primary Case Capture Mode" />
                        <PrimaryCaseCaptureModeDropdown
                            selectedValue={primaryCaseCaptureMode}
                            onChange={(e: any) => handlePrimaryCaseCaptureModeChange(e)}
                            data-test-id={ANALYTICS_NAMES.FacilitySubscriptionDataModal_PrimaryCaseCaptureMode}
                        />
                    </>
                    <Checkbox
                        checked={isReportingMacra}
                        onChange={() => {
                            if (isReportingMacra === false) {
                                setIsReportingMacra(true);
                            } else {
                                setIsReportingMacra(false);
                            }
                        }}
                        label={'Reporting MACRA?'}
                        className={styles.checkbox}
                        data-test-id={ANALYTICS_NAMES.FacilitySubscriptionDataModal_IsReportingMacra}
                    />
                    <Checkbox
                        checked={macraFormDataEntry}
                        onChange={() => {
                            if (macraFormDataEntry === false) {
                                setMacraFormDataEntry(true);
                            } else {
                                setMacraFormDataEntry(false);
                            }
                        }}
                        label={'MACRA Form Data Entry'}
                        className={styles.checkbox}
                        data-test-id={ANALYTICS_NAMES.FacilitySubscriptionDataModal_MacraFormDataEntry}
                    />
                    {macraFormDataEntry && (
                        <>
                            <Label text="Data Entry Servicer" />
                            <DataEntryServicerDropdown
                                selectedValue={macraFormDataEntryServicer}
                                onChange={(e: any) => handleDataEntryServicerChange(e)}
                                data-test-id={ANALYTICS_NAMES.FacilitySubscriptionDataModal_MacraDataEntryServicer}
                            />
                        </>
                    )}
                </div>
            )}
            <ModalFooter
                primaryText={'Save'}
                primaryClick={handleSave}
                secondaryClick={() => props.onClose()}
                isLoading={isLoading}
            />
        </SideModal>
    );
}

export default FacilitySubscriptionDataModal;
