import { ReactSelectOption } from 'src/utils/types';
import { observable, action, computed, reaction, IReactionDisposer, makeObservable } from 'mobx';
import { createContext } from 'react';
import * as macraAPI from 'src/api/macra';
import {
    MacraCaseData,
    MacraMeasure,
    MacraDataResults,
    DailyMacraCaseCounts,
    DailyMacraCaseCountData,
    ComplianceData,
    ProviderCaseData,
    ComplianceDetails,
    ByProviderTableRowData,
    ByProviderTableSurveyRowData,
    CasesTableRowData,
    ProviderSurveysResult,
} from 'common/lib/model/macra/MacraYear';
import { Survey, SurveyDefinitionContent, SurveyStateCodes } from 'common/lib/model/surveys/Survey';
import { DateRangeType } from 'common/lib/model/DateRangeType';
import { IdMode } from 'common/lib/model/IdMode';
import {
    measureDefinitions2023,
    measureDefinitions2024,
    ValueMode,
    calculateMeasureMeans,
} from 'common/lib/util/Macra';
import { format, addDays, endOfQuarter, endOfMonth, parseISO, lastDayOfMonth } from 'date-fns';
import { API_DATE_FORMAT } from 'src/utils/constants';
import { AbgMeasureCalculatedMean, AbgQcdrMeasure, MacraAvailableYears } from 'common/lib/model/macra/Macra';
import * as utils from 'src/utils';
import { ComplicationData } from 'common/lib/model/Scorecard/Scorecard';
import { AxiosResponse } from 'axios';

class MacraStore {
    @observable isLoading: boolean = false;
    @observable year: MacraAvailableYears = 2023;
    @observable currentYear: MacraAvailableYears = 2024; // This sets the current macra year (to check against)
    @observable macraData: MacraCaseData[] = [];
    @observable complications: ComplicationData[] = [];
    @observable startDate: string = '2023-01-01';
    @observable endDate: string = '2023-01-30';
    @observable selectedId: string | number = 1;
    @observable valueMode: ValueMode = ValueMode.Projected;
    @observable selectedProviderValue: number = 0;
    @observable providerSurveys: ProviderSurveysResult = null;
    @observable measureDefinitions: AbgQcdrMeasure[] = measureDefinitions2023;

    private providerReaction: IReactionDisposer;
    private getMacraDataReaction: IReactionDisposer;

    dateType: DateRangeType;
    currentIdMode: IdMode = IdMode.Facility;
    dateOptions: ReactSelectOption[] = this.constructDateOptions(this.year);
    valueModeOptions = [
        { value: 'Projected', label: 'Projected Performance (No Billing Codes)' },
        { value: 'Actual', label: 'Projected Performance (With Billing Codes)' },
    ];

    constructor() {
        makeObservable(this);
        this.setupReactions();
    }

    @action
    reset() {
        this.disposeReactions();
        this.isLoading = false;
        this.macraData = [];
        this.complications = [];
        this.selectedId = undefined;
        this.valueMode = ValueMode.Projected;
        this.selectedProviderValue = null;
        this.setupReactions();
    }

    setupReactions() {
        this.providerReaction = reaction(
            () => this.providerDropdownValues,
            (values) =>
                (this.selectedProviderValue =
                    values.length > 1 ? (values[1].value as number) : (values[0].value as number)),
        );
        this.getMacraDataReaction = reaction(
            () => [this.selectedId, this.startDate],
            (values) => this.getMacraData(),
        );
    }

    disposeReactions() {
        this.providerReaction && this.providerReaction();
        this.getMacraDataReaction && this.getMacraDataReaction();
    }

    @action
    setMacraYear(year: MacraAvailableYears) {
        if (this.year === year) return;

        const currentMonth = `0${new Date().getMonth() + 1}`.slice(-2);
        const currentLastDayOfMonthObject = lastDayOfMonth(new Date(year, new Date().getMonth(), new Date().getDate()));
        const currentLastDayOfMonth = ('0' + currentLastDayOfMonthObject.getDate()).slice(-2);

        this.year = year;
        this.startDate = `${year}-${currentMonth}-01`;
        this.endDate = `${year}-${currentMonth}-${currentLastDayOfMonth}`;
        this.dateOptions = this.constructDateOptions(year);

        if (year === 2023) {
            this.measureDefinitions = measureDefinitions2023;
        } else if (year === 2024) {
            this.measureDefinitions = measureDefinitions2024;
        }
    }

    constructDateOptions(year: MacraAvailableYears) {
        return [
            { value: 'Q1', label: 'Q1' },
            { value: 'Q2', label: 'Q2' },
            { value: 'Q3', label: 'Q3' },
            { value: 'Q4', label: 'Q4' },
            { value: `${year}-01-01`, label: 'January' },
            { value: `${year}-02-01`, label: 'February' },
            { value: `${year}-03-01`, label: 'March' },
            { value: `${year}-04-01`, label: 'April' },
            { value: `${year}-05-01`, label: 'May' },
            { value: `${year}-06-01`, label: 'June' },
            { value: `${year}-07-01`, label: 'July' },
            { value: `${year}-08-01`, label: 'August' },
            { value: `${year}-09-01`, label: 'September' },
            { value: `${year}-10-01`, label: 'October' },
            { value: `${year}-11-01`, label: 'November' },
            { value: `${year}-12-01`, label: 'December' },
        ];
    }

    @action
    async getMacraData() {
        if (this.isLoading) return;

        try {
            this.setIsLoading(true);

            const result: AxiosResponse = await macraAPI.getMacraData(this.year, {
                id: this.selectedId,
                startDate: this.startDate,
                endDate: this.endDate,
                isTin: this.currentIdMode === IdMode.TIN,
            });

            const dataUrl = result.data.cacheUrl;

            const macraResults = await macraAPI.getMacraResultsFromS3(dataUrl);

            const macraDataResult: MacraDataResults = macraResults.data;
            this.setMacraData(macraDataResult.caseData || []);
            this.setComplications(macraDataResult.complications || []);
            this.setIsLoading(false);
        } catch (error) {
            this.setIsLoading(false);
        }
    }

    @action
    async getProviderSurveys() {
        const params = { id: this.selectedId, encFormIds: this.macraData.map((m) => m.encounterFormId) };
        const result = await macraAPI.getProviderSurveys(this.year, params);
        this.setProviderSurveys(result.data);
    }

    @action
    setProviderSurveys(value: ProviderSurveysResult) {
        this.providerSurveys = value;
    }
    @action
    setMacraData(value: MacraCaseData[]) {
        this.macraData = value;
    }

    @action
    setComplications(value: ComplicationData[]) {
        this.complications = value;
    }

    @action
    setIsLoading(value: boolean) {
        this.isLoading = value;
    }

    @action
    setSelectedId(value: number | string) {
        if (typeof value === 'number') {
            this.currentIdMode = IdMode.Facility;
            this.selectedId = value;
        } else {
            // This has to be a string, not a number. This supports TINs with leading 0s
            const tin = value?.split(':')[1]?.trim();
            this.currentIdMode = IdMode.TIN;
            this.selectedId = tin;
        }
    }

    @action
    setStartDate(value: string) {
        switch (value) {
            case 'Q1':
                this.dateType = DateRangeType.Quarter;
                this.startDate = `${this.year}-01-01`;
                this.endDate = format(endOfQuarter(parseISO(this.startDate)), API_DATE_FORMAT);
                break;
            case 'Q2':
                this.dateType = DateRangeType.Quarter;
                this.startDate = `${this.year}-04-01`;
                this.endDate = format(endOfQuarter(parseISO(this.startDate)), API_DATE_FORMAT);
                break;
            case 'Q3':
                this.dateType = DateRangeType.Quarter;
                this.startDate = `${this.year}-07-01`;
                this.endDate = format(endOfQuarter(parseISO(this.startDate)), API_DATE_FORMAT);
                break;
            case 'Q4':
                this.dateType = DateRangeType.Quarter;
                this.startDate = `${this.year}-10-01`;
                this.endDate = format(endOfQuarter(parseISO(this.startDate)), API_DATE_FORMAT);
                break;
            default:
                this.dateType = DateRangeType.Month;
                this.startDate = value;
                this.endDate = format(endOfMonth(parseISO(this.startDate)), API_DATE_FORMAT);
        }
    }

    @computed
    get dailyCaseCountData() {
        let currentDate = parseISO(this.startDate);
        const currentEndDate = parseISO(this.endDate);
        let dailyCounts: DailyMacraCaseCounts[] = [];
        while (currentDate.getTime() <= currentEndDate.getTime()) {
            const count = this.macraData.reduce(
                (aggregator: DailyMacraCaseCounts, sample: MacraCaseData) => {
                    const qcdrEvalResult =
                        this.valueMode === ValueMode.Projected ? sample.qcdrEvalResultProjected : sample.qcdrEvalResult;

                    // To work around timezone issues and the fact that we store 0 value times in our date strings,
                    // just using substring here to get the year/month/day.
                    const dos = sample.dos && sample.dos.substring(0, 10);
                    if (dos === format(currentDate, API_DATE_FORMAT)) {
                        if (!sample.qcdrEvalDateTime) {
                            aggregator.pendingQcdrCalculation++;
                        } else if (qcdrEvalResult.admissible) {
                            aggregator.admissible++;
                        } else {
                            aggregator.nonAdmissible++;
                        }
                        aggregator.totalCases++;
                    }
                    return aggregator;
                },
                {
                    date: currentDate.toDateString(),
                    totalCases: 0,
                    pendingQcdrCalculation: 0,
                    admissible: 0,
                    nonAdmissible: 0,
                } as DailyMacraCaseCounts,
            );

            dailyCounts.push(count);
            currentDate = addDays(currentDate, 1);
        }

        const totalCases = dailyCounts.reduce((agg, current: DailyMacraCaseCounts, index) => {
            return agg + current.totalCases;
        }, 0);
        return {
            totalCases,
            dailyCounts,
        } as DailyMacraCaseCountData;
    }

    @computed
    get measureMeans() {
        return calculateMeasureMeans(this.macraData, this.measureDefinitions, this.valueMode);
    }

    @computed
    get providers() {
        return this.macraData
            .map((value) => {
                return value.anesProviders;
            })
            .flat()
            .filter((item, index, array) => {
                return array.findIndex((provider) => item.providerId === provider.providerId) === index;
            })
            .sort((a, b) => a.providerName.localeCompare(b.providerName));
    }

    @computed
    get providerDropdownValues() {
        const options = utils.formatSelectOptions(this.providers, {
            valueKey: 'providerId',
            labelKey: 'providerName',
        });

        return [{ value: -1, label: 'No Provider Specified' }, ...options];
    }

    @computed
    get complianceData() {
        const casesByAnesProvider: Map<string, ProviderCaseData> = new Map();
        const casesWithoutProvider: MacraCaseData[] = [];

        this.macraData.forEach((macraCase) => {
            const qcdrEvalResult =
                this.valueMode === ValueMode.Projected ? macraCase.qcdrEvalResultProjected : macraCase.qcdrEvalResult;

            if (!macraCase.anesProviders || macraCase.anesProviders.length == 0) {
                casesWithoutProvider.push(macraCase);
            } else {
                macraCase.anesProviders.forEach((provider) => {
                    let providerCaseDetails = casesByAnesProvider.get(provider.providerName);
                    if (!providerCaseDetails) {
                        providerCaseDetails = {
                            provider: provider,
                            cases: [],
                            measureMeans: [],
                            admissibleCount: 0,
                        };
                    }
                    providerCaseDetails.cases.push(macraCase);
                    providerCaseDetails.admissibleCount += qcdrEvalResult && qcdrEvalResult.admissible === true ? 1 : 0;
                    casesByAnesProvider.set(provider.providerName, providerCaseDetails);
                });
            }
        });

        casesByAnesProvider.forEach((value, providerName, map) => {
            const providerCaseDetails = casesByAnesProvider.get(providerName);
            providerCaseDetails.measureMeans = calculateMeasureMeans(
                providerCaseDetails.cases,
                this.measureDefinitions,
                this.valueMode,
            );
        });

        return {
            casesByAnesProvider,
            casesWithoutProvider,
        } as ComplianceData;
    }

    @computed
    get byProviderTableData() {
        if (this.macraData.length == 0) return [];

        const data = Array.from(this.complianceData.casesByAnesProvider.values());

        const providerTableData: ByProviderTableRowData[] = data
            .map((providerDetails) => {
                const admissiblePercentage = providerDetails.admissibleCount / providerDetails.cases.length;
                const completedFormsCount = providerDetails.cases.reduce((sum, currCase, index) => {
                    return Math.floor(parseFloat(currCase.formCompletePct)) + sum;
                }, 0);
                const measures = this.measureDefinitions.reduce(
                    (measureObject: any, measure: AbgQcdrMeasure) => ({
                        ...measureObject,
                        [measure.name]: this.getMeasureMeanByMeasureName(providerDetails.measureMeans, measure.name),
                    }),
                    {},
                );

                const result: ByProviderTableRowData = {
                    provider: providerDetails.provider.providerName,
                    admissiblePercentage: isNaN(admissiblePercentage)
                        ? 'N/A'
                        : {
                              value: admissiblePercentage,
                              n: providerDetails.admissibleCount,
                              d: providerDetails.cases.length,
                          },
                    totalCases: providerDetails.cases.length,
                    correctable: providerDetails.cases.filter((macraCase) => macraCase.qcdrMissingDataCount > 0).length,
                    formCompletion: {
                        value: completedFormsCount / providerDetails.cases.length,
                        n: completedFormsCount,
                        d: providerDetails.cases.length,
                    },
                    ...measures,
                };

                return result;
            })
            .sort((a, b) => a.provider.localeCompare(b.provider));

        const noProviderMeasureMeans = calculateMeasureMeans(
            this.complianceData.casesWithoutProvider,
            this.measureDefinitions,
            this.valueMode,
        );
        const completedFormsCount = this.complianceData.casesWithoutProvider.reduce((sum: number, currCase) => {
            return Math.floor(parseFloat(currCase.formCompletePct));
        }, 0);

        const measureMeans = this.measureDefinitions.reduce(
            (measureMean: any, measure: AbgQcdrMeasure) => ({
                ...measureMean,
                [measure.name]: this.getMeasureMeanByMeasureName(noProviderMeasureMeans, measure.name),
            }),
            {},
        );

        const noProviderTableData: ByProviderTableRowData = {
            provider: 'No Provider Specified',
            admissiblePercentage: 'N/A',
            totalCases: this.complianceData.casesWithoutProvider.length,
            correctable: this.complianceData.casesWithoutProvider.filter(
                (macraCase) => macraCase.qcdrMissingDataCount > 0,
            ).length,
            formCompletion: {
                value: completedFormsCount / this.complianceData.casesWithoutProvider.length,
                n: completedFormsCount,
                d: this.complianceData.casesWithoutProvider.length,
            },
            ...measureMeans,
        };
        return [noProviderTableData, ...providerTableData];
    }

    @computed
    get byProviderSurveyData() {
        if (this.macraData.length == 0) return [];
        if (
            !this.providerSurveys ||
            this.providerSurveys.surveys.length === 0 ||
            !this.providerSurveys.surveyDefinitionContent
        )
            return [];

        const data = Array.from(this.complianceData.casesByAnesProvider.values());
        const allSurveys = this.providerSurveys.surveys;
        const surveyDefinitionContent = this.providerSurveys.surveyDefinitionContent;

        const providerTableData: ByProviderTableSurveyRowData[] = data
            .map((providerDetails) => {
                const encounterFormIds = providerDetails.cases.map((c) => parseInt(c.encounterFormId));
                const surveys = allSurveys.filter((s) =>
                    encounterFormIds.includes(parseInt(s.relatedEncounterFormId as any)),
                );

                const result: ByProviderTableSurveyRowData = {
                    provider: providerDetails.provider.providerName,
                    encounterFormIds,
                    surveys,
                    total: surveys.length,
                    totalCompleted: surveys.filter(
                        (s) => s.surveyState.surveyStateCode === SurveyStateCodes.SURVEY_COMPLETED,
                    ).length,
                    totalStarted: surveys.filter(
                        (s) => s.surveyState.surveyStateCode === SurveyStateCodes.SURVEY_STARTED,
                    ).length,
                    totalOpened: surveys.filter((s) => s.surveyState.surveyStateCode === SurveyStateCodes.SURVEY_OPENED)
                        .length,
                    data: this.getSurveyAverages(surveys, surveyDefinitionContent),
                };
                return result;
            })
            .sort((a, b) => a.provider.localeCompare(b.provider));

        const noProviderEncFormIds = this.complianceData.casesWithoutProvider.map((c) => parseInt(c.encounterFormId));
        const noProviderSurveys = allSurveys.filter((s) =>
            noProviderEncFormIds.includes(parseInt(s.relatedEncounterFormId as any)),
        );
        const noProviderTableData: ByProviderTableSurveyRowData = {
            provider: 'No Provider Specified',
            encounterFormIds: noProviderEncFormIds,
            surveys: noProviderSurveys,
            total: noProviderSurveys.length,
            totalCompleted: noProviderSurveys.filter(
                (s) => s.surveyState.surveyStateCode === SurveyStateCodes.SURVEY_COMPLETED,
            ).length,
            totalStarted: noProviderSurveys.filter(
                (s) => s.surveyState.surveyStateCode === SurveyStateCodes.SURVEY_STARTED,
            ).length,
            totalOpened: noProviderSurveys.filter(
                (s) => s.surveyState.surveyStateCode === SurveyStateCodes.SURVEY_OPENED,
            ).length,
            data: this.getSurveyAverages(noProviderSurveys, surveyDefinitionContent),
        };

        return [noProviderTableData, ...providerTableData];
    }

    @computed
    get casesTableData() {
        let providerCases: MacraCaseData[] = [];

        if (!this.selectedProviderValue || this.selectedProviderValue == -1) {
            providerCases = this.getCasesForProvider(null);
        } else {
            const selectedProvider = this.providers.find(
                (provider) => provider.providerId == this.selectedProviderValue,
            );

            if (selectedProvider) {
                providerCases = this.getCasesForProvider(selectedProvider.providerName);
            }
        }

        return providerCases
            .map((macraCase) => {
                const evalResult =
                    this.valueMode === ValueMode.Projected
                        ? macraCase.qcdrEvalResultProjected
                        : macraCase.qcdrEvalResult;

                if (!evalResult) return;

                const formattedDate = format(parseISO(this.startDate), 'yyyy');
                if (evalResult.qcdrVersion.startsWith(`ABG.${formattedDate}`)) {
                    const measureData = evalResult.measures.reduce(
                        (obj, curr, index) => obj.set(curr.name, curr),
                        new Map<string, MacraMeasure>(),
                    );

                    const measureDataObj: any = {};
                    measureData.forEach((value, key) => (measureDataObj[key] = value));

                    return {
                        evalResult,
                        case: macraCase,
                        ...measureDataObj,
                    } as CasesTableRowData;
                }
            })
            .filter((value) => value != undefined);
    }

    getMeasureMeanByMeasureName(means: AbgMeasureCalculatedMean[], measureName: string): ComplianceDetails {
        const measureMean = means.find((calculatedMean) => calculatedMean.measureDefinition.name == measureName);

        return measureMean != null
            ? { value: measureMean.mean, n: measureMean.numerator, d: measureMean.denominator }
            : { value: 0, n: 0, d: 0 };
    }

    getAverage = (arr: number[]) => Math.round((arr.reduce((p, c) => p + c, 0) / arr.length) * 100) / 100;

    getSurveyAverages(surveys: Survey[], content: SurveyDefinitionContent) {
        // Get the survey results data
        const data: (number | string)[] = [];
        content.fields.forEach((f) => {
            // Check if survey has data for the field
            const validSurveyResponsesForField = surveys
                .filter((s) => s.responseData[f.name])
                .map((s) => s.responseData[f.name]);
            data.push(validSurveyResponsesForField.length === 0 ? '-' : this.getAverage(validSurveyResponsesForField));
        });
        return data;
    }

    getCasesForProvider(providerName: string) {
        if (providerName) {
            return this.complianceData.casesByAnesProvider.get(providerName).cases;
        } else {
            return this.complianceData.casesWithoutProvider;
        }
    }
}

export const MacraStoreObject = new MacraStore();

export default createContext(MacraStoreObject);
