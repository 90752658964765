import React, { useState, useContext, useEffect, ReactNode } from 'react';
import styles from './styles.module.scss';
import SideModal from 'src/components/SideModal';
import Input, { Label } from 'src/components/Input';
import TimePicker from 'src/components/TimePicker';
import FacilityDropDown from 'src/components/FacilityDropDown';
import EventStore from 'src/stores/EventStore';
import DatePicker from 'src/components/DatePicker';
import { format } from 'date-fns';
import PatientDropDown from 'src/components/PatientDropDown';
import { parseError, getPatientName } from 'src/utils';
import { Patient } from 'src/stores/PatientStore';
import ModalFooter from 'src/components/ModalFooter';
import ModalHeader from 'src/components/ModalHeader';
import UserStore from 'src/stores/UserStore';
import { ToastStoreObject } from 'src/stores/ToastStore';
import Flex from 'src/components/Flex';
import EventAssigneeDropDown from '../EventAssigneeDropDown';
import OptionDropDown, { OptionTypes } from '../OptionDropDown';
import { API_DATE_FORMAT } from 'src/utils/constants';
import { ANALYTICS_NAMES } from 'src/utils/analytics';
import { Event } from 'src/utils/types';

export interface CreateEventModalProps {
    type: 'task' | 'appointment';
    onClose: Function;
    onSave: Function;
    title?: ReactNode;
    patientId?: number;
    patient?: Patient;
    facilityId?: number;
}

function CreateEventModal(props: CreateEventModalProps) {
    const isTask = props.type === 'task';
    const [isLoading, setIsLoading] = useState(false);
    const userStore = useContext(UserStore);
    const eventStore = useContext(EventStore);
    const [event, setEvent] = useState<Event & { selectedPatient?: Patient }>({
        eventName: props.patient ? `${getPatientName(props.patient)}` : '',
        eventDescription: '',
        eventLocation: '',
        eventStartDate: format(new Date(), API_DATE_FORMAT),
        eventStartTime: '12:30:00',
        eventEndDate: format(new Date(), API_DATE_FORMAT),
        eventEndTime: '13:00:00',
        eventTypeCode: undefined,
        assigneeTeamId: undefined,
        assigneeUsrId: userStore.orgUsrId,
        patientId: props.patientId ? props.patientId : undefined,
        facId: props.facilityId ? props.facilityId : eventStore.selectedFacilityId,
        eventClassCode: isTask ? 'TASK' : 'APPOINTMENT',
        selectedPatient: props.patient,
    });

    useEffect(() => {
        if (!event.assigneeUsrId && userStore.orgUsrId) {
            setEvent({ ...event, assigneeUsrId: userStore.orgUsrId });
        }
    }, [userStore.orgUsrId]);

    const updateInput = (param: string) => (t: string) => setEvent({ ...event, [param]: t });
    const updateTime = (param: string) => (t: string) => setEvent({ ...event, [param]: t });

    async function save() {
        const eventData = { ...event };
        delete eventData.selectedPatient;

        if (!event.eventName) {
            if (event.selectedPatient || props.patient) {
                eventData.eventName = getPatientName(event.selectedPatient || props.patient);
            } else {
                return;
            }
        }
        // TODO: Validate start time and date is before end time and date

        if (!event.eventTypeCode) {
            return;
        }
        if (!isTask && !event.patientId) {
            return;
        }

        try {
            setIsLoading(true);
            await eventStore.createEvent(eventData);
            props.onSave();
        } catch (error) {
            ToastStoreObject.show(parseError(error));
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <SideModal isOpen={true} onClose={props.onClose}>
            <ModalHeader
                title={props.title ? props.title : isTask ? 'New Task' : 'New Appointment'}
                onClose={props.onClose}
            />
            <div className={styles.inviteModalContentWrap}>
                <div>
                    <Label text="Facility" required={true} />
                    <FacilityDropDown
                        data-test-id={ANALYTICS_NAMES.CreateEventModal_Input_Facility}
                        isDisabled={true}
                        selectedValue={event.facId}
                        onChange={(s: any) => setEvent({ ...event, facId: s.value })}
                    />
                </div>
                <div>
                    <Label text="Assignee User or Team" required={true} />
                    <EventAssigneeDropDown
                        data-test-id={ANALYTICS_NAMES.CreateEventModal_Input_Assignee}
                        facId={event.facId}
                        selectedAssigneeType={event.assigneeTeamId ? 'team' : 'user'}
                        selectedValue={event.assigneeUsrId || event.assigneeTeamId}
                        onChange={(s: any, assigneeType: 'team' | 'user') =>
                            setEvent({
                                ...event,
                                [assigneeType === 'team' ? 'assigneeTeamId' : 'assigneeUsrId']: s.value,
                                [assigneeType !== 'team' ? 'assigneeTeamId' : 'assigneeUsrId']: undefined,
                            })
                        }
                    />
                </div>
                <div>
                    <Label text="Type" required={true} />
                    <OptionDropDown
                        data-test-id={ANALYTICS_NAMES.CreateEventModal_Input_Type}
                        facId={event.facId}
                        type={isTask ? OptionTypes.TaskTypes : OptionTypes.AppointmentTypes}
                        onChange={(data: any) => setEvent({ ...event, eventTypeCode: data.value })}
                    />
                </div>
                <Input
                    data-test-id={ANALYTICS_NAMES.CreateEventModal_Input_Name}
                    className={styles.input}
                    label="Event Name"
                    required={isTask}
                    value={event.eventName}
                    onChangeText={updateInput('eventName')}
                />
                <Input
                    data-test-id={ANALYTICS_NAMES.CreateEventModal_Input_Description}
                    className={styles.input}
                    label="Event Description"
                    value={event.eventDescription}
                    onChangeText={updateInput('eventDescription')}
                />
                {/* Hide the patient field if it's filled in and not editable */}
                {!props.patientId ? (
                    <div>
                        <Label text="Patient" required={!isTask} />
                        <PatientDropDown
                            data-test-id={ANALYTICS_NAMES.CreateEventModal_Input_Patient}
                            selectedValue={event.patientId || undefined}
                            facId={event.facId}
                            onChange={(patient: Patient) =>
                                setEvent({
                                    ...event,
                                    selectedPatient: patient,
                                    patientId: patient ? patient.patientId : undefined,
                                })
                            }
                        />
                    </div>
                ) : null}
                {!isTask ? (
                    <Input
                        data-test-id={ANALYTICS_NAMES.CreateEventModal_Input_Location}
                        className={styles.input}
                        label="Event Location"
                        value={event.eventLocation}
                        onChangeText={updateInput('eventLocation')}
                    />
                ) : null}
                <Flex direction="row" value={1} justify="between">
                    <Flex value={1} className={styles.leftInput}>
                        <Label text="Event Start Date" />
                        <DatePicker
                            data-test-id={ANALYTICS_NAMES.CreateEventModal_Input_StartDate}
                            value={event.eventStartDate}
                            onChange={(date: any) => {
                                if (date) {
                                    const value = format(date, API_DATE_FORMAT);
                                    setEvent({ ...event, eventStartDate: value, eventEndDate: value });
                                }
                            }}
                        />
                    </Flex>
                    <Flex value={1}>
                        <Label text="Event Start Time" />
                        <TimePicker
                            data-test-id={ANALYTICS_NAMES.CreateEventModal_Input_StartTime}
                            onChange={updateTime('eventStartTime')}
                            time={event.eventStartTime}
                        />
                    </Flex>
                </Flex>
                <Flex direction="row" value={1} justify="between">
                    <Flex value={1} className={styles.leftInput}>
                        <Label text="Event End Date" />
                        <DatePicker
                            data-test-id={ANALYTICS_NAMES.CreateEventModal_Input_EndDate}
                            value={event.eventEndDate}
                            onChange={(date: any) => {
                                if (date) {
                                    const value = format(date, API_DATE_FORMAT);
                                    if (new Date(event.eventStartDate) > new Date(value)) {
                                        setEvent({ ...event, eventEndDate: event.eventStartDate });
                                    } else {
                                        setEvent({ ...event, eventEndDate: value });
                                    }
                                }
                            }}
                        />
                    </Flex>
                    <Flex value={1}>
                        <Label text="Event End Time" />
                        <TimePicker
                            data-test-id={ANALYTICS_NAMES.CreateEventModal_Input_EndTime}
                            onChange={updateTime('eventEndTime')}
                            time={event.eventEndTime}
                        />
                    </Flex>
                </Flex>
            </div>
            <ModalFooter
                primaryText="Create"
                primaryClick={save}
                primaryProps={{
                    disabled:
                        !event.eventTypeCode ||
                        (!isTask && !event.patientId) ||
                        (!event.assigneeTeamId && !event.assigneeUsrId) ||
                        !event.facId,
                }}
                isLoading={isLoading}
                secondaryClick={() => props.onClose()}
            />
        </SideModal>
    );
}

export default CreateEventModal;
