import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router';
import Table from 'src/components/Table';
import Flex from 'src/components/Flex';
import Button from 'src/components/Button';
import PageContainer from 'src/components/PageContainer';
import NavBar from 'src/containers/NavBar';
import PageContent from 'src/components/PageContent';
import FilteredSearch, { FilteredSearchQuery } from 'src/components/FilteredSearch';
import { getImportBatchFilters } from 'src/utils/filters';
import styles from './styles.module.scss';
import { CellInfo } from 'react-table';
import DateComponent from 'src/components/DateComponent';
import Icon from 'src/components/Icon';
import { ICONS, ROUTES } from 'src/utils/constants';
import { ModalStoreObject, ModalTypes } from 'src/stores/ModalStore';
import FilterStore from 'src/stores/FilterStore';
import { FilterQueryParams, ImportBatch } from 'src/utils/types';
import { ErrorStoreObject, ErrorTypes } from 'src/stores/ErrorStore';
import ImportBatchStore from 'src/stores/ImportBatchStore';
import FacilityStore from 'src/stores/FacilityStore';
import { toJS } from 'mobx';
import RecordStatus from 'src/components/RecordStatus';
import { observer } from 'mobx-react';
import Pagination from 'src/components/Table/Pagination';
import FeatureFlagStore from 'src/stores/FeatureFlagStore';
import { ANALYTICS_NAMES } from 'src/utils/analytics';

const FILTER_PAGE = 'importPage';

function Import() {
    const filterStore = useContext(FilterStore);
    const facilityStore = useContext(FacilityStore);
    const importBatchStore = useContext(ImportBatchStore);
    const importFilters: FilterQueryParams = filterStore[FILTER_PAGE];
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();
    const featureFlagStore = useContext(FeatureFlagStore);

    const formColumns = [
        {
            Header: 'NAME',
            accessor: 'importBatchTitle',
            minWidth: 150,
        },
        {
            Header: 'FACILITY',
            accessor: 'facilityId',
            minWidth: 150,
            Cell: (props: CellInfo) => {
                const fac = facilityStore.findFacility(parseInt(props.original.facilityId));
                return <span>{fac ? fac.facNm : ''}</span>;
            },
        },
        {
            Header: 'TYPE',
            accessor: 'batchType',
            minWidth: 150,
            Cell: (props: CellInfo) => {
                const template = importBatchStore.findBatchTemplate(
                    (props.original as ImportBatch).importBatchTemplateGuid,
                );
                return <span>{template && template.importBatchTemplateTitle}</span>;
            },
        },
        {
            Header: 'COUNT',
            accessor: 'batchCount',
            minWidth: 150,
            Cell: (props: CellInfo) => {
                const batchRecordStates = importBatchStore.aggregateRecordStates.filter(
                    (state: any) => state.importBatchGuid === props.original.importBatchGuid,
                );
                const recordCounts = batchRecordStates.map((state: any) => state.recordCount);
                return (
                    <span>
                        {recordCounts.reduce((a, b) => (isNaN(a) || isNaN(b) ? 'N/A' : parseInt(a) + parseInt(b)), 0)}
                    </span>
                );
            },
        },
        {
            Header: 'CREATED',
            accessor: 'createdAt',
            minWidth: 150,
            Cell: (props: CellInfo) => {
                return <DateComponent date={props.original.createdAt} />;
            },
        },
        {
            Header: 'STATUS',
            accessor: 'batchStatus',
            minWidth: 200,
            Cell: (props: CellInfo) => {
                const batchRecordStates = importBatchStore.aggregateRecordStates.filter(
                    (state: any) => state.importBatchGuid === props.original.importBatchGuid,
                );
                return <RecordStatus batchRecordStates={batchRecordStates} />;
            },
        },
        {
            Header: 'ACTIONS',
            minWidth: 200,
            style: { paddingVertical: 0, paddingHorizontal: 20 },
            Cell: (props: CellInfo) => (
                <Flex direction="row">
                    <Button
                        type="primary"
                        text="View Details"
                        onClick={() => handleViewDetails(props.original as ImportBatch)}
                        className={styles.viewDetailsButton}
                        data-test-id={ANALYTICS_NAMES.Import_ViewDetails}
                    />
                </Flex>
            ),
        },
    ];

    async function retrieveImportBatches(forceRefresh?: boolean) {
        try {
            setIsLoading(true);
            await facilityStore.getFacilities();
            await importBatchStore.getImportBatchTemplates(false, true);
            await importBatchStore.getImportBatches(importFilters, forceRefresh);
            await importBatchStore.getRecordStatesForAllBatches();
            setIsLoading(false);
        } catch (e) {
            ErrorStoreObject.setError(ErrorTypes.Loading);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        retrieveImportBatches(true);
    }, [importFilters]);

    function handleViewDetails(importBatch: ImportBatch) {
        // Reset the import batch details page to 1 so we don't get stuck on a page that doesn't exist
        filterStore.importBatchDetailsPage.pagination.page = 1;

        const state = toJS(importBatch);
        history.push(ROUTES.getString(ROUTES.ImportDetail, importBatch.importBatchGuid), state);
    }

    function handleCreateImport() {
        ModalStoreObject.showModal(ModalTypes.CreateImportModal, {
            onClose: (refresh?: boolean) => {
                if (refresh) {
                    retrieveImportBatches(true);
                }
                ModalStoreObject.hideModal();
            },
        });
    }

    function handlePaginationFilterChange(newPage: number) {
        const updatedFilters = { ...importFilters };
        updatedFilters.pagination.page = newPage;
        filterStore.setFilters(FILTER_PAGE, updatedFilters);
    }

    async function handleSearch({ query, fields, orStatement }: FilteredSearchQuery) {
        const updatedFilters = { ...importFilters };
        updatedFilters.pagination.page = 1;
        updatedFilters.query = query;
        updatedFilters.orStatement = orStatement;

        updatedFilters.filters = fields;

        filterStore.setFilters(FILTER_PAGE, updatedFilters);
    }

    return (
        <PageContainer>
            <NavBar />
            <PageContent>
                <Flex direction="row" className={styles.filterWrap}>
                    <Flex value={1}>
                        <FilteredSearch
                            initialValues={{
                                query: importFilters.query,
                                fields: importFilters.filters,
                                orStatement: importFilters.orStatement,
                            }}
                            onSearch={handleSearch}
                            searchPlaceholder={'Search by import batch name'}
                            filterOptions={getImportBatchFilters() as any}
                        />
                    </Flex>
                    {featureFlagStore.isEnabled('import-actions') ? (
                        <Button
                            leftIcon={<Icon className={styles.plusIcon} name={ICONS.PlusButton} />}
                            type="primary"
                            onClick={handleCreateImport}
                            text="New Import"
                            className={styles.newImportButton}
                            data-test-id={ANALYTICS_NAMES.Import_Create}
                        />
                    ) : null}
                </Flex>

                <div className={styles.tableWrap}>
                    <div className={styles.tableTitle}>Import Batches</div>
                    <Table
                        isLoading={isLoading}
                        columns={formColumns}
                        data={importBatchStore.importBatches}
                        noDataText="Filter your search to view import batches"
                    />
                    {/* Custom Pagination for server side data */}
                    {importBatchStore.importBatches.length > 0 ? (
                        <Pagination
                            pages={Math.ceil(importBatchStore.importBatchesCount / importFilters.pagination.limit) || 1}
                            page={importFilters.pagination.page - 1}
                            onPageChange={(page: number) => handlePaginationFilterChange(page + 1)}
                            showing={importBatchStore.importBatches.length}
                            totalRecords={importBatchStore.importBatchesCount}
                        />
                    ) : null}
                </div>
            </PageContent>
        </PageContainer>
    );
}

export default observer(Import);
