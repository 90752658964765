import { UseMutationResult, useMutation, useQueryClient } from 'react-query';
import { createFormCatalog } from 'src/api/formCatalogs';
import { FormCatalog } from 'common/lib/entity/framework/FormCatalog';
import { formCatalogsKey } from './useFormCatalogs';
import { formCatalogKey } from './useFormCatalog';

export const createFormCatalogKey = 'createFormCatalog';

export interface UseCreateFormCatalogInputVariables {
    formModelId: FormCatalog['formModelId'];
    formName: FormCatalog['formName'];
    formTitle: FormCatalog['formTitle'];
    formDescription: FormCatalog['formDescription'];
    formContent?: FormCatalog['formContent'];
    formProperties?: FormCatalog['formProperties'];
}

export const useCreateFormCatalog = (): UseMutationResult<
    FormCatalog['formCatalogId'],
    Error,
    UseCreateFormCatalogInputVariables,
    unknown
> => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationKey: [createFormCatalogKey],
        mutationFn: async (variables) => {
            const { formModelId, formName, formTitle, formDescription, formContent, formProperties } = variables;

            if (!formModelId) {
                throw new Error('Form model id is required');
            }

            if (!formName) {
                throw new Error('Form name is required');
            }

            if (!formTitle) {
                throw new Error('Form title is required');
            }

            if (!formDescription) {
                throw new Error('Form description is required');
            }

            return await createFormCatalog({
                formModelId,
                formName,
                formTitle,
                formDescription,
                formContent,
                formProperties,
            });
        },
        retry: false,
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: [formCatalogsKey] });
            await queryClient.invalidateQueries({ queryKey: [formCatalogKey] });
        },
    });
};
