import React from 'react';

const SvgShape = (props) => (
    <svg width={16} height={16} {...props} viewBox="0 0 16 16">
        <path
            d="M15.752 14.555l-4.433-4.434a6.306 6.306 0 00-.87-8.565 6.302 6.302 0 00-8.603.29 6.306 6.306 0 00-.29 8.605 6.302 6.302 0 008.563.871l4.432 4.434a.86.86 0 001.2 0 .85.85 0 000-1.2zM1.743 6.325a4.583 4.583 0 119.167 0 4.583 4.583 0 01-9.167 0z"
            fill={props.color || '#FFF'}
            fillRule="nonzero"
        />
    </svg>
);

export default SvgShape;
