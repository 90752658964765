import { UseMutationResult, useMutation, useQueryClient } from 'react-query';
import { createFormModel } from 'src/api/formModels';
import { FormModel } from 'common/lib/entity/framework/FormModel';
import { formModelsKey } from './useFormModels';

export const createFormModelKey = 'createFormModel';

export interface UseCreateFormModelInputVariables {
    modelName: FormModel['modelName'];
    modelDescription: FormModel['modelDescription'];
    modelContent: FormModel['modelContent'];
}

export const useCreateFormModel = (): UseMutationResult<
    FormModel['formModelId'],
    Error,
    UseCreateFormModelInputVariables,
    unknown
> => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationKey: [createFormModelKey],
        mutationFn: async (variables) => {
            const { modelName, modelDescription, modelContent } = variables;
            if (!modelContent) {
                throw new Error('Model content is required');
            }

            return await createFormModel({ modelName, modelDescription, modelContent });
        },
        retry: false,
        onSuccess: async (data) => {
            queryClient.invalidateQueries({ queryKey: [formModelsKey] });

            return data;
        },
    });
};
