import React, { useContext, useEffect, useState } from 'react';
import styles from './styles.module.scss';
import Flex from 'src/components/Flex';
import Button from 'src/components/Button';
import { observer } from 'mobx-react';
import PageContainer from 'src/components/PageContainer';
import NavBar from '../NavBar';
import PageContent from 'src/components/PageContent';
import Table from 'src/components/Table';
import VehicleStore, { Vehicle } from 'src/stores/VehicleStore';
import { ModalStoreObject, ModalTypes } from 'src/stores/ModalStore';
import ReferenceListStore from 'src/stores/ReferenceListStore';
import { OptionTypes } from 'src/components/OptionDropDown';
import LoadingCover from 'src/components/LoadingCover';
import { ToastStoreObject } from 'src/stores/ToastStore';
import * as utils from 'src/utils';
import Icon from 'src/components/Icon';
import { ICONS } from 'src/utils/constants';
import * as variables from 'src/styles/variables';
import NemsisErrorBanner, { NemsisErrorBannerMode } from '../NemsisErrorBanner';
import NemsisStore from 'src/stores/NemsisStore';
import TableActiveCol from 'src/components/TableActiveCol';
import { parseError } from 'src/utils';
import { ANALYTICS_NAMES } from 'src/utils/analytics';

function SettingsVehicles() {
    const vehicleStore = useContext(VehicleStore);
    const refListStore = useContext(ReferenceListStore);
    const nemsisStore = useContext(NemsisStore);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        loadVehicleData({ initial: true });
    }, []);

    async function loadVehicleData(options: { initial?: boolean } = {}) {
        try {
            setIsLoading(true);
            await Promise.all([vehicleStore.getVehicles(), ...(options.initial ? [loadRefLists()] : [])]);
        } catch (error) {
            ToastStoreObject.show(utils.parseError(error));
        } finally {
            setIsLoading(false);
        }
    }

    async function loadRefLists() {
        try {
            await refListStore.getMultipleLists([
                OptionTypes.EMSStateCertificationLicensureLevels,
                OptionTypes.EMSVehicleType,
            ]);
        } catch (error) {
            ToastStoreObject.show(utils.parseError(error));
        }
    }

    function handleAdd() {
        showModal();
    }

    function handleEdit(vehicle: Vehicle) {
        showModal(vehicle);
    }

    function showModal(vehicle: Vehicle = null) {
        ModalStoreObject.showModal(ModalTypes.VehicleModal, {
            onClose: () => ModalStoreObject.hideModal(),
            onSave: () => onSave(),
            vehicle,
        });
    }

    async function toggleActive(vehicle: Vehicle) {
        try {
            await vehicleStore.toggleActive(vehicle);
            await nemsisStore.validateDemData(true);
        } catch (error: any) {
            ToastStoreObject.show(parseError(error));
        } finally {
            ModalStoreObject.hideModal();
            await loadVehicleData();
        }
    }

    async function onSave() {
        ModalStoreObject.hideModal();
        await loadVehicleData();
    }

    function handleConfirm(vehicle: Vehicle) {
        ModalStoreObject.showModal(ModalTypes.ConfirmationModal, {
            title: `Are you sure you would like to deactivate this vehicle?`,
            onConfirm: () => toggleActive(vehicle),
            onCancel: () => ModalStoreObject.hideModal(),
            confirmButtonText: 'Deactivate',
        });
    }

    function getFacilityTypeLabel(vehicle: Vehicle) {
        const refList = refListStore.referenceListValues[OptionTypes.EMSVehicleType];

        if (!refList) return null;

        const vehicleTypeRefValue = refList.find((value) => value.listValueCode === vehicle.vehicleTypeCode);

        return vehicleTypeRefValue ? vehicleTypeRefValue.listValueDisplayName : null;
    }

    return (
        <PageContainer>
            <NavBar />
            <PageContent>
                <NemsisErrorBanner mode={NemsisErrorBannerMode.DEM} />
                <Flex self="stretch" align="center" justify="start">
                    <Flex value={1} className={styles.pageTitle}>
                        VEHICLES
                    </Flex>
                    <Button
                        leftIcon={<Icon className={styles.plusIcon} name={ICONS.PlusButton} />}
                        type="primary"
                        text="New Vehicle"
                        onClick={handleAdd}
                    />
                </Flex>
                <Table
                    className={styles.tableWrap}
                    columns={[
                        {
                            Header: 'Unit Number',
                            accessor: 'vehicleNumber',
                            minWidth: 160,
                            Cell: (cellProps) => {
                                const vehicle = cellProps.original as Vehicle;
                                return <span>{vehicle.vehicleNumber || '-'}</span>;
                            },
                        },
                        {
                            Header: 'Vehicle ID',
                            accessor: 'vehicleIdNumber',
                            minWidth: 250,
                            Cell: (cellProps) => {
                                const vehicle = cellProps.original as Vehicle;
                                return <span>{vehicle.vehicleIdNumber || '-'}</span>;
                            },
                        },
                        {
                            Header: 'Call Sign',
                            accessor: 'callSign',
                            minWidth: 200,
                            Cell: (cellProps) => {
                                const vehicle = cellProps.original as Vehicle;
                                return <span>{vehicle.callSign || '-'}</span>;
                            },
                        },
                        {
                            Header: 'Vehicle Type',
                            accessor: 'vehicleTypeCode',
                            minWidth: 250,
                            Cell: (cellProps) => {
                                const vehicle = cellProps.original as Vehicle;
                                return <span>{getFacilityTypeLabel(vehicle) || '-'}</span>;
                            },
                        },
                        {
                            Header: 'Status',
                            accessor: 'active',
                            minWidth: 100,
                            Cell: (cellProps) => {
                                const vehicle = cellProps.original as Vehicle;
                                return <TableActiveCol isActive={vehicle.active} />;
                            },
                        },
                        {
                            Header: 'ACTIONS',
                            accessor: 'actions',
                            minWidth: 300,
                            style: { paddingVertical: 0, paddingHorizontal: 20 },
                            Cell: (cellProps) => {
                                const vehicle = cellProps.original as Vehicle;
                                return (
                                    <Flex direction="row">
                                        <Button
                                            data-test-id={ANALYTICS_NAMES.Vehicles_Table_Edit}
                                            onClick={() => {
                                                handleEdit(vehicle);
                                            }}
                                            type="secondary"
                                            leftIcon={<Icon name={ICONS.Pencil2} color={variables.red} />}
                                        />
                                        {cellProps.original.active ? (
                                            <Button
                                                data-test-id={ANALYTICS_NAMES.Vehicles_Table_Deactivate}
                                                onClick={() => handleConfirm(vehicle)}
                                                type="secondary"
                                                text="Deactivate"
                                                className={styles.actionButtons}
                                            />
                                        ) : (
                                            <Button
                                                data-test-id={ANALYTICS_NAMES.Vehicles_Table_Reactivate}
                                                onClick={() => toggleActive(vehicle)}
                                                type="primary"
                                                text="Reactivate"
                                                className={styles.actionButtons}
                                            />
                                        )}
                                    </Flex>
                                );
                            },
                        },
                    ]}
                    data={vehicleStore.vehicles || []}
                />
                {isLoading ? <LoadingCover style={{ top: 90 }} /> : null}
            </PageContent>
        </PageContainer>
    );
}

export default observer(SettingsVehicles);
