import React, { useRef, useState } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import Card from 'src/components/Card';
import Button from 'src/components/Button';
import Icon from 'src/components/Icon';
import Tooltip from 'src/components/Tooltip';
import useClickOutside from 'src/utils/hooks/useClickOutside';

export interface MoreIconProps {
    options: { title: string; onClick: Function }[];
    className?: string;
}

function MoreIcon(props: MoreIconProps) {
    const buttonRef = useRef<HTMLButtonElement>(null);
    const node = useRef<HTMLDivElement>();
    const [refState, setRefState] = useState(false);

    useClickOutside(() => setRefState(false), [node, buttonRef]);

    return (
        <>
            <Button
                ref={buttonRef}
                type="transparent"
                leftIcon={<Icon type="fa" name="ellipsis-v" />}
                className={classNames(styles.actionButton, props.className)}
                onClick={() => setRefState(!refState)}
            />
            <Tooltip element={buttonRef} isOpen={refState}>
                <Card ref={node} elevation={4}>
                    {props.options.map((o) => (
                        <div key={o.title}>
                            <Button
                                text={o.title}
                                type="transparent"
                                onClick={() => {
                                    setRefState(false);
                                    o.onClick();
                                }}
                            />
                        </div>
                    ))}
                </Card>
            </Tooltip>
        </>
    );
}

export default MoreIcon;
