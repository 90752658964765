import React, { useState, useContext, useEffect } from 'react';
import styles from './styles.module.scss';
import Flex from 'src/components/Flex';
import CenterModal from 'src/components/CenterModal';
import ModalHeader from 'src/components/ModalHeader';
import ModalFooter from 'src/components/ModalFooter';
import Input from '../Input';
import PaymentsStore from 'src/stores/PaymentsStore';
import { ToastStoreObject } from 'src/stores/ToastStore';
import { parseError } from 'src/utils';
import LoadingIcon from '../LoadingIcon';

interface CancelPaymentModalProps {
    onSave: Function;
    onCancel: Function;
    paymentRequest: any;
}

const CancelPaymentModal = (props: CancelPaymentModalProps) => {
    const paymentsStore = useContext(PaymentsStore);
    const [isLoading, setIsLoading] = useState(false);
    const [cancelReasonText, setCancelReasonText] = useState('');

    async function handleCancel() {
        setIsLoading(true);
        try {
            await paymentsStore.cancelPaymentRequest(props.paymentRequest.paymentRequestGuid, cancelReasonText);
            props.onSave();
        } catch (e: any) {
            ToastStoreObject.show(parseError(e));
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <CenterModal isOpen={true} onClose={props.onCancel}>
            <div>
                <ModalHeader title="Cancel Payment Request" />
                {isLoading ? (
                    <Flex justify="center">
                        <LoadingIcon />
                    </Flex>
                ) : (
                    <Flex direction="column" className={styles.modalContent} align="start" self="stretch">
                        <p>Are you sure you want to cancel this payment request?</p>
                        <Input
                            label="Reason"
                            value={cancelReasonText}
                            onChangeText={(v: string) => setCancelReasonText(v)}
                        />
                    </Flex>
                )}
                <ModalFooter
                    primaryText="Yes"
                    primaryClick={handleCancel}
                    secondaryText="No"
                    secondaryClick={props.onCancel}
                />
            </div>
        </CenterModal>
    );
};

export default CancelPaymentModal;
