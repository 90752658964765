import { ROUTES } from './constants';
import { UserStoreObject } from 'src/stores/UserStore';

export function validateRoute(route: string) {
    const availableRoutes: { [key in string]: boolean } = {
        [ROUTES.AdminAllUsers]: UserStoreObject.adminIndicator,
        [ROUTES.AdminFlowScriptTemplates]: UserStoreObject.adminIndicator,
        [ROUTES.AdminFlowScriptTemplateDetail]: UserStoreObject.adminIndicator,
        [ROUTES.AdminImportBatchTemplates]: UserStoreObject.adminIndicator,
        [ROUTES.AdminSurveyTemplates]: UserStoreObject.adminIndicator,
        [ROUTES.Appointments]: UserStoreObject.userPermissions.canView.patientEvents,
        [ROUTES.AutomatedDispenseSystems]: UserStoreObject.userPermissions.canView.automatedDispenseSystems,
        [ROUTES.Customers]: UserStoreObject.userPermissions.canView.customers,
        [ROUTES.CustomerDetail]: UserStoreObject.userPermissions.canView.customers,
        [ROUTES.Encounters]: UserStoreObject.userPermissions.canView.patientEncounters,
        [ROUTES.ForgotPassword]: true,
        [ROUTES.FormDevelopmentDeploymentDetail]: UserStoreObject.userPermissions.canView.formDevelopment,
        [ROUTES.FormDevelopmentFormDetail]: UserStoreObject.userPermissions.canView.formDevelopment,
        [ROUTES.FormDevelopment]: UserStoreObject.userPermissions.canView.formDevelopment,
        [ROUTES.Forms]: UserStoreObject.userPermissions.canView.patientEncounters,
        [ROUTES.Home]: true,
        [ROUTES.Import]: true,
        [ROUTES.ImportDetail]: true,
        [ROUTES.ImportRecord]: true,
        [ROUTES.Invitation]: true,
        [ROUTES.Login]: true,
        [ROUTES.PatientDetails]: UserStoreObject.userPermissions.canView.patientDetails,
        [ROUTES.Patients]: UserStoreObject.userPermissions.canView.patients,
        [ROUTES.Payers]: UserStoreObject.userPermissions.canView.paymentRequests,
        [ROUTES.PayerCategories]: UserStoreObject.userPermissions.canView.paymentRequests,
        [ROUTES.PaymentCollection]: true,
        [ROUTES.PaymentCollectionStatus]: true,
        [ROUTES.PaymentGateways]: UserStoreObject.userPermissions.canView.paymentRequests,
        [ROUTES.PaymentRequestDetail]: UserStoreObject.userPermissions.canView.paymentRequests,
        [ROUTES.PaymentRequestImport]: UserStoreObject.userPermissions.canCreate.paymentRequests,
        [ROUTES.PaymentRequests]: UserStoreObject.userPermissions.canView.paymentRequests,
        [ROUTES.PaymentTypes]: UserStoreObject.userPermissions.canView.paymentRequests,
        [ROUTES.Profile]: true,
        [ROUTES.QcdrSettings]: UserStoreObject.userPermissions.canView.qcdrSettings,
        [ROUTES.QcdrSettings2023]: UserStoreObject.userPermissions.canView.qcdrSettings,
        [ROUTES.ReportsChp]: UserStoreObject.userPermissions.canView.reports,
        [ROUTES.ReportsMacra]: UserStoreObject.userPermissions.canView.macra,
        [ROUTES.ReportsMacra2023]: UserStoreObject.userPermissions.canView.macra,
        [ROUTES.Reset]: true,
        [ROUTES.SettingsDevices]: UserStoreObject.userPermissions.canEdit.nemsis,
        [ROUTES.SettingsFacilities]: UserStoreObject.userPermissions.canView.facilities,
        [ROUTES.SettingsNemsis]: UserStoreObject.userPermissions.canEdit.nemsis,
        [ROUTES.SettingsProviders]: UserStoreObject.userPermissions.canView.providers,
        [ROUTES.SettingsRefListDetails]: UserStoreObject.userPermissions.canEdit.referenceListValues,
        [ROUTES.SettingsRefLists]:
            UserStoreObject.userPermissions.canEdit.referenceLists ||
            UserStoreObject.userPermissions.canEdit.valueSetDetails,
        [ROUTES.SettingsTags]: UserStoreObject.userPermissions.canView.tags,
        [ROUTES.SettingsTeams]: UserStoreObject.userPermissions.canView.teams,
        [ROUTES.SettingsTransportFacilities]: UserStoreObject.userPermissions.canEdit.nemsis,
        [ROUTES.SettingsUsers]: UserStoreObject.userPermissions.canView.users,
        [ROUTES.SettingsValueSetDetails]: UserStoreObject.userPermissions.canView.valueSets,
        [ROUTES.SettingsVehicles]: UserStoreObject.userPermissions.canEdit.nemsis,
        [ROUTES.SurveyDefinitions]: UserStoreObject.userPermissions.canView.surveyDefinitions,
        [ROUTES.SurveyDefinitionDetails]: UserStoreObject.userPermissions.canView.surveyDefinitionDetails,
        [ROUTES.Surveys]: UserStoreObject.userPermissions.canView.surveyResults,
        [ROUTES.TelehealthSession]: true,
        [ROUTES.TelehealthVisits]: true,
        [ROUTES.TelehealthWaitingRoom]: true,
        [ROUTES.ViewEncounterForm]:
            !UserStoreObject.userPermissions.hasBeenSet ||
            (UserStoreObject.userPermissions.hasBeenSet && UserStoreObject.userPermissions.canView.patients),
        [ROUTES.VitalsConnections]: true,
    };

    const isValid = availableRoutes[route];
    if (!isValid) {
        console.log('invalid route or not allowed: ', route);
    }

    return isValid;
}
