import * as ROUTES from 'src/api/routes';
import request from 'src/api/utils';
import * as utils from 'src/utils';

export async function sendUserInvite(invitationData: any) {
    return await request({ ...ROUTES.SEND_USER_INVITE, data: invitationData });
}

export async function resendUserInvite(invite: any) {
    return await request({ ...ROUTES.RESEND_USER_INVITE, pathParams: { inviteId: invite.inviteId } });
}

export async function cancelUserInvite(invite: any) {
    return await request({ ...ROUTES.CANCEL_USER_INVITE, pathParams: { inviteId: invite.inviteId } });
}

export async function getInvitations(params: object = {}) {
    const invitations = await request({ ...ROUTES.GET_INVITATIONS, params });
    return {
        result: invitations.data.result || [],
        count: invitations.data.count || 0,
    };
}

export async function getOrgNameFromInvite(inviteKey: string) {
    const orgName = await request({ ...ROUTES.GET_ORG_NAME_FROM_INVITE, pathParams: { inviteKey } });
    return orgName;
}

export async function acceptInviteNewUser(
    inviteKey: string,
    data: { username: string; firstName: string; lastName: string; password: string },
) {
    const orgName = await request({ ...ROUTES.ACCEPT_INVITE_NEW_USER, pathParams: { inviteKey }, data });
    return orgName;
}

export async function acceptInviteExistingUser(inviteKey: string, userId: number) {
    const orgName = await request({ ...ROUTES.ACCEPT_INVITE_EXISTING_USER, pathParams: { inviteKey, userId } });
    return orgName;
}
