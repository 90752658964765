import React, { useState, useContext, useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import NavBar from 'src/containers/NavBar';
import PageContent from 'src/components/PageContent';
import PageContainer from 'src/components/PageContainer';
import { FlowScriptTemplate, FlowScriptTemplateHistory } from 'src/utils/types';
import { ErrorStoreObject, ErrorTypes } from 'src/stores/ErrorStore';
import Flex from 'src/components/Flex';
import Button from 'src/components/Button';
import styles from './styles.module.scss';
import CodeEditor from 'src/components/CodeEditor';
import TableActiveCol from 'src/components/TableActiveCol';
import { CellInfo } from 'react-table';
import Table from 'src/components/Table';
import Input, { Label } from 'src/components/Input';
import { ModalStoreObject, ModalTypes } from 'src/stores/ModalStore';
import * as variables from 'src/styles/variables';
import * as adminService from 'src/api/admin';
import DateComponent from 'src/components/DateComponent';
import Tag from 'src/components/Tag';
import { ANALYTICS_NAMES } from 'src/utils/analytics';
import { ROUTES } from 'src/utils/constants';

interface PublishedVersionHistory {
    createdAt: Date;
    fromVersion: number;
    toVersion: number;
}

function AdminFlowScriptTemplateDetail() {
    const history = useHistory();
    const flowScriptTemplateGuid = useParams<{ flowScriptTemplateGuid: string }>().flowScriptTemplateGuid;
    const [isLoading, setIsLoading] = useState(false);
    const [template, setTemplate] = useState<FlowScriptTemplate>(null);
    const [currentTemplate, setCurrentTemplate] = useState<FlowScriptTemplateHistory>(null);
    const [scriptContent, setScriptContent] = useState<FlowScriptTemplateHistory['scriptContent']>('');
    const [flowScriptTemplateHistoryNote, setFlowScriptTemplateHistoryNote] = useState<
        FlowScriptTemplateHistory['flowScriptTemplateHistoryNote']
    >('');
    const [defaultHandler, setDefaultHandler] = useState<FlowScriptTemplateHistory['defaultHandler']>('');

    async function retrieveTemplate() {
        try {
            setIsLoading(true);
            const flowScriptTemplate = await adminService.getFlowScriptTemplate(flowScriptTemplateGuid);
            setTemplate(flowScriptTemplate);
            const currentFlowScriptTemplate = flowScriptTemplate.histories.find(
                (h: FlowScriptTemplateHistory) => h.flowScriptTemplateVersion === flowScriptTemplate.publishedVersion,
            );
            setCurrentTemplate(currentFlowScriptTemplate);
            setScriptContent(currentFlowScriptTemplate.scriptContent);
            setDefaultHandler(currentFlowScriptTemplate.defaultHandler);
            setFlowScriptTemplateHistoryNote(currentFlowScriptTemplate.flowScriptTemplateHistoryNote);
            setIsLoading(false);
        } catch (e) {
            ErrorStoreObject.setError(ErrorTypes.Loading);
        }
    }

    async function handlePublish(templateVersion: number) {
        try {
            setIsLoading(true);
            const updatedTemplate = await adminService.publishFlowScriptTemplateVersion(
                template.flowScriptTemplateGuid,
                templateVersion,
            );
            ModalStoreObject.hideModal();
            setTemplate(updatedTemplate);
            setIsLoading(false);
        } catch (e) {
            ErrorStoreObject.setError(ErrorTypes.Loading);
        }
    }

    async function handleSave() {
        try {
            setIsLoading(true);
            const updatedHistory = await adminService.updateFlowScriptTemplateHistory(
                template.flowScriptTemplateGuid,
                currentTemplate.flowScriptTemplateHistoryId,
                { scriptContent, defaultHandler, flowScriptTemplateHistoryNote },
            );
            setCurrentTemplate(updatedHistory);
            setScriptContent(updatedHistory.scriptContent);
            setDefaultHandler(updatedHistory.defaultHandler);
            setFlowScriptTemplateHistoryNote(updatedHistory.flowScriptTemplateHistoryNote);
            await retrieveTemplate();
            setIsLoading(false);
        } catch (e) {
            ErrorStoreObject.setError(ErrorTypes.Loading);
        }
    }

    async function createNewHistory() {
        try {
            setIsLoading(true);
            await adminService.createNewFlowScriptTemplateHistory(template.flowScriptTemplateGuid);
            await retrieveTemplate();
            setIsLoading(false);
        } catch (e) {
            ErrorStoreObject.setError(ErrorTypes.Loading);
        }
    }

    function handleBack() {
        history.push(ROUTES.getString(ROUTES.AdminFlowScriptTemplates));
    }

    function isActiveRow(state: any, rowInfo: any, instance: any) {
        if (
            currentTemplate &&
            rowInfo.original.flowScriptTemplateVersion === currentTemplate.flowScriptTemplateVersion
        ) {
            return {
                style: {
                    background: variables.offWhite,
                },
            };
        }
        return {};
    }

    function getTemplateNavigation() {
        const templates = template ? template.histories : [];
        const sortedTemplates = templates.sort((a: FlowScriptTemplateHistory, b: FlowScriptTemplateHistory) => {
            if (a.flowScriptTemplateVersion < b.flowScriptTemplateVersion) return 1;
            if (a.flowScriptTemplateVersion > b.flowScriptTemplateVersion) return -1;

            return 0;
        });
        const columns = [
            {
                Header: 'VERSION',
                accessor: 'flowScriptTemplateVersion',
                minWidth: 50,
            },
            {
                Header: 'UPDATED',
                accessor: 'updatedAt',
                minWidth: 100,
                Cell: (props: CellInfo) => {
                    return (
                        <div>
                            <DateComponent date={props.value} showDateTime={true} />
                            <div className={styles.smallText}>Note: {props.original.flowScriptTemplateHistoryNote}</div>
                        </div>
                    );
                },
            },
            {
                Header: 'ACTIVE',
                accessor: 'flowScriptTemplateVersion',
                minWidth: 50,
                Cell: (props: CellInfo) => <TableActiveCol isActive={props.value === template.publishedVersion} />,
            },
            {
                Header: 'ACTIONS',
                accessor: 'actions',
                sortable: false,
                style: { paddingVertical: 0, paddingHorizontal: 20 },
                Cell: (props: CellInfo) => {
                    const hasBeenPublished = template.publishedVersionHistory.find(
                        (version: PublishedVersionHistory) =>
                            version.toVersion === props.original.flowScriptTemplateVersion ||
                            version.fromVersion === props.original.flowScriptTemplateVersion,
                    );
                    return (
                        <div className={styles.actionWrap}>
                            <Button
                                onClick={() => {
                                    ModalStoreObject.showModal(ModalTypes.ConfirmationModal, {
                                        title:
                                            'Are you sure you want to publish? You will not be able to edit this version after it is published.',
                                        onConfirm: () => handlePublish(props.original.flowScriptTemplateVersion),
                                        onCancel: () => ModalStoreObject.hideModal(),
                                        confirmButtonText: 'Publish',
                                        cancelButtonText: 'Cancel',
                                    });
                                }}
                                className={styles.leftButton}
                                disabled={template.publishedVersion === props.original.flowScriptTemplateVersion}
                                type="secondarySmall"
                                text="Publish"
                                data-test-id={ANALYTICS_NAMES.FlowScriptTemplateDetail_Publish}
                            />
                            <Button
                                onClick={() => {
                                    setCurrentTemplate(props.original);
                                    setScriptContent(props.original.scriptContent);
                                    setDefaultHandler(props.original.defaultHandler);
                                    setFlowScriptTemplateHistoryNote(props.original.flowScriptTemplateHistoryNote);
                                }}
                                type="small"
                                text={`${hasBeenPublished ? 'View' : 'Edit'} »`}
                                data-test-id={
                                    hasBeenPublished
                                        ? ANALYTICS_NAMES.FlowScriptTemplateDetail_View
                                        : ANALYTICS_NAMES.FlowScriptTemplateDetail_Edit
                                }
                            />
                        </div>
                    );
                },
            },
        ];
        return <Table isLoading={isLoading} columns={columns} data={sortedTemplates} getTrProps={isActiveRow} />;
    }

    function getEditor() {
        if (currentTemplate) {
            const hasBeenPublished = template.publishedVersionHistory.find(
                (version: PublishedVersionHistory) =>
                    version.toVersion === currentTemplate.flowScriptTemplateVersion ||
                    version.fromVersion === currentTemplate.flowScriptTemplateVersion,
            );
            return (
                <div className={styles.currentTemplate}>
                    <Input
                        label="Template Version Note"
                        value={flowScriptTemplateHistoryNote}
                        onChangeText={(v: string) => setFlowScriptTemplateHistoryNote(v)}
                        readOnly={hasBeenPublished ? true : false}
                    />
                    <Input
                        label="Default Handler"
                        value={defaultHandler}
                        onChangeText={(v: string) => setDefaultHandler(v)}
                        readOnly={hasBeenPublished ? true : false}
                    />
                    <div className={styles.labelWrap}>
                        <Label text="Script Content" />
                    </div>
                    <CodeEditor
                        value={scriptContent}
                        readOnly={hasBeenPublished ? true : false}
                        onChange={(v: string) => setScriptContent(v)}
                    />
                    <Flex justify="end" className={styles.templateControls}>
                        {hasBeenPublished ? null : (
                            <Button
                                type="primary"
                                onClick={handleSave}
                                text="Save"
                                data-test-id={ANALYTICS_NAMES.FlowScriptTemplateDetail_Save}
                            />
                        )}
                    </Flex>
                </div>
            );
        }

        return null;
    }

    useEffect(() => {
        retrieveTemplate();
    }, []);

    return (
        <PageContainer>
            <NavBar />
            <PageContent>
                <div
                    className={styles.backWrap}
                    onClick={() => handleBack()}
                    data-test-id={ANALYTICS_NAMES.FlowScriptTemplateDetail_Back}
                >
                    <span className={styles.backArrow}>{`< `}</span>Back to Template List
                </div>
                <Flex align="center">
                    <Flex value={1} className={styles.pageTitle}>
                        {template ? template.flowScriptTemplateTitle : ''}
                    </Flex>
                    <Flex></Flex>
                </Flex>
                <Flex className={styles.batchContent}>
                    <Flex value={2} className={styles.tableWrap}>
                        <Flex className={styles.tableTitle} align="center">
                            <Flex value={1}>Template Versions</Flex>
                            <Button
                                type="primary"
                                text="New Version"
                                onClick={createNewHistory}
                                data-test-id={ANALYTICS_NAMES.FlowScriptTemplateDetail_NewVersion}
                            />
                        </Flex>
                        {getTemplateNavigation()}
                    </Flex>
                    <Flex value={3} className={styles.tableWrap}>
                        <Flex className={styles.tableTitle} align="center">
                            <Flex value={1}>Template</Flex>
                            <div className={styles.versionNumber}>
                                <Tag
                                    color="red"
                                    text={`Version #${
                                        currentTemplate ? currentTemplate.flowScriptTemplateVersion.toString() : ''
                                    }`}
                                />
                            </div>
                        </Flex>
                        {getEditor()}
                    </Flex>
                </Flex>
            </PageContent>
        </PageContainer>
    );
}
export default AdminFlowScriptTemplateDetail;
