import React from 'react';

const SvgPictureStackHuman = (props) => (
    <svg width={22} height={18} {...props} viewBox="0 0 22 18">
        <g
            transform="translate(1 1)"
            stroke={props.color || '#FFF'}
            strokeWidth={1.35}
            fill="none"
            fillRule="evenodd"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <ellipse cx={7.955} cy={5.667} rx={2.386} ry={2.333} />
            <path d="M3.864 12.667c0-2.21 1.831-4 4.09-4 2.26 0 4.091 1.79 4.091 4H3.864z" />
            <rect x={0.455} y={0.667} width={15} height={14.667} rx={2.7} />
            <path d="M16.818.667c1.506 0 2.727 1.194 2.727 2.666v9.334c0 1.472-1.22 2.666-2.727 2.666" />
        </g>
    </svg>
);

export default SvgPictureStackHuman;
