import React, { useRef, RefObject, useState, useEffect, useContext } from 'react';
import Portal from '../Portal';
import styles from './styles.module.scss';
import classNames from 'classnames';
import Flex from 'src/components/Flex';
import Card from 'src/components/Card';
import Input from 'src/components/Input';
import useClickOutside from 'src/utils/hooks/useClickOutside';
import OrgStore, { Organization } from 'src/stores/OrgStore';
import UserStore from 'src/stores/UserStore';
import { useHistory } from 'react-router';

export interface OrgSelectPanelProps {
    isOpen: boolean;
    onClose: Function;
    parentRef: RefObject<HTMLDivElement>;
}

export function OrgItem(props: { item: any; onSelect: Function }) {
    const { item } = props;
    return (
        <Flex direction="row" align="center" className={styles.resultsRow} onClick={() => props.onSelect(item)}>
            <Flex direction="column" align="start" justify="center">
                <div className={styles.name}>{item.orgNm}</div>
            </Flex>
        </Flex>
    );
}

function OrgSelectPanel(props: OrgSelectPanelProps) {
    if (!props.isOpen) {
        return null;
    }

    const node = useRef<HTMLDivElement>();
    const orgStore = useContext(OrgStore);
    const userStore = useContext(UserStore);
    const [orgs, setOrgs] = useState(orgStore.orgs);
    const [filteredOrg, setFilteredOrg] = useState([]);
    const [searchText, setSearchText] = useState('');
    const history = useHistory();
    useClickOutside(() => {
        props.onClose();
    }, [node, props.parentRef]);

    useEffect(() => {
        if (orgStore.orgs.length === 0) {
            getOrgs();
        }
    }, []);
    useEffect(() => {
        const activeOrgs = orgStore.orgs.filter((org: Organization) => org.actvInd);
        setOrgs(activeOrgs);
    }, [orgStore.orgs]);
    useEffect(() => {
        setFilteredOrg(orgs.filter((o) => o.orgNm.toLowerCase().includes(searchText.toLowerCase())));
    }, [searchText]);

    async function getOrgs() {
        await orgStore.getOrgsForUser();
    }

    function handleSelect(org: any) {
        userStore.changeOrg(org, history);
        props.onClose();
    }

    return (
        <Portal node={props.parentRef.current}>
            <div ref={node} className={classNames(styles.panelWrap)}>
                <div className={styles.triangle} />
                <Card elevation={4}>
                    <Flex direction="column" className={styles.searchWrap}>
                        {orgs.length > 10 ? (
                            <Input
                                className={styles.searchInput}
                                value={searchText}
                                placeholder="Search"
                                onChangeText={(t: string) => setSearchText(t)}
                            />
                        ) : null}
                        <Flex value={1} className={styles.pageTitle}>
                            <div className={styles.resultsWrap}>
                                {(searchText ? filteredOrg : orgs).map((org: Organization) => (
                                    <OrgItem key={org.orgId} item={org} onSelect={handleSelect} />
                                ))}
                            </div>
                        </Flex>
                    </Flex>
                </Card>
            </div>
        </Portal>
    );
}

export default OrgSelectPanel;
