import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import Footer from 'src/components/Footer';

export interface PageContainerProps extends React.HTMLAttributes<HTMLDivElement> {
    allowScaling?: boolean;
}

const PageContainer: React.SFC<PageContainerProps> = ({ children, className, allowScaling, ...rest }) => {
    return (
        <div {...rest} className={classNames(styles.container, { [styles.fixedWidth]: !allowScaling }, className)}>
            {children}
            <Footer />
        </div>
    );
};

export default PageContainer;
