import { observable, action, makeObservable } from 'mobx';
import { createContext } from 'react';
import * as referenceListAPI from 'src/api/referenceList';
import * as utils from 'src/utils';
import { FilterQueryParams } from 'src/utils/types';

export interface ReferenceListExtendedProperty {
    propertyName?: string;
    propertyDisplayName?: string;
    propertyType?: string;
    propertyDescription?: string;
    required?: boolean;
    active?: boolean;
}

export interface ReferenceList {
    listId?: number;
    listName?: string;
    //value set///////////
    facility?: Facility;
    valSetId?: number;
    valueSetName?: string;
    valueSetDesc?: string;
    //////////////////////
    listDisplayName?: string;
    listDescription?: string;
    adminRoleList?: number[];
    listExtendedProperties?: ReferenceListExtendedProperty[];
    actvInd?: boolean;
}

export interface Facility {
    facNm: string;
}

export interface SelectComponent {
    value: string | number;
    label: string;
}

export interface ReferenceListValue {
    listValueId?: number;
    listId?: number;
    listValueCode?: string;
    listValueDisplayName?: string;
    listValueDescription?: string;
    listValueExtendedProperties?: { [index: string]: any };
    facilityDisplayExcludeList?: number[];
    actvInd?: boolean;
}

export interface ReferenceListFilter {
    filterName: string;
    filterValue: any;
}

export enum LocalOptions {
    yesNo = 'yesNo',
    AccountOptions = 'AccountOptions',
    TelehealthPriority = 'TelehealthPriority',
    PreviousYears = 'PreviousYears',
}
export enum RefListTypes {
    Race = 'Race',
    Ethnicity = 'Ethnicity',
    Gender = 'Gender',
    Language = 'Language',
    PersonPhoneNumberTypes = 'PersonPhoneNumberTypes',
    PersonEmailAddressTypes = 'PersonEmailAddressTypes',
    PersonAddressTypes = 'PersonAddressTypes',
    PatientNeedTypes = 'PatientNeedTypes',
    PatientContactRelationship = 'PatientContactRelationship',
    AppointmentCancelationReasonTypes = 'AppointmentCancelationReasonTypes',
    TaskCancelationReasonTypes = 'TaskCancelationReasonTypes',
    TaskTypes = 'TaskTypes',
    AppointmentTypes = 'AppointmentTypes',
    PatientNoteTypes = 'PatientNoteTypes',
    MedicationUnitsOfMeasure = 'MedicationUnitsOfMeasure',
    MedicationRouteTypes = 'MedicationRouteTypes',
    MedicationFrequencyTypes = 'MedicationFrequencyTypes',
    MedicationDirectedUsageTypes = 'MedicationDirectedUsageTypes',
    CHPVisitTypes = 'CHPVisitTypes',
    ProviderTypes = 'ProviderTypes',
    MilitaryServiceStatusCodes = 'MilitaryServiceStatusCodes',
    MilitaryServiceBranches = 'MilitaryServiceBranches',
    MilitaryDischargeStatusCodes = 'MilitaryDischargeStatusCodes',
    CensusTracts = 'CensusTracts',
    EMSTypeOfService = 'EMSTypeOfService',
    EMSStateCertificationLicensureLevels = 'EMSStateCertificationLicensureLevels',
    EMSOrganizationStatus = 'EMSOrganizationStatus',
    EMSOrganizationalType = 'EMSOrganizationalType',
    EMSAgencyOrganizationalTaxStatus = 'EMSAgencyOrganizationalTaxStatus',
    EMSAgencyTimeZone = 'EMSAgencyTimeZone',
    EMSYesNoValues = 'EMSYesNoValues',
    EMSAgencyContactType = 'EMSAgencyContactType',
    EMSANSICountryCodesCAMXUS = 'EMSANSICountryCodesCAMXUS',
    EMSPhoneNumberType = 'EMSPhoneNumberType',
    EMSEmailAddressType = 'EMSEmailAddressType',
    EMSAgencyMedicalDirectorDegree = 'EMSAgencyMedicalDirectorDegree',
    EMSAgencyMedicalDirectorBoardCertificationType = 'EMSAgencyMedicalDirectorBoardCertificationType',
    EMSMedicalDirectorCompensation = 'EMSMedicalDirectorCompensation',
    EMSLicensureLevels = 'EMSLicensureLevels',
    EMSProtocolsUsed = 'EMSProtocolsUsed',
    EMSAgencySpecialtyServiceCapability = 'EMSAgencySpecialtyServiceCapability',
    EMSEMDtoAgencyServiceArea = 'EMSEMDtoAgencyServiceArea',
    EMSPatientMonitoringCapability = 'EMSPatientMonitoringCapability',
    EMSVehicleType = 'EMSVehicleType',
    EMSDistanceUnits = 'EMSDistanceUnits',
    EMSMedicalDeviceType = 'EMSMedicalDeviceType',
    EMSTypeOfFacility = 'EMSTypeOfFacility',
    EMSHospitalDesignation = 'EMSHospitalDesignation',
    EMSDemographicRace = 'EMSDemographicRace',
    EMSPersonnelHighestEducationalDegree = 'EMSPersonnelHighestEducationalDegree',
    EMSPersonnelDegreeFieldofStudy = 'EMSPersonnelDegreeFieldofStudy',
    EMSPersonnelVehicleLicenseType = 'EMSPersonnelVehicleLicenseType',
    EMSPersonnelForeignLanguageAbility = 'EMSPersonnelForeignLanguageAbility',
    EMSImmunizationType = 'EMSImmunizationType',
    EMSMemberLevel = 'EMSMemberLevel',
    EMSNationalRegistryCertificationLevel = 'EMSNationalRegistryCertificationLevel',
    EMSEmploymentStatus = 'EMSEmploymentStatus',
    EMSJobResponsibilities = 'EMSJobResponsibilities',
    EMSLocationType = 'EMSLocationType',
    EMSProceduresPermittedByState = 'EMSProceduresPermittedByState',
    EMSMedicationsPermittedByState = 'EMSMedicationsPermittedByState',
    EMSAgencyProcedures = 'EMSAgencyProcedures',
    EMSAgencyMedications = 'EMSAgencyMedications',
    EMSGender = 'EMSGender',
    ANSIStateCodes = 'GHGLOBAL:ANSIStateCodes',
    ANSICountyCodes = 'GHGLOBAL:ANSICountyCodes',
    ANSICityCodes = 'GHGLOBAL:ANSICityCodes',
    ZipCodes = 'GHGLOBAL:ZipCodes',
    ANSICountryCodes = 'GHGLOBAL:ANSICountryCodes',
    RxNormCodes = 'GHGLOBAL:RxNormCodes',
}

export interface IANSICountyExtendedProperties {
    countyName: string;
    nemsisCode: string;
    stateAnsiCode: string;
    countyAnsiCode: string;
    nemsisCodeType: string;
    stateAbbreviation: string;
}

export function getRefListDropdownValues(property: ReferenceListValue[]) {
    return utils
        .formatSelectOptions(property, {
            valueKey: 'listValueCode',
            labelKey: 'listValueDisplayName',
        })
        .sort((a, b) => (a.label > b.label ? 1 : -1));
}

function getCityDropdownValues(property: ReferenceListValue[]) {
    return utils
        .formatSelectOptions(property, {
            valueKey: 'listValueCode',
            getLabel: (rlv: ReferenceListValue) =>
                `${rlv.listValueDisplayName} (${rlv.listValueExtendedProperties.countyName})`,
        })
        .sort((a, b) => (a.label > b.label ? 1 : -1));
}

class ReferenceListStore {
    @observable referenceLists: ReferenceList[] = [];
    @observable totalListCount: number = 0;
    @observable referenceListValues: { [key in RefListTypes]?: ReferenceListValue[] } = {};
    @observable referenceListDropdownValues: { [key in RefListTypes]?: SelectComponent[] } = {};
    @observable loadingTypes: { [key in RefListTypes]?: boolean } = {};
    @observable loadingFilteredTypes: Map<string, boolean> = new Map();
    @observable filteredReferenceListValues: Map<string, ReferenceListValue[]> = new Map();
    @observable filteredReferenceListDropdownValues: Map<string, SelectComponent[]> = new Map();

    constructor() {
        makeObservable(this);
    }

    @action
    reset() {
        this.referenceLists = [];
        this.referenceListValues = {};
        this.referenceListDropdownValues = {};
        this.loadingTypes = {};
        this.loadingFilteredTypes.clear();
        this.filteredReferenceListValues.clear();
        this.filteredReferenceListDropdownValues.clear();
    }

    @action
    async getReferenceLists(includeValueSets: boolean, params?: { query?: string }) {
        const referenceLists = await referenceListAPI.getReferenceLists(includeValueSets, params);
        this.setReferenceLists(referenceLists);
    }

    @action
    async getMultipleLists(types: RefListTypes[]) {
        try {
            types.forEach((t) => {
                this.loadingTypes[t] = true;
            });
            const results = await referenceListAPI.getReferenceListsWithValues({ listNames: types });
            const data = results.data || {};

            Object.keys(data).map((type: any) => {
                if (types.includes(type)) {
                    this.setValues(data[type] || [], type);
                }
            });
        } finally {
            types.forEach((t) => {
                this.loadingTypes[t] = false;
            });
        }
    }

    @action
    async getData(type: RefListTypes, force: boolean = false) {
        // Don't reload type data if we already have it
        if (!force && (this.referenceListValues[type] || []).length > 0) {
            return;
        }

        if (this.loadingTypes[type] && !force) {
            // This is currently loading, don't do anything
            return;
        }
        try {
            this.loadingTypes[type] = true;
            const results = await referenceListAPI.getReferenceListValues(type);
            this.setValues(results, type);
        } finally {
            this.loadingTypes[type] = false;
        }
    }

    @action
    async getFilteredData(type: RefListTypes, extendedPropertyFilter: ReferenceListFilter) {
        const indexString = `${type}-${extendedPropertyFilter.filterValue}`;

        if (this.filteredReferenceListValues.has(indexString)) return this.filteredReferenceListValues.get(indexString);

        if (this.loadingFilteredTypes.has(indexString)) {
            // This is currently loading, don't do anything
            return;
        }
        try {
            this.loadingFilteredTypes.set(indexString, true);
            const results = await referenceListAPI.getReferenceListValues(type, { ...extendedPropertyFilter });
            this.setFilteredValues(results, type, extendedPropertyFilter);
            return results.data;
        } finally {
            this.loadingFilteredTypes.set(indexString, false);
        }
    }

    @action
    setReferenceLists(referenceLists: ReferenceList[], total: number = 0) {
        this.referenceLists = referenceLists || [];
        this.totalListCount = referenceLists.length;
    }

    @action
    setValues(data: ReferenceListValue[], type: RefListTypes) {
        this.referenceListValues[type] = data;
        this.referenceListDropdownValues[type] = getRefListDropdownValues(data);
    }

    @action
    setFilteredValues(data: ReferenceListValue[], type: RefListTypes, extendedPropertyFilter: ReferenceListFilter) {
        const indexString = `${type}-${extendedPropertyFilter.filterValue}`;

        const dropdownValues =
            type === RefListTypes.ANSICityCodes ? getCityDropdownValues(data) : getRefListDropdownValues(data);

        this.filteredReferenceListValues.set(indexString, data);
        this.filteredReferenceListDropdownValues.set(indexString, dropdownValues);
    }

    @action
    resetType(type: string) {
        if (type in RefListTypes) {
            delete this.referenceListValues[type as RefListTypes];
            delete this.referenceListDropdownValues[type as RefListTypes];
        }
    }
}

export const ReferenceListStoreObject = new ReferenceListStore();

export default createContext(ReferenceListStoreObject);
