import React from 'react';
import Button from 'src/components/Button';
import Icon from 'src/components/Icon';
import styles from './styles.module.scss';
import { ICONS } from 'src/utils/constants';
import * as variables from 'src/styles/variables';

export default function ModalCloseButton({ onClose }: { onClose: Function }) {
    return (
        <Button
            type="transparent"
            leftIcon={<Icon name={ICONS.Close} color={variables.black} className={styles.icon} />}
            onClick={() => onClose()}
            className={styles.button}
        />
    );
}
