import { UseMutationResult, useMutation, useQueryClient } from 'react-query';
import { updateFormModel } from 'src/api/formModels';
import { FormModel } from 'common/lib/entity/framework/FormModel';
import { formModelsKey } from './useFormModels';

export const updateFormModelKey = 'updateFormModel';

export interface UseUpdateFormModelInputVariables {
    formModelId: FormModel['formModelId'];
    modelName: FormModel['modelName'];
    modelDescription: FormModel['modelDescription'];
    modelContent: FormModel['modelContent'];
}

export const useUpdateFormModel = (): UseMutationResult<void, Error, UseUpdateFormModelInputVariables, unknown> => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationKey: [updateFormModelKey],
        mutationFn: async (variables) => {
            const { formModelId, modelName, modelDescription, modelContent } = variables;
            if (!modelContent) {
                throw new Error('Model content is required');
            }

            return await updateFormModel(formModelId, { modelName, modelDescription, modelContent });
        },
        retry: false,
        onSuccess: async (data) => {
            queryClient.invalidateQueries({ queryKey: [formModelsKey] });

            return data;
        },
    });
};
