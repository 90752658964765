import React, { useContext, useState, useEffect } from 'react';
import Flex from 'src/components/Flex';
import PageContainer from 'src/components/PageContainer';
import PageContent from 'src/components/PageContent';
import Button from 'src/components/Button';
import NavBar from 'src/containers/NavBar';
import Card from 'src/components/Card';
import styles from './styles.module.scss';
import Table from 'src/components/Table';
import FeatureFlagStore from 'src/stores/FeatureFlagStore';
import Icon from 'src/components/Icon';
import { ICONS } from 'src/utils/constants';
import { CellInfo } from 'react-table';
import DateComponent from 'src/components/DateComponent';
import PaymentsStore, { PayerCategory } from 'src/stores/PaymentsStore';
import ActionMenu from 'src/components/ActionMenu';
import { ModalStoreObject, ModalTypes } from 'src/stores/ModalStore';
import TableActiveCol from 'src/components/TableActiveCol';
import { ANALYTICS_NAMES } from 'src/utils/analytics';
import { observer } from 'mobx-react';
import { ToastStoreObject } from 'src/stores/ToastStore';
import { parseError } from 'src/utils';

function PayerCategories() {
    const paymentsStore = useContext(PaymentsStore);
    const featureFlagStore = useContext(FeatureFlagStore);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        getPayerCategories(true);
    }, []);

    const columns = [
        {
            Header: 'NAME',
            accessor: 'payerCategoryTitle',
        },
        {
            Header: 'DESCRIPTION',
            accessor: 'payerCategoryDescription',
        },
        {
            Header: 'CODE',
            accessor: 'payerCategoryCode',
        },
        {
            Header: 'CREATED',
            accessor: 'createdAt',
            Cell: (props: CellInfo) => {
                return <DateComponent date={props.value} />;
            },
        },
        {
            Header: 'UPDATED',
            accessor: 'updatedAt',
            Cell: (props: CellInfo) => {
                return <DateComponent date={props.value} />;
            },
        },
        {
            Header: 'ACTIVE',
            accessor: 'isActive',
            minWidth: 50,
            Cell: (props: CellInfo) => <TableActiveCol isActive={props.value.toString() === 'true'} />,
        },
        {
            Header: 'ACTIONS',
            accessor: 'actions',
            maxWidth: 125,
            Cell: (props: CellInfo) => (
                <ActionMenu
                    entityType="payerCategory"
                    entity={props.original}
                    onItemClick={(action: { id: string; label: string }) => handleActionClick(action, props.original)}
                />
            ),
        },
    ];

    async function getPayerCategories(force = false) {
        setIsLoading(true);

        try {
            await paymentsStore.getPayerCategories(true, force);
        } catch (e) {
            ToastStoreObject.show(parseError(e));
        } finally {
            setIsLoading(false);
        }
    }

    function handleActionClick(action: { id: string; label: string }, item: PayerCategory) {
        switch (action.id) {
            case 'edit':
                ModalStoreObject.showModal(ModalTypes.PayerCategoryModal, {
                    onClose: () => ModalStoreObject.hideModal(),
                    onSave: () => {
                        getPayerCategories(true);
                        ModalStoreObject.hideModal();
                    },
                    payerCategory: item,
                    mode: 'edit',
                });
                break;
            case 'activate':
                if (item.isActive) {
                    ModalStoreObject.showModal(ModalTypes.ConfirmationModal, {
                        title: 'Are you sure you would like to deactivate this payer category?',
                        onConfirm: () => handleDeactivate(item.payerCategoryId),
                        onCancel: () => ModalStoreObject.hideModal(),
                        confirmButtonText: 'Deactivate',
                    });
                } else {
                    handleReactivate(item.payerCategoryId);
                }
                break;
        }
    }

    function handleCreate() {
        ModalStoreObject.showModal(ModalTypes.PayerCategoryModal, {
            onClose: () => ModalStoreObject.hideModal(),
            onSave: () => {
                getPayerCategories(true);
                ModalStoreObject.hideModal();
            },
        });
    }

    async function handleDeactivate(payerCategoryId: PayerCategory['payerCategoryId']) {
        setIsLoading(true);
        ModalStoreObject.hideModal();

        try {
            await paymentsStore.deactivatePayerCategory(payerCategoryId);
            getPayerCategories(true);
        } catch (e) {
            ToastStoreObject.show(parseError(e));
        } finally {
            setIsLoading(false);
        }
    }

    async function handleReactivate(payerCategoryId: PayerCategory['payerCategoryId']) {
        setIsLoading(true);
        ModalStoreObject.hideModal();

        try {
            await paymentsStore.reactivatePayerCategory(payerCategoryId);
            getPayerCategories(true);
        } catch (e) {
            ToastStoreObject.show(parseError(e));
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <PageContainer>
            <NavBar />
            <PageContent>
                <Flex direction="row" justify="end" className={styles.filterWrap}>
                    {featureFlagStore.isEnabled('payments') ? (
                        <Button
                            leftIcon={<Icon className={styles.plusIcon} name={ICONS.PlusButton} />}
                            type="primary"
                            onClick={() => handleCreate()}
                            text="Create Payer Category"
                            className={styles.createButton}
                            data-test-id={ANALYTICS_NAMES.PayerCategory_Create}
                        />
                    ) : null}
                </Flex>
                <Card className={styles.cardWrap}>
                    <div className={styles.tableTitle}>Payer Categories</div>
                    <Table
                        isLoading={isLoading}
                        columns={columns}
                        data={paymentsStore.payerCategories}
                        showPagination={false}
                    />
                </Card>
            </PageContent>
        </PageContainer>
    );
}

export default observer(PayerCategories);
