import React, { Component, createRef } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import Icon from 'src/components/Icon';
import * as variables from 'src/styles/variables';
import { ANALYTICS_NAMES } from 'src/utils/analytics';

export interface InputProps extends React.HTMLAttributes<HTMLInputElement | HTMLTextAreaElement> {
    placeholder?: string;
    disabled?: boolean;
    value?: string | number;
    label?: string;
    onChangeText?: Function;
    type?: string;
    readOnly?: boolean;
    className?: string;
    wrapClassName?: string;
    multiLine?: boolean;
    rows?: number;
    infoState?: 'error' | 'warning';
    name?: string;
    errorMessage?: string;
    icon?: string;
    iconColor?: string;
    iconSize?: number;
    required?: boolean;
    step?: number;
    autoFocus?: boolean;
    autoSize?: boolean;
    disableBorder?: boolean;
    maxLength?: number;
}

export function Label({ text, className, required }: { text: string; className?: string; required?: boolean }) {
    return (
        <div className={classNames(styles.label, className)}>
            {text.toUpperCase()}
            {required ? <span className={styles.required}>*</span> : null}
        </div>
    );
}

const AUTOSIZE_LIMIT = 250;
class Input extends Component<InputProps, {}> {
    private input = createRef<HTMLInputElement>();

    clear() {
        if (this.input.current) {
            this.input.current.value = '';
        }
    }
    focus() {
        if (this.input.current) {
            this.input.current.focus();
        }
    }
    blur() {
        if (this.input.current) {
            this.input.current.blur();
        }
    }
    handleKeyDown(e: any) {
        // Autosize the text area
        e.target.style.height = 'inherit';
        e.target.style.height = `${Math.min(e.target.scrollHeight, AUTOSIZE_LIMIT)}px`;
    }
    handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (this.props.onChangeText) {
            this.props.onChangeText(e.target.value, e);
        } else if (this.props.onChange) {
            this.props.onChange(e);
        }
    };
    renderInput = () => {
        const {
            disabled,
            className,
            wrapClassName,
            value,
            infoState,
            icon,
            iconSize,
            iconColor,
            errorMessage,
            type,
            disableBorder,
            ...rest
        } = this.props;
        if (type !== 'number') {
            delete rest.step;
        }
        delete rest.multiLine;
        delete rest.onChangeText;
        delete rest.label;
        delete rest.required;
        delete rest.autoSize;
        return (
            <div
                className={classNames(
                    styles.inputWrap,
                    {
                        [styles.disabled]: disabled,
                        [styles.error]: infoState === 'error' || errorMessage,
                        [styles.warning]: infoState === 'warning',
                        [styles.row]: icon,
                        [styles.noBorder]: disableBorder,
                    },
                    wrapClassName,
                )}
            >
                {icon ? (
                    <Icon name={icon} color={iconColor || variables.white} size={iconSize} className={styles.icon} />
                ) : null}
                <input
                    ref={this.input}
                    {...rest}
                    type={type || 'text'}
                    value={value === null ? '' : value}
                    disabled={disabled}
                    onChange={this.handleChange}
                    className={classNames(styles.input, className)}
                />
            </div>
        );
    };

    render() {
        const {
            multiLine,
            className,
            wrapClassName,
            label,
            disabled,
            value,
            errorMessage,
            required,
            autoSize,
            ...rest
        } = this.props;
        // Remove this property from the props while rendering
        delete rest.onChangeText;
        if (multiLine) {
            return (
                <div>
                    {label ? <Label text={label} required={required} /> : null}
                    <div
                        className={classNames(
                            styles.inputWrap,
                            {
                                [styles.disabled]: disabled,
                                [styles.error]: this.props.infoState === 'error',
                                [styles.warning]: this.props.infoState === 'warning',
                            },
                            wrapClassName,
                        )}
                    >
                        <textarea
                            {...rest}
                            onKeyDown={autoSize ? this.handleKeyDown : undefined}
                            className={classNames(styles.textArea, className)}
                            onChange={this.handleChange}
                            value={value === null ? '' : value}
                        />
                    </div>
                    {errorMessage ? <div className={styles.fieldError}>{errorMessage}</div> : null}
                </div>
            );
        }
        if (label) {
            return (
                <div className={styles.labelWrap}>
                    <Label text={label} required={required} />
                    {this.renderInput()}
                    {errorMessage ? <div className={styles.fieldError}>{errorMessage}</div> : null}
                </div>
            );
        }
        return (
            <>
                {this.renderInput()}
                {errorMessage ? <div className={styles.fieldError}>{errorMessage}</div> : null}
            </>
        );
    }
}
export default Input;
