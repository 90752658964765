import React from 'react';

const SvgCheckCircle = (props) => (
    <svg width={22} height={22} {...props} viewBox="0 0 22 22">
        <g
            stroke={props.color || '#FFF'}
            strokeWidth={1.395}
            fill="none"
            fillRule="evenodd"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path d="M6.625 8.5l2.745 3.842a1.272 1.272 0 002.012.075l8.993-10.793M14.986 4.187a8.74 8.74 0 103.347 3.794" />
        </g>
    </svg>
);

export default SvgCheckCircle;
