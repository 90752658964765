import { MacraAvailableYears } from 'common/lib/model/macra/Macra';
import * as ROUTES from 'src/api/routes';
import request from 'src/api/utils';
import { Facility } from 'src/stores/FacilityStore';

export type MacraQueryParams = {
    id: string | number;
    startDate: string;
    endDate: string;
    isTin?: boolean;
    enforceRollup?: boolean;
};

export async function getMacraData(year: number, params: MacraQueryParams) {
    const response = await request({ ...ROUTES.MACRA_GET_DATA, pathParams: { year }, params });
    return response;
}

export async function getProviderSurveys(year: number, data: { id: string | number; encFormIds: (string | number)[] }) {
    const response = await request({ ...ROUTES.MACRA_GET_PROVIDER_SURVEYS, pathParams: { year }, data });
    return response;
}

export async function getMacraResultsFromS3(url: string) {
    const response = await request({ url, method: 'get', anonymous: true, external: true });
    return response;
}

export async function getQcdrSettings(year: number) {
    const response = await request({ ...ROUTES.MACRA_GET_SETTINGS, pathParams: { year } });
    return response;
}

export async function saveQcdrSettings(year: number, step: number, settings: object) {
    const response = await request({
        ...ROUTES.MACRA_SAVE_SETTINGS,
        pathParams: { year },
        data: settings,
        params: { step },
    });
    return response;
}

export async function saveMacraSignature(year: number, signature: string) {
    const response = await request({ ...ROUTES.MACRA_SAVE_SIGNATURE, pathParams: { year }, data: { signature } });
    return response;
}

export async function getMacraSignature(year: number) {
    const response = await request({ ...ROUTES.MACRA_GET_SIGNATURE, pathParams: { year } });
    return response;
}

export async function getQcdrExport(params: {
    startDate: string;
    endDate: string;
    facilityId: string | number;
    isTin: boolean;
    year: MacraAvailableYears;
}) {
    const { startDate, endDate, facilityId, isTin, year } = params;
    const url = await request({
        ...ROUTES.MACRA_GET_QCDR_EXPORT,
        params: { startDate, endDate, facilityId, isTin, year },
    });
    return url.data;
}

export async function downloadQcdrExport(url: string) {
    const response = await request({ url, method: 'get', anonymous: true, external: true });
    return response;
}

export async function getAqiExport(params: {
    practiceId: string;
    taxId: string;
    startDate: string;
    endDate: string;
    facilityId: string | number;
    year: number;
}) {
    const url = await request({
        ...ROUTES.MACRA_GET_AQI_EXPORT,
        data: params,
    });
    return url.data;
}

export async function downloadAqiExport(url: string) {
    const response = await request({ url, method: 'get', anonymous: true, external: true });
    return response;
}

export async function getAqiOptions() {
    const response = await request({ ...ROUTES.MACRA_GET_AQI_OPTIONS });
    return response.data || {};
}
