import React from 'react';

const SvgNavigationMenu2 = (props) => (
    <svg width={16} height={14} {...props} viewBox="0 0 16 14">
        <g
            transform="translate(1 1)"
            stroke={props.color || '#FFF'}
            fill="none"
            fillRule="evenodd"
            opacity={0.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <rect x={3.938} y={0.439} width={9.625} height={2.625} rx={0.9} />
            <rect x={2.188} y={4.689} width={9.625} height={2.625} rx={0.9} />
            <rect x={0.438} y={8.939} width={9.625} height={2.625} rx={0.9} />
        </g>
    </svg>
);

export default SvgNavigationMenu2;
