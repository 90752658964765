import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router';
import styles from './styles.module.scss';
import Flex from 'src/components/Flex';
import Button from 'src/components/Button';
import graphiumLogo from 'public/assets/graphium-logo-light-background.svg';
import { parse } from 'query-string';
import { PaymentRequestContact } from 'src/stores/PaymentsStore';
import { getPaymentRequestContact, unsubscribePaymentRequestContact } from 'src/api/payments';
import { parseError } from 'src/utils';
import LoadingIcon from 'src/components/LoadingIcon';

function PaymentRequestContactUnsubscribe() {
    const location = useLocation();
    const queryStrings = parse(location.search);
    const paymentRequestContactGuid = queryStrings.paymentRequestContactGuid as string;

    const [paymentRequestContact, setPaymentRequestContact] = useState<PaymentRequestContact | null>(null);
    const [unsubscribeState, setUnsubscribeState] = useState('loading');
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        loadPaymentRequestContact();
    }, []);

    async function loadPaymentRequestContact() {
        try {
            const result = await getPaymentRequestContact(paymentRequestContactGuid);
            validateContact(result);
            setPaymentRequestContact(result || {});
            setUnsubscribeState('ask');
        } catch (e) {
            setErrorMessage(parseError(e));
            setUnsubscribeState('error');
        }
    }

    function validateContact(contact: PaymentRequestContact) {
        if (!contact) {
            throw new Error('Could not find email to unsubscribe');
        }
        if (contact.contactType !== 'EMAIL') {
            throw new Error('Texts cannot be unsubscribed through this page. Please reply "STOP" to the text');
        }
    }

    async function handleUnsubscribe() {
        try {
            await unsubscribePaymentRequestContact(paymentRequestContactGuid);
            setUnsubscribeState('unsubscribed');
        } catch (e) {
            setErrorMessage(parseError(e));
            setUnsubscribeState('error');
        }
    }

    function getContent() {
        switch (unsubscribeState) {
            case 'loading':
                return (
                    <Flex align="center" justify="center">
                        <LoadingIcon />
                    </Flex>
                );
            case 'error':
                return (
                    <Flex className={styles.innerContainer} direction="column">
                        <div className={styles.title}>Error</div>
                        <div className={styles.text}>{errorMessage}</div>
                    </Flex>
                );
            case 'ask':
                return (
                    <Flex className={styles.innerContainer} direction="column">
                        <div className={styles.title}>Unsubscribe?</div>
                        <div className={styles.text}>
                            Are you sure you want to unsubscribe "{paymentRequestContact?.contactInfo}"?
                        </div>
                        <Button onClick={handleUnsubscribe} text="Yes" type="primary" className={styles.payButton} />
                    </Flex>
                );
            case 'unsubscribed':
                return (
                    <Flex className={styles.innerContainer} direction="column">
                        <div className={styles.title}>Unsubscribed</div>
                        <div className={styles.text}>You have been unsubscribed</div>
                    </Flex>
                );
        }
    }
    return (
        <Flex align="center" direction="column" className={styles.pageContainer}>
            <Flex className={styles.paymentContainer} direction="column">
                <div className={styles.logo}>
                    <div className={styles.logoContainer}>
                        <img src={graphiumLogo} />
                    </div>
                </div>
                {getContent()}
            </Flex>
            <div className={styles.copyright}>
                &copy; {`${new Date().getFullYear()} Graphium Health`}, All rights reserved
            </div>
        </Flex>
    );
}

export default PaymentRequestContactUnsubscribe;
