import React, { useState, useEffect } from 'react';
import NavBar from 'src/containers/NavBar';
import PageContent from 'src/components/PageContent';
import PageContainer from 'src/components/PageContainer';
import Card from 'src/components/Card';
import Flex from 'src/components/Flex';
import Table from 'src/components/Table';
import styles from './styles.module.scss';
import { CellInfo } from 'react-table';
import Button from 'src/components/Button';
import { ErrorStoreObject, ErrorTypes } from 'src/stores/ErrorStore';
import TableActiveCol from 'src/components/TableActiveCol';
import { getImportBatchTemplates } from 'src/api/importBatch';
import { ModalStoreObject, ModalTypes } from 'src/stores/ModalStore';
import { ANALYTICS_NAMES } from 'src/utils/analytics';

function AdminImportBatchTemplates() {
    const columns = [
        {
            Header: 'TITLE',
            accessor: 'importBatchTemplateTitle',
            minWidth: 150,
        },
        {
            Header: 'DESCRIPTION',
            accessor: 'importBatchTemplateDescription',
            minWidth: 200,
        },
        {
            Header: 'REVIEW BEFORE PROCESS?',
            accessor: 'reviewBeforeProcess',
            Cell: (props: CellInfo) => <TableActiveCol isActive={props.value} activeText="YES" inactiveText="NO" />,
        },
        {
            Header: 'ACTIVE',
            accessor: 'active',
            minWidth: 75,
            Cell: (props: CellInfo) => <TableActiveCol isActive={props.value} />,
        },
        {
            Header: 'ACTIONS',
            accessor: 'actions',
            sortable: false,
            minWidth: 50,
            style: { paddingVertical: 0, paddingHorizontal: 20 },
            Cell: (props: CellInfo) => (
                <div className={styles.actionWrap}>
                    <Button
                        onClick={() => {
                            ModalStoreObject.showModal(ModalTypes.CreateImportBatchTemplate, {
                                onClose: () => ModalStoreObject.hideModal(),
                                onSave: () => {
                                    retrieveTemplates();
                                    ModalStoreObject.hideModal();
                                },
                                isEdit: true,
                                importBatchTemplate: props.original,
                            });
                        }}
                        type="secondarySmall"
                        text="Edit"
                        data-test-id={ANALYTICS_NAMES.ImportBatchTemplates_Edit}
                    />
                </div>
            ),
        },
    ];

    const [isLoading, setIsLoading] = useState(false);
    const [templates, setTemplates] = useState<any[]>([]);

    async function retrieveTemplates() {
        try {
            setIsLoading(true);
            const templateData = await getImportBatchTemplates();
            setTemplates(templateData);
            setIsLoading(false);
        } catch (e) {
            ErrorStoreObject.setError(ErrorTypes.Loading);
        }
    }
    useEffect(() => {
        retrieveTemplates();
    }, []);

    function createImportBatchTemplate() {
        ModalStoreObject.showModal(ModalTypes.CreateImportBatchTemplate, {
            onClose: () => ModalStoreObject.hideModal(),
            onSave: () => {
                retrieveTemplates();
                ModalStoreObject.hideModal();
            },
        });
    }

    return (
        <PageContainer>
            <NavBar />
            <PageContent>
                <Flex direction="row" self="stretch" align="center" justify="start">
                    <Flex value={1} />
                    <Button
                        type="primary"
                        onClick={createImportBatchTemplate}
                        text="Create Template"
                        data-test-id={ANALYTICS_NAMES.ImportBatchTemplates_CreateTemplate}
                    />
                </Flex>
                <Card className={styles.cardWrap}>
                    <div className={styles.tableTitle}>Import Batch Templates</div>
                    <Table isLoading={isLoading} columns={columns} data={templates} showPagination={false} />
                </Card>
            </PageContent>
        </PageContainer>
    );
}
export default AdminImportBatchTemplates;
