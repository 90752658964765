import React from 'react';

const SvgLoveItSearch = (props) => (
    <svg width={18} height={19} {...props} viewBox="0 0 18 19">
        <g
            transform="translate(1 1)"
            stroke={props.color || '#FFF'}
            strokeWidth={1.3}
            fill="none"
            fillRule="evenodd"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <ellipse cx={10.98} cy={11.156} rx={3.661} ry={3.719} />
            <path d="M16.21 16.469l-2.641-2.683M5.234 11.156L1.682 7.391A4.066 4.066 0 01.94 2.753h0A3.96 3.96 0 013.845.583a3.916 3.916 0 013.43 1.126l1.092 1.109L9.46 1.709a3.916 3.916 0 013.43-1.126 3.96 3.96 0 012.905 2.17h0a4.079 4.079 0 01-.014 3.622" />
        </g>
    </svg>
);

export default SvgLoveItSearch;
