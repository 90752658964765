import React from 'react';

const SvgPencil2 = (props) => (
    <svg width={19} height={19} {...props} viewBox="0 0 19 19">
        <g
            stroke={props.color || '#FFF'}
            strokeWidth={1.2}
            fill="none"
            fillRule="evenodd"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path d="M17.569 1.436a2.875 2.875 0 00-4.083.028L2.035 12.912.594 18.406l5.495-1.44L17.541 5.518a2.873 2.873 0 00.028-4.082h0zM13.158 1.792l4.055 4.053M11.505 3.445l4.054 4.053" />
            <path d="M2.035 12.912l2.842 1.213 1.216 2.837M13.532 5.471l-8.655 8.653" />
        </g>
    </svg>
);

export default SvgPencil2;
