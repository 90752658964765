import React, { useContext, useEffect, useState } from 'react';
import Flex from 'src/components/Flex';
import styles from './styles.module.scss';
import { observer } from 'mobx-react';
import FacilityDropDown from 'src/components/FacilityDropDown';
import { Facility } from 'src/stores/FacilityStore';
import PaymentTypeDropDown from 'src/components/PaymentTypeDropDown';
import PaymentsStore, { PaymentType } from 'src/stores/PaymentsStore';
import { Label } from 'src/components/Input';

interface FacilitySelectProps {
    onChange?: Function;
}

function FacilitySelect(props: FacilitySelectProps) {
    const [selectedFacility, setSelectedFacility] = useState<Facility['facId']>(null);
    const [selectedType, setSelectedType] = useState<PaymentType['paymentTypeId']>(null);
    const paymentsStore = useContext(PaymentsStore);

    useEffect(() => {
        if (props.onChange) {
            props.onChange({ selectedFacility, selectedType });
        }
    }, [selectedFacility, selectedType]);

    async function handleFacilityChange(value: any) {
        setSelectedFacility(value);
        await paymentsStore.getPaymentTypes({ force: true, facilityId: value });
        if (!paymentsStore.paymentTypes.find((t) => t.paymentTypeId === selectedType)) {
            setSelectedType(null);
        }
    }

    return (
        <Flex direction="column">
            <div className={styles.stepTitle}>Select a Facility and Payment Type</div>
            <div className={styles.selectContainer}>
                <>
                    <Label text="Facility" />
                    <FacilityDropDown onChange={(f: any) => handleFacilityChange(f.value)} />
                </>
                <>
                    <Label text="Payment Type" />
                    <PaymentTypeDropDown selectedValue={selectedType} onChange={(t: any) => setSelectedType(t.value)} />
                </>
            </div>
        </Flex>
    );
}

export default observer(FacilitySelect);
