import { UseQueryResult, useQuery } from 'react-query';
import { fetchFacilitiesByOrg } from 'src/api/facilities';
import { UserStoreObject } from 'src/stores/UserStore';
import { Org } from 'common/lib/entity/index/Org';
import { Facility } from 'common/lib/entity/org/Facility';

export const facilitiesByOrgKey = 'facilitiesByOrg';

export const useFacilitiesByOrg = (orgNmIntrnl: Org['orgNmIntrnl']): UseQueryResult<Facility[]> => {
    return useQuery({
        queryKey: [facilitiesByOrgKey],
        queryFn: async () => {
            return await fetchFacilitiesByOrg(orgNmIntrnl);
        },
        enabled: UserStoreObject.isGraphiumAdmin(),
        initialData: [],
    });
};
