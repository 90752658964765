import React from 'react';
import Flex from 'src/components/Flex';
import styles from './styles.module.scss';
import Checkbox from 'src/components/Checkbox';
import { OrgUserWithColor } from 'src/stores/OrgUserStore';

interface Props {
    user: OrgUserWithColor;
    isChecked: boolean;
    count: number;
    onChange: (b: boolean) => any;
}

function UserFilterItem(props: Props) {
    return (
        <Flex direction="row" align="center" className={styles.userItem}>
            <Flex value={1}>
                <Checkbox
                    checked={props.isChecked}
                    onChange={(e) => props.onChange(e.target.checked)}
                    label={`${props.user.frstNm} ${props.user.lastNm}`}
                />
            </Flex>
            <Flex
                align="center"
                justify="center"
                className={styles.userColorBlock}
                style={{ backgroundColor: props.user.color.active }}
            >
                {props.count > 99 ? 99 : props.count}
            </Flex>
        </Flex>
    );
}
export default UserFilterItem;
