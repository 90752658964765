import React, { useContext, useEffect, useState } from 'react';
import styles from './styles.module.scss';
import Input, { Label } from 'src/components/Input';
import { PayerCategory, PaymentGateway, PaymentType } from 'src/stores/PaymentsStore';
import SideModal from 'src/components/SideModal';
import ModalHeader from 'src/components/ModalHeader';
import ModalFooter from 'src/components/ModalFooter';
import { ANALYTICS_NAMES } from 'src/utils/analytics';
import PaymentGatewayDropDown from 'src/components/PaymentGatewayDropDown';
import PaymentsStore from 'src/stores/PaymentsStore';
import { ToastStoreObject } from 'src/stores/ToastStore';
import { parseError } from 'src/utils';
import Checkbox from 'src/components/Checkbox';
import Toggle from 'src/components/Toggle';
import Flex from 'src/components/Flex';
import { PayerCategoryMapping, PaymentTypeConfig } from 'common/lib/model/payment/paymentTypeConfig';
import FacilityDropDown from '../FacilityDropDown';

interface PaymentTypeModalProps {
    paymentType?: PaymentType;
    payerCategories?: PayerCategory[];
    mode?: string;
    onClose?: Function;
    onSave?: Function;
}

const DEFAULT_CONFIG: PaymentTypeConfig = {
    mode: 'FIXED_AMOUNT',
    defaultAmount: 150,
};

function PaymentTypeModal(props: PaymentTypeModalProps) {
    const paymentsStore = useContext(PaymentsStore);
    const [isLoading, setIsLoading] = useState(false);
    const [title, setTitle] = useState<PaymentType['paymentTypeTitle']>(
        props.paymentType ? props.paymentType.paymentTypeTitle : '',
    );
    const [description, setDescription] = useState<PaymentType['paymentTypeDescription']>(
        props.paymentType ? props.paymentType.paymentTypeDescription : '',
    );
    const [paymentGateway, setPaymentGateway] = useState<PaymentType['paymentGatewayId']>(
        props.paymentType ? props.paymentType.paymentGatewayId : null,
    );

    // Config State
    const configToUse: PaymentTypeConfig =
        props.paymentType && props.paymentType.paymentTypeConfig ? props.paymentType.paymentTypeConfig : DEFAULT_CONFIG;
    const [mode, setMode] = useState(configToUse.mode);
    const [defaultAmount, setDefaultAmount] = useState(configToUse.defaultAmount);
    const [resendEnabled, setResendEnabled] = useState(configToUse.resendEnabled || false);
    const [resendInterval, setResendInterval] = useState(configToUse.resendInterval || 1);
    const [resendExpireDays, setResendExpireDays] = useState(configToUse.resendExpireDays || 30);
    const [isOrgWide, setIsOrgWide] = useState(props.paymentType ? props.paymentType.isOrgWide : false);
    // TODO: Fix this in the service so we don't have to parseInt
    const [facilityIds, setFacilityIds] = useState(
        props.paymentType && props.paymentType.facilityIds
            ? props.paymentType.facilityIds.map((f: any) => parseInt(f))
            : [],
    );

    // Payer Category Mapping States
    const [payerCategoryMappings, setPayerCategoryMappings] = useState(
        props.payerCategories
            ? props.payerCategories.map((pc) => {
                  const existingMapping =
                      props.paymentType &&
                      props.paymentType.paymentTypeConfig &&
                      props.paymentType.paymentTypeConfig.payerCategoryMapping
                          ? props.paymentType.paymentTypeConfig.payerCategoryMapping.find(
                                (m) => m.payerCategoryCode === pc.payerCategoryCode,
                            )
                          : null;
                  return {
                      payerCategoryCode: pc.payerCategoryCode,
                      amount: existingMapping ? existingMapping.amount : 0,
                      exclude: existingMapping ? existingMapping.exclude : false,
                  };
              })
            : null,
    );
    const [config, setConfig] = useState(configToUse);

    useEffect(() => {
        setConfig(
            Object.assign(
                {},
                {
                    mode,
                    defaultAmount,
                    payerCategoryMapping: payerCategoryMappings,
                    resendEnabled,
                    resendInterval,
                    resendExpireDays,
                },
                mode === 'PAYER_CATEGORY' ? { payerCategoryMappings } : null,
            ),
        );
    }, [defaultAmount, mode, payerCategoryMappings, resendEnabled, resendInterval, resendExpireDays]);

    async function handleSave() {
        setIsLoading(true);

        try {
            if (props.mode === 'edit') {
                await paymentsStore.updatePaymentType(props.paymentType.paymentTypeId, {
                    paymentTypeTitle: title,
                    paymentTypeDescription: description,
                    paymentGatewayId: paymentGateway,
                    paymentTypeConfig: config,
                    isOrgWide,
                    facilityIds: isOrgWide ? [] : facilityIds,
                });
            } else {
                await paymentsStore.createPaymentType({
                    paymentTypeTitle: title,
                    paymentTypeDescription: description,
                    paymentGatewayId: paymentGateway,
                    paymentTypeConfig: config,
                    isOrgWide,
                    facilityIds: isOrgWide ? [] : facilityIds,
                });
            }

            if (props.onSave) {
                props.onSave();
            }
            if (props.onClose) {
                props.onClose();
            }
        } catch (e) {
            ToastStoreObject.show(parseError(e));
        } finally {
            setIsLoading(false);
        }
    }

    function handlePayerCategoryAmountChange(newAmount: number, index: number) {
        let mappings = [...payerCategoryMappings];
        mappings[index].amount = newAmount;
        setPayerCategoryMappings(mappings);
    }

    function handleExcludeCategoryChange(exclude: boolean, index: number) {
        let mappings = [...payerCategoryMappings];
        mappings[index].exclude = exclude;
        setPayerCategoryMappings(mappings);
    }

    function getPayerCategoryNameFromCode(code: string) {
        return props.payerCategories.find((pc) => pc.payerCategoryCode === code)?.payerCategoryTitle;
    }

    function getPayerCategoryMappings() {
        return payerCategoryMappings && payerCategoryMappings.length
            ? payerCategoryMappings.map((mapping: PayerCategoryMapping, index: number) => (
                  <li key={index}>
                      <Flex align="center">
                          <Input
                              label={getPayerCategoryNameFromCode(mapping.payerCategoryCode)}
                              value={mapping.exclude ? 0 : mapping.amount}
                              type="number"
                              onChangeText={(v: string) => handlePayerCategoryAmountChange(parseInt(v), index)}
                              data-test-id={ANALYTICS_NAMES.PaymentTypeModal_PayerCategory}
                              disabled={mapping.exclude}
                          />
                          <div className={styles.excludeCategory}>
                              <Checkbox
                                  label="Exclude charge?"
                                  checked={mapping.exclude}
                                  onChange={(e) => handleExcludeCategoryChange(e.target.checked, index)}
                                  data-test-id={ANALYTICS_NAMES.PaymentTypeModal_ExcludeCategory}
                              />
                          </div>
                      </Flex>
                  </li>
              ))
            : null;
    }

    return (
        <SideModal isOpen={true} onClose={props.onClose}>
            <ModalHeader title={`${props.mode === 'edit' ? 'Edit' : 'Create'} Payment Type`} onClose={props.onClose} />
            <div className={styles.contentWrap}>
                <Input
                    label="Title"
                    value={title}
                    onChangeText={(v: string) => setTitle(v)}
                    data-test-id={ANALYTICS_NAMES.PaymentTypeModal_Title}
                />
                <Input
                    label="Description"
                    value={description}
                    onChangeText={(v: string) => setDescription(v)}
                    data-test-id={ANALYTICS_NAMES.PaymentTypeModal_Description}
                />
                <>
                    <Label text="Payment Gateway" />
                    <PaymentGatewayDropDown
                        onChange={(pg: any) => setPaymentGateway(pg.value)}
                        selectedValue={paymentGateway}
                        data-test-id={ANALYTICS_NAMES.PaymentTypeModal_Gateway}
                    />
                </>
                <Input
                    label="Default Amount"
                    value={defaultAmount}
                    type="number"
                    onChangeText={(v: string) => setDefaultAmount(parseInt(v))}
                    data-test-id={ANALYTICS_NAMES.PaymentTypeModal_DefaultAmount}
                />
                <Flex className={styles.inputWrap} align="center">
                    <Toggle
                        toggled={mode === 'PAYER_CATEGORY'}
                        onChange={(isToggled: boolean) =>
                            isToggled ? setMode('PAYER_CATEGORY') : setMode('FIXED_AMOUNT')
                        }
                    />
                    <Label text="Set Payer Category Amounts?" className={styles.leftMargin} />
                </Flex>
                {mode === 'PAYER_CATEGORY' ? (
                    <div className={styles.payerCategories}>
                        <ul>{getPayerCategoryMappings()}</ul>
                    </div>
                ) : null}
                <div className={styles.inputWrap}>
                    <Label text="Facility Access" className={styles.bottomMargin} />
                    <Checkbox
                        checked={isOrgWide}
                        onChange={(e) => setIsOrgWide(e.target.checked)}
                        label="Applies across all facilities?"
                        data-test-id={ANALYTICS_NAMES.PaymentTypeModal_IsOrgWide}
                    />
                </div>
                {!isOrgWide && (
                    <div className={styles.inputWrap}>
                        <Label text="Facilities" className={styles.bottomMargin} />
                        <FacilityDropDown
                            isMulti={true}
                            selectedValue={facilityIds}
                            onChange={(f: any[]) => setFacilityIds(f.map((fac: any) => fac.value))}
                        />
                    </div>
                )}
                <Flex className={styles.inputWrap} align="center">
                    <Toggle toggled={resendEnabled} onChange={(isToggled: boolean) => setResendEnabled(isToggled)} />
                    <Label text="Send Reminders?" className={styles.leftMargin} />
                </Flex>
                {resendEnabled && (
                    <Flex align="center" className={styles.payerCategories}>
                        <span>Send reminders every</span>
                        <div className={styles.smallInputContainer}>
                            <Input
                                className={styles.smallInput}
                                type="number"
                                value={resendInterval}
                                onChangeText={(v: number) =>
                                    setResendInterval(Math.max(Math.min(v, resendExpireDays), 1))
                                }
                            />
                        </div>
                        <span>day(s) for</span>
                        <div className={styles.smallInputContainer}>
                            <Input
                                className={styles.smallInput}
                                type="number"
                                value={resendExpireDays}
                                onChangeText={(v: number) => setResendExpireDays(Math.min(v, 30))}
                            />
                        </div>
                        <span>total days.</span>
                    </Flex>
                )}
            </div>
            <ModalFooter
                primaryText={props.mode === 'edit' ? 'Save' : 'Create'}
                primaryClick={handleSave}
                secondaryClick={() => props.onClose()}
                isLoading={isLoading}
            />
        </SideModal>
    );
}

export default PaymentTypeModal;
