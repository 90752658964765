import React, { useState } from 'react';
import { observer } from 'mobx-react';
import styles from 'src/containers/Reports/Macra/styles.module.scss';
import Flex from 'src/components/Flex';
import TabNav from 'src/components/TabNav';

// Tabs
import ProviderTab from 'src/containers/Reports/Macra/Tabs/ProviderTab';
import CasesTab from 'src/containers/Reports/Macra/Tabs/CasesTab';
import PopulationTab from 'src/containers/Reports/Macra/Tabs/PopulationTab';
import QualityTab from 'src/containers/Reports/Macra/Tabs/QualityTab';
import OutcomesTab from 'src/containers/Reports/Macra/Tabs/OutcomesTab';
import PatientSurveyTab from 'src/containers/Reports/Macra/Tabs/PatientSurveyTab';

function MacraComplianceDetails() {
    const [selectedTab, setSelectedTab] = useState('By Provider');

    function handleTabChange(tabName: string) {
        setSelectedTab(tabName);
    }

    function getSelectedTab() {
        switch (selectedTab) {
            case 'By Provider':
                return <ProviderTab />;
            case 'Cases':
                return <CasesTab />;
            case 'Population Health':
                return <PopulationTab />;
            case 'Quality':
                return <QualityTab />;
            case 'Outcomes':
                return <OutcomesTab />;
            case 'Patient Surveys':
                return <PatientSurveyTab />;
        }
    }

    return <Flex direction="column">
        <h2>Compliance Details</h2>
        <div className={styles.tabs}>
            <TabNav
                tabs={[
                    'By Provider',
                    'Cases',
                    'Population Health',
                    'Quality',
                    'Outcomes',
                    'Patient Surveys',
                ]}
                onTabClick={(tabName: string) => handleTabChange(tabName)}
            />
        </div>
        {getSelectedTab()}
    </Flex>;
}

export default observer(MacraComplianceDetails);