import React, { useState, useEffect, useContext } from 'react';
import styles from './styles.module.scss';
import Flex from 'src/components/Flex/index';
import { observer } from 'mobx-react';
import Button from 'src/components/Button';
import PatientMedicationStore, { PatientMedication } from 'src/stores/PatientMedicationStore';
import Icon from 'src/components/Icon';
import Table from 'src/components/Table';
import { CellInfo } from 'react-table';
import { toJS } from 'mobx';
import { ICONS } from 'src/utils/constants';
import ActionMenu from 'src/components/ActionMenu';
import DateComponent from 'src/components/DateComponent';
import { Patient } from 'src/stores/PatientStore';
import { ANALYTICS_NAMES } from 'src/utils/analytics';
import UserStore from 'src/stores/UserStore';
import { ModalStoreObject, ModalTypes } from 'src/stores/ModalStore';

interface MedicationsProps {
    patientId: Patient['patientId'];
    patient: Patient;
}
function Medications(props: MedicationsProps) {
    const patientMedicationStore = useContext(PatientMedicationStore);
    const userStore = useContext(UserStore);
    const [isLoading, setIsLoading] = useState(false);
    const [patientMedications, setPatientMedications] = useState([]);

    const columns = [
        {
            Header: 'MEDICATION NAME',
            accessor: 'medicationName',
            minWidth: 150,
        },
        {
            Header: 'STARTED',
            accessor: 'medicationStartDate',
            minWidth: 100,
            Cell: (props: CellInfo) => <DateComponent date={props.value} />,
        },
        {
            Header: 'ENDED',
            accessor: 'medicationEndDate',
            minWidth: 100,
            Cell: (props: CellInfo) => <DateComponent date={props.value} />,
        },
        {
            Header: 'DOSE AMOUNT',
            accessor: 'medicationDoseAmount',
            minWidth: 150,
        },
        {
            Header: 'DOSE UNITS',
            accessor: 'medicationDoseUnits',
            minWidth: 150,
        },
        {
            Header: 'FREQUENCY',
            accessor: 'medicationFrequency',
            minWidth: 150,
        },
        {
            Header: 'ACTIONS',
            minWidth: 100,
            style: { paddingVertical: 0, paddingHorizontal: 20 },
            Cell: (props: CellInfo) => {
                return (
                    <ActionMenu
                        entityType="medication"
                        entity={props.original}
                        onItemClick={(action: { id: string; label: string }) =>
                            handleActionClick(action, props.original)
                        }
                    />
                );
            },
        },
    ];

    const handleActionClick = (action: { id: string; label: string }, medication: PatientMedication) => {
        switch (action.id) {
            case 'edit':
                handleCreateMedication(medication);
                break;
        }
    };

    async function loadMedications() {
        try {
            setIsLoading(true);
            await patientMedicationStore.getPatientMedications(props.patientId);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        loadMedications();
    }, []);

    useEffect(() => {
        setPatientMedications(patientMedicationStore.medications);
    }, [patientMedicationStore.medications]);

    function handleCreateMedication(medication: PatientMedication) {
        ModalStoreObject.showModal(ModalTypes.PatientMedication, {
            medication,
            facilityId: props.patient.facId,
            patientId: props.patientId,
            onClose: () => ModalStoreObject.hideModal(),
            onSave: () => ModalStoreObject.hideModal(),
        });
    }

    return (
        <div className={styles.container}>
            <Flex self="stretch" align="center" justify="start" className={styles.headerWrap}>
                <Flex value={2} justify="end">
                    <Button
                        leftIcon={<Icon className={styles.plusIcon} name={ICONS.PlusButton} />}
                        type="primary"
                        onClick={() => {
                            handleCreateMedication(null);
                        }}
                        isLocked={!userStore.userPermissions.canEdit.patientMedications}
                        text="New Medication"
                        data-test-id={ANALYTICS_NAMES.PatientDetails_Medications_Create}
                    />
                </Flex>
            </Flex>
            <div className={styles.tableWrap}>
                <div className={styles.tableTitle}>Medications</div>
                <Table columns={columns} data={toJS(patientMedications)} isLoading={isLoading} />
            </div>
        </div>
    );
}

export default observer(Medications);
