import { UseMutationResult, useMutation, useQueryClient } from 'react-query';
import { updateFormCatalog } from 'src/api/formCatalogs';
import { FormCatalog } from 'common/lib/entity/framework/FormCatalog';
import { formCatalogKey } from './useFormCatalog';
import { formCatalogsKey } from './useFormCatalogs';

export const updateFormCatalogKey = 'updateFormCatalog';

export interface UseUpdateFormCatalogInputVariables {
    formModelId?: FormCatalog['formModelId'];
    formName?: FormCatalog['formName'];
    formDescription?: FormCatalog['formDescription'];
    formContent?: FormCatalog['formContent'];
    formPages?: FormCatalog['formPages'];
}

export const useUpdateFormCatalog = (
    formCatalogId: FormCatalog['formCatalogId'],
): UseMutationResult<void, Error, UseUpdateFormCatalogInputVariables, unknown> => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationKey: [updateFormCatalogKey],
        mutationFn: async (variables) => {
            const { formModelId, formName, formDescription, formContent, formPages } = variables;

            if (!formCatalogId) {
                throw new Error('formCatalogId is required');
            }

            return await updateFormCatalog(formCatalogId, {
                formModelId,
                formName,
                formDescription,
                formContent,
                formPages,
            });
        },
        retry: false,
        onSuccess: async (data) => {
            queryClient.invalidateQueries({ queryKey: [formCatalogKey] });
            queryClient.invalidateQueries({ queryKey: [formCatalogsKey] });

            return data;
        },
    });
};
