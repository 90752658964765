import { useState, useEffect } from 'react';

const SHOW_LOADING_TIMEOUT = 8 * 1000;

export default function useIsOptionsLoading(options: any[]): boolean {
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        let timeout: any;
        if (options.length === 0) {
            setIsLoading(true);
            timeout = setTimeout(() => setIsLoading(false), SHOW_LOADING_TIMEOUT);
        } else {
            setIsLoading(false);
        }
        return () => {
            if (timeout) {
                clearTimeout(timeout);
            }
        };
    }, [options]);

    return isLoading;
}
