import React, { useState, useContext } from 'react';
import styles from './styles.module.scss';
import Input, { Label } from 'src/components/Input';
import { useEffect } from 'react';
import { Provider } from 'src/stores/ProviderStore';
import {
    IProviderDetails,
    IProviderImmunization,
    IProviderLicensure,
    IProviderCertification,
    IProviderPhone,
    IProviderEmail,
    AgencyStateCode,
    IAgencyCustomResult,
} from 'common/lib/model/nemsis3_4/demographic';
import { isValidNpi } from 'common/lib/util/ProviderUtil';
import { observer } from 'mobx-react';
import { OptionTypes } from '../OptionDropDown';
import Flex from '../Flex';
import NemsisOptionDropDown from '../NemsisOptionDropDown';
import { getDropdownValue } from 'src/utils/nemsis';
import { NaValues } from 'common/lib/model/nemsis3_4/demographic';
import NemsisInput from '../NemsisInput';
import Button from 'src/components/Button';
import SideModal from '../SideModal';
import ModalHeader from '../ModalHeader';
import ModalFooter from '../ModalFooter';
import * as providerService from 'src/api/providers';
import { ToastStoreObject } from 'src/stores/ToastStore';
import * as utils from 'src/utils';
import DatePicker from '../DatePicker';
import { format } from 'date-fns';
import { API_DATE_FORMAT } from 'src/utils/constants';
import Accordion from '../Accordion';
import UserStore, { OrgType } from 'src/stores/UserStore';
import NemsisDatePicker from '../NemsisDatePicker';
import useFlag from 'src/utils/hooks/useFlag';
import ReferenceListStore, { SelectComponent } from 'src/stores/ReferenceListStore';
import OrgStore from 'src/stores/OrgStore';
import NemsisStore from 'src/stores/NemsisStore';
import { useAgencyState } from 'src/utils/hooks/useAgencyState';
import useFeatureValue from 'src/utils/hooks/useFeatureValue';
import { NemsisMode } from 'common/lib/model/NemsisMode';
import { useAgencyCustomResults } from 'src/utils/hooks/useAgencyCustomResults';
import { toJS } from 'mobx';
import FacilityDropDown from '../FacilityDropDown';
import { Facility } from 'common/lib/entity/org/Facility';

enum DataTarget {
    PROVIDER = 'provider',
    PROVIDER_DETAIL = 'providerDetails',
}

export interface ProviderModalProps {
    provider: Provider;
    onSave: Function;
    onClose: Function;
    facilityId: Provider['facId'];
}

const defaultProviderImmunization: IProviderImmunization = {
    statusCode: '',
    year: null,
};

const defaultProviderLicensure: IProviderLicensure = {
    stateCode: '',
    licensureId: '',
    licensureLevelCode: '',
    certificationDate: null,
    initialStateLicensureIssueDate: null,
    currentStateLicensureExpirationDate: null,
};

const defaultProviderCertification: IProviderCertification = {
    practiceLevelCode: '',
    dateOfCertification: null,
};

const defaultNotValues: IProviderDetails['notValues'] = {
    provider: {
        phoneNumbers: [{}],
        emailAddresses: [{}],
    },
    providerDetails: {
        licensures: [{}],
    },
};

const defaultProviderDetails: IProviderDetails = {
    raceCodes: [],
    citizenshipCode: '',
    highestEducationalDegreeCode: '',
    fieldOfStudyCodes: [],
    motorVehicleLicenseTypeCodes: [],
    immunizations: [{ ...defaultProviderImmunization }],
    foreignLanguageAbilityCodes: [],
    licensures: [{ ...defaultProviderLicensure }],
    nationalRegistryNumber: '',
    nationalRegistryCertificationLevelCode: '',
    nationalRegistryExpirationDate: null,
    employmentStatusCode: '',
    employmentStatusDate: null,
    hireDate: null,
    primaryEmsJobRoleCode: '',
    otherJobResponsibilityCodes: [],
    yearsInService: null,
    yearsInServiceDocumentedDate: null,
    certifications: [{ ...defaultProviderCertification }],
    notValues: { ...defaultNotValues },
};

const defaultProviderPhone: IProviderPhone = {
    phoneNumber: '',
    typeCode: '',
};

const defaultProviderEmail: IProviderEmail = {
    emailAddress: '',
    typeCode: '',
};

const defaultProvider: Provider = {
    prvrTyp: '',
    frstNm: '',
    lastNm: '',
    natlPrvrId: '',
    localPrvrId: '',
    spclty: '',
    groupNm: '',
    prvrDetails: utils.clone(defaultProviderDetails),
    middleName: '',
    addressLine1: '',
    addressLine2: '',
    cityCode: '',
    cityName: '',
    stateCode: '',
    zipCode: '',
    countryCode: '',
    phoneNumbers: [],
    emailAddresses: [],
    providerDob: null,
    genderCode: '',
};

function ProviderModal(props: ProviderModalProps) {
    const [data, setData] = useState<Provider>(
        props.provider
            ? { ...props.provider, prvrDetails: props.provider.prvrDetails || utils.clone(defaultProviderDetails) }
            : {
                  ...utils.clone(defaultProvider),
                  facId: props.facilityId,
              },
    );
    const isEdit = !!props.provider;
    const [hasValidNpi, setNpiWarning] = useState(
        (props.provider && props.provider.natlPrvrId && isValidNpi(props.provider.natlPrvrId)) || false,
    );
    const [isFieldMed, setIsFieldMed] = useState(true);
    const userStore = useContext(UserStore);
    const referenceListStore = useContext(ReferenceListStore);
    const nemsisStore = useContext(NemsisStore);
    const nemsisEnabled = useFlag('nemsis');
    const nemsisMode = useFeatureValue('nemsis_mode');
    const agencyState = useAgencyState();
    const [customResults, setCustomResults, saveCustomResults] = useAgencyCustomResults();

    useEffect(() => {
        setIsFieldMed(userStore.selectedOrgType === OrgType.FIELDMED);
    }, []);

    useEffect(() => {
        if (data.stateCode === '' || data.stateCode === null) return;
        referenceListStore.getFilteredData(OptionTypes.ANSICityCodes, {
            filterName: 'stateAnsiCode',
            filterValue: data.stateCode,
        });
    }, [data.stateCode]);

    const checkNpId = (value: string) => {
        setNpiWarning(isValidNpi(value));
        setData({ ...data, natlPrvrId: value });
    };

    function handleNaChange(target: DataTarget, property: string, subProperty?: string, index?: number) {
        const newData: any = { ...data };
        if (!data.prvrDetails) newData.prvrDetails = { ...defaultProviderDetails };

        if (!data.prvrDetails.notValues) {
            newData.prvrDetails.notValues = { ...defaultNotValues };
        }

        if (subProperty) {
            // Make sure everything is defaulted all the way down
            if (!newData.prvrDetails.notValues[target][property]) {
                newData.prvrDetails.notValues[target] = { ...newData.prvrDetails.notValues[target], [property]: [] };
            }
            if (!newData.prvrDetails.notValues[target][property][index]) {
                newData.prvrDetails.notValues[target][property][index] = {};
            }

            newData.prvrDetails.notValues[target][property][index][subProperty] = newData.prvrDetails.notValues[target][
                property
            ][index][subProperty]
                ? undefined
                : NaValues.NOT_APPLICABLE;
        } else {
            newData.prvrDetails.notValues[target][property] =
                newData.prvrDetails.notValues[target] && newData.prvrDetails.notValues[target][property]
                    ? undefined
                    : NaValues.NOT_APPLICABLE;
        }

        setData(newData);
    }

    const updateInput = (param: keyof Provider) => (t: string) => setData({ ...data, [param]: t });
    const updateProviderDetailInput = (param: keyof IProviderDetails, t: string) => {
        setData({ ...data, prvrDetails: { ...data.prvrDetails, [param]: t } });
    };

    function updateNestedInput({
        param,
        subParam,
        index,
        value,
    }: {
        param: keyof Provider;
        subParam: string;
        index: number;
        value: any;
    }) {
        const newData: any = { ...data };
        if (!newData[param]) {
            newData[param] = [];
        }
        if (!newData[param][index]) {
            newData[param][index] = {};
        }
        newData[param][index][subParam] = value;
        setData(newData);
    }

    function updateNestedDetailInput({
        param,
        subParam,
        index,
        value,
    }: {
        param: keyof IProviderDetails;
        subParam: string;
        index: number;
        value: any;
    }) {
        const newData: any = { ...data };
        if (!newData.prvrDetails[param]) {
            newData.prvrDetails[param] = [];
        }
        if (!newData.prvrDetails[param][index]) {
            newData.prvrDetails[param][index] = {};
        }
        newData.prvrDetails[param][index][subParam] = value;
        setData(newData);
    }

    function updateNestedProviderDetailInput({
        param,
        subParam,
        index,
        value,
    }: {
        param: keyof IProviderDetails;
        subParam: string;
        index: number;
        value: any;
    }) {
        const newData: any = { ...data };
        if (!newData.prvrDetails) newData.prvrDetails = { ...defaultProviderDetails };

        if (!newData.prvrDetails[param]) {
            newData.prvrDetails[param] = [];
        }
        if (!newData.prvrDetails[param][index]) {
            newData.prvrDetails[param][index] = {};
        }
        newData.prvrDetails[param][index][subParam] = value;
        setData(newData);
    }

    function getNotValBool(
        parentProp: string,
        childProp?: string,
        index?: number,
        target: DataTarget = DataTarget.PROVIDER,
    ) {
        if (target === DataTarget.PROVIDER)
            return getProviderNotValBool(parentProp as keyof Provider, childProp, index);
        else return getDetailsNotValBool(parentProp as keyof IProviderDetails, childProp, index);
    }

    function getProviderNotValBool(parentProp: keyof Provider, childProp?: string, index?: number) {
        if (childProp) {
            return !!(
                data &&
                data.prvrDetails &&
                data.prvrDetails.notValues &&
                data.prvrDetails.notValues.provider &&
                // @ts-ignore
                data.prvrDetails.notValues.provider[parentProp] &&
                // @ts-ignore
                data.prvrDetails.notValues.provider[parentProp][index] &&
                // @ts-ignore
                data.prvrDetails.notValues.provider[parentProp][index][childProp]
            );
        }
        return !!(
            data &&
            data.prvrDetails &&
            data.prvrDetails.notValues &&
            data.prvrDetails.notValues.provider &&
            // @ts-ignore
            data.prvrDetails.notValues.provider[parentProp]
        );
    }

    function getDetailsNotValBool(parentProp: keyof IProviderDetails, childProp?: string, index?: number) {
        if (childProp) {
            return !!(
                data &&
                data.prvrDetails &&
                data.prvrDetails.notValues &&
                data.prvrDetails.notValues.providerDetails &&
                data.prvrDetails.notValues.providerDetails[parentProp] &&
                data.prvrDetails.notValues.providerDetails[parentProp][index] &&
                data.prvrDetails.notValues.providerDetails[parentProp][index][childProp]
            );
        }
        return !!(
            data &&
            data.prvrDetails &&
            data.prvrDetails.notValues &&
            data.prvrDetails.notValues.providerDetails &&
            data.prvrDetails.notValues.providerDetails[parentProp]
        );
    }

    function renderDropDown({
        key,
        label,
        type,
        isMulti,
        isNa,
        nested,
        target = DataTarget.PROVIDER,
        virtualize = false,
        options,
        disabled,
    }: {
        key: keyof Provider;
        label: string;
        type: OptionTypes;
        isMulti?: boolean;
        isNa?: boolean;
        nested?: { index: number; row: any; key: string };
        target?: DataTarget;
        virtualize?: boolean;
        options?: SelectComponent[];
        disabled?: boolean;
    }) {
        const value = nested ? nested.row[nested.key] : data[key];
        return (
            <NemsisOptionDropDown
                nemsisLabel={label}
                isMulti={isMulti}
                selectedValue={
                    getNotValBool(key, nested && nested.key, nested && nested.index) ? NaValues.NOT_APPLICABLE : value
                }
                onChange={(d: any) => {
                    const val = getDropdownValue(d, isMulti ? [] : undefined);
                    if (nested) {
                        return updateNestedInput({ param: key, subParam: nested.key, index: nested.index, value: val });
                    }
                    return setData({ ...data, [key]: val });
                }}
                {...(isNa
                    ? {
                          onNaClick: () => handleNaChange(target, key, nested && nested.key, nested && nested.index),
                          naSelected: getNotValBool(key, nested && nested.key, nested && nested.index, target),
                      }
                    : {})}
                type={type}
                virtualize={virtualize}
                isDisabled={disabled}
                options={options || null}
            />
        );
    }

    function renderDetailsDropDown({
        key,
        label,
        type,
        isMulti,
        isNa,
        nested,
        target = DataTarget.PROVIDER_DETAIL,
    }: {
        key: keyof IProviderDetails;
        label: string;
        type: OptionTypes;
        isMulti?: boolean;
        isNa?: boolean;
        nested?: { index: number; row: any; key: string };
        target?: DataTarget;
    }) {
        const value = nested ? nested.row[nested.key] : data.prvrDetails[key];
        return (
            <NemsisOptionDropDown
                nemsisLabel={label}
                isMulti={isMulti}
                selectedValue={
                    getNotValBool(key, nested && nested.key, nested && nested.index, DataTarget.PROVIDER_DETAIL)
                        ? NaValues.NOT_APPLICABLE
                        : value
                }
                onChange={(d: any) => {
                    const val = getDropdownValue(d, isMulti ? [] : undefined);
                    if (nested) {
                        return updateNestedDetailInput({
                            param: key,
                            subParam: nested.key,
                            index: nested.index,
                            value: val,
                        });
                    }
                    return setData({ ...data, prvrDetails: { ...data.prvrDetails, [key]: val } });
                }}
                {...(isNa
                    ? {
                          onNaClick: () => handleNaChange(target, key, nested && nested.key, nested && nested.index),
                          naSelected: getNotValBool(key, nested && nested.key, nested && nested.index, target),
                      }
                    : {})}
                type={type}
            />
        );
    }

    function handleRemoveRow(parentProp: keyof Provider, index: number) {
        const currentData = { ...data };
        // @ts-ignore
        (currentData[parentProp] || []).splice(index, 1);
        // remove any not values
        // @ts-ignore
        (currentData.prvrDetails.notValues.provider[parentProp] || []).splice(index, 1);
        setData(currentData);
    }

    function handleDetailsRemoveRow(parentProp: keyof IProviderDetails, index: number) {
        const currentData = { ...data };
        // @ts-ignore
        (currentData.prvrDetails[parentProp] || []).splice(index, 1);
        // remove any not values
        (currentData.prvrDetails.notValues.providerDetails[parentProp] || []).splice(index, 1);
        setData(currentData);
    }

    function handleAddRow(property: keyof Provider) {
        const currentData = { ...data };
        if (property === 'emailAddresses') {
            if (!currentData[property]) currentData[property] = [];
            currentData[property].push({ ...defaultProviderEmail });
        }
        if (property === 'phoneNumbers') {
            if (!currentData[property]) currentData[property] = [];
            currentData[property].push({ ...defaultProviderPhone });
        }
        setData(currentData);
    }

    function handleDetailsAddRow(property: keyof IProviderDetails) {
        const currentData = { ...data };

        if (property === 'immunizations') {
            if (!currentData.prvrDetails[property]) currentData.prvrDetails[property] = [];
            currentData.prvrDetails[property].push({ ...defaultProviderImmunization });
        }
        if (property === 'certifications') {
            if (!currentData.prvrDetails[property]) currentData.prvrDetails[property] = [];
            currentData.prvrDetails[property].push({ ...defaultProviderCertification });
        }
        if (property === 'licensures') {
            if (!currentData.prvrDetails[property]) currentData.prvrDetails[property] = [];
            currentData.prvrDetails[property].push({ ...defaultProviderLicensure });

            // add an empty object to track indexes of not values correctly
            if (!currentData.prvrDetails) currentData.prvrDetails = { ...defaultProviderDetails };
            currentData.prvrDetails.notValues.providerDetails[property] = [
                ...currentData.prvrDetails.notValues.providerDetails[property],
                {},
            ];
        }
        setData(currentData);
    }

    async function handleSave() {
        try {
            if (isEdit) await providerService.editProvider(props.facilityId, data.prvrId, data);
            else await providerService.createProvider(props.facilityId, data);

            await saveCustomResults();

            if (isFieldMed && nemsisEnabled) await nemsisStore.validateDemData(true);

            props.onSave();
            props.onClose();
        } catch (error) {
            ToastStoreObject.show(utils.parseError(error));
        }
    }

    function getPrimaryForm() {
        return (
            <>
                <Flex direction="row" justify="between" className={styles.formRow}>
                    <Flex value={1}>
                        <Input label="First Name" value={data.frstNm} onChangeText={updateInput('frstNm')} />
                    </Flex>
                    <Flex value={0.1} />
                    {nemsisEnabled && isFieldMed && (
                        <>
                            <Flex value={1}>
                                <Input
                                    label="Middle Name"
                                    value={data.middleName}
                                    onChangeText={updateInput('middleName')}
                                />
                            </Flex>
                            <Flex value={0.1} />
                        </>
                    )}
                    <Flex value={1}>
                        <Input label="Last Name" value={data.lastNm} onChangeText={updateInput('lastNm')} />
                    </Flex>
                </Flex>
                <Flex direction="row" justify="between" className={styles.formRow}>
                    <Flex value={1}>
                        {renderDropDown({
                            key: 'prvrTyp',
                            label: 'Provider Type',
                            type: OptionTypes.ProviderTypes,
                        })}
                    </Flex>
                </Flex>
                <Flex direction="row" justify="between" className={styles.formRow}>
                    {nemsisEnabled && isFieldMed && (
                        <>
                            <Flex value={1}>
                                <NemsisDatePicker
                                    nemsisLabel="DOB"
                                    value={data.providerDob}
                                    onChange={(date) => {
                                        if (date) {
                                            const value = format(date, API_DATE_FORMAT);
                                            setData({ ...data, providerDob: value });
                                        }
                                    }}
                                    onNaClick={() => handleNaChange(DataTarget.PROVIDER, 'providerDob')}
                                    naSelected={getNotValBool('providerDob')}
                                />
                            </Flex>
                            <Flex value={0.1}></Flex>
                        </>
                    )}
                    <Flex value={1}>
                        <Input
                            label="NPI"
                            value={data.natlPrvrId}
                            infoState={!hasValidNpi ? 'warning' : null}
                            onChangeText={(value: string) => checkNpId(value)}
                        />
                    </Flex>
                    <Flex value={0.1}></Flex>
                    <Flex value={1}>
                        <Input
                            label="Local Provider Id"
                            value={data.localPrvrId}
                            onChangeText={updateInput('localPrvrId')}
                        />
                    </Flex>
                </Flex>
                <Flex direction="row" justify="between" className={styles.formRow}>
                    <Flex value={1}>
                        <Input label="Specialty" value={data.spclty} onChangeText={updateInput('spclty')} />
                    </Flex>
                </Flex>
                {!isEdit && (
                    <Flex direction="row" justify="between" className={styles.formRow}>
                        <Flex value={1}>
                            <Label text="Facility/s" />
                            <FacilityDropDown
                                selectedValue={props.facilityId}
                                isMulti={true}
                                onChange={(v: Facility[]) =>
                                    setData({ ...data, facilityList: v.map((f: any) => f.value) })
                                }
                            />
                        </Flex>
                    </Flex>
                )}
            </>
        );
    }

    function getCustomValue(result: IAgencyCustomResult) {
        if (!result) return '';
        return result.notValue === NaValues.NOT_APPLICABLE ? result.notValue : result.customDataElementResult;
    }

    return (
        <SideModal isOpen={true} onClose={() => props.onClose()}>
            <ModalHeader
                title={
                    isEdit
                        ? `Edit ${data && data.frstNm ? `${data.frstNm} ${data.lastNm}` : 'Provider'}`
                        : 'New Provider'
                }
                onClose={() => props.onClose()}
            />
            <div className={styles.contentWrap}>
                <Flex direction="column" className={styles.form}>
                    {nemsisEnabled && isFieldMed ? (
                        <Accordion startOpen={true} label="Primary" removeExtraSpacing={true}>
                            {getPrimaryForm()}
                        </Accordion>
                    ) : (
                        getPrimaryForm()
                    )}

                    {nemsisEnabled && isFieldMed && (
                        <Accordion startOpen={false} label="Address" removeExtraSpacing={true}>
                            <Flex direction="row" justify="between" className={styles.formRow}>
                                <Flex value={1}>
                                    <Input
                                        label="Street Address"
                                        value={data.addressLine1}
                                        onChangeText={updateInput('addressLine1')}
                                    />
                                </Flex>
                            </Flex>
                            <Flex direction="row" justify="between" className={styles.formRow}>
                                <Flex value={1}>
                                    <Input
                                        label="Street Address 2"
                                        value={data.addressLine2}
                                        onChangeText={updateInput('addressLine2')}
                                    />
                                </Flex>
                            </Flex>
                            <Flex direction="row" justify="between" className={styles.formRow}>
                                <Flex value={1}>
                                    {renderDropDown({
                                        key: 'stateCode',
                                        label: 'State',
                                        type: OptionTypes.ANSIStateCodes,
                                    })}
                                </Flex>
                                <Flex value={0.1}></Flex>
                                <Flex value={1}>
                                    {renderDropDown({
                                        key: 'cityCode',
                                        label: 'City',
                                        type: OptionTypes.ANSICityCodes,
                                        virtualize: true,
                                        disabled: data.stateCode === '',
                                        options:
                                            referenceListStore.filteredReferenceListDropdownValues.get(
                                                `${OptionTypes.ANSICityCodes}-${data.stateCode}`,
                                            ) || [],
                                    })}
                                </Flex>
                                <Flex value={0.1}></Flex>
                                <Flex value={1}>
                                    <Input
                                        label="Zip Code"
                                        value={data.zipCode}
                                        onChangeText={updateInput('zipCode')}
                                    />
                                </Flex>
                            </Flex>
                            <Flex direction="row" justify="between" className={styles.formRow}>
                                <Flex value={1}>
                                    {renderDropDown({
                                        key: 'countryCode',
                                        label: 'Country',
                                        type: OptionTypes.EMSANSICountryCodesCAMXUS,
                                    })}
                                </Flex>
                            </Flex>
                        </Accordion>
                    )}
                    {nemsisEnabled && isFieldMed && (
                        <>
                            <Accordion startOpen={false} label="Demographics" removeExtraSpacing={true}>
                                {isFieldMed && (
                                    <Flex direction="row" justify="between" className={styles.formRow}>
                                        <Flex value={1}>
                                            {renderDropDown({
                                                key: 'genderCode',
                                                label: 'Gender',
                                                type: OptionTypes.EMSGender,
                                                isNa: true,
                                            })}
                                        </Flex>
                                    </Flex>
                                )}
                                <Flex direction="row" justify="between" className={styles.formRow}>
                                    <Flex value={1}>
                                        {renderDetailsDropDown({
                                            key: 'raceCodes',
                                            label: 'Race',
                                            type: OptionTypes.EMSDemographicRace,
                                            isNa: true,
                                            isMulti: true,
                                        })}
                                    </Flex>
                                </Flex>
                                <Flex direction="row" justify="between" className={styles.formRow}>
                                    <Flex value={1}>
                                        {renderDetailsDropDown({
                                            key: 'citizenshipCode',
                                            label: 'Citizenship',
                                            type: OptionTypes.ANSICountryCodes,
                                        })}
                                    </Flex>
                                    <Flex value={0.1}></Flex>
                                    <Flex value={1}>
                                        {renderDetailsDropDown({
                                            key: 'highestEducationalDegreeCode',
                                            label: 'Highest Level of Edu.',
                                            type: OptionTypes.EMSPersonnelHighestEducationalDegree,
                                        })}
                                    </Flex>
                                </Flex>
                                <Flex direction="row" justify="between" className={styles.formRow}>
                                    <Flex value={1}>
                                        {renderDetailsDropDown({
                                            key: 'fieldOfStudyCodes',
                                            label: 'Fields of Study',
                                            type: OptionTypes.EMSPersonnelDegreeFieldofStudy,
                                            isMulti: true,
                                        })}
                                    </Flex>
                                </Flex>
                                <Flex direction="row" justify="between" className={styles.formRow}>
                                    <Flex value={1}>
                                        {renderDetailsDropDown({
                                            key: 'motorVehicleLicenseTypeCodes',
                                            label: 'Motor Vehicle Licenses',
                                            type: OptionTypes.EMSPersonnelVehicleLicenseType,
                                            isMulti: true,
                                        })}
                                    </Flex>
                                </Flex>

                                <Flex direction="row" justify="between" className={styles.formRow}>
                                    <Flex value={1}>
                                        {renderDetailsDropDown({
                                            key: 'foreignLanguageAbilityCodes',
                                            label: 'Foreign Languages',
                                            type: OptionTypes.EMSPersonnelForeignLanguageAbility,
                                            isMulti: true,
                                        })}
                                    </Flex>
                                </Flex>
                            </Accordion>

                            <Accordion startOpen={false} label="Employment" removeExtraSpacing={true}>
                                <Flex direction="row" justify="between" className={styles.formRow}>
                                    <Flex value={1}>
                                        <NemsisInput
                                            nemsisLabel="National Registry Number"
                                            value={data.prvrDetails.nationalRegistryNumber}
                                            onChangeText={(value: string) =>
                                                updateProviderDetailInput('nationalRegistryNumber', value)
                                            }
                                        />
                                    </Flex>
                                    <Flex value={0.1}></Flex>
                                    <Flex value={1}>
                                        <DatePicker
                                            label="National Registry Expiration Date"
                                            value={data.prvrDetails.nationalRegistryExpirationDate}
                                            onChange={(date) => {
                                                if (date) {
                                                    const value = format(date, API_DATE_FORMAT);
                                                    updateProviderDetailInput('nationalRegistryExpirationDate', value);
                                                }
                                            }}
                                        />
                                    </Flex>
                                </Flex>
                                <Flex direction="row" justify="between" className={styles.formRow}>
                                    <Flex value={1}>
                                        {renderDetailsDropDown({
                                            key: 'nationalRegistryCertificationLevelCode',
                                            label: 'National Registry Certification',
                                            type: OptionTypes.EMSNationalRegistryCertificationLevel,
                                        })}
                                    </Flex>
                                </Flex>
                                <Flex direction="row" justify="between" className={styles.formRow}>
                                    <Flex value={1}>
                                        {renderDetailsDropDown({
                                            key: 'employmentStatusCode',
                                            label: 'Employment Status',
                                            type: OptionTypes.EMSEmploymentStatus,
                                            isNa: true,
                                        })}
                                    </Flex>
                                </Flex>
                                <Flex direction="row" justify="between" className={styles.formRow}>
                                    <Flex value={1}>
                                        <NemsisDatePicker
                                            nemsisLabel="Employment Status Date"
                                            value={data.prvrDetails.employmentStatusDate}
                                            onChange={(date) => {
                                                if (date) {
                                                    const value = format(date, API_DATE_FORMAT);
                                                    updateProviderDetailInput('employmentStatusDate', value);
                                                }
                                            }}
                                            onNaClick={() =>
                                                handleNaChange(DataTarget.PROVIDER_DETAIL, 'employmentStatusDate')
                                            }
                                            naSelected={getDetailsNotValBool('employmentStatusDate')}
                                        />
                                    </Flex>
                                    <Flex value={0.1}></Flex>
                                    <Flex value={1}>
                                        <DatePicker
                                            label="Hire Date"
                                            value={data.prvrDetails.hireDate}
                                            onChange={(date) => {
                                                if (date) {
                                                    const value = format(date, API_DATE_FORMAT);
                                                    updateProviderDetailInput('hireDate', value);
                                                }
                                            }}
                                        />
                                    </Flex>
                                </Flex>
                                <Flex direction="row" justify="between" className={styles.formRow}>
                                    <Flex value={1}>
                                        {renderDetailsDropDown({
                                            key: 'primaryEmsJobRoleCode',
                                            label: 'Primary Job Role',
                                            type: OptionTypes.EMSJobResponsibilities,
                                            isNa: true,
                                        })}
                                    </Flex>
                                </Flex>
                                <Flex direction="row" justify="between" className={styles.formRow}>
                                    <Flex value={1}>
                                        {renderDetailsDropDown({
                                            key: 'otherJobResponsibilityCodes',
                                            label: 'Other Job Responsibilites',
                                            type: OptionTypes.EMSJobResponsibilities,
                                            isMulti: true,
                                            isNa: true,
                                        })}
                                    </Flex>
                                </Flex>
                                <Flex direction="row" justify="between" className={styles.formRow}>
                                    <Flex value={1}>
                                        <NemsisInput
                                            nemsisLabel="Years in Service"
                                            value={data.prvrDetails.yearsInService}
                                            onChangeText={(value: string) =>
                                                updateProviderDetailInput('yearsInService', value)
                                            }
                                        />
                                    </Flex>
                                    <Flex value={0.1}></Flex>
                                    <Flex value={1}>
                                        <DatePicker
                                            label="Years In Service Documented Date"
                                            value={data.prvrDetails.yearsInServiceDocumentedDate}
                                            onChange={(date) => {
                                                if (date) {
                                                    const value = format(date, API_DATE_FORMAT);
                                                    updateProviderDetailInput('yearsInServiceDocumentedDate', value);
                                                }
                                            }}
                                        />
                                    </Flex>
                                </Flex>
                            </Accordion>
                            <Accordion startOpen={false} label="Phone Numbers" removeExtraSpacing={true}>
                                <Flex direction="row" justify="between" className={styles.formRow}>
                                    <Flex value={1} direction="column" className={styles.newRow}>
                                        <span className={styles.heading}>Phone Number</span>
                                        {(data.phoneNumbers || []).map((s, index) => (
                                            <Flex
                                                key={`${index}`}
                                                direction="row"
                                                justify="between"
                                                align="stretch"
                                                className={styles.formRow}
                                            >
                                                <Flex value={0.5}>
                                                    {renderDropDown({
                                                        key: 'phoneNumbers',
                                                        label: 'Type',
                                                        type: OptionTypes.EMSPhoneNumberType,
                                                        nested: { row: s, key: 'typeCode', index },
                                                    })}
                                                </Flex>
                                                <Flex value={0.1} />
                                                <Flex value={1}>
                                                    <NemsisInput
                                                        nemsisLabel="Phone Number"
                                                        value={
                                                            getNotValBool('phoneNumbers', 'phoneNumber', index)
                                                                ? NaValues.NOT_APPLICABLE
                                                                : s.phoneNumber
                                                        }
                                                        onChangeText={(text: string) =>
                                                            updateNestedInput({
                                                                param: 'phoneNumbers',
                                                                subParam: 'phoneNumber',
                                                                index,
                                                                value: text,
                                                            })
                                                        }
                                                    />
                                                </Flex>
                                                <Flex value={0.7} align="end" justify="end">
                                                    <Button
                                                        type="primary"
                                                        text="Remove"
                                                        onClick={() => handleRemoveRow('phoneNumbers', index)}
                                                        className={styles.buttonWrap}
                                                    />
                                                </Flex>
                                            </Flex>
                                        ))}
                                        <Button
                                            type="transparent"
                                            text="+ Add Phone Number"
                                            onClick={() => handleAddRow('phoneNumbers')}
                                            className={styles.addUnderline}
                                        />
                                    </Flex>
                                </Flex>
                            </Accordion>
                            <Accordion startOpen={false} label="Email Addresses" removeExtraSpacing={true}>
                                <Flex direction="row" justify="between" className={styles.formRow}>
                                    <Flex value={1} direction="column" className={styles.newRow}>
                                        <span className={styles.heading}>Email Address</span>
                                        {(data.emailAddresses || []).map((s, index) => (
                                            <Flex
                                                key={`${index}`}
                                                direction="row"
                                                justify="between"
                                                align="stretch"
                                                className={styles.formRow}
                                            >
                                                <Flex value={0.5}>
                                                    {renderDropDown({
                                                        key: 'emailAddresses',
                                                        label: 'Type',
                                                        type: OptionTypes.EMSEmailAddressType,
                                                        nested: { row: s, key: 'typeCode', index },
                                                    })}
                                                </Flex>
                                                <Flex value={0.1} />
                                                <Flex value={1}>
                                                    <NemsisInput
                                                        nemsisLabel="Email Address"
                                                        value={s.emailAddress}
                                                        onChangeText={(text: string) =>
                                                            updateNestedInput({
                                                                param: 'emailAddresses',
                                                                subParam: 'emailAddress',
                                                                index,
                                                                value: text,
                                                            })
                                                        }
                                                    />
                                                </Flex>
                                                <Flex value={0.7} align="end" justify="end">
                                                    <Button
                                                        type="primary"
                                                        text="Remove"
                                                        onClick={() => handleRemoveRow('emailAddresses', index)}
                                                        className={styles.buttonWrap}
                                                    />
                                                </Flex>
                                            </Flex>
                                        ))}
                                        <Button
                                            type="transparent"
                                            text="+ Add Email Address"
                                            onClick={() => handleAddRow('emailAddresses')}
                                            className={styles.addUnderline}
                                        />
                                    </Flex>
                                </Flex>
                            </Accordion>
                            <Accordion startOpen={false} label="Immunizations" removeExtraSpacing={true}>
                                <Flex direction="row" justify="between" className={styles.formRow}>
                                    <Flex value={1} direction="column" className={styles.newRow}>
                                        <span className={styles.heading}>Immunizations</span>
                                        {(data.prvrDetails.immunizations || []).map((s, index) => (
                                            <Flex
                                                key={`${index}`}
                                                direction="row"
                                                justify="between"
                                                align="stretch"
                                                className={styles.formRow}
                                            >
                                                <Flex value={0.5}>
                                                    {renderDetailsDropDown({
                                                        key: 'immunizations',
                                                        label: 'Immunization',
                                                        type: OptionTypes.EMSImmunizationType,
                                                        nested: { row: s, key: 'statusCode', index },
                                                    })}
                                                </Flex>
                                                <Flex value={0.1} />
                                                <Flex value={1}>
                                                    <NemsisInput
                                                        nemsisLabel="Year"
                                                        value={s.year}
                                                        onChangeText={(text: string) =>
                                                            updateNestedDetailInput({
                                                                param: 'immunizations',
                                                                subParam: 'year',
                                                                index,
                                                                value: text,
                                                            })
                                                        }
                                                    />
                                                </Flex>
                                                <Flex value={0.7} align="end" justify="end">
                                                    <Button
                                                        type="primary"
                                                        text="Remove"
                                                        onClick={() => handleDetailsRemoveRow('immunizations', index)}
                                                        className={styles.buttonWrap}
                                                    />
                                                </Flex>
                                            </Flex>
                                        ))}
                                        <Button
                                            type="transparent"
                                            text="+ Add Immunization"
                                            onClick={() => handleDetailsAddRow('immunizations')}
                                            className={styles.addUnderline}
                                        />
                                    </Flex>
                                </Flex>
                            </Accordion>
                            <Accordion startOpen={false} label="Licensures" removeExtraSpacing={true}>
                                <Flex direction="row" justify="between" className={styles.formRow}>
                                    <Flex value={1} direction="column" className={styles.newRow}>
                                        <span className={styles.heading}>Licensures</span>
                                        {(data.prvrDetails.licensures || []).map((s, index) => (
                                            <Flex
                                                key={`${index}`}
                                                direction="column"
                                                justify="between"
                                                align="stretch"
                                                className={styles.formRow}
                                            >
                                                <Flex direction="row" justify="between" className={styles.formRow}>
                                                    <Flex value={1}>
                                                        {renderDetailsDropDown({
                                                            key: 'licensures',
                                                            label: 'Licensure Level',
                                                            type: OptionTypes.EMSMemberLevel,
                                                            nested: { row: s, key: 'licensureLevelCode', index },
                                                            isNa: true,
                                                        })}
                                                    </Flex>
                                                </Flex>
                                                <Flex direction="row" justify="between" className={styles.formRow}>
                                                    <Flex value={0.5}>
                                                        {renderDetailsDropDown({
                                                            key: 'licensures',
                                                            label: 'State',
                                                            type: OptionTypes.ANSIStateCodes,
                                                            isNa: true,
                                                            nested: { row: s, key: 'stateCode', index },
                                                        })}
                                                    </Flex>
                                                    <Flex value={0.1} />
                                                    <Flex value={1}>
                                                        <NemsisInput
                                                            nemsisLabel="Licensure ID"
                                                            value={
                                                                getDetailsNotValBool('licensures', 'licensureId', index)
                                                                    ? NaValues.NOT_APPLICABLE
                                                                    : s.licensureId
                                                            }
                                                            onChangeText={(text: string) =>
                                                                updateNestedDetailInput({
                                                                    param: 'licensures',
                                                                    subParam: 'licensureId',
                                                                    index,
                                                                    value: text,
                                                                })
                                                            }
                                                            onNaClick={() =>
                                                                handleNaChange(
                                                                    DataTarget.PROVIDER_DETAIL,
                                                                    'licensures',
                                                                    'licensureId',
                                                                    index,
                                                                )
                                                            }
                                                            naSelected={getDetailsNotValBool(
                                                                'licensures',
                                                                'licensureId',
                                                                index,
                                                            )}
                                                        />
                                                    </Flex>
                                                </Flex>
                                                <Flex direction="row" justify="between" className={styles.formRow}>
                                                    <Flex value={1}>
                                                        <DatePicker
                                                            label="Certification Date"
                                                            onlyPast={true}
                                                            value={s.certificationDate}
                                                            onChange={(date) => {
                                                                if (date) {
                                                                    const value = format(date, API_DATE_FORMAT);
                                                                    updateNestedDetailInput({
                                                                        param: 'licensures',
                                                                        subParam: 'certificationDate',
                                                                        index,
                                                                        value,
                                                                    });
                                                                }
                                                            }}
                                                        />
                                                    </Flex>
                                                    <Flex value={0.1} />
                                                    <Flex value={1}>
                                                        <DatePicker
                                                            label="Issue Date"
                                                            onlyPast={true}
                                                            value={s.initialStateLicensureIssueDate}
                                                            onChange={(date) => {
                                                                if (date) {
                                                                    const value = format(date, API_DATE_FORMAT);
                                                                    updateNestedDetailInput({
                                                                        param: 'licensures',
                                                                        subParam: 'initialStateLicensureIssueDate',
                                                                        index,
                                                                        value,
                                                                    });
                                                                }
                                                            }}
                                                        />
                                                    </Flex>
                                                    <Flex value={0.1} />
                                                    <Flex value={1}>
                                                        <DatePicker
                                                            label="Expiration Date"
                                                            onlyPast={true}
                                                            value={s.currentStateLicensureExpirationDate}
                                                            onChange={(date) => {
                                                                if (date) {
                                                                    const value = format(date, API_DATE_FORMAT);
                                                                    updateNestedDetailInput({
                                                                        param: 'licensures',
                                                                        subParam: 'currentStateLicensureExpirationDate',
                                                                        index,
                                                                        value,
                                                                    });
                                                                }
                                                            }}
                                                        />
                                                    </Flex>
                                                    <Flex value={0.7} align="end" justify="end">
                                                        <Button
                                                            type="primary"
                                                            text="Remove"
                                                            onClick={() => handleDetailsRemoveRow('licensures', index)}
                                                            className={styles.buttonWrap}
                                                        />
                                                    </Flex>
                                                </Flex>
                                            </Flex>
                                        ))}
                                        <Button
                                            type="transparent"
                                            text="+ Add Licensure"
                                            onClick={() => handleDetailsAddRow('licensures')}
                                            className={styles.addUnderline}
                                        />
                                    </Flex>
                                </Flex>
                            </Accordion>

                            <Accordion startOpen={false} label="Certifications" removeExtraSpacing={true}>
                                <Flex direction="row" justify="between" className={styles.formRow}>
                                    <Flex value={1} direction="column" className={styles.newRow}>
                                        <span className={styles.heading}>Certifications</span>
                                        {(data.prvrDetails.certifications || []).map((s, index) => (
                                            <Flex
                                                key={`${index}`}
                                                direction="column"
                                                justify="between"
                                                align="stretch"
                                                className={styles.formRow}
                                            >
                                                <Flex direction="row" justify="between" className={styles.formRow}>
                                                    <Flex value={2}>
                                                        {renderDetailsDropDown({
                                                            key: 'certifications',
                                                            label: 'Practice Level',
                                                            type: OptionTypes.EMSMemberLevel,
                                                            nested: { row: s, key: 'practiceLevelCode', index },
                                                        })}
                                                    </Flex>
                                                    <Flex value={0.1} />
                                                    <Flex value={1}>
                                                        <DatePicker
                                                            label="Certification Date"
                                                            onlyPast={true}
                                                            value={s.dateOfCertification}
                                                            onChange={(date) => {
                                                                if (date) {
                                                                    const value = format(date, API_DATE_FORMAT);
                                                                    updateNestedDetailInput({
                                                                        param: 'certifications',
                                                                        subParam: 'dateOfCertification',
                                                                        index,
                                                                        value,
                                                                    });
                                                                }
                                                            }}
                                                        />
                                                    </Flex>
                                                    <Flex value={0.7} align="end" justify="end">
                                                        <Button
                                                            type="primary"
                                                            text="Remove"
                                                            onClick={() =>
                                                                handleDetailsRemoveRow('certifications', index)
                                                            }
                                                            className={styles.buttonWrap}
                                                        />
                                                    </Flex>
                                                </Flex>
                                            </Flex>
                                        ))}
                                        <Button
                                            type="transparent"
                                            text="+ Add Certification"
                                            onClick={() => handleDetailsAddRow('certifications')}
                                            className={styles.addUnderline}
                                        />
                                    </Flex>
                                </Flex>
                            </Accordion>
                        </>
                    )}
                </Flex>
            </div>
            <ModalFooter
                primaryText={isEdit ? 'Save' : 'Create'}
                primaryClick={handleSave}
                secondaryClick={() => {
                    setData(defaultProvider);
                    props.onClose();
                }}
            />
        </SideModal>
    );
}

export default observer(ProviderModal);
