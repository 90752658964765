// eslint-disable-next-line import/named
import { observable, reaction, IReactionDisposer, action, makeObservable } from 'mobx';
import { createContext } from 'react';
import Storage from 'src/utils/storage';
import { UserStoreObject, UserData } from './UserStore';
import { ErrorStoreObject } from './ErrorStore';
import { loggedInActions } from 'src/utils/startup';
import { OrgStoreObject, Organization } from './OrgStore';
import { FacilityStoreObject } from './FacilityStore';
import { FilterStoreObject } from './FilterStore';
import { AdminStoreObject } from './AdminStore';
import { EncounterStoreObject } from './EncounterStore';
import { EventStateStoreObject } from './EventStateStore';
import { EventStoreObject } from './EventStore';
import { FormStoreObject } from './FormStore';
import { MacraStoreObject } from './MacraStore';
import { NeedStateStoreObject } from './NeedStateStore';
import { OrgUserStoreObject } from './OrgUserStore';
import { PatientMedicationStoreObject } from './PatientMedicationStore';
import { PatientNeedStoreObject } from './PatientNeedStore';
import { PatientNoteAttachmentStoreObject } from './PatientNoteAttachmentStore';
import { PatientStateStoreObject } from './PatientStateStore';
import { PatientStoreObject } from './PatientStore';
import { QcdrSettingsStoreObject } from './QcdrSettingsStore';
import { ReferenceListStoreObject } from './ReferenceListStore';
import { RoleStoreObject } from './RoleStore';
import { TagStoreObject } from './TagStore';
import { TeamStoreObject } from './TeamStore';
import { TelehealthStoreObject } from './TelehealthStore';
import { ScorecardStoreObject } from './ScorecardStore';
import { NemsisStoreObject } from './NemsisStore';

interface Auth0User {
    email: string;
    family_name: string;
    given_name: string;
    ['https://graphiumemr.com/ghIndexUser']: UserData;
    ['https://graphiumemr.com/ghOrganizations']: Organization[];
    name: string;
    nickname: string;
    picture: string;
    sub: string;
    updated_at: string;
}

type StartupDataType = 'login' | 'orgswitch' | 'load' | null;
class AuthStore {
    private auth0: any = {};
    private IDLE_MINUTES: number = import.meta.env.VITE_APP_ENV === 'local' ? 1440 : 15; // 24 hours for local, 15 min for everything else
    private IDLE_TIMEOUT_DURATION: number = this.IDLE_MINUTES * 60 * 1000;
    private idleTimer: NodeJS.Timeout = null;
    private authReactionDispose: IReactionDisposer;

    @observable isAuthenticated: boolean = false;
    @observable isIdle: boolean = false;
    @observable isLoadingStartupData: StartupDataType = null;
    @observable accessToken: string = '';

    constructor() {
        makeObservable(this);

        if (this.isAuthenticated && FacilityStoreObject.facilities.length === 0) {
            this.setLoadingStartupData('load');
        }
    }

    @action
    setLoadingStartupData(val: StartupDataType | null) {
        if (this.isLoadingStartupData !== val) {
            this.isLoadingStartupData = val;
        }
    }

    @action
    setIdle(idleState: boolean) {
        this.isIdle = idleState;
    }

    @action
    async handleAuthentication(auth0: any) {
        // Store auth0 object for use
        this.auth0 = auth0;
        // Setup user
        const user: Auth0User = auth0.user;
        const ghIndexUser: any = user['https://graphiumemr.com/ghIndexUser'];
        const ghOrganizations: any = user['https://graphiumemr.com/ghOrganizations'];

        const { emailAddr, frstNm, lastNm, usrId, usrNm, graphiumAdminInd } = ghIndexUser;

        UserStoreObject.setUserData({
            indexEmailAddr: emailAddr,
            frstNm,
            lastNm,
            indexUsrId: usrId,
            usrNm,
            adminIndicator: graphiumAdminInd,
            // Need to provide a valid organization so that authentication will pass and validate the user in that org
            selectedOrg: ghOrganizations[0],
        });

        this.isLoadingStartupData = 'login';
        this.isAuthenticated = true;

        const token = await auth0.getAccessTokenSilently({
            audience: import.meta.env.VITE_AUTH0_AUDIENCE,
        });
        this.accessToken = token;

        // Load the logged in actions
        loggedInActions();
    }

    logout = () => {
        this.resetAllStores();
        Storage.clear();
        ErrorStoreObject.clearAllErrors();
        this.auth0.logout({ localOnly: true });
        this.isAuthenticated = false;
        this.setIdle(false);
    };

    startIdleTimeout = () => {
        this.idleTimer = setTimeout(() => this.handleIdle(), this.IDLE_TIMEOUT_DURATION);
        window.onclick = this.restartIdleTimeout;
        window.onmousemove = this.restartIdleTimeout;
        window.onkeypress = this.restartIdleTimeout;
        window.onscroll = this.restartIdleTimeout;
    };

    clearIdleTimeout = () => {
        if (this.idleTimer !== null) clearTimeout(this.idleTimer);
        window.onclick = null;
        window.onmousemove = null;
        window.onkeypress = null;
        window.onscroll = null;
    };

    restartIdleTimeout = () => {
        this.clearIdleTimeout();
        this.startIdleTimeout();
        this.setIdle(false);
    };

    handleIdle = () => {
        this.clearIdleTimeout();
        this.setIdle(true);
    };

    handleResetIdle = () => {
        this.restartIdleTimeout();
    };

    setupReactions = () => {
        this.authReactionDispose = reaction(
            () => this.isAuthenticated,
            (isAuthenticated) => {
                if (isAuthenticated) this.startIdleTimeout();
                else this.clearIdleTimeout();
            },
            { fireImmediately: true },
        );
    };

    disposeReactions = () => {
        this.authReactionDispose();
    };

    getAccessToken = () => {
        return this.accessToken;
    };

    resetAllStores(exclude: ('user' | 'org')[] = []) {
        if (!exclude.includes('user')) {
            UserStoreObject.reset();
        }
        if (!exclude.includes('org')) {
            OrgStoreObject.reset();
        }

        AdminStoreObject.reset();
        EncounterStoreObject.reset();
        EventStateStoreObject.reset();
        EventStoreObject.reset();
        FacilityStoreObject.reset();
        FilterStoreObject.reset();
        FormStoreObject.reset();
        MacraStoreObject.reset();
        NeedStateStoreObject.reset();
        OrgUserStoreObject.reset();
        PatientMedicationStoreObject.reset();
        PatientNeedStoreObject.reset();
        PatientNoteAttachmentStoreObject.reset();
        PatientStateStoreObject.reset();
        PatientStoreObject.reset();
        QcdrSettingsStoreObject.reset();
        ReferenceListStoreObject.reset();
        RoleStoreObject.reset();
        TagStoreObject.reset();
        TeamStoreObject.reset();
        TelehealthStoreObject.reset();
        ScorecardStoreObject.reset();
        NemsisStoreObject.reset();
    }
}

// Import this to any other store that needs to use a value from it
export const AuthStoreObject = new AuthStore();

export default createContext(AuthStoreObject);
