import * as ROUTES from 'src/api/routes';
import request from 'src/api/utils';
import { UserStoreObject } from 'src/stores/UserStore';

export async function updateUser(userId: number, data: { [key: string]: any }) {
    if (data.oldPassword && data.newPassword) {
        // If trying to update password, do that and then update the user
        await updatePassword({ oldPassword: data.oldPassword, newPassword: data.newPassword });
        delete data.oldPassword;
        delete data.newPassword;
    }
    return await request({ ...ROUTES.UPDATE_MY_USER, pathParams: { userId }, data });
}

export async function updatePassword(data: object) {
    const username = UserStoreObject.usrNm;
    return await request({ ...ROUTES.UPDATE_MY_USER_PASSWORD, pathParams: { username }, data });
}
