import { action, observable, makeObservable } from 'mobx';
import { createContext } from 'react';
import * as telehealthApi from 'src/api/telehealth';

class TelehealthStore {
    @observable telehealthVisits: any = [];
    @observable totalTelehealthVisitsCount: number = 0;

    constructor() {
        makeObservable(this);
    }

    @action
    reset() {
        this.telehealthVisits = [];
        this.totalTelehealthVisitsCount = 0;
    }

    @action
    async getTelehealthVisits(params: any) {
        const { result, count } = await telehealthApi.getFilteredTelehealthSessions(params);
        this.setTelehealthVisits(result || [], count);
    }

    @action
    setTelehealthVisits(visits: any, total: number = 0) {
        this.telehealthVisits = visits;
        this.totalTelehealthVisitsCount = total;
    }
}

// Import this to any other store that needs to use a value from it
export const TelehealthStoreObject = new TelehealthStore();

export default createContext(TelehealthStoreObject);
