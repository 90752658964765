import React, { useContext, useState, useRef, useEffect } from 'react';
import { observer } from 'mobx-react';
import CsvDownloader from 'react-csv-downloader';
import classNames from 'classnames';
import { CellInfo, Column, FinalState } from 'react-table';
import styles from 'src/containers/Reports/Macra/styles.module.scss';
import * as variables from 'src/styles/variables';
import Flex from 'src/components/Flex';
import Button from 'src/components/Button';
import Input from 'src/components/Input';
import Table from 'src/components/Table';
import { PercentageDetailCell } from 'src/components/CellRenderers/PercentageDetailCell';
import MacraStore from 'src/stores/MacraStore';
import { ICONS } from 'src/utils/constants';
import { AbgQcdrMeasure } from 'common/lib/model/macra/Macra';
import { measureDefinitions2023, measureDefinitions2024 } from 'common/lib/util/Macra';

function ProviderTab() {
    const macraStore = useContext(MacraStore);
    const [csvHeaders, setCsvHeaders] = useState(null);
    const [csvData, setCsvData] = useState(null);
    const [providerTableFilter, setProviderTableFilter] = useState('');
    const populationCsvLink = useRef(null);
    const providerTable = useRef(null);

    let measureDefinitions;
    switch (macraStore.year) {
        case 2023:
            measureDefinitions = measureDefinitions2023;
            break;
        case 2024:
        default:
            measureDefinitions = measureDefinitions2024;
            break;
    }
    const measureColumns = measureDefinitions.map((measureDefinition: AbgQcdrMeasure) => ({
        Header: measureDefinition.name.toUpperCase(),
        id: measureDefinition.name.toUpperCase(),
        accessor: (row: any) =>
            row[measureDefinition.name.toUpperCase()]
                ? Math.round(row[measureDefinition.name.toUpperCase()].value * 100)
                : 0,
        minWidth: 75,
        headerClassName: classNames(styles.tableHeader, styles.alignCenter),
        className: styles.tableFontSize,
        Cell: (props: CellInfo) => <PercentageDetailCell data={props.original[measureDefinition.name.toUpperCase()]} />,
    }));
    const byProviderColumns: Column[] = [
        {
            Header: 'PROVIDER',
            accessor: 'provider',
            minWidth: 150,
            headerClassName: styles.tableHeader,
            className: styles.tableFontSize,
        },
        {
            Header: 'CASES',
            accessor: 'totalCases',
            minWidth: 75,
            headerClassName: classNames(styles.tableHeader, styles.alignCenter),
            className: styles.tableFontSize,
            Cell: (props: CellInfo) => <div style={{ textAlign: 'center' }}>{props.value}</div>,
        },
        {
            Header: 'FORM CMPLT %',
            id: 'formCompletion.value',
            accessor: (row) => Math.round(row.formCompletion.value * 100),
            minWidth: 125,
            headerClassName: classNames(styles.tableHeader, styles.alignCenter),
            className: styles.tableFontSize,
            Cell: (props: CellInfo) => <PercentageDetailCell data={props.original.formCompletion} showBgState={true} />,
        },
        {
            Header: '% ADMISSIBLE',
            id: 'admissiblePercentage',
            accessor: (row) => Math.round(row.admissiblePercentage.value * 100),
            minWidth: 125,
            headerClassName: classNames(styles.tableHeader, styles.alignCenter),
            className: styles.tableFontSize,
            Cell: (props: CellInfo) => <PercentageDetailCell data={props.original.admissiblePercentage} />,
        },
        ...measureColumns,
    ];

    useEffect(() => {
        updateCsvData();
    }, [macraStore.byProviderTableData, providerTableFilter]);

    function updateCsvData() {
        if (!providerTable.current) return;

        const { columns, sortedData } = providerTable.current.getResolvedState() as FinalState;

        const headers = columns.map((column, index) => {
            return {
                displayName: column.Header,
                id: column.id ? column.id : column.accessor,
            };
        });

        setCsvData(sortedData);
        setCsvHeaders(headers);
    }

    return (
        <div className={styles.tabContent}>
            <Flex direction="row" align="center" justify="end">
                {csvData && csvData.length > 0 ? (
                    <CsvDownloader
                        className={styles.wrapRight}
                        columns={csvHeaders}
                        datas={csvData}
                        filename={'ByProviderCSV.csv'}
                    >
                        <Button onClick={null}>Download CSV</Button>
                    </CsvDownloader>
                ) : null}
                <Input
                    icon={ICONS.Search}
                    iconColor={variables.grey}
                    iconSize={20}
                    placeholder="Filter Table"
                    value={providerTableFilter}
                    onChangeText={(text: string) => {
                        setProviderTableFilter(text);
                    }}
                />
            </Flex>
            <div className={styles.tableWrap}>
                <Table
                    ref={providerTable}
                    isLoading={macraStore.isLoading}
                    columns={byProviderColumns}
                    data={macraStore.byProviderTableData}
                    showPagination={true}
                    globalFilter={providerTableFilter}
                />
            </div>
        </div>
    );
}

export default observer(ProviderTab);
