import React from 'react';

const SvgEdit = (props) => (
    <svg width={31} height={31} {...props} viewBox="0 0 31 31">
        <g fill={props.color || '#FFF'} fillRule="evenodd">
            <circle opacity={0.1} cx={15.5} cy={15.5} r={15.5} />
            <g fillRule="nonzero">
                <path d="M9.715 18.463a.285.285 0 00-.479.14L8.43 22.23a.285.285 0 00.34.34l3.627-.806a.285.285 0 00.14-.48l-2.822-2.82zM18.379 13.225l-6.246 6.246a.144.144 0 000 .201l1.008 1.008c.112.109.29.109.403 0l6.044-6.044a.285.285 0 000-.403l-1.007-1.008a.144.144 0 00-.202 0zM17.775 12.42l-1.008-1.008a.285.285 0 00-.403 0l-6.044 6.044a.285.285 0 000 .403l1.008 1.008a.144.144 0 00.201 0l6.246-6.246a.144.144 0 000-.202zM20.999 10.002a2.282 2.282 0 00-3.225 0l-.403.403a.285.285 0 000 .403l2.821 2.82c.113.107.29.107.403 0l.404-.403a2.28 2.28 0 000-3.223z" />
            </g>
        </g>
    </svg>
);

export default SvgEdit;
