import React, { useState, useContext, useEffect } from 'react';
import classNames from 'classnames';
import { CellInfo } from 'react-table';
import { observer } from 'mobx-react';
import NavBar from 'src/containers/NavBar';
import PageContent from 'src/components/PageContent';
import PageContainer from 'src/components/PageContainer';
import Card from 'src/components/Card';
import Flex from 'src/components/Flex';
import Button from 'src/components/Button';
import Icon from 'src/components/Icon';
import Table from 'src/components/Table';
import styles from './styles.module.scss';
import * as providerService from 'src/api/providers';
import { Provider } from 'src/stores/ProviderStore';
import { ToastStoreObject } from 'src/stores/ToastStore';
import { ErrorStoreObject, ErrorTypes } from 'src/stores/ErrorStore';
import { parseError } from 'src/utils';
import FacilityDropDown from 'src/components/FacilityDropDown';
import { ICONS } from 'src/utils/constants';
import * as variables from 'src/styles/variables';
import FilterStore from 'src/stores/FilterStore';
import Pagination from 'src/components/Table/Pagination';
import ReferenceListItemDisplay from 'src/components/ReferenceListItemDisplay';
import ReferenceListStore, { RefListTypes } from 'src/stores/ReferenceListStore';
import { ModalStoreObject, ModalTypes } from 'src/stores/ModalStore';
import { ANALYTICS_NAMES } from 'src/utils/analytics';
import UserStore, { OrgType } from 'src/stores/UserStore';
import { Facility } from 'src/stores/FacilityStore';
import useDebouncedCallback from 'src/utils/hooks/useDebouncedCallback';
import Input from 'src/components/Input';
import { OptionTypes } from 'src/components/OptionDropDown';
import NemsisErrorBanner, { NemsisErrorBannerMode } from '../NemsisErrorBanner';
import NemsisStore from 'src/stores/NemsisStore';
import useFlag from 'src/utils/hooks/useFlag';

const FILTER_PAGE = 'providersPage';

function Providers() {
    const userStore = useContext(UserStore);
    const filterStore = useContext(FilterStore);
    const refListStore = useContext(ReferenceListStore);
    const [isLoading, setIsLoading] = useState(false);
    const [providerState, setProviderState] = useState([]);
    const [providersTotal, setProvidersTotal] = useState(0);
    const [searchValue, setSearchValue] = useState(filterStore[FILTER_PAGE].query);
    const [isFieldMed, setIsFieldMed] = useState(false);
    const nemsisEnabled = useFlag('nemsis');

    const columns = [
        {
            Header: 'FIRST NAME',
            accessor: 'frstNm',
            minWidth: 150,
        },
        {
            Header: 'LAST NAME',
            accessor: 'lastNm',
            minWidth: 150,
        },
        {
            Header: 'NPI',
            accessor: 'natlPrvrId',
            minWidth: 150,
        },
        {
            Header: 'TYPE',
            accessor: 'prvrTyp',
            minWidth: 150,
            Cell: (cellProps: CellInfo) => (
                <ReferenceListItemDisplay type={RefListTypes.ProviderTypes} value={cellProps.value} />
            ),
        },
        {
            Header: 'SPECIALTY',
            accessor: 'spclty',
            minWidth: 200,
        },
        {
            Header: 'ACTIVE',
            accessor: 'actvInd',
            minWidth: 100,
            Cell: (props: CellInfo) => (
                <span className={classNames({ [styles.redText]: !props.value, [styles.greenText]: props.value })}>
                    {props.value ? 'ACTIVE' : 'INACTIVE'}
                </span>
            ),
        },
        {
            Header: 'ACTIONS',
            accessor: 'actvInd',
            minWidth: 200,
            style: { paddingVertical: 0, paddingHorizontal: 20 },
            Cell: (props: CellInfo) => (
                <Flex direction="row">
                    <Button
                        onClick={() => {
                            showModal(props.original);
                        }}
                        type="secondary"
                        isLocked={!userStore.userPermissions.canEdit.providers}
                        leftIcon={<Icon name={ICONS.Pencil2} color={variables.red} />}
                        data-test-id={ANALYTICS_NAMES.Providers_Table_Edit}
                    />
                    {props.value ? (
                        <Button
                            onClick={() => handleConfirm(props.original.prvrId)}
                            type="secondary"
                            text="Deactivate"
                            isLocked={!userStore.userPermissions.canEdit.providers}
                            className={styles.actionButtons}
                            data-test-id={ANALYTICS_NAMES.Providers_Table_Deactivate}
                        />
                    ) : (
                        <Button
                            onClick={() => handleReactivate(props.original.prvrId)}
                            type="primary"
                            text="Reactivate"
                            isLocked={!userStore.userPermissions.canEdit.providers}
                            className={styles.actionButtons}
                            data-test-id={ANALYTICS_NAMES.Providers_Table_Reactivate}
                        />
                    )}
                </Flex>
            ),
        },
    ];

    const facilityId = filterStore[FILTER_PAGE].facility[0];
    const [updateFilterQuery] = useDebouncedCallback((query: string) => {
        const updatedFilters = { ...filterStore[FILTER_PAGE] };
        updatedFilters.query = query;
        // Whenever we change filters, we should reset to page 1
        updatedFilters.pagination.page = 1;
        filterStore.setFilters(FILTER_PAGE, updatedFilters);
    }, 250);

    useEffect(() => {
        loadRefLists();
        setIsFieldMed(userStore.selectedOrgType === OrgType.FIELDMED);
    }, []);

    async function loadRefLists() {
        try {
            await refListStore.getMultipleLists([
                OptionTypes.ProviderTypes,
                OptionTypes.ANSIStateCodes,
                OptionTypes.EMSANSICountryCodesCAMXUS,
                OptionTypes.EMSGender,
                OptionTypes.EMSDemographicRace,
                OptionTypes.ANSICountryCodes,
                OptionTypes.EMSPersonnelHighestEducationalDegree,
                OptionTypes.EMSPersonnelDegreeFieldofStudy,
                OptionTypes.EMSPersonnelVehicleLicenseType,
                OptionTypes.EMSPersonnelForeignLanguageAbility,
                OptionTypes.EMSNationalRegistryCertificationLevel,
                OptionTypes.EMSEmploymentStatus,
                OptionTypes.EMSJobResponsibilities,
                OptionTypes.EMSPhoneNumberType,
                OptionTypes.EMSEmailAddressType,
                OptionTypes.EMSImmunizationType,
                OptionTypes.EMSMemberLevel,
            ]);
        } catch (e) {}
    }

    async function getProviders() {
        try {
            setIsLoading(true);
            const { result, count } = await providerService.getProviders({ filters: filterStore[FILTER_PAGE] });
            setProviderState(result);
            setProvidersTotal(count);
            setIsLoading(false);
        } catch (e) {
            ErrorStoreObject.setError(ErrorTypes.Loading);
        }
    }
    function handleUpdateSearchQuery(t: string) {
        setSearchValue(t);
        updateFilterQuery(t);
    }

    useEffect(() => {
        if (filterStore[FILTER_PAGE].facility.length > 0 || filterStore[FILTER_PAGE].query.length > 0) {
            getProviders();
        }
    }, [filterStore[FILTER_PAGE]]);

    function handleChangeFacility(newFacilityId: Facility['facId']) {
        if (newFacilityId) {
            const updatedFilters = { ...filterStore[FILTER_PAGE] };
            updatedFilters.facility = [newFacilityId];

            // Whenever we change filters, we should reset to page 1
            updatedFilters.pagination.page = 1;
            filterStore.setFilters(FILTER_PAGE, updatedFilters);
        }
    }

    const handleReactivate = async (providerId: number) => {
        try {
            await providerService.reactivateProvider(facilityId, providerId);
            getProviders();
        } catch (e) {
            ToastStoreObject.show(parseError(e));
        }
    };

    async function handleDeactivate(providerId: number) {
        try {
            await providerService.deactivateProvider(facilityId, providerId);
            getProviders();
            ModalStoreObject.hideModal();
        } catch (e) {
            ToastStoreObject.show(parseError(e));
        }
    }
    const handlePaginationFilterChange = function (newPage: number) {
        const updatedFilters = { ...filterStore[FILTER_PAGE] };
        updatedFilters.pagination.page = newPage;
        filterStore.setFilters(FILTER_PAGE, updatedFilters);
    };

    function handleConfirm(providerId: number) {
        ModalStoreObject.showModal(ModalTypes.ConfirmationModal, {
            title: 'Are you sure you would like to deactivate this provider?',
            onConfirm: () => handleDeactivate(providerId),
            onCancel: () => ModalStoreObject.hideModal(),
            confirmButtonText: 'Deactivate',
        });
    }

    function showModal(provider: Provider = null) {
        ModalStoreObject.showModal(ModalTypes.Provider, {
            onClose: () => ModalStoreObject.hideModal(),
            onSave: () => onSave(),
            provider,
            facilityId,
        });
    }

    async function onSave() {
        ModalStoreObject.hideModal();
        await getProviders();
    }

    return (
        <PageContainer>
            <NavBar />
            <PageContent>
                {isFieldMed && nemsisEnabled && <NemsisErrorBanner mode={NemsisErrorBannerMode.DEM} />}
                <Flex self="stretch" align="center" justify="start">
                    <Flex value={1} direction="row">
                        <Flex value={1} className={styles.filterWrap}>
                            <FacilityDropDown
                                selectedValue={facilityId}
                                onChange={(filters: any) => handleChangeFacility(filters.value)}
                                placeholder="Filter by Facility"
                            />
                        </Flex>
                        <Flex value={1} className={styles.searchWrap}>
                            <Input
                                className={styles.input}
                                placeholder="Search..."
                                value={searchValue}
                                onChangeText={handleUpdateSearchQuery}
                            />
                        </Flex>
                        <Flex value={1} />
                    </Flex>
                    <Button
                        leftIcon={<Icon className={styles.plusIcon} name={ICONS.PlusButton} />}
                        type="primary"
                        onClick={() => {
                            showModal();
                        }}
                        isLocked={!userStore.userPermissions.canEdit.providers}
                        text="New Provider"
                        data-test-id={ANALYTICS_NAMES.Providers_Create}
                    />
                </Flex>
                <Card className={styles.cardWrap} data-test-id={ANALYTICS_NAMES.Providers_Content}>
                    <Flex value={1} className={styles.pageTitle}>
                        <span data-test-id={ANALYTICS_NAMES.Providers_Title}>Providers</span>
                    </Flex>
                    <Table
                        isLoading={isLoading}
                        columns={columns}
                        data={providerState}
                        showPagination={false}
                        pageSize={filterStore[FILTER_PAGE].pagination.limit}
                        getProps={() => ({ 'data-test-id': ANALYTICS_NAMES.Providers_Table })}
                    />
                    <Pagination
                        pages={Math.ceil(providersTotal / filterStore[FILTER_PAGE].pagination.limit) || 1}
                        page={filterStore[FILTER_PAGE].pagination.page - 1}
                        onPageChange={(page: number) => handlePaginationFilterChange(page + 1)}
                        showing={providerState.length}
                        totalRecords={providersTotal}
                    />
                </Card>
            </PageContent>
        </PageContainer>
    );
}
export default observer(Providers);
