import React, { useState, useEffect } from 'react';
import Flex from 'src/components/Flex';
import styles from '../../styles.module.scss';
import Checkbox from 'src/components/Checkbox';

interface YesNoProps {
    input: any;
    isValid: boolean;
    onChange?: Function;
    value?: Boolean;
}

function YesNo(props: YesNoProps) {
    const [value, setValue] = useState(props.value || null);

    useEffect(() => {
        if (props.onChange) {
            props.onChange(value);
        }
    }, [value]);

    return (
        <div>
            <div className={styles.label}>{props.input.title ? props.input.title[0] : ''}</div>
            <Flex align="center" className={styles.inputWrap}>
                <Checkbox
                    label="No"
                    infoState={props.isValid ? null : 'error'}
                    onChange={() => {
                        if (value === false) {
                            setValue(null);
                        } else {
                            setValue(false);
                        }
                    }}
                    checked={value === false}
                    className={styles.yesNoLeft}
                />
                <Checkbox
                    label="Yes"
                    infoState={props.isValid ? null : 'error'}
                    onChange={() => {
                        if (value === true) {
                            setValue(null);
                        } else {
                            setValue(true);
                        }
                    }}
                    checked={value === true}
                    className={styles.yesNoRight}
                />
            </Flex>
        </div>
    );
}

export default YesNo;
