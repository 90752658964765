import * as ROUTES from 'src/api/routes';
import request from './utils';
import { FormAsset } from 'common/lib/entity/framework/FormAsset';

export async function getFormAssets() {
    const result = await request({ ...ROUTES.GET_FORM_ASSETS });
    return result?.data || [];
}

export async function getFormAssetById(formAssetId: FormAsset['formAssetId']) {
    const result = await request({ ...ROUTES.GET_FORM_ASSET_BY_ID, pathParams: { formAssetId } });
    return result?.data || {};
}

export async function getFormAssetTypes() {
    const result = await request({ ...ROUTES.GET_FORM_ASSET_TYPES });
    return result?.data || [];
}

export async function createFormAsset(
    formAsset: Partial<FormAsset>,
): Promise<{ formAssetId: FormAsset['formAssetId'] }> {
    const result = await request({ ...ROUTES.CREATE_FORM_ASSET, data: { ...formAsset } });
    return result?.data || {};
}

export async function updateFormAsset(
    formAssetId: FormAsset['formAssetId'],
    formAsset: Partial<FormAsset>,
): Promise<void> {
    await request({ ...ROUTES.UPDATE_FORM_ASSET, pathParams: { formAssetId }, data: { ...formAsset } });
}
