import { EventInput } from '@fullcalendar/core';
import { PaginationQuery } from 'src/stores/FilterStore';
import { FilteredSearchQuery } from 'src/components/FilteredSearch';
import { EventState } from 'src/stores/EventStateStore';
import { Team } from 'src/stores/TeamStore';
import { Patient } from 'src/stores/PatientStore';
import { Facility } from 'src/stores/FacilityStore';

export type ReactSelectOption = { label: string; value: string | number };

export interface ModelDefinition {
    modelDefnId?: number;
    modelDefnNm?: string;
    modelDefnDesc?: string;
    modelDefnVer?: number;
    modelDefnCntnt?: string;
    actvInd?: boolean;
    insDttm?: Date;
    updDttm?: Date;
    audVer?: number;
    formDefinitionHistories?: FormDefinitionHistory[];
    formDefinitions?: FormDefinition[];
}

export interface FormDefinition {
    formDefnId?: number;
    facId?: number;
    modelDefnId?: number;
    formDefnNm?: string;
    formDefnDesc?: string;
    formDefnTitle?: string;
    formDefnVer?: number;
    formDefnCntnt?: string;
    modelDefnVer?: number;
    formDefnTyp?: string;
    formDefnSchemaVer?: string;
    propMap?: string;
    insDttm?: Date;
    updDttm?: Date;
    audVer?: number;
    actvInd?: boolean;
    modelDefinition: ModelDefinition;
    encounterForms?: EncounterForm[];
}

export interface FormDefinitionHistory {
    formDefnHistId?: number;
    formDefnId?: number;
    modelDefnId?: number;
    formDefnNm?: string;
    formDefnDesc?: string;
    formDefnTitle?: string;
    formDefnVer?: number;
    formDefnCntnt?: string;
    actvInd?: boolean;
    insDttm?: Date;
    modelDefnVer?: number;
    formDefnTyp?: string;
    formDefnSchemaVer?: string;
    propMap?: string;
}

export interface EncounterForm {
    enctrFormId?: number;
    enctrId?: number;
    formDefnId?: number;
    formDefnVer?: number;
    formValidInd?: boolean;
    voidInd?: boolean;
    formCmpltPct?: number;
    formLastOpndDttm?: string;
    extStrokeStorageInd?: boolean;
    insDttm?: Date;
    updDttm?: Date;
    formDefinition?: FormDefinition;
    formDefinitionHistory?: FormDefinitionHistory;
    encounter?: Encounter;
}

export interface EncounterFormRaw {
    formCategory: 'chp' | 'ems' | 'surgery';
    enctr_form_id?: number;
    enctr_id?: number;
    form_defn_id?: number;
    form_defn_ver?: number;
    form_valid_ind?: boolean;
    void_ind?: boolean;
    form_cmplt_pct?: number;
    form_last_opnd_dttm?: Date;
    ext_stroke_storage_ind?: boolean;
    aud_ver?: number;
    ins_dttm?: Date;
    upd_dttm?: Date;

    tagNames: string[];
    encounterFormModel: any;
    formDefinitionHistory: {
        form_defn_hist_id?: number;
        form_defn_id?: number;
        form_defn_title?: string;
        form_defn_ver?: number;
    };
    encounter: {
        ins_dttm?: Date;
        upd_dttm?: Date;
        enctr_id?: number;
        fac_id?: number;
        enctr_no?: string;
        admit_dt?: Date;
        admit_tm?: string;
        disch_dt?: Date;
        disch_tm?: string;
        pat_mrn?: string;
        pat_frst_nm?: string;
        pat_last_nm?: string;
        pat_dob?: Date;
        pat_gender_cd?: string;
        pat_ssn?: string;
        pat_addr_ln_1?: string;
        pat_addr_ln_2?: string;
        pat_city?: string;
        pat_state_cd?: string;
        pat_zip_cd?: string;
        pat_ph_no_home?: string;
        pat_ph_no_bus?: string;
        pat_ph_no_mob?: string;
        pat_email_home?: string;
        pat_email_bus?: string;
        patient_id?: number;
        enctr_admit_typ?: string;
        enctr_pat_class?: string;
        enctr_pat_typ?: string;
        enctr_hosp_svc_cd?: string;
    };
}

export interface EncounterFormPdfResponse {
    auditVersion?: number;
    createTime?: string;
    encounterFormId?: number;
    encounterId?: number;
    formDef?: {
        active?: boolean;
        createTime?: string;
        formDefinitionContent?: any;
        formDefinitionDescription?: string;
        formDefinitionId?: number;
        formDefinitionName?: string;
        formDefinitionPages?: {
            createTime?: string;
            formDefinitionId?: number;
            formDefinitionPageHistId?: number;
            formDefinitionPageId?: number;
            pageName?: string;
            pageContent?: any;
        }[];
        formDefinitionTitle?: string;
        formDefinitionType?: string;
        formDefinitionVersion?: number;
        modelDefinitionId?: number;
        schemaVersion?: string;
    };
    formDefinitionId?: number;
    formDefinitionType?: string;
    formDefinitionVersion?: number;
    formTags?: any[];
    formValid?: boolean;
    lastOpenedDate?: null;
    modelData?: {
        formBitmaps?: any[];
        formDetails?: any[];
        primaryFormData?: any;
    };
    pages?: {
        auditVersion?: number;
        createTime?: string;
        encounterFormId?: number;
        pageId?: number;
        pageName?: string;
        pageNumber?: number;
        updateTime?: string;
    }[];
    percentComplete?: null;
    strokes?: any[];
    updateTime?: string;
    voided?: boolean;
    facility?: FacilityLongKeys;
    organization?: OrganizationLongKeys;
}

export interface Encounter {
    enctrId?: number;
    facId?: number;
    enctrNo?: string;
    patMrn?: string;
    patLastNm?: string;
    patFrstNm?: string;
    patMidNm?: string;
    patSsn?: string;
    patDob?: string;
    patZipCd?: string;
    admitDt?: string;
    admitTm?: string;
    dischDt?: string;
    dischTm?: string;
    purgedInd?: boolean;
    purgedDttm?: Date;
    patAddrLn1?: string;
    patAddrLn2?: string;
    patCity?: string;
    patStateCd?: string;
    patCntryCd?: string;
    patPhNoHome?: string;
    patPhNoBus?: string;
    patPhNoMob?: string;
    patEmailHome?: string;
    patEmailBus?: string;
    patDrLicNo?: string;
    patDrLicSt?: string;
    patDrLicExp?: Date;
    patOccup?: string;
    patPrimLang?: string;
    patMaritalStatus?: string;
    patReligion?: string;
    patGenderCd?: string;
    patRace?: string;
    patEthnicGrp?: string;
    patNationality?: string;
    patDeceasedInd?: boolean;
    patDeceasedDt?: Date;
    enctrAdmitTyp?: string;
    enctrPatClass?: string;
    enctrPatTyp?: string;
    enctrHospSvcCd?: string;
    accDt?: Date;
    accCd?: string;
    accDesc?: string;
    accLocn?: string;
    accAutoStCd?: string;
    accAutoStNm?: string;
    accJobRelInd?: string;
    accDeathInd?: string;
    enctrInsuranceDoc?: JSON;
    enctrDiagnosisDoc?: JSON;
    enctrRelationsDoc?: JSON;
    enctrGuarantorDoc?: JSON;
    enctrFinClass?: string;
    enctrStatCd?: string;
    patientId?: number;
    eventId?: number;
    insDttm?: Date;
    updDttm?: Date;
    audVer?: number;
    forms?: EncounterForm[];
    patient?: Patient;
    facility?: Facility;
    telehealthSession?: TelehealthSession[];
}

export interface Event {
    eventId?: number;
    eventClassCode?: string;
    eventName?: string;
    eventDescription?: string;
    eventLocation?: string;
    eventTypeCode?: string;
    eventStateId?: number;
    facId?: number;
    eventStartDate?: string;
    eventStartTime?: string;
    eventEndDate?: string;
    eventEndTime?: string;
    assigneeUsrId?: number;
    assigneeTeamId?: number;
    patientId?: number;
    cancelReasonType?: string;
    cancelReasonText?: string;
    rescheduledEventId?: number;
    eventState?: EventState;
    assigneeTeam?: Team;
    assigneeUsr?: any;
    patient?: Patient;
    encounter?: Encounter;
}

export interface CalendarEventInterface extends EventInput {
    title: string;
    start: Date;
    end: Date;
    extendedProps: {
        ghEvent: Event;
    };
}

export type FilterQueryParams = {
    query?: string;
    pagination?: PaginationQuery;
    orStatement?: boolean;
    filters?: FilteredSearchQuery['fields'];
};

export type FlowScriptTemplateHistory = {
    flowScriptTemplateHistoryId: number | string;
    flowScriptTemplateGuid?: string;
    flowScriptTemplateVersion?: number;
    flowScriptTemplateHistoryNote?: string;
    scriptContent: string;
    defaultHandler: string;
    configParams: {};
    createdAt: Date | string;
    updatedAt: Date | string;
    template?: FlowScriptTemplate | null;
    execAttempts?: ScriptExecutionAttempt[];
};

export type FlowScriptType = {
    flowScriptTypeId: number | string;
    flowScriptTypeCode: string;
    flowScriptTypeTitle: string;
    flowScriptTypeDescription?: string;
    createdAt: Date | string;
    updatedAt: Date | string;
    flowScriptTemplates?: FlowScriptTemplate[];
    flowScripts?: FlowScript[];
};

export type FlowScriptTemplateOrgs = {
    flowScriptTemplateOrgId: number;
    flowScriptTemplateGuid: string;
    orgNameInternal: string;
    createdAt: Date;
    flowScriptTemplate?: FlowScriptTemplate | null;
};

export type FlowScriptTemplate = {
    flowScriptTemplateGuid: string;
    flowScriptTemplateTitle: string;
    flowScriptTemplateDescription: string;
    active?: boolean;
    createdAt: Date | string;
    updatedAt: Date | string;
    systemGlobal: boolean;
    flowScriptTypeId: number | string;
    publishedVersion: number;
    publishedVersionHistory: any[];
    flowScriptType?: FlowScriptType;
    flowScriptTemplateOrgs?: FlowScriptTemplateOrgs[] | null;
    histories?: FlowScriptTemplateHistory[];
    flowScripts?: FlowScript[];
    flowScriptHistories?: FlowScriptHistory[];
};

export type FlowScriptHistory = {
    flowScriptHistoryId: number;
    flowScriptGuid: string;
    flowScriptVersion?: number;
    flowScriptTemplateGuid?: string;
    flowScriptTitle: string;
    flowScriptDescription?: string;
    scriptContent?: string;
    configParams: {};
    configValues: string;
    timeoutSeconds?: number;
    maxRetries?: number;
    templateVersionAlias: string;
    createdAt: Date;
    defaultHandler?: string;
    flowScript?: FlowScript | null;
    flowScriptTemplate?: FlowScriptTemplate | null;
    scriptExecutionAttempt?: ScriptExecutionAttempt | null;
};

export type FlowScript = {
    flowScriptGuid: string;
    flowScriptTypeId: number;
    active: boolean;
    createdAt: Date;
    updatedAt: Date;
    publishedVersion: number;
    publishedVersionHistory: [];
    flowScriptType?: FlowScriptType | null;
    flowScriptHistories?: FlowScriptHistory[];
    scheduledJobs?: ScheduledJob[];
};

export type ScheduledJob = {
    scheduledJobGuid: string;
    jobScheduleId: number;
    orgNameInternal: string;
    flowScriptGuid: string;
    scheduledJobTitle: string;
    scheduledJobDescription?: string;
    active?: boolean;
    createdAt: Date;
    updatedAt: Date;
    jobSchedule?: JobSchedule | null;
    flowScript?: FlowScript | null;
    scheduledJobRuns?: ScheduledJobRun[];
};

export type JobSchedule = {
    jobScheduleId: number;
    jobScheduleCode: string;
    jobScheduleTitle: string;
    jobScheduleDescription?: string;
    createdAt: Date;
    updatedAt: Date;
    scheduledJobs?: ScheduledJob[];
};

export type ScheduledJobRun = {
    scheduledJobRunGuid: string;
    scheduledJobGuid: string;
    scheduledDatetime?: Date;
    attemptCount: number;
    jobStateId: number;
    currentScriptExecutionAttemptGuid: string;
    jobStateTransitions: [];
    createdAt: Date;
    updatedAt: Date;
    scheduledJob?: ScheduledJob | null;
    jobState?: ScheduledJobState | null;
    currentScriptExecutionAttempt?: ScriptExecutionAttempt | null;
};

export type ScheduledJobState = {
    scheduledJobStateId: number;
    scheduledJobStateCode: string;
    scheduledJobStateTitle: string;
    scheduledJobStateDescription?: string;
    createdAt: Date;
    updatedAt: Date;
    scheduledJobRuns?: ScheduledJobRun[];
};

export type ScriptExecutionAttempt = {
    scriptExecutionAttemptGuid: string;
    flowScriptHistoryId: number;
    flowScriptTemplateHistoryId?: number;
    executionStateId: number;
    executionStateTransitions: [];
    createdAt: Date;
    updatedAt: Date;
    flowScriptHistory?: FlowScriptHistory | null;
    flowScriptTemplateHistory?: FlowScriptTemplateHistory | null;
    executionState?: ScriptExecutionStates | null;
    scheduledJobRuns?: ScheduledJobRun[];
};

export type ScriptExecutionStates = {
    executionStateId: number;
    executionStateCode: string;
    executionStateTitle: string;
    executionStateDescription?: string;
    createdAt: Date;
    updatedAt: Date;
    scriptExecutionAttempt?: ScriptExecutionAttempt | null;
};

export type TelehealthSessionState = {
    telehealthSessionStateId: number;
    telehealthSessionStateCode: string | SessionStateCodes;
    telehealthSessionStateTitle: string;
    telehealthSessionStateDescription?: string;
    activeIndicator: boolean;
    createTime: Date;
    updateTime: Date;
    auditVersion: number;
    telehealthSession?: TelehealthSession;
};

export type TelehealthSessionDetails = {
    videoProvider: 'opentok';
    opentokApiKey: string;
    sessionId: string;
    initiatorToken: string;
    responderToken: string;
    initiatorLastPingDttm?: string;
    responderLastPingDttm?: string;
    initiatorConnectedDttm?: string;
    responderConnectedDttm?: string;
    sessionEndDttm?: string;
    archiveId?: string;
    archivedUrl?: string;
    patientPhone?: string;
    patientEmail?: string;
};
export type TelehealthSession = {
    telehealthSessionId: number;
    telehealthSessionGuid: string;
    encounterId: number;
    encounterFormId?: number;
    telehealthSessionStateId: number;
    telehealthSessionStateTransitions?: any[];
    telehealthSessionStateDttm: Date;
    telehealthSessionDetail?: TelehealthSessionDetails;
    initiatingOrgUserId?: number;
    respondingOrgUserId?: number;
    respondingUserNote?: string;
    telehealthSessionDurationSeconds?: number;
    priorityCode?: string;
    telehealthSessionReason?: string;
    telehealthSessionCancellationReason?: string;
    createTime: Date;
    updateTime: Date;
    auditVersion: number;
    telehealthSessionState?: TelehealthSessionState;
    encounter?: Encounter;
};
export type OrgOpt = {
    optId: number;
    orgId: number;
    optNm: string;
    optVal: string;
    insDttm: Date;
    updDttm: Date;
    audVer: number;
};

export enum SessionStateCodes {
    SESSION_CREATED = 'SESSION_CREATED',
    CONNECTION_PENDING = 'CONNECTION_PENDING',
    SESSION_CANCELED_BY_INITIATOR = 'SESSION_CANCELED_BY_INITIATOR',
    SESSION_CANCELED_BY_RESPONDER = 'SESSION_CANCELED_BY_RESPONDER',
    CONNECTION_STARTED = 'CONNECTION_STARTED',
    CONNECTION_INTERRUPTED = 'CONNECTION_INTERRUPTED',
    CONNECTION_ENDED = 'CONNECTION_ENDED',
    SESSION_COMPLETED = 'SESSION_COMPLETED',
}

export type FacilityLongKeys = {
    facilityId: number;
    facilityName: string;
    facilityDescription: string;
    addressLine1: string;
    addressLine2: string;
    addressCityName: string;
    addressStateCode: string;
    addressZipCode: string;
    phoneNumberMain: string;
    activeIndicator: boolean;
    createTime: Date | string;
    updateTime: Date | string;
    auditVersion: number;
};
export type OrganizationLongKeys = {
    organizationId: number;
    organizationName: string;
    organizationNameInternal: string;
    createTime: Date | string;
    updateTime: Date | string;
    auditVersion: number;
};

export type ImportBatch = {
    importBatchGuid: string;
    importBatchTitle: string;
    importBatchTemplateGuid: string;
    facilityId?: number | string;
    orgInternalName: string;
    reviewBeforeProcess?: boolean;
    createdAt: Date | string;
    updatedAt: Date | string;
    auditVersion: number;
    importBatchTemplate?: ImportBatchTemplate;
};

export type ImportBatchTemplate = {
    importBatchTemplateGuid: string;
    importBatchTemplateTitle: string;
    importBatchTemplateDescription?: string;
    targetDataModel: any;
    flowScriptGuid: string;
    createdAt: Date | string;
    updatedAt: Date | string;
    auditVersion: number;
    reviewBeforeProcess: boolean;
    active: boolean;
};

export type ImportBatchState = {
    importBatchGuid: string;
    importBatchTitle: string;
    importBatchRecordStateCode: string;
    importBatchRecordStateTitle: string;
    recordCount: string | number;
};

export type ImportBatchRecord = {
    importBatchRecordGuid: string;
    importBatchGuid: string;
    recordIndex: number;
    recordSourceData: any;
    recordCurrentData?: any;
    recordStateId: number;
    recordStateHistory: any;
    createdAt: Date | string;
    updatedAt: Date | string;
    auditVersion: number;
    importBatchRecordExecution?: any;
};

export type ImportBatchRecordState = {
    importBatchRecordStateId: string | number;
    importBatchRecordStateCode: string;
    importBatchRecordStateTitle: string;
    importBatchRecordStateDescription?: string;
    createdAt: Date | string;
    updatedAt: Date | string;
    auditVersion: number;
    active?: boolean;
};

export type ImportBatchRecordViewState = {
    importBatch: ImportBatch;
    importBatchRecords: ImportBatchRecord[];
    importBatchTemplate: ImportBatchTemplate;
    importBatchRecordState: ImportBatchRecordState;
};

export type TargetDataModelItem = {
    key: string;
    label: string;
    type: string;
    showInList?: boolean;
};
