import React, { useState, useContext } from 'react';
import styles from './styles.module.scss';
import Input from 'src/components/Input';
import PaymentsStore, { PayerCategory } from 'src/stores/PaymentsStore';
import SideModal from 'src/components/SideModal';
import ModalHeader from 'src/components/ModalHeader';
import ModalFooter from 'src/components/ModalFooter';
import { ANALYTICS_NAMES } from 'src/utils/analytics';
import { ToastStoreObject, ToastType } from 'src/stores/ToastStore';

interface PayerCategoryModalProps {
    payerCategory?: PayerCategory;
    mode?: string;
    onClose?: Function;
    onSave?: Function;
}

function PayerCategoryModal(props: PayerCategoryModalProps) {
    const paymentsStore = useContext(PaymentsStore);
    const [isLoading, setIsLoading] = useState(false);
    const [title, setTitle] = useState(props.payerCategory ? props.payerCategory.payerCategoryTitle : '');
    const [description, setDescription] = useState(
        props.payerCategory ? props.payerCategory.payerCategoryDescription : '',
    );
    const [code, setCode] = useState(props.payerCategory ? props.payerCategory.payerCategoryCode : '');

    async function handleSave() {
        setIsLoading(true);

        if (props.mode === 'edit') {
            await paymentsStore.updatePayerCategory(props.payerCategory.payerCategoryId, {
                payerCategoryTitle: title,
                payerCategoryDescription: description,
            });
            ToastStoreObject.show(`${title} has been updated`, ToastType.Success);
        } else {
            await paymentsStore.createPayerCategory({
                payerCategoryTitle: title,
                payerCategoryDescription: description,
                payerCategoryCode: code,
            });
            ToastStoreObject.show(`New payer category "${title}" has been created`, ToastType.Success);
        }

        setIsLoading(false);

        if (props.onSave) {
            props.onSave();
        }
    }

    return (
        <SideModal isOpen={true} onClose={props.onClose}>
            <ModalHeader
                title={`${props.mode === 'edit' ? 'Edit' : 'Create'} Payer Category`}
                onClose={props.onClose}
            />
            <div className={styles.contentWrap}>
                <Input
                    label="Code"
                    value={code}
                    disabled={props.mode === 'edit'}
                    onChangeText={(v: string) => setCode(v)}
                    data-test-id={ANALYTICS_NAMES.PayerCategoryModal_Code}
                />
                <Input
                    label="Title"
                    value={title}
                    onChangeText={(v: string) => setTitle(v)}
                    data-test-id={ANALYTICS_NAMES.PayerCategoryModal_Title}
                />
                <Input
                    label="Description"
                    value={description}
                    onChangeText={(v: string) => setDescription(v)}
                    data-test-id={ANALYTICS_NAMES.PayerCategoryModal_Description}
                />
            </div>
            <ModalFooter
                primaryText={props.mode === 'edit' ? 'Edit' : 'Save'}
                primaryClick={handleSave}
                secondaryClick={() => props.onClose()}
                isLoading={isLoading}
            />
        </SideModal>
    );
}

export default PayerCategoryModal;
