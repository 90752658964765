import * as ROUTES from 'src/api/routes';
import request from 'src/api/utils';
import * as utils from 'src/utils';

export async function fetchTeams(params = {}) {
    return await request({ ...ROUTES.GET_TEAMS, params });
}

export async function updateTeam(teamId: number, data: object) {
    const cleanedData = utils.removeNull(data);
    return await request({ ...ROUTES.UPDATE_TEAM, pathParams: { teamId }, data: cleanedData });
}

export async function createTeam(data: object) {
    const cleanedData = utils.removeNull(data);
    return await request({ ...ROUTES.CREATE_TEAM, data: cleanedData });
}

export async function deactivateTeam(teamId: number) {
    return await request({ ...ROUTES.DEACTIVATE_TEAM, pathParams: { teamId } });
}

export async function reactivateTeam(teamId: number) {
    return await request({ ...ROUTES.REACTIVATE_TEAM, pathParams: { teamId } });
}
