import React, { forwardRef, useEffect, useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { isValidNpi } from 'common/lib/util/ProviderUtil';
import Input, { InputProps, Label } from '../Input';
import * as variables from 'src/styles/variables';
import * as validation from 'src/utils/validation';
import Flex from '../Flex';
import OptionDropDown, { OptionTypes, OptionDropDownProps } from '../OptionDropDown';
import { ToastStoreObject } from 'src/stores/ToastStore';

export interface NemsisCreatablePropsProps {
    onNaClick?: Function;
    onChange?: Function;
    naSelected?: boolean;
    nemsisLabel?: string;
    required?: boolean;
    selectedValues?: any;
    validation?: 'NPI';
    placeholder?: string;
}

function NemsisCreatableProps(props: NemsisCreatablePropsProps) {
    const [input, setInput] = useState('');
    const [values, setValues] = useState([]);

    useEffect(() => {
        if (props.selectedValues) setValues(props.selectedValues.map((e: any) => ({ label: e, value: e })));
    }, [props.selectedValues]);

    function handleKeyDown(e: any) {
        if (!input) return;
        switch (e.key) {
            case 'Enter':
            case 'Tab':
            case ',':
                handleValidation();
                e.preventDefault();
                break;
            default:
                break;
        }
    }

    function handleValidation() {
        if (input === '') {
            return;
        }
        if (props.validation === 'NPI') {
            if (!isValidNpi(input)) {
                ToastStoreObject.show('Invalid entry');
            }
        }
        setValues([...values, { label: input, value: input }]);
        props.onChange([...values.map((e) => e.value), input]);
        setInput('');
    }

    const badStyles = {
        multiValue: (styles: any, { data }: { data: any }) => {
            if (props.validation) {
                if (props.validation === 'NPI') {
                    if (!isValidNpi(data.value)) {
                        return {
                            ...styles,
                            backgroundColor: variables.lightRed,
                        };
                    }
                }
            }
            return { ...styles };
        },
    };
    function handleNaClick() {
        props.onNaClick && props.onNaClick();
    }
    return (
        <>
            {props.nemsisLabel ? <Label text={props.nemsisLabel} required={props.required} /> : null}
            <Flex direction="row" justify="between" align="center" className={styles.parentWrap}>
                <CreatableSelect
                    className={styles.inputWrap}
                    isDisabled={props.naSelected}
                    inputValue={input}
                    onBlur={handleValidation}
                    isClearable={true}
                    isMulti={true}
                    menuIsOpen={false}
                    onChange={(t: any) => {
                        if (t) {
                            setValues(t);
                            props.onChange(t.map((e: any) => e.value));
                        } else {
                            setValues([]);
                            props.onChange([]);
                        }
                    }}
                    onInputChange={(t: string) => {
                        setInput(t);
                    }}
                    onKeyDown={handleKeyDown}
                    placeholder={props.placeholder}
                    value={values}
                    styles={badStyles}
                />
                {props.onNaClick && (
                    <div
                        onClick={handleNaClick}
                        className={classNames(styles.naWrap, { [styles.naActive]: props.naSelected })}
                    >
                        N/A
                    </div>
                )}
            </Flex>
        </>
    );
}

export default NemsisCreatableProps;
