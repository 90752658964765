import React, { useContext, useEffect, useState } from 'react';
import Flex from 'src/components/Flex';
import PageContainer from 'src/components/PageContainer';
import PageContent from 'src/components/PageContent';
import NavBar from 'src/containers/NavBar';
import styles from '../styles.module.scss';
import PaymentsStore, { Payer, PaymentRequest, PaymentType } from 'src/stores/PaymentsStore';
import Button from 'src/components/Button';
import { useHistory } from 'react-router-dom';
import { ROUTES } from 'src/utils/constants';
import LoadingIcon from 'src/components/LoadingIcon';
import { ToastStoreObject } from 'src/stores/ToastStore';
import { parseError } from 'src/utils';

// Steps
import FacilitySelect from 'src/components/PaymentRequestImportSteps/FacilitySelect';
import Import from 'src/components/PaymentRequestImportSteps/Import';
import ReviewPayers from 'src/components/PaymentRequestImportSteps/ReviewPayers';
import ReviewPaymentRequests from 'src/components/PaymentRequestImportSteps/ReviewPaymentRequests';
import { Facility } from 'src/stores/FacilityStore';
import { ModalStoreObject, ModalTypes } from 'src/stores/ModalStore';
import classNames from 'classnames';

const IMPORT_STEPS = ['Facility', 'Encounters', 'Insurance', 'Review'];

function PaymentRequestImport() {
    const paymentsStore = useContext(PaymentsStore);
    const history = useHistory();
    const [activeStepIndex, setActiveStepIndex] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [facilityId, setFacilityId] = useState<Facility['facId']>(null);
    const [paymentTypeId, setPaymentTypeId] = useState<PaymentType['paymentTypeId']>(null);
    const [records, setRecords] = useState([]);
    const [encounterNumbers, setEncounterNumbers] = useState<string[]>([]);
    const [hasSubmittedRecordsOrEncounters, setHasSubmittedRecordsOrEncounters] = useState(false);
    const [procedureDate, setProcedureDate] = useState('');
    const [payers, setPayers] = useState<Partial<Payer>[]>([]);
    const [paymentRequests, setPaymentRequests] = useState<Partial<PaymentRequest>[]>([]);

    useEffect(() => {
        if (records && records.length && !hasSubmittedRecordsOrEncounters) {
            setHasSubmittedRecordsOrEncounters(true);
            submitRecordsOrEncounters();
        }
    }, [records]);

    async function submitRecordsOrEncounters() {
        setIsLoading(true);

        if (hasSubmittedRecordsOrEncounters) return;

        try {
            if (records && records.length) {
                await paymentsStore.submitImportEncounters({
                    facilityId,
                    paymentTypeId,
                    importEncounters: records,
                });
            } else if (encounterNumbers && encounterNumbers.length) {
                await paymentsStore.submitImportEncounterNumbers({
                    facilityId,
                    paymentTypeId,
                    encounterNumbers,
                    procedureDate,
                });
            } else {
                throw new Error('No valid records or encounter numbers found');
            }
        } catch (e: any) {
            ToastStoreObject.show(parseError(e));
        } finally {
            increaseStep();
            setIsLoading(false);
        }
    }

    async function handleStep() {
        setIsLoading(true);

        switch (activeStepIndex) {
            case 0:
                setIsLoading(false);

                if (paymentTypeId && facilityId) {
                    increaseStep();
                } else {
                    if (!paymentTypeId) {
                        ToastStoreObject.show('Please select a payment type');
                    }

                    if (!facilityId) {
                        ToastStoreObject.show('Please select a facility');
                    }
                }
                break;
            case 1:
                if (!records.length && !encounterNumbers.length) {
                    ToastStoreObject.show('Please import a CSV file or enter encounter numbers and a procedure date.');
                    setIsLoading(false);
                    return;
                }

                await submitRecordsOrEncounters();
                break;
            case 2:
                if (paymentsStore.uncategorizedPayerNames.length !== payers.length) {
                    ToastStoreObject.show('Please categorize all payers.');
                    setIsLoading(false);
                    return;
                }

                try {
                    const defaultData = {
                        facilityId,
                        newPayers: payers,
                        paymentTypeId,
                        procedureDate,
                    };
                    await paymentsStore.submitProvidersReview(
                        records && records.length
                            ? Object.assign(defaultData, { importEncounters: records })
                            : Object.assign(defaultData, { encounterNumbers }),
                    );
                } catch (e: any) {
                    ToastStoreObject.show(parseError(e));
                } finally {
                    increaseStep();
                    setIsLoading(false);
                }
                break;
            case 3:
                if (!paymentRequests.length) {
                    ToastStoreObject.show('Please select payment requests to import.');
                    setIsLoading(false);
                    return;
                }

                let batch;
                try {
                    batch = await paymentsStore.submitPaymentRequests({
                        facilityId,
                        newPayers: payers,
                        newPaymentRequests: paymentRequests,
                        paymentTypeId,
                    });
                } catch (e: any) {
                    ToastStoreObject.show(parseError(e));
                } finally {
                    // Pass through the batch to tell it to pop up toast w/ link
                    history.push(ROUTES.getString(ROUTES.PaymentRequests), {
                        importBatch: batch,
                    });
                    setIsLoading(false);
                }
                break;
        }
    }

    function increaseStep() {
        setActiveStepIndex(activeStepIndex === IMPORT_STEPS.length - 1 ? activeStepIndex : activeStepIndex + 1);
    }

    function handleCancel() {
        ModalStoreObject.showModal(ModalTypes.ConfirmationModal, {
            title: 'Are you sure you want to cancel this import? All progress will be lost.',
            onConfirm: () => {
                history.push(ROUTES.getString(ROUTES.PaymentRequests));
                ModalStoreObject.hideModal();
            },
            onCancel: () => ModalStoreObject.hideModal(),
            confirmButtonText: 'Confirm',
        });
    }

    function getStepContent() {
        if (isLoading) {
            // TODO: A better loader with more information about each step and what it's doing
            return (
                <Flex align="center" justify="center">
                    <LoadingIcon />
                </Flex>
            );
        }

        switch (activeStepIndex) {
            case 0:
                return (
                    <FacilitySelect
                        onChange={(data: {
                            selectedFacility: Facility['facId'];
                            selectedType: PaymentType['paymentTypeId'];
                        }) => {
                            setFacilityId(data.selectedFacility);
                            setPaymentTypeId(data.selectedType);
                        }}
                    />
                );
            case 1:
                return (
                    <Import
                        onChange={(records: any[]) => {
                            if (records) {
                                setRecords(records);
                            }
                        }}
                        onClose={() => history.push(ROUTES.getString(ROUTES.PaymentRequests))}
                    />
                );
            case 2:
                if (!paymentsStore.uncategorizedPayerNames.length) {
                    handleStep();
                }

                return <ReviewPayers onChange={(newPayers: Partial<Payer>[]) => setPayers(newPayers)} />;
            case 3:
                return (
                    <ReviewPaymentRequests
                        onChange={(newPaymentRequests: Partial<PaymentRequest>[]) =>
                            setPaymentRequests(newPaymentRequests)
                        }
                    />
                );
        }
    }

    return (
        <PageContainer>
            <NavBar />
            <PageContent>
                <Flex
                    value={1}
                    className={classNames(styles.pageTitle, {
                        [styles.pageTitleWide]: activeStepIndex === 3,
                    })}
                >
                    Import Payment Requests
                </Flex>
                <Flex
                    direction="column"
                    className={classNames(styles.importContainer, {
                        [styles.importContainerWide]: activeStepIndex === 3,
                    })}
                >
                    <Flex value={1}>{getStepContent()}</Flex>
                    <Flex direction="row" className={styles.stepControl}>
                        <Button text="Cancel" type="secondary" onClick={() => handleCancel()} />
                        <Flex value={1} />
                        <Button
                            text={activeStepIndex === IMPORT_STEPS.length - 1 ? 'Submit' : 'Next'}
                            type="primary"
                            onClick={() => handleStep()}
                        />
                    </Flex>
                </Flex>
                <span className={styles.optInText}>
                    Message and data rates may apply. Frequency varies. Text HELP for assistance or STOP to opt-out.
                    <br />
                    <a href="https://graphiumhealth.com/sms/" target="_blank">
                        Terms
                    </a>{' '}
                    |{' '}
                    <a href="https://graphiumhealth.com/privacy/" target="_blank">
                        Privacy Policy
                    </a>
                </span>
            </PageContent>
        </PageContainer>
    );
}

export default PaymentRequestImport;
