import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

export interface PercentageCellProps {
    value: string | number;
    showBgState?: boolean;
}

export function PercentageCell({ value, showBgState = false }: PercentageCellProps) {
    const displayValue =
        typeof value === 'string' ? `${Math.round(parseFloat(value) * 100)}%` : `${Math.round(value * 100)}%`;
    const colorStyle = showBgState ? (displayValue === '100%' || displayValue === '-' ? styles.green : styles.red) : '';
    return (
        <div className={classNames(styles.cellContainer, colorStyle)}>
            <div className={styles.cellData}>
                <span className={styles.cellValue}>{displayValue}</span>
            </div>
        </div>
    );
}
