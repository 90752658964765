import flagsmith from 'flagsmith';
import { createContext } from 'react';
import { action, observable, makeObservable } from 'mobx';
import { UserStoreObject } from './UserStore';

class FeatureFlagStore {
    @observable loaded: boolean = false;
    @observable flags: any = {};
    @observable enabledFeatures: string[] = [];

    constructor() {
        makeObservable(this);
    }

    loadFlags() {
        this.loaded = false;
        flagsmith.init({
            api: 'https://api.flagsmith.com/api/v1/',
            environmentID: import.meta.env.VITE_FLAGSMITH_ENV_ID.toString(),
            identity: UserStoreObject.usrNm,
            traits: {
                email: UserStoreObject.orgEmailAddr,
                orgNameInternal: UserStoreObject.selectedOrg.orgNmIntrnl,
            },
            onChange: (oldFlags, params) => {
                this.loaded = true;
            },
        });
    }

    async listFlags() {
        await flagsmith.getFlags();
        // @ts-ignore
        this.flags = flagsmith.flags;

        const enabledFlags = Object.fromEntries(
            Object.entries(this.flags).filter(([key, value]: any) => value.enabled && value.value === null),
        );
        this.enabledFeatures = Object.keys(enabledFlags);
    }

    isEnabled(flagName: string) {
        return flagsmith.hasFeature(flagName);
    }

    getValue(flagName: string) {
        return flagsmith.getValue(flagName);
    }
}

// Import this to any other store that needs to use a value from it
export const FeatureFlagStoreObject = new FeatureFlagStore();

export default createContext(FeatureFlagStoreObject);
