import React, { useRef, useState, useEffect } from 'react';
import styles from './styles.module.scss';

export interface CallTimerProps {
    callStartedDate: Date | null;
    userWaitingDate: Date | null;
}

function formatCallDuration(time: number) {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
}

const CallTimer = ({ callStartedDate, userWaitingDate }: CallTimerProps) => {
    const timerInterval = useRef<NodeJS.Timer>(null);
    const [callTime, setCallTime] = useState(0);

    useEffect(() => {
        if (callStartedDate || userWaitingDate) {
            timerInterval.current = setInterval(() => {
                const now = new Date();
                let time = 0;
                if (callStartedDate) {
                    time = Math.floor((now.valueOf() - callStartedDate.valueOf()) / 1000);
                } else if (userWaitingDate) {
                    time = Math.floor((now.valueOf() - userWaitingDate.valueOf()) / 1000);
                }
                // This can happen if the server time and client time are off and it produces a negative number
                if (time < 0) {
                    time = 0;
                }
                setCallTime(time);
            }, 1000);
        }

        return () => {
            clearInterval(timerInterval.current);
        };
    }, [callStartedDate, userWaitingDate]);

    if ((!callStartedDate && !userWaitingDate) || callTime < 0) {
        return null;
    }
    return (
        <div className={styles.timeBox}>
            <span>{callStartedDate ? 'Call Time: ' : 'Waiting Time: '}</span>
            {formatCallDuration(callTime)}
        </div>
    );
};

export default CallTimer;
