import React from 'react';
import styles from './styles.module.scss';
import { CellInfo } from 'react-table';
import DateComponent from 'src/components/DateComponent';
import { FormDeployment } from 'common/lib/entity/framework/FormDeployment';
import Flex from 'src/components/Flex';
import Button from 'src/components/Button';
import Icon from 'src/components/Icon';
import { ICONS, ROUTES } from 'src/utils/constants';
import * as variables from 'src/styles/variables';
import Card from 'src/components/Card';
import Table from 'src/components/Table';
import { ModalStoreObject, ModalTypes } from 'src/stores/ModalStore';
import { useHistory, useParams } from 'react-router-dom';
import { useFormCatalog } from 'src/queries/useFormCatalog';

function FacilityMappings() {
    const history = useHistory();
    const { formCatalogId } = useParams<{ formCatalogId: string }>();
    const { data: formCatalog, isFetching } = useFormCatalog(formCatalogId);
    const { formFacilityMappings } = formCatalog || {};

    const columns = [
        {
            Header: 'Organization',
            accessor: 'orgName',
        },
        {
            Header: 'Facility',
            accessor: 'facilityName',
        },
        {
            Header: 'Form Name',
            accessor: 'formDefinitionName',
        },
        {
            Header: 'Last Deployed',
            accessor: 'formDeployments',
            maxWidth: 200,
            Cell: (props: CellInfo) => {
                const deployments = props.value || [];
                const filteredDeployments = deployments.filter((deployment: FormDeployment) => deployment.deployedAt);
                const sortedDeployments = filteredDeployments.sort((a: FormDeployment, b: FormDeployment) => {
                    return new Date(b.deployedAt).getTime() - new Date(a.deployedAt).getTime();
                });

                if (!sortedDeployments.length) {
                    return <span>N/A</span>;
                }

                return (
                    <span>
                        <DateComponent date={sortedDeployments[0].deployedAt} />
                    </span>
                );
            },
        },
        {
            Header: 'Actions',
            maxWidth: 150,
            style: { paddingVertical: 0, paddingHorizontal: 20 },
            Cell: (props: CellInfo) => (
                <Flex gap={8}>
                    <Button
                        type="small"
                        text="Deploy"
                        onClick={() => {
                            history.push(
                                ROUTES.FormDevelopmentDeploymentDetail.replace(':formCatalogId', formCatalogId).replace(
                                    ':formFacilityMappingId',
                                    props.original.formFacilityMappingId,
                                ),
                            );
                        }}
                    />
                    <Button
                        onClick={() => {
                            ModalStoreObject.showModal(ModalTypes.FormFacilityMappingModal, {
                                formCatalog,
                                formFacilityMapping: props.original,
                                onClose: () => ModalStoreObject.hideModal(),
                                onSave: () => {
                                    ModalStoreObject.hideModal();
                                },
                            });
                        }}
                        type="secondarySmall"
                        leftIcon={<Icon name={ICONS.Pencil2} color={variables.red} />}
                    />
                </Flex>
            ),
        },
    ];

    return (
        <div className={styles.container}>
            <Flex direction="row" justify="end">
                <Button
                    leftIcon={<Icon className={styles.plusIcon} name={ICONS.PlusButton} />}
                    type="primary"
                    onClick={() => {
                        ModalStoreObject.showModal(ModalTypes.FormFacilityMappingModal, {
                            formCatalog,
                            onClose: () => ModalStoreObject.hideModal(),
                            onSave: () => {
                                ModalStoreObject.hideModal();
                            },
                        });
                    }}
                    text="New Facility Mapping"
                />
            </Flex>
            <Card className={styles.cardWrap}>
                <Table
                    isLoading={isFetching && formFacilityMappings?.length === 0}
                    columns={columns}
                    data={formFacilityMappings || []}
                    showPagination={false}
                />
            </Card>
        </div>
    );
}

export default FacilityMappings;
