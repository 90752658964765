import React, { useEffect, useState } from 'react';
import styles from '../styles.module.scss';
import { FormAsset } from 'common/lib/entity/framework/FormAsset';
import { useFormAsset } from 'src/queries/useFormAsset';
import LoadingIcon from 'src/components/LoadingIcon';

function FormAssetImage(props: { selectedAsset: FormAsset; inlineImage?: string }) {
    const { selectedAsset, inlineImage } = props;
    const [formAssetContent, setFormAssetContent] = useState('');
    const { data: formAsset, isFetching, refetch: getFormAsset } = useFormAsset(selectedAsset?.formAssetId);

    useEffect(() => {
        getFormAsset();
    }, [selectedAsset]);

    useEffect(() => {
        setFormAssetContent(formAsset.formAssetContent);
    }, [formAsset.formAssetContent]);

    return (
        <div className={styles.imageContainer}>
            {isFetching ? (
                <LoadingIcon />
            ) : (
                <div
                    className={styles.image}
                    style={{ backgroundImage: `url(${inlineImage || formAssetContent})` }}
                ></div>
            )}
        </div>
    );
}

export default FormAssetImage;
