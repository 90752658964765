import { RefListTypes, LocalOptions } from 'src/stores/ReferenceListStore';
import { OrgUserStoreObject } from 'src/stores/OrgUserStore';
import { PatientStateStoreObject } from 'src/stores/PatientStateStore';
import { SessionStateCodes } from './types';
import { formatSelectOptions, getTelehealthStatusExplanation } from '.';
import { FacilityStoreObject } from 'src/stores/FacilityStore';
import { FilterOptionsItem } from 'src/components/FilteredSearch';
import { ImportBatchStoreObject } from 'src/stores/ImportBatchStore';
import { PaymentsStoreObject } from 'src/stores/PaymentsStore';
import { CustomerStatus } from 'common/lib/entity/index/Customer';

export const FILTER_METHODS = {
    Greater_Than: 'Is Greater Than',
    Less_Than: 'Is Less Than',
    Greater_Than_Time: 'Is Greater Than Time',
    Less_Than_Time: 'Is Less Than Time',
    Greater_Than_Number: 'Is Greater Than Number',
    Less_Than_Number: 'Is Less Than Number',
    Equals_Number: 'Is Equal to the Number',
    Between_Number: 'Is Between Numbers',
    Does_Not_Equals_Number: 'Does Not Equal the Number',
    Equals_Text: 'Is Equal to the Text',
    Equals_Date: 'Is Equal to the Date',
    Equals_Time: 'Is Equal to the Time',
    Equals_Date_Only: 'Is Equal to the Date',
    Does_Not_Equals_Text: 'Does Not Equal the Text',
    Does_Not_Equals_Date: 'Does Not Equal the Date',
    Does_Not_Equals_Date_Only: 'Does Not Equal the Date',
    Does_Not_Equals_Time: 'Does Not Equal the Time',
    Between_Date: 'Is Between',
    Between_Date_Only: 'Is Between Dates',
    Between_Time: 'Is Between Times',
    Contains: 'Contains',
    Contains_Number: 'Contains Number',
    Matches_Dropdown: 'Matches',
    One_Of_Dropdown: 'One Of',
};

const numberType = [
    FILTER_METHODS.Equals_Number,
    FILTER_METHODS.Does_Not_Equals_Number,
    FILTER_METHODS.Contains_Number,
];

const textType = [FILTER_METHODS.Equals_Text, FILTER_METHODS.Does_Not_Equals_Text, FILTER_METHODS.Contains];
const dateType = [
    FILTER_METHODS.Greater_Than,
    FILTER_METHODS.Less_Than,
    FILTER_METHODS.Between_Date,
    FILTER_METHODS.Equals_Date,
];
const dateOnlyType = [
    FILTER_METHODS.Greater_Than,
    FILTER_METHODS.Less_Than,
    FILTER_METHODS.Between_Date_Only,
    FILTER_METHODS.Equals_Date_Only,
    FILTER_METHODS.Does_Not_Equals_Date_Only,
];
const timeType = [
    FILTER_METHODS.Greater_Than,
    FILTER_METHODS.Less_Than,
    FILTER_METHODS.Between_Time,
    FILTER_METHODS.Equals_Time,
    FILTER_METHODS.Does_Not_Equals_Time,
];

export function getRequiredEncounterFilters(): FilterOptionsItem[] {
    return [
        {
            field: {
                name: 'facId',
                label: 'Facility',
            },
            availableFilterTypes: [FILTER_METHODS.Matches_Dropdown],
            options: FacilityStoreObject.facilitiesDropdownValues,
        },
    ];
}

export function getRequiredPaymentRequestFilters(): FilterOptionsItem[] {
    return [
        {
            field: {
                name: 'facilityId',
                label: 'Facility',
            },
            availableFilterTypes: [FILTER_METHODS.Matches_Dropdown],
            options: FacilityStoreObject.facilitiesDropdownValues,
        },
    ];
}

export function getRequiredFormFilters(): FilterOptionsItem[] {
    return [
        {
            field: {
                name: 'facId',
                label: 'Facility',
            },
            availableFilterTypes: [FILTER_METHODS.Matches_Dropdown],
            options: FacilityStoreObject.facilitiesDropdownValues,
        },
    ];
}

export function getRequiredPatientFilters(): FilterOptionsItem[] {
    return [
        {
            field: {
                name: 'facId',
                label: 'Facility',
            },
            availableFilterTypes: [FILTER_METHODS.Matches_Dropdown],
            options: FacilityStoreObject.facilitiesDropdownValues,
        },
    ];
}

export function getReferenceListFilters(): FilterOptionsItem[] {
    return [
        {
            field: {
                name: 'facId',
                label: 'Facility',
            },
            availableFilterTypes: [FILTER_METHODS.One_Of_Dropdown],
            options: FacilityStoreObject.facilitiesDropdownValues,
        },
    ];
}

export function getEncounterFilters(): FilterOptionsItem[] {
    return [
        {
            field: {
                name: 'enctrNo',
                label: 'Encounter Number',
            },
            availableFilterTypes: textType,
        },
        {
            field: {
                name: 'patMrn',
                label: 'Patient MRN',
            },
            availableFilterTypes: numberType,
        },
        {
            field: {
                name: 'patLastNm',
                label: 'Patient Last Name',
            },
            availableFilterTypes: textType,
        },
        {
            field: {
                name: 'patFrstNm',
                label: 'Patient First Name',
            },
            availableFilterTypes: textType,
        },
        {
            field: {
                name: 'patMidNm',
                label: 'Patient Middle Name',
            },
            availableFilterTypes: textType,
        },
        {
            field: {
                name: 'patSsn',
                label: 'Patient SSN',
            },
            availableFilterTypes: textType,
        },
        {
            field: {
                name: 'patZipCd',
                label: 'Patient Zip Code',
            },
            availableFilterTypes: textType,
        },
        {
            field: {
                name: 'patCity',
                label: 'Patient City',
            },
            availableFilterTypes: textType,
        },
        {
            field: {
                name: 'patStateCd',
                label: 'Patient State Code',
            },
            availableFilterTypes: textType,
        },
        {
            field: {
                name: 'patDob',
                label: 'Patient DOB',
            },
            availableFilterTypes: dateType,
        },
        {
            field: {
                name: 'admitDt',
                label: 'Admit Date',
            },
            availableFilterTypes: dateOnlyType,
        },
        {
            field: {
                name: 'admitTm',
                label: 'Admit Time',
            },
            availableFilterTypes: timeType,
        },
        {
            field: {
                name: 'dischDt',
                label: 'Discharge Date',
            },
            availableFilterTypes: dateOnlyType,
        },
        {
            field: {
                name: 'dischTm',
                label: 'Discharge Time',
            },
            availableFilterTypes: timeType,
        },
        {
            field: {
                name: 'patGenderCd',
                label: 'Patient Gender',
            },
            availableFilterTypes: [FILTER_METHODS.Matches_Dropdown],
            optionType: RefListTypes.Gender,
        },
        {
            field: {
                name: 'patRace',
                label: 'Patient Race',
            },
            availableFilterTypes: [FILTER_METHODS.Matches_Dropdown],
            optionType: RefListTypes.Race,
        },
        {
            field: {
                name: 'patEthnicGrp',
                label: 'Patient Ethnicity',
            },
            availableFilterTypes: [FILTER_METHODS.Matches_Dropdown],
            optionType: RefListTypes.Ethnicity,
        },
        {
            field: {
                name: 'enctrHospSvcCd',
                label: 'Hospital Service Code',
            },
            availableFilterTypes: textType,
        },
        {
            field: {
                name: 'enctrStatCd',
                label: 'Status Code',
            },
            availableFilterTypes: textType,
        },
    ];
}

export function getFormFilters(): FilterOptionsItem[] {
    return [
        {
            field: {
                name: 'formCmpltPct', // Between 0 and 1
                label: 'Completion Percentage',
            },
            availableFilterTypes: [FILTER_METHODS.Between_Number, FILTER_METHODS.Equals_Number],
        },
        {
            field: {
                name: 'customFormStatus',
                label: 'Form Completed',
            },
            availableFilterTypes: [FILTER_METHODS.Matches_Dropdown],
            optionType: LocalOptions.yesNo, // This is from OptionTypes. We need to move OptionTypes to a different file so that there is no import error when running dev
        },
        {
            field: {
                name: 'voidInd',
                label: 'Voided',
            },
            availableFilterTypes: [FILTER_METHODS.Matches_Dropdown],
            optionType: LocalOptions.yesNo, // This is from OptionTypes. We need to move OptionTypes to a different file so that there is no import error when running dev
        },
        {
            field: {
                name: 'formLastOpndDttm',
                label: 'Last Opened',
            },
            availableFilterTypes: [
                FILTER_METHODS.Greater_Than,
                FILTER_METHODS.Less_Than,
                FILTER_METHODS.Between_Date,
                FILTER_METHODS.Equals_Date,
            ],
        },
        {
            field: {
                name: 'insDttm',
                label: 'Created Date',
            },
            availableFilterTypes: [
                FILTER_METHODS.Greater_Than,
                FILTER_METHODS.Less_Than,
                FILTER_METHODS.Between_Date,
                FILTER_METHODS.Equals_Date,
            ],
        },
        {
            field: {
                name: 'updDttm',
                label: 'Updated Date',
            },
            availableFilterTypes: [
                FILTER_METHODS.Greater_Than,
                FILTER_METHODS.Less_Than,
                FILTER_METHODS.Between_Date,
                FILTER_METHODS.Equals_Date,
            ],
        },
    ];
}

export function getPatientFilters(): FilterOptionsItem[] {
    return [
        {
            field: {
                name: 'patientGenderCode',
                label: 'Gender',
            },
            availableFilterTypes: [FILTER_METHODS.Matches_Dropdown, FILTER_METHODS.One_Of_Dropdown],
            optionType: RefListTypes.Gender,
        },
        {
            field: {
                name: 'patientLastName',
                label: 'Patient Last Name',
            },
            availableFilterTypes: [
                FILTER_METHODS.Equals_Text,
                FILTER_METHODS.Contains,
                FILTER_METHODS.Does_Not_Equals_Text,
            ],
        },
        {
            field: {
                name: 'patientFirstName',
                label: 'Patient First Name',
            },
            availableFilterTypes: [
                FILTER_METHODS.Equals_Text,
                FILTER_METHODS.Contains,
                FILTER_METHODS.Does_Not_Equals_Text,
            ],
        },
        {
            field: {
                name: 'patientMiddleName',
                label: 'Patient Middle Name',
            },
            availableFilterTypes: [
                FILTER_METHODS.Equals_Text,
                FILTER_METHODS.Contains,
                FILTER_METHODS.Does_Not_Equals_Text,
            ],
        },
        {
            field: {
                name: 'patientSsn',
                label: 'Patient SSN',
            },
            availableFilterTypes: [
                FILTER_METHODS.Equals_Text,
                FILTER_METHODS.Contains,
                FILTER_METHODS.Does_Not_Equals_Text,
            ],
        },
        {
            field: {
                name: 'patientDob',
                label: 'Patient DOB',
            },
            availableFilterTypes: [
                FILTER_METHODS.Greater_Than,
                FILTER_METHODS.Less_Than,
                FILTER_METHODS.Between_Date,
                FILTER_METHODS.Equals_Date,
                FILTER_METHODS.Does_Not_Equals_Date,
            ],
        },
        {
            field: {
                name: 'patientRaceCode',
                label: 'Patient Race',
            },
            availableFilterTypes: [FILTER_METHODS.Matches_Dropdown],
            optionType: RefListTypes.Race,
        },
        {
            field: {
                name: 'patientEthnicityCode',
                label: 'Patient Ethnicity',
            },
            availableFilterTypes: [FILTER_METHODS.Matches_Dropdown],
            optionType: RefListTypes.Ethnicity,
        },
        {
            field: {
                name: 'primaryProviderName',
                label: 'Primary Provider Name',
            },
            availableFilterTypes: [
                FILTER_METHODS.Equals_Text,
                FILTER_METHODS.Contains,
                FILTER_METHODS.Does_Not_Equals_Text,
            ],
        },
        {
            field: {
                name: 'patientReferralSource',
                label: 'Referral Source',
            },
            availableFilterTypes: [
                FILTER_METHODS.Equals_Text,
                FILTER_METHODS.Contains,
                FILTER_METHODS.Does_Not_Equals_Text,
            ],
        },
        {
            field: {
                name: 'patientReferralReason',
                label: 'Referral Reason',
            },
            availableFilterTypes: [
                FILTER_METHODS.Equals_Text,
                FILTER_METHODS.Contains,
                FILTER_METHODS.Does_Not_Equals_Text,
            ],
        },
        {
            field: {
                name: 'patientStateId',
                label: 'Status',
            },
            availableFilterTypes: [FILTER_METHODS.Matches_Dropdown],
            options: PatientStateStoreObject.patientStatesDropdownValues,
        },
    ];
}

export function getTelehealthFilters(): FilterOptionsItem[] {
    return [
        {
            field: {
                name: 'telehealthSessionGuid',
                label: 'Telehealth Visit Number',
            },
            availableFilterTypes: [
                FILTER_METHODS.Equals_Number,
                FILTER_METHODS.Does_Not_Equals_Number,
                FILTER_METHODS.Contains_Number,
            ],
        },
        {
            field: {
                name: 'initiatingOrgUserId',
                label: 'Initiating User',
            },
            availableFilterTypes: [FILTER_METHODS.Matches_Dropdown],
            options: OrgUserStoreObject.orgUsersDropdownValues,
        },
        {
            field: {
                name: 'respondingOrgUserId',
                label: 'Provider',
            },
            availableFilterTypes: [FILTER_METHODS.Matches_Dropdown],
            options: OrgUserStoreObject.orgUsersDropdownValues,
        },
        {
            field: {
                name: 'createTime',
                label: 'Created Date',
            },
            availableFilterTypes: [
                FILTER_METHODS.Greater_Than,
                FILTER_METHODS.Less_Than,
                FILTER_METHODS.Between_Date,
                FILTER_METHODS.Equals_Date,
            ],
        },
        {
            field: {
                name: 'telehealthSessionStateCode',
                label: 'Visit Status',
            },
            availableFilterTypes: [FILTER_METHODS.Matches_Dropdown],
            options: [
                {
                    value: SessionStateCodes.SESSION_CREATED,
                    label: getTelehealthStatusExplanation(SessionStateCodes.SESSION_CREATED),
                },
                {
                    value: SessionStateCodes.CONNECTION_PENDING,
                    label: getTelehealthStatusExplanation(SessionStateCodes.CONNECTION_PENDING),
                },
                {
                    value: SessionStateCodes.CONNECTION_STARTED,
                    label: getTelehealthStatusExplanation(SessionStateCodes.CONNECTION_STARTED),
                },
                {
                    value: SessionStateCodes.CONNECTION_ENDED,
                    label: getTelehealthStatusExplanation(SessionStateCodes.CONNECTION_ENDED),
                },
                {
                    value: SessionStateCodes.SESSION_COMPLETED,
                    label: getTelehealthStatusExplanation(SessionStateCodes.SESSION_COMPLETED),
                },
                {
                    value: SessionStateCodes.SESSION_CANCELED_BY_INITIATOR,
                    label: getTelehealthStatusExplanation(SessionStateCodes.SESSION_CANCELED_BY_INITIATOR),
                },
                {
                    value: SessionStateCodes.SESSION_CANCELED_BY_RESPONDER,
                    label: getTelehealthStatusExplanation(SessionStateCodes.SESSION_CANCELED_BY_RESPONDER),
                },
                {
                    value: SessionStateCodes.CONNECTION_INTERRUPTED,
                    label: getTelehealthStatusExplanation(SessionStateCodes.CONNECTION_INTERRUPTED),
                },
            ],
        },
    ];
}

export function getImportBatchFilters(): FilterOptionsItem[] {
    return [
        {
            field: {
                name: 'facilityId',
                label: 'Facility',
            },
            availableFilterTypes: [FILTER_METHODS.Matches_Dropdown],
            options: FacilityStoreObject.facilitiesDropdownValues,
        },
        {
            field: {
                name: 'importBatchTemplate.importBatchTemplateGuid',
                label: 'Type',
            },
            availableFilterTypes: [FILTER_METHODS.Matches_Dropdown],
            options: ImportBatchStoreObject.batchTemplatesDropdownValues,
        },
    ];
}

export function getImportBatchRecordsFilters(): FilterOptionsItem[] {
    return [
        {
            field: {
                name: 'recordStateId',
                label: 'Record State',
            },
            availableFilterTypes: [FILTER_METHODS.Matches_Dropdown],
            options: ImportBatchStoreObject.recordStatesDropdownValues,
        },
    ];
}

export function getPaymentRequestsFilters(): FilterOptionsItem[] {
    return [
        {
            field: {
                name: 'paymentRequestStateId',
                label: 'Status',
            },
            availableFilterTypes: [FILTER_METHODS.Matches_Dropdown],
            options: PaymentsStoreObject.paymentRequestStatesDropDownValues,
        },
        {
            field: {
                name: 'payerName',
                label: 'Payer Name',
            },
            availableFilterTypes: textType,
        },
        {
            field: {
                name: 'payerCategoryIds',
                label: 'Payer Category',
            },
            availableFilterTypes: [FILTER_METHODS.One_Of_Dropdown],
            options: PaymentsStoreObject.payerCategoriesDropDownValuesWithId,
        },
        {
            field: {
                name: 'procedureDate',
                label: 'Procedure Date',
            },
            availableFilterTypes: dateType,
        },
        {
            field: {
                name: 'paymentRequestAmount',
                label: 'Amount',
            },
            availableFilterTypes: numberType,
        },
        {
            field: {
                name: 'enctrNo',
                label: 'Encounter Number',
            },
            availableFilterTypes: textType,
        },
    ];
}

export function getPayersFilters(): FilterOptionsItem[] {
    return [
        {
            field: {
                name: 'payerCategoryId',
                label: 'Category',
            },
            availableFilterTypes: [FILTER_METHODS.Matches_Dropdown],
            options: PaymentsStoreObject.payerCategoriesDropDownValuesWithId,
        },
    ];
}

export function getCustomerFilters(): FilterOptionsItem[] {
    return [
        {
            field: {
                name: 'orgs',
                label: 'Org Count',
            },
            availableFilterTypes: [
                FILTER_METHODS.Less_Than_Number,
                FILTER_METHODS.Greater_Than_Number,
                FILTER_METHODS.Between_Number,
            ],
        },
        {
            field: {
                name: 'subscriptionData.initialContractDate',
                label: 'Initial Contract Date',
            },
            isNestedJson: true,
            nestedJsonProperty: 'initialContractDate',
            availableFilterTypes: [
                FILTER_METHODS.Equals_Date,
                FILTER_METHODS.Greater_Than,
                FILTER_METHODS.Less_Than,
                FILTER_METHODS.Between_Date,
            ],
        },
        {
            field: {
                name: 'subscriptionData.customerStatus',
                label: 'Customer Status',
            },
            isNestedJson: true,
            nestedJsonProperty: 'customerStatus',
            availableFilterTypes: [FILTER_METHODS.One_Of_Dropdown, FILTER_METHODS.Matches_Dropdown],
            options: formatSelectOptions(
                Object.entries(CustomerStatus).map(([key, value]) => ({ key, value })),
                { valueKey: 'value', labelKey: 'key' },
            ),
        },
    ];
}
