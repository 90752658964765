import React from 'react';
import styles from './styles.module.scss';
import { useParams } from 'react-router-dom';
import { FormCatalog } from 'common/lib/entity/framework/FormCatalog';
import { useFormCatalog } from 'src/queries/useFormCatalog';
import Card from 'src/components/Card';
import Table from 'src/components/Table';
import { CellInfo } from 'react-table';
import DateComponent from 'src/components/DateComponent';

function Versions() {
    const { formCatalogId } = useParams<{ formCatalogId: FormCatalog['formCatalogId'] }>();
    const { data: formCatalog, isFetching } = useFormCatalog(formCatalogId);
    const { formCatalogVersions } = formCatalog || {};

    const columns = [
        {
            Header: 'Version',
            accessor: 'formVersionName',
        },
        {
            Header: 'Description',
            accessor: 'formVersionDescription',
        },
        {
            Header: 'Updated',
            accessor: 'updatedAt',
            maxWidth: 200,
            Cell: (props: CellInfo) => (
                <div className={styles.date}>
                    <DateComponent date={props.value} showDateTime={true} />
                </div>
            ),
        },
    ];

    return (
        <div className={styles.container}>
            <Card className={styles.cardWrap}>
                <Table
                    isLoading={isFetching && formCatalogVersions?.length === 0}
                    columns={columns}
                    data={formCatalogVersions || []}
                    showPagination={false}
                />
            </Card>
        </div>
    );
}

export default Versions;
