export interface FacilitySubscriptionData {
    orderDate: string;
    goLiveDate?: string;
    offboardDate?: string;
    facilityType: FacilityType;
    facilityStatus: FacilityStatus;
    offboardReason?: FacilityOffboardReason;
    otherOffboardReason?: string;
    offboardToOrgInternalName?: string;
    offboardToFacilityId?: number;
    primaryUse: FacilityPrimaryUse;
    primaryCaseCaptureMode: FacilityPrimaryCaseCaptureMode;
    isReportingMacra: boolean;
    macraFormDataEntry: boolean;
    macraFormDataEntryServicer: MacraDataEntryServicer;
}

export enum FacilityPrimaryUse {
    Macra = 'MACRA',
    AnesthesiaEmr = 'ANESTHESIA_EMR',
    ChargeCapture = 'CHARGE_CAPTURE',
    CHP = 'CHP',
    EMS = 'EMS',
}

export enum FacilityPrimaryCaseCaptureMode {
    PaperDataEntry = 'PAPER_DATA_ENTRY',
    DigitalPaper = 'DIGITAL_PAPER',
    DigitalExpress = 'DIGITAL_EXPRESS',
    EpicEWF = 'EPIC_EWF',
    Integration = 'INTEGRATION',
}

export enum FacilityType {
    Hospital = 'HOSPITAL',
    AscGeneral = 'ASC_GENERAL',
    PainClinic = 'PAIN_CLINIC',
    DentalOffice = 'DENTAL_OFFICE',
    AscGi = 'ASC_GI',
    AscEye = 'ASC_EYE',
}

export enum FacilityStatus {
    Onboarding = 'ONBOARDING',
    Active = 'ACTIVE',
    Offboarding = 'OFFBOARDING',
    Offboarded = 'OFFBOARDED',
}

export enum FacilityOffboardReason {
    CustomerCancelled = 'CUSTOMER_CANCELLED',
    FailureToPay = 'FAILURE_TO_PAY',
    CustomerLostContract = 'CUSTOMER_LOST_CONTRACT',
    TrialCancelled = 'TRIAL_CANCELLED',
    FacilityMigrated = 'FACILITY_MIGRATED',
    FacilityMerged = 'FACILITY_MERGED',
    FacilityCreationError = 'FACILITY_CREATION_ERROR',
    FailureToGoLive = 'FAILURE_TO_GO_LIVE',
    Other = 'OTHER',
}

export enum MacraDataEntryServicer {
    Graphium = 'GRAPHIUM',
    Self = 'SELF',
}
