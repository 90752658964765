import React, { useState, useEffect, useContext } from 'react';
import styles from './styles.module.scss';
import Flex from 'src/components/Flex/index';
import { observer } from 'mobx-react';
import Button from 'src/components/Button';
import PatientNeedStore, { PatientNeed } from 'src/stores/PatientNeedStore';
import Icon from 'src/components/Icon';
import Table from 'src/components/Table';
import { CellInfo } from 'react-table';
import { toJS } from 'mobx';
import NeedStateDropDown from 'src/components/NeedStateDropDown';
import FilterComponent from 'src/components/FilterComponent';
import ReferenceListStore, { RefListTypes } from 'src/stores/ReferenceListStore';
import { ICONS } from 'src/utils/constants';
import ActionMenu from 'src/components/ActionMenu';
import { ModalStoreObject, ModalTypes } from 'src/stores/ModalStore';
import ReferenceListItemDisplay from 'src/components/ReferenceListItemDisplay';
import { Patient } from 'src/stores/PatientStore';
import { ANALYTICS_NAMES } from 'src/utils/analytics';
import UserStore from 'src/stores/UserStore';
import { getPatientName } from 'src/utils';

interface NeedsProps {
    patientId: number;
    patient: Patient;
}
function Needs(props: NeedsProps) {
    const patientNeedStore = useContext(PatientNeedStore);
    const userStore = useContext(UserStore);
    const [patientNeeds, setPatientNeeds] = useState(patientNeedStore.needs);
    const referenceListStore = useContext(ReferenceListStore);
    const [isLoading, setIsLoading] = useState(false);
    const [filters, setFilters] = useState({ type: [], status: [1] });

    const columns = [
        {
            Header: 'NEED TYPE',
            accessor: 'patientNeedType',
            minWidth: 150,
            Cell: (cellProps: CellInfo) => (
                <ReferenceListItemDisplay type={RefListTypes.PatientNeedTypes} value={cellProps.value} />
            ),
        },
        {
            Header: 'DESCRIPTION',
            accessor: 'patientNeedDescription',
            minWidth: 175,
        },
        {
            Header: 'STATUS',
            accessor: 'needState',
            minWidth: 100,
            Cell: (cellProps: CellInfo) => <span>{cellProps.value.patientNeedStateTitle}</span>,
        },
        {
            Header: 'ACTIONS',
            accessor: 'patientNeedType',
            minWidth: 100,
            style: { paddingVertical: 0, paddingHorizontal: 20 },
            Cell: (cellProps: CellInfo) => {
                return (
                    <ActionMenu
                        entityType="need"
                        entity={cellProps.original}
                        onItemClick={(action: { id: string; label: string }) =>
                            handleActionClick(action, cellProps.original)
                        }
                    />
                );
            },
        },
    ];

    const handleActionClick = (action: { id: string; label: string }, need: PatientNeed) => {
        switch (action.id) {
            case 'edit':
                handleCreateNeed(need);
                break;
        }
    };

    async function loadNeeds() {
        try {
            setIsLoading(true);
            await patientNeedStore.getPatientNeeds(props.patientId, {
                filters,
            });
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        // We do this filtering client side because we do not make a new get request on update or create. If the result that is returned from the update request does not match the filters I have selected, we do not want to show it.
        if (filters.type.length > 0) {
            setPatientNeeds(patientNeedStore.needs.filter((n) => filters.type.includes(n.patientNeedType)));
        }
        if (filters.status.length > 0) {
            setPatientNeeds(patientNeedStore.needs.filter((n) => filters.status.includes(n.patientNeedStateId)));
        } else {
            setPatientNeeds(patientNeedStore.needs);
        }
    }, [patientNeedStore.needs]);

    useEffect(() => {
        loadNeeds();
    }, [filters]);

    const handleFilterChange = async function(type: string, filterVals: { label: string; value: string }[]) {
        let newFilters: any[] = [];
        if (
            filterVals &&
            filterVals.length !== 0 &&
            filterVals[0] &&
            filterVals[0].value !== undefined &&
            filterVals[0].value !== null
        ) {
            newFilters = filterVals.map((f) => f.value);
        }

        const updatedFilters = { ...filters };
        if (type === 'status') {
            updatedFilters.status = newFilters;
        } else if (type === 'type') {
            updatedFilters.type = newFilters;
        }
        setFilters(updatedFilters);
    };

    useEffect(() => {
        if ((referenceListStore.referenceListDropdownValues[RefListTypes.PatientNeedTypes] || []).length === 0) {
            referenceListStore.getData(RefListTypes.PatientNeedTypes);
        }
    }, []);

    function handleCreateNeed(need: PatientNeed) {
        ModalStoreObject.showModal(ModalTypes.PatientNeed, {
            need,
            title: `Create a Need for ${getPatientName(props.patient)}`,
            patientId: props.patientId,
            onClose: () => ModalStoreObject.hideModal(),
            onSave: () => ModalStoreObject.hideModal(),
        });
    }

    return (
        <div className={styles.container}>
            <Flex self="stretch" align="center" justify="start" className={styles.headerWrap}>
                <Flex value={1} className={styles.filterWrap}>
                    <NeedStateDropDown
                        defaultValue={filters.status[0]}
                        isMulti={true}
                        placeholder="Filter by Status"
                        onChange={(filters: any) => handleFilterChange('status', filters)}
                    />
                </Flex>
                <Flex value={1} className={styles.filterWrap}>
                    <FilterComponent
                        onUpdate={(filters: any) => handleFilterChange('type', filters)}
                        placeholder="Filter by Type"
                        options={referenceListStore.referenceListDropdownValues[RefListTypes.PatientNeedTypes]}
                    />
                </Flex>
                <Flex value={2} justify="end">
                    <Button
                        leftIcon={<Icon className={styles.plusIcon} name={ICONS.PlusButton} />}
                        type="primary"
                        onClick={() => {
                            handleCreateNeed(null);
                        }}
                        isLocked={!userStore.userPermissions.canEdit.patientNeeds}
                        text="Create Need"
                        data-test-id={ANALYTICS_NAMES.PatientDetails_Needs_Create}
                    />
                </Flex>
            </Flex>
            <div className={styles.tableWrap}>
                <div className={styles.tableTitle}>Patient Needs</div>
                <Table columns={columns} data={toJS(patientNeeds)} isLoading={isLoading} />
            </div>
        </div>
    );
}

export default observer(Needs);
