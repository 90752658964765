import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { ANALYTICS_NAMES } from 'src/utils/analytics';

export interface GenderProps extends React.HTMLAttributes<HTMLSpanElement> {
    value?: string;
}
const Gender = React.memo(({ value, className, ...rest }: GenderProps) => (
    <span
        {...rest}
        className={classNames(className, {
            [styles.blueText]: value === 'M',
            [styles.redText]: value === 'F',
        })}
        data-test-id={ANALYTICS_NAMES.Gender_Label}
    >
        {value === 'M' ? 'Male' : value === 'F' ? 'Female' : ''}
    </span>
));
Gender.displayName = 'Gender';

export default Gender;
