import React from 'react';
import { observer } from 'mobx-react';
import CenterModal from '../CenterModal';
import ModalHeader from '../ModalHeader';
import ModalFooter from '../ModalFooter';
import { ModalStoreObject } from 'src/stores/ModalStore';
import Flex from '../Flex';
import styles from './styles.module.scss';

interface NemsisErrorsModalProps {
    errors: string[];
}

function NemsisErrorsModal(props: NemsisErrorsModalProps) {
    return (
        <CenterModal isOpen={true}>
            <ModalHeader title="NEMSIS Validation Errors" />
            <Flex direction="column" className={styles.modalContent}>
                {props.errors.map((error, index) => (
                    <li key={index}>{error}</li>
                ))}
            </Flex>
            <ModalFooter primaryText="Close" primaryClick={() => ModalStoreObject.hideModal()} showSecondary={false} />
        </CenterModal>
    );
}

export default NemsisErrorsModal;
