export const lightBlue = '#96ddff';
export const blue = '#24a7e7';
export const darkBlue = '#182c49';
export const red = '#da1057';
export const lightRed = '#fbe7ee';
export const brightRed = '#ff1111';
export const white = '#fff';
export const yellow = '#ffbb42';
export const darkYellow = '#835402';
export const warningYellow = '#ffcc00';
export const green = '#5abc86';
export const darkGreen = '#1c593d';
export const black = '#323232';
export const deepBlack = '#000000';
export const pink = '#fbe7ee';
export const darkGrey = '#8b95a4';
export const grey = '#8b95a4';
export const offWhite = '#f9f9f9';
export const lightGrey = '#f1f0f0';
export const lightGrey2 = '#f1f2f2';
export const lightGrey3 = '#e8e8e8';
export const lightGrey4 = '#c8c8c8';
export const calendar1 = '#e67c73';
export const calendarInactive1 = '#dfb7b3';
export const calendar2 = '#f6bf26';
export const calendarInactive2 = '#f4dd9e';
export const calendar3 = '#33b679';
export const calendarInactive3 = '#9ddec0';
export const calendar4 = '#4285f4';
export const calendarInactive4 = '#8dafe6';
export const calendar5 = '#9e69af';
export const calendarInactive5 = '#cfb3d8';
export const calendar6 = '#a79b8e';
export const calendarInactive6 = '#e4d6c7';
export const calendar7 = '#34f2f5';
export const calendarInactive7 = '#bceff0';
export const calendar8 = '#b3318e';
export const calendarInactive8 = '#ac8ea4';
export const calendar9 = '#bfe82d';
export const calendarInactive9 = '#d4deb3';
export const padding1 = '50px';
export const padding2 = '30px';
export const padding3 = '20px';
export const padding4 = '14px';
export const padding5 = '10px';
export const padding6 = '5px';
export const padding7 = '3px';
export const fontFamily =
    "'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell','Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif";
