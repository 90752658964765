import React from 'react';
import * as utils from 'src/utils';

export interface AgeProps extends React.HTMLAttributes<HTMLSpanElement> {
    dob: string;
}
const Age = React.memo(({ dob, ...rest }: AgeProps) => {
    let age: number | string = '';
    if (dob) {
        const calcAge = utils.calculateAge(dob);
        if (!isNaN(calcAge) && calcAge >= 0) {
            age = calcAge;
        }
    }
    return <span {...rest}>{age}</span>;
});
Age.displayName = 'Age';

export default Age;
