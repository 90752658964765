import * as ROUTES from 'src/api/routes';
import request from 'src/api/utils';
import { Facility } from 'src/stores/FacilityStore';
import {
    Payer,
    PaymentType,
    PaymentRequest,
    PaymentGateway,
    PayerCategory,
    PaymentRequestContact,
    PaymentTransaction,
} from 'src/stores/PaymentsStore';
import { FilterQueryParams } from 'src/utils/types';

export async function getPaymentRequests(params: FilterQueryParams = {}) {
    const response = await request({ ...ROUTES.GET_PAYMENT_REQUESTS, params });
    return { result: response.data.result || [], count: response.data.count || 0 };
}

export async function getPaymentRequest(
    paymentRequestGuid: PaymentRequest['paymentRequestGuid'],
    options: {
        includePaymentType?: boolean;
        includeGateway?: boolean;
        includeContacts?: boolean;
        includeTransactions?: boolean;
    },
) {
    const paymentRequest = await request({
        ...ROUTES.GET_PAYMENT_REQUEST,
        pathParams: { paymentRequestGuid },
        params: { ...options },
    });
    return paymentRequest.data || {};
}

export async function createPaymentIntent(
    paymentRequestContactGuid: PaymentRequestContact['paymentRequestContactGuid'],
) {
    const paymentIntent = await request({
        ...ROUTES.CREATE_PAYMENT_INTENT,
        pathParams: { paymentRequestContactGuid },
    });
    return paymentIntent.data || {};
}

export async function createPaymentTransaction(
    paymentRequestGuid: PaymentRequest['paymentRequestGuid'],
    data: {
        paymentIntent: string;
        paymentRequestContactGuid: PaymentRequestContact['paymentRequestContactGuid'];
        paymentGatewayId: PaymentGateway['paymentGatewayId'];
        paymentGatewayRequestData: PaymentTransaction['paymentGatewayRequestData'];
    },
) {
    const { paymentIntent, paymentRequestContactGuid, paymentGatewayId, paymentGatewayRequestData } = data;
    return await request({
        ...ROUTES.CREATE_PAYMENT_TRANSACTION,
        pathParams: { paymentRequestGuid },
        data: {
            paymentIntent,
            newPaymentTransaction: {
                paymentRequestContactGuid,
                paymentGatewayId,
                paymentGatewayRequestData,
            },
        },
    });
}

export async function getPaymentRequestStates(includeInactive?: boolean) {
    const paymentRequestStates = await request({ ...ROUTES.GET_PAYMENT_REQUEST_STATES, params: { includeInactive } });

    return paymentRequestStates.data || [];
}

export async function getPaymentRequestContactStates(includeInactive?: boolean) {
    const paymentRequestContactStates = await request({
        ...ROUTES.GET_PAYMENT_REQUEST_CONTACT_STATES,
        params: { includeInactive },
    });

    return paymentRequestContactStates.data || [];
}

export async function getPaymentTransactionStates(includeInactive?: boolean) {
    const paymentTransactionStates = await request({
        ...ROUTES.GET_PAYMENT_TRANSACTION_STATES,
        params: { includeInactive },
    });

    return paymentTransactionStates.data || [];
}

export async function getPaymentTypes(includeInactive: boolean, facilityId: number) {
    const paymentTypes = await request({ ...ROUTES.GET_ORG_PAYMENT_TYPES, params: { includeInactive, facilityId } });
    return paymentTypes.data || [];
}

export async function createPaymentType(data: Partial<PaymentType>) {
    return await request({ ...ROUTES.CREATE_PAYMENT_TYPE, data: { newPaymentType: data } });
}

export async function updatePaymentType(paymentTypeId: PaymentType['paymentTypeId'], data: Partial<PaymentType>) {
    return await request({ ...ROUTES.UPDATE_PAYMENT_TYPE, pathParams: { paymentTypeId }, data: { paymentType: data } });
}

export async function deactivatePaymentType(paymentTypeId: PaymentType['paymentTypeId']) {
    return await request({ ...ROUTES.DEACTIVATE_PAYMENT_TYPE, pathParams: { paymentTypeId } });
}

export async function reactivatePaymentType(paymentTypeId: PaymentType['paymentTypeId']) {
    return await request({ ...ROUTES.REACTIVATE_PAYMENT_TYPE, pathParams: { paymentTypeId } });
}

export async function updatePaymentTypeEmailText(paymentTypeId: PaymentType['paymentTypeId'], updatedText: string) {
    return await request({
        ...ROUTES.UPDATE_PAYMENT_TYPE_EMAIL_TEXT,
        pathParams: { paymentTypeId },
        data: { emailText: updatedText },
    });
}

export async function updatePaymentTypeSmsText(paymentTypeId: PaymentType['paymentTypeId'], updatedText: string) {
    return await request({
        ...ROUTES.UPDATE_PAYMENT_TYPE_SMS_TEXT,
        pathParams: { paymentTypeId },
        data: { smsText: updatedText },
    });
}

export async function updatePaymentTypeLandingPageText(
    paymentTypeId: PaymentType['paymentTypeId'],
    updatedText: string,
) {
    return await request({
        ...ROUTES.UPDATE_PAYMENT_TYPE_LANDING_PAGE_TEXT,
        pathParams: { paymentTypeId },
        data: { landingPageText: updatedText },
    });
}

export async function getPaymentGateways(includeInactive: boolean) {
    const paymentGateways = await request({ ...ROUTES.GET_ORG_PAYMENT_GATEWAYS, params: { includeInactive } });
    return paymentGateways.data || [];
}

export async function createPaymentGateway(data: Partial<PaymentGateway>) {
    return await request({ ...ROUTES.CREATE_PAYMENT_GATEWAY, data: { newGateway: data } });
}

export async function updatePaymentGateway(
    paymentGatewayId: PaymentGateway['paymentGatewayId'],
    data: Partial<PaymentGateway>,
) {
    return await request({
        ...ROUTES.UPDATE_PAYMENT_GATEWAY,
        data: { gateway: data },
        pathParams: { paymentGatewayId },
    });
}

export async function deactivatePaymentGateway(paymentGatewayId: PaymentGateway['paymentGatewayId']) {
    return await request({ ...ROUTES.DEACTIVATE_PAYMENT_GATEWAY, pathParams: { paymentGatewayId } });
}

export async function reactivatePaymentGateway(paymentGatewayId: PaymentGateway['paymentGatewayId']) {
    return await request({ ...ROUTES.REACTIVATE_PAYMENT_GATEWAY, pathParams: { paymentGatewayId } });
}

export async function getPaymentGatewayTypes() {
    const paymentGatewayTypes = await request({ ...ROUTES.GET_PAYMENT_GATEWAY_TYPES });
    return paymentGatewayTypes.data || [];
}

export async function getPayers(params: FilterQueryParams = {}) {
    const response = await request({ ...ROUTES.GET_PAYERS, params });
    return { result: response.data.result || [], count: response.data.count || 0 };
}

export async function getPayerCategories(includeInactive: boolean) {
    const categories = await request({ ...ROUTES.GET_PAYER_CATEGORIES, params: { includeInactive } });
    return categories.data || [];
}

export async function createPayerCategory(data: {
    payerCategoryTitle: PayerCategory['payerCategoryTitle'];
    payerCategoryDescription: PayerCategory['payerCategoryDescription'];
    payerCategoryCode: PayerCategory['payerCategoryCode'];
}) {
    return await request({ ...ROUTES.CREATE_PAYER_CATEGORY, data: { newPayerCategory: data } });
}

export async function updatePayerCategory(
    payerCategoryId: PayerCategory['payerCategoryId'],
    data: {
        payerCategoryTitle: PayerCategory['payerCategoryTitle'];
        payerCategoryDescription: PayerCategory['payerCategoryDescription'];
    },
) {
    return await request({
        ...ROUTES.UPDATE_PAYER_CATEGORY,
        pathParams: { payerCategoryId },
        data: {
            ...data,
        },
    });
}

export async function deactivatePayerCategory(payerCategoryId: PayerCategory['payerCategoryId']) {
    return await request({ ...ROUTES.DEACTIVATE_PAYER_CATEGORY, pathParams: { payerCategoryId } });
}

export async function reactivatePayerCategory(payerCategoryId: PayerCategory['payerCategoryId']) {
    return await request({ ...ROUTES.REACTIVATE_PAYER_CATEGORY, pathParams: { payerCategoryId } });
}

export async function submitImportEncounters(
    facilityId: Facility['facId'],
    paymentTypeId: PaymentType['paymentTypeId'],
    importEncounters: any[],
) {
    const payerCategorizationData = await request({
        ...ROUTES.SUBMIT_PAYMENT_IMPORT_ENCOUNTER_DATA,
        data: {
            facilityId,
            paymentTypeId,
            importEncounters,
        },
    });
    return payerCategorizationData.data || {};
}

export async function submitImportEncounterNumbers(
    facilityId: Facility['facId'],
    paymentTypeId: PaymentType['paymentTypeId'],
    encounterNumbers: string[],
    procedureDate: Date | string,
) {
    const payerCategorizationData = await request({
        ...ROUTES.SUBMIT_PAYMENT_IMPORT_ENCOUNTER_DATA,
        data: {
            facilityId,
            paymentTypeId,
            encounterNumbers,
            procedureDate,
        },
    });
    return payerCategorizationData.data || {};
}

export async function submitPayersReview(data: {
    facilityId: Facility['facId'];
    importEncounters?: any[];
    encounterNumbers?: string[];
    procedureDate?: Date | string;
    newPayers: Partial<Payer>[];
    paymentTypeId: PaymentType['paymentTypeId'];
}) {
    const encountersData = await request({
        ...ROUTES.SUBMIT_UPDATED_PAYER_CATEGORIES,
        data,
    });
    return encountersData.data || {};
}

export async function submitPaymentRequests(data: {
    facilityId: Facility['facId'];
    newPayers: Partial<Payer>[];
    newPaymentRequests: Partial<PaymentRequest>[];
    paymentTypeId: PaymentType['paymentTypeId'];
}) {
    const requestsData = await request({
        ...ROUTES.SUBMIT_PAYMENT_REQUESTS,
        data,
    });
    return requestsData.data || [];
}

export async function resendContacts(paymentRequestContactGuids: PaymentRequestContact['paymentRequestContactGuid'][]) {
    return await request({
        ...ROUTES.RESEND_PAYMENT_CONTACTS,
        data: { paymentRequestContactGuids },
    });
}

export async function collectPayment(
    paymentRequestGuid: PaymentRequest['paymentRequestGuid'],
    options: {
        paymentAmountCollected: PaymentRequest['paymentRequestAmount'];
        markAsCompleted?: boolean;
        paymentDate?: string | Date;
        paymentNote?: string;
    },
) {
    return await request({
        ...ROUTES.COLLECT_PAYMENT,
        pathParams: { paymentRequestGuid },
        data: { ...options },
    });
}

export async function cancelPaymentRequest(
    paymentRequestGuid: PaymentRequest['paymentRequestGuid'],
    cancelReasonText: string,
) {
    return await request({
        ...ROUTES.CANCEL_PAYMENT_REQUEST,
        pathParams: { paymentRequestGuid },
        data: { cancelReasonText },
    });
}

export async function createPayer(data: any) {
    return await request({
        ...ROUTES.CREATE_PAYER,
        data: { payer: data },
    });
}

export async function updatePayer(payerId: Payer['payerId'], payer: Partial<Payer>) {
    return await request({
        ...ROUTES.UPDATE_PAYER,
        pathParams: { payerId },
        data: {
            payerCategoryId: payer.payerCategoryId,
        },
    });
}

export async function createPaymentRequestContact(
    paymentRequestGuid: PaymentRequest['paymentRequestGuid'],
    type: 'SMS' | 'EMAIL',
    contactInfo: string,
) {
    return await request({
        ...ROUTES.CREATE_PAYMENT_REQUEST_CONTACT,
        pathParams: { paymentRequestGuid },
        data: {
            paymentRequestContact: {
                contactType: type,
                contactInfo,
            },
        },
    });
}

export async function getPaymentRequestContact(
    paymentRequestContactGuid: PaymentRequestContact['paymentRequestContactGuid'],
    options?: {
        includePaymentRequest?: boolean;
        includePaymentGateway?: boolean;
    },
) {
    const { includePaymentRequest, includePaymentGateway } = options;
    const paymentRequestContact = await request({
        ...ROUTES.GET_PAYMENT_REQUEST_CONTACT,
        pathParams: { paymentRequestContactGuid },
        params: { includePaymentRequest, includePaymentGateway },
    });
    return paymentRequestContact.data || {};
}

export async function unsubscribePaymentRequestContact(
    paymentRequestContactGuid: PaymentRequestContact['paymentRequestContactGuid'],
) {
    const paymentRequestContact = await request({
        ...ROUTES.UNSUBSCRIBE_PAYMENT_REQUEST_CONTACT,
        pathParams: { paymentRequestContactGuid },
    });
    return paymentRequestContact.data || {};
}

export async function updatePaymentRequestAmount(
    paymentRequestGuid: PaymentRequest['paymentRequestGuid'],
    paymentRequestAmount: number,
) {
    const paymentRequest = await request({
        ...ROUTES.UPDATE_PAYMENT_AMOUNT,
        pathParams: { paymentRequestGuid },
        data: { paymentRequestAmount },
    });

    return paymentRequest.data || {};
}
