import React, { useState, useEffect, useContext } from 'react';
import styles from './styles.module.scss';
import Select from 'react-select';
import { observer } from 'mobx-react';
import RoleStore from 'src/stores/RoleStore';
import * as utils from 'src/utils';
import useIsOptionsLoading from 'src/utils/hooks/useIsOptionsLoading';

export interface RoleDropDownProps {
    onChange: Function;
    selected?: number;
    allowEmpty?: boolean;
    selectedValue?: string | string[] | number | number[] | any;
    isMulti?: boolean;
    placeholder?: string;
}

function RoleDropDown(props: RoleDropDownProps) {
    const roleStore = useContext(RoleStore);
    const [selected, setSelected] = useState(null);

    const options = roleStore.rolesDropdownValues;
    const isLoading = useIsOptionsLoading(options);

    useEffect(() => {
        roleStore.getRoles();
    }, []);
    useEffect(() => {
        if (Array.isArray(props.selectedValue)) {
            const newVals = (props.selectedValue || []).map((v: any) => options.find((i) => i.value === v));
            if (newVals) {
                setSelected(newVals);
            }
        } else {
            const selectedVal = options.find((i) => i.value === props.selectedValue);
            if (selectedVal) {
                setSelected(selectedVal);
            }
        }
    }, [props.selectedValue, options]);

    return (
        <Select
            value={props.selected ? props.selected : selected}
            onChange={(s: any) => {
                if (s) {
                    setSelected(s);
                }
                props.onChange(s || (props.isMulti ? [] : null));
            }}
            isLoading={isLoading}
            isClearable={props.allowEmpty}
            className={styles.select}
            options={options}
            placeholder={props.placeholder ? props.placeholder : 'Select Roles'}
            onMenuOpen={() => {
                roleStore.getRoles();
            }}
            isMulti={props.isMulti}
            closeMenuOnSelect={false}
            styles={utils.styleSelectComponent()}
        />
    );
}

export default observer(RoleDropDown);
