import React, { useState, useContext, useEffect, useRef } from 'react';
import Input, { Label } from '../../components/Input';
import NavBar from '../NavBar';
import PageContent from '../../components/PageContent';
import PageContainer from '../../components/PageContainer';
import Card from '../../components/Card';
import Flex from 'src/components/Flex';
import Button from 'src/components/Button';
import Icon from 'src/components/Icon';
import Tooltip from 'src/components/Tooltip';
import styles from './styles.module.scss';
import { observer } from 'mobx-react';
import UserStore from 'src/stores/UserStore';
import useYup from 'src/utils/hooks/useYup';
import * as validation from 'src/utils/validation';
import * as utils from 'src/utils';
import * as yup from 'yup';
import { ANALYTICS_NAMES } from 'src/utils/analytics';

const pwValidationSchema = yup.object().shape({
    oldPassword: yup.string().required('Password is required'),
    newPassword: validation.passwordValidationYup,
    confirmPassword: yup
        .string()
        .oneOf([yup.ref('newPassword'), null], "Passwords don't match")
        .required('Confirm Password is required'),
});

const userValidationSchema = yup.object().shape({
    frstNm: yup.string().required('First Name is required'),
    lastNm: yup.string().required('Last Name is required'),
    orgEmailAddr: validation.emailValidationYup.required('Email is required'),
    // indexEmailAddr: validation.emailValidationYup.required('Email is required'),
});

function MyProfile() {
    const userStore = useContext(UserStore);
    const [isEditing, setIsEditing] = useState(false);
    const defaultPasswordState = {
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
    };
    const [user, setUser] = useState({
        frstNm: userStore.frstNm || '',
        lastNm: userStore.lastNm || '',
        orgEmailAddr: userStore.orgEmailAddr || '',
        indexEmailAddr: userStore.indexEmailAddr || '',
    });
    const [passwordState, setPasswordState] = useState(defaultPasswordState);
    const { errors: passwordErrors } = useYup(passwordState, pwValidationSchema, {
        validateOnChange: true,
    });
    const { errors: userErrors, isValid: isValidUser } = useYup(user, userValidationSchema, { validateOnChange: true });

    // Setup tooltip handling
    const loginRef = useRef(null);
    const orgRef = useRef(null);
    const passwordRef = useRef(null);
    const [tooltipIsOpen, setTooltipIsOpen] = useState(false);
    const [tooltipText, setTooltipText] = useState('');
    const [tooltipRef, setTooltipRef] = useState(loginRef);

    useEffect(() => {
        userStore.getMyProfileUser();
    }, []);

    useEffect(() => {
        if (user.indexEmailAddr !== userStore.indexEmailAddr) {
            setUser({ ...user, indexEmailAddr: userStore.indexEmailAddr });
        }
        if (user.orgEmailAddr !== userStore.orgEmailAddr) {
            setUser({ ...user, orgEmailAddr: userStore.orgEmailAddr });
        }
    }, [userStore.indexEmailAddr, userStore.orgEmailAddr]);

    function resetUser() {
        setUser({
            frstNm: userStore.frstNm || '',
            lastNm: userStore.lastNm || '',
            orgEmailAddr: userStore.orgEmailAddr || '',
            indexEmailAddr: userStore.indexEmailAddr || '',
        });
    }

    const updateInput = (param: string) => (t: string) => {
        setUser({ ...user, [param]: t });
    };
    const updatePassword = (param: string) => (t: string) => setPasswordState({ ...passwordState, [param]: t });

    async function save() {
        let updateData = {};

        if (!isValidUser) {
            // Some user fields are invalid
            return;
        }

        if (passwordState.oldPassword || passwordState.newPassword || passwordState.confirmPassword) {
            // Check that all fields are valid
            if (passwordErrors.oldPassword || passwordErrors.newPassword || passwordErrors.confirmPassword) {
                // There is an error with 1 or more password fields
                return;
            }
            updateData = { oldPassword: passwordState.oldPassword, newPassword: passwordState.newPassword };
        }
        // TODO: Validate fields

        const changedData = utils.onlyChangedFields(userStore, user, ['frstNm', 'lastNm', 'orgEmailAddr']);

        updateData = { ...updateData, ...changedData };

        await userStore.updateUser(updateData);
        setIsEditing(false);
        setUser({
            frstNm: userStore.frstNm || '',
            lastNm: userStore.lastNm || '',
            orgEmailAddr: userStore.orgEmailAddr || '',
            indexEmailAddr: userStore.indexEmailAddr || '',
        });
        setPasswordState(defaultPasswordState);
    }

    return (
        <PageContainer>
            <NavBar />
            <PageContent>
                <Card label={isEditing ? 'Edit Profile' : 'Profile'}>
                    <div className={styles.contentWrap}>
                        <Flex direction="row" value={1} justify="between" align="center">
                            <Flex className={styles.inputWrap} value={1}>
                                <Label text="FIRST NAME" />
                                {isEditing ? (
                                    <Input
                                        value={user.frstNm}
                                        onChangeText={updateInput('frstNm')}
                                        className={styles.input}
                                        errorMessage={userErrors.frstNm}
                                    />
                                ) : (
                                    <div className={styles.field}>{userStore.frstNm || '-'}</div>
                                )}
                            </Flex>
                            <Flex className={styles.inputWrap} value={1}>
                                <Label text="LAST NAME" />
                                {isEditing ? (
                                    <Input
                                        value={user.lastNm}
                                        onChangeText={updateInput('lastNm')}
                                        className={styles.input}
                                        errorMessage={userErrors.lastNm}
                                    />
                                ) : (
                                    <div className={styles.field}>{userStore.lastNm || '-'}</div>
                                )}
                            </Flex>
                            <Flex value={2} className={styles.inputWrap}>
                                <Flex direction="row">
                                    <Label text="LOGIN EMAIL ADDRESS" />
                                    <div
                                        ref={loginRef}
                                        onMouseOver={() => {
                                            setTooltipRef(loginRef);
                                            setTooltipText('Used for logging in and email notifications');
                                            setTooltipIsOpen(true);
                                        }}
                                        onMouseOut={() => setTooltipIsOpen(false)}
                                    >
                                        <Icon type="fa" name="question-circle" className={styles.help} />
                                    </div>
                                </Flex>
                                <div className={styles.field}>{userStore.indexEmailAddr || '-'}</div>
                            </Flex>
                            <Flex value={2} className={styles.inputWrap}>
                                <Flex direction="row">
                                    <Label text="ORGANIZATION EMAIL ADDRESS" />
                                    <div
                                        ref={orgRef}
                                        onMouseOver={() => {
                                            setTooltipRef(orgRef);
                                            setTooltipText('Used only for this organization');
                                            setTooltipIsOpen(true);
                                        }}
                                        onMouseOut={() => setTooltipIsOpen(false)}
                                    >
                                        <Icon type="fa" name="question-circle" className={styles.help} />
                                    </div>
                                </Flex>
                                {isEditing ? (
                                    <Input
                                        value={user.orgEmailAddr}
                                        onChangeText={updateInput('orgEmailAddr')}
                                        className={styles.input}
                                        errorMessage={userErrors.orgEmailAddr}
                                    />
                                ) : (
                                    <div className={styles.field}>{userStore.orgEmailAddr || '-'}</div>
                                )}
                            </Flex>
                            {!isEditing ? (
                                <Flex>
                                    <Button
                                        type="primary"
                                        onClick={() => setIsEditing(true)}
                                        className={styles.saveBtn}
                                        text="Edit"
                                        data-test-id={ANALYTICS_NAMES.Profile_Edit_Open}
                                    />
                                </Flex>
                            ) : null}
                        </Flex>
                        {isEditing ? (
                            <Flex direction="row" value={1} justify="between" align="center">
                                <Flex value={1} direction="row" align="center" className={styles.inputWrap}>
                                    <Input
                                        type="password"
                                        value={passwordState.oldPassword}
                                        onChangeText={updatePassword('oldPassword')}
                                        label="OLD PASSWORD"
                                        placeholder="**********"
                                        className={styles.input}
                                        errorMessage={
                                            passwordState.oldPassword &&
                                            passwordState.newPassword &&
                                            passwordErrors.oldPassword
                                                ? passwordErrors.oldPassword
                                                : undefined
                                        }
                                    />
                                    <div
                                        ref={passwordRef}
                                        onMouseOver={() => {
                                            setTooltipRef(passwordRef);
                                            setTooltipText("Leave blank if you don't want to change your password");
                                            setTooltipIsOpen(true);
                                        }}
                                        onMouseOut={() => setTooltipIsOpen(false)}
                                    >
                                        <Icon type="fa" name="question-circle" className={styles.help} />
                                    </div>
                                </Flex>
                                <Flex value={1} className={styles.inputWrap}>
                                    <Input
                                        type="password"
                                        value={passwordState.newPassword}
                                        onChangeText={updatePassword('newPassword')}
                                        label="NEW PASSWORD"
                                        placeholder="**********"
                                        errorMessage={
                                            passwordState.newPassword && passwordErrors.newPassword
                                                ? passwordErrors.newPassword
                                                : undefined
                                        }
                                        className={styles.input}
                                    />
                                </Flex>
                                <Flex value={1} className={styles.inputWrap}>
                                    <Input
                                        type="password"
                                        value={passwordState.confirmPassword}
                                        onChangeText={updatePassword('confirmPassword')}
                                        label="CONFIRM PASSWORD"
                                        className={styles.input}
                                        placeholder="**********"
                                        errorMessage={
                                            passwordState.confirmPassword && passwordErrors.confirmPassword
                                                ? passwordErrors.confirmPassword
                                                : undefined
                                        }
                                    />
                                </Flex>
                            </Flex>
                        ) : null}
                        {isEditing ? (
                            <Flex direction="row">
                                <Button
                                    type="outline"
                                    onClick={() => {
                                        setIsEditing(false);
                                        resetUser();
                                    }}
                                    className={styles.cancelBtn}
                                    text="Cancel"
                                    data-test-id={ANALYTICS_NAMES.Profile_Edit_Cancel}
                                />
                                <Button
                                    type="primary"
                                    onClick={() => save()}
                                    className={styles.saveBtn}
                                    text="Save"
                                    data-test-id={ANALYTICS_NAMES.Profile_Edit_Save}
                                />
                            </Flex>
                        ) : null}
                    </div>
                </Card>
                <Tooltip element={tooltipRef} isOpen={tooltipIsOpen} direction="top">
                    <div className={styles.tooltip}>{tooltipText}</div>
                </Tooltip>
            </PageContent>
        </PageContainer>
    );
}
export default observer(MyProfile);
