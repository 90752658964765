import React from 'react';
import CenterModal from 'src/components/CenterModal';
import Flex from 'src/components/Flex';
import Input from 'src/components/Input';
import ModalFooter from 'src/components/ModalFooter';
import ModalHeader from 'src/components/ModalHeader';
import { ANALYTICS_NAMES } from 'src/utils/analytics';
import styles from './styles.module.scss';

interface EditTextContentProps {
    type: 'email' | 'sms' | 'landing';
    value: string;
    isOpen: boolean;
    onChange?: Function;
    onClose?: Function;
    onSave?: Function;
}

function EditTextContent(props: EditTextContentProps) {
    function getTitle() {
        switch (props.type) {
            case 'email':
                return 'Edit Email Text';
            case 'sms':
                return 'Edit SMS Text';
            case 'landing':
                return 'Edit Landing Page Text';
        }
    }

    function handleSave() {
        if (props.onSave) {
            props.onSave();
        }
    }

    return (
        <CenterModal isOpen={props.isOpen} onClose={props.onClose}>
            <ModalHeader title={getTitle()} onClose={() => props.onClose()} />
            <Flex direction="column" className={styles.editContainer}>
                <Input
                    multiLine={true}
                    value={props.value}
                    rows={20}
                    className={styles.editInput}
                    onChangeText={(v: string) => props.onChange && props.onChange(v)}
                    data-test-id={ANALYTICS_NAMES.PaymentType_EditInput}
                />
            </Flex>
            <ModalFooter primaryText="Save" primaryClick={() => handleSave()} secondaryClick={() => props.onClose()} />
        </CenterModal>
    );
}

export default EditTextContent;
