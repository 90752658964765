import React, { useState, useEffect, useContext } from 'react';
import Flex from 'src/components/Flex';
import Button from 'src/components/Button';
import LoadingIcon from 'src/components/LoadingIcon';
import * as variables from 'src/styles/variables';
import { Elements, useStripe } from '@stripe/react-stripe-js';
import { Appearance, loadStripe } from '@stripe/stripe-js';
import styles from './styles.module.scss';
import graphiumLogo from 'public/assets/graphium-logo-light-background.svg';
import premierLogo from 'public/assets/organizationLogos/premier.webp';
import { parseError } from 'src/utils';
import { ToastStoreObject } from 'src/stores/ToastStore';
import { useHistory, useParams } from 'react-router';
import { ROUTES } from 'src/utils/constants';
import { getPaymentRequestContact } from 'src/api/payments';

const STRIPE_APPEARANCE: Appearance = {
    theme: 'stripe',
    variables: {
        fontFamily: variables.fontFamily,
    },
};

function StatusForm() {
    const stripe = useStripe();
    const history = useHistory();
    const { paymentRequestContactGuid } = useParams<{ paymentRequestContactGuid: string }>();

    const [isLoading, setIsLoading] = useState(true);
    const [status, setStatus] = useState('');

    useEffect(() => {
        if (!stripe) return;

        const clientSecret = new URLSearchParams(window.location.search).get('payment_intent_client_secret');

        if (!clientSecret) return;

        setPaymentIntentMessage(clientSecret);
    }, [stripe]);

    async function setPaymentIntentMessage(clientSecret: string) {
        try {
            const { paymentIntent } = await stripe.retrievePaymentIntent(clientSecret);
            setStatus(paymentIntent.status);
        } catch (e) {
            ToastStoreObject.show(parseError(e));
        } finally {
            setIsLoading(false);
        }
    }

    function getStatusTitle() {
        switch (status) {
            case 'succeeded':
                return 'Payment Successful';
            case 'processing':
                return 'Payment Processing';
            case 'payment_failed':
                return 'Payment Failed';
            default:
                return 'Uh oh!';
        }
    }

    function handleTryAgain() {
        history.push(ROUTES.getString(ROUTES.PaymentCollection, paymentRequestContactGuid));
    }

    function getStatusContent() {
        // TODO: Replace this with actually good text
        switch (status) {
            case 'succeeded':
                return <div className={styles.text}>You have successfully paid for your service. Thank you!</div>;
            case 'processing':
                return <div className={styles.text}>Processing your payment. Please wait.</div>;
            case 'payment_failed':
                return (
                    <>
                        <div className={styles.text}>Your payment has failed to process. Please try again.</div>
                        <Button
                            className={styles.payButton}
                            type="primary"
                            text="Try Again"
                            onClick={() => handleTryAgain()}
                        />
                    </>
                );
            default:
                return <div className={styles.text}>Something went wrong.</div>;
        }
    }

    return (
        <Flex className={styles.innerContainer} direction="column" align={isLoading ? 'center' : 'start'}>
            {isLoading ? (
                <LoadingIcon />
            ) : (
                <>
                    <div className={styles.title}>{getStatusTitle()}</div>
                    {getStatusContent()}
                </>
            )}
        </Flex>
    );
}

function PaymentCollectionStatus() {
    const [payState, setPayState] = useState('loading');
    const [stripePromise, setStripePromise] = useState(null);
    const [paymentRequest, setPaymentRequest] = useState(null);
    const { paymentRequestContactGuid } = useParams<{ paymentRequestContactGuid: string }>();
    const clientSecret = new URLSearchParams(window.location.search).get('payment_intent_client_secret');

    useEffect(() => {
        getPaymentRequest();
    }, []);

    useEffect(() => {
        if (stripePromise) {
            setPayState('status');
        }
    }, [stripePromise]);

    async function getPaymentRequest() {
        try {
            const paymentRequestContact = await getPaymentRequestContact(paymentRequestContactGuid, {
                includePaymentRequest: true,
                includePaymentGateway: true,
            });
            const apiKey = paymentRequestContact?.paymentRequest?.paymentType?.gateway?.paymentGatewayConfig?.apiKey;
            setPaymentRequest(paymentRequestContact?.paymentRequest);

            if (!stripePromise) {
                setStripePromise(loadStripe(apiKey));
            }
        } catch (e) {
            setPayState('error');
        }
    }

    function getLogo() {
        if (!paymentRequest || !paymentRequest.orgName) {
            return graphiumLogo;
        }

        switch (paymentRequest.orgName) {
            case 'pams2002':
                return premierLogo;
            default:
                return graphiumLogo;
        }
    }

    function getContent() {
        switch (payState) {
            case 'loading':
                return (
                    <Flex align="center" justify="center">
                        <LoadingIcon />
                    </Flex>
                );
            case 'error':
                return (
                    <>
                        <Flex className={styles.innerContainer} direction="column">
                            <div className={styles.title}>Error</div>
                            <div className={styles.text}>
                                There was an error loading your payment request status. Please try again later.
                            </div>
                        </Flex>
                    </>
                );
            default:
                return (
                    <Elements
                        stripe={stripePromise}
                        options={{
                            clientSecret,
                            appearance: STRIPE_APPEARANCE,
                        }}
                    >
                        <StatusForm />
                    </Elements>
                );
        }
    }

    return (
        <Flex align="center" direction="column" className={styles.pageContainer}>
            <Flex className={styles.paymentContainer} direction="column">
                <div className={styles.logo}>
                    <div className={styles.logoContainer}>
                        <img src={getLogo()} />
                    </div>
                </div>
                {getContent()}
            </Flex>
            <div className={styles.copyright}>
                &copy; {`${new Date().getFullYear()} Graphium Health`}, All rights reserved
            </div>
        </Flex>
    );
}

export default PaymentCollectionStatus;
