import React, { useState, useEffect, useContext } from 'react';
import styles from './styles.module.scss';
import SideModal from 'src/components/SideModal';
import Input, { Label } from 'src/components/Input';
import ModalFooter from 'src/components/ModalFooter';
import ModalHeader from 'src/components/ModalHeader';
import FacilityStore, { Facility, FacilityPhoneNumber } from 'src/stores/FacilityStore';
import { ToastStoreObject, ToastType } from 'src/stores/ToastStore';
import * as utils from 'src/utils';
import Flex from '../Flex';
import NemsisOptionDropDown from '../NemsisOptionDropDown';
import { getDropdownValue } from 'src/utils/nemsis';
import UserStore, { OrgType, UserStoreObject } from 'src/stores/UserStore';
import Button from 'src/components/Button';
import { OptionTypes } from '../OptionDropDown';
import useFlag from 'src/utils/hooks/useFlag';
import ReferenceListStore from 'src/stores/ReferenceListStore';
import { observer } from 'mobx-react';
import NemsisStore from 'src/stores/NemsisStore';
import Select from 'react-select';

export interface FacilityModalProps {
    facility: Facility;
    onClose: Function;
    onSave: Function;
}

const defaultFacilityPhone: FacilityPhoneNumber = {
    typeCode: '',
    phoneNumber: '',
};

const defaultFacility: Facility = {
    facNm: '',
    facNmIntrnl: '',
    facDesc: '',
    addrLn1: '',
    addrLn2: '',
    addrZipCd: '',
    addrStCd: '',
    phNoMain: '',
    addrCityNm: '',
    facilityTypeCode: '',
    facilityNumber: '',
    gpsLocation: '',
    nationalGridCoordinates: '',
    cityCode: '',
    stateCode: '',
    countyCode: '',
    countryCode: '',
    facilityPhones: [],
};

const FACILITY_TIMEZONES = [
    { value: 'US/Eastern', label: 'Eastern' },
    { value: 'US/Central', label: 'Central' },
    { value: 'US/Mountain', label: 'Mountain' },
    { value: 'US/Pacific', label: 'Pacific' },
];

function FacilityModal(props: FacilityModalProps) {
    const [data, setData] = useState<Facility>(props.facility || { ...defaultFacility });
    const isEdit = !!props.facility;
    const [shouldShow, setShouldShow] = useState(true);
    const [facilityTimeZone, setFacilityTimeZone] = useState('US/Central');
    const userStore = useContext(UserStore);
    const facilityStore = useContext(FacilityStore);
    const referenceListStore = useContext(ReferenceListStore);
    const nemsisStore = useContext(NemsisStore);
    const nemsisEnabled = useFlag('nemsis');

    useEffect(() => {
        setShouldShow(userStore.selectedOrgType === OrgType.FIELDMED);
    }, []);

    useEffect(() => {
        if (data.stateCode === null) return;

        referenceListStore.getFilteredData(OptionTypes.ANSICityCodes, {
            filterName: 'stateAnsiCode',
            filterValue: data.stateCode,
        });

        referenceListStore.getFilteredData(OptionTypes.ANSICountyCodes, {
            filterName: 'stateAnsiCode',
            filterValue: data.stateCode,
        });
    }, [data.stateCode]);

    async function handleSave() {
        try {
            if (isEdit) {
                await facilityStore.updateFacility(data.facId, data);
            } else {
                await facilityStore.createFacility(Object.assign(data, { facilityTimeZone }));
            }

            if (nemsisEnabled) {
                await nemsisStore.validateDemData(true);
            }

            props.onSave();
            props.onClose();
        } catch (e) {
            ToastStoreObject.show(utils.parseError(e), ToastType.Error);
        }
    }

    const updateInput = (param: string) => (t: string) => setData({ ...data, [param]: t });

    function updateNestedInput({
        param,
        subParam,
        index,
        value,
    }: {
        param: keyof Facility;
        subParam: string;
        index: number;
        value: any;
    }) {
        const newData: any = { ...data };
        if (!newData[param]) {
            newData[param] = [];
        }
        if (!newData[param][index]) {
            newData[param][index] = {};
        }
        newData[param][index][subParam] = value;
        setData(newData);
    }

    function handleRemoveRow(parentProp: keyof Facility, index: number) {
        const currentData = { ...data };
        // @ts-ignore
        (currentData[parentProp] || []).splice(index, 1);

        setData(currentData);
    }

    function handleAddRow(property: keyof Facility) {
        const currentData = { ...data };
        if (property === 'facilityPhones') {
            if (!currentData[property]) currentData[property] = [];
            currentData[property].push({ ...defaultFacilityPhone });
        }
        setData(currentData);
    }

    function getLatitudeLongitude(): { latitude: string; longitude: string } {
        if (data.gpsLocation && data.gpsLocation.length > 0) {
            const splitData = data.gpsLocation.split(',');

            return {
                latitude: splitData[0] || '',
                longitude: splitData[1] || '',
            };
        } else {
            return {
                latitude: '',
                longitude: '',
            };
        }
    }

    function getCityRefListValue(cityCode: string) {
        const cityValues =
            referenceListStore.filteredReferenceListValues.get(`${OptionTypes.ANSICityCodes}-${data.stateCode}`) || [];
        return cityValues.find((value) => {
            return value.listValueCode === cityCode;
        });
    }

    function getStateRefListValueByCode(stateCode: string) {
        const stateValues = referenceListStore.referenceListValues[OptionTypes.ANSIStateCodes] || [];
        return stateValues.find((value) => {
            return value.listValueCode === stateCode;
        });
    }

    return (
        <SideModal isOpen={true} onClose={() => props.onClose()}>
            <ModalHeader
                title={props.facility ? `Edit ${data.facNm || 'Facility'}` : 'New Facility'}
                onClose={() => props.onClose()}
            />
            <div className={styles.contentWrap}>
                <Flex direction="column" className={styles.form}>
                    {nemsisEnabled && shouldShow && (
                        <Flex direction="row" justify="between" className={styles.formRow}>
                            <Flex value={1}>
                                <NemsisOptionDropDown
                                    nemsisLabel="Facility Type"
                                    selectedValue={data.facilityTypeCode}
                                    type={OptionTypes.EMSLocationType}
                                    onChange={(d: any) => {
                                        const val = getDropdownValue(d, undefined);
                                        return setData({ ...data, facilityTypeCode: val });
                                    }}
                                />
                            </Flex>
                        </Flex>
                    )}
                    <Flex direction="row" justify="between" className={styles.formRow}>
                        <Flex value={1}>
                            <Input
                                className={styles.input}
                                label="Name"
                                value={data.facNm}
                                onChangeText={updateInput('facNm')}
                            />
                        </Flex>
                        {nemsisEnabled && (
                            <>
                                <Flex value={0.1} />
                                <Flex value={1}>
                                    <Input
                                        className={styles.input}
                                        label="Facility Number"
                                        value={data.facilityNumber}
                                        onChangeText={updateInput('facilityNumber')}
                                    />
                                </Flex>
                            </>
                        )}
                    </Flex>
                    <Flex direction="row" justify="between" className={styles.formRow}>
                        <Flex value={1}>
                            <Input
                                className={styles.input}
                                label="Internal Name"
                                value={data.facNmIntrnl}
                                disabled={isEdit}
                                onChangeText={(v: string) => {
                                    const filteredValue = v.replace(/[^a-zA-Z]/g, '').toUpperCase();
                                    setData({ ...data, facNmIntrnl: filteredValue });
                                }}
                            />
                        </Flex>
                    </Flex>
                    {!isEdit && (
                        <Flex direction="row" justify="between" className={styles.formRow}>
                            <Flex value={1}>
                                <Label text="Time Zone" className={styles.labelWrap} />
                                <Select
                                    value={FACILITY_TIMEZONES.find((timezone) => timezone.value === facilityTimeZone)}
                                    placeholder="Select Time Zone"
                                    onChange={(s: any) => setFacilityTimeZone(s.value)}
                                    options={FACILITY_TIMEZONES}
                                    styles={utils.styleSelectComponent(true)}
                                    menuPortalTarget={document.body}
                                />
                            </Flex>
                        </Flex>
                    )}
                    <Flex direction="row" justify="between" className={styles.formRow}>
                        <Flex value={1}>
                            <Input
                                className={styles.input}
                                label="Description"
                                value={data.facDesc}
                                onChangeText={updateInput('facDesc')}
                            />
                        </Flex>
                    </Flex>
                    <Flex direction="row" justify="between" className={styles.formRow}>
                        <Flex value={1}>
                            <Input
                                className={styles.input}
                                label="Address 1"
                                value={data.addrLn1}
                                onChangeText={updateInput('addrLn1')}
                            />
                        </Flex>
                    </Flex>
                    <Flex direction="row" justify="between" className={styles.formRow}>
                        <Flex value={1}>
                            <Input
                                className={styles.input}
                                label="Address 2"
                                value={data.addrLn2}
                                onChangeText={updateInput('addrLn2')}
                            />
                        </Flex>
                    </Flex>
                    {nemsisEnabled ? (
                        <>
                            <Flex direction="row" justify="between" className={styles.formRow}>
                                <Flex value={1}>
                                    <NemsisOptionDropDown
                                        nemsisLabel="State"
                                        selectedValue={data.stateCode}
                                        type={OptionTypes.ANSIStateCodes}
                                        onChange={(d: any) => {
                                            const val = getDropdownValue(d, undefined);
                                            const stateRefValue = getStateRefListValueByCode(val);
                                            const abbr = stateRefValue.listValueExtendedProperties.stateAbbreviation;
                                            return setData({
                                                ...data,
                                                addrStCd: abbr,
                                                stateCode: val,
                                                cityCode: null,
                                                countyCode: null,
                                            });
                                        }}
                                    />
                                </Flex>
                                <Flex value={0.1} />
                                <Flex value={1}>
                                    <NemsisOptionDropDown
                                        nemsisLabel="City"
                                        selectedValue={data.cityCode}
                                        type={OptionTypes.ANSICityCodes}
                                        onChange={(d: any) => {
                                            const val = getDropdownValue(d, undefined);
                                            const stateRefValue = getCityRefListValue(val);
                                            const name = stateRefValue.listValueDisplayName;
                                            return setData({ ...data, addrCityNm: name, cityCode: val });
                                        }}
                                        isDisabled={data.stateCode === '' || data.stateCode == null}
                                        virtualize={true}
                                        options={
                                            referenceListStore.filteredReferenceListDropdownValues.get(
                                                `${OptionTypes.ANSICityCodes}-${data.stateCode}`,
                                            ) || []
                                        }
                                    />
                                </Flex>
                                <Flex value={0.1} />
                                <Flex value={1}>
                                    <Input
                                        className={styles.input}
                                        label="Zip Code"
                                        value={data.addrZipCd}
                                        onChangeText={updateInput('addrZipCd')}
                                    />
                                </Flex>
                            </Flex>
                        </>
                    ) : (
                        <>
                            <Flex direction="row" justify="between" className={styles.formRow}>
                                <Flex value={1}>
                                    <Input
                                        className={styles.input}
                                        label="City"
                                        value={data.addrCityNm}
                                        onChangeText={updateInput('addrCityNm')}
                                    />
                                </Flex>
                                <Flex value={0.1} />
                                <Flex value={1}>
                                    <Input
                                        className={styles.input}
                                        label="State"
                                        value={data.addrStCd}
                                        onChangeText={updateInput('addrStCd')}
                                    />
                                </Flex>
                                <Flex value={0.1} />
                                <Flex value={1}>
                                    <Input
                                        className={styles.input}
                                        label="Zip Code"
                                        value={data.addrZipCd}
                                        onChangeText={updateInput('addrZipCd')}
                                    />
                                </Flex>
                            </Flex>
                        </>
                    )}
                    {nemsisEnabled && (
                        <Flex direction="row" justify="between" className={styles.formRow}>
                            <Flex value={1}>
                                <NemsisOptionDropDown
                                    nemsisLabel="County"
                                    selectedValue={data.countyCode}
                                    type={OptionTypes.ANSICountyCodes}
                                    onChange={(d: any) => {
                                        const val = getDropdownValue(d, undefined);
                                        return setData({ ...data, countyCode: val });
                                    }}
                                    isDisabled={data.stateCode === '' || data.stateCode == null}
                                    virtualize={true}
                                    options={
                                        referenceListStore.filteredReferenceListDropdownValues.get(
                                            `${OptionTypes.ANSICountyCodes}-${data.stateCode}`,
                                        ) || []
                                    }
                                />
                            </Flex>
                            <Flex value={0.1} />
                            <Flex value={1}>
                                <NemsisOptionDropDown
                                    nemsisLabel="Country"
                                    selectedValue={data.countryCode}
                                    type={OptionTypes.EMSANSICountryCodesCAMXUS}
                                    onChange={(d: any) => {
                                        const val = getDropdownValue(d, undefined);
                                        return setData({ ...data, countryCode: val });
                                    }}
                                    virtualize={true}
                                />
                            </Flex>
                        </Flex>
                    )}
                    {nemsisEnabled && (
                        <Flex direction="row" justify="between" className={styles.formRow}>
                            <Flex value={1}>
                                <Input
                                    className={styles.input}
                                    label="Latitude"
                                    value={getLatitudeLongitude().latitude}
                                    onChangeText={(text: string) => {
                                        let newData = { ...data };
                                        let gps = getLatitudeLongitude();
                                        newData.gpsLocation = `${text},${gps.longitude}`;
                                        setData(newData);
                                    }}
                                />
                            </Flex>
                            <Flex value={0.1} />
                            <Flex value={1}>
                                <Input
                                    className={styles.input}
                                    label="Longitude"
                                    value={getLatitudeLongitude().longitude}
                                    onChangeText={(text: string) => {
                                        let newData = { ...data };
                                        let gps = getLatitudeLongitude();
                                        newData.gpsLocation = `${gps.latitude},${text}`;
                                        setData(newData);
                                    }}
                                />
                            </Flex>
                        </Flex>
                    )}
                    {nemsisEnabled && (
                        <Flex direction="row" justify="between" className={styles.formRow}>
                            <Flex value={1}>
                                <Input
                                    className={styles.input}
                                    label="US National Grid Coordinates"
                                    value={data.nationalGridCoordinates}
                                    onChangeText={updateInput('nationalGridCoordinates')}
                                />
                            </Flex>
                        </Flex>
                    )}
                    <Flex direction="row" justify="between" className={styles.formRow}>
                        <Flex value={1}>
                            <Input
                                className={styles.input}
                                label="Main Phone Number"
                                value={data.phNoMain}
                                onChangeText={updateInput('phNoMain')}
                            />
                        </Flex>
                    </Flex>
                    {nemsisEnabled && (
                        <Flex direction="row" justify="between" className={styles.formRow}>
                            <Flex value={1} direction="column" className={styles.newRow}>
                                <span className={styles.heading}>Additional Phone Numbers</span>
                                {(data.facilityPhones || []).map((pn, index) => (
                                    <Flex
                                        key={`pn${index}`}
                                        direction="row"
                                        justify="between"
                                        align="center"
                                        className={styles.formRow}
                                    >
                                        <Flex value={1}>
                                            <NemsisOptionDropDown
                                                nemsisLabel="Type"
                                                type={OptionTypes.EMSPhoneNumberType}
                                                selectedValue={pn.typeCode}
                                                onChange={(d: any) => {
                                                    const value = getDropdownValue(d, []);
                                                    updateNestedInput({
                                                        param: 'facilityPhones',
                                                        subParam: 'typeCode',
                                                        index,
                                                        value,
                                                    });
                                                }}
                                            />
                                        </Flex>
                                        <Flex value={0.1}></Flex>
                                        <Flex value={1}>
                                            <Input
                                                key={`pninput${index}`}
                                                label="Phone Number"
                                                type="string"
                                                value={pn.phoneNumber}
                                                onChangeText={(text: string) => {
                                                    updateNestedInput({
                                                        param: 'facilityPhones',
                                                        subParam: 'phoneNumber',
                                                        index,
                                                        value: text,
                                                    });
                                                }}
                                            />
                                        </Flex>

                                        <Flex value={0.7} align="end" justify="end">
                                            <Button
                                                type="primary"
                                                text="Remove"
                                                onClick={() => handleRemoveRow('facilityPhones', index)}
                                            />
                                        </Flex>
                                    </Flex>
                                ))}
                                <Button
                                    type="transparent"
                                    text="+ Add Phone Number"
                                    onClick={() => handleAddRow('facilityPhones')}
                                    className={styles.addUnderline}
                                />
                            </Flex>
                        </Flex>
                    )}
                </Flex>
            </div>
            <ModalFooter
                primaryText={isEdit ? 'Save' : 'Create'}
                primaryClick={handleSave}
                secondaryClick={() => {
                    setData({ ...defaultFacility });
                    props.onClose();
                }}
            />
        </SideModal>
    );
}

export default observer(FacilityModal);
