import { UseQueryResult, useQuery } from 'react-query';
import { getFormFacilityMappingFormDefinitions } from 'src/api/formFacilityMappings';
import { UserStoreObject } from 'src/stores/UserStore';
import { FormFacilityMapping } from 'common/lib/entity/framework/FormFacilityMapping';
import { FormDefinition } from 'common/lib/entity/org/FormDefinition';

export const formFacilityMappingFormDefinitionsKey = 'formFacilityMappingFormDefinitions';

export const useFormFacilityMappingFormDefinitions = (
    orgNameInternal: FormFacilityMapping['orgNameInternal'],
    facilityId: FormFacilityMapping['facilityId'],
): UseQueryResult<FormDefinition[]> => {
    return useQuery({
        queryKey: [formFacilityMappingFormDefinitionsKey],
        queryFn: async () => {
            if (!orgNameInternal) {
                throw new Error('Organization name is required');
            }

            if (!facilityId) {
                throw new Error('Facility id is required');
            }

            return await getFormFacilityMappingFormDefinitions(orgNameInternal, facilityId);
        },
        enabled: orgNameInternal != null && facilityId != null && UserStoreObject.isGraphiumAdmin(),
        initialData: [],
    });
};
