import React from 'react';

const SvgSingleNeutralActionsAddress = (props) => (
    <svg width={19} height={19} {...props} viewBox="0 0 19 19">
        <g
            transform="translate(1 1)"
            stroke={props.color || '#FFF'}
            strokeWidth={1.2}
            fill="none"
            fillRule="evenodd"
            opacity={0.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <circle cx={12.219} cy={12.219} r={1.594} />
            <path d="M13.813 12.219v.797a1.327 1.327 0 001.328 1.328h0a1.327 1.327 0 001.328-1.328v-.6a4.381 4.381 0 00-4.14-4.446 4.25 4.25 0 00-1.118 8.383 4.35 4.35 0 002.101-.027" />
            <circle cx={4.25} cy={2.656} r={2.125} />
            <path d="M7.476 7.712a3.72 3.72 0 00-6.945 1.85v1.594h1.594l.531 5.313h3.188l.229-2.29" />
        </g>
    </svg>
);

export default SvgSingleNeutralActionsAddress;
