import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './assets/icons/fa.min.css';
import './assets/icons/solid.min.css';
import './index.scss';
import App from './App';
import Auth0ProviderWithHistory from './Auth0ProviderWithHistory';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            retry: false,
        },
    },
});

ReactDOM.render(
    <QueryClientProvider client={queryClient}>
        <BrowserRouter>
            <Auth0ProviderWithHistory>
                <App />
                <ReactQueryDevtools initialIsOpen={false} />
            </Auth0ProviderWithHistory>
        </BrowserRouter>
    </QueryClientProvider>,
    document.getElementById('root'),
);
