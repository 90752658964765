import React, { useState, useEffect, useContext, useRef } from 'react';
import styles from './styles.module.scss';
import Select from 'react-select';
import FacilityStore from 'src/stores/FacilityStore';
import { observer } from 'mobx-react';
import * as utils from 'src/utils';
import useIsOptionsLoading from 'src/utils/hooks/useIsOptionsLoading';
import { OptionsType, GroupedOptionsType } from 'react-select/src/types';

export interface FacilityDropDownProps {
    onChange: Function;
    selectedValue?: any;
    allowEmpty?: boolean;
    isDefaultEmpty?: boolean;
    isMulti?: boolean;
    placeholder?: string;
    isDisabled?: boolean;
    closeMenuOnSelect?: boolean;
    allowSelectAll?: boolean;
    usePortal?: boolean;
    prependOptions?: OptionsType<any> | GroupedOptionsType<any>;
}

function FacilityDropDown(props: FacilityDropDownProps) {
    const facilityStore = useContext(FacilityStore);
    const selectRef = useRef(null);
    const [selected, setSelected] = useState(null);

    // Rebuild options on each render to possibly add the "Select All"
    const options = props.prependOptions
        ? [...props.prependOptions, ...facilityStore.facilitiesDropdownValues]
        : [...facilityStore.facilitiesDropdownValues];

    const isLoading = useIsOptionsLoading(options);

    if (props.allowSelectAll) {
        if (options.length !== (selected || []).length) {
            options.unshift({ label: 'Select All', value: 'select_all_option' });
        }
    }

    const defaultProps = {
        ref: selectRef,
        value: selected,
        onChange: (s: any) => {
            if (!s) {
                setSelected(null);
                props.onChange(props.isMulti ? [] : null);
                return;
            }
            if (props.allowSelectAll && s.find((item: any) => item.value === 'select_all_option')) {
                const newOptions = options.slice(1, options.length);
                setSelected(newOptions);
                props.onChange(newOptions);
                selectRef.current.blur();
            } else {
                setSelected(s);
                props.onChange(s);
            }
        },
        isDisabled: props.isDisabled,
        isLoading: isLoading,
        className: styles.select,
        isClearable: props.allowEmpty,
        options: options,
        placeholder: props.placeholder || 'Select Facility',
        onMenuOpen: () => {
            // Make sure it refetches facilities when the menu is opened in case there was an initial load issue
            facilityStore.getFacilities();
        },
        isMulti: props.isMulti,
        closeMenuOnSelect: props.closeMenuOnSelect,
        styles: utils.styleSelectComponent(props.usePortal),
        classNamePrefix: 'facility_selector',
    };

    // Query the facilities if we don't have them yet.
    useEffect(() => {
        facilityStore.getFacilities();
    }, []);

    useEffect(() => {
        let selectedVal;
        if (!props.selectedValue && !props.isDefaultEmpty && facilityStore.defaultFacilityId) {
            selectedVal = options.find((i) => i.value === facilityStore.defaultFacilityId);
        } else if (utils.isArray(props.selectedValue)) {
            selectedVal = options.filter((i) => props.selectedValue.indexOf(i.value) > -1);
        } else {
            selectedVal = options.find((i) => i.value === props.selectedValue);
        }
        if (selectedVal) {
            if (props.isMulti && !utils.isArray(selectedVal)) {
                selectedVal = [selectedVal];
            }
            setSelected(selectedVal);
            // Only run onchange when there is a null or undefined value passed in
            // If the parent component is managing selected values and it has a value already, don't override it
            if (!props.selectedValue) {
                props.onChange(selectedVal);
            }
        }
    }, [props.selectedValue, facilityStore.defaultFacilityId]);

    return (
        <Select
            {...(props.usePortal ? Object.assign(defaultProps, { menuPortalTarget: document.body }) : defaultProps)}
        />
    );
}

export default observer(FacilityDropDown);
