import { isArray } from 'src/utils/index';

export function getDropdownValue(option: any, defaultValue?: any) {
    if (!option) {
        return defaultValue;
    }
    if (isArray(option)) {
        return option.map((o) => o.value);
    }
    return option.value;
}
