import * as ROUTES from 'src/api/routes';
import request from 'src/api/utils';
import * as utils from 'src/utils';
import { OrgUser } from 'src/stores/OrgUserStore';
import {
    IAgencyConfigurations,
    IAgencyInformation,
    IAgencyContacts,
    IAgencyCustomResult,
} from 'common/lib/model/nemsis3_4/demographic';
import { Org } from 'common/lib/entity/index/Org';
import { OrgType } from 'common/lib/model/OrgType';
import { Customer } from 'common/lib/entity/index/Customer';

export async function getUsers(params?: object) {
    const response = await request({ ...ROUTES.GET_ORG_USERS, params });
    let users = response.data || [];
    // TODO: Return the roles in a flat array instead of nested inside each role
    users.forEach((user: any) => {
        const flattenedRoles = user.roles.map((role: any) => role.details);
        user.roles = flattenedRoles;
        if (utils.isString(user.usrId)) {
            user.usrId = utils.getNumberOrNull(user.usrId);
        }
    });

    users = users.filter((u: OrgUser) => u.usrNm !== 'graphium_svc');

    return users;
}

export async function getUserByUsername(username: string) {
    return await request({ ...ROUTES.GET_ORG_USER_BY_USERNAME, pathParams: { username } });
}

export async function editUser(userId: number, data: object) {
    return await request({ ...ROUTES.EDIT_ORG_USER, pathParams: { userId }, data });
}

export async function deactivateUser(userId: number) {
    return await request({ ...ROUTES.DEACTIVATE_ORG_USER, pathParams: { userId } });
}

export async function reactivateUser(userId: number) {
    return await request({ ...ROUTES.REACTIVATE_ORG_USER, pathParams: { userId } });
}

export async function getUserTeams(userId: number, facilityId: number) {
    return await request({ ...ROUTES.GET_ORG_USER_TEAMS, pathParams: { userId, facilityId } });
}

export async function updateUserTeams(userId: number, facilityId: number, data: object) {
    return await request({ ...ROUTES.UPDATE_ORG_USER_TEAMS, pathParams: { userId, facilityId }, data });
}

export async function getUserFacilities(userId: number) {
    return await request({ ...ROUTES.GET_ORG_USER_FACILITIES, pathParams: { userId } });
}

export async function updateUserFacilities(userId: number, facilityIds: number[]) {
    return await request({ ...ROUTES.UPDATE_ORG_USER_FACILITIES, pathParams: { userId }, data: { facilityIds } });
}

export async function getOrgsForUser() {
    return await request(ROUTES.GET_ORGS_FOR_USER);
}

export async function getOrgDbOrg() {
    return await request(ROUTES.GET_ORGDB_ORG);
}

export async function updateAgencyData(data: {
    agencyInfo?: IAgencyInformation;
    agencyContacts?: IAgencyContacts;
    agencyConfigurations?: IAgencyConfigurations;
    agencyCustomResults?: IAgencyCustomResult[];
}) {
    return await request({ ...ROUTES.UPDATE_AGENCY_DATA, data });
}

export async function createOrganization(data: {
    customerId: Customer['customerId'];
    orgNm: Org['orgNm'];
    orgIdentifier: string;
    orgType: OrgType;
    enableAnalytics: boolean;
    enableVitals: boolean;
    enableTelehealth: boolean;
}) {
    return await request({ ...ROUTES.CREATE_ORGANIZATION, data });
}

export async function getAllActiveOrgs() {
    const result = await request({ ...ROUTES.GET_ALL_ACTIVE_ORGS });
    return result?.data || [];
}
