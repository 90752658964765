import React, { ReactNode } from 'react';
import Flex from 'src/components/Flex';
import ModalCloseButton from 'src/components/ModalCloseButton';
import styles from './styles.module.scss';

export interface ModalHeaderProps {
    title?: ReactNode;
    onClose?: Function;
    children?: ReactNode;
}

export default function ModalHeader(props: ModalHeaderProps) {
    return (
        <Flex direction="row" className={styles.modalTitle} self="stretch">
            {props.children || (
                <>
                    <Flex value={1} align="center" className={styles.onlyTitle}>
                        {props.title}
                    </Flex>
                    {props.onClose ? (
                        <Flex justify="end" align="center">
                            <ModalCloseButton onClose={props.onClose} />
                        </Flex>
                    ) : null}
                </>
            )}
        </Flex>
    );
}
