import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

interface TableActiveColProps {
    isActive: boolean;
    activeText?: string;
    inactiveText?: string;
}

const TableActiveCol: React.SFC<TableActiveColProps> = ({ isActive, activeText, inactiveText }) => {
    return (
        <span className={classNames({ [styles.redText]: !isActive, [styles.greenText]: isActive })}>
            {isActive ? activeText || 'ACTIVE' : inactiveText || 'INACTIVE'}
        </span>
    );
};

export default TableActiveCol;
