import { UseMutationResult, useMutation, useQueryClient } from 'react-query';
import { prepareFormFacilityMappingForDeployment } from 'src/api/formFacilityMappings';
import { FormCatalog } from 'common/lib/entity/framework/FormCatalog';
import { FormDeployment } from 'common/lib/entity/framework/FormDeployment';
import { formFacilityMappingKey } from './useFormFacilityMapping';

export const prepareFormFacilityMappingForDeploymentKey = 'prepareFormFacilityMappingForDeployment';

export interface UsePrepareFormFacilityMappingForDeploymentInputVariables {
    formCatalogVersionId: FormDeployment['formCatalogVersionId'];
    deploymentDescription?: FormDeployment['deploymentDescription'];
}

export const usePrepareFormFacilityMappingForDeployment = (
    formCatalogId: FormCatalog['formCatalogId'],
): UseMutationResult<
    { formDeploymentId: FormDeployment['formDeploymentId'], deploymentDetails: FormDeployment['deploymentDetails'] },
    Error,
    UsePrepareFormFacilityMappingForDeploymentInputVariables,
    unknown
> => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationKey: [prepareFormFacilityMappingForDeploymentKey],
        mutationFn: async (variables) => {
            const { formCatalogVersionId, deploymentDescription } = variables;

            if (!formCatalogId) {
                throw new Error('formCatalogId is required');
            }

            if (!formCatalogVersionId) {
                throw new Error('formCatalogVersionId is required');
            }

            return await prepareFormFacilityMappingForDeployment(formCatalogId, {
                formCatalogVersionId,
                deploymentDescription,
            });
        },
        onSuccess: async (data) => {
            queryClient.invalidateQueries({ queryKey: [formFacilityMappingKey] });
            return data;
        },
        retry: false,
    });
};
