import React, { useContext, useState } from 'react';
import { format } from 'date-fns';
import Flex from 'src/components/Flex';
import EventStore from 'src/stores/EventStore';
import { Label } from 'src/components/Input';
import DatePicker from 'src/components/DatePicker';
import TimePicker from 'src/components/TimePicker';
import ModalHeader from 'src/components/ModalHeader';
import ModalFooter from 'src/components/ModalFooter';
import * as utils from 'src/utils';
import { ViewProps, EventDetailViews } from '../index';
import styles from '../styles.module.scss';
import { API_DATE_FORMAT } from 'src/utils/constants';
import { ToastStoreObject } from 'src/stores/ToastStore';

export default function RescheduleView(props: ViewProps) {
    const { event } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [state, setState] = useState({
        eventStartDate: event.eventStartDate,
        eventEndDate: event.eventEndDate,
        eventStartTime: event.eventStartTime,
        eventEndTime: event.eventEndTime,
    });
    const eventStore = useContext(EventStore);
    async function save() {
        try {
            setIsLoading(true);
            await eventStore.rescheduleEvent(event.eventId, state);
            setIsLoading(false);
            props.onChangeComplete && props.onChangeComplete();
            props.onClose();
        } catch (error) {
            ToastStoreObject.show(utils.parseError(error));
            setIsLoading(false);
        }
    }
    return (
        <div className={styles.content}>
            <ModalHeader title="Reschedule" onClose={props.onClose} />
            <div className={styles.mainContent}>
                <div>
                    <Label text="Time/Date" />
                    <DatePicker
                        value={state.eventStartDate}
                        onChange={(date) => {
                            if (date) {
                                const value = format(new Date(date), API_DATE_FORMAT);
                                setState({ ...state, eventStartDate: value, eventEndDate: value });
                            }
                        }}
                    />
                    <Flex direction="row" align="center">
                        <Flex value={1}>
                            <TimePicker
                                onChange={(t: string) => setState({ ...state, eventStartTime: t })}
                                time={state.eventStartTime}
                            />
                        </Flex>
                        <div className={styles.dash}>{' - '}</div>
                        <Flex value={1}>
                            <TimePicker
                                onChange={(t: string) => setState({ ...state, eventEndTime: t })}
                                time={state.eventEndTime}
                            />
                        </Flex>
                    </Flex>
                </div>
            </div>
            <ModalFooter
                secondaryText="Go Back"
                primaryText="Done"
                isLoading={isLoading}
                secondaryClick={() => props.onChangeView(EventDetailViews.EVENT)}
                primaryClick={save}
            />
        </div>
    );
}
