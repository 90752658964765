import { action, observable, makeObservable } from 'mobx';
import { createContext } from 'react';
import * as patientsAPI from 'src/api/patients';
import { Patient } from './PatientStore';

export interface PatientNeed {
    patientNeedId?: number;
    patientId?: number;
    patientNeedStateId?: number;
    patientNeedType?: string;
    patientNeedDescription?: string;
    facilityId?: number;
    needState?: {
        patientNeedStateCode?: string;
        patientNeedStateDescription?: string;
        patientNeedStateId?: number;
        patientNeedStateTitle?: string;
    };
}
export interface PatientNeedState {
    patientNeedStateId?: number;
    patientNeedStateCode?: string;
    patientNeedStateTitle?: string;
    patientNeedStateDescription?: string;
}

class PatientNeedStore {
    @observable needs: PatientNeed[] = [];
    @observable needStates: PatientNeedState[] = [];

    constructor() {
        makeObservable(this);
    }

    @action
    reset() {
        this.needs = [];
        this.needStates = [];
    }

    @action
    async getPatientNeeds(patientId: number, params: {} = {}) {
        const needs = await patientsAPI.getPatientNeeds(patientId, params);
        this.setPatientNeeds(needs.data || []);
    }

    @action
    setPatientNeeds(needs: PatientNeed[]) {
        this.needs = needs;
    }

    @action
    async createPatientNeed(patient: Patient, need: PatientNeed) {
        const newNeed = await patientsAPI.createPatientNeed(patient, need);
        const newNeeds = [...this.needs, newNeed.data];
        this.setPatientNeeds(newNeeds);
    }

    @action
    async updatePatientNeed(patient: Patient, patientNeedId: number, need: PatientNeed) {
        const response = await patientsAPI.updatePatientNeed(patient, patientNeedId, need);
        const updatedNeed = response.data;
        const newNeeds = this.needs.map((n: PatientNeed) =>
            n.patientNeedId === updatedNeed.patientNeedId ? updatedNeed : n,
        );
        this.setPatientNeeds(newNeeds);
    }

    @action
    async getPatientNeedStates() {
        const needStates = await patientsAPI.getPatientNeedStates();
        this.setPatientNeedStates(needStates.data || []);
    }

    @action
    setPatientNeedStates(needStates: PatientNeedState[]) {
        this.needStates = needStates;
    }
}

// Import this to any other store that needs to use a value from it
export const PatientNeedStoreObject = new PatientNeedStore();

export default createContext(PatientNeedStoreObject);
