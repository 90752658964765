import React from 'react';
import styles from './styles.module.scss';
import { ANALYTICS_NAMES } from 'src/utils/analytics';
import { getBrandingString } from '../FeatureFlag';

function Footer() {
    return (
        <div className={styles.container}>
            <div className={styles.copyright} data-test-id={ANALYTICS_NAMES.Footer_Copyright}>
                &copy; {`${new Date().getFullYear()} ${getBrandingString()}`}, All rights reserved
            </div>
            <div className={styles.version}>Version {import.meta.env.PACKAGE_VERSION}</div>
        </div>
    );
}

export default Footer;
