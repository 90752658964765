import React from 'react';

const SvgMobilePhone2 = (props) => (
    <svg width={8} height={13} {...props} viewBox="0 0 8 13">
        <path
            d="M6.5 0h-5C.672 0 0 .73 0 1.63v9.74C0 12.27.672 13 1.5 13h5c.828 0 1.5-.73 1.5-1.63V1.63C8 .73 7.328 0 6.5 0zM4 12.185c-.276 0-.5-.243-.5-.543 0-.3.224-.543.5-.543s.5.243.5.543c0 .3-.224.543-.5.543zm3-2.444c0 .3-.224.543-.5.543h-5c-.276 0-.5-.243-.5-.543V1.9c0-.3.224-.543.5-.543h5c.276 0 .5.243.5.543v7.84z"
            fill={props.color || '#FFF'}
            fillRule="nonzero"
        />
    </svg>
);

export default SvgMobilePhone2;
