import React, { useContext } from 'react';
import { ReportingSetting, QcdrSettings, ProviderReportingStatus } from 'common/lib/model/macra/QcdrSettings';
import Flex from '../Flex';
import Checkbox from '../Checkbox';
import Table from '../Table';
import { CellInfo } from 'react-table';
import QcdrSettingsStore from 'src/stores/QcdrSettingsStore';
import { PqrsProvider } from 'common/lib/model/macra/PqrsProvider';
import Card from '../Card';
import styles from './styles.module.scss';

interface Props {
    settings: QcdrSettings['reportingSettings'];
    onSettingChange: (setting: ReportingSetting) => void;
}

function ReportingSettingsStep(props: Props) {
    const qcdrSettingStore = useContext(QcdrSettingsStore);

    return (
        <div>
            <Flex direction="row">
                <Flex value={1}>
                    <h3>STEP 2: REPORTING SETTINGS</h3>
                </Flex>
                <Flex>
                    <h3>Total QCDR Registration Fee: ${qcdrSettingStore.reportingFee}</h3>
                </Flex>
            </Flex>
            {props.settings.map((setting, index) => {
                const tinSettingForThisReportingSetting = qcdrSettingStore.tinSettings.find(
                    (tinSetting) => tinSetting.groupTin === setting.tin,
                );

                if (tinSettingForThisReportingSetting?.submitForFacility) {
                    return (
                        <Card label={`TIN: ${setting.tin}`} key={setting.tin} className={styles.cardWrap}>
                            <Flex direction="column" className={styles.contentWrap}>
                                <Checkbox
                                    data-test-id="GroupReportingChkBox"
                                    checked={setting.reportingType === 'group'}
                                    label="Group Reporting"
                                    onChange={(e) => {
                                        props.onSettingChange({
                                            ...setting,
                                            reportingType: e.target.checked ? 'group' : 'ind',
                                        });
                                    }}
                                />

                                <Table
                                    key={'table_' + index}
                                    isLoading={false}
                                    columns={[
                                        {
                                            Header: 'PROVIDER NAME',
                                            accessor: 'npi',
                                            minWidth: 150,
                                            Cell: (props: CellInfo) =>
                                                qcdrSettingStore.getProviderByNpi(props.value) && (
                                                    <span>
                                                        {
                                                            qcdrSettingStore.getProviderByNpi(props.value)
                                                                .providerLastName
                                                        }
                                                        ,{' '}
                                                        {
                                                            qcdrSettingStore.getProviderByNpi(props.value)
                                                                .providerFirstName
                                                        }
                                                    </span>
                                                ),
                                        },
                                        {
                                            Header: 'NPI',
                                            accessor: 'npi',
                                            minWidth: 150,
                                            Cell: (props: CellInfo) => <span>{props.value}</span>,
                                        },
                                        {
                                            Header: 'TYPE',
                                            accessor: 'npi',
                                            minWidth: 150,
                                            Cell: (props: CellInfo) =>
                                                qcdrSettingStore.getProviderByNpi(props.value) && (
                                                    <span>
                                                        {qcdrSettingStore.getProviderByNpi(props.value).providerType}
                                                    </span>
                                                ),
                                        },
                                        {
                                            Header: 'CASE COUNT',
                                            accessor: 'npi',
                                            Cell: (props: CellInfo) => {
                                                const allProviderInstances = qcdrSettingStore.pqrsProviders
                                                    .filter(
                                                        (provider: PqrsProvider) =>
                                                            provider.providerNpi === props.value,
                                                    )
                                                    .map((provider: PqrsProvider) =>
                                                        provider.providerCaseCount
                                                            ? parseInt(provider.providerCaseCount)
                                                            : 0,
                                                    );
                                                const providerCaseCount = allProviderInstances.reduce(
                                                    (a: number, b: number) => a + b,
                                                    0,
                                                );
                                                return <span>{providerCaseCount}</span>;
                                            },
                                        },
                                        {
                                            Header: 'ENABLE REPORTING',
                                            accessor: 'enableReporting',
                                            headerClassName: styles.alignCenter,
                                            minWidth: 150,
                                            Cell: (cellProps: CellInfo) => (
                                                <Flex direction="column" align="center" className={styles.checkBoxWrap}>
                                                    <Checkbox
                                                        data-test-id="EnableReportingChkBox"
                                                        checked={cellProps.value}
                                                        onChange={(e) => {
                                                            props.onSettingChange({
                                                                ...setting,
                                                                providers: setting.providers.map<
                                                                    ProviderReportingStatus
                                                                >((status) => {
                                                                    return status.npi === cellProps.original.npi
                                                                        ? {
                                                                              ...status,
                                                                              enableReporting: e.target.checked,
                                                                          }
                                                                        : status;
                                                                }),
                                                            });
                                                        }}
                                                    />
                                                </Flex>
                                            ),
                                        },
                                    ]}
                                    data={setting.providers}
                                />
                            </Flex>
                        </Card>
                    );
                }
            })}
        </div>
    );
}

export default ReportingSettingsStep;
