import React from 'react';

const SvgCalendarCheck = (props) => (
    <svg width={17} height={17} {...props} viewBox="0 0 17 17">
        <g fill={props.color || '#FFF'} fillRule="nonzero">
            <path d="M15.23 2.125H13.28a.177.177 0 01-.177-.177V.708a.708.708 0 00-1.416 0v3.365a.531.531 0 11-1.063 0V2.479a.354.354 0 00-.354-.354H5.844a.177.177 0 01-.177-.176V.709a.708.708 0 00-1.417 0v3.364a.531.531 0 01-1.063 0V2.479a.354.354 0 00-.354-.354H1.771c-.783 0-1.417.634-1.417 1.417v12.041C.354 16.366.988 17 1.771 17h13.458c.783 0 1.417-.634 1.417-1.417V3.542c0-.783-.634-1.417-1.417-1.417zm0 13.104a.354.354 0 01-.355.354H2.125a.354.354 0 01-.354-.354v-8.5c0-.195.158-.354.354-.354h12.75c.196 0 .354.159.354.354v8.5z" />
            <path d="M8.5 7.438a3.542 3.542 0 100 7.083 3.542 3.542 0 000-7.084zm2.004 2.833l-1.715 2.287a.975.975 0 01-1.47.103l-.885-.888a.531.531 0 11.752-.751l.814.814 1.653-2.203a.531.531 0 01.85.638z" />
        </g>
    </svg>
);

export default SvgCalendarCheck;
