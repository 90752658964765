import React, { useState, useEffect, useContext } from 'react';
import styles from './styles.module.scss';
import { observer } from 'mobx-react';
import PatientStore, { Patient } from 'src/stores/PatientStore';
import { AccordionItem } from '../Information/index';
import Input from 'src/components/Input';
import Accordion from 'src/components/Accordion';
import Button from 'src/components/Button';
import QuickAction from 'src/components/QuickAction';
import Flex from 'src/components/Flex';
import { ICONS } from 'src/utils/constants';
import { ANALYTICS_NAMES } from 'src/utils/analytics';
import UserStore from 'src/stores/UserStore';

interface InformationProps {
    patient: Patient;
    disableEdit?: boolean;
}
function OtherInfo(props: InformationProps) {
    const [isEditing, setIsEditing] = useState(false);
    const [patient, setPatient] = useState<Patient>({});
    const patientStore = useContext(PatientStore);
    const userStore = useContext(UserStore);

    useEffect(() => {
        resetPatient();
    }, [props.patient]);

    function resetPatient() {
        setPatient({
            primaryDiagnosisNote: props.patient.primaryDiagnosisNote || '',
            allergyNote: props.patient.allergyNote || '',
            pastMedicalHistoryNote: props.patient.pastMedicalHistoryNote || '',
            immunizationHistoryNote: props.patient.immunizationHistoryNote || '',
            familyHistoryNote: props.patient.familyHistoryNote || '',
            socialHistoryNote: props.patient.socialHistoryNote || '',
        });
    }

    const updateInput = (param: string) => (t: string) => setPatient({ ...patient, [param]: t });

    return (
        <Accordion
            label="Other"
            isEditing={isEditing}
            rightAction={
                isEditing ? (
                    <Flex direction="row">
                        <Button
                            onClick={() => {
                                setIsEditing(false);
                                resetPatient();
                            }}
                            className={styles.cancelBtn}
                            text="Cancel"
                            data-test-id={ANALYTICS_NAMES.PatientDetails_OtherInfo_Cancel}
                        />
                        <Button
                            onClick={async () => {
                                setIsEditing(false);
                                await patientStore.updatePatient(props.patient.patientId, patient);
                                await patientStore.getActivePatient(props.patient.patientId);
                            }}
                            className={styles.saveBtn}
                            text="Save"
                            data-test-id={ANALYTICS_NAMES.PatientDetails_OtherInfo_Save}
                        />
                    </Flex>
                ) : props.disableEdit ? null : (
                    <QuickAction
                        className={styles.editIcon}
                        iconName={ICONS.Edit}
                        isLocked={!userStore.userPermissions.canEdit.patientDetails}
                        onClick={() => setIsEditing(true)}
                        data-test-id={ANALYTICS_NAMES.PatientDetails_OtherInfo_Edit}
                    />
                )
            }
            bottomAction={
                isEditing ? (
                    <Flex direction="row" align="center" justify="end" value={1}>
                        <Button
                            onClick={() => {
                                setIsEditing(false);
                                resetPatient();
                            }}
                            className={styles.cancelBtn}
                            text="Cancel"
                            data-test-id={ANALYTICS_NAMES.PatientDetails_OtherInfo_Cancel}
                        />
                        <Button
                            onClick={async () => {
                                setIsEditing(false);
                                await patientStore.updatePatient(props.patient.patientId, patient);
                                await patientStore.getActivePatient(props.patient.patientId);
                            }}
                            className={styles.saveBtn}
                            text="Save"
                            data-test-id={ANALYTICS_NAMES.PatientDetails_OtherInfo_Save}
                        />
                    </Flex>
                ) : null
            }
        >
            <AccordionItem
                label="Primary Diagnosis Note"
                value={
                    isEditing ? (
                        <Input
                            value={patient.primaryDiagnosisNote}
                            onChangeText={updateInput('primaryDiagnosisNote')}
                        />
                    ) : (
                        patient.primaryDiagnosisNote
                    )
                }
            />
            <AccordionItem
                label="Allergy Note"
                value={
                    isEditing ? (
                        <Input value={patient.allergyNote} onChangeText={updateInput('allergyNote')} />
                    ) : (
                        patient.allergyNote
                    )
                }
            />
            <AccordionItem
                label="Past Medical History Note"
                value={
                    isEditing ? (
                        <Input
                            value={patient.pastMedicalHistoryNote}
                            onChangeText={updateInput('pastMedicalHistoryNote')}
                        />
                    ) : (
                        patient.pastMedicalHistoryNote
                    )
                }
            />
            <AccordionItem
                label="Immunization History Note"
                value={
                    isEditing ? (
                        <Input
                            value={patient.immunizationHistoryNote}
                            onChangeText={updateInput('immunizationHistoryNote')}
                        />
                    ) : (
                        patient.immunizationHistoryNote
                    )
                }
            />
            <AccordionItem
                label="Family History Note"
                value={
                    isEditing ? (
                        <Input value={patient.familyHistoryNote} onChangeText={updateInput('familyHistoryNote')} />
                    ) : (
                        patient.familyHistoryNote
                    )
                }
            />
            <AccordionItem
                label="Social History Note"
                value={
                    isEditing ? (
                        <Input value={patient.socialHistoryNote} onChangeText={updateInput('socialHistoryNote')} />
                    ) : (
                        patient.socialHistoryNote
                    )
                }
            />
        </Accordion>
    );
}

export default observer(OtherInfo);
