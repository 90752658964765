import React, { useState, useEffect } from 'react';
import styles from './styles.module.scss';
import * as variables from 'src/styles/variables';
import { FormUtils } from 'common/lib/form-generation/util/FormUtils';
import { ModelPropertyUpdate } from 'common/lib/model/model/ModelPropertyUpdate';
import { Container, Row, Col } from 'react-grid-system';
import { ValidationUtil } from 'common/lib/form-generation/util/ValidationUtil';
import Flex from 'src/components/Flex';
import Icon from 'src/components/Icon';
import { ICONS } from 'src/utils/constants';

import InputGroup from './inputs/InputGroup';
import DateTime from './inputs/DateTime';
import List from './inputs/List';
import NumberPad from './inputs/NumberPad';
import TextInput from './inputs/TextInput';
import YesNo from './inputs/YesNo';
import TimeInput from './inputs/TimeInput';
import DateInput from './inputs/DateInput';
import Camera from './inputs/Camera';
import FileInput from './inputs/FileInput';

interface FormProps {
    page: number;
    title: string;
    inputs: any;
    model: any;
    formUtils: FormUtils;
    facId: number;
    validationUtil: ValidationUtil;
    validationData: { [key: string]: boolean };
    valueSets: any;
    onChange: Function;
    onDownload: Function;
}

interface IndexReplaceObject {
    parentReplaceData?: IndexReplaceObject;
    indexName: string;
    index: number;
}

interface ValueChange {
    input: any;
    value: any;
    options?: {
        item?: { [key: string]: string | number };
        isNotValueChange?: boolean;
        extendedData?: { [key: string]: string | number };
    };
}

function useForceUpdate() {
    const [value, setValue] = useState(0);
    return () => setValue((value) => value + 1);
}

function DiscreteForm(props: FormProps) {
    const [modelPropertyUpdates, setModelPropertyUpdates] = useState<ModelPropertyUpdate[]>([]);
    const forceUpdate = useForceUpdate();
    let replaceObjects: IndexReplaceObject[] = [];

    useEffect(() => {
        props.onChange(modelPropertyUpdates);
    }, [modelPropertyUpdates]);

    function generateModelPropertyUpdate(params: {
        pageId: number;
        propertyType: string;
        propertyObject: any;
        value: any;
        inputName?: string;
        initializeAsEmptyArray?: boolean;
        isNotValueChange?: boolean;
    }) {
        // Create blank array to store updates
        let newUpdates: ModelPropertyUpdate[] = [];

        let {
            propertyType,
            propertyObject,
            value,
            inputName,
            pageId,
            initializeAsEmptyArray,
            isNotValueChange,
        } = params;

        // Get property object values
        const {
            jsonPointer,
            jsonPropertyType,
            jsonIndex,
            modelProperty,
            timeModelProperty,
            timeJsonIndex,
            timeJsonPointer,
            timeJsonPropertyType,
            toModelProperty,
            toJsonPointer,
            toJsonPropertyType,
            toJsonIndex,
            sequenceNumber,
            notValueModelProperty,
            notValueJsonPointer,
            notValueJsonIndex,
            fileType,
        } = propertyObject;

        if (jsonPropertyType && jsonPropertyType === 'NUMBER' && !isNotValueChange) {
            value = parseFloat(value);
        }

        // Generate field value update
        const modelPropertyUpdate: ModelPropertyUpdate = Object.assign({
            pageId,
            propertyName:
                isNotValueChange && notValueModelProperty ? notValueModelProperty : modelProperty || toModelProperty,
            fieldValue: value && value.date ? value.date : value,
            formValid: props.validationUtil.getFormCompletionPercentage() === 1,
            percentComplete: props.validationUtil.getFormCompletionPercentage(),
            ...(sequenceNumber ? { propertySequence: sequenceNumber } : {}),
            ...(jsonPointer || notValueJsonPointer
                ? {
                      jsonPointer: isNotValueChange && notValueJsonPointer ? notValueJsonPointer : jsonPointer,
                  }
                : {}),
            ...(jsonPropertyType || isNotValueChange
                ? {
                      jsonPropertyType: isNotValueChange ? 'STRING' : jsonPropertyType,
                  }
                : {}),
            ...(jsonIndex || notValueJsonIndex
                ? {
                      jsonIndex: isNotValueChange && notValueJsonIndex ? notValueJsonIndex : jsonIndex,
                  }
                : {}),
            ...(toJsonPointer ? { jsonPointer: toJsonPointer } : {}),
            ...(toJsonPropertyType ? { jsonPropertyType: toJsonPropertyType } : {}),
            ...(toJsonIndex ? { jsonIndex: toJsonIndex } : {}),
            ...(inputName ? { inputName } : {}),
            ...(initializeAsEmptyArray ? { initializeAsEmptyArray } : {}),
            ...(fileType ? { fileType } : {}),
        });

        // Add field value to array
        if (modelPropertyUpdate.fieldValue !== undefined) newUpdates.push(modelPropertyUpdate);

        // If it's a dateTime we add a separate property for time
        if (propertyType === 'dateTime' && timeModelProperty) {
            // Generate field value update
            const modelPropertyUpdate: ModelPropertyUpdate = Object.assign({
                propertyName: timeModelProperty,
                fieldValue: value.time,
                formValid: true,
                percentComplete: 1,
                ...(timeJsonPointer ? { jsonPointer: timeJsonPointer } : {}),
                ...(timeJsonPropertyType ? { jsonPropertyType: timeJsonPropertyType } : {}),
                ...(timeJsonIndex ? { jsonIndex: timeJsonIndex } : {}),
                ...(inputName ? { inputName } : {}),
            });

            // Add field value to array
            if (modelPropertyUpdate.fieldValue !== undefined) newUpdates.push(modelPropertyUpdate);
        }

        return newUpdates;
    }

    function registerValueChange(changes: ValueChange[]) {
        let newUpdates: ModelPropertyUpdate[] = [];
        const pageId = props.formUtils.getOrderedPages()[props.page].pageId;

        changes.forEach((change: ValueChange) => {
            const { input, value, options } = change;
            const { item, isNotValueChange, extendedData } = options || ({} as any);
            const inputMethod = input.inputMethod;
            // Check if it has a grid, if so we are updating an entire array for an input group
            const propertyType = input.grid ? 'JSON_ARRAY' : Object.keys(inputMethod[0])[0];
            const propertyObject = input.grid
                ? Object.assign(input.$, { jsonPropertyType: 'JSON_ARRAY' })
                : JSON.parse(JSON.stringify(inputMethod[0][propertyType][0].$));

            const updatedPropertyObject = extendedData
                ? Object.assign(replaceVariablesInInput(propertyObject), {
                      ...extendedData,
                  })
                : replaceVariablesInInput(propertyObject);

            const inputName = input.$.name;

            newUpdates.push(
                ...generateModelPropertyUpdate({
                    pageId: Number(pageId),
                    propertyType,
                    propertyObject: updatedPropertyObject,
                    value,
                    inputName,
                    ...(input.grid || (extendedData && extendedData.initializeAsEmptyArray)
                        ? {
                              initializeAsEmptyArray: extendedData ? extendedData.initializeAsEmptyArray : '/',
                          }
                        : null),
                    isNotValueChange,
                }),
            );

            // Loop through bindings to add values
            const bindings = inputMethod && inputMethod[0][propertyType][0].bindings;

            if (bindings) {
                bindings.forEach((binding: any) => {
                    const bindingName = Object.keys(binding)[0];
                    switch (bindingName) {
                        case 'expressionBinding':
                            const bindingsToSet = binding[bindingName];
                            bindingsToSet.forEach((bindingToSet: any) => {
                                const replacedData = Object.assign(
                                    replaceVariablesInInput(JSON.parse(JSON.stringify(bindingToSet.$))),
                                    {
                                        ...(propertyObject.jsonIndex
                                            ? {
                                                  toJsonIndex: propertyObject.jsonIndex,
                                              }
                                            : null),
                                    },
                                );

                                const expressionValue = props.formUtils.evaluateFormDefinitionExpression(
                                    pageId,
                                    replacedData.expression,
                                    item,
                                );

                                const bindingExtendedData =
                                    extendedData && replacedData.toPersistedJsonPropertyName
                                        ? Object.assign(extendedData, {
                                              ...(extendedData.jsonPointer
                                                  ? {
                                                        jsonPointer: `/${replacedData.toPersistedJsonPropertyName}`,
                                                    }
                                                  : null),
                                              ...(extendedData.toJsonPointer
                                                  ? {
                                                        toJsonPointer: extendedData.toJsonPointer.replace(
                                                            updatedPropertyObject.persistedJsonPropertyName,
                                                            replacedData.toPersistedJsonPropertyName,
                                                        ),
                                                    }
                                                  : null),
                                          })
                                        : {};

                                const updateValues = Object.assign({
                                    pageId: Number(pageId),
                                    propertyType: propertyType,
                                    propertyObject: extendedData
                                        ? Object.assign(replacedData, {
                                              ...bindingExtendedData,
                                          })
                                        : replacedData,
                                    value: value !== null ? expressionValue : value,
                                });
                                newUpdates.push(...generateModelPropertyUpdate(updateValues));
                            });
                            break;
                    }
                });
            }
        });

        setModelPropertyUpdates([...modelPropertyUpdates, ...newUpdates]);
    }

    function getInputValue(inputMethodData: any, isTime?: boolean) {
        const { model } = props;

        return props.formUtils.getModelPropertyValue(inputMethodData, model, isTime);
    }

    function replaceVariablesInInput(inputData: any) {
        for (const replaceObject of replaceObjects) {
            if (replaceObject.indexName && replaceObject.index >= 0) {
                inputData = Object.assign(inputData, {
                    ...(inputData.jsonIndex
                        ? {
                              jsonIndex: inputData.jsonIndex.replace(
                                  `{${replaceObject.indexName}}`,
                                  replaceObject.index.toString(),
                              ),
                          }
                        : null),
                    ...(inputData.toJsonIndex
                        ? {
                              toJsonIndex: inputData.toJsonIndex.replace(
                                  `{${replaceObject.indexName}}`,
                                  replaceObject.index.toString(),
                              ),
                          }
                        : null),
                    ...(inputData.timeJsonIndex
                        ? {
                              timeJsonIndex: inputData.timeJsonIndex.replace(
                                  `{${replaceObject.indexName}}`,
                                  replaceObject.index.toString(),
                              ),
                          }
                        : null),
                    ...(inputData.notValueJsonIndex
                        ? {
                              notValueJsonIndex: inputData.notValueJsonIndex.replace(
                                  `{${replaceObject.indexName}}`,
                                  replaceObject.index.toString(),
                              ),
                          }
                        : null),
                    ...(inputData.jsonPointer
                        ? {
                              jsonPointer: inputData.jsonPointer.replace(
                                  `{${replaceObject.indexName}}`,
                                  replaceObject.index.toString(),
                              ),
                          }
                        : null),
                    ...(inputData.toJsonPointer
                        ? {
                              toJsonPointer: inputData.toJsonPointer.replace(
                                  `{${replaceObject.indexName}}`,
                                  replaceObject.index.toString(),
                              ),
                          }
                        : null),
                    ...(inputData.timeJsonPointer
                        ? {
                              timeJsonPointer: inputData.timeJsonPointer.replace(
                                  `{${replaceObject.indexName}}`,
                                  replaceObject.index.toString(),
                              ),
                          }
                        : null),
                    ...(inputData.notValueJsonPointer
                        ? {
                              notValueJsonPointer: inputData.notValueJsonPointer.replace(
                                  `{${replaceObject.indexName}}`,
                                  replaceObject.index.toString(),
                              ),
                          }
                        : null),
                });
            }
        }

        return inputData;
    }

    function renderInput(input: any, index: number, visibleProperty: string) {
        const inputMethods = input.inputMethod;
        if (inputMethods) {
            const inputMethod = Object.keys(inputMethods[0])[0];
            const tempData = inputMethods[0][inputMethod][0].$;
            const inputMethodData = replaceVariablesInInput(tempData);

            if (!inputMethodData) return;

            const currentValue = getInputValue(inputMethodData) || '';
            const enableNotValues = inputMethodData.enableNotValues;
            const notValue = enableNotValues
                ? getInputValue(
                      Object.assign(JSON.parse(JSON.stringify(inputMethodData)), {
                          modelProperty: inputMethodData.notValueModelProperty,
                          jsonIndex: inputMethodData.notValueJsonIndex,
                          jsonPointer: inputMethodData.notValueJsonPointer,
                      }),
                  ) || ''
                : '';
            const modelProperty = inputMethodData.modelProperty;
            const inputName = input.$.name;
            const isValid = props.validationData.hasOwnProperty(inputName) ? props.validationData[inputName] : true;

            switch (inputMethod) {
                case 'textInput':
                    return (
                        <TextInput
                            input={input}
                            key={index}
                            value={currentValue}
                            notValue={notValue}
                            isValid={isValid}
                            enableNotValues={enableNotValues}
                            onChange={(value: string) => registerValueChange([{ input, value }])}
                            onNaChange={(value: string) =>
                                registerValueChange([
                                    {
                                        input,
                                        value,
                                        options: {
                                            isNotValueChange: true,
                                        },
                                    },
                                ])
                            }
                        />
                    );

                case 'numberPad':
                    return (
                        <NumberPad
                            input={input}
                            key={index}
                            value={currentValue}
                            notValue={notValue}
                            isValid={isValid}
                            enableNotValues={enableNotValues}
                            onChange={(value: string) => registerValueChange([{ input, value }])}
                            onNaChange={(value: string) =>
                                registerValueChange([
                                    {
                                        input,
                                        value,
                                        options: {
                                            isNotValueChange: true,
                                        },
                                    },
                                ])
                            }
                        />
                    );

                case 'dateTime':
                    const timeValue = getInputValue(inputMethodData, true);
                    return (
                        <DateTime
                            input={input}
                            isValid={isValid}
                            key={index}
                            dateValue={currentValue}
                            timeValue={timeValue}
                            notValue={notValue}
                            enableNotValues={enableNotValues}
                            onChange={(value: { date: string; time: string }) =>
                                registerValueChange([{ input, value }])
                            }
                            onNaChange={(value: string) =>
                                registerValueChange([
                                    {
                                        input,
                                        value,
                                        options: {
                                            isNotValueChange: true,
                                        },
                                    },
                                ])
                            }
                        />
                    );

                case 'date':
                    return (
                        <DateInput
                            input={input}
                            isValid={isValid}
                            key={index}
                            value={currentValue}
                            notValue={notValue}
                            enableNotValues={enableNotValues}
                            onChange={(value: { date: string; time: string }) =>
                                registerValueChange([{ input, value }])
                            }
                            onNaChange={(value: string) =>
                                registerValueChange([
                                    {
                                        input,
                                        value,
                                        options: {
                                            isNotValueChange: true,
                                        },
                                    },
                                ])
                            }
                        />
                    );

                case 'time':
                    return (
                        <TimeInput
                            input={input}
                            isValid={isValid}
                            key={index}
                            value={currentValue}
                            notValue={notValue}
                            enableNotValues={enableNotValues}
                            onChange={(value: { date: string; time: string }) =>
                                registerValueChange([{ input, value }])
                            }
                            onNaChange={(value: string) =>
                                registerValueChange([
                                    {
                                        input,
                                        value,
                                        options: {
                                            isNotValueChange: true,
                                        },
                                    },
                                ])
                            }
                        />
                    );

                case 'yesNo':
                    return (
                        <YesNo
                            input={input}
                            isValid={isValid}
                            key={index}
                            value={currentValue}
                            onChange={(value: string) => registerValueChange([{ input, value }])}
                        />
                    );

                case 'list':
                    return (
                        <List
                            input={input}
                            key={index}
                            value={currentValue}
                            notValue={notValue}
                            enableNotValues={enableNotValues}
                            isMulti={inputMethodData.multiSelectEnabled === 'true'}
                            visibleProperty={visibleProperty}
                            onChange={(value: { label: string; value: any; item: any }, item: any) => {
                                if (inputMethodData.multiSelectEnabled === 'true' && Array.isArray(value)) {
                                    if (value.length === 0) {
                                        registerValueChange([
                                            {
                                                input,
                                                value: null,
                                                options: {
                                                    item: {},
                                                },
                                            },
                                        ]);
                                        return;
                                    }

                                    value.forEach((valueItem: any, index: number) => {
                                        let jsonData = {};
                                        if (inputMethodData.jsonIndex && inputMethodData.jsonPointer) {
                                            jsonData = Object.assign({
                                                ...(index === 0
                                                    ? {
                                                          initializeAsEmptyArray: `${inputMethodData.jsonPointer}`,
                                                      }
                                                    : null),
                                                toJsonPointer: `${inputMethodData.jsonPointer}/${index}/${inputMethodData.persistedJsonPropertyName}`,
                                                jsonPropertyType: 'STRING',
                                            });
                                        } else {
                                            jsonData = Object.assign({
                                                ...(index === 0
                                                    ? {
                                                          initializeAsEmptyArray: '/',
                                                      }
                                                    : null),
                                                jsonIndex: index.toString(),
                                                jsonPointer: `/${inputMethodData.persistedJsonPropertyName}`,
                                                jsonPropertyType: 'STRING',
                                            });
                                        }

                                        registerValueChange([
                                            {
                                                input,
                                                value: valueItem.value,
                                                options: {
                                                    item: valueItem.item,
                                                    extendedData: jsonData,
                                                },
                                            },
                                        ]);
                                    });
                                } else {
                                    registerValueChange([
                                        {
                                            input,
                                            value: value && value.value ? value.value : value,
                                            options: { item: value ? value.item : {} },
                                        },
                                    ]);
                                }
                            }}
                            onNaChange={(value: string) =>
                                registerValueChange([
                                    {
                                        input,
                                        value,
                                        options: {
                                            isNotValueChange: true,
                                        },
                                    },
                                ])
                            }
                            isValid={isValid}
                            facId={props.facId}
                            formUtils={props.formUtils}
                            valueSets={props.valueSets}
                        />
                    );

                case 'camera':
                    const pageId = props.formUtils.getOrderedPages()[props.page].pageId;
                    const bitmapValue = props.formUtils.getBitmapPropertyValue(
                        pageId,
                        modelProperty,
                        inputMethodData.sequenceNumber,
                    );

                    return (
                        <Camera
                            key={index}
                            input={input}
                            value={bitmapValue}
                            onChange={(value: string) => registerValueChange([{ input, value }])}
                        />
                    );

                case 'file':
                    return (
                        <FileInput
                            key={index}
                            input={input}
                            value={currentValue}
                            acceptTypes={inputMethodData.acceptTypes}
                            onSave={(blobId: string, fileName: string, type: string) => {
                                const actualBlobId = blobId.match(/([^\/]*)\/*$/)[1];
                                props.onDownload(actualBlobId, fileName, type);
                            }}
                            onChange={(valueObject: any) => {
                                const { fileName, fileSize, type, blobId } = valueObject;

                                const updates = [];

                                if (fileName) {
                                    updates.push({
                                        input,
                                        value: fileName,
                                        options: {
                                            extendedData: {
                                                jsonPointer: `${inputMethodData.jsonPointer}/fileName`,
                                                jsonPropertyType: 'STRING',
                                            },
                                        },
                                    });
                                }

                                if (fileSize) {
                                    updates.push({
                                        input,
                                        value: fileSize,
                                        options: {
                                            extendedData: {
                                                jsonPointer: `${inputMethodData.jsonPointer}/fileSize`,
                                                jsonPropertyType: 'NUMBER',
                                            },
                                        },
                                    });
                                }

                                if (type) {
                                    updates.push({
                                        input,
                                        value: type,
                                        options: {
                                            extendedData: {
                                                jsonPointer: `${inputMethodData.jsonPointer}/type`,
                                                jsonPropertyType: 'STRING',
                                            },
                                        },
                                    });
                                }

                                if (blobId) {
                                    updates.push({
                                        input,
                                        value: blobId,
                                        options: {
                                            extendedData: {
                                                jsonPointer: `${inputMethodData.jsonPointer}/blobId`,
                                                jsonPropertyType: 'BLOB',
                                                fileType: type,
                                            },
                                        },
                                    });
                                }

                                registerValueChange(updates);
                            }}
                        />
                    );
            }
        }
    }

    function handleAdd(inputGroup: any) {
        const inputGroupData = getInputValue(inputGroup) || [];
        inputGroupData.push({});
        forceUpdate();
    }

    function handleDelete(inputGroup: any, index: number) {
        const inputGroupData = getInputValue(inputGroup.$) || [];
        inputGroupData.splice(index, 1);
        registerValueChange([{ input: inputGroup, value: inputGroupData }]);
        forceUpdate();
    }

    function renderInputGroups(inputGroup: any, isRepeating: boolean) {
        const pageId = props.formUtils.getOrderedPages()[props.page].pageId;

        return inputGroup.map((inputGroup: any, groupIndex: number) => {
            const value =
                inputGroup && inputGroup.title
                    ? props.formUtils.evaluateFormDefinitionExpression(pageId, inputGroup.title[0])
                    : inputGroup.subtitle &&
                      props.formUtils.evaluateFormDefinitionExpression(pageId, inputGroup.subtitle[0])
                    ? inputGroup.subtitle
                    : '';

            const parsedInputGroupData = replaceVariablesInInput(JSON.parse(JSON.stringify(inputGroup.$)));
            let inputGroupData = getInputValue(parsedInputGroupData) || [{}];

            let inputs;
            if (inputGroup.input) {
                inputs = inputGroup.input.map((input: any, inputIndex: number) => {
                    const isVisible = props.formUtils.getRendererVisibility(input, pageId);
                    return isVisible ? renderInput(input, inputIndex, isVisible) : null;
                });
            } else if (inputGroup.grid) {
                const indexNameToReplace = inputGroup.$.repeatingIndexName;

                inputs = inputGroupData.map((_: any, repeatableIndex: number) => {
                    replaceObjects = replaceObjects.filter(
                        (ro: IndexReplaceObject) => ro.indexName !== indexNameToReplace,
                    );
                    replaceObjects.push({
                        indexName: indexNameToReplace,
                        index: repeatableIndex,
                    });

                    return (
                        <div className={styles.gridContainer} key={repeatableIndex}>
                            {isRepeating ? (
                                <Flex className={styles.repeatableHeader}>
                                    {inputGroup.groupItemNameSingular ? (
                                        <div className={styles.repeatableTitle}>{`${
                                            inputGroup.groupItemNameSingular[0]
                                        } ${repeatableIndex + 1}`}</div>
                                    ) : null}
                                    {inputGroupData.length > 1 ? (
                                        <div
                                            className={styles.repeatableDelete}
                                            onClick={() => handleDelete(inputGroup, repeatableIndex)}
                                        >
                                            <Icon name={ICONS.Close} size={10} color={variables.darkBlue} />
                                        </div>
                                    ) : null}
                                </Flex>
                            ) : null}
                            {renderGrid(inputGroup.grid)}
                        </div>
                    );
                });
            }
            return (
                <InputGroup
                    inputGroup={inputGroup}
                    inputs={inputs}
                    value={value}
                    key={groupIndex}
                    isRepeating={isRepeating}
                    isGrid={inputGroup.grid ? true : false}
                    onAdd={isRepeating ? () => handleAdd(parsedInputGroupData) : null}
                />
            );
        });
    }

    function renderGrid(grid: any) {
        const pageId = props.formUtils.getOrderedPages()[props.page].pageId;
        const rows = grid[0].row;

        if (!rows) return;

        return (
            <div className={styles.repeatableContainer}>
                <Container>
                    {rows.map((row: any, rowIndex: number) => (
                        <Row key={rowIndex}>
                            {row.column.map((column: any, inputIndex: any) => {
                                const columnWidth = column.$ && column.$.lg ? parseInt(column.$.lg) : 12;

                                if (column.input) {
                                    // This is a hack to deep copy the object. Can't use circular references here (Should never need to).
                                    const input = JSON.parse(JSON.stringify(column.input[0]));
                                    const isVisible = props.formUtils.getRendererVisibility(input, pageId);
                                    // Get rid of the outer padding for the grid. No setting in the library would do this.
                                    const columnStyle = Object.assign(
                                        { paddingLeft: 0 },
                                        {
                                            ...(inputIndex === row.column.length - 1 ? { paddingRight: 0 } : null),
                                        },
                                    );
                                    return (
                                        <Col sm={columnWidth} style={columnStyle} key={inputIndex}>
                                            {isVisible ? renderInput(input, inputIndex, isVisible) : null}
                                        </Col>
                                    );
                                }

                                if (column.inputGroup || column.repeatableInputGroup) {
                                    return renderInputGroups(
                                        column.inputGroup || column.repeatableInputGroup,
                                        column.repeatableInputGroup ? true : false,
                                    );
                                }
                            })}
                        </Row>
                    ))}
                </Container>
            </div>
        );
    }

    function renderInputs(section: any) {
        const pageId = props.formUtils.getOrderedPages()[props.page].pageId;

        return (
            <div>
                {section.input &&
                    section.input.map((input: any, index: number) => {
                        const isVisible = props.formUtils.getRendererVisibility(input, pageId);

                        return isVisible ? renderInput(input, index, isVisible) : null;
                    })}
                {section.inputGroup || section.repeatableInputGroup
                    ? renderInputGroups(
                          section.inputGroup || section.repeatableInputGroup,
                          section.repeatableInputGroup ? true : false,
                      )
                    : null}
                {section.grid ? renderGrid(section.grid) : null}
            </div>
        );
    }

    function renderForm() {
        const sections = props.inputs[props.page].discreteInputs[0].section;
        return (
            <div className={styles.formWrap}>
                {sections.map((section: any, index: number) => {
                    return (
                        <div key={index} className={styles.contentWrap}>
                            {renderInputs(section)}
                        </div>
                    );
                })}
            </div>
        );
    }

    return <div>{renderForm()}</div>;
}

export default DiscreteForm;
