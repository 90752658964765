import React from 'react';

const SvgClose = (props) => (
    <svg width={16} height={16} {...props} viewBox="0 0 16 16">
        <g stroke={props.color || '#FFF'} strokeWidth={2} fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
            <path d="M1.292 1.291l13.416 13.417M14.708 1.291L1.292 14.708" />
        </g>
    </svg>
);

export default SvgClose;
