import React, { useState, useContext } from 'react';
import styles from './styles.module.scss';
import Button from 'src/components/Button';
import { ANALYTICS_NAMES } from 'src/utils/analytics';
import Flex from 'src/components/Flex';
import * as telehealthApi from 'src/api/telehealth';
import DateComponent from '../DateComponent';
import Table from '../Table';
import UserStore from 'src/stores/UserStore';
import { CellInfo } from 'react-table';
import ActionMenu from '../ActionMenu';
import { ModalStoreObject, ModalTypes } from 'src/stores/ModalStore';
import EncounterStore from 'src/stores/EncounterStore';
import { Patient } from 'src/stores/PatientStore';
import { getPatientName, getTelehealthStatusExplanation, parseError } from 'src/utils';
import { ROUTES } from 'src/utils/constants';
import { useHistory } from 'react-router-dom';
import { TelehealthSession, SessionStateCodes, EncounterForm, Encounter } from 'src/utils/types';
import PercentageBar from '../PercentageBar';
import { getUnvoidedFormActionsGroupName } from 'src/utils/actions';
import { ToastStoreObject, ToastType } from 'src/stores/ToastStore';

interface EncounterItemProps {
    encounter: Encounter;
    isShowVoidedFormsChecked?: boolean;
    patient?: Patient;
    onSave?: Function;
    opensFormInline?: boolean;
    onAddForm?: Function;
    onEditForm?: Function;
}

const EncounterItem: React.SFC<EncounterItemProps> = ({
    encounter,
    patient,
    isShowVoidedFormsChecked,
    onSave,
    opensFormInline,
    onAddForm,
    onEditForm,
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const userStore = useContext(UserStore);
    const history = useHistory();

    const encounterStore = useContext(EncounterStore);
    const telehealthSession: TelehealthSession[] = encounter.telehealthSession || [];

    const formColumns = [
        {
            Header: 'FORM NAME',
            accessor: 'formDefinitionHistory.formDefnTitle',
            minWidth: 150,
        },
        {
            Header: 'LAST UPDATED',
            accessor: 'updDttm',
            minWidth: 100,
            Cell: (props: CellInfo) => <DateComponent date={props.value} showDateTime={true} />,
        },
        {
            Header: 'FORM COMPLETION',
            accessor: 'formCmpltPct',
            minWidth: 100,
            Cell: (props: CellInfo) => <PercentageBar value={props.value} />,
        },
        {
            Header: 'ACTIONS',
            accessor: 'enctrFormId',
            minWidth: 100,
            style: { paddingVertical: 0, paddingHorizontal: 20 },
            Cell: (props: CellInfo) => (
                <ActionMenu
                    entityType={
                        props.original.voidInd
                            ? 'patientVisitsVoided'
                            : getUnvoidedFormActionsGroupName(props.original.formDefinitionHistory.formDefnTyp)
                    }
                    entity={props.original}
                    onItemClick={(action: { id: string; label: string }) => handleActionClick(action, props.original)}
                />
            ),
        },
    ];

    const telehealthColumns = [
        {
            Header: 'STATUS',
            accessor: 'telehealthSessionState',
            minWidth: 160,
            Cell: (props: CellInfo) => {
                return (
                    <span>
                        {getTelehealthStatusExplanation(
                            (props.value || {}).telehealthSessionStateCode,
                            props.original.telehealthSessionDetail,
                        )}
                    </span>
                );
            },
        },
        {
            Header: 'CREATED ON',
            accessor: 'createTime',
            minWidth: 125,
            Cell: (props: CellInfo) => <DateComponent date={props.value} showDateTime={true} />,
        },
        {
            Header: 'CANCELLATION REASON',
            accessor: 'telehealthSessionCancellationReason',
            minWidth: 250,
            style: { whiteSpace: 'unset' },
            Cell: (props: CellInfo) => <div style={{ padding: '10px 0' }}>{props.value}</div>,
        },

        {
            Header: 'NOTES',
            accessor: 'respondingUserNote',
            minWidth: 250,
            style: { whiteSpace: 'unset' },
            Cell: (props: CellInfo) => <div style={{ padding: '10px 0' }}>{props.value}</div>,
        },
        {
            Header: 'ACTIONS',
            accessor: 'telehealthSessionState',
            minWidth: 125,
            style: { paddingVertical: 0, paddingHorizontal: 20 },
            Cell: (props: CellInfo) => {
                const isJoinable =
                    props.value.telehealthSessionStateCode === SessionStateCodes.CONNECTION_PENDING ||
                    props.value.telehealthSessionStateCode === SessionStateCodes.CONNECTION_INTERRUPTED ||
                    props.value.telehealthSessionStateCode === SessionStateCodes.SESSION_CREATED;
                const telehealthRecording =
                    props.original && props.original.telehealthSessionDetail
                        ? props.original.telehealthSessionDetail.archiveId
                        : null;
                return (
                    <Flex value={2} justify="end" align="center">
                        {isJoinable ? (
                            <Button
                                type="outline"
                                onClick={() => {
                                    history.push(
                                        ROUTES.getString(ROUTES.TelehealthSession, props.original.telehealthSessionId),
                                    );
                                }}
                                text="Join Call"
                                className={styles.addFormButton}
                            />
                        ) : telehealthRecording ? (
                            <Button
                                type="primary"
                                onClick={() => {
                                    handleWatchSession(props.original);
                                }}
                                text="Watch Call"
                                className={styles.addFormButton}
                            />
                        ) : null}
                    </Flex>
                );
            },
        },
    ];

    async function changeVoidEncounterForm(form: EncounterForm, voidInd: boolean) {
        try {
            setIsLoading(true);
            const updateData = { voidInd };
            await encounterStore.updateEncounterForm(form.enctrFormId, updateData);
            ModalStoreObject.hideModal();
            ToastStoreObject.show(
                `${form.formDefinitionHistory.formDefnTitle} form was ${voidInd ? 'voided' : 'unvoided'}.`,
                ToastType.Success,
            );
        } catch (e) {
            ToastStoreObject.show(parseError(e), ToastType.Error);
        } finally {
            setIsLoading(false);
            ModalStoreObject.hideModal();
        }
    }

    const handleActionClick = (action: { id: string; label: string }, form: EncounterForm) => {
        switch (action.id) {
            case 'details':
                history.push(
                    ROUTES.getString(ROUTES.ViewEncounterForm, null, {
                        encounterFormId: form.enctrFormId,
                        facId: encounter.facId,
                    }),
                );
                break;

            case 'edit':
                if (opensFormInline) {
                    onEditForm({ enctrId: encounter.enctrId, facId: encounter.facId, enctrFormId: form.enctrFormId });
                } else {
                    ModalStoreObject.showModal(ModalTypes.EditEncounterForm, {
                        onClose: () => {
                            ModalStoreObject.hideModal();
                            onSave();
                        },
                        enctrId: encounter.enctrId,
                        facId: encounter.facId,
                        enctrFormId: form.enctrFormId,
                    });
                }
                break;

            case 'void':
                ModalStoreObject.showModal(ModalTypes.ConfirmationModal, {
                    title: 'Are you sure you want to void this form?',
                    onConfirm: () => changeVoidEncounterForm(form, true),
                    onCancel: () => ModalStoreObject.hideModal(),
                    confirmButtonText: 'Yes, Void',
                    cancelButtonText: 'No',
                });
                break;

            case 'unvoid':
                ModalStoreObject.showModal(ModalTypes.ConfirmationModal, {
                    title: 'Are you sure you want to unvoid this form?',
                    onConfirm: () => changeVoidEncounterForm(form, false),
                    onCancel: () => ModalStoreObject.hideModal(),
                    confirmButtonText: 'Yes, Unvoid',
                    cancelButtonText: 'No',
                });
                break;
        }
    };

    function openEncounterModal() {
        ModalStoreObject.showModal(ModalTypes.Encounter, {
            onClose: () => {
                ModalStoreObject.hideModal();
            },
            title: `Edit Encounter${patient ? ` for ${getPatientName(patient)}` : ''}`,
            encounter,
            isOpen: true,
            onSave: async () => {
                ModalStoreObject.hideModal();
                onSave();
            },
            patientId: patient ? patient.patientId : null,
            facId: patient ? patient.facId : null,
            patient: patient,
        });
    }

    async function handleWatchSession(session: TelehealthSession) {
        const results = await telehealthApi.getTelehealthArchiveUrl(session.telehealthSessionId);
        ModalStoreObject.showModal(ModalTypes.Video, {
            onClose: () => ModalStoreObject.hideModal(),
            url: results.data.url,
        });
    }

    function openAddFormModal() {
        ModalStoreObject.showModal(ModalTypes.AddEncounterForm, {
            title: 'Add Form',
            onClose: () => {
                ModalStoreObject.hideModal();
                onSave();
            },
            enctrId: encounter.enctrId,
            facId: encounter.facId,
            confirmButtonText: 'Add Form',
            cancelButtonText: 'Cancel',
            patientId: patient ? patient.patientId : null,
        });
    }

    return (
        <div className={styles.tableWrap} key={encounter.enctrId}>
            <Flex direction="row">
                <Flex>
                    <div className={styles.tableTitle}>Encounter #: {encounter.enctrNo}</div>
                    <div className={styles.tableTitle}>
                        Created on: <DateComponent date={encounter.insDttm} />
                    </div>
                    {encounter.admitDt ? (
                        <div className={styles.tableTitle}>
                            Encounter Date: <DateComponent date={encounter.admitDt} />
                        </div>
                    ) : null}
                </Flex>
                <Flex value={2} justify="end" align="center">
                    <Button
                        type="outline"
                        onClick={() => openEncounterModal()}
                        text="Edit Info"
                        isLocked={!userStore.userPermissions.canEdit.patientEncounters}
                        className={styles.editButton}
                        data-test-id={ANALYTICS_NAMES.PatientDetails_Visits_Edit}
                    />
                    <Button
                        type="primary"
                        onClick={() => (opensFormInline ? onAddForm() : openAddFormModal())}
                        text="Add Form"
                        className={styles.addFormButton}
                        isLocked={!userStore.userPermissions.canEdit.patientEncounters}
                        data-test-id={ANALYTICS_NAMES.PatientDetails_Visits_AddForm}
                    />
                </Flex>
            </Flex>
            {telehealthSession.length > 0 ? (
                <Flex direction="column">
                    <div className={styles.tableTitle}>Telehealth Visits</div>
                    <Table columns={telehealthColumns} data={telehealthSession} isLoading={isLoading} />
                </Flex>
            ) : null}
            <Flex direction="column">
                <div className={styles.tableTitle}>Forms</div>
                <Table
                    columns={formColumns}
                    data={
                        isShowVoidedFormsChecked
                            ? encounter.forms
                            : (encounter.forms || []).filter((ef: any) => !ef.voidInd)
                    }
                    isLoading={isLoading}
                />
            </Flex>
        </div>
    );
};

export default EncounterItem;
