import React, { useContext } from 'react';
import styles from './styles.module.scss';
import PageContainer from 'src/components/PageContainer';
import NavBar from 'src/containers/NavBar';
import AuthStore from 'src/stores/AuthStore';
import { observer } from 'mobx-react';

function LoadingError({ text }: { text?: string }) {
    const authStore = useContext(AuthStore);

    const errorText = text || 'There was an issue loading this content. Please try again later.';

    return (
        <PageContainer>
            {authStore.isAuthenticated ? <NavBar /> : null}
            <div className={styles.errorContainer}>{errorText}</div>
        </PageContainer>
    );
}

export default observer(LoadingError);
