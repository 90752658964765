import React, { useContext, useState } from 'react';
import { observer } from 'mobx-react';
import Select from 'react-select';
import { CellInfo, Column } from 'react-table';
import classNames from 'classnames';
import { styleSelectComponent } from 'src/utils';
import { ICONS, API_DATE_FORMAT } from 'src/utils/constants';
import styles from 'src/containers/Reports/Macra/styles.module.scss';
import * as variables from 'src/styles/variables';
import Flex from 'src/components/Flex';
import Input from 'src/components/Input';
import Table from 'src/components/Table';
import Button from 'src/components/Button';
import ActionMenu from 'src/components/ActionMenu';
import { PercentageCell } from 'src/components/CellRenderers/PercentageCell';
import { MeasureStatusCell } from 'src/components/CellRenderers/MeasureStatusCell';
import MacraStore from 'src/stores/MacraStore';
import { CasesTableRowData } from 'common/lib/model/macra/MacraYear';
import { ModalStoreObject, ModalTypes } from 'src/stores/ModalStore';
import { IconLegendModal } from 'src/containers/Reports/Macra/Modals/IconLegendModal';
import { CaseDetailModal } from 'src/containers/Reports/Macra/Modals/CaseDetailModal';
import { AbgQcdrMeasure, MacraCaseDataProvider } from 'common/lib/model/macra/Macra';
import { measureDefinitions2023, measureDefinitions2024 } from 'common/lib/util/Macra';
import { format, utcToZonedTime } from 'date-fns-tz';

function CasesTab() {
    const macraStore = useContext(MacraStore);
    const [casesTableFilter, setCasesTableFilter] = useState('');

    let measureDefinitions;
    switch (macraStore.year) {
        case 2023:
            measureDefinitions = measureDefinitions2023;
            break;
        case 2024:
        default:
            measureDefinitions = measureDefinitions2024;
            break;
    }
    const measureColumns = measureDefinitions.map((measureDefinition: AbgQcdrMeasure) => ({
        Header: measureDefinition.name.toUpperCase(),
        accessor: measureDefinition.name.toUpperCase(),
        minWidth: 65,
        headerClassName: classNames(styles.alignCenter, styles.tableHeader),
        className: classNames(styles.alignCenter, styles.tableFontSize),
        Cell: (props: CellInfo) => <MeasureStatusCell measure={props.value} />,
    }));
    const casesColumns: Column[] = [
        {
            Header: 'EN',
            id: 'case.encounterNumber',
            accessor: (row) => row.case.encounterNumber,
            minWidth: 105,
            headerClassName: classNames(styles.tableHeader),
            className: styles.tableFontSize,
        },
        {
            Header: 'DOS',
            id: 'case.dos',
            accessor: (row) =>
                row.case && row.case.dos
                    ? format(utcToZonedTime(row.case.dos, 'UTC'), API_DATE_FORMAT, { timeZone: 'UTC' })
                    : row.case.dos,
            minWidth: 90,
            headerClassName: classNames(styles.tableHeader),
            className: classNames(styles.tableFontSize),
            Cell: (props: CellInfo) => <span>{props.value}</span>,
        },
        {
            Header: 'PROVIDERS',
            id: 'case.anesProviders',
            accessor: (row) => row.case.anesProviders.map((p: MacraCaseDataProvider) => p.providerName).join(', '),
            minWidth: 150,
            headerClassName: classNames(styles.tableHeader),
            className: styles.tableFontSize,
            Cell: (props: CellInfo) => <div className={styles.wrapText}>{props.value}</div>,
        },
        {
            Header: 'FORM CMPLT %',
            id: 'case.formCompletePct',
            accessor: (row) => Math.round(row.case.formCompletePct.value * 100),
            minWidth: 110,
            headerClassName: classNames(styles.alignCenter, styles.tableHeader),
            className: styles.tableFontSize,
            Cell: (props: CellInfo) => (
                <PercentageCell value={props.original.case.formCompletePct} showBgState={true} />
            ),
        },
        {
            Header: 'ADMISSIBLE',
            id: 'evalResult.admissible',
            accessor: (row) => (row.evalResult.admissible ? 'YES' : 'NO'),
            minWidth: 100,
            headerClassName: classNames(styles.alignCenter, styles.tableHeader),
            className: styles.tableFontSize,
            Cell: (props: CellInfo) => <div className={styles.alignCenter}>{props.value}</div>,
        },
        {
            Header: 'ERRORS',
            id: 'evalResult.errors',
            accessor: (row) => row.evalResult.errors,
            minWidth: 100,
            headerClassName: classNames(styles.tableHeader),
            className: styles.tableFontSize,
        },
        ...measureColumns,
        {
            Header: 'ACTIONS',
            accessor: 'actions',
            sortable: false,
            style: { paddingVertical: 0, paddingHorizontal: 20 },
            Cell: (props: CellInfo) => (
                <div className={styles.actionWrap}>
                    <ActionMenu
                        actions={[
                            props.original.case.importResult && { id: 'viewRecord', label: 'View Record' },
                            { id: 'viewDetails', label: 'View Details' },
                        ]}
                        onItemClick={async (action: { id: string; label: string }) => {
                            const data: CasesTableRowData = props.original;
                            switch (action.id) {
                                case 'viewRecord':
                                    const { importBatchGuid, recordIndex } = data.case.importResult;
                                    const url = `https://dashboard.graphiumemr.com/collector/batch/record/${importBatchGuid}/${recordIndex}/dataEntry`;
                                    window.open(url);
                                    break;
                                case 'viewDetails':
                                    ModalStoreObject.showModal(ModalTypes.ViewContent, {
                                        onClose: () => ModalStoreObject.hideModal(),
                                        staticContent: <CaseDetailModal rowData={data} year={macraStore.year} />,
                                        title: `Case Details`,
                                    });
                                    break;
                            }
                        }}
                    />
                </div>
            ),
        },
    ];

    return (
        <div className={styles.tabContent}>
            <Flex direction="row" value={1}>
                <Flex align="start" value={1} self="start">
                    <Select
                        value={macraStore.providerDropdownValues.find(
                            (o) => o.value === macraStore.selectedProviderValue,
                        )}
                        onChange={(s: any) => {
                            macraStore.selectedProviderValue = s.value;
                        }}
                        isClearable={false}
                        isSearchable={false}
                        className={styles.providerSelectDropdown}
                        options={macraStore.providerDropdownValues}
                        placeholder="Select Provider"
                        styles={styleSelectComponent()}
                    />
                </Flex>
                <Flex direction="column" align="end">
                    <Input
                        icon={ICONS.Search}
                        iconColor={variables.grey}
                        iconSize={20}
                        placeholder="Filter Table"
                        value={casesTableFilter}
                        onChangeText={(text: string) => {
                            setCasesTableFilter(text);
                        }}
                    />
                </Flex>
            </Flex>
            <div className={styles.tableWrap}>
                <Table
                    isLoading={macraStore.isLoading}
                    columns={casesColumns}
                    data={macraStore.casesTableData}
                    showPagination={true}
                    globalFilter={casesTableFilter}
                />
            </div>
            <Flex direction="column" align="center">
                <Button
                    type="outline"
                    text="Legend"
                    onClick={(e) => {
                        ModalStoreObject.showModal(ModalTypes.ViewContent, {
                            onClose: () => ModalStoreObject.hideModal(),
                            staticContent: <IconLegendModal />,
                            title: `Legend`,
                        });
                    }}
                />
            </Flex>
        </div>
    );
}

export default observer(CasesTab);
