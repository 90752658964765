import React, { useRef, useState } from 'react';
import Card from 'src/components/Card';
import Tooltip from 'src/components/Tooltip';
import styles from './styles.module.scss';

export interface TextTooltipProps {
    text: string;
    children: React.ReactNode;
    className?: string;
}

function TextTooltip(props: TextTooltipProps) {
    const buttonRef = useRef<HTMLDivElement>(null);
    const [refState, setRefState] = useState(false);

    return (
        <>
            <div ref={buttonRef} onMouseEnter={() => setRefState(true)} onMouseLeave={() => setRefState(false)}>
                {props.children}
            </div>
            <Tooltip element={buttonRef} isOpen={refState} direction="top">
                <Card elevation={4} className={styles.tooltip}>
                    {props.text}
                </Card>
            </Tooltip>
        </>
    );
}

export default TextTooltip;
