import React, { useState, useRef, useEffect } from 'react';
import styles from './styles.module.scss';
import Icon from 'src/components/Icon';
import Flex from 'src/components/Flex';
import Button from 'src/components/Button';
import * as variables from 'src/styles/variables';
import Input from '../Input';
import { ICONS } from 'src/utils/constants';
import Card from '../Card';
import { OptionTypes } from '../OptionDropDown';
import Portal from '../Portal';
import useClickOutside from 'src/utils/hooks/useClickOutside';
import FilteredSearchRow from '../FilteredSearchRow';
import { ReactSelectOption } from 'src/utils/types';
import Toggle from '../Toggle';
import { exists } from 'src/utils';
import useDebouncedCallback from 'src/utils/hooks/useDebouncedCallback';
import useIsMount from 'src/utils/hooks/useIsMount';
import { FILTER_METHODS } from 'src/utils/filters';
import { ANALYTICS_NAMES } from 'src/utils/analytics';

export interface FilteredSearchQuery {
    query: string;
    fields: {
        name: string;
        method: string;
        model?: string;
        isNestedJson?: boolean;
        nestedJsonProperty?: string;
        data: { value1?: any; value2?: any };
    }[];
    orStatement?: boolean;
}

export type SubFilters = { [key in string]: FilterOptionsItem[] };

export interface FilterOptionsItem {
    field: {
        name: string;
        label: string;
        model?: string;
    };
    isNestedJson?: boolean;
    nestedJsonProperty?: string;
    subFilters?: SubFilters;
    availableFilterTypes: string[];
    options?: ReactSelectOption[];
    parentField?: string;
    optionType?: OptionTypes;
}
export interface FilterRowProps {
    id: number;
    field: string;
    isNestedJson?: boolean;
    nestedJsonProperty?: string;
    subFilters?: SubFilters;
    parentField?: string;
    selectedFilterType: string;
    availableFilterTypes: string[];
    extra: { options: FilterOptionsItem['options']; optionType: FilterOptionsItem['optionType'] };

    compareValue1: any | null;
    compareValue2: any | null;
    model?: FilterOptionsItem['field']['model'];
}

export interface FilteredSearchProps {
    onSearch: (a: FilteredSearchQuery) => void;
    filterOptions: FilterOptionsItem[];
    requiredFilters?: FilterOptionsItem[];
    searchPlaceholder?: string;
    commonFilters?: any;
    initialValues?: Partial<FilteredSearchQuery>;
    hideOrStatementSwitch?: boolean;
}

function FilteredSearch({
    onSearch,
    filterOptions,
    searchPlaceholder,
    commonFilters,
    initialValues,
    requiredFilters,
    hideOrStatementSwitch,
}: FilteredSearchProps) {
    const parentNode = useRef<HTMLDivElement>(null);
    const node = useRef<HTMLDivElement>();
    const isMount = useIsMount();
    const [searchDebounced] = useDebouncedCallback(handleSearch, 500);

    const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false);
    const [searchValue, setSearchValue] = useState<FilteredSearchQuery['query']>(initialValues.query || '');
    const [fieldsValue, setFieldsValue] = useState<FilteredSearchQuery['fields']>(initialValues.fields || []);
    const [orStatement, setOrStatement] = useState(
        exists(initialValues.orStatement) ? initialValues.orStatement : false,
    );
    const [activeCustomFilters, setActiveCustomFilters] = useState<FilterRowProps[]>([]);

    const [addedFilters, setAddedFilters] = useState<FilterOptionsItem[]>([]);

    const allFilters: FilterOptionsItem[] = [].concat(filterOptions, addedFilters);
    useEffect(() => {
        if (isMount) {
            return;
        }
        if (!isFilterMenuOpen) {
            handleSearch();
        }
    }, [isFilterMenuOpen]);

    function handleSearch() {
        onSearch({ query: searchValue, fields: fieldsValue, orStatement });
    }

    function handleUpdateSearchQuery(text: string) {
        if (isFilterMenuOpen) {
            setIsFilterMenuOpen(false);
        }
        setSearchValue(text);
        searchDebounced();
    }

    function handleClearNonRequiredFilters() {
        const requiredFields = requiredFilters ? requiredFilters.map((f) => f.field.name) : [];
        const clearedFields = fieldsValue.filter((field) => requiredFields.includes(field.name));
        setFieldsValue(clearedFields);
        setActiveCustomFilters([]);
        setOrStatement(false);
        searchDebounced();
    }

    function getNewRow(): FilterRowProps {
        return {
            id: Date.now(), // Need a uniq id for every row added
            field: filterOptions[0].field.name,
            selectedFilterType: filterOptions[0].availableFilterTypes[0],
            parentField: filterOptions[0].parentField,
            availableFilterTypes: filterOptions[0].availableFilterTypes,
            extra: { options: filterOptions[0].options, optionType: filterOptions[0].optionType },
            compareValue1: null,
            compareValue2: null,
            model: filterOptions[0].field.model,
            isNestedJson: filterOptions[0].isNestedJson,
            nestedJsonProperty: filterOptions[0].nestedJsonProperty,
        };
    }

    function getRequiredRows(): FilterRowProps[] {
        const requiredArr: FilterRowProps[] = [];
        if (!requiredFilters) {
            setActiveCustomFilters(getActiveRows(filterOptions));
            return [];
        }
        let newAddedFilters: FilterOptionsItem[] = [];
        const ts = Date.now();
        requiredFilters.forEach((f: FilterOptionsItem, i: number) => {
            const initVal =
                initialValues && initialValues.fields.length > 0
                    ? initialValues.fields.find((i) => i.name === f.field.name)
                    : null;
            const newRequiredRow: FilterRowProps = {
                id: ts + i, // Need a uniq id for every row added
                field: f.field.name,
                selectedFilterType: initVal ? initVal.method : f.availableFilterTypes[0],
                availableFilterTypes: f.availableFilterTypes,
                extra: { options: f.options, optionType: f.optionType },
                compareValue1:
                    initVal && initVal.data && initVal.data.value1
                        ? initVal.data.value1
                        : f.options && f.options[0] && f.options[0].value
                        ? f.options[0].value
                        : null,
                compareValue2: initVal && initVal.data && initVal.data.value2 ? initVal.data.value2 : null,
                model: f.field.model,
            };
            requiredArr.push(newRequiredRow);
            if (f.subFilters && f.subFilters[newRequiredRow.compareValue1]) {
                // remove any fields that have a parentField property that matches the newRow field name.
                const availableToAdd = f.subFilters[newRequiredRow.compareValue1];
                newAddedFilters = newAddedFilters.concat(availableToAdd);
            }
        });
        if (newAddedFilters.length > 0) {
            setAddedFilters(newAddedFilters);

            setActiveCustomFilters(getActiveRows([].concat(filterOptions, newAddedFilters)));
        } else {
            setActiveCustomFilters(getActiveRows(filterOptions));
        }
        return requiredArr;
    }
    function getActiveRows(filters: any): FilterRowProps[] {
        const activeRows: FilterRowProps[] = [];
        if (!filters) return [];
        const ts = Date.now();
        filters.forEach((f: FilterOptionsItem, i: number) => {
            const initVal =
                initialValues && initialValues.fields.length > 0
                    ? initialValues.fields.find((i) => i.name === f.field.name)
                    : null;
            if (!initVal) return;
            const newActiveRow: FilterRowProps = {
                id: ts + i, // Need a uniq id for every row added
                field: f.field.name,
                selectedFilterType: initVal ? initVal.method : f.availableFilterTypes[0],
                availableFilterTypes: f.availableFilterTypes,
                extra: { options: f.options, optionType: f.optionType },
                compareValue1:
                    initVal && initVal.data && (initVal.data.value1 || initVal.data.value1 === false)
                        ? initVal.data.value1
                        : f.options && f.options[0] && f.options[0].value
                        ? f.options[0].value
                        : null,
                compareValue2: initVal && initVal.data && initVal.data.value2 ? initVal.data.value2 : null,
                model: f.field.model,
                isNestedJson: filterOptions[0].isNestedJson,
                nestedJsonProperty: filterOptions[0].nestedJsonProperty,
            };
            activeRows.push(newActiveRow);
        });
        return activeRows;
    }
    const [activeRequiredFilters, setActiveRequiredFilters] = useState<any>(() => getRequiredRows());
    const [fieldOptions, setFieldOptions] = useState<ReactSelectOption[]>(() =>
        allFilters.map((o) => ({ value: o.field.name, label: o.field.label })),
    );

    useEffect(() => {
        callUpdate([].concat(activeRequiredFilters, activeCustomFilters));
    }, [orStatement]);

    const showPreMade =
        ((activeCustomFilters || []).length === 1 &&
            activeCustomFilters[0].compareValue1 === null &&
            activeCustomFilters[0].compareValue2 === null) ||
        (activeCustomFilters || []).length === 0;
    useClickOutside(() => {
        setIsFilterMenuOpen(false);
    }, [node, parentNode]);

    useEffect(() => {
        setFieldOptions(allFilters.map((o) => ({ value: o.field.name, label: o.field.label })));
    }, [filterOptions, addedFilters]);

    function callUpdate(newFilters: FilterRowProps[]) {
        setFieldsValue(
            newFilters.map((f) => ({
                name: f.field,
                method: f.selectedFilterType,
                model: f.model,
                isNestedJson: f.isNestedJson,
                nestedJsonProperty: f.nestedJsonProperty,
                data: { value1: f.compareValue1, value2: f.compareValue2 },
            })),
        );
        setOrStatement(orStatement);
    }

    function changeActiveFilters(newFilters: FilterRowProps[]) {
        setActiveCustomFilters(newFilters);
        // Format new filters to send to parent component
        callUpdate([].concat(activeRequiredFilters, newFilters));
    }

    function handleChangeRow(newRow: FilterRowProps, index: number) {
        const newFilters = (activeCustomFilters || []).map((r, rIndex) => (rIndex === index ? newRow : r));
        const oldRow = (activeCustomFilters || [])[index];

        // If the field is changing, change the other params to match the filterOptions
        if (oldRow.field !== newRow.field) {
            const selectedFilterOption: FilterOptionsItem = allFilters.find(
                (f: FilterOptionsItem) => f.field.name === newRow.field,
            );
            newFilters[index] = {
                ...oldRow,
                model: selectedFilterOption.field.model,
                field: newRow.field,
                selectedFilterType: selectedFilterOption.availableFilterTypes[0],
                availableFilterTypes: selectedFilterOption.availableFilterTypes,
                parentField: selectedFilterOption.parentField,
                extra: { options: selectedFilterOption.options, optionType: selectedFilterOption.optionType },
                compareValue1: null,
                compareValue2: null,
                isNestedJson: selectedFilterOption.isNestedJson,
                nestedJsonProperty: selectedFilterOption.nestedJsonProperty,
            };
        } else if (oldRow.selectedFilterType !== newRow.selectedFilterType) {
            // If the filter type changes, determine if we should clear any of the compared values
            if (
                [FILTER_METHODS.One_Of_Dropdown].includes(newRow.selectedFilterType) ||
                [FILTER_METHODS.One_Of_Dropdown].includes(oldRow.selectedFilterType)
            ) {
                newFilters[index].compareValue1 = null;
                newFilters[index].compareValue2 = null;
            } else if (
                [
                    FILTER_METHODS.Between_Number,
                    FILTER_METHODS.Between_Date,
                    FILTER_METHODS.Between_Date_Only,
                    FILTER_METHODS.Between_Time,
                ].includes(oldRow.selectedFilterType)
            ) {
                newFilters[index].compareValue2 = null;
            }
        }

        changeActiveFilters(newFilters);
    }

    function handleChangeRequiredRow(newRow: FilterRowProps, index: number) {
        const newFilters = activeRequiredFilters.map((r: any, rIndex: number) => (rIndex === index ? newRow : r));
        const oldRow = activeRequiredFilters[index];

        if (oldRow.selectedFilterType !== newRow.selectedFilterType) {
            // If the filter type changes, determine if we should clear any of the compared values
            if (
                [FILTER_METHODS.One_Of_Dropdown].includes(newRow.selectedFilterType) ||
                [FILTER_METHODS.One_Of_Dropdown].includes(oldRow.selectedFilterType)
            ) {
                newFilters[index].compareValue1 = null;
                newFilters[index].compareValue2 = null;
            } else if (
                [
                    FILTER_METHODS.Between_Number,
                    FILTER_METHODS.Between_Date,
                    FILTER_METHODS.Between_Date_Only,
                    FILTER_METHODS.Between_Time,
                ].includes(oldRow.selectedFilterType)
            ) {
                newFilters[index].compareValue2 = null;
            }
        }
        setActiveRequiredFilters(newFilters);

        if (oldRow.compareValue1 !== newRow.compareValue1) {
            const origFilter = requiredFilters.find((f) => f.field.name === newRow.field);
            if (origFilter && origFilter.subFilters && origFilter.subFilters[newRow.compareValue1]) {
                // remove any fields that have a parentField property that matches the newRow field name.
                const availableToAdd = origFilter.subFilters[newRow.compareValue1];
                setAddedFilters(availableToAdd);
                const newActive = activeCustomFilters.filter(
                    (a) => !addedFilters.map((af) => af.field.name).includes(a.field),
                );
                setActiveCustomFilters(newActive);
                callUpdate([].concat(newFilters, newActive));
            } else if (origFilter && origFilter.subFilters && !origFilter.subFilters[newRow.compareValue1]) {
                setAddedFilters([]);
                // remove any active filters that used this property.
                const updActive = (activeCustomFilters || []).filter(
                    (a) => !a.parentField || a.parentField !== newRow.field,
                );
                setActiveCustomFilters(updActive);
                callUpdate([].concat(newFilters, updActive));
            } else {
                callUpdate([].concat(newFilters, activeCustomFilters));
            }
        } else {
            callUpdate([].concat(newFilters, activeCustomFilters));
        }
    }

    return (
        <>
            <Card ref={parentNode}>
                <Flex direction="row" self="stretch" align="center" justify="start" className={styles.wrap}>
                    <Flex value={1} className={styles.searchWrap}>
                        <div className={styles.searchWrap}>
                            <Input
                                disableBorder={true}
                                className={styles.input}
                                placeholder={searchPlaceholder || 'Search for an item'}
                                icon={ICONS.Search}
                                iconColor={variables.grey}
                                iconSize={20}
                                value={searchValue}
                                onChangeText={handleUpdateSearchQuery}
                            />
                        </div>
                    </Flex>
                    <Flex
                        direction="row"
                        align="center"
                        justify="start"
                        self="stretch"
                        value={1}
                        className={styles.filterWrap}
                    >
                        <Flex value={1} direction="row" align="center">
                            <Button
                                preventDouble={false}
                                type="transparent"
                                onClick={() => setIsFilterMenuOpen(true)}
                                className={styles.addFilterBtn}
                                data-test-id={ANALYTICS_NAMES.Filtered_Search_Open_Button}
                            >
                                <Flex direction="row" align="center">
                                    <Icon
                                        className={styles.filterIcon}
                                        color={variables.grey}
                                        size={20}
                                        name={ICONS.PlusButton}
                                    />
                                    <span className={styles.underlined}>Filter your search by adding a filter</span>
                                    {(fieldsValue || []).length > 0 ? (
                                        <Flex className={styles.numSelectedFilters}>{fieldsValue.length} Selected</Flex>
                                    ) : null}

                                    <Flex></Flex>
                                </Flex>
                            </Button>
                            {(fieldsValue || []).length > (requiredFilters || []).length ? (
                                <Button
                                    text="Clear"
                                    leftIcon={<Icon size={8} className={styles.clearIcon} name={ICONS.Close} />}
                                    onClick={handleClearNonRequiredFilters}
                                    className={styles.clearFiltersBtn}
                                />
                            ) : null}
                        </Flex>
                    </Flex>
                </Flex>
            </Card>
            {isFilterMenuOpen && (
                <Portal node={parentNode.current}>
                    <div
                        ref={node}
                        className={styles.filterPanelWrap}
                        style={{
                            minWidth:
                                parentNode.current && parentNode.current.clientWidth
                                    ? parentNode.current.clientWidth
                                    : 0,
                        }}
                    >
                        <Card elevation={4} className={styles.filterPanel}>
                            <Flex direction="row" align="start" className={styles.premadeFilters}>
                                <Flex
                                    value={1}
                                    direction="column"
                                    align="start"
                                    style={{
                                        opacity: showPreMade ? 1 : 0.3,
                                    }}
                                    className={styles.preMade}
                                >
                                    <span className={styles.filterHeader}>Choose from Common Filters</span>
                                    <Flex direction="row" align="center">
                                        {commonFilters}
                                    </Flex>
                                </Flex>
                                <Button
                                    leftIcon={
                                        <Icon
                                            className={styles.plusIcon}
                                            size={16}
                                            name={ICONS.Search}
                                            color={variables.white}
                                        />
                                    }
                                    type="primary"
                                    onClick={() => setIsFilterMenuOpen(false)}
                                    text="Search"
                                />
                            </Flex>
                            {((activeCustomFilters || []).length > 0 || activeRequiredFilters.length > 0) && (
                                <Flex direction="row" className={styles.showResults}>
                                    <Flex value={1}>
                                        <span className={styles.filterHeader}>
                                            Create a Custom Filter that shows results:
                                        </span>
                                    </Flex>
                                    {!hideOrStatementSwitch && (
                                        <Flex>
                                            <span className={styles.orStatement}>Make Or Statement?</span>
                                            <Toggle
                                                toggled={orStatement}
                                                onChange={(selected: boolean) => {
                                                    setOrStatement(selected);
                                                }}
                                            />
                                        </Flex>
                                    )}
                                </Flex>
                            )}
                            <Flex direction="column">
                                {activeRequiredFilters.map((f: FilterRowProps, index: number) => (
                                    <FilteredSearchRow
                                        key={f.id}
                                        filter={f}
                                        isRequired={true}
                                        onUpdateRow={(newRow: FilterRowProps) => handleChangeRequiredRow(newRow, index)}
                                        fieldOptions={[
                                            {
                                                value:
                                                    requiredFilters.find((r) => r.field.name === f.field).field.name ||
                                                    '',
                                                label:
                                                    requiredFilters.find((r) => r.field.name === f.field).field.label ||
                                                    '',
                                            },
                                        ]}
                                    />
                                ))}
                                {(activeCustomFilters || []).map((f: FilterRowProps, index) => (
                                    <FilteredSearchRow
                                        key={f.id}
                                        filter={f}
                                        onUpdateRow={(newRow: FilterRowProps) => handleChangeRow(newRow, index)}
                                        onDeleteRow={() =>
                                            changeActiveFilters(
                                                (activeCustomFilters || []).filter((_, i) => i !== index),
                                            )
                                        }
                                        fieldOptions={fieldOptions}
                                    />
                                ))}
                                <Button
                                    preventDouble={false}
                                    leftIcon={
                                        <Icon
                                            className={styles.plusIcon}
                                            size={16}
                                            name={ICONS.PlusButton}
                                            color={variables.black}
                                        />
                                    }
                                    type="outline"
                                    onClick={() => changeActiveFilters([...(activeCustomFilters || []), getNewRow()])}
                                    text="Add New Filter"
                                    className={styles.newEncounterButton}
                                    data-test-id={ANALYTICS_NAMES.Filtered_Search_Add_Button}
                                />
                            </Flex>
                        </Card>
                    </div>
                </Portal>
            )}
        </>
    );
}

export default FilteredSearch;
