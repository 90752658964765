import React, { useState, useEffect, useContext } from 'react';
import NavBar from 'src/containers/NavBar';
import PageContent from 'src/components/PageContent';
import PageContainer from 'src/components/PageContainer';
import Flex from 'src/components/Flex';
import styles from './styles.module.scss';
import { observer } from 'mobx-react';
import { ICONS } from 'src/utils/constants';
import LoadingCover from 'src/components/LoadingCover';
import Button from 'src/components/Button';
import { toJS } from 'mobx';
import EncounterStore from 'src/stores/EncounterStore';
import EncounterItem from 'src/components/EncounterItem';
import FilterStore from 'src/stores/FilterStore';
import Pagination from 'src/components/Table/Pagination';
import FilteredSearch, { FilteredSearchQuery } from 'src/components/FilteredSearch';
import Icon from 'src/components/Icon';
import UserStore from 'src/stores/UserStore';
import Checkbox from 'src/components/Checkbox';
import useIsMount from 'src/utils/hooks/useIsMount';
import { ModalStoreObject, ModalTypes } from 'src/stores/ModalStore';
import SubNav from '../SubNav';
import { FilterQueryParams, Encounter } from 'src/utils/types';
import { getEncounterFilters, getRequiredEncounterFilters, FILTER_METHODS } from 'src/utils/filters';

const FILTER_PAGE = 'encountersPage';

const encounterPreMadeFilters = ['Active Encounters'];

function Encounters() {
    const encounterStore = useContext(EncounterStore);
    const userStore = useContext(UserStore);
    const filterStore = useContext(FilterStore);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedPreMadeFilters, setSelectedPreMadeFilters] = useState([]);
    const encounters = encounterStore.encounters;
    const encounterFilters: FilterQueryParams = filterStore[FILTER_PAGE];
    const isMount = useIsMount();

    async function loadEncounters() {
        try {
            setIsLoading(true);
            await encounterStore.getAllEncounters(encounterFilters);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        // Don't load on initial mount
        if (isMount) {
            return;
        }
        loadEncounters();
    }, [encounterFilters]);

    async function handleSearch({ query, fields, orStatement }: FilteredSearchQuery) {
        const updatedFilters = { ...encounterFilters };
        updatedFilters.pagination.page = 1;
        updatedFilters.query = query;
        updatedFilters.orStatement = orStatement;

        // determine if searching by custom query or by premade filters
        const searchingPreMade =
            (((fields || []).length === 1 && fields[0].data.value1 === null && fields[0].data.value2 === null) ||
                (fields || []).length === 0) &&
            selectedPreMadeFilters.length > 0;

        if (searchingPreMade) {
            // handle Premade
            let preMadeFilters: any[] = [];
            if (selectedPreMadeFilters.includes('Active Encounters')) {
                preMadeFilters = [
                    ...preMadeFilters,
                    {
                        name: 'enctrStatCd',
                        method: FILTER_METHODS.Equals_Text,
                        data: { value1: 'ACTIVE', value2: null },
                    },
                ];
            }
            updatedFilters.filters = preMadeFilters;
        } else {
            updatedFilters.filters = fields;
        }

        filterStore.setFilters(FILTER_PAGE, updatedFilters);
    }

    const handlePaginationFilterChange = function(newPage: number) {
        const updatedFilters = { ...encounterFilters };
        updatedFilters.pagination.page = newPage;
        filterStore.setFilters(FILTER_PAGE, updatedFilters);
    };

    function createAdHocEncounter() {
        ModalStoreObject.showModal(ModalTypes.Encounter, {
            onClose: () => {
                ModalStoreObject.hideModal();
            },
            title: `Create a new Encounter`,
            isOpen: true,
            onSave: async () => {
                ModalStoreObject.hideModal();
                loadEncounters();
            },
            isAdHoc: true,
        });
    }

    const encountersArr = toJS(encounters);

    return (
        <PageContainer>
            <NavBar />
            <SubNav type="patients" />

            <PageContent>
                <Flex direction="row" className={styles.filterWrap}>
                    <Flex value={1}>
                        <FilteredSearch
                            initialValues={{
                                query: encounterFilters.query,
                                fields: encounterFilters.filters,
                                orStatement: encounterFilters.orStatement,
                            }}
                            onSearch={handleSearch}
                            searchPlaceholder={'Search by Encounter Number'}
                            commonFilters={
                                <Flex direction="row" wrap="wrap">
                                    {encounterPreMadeFilters.map((f) => (
                                        <Checkbox
                                            key={f}
                                            checked={selectedPreMadeFilters.includes(f)}
                                            onChange={() => {
                                                const isAlreadyChecked = selectedPreMadeFilters.includes(f);
                                                if (isAlreadyChecked) {
                                                    const newFilters = selectedPreMadeFilters.filter((i) => i !== f);
                                                    setSelectedPreMadeFilters(newFilters);
                                                } else {
                                                    setSelectedPreMadeFilters([...selectedPreMadeFilters, f]);
                                                }
                                            }}
                                            label={f}
                                        />
                                    ))}
                                </Flex>
                            }
                            requiredFilters={getRequiredEncounterFilters() as any}
                            filterOptions={getEncounterFilters() as any}
                        />
                    </Flex>
                    <Button
                        leftIcon={<Icon className={styles.plusIcon} name={ICONS.PlusButton} />}
                        type="primary"
                        onClick={createAdHocEncounter}
                        text="New Encounter"
                        className={styles.newEncounterButton}
                        isLocked={!userStore.userPermissions.canCreate.patients} //TODO create new permission for encounters
                        disabled={isLoading}
                    />
                </Flex>

                {encounterStore.totalEncountersCount > 0 && (
                    <div>{encounterStore.totalEncountersCount} total results</div>
                )}
                <div className={styles.tableWrap}>
                    <div style={{ position: 'relative', minHeight: 250 }}>
                        {isLoading ? <LoadingCover /> : null}
                        {encountersArr.length === 0 ? (
                            <Flex>Filter your Search to View Encounters</Flex>
                        ) : (
                            encountersArr.map((encounter: Encounter) => (
                                <EncounterItem
                                    key={encounter.enctrId}
                                    encounter={encounter}
                                    isShowVoidedFormsChecked={true}
                                    onSave={loadEncounters}
                                />
                            ))
                        )}
                    </div>
                </div>
                {/* Custom Pagination for server side data */}
                {encountersArr.length > 0 ? (
                    <Pagination
                        pages={Math.ceil(encounterStore.totalEncountersCount / encounterFilters.pagination.limit) || 1}
                        page={encounterFilters.pagination.page - 1}
                        onPageChange={(page: number) => handlePaginationFilterChange(page + 1)}
                        showing={encounterStore.encounters.length}
                        totalRecords={encounterStore.totalEncountersCount}
                    />
                ) : null}
            </PageContent>
        </PageContainer>
    );
}
export default observer(Encounters);
