import React, { useContext, useState, useEffect } from 'react';
import Flex from 'src/components/Flex';
import PageContainer from 'src/components/PageContainer';
import PageContent from 'src/components/PageContent';
import Button from 'src/components/Button';
import NavBar from 'src/containers/NavBar';
import Card from 'src/components/Card';
import styles from './styles.module.scss';
import Table from 'src/components/Table';
import FilteredSearch, { FilteredSearchQuery } from 'src/components/FilteredSearch';
import FilterStore from 'src/stores/FilterStore';
import FeatureFlagStore from 'src/stores/FeatureFlagStore';
import { FilterQueryParams } from 'src/utils/types';
import { getPaymentRequestsFilters, getRequiredPaymentRequestFilters } from 'src/utils/filters';
import Icon from 'src/components/Icon';
import { ICONS, ROUTES } from 'src/utils/constants';
import { CellInfo } from 'react-table';
import DateComponent from 'src/components/DateComponent';
import PaymentsStore, { PaymentRequest, PaymentRequestState, PaymentType } from 'src/stores/PaymentsStore';
import ActionMenu from 'src/components/ActionMenu';
import { useHistory, useLocation } from 'react-router';
import { observer } from 'mobx-react';
import { ToastStoreObject } from 'src/stores/ToastStore';
import { parseError } from 'src/utils';
import useIsMount from 'src/utils/hooks/useIsMount';
import Pagination from 'src/components/Table/Pagination';
import { ModalStoreObject, ModalTypes } from 'src/stores/ModalStore';
import { formatNumberToCurrency } from 'common/lib/util/number';
import PaymentStatus from 'src/components/PaymentStatus';
import { DISPLAY_DATE_FORMAT, DISPLAY_TIME_FORMAT } from 'src/utils/constants';

const FILTER_PAGE = 'paymentRequestsPage';

interface LocationState {
    importBatch: any;
}

function PaymentRequests() {
    const filterStore = useContext(FilterStore);
    const paymentsStore = useContext(PaymentsStore);
    const paymentRequestFilters: FilterQueryParams = filterStore[FILTER_PAGE];
    const featureFlagStore = useContext(FeatureFlagStore);
    const history = useHistory();
    const location = useLocation<LocationState>();
    const locationState = location.state;
    const isMount = useIsMount();

    const [isLoading, setIsLoading] = useState(false);
    const [isImporting, setIsImporting] = useState(false);
    const [importBatchGuid, setImportBatchGuid] = useState(null);

    const columns = [
        {
            Header: 'Patient Name',
            accessor: 'name',
        },
        {
            Header: 'Type',
            accessor: 'paymentType',
            minWidth: 150,
        },
        {
            Header: 'Facility',
            accessor: 'facility',
            minWidth: 150,
        },
        {
            Header: 'Amount',
            accessor: 'amount',
            minWidth: 60,
            Cell: (props: CellInfo) => {
                return <span>{formatNumberToCurrency(props.value)}</span>;
            },
        },
        {
            Header: 'Status',
            accessor: 'stateId',
            minWidth: 80,
            Cell: (props: CellInfo) => {
                return <PaymentStatus type="PaymentRequest" status={props.value} />;
            },
        },
        {
            Header: 'Updated',
            accessor: 'updatedAt',
            minWidth: 100,
            Cell: (props: CellInfo) => {
                return <DateComponent date={props.value} format={`${DISPLAY_DATE_FORMAT} ${DISPLAY_TIME_FORMAT}`} />;
            },
        },
        {
            Header: 'Actions',
            accessor: 'actions',
            minWidth: 125,
            Cell: (props: CellInfo) => (
                <Flex>
                    {showActions(props.original.stateId) ? (
                        <ActionMenu
                            entityType="paymentRequest"
                            entity={props.original}
                            onItemClick={(action: { id: string; label: string }) =>
                                handleActionClick(action, props.original)
                            }
                        />
                    ) : null}
                    <Button
                        type="small"
                        text="View Details"
                        className={
                            showActions(props.original.stateId) ? styles.detailsButton : styles.detailsButtonNoActions
                        }
                        onClick={() =>
                            history.push(
                                ROUTES.getString(ROUTES.PaymentRequestDetail, props.original.paymentRequestGuid),
                            )
                        }
                    />
                </Flex>
            ),
        },
    ];

    useEffect(() => {
        getPaymentRequestData(true);
    }, []);

    useEffect(() => {
        if (locationState && locationState.importBatch) {
            const importBatchGuid = locationState.importBatch && locationState.importBatch[0].importBatchGuid;
            setImportBatchGuid(importBatchGuid);
            setIsImporting(true);
            // Make sure we replace the history state after we have handled it
            history.replace(location.pathname, { ...locationState, importBatch: undefined });
        }
    }, [locationState]);

    useEffect(() => {
        // Don't load on initial mount
        if (isMount) {
            return;
        }
        getPaymentRequestData(true);
    }, [paymentRequestFilters]);

    async function getPaymentRequestData(force = false) {
        try {
            setIsLoading(true);
            await paymentsStore.getPaymentRequestStates();
            await paymentsStore.getPaymentTypes({ includeInactive: true, force });
            await paymentsStore.getPayerCategories();
            await paymentsStore.getPaymentRequests(paymentRequestFilters, force);
        } catch (e: any) {
            ToastStoreObject.show(parseError(e));
        } finally {
            setIsLoading(false);
        }
    }

    function showActions(stateId: any) {
        const state = paymentsStore.paymentRequestStates.find(
            (prs: PaymentRequestState) => prs.paymentRequestStateId.toString() === stateId.toString(),
        );
        return (
            state &&
            state.paymentRequestStateCode !== 'REQUEST_COMPLETED' &&
            state.paymentRequestStateCode !== 'REQUEST_CANCELLED'
        );
    }

    const handlePaginationFilterChange = function (newPage: number) {
        const updatedFilters = { ...paymentRequestFilters };
        updatedFilters.pagination.page = newPage;
        filterStore.setFilters(FILTER_PAGE, updatedFilters);
    };

    function handleSearch({ query, fields }: FilteredSearchQuery) {
        const updatedFilters = { ...paymentRequestFilters };
        updatedFilters.pagination.page = 1;
        updatedFilters.query = query;
        updatedFilters.filters = fields;

        filterStore.setFilters(FILTER_PAGE, updatedFilters);
    }

    function handleImport() {
        history.push(ROUTES.getString(ROUTES.PaymentRequestImport));
    }

    function handleActionClick(action: { id: string; label: string }, item: PaymentRequest) {
        switch (action.id) {
            case 'resend':
                ModalStoreObject.showModal(ModalTypes.ResendPaymentRequestModal, {
                    onCancel: () => ModalStoreObject.hideModal(),
                    paymentRequestGuid: item.paymentRequestGuid,
                });
                break;
            case 'collect':
                ModalStoreObject.showModal(ModalTypes.CollectPaymentModal, {
                    onCancel: () => ModalStoreObject.hideModal(),
                    onSave: () => {
                        getPaymentRequestData(true);
                        ModalStoreObject.hideModal();
                    },
                    paymentRequestGuid: item.paymentRequestGuid,
                });
                break;
            case 'cancel':
                ModalStoreObject.showModal(ModalTypes.CancelPaymentModal, {
                    onSave: () => {
                        getPaymentRequestData(true);
                        ModalStoreObject.hideModal();
                    },
                    onCancel: () => ModalStoreObject.hideModal(),
                    paymentRequest: item,
                });
                break;
        }
    }

    function handleProcessingClick() {
        history.push(ROUTES.getString(ROUTES.ImportDetail, importBatchGuid), {
            isFromPaymentRequests: true,
        });
    }

    return (
        <PageContainer>
            <NavBar />
            <PageContent>
                <Flex direction="row" className={styles.filterWrap}>
                    <Flex value={1}>
                        <FilteredSearch
                            initialValues={{
                                query: paymentRequestFilters.query,
                                fields: paymentRequestFilters.filters,
                                orStatement: paymentRequestFilters.orStatement,
                            }}
                            onSearch={handleSearch}
                            searchPlaceholder={'Search by patient name'}
                            filterOptions={getPaymentRequestsFilters() as any}
                            hideOrStatementSwitch={true}
                            requiredFilters={getRequiredPaymentRequestFilters() as any}
                        />
                    </Flex>
                    {featureFlagStore.isEnabled('payments') ? (
                        <Button
                            leftIcon={<Icon className={styles.plusIcon} name={ICONS.PlusButton} />}
                            type="primary"
                            onClick={() => handleImport()}
                            text="Import Payment Requests"
                            className={styles.importButton}
                        />
                    ) : null}
                </Flex>
                {isImporting && (
                    <div className={styles.importToast}>
                        Your import is processing.{' '}
                        <span className={styles.importToastLink} onClick={() => handleProcessingClick()}>
                            View Status
                        </span>
                    </div>
                )}
                <Card className={styles.cardWrap}>
                    <div className={styles.tableTitle}>Payment Requests</div>
                    <Table
                        isLoading={isLoading}
                        columns={columns}
                        data={paymentsStore.paymentRequests}
                        showPagination={false}
                    />
                </Card>
                <Pagination
                    pages={
                        Math.ceil(paymentsStore.totalPaymentRequestCount / filterStore[FILTER_PAGE].pagination.limit) ||
                        1
                    }
                    page={filterStore[FILTER_PAGE].pagination.page - 1}
                    onPageChange={(page: number) => handlePaginationFilterChange(page + 1)}
                    showing={paymentsStore.paymentRequests.length}
                    totalRecords={paymentsStore.totalPaymentRequestCount}
                />
            </PageContent>
        </PageContainer>
    );
}

export default observer(PaymentRequests);
