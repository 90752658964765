import { UseQueryResult, useQuery } from 'react-query';
import { getFormModels } from 'src/api/formModels';
import { FormModel } from 'common/lib/entity/framework/FormModel';
import { UserStoreObject } from 'src/stores/UserStore';

export const formModelsKey = 'formModels';

export const useFormModels = (): UseQueryResult<FormModel[]> => {
    return useQuery({
        queryKey: [formModelsKey],
        queryFn: async () => {
            return await getFormModels();
        },
        enabled: UserStoreObject.isGraphiumAdmin(),
        initialData: [],
    });
};
