// Define all analytic names. Or just put them in the code and write something to scrape them all later
export const ANALYTICS_NAMES = {
    Accordion_Open: 'Accordion_Open',
    Accordion_Remove_Spacing: 'Accordion_Remove_Spacing',
    Accordion_Toggle: 'Accordion_Toggle',
    ActionMenu_Gear: 'ActionMenu_Gear',
    ActionMenu_Portal: 'ActionMenu_Portal',
    AddRows_Add_Row_Button: 'AddRows_Add_Row_Button',
    AddRows_Address_Row_Delete_Button: 'AddRows_Address_Row_Delete_Button',
    AddRows_Address_Row_Input: 'AddRows_Address_Row_Input',
    AddRows_Address_Row_Wrapper: 'AddRows_Address_Row_Wrapper',
    AddRows_Address_Row: 'AddRows_Address_Row',
    AddRows_Email_Row_Delete_Button: 'AddRows_Email_Row_Delete_Button',
    AddRows_Email_Row_Input: 'AddRows_Email_Row_Input',
    AddRows_Email_Row_Wrapper: 'AddRows_Email_Row_Wrapper',
    AddRows_Email_Row: 'AddRows_Email_Row',
    AddRows_Phone_Row_Delete_Button: 'AddRows_Phone_Row_Delete_Button',
    AddRows_Phone_Row_Input: 'AddRows_Phone_Row_Input',
    AddRows_Phone_Row_Wrapper: 'AddRows_Phone_Row_Wrapper',
    AddRows_Phone_Row: 'AddRows_Phone_Row',
    AdminUsers_Activate_User: 'AdminUsers_Activate_User',
    AdminUsers_View_User: 'AdminUsers_View_User',
    AnimatedComponent_Transition_Group: 'AnimatedComponent_Transition_Group',
    Appointments_CreateAppointment: 'Appointments_CreateAppointment',
    Appointments_CreateTask: 'Appointments_CreateTask',
    Appointments_FilterAppointments: 'Appointments_FilterAppointments',
    Appointments_FilterTasks: 'Appointments_FilterTasks',
    Appointments_SelectAllTeams: 'Appointments_SelectAllTeams',
    Appointments_SelectNoneTeams: 'Appointments_SelectNoneTeams',
    Appointments_SelectAllUsers: 'Appointments_SelectAllUsers',
    Appointments_SelectNoneUsers: 'Appointments_SelectNoneUsers',
    Appointments_SelectView: 'Appointments_SelectView',
    Appointments_Status: 'Appointments_Status',
    AutomatedDispenseSystem_Edit: 'AutomatedDispenseSystem_Edit',
    AutomatedDispenseSystemModal_DisplayName: 'AutomatedDispenseSystemModal_DisplayName',
    CalendarEvent_Content: 'CalendarEvent_Content',
    CreateAttachmentModal_Input_Description: 'CreateAttachmentModal_Input_Description',
    CreateAttachmentModal_Input_File: 'CreateAttachmentModal_Input_File',
    CreateEventModal_Input_Assignee: 'CreateEventModal_Input_Assignee',
    CreateEventModal_Input_Description: 'CreateEventModal_Input_Description',
    CreateEventModal_Input_EndDate: 'CreateEventModal_Input_EndDate',
    CreateEventModal_Input_EndTime: 'CreateEventModal_Input_EndTime',
    CreateEventModal_Input_Facility: 'CreateEventModal_Input_Facility',
    CreateEventModal_Input_Location: 'CreateEventModal_Input_Location',
    CreateEventModal_Input_Name: 'CreateEventModal_Input_Name',
    CreateEventModal_Input_Patient: 'CreateEventModal_Input_Patient',
    CreateEventModal_Input_StartDate: 'CreateEventModal_Input_StartDate',
    CreateEventModal_Input_StartTime: 'CreateEventModal_Input_StartTime',
    CreateEventModal_Input_Type: 'CreateEventModal_Input_Type',
    CreateMedicationModal_Input_AdminRoute: 'CreateMedicationModal_Input_AdminRoute',
    CreateMedicationModal_Input_BrandName: 'CreateMedicationModal_Input_BrandName',
    CreateMedicationModal_Input_DirectedUsage: 'CreateMedicationModal_Input_DirectedUsage',
    CreateMedicationModal_Input_DoseAmount: 'CreateMedicationModal_Input_DoseAmount',
    CreateMedicationModal_Input_EndDate: 'CreateMedicationModal_Input_EndDate',
    CreateMedicationModal_Input_Frequency: 'CreateMedicationModal_Input_Frequency',
    CreateMedicationModal_Input_Instructions: 'CreateMedicationModal_Input_Instructions',
    CreateMedicationModal_Input_Name: 'CreateMedicationModal_Input_Name',
    CreateMedicationModal_Input_Opioid: 'CreateMedicationModal_Input_Opioid',
    CreateMedicationModal_Input_PrescribedBy: 'CreateMedicationModal_Input_PrescribedBy',
    CreateMedicationModal_Input_PrescriptionDate: 'CreateMedicationModal_Input_PrescriptionDate',
    CreateMedicationModal_Input_Purpose: 'CreateMedicationModal_Input_Purpose',
    CreateMedicationModal_Input_Quantity: 'CreateMedicationModal_Input_Quantity',
    CreateMedicationModal_Input_StartDate: 'CreateMedicationModal_Input_StartDate',
    CreateMedicationModal_Input_Units: 'CreateMedicationModal_Input_Units',
    CreateOrganizationModal_OrgName: 'CreateOrganizationModal_OrgName',
    CreateOrganizationModal_OrgIdentifier: 'CreateOrganizationModal_OrgIdentifier',
    CreateImportModal_Facility: 'CreateImportModal_Facility',
    CreateImportModal_Template: 'CreateImportModal_Template',
    CreateImportModal_Title: 'CreateImportModal_Title',
    CustomerContactModal_Email: 'CustomerContactModal_Email',
    CustomerContactModal_Facility: 'CustomerContactModal_Facility',
    CustomerContactModal_FirstName: 'CustomerContactModal_FirstName',
    CustomerContactModal_LastName: 'CustomerContactModal_LastName',
    CustomerContactModal_Phone: 'CustomerContactModal_Phone',
    CustomerContactModal_Title: 'CustomerContactModal_Title',
    CustomerDocumentModal_Type: 'CustomerDocumentModal_Type',
    CustomerDocumentModal_Title: 'CustomerDocumentModal_Title',
    CustomerDocumentModal_Url: 'CustomerDocumentModal_Url',
    CustomerDocumentModal_Status: 'CustomerDocumentModal_Status',
    CustomerDocumentModal_DateCompleted: 'CustomerDocumentModal_DateCompleted',
    CustomerDocumentModal_Facility: 'CustomerDocumentModal_Facility',
    CustomerModal_CustomerName: 'CustomerModal_CustomerName',
    CustomerModal_CustomerStreetAddress1: 'CustomerModal_CustomerStreetAddress1',
    CustomerModal_CustomerStreetAddress2: 'CustomerModal_CustomerStreetAddress2',
    CustomerModal_CustomerCity: 'CustomerModal_CustomerCity',
    CustomerModal_CustomerState: 'CustomerModal_CustomerState',
    CustomerModal_CustomerPostalCode: 'CustomerModal_CustomerPostalCode',
    CustomerModal_CustomerType: 'CustomerModal_CustomerType',
    CustomerStatusModal_CustomerStatus: 'CustomerStatusModal_CustomerStatus',
    CustomerStatusModal_InitialContractDate: 'CustomerStatusModal_InitialContractDate',
    CustomerStatusModal_IsDemoCustomer: 'CustomerStatusModal_IsDemoCustomer',
    CustomerStatusModal_IsTrialCustomer: 'CustomerStatusModal_IsTrialCustomer',
    CustomerStatusModal_OffboardDate: 'CustomerStatusModal_OffboardDate',
    CustomerStatusModal_OffboardedToCustomer: 'CustomerStatusModal_OffboardedToCustomer',
    CustomerStatusModal_OffboardReason: 'CustomerStatusModal_OffboardReason',
    CustomerModal_OtherOffboardReason: 'CustomerModal_OtherOffboardReason',
    DatePicker_Input_Wrap: 'DatePicker_Input_Wrap',
    DatePicker_Label: 'DatePicker_Label',
    Devices_Table_Deactivate: 'Devices_Table_Deactivate',
    Devices_Table_Edit: 'Devices_Table_Edit',
    Devices_Table_Reactivate: 'Devices_Table_Reactivate',
    DiscreteForm_AddForm: 'DiscreteForm_AddForm',
    DiscreteForm_AddPage: 'DiscreteForm_AddPage',
    DiscreteForm_CameraInput: 'DiscreteForm_CameraInput',
    DiscreteForm_FileInput: 'DiscreteForm_FileInput',
    DiscreteForm_ListInput: 'DiscreteForm_ListInput',
    DiscreteForm_NumberInput: 'DiscreteForm_NumberInput',
    DiscreteForm_SaveForm: 'DiscreteForm_SaveForm',
    DiscreteForm_SelectPage: 'DiscreteForm_SelectPage',
    DiscreteForm_TextInput: 'DiscreteForm_TextInput',
    EncounterForm_ViewForm_EditForm: 'EncounterForm_ViewForm_EditForm',
    EncounterForm_ViewForm_UnvoidForm: 'EncounterForm_ViewForm_UnvoidForm',
    EncounterForm_ViewForm_ViewForm: 'EncounterForm_ViewForm_ViewForm',
    EncounterForm_ViewForm_VoidForm: 'EncounterForm_ViewForm_VoidForm',
    EncounterForm_ViewForm_DownloadForm: 'EncounterForm_ViewForm_DownloadForm',
    EventDetailsCancelButton: 'EventDetailsCancelButton',
    EventDetailsCloseButton: 'EventDetailsCloseButton',
    EventDetailsDeleteButton: 'EventDetailsDeleteButton',
    EventDetailsDoneButton: 'EventDetailsDoneButton',
    EventDetailsMoreButton: 'EventDetailsMoreButton',
    EventDetailsEditButton: 'EventDetailsEditButton',
    EventDetailsVisitButton: 'EventDetailsVisitButton',
    EventDetailsMarkCompleteButton: 'EventDetailsMarkCompleteButton',
    Facilities_Table_Deactivate: 'Facilities_Table_Deactivate',
    Facilities_Table_Edit: 'Facilities_Table_Edit',
    Facilities_Table_Reactivate: 'Facilities_Table_Reactivate',
    FacilitySubscriptionDataModal_FacilityMerged: 'FacilitySubscriptionDataModal_FacilityMerged',
    FacilitySubscriptionDataModal_FacilityStatus: 'FacilitySubscriptionDataModal_FacilityStatus',
    FacilitySubscriptionDataModal_FacilityType: 'FacilitySubscriptionDataModal_FacilityType',
    FacilitySubscriptionDataModal_GoLiveDate: 'FacilitySubscriptionDataModal_GoLiveDate',
    FacilitySubscriptionDataModal_IsReportingMacra: 'FacilitySubscriptionDataModal_IsReportingMacra',
    FacilitySubscriptionDataModal_MacraDataEntryServicer: 'FacilitySubscriptionDataModal_MacraDataEntryServicer',
    FacilitySubscriptionDataModal_MacraFormDataEntry: 'FacilitySubscriptionDataModal_MacraFormDataEntry',
    FacilitySubscriptionDataModal_OffboardDate: 'FacilitySubscriptionDataModal_OffboardDate',
    FacilitySubscriptionDataModal_OffboardReason: 'FacilitySubscriptionDataModal_OffboardReason',
    FacilitySubscriptionDataModal_OtherOffboardReason: 'FacilitySubscriptionDataModal_OtherOffboardReason',
    FacilitySubscriptionDataModal_OrderDate: 'FacilitySubscriptionDataModal_OrderDate',
    FacilitySubscriptionDataModal_PrimaryCaseCaptureMode: 'FacilitySubscriptionDataModal_PrimaryCaseCaptureMode',
    FacilitySubscriptionDataModal_PrimaryUse: 'FacilitySubscriptionDataModal_PrimaryUse',
    Filtered_Search_Add_Button: 'Filtered_Search_Add_Button',
    Filtered_Search_Open_Button: 'Filtered_Search_Open_Button',
    FlowScriptTemplateDetail_Back: 'FlowScriptTemplateDetail_Back',
    FlowScriptTemplateDetail_Edit: 'FlowScriptTemplateDetail_Edit',
    FlowScriptTemplateDetail_NewVersion: 'FlowScriptTemplateDetail_NewVersion',
    FlowScriptTemplateDetail_Publish: 'FlowScriptTemplateDetail_Publish',
    FlowScriptTemplateDetail_Save: 'FlowScriptTemplateDetail_Save',
    FlowScriptTemplateDetail_View: 'FlowScriptTemplateDetail_View',
    FlowScriptTemplateModal_Description: 'FlowScriptTemplateModal_Description',
    FlowScriptTemplateModal_Title: 'FlowScriptTemplateModal_Title',
    FlowScriptTemplateModal_Type: 'FlowScriptTemplateModal_Type',
    FlowScriptTemplates_Activation: 'FlowScriptTemplates_Activation',
    FlowScriptTemplates_CreateTemplate: 'FlowScriptTemplates_CreateTemplate',
    FlowScriptTemplates_Edit: 'FlowScriptTemplates_Edit',
    FlowScriptTemplates_ViewDetails: 'FlowScriptTemplates_ViewDetails',
    Footer_Copyright: 'Footer_Copyright',
    ForgotPassword_Reset: 'ForgotPassword_Reset',
    FormAssetModal_AssetName: 'FormAssetModal_AssetName',
    FormAssetModal_AssetDescription: 'FormAssetModal_AssetDescription',
    FormAssets_ViewAsset: 'FormAssets_ViewAsset',
    FormModelModal_ModelName: 'FormModelModal_ModelName',
    FormModelModal_ModelDescription: 'FormModelModal_ModelDescription',
    FormModelModal_ModelContent: 'FormModelModal_ModelContent',
    FormCatalogModal_FormName: 'FormCatalogModal_FormName',
    FormCatalogModal_FormTitle: 'FormCatalogModal_FormTitle',
    FormCatalogModal_FormDescription: 'FormCatalogModal_FormDescription',
    FormCatalogVersionModal_FormVersionName: 'FormCatalogVersionModal_FormVersionName',
    FormCatalogVersionModal_FormVersionDescription: 'FormCatalogVersionModal_FormVersionDescription',
    FormCatalogPageModal_PageName: 'FormCatalogPageModal_PageName',
    FormCatalogPageModal_MinCount: 'FormCatalogPageModal_MinCount',
    FormCatalogPageModal_MaxCount: 'FormCatalogPageModal_MaxCount',
    FormCatalogPageModal_PageImage: 'FormCatalogPageModal_PageImage',
    FormCatalogPages_ViewPage: 'FormCatalogPages_ViewPage',
    Gender_Label: 'Gender_Label',
    Import_Assign: 'Import_Assign',
    Import_Create: 'Import_Create',
    Import_Ignore: 'Import_Ignore',
    Import_ViewDetails: 'Import_ViewDetails',
    ImportBatchTemplateModal_Description: 'ImportBatchTemplateModal_Description',
    ImportBatchTemplateModal_FlowScriptTemplate: 'ImportBatchTemplateModal_FlowScriptTemplate',
    ImportBatchTemplateModal_Review: 'ImportBatchTemplateModal_Review',
    ImportBatchTemplateModal_TargetDataModel: 'ImportBatchTemplateModal_TargetDataModel',
    ImportBatchTemplateModal_Title: 'ImportBatchTemplateModal_Title',
    ImportBatchTemplates_CreateTemplate: 'ImportBatchTemplates_CreateTemplate',
    ImportBatchTemplates_Edit: 'ImportBatchTemplates_Edit',
    ImportDetail_EditRecord: 'ImportDetail_EditRecord',
    ImportDetail_ErrorIcon: 'ImportDetail_ErrorIcon',
    ImportDetail_Ignore: 'ImportDetail_Ignore',
    ImportDetail_IgnoreSelected: 'ImportDetail_IgnoreSelected',
    ImportDetail_RefreshRecords: 'ImportDetail_RefreshRecords',
    ImportDetail_Reprocess: 'ImportDetail_Reprocess',
    ImportDetail_ReprocessSelected: 'ImportDetail_ReprocessSelected',
    ImportDetail_SelectAll: 'ImportDetail_SelectAll',
    ImportDetail_SelectRecord: 'ImportDetail_SelectRecord',
    ImportDetail_Unignore: 'ImportDetail_Unignore',
    Invitations_CreateInvitation: 'Invitations_CreateInvitation',
    Invitations_Table_Cancel: 'Invitations_Table_Cancel',
    Invitations_Table_Details: 'Invitations_Table_Details',
    Invitations_Table_Resend: 'Invitations_Table_Resend',
    InviteAccept_Create: 'InviteAccept_Create',
    InviteAccept_Login: 'InviteAccept_Login',
    Login_ForgotPassword: 'Login_ForgotPassword',
    Login_LoginButton: 'Login_LoginButton',
    LogoutButton: 'LogoutButton',
    MacraAQIExportModal_PracticeID: 'MacraAQIExportModal_PracticeID',
    MacraAQIExportModal_TaxID: 'MacraAQIExportModal_TaxID',
    ModalFooter_Cancel: 'ModalFooter_Cancel',
    ModalFooter_Submit: 'ModalFooter_Submit',
    Nav_OrgSwitch: 'Nav_OrgSwitch',
    Nav_Search: 'Nav_Search',
    Nav_Settings: 'Nav_Settings',
    Not_Value_Button: 'Not_Value_Button',
    Not_Value_Button_Date_Time: 'Not_Value_Button_Date_Time',
    Pagination_Container: 'Pagination_Container',
    Pagination_Next: 'Pagination_Next',
    Pagination_Page: 'Pagination_Page',
    Pagination_Previous: 'Pagination_Previous',
    PasswordReset_ConfirmPassword: 'PasswordReset_ConfirmPassword',
    PasswordReset_GoToLogin: 'PasswordReset_GoToLogin',
    PasswordReset_NewPassword: 'PasswordReset_NewPassword',
    PasswordReset_SetPassword: 'PasswordReset_SetPassword',
    PatientDetails_Appointments_Create: 'PatientDetails_Appointments_Create',
    PatientDetails_Appointments_Table_Details: 'PatientDetails_Appointments_Table_Details',
    PatientDetails_Attachments_Create: 'PatientDetails_Attachments_Create',
    PatientDetails_ContactInfo_Cancel: 'PatientDetails_ContactInfo_Cancel',
    PatientDetails_ContactInfo_Edit: 'PatientDetails_ContactInfo_Edit',
    PatientDetails_ContactInfo_Save: 'PatientDetails_ContactInfo_Save',
    PatientDetails_Medications_Create: 'PatientDetails_Medications_Create',
    PatientDetails_Medications_Table_Edit: 'PatientDetails_Medications_Table_Edit',
    PatientDetails_Needs_Create: 'PatientDetails_Needs_Create',
    PatientDetails_Needs_Table_Edit: 'PatientDetails_Needs_Table_Edit',
    PatientDetails_Notes_Create: 'PatientDetails_Notes_Create',
    PatientDetails_Notes_CreateAttachment: 'PatientDetails_Notes_CreateAttachment',
    PatientDetails_Notes_Delete: 'PatientDetails_Notes_Delete',
    PatientDetails_Notes_Edit: 'PatientDetails_Notes_Edit',
    PatientDetails_Notes_ViewAttachment: 'PatientDetails_Notes_ViewAttachment',
    PatientDetails_OtherInfo_Cancel: 'PatientDetails_OtherInfo_Cancel',
    PatientDetails_OtherInfo_Edit: 'PatientDetails_OtherInfo_Edit',
    PatientDetails_OtherInfo_Save: 'PatientDetails_OtherInfo_Save',
    PatientDetails_PatientInfo_Cancel: 'PatientDetails_PatientInfo_Cancel',
    PatientDetails_PatientInfo_Edit: 'PatientDetails_PatientInfo_Edit',
    PatientDetails_PatientInfo_Save: 'PatientDetails_PatientInfo_Save',
    PatientDetails_PatientInfo_ViewSSN: 'PatientDetails_PatientInfo_ViewSSN',
    PatientDetails_QuickActions_Appointment: 'PatientDetails_QuickActions_Appointment',
    PatientDetails_QuickActions_Attachment: 'PatientDetails_QuickActions_Attachment',
    PatientDetails_QuickActions_Need: 'PatientDetails_QuickActions_Need',
    PatientDetails_QuickActions_Note: 'PatientDetails_QuickActions_Note',
    PatientDetails_QuickActions_Task: 'PatientDetails_QuickActions_Task',
    PatientDetails_QuickActions_Visit: 'PatientDetails_QuickActions_Visit',
    PatientDetails_Tabs_Activity: 'PatientDetails_Tabs_Activity',
    PatientDetails_Tabs_Appointments: 'PatientDetails_Tabs_Appointments',
    PatientDetails_Tabs_Labs: 'PatientDetails_Tabs_Labs',
    PatientDetails_Tabs_Medications: 'PatientDetails_Tabs_Medications',
    PatientDetails_Tabs_Needs: 'PatientDetails_Tabs_Needs',
    PatientDetails_Tabs_NotesAttachments: 'PatientDetails_Tabs_NotesAttachments',
    PatientDetails_Tabs_Tasks: 'PatientDetails_Tabs_Tasks',
    PatientDetails_Tabs_Visits: 'PatientDetails_Tabs_Visits',
    PatientDetails_Tabs_Vitals: 'PatientDetails_Tabs_Vitals',
    PatientDetails_Tasks_Create: 'PatientDetails_Tasks_Create',
    PatientDetails_Tasks_Table_Details: 'PatientDetails_Tasks_Table_Details',
    PatientDetails_Visits_AddForm: 'PatientDetails_Visits_AddForm',
    PatientDetails_Visits_Create: 'PatientDetails_Visits_Create',
    PatientDetails_Visits_Edit: 'PatientDetails_Visits_Edit',
    PatientDetails_Visits_Table_EditForm: 'PatientDetails_Visits_Table_EditForm',
    PatientDetails_Visits_Table_UnvoidForm: 'PatientDetails_Visits_Table_UnvoidForm',
    PatientDetails_Visits_Table_ViewForm: 'PatientDetails_Visits_Table_ViewForm',
    PatientDetails_Visits_Table_VoidForm: 'PatientDetails_Visits_Table_VoidForm',
    PatientDetails_PatientName: 'PatientDetails_PatientName',
    Patients_Create: 'Patients_Create',
    Patients_Details: 'Patients_Details',
    PatientVideo_Date_Input: 'PatientVideo_Date_Input',
    PatientVideo_Submit_Button: 'PatientVideo_Submit_Button',
    Payer_Create: 'Payer_Create',
    Payer_Edit: 'Payer_Edit',
    PayerCategory_Activate: 'PayerCategory_Activate',
    PayerCategory_Create: 'PayerCategory_Create',
    PayerCategory_Edit: 'PayerCategory_Edit',
    PayerCategoryModal_Code: 'PayerCategoryModal_Code',
    PayerCategoryModal_Description: 'PayerCategoryModal_Description',
    PayerCategoryModal_Title: 'PayerCategoryModal_Title',
    PayerModal_Category: 'PayerModal_Category',
    PayerModal_Name: 'PayerModal_Name',
    PaymentGateway_Activate: 'PaymentGateway_Activate',
    PaymentGateway_Create: 'PaymentGateway_Create',
    PaymentGateway_Edit: 'PaymentGateway_Edit',
    PaymentGatewayModal_Description: 'PaymentGatewayModal_Description',
    PaymentGatewayModal_StripeApiKey: 'PaymentGatewayModal_StripeApiKey',
    PaymentGatewayModal_StripeSecretKey: 'PaymentGatewayModal_StripeSecretKey',
    PaymentGatewayModal_StripeTestMode: 'PaymentGatewayModal_StripeTestMode',
    PaymentGatewayModal_Title: 'PaymentGatewayModal_Title',
    PaymentGatewayModal_Type: 'PaymentGatewayModal_Type',
    PaymentRequest_Cancel: 'PaymentRequest_Cancel',
    PaymentRequest_Collect: 'PaymentRequest_Collect',
    PaymentRequest_Resend: 'PaymentRequest_Send',
    PaymentRequestDetails_Back: 'PaymentRequestDetails_Back',
    PaymentType_Activate: 'PaymentType_Activate',
    PaymentType_Create: 'PaymentType_Create',
    PaymentType_Edit: 'PaymentType_Edit',
    PaymentType_EditEmail: 'PaymentType_EditEmail',
    PaymentType_EditInput: 'PaymentType_EditInput',
    PaymentType_EditLanding: 'PaymentType_EditLanding',
    PaymentType_EditSMS: 'PaymentType_EditSMS',
    PaymentTypeModal_DefaultAmount: 'PaymentTypeModal_DefaultAmount',
    PaymentTypeModal_Description: 'PaymentTypeModal_Description',
    PaymentTypeModal_ExcludeCategory: 'PaymentTypeModal_ExcludeCategory',
    PaymentTypeModal_Gateway: 'PaymentTypeModal_Gateway',
    PaymentTypeModal_IgnoreMultipleInsurance: 'PaymentTypeModal_IgnoreMultipleInsurance',
    PaymentTypeModal_IsOrgWide: 'PaymentTypeModal_IsOrgWide',
    PaymentTypeModal_PayerCategory: 'PaymentTypeModal_PayerCategory',
    PaymentTypeModal_Title: 'PaymentTypeModal_Title',
    Profile_Edit_Cancel: 'Profile_Edit_Cancel',
    Profile_Edit_Open: 'Profile_Edit_Open',
    Profile_Edit_Save: 'Profile_Edit_Save',
    Providers_Content: 'Providers_Content',
    Providers_Create: 'Providers_Create',
    Providers_Table_Deactivate: 'Providers_Table_Deactivate',
    Providers_Table_Edit: 'Providers_Table_Edit',
    Providers_Table_Reactivate: 'Providers_Table_Reactivate',
    Providers_Table: 'Providers_Table',
    Providers_Title: 'Providers_Title',
    RefListDetails_Back: 'RefListDetails_Back',
    RefListDetails_New: 'RefListDetails_New',
    RefListDetails_NewExtended: 'RefListDetails_NewExtended',
    RefListDetails_PropertyActivate: 'RefListDetails_PropertyActivate',
    RefListDetails_Table_Activate: 'RefListDetails_Table_Activate',
    RefListDetails_Table_Edit: 'RefListDetails_Table_Edit',
    RefListDetails_Table_ExcludeFacilities: 'RefListDetails_Table_ExcludeFacilities',
    RefLists_Table_Activate: 'RefLists_Table_Activate',
    RefLists_Create: 'RefLists_Create',
    RefLists_Table_Details: 'RefLists_Table_Details',
    RefLists_Table_Edit: 'RefLists_Table_Edit',
    Report_Column: 'Report_Column',
    Report_Pie: 'Report_Pie',
    Report_Table: 'Report_Table',
    Report_ViewSelector: 'Report_ViewSelector',
    SideModal: 'SideModal',
    Tags_Create: 'Tags_Create',
    Tags_Table_Deactivate: 'Tags_Table_Deactivate',
    Tags_Table_Edit: 'Tags_Table_Edit',
    Tags_Table_Reactivate: 'Tags_Table_Reactivate',
    Teams_Create: 'Teams_Create',
    Teams_Table_Deactivate: 'Teams_Table_Deactivate',
    Teams_Table_Edit: 'Teams_Table_Edit',
    Teams_Table_Reactivate: 'Teams_Table_Reactivate',
    Teams_Table_ViewUsers: 'Teams_Table_ViewUsers',
    Telehealth_CancelVisit: 'Telehealth_CancelVisit',
    Telehealth_CompleteVisit: 'Telehealth_CompleteVisit',
    TelehealthSessionVideo_StartCall: 'TelehealthSessionVideo_StartCall',
    TelehealthSessionVideo_Cancel: 'TelehealthSessionVideo_Cancel',
    TelehealthSessionVideo_RejoinCall: 'TelehealthSessionVideo_RejoinCall',
    TelehealthSessionVideo_EndCall: 'TelehealthSessionVideo_EndCall',
    TelehealthSessionVideo_CancelCall: 'TelehealthSessionVideo_CancelCall',
    TelehealthSessionVideo_CancelGoBack: 'TelehealthSessionVideo_CancelGoBack',
    TelehealthSessionVideo_CompleteCall: 'TelehealthSessionVideo_CompleteCall',
    Toast_Close: 'Toast_Close',
    TransportFacilities_Table_Deactivate: 'TransportFacilities_Table_Deactivate',
    TransportFacilities_Table_Edit: 'TransportFacilities_Table_Edit',
    TransportFacilities_Table_Reactivate: 'TransportFacilities_Table_Reactivate',
    Users_CreateInvitation: 'Users_CreateInvitation',
    Users_Show_Deactivated: 'Users_Show_Deactivated',
    Users_Show_Activated: 'Users_Show_Activated',
    Users_Table_Activate: 'Users_Table_Activate',
    Users_Table_Edit: 'Users_Table_Edit',
    Users_Table_Facilities: 'Users_Table_Facilities',
    Users_Table_Teams: 'Users_Table_Teams',
    ValueSetDetail_New: 'ValueSetDetail_New',
    Value_Sets_Table_Info: 'Value_Sets_Table_Info',
    ValueSetDetail_Table_Edit: 'ValueSetDetail_Table_Edit',
    ValueSetDetail_Table_Activate: 'ValueSetDetail_Table_Activate',
    Vehicles_Table_Deactivate: 'Vehicles_Table_Deactivate',
    Vehicles_Table_Edit: 'Vehicles_Table_Edit',
    Vehicles_Table_Reactivate: 'Vehicles_Table_Reactivate',
    VitalsConnection_Activate: 'VitalsConnection_Activate',
    VitalsConnectionModal_ConnectionNotes: 'VitalsConnectionModal_ConnectionNotes',
    VitalsConnection_Create: 'VitalsConnection_Create',
    VitalsConnectionModal_DisplayName: 'VitalsConnectionModal_DisplayName',
    VitalsConnection_Edit: 'VitalsConnection_Edit',
    VitalsConnectionModal_FacilityId: 'VitalsConnectionModal_FacilityId',
    VitalsConnectionModal_UnitIdentifier: 'VitalsConnectionModal_UnitIdentifier',
    VitalsConnectionModal_UnitVendorName: 'VitalsConnectionModal_UnitVendorName',
};
