import React, { useState, useContext, useEffect } from 'react';
import styles from './styles.module.scss';
import SideModal from 'src/components/SideModal';
import Input, { Label } from 'src/components/Input';
import ModalHeader from 'src/components/ModalHeader';
import OptionDropDown, { OptionTypes } from '../OptionDropDown';
import * as utils from 'src/utils';
import ModalFooter from '../ModalFooter';
import { ToastStoreObject, ToastType } from 'src/stores/ToastStore';
import PatientMedicationStore, { PatientMedication } from 'src/stores/PatientMedicationStore';
import { Facility } from 'src/stores/FacilityStore';
import DatePicker from '../DatePicker';
import { format } from 'date-fns';
import { API_DATE_FORMAT } from 'src/utils/constants';
import { ANALYTICS_NAMES } from 'src/utils/analytics';

export interface CreateMedicationModalProps {
    onClose: Function;
    onSave: Function;
    patientId: number;
    facilityId: Facility['facId'];
    medication?: PatientMedication;
}
export function CreateMedicationModal(props: CreateMedicationModalProps) {
    const patientMedicationStore = useContext(PatientMedicationStore);

    const defaultMedication: PatientMedication = {
        medicationName: '',
        medicationBrandName: '',
        opioidIndicator: null,
        medicationDoseAmount: '',
        medicationDoseUnits: '',
        medicationAdminRoute: '',
        medicationFrequency: '',
        directedUsageType: '',
        medicationInstructions: '',
        medicationTotalQty: null,
        medicationStartDate: null,
        medicationEndDate: null,
        medicationPurpose: '',
        prescribedBy: '',
        prescriptionDate: null,
    };

    const [medication, setMedication] = useState<PatientMedication>(props.medication || defaultMedication);
    const [isLoading, setIsLoading] = useState(false);
    const [isEdit, setIsEdit] = useState(props.medication ? true : false);

    useEffect(() => {
        if (props.medication) {
            setMedication(props.medication);
            setIsEdit(true);
        }
    }, [props.medication]);

    const updateInput = (param: string) => (t: string) => setMedication({ ...medication, [param]: t });
    function handleUpdate(param: string, value: any) {
        setMedication({ ...medication, [param]: value });
    }

    async function createMedication(medication: PatientMedication) {
        // create medication
        try {
            setIsLoading(true);
            if (isEdit) {
                await updateMedication(medication);
            } else {
                await patientMedicationStore.createPatientMedication(
                    { patientId: props.patientId, facId: props.facilityId },
                    medication,
                );
            }
        } catch (error) {
            ToastStoreObject.show(utils.parseError(error));
        } finally {
            setIsLoading(false);
            setMedication(defaultMedication);
            props.onSave();
        }
    }

    async function updateMedication(medication: PatientMedication) {
        // update medication
        const fieldsArr = [
            'medicationName',
            'medicationBrandName',
            'medicationDoseAmount',
            'medicationDoseUnits',
            'medicationAdminRoute',
            'medicationFrequency',
            'directedUsageType',
            'medicationInstructions',
            'medicationTotalQty',
            'medicationStartDate',
            'medicationEndDate',
            'medicationPurpose',
            'prescribedBy',
            'prescriptionDate',
            'opioidIndicator',
        ];
        if (!utils.haveFieldsChanged(props.medication, medication, fieldsArr)) {
            return;
        }
        const changedData = utils.onlyChangedFields(props.medication, medication, fieldsArr);
        await patientMedicationStore.updatePatientMedication(
            { patientId: props.patientId, facId: props.facilityId },
            medication.patientMedicationId,
            changedData,
        );
    }

    return (
        <SideModal isOpen={true} onClose={() => props.onClose()}>
            <ModalHeader title={isEdit ? 'Edit Medication' : 'Add a Medication'} onClose={() => props.onClose()} />
            <div className={styles.inviteModalContentWrap}>
                <Input
                    data-test-id={ANALYTICS_NAMES.CreateMedicationModal_Input_Name}
                    className={styles.input}
                    label="Medication Name"
                    value={medication.medicationName}
                    onChangeText={updateInput('medicationName')}
                />
                <Input
                    data-test-id={ANALYTICS_NAMES.CreateMedicationModal_Input_BrandName}
                    className={styles.input}
                    label="Medication Brand Name"
                    value={medication.medicationBrandName}
                    onChangeText={updateInput('medicationBrandName')}
                />
                <div>
                    <Label text="Is this an Opioid?" required={true} />
                    <OptionDropDown
                        data-test-id={ANALYTICS_NAMES.CreateMedicationModal_Input_Opioid}
                        selectedValue={medication.opioidIndicator}
                        onChange={(s: any) => handleUpdate('opioidIndicator', s.value)}
                        type={OptionTypes.yesNo}
                    />
                </div>
                <Input
                    data-test-id={ANALYTICS_NAMES.CreateMedicationModal_Input_DoseAmount}
                    className={styles.input}
                    type="number"
                    label="Medication Dose Amount"
                    value={medication.medicationDoseAmount}
                    onChangeText={updateInput('medicationDoseAmount')}
                />
                <div>
                    <Label text="Medication Units" />
                    <OptionDropDown
                        data-test-id={ANALYTICS_NAMES.CreateMedicationModal_Input_Units}
                        facId={props.facilityId}
                        selectedValue={medication.medicationDoseUnits}
                        onChange={(s: any) => handleUpdate('medicationDoseUnits', s.value)}
                        type={OptionTypes.MedicationUnitsOfMeasure}
                    />
                </div>
                <div>
                    <Label text="Medication Admin route" />
                    <OptionDropDown
                        data-test-id={ANALYTICS_NAMES.CreateMedicationModal_Input_AdminRoute}
                        facId={props.facilityId}
                        selectedValue={medication.medicationAdminRoute}
                        onChange={(s: any) => handleUpdate('medicationAdminRoute', s.value)}
                        type={OptionTypes.MedicationRouteTypes}
                    />
                </div>
                <div>
                    <Label text="Medication Frequency" />
                    <OptionDropDown
                        data-test-id={ANALYTICS_NAMES.CreateMedicationModal_Input_Frequency}
                        facId={props.facilityId}
                        selectedValue={medication.medicationFrequency}
                        onChange={(s: any) => handleUpdate('medicationFrequency', s.value)}
                        type={OptionTypes.MedicationFrequencyTypes}
                    />
                </div>
                <div>
                    <Label text="Directed Usage Type" />
                    <OptionDropDown
                        data-test-id={ANALYTICS_NAMES.CreateMedicationModal_Input_DirectedUsage}
                        facId={props.facilityId}
                        selectedValue={medication.directedUsageType}
                        onChange={(s: any) => handleUpdate('directedUsageType', s.value)}
                        type={OptionTypes.MedicationDirectedUsageTypes}
                    />
                </div>
                <Input
                    data-test-id={ANALYTICS_NAMES.CreateMedicationModal_Input_Instructions}
                    className={styles.input}
                    label="Medication Instructions"
                    value={medication.medicationInstructions}
                    onChangeText={updateInput('medicationInstructions')}
                />
                <Input
                    data-test-id={ANALYTICS_NAMES.CreateMedicationModal_Input_Quantity}
                    className={styles.input}
                    type="number"
                    label="Medication Total Quantity"
                    value={medication.medicationTotalQty}
                    onChangeText={updateInput('medicationTotalQty')}
                />
                <Input
                    data-test-id={ANALYTICS_NAMES.CreateMedicationModal_Input_Purpose}
                    className={styles.input}
                    label="Medication Purpose"
                    value={medication.medicationPurpose}
                    onChangeText={updateInput('medicationPurpose')}
                />
                <DatePicker
                    data-test-id={ANALYTICS_NAMES.CreateMedicationModal_Input_StartDate}
                    label="Start Date"
                    value={medication.medicationStartDate}
                    onChange={(date: Date) => {
                        if (date) {
                            const value = format(date, API_DATE_FORMAT);
                            handleUpdate('medicationStartDate', value);
                        }
                    }}
                />
                <DatePicker
                    data-test-id={ANALYTICS_NAMES.CreateMedicationModal_Input_EndDate}
                    label="End Date"
                    value={medication.medicationEndDate}
                    onChange={(date: Date) => {
                        if (date) {
                            const value = format(date, API_DATE_FORMAT);
                            handleUpdate('medicationEndDate', value);
                        }
                    }}
                />
                <Input
                    data-test-id={ANALYTICS_NAMES.CreateMedicationModal_Input_PrescribedBy}
                    className={styles.input}
                    label="Prescribed By"
                    value={medication.prescribedBy}
                    onChangeText={updateInput('prescribedBy')}
                />
                <DatePicker
                    data-test-id={ANALYTICS_NAMES.CreateMedicationModal_Input_PrescriptionDate}
                    label="Prescription Date"
                    value={medication.prescriptionDate}
                    onChange={(date: Date) => {
                        if (date) {
                            const value = format(date, API_DATE_FORMAT);
                            handleUpdate('prescriptionDate', value);
                        }
                    }}
                />
            </div>
            <ModalFooter
                primaryText={props.medication ? 'Save' : 'Create'}
                primaryClick={async () => {
                    if (medication.opioidIndicator === null) {
                        ToastStoreObject.show('Please select whether this medication is an Opioid.', ToastType.Error);
                    } else {
                        await createMedication(medication);
                    }
                }}
                secondaryClick={() => {
                    props.onClose();
                    setMedication(defaultMedication);
                }}
                isLoading={isLoading}
            />
        </SideModal>
    );
}

export default CreateMedicationModal;
