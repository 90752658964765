import React, { useState, useRef, useEffect } from 'react';
import NavBar from 'src/containers/NavBar';
import PageContent from 'src/components/PageContent';
import PageContainer from 'src/components/PageContainer';
import TabNav from 'src/components/TabNav';
import Invitations from 'src/containers/Invitations';
import Users from 'src/containers/Users';
import styles from './styles.module.scss';

function UsersAndInvitations() {
    const [pageState, setPageState] = useState('users');
    const [isOpeningInvitationModal, setIsOpeningInvitationModal] = useState(false);

    useEffect(() => {
        if (pageState === 'invitations' && isOpeningInvitationModal) {
            invitationsRef.current.openInvitationsModal();
            tabRef.current.setTab('Invitations');
            setIsOpeningInvitationModal(false);
        }
    }, [pageState]);

    const invitationsRef = useRef(null);
    const tabRef = useRef(null);

    function handleTabClick(tab: string) {
        setPageState(tab.toLowerCase());
    }

    function openInvitationsModal() {
        setIsOpeningInvitationModal(true);
        setPageState('invitations');
    }

    return (
        <PageContainer>
            <NavBar />
            <PageContent>
                <div className={styles.tabContainer}>
                    <TabNav tabs={['Users', 'Invitations']} onTabClick={handleTabClick} ref={tabRef} />
                </div>
                <div>
                    {pageState === 'users' ? (
                        <Users onInvite={openInvitationsModal} />
                    ) : (
                        <Invitations ref={invitationsRef} />
                    )}
                </div>
            </PageContent>
        </PageContainer>
    );
}

export default UsersAndInvitations;
