import React, { useState, useEffect } from 'react';
import styles from './styles.module.scss';
import { OrgUser } from 'src/stores/OrgUserStore';
import CenterModal from '../CenterModal';
import * as adminService from 'src/api/admin';
import * as utils from 'src/utils';
import { ToastStoreObject, ToastType } from 'src/stores/ToastStore';
import LoadingIcon from '../LoadingIcon';
import Flex from '../Flex';
import ModalHeader from '../ModalHeader';
import ModalFooter from '../ModalFooter';

export interface AdminAuth0UserStatusProps {
    onClose: Function;
    user: OrgUser;
}

function AdminAuth0UserStatusModal(props: AdminAuth0UserStatusProps) {
    const [isLoading, setIsLoading] = useState(true);
    const [isBlocked, setIsBlocked] = useState(false);

    useEffect(() => {
        getBlockedStatus();
    }, []);

    async function getBlockedStatus() {
        setIsLoading(true);

        try {
            const blockStatus = await adminService.isUserBlocked(props.user.usrNm);
            setIsBlocked(blockStatus);
        } catch (err) {
            ToastStoreObject.show(utils.parseError(err));
        } finally {
            setIsLoading(false);
        }
    }

    async function handleUnblock() {
        setIsLoading(true);

        try {
            await adminService.unblockUser(props.user.usrNm);
            ToastStoreObject.show('Successfully unblocked user', ToastType.Success);
        } catch (err) {
            ToastStoreObject.show(utils.parseError(err));
        } finally {
            setIsLoading(false);
            props.onClose();
        }
    }

    return (
        <CenterModal isOpen={true} onClose={props.onClose}>
            <ModalHeader title="Checking Auth0 Status" />
            <Flex align="center" justify="center" className={styles.status}>
                {isLoading ? (
                    <LoadingIcon />
                ) : (
                    <div>{`User (${props.user.usrNm}) is ${isBlocked ? '' : 'not'} blocked`}</div>
                )}
            </Flex>
            <ModalFooter
                primaryText={isBlocked ? 'Unblock' : 'Close'}
                primaryClick={() => (isBlocked ? handleUnblock() : props.onClose())}
                primaryProps={{ disabled: isLoading }}
                secondaryClick={() => {
                    props.onClose();
                }}
                isLoading={isLoading}
            />
        </CenterModal>
    );
}

export default AdminAuth0UserStatusModal;
