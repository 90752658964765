import React from 'react';
import { format as formatDateFns, isValid, parseISO } from 'date-fns';
import { DISPLAY_DATE_FORMAT, DISPLAY_TIME_FORMAT } from 'src/utils/constants';
import { getTimeDate, isString } from 'src/utils';

interface DateComponentProps extends React.HTMLAttributes<HTMLSpanElement> {
    date: number | string | Date | null;
    format?: string;
    isTime?: boolean;
    showDateTime?: boolean;
}

// Format reference: https://date-fns.org/v1.30.1/docs/format
const DateComponent = React.memo(
    ({ date, format = DISPLAY_DATE_FORMAT, isTime, showDateTime, ...rest }: DateComponentProps) => {
        const showFormat = showDateTime ? `${DISPLAY_DATE_FORMAT} ${DISPLAY_TIME_FORMAT}` : format;
        let str;
        if (isTime && isString(date)) {
            str = formatDateFns(getTimeDate(date as string), DISPLAY_TIME_FORMAT);
        } else if (isString(date) && isValid(parseISO(date.toString()))) {
            const dateObject = new Date(date);
            const offsetDate = new Date(dateObject.valueOf() + dateObject.getTimezoneOffset() * 60 * 1000);
            str = formatDateFns(offsetDate, showFormat);
        } else if (date === null || !date) {
            str = '-';
        } else if (isValid(parseISO(date.toString()))) {
            str = formatDateFns(parseISO(date.toString()), showFormat);
        }
        if (!str || str === 'Invalid Date') {
            str = '-';
        }
        return <span {...rest}>{str}</span>;
    },
);
DateComponent.displayName = 'DateComponent';

export default DateComponent;
