import * as ROUTES from 'src/api/routes';
import request from './utils';
import { FormCatalog, FormCatalogPage } from 'common/lib/entity/framework/FormCatalog';
import { FormCatalogVersion } from 'common/lib/entity/framework/FormCatalogVersion';
import { FormDefinition } from 'common/lib/entity/org/FormDefinition';

export async function getFormCatalogs() {
    const result = await request({ ...ROUTES.GET_FORM_CATALOGS });
    return result?.data || [];
}

export async function getFormCatalogById(formCatalogId: FormCatalog['formCatalogId']) {
    const result = await request({ ...ROUTES.GET_FORM_CATALOG_BY_ID, pathParams: { formCatalogId } });
    return result?.data || {};
}

export async function createFormCatalog(formCatalog: Partial<FormCatalog>): Promise<FormCatalog['formCatalogId']> {
    const result = await request({ ...ROUTES.CREATE_FORM_CATALOG, data: { ...formCatalog } });
    return result?.data || {};
}

export async function importFormCatalog(data: {
    formName: FormCatalog['formName'];
    formTitle: FormCatalog['formTitle'];
    formModelId: FormCatalog['formModelId'];
    orgNameInternal: string;
    facilityId: FormDefinition['facId'];
    formDefinitionId: FormDefinition['formDefnId'];
}): Promise<FormCatalog['formCatalogId']> {
    const { formName, formTitle, formModelId, orgNameInternal, facilityId, formDefinitionId } = data;
    const result = await request({
        ...ROUTES.IMPORT_FORM_CATALOG,
        data: { formName, formTitle, formModelId, orgNameInternal, facilityId, formDefinitionId },
    });
    return result?.data || {};
}

export async function updateFormCatalog(
    formCatalogId: FormCatalog['formCatalogId'],
    formCatalog: Partial<FormCatalog>,
): Promise<void> {
    await request({ ...ROUTES.UPDATE_FORM_CATALOG, pathParams: { formCatalogId }, data: { ...formCatalog } });
}

export async function saveFormCatalogAsVersion(
    formCatalogId: FormCatalog['formCatalogId'],
    formVersionName: FormCatalogVersion['formVersionName'],
    formVersionDescription: FormCatalogVersion['formVersionDescription'],
) {
    const result = await request({
        ...ROUTES.SAVE_FORM_CATALOG_AS_VERSION,
        pathParams: { formCatalogId },
        data: { formVersionName, formVersionDescription },
    });
    return result?.data || {};
}

export async function addFormCatalogPage(
    formCatalogId: FormCatalog['formCatalogId'],
    page: Partial<FormCatalogPage>,
): Promise<{ pageId: FormCatalogPage['pageId'] }> {
    const result = await request({ ...ROUTES.ADD_FORM_CATALOG_PAGE, pathParams: { formCatalogId }, data: { ...page } });
    return result?.data || {};
}

export async function updateFormCatalogPage(data: {
    formCatalogId: FormCatalog['formCatalogId'];
    pageId: FormCatalogPage['pageId'];
    minCount?: FormCatalogPage['minCount'];
    maxCount?: FormCatalogPage['maxCount'];
    pageImage?: FormCatalogPage['originalPageImage'];
}): Promise<void> {
    const { formCatalogId, pageId, minCount, maxCount, pageImage } = data;
    await request({
        ...ROUTES.UPDATE_FORM_CATALOG_PAGE,
        pathParams: { formCatalogId, pageId },
        data: { minCount, maxCount, pageImage },
    });
}

export async function getFormCatalogPageUrl(
    formCatalogId: FormCatalog['formCatalogId'],
    pageId: FormCatalogPage['pageId'],
) {
    const result = await request({
        ...ROUTES.GET_FORM_CATALOG_PAGE_URL,
        pathParams: { formCatalogId, pageId },
    });
    return result?.data || {};
}
