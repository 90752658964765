import React from 'react';

const SvgNotesBookText = (props) => (
    <svg width={19} height={19} {...props} viewBox="0 0 19 19">
        <g
            stroke={props.color || '#FFF'}
            strokeWidth={1.15}
            fill="none"
            fillRule="evenodd"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path d="M17.469 16.33a1.103 1.103 0 01-1.063 1.139H2.594A1.103 1.103 0 011.53 16.33V4.795a1.103 1.103 0 011.063-1.139h13.812c.608.022 1.083.531 1.063 1.139V16.33zM4.719 1.531V4.72M7.906 1.531V4.72M11.094 1.531V4.72M14.281 1.531V4.72M5.781 7.906h7.438M5.781 11.094h7.438M5.781 14.281H9.5" />
        </g>
    </svg>
);

export default SvgNotesBookText;
