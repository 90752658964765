import { UseQueryResult, useQuery } from 'react-query';
import { getFormAssetById } from 'src/api/formAssets';
import { UserStoreObject } from 'src/stores/UserStore';
import { FormAsset } from 'common/lib/entity/framework/FormAsset';

export const formAssetKey = 'formAsset';

export const useFormAsset = (formAssetId: FormAsset['formAssetId']): UseQueryResult<FormAsset> => {
    return useQuery({
        queryKey: [formAssetKey],
        queryFn: async () => {
            return await getFormAssetById(formAssetId);
        },
        enabled: formAssetId && UserStoreObject.isGraphiumAdmin(),
        initialData: [],
    });
};
