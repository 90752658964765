import * as ROUTES from 'src/api/routes';
import request from 'src/api/utils';

export async function getReport(
    reportId: string,
    data: {
        facilityId: number;
        fromDate?: string;
        toDate?: string;
    },
) {
    const report = await request({ ...ROUTES.GET_REPORT, pathParams: { reportId }, params: { ...data } });
    return report.data || [];
}
