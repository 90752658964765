import { UseQueryResult, useQuery } from 'react-query';
import { getFormAssetTypes } from 'src/api/formAssets';
import { UserStoreObject } from 'src/stores/UserStore';
import { FormAssetType } from 'common/lib/entity/framework/FormAssetType';

export const formAssetTypesKey = 'formAssetTypes';

export const useFormAssetTypes = (): UseQueryResult<FormAssetType[]> => {
    return useQuery({
        queryKey: [formAssetTypesKey],
        queryFn: async () => {
            return await getFormAssetTypes();
        },
        enabled: UserStoreObject.isGraphiumAdmin(),
        initialData: [],
    });
};
