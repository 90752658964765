import { FormCatalog, FormCatalogPage } from 'common/lib/entity/framework/FormCatalog';
import React, { useEffect, useRef, useState } from 'react';
import Flex from 'src/components/Flex';
import styles from './styles.module.scss';
import Button from 'src/components/Button';
import Icon from 'src/components/Icon';
import { ICONS } from 'src/utils/constants';
import Card from 'src/components/Card';
import Table from 'src/components/Table';
import { CellInfo } from 'react-table';
import { ANALYTICS_NAMES } from 'src/utils/analytics';
import { useParams } from 'react-router-dom';
import { useFormCatalog } from 'src/queries/useFormCatalog';
import { ModalStoreObject, ModalTypes } from 'src/stores/ModalStore';
import Input, { Label } from 'src/components/Input';
import { useUpdateFormCatalogPage } from 'src/queries/useUpdateFormCatalogPage';
import { ToastStoreObject, ToastType } from 'src/stores/ToastStore';
import FormCatalogPageImage from './FormCatalogPageImage';

function Pages() {
    const { formCatalogId } = useParams<any>();
    const [formPages, setFormPages] = useState<FormCatalogPage[]>([]);
    const [selectedPage, setSelectedPage] = useState<FormCatalogPage | null>(null);
    const [minCount, setMinCount] = useState(null);
    const [maxCount, setMaxCount] = useState(null);
    const [pageImage, setPageImage] = useState(null);
    const [fileName, setFileName] = useState('');
    const [isChanged, setIsChanged] = useState(false);
    const { data: formCatalog } = useFormCatalog(formCatalogId);
    const {
        mutate: updateFormCatalogPage,
        isLoading: isUpdateFormCatalogPageLoading,
        isSuccess: isUpdateFormCatalogPageSuccess,
        isError: isUpdateFormCatalogPageError,
        error: updateFormCatalogPageError,
    } = useUpdateFormCatalogPage(formCatalogId);

    useEffect(() => {
        if (formCatalog) {
            setFormPages(formCatalog.formPages);
        }
    }, [formCatalog]);

    useEffect(() => {
        if (selectedPage) {
            setMinCount(selectedPage.minCount);
            setMaxCount(selectedPage.maxCount);
            setPageImage(selectedPage.originalPageImage);
            setFileName('');
        }
    }, [selectedPage]);

    useEffect(() => {
        if (isUpdateFormCatalogPageSuccess) {
            ToastStoreObject.show('Form catalog page updated successfully', ToastType.Success);
        }

        if (isUpdateFormCatalogPageError) {
            ToastStoreObject.show(updateFormCatalogPageError.message, ToastType.Error);
        }
    }, [isUpdateFormCatalogPageSuccess, isUpdateFormCatalogPageError]);

    const columns = [
        {
            Header: 'Name',
            accessor: 'pageName',
        },
        {
            Header: 'Min',
            maxWidth: 75,
            accessor: 'minCount',
        },
        {
            Header: 'Max',
            maxWidth: 75,
            accessor: 'maxCount',
        },
        {
            Header: 'Actions',
            maxWidth: 150,
            style: { paddingVertical: 0, paddingHorizontal: 20 },
            Cell: (props: CellInfo) => (
                <Flex gap={8}>
                    <Button
                        type="small"
                        text="View »"
                        data-test-id={ANALYTICS_NAMES.FormCatalogPages_ViewPage}
                        onClick={() => setSelectedPage(props.original)}
                    />
                </Flex>
            ),
        },
    ];

    function getPageImageContent() {
        return (
            <div className={styles.imageContainer}>
                <FormCatalogPageImage formCatalogId={formCatalogId} pageId={selectedPage?.pageId} />
            </div>
        );
    }

    async function handleSave() {
        if (selectedPage) {
            updateFormCatalogPage({
                pageId: selectedPage?.pageId,
                minCount,
                maxCount,
                pageImage,
            });
        }
    }

    return (
        <div className={styles.container}>
            <Flex direction="row" justify="end">
                <Button
                    leftIcon={<Icon className={styles.plusIcon} name={ICONS.PlusButton} />}
                    type="primary"
                    onClick={() => {
                        ModalStoreObject.showModal(ModalTypes.FormCatalogPageModal, {
                            formCatalogId,
                            onClose: () => ModalStoreObject.hideModal(),
                            onSave: () => {
                                ModalStoreObject.hideModal();
                            },
                        });
                    }}
                    text="New Page"
                />
            </Flex>
            <Flex direction="row" gap={24}>
                <Card className={styles.cardWrap}>
                    <Table columns={columns} data={formPages || []} showPagination={false} />
                </Card>
                <Card className={styles.cardWrap}>
                    {selectedPage ? (
                        <>
                            <Flex className={styles.header}>
                                <div className={styles.title}>{selectedPage?.pageName}</div>
                            </Flex>
                            <div className={styles.content}>
                                <Flex gap={16}>
                                    <Input
                                        type="number"
                                        label="Min"
                                        value={minCount}
                                        onChangeText={(v: number) => {
                                            setIsChanged(true);
                                            setMinCount(v);
                                        }}
                                    />
                                    <Input
                                        type="number"
                                        label="Max"
                                        value={maxCount}
                                        onChangeText={(v: number) => {
                                            setIsChanged(true);
                                            setMaxCount(v);
                                        }}
                                    />
                                </Flex>
                                {getPageImageContent()}
                                <>
                                    <div className={styles.replaceImage}>
                                        <Label text="Replace Image" className={styles.labelWrap} />
                                        <Flex align="center" justify="start">
                                            <input
                                                type="file"
                                                id="formPagesFileInput"
                                                className={styles.hidden}
                                                accept="image/png,image/jpeg,image/jpg"
                                                onChange={(e) => {
                                                    const uploadedFile = e.target.files[0];
                                                    if (!uploadedFile) {
                                                        return;
                                                    }

                                                    const reader = new FileReader();
                                                    reader.onload = (e) => {
                                                        if (e.target?.result) {
                                                            setPageImage(e.target.result.toString());
                                                        }
                                                    };
                                                    reader.readAsDataURL(uploadedFile);

                                                    setFileName(uploadedFile.name);
                                                    setIsChanged(true);
                                                }}
                                            />
                                            <label htmlFor="formPagesFileInput" className={styles.fileInputButton}>
                                                Choose a File
                                            </label>
                                            <span className={styles.fileName}>{`${
                                                fileName ? fileName : 'No File Chosen'
                                            }`}</span>
                                        </Flex>
                                    </div>
                                </>
                                <Flex justify="end" className={styles.controls}>
                                    <Button
                                        type="primary"
                                        onClick={() => handleSave()}
                                        text="Save"
                                        disabled={!isChanged || isUpdateFormCatalogPageLoading}
                                        isLoading={isUpdateFormCatalogPageLoading}
                                    />
                                </Flex>
                            </div>
                        </>
                    ) : (
                        <div className={styles.empty}>Select a page to view</div>
                    )}
                </Card>
            </Flex>
        </div>
    );
}

export default Pages;
