import React, { useState, forwardRef, useImperativeHandle } from 'react';
import styles from './styles.module.scss';
import Flex from 'src/components/Flex';
import classNames from 'classnames';

export interface TabItemProps {
    title: string;
    onClick: Function;
}

export interface TabNavProps {
    tabs: string[];
    onTabClick: Function;
    selectedTabIndex?: number;
}

const TabNav = forwardRef((props: TabNavProps, ref) => {
    useImperativeHandle(ref, () => ({
        setTab(tabName: string) {
            selectTab(tabName);
        },
    }));

    const [activeTab, setActiveTab] = useState(
        props.selectedTabIndex ? props.tabs[props.selectedTabIndex] : props.tabs[0] || '',
    );

    function selectTab(tabName: string) {
        if (activeTab === tabName) {
            return;
        }

        setActiveTab(tabName);
        props.onTabClick(tabName);
    }

    function TabItem(props: TabItemProps) {
        return (
            <Flex
                className={classNames([styles.tab], {
                    [styles.isActive]: activeTab === props.title,
                })}
                onClick={() => selectTab(props.title)}
                data-test-id={`TabNav_${props.title}`}
            >
                {props.title}
            </Flex>
        );
    }

    function generateTabs() {
        return props.tabs.map((tab, index) => (
            <TabItem key={index} title={tab} onClick={(title: string) => props.onTabClick(title)} />
        ));
    }

    return (
        <Flex className={styles.container} direction="row">
            {generateTabs()}
        </Flex>
    );
});
TabNav.displayName = 'TabNav';

export default TabNav;
