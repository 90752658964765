import React from 'react';

const SvgBell = (props) => (
    <svg width={24} height={27} {...props} viewBox="0 0 24 27">
        <g transform="translate(0 1)" fill="none" fillRule="evenodd">
            <path
                d="M22.509 18.398c-.988-1.055-2.835-2.64-2.835-7.835 0-3.946-2.785-7.105-6.54-7.88V1.625A1.63 1.63 0 0011.5 0a1.63 1.63 0 00-1.635 1.625v1.058c-3.754.775-6.539 3.934-6.539 7.88 0 5.194-1.847 6.78-2.835 7.835-.306.327-.442.719-.44 1.102a1.632 1.632 0 001.64 1.625h19.617c.977 0 1.636-.792 1.64-1.625a1.58 1.58 0 00-.44-1.102zm-19.006.29c1.084-1.42 2.27-3.775 2.276-8.096l-.003-.03c0-3.14 2.562-5.687 5.724-5.687 3.162 0 5.724 2.546 5.724 5.688 0 .01-.003.019-.003.029.006 4.321 1.192 6.676 2.276 8.095H3.503zM11.5 26a3.26 3.26 0 003.27-3.25H8.23A3.26 3.26 0 0011.5 26z"
                fill="#FFF"
                fillRule="nonzero"
            />
            <circle stroke="#0A1C43" strokeWidth={2} fill="#FFBB42" cx={18} cy={5} r={5} />
        </g>
    </svg>
);

export default SvgBell;
