import React from 'react';

const SvgIconspaceHospital = (props) => (
    <svg width={18} height={18} {...props} viewBox="0 0 18 18">
        <g fill="none" fillRule="evenodd">
            <path d="M-2-2h21.25v21.25H-2z" />
            <path
                d="M6.075 13.3a.425.425 0 00-.425.425V16.7h7.65v-2.975a.425.425 0 00-.425-.425H9.9v3.4h-.85v-3.4H6.075zM4.8 16.7v-2.975c0-.704.57-1.275 1.275-1.275h6.8c.704 0 1.275.57 1.275 1.275V16.7h1.275c.235 0 .425-.19.425-.425V5.382L9.05 3.95l-6.8 1.432v10.893c0 .235.19.425.425.425H4.8zm5.1-8.5h1.275a.425.425 0 010 .85H9.9v1.275a.425.425 0 11-.85 0V9.05H7.775a.425.425 0 110-.85H9.05V6.925a.425.425 0 11.85 0V8.2zm6.8 8.075c0 .704-.57 1.275-1.275 1.275H2.675c-.704 0-1.275-.57-1.275-1.275V5.561l-.337.07a.425.425 0 01-.513-.415v-2.55c0-.201.14-.375.337-.416L8.962.55h.176l8.075 1.7a.425.425 0 01.337.416v2.55c0 .27-.248.471-.513.416L16.7 5.56v10.714zM1.4 4.692L8.962 3.1h.176L16.7 4.692V3.011L9.05 1.4 1.4 3.01v1.681z"
                fill={props.color || '#FFF'}
                fillRule="nonzero"
            />
        </g>
    </svg>
);

export default SvgIconspaceHospital;
