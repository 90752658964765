import { UseMutationResult, useMutation, useQueryClient } from 'react-query';
import { FormCatalogOverrides, FormFacilityMapping } from 'common/lib/entity/framework/FormFacilityMapping';
import { updateFormFacilityMapping } from 'src/api/formFacilityMappings';
import { formFacilityMappingsKey } from './useFormFacilityMappings';

export const updateFormFacilityMappingKey = 'updateFormFacilityMapping';

export interface UseUpdateFormFacilityMappingInputVariables {
    formOverrides: FormFacilityMapping['formOverrides'];
}

export const useUpdateFormFacilityMapping = (
    formFacilityMappingId: FormFacilityMapping['formFacilityMappingId'],
): UseMutationResult<void, Error, UseUpdateFormFacilityMappingInputVariables, unknown> => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationKey: [updateFormFacilityMappingKey],
        mutationFn: async (variables) => {
            const { formOverrides } = variables;

            if (!formFacilityMappingId) {
                throw new Error('Form facility mapping id is required');
            }

            if (formOverrides && !Array.isArray(formOverrides.pageOverrides)) {
                console.log(formOverrides);
                throw new Error('Form overrides must be set and page overrides must be an array');
            }

            return await updateFormFacilityMapping(formFacilityMappingId, {
                formOverrides,
            });
        },
        onSuccess: async (data) => {
            queryClient.invalidateQueries({ queryKey: [formFacilityMappingsKey] });
            return data;
        },
        retry: false,
    });
};
