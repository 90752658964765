import React, { useContext, useEffect } from 'react';
import styles from './styles.module.scss';
import Flex from 'src/components/Flex';
import { observer } from 'mobx-react';
import PageContainer from 'src/components/PageContainer';
import NavBar from '../NavBar';
import PageContent from 'src/components/PageContent';
import Button from 'src/components/Button';
import Icon from 'src/components/Icon';

function SurveyDefinitionDetails() {
    return (
        <PageContainer>
            <NavBar />
            <PageContent>
                <Flex value={1} className={styles.pageTitle}>
                    Create your survey definition
                </Flex>
                <Flex value={1} className={styles.pageSubTitle}>
                    Select and add a section to your survey from the options below
                </Flex>
            </PageContent>
        </PageContainer>
    );
}

export default observer(SurveyDefinitionDetails);
