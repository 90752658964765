import React, { useState, useEffect, useRef } from 'react';
import Input from 'src/components/Input';
import NemsisInput from 'src/components/NemsisInput';
import { ANALYTICS_NAMES } from 'src/utils/analytics';
import { NaValues } from 'common/lib/model/nemsis3_4/demographic';

interface NumberPadProps {
    input: any;
    value?: string;
    notValue?: string;
    isValid?: boolean;
    enableNotValues?: boolean;
    onChange?: Function;
    onNaChange?: Function;
}

function NumberPad(props: NumberPadProps) {
    const firstValueRender = useRef(true);
    const firstNotValueRender = useRef(true);
    const [value, setValue] = useState(props.value || '');
    const [notValue, setNotValue] = useState(props.notValue || '');

    useEffect(() => {
        if (props.onChange && !firstValueRender.current) {
            const maxLength = getMaxLength();
            props.onChange(maxLength && value ? value.slice(0, maxLength) : value);
        }
        firstValueRender.current = false;
    }, [value]);

    useEffect(() => {
        if (props.onNaChange && !firstNotValueRender.current) {
            props.onNaChange(notValue);
        }
        firstNotValueRender.current = false;
    }, [notValue]);

    function getMaxLength() {
        const inputMethod = props.input && props.input.inputMethod && props.input.inputMethod[0];
        const textInput = inputMethod.textInput && inputMethod.textInput[0] && inputMethod.textInput[0].$;
        return textInput && textInput.maxChar ? textInput.maxChar : null;
    }

    const inputProps = {
        type: notValue === NaValues.NOT_APPLICABLE ? 'text' : 'number',
        step: 0.1,
        value: notValue === NaValues.NOT_APPLICABLE ? notValue : value,
        onChangeText: (updatedValue: string) => setValue(updatedValue),
        maxLength: getMaxLength(),
        ['data-test-id']: ANALYTICS_NAMES.DiscreteForm_NumberInput,
        ...(props.enableNotValues
            ? {
                  nemsisLabel: props.input.title ? props.input.title[0] : '',
                  onNaClick: () => setNotValue(notValue === NaValues.NOT_APPLICABLE ? '' : NaValues.NOT_APPLICABLE),
              }
            : {
                  label: props.input.title ? props.input.title[0] : '',
              }),
    };

    return props.enableNotValues ? (
        <NemsisInput {...inputProps} infoState={props.isValid ? null : 'error'} />
    ) : (
        <Input {...inputProps} infoState={props.isValid ? null : 'error'} />
    );
}

export default NumberPad;
