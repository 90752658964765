import React from 'react';
import PageContent from 'src/components/PageContent';
import NavBar from '../NavBar';
import PageContainer from 'src/components/PageContainer';
import TabNav from 'src/components/TabNav';
import styles from './styles.module.scss';
import Forms from './Forms';
import Models from './Models';
import Library from './Library';

const FORM_DEVELOPMENT_TABS = ['Forms', 'Models', 'Library'];

function FormDevelopment() {
    const [selectedTab, setSelectedTab] = React.useState('Forms');

    function getContent() {
        switch (selectedTab) {
            case 'Forms':
            default:
                return <Forms />;
            case 'Models':
                return <Models />;
            case 'Library':
                return <Library />;
        }
    }

    return (
        <PageContainer>
            <NavBar />
            <PageContent>
                <div className={styles.tabContainer}>
                    <TabNav
                        tabs={FORM_DEVELOPMENT_TABS}
                        onTabClick={(tab: string) => {
                            setSelectedTab(tab);
                        }}
                    />
                </div>
                {getContent()}
            </PageContent>
        </PageContainer>
    );
}

export default FormDevelopment;
