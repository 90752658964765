import React, { useState, useEffect, forwardRef, useImperativeHandle, useContext } from 'react';
import classNames from 'classnames';

import Card from 'src/components/Card';
import Flex from 'src/components/Flex';
import Table from 'src/components/Table';
import Button from 'src/components/Button';
import Icon from 'src/components/Icon';
import styles from './styles.module.scss';
import { observer } from 'mobx-react';
import SideModal from 'src/components/SideModal';
import MultiStep from 'src/components/MultiStep';
import Step1 from 'src/components/CreateInvitationSteps/Step1';
import Step2 from 'src/components/CreateInvitationSteps/Step2';
import Step3 from 'src/components/CreateInvitationSteps/Step3';
import * as invitationsAPI from 'src/api/invitations';
import { CellInfo } from 'react-table';
import ModalFooter from 'src/components/ModalFooter';
import ModalHeader from 'src/components/ModalHeader';
import { Role } from 'src/stores/RoleStore';
import { Facility } from 'src/stores/FacilityStore';
import { ToastStoreObject } from 'src/stores/ToastStore';
import { ErrorStoreObject, ErrorTypes } from 'src/stores/ErrorStore';
import { parseError } from 'src/utils';
import * as validation from 'src/utils/validation';
import { ICONS, DISPLAY_DATE_FORMAT } from 'src/utils/constants';
import { format } from 'date-fns';
import * as variables from 'src/styles/variables';
import CenterModal from 'src/components/CenterModal';
import DateComponent from 'src/components/DateComponent';
import { ANALYTICS_NAMES } from 'src/utils/analytics';
import UserStore from 'src/stores/UserStore';
import { copyTextToClipboard, getUserInvitationURL } from 'src/utils/dependentUtils';
import Pagination from 'src/components/Table/Pagination';
import FilterStore from 'src/stores/FilterStore';

const FILTER_PAGE = 'invitationsPage';

const Invitations = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
        openInvitationsModal() {
            setModalState(true);
        },
    }));
    const userStore = useContext(UserStore);
    const [activeIndexState, setActiveIndexState] = useState(0);
    const [modalState, setModalState] = useState(false);
    const [detailsModalState, setDetailsModalState] = useState(false);
    const [detailsModalData, setDetailsModalData] = useState<any>({});
    const [isLoading, setIsLoading] = useState(false);
    const [invitationState, setInvitationState] = useState([]);
    const [invitationData, setInvitationData] = useState({ emails: [], roleIds: [], facilityIds: [] });
    const [invitationsTotal, setInvitationsTotal] = useState(0);
    const isLast = activeIndexState === 2; // NUMBER OF STEPS
    const [validEmails, setValidEmails] = useState([]);
    const filterStore = useContext(FilterStore);

    const columns = [
        {
            Header: 'DATE CREATED',
            accessor: 'insDttm',
            minWidth: 100,
            Cell: (props: CellInfo) => <span>{format(new Date(props.value), DISPLAY_DATE_FORMAT)}</span>,
        },
        {
            Header: 'EMAIL ADDRESS',
            accessor: 'emailAddr',
            minWidth: 200,
        },
        {
            Header: 'INVITATION STATUS',
            accessor: 'inviteStatCd',
            minWidth: 150,
            Cell: (props: CellInfo) => (
                <span
                    className={classNames({
                        [styles.redText]: props.value === 'NEW',
                        [styles.greenText]: props.value === 'ACCEPTED',
                    })}
                >
                    {props.value.toUpperCase()}
                </span>
            ),
        },
        {
            Header: 'USERNAME',
            accessor: 'userName',
            minWidth: 100,
        },
        {
            Header: 'ACTIONS',
            minWidth: 200,
            accessor: 'inviteStatCd',
            style: { paddingVertical: 0, paddingHorizontal: 20 },
            Cell: (props: CellInfo) => (
                <Flex direction="row">
                    {props.value === 'NEW' || props.value === 'RESENT' ? (
                        <React.Fragment>
                            <Button
                                type="secondary"
                                text="Resend"
                                className={styles.resendButton}
                                leftIcon={
                                    <Icon
                                        name={ICONS.SynchronizeArrow1}
                                        color={variables.red}
                                        className={styles.secondaryIcon}
                                    />
                                }
                                isLocked={!userStore.userPermissions.canEdit.invitations}
                                onClick={() => resendInvite(props.original)}
                                data-test-id={ANALYTICS_NAMES.Invitations_Table_Resend}
                            />
                            <Button
                                type="secondary"
                                text="Cancel"
                                className={styles.resendButton}
                                leftIcon={
                                    <Icon
                                        name={ICONS.Close}
                                        color={variables.red}
                                        size={15}
                                        className={styles.secondaryIcon}
                                    />
                                }
                                isLocked={!userStore.userPermissions.canEdit.invitations}
                                onClick={() => cancelInvite(props.original)}
                                data-test-id={ANALYTICS_NAMES.Invitations_Table_Cancel}
                            />
                            <Button
                                type="secondary"
                                text="Details"
                                leftIcon={
                                    <Icon name={ICONS.Details} color={variables.red} className={styles.secondaryIcon} />
                                }
                                className={styles.resendButton}
                                isLocked={!userStore.userPermissions.canView.invitations}
                                onClick={() => handleDetails(props.original)}
                                data-test-id={ANALYTICS_NAMES.Invitations_Table_Details}
                            />
                        </React.Fragment>
                    ) : (
                        <Button
                            type="secondary"
                            text="Details"
                            leftIcon={
                                <Icon name={ICONS.Details} color={variables.red} className={styles.secondaryIcon} />
                            }
                            className={styles.resendButton}
                            isLocked={!userStore.userPermissions.canView.invitations}
                            onClick={() => handleDetails(props.original)}
                            data-test-id={ANALYTICS_NAMES.Invitations_Table_Details}
                        />
                    )}
                </Flex>
            ),
        },
    ];

    useEffect(() => {
        fetchInvitations();
    }, []);

    useEffect(() => {
        fetchInvitations();
    }, [filterStore[FILTER_PAGE]]);

    async function fetchInvitations() {
        try {
            // Call API to get all invitations for org
            setIsLoading(true);
            const invitations = await invitationsAPI.getInvitations({
                filters: filterStore[FILTER_PAGE],
            });
            setInvitationsTotal(invitations.count || 0);
            setInvitationState(invitations.result || []);
        } catch (e) {
            ErrorStoreObject.setError(ErrorTypes.Loading);
        } finally {
            setIsLoading(false);
        }
    }

    // Transforms the array from a set back to an array, kills all duplicates
    function ensureUniqueEmails(ogArray: any) {
        let set = new Set(ogArray);
        return Array.from(set);
    }

    async function handleCreate() {
        try {
            ensureUniqueEmails(invitationData.emails);
            setIsLoading(true);
            await invitationsAPI.sendUserInvite(invitationData);
            setActiveIndexState(0);
            setInvitationData({ emails: [], roleIds: [], facilityIds: [] });
            setValidEmails([]);
            setModalState(false);
            fetchInvitations();
        } catch (e) {
            ToastStoreObject.show(parseError(e));
        } finally {
            setIsLoading(false);
        }
    }

    async function resendInvite(invite: any) {
        try {
            await invitationsAPI.resendUserInvite(invite);
            fetchInvitations();
        } catch (e) {
            ToastStoreObject.show(parseError(e));
        }
    }

    async function cancelInvite(invite: any) {
        try {
            await invitationsAPI.cancelUserInvite(invite);
            fetchInvitations();
        } catch (e) {
            ToastStoreObject.show(parseError(e));
        }
    }

    function handleEmailParse(emails: string[]) {
        // const emails = text.split(',');
        emails.forEach((e) => e.trim());
        setValidEmails(emails.map((e) => validation.emailRx.test(e)));
        setInvitationData({ ...invitationData, emails });
    }

    function handleDetails(invitation: any) {
        setDetailsModalData(invitation);
        setDetailsModalState(true);
    }

    //Checking if there is a duplicate and will return true if there are none
    function noDuplicates(arr: any[]) {
        return arr.some((x) => arr.indexOf(x) !== arr.lastIndexOf(x));
    }

    const handlePaginationFilterChange = function(newPage: number) {
        const updatedFilters = { ...filterStore[FILTER_PAGE] };
        updatedFilters.pagination.page = newPage;
        filterStore.setFilters(FILTER_PAGE, updatedFilters);
    };

    return (
        <>
            <Flex self="stretch" align="center" justify="end">
                <Button
                    leftIcon={<Icon className={styles.plusIcon} name={ICONS.PlusButton} />}
                    type="primary"
                    onClick={() => setModalState(true)}
                    isLocked={!userStore.userPermissions.canEdit.invitations}
                    text="New Invitation"
                    data-test-id={ANALYTICS_NAMES.Invitations_CreateInvitation}
                />
            </Flex>
            <Card className={styles.cardWrap}>
                <div className={styles.tableTitle}>Invitations</div>
                <Table isLoading={isLoading} columns={columns} data={invitationState} />
                <Pagination
                    pages={Math.ceil(invitationsTotal / filterStore[FILTER_PAGE].pagination.limit) || 1}
                    page={filterStore[FILTER_PAGE].pagination.page - 1}
                    onPageChange={(page: number) => handlePaginationFilterChange(page + 1)}
                    showing={invitationState.length}
                    totalRecords={invitationsTotal}
                />
            </Card>
            <SideModal isOpen={modalState} onClose={() => setModalState(false)}>
                <ModalHeader title="New Invitation" onClose={() => setModalState(false)} />
                <MultiStep
                    activeIndex={activeIndexState}
                    steps={[
                        <Step1
                            key={1}
                            onChange={(emails: string[]) => handleEmailParse(emails)}
                            selectedEmails={invitationData.emails}
                        />,
                        <Step2
                            key={2}
                            onChangeRoles={(roles?: [{ value: number; label: string }], facilities?: number[]) =>
                                setInvitationData({
                                    ...invitationData,
                                    roleIds: roles ? roles.map((r) => r.value) : [],
                                    facilityIds: facilities ? facilities : invitationData.facilityIds,
                                })
                            }
                            onChangeFacilities={(facilities?: number[]) => {
                                setInvitationData({
                                    ...invitationData,
                                    facilityIds: facilities,
                                });
                            }}
                        />,
                        <Step3 key={3} invitationData={invitationData} />,
                    ]}
                />
                <ModalFooter
                    primaryText={isLast ? 'Send Invitation' : 'Next'}
                    secondaryText={activeIndexState === 0 ? 'Cancel' : 'Back'}
                    primaryClick={
                        isLast
                            ? handleCreate
                            : () => {
                                  setActiveIndexState(activeIndexState + 1);
                              }
                    }
                    primaryProps={{
                        disabled:
                            ((validEmails.indexOf(false) >= 0 ||
                                validEmails.length === 0 ||
                                noDuplicates(invitationData.emails)) &&
                                activeIndexState === 0) ||
                            (invitationData.roleIds.length < 1 && activeIndexState === 1),
                    }}
                    secondaryClick={() => {
                        if (activeIndexState === 0) {
                            setInvitationData({ emails: [], roleIds: [], facilityIds: [] });
                            setValidEmails([]);
                        }
                        setActiveIndexState(activeIndexState === 0 ? 0 : activeIndexState - 1);
                        setModalState(activeIndexState === 0 ? false : true);
                    }}
                    isLoading={isLoading}
                />
            </SideModal>
            <CenterModal isOpen={detailsModalState} onClose={() => setDetailsModalState(false)}>
                <ModalHeader title="Invitation Details" onClose={() => setDetailsModalState(false)} />
                <div className={styles.detailsContainer}>
                    <div className={styles.detailsEntry}>
                        <div className={styles.detailsLabel}>Date Created</div>
                        <div className={styles.detailsInfo}>
                            {detailsModalData && <DateComponent date={detailsModalData.insDttm} showDateTime={true} />}
                        </div>
                    </div>
                    <div className={styles.detailsEntry}>
                        <div className={styles.detailsLabel}>Last Updated</div>
                        <div className={styles.detailsInfo}>
                            {detailsModalData && <DateComponent date={detailsModalData.updDttm} showDateTime={true} />}
                        </div>
                    </div>
                    <div className={styles.detailsEntry}>
                        <div className={styles.detailsLabel}>Email Address</div>
                        <div className={styles.detailsInfo}>{detailsModalData && detailsModalData.emailAddr}</div>
                    </div>
                    <div className={styles.detailsEntry}>
                        <div className={styles.detailsLabel}>Invitation Status</div>
                        <div
                            className={classNames([styles.detailsInfo], {
                                [styles.redText]: detailsModalData.inviteStatCd === 'NEW',
                                [styles.greenText]: detailsModalData.inviteStatCd === 'ACCEPTED',
                            })}
                        >
                            {detailsModalData && detailsModalData.inviteStatCd}
                        </div>
                    </div>
                    <div className={styles.detailsEntry}>
                        <div className={styles.detailsLabel}>Username</div>
                        <div className={styles.detailsInfo}>
                            {detailsModalData && detailsModalData.userName ? detailsModalData.userName : 'N/A'}
                        </div>
                    </div>
                    <div className={styles.detailsEntry}>
                        <div className={styles.detailsLabel}>Role</div>
                        <div className={styles.detailsInfo}>
                            {detailsModalData &&
                                (detailsModalData.roleInfo || [])
                                    .filter((r: Role) => r != null && r != undefined)
                                    .map((r: Role) => `${r.roleDesc}`)
                                    .join(', ')}
                        </div>
                    </div>
                    <div className={styles.detailsEntry}>
                        <div className={styles.detailsLabel}>Facility</div>
                        <div className={styles.detailsInfo}>
                            {detailsModalData &&
                                (detailsModalData.facilityInfo || [])
                                    .filter((f: Facility) => f != null && f != undefined)
                                    .map((f: Facility) => `${f.facNm}`)
                                    .join(', ')}
                        </div>
                    </div>
                    {detailsModalData &&
                    (detailsModalData.inviteStatCd === 'NEW' || detailsModalData.inviteStatCd === 'RESENT') ? (
                        <div className={styles.detailsEntry}>
                            <div className={styles.detailsLabel}>Invitation URL</div>
                            <div className={classNames(styles.detailsInfo, styles.invitationUrl)}>
                                {getUserInvitationURL({
                                    orgNameInternal: userStore.selectedOrg.orgNmIntrnl,
                                    inviteKey: detailsModalData.inviteKeyCd,
                                    email: detailsModalData.emailAddr,
                                })}
                                <div>
                                    <Button
                                        type="secondary"
                                        text="Copy Link"
                                        className={styles.copyLink}
                                        onClick={() =>
                                            copyTextToClipboard(
                                                getUserInvitationURL({
                                                    orgNameInternal: userStore.selectedOrg.orgNmIntrnl,
                                                    inviteKey: detailsModalData.inviteKeyCd,
                                                    email: detailsModalData.emailAddr,
                                                }),
                                            )
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    ) : null}
                </div>
                <ModalFooter primaryText="OK" primaryClick={() => setDetailsModalState(false)} showSecondary={false} />
            </CenterModal>
        </>
    );
});
Invitations.displayName = 'Invitations';

export default observer(Invitations);
