import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { format } from 'date-fns';
import Select from 'react-select';
import Icon from 'src/components/Icon';
import Flex from 'src/components/Flex';
import Button from 'src/components/Button';
import * as variables from 'src/styles/variables';
import Input, { Label } from '../Input';
import { ICONS, API_DATE_FORMAT } from 'src/utils/constants';
import * as utils from 'src/utils';
import DatePicker from '../DatePicker';
import { FilterRowProps } from '../FilteredSearch';
import OptionDropDown from '../OptionDropDown';
import { ReactSelectOption } from 'src/utils/types';
import { FILTER_METHODS } from 'src/utils/filters';
import TimePicker from '../TimePicker';

export interface FilteredSearchRowProps {
    onUpdateRow: (f: FilterRowProps) => void;
    onDeleteRow?: () => void;
    filter: FilterRowProps;
    fieldOptions: ReactSelectOption[];
    isRequired?: boolean;
}

function sortOptions(arr: any[]) {
    const newArr = [...arr];
    newArr.sort(function (a, b) {
        const textA = (a.label || '').toUpperCase();
        const textB = (b.label || '').toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
    return newArr;
}

function DataInputs({
    filter,
    onUpdateRow,
    selectProps,
}: {
    filter: FilteredSearchRowProps['filter'];
    onUpdateRow: FilteredSearchRowProps['onUpdateRow'];
    selectProps: {};
}) {
    switch (filter.selectedFilterType) {
        case FILTER_METHODS.Between_Date:
        case FILTER_METHODS.Between_Date_Only:
            return (
                <Flex direction="row" align="end" className={styles.multipleFields}>
                    <Flex direction="column">
                        <Label className={styles.fieldLabel} text="Start Date" />
                        <DatePicker
                            inputClassName={styles.multipleField}
                            value={filter.compareValue1}
                            onChange={(date: any) => {
                                if (date) {
                                    const value = format(date, API_DATE_FORMAT);
                                    onUpdateRow({ ...filter, compareValue1: value });
                                }
                            }}
                        />
                    </Flex>
                    <div className={styles.andText}>and</div>
                    <Flex direction="column">
                        <Label className={styles.fieldLabel} text="End Date" />
                        <DatePicker
                            inputClassName={styles.multipleField}
                            value={filter.compareValue2}
                            onChange={(date: any) => {
                                if (date) {
                                    const value = format(date, API_DATE_FORMAT);
                                    onUpdateRow({ ...filter, compareValue2: value });
                                }
                            }}
                        />
                    </Flex>
                </Flex>
            );
        case FILTER_METHODS.Between_Time:
            return (
                <Flex direction="row" align="end" className={styles.multipleFields}>
                    <Flex direction="column">
                        <Label className={styles.fieldLabel} text="Start Time" />
                        <TimePicker
                            onChange={(t: string) => onUpdateRow({ ...filter, compareValue1: t })}
                            time={filter.compareValue1}
                        />
                    </Flex>
                    <div className={styles.andText}>and</div>
                    <Flex direction="column">
                        <Label className={styles.fieldLabel} text="End Time" />
                        <TimePicker
                            onChange={(t: string) => onUpdateRow({ ...filter, compareValue2: t })}
                            time={filter.compareValue2}
                        />
                    </Flex>
                </Flex>
            );
        case FILTER_METHODS.Between_Number:
            return (
                <Flex direction="row" align="end" className={styles.multipleFields}>
                    <Flex direction="column">
                        <Label className={styles.fieldLabel} text="Start" />
                        <Input
                            type="number"
                            wrapClassName={styles.multipleField}
                            placeholder="Enter number"
                            value={filter.compareValue1}
                            onChangeText={(t: string) => onUpdateRow({ ...filter, compareValue1: t })}
                        />
                    </Flex>
                    <div className={styles.andText}>and</div>
                    <Flex direction="column">
                        <Label className={styles.fieldLabel} text="End" />
                        <Input
                            type="number"
                            wrapClassName={styles.multipleField}
                            placeholder="Enter number"
                            value={filter.compareValue2}
                            onChangeText={(t: string) => onUpdateRow({ ...filter, compareValue2: t })}
                        />
                    </Flex>
                </Flex>
            );

        case FILTER_METHODS.Equals_Date:
        case FILTER_METHODS.Does_Not_Equals_Date:
        case FILTER_METHODS.Does_Not_Equals_Date_Only:
        case FILTER_METHODS.Equals_Date_Only:
        case FILTER_METHODS.Greater_Than:
        case FILTER_METHODS.Less_Than:
            return (
                <div className={styles.onlyField}>
                    <DatePicker
                        value={filter.compareValue1}
                        onChange={(date: any) => {
                            if (date) {
                                const value = format(date, API_DATE_FORMAT);
                                onUpdateRow({ ...filter, compareValue1: value });
                            }
                        }}
                    />
                </div>
            );

        case FILTER_METHODS.Equals_Time:
        case FILTER_METHODS.Does_Not_Equals_Time:
        case FILTER_METHODS.Greater_Than_Time:
        case FILTER_METHODS.Less_Than_Time:
            return (
                <div className={styles.onlyField}>
                    <TimePicker
                        onChange={(t: string) => onUpdateRow({ ...filter, compareValue1: t })}
                        time={filter.compareValue1}
                    />
                </div>
            );

        case FILTER_METHODS.Equals_Number:
        case FILTER_METHODS.Does_Not_Equals_Number:
        case FILTER_METHODS.Contains_Number:
        case FILTER_METHODS.Greater_Than_Number:
        case FILTER_METHODS.Less_Than_Number:
            return (
                <Input
                    type="number"
                    wrapClassName={styles.onlyField}
                    placeholder="Enter number"
                    value={filter.compareValue1}
                    onChangeText={(t: string) => onUpdateRow({ ...filter, compareValue1: t })}
                />
            );

        case FILTER_METHODS.Equals_Text:
        case FILTER_METHODS.Does_Not_Equals_Text:
        case FILTER_METHODS.Contains:
            return (
                <Input
                    wrapClassName={styles.onlyField}
                    placeholder="Enter Text"
                    value={filter.compareValue1}
                    onChangeText={(t: string) => onUpdateRow({ ...filter, compareValue1: t })}
                />
            );

        case FILTER_METHODS.Matches_Dropdown:
            if (filter.extra.options) {
                return (
                    <Select
                        {...selectProps}
                        onChange={(v: any) => onUpdateRow({ ...filter, compareValue1: v.value })}
                        value={filter.extra.options.find((o) => o.value === filter.compareValue1)}
                        options={sortOptions(filter.extra.options)}
                    />
                );
            }
            return (
                <OptionDropDown
                    className={styles.onlyField}
                    selectedValue={filter.compareValue1}
                    onChange={(s: any) => onUpdateRow({ ...filter, compareValue1: s.value })}
                    type={filter.extra.optionType}
                />
            );

        case FILTER_METHODS.One_Of_Dropdown:
            const oneOfCommonProps = {
                isMulti: true,
                onChange: (s: any) => {
                    onUpdateRow({ ...filter, compareValue1: (s || []).map((v: ReactSelectOption) => v.value) });
                },
            };
            if (filter.extra.options) {
                return (
                    <Select
                        {...selectProps}
                        {...oneOfCommonProps}
                        value={filter.extra.options.filter((o) => (filter.compareValue1 || []).includes(o.value))}
                        options={filter.extra.options}
                    />
                );
            }
            return (
                <OptionDropDown
                    {...oneOfCommonProps}
                    className={styles.onlyField}
                    selectedValue={filter.compareValue1 || []}
                    type={filter.extra.optionType}
                />
            );

        default:
            return null;
    }
}

function FilteredSearchRow({ onUpdateRow, onDeleteRow, filter, fieldOptions, isRequired }: FilteredSearchRowProps) {
    const typeOptions: ReactSelectOption[] = filter.availableFilterTypes.map((t) => ({ value: t, label: t }));
    const selectProps = {
        className: classNames(styles.select),
        isClearable: false,
        isMulti: false,
        styles: utils.styleSelectComponent(),
    };

    const [availableOptions, setAvailableOptions] = useState(fieldOptions);
    useEffect(() => {
        setAvailableOptions(fieldOptions);
    }, [fieldOptions]);

    return (
        <Flex direction="row" align="center" className={styles.filterRow}>
            {!isRequired ? (
                <Button
                    leftIcon={<Icon className={styles.plusIcon} name={ICONS.Close} size={15} color={variables.black} />}
                    type="transparent"
                    onClick={onDeleteRow}
                    className={styles.removeFilterBtn}
                />
            ) : (
                <div className={styles.removeFilterBtn} style={{ marginLeft: 24 }}></div>
            )}
            <Flex direction="row" className={styles.showText}>
                Where
            </Flex>
            <Select
                {...selectProps}
                onChange={(v: any) => onUpdateRow({ ...filter, field: v.value })}
                value={fieldOptions.find((o) => o.value === filter.field)}
                options={sortOptions(availableOptions)}
                isDisabled={isRequired}
            />
            <Select
                {...selectProps}
                onChange={(v: any) => {
                    onUpdateRow({ ...filter, selectedFilterType: v.value });
                }}
                value={typeOptions.find((o) => o.value === filter.selectedFilterType)}
                options={typeOptions}
            />
            <Flex value={1} direction="row" align="center">
                <DataInputs filter={filter} onUpdateRow={onUpdateRow} selectProps={selectProps} />
            </Flex>
        </Flex>
    );
}

export default FilteredSearchRow;
