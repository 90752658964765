import * as ROUTES from 'src/api/routes';
import request from './utils';
import { FormFacilityMapping } from 'common/lib/entity/framework/FormFacilityMapping';
import { FormDeployment } from 'common/lib/entity/framework/FormDeployment';
import { FormDefinition } from 'common/lib/entity/org/FormDefinition';

export async function getFormFacilityMappings() {
    const result = await request({ ...ROUTES.GET_FORM_FACILITY_MAPPINGS });
    return result?.data || [];
}

export async function getFormFacilityMappingById(formFacilityMappingId: FormFacilityMapping['formFacilityMappingId'], getOrgFormDefinition: boolean = false) {
    const result = await request({ ...ROUTES.GET_FORM_FACILITY_MAPPING_BY_ID, pathParams: { formFacilityMappingId }, params: { getOrgFormDefinition } });
    return result?.data || {};
}

export async function createFormFacilityMapping(formFacilityMapping: {
    formCatalogId: FormFacilityMapping['formCatalogId'];
    orgNameInternal: FormFacilityMapping['orgNameInternal'];
    orgName: FormFacilityMapping['orgName'];
    facilityId: FormFacilityMapping['facilityId'];
    facilityName: FormFacilityMapping['facilityName'];
    formDefinitionId?: FormFacilityMapping['formDefinitionId'];
    formDefinitionName?: FormFacilityMapping['formDefinitionName'];
    formDefinitionTitle?: FormDefinition['formDefnTitle'];
    formDefinitionDescription?: FormDefinition['formDefnDesc'];
    formOverrides?: FormFacilityMapping['formOverrides'];
}): Promise<{ formFacilityMappingId: FormFacilityMapping['formFacilityMappingId'] }> {
    const result = await request({ ...ROUTES.CREATE_FORM_FACILITY_MAPPING, data: { ...formFacilityMapping } });
    return result?.data || {};
}

export async function updateFormFacilityMapping(
    formFacilityMappingId: FormFacilityMapping['formFacilityMappingId'],
    formFacilityMapping: Partial<FormFacilityMapping>,
): Promise<void> {
    await request({
        ...ROUTES.UPDATE_FORM_FACILITY_MAPPING,
        pathParams: { formFacilityMappingId },
        data: formFacilityMapping,
    });
}

export async function prepareFormFacilityMappingForDeployment(
    formFacilityMappingId: FormDeployment['formFacilityMappingId'],
    data: {
        formCatalogVersionId: FormDeployment['formCatalogVersionId'];
        deploymentDescription?: FormDeployment['deploymentDescription'];
    },
): Promise<{ formDeploymentId: FormDeployment['formDeploymentId'], deploymentDetails: FormDeployment['deploymentDetails'] }> {
    const result = await request({
        ...ROUTES.PREPARE_FORM_FACILITY_MAPPING_FOR_DEPLOYMENT,
        pathParams: { formFacilityMappingId },
        data,
    });
    return result?.data || {};
}

export async function deployFormFacilityMapping(formDeploymentId: FormDeployment['formDeploymentId']): Promise<void> {
    await request({ ...ROUTES.DEPLOY_FORM_FACILITY_MAPPING, pathParams: { formDeploymentId } });
}

export async function getFormFacilityMappingFormDefinitions(
    orgNameInternal: FormFacilityMapping['orgNameInternal'],
    facilityId: FormFacilityMapping['facilityId'],
) {
    const result = await request({
        ...ROUTES.GET_FORM_FACILITY_MAPPING_FORM_DEFINITIONS,
        params: { orgNameInternal, facilityId },
    });
    return result?.data || [];
}
