import { ITransportFacilityPhone } from 'common/lib/model/nemsis3_4/demographic';
import { observable, action, makeObservable } from 'mobx';
import * as transportationFacilityAPI from 'src/api/transportFacility';
import { createContext } from 'react';

export interface TransportFacility {
    transportFacilityId?: number;
    facilityTypeCode: string;
    facilityName: string;
    facilityLocationCode: string;
    hospitalDesignationCodes: string[];
    facilityNpis: string[];
    suiteRoomApartment: string;
    address1: string;
    address2: string;
    cityCode: string;
    cityName: string;
    stateCode: string;
    zipCode: string;
    countyCode: string;
    countryCode: string;
    gpsLocation: string;
    nationalGridCoordinates: string;
    phoneNumbers: ITransportFacilityPhone[];
    notValues: { [key in keyof TransportFacility]?: any };
    active?: boolean;
    insDttm?: Date;
    updDttm?: Date;
    audVer?: number;
}

class TransportFacilityStore {
    @observable transportFacilities: TransportFacility[] = [];

    constructor() {
        makeObservable(this);
    }

    @action
    async getTransportFacilities() {
        const results = await transportationFacilityAPI.getTransportFacilities();
        this.transportFacilities = results.data;
    }

    @action
    async createTransportFacility(transportFacility: TransportFacility) {
        const result = await transportationFacilityAPI.createTransportFacility(transportFacility);
        this.transportFacilities = [...this.transportFacilities, result.data];
    }

    @action
    async updateTransportFacility(transportFacility: TransportFacility) {
        const result = await transportationFacilityAPI.updateTransportFacility(transportFacility);
    }

    @action
    async toggleActive(transportFacility: TransportFacility) {
        const updatedTransportFacility = { ...transportFacility, active: !transportFacility.active };
        const result = await transportationFacilityAPI.updateTransportFacility(updatedTransportFacility);
    }
}

export const TransportFacilityStoreObject = new TransportFacilityStore();

export default createContext(TransportFacilityStoreObject);
