import * as ROUTES from 'src/api/routes';
import request from 'src/api/utils';
import * as utils from 'src/utils';
import { Provider } from 'src/stores/ProviderStore';

export async function getProviders(params: object = {}) {
    const response = await request({ ...ROUTES.GET_PROVIDERS, params });
    return { result: response.data.result || [], count: response.data.count || 0 };
}

export async function createProvider(facilityId: number, data: Provider) {
    return await request({ ...ROUTES.CREATE_PROVIDER, params: { facilityId }, data });
}

export async function editProvider(facilityId: number, providerId: number, data: Provider) {
    return await request({ ...ROUTES.EDIT_PROVIDER, pathParams: { providerId }, params: { facilityId }, data });
}

export async function deactivateProvider(facilityId: number, providerId: number) {
    return await request({ ...ROUTES.DEACTIVATE_PROVIDER, pathParams: { providerId }, params: { facilityId } });
}

export async function reactivateProvider(facilityId: number, providerId: number) {
    return await request({ ...ROUTES.REACTIVATE_PROVIDER, pathParams: { providerId }, params: { facilityId } });
}
