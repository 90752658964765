export type Survey = {
    surveyGuid: string;
    surveyDefinitionHistoryId: number;
    relatedEncounterFormId: number;
    surveyStateId: number;
    responseData: any;
    surveySource: string;
    surveyStateHistory: any;
    orgNameInternal: string;
    facilityId: number;
    createdAt: Date;
    updatedAt: Date;
    auditVersion: number;
    surveyState?: SurveyState;
    surveyDefinitionHistory?: SurveyDefinitionHistory;
};
export type SurveyRequest = {
    surveyRequestGuid: string;
    surveyGuid: string;
    surveyRequestStateId: number;
    surveyRequestStateHistory: {};
    surveyRequestType: 'EMAIL' | 'SMS';
    surveyRequestContactInfo: { email?: string; phone?: string };
    unsubscribed: boolean;
    createdAt: Date;
    updatedAt: Date;
    auditVersion: number;
    survey?: Survey;
    surveyRequestState?: SurveyRequestState;
};

export type SurveyUnauth = {
    surveyDefinitionContent: { fields: any[] };
    surveyDefinitionTitle: string;
    surveyGuid: string;
    surveyStateTitle: string;
    surveyStateCode: SurveyStateCodes;
    responseData?: any;
};
export type SurveyRequestUnauth = {
    surveyRequestGuid: SurveyRequest['surveyRequestGuid'];
    surveyGuid: SurveyRequest['surveyGuid'];
    surveyRequestContactInfo: SurveyRequest['surveyRequestContactInfo'];
    createdAt: Date;
    unsubscribed: SurveyRequest['unsubscribed'];
    facilityName?: string;
};

export type SurveyState = {
    surveyStateId: number;
    surveyStateCode: SurveyStateCodes;
    surveyStateTitle: string;
    surveyStateDescription: string;
    createdAt: Date;
    updatedAt: Date;
    auditVersion: number;
    active: boolean;
};
export type SurveyRequestState = {
    surveyRequestStateId: number;
    surveyRequestStateCode: string;
    surveyRequestStateTitle: string;
    surveyRequestStateDescription: string;
    active: boolean;
    createdAt: Date;
    updatedAt: Date;
    auditVersion: number;
};

export type SurveyDefinitionHistory = {
    surveyDefinitionHistoryId: number;
    surveyDefinitionHistoryVer: number;
    surveyDefinitionId: number;
    surveyDefinitionTitle: string;
    surveyDefinitionDescription: string;
    surveyDefinitionContent: string | SurveyDefinitionContent;
    createdAt: Date;
    updatedAt: Date;
    auditVersion: number;
};

export type SurveyDefinition = {
    surveyDefinitionId: number;
    surveyDefinitionCode: string;
    publishedVersion: number;
    publishedVersionHistory: any;
    createdAt: Date;
    updatedAt: Date;
    auditVersion: number;
    systemGlobal: boolean;
    active: boolean;
};

export enum SurveyRequestStateCode {
    REQUEST_CREATED = 'REQUEST_CREATED',
    BLOCKED_USER = 'BLOCKED_USER',
    REQUEST_SENT = 'REQUEST_SENT',
    REQUEST_OPENED = 'REQUEST_OPENED',
    CHANNEL_FAILED = 'CHANNEL_FAILED',
}
export enum SurveyStateCodes {
    SURVEY_CREATED = 'SURVEY_CREATED',
    SURVEY_OPENED = 'SURVEY_OPENED',
    SURVEY_STARTED = 'SURVEY_STARTED',
    SURVEY_COMPLETED = 'SURVEY_COMPLETED',
    SURVEY_CANCELLED = 'SURVEY_CANCELLED',
    CONTACT_INITIATED = 'CONTACT_INITIATED',
}

export interface SurveyDefinitionContent {
    fields: SurveyField[];
}

export type SurveyResponse = {
    [key in string]: any;
};

export interface SurveyDefinitionContent {
    fields: SurveyField[];
}
export interface SurveyField {
    id: number;
    name: string;
    title: string;
    type: FieldTypes;
    options: { value: number; label: string }[];
}
export enum FieldTypes {
    SCALE = 'SCALE',
    TEXT = 'TEXT',
    DROPDOWN = 'DROPDOWN',
    NUMBER = 'NUMBER',
}
