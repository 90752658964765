import React from 'react';

const SvgSidebarInfo = (props) => (
    <svg width={14} height={14} {...props} viewBox="0 0 14 14">
        <path
            d="M7 0a7.001 7.001 0 10.002 14.002A7.001 7.001 0 007 0zm0 3.105a1.185 1.185 0 110 2.37 1.185 1.185 0 010-2.37zm1.58 7.17a.339.339 0 01-.338.338H5.758a.339.339 0 01-.339-.339v-.677c0-.187.152-.339.34-.339h.338V7.452h-.339a.339.339 0 01-.339-.34v-.677c0-.187.152-.338.34-.338h1.806c.187 0 .338.151.338.338v2.823h.339c.187 0 .339.152.339.339v.677z"
            fill={props.color || '#FFF'}
            fillRule="nonzero"
        />
    </svg>
);

export default SvgSidebarInfo;
