import React, { useContext, useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import Card from 'src/components/Card';
import Flex from 'src/components/Flex';
import PageContainer from 'src/components/PageContainer';
import PageContent from 'src/components/PageContent';
import NavBar from 'src/containers/NavBar';
import Table from 'src/components/Table';
import { ANALYTICS_NAMES } from 'src/utils/analytics';
import { ICONS, ROUTES } from 'src/utils/constants';
import { CellInfo } from 'react-table';
import DateComponent from 'src/components/DateComponent';
import styles from '../styles.module.scss';
import PaymentsStore, {
    PaymentRequest,
    PaymentRequestState,
    PaymentRequestContact,
    Payer,
    PaymentTransaction,
} from 'src/stores/PaymentsStore';
import { Encounter } from 'src/utils/types';
import { observer } from 'mobx-react';
import { ToastStoreObject } from 'src/stores/ToastStore';
import { parseError } from 'src/utils';
import LoadingIcon from 'src/components/LoadingIcon';
import { formatNumberToCurrency } from 'common/lib/util/number';
import Icon from 'src/components/Icon';
import classNames from 'classnames';
import PaymentStatus from 'src/components/PaymentStatus';
import Button from 'src/components/Button';
import { ModalStoreObject, ModalTypes } from 'src/stores/ModalStore';
import Input from 'src/components/Input';
import AnimateHeight from 'react-animate-height';
import * as ContactValidator from 'common/lib/util/ContactValidator';

function PaymentRequestDetail() {
    const history = useHistory();
    const { paymentRequestId } = useParams<any>();
    const paymentsStore = useContext(PaymentsStore);

    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingContact, setIsLoadingContact] = useState(false);
    const [paymentRequest, setPaymentRequest] = useState<PaymentRequest>(null);
    const [encounter, setEncounter] = useState<Encounter>(null);
    const [payer, setPayer] = useState<Payer>(null);
    const [isAddingPhone, setIsAddingPhone] = useState(false);
    const [isAddingEmail, setIsAddingEmail] = useState(false);
    const [addContactInfo, setAddContactInfo] = useState('');
    const [addContactInfoIsValid, setAddContactInfoIsValid] = useState(true);
    const transactionColumns = [
        {
            Header: 'Date',
            accessor: 'paymentTransactionPostingDate',
            Cell: (props: CellInfo) => {
                return <DateComponent date={props.value} />;
            },
        },
        {
            Header: 'Amount',
            accessor: 'paymentAmountCollected',
            Cell: (props: CellInfo) => {
                return <span>{formatNumberToCurrency(props.value)}</span>;
            },
        },
        {
            Header: 'Status',
            accessor: 'paymentTransactionStateId',
            Cell: (props: CellInfo) => {
                return <PaymentStatus type="PaymentTransaction" status={props.value} />;
            },
        },
        {
            Header: 'Reference',
            accessor: 'paymentGatewayResponseData',
            minWidth: 300,
            Cell: (props: CellInfo) => {
                const charges = props.value?.data?.object?.charges?.data;
                const paymentIntent = props.value?.data?.object?.id || '';
                const reference = charges?.length ? charges[0].id : paymentIntent;
                return <span>{`Reference #: ${reference}`}</span>;
            },
        },
        {
            Header: 'Comments',
            accessor: 'paymentTransactionComments',
            minWidth: 300,
            style: { whiteSpace: 'unset' },
        },
    ];

    useEffect(() => {
        loadPaymentRequest();
    }, []);

    useEffect(() => {
        if (paymentsStore.paymentRequest) {
            setPaymentRequest(paymentsStore.paymentRequest.paymentRequest);
            setEncounter(paymentsStore.paymentRequest.encounter);
            setPayer(paymentsStore.paymentRequest.payer);
        }
    }, [paymentsStore.paymentRequest]);

    async function loadPaymentRequest() {
        setIsLoading(true);

        try {
            await paymentsStore.getPaymentRequest(paymentRequestId, {
                includeContacts: true,
                includeTransactions: true,
            });
            await paymentsStore.getPaymentRequestStates();
        } catch (e) {
            ToastStoreObject.show(parseError(e));
        } finally {
            setIsLoading(false);
        }
    }

    function handleBack() {
        history.push(ROUTES.getString(ROUTES.PaymentRequests));
    }

    async function handleAddContact(type: 'SMS' | 'EMAIL') {
        setIsLoadingContact(true);
        const contactIsValid = validateAddContactInfo(addContactInfo);
        if (!contactIsValid) {
            setAddContactInfoIsValid(false);
            const errorMessage = isAddingPhone ? 'Phone must be in xxx-xxx-xxxx format' : 'Invalid email format';
            ToastStoreObject.show(errorMessage);
            setIsLoadingContact(false);
            return;
        }

        try {
            await paymentsStore.addPaymentRequestContact(paymentRequest.paymentRequestGuid, type, addContactInfo);
            await loadPaymentRequest();
        } catch (e) {
            ToastStoreObject.show(parseError(e));
        } finally {
            setAddContactInfoIsValid(true);
            setIsLoadingContact(false);
            setIsAddingEmail(false);
            setIsAddingPhone(false);
        }
    }

    function isEditable() {
        const paymentRequestStates = paymentsStore.paymentRequestStates;
        const currentState = paymentRequestStates.find(
            (prs: PaymentRequestState) =>
                prs.paymentRequestStateId.toString() === paymentRequest.paymentRequestStateId.toString(),
        );

        if (!currentState) return true;

        return (
            currentState.paymentRequestStateCode === 'REQUEST_CREATED' ||
            currentState.paymentRequestStateCode === 'REQUEST_PENDING'
        );
    }

    function handleUnsubscribeContact(contact: PaymentRequestContact) {
        ModalStoreObject.showModal(ModalTypes.ConfirmationModal, {
            title: 'Are you sure you want to deactivate this contact?',
            onConfirm: () => {
                unsubscribeContact(contact);
                ModalStoreObject.hideModal();
            },
            onCancel: () => ModalStoreObject.hideModal(),
            confirmButtonText: 'Deactivate',
        });
    }

    async function unsubscribeContact(contact: PaymentRequestContact) {
        setIsLoading(true);
        try {
            await paymentsStore.unsubscribePaymentRequestContact(contact.paymentRequestContactGuid);
            await loadPaymentRequest();
        } catch (e) {
            ToastStoreObject.show(parseError(e));
        } finally {
            setIsLoading(false);
        }
    }

    function validateAddContactInfo(input: string): boolean {
        return isAddingPhone ? ContactValidator.phoneNumberIsValid(input) : ContactValidator.emailAddressIsValid(input);
    }

    function getContacts(type: 'SMS' | 'EMAIL') {
        const contacts =
            paymentRequest && paymentRequest.contacts
                ? paymentRequest.contacts.filter((contact: PaymentRequestContact) => contact.contactType === type)
                : [];
        if (!contacts.length) {
            return (
                <li>
                    <Flex>
                        <Flex className={styles.detailTitle}>
                            {type === 'SMS' ? 'Phone Number' : 'Email Address'}
                            {isEditable() && (
                                <Icon
                                    className={styles.plusIcon}
                                    name={ICONS.PlusButton}
                                    onClick={() => {
                                        setAddContactInfo('');
                                        if (type === 'SMS') {
                                            setIsAddingPhone(true);
                                            setIsAddingEmail(false);
                                        } else if (type === 'EMAIL') {
                                            setIsAddingEmail(true);
                                            setIsAddingPhone(false);
                                        }
                                    }}
                                />
                            )}
                        </Flex>
                        <div className={classNames(styles.detailValue, styles.detailContact)}>{`No ${
                            type === 'SMS' ? 'phone numbers' : 'email addresses'
                        } added`}</div>
                    </Flex>
                </li>
            );
        }

        return contacts.map((number: PaymentRequestContact, index: number) => (
            <li key={index}>
                <Flex>
                    <Flex className={styles.detailTitle}>
                        {type === 'SMS' ? 'Phone Number' : 'Email Address'}
                        {isEditable() && contacts.length - 1 === index ? (
                            <Icon
                                className={styles.plusIcon}
                                name={ICONS.PlusButton}
                                onClick={() => {
                                    setAddContactInfo('');
                                    if (type === 'SMS') {
                                        setIsAddingPhone(true);
                                        setIsAddingEmail(false);
                                    } else if (type === 'EMAIL') {
                                        setIsAddingEmail(true);
                                        setIsAddingPhone(false);
                                    }
                                }}
                            />
                        ) : null}
                    </Flex>
                    <Flex align="center" className={classNames(styles.detailValue, styles.detailContact)}>
                        <div className={styles.contactInfo} title={number.contactInfo}>
                            {number.contactInfo}
                        </div>
                        <PaymentStatus type="PaymentRequestContact" status={number.paymentRequestContactStateId} />
                        {isEditable() && (
                            <Icon
                                name={ICONS.Close}
                                size={8}
                                className={styles.closeIcon}
                                onClick={() => handleUnsubscribeContact(number)}
                            />
                        )}
                    </Flex>
                </Flex>
            </li>
        ));
    }

    function getAddContact(type: 'SMS' | 'EMAIL') {
        return (
            <li>
                <Flex>
                    <Flex className={styles.detailTitle}>
                        {type === 'SMS' ? 'New Phone Number' : 'New Email Address'}
                    </Flex>
                    <Flex align="center">
                        <Input
                            onChangeText={(v: string) => {
                                setAddContactInfo(v);
                                validateAddContactInfo(v)
                                    ? setAddContactInfoIsValid(true)
                                    : setAddContactInfoIsValid(false);
                            }}
                            infoState={addContactInfoIsValid ? null : 'error'}
                        />
                        {isLoadingContact ? (
                            <LoadingIcon />
                        ) : (
                            <>
                                <Button
                                    className={styles.detailButton}
                                    type="small"
                                    text="Add"
                                    onClick={() => handleAddContact(type)}
                                    disabled={addContactInfo === ''}
                                />
                                <Button
                                    className={styles.detailButton}
                                    type="secondarySmall"
                                    text="Cancel"
                                    onClick={() => {
                                        if (type === 'SMS') {
                                            setIsAddingPhone(false);
                                        } else if (type === 'EMAIL') {
                                            setIsAddingEmail(false);
                                        }
                                    }}
                                />
                            </>
                        )}
                    </Flex>
                </Flex>
            </li>
        );
    }

    function getAmountCollected() {
        const transactions = paymentRequest.transactions || [];
        return transactions
            .map((t: PaymentTransaction) => parseInt(t.paymentAmountCollected.toString()))
            .reduce((a: number, b: number) => a + b);
    }

    return (
        <PageContainer>
            <NavBar />
            <PageContent>
                {isLoading || !paymentRequest ? (
                    <Flex align="center" justify="center">
                        <LoadingIcon />
                    </Flex>
                ) : (
                    <div className={styles.detailContainer}>
                        <Flex self="stretch" align="center" justify="start">
                            <Flex
                                value={1}
                                className={styles.backWrap}
                                onClick={handleBack}
                                data-test-id={ANALYTICS_NAMES.PaymentRequestDetails_Back}
                            >
                                <span className={styles.backArrow}>{`< `}</span>Back to Payment Requests
                            </Flex>
                            <Flex>
                                {isEditable() && (
                                    <>
                                        <Button
                                            className={styles.detailButton}
                                            type="small"
                                            text="Collect Payment"
                                            onClick={() => {
                                                ModalStoreObject.showModal(ModalTypes.CollectPaymentModal, {
                                                    onCancel: () => ModalStoreObject.hideModal(),
                                                    onSave: () => {
                                                        loadPaymentRequest();
                                                        ModalStoreObject.hideModal();
                                                    },
                                                    paymentRequestGuid: paymentRequest.paymentRequestGuid,
                                                });
                                            }}
                                        />
                                        <Button
                                            className={styles.detailButton}
                                            type="secondarySmall"
                                            text="Cancel Payment Request"
                                            onClick={() => {
                                                ModalStoreObject.showModal(ModalTypes.CancelPaymentModal, {
                                                    onSave: () => {
                                                        ModalStoreObject.hideModal();
                                                        handleBack();
                                                    },
                                                    onCancel: () => ModalStoreObject.hideModal(),
                                                    paymentRequest,
                                                });
                                            }}
                                        />
                                    </>
                                )}
                            </Flex>
                        </Flex>
                        <Flex className={styles.detailInformation}>
                            <Flex value={1} className={styles.customerInformation}>
                                <Card className={styles.detailCardWrap}>
                                    <div className={styles.tableTitle}>Patient Information</div>
                                    <div className={styles.detailContent}>
                                        <ul>
                                            <li>
                                                <Flex>
                                                    <div className={styles.detailTitle}>First Name</div>
                                                    <div className={styles.detailValue}>
                                                        {encounter && encounter.patFrstNm}
                                                    </div>
                                                </Flex>
                                            </li>
                                            <li>
                                                <Flex>
                                                    <div className={styles.detailTitle}>Last Name</div>
                                                    <div className={styles.detailValue}>
                                                        {encounter && encounter.patLastNm}
                                                    </div>
                                                </Flex>
                                            </li>
                                            {getContacts('SMS')}
                                            <AnimateHeight duration={500} height={isAddingPhone ? 'auto' : 0}>
                                                {isAddingPhone ? getAddContact('SMS') : <></>}
                                            </AnimateHeight>
                                            {getContacts('EMAIL')}
                                            <AnimateHeight duration={500} height={isAddingEmail ? 'auto' : 0}>
                                                {isAddingEmail ? getAddContact('EMAIL') : <></>}
                                            </AnimateHeight>
                                        </ul>
                                    </div>
                                </Card>
                            </Flex>
                            <Flex value={1}>
                                <Card className={styles.detailCardWrap}>
                                    <Flex className={styles.tableTitle}>
                                        Payment Request
                                        <PaymentStatus
                                            type="PaymentRequest"
                                            status={paymentRequest.paymentRequestStateId}
                                        />
                                    </Flex>
                                    <div className={styles.detailContent}>
                                        <ul>
                                            <li>
                                                <Flex>
                                                    <div className={styles.detailTitle}>Created On</div>
                                                    <div className={styles.detailValue}>
                                                        <DateComponent
                                                            date={paymentRequest.createdAt}
                                                            showDateTime={true}
                                                        />
                                                    </div>
                                                </Flex>
                                            </li>
                                            <li>
                                                <Flex>
                                                    <div className={styles.detailTitle}>Encounter Number</div>
                                                    <div className={styles.detailValue}>
                                                        {encounter && encounter.enctrNo}
                                                    </div>
                                                </Flex>
                                            </li>
                                            <li>
                                                <Flex>
                                                    <div className={styles.detailTitle}>Payer</div>
                                                    <div className={styles.detailValue}>{payer && payer.payerName}</div>
                                                </Flex>
                                            </li>
                                            <li>
                                                <Flex>
                                                    <div className={styles.detailTitle}>Payer Category</div>
                                                    <div className={styles.detailValue}>
                                                        {payer && payer.category && payer.category.payerCategoryTitle}
                                                    </div>
                                                </Flex>
                                            </li>
                                            <li>
                                                <Flex>
                                                    <Flex className={styles.detailTitle}>
                                                        Amount Requested
                                                        {isEditable() && (
                                                            <Icon
                                                                className={styles.editIcon}
                                                                name={ICONS.Edit}
                                                                onClick={() => {
                                                                    ModalStoreObject.showModal(
                                                                        ModalTypes.ChangePaymentAmountModal,
                                                                        {
                                                                            onCancel: () =>
                                                                                ModalStoreObject.hideModal(),
                                                                            onSave: () => {
                                                                                loadPaymentRequest();
                                                                                ModalStoreObject.hideModal();
                                                                            },
                                                                            paymentRequest,
                                                                        },
                                                                    );
                                                                }}
                                                            />
                                                        )}
                                                    </Flex>
                                                    <Flex align="center" className={styles.detailValue}>
                                                        {formatNumberToCurrency(paymentRequest.paymentRequestAmount)}
                                                    </Flex>
                                                </Flex>
                                            </li>
                                            {paymentRequest.transactions.length ? (
                                                <li>
                                                    <Flex>
                                                        <div className={styles.detailTitle}>Amount Collected</div>
                                                        <Flex align="center" className={styles.detailValue}>
                                                            {formatNumberToCurrency(getAmountCollected())}
                                                        </Flex>
                                                    </Flex>
                                                </li>
                                            ) : null}
                                            {paymentRequest.state.paymentRequestStateCode === 'REQUEST_CANCELLED' ? (
                                                <li>
                                                    <Flex>
                                                        <div className={styles.detailTitle}>Cancellation Reason</div>
                                                        <Flex align="center" className={styles.detailValue}>
                                                            {paymentRequest.cancelReasonText}
                                                        </Flex>
                                                    </Flex>
                                                </li>
                                            ) : null}
                                        </ul>
                                    </div>
                                </Card>
                            </Flex>
                        </Flex>
                        <Flex className={styles.transactionTable} direction="column">
                            <Card className={styles.cardWrap}>
                                <div className={styles.tableTitle}>Payment Transactions</div>
                                <Table
                                    data={paymentRequest.transactions}
                                    columns={transactionColumns}
                                    noDataText="No payment transactions have been recorded."
                                />
                            </Card>
                        </Flex>
                    </div>
                )}
            </PageContent>
        </PageContainer>
    );
}

export default observer(PaymentRequestDetail);
