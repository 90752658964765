import { observable, action, toJS, makeObservable } from 'mobx';
import { createContext } from 'react';
import {
    ImportBatch,
    ImportBatchTemplate,
    ImportBatchRecord,
    ImportBatchRecordState,
    FilterQueryParams,
} from 'src/utils/types';
import { AggregateRecordStates } from 'common/lib/model/import/Import';
import * as importBatchService from 'src/api/importBatch';
import * as utils from 'src/utils';

class ImportBatchStore {
    @observable importBatches: ImportBatch[] = [];
    @observable importBatchesCount: number = 0;
    @observable importBatchRecordsCount: number = 0;
    @observable aggregateRecordStates: AggregateRecordStates[] = [];
    @observable importBatchTemplates: ImportBatchTemplate[] = [];
    @observable batchTemplatesDropdownValues: { value: string | number; label: string }[] = [];
    @observable importBatchRecordStates: ImportBatchRecordState[] = [];
    @observable recordStatesDropdownValues: { value: string | number; label: string }[] = [];

    constructor() {
        makeObservable(this);
    }

    @action
    reset() {
        this.importBatches = [];
        this.aggregateRecordStates = [];
        this.importBatchTemplates = [];
        this.batchTemplatesDropdownValues = [];
        this.importBatchRecordStates = [];
        this.recordStatesDropdownValues = [];
    }

    @action
    async getImportBatches(filters: FilterQueryParams, force: boolean = false) {
        if (!force && this.importBatches.length > 0) {
            return;
        }
        this.importBatches = [];
        const { result, count } = await importBatchService.getImportBatches(filters);
        this.importBatches = result;
        this.importBatchesCount = count;
    }

    async getImportBatchByGuid(guid: string) {
        const importBatch = await importBatchService.getImportBatchByGuid(guid);
        return importBatch;
    }

    @action
    async getRecordStatesForAllBatches() {
        this.aggregateRecordStates = [];
        const aggregateStates = await importBatchService.getRecordStatesForAllBatches();
        this.aggregateRecordStates = aggregateStates;
    }

    @action
    async getImportBatchTemplates(force: boolean = false, onlyActive?: boolean) {
        if (!force && this.importBatchTemplates.length > 0) {
            return;
        }
        const importBatchTemplates = await importBatchService.getImportBatchTemplates(onlyActive);
        this.importBatchTemplates = importBatchTemplates;
        this.batchTemplatesDropdownValues = utils.formatSelectOptions(importBatchTemplates, {
            valueKey: 'importBatchTemplateGuid',
            labelKey: 'importBatchTemplateTitle',
        });
    }

    async getImportBatchRecordsByGuid(guid: string, filters: FilterQueryParams) {
        const { result, count } = await importBatchService.getImportBatchRecordsByGuid(guid, filters);
        this.importBatchRecordsCount = count;
        return result;
    }

    @action
    async getImportBatchRecordStates(force: boolean = false) {
        if (!force && this.getImportBatchRecordStates.length > 0) {
            return;
        }

        const states = await importBatchService.getImportBatchRecordStates();
        this.importBatchRecordStates = states;
        this.recordStatesDropdownValues = utils.formatSelectOptions(states, {
            valueKey: 'importBatchRecordStateId',
            labelKey: 'importBatchRecordStateTitle',
        });
    }

    async getImportBatchRecordByGuid(guid: string) {
        const record = await importBatchService.getImportBatchRecordByGuid(guid);
        return record;
    }

    async updateImportBatchRecordByGuid(guid: string, data: ImportBatchRecord['recordCurrentData']) {
        const record = await importBatchService.updateImportBatchRecordByGuid(guid, data);
        return record;
    }

    async reprocessRecords(
        records: ImportBatchRecord['importBatchRecordGuid'][],
        importBatchTemplateGuid: ImportBatch['importBatchTemplateGuid'],
    ) {
        await importBatchService.reprocessImportBatchRecords({ records, importBatchTemplateGuid });
    }

    async ignoreRecords(records: ImportBatchRecord['importBatchRecordGuid'][]) {
        await importBatchService.ignoreImportBatchRecords({ records });
    }

    async unignoreRecords(
        records: ImportBatchRecord['importBatchRecordGuid'][],
        importBatchTemplateGuid: ImportBatch['importBatchTemplateGuid'],
    ) {
        await importBatchService.unignoreImportBatchRecords({ records, importBatchTemplateGuid });
    }

    findBatchRecordState(id: number) {
        return this.importBatchRecordStates.find((state) => state.importBatchRecordStateId == id);
    }

    findBatchTemplate(guid: string) {
        return this.importBatchTemplates.find((template) => template.importBatchTemplateGuid == guid);
    }
}

export const ImportBatchStoreObject = new ImportBatchStore();

export default createContext(ImportBatchStoreObject);
