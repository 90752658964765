import { observable, action, makeObservable } from 'mobx';
import { createContext } from 'react';

export enum ModalTypes {
    CreateEvent,
    Encounter,
    AddEncounterForm,
    EditEncounterForm,
    PatientNeed,
    PatientNote,
    PatientAttachment,
    ConfirmationModal,
    PatientMedication,
    CreatePatient,
    CreateScheduledJob,
    ViewContent,
    ResendInvite,
    CancelReason,
    Video,
    EncounterFormRawDetails,
    CreateFlowScriptTemplate,
    CreateImportBatchTemplate,
    CreateImportModal,
    AgencyContactModal,
    AgencyConfigurationModal,
    DeviceModal,
    VehicleModal,
    ImportBatchRecord,
    TransportFacilityModal,
    Provider,
    Facility,
    DiscreteFormModal,
    SubmitToQcdrModal,
    NemsisErrorsModal,
    PayerModal,
    PayerCategoryModal,
    PaymentTypeModal,
    PaymentGatewayModal,
    ResendPaymentRequestModal,
    CollectPaymentModal,
    CancelPaymentModal,
    ChangePaymentAmountModal,
    VitalsConnectionModal,
    AdminAuth0UserStatusModal,
    CustomerModal,
    CustomerDocumentModal,
    AutomatedDispenseSystemModal,
    CustomerContactModal,
    CustomerStatusModal,
    FacilitySubscriptionDataModal,
    CreateOrganizationModal,
    MacraAQIExportModal,
    FormModelModal,
    FormCatalogModal,
    FormAssetModal,
    FormCatalogPageModal,
    FormCatalogVersionModal,
    FormFacilityMappingModal,
}

// This is a way to manage global app modals that are showing within the app. We can dispatch these actions to show certain modal types from anywhere. They are rendered within the global app handler.
// This currently only supports showing 1 modal at a time
class ModalStore {
    @observable modalOptions: { type: ModalTypes; props: any } = { type: null, props: {} };

    constructor() {
        makeObservable(this);
    }

    @action
    showModal(type: ModalTypes, props: any = {}) {
        this.modalOptions = { type, props };
    }

    @action
    hideModal() {
        this.modalOptions = { type: null, props: {} };
    }
}

// Import this to any other store that needs to use a value from it
export const ModalStoreObject = new ModalStore();

export default createContext(ModalStoreObject);
