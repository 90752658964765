import { observable, action, makeObservable } from 'mobx';
import { createContext } from 'react';
import * as patientService from 'src/api/patients';
import { arrToObj } from 'src/utils';
import { FilterQueryParams } from 'src/utils/types';

export interface PatientAddress {
    stateCode?: string;
    city?: string;
    type?: string;
    line1?: string;
    zipCode?: string;
}

export interface PatientEmail {
    type?: string;
    email?: string;
}

export interface PatientPhone {
    type?: string;
    phone?: string;
}

export interface Patient {
    patientId?: number;
    facId?: number;
    patientNumber?: string;
    patientStateId?: number;
    patientStateTransitions?: [];
    patientLastName?: string;
    patientFirstName?: string;
    patientMiddleName?: string;
    patientSsn?: string;
    patientDob?: string;
    patientAddresses?: PatientAddress[];
    patientPhones?: PatientPhone[];
    patientEmails?: PatientEmail[];
    patientGenderCode?: string;
    patientRaceCode?: string;
    patientEthnicityCode?: string;
    patientPrimaryLanguageCode?: string;
    patientReferralSource?: string;
    patientReferralReason?: string;
    patientHasInsuranceInd?: boolean;
    patientDoNotResuscitateInd?: boolean;
    primaryProviderName?: string;
    primaryProviderAddress?: string;
    primaryProviderSpecialty?: string;
    primaryProviderPhone?: string;
    additionalProvidersNote?: string;
    powerOfAttorneyName?: string;
    powerOfAttorneyPhone?: string;
    emergencyContactName?: string;
    emergencyContactPhone?: string;
    emergencyContactRelationshipCode?: string;
    primaryDiagnosisNote?: string;
    allergyNote?: string;
    pastMedicalHistoryNote?: string;
    immunizationHistoryNote?: string;
    familyHistoryNote?: string;
    socialHistoryNote?: string;
    militaryServiceStatusCode?: string;
    militaryServiceBranchCode?: string;
    militaryRecordOnFileInd?: boolean;
    militaryDischargeStatusCode?: string;
    militaryRelatedDisabilityInd?: boolean;
    insDttm?: string;
    updDttm?: string;
    audVer?: number;
}
class PatientStore {
    @observable patients: Patient[] = [];
    @observable patientsObject: { [key in Patient['patientId']]: Patient } = {};
    @observable activePatient: Patient = {};
    @observable totalPatientsCount: number = 0;

    constructor() {
        makeObservable(this);
    }

    @action
    reset() {
        this.patients = [];
        this.patientsObject = {};
        this.activePatient = {};
        this.totalPatientsCount = 0;
    }

    @action
    async getPatients(params?: FilterQueryParams) {
        const { result, count } = await patientService.getPatients(params);
        this.setPatients(result, count);
    }

    @action
    async getActivePatient(id: number) {
        if (this.patientsObject[id]) {
            this.setActivePatient(this.patientsObject[id]);
        } else {
            const patient = await patientService.getPatient(id);
            this.setActivePatient(patient.data || {});
            this.patientsObject[id] = patient.data;
        }
    }

    @action
    async updatePatient(id: Patient['patientId'], data: Patient) {
        const response = await patientService.updatePatient(id, data);
        const updatedPatient = response.data;

        if (this.patientsObject[updatedPatient.patientId]) {
            this.patientsObject[updatedPatient.patientId] = updatedPatient;
        } else {
            this.patientsObject[updatedPatient.patientId] = updatedPatient;
        }
    }

    @action
    setPatients(patients: Patient[], total: number = 0) {
        this.patients = patients;
        this.patientsObject = arrToObj(patients, 'patientId');
        this.totalPatientsCount = total;
    }

    @action
    setActivePatient(activePatient: Patient) {
        this.activePatient = activePatient;
    }
}

// Import this to any other store that needs to use a value from it
export const PatientStoreObject = new PatientStore();

export default createContext(PatientStoreObject);
