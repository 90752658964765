import React, { useEffect, useContext } from 'react';
import ReferenceListStore, { RefListTypes } from 'src/stores/ReferenceListStore';
import { observer } from 'mobx-react';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    type: RefListTypes;
    value: string;
}

function ReferenceListItemDisplay({ value, type, ...rest }: Props) {
    const referenceListStore = useContext(ReferenceListStore);

    const refItem = (referenceListStore.referenceListValues[type] || []).find((v) => v.listValueCode === value);
    const refListValue = (refItem || {}).listValueDisplayName;

    useEffect(() => {
        referenceListStore.getData(type);
    }, []);

    return <div {...rest}>{refListValue || '-'}</div>;
}

export default observer(ReferenceListItemDisplay);
