import React, { useState, useEffect, useContext } from 'react';
import { useHistory, useLocation } from 'react-router';
import Table from 'src/components/Table';
import PageContainer from 'src/components/PageContainer';
import NavBar from 'src/containers/NavBar';
import PageContent from 'src/components/PageContent';
import Button from 'src/components/Button';
import Flex from 'src/components/Flex';
import { format, parseISO } from 'date-fns';
import { CellInfo } from 'react-table';
import Checkbox from 'src/components/Checkbox';
import { ROUTES, ICONS } from 'src/utils/constants';
import ImportBatchStore from 'src/stores/ImportBatchStore';
import { ErrorStoreObject, ErrorTypes } from 'src/stores/ErrorStore';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import FacilityStore from 'src/stores/FacilityStore';
import {
    ImportBatchRecord,
    ImportBatchRecordState,
    ImportBatch,
    ImportBatchTemplate,
    FilterQueryParams,
} from 'src/utils/types';
import DateComponent from 'src/components/DateComponent';
import Icon from 'src/components/Icon';
import * as variables from 'src/styles/variables';
import { ModalStoreObject, ModalTypes } from 'src/stores/ModalStore';
import * as utils from 'src/utils';
import { ToastStoreObject } from 'src/stores/ToastStore';
import styles from '../styles.module.scss';
import FilterStore from 'src/stores/FilterStore';
import FilteredSearch, { FilteredSearchQuery } from 'src/components/FilteredSearch';
import { getImportBatchRecordsFilters } from 'src/utils/filters';
import Pagination from 'src/components/Table/Pagination';
import FeatureFlagStore from 'src/stores/FeatureFlagStore';
import { ANALYTICS_NAMES } from 'src/utils/analytics';

const DETAIL_FIELDS = [
    {
        id: 'facilityId',
        name: 'Facility',
    },
    {
        id: 'updatedAt',
        name: 'Batch Updated',
    },
    {
        id: 'createdAt',
        name: 'Created',
    },
    {
        id: 'importBatchTemplateGuid',
        name: 'Template',
    },
];

const FILTER_PAGE = 'importBatchDetailsPage';

interface LocationState {
    isFromPaymentRequests: boolean;
}

function ImportDetail() {
    const history = useHistory();
    const importBatchStore = useContext(ImportBatchStore);
    const facilityStore = useContext(FacilityStore);
    const [importBatch, setImportBatch] = useState(null);
    const [batchRecords, setBatchRecords] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [customColumns, setCustomColumns] = useState([]);
    const [selectedRows, setSelectedRows] = useState<ImportBatchRecord['importBatchRecordGuid'][]>([]);
    const [filteredBatchRecords, setFilteredBatchRecords] = useState([]);
    const [recordsTableFilter, setRecordsTableFilter] = useState('');
    const [selectAllChecked, setSelectAllChecked] = useState(false);
    const [isFromPaymentRequests, setIsFromPaymentRequests] = useState(false);
    const filterStore = useContext(FilterStore);
    const recordFilters: FilterQueryParams = filterStore[FILTER_PAGE];
    const featureFlagStore = useContext(FeatureFlagStore);
    const location = useLocation<LocationState>();
    const locationState = location.state;

    const { importId } = useParams<any>();

    async function retrieveData() {
        try {
            setSelectAllChecked(false);
            setSelectedRows([]);
            setIsLoading(true);

            await Promise.all([
                facilityStore.getFacilities(),
                importBatchStore.getImportBatchTemplates(true),
                importBatchStore.getImportBatchRecordStates(),
            ]);

            const [batchResult, batchRecordsResult] = await Promise.all([
                await importBatchStore.getImportBatchByGuid(importId),
                await importBatchStore.getImportBatchRecordsByGuid(importId, recordFilters),
            ]);

            setImportBatch(batchResult);
            setBatchRecords(batchRecordsResult);
        } catch (e) {
            ErrorStoreObject.setError(ErrorTypes.Loading);
        } finally {
            setIsLoading(false);
        }
    }

    async function refreshRecords() {
        try {
            setIsLoading(true);
            const batchRecordsResult = await importBatchStore.getImportBatchRecordsByGuid(importId, recordFilters);
            setBatchRecords(batchRecordsResult);
            setIsLoading(false);
        } catch (e) {
            ToastStoreObject.show(utils.parseError(e));
        }
    }

    async function handleSearch({ query, fields, orStatement }: FilteredSearchQuery) {
        const updatedFilters = { ...recordFilters };
        updatedFilters.pagination.page = 1;
        updatedFilters.query = query;
        updatedFilters.orStatement = orStatement;

        updatedFilters.filters = fields;

        filterStore.setFilters(FILTER_PAGE, updatedFilters);
    }

    useEffect(() => {
        retrieveData();
    }, [recordFilters]);

    useEffect(() => {
        if (!importBatch || !importBatchStore.importBatchTemplates) return;

        const importBatchTemplateGuid = importBatch.importBatchTemplateGuid;
        const importBatchTemplate = importBatchStore.importBatchTemplates.find(
            (template: ImportBatchTemplate) => template.importBatchTemplateGuid == importBatchTemplateGuid,
        );
        const fieldsToShow = importBatchTemplate.targetDataModel
            ? importBatchTemplate.targetDataModel.filter((dataField: any) => dataField.showInList)
            : [];

        const columns = fieldsToShow.length
            ? fieldsToShow.map((customField: any) => {
                  return {
                      Header: customField.label.toUpperCase(),
                      accessor: `recordCurrentData.${customField.key}`,
                      Cell: (props: CellInfo) => <span>{props.value}</span>,
                  };
              })
            : [
                  {
                      Header: 'RECORD ID',
                      accessor: 'importBatchRecordGuid',
                      minWidth: 150,
                  },
              ];

        setCustomColumns(columns);
    }, [batchRecords]);

    useEffect(() => {
        if (locationState && locationState.isFromPaymentRequests) {
            setIsFromPaymentRequests(locationState.isFromPaymentRequests);
            // Make sure we replace the history state after we have handled it
            history.replace(location.pathname, { ...locationState, isFromPaymentRequests: undefined });
        }
    }, [locationState]);

    function getInfoLabel(key: string) {
        const field = DETAIL_FIELDS.find((field: { id: string; name: string }) => field.id === key);
        return field.name || '';
    }

    function getInfoValue(key: string) {
        if (importBatch === null || !(key in importBatch)) return '-';

        switch (key) {
            case 'updatedAt':
            case 'createdAt':
                return format(parseISO(importBatch[key].toString()), 'MM/dd/yyyy - h:mma');
            case 'facilityId':
                if (importBatch[key]) return facilityStore.findFacility(parseInt(importBatch[key])).facNm;
                else return '-';
            case 'importBatchTemplateGuid':
                if (importBatchStore.importBatchTemplates.length > 0)
                    return importBatchStore.findBatchTemplate(importBatch[key]).importBatchTemplateTitle;
                else return '-';
            default:
                return importBatch[key].toString();
        }
    }

    function handleEditRecord(record: ImportBatchRecord) {
        ModalStoreObject.showModal(ModalTypes.ImportBatchRecord, {
            onClose: () => ModalStoreObject.hideModal(),
            onSave: () => retrieveData(),
            record,
            importBatch,
        });
    }

    function handleBack() {
        if (isFromPaymentRequests) {
            history.push(ROUTES.getString(ROUTES.PaymentRequests));
            return;
        }

        history.push(ROUTES.getString(ROUTES.Import));
    }

    function handleReprocess(forceRows?: ImportBatchRecord['importBatchRecordGuid'][]) {
        ModalStoreObject.showModal(ModalTypes.ConfirmationModal, {
            title: 'Are you sure you want to reprocess these records?',
            onConfirm: () => doReprocess(forceRows),
            onCancel: () => ModalStoreObject.hideModal(),
            confirmButtonText: 'Yes, Reprocess',
            cancelButtonText: 'No',
        });
    }

    async function doReprocess(forceRows?: ImportBatchRecord['importBatchRecordGuid'][]) {
        try {
            ModalStoreObject.hideModal();
            setIsLoading(true);
            await importBatchStore.reprocessRecords(
                forceRows ? forceRows : selectedRows,
                importBatch.importBatchTemplateGuid,
            );
            setSelectedRows([]);
            setSelectAllChecked(false);
            handleBack();
        } catch (e) {
            ToastStoreObject.show(utils.parseError(e));
        }
    }

    function handleIgnore(forceRows?: ImportBatchRecord['importBatchRecordGuid'][]) {
        ModalStoreObject.showModal(ModalTypes.ConfirmationModal, {
            title: 'Are you sure you want to ignore these records?',
            onConfirm: () => doIgnore(forceRows),
            onCancel: () => ModalStoreObject.hideModal(),
            confirmButtonText: 'Yes, Ignore',
            cancelButtonText: 'No',
        });
    }

    async function doIgnore(forceRows?: ImportBatchRecord['importBatchRecordGuid'][]) {
        try {
            await importBatchStore.ignoreRecords(forceRows ? forceRows : selectedRows);
            setSelectedRows([]);
            ModalStoreObject.hideModal();
            retrieveData();
        } catch (e) {
            ToastStoreObject.show(utils.parseError(e));
        }
    }

    function handleUnignore(forceRows?: ImportBatchRecord['importBatchRecordGuid'][]) {
        ModalStoreObject.showModal(ModalTypes.ConfirmationModal, {
            title: 'Are you sure you want to unignore these records?',
            onConfirm: () => doUnignore(forceRows),
            onCancel: () => ModalStoreObject.hideModal(),
            confirmButtonText: 'Yes, Unignore',
            cancelButtonText: 'No',
        });
    }

    async function doUnignore(forceRows?: ImportBatchRecord['importBatchRecordGuid'][]) {
        try {
            await importBatchStore.unignoreRecords(
                forceRows ? forceRows : selectedRows,
                importBatch.importBatchTemplateGuid,
            );
            setSelectedRows([]);
            ModalStoreObject.hideModal();
            retrieveData();
        } catch (e) {
            ToastStoreObject.show(utils.parseError(e));
        }
    }

    function updateSelectedRows(importBatchRecordGuid: ImportBatchRecord['importBatchRecordGuid']) {
        setSelectedRows((oldRows: ImportBatchRecord['importBatchRecordGuid'][]) => {
            if (oldRows.includes(importBatchRecordGuid)) {
                return [
                    ...oldRows.filter(
                        (row: ImportBatchRecord['importBatchRecordGuid']) => row !== importBatchRecordGuid,
                    ),
                ];
            }

            return [...oldRows, importBatchRecordGuid];
        });
        setSelectAllChecked(false);
    }

    function selectAllRecords() {
        if (selectAllChecked) {
            setSelectedRows([]);
            setSelectAllChecked(false);
        } else {
            setSelectedRows(
                batchRecords.map((record: ImportBatchRecord) =>
                    isEditable(importBatchStore.findBatchRecordState(record.recordStateId).importBatchRecordStateCode)
                        ? record.importBatchRecordGuid
                        : null,
                ),
            );
            setSelectAllChecked(true);
        }
    }

    function getRowControls(
        rowData: ImportBatchRecord,
        stateCode: ImportBatchRecordState['importBatchRecordStateCode'],
    ) {
        const editRecordButton = (
            <Button
                key={1}
                type="small"
                text="Edit Record"
                disabled={stateCode === 'SEARCHING' || stateCode === 'PENDING_PROCESSING' || stateCode === 'PROCESSING'}
                onClick={() => handleEditRecord(rowData)}
                className={styles.viewDetailsButton}
                data-test-id={ANALYTICS_NAMES.ImportDetail_EditRecord}
            />
        );

        const reprocessButton = (
            <Button
                key={2}
                type="small"
                text="Reprocess"
                disabled={stateCode === 'SEARCHING' || stateCode === 'PENDING_PROCESSING' || stateCode === 'PROCESSING'}
                onClick={() => handleReprocess([rowData.importBatchRecordGuid])}
                className={styles.viewDetailsButton}
                data-test-id={ANALYTICS_NAMES.ImportDetail_Reprocess}
            />
        );

        const ignoreButton = (
            <Button
                key={3}
                type="small"
                text="Ignore"
                disabled={stateCode === 'SEARCHING' || stateCode === 'PENDING_PROCESSING' || stateCode === 'PROCESSING'}
                onClick={() => handleIgnore([rowData.importBatchRecordGuid])}
                className={styles.viewDetailsButton}
                data-test-id={ANALYTICS_NAMES.ImportDetail_Ignore}
            />
        );

        const unignoreButton = (
            <Button
                key={4}
                type="small"
                text="Unignore"
                onClick={() => handleUnignore([rowData.importBatchRecordGuid])}
                className={styles.viewDetailsButton}
                data-test-id={ANALYTICS_NAMES.ImportDetail_Unignore}
            />
        );

        let controls = [];

        if (!featureFlagStore.isEnabled('import-actions')) {
            return null;
        }

        if (stateCode === 'ENTITY_CREATED' || stateCode === 'ENTITY_UPDATED') {
            return null;
        }

        if (stateCode === 'IGNORED') {
            controls.push(unignoreButton);
            return controls;
        }

        if (
            stateCode === 'MATCH_ERROR' ||
            stateCode === 'NO_MATCH' ||
            stateCode === 'PROCESSING_ERROR' ||
            stateCode === 'PROCESSING_MATCH_ERROR'
        ) {
            controls.push(reprocessButton);
        }

        if (stateCode !== 'IGNORED') {
            controls.push(ignoreButton, editRecordButton);
        }

        return controls;
    }

    function isEditable(recordState: ImportBatchRecordState['importBatchRecordStateCode']) {
        switch (recordState) {
            case 'ENTITY_UPDATED':
            case 'ENTITY_CREATED':
            case 'IGNORED':
                return false;
            default:
                return true;
        }
    }

    function isRecordError(responseType: string) {
        switch (responseType) {
            case 'ERROR':
            case 'NO_MATCH':
            case 'MATCH_ERROR':
            case 'PROCESSING_ERROR':
            case 'PROCESSING_MATCH_ERROR':
                return true;
            default:
                return false;
        }
    }

    const checkboxColumn = [
        {
            Header: (
                <Checkbox
                    checked={selectAllChecked}
                    data-test-id={ANALYTICS_NAMES.ImportDetail_SelectAll}
                    onChange={() => selectAllRecords()}
                />
            ),
            minWidth: 25,
            sortable: false,
            Cell: (props: CellInfo) => (
                <Checkbox
                    checked={selectedRows.indexOf(props.original.importBatchRecordGuid) > -1}
                    disabled={
                        !isEditable(
                            importBatchStore.findBatchRecordState(props.original.recordStateId)
                                .importBatchRecordStateCode,
                        )
                    }
                    onChange={() => updateSelectedRows(props.original.importBatchRecordGuid)}
                    data-test-id={ANALYTICS_NAMES.ImportDetail_SelectRecord}
                />
            ),
        },
    ];

    const defaultColumns = [
        {
            Header: 'UPDATED',
            accessor: 'updatedAt',
            maxWidth: 175,
            Cell: (props: CellInfo) => <DateComponent date={props.value} showDateTime={true} />,
        },
        {
            Header: 'STATUS',
            accessor: 'recordStateId',
            maxWidth: 175,
            Cell: (props: CellInfo) => (
                <span>
                    {importBatchStore.findBatchRecordState(props.original.recordStateId).importBatchRecordStateTitle}
                </span>
            ),
        },
        {
            Header: 'ERRORS',
            accessor: 'importBatchRecordExecution',
            maxWidth: 100,
            Cell: (props: CellInfo) => {
                if (!props.value || !props.value.length) return null;

                // Sort the import batch record executions by date created
                const sorted = props.value.sort((a: any, b: any) => {
                    return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
                });
                // Get the last created script execution attempt
                const { scriptExecutionAttempt } = sorted[0];

                if (!scriptExecutionAttempt) return null;

                return (
                    <Flex direction="row" justify="center">
                        {scriptExecutionAttempt.responseData &&
                        scriptExecutionAttempt.responseData.e &&
                        isRecordError(scriptExecutionAttempt.responseData.type) &&
                        scriptExecutionAttempt.responseData.e &&
                        scriptExecutionAttempt.responseData.e.message ? (
                            <Icon
                                name={ICONS.NotesBookText}
                                color={variables.red}
                                size={20}
                                onClick={() => {
                                    ModalStoreObject.showModal(ModalTypes.ViewContent, {
                                        onClose: () => ModalStoreObject.hideModal(),
                                        staticContent: (
                                            <div className={styles.errorDetailsModal}>
                                                {scriptExecutionAttempt.responseData.e.message}
                                            </div>
                                        ),
                                        title: `Error Message`,
                                    });
                                }}
                                data-test-id={ANALYTICS_NAMES.ImportDetail_ErrorIcon}
                            />
                        ) : null}
                    </Flex>
                );
            },
        },
        featureFlagStore.isEnabled('import-actions')
            ? {
                  Header: 'ACTIONS',
                  minWidth: 150,
                  style: { paddingVertical: 0, paddingHorizontal: 20 },
                  Cell: (props: CellInfo) => (
                      <Flex direction="row">
                          {getRowControls(
                              props.original,
                              importBatchStore.findBatchRecordState(props.original.recordStateId)
                                  .importBatchRecordStateCode,
                          )}
                      </Flex>
                  ),
              }
            : {},
    ];

    function handlePaginationFilterChange(newPage: number) {
        const updatedFilters = { ...recordFilters };
        updatedFilters.pagination.page = newPage;
        filterStore.setFilters(FILTER_PAGE, updatedFilters);
    }

    return (
        <PageContainer>
            <NavBar />
            <PageContent>
                <div className={styles.backWrap} onClick={() => handleBack()}>
                    <span className={styles.backArrow}>{`< `}</span>
                    {`Back to ${isFromPaymentRequests ? 'Payment Requests' : 'Batch List'}`}
                </div>

                <Flex className={styles.batchContent}>
                    <div className={styles.tableWrap}>
                        <div className={styles.tableTitle}>
                            <div>{importBatch ? importBatch.importBatchTitle : ''}</div>
                            <Flex wrap="wrap" className={styles.batchInfo}>
                                {DETAIL_FIELDS.map((value: { id: string; name: string }, index: number) => (
                                    <Flex key={index} grow={1} className={styles.infoContainer}>
                                        <div className={styles.infoName}>{getInfoLabel(value.id)}</div>
                                        <div className={styles.infoData}>
                                            {importBatch == null || !(value.id in importBatch)
                                                ? '-'
                                                : getInfoValue(value.id)}
                                        </div>
                                    </Flex>
                                ))}
                            </Flex>
                        </div>
                        <Flex direction="row" className={styles.recordFilterWrap}>
                            <Flex value={1}>
                                <FilteredSearch
                                    initialValues={{
                                        query: recordFilters.query,
                                        fields: recordFilters.filters,
                                        orStatement: recordFilters.orStatement,
                                    }}
                                    onSearch={handleSearch}
                                    searchPlaceholder={'Search records by Encounter Number'}
                                    filterOptions={getImportBatchRecordsFilters() as any}
                                />
                            </Flex>
                        </Flex>
                        <div>
                            <Flex>
                                <Flex value={1} className={styles.tableTitle}>
                                    Records
                                </Flex>
                                <div>
                                    <Button
                                        className={styles.buttonWrap}
                                        type="primary"
                                        disabled={isLoading}
                                        text="Refresh Records"
                                        onClick={() => refreshRecords()}
                                        data-test-id={ANALYTICS_NAMES.ImportDetail_RefreshRecords}
                                    />
                                    {featureFlagStore.isEnabled('import-actions') ? (
                                        <>
                                            <Button
                                                className={styles.buttonWrap}
                                                type="primary"
                                                disabled={selectedRows.length === 0}
                                                text="Ignore Selected Records"
                                                onClick={() => handleIgnore()}
                                                data-test-id={ANALYTICS_NAMES.ImportDetail_IgnoreSelected}
                                            />
                                            <Button
                                                className={styles.buttonWrap}
                                                type="primary"
                                                disabled={selectedRows.length === 0}
                                                text="Reprocess Selected Records"
                                                onClick={() => handleReprocess()}
                                                data-test-id={ANALYTICS_NAMES.ImportDetail_ReprocessSelected}
                                            />
                                        </>
                                    ) : null}
                                </div>
                            </Flex>
                        </div>
                        <Table
                            columns={[...checkboxColumn, ...customColumns, ...defaultColumns]}
                            data={batchRecords}
                            noDataText="No records match your search, please adjust your filters"
                            loading={isLoading}
                            showPagination={false}
                            pageSize={filterStore[FILTER_PAGE].pagination.limit}
                        />
                        {/* Custom Pagination for server side data */}
                        {batchRecords.length > 0 ? (
                            <Pagination
                                pages={
                                    Math.ceil(
                                        importBatchStore.importBatchRecordsCount / recordFilters.pagination.limit,
                                    ) || 1
                                }
                                page={recordFilters.pagination.page - 1}
                                onPageChange={(page: number) => handlePaginationFilterChange(page + 1)}
                                showing={batchRecords.length}
                                totalRecords={importBatchStore.importBatchRecordsCount}
                            />
                        ) : null}
                    </div>
                </Flex>
            </PageContent>
        </PageContainer>
    );
}

export default observer(ImportDetail);
