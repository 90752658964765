import request from 'src/api/utils';
import * as ROUTES from 'src/api/routes';
import { AutomatedDispenseSystem } from 'common/lib/entity/org/AutomatedDispenseSystem';

export async function getAutomatedDispenseSystems(params?: {
    includeInactive: boolean;
    query?: string;
    facilityId?: number;
}) {
    const result = await request({
        ...ROUTES.GET_ADS,
        params: { ...params },
    });

    return result.data;
}

export async function updateAutomatedDispenseSystem(
    systemId: AutomatedDispenseSystem['systemId'],
    adsUpdates: Partial<AutomatedDispenseSystem>,
) {
    return await request({
        ...ROUTES.UPDATE_ADS,
        pathParams: { systemId },
        data: { ...adsUpdates },
    });
}
