import React from 'react';

const SvgTimeClockCircle1Alternate = (props) => (
    <svg width={17} height={17} {...props} viewBox="0 0 17 17">
        <g fill={props.color || '#FFF'} fillRule="nonzero">
            <path d="M8.5 0A8.5 8.5 0 1017 8.5 8.51 8.51 0 008.5 0zm0 15.583A7.083 7.083 0 1115.583 8.5 7.091 7.091 0 018.5 15.583z" />
            <path d="M13.458 7.792H8.793L6.876 5.875a.708.708 0 00-1.002 1.002L8 9.002a.708.708 0 00.501.206h4.958a.708.708 0 000-1.416z" />
            <circle cx={3.365} cy={8.501} r={1} />
            <circle cx={8.5} cy={3.365} r={1} />
            <circle cx={8.5} cy={13.636} r={1} />
        </g>
    </svg>
);

export default SvgTimeClockCircle1Alternate;
