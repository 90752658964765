import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

export interface LoadingIconProps {
    type?: 'dot' | 'darkDot' | 'spinner';
}

function LoadingIcon(props: LoadingIconProps) {
    if (props.type === 'dot' || props.type === 'darkDot') {
        return (
            <div className={classNames({
                [styles.dot]: props.type === 'dot' || props.type === 'darkDot',
                [styles.dark]: props.type === 'darkDot',
            })}>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        );
    }
    return (
        <div className={styles.loader}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    );
}

export default LoadingIcon;
