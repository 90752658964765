import React, { useContext, useState, useEffect } from 'react';
import Flex from 'src/components/Flex';
import PageContainer from 'src/components/PageContainer';
import PageContent from 'src/components/PageContent';
import Button from 'src/components/Button';
import NavBar from 'src/containers/NavBar';
import Card from 'src/components/Card';
import styles from './styles.module.scss';
import Table from 'src/components/Table';
import FeatureFlagStore from 'src/stores/FeatureFlagStore';
import Icon from 'src/components/Icon';
import { ICONS } from 'src/utils/constants';
import { CellInfo } from 'react-table';
import DateComponent from 'src/components/DateComponent';
import PaymentsStore, { PayerCategory, PaymentGateway, PaymentGatewayType } from 'src/stores/PaymentsStore';
import ActionMenu from 'src/components/ActionMenu';
import { ModalStoreObject, ModalTypes } from 'src/stores/ModalStore';
import TableActiveCol from 'src/components/TableActiveCol';
import { ANALYTICS_NAMES } from 'src/utils/analytics';
import { observer } from 'mobx-react';
import { ToastStoreObject } from 'src/stores/ToastStore';
import { parseError } from 'src/utils';

function PaymentGateways() {
    const paymentsStore = useContext(PaymentsStore);
    const featureFlagStore = useContext(FeatureFlagStore);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        getPaymentGateways(true);
    }, []);

    const columns = [
        {
            Header: 'NAME',
            accessor: 'paymentGatewayTitle',
        },
        {
            Header: 'DESCRIPTION',
            accessor: 'paymentGatewayDescription',
        },
        {
            Header: 'TYPE',
            accessor: 'paymentGatewayTypeId',
            Cell: (props: CellInfo) => {
                const paymentGatewayType = paymentsStore.paymentGatewayTypes.find(
                    (pgt: PaymentGatewayType) => pgt.paymentGatewayTypeId.toString() === props.value,
                );
                return <span>{paymentGatewayType && paymentGatewayType.paymentGatewayTypeTitle}</span>;
            },
        },
        {
            Header: 'CREATED',
            accessor: 'createdAt',
            minWidth: 75,
            Cell: (props: CellInfo) => {
                return <DateComponent date={props.value} />;
            },
        },
        {
            Header: 'UPDATED',
            accessor: 'updatedAt',
            minWidth: 75,
            Cell: (props: CellInfo) => {
                return <DateComponent date={props.value} />;
            },
        },
        {
            Header: 'ACTIVE',
            accessor: 'isActive',
            minWidth: 50,
            Cell: (props: CellInfo) => <TableActiveCol isActive={props.value && props.value.toString() === 'true'} />,
        },
        {
            Header: 'ACTIONS',
            accessor: 'actions',
            maxWidth: 125,
            Cell: (props: CellInfo) => (
                <ActionMenu
                    entityType="paymentGateway"
                    entity={props.original}
                    onItemClick={(action: { id: string; label: string }) => handleActionClick(action, props.original)}
                />
            ),
        },
    ];

    async function getPaymentGateways(force = false) {
        setIsLoading(true);

        try {
            await paymentsStore.getPaymentGateways(true, force);
            await paymentsStore.getPaymentGatewayTypes(force);
        } catch (e: any) {
            ToastStoreObject.show(parseError(e));
        } finally {
            setIsLoading(false);
        }
    }

    function handleActionClick(action: { id: string; label: string }, item: PaymentGateway) {
        switch (action.id) {
            case 'edit':
                ModalStoreObject.showModal(ModalTypes.PaymentGatewayModal, {
                    onClose: () => ModalStoreObject.hideModal(),
                    onSave: () => getPaymentGateways(true),
                    paymentGateway: item,
                    mode: 'edit',
                });
                break;
            case 'activate':
                if (item.isActive) {
                    ModalStoreObject.showModal(ModalTypes.ConfirmationModal, {
                        title: 'Are you sure you would like to deactivate this payment gateway?',
                        onConfirm: () => handleDeactivate(item.paymentGatewayId),
                        onCancel: () => ModalStoreObject.hideModal(),
                        confirmButtonText: 'Deactivate',
                    });
                } else {
                    handleReactivate(item.paymentGatewayId);
                }
                break;
        }
    }

    async function handleDeactivate(paymentGatewayId: PaymentGateway['paymentGatewayId']) {
        setIsLoading(true);
        ModalStoreObject.hideModal();

        try {
            await paymentsStore.deactivatePaymentGateway(paymentGatewayId);
            getPaymentGateways(true);
        } catch (e: any) {
            ToastStoreObject.show(parseError(e));
        } finally {
            setIsLoading(false);
        }
    }

    async function handleReactivate(paymentGatewayId: PaymentGateway['paymentGatewayId']) {
        setIsLoading(true);
        ModalStoreObject.hideModal();

        try {
            await paymentsStore.reactivatePaymentGateway(paymentGatewayId);
            getPaymentGateways(true);
        } catch (e: any) {
            ToastStoreObject.show(parseError(e));
        } finally {
            setIsLoading(false);
        }
    }

    function handleCreate() {
        ModalStoreObject.showModal(ModalTypes.PaymentGatewayModal, {
            onClose: () => ModalStoreObject.hideModal(),
            onSave: () => getPaymentGateways(true),
        });
    }

    return (
        <PageContainer>
            <NavBar />
            <PageContent>
                <Flex direction="row" justify="end" className={styles.filterWrap}>
                    {featureFlagStore.isEnabled('payments') ? (
                        <Button
                            leftIcon={<Icon className={styles.plusIcon} name={ICONS.PlusButton} />}
                            type="primary"
                            onClick={() => handleCreate()}
                            text="Create Payment Gateway"
                            className={styles.createButton}
                            data-test-id={ANALYTICS_NAMES.PaymentGateway_Create}
                        />
                    ) : null}
                </Flex>
                <Card className={styles.cardWrap}>
                    <div className={styles.tableTitle}>Payment Gateways</div>
                    <Table
                        isLoading={isLoading}
                        columns={columns}
                        data={paymentsStore.paymentGateways}
                        showPagination={false}
                    />
                </Card>
            </PageContent>
        </PageContainer>
    );
}

export default observer(PaymentGateways);
