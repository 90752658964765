import { observable, action, makeObservable } from 'mobx';
import { createContext } from 'react';
import * as surveyDefinitionsService from 'src/api/surveyDefinitions';

class SurveyDefinitionStore {
    @observable surveyDefinitions: any[] = [];
    @observable totalCount: number = 0;

    constructor() {
        makeObservable(this);
    }

    @action
    reset() {
        this.surveyDefinitions = [];
        this.totalCount = 0;
    }

    async getSurveyDefinitions() {
        const { result, count } = await surveyDefinitionsService.getSurveyDefinitions();
        this.setSurveyDefinitions(result || [], count);
    }

    @action
    setSurveyDefinitions(surveyDefinitions: any[], totalCount: number = 0) {
        this.surveyDefinitions = surveyDefinitions;
        this.totalCount = totalCount;
    }
}

export const SurveyDefinitionStoreObject = new SurveyDefinitionStore();

export default createContext(SurveyDefinitionStoreObject);
