import React from 'react';

const SvgLightModeNight = (props) => (
    <svg width={20} height={20} {...props} viewBox="0 0 20 20">
        <path
            d="M1.53 18.38a9.232 9.232 0 01.74-3.518c.52-1.039 2.689-1.759 5.412-2.767.736-.273.615-2.196.29-2.555a5.429 5.429 0 01-1.415-4.212A3.551 3.551 0 0110 1.438a3.551 3.551 0 013.448 3.89 5.429 5.429 0 01-1.415 4.212c-.326.36-.447 2.282.289 2.555 2.723 1.008 4.893 1.728 5.412 2.767a9.232 9.232 0 01.74 3.518H1.53z"
            stroke={props.color || '#FFF'}
            strokeWidth={1.52}
            fill="none"
            fillRule="evenodd"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default SvgLightModeNight;
