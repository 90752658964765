import React, { useEffect, useState } from 'react';
import CodeEditor from 'src/components/CodeEditor';
import Flex from 'src/components/Flex';
import { useParams } from 'react-router-dom';
import { useFormCatalog } from 'src/queries/useFormCatalog';
import { useUpdateFormCatalog } from 'src/queries/useUpdateFormCatalog';
import styles from './styles.module.scss';

function GeneratedFormDefinition() {
    const { formCatalogId } = useParams<any>();
    const { data: formCatalog } = useFormCatalog(formCatalogId);
    const [formContentGenerated, setFormContentGenerated] = useState('');

    useEffect(() => {
        setFormContentGenerated(formCatalog.formContentGenerated);
    }, [formCatalog]);

    return (
        <>
            <Flex direction="row" gap={16} justify="end" className={styles.controls}>
            </Flex>
            <Flex className={styles.container}>
                <CodeEditor
                    minLines={30}
                    maxLines={60}
                    mode="xml"
                    theme="xcode"
                    value={formContentGenerated}
                />
            </Flex>
        </>
    );
}

export default GeneratedFormDefinition;
