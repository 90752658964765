import { CustomerStatus } from 'common/lib/entity/index/Customer';
import { observer } from 'mobx-react';
import React from 'react';
import { useState } from 'react';
import Select from 'react-select';
import { styleSelectComponent } from 'src/utils';
import styles from '../styles.module.scss';

interface CustomerStatusDropdownProps {
    onChange: Function;
    selectedValue?: CustomerStatus;
}

function CustomerStatusDropdown(props: CustomerStatusDropdownProps) {
    const options = mapStatusOptions();
    const [selected, setSelected] = useState(options.find((i) => i.value === props.selectedValue) || null);

    function mapStatusOptions() {
        const statuses = Object.values(CustomerStatus);
        const statusOptions = statuses.map((value) => {
            let label;
            switch (value) {
                case CustomerStatus.Active:
                    label = 'Active';
                    break;
                case CustomerStatus.Offboarded:
                    label = 'Offboarded';
                    break;
                case CustomerStatus.Offboarding:
                    label = 'Offboarding';
                    break;
                case CustomerStatus.Onboarding:
                    label = 'Onboarding';
                    break;
            }

            return { label, value };
        });

        return statusOptions.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
    }

    return (
        <Select
            value={selected}
            onChange={(s: any) => {
                setSelected(s);
                props.onChange(s || null);
            }}
            isClearable={true}
            className={styles.select}
            options={options}
            placeholder="Select Status"
            styles={styleSelectComponent()}
        />
    );
}

export default observer(CustomerStatusDropdown);
