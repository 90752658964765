import React, { useState } from 'react';
import styles from './styles.module.scss';
import { useEffect } from 'react';
import FacilityDropdown from 'src/components/FacilityDropDown';

export interface FacilitySelectorModalProps {
    selectedFacilities: number[];
    onChange: Function;
}

function FacilitySelectorModal(props: FacilitySelectorModalProps) {
    const [facilities, setFacilities] = useState<number[]>(props.selectedFacilities || []);

    useEffect(() => {
        props.onChange(facilities);
    }, [facilities]);

    function handleFacilityChange(facilities: any) {
        setFacilities(facilities.map((facility: any) => facility.value));
    }

    return (
        <div className={styles.contentWrap}>
            <FacilityDropdown
                isMulti={true}
                selectedValue={props.selectedFacilities}
                onChange={(facilities: any) => handleFacilityChange(facilities)}
                usePortal={true}
            />
        </div>
    );
}

export default FacilitySelectorModal;
