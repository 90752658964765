export function phoneNumberIsValid(phone: string): boolean {
    const phoneRegEx = new RegExp('^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}$');

    return phoneRegEx.test(phone);
}

export function emailAddressIsValid(email: string): boolean {
    const emailRegEx = new RegExp('^[^\\s@]+@[^\\s@]+\\.[^\\s@]+$');

    return emailRegEx.test(email);
}
