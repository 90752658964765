import React, { useState, useEffect } from 'react';
import styles from './styles.module.scss';
import Input from 'src/components/Input';
import { Team } from 'src/stores/TeamStore';
import { Facility } from 'src/stores/FacilityStore';
import { observer } from 'mobx-react';

export interface TeamModalProps {
    team: Team;
    onChange: Function;
    facilityId: Facility['facId'];
}

function TeamModal(props: TeamModalProps) {
    const [teamName, setTeamName] = useState(props.team.teamName || '');
    const [teamDescription, setTeamDescription] = useState(props.team.teamDescription || '');

    useEffect(() => {
        props.onChange({
            teamId: props.team.teamId,
            teamName,
            teamDescription,
            facId: props.facilityId,
        });
    }, [teamName, teamDescription, props.facilityId]);

    return (
        <div className={styles.contentWrap}>
            <Input label="Team Name" value={teamName} onChangeText={(value: string) => setTeamName(value)} />
            <Input
                label="Description"
                value={teamDescription}
                onChangeText={(value: string) => setTeamDescription(value)}
            />
        </div>
    );
}

export default observer(TeamModal);
