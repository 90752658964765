import React from 'react';

const SvgMultipleUsersAdd = (props) => (
    <svg width={20} height={16} {...props} viewBox="0 0 20 16">
        <g
            stroke={props.color || '#FFF'}
            strokeWidth={1.3}
            fill="none"
            fillRule="evenodd"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path d="M1.5 14.5c.01-.928.202-1.846.565-2.7.4-.8 2.063-1.35 4.153-2.124.565-.209.472-1.684.222-1.96a4.167 4.167 0 01-1.085-3.231A2.725 2.725 0 018 1.5a2.725 2.725 0 012.645 2.985A4.167 4.167 0 019.56 7.717c-.25.276-.343 1.751.222 1.96 2.09.774 3.754 1.327 4.153 2.124.363.854.555 1.772.565 2.7l-13-.001zM11.912 1.737A2.573 2.573 0 0113 1.5a2.725 2.725 0 012.645 2.985 4.167 4.167 0 01-1.085 3.232c-.25.276-.343 1.751.222 1.96 2.09.774 3.754 1.327 4.153 2.124.024.049.048.1.07.15L19 14" />
        </g>
    </svg>
);

export default SvgMultipleUsersAdd;
