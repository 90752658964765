import React, { useContext, useEffect, useState } from 'react';
import Flex from 'src/components/Flex';
import styles from './styles.module.scss';
import { observer } from 'mobx-react';
import Table from 'src/components/Table';
import { CellInfo } from 'react-table';
import PayerCategoryDropDown from '../PayerCategoryDropDown';
import PaymentsStore, { Payer, PayerCategory } from 'src/stores/PaymentsStore';
import Card from 'src/components/Card';

interface ReviewPayersProps {
    onChange?: Function;
}

function ReviewPayers(props: ReviewPayersProps) {
    const paymentsStore = useContext(PaymentsStore);
    const [payers, setPayers] = useState<Partial<Payer>[]>([]);

    const columns = [
        {
            Header: 'INSURANCE',
            accessor: 'payerName',
            minWidth: 150,
            Cell: (props: CellInfo) => {
                return <span>{props.original}</span>;
            },
        },
        {
            Header: 'INSURANCE TYPE',
            accessor: 'insuranceType',
            Cell: (props: CellInfo) => {
                return (
                    <PayerCategoryDropDown
                        onChange={(v: any) => handlePayerCategorization(props.original, v.value)}
                        menuPortalTarget={document.body}
                    />
                );
            },
        },
    ];

    useEffect(() => {
        if (props.onChange) {
            props.onChange(payers);
        }
    }, [payers]);

    function handlePayerCategorization(
        payerName: Payer['payerName'],
        payerCategoryCode: PayerCategory['payerCategoryCode'],
    ) {
        const payerCategory = paymentsStore.payerCategories.find(
            (pc: PayerCategory) => pc.payerCategoryCode === payerCategoryCode,
        );
        const payersWithout = payers.filter((p: Partial<Payer>) => p.payerName !== payerName);
        setPayers([...payersWithout, { payerName, payerCategoryId: payerCategory.payerCategoryId }]);
    }

    return (
        <Flex direction="column">
            <div className={styles.stepTitle}>Review Providers with Unmatched Insurance</div>
            <Card className={styles.cardWrap}>
                <Table columns={columns} data={paymentsStore.uncategorizedPayerNames} showPagination={false} />
            </Card>
        </Flex>
    );
}

export default observer(ReviewPayers);
