import React, { useState, useContext, useEffect } from 'react';
import { observer } from 'mobx-react';
import PageContainer from 'src/components/PageContainer';
import NavBar from '../NavBar';
import PageContent from 'src/components/PageContent';
import TabNav from 'src/components/TabNav';
import LoadingCover from 'src/components/LoadingCover';
import AgencyInformation from './AgencyInformation';
import { AgencyContacts } from './AgencyContacts';
import OrgStore from 'src/stores/OrgStore';
import ReferenceListStore from 'src/stores/ReferenceListStore';
import { OptionTypes } from 'src/components/OptionDropDown';
import NemsisSubmission from './NemsisSubmission';
import NemsisStore from 'src/stores/NemsisStore';

const Tabs = ['Agency Information', 'Agency Contacts', 'NEMSIS Submission'] as const;
// https://steveholgado.com/typescript-types-from-arrays/
type TabString = typeof Tabs[number];

function SettingsNemsis() {
    const [selectedTab, setSelectedTab] = useState<TabString>(Tabs[0]);
    const [isLoading, setIsLoading] = useState(false);
    const orgStore = useContext(OrgStore);
    const nemsisStore = useContext(NemsisStore);
    const refListStore = useContext(ReferenceListStore);

    useEffect(() => {
        loadOrgNemsisDemographicData({ initial: true });
    }, []);

    async function loadRefLists() {
        try {
            await refListStore.getMultipleLists([
                OptionTypes.ANSICountyCodes,
                OptionTypes.ANSIStateCodes,
                OptionTypes.EMSAgencyContactType,
                OptionTypes.EMSAgencyOrganizationalTaxStatus,
                OptionTypes.EMSAgencyTimeZone,
                OptionTypes.EMSANSICountryCodesCAMXUS,
                OptionTypes.EMSEmailAddressType,
                OptionTypes.EMSHospitalDesignation,
                OptionTypes.EMSLicensureLevels,
                OptionTypes.EMSOrganizationalType,
                OptionTypes.EMSOrganizationStatus,
                OptionTypes.EMSPatientMonitoringCapability,
                OptionTypes.EMSPhoneNumberType,
                OptionTypes.EMSProtocolsUsed,
                OptionTypes.EMSStateCertificationLicensureLevels,
                OptionTypes.EMSTypeOfFacility,
                OptionTypes.EMSTypeOfService,
                OptionTypes.EMSYesNoValues,
                OptionTypes.EMSAgencyMedicalDirectorDegree,
                OptionTypes.EMSAgencyMedicalDirectorBoardCertificationType,
                OptionTypes.EMSMedicalDirectorCompensation,
            ]);
        } catch (e) {}
    }

    async function loadOrgNemsisDemographicData(options: { initial?: boolean } = {}) {
        try {
            setIsLoading(true);
            await Promise.all([orgStore.getOrgDbOrg(), ...(options.initial ? [loadRefLists()] : [])]);
        } finally {
            setIsLoading(false);
        }
    }

    function getSelectedTab() {
        const commonProps = { onRefresh: loadOrgNemsisDemographicData };
        switch (selectedTab) {
            case 'Agency Information':
                return <AgencyInformation {...commonProps} />;

            case 'Agency Contacts':
                return <AgencyContacts {...commonProps} />;

            case 'NEMSIS Submission':
                return <NemsisSubmission />;
        }
    }

    return (
        <PageContainer>
            <NavBar />
            <PageContent>
                <TabNav tabs={Tabs as any} onTabClick={setSelectedTab} />
                {isLoading || !orgStore.currentOrgDbOrg ? <LoadingCover style={{ top: 90 }} /> : getSelectedTab()}
            </PageContent>
        </PageContainer>
    );
}

export default observer(SettingsNemsis);
