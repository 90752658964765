export function formatNumberToCurrency(amount: number) {
    if (Intl && Intl.NumberFormat) {
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        });

        return formatter.format(amount);
    }

    return `$${amount.toFixed(2)}`;
}
