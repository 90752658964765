import React from 'react';

const SvgAttachmnent = (props) => (
    <svg width={33} height={33} {...props} viewBox="0 0 33 33">
        <g transform="translate(1 1)" fill="none" fillRule="evenodd">
            <circle stroke="#F1F0F0" fill="#F9F9F9" cx={15.5} cy={15.5} r={15.5} />
            <path
                d="M23.469 13.887l-7.776 7.637c-1.867 1.834-4.894 1.834-6.761 0a4.636 4.636 0 010-6.64l6.386-6.272a3.226 3.226 0 013.1-.854 3.16 3.16 0 012.276 2.236 3.093 3.093 0 01-.869 3.044l-6.386 6.275a1.615 1.615 0 01-2.234-.019 1.545 1.545 0 01-.02-2.194l6.387-6.274"
                stroke="#DA1057"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.55}
            />
        </g>
    </svg>
);

export default SvgAttachmnent;
