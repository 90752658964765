const NUM_REGX =  /^(\s*|[\(\d-\)]+)$/;
export const PHONE_REGX = /^(\s*|[\+]?[(][0-9]{3}[)][-\s\.][0-9]{3}[-\s\.]?[0-9]{4})$/;
export const EMAIL_REGX = /^(\s*|[\w-\.]+@([\w-]+\.)+[\w-]{2,4})$/;

export function getAutoFormatPhone(phoneNum: string) {
   
    if(!NUM_REGX.test(phoneNum)){
        return "";
    }

    if(phoneNum.length == 3){
        phoneNum = phoneNum.charAt(0) == "(" ? phoneNum : "(" + phoneNum + ")-";
    }

    if(phoneNum.length == 10) {
        phoneNum = phoneNum.charAt(9) == "-" ? phoneNum : phoneNum.substring(0,9) + "-" + phoneNum.substring(9);
    }

    return phoneNum;
}
