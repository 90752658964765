import * as variables from 'src/styles/variables';
import { parseISO as parseDateFns, isValid } from 'date-fns';
import memoize from 'lodash/memoize';
import { Patient } from 'src/stores/PatientStore';
import { ReactSelectOption, TelehealthSession, SessionStateCodes } from './types';
import { Encounter } from 'src/utils/types';

/**
 * Check if a given value exists
 *
 * @export
 * @param {*} val
 * @returns {boolean}
 */
export function exists(val: any): boolean {
    return val !== undefined;
}

/**
 * Do a deep copy of an object or array
 *
 * @export
 * @param {*} val
 * @returns {any}
 */
export function clone(val: any): any {
    return JSON.parse(JSON.stringify(val));
}

/**
 * Check if a given value is an array
 *
 * @export
 * @param {*} val
 * @returns {val is any[]}
 */
export function isArray(val: any): val is any[] {
    return Array.isArray(val);
}

/**
 * Check if a given value is an object
 *
 * @export
 * @param {*} val
 * @returns {val is object}
 */
export function isObject(val: any): val is object {
    return typeof val === 'object' && !isArray(val) && val !== null;
}

/**
 * Check if a given value is a string
 *
 * @export
 * @param {*} val
 * @returns {val is string}
 */
export function isString(val: any): val is string {
    return typeof val === 'string';
}

/**
 * Check if a given value is a boolean
 *
 * @export
 * @param {*} val
 * @returns {val is boolean}
 */
export function isBool(val: any): val is boolean {
    return typeof val === 'boolean';
}

/**
 * Check if a given value is a number
 *
 * @export
 * @param {*} val
 * @returns {val is number}
 */
export function isNumber(val: any): val is number {
    return typeof val === 'number';
}

/**
 * Remove any null values from an object
 *
 * @export
 * @param {({ [key in string | number]: any })} obj
 * @returns {object}
 */
export function removeNull(obj: { [key in string | number]: any }): object {
    Object.keys(obj).forEach((key) => obj[key] === null && delete obj[key]);
    return obj;
}

/**
 * Calculate age in years based on a date of birth
 *
 * @export
 * @param {(number | string | Date)} birthday
 * @returns {number}
 */
export function calculateAge(birthday: number | string | Date): number {
    const ageDifMs = Date.now() - new Date(birthday).getTime();
    const ageDate = new Date(ageDifMs); // miliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970);
}

/**
 * Get a random number between between min and max inclusive
 *
 * @export
 * @param {number} min
 * @param {number} max
 * @returns {number}
 */
export function randomIntFromInterval(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1) + min);
}

/**
 * Format arrays into options to be used with react-select
 *
 * @export
 * @param {any[]} arr
 * @param {{ valueKey?: string | number; labelKey?: string; getValue?: Function; getLabel?: Function }} options
 * @returns {ReactSelectOption[]}
 */
export function formatSelectOptions(
    arr: any[],
    options: { valueKey?: string | number; labelKey?: string; getValue?: Function; getLabel?: Function },
): ReactSelectOption[] {
    return arr.map((a) => {
        let value;
        if (options.valueKey) {
            value = a[options.valueKey];
        } else if (options.getValue) {
            value = options.getValue(a);
        }
        let label;
        if (options.labelKey) {
            label = a[options.labelKey];
        } else if (options.getLabel) {
            label = options.getLabel(a);
        }
        return { value, label };
    });
}

export function styleSelectComponent(usePortal: boolean = false): any {
    const defaultStyles = {
        dropdownIndicator: (provided: any) => ({ ...provided, color: variables.darkBlue, fontSize: 12, marginLeft: 0 }),
        indicatorSeparator: () => ({ display: 'none' }),
        control: (provided: any) => ({
            ...provided,
            borderColor: variables.lightGrey,
            paddingTop: 2,
            paddingBottom: 2,
        }),
        menu: (provided: any) => ({ ...provided, zIndex: 5 }),
        singleValue: (provided: any) => ({
            ...provided,
            fontSize: 14,
            color: variables.darkBlue,
            fontWeight: 500,
            whiteSpace: 'normal',
        }),
        placeholder: (provided: any) => ({
            ...provided,
            fontSize: 14,
            color: variables.darkBlue,
            opacity: 0.4,
            fontWeight: 600,
        }),
        multiValueLabel: (provided: any) => ({
            ...provided,
            whiteSpace: 'normal',
        }),
    };

    return usePortal
        ? Object.assign(defaultStyles, {
              menuPortal: (base: any) => ({ ...base, zIndex: 2147483646 }),
          })
        : defaultStyles;
}

/**
 * Credit David Walsh (https://davidwalsh.name/javascript-debounce-function)
 * Returns a function, that, as long as it continues to be invoked, will not
 * be triggered. The function will be called after it stops being called for
 * N milliseconds. If `immediate` is passed, trigger the function on the
 * leading edge, instead of the trailing.
 *
 * @export
 * @param {Function} func
 * @param {number} [wait=50]
 * @param {boolean} [immediate]
 * @returns {Function}
 */
export function debounce(func: Function, wait: number = 50, immediate?: boolean) {
    let timeout: any;

    return function executedFunction(this: any, ...args: any[]) {
        const context = this;

        const later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };

        const callNow = immediate && !timeout;

        clearTimeout(timeout);

        timeout = setTimeout(later, wait);

        if (callNow) func.apply(context, args);
    };
}

type ObjWithStrings = { [key: string]: any };
/**
 * Pass in 2 objects and an array of the fields to check in each one and it will compare the two objects against those fields
 *
 * @export
 * @param {ObjWithStrings} obj1
 * @param {ObjWithStrings} obj2
 * @param {string[]} fieldsArr
 * @returns {boolean}
 */
export function haveFieldsChanged(obj1: ObjWithStrings, obj2: ObjWithStrings, fieldsArr: string[]): boolean {
    for (const field of fieldsArr) {
        if (obj1[field] !== obj2[field]) {
            return true;
        }
    }
    return false;
}

/**
 * Returns only the changed values with the 2nd paramater being the newest value
 *
 * @export
 * @param {ObjWithStrings} obj1
 * @param {ObjWithStrings} obj2
 * @param {string[]} fieldsArr
 * @returns {object}
 */
export function onlyChangedFields(obj1: ObjWithStrings, obj2: ObjWithStrings, fieldsArr: string[]): object {
    const newObj: ObjWithStrings = {};
    for (const field of fieldsArr) {
        if (obj1[field] !== obj2[field]) {
            newObj[field] = obj2[field];
        }
    }
    return newObj;
}

/**
 * parseInt but don't return NaN
 *
 * @export
 * @param {string} data
 * @returns {number | null}
 */
export function getNumberOrNull(data: string): number | null {
    const parsed = parseInt(data);
    if (isNaN(parsed)) {
        return null;
    }
    return parsed;
}

/**
 * Run forEach and wait for each one to finish before going on to the next one
 *
 * @export
 * @param {*} array
 * @param {*} callback
 */
export async function asyncForEach(array: [], callback: Function) {
    for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array);
    }
}

/**
 * Run async requests in parallel, don't wait for each one before starting the next one
 *
 * @export
 * @param {*} array
 * @param {*} callback
 * @returns {Promise<any>}
 */
export async function runInParallel(array: [], callback: (...args: any[]) => Promise<any>): Promise<any> {
    return await Promise.all(array.map(callback));
}

/**
 * Parse message from error
 * @param err
 */
export function parseError(err?: { response?: { data?: { message?: string } } }) {
    return (err.response && err.response.data && err.response.data.message) || 'An error has occured.';
}

/**
 * Turn array into object
 * @param array
 * @param idKey
 */
export function arrToObj(array: any[], idKey: string = 'id') {
    return array.reduce((obj, item) => {
        obj[item[idKey]] = item;
        return obj;
    }, {});
}

/**
 * Get a Date object from a time in the format "hh:mm"
 * @param {string} t
 * @returns {Date}
 */
export const getTimeDate = memoize(function getTimeDate(t: string): Date | undefined {
    return !t ? undefined : parseDateFns(isValid(new Date(t)) ? t : `2019-07-01 ${t}`);
});

/**
 * Validate a MM/DD/YYYY date string
 *
 * @export
 * @param {string} dateStr
 * @returns
 */
export function validateDate(dateStr: string): boolean {
    const regExp = /^(\d\d?)\/(\d\d?)\/(\d{4})$/;
    const matches = dateStr.match(regExp);
    const maxDate = [0, 31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    let isValid = false;

    if (matches) {
        const month = parseInt(matches[1]);
        const date = parseInt(matches[2]);
        const year = parseInt(matches[3]);

        isValid = month <= 12 && month > 0;
        isValid = isValid && date <= maxDate[month] && date > 0;

        const leapYear = year % 400 == 0 || (year % 4 == 0 && year % 100 != 0);
        isValid = isValid && (month != 2 || leapYear || date <= 28);
    }

    return isValid;
}

export const getPatientName = (patient: Patient) =>
    `${patient.patientFirstName || ''} ${patient.patientLastName || ''}`;

export function getPatientPhone(phoneNumbers: Patient['patientPhones']) {
    const numbers = phoneNumbers || [];
    const mobileNum = numbers.find((n) => (n.type || '').toLowerCase() === 'mobile') || {};
    const homeNum = numbers.find((n) => (n.type || '').toLowerCase() === 'home') || {};

    return mobileNum.phone || homeNum.phone || (numbers[0] || {}).phone || '';
}

export const getTeleheathPatientInfo = (
    session?: TelehealthSession,
    type?: 'firstName' | 'lastName' | 'dob' | 'gender',
) => {
    const encounter: Encounter = ((session || {}) as any).encounter || {};
    const patient: Patient = encounter.patient || {};
    switch (type) {
        case 'firstName':
            return patient.patientFirstName || encounter.patFrstNm || '';
        case 'lastName':
            return patient.patientLastName || encounter.patLastNm || '';
        case 'dob':
            return patient.patientDob || encounter.patDob || '';
        case 'gender':
            return patient.patientGenderCode || encounter.patGenderCd || '';

        default:
            return '';
    }
};

export function getTelehealthStatusExplanation(
    statusCode: SessionStateCodes,
    sessionDetail?: TelehealthSession['telehealthSessionDetail'],
) {
    if (statusCode === SessionStateCodes.SESSION_CREATED) {
        return 'Not Started';
    }
    if (statusCode === SessionStateCodes.CONNECTION_PENDING) {
        if (sessionDetail && sessionDetail.responderConnectedDttm) {
            return 'Waiting for Patient';
        }
        if (sessionDetail && sessionDetail.initiatorConnectedDttm) {
            return 'Waiting for Provider';
        }
        return 'Waiting for participant';
    }
    if (statusCode === SessionStateCodes.CONNECTION_STARTED) {
        return 'Call in Progress';
    }
    if (statusCode === SessionStateCodes.CONNECTION_INTERRUPTED) {
        return 'Call Interrupted';
    }
    if (statusCode === SessionStateCodes.CONNECTION_ENDED) {
        return 'Call Ended';
    }
    if (statusCode === SessionStateCodes.SESSION_COMPLETED) {
        return 'Visit Complete';
    }
    if (statusCode === SessionStateCodes.SESSION_CANCELED_BY_INITIATOR) {
        return 'Canceled by Patient';
    }
    if (statusCode === SessionStateCodes.SESSION_CANCELED_BY_RESPONDER) {
        return 'Canceled by Provider';
    }
    return statusCode;
}

export function getDropdownValue(option: any, defaultValue?: any) {
    if (!option) {
        return defaultValue;
    }
    if (isArray(option)) {
        return option.map((o) => o.value);
    }
    return option.value;
}
