import { action, observable, makeObservable } from 'mobx';
import { createContext } from 'react';
import * as encountersAPI from 'src/api/encounters';
import { Encounter, FilterQueryParams, Event, EncounterForm } from 'src/utils/types';

class EncounterStore {
    @observable patientEncounters: Encounter[] = [];
    @observable encounters: Encounter[] = [];
    @observable totalEncountersCount: number = 0;

    constructor() {
        makeObservable(this);
    }

    @action
    reset() {
        this.patientEncounters = [];
        this.encounters = [];
        this.totalEncountersCount = 0;
    }

    @action
    async getPatientEncounters(patientId: Encounter['patientId'], params: {} = {}) {
        const encounters = await encountersAPI.getPatientEncounters(patientId, params);
        this.setPatientEncounters(encounters.data || []);
    }
    @action
    async getAllEncounters(params: FilterQueryParams = {}) {
        const { result, count } = await encountersAPI.getAllEncounters(params);
        this.setEncounters(result || [], count);
    }

    @action
    setPatientEncounters(encounters: Encounter[]) {
        this.patientEncounters = encounters;
    }

    @action
    setEncounters(encounters: Encounter[], total: number = 0) {
        this.encounters = encounters;
        this.totalEncountersCount = total;
    }

    @action
    async createPatientEncounter(patientId: number, patientEncounter: Encounter, completeEventId?: Event['eventId']) {
        const results = await encountersAPI.createEncounter(patientEncounter, completeEventId);
        this.getPatientEncounters(patientId);
        return results.data;
    }

    @action
    async createEncounter(encounter: Encounter, completeEventId?: Event['eventId']) {
        const results = await encountersAPI.createEncounter(encounter, completeEventId);
        return results.data;
    }

    @action
    async createEncounterWithPatient(encounter: Encounter, completeEventId?: Event['eventId']) {
        const results = await encountersAPI.createEncounterWithPatient(encounter, completeEventId);
        return results.data;
    }

    @action
    async updateEncounter(encounterId: Encounter['enctrId'], encounter: Encounter) {
        await encountersAPI.updateEncounter(encounterId, encounter);
    }

    @action
    async updateEncounterForm(encounterFormId: number, encounterForm: EncounterForm) {
        await encountersAPI.updateEncounterForm(encounterFormId, encounterForm);
        this.getAllEncounters();
    }
}

// Import this to any other store that needs to use a value from it
export const EncounterStoreObject = new EncounterStore();

export default createContext(EncounterStoreObject);
