import { useEffect, useContext, useState } from 'react';
import FeatureFlagStore from 'src/stores/FeatureFlagStore';

export default function useFlag(featureName: string) {
    const flagStore = useContext(FeatureFlagStore);
    const [isEnabled, setIsEnabled] = useState(false);

    useEffect(() => {
        if (flagStore.loaded) {
            const enabled = flagStore.isEnabled(featureName);
            setIsEnabled(enabled);
        }
    }, [flagStore.loaded]);

    return isEnabled;
}
