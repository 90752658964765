import React, { useContext, useEffect, useState } from 'react';
import Flex from 'src/components/Flex';
import styles from './styles.module.scss';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import Table from 'src/components/Table';
import { CellInfo } from 'react-table';
import PaymentsStore, { PaymentRequest } from 'src/stores/PaymentsStore';
import Card from 'src/components/Card';
import DateComponent from 'src/components/DateComponent';
import Input from 'src/components/Input';
import Checkbox from 'src/components/Checkbox';
import * as variables from 'src/styles/variables';

interface ReviewPaymentRequestsProps {
    onChange?: Function;
}

function ReviewPaymentRequests(props: ReviewPaymentRequestsProps) {
    const paymentsStore = useContext(PaymentsStore);
    const [qualifiedData, setQualifiedData] = useState([]);
    const [unqualifiedData, setUnqualifiedData] = useState([]);
    const [selectedRequests, setSelectedRequests] = useState<PaymentRequest['relatedEncounterId'][]>([]);

    const qualifiedCheckboxColumn = [
        {
            Header: <Checkbox checked={false} onChange={() => selectAllQualified()} />,
            maxWidth: 50,
            sortable: false,
            Cell: (props: CellInfo) => (
                <Checkbox
                    disabled={!canSelect(props.original.encounterNumber)}
                    checked={selectedRequests.includes(props.original.encounterNumber)}
                    onChange={() => handleRequestSelect(props.original.encounterNumber)}
                />
            ),
        },
    ];

    const unqualifiedCheckboxColumn = [
        {
            Header: <Checkbox checked={false} onChange={() => selectAllUnqualified()} />,
            maxWidth: 50,
            sortable: false,
            Cell: (props: CellInfo) => (
                <Checkbox
                    disabled={!canSelect(props.original.encounterNumber)}
                    checked={selectedRequests.includes(props.original.encounterNumber)}
                    onChange={() => handleRequestSelect(props.original.encounterNumber)}
                />
            ),
        },
    ];

    const columns = [
        {
            Header: 'Procedure Date',
            accessor: 'procedureDate',
            maxWidth: 140,
            Cell: (props: CellInfo) => {
                return <DateComponent date={props.value} />;
            },
        },
        {
            Header: 'Enc. #',
            maxWidth: 160,
            accessor: 'encounterNumber',
        },
        {
            Header: 'First Name',
            accessor: 'firstName',
            Cell: (props: CellInfo) => {
                if (props.original.encounterId) {
                    return <span>{props.value}</span>;
                } else {
                    return (
                        <Input
                            value={props.value}
                            className={styles.input}
                            wrapClassName={styles.inputWrap}
                            infoState={!props.value ? 'error' : null}
                            errorMessage={!props.value ? 'Enter First Name' : null}
                            onChangeText={(v: string) =>
                                handleFieldChange(props.original.encounterNumber, props.column.id, v)
                            }
                        />
                    );
                }
            },
        },
        {
            Header: 'Last Name',
            accessor: 'lastName',
            Cell: (props: CellInfo) => {
                if (props.original.encounterId) {
                    return <span>{props.value}</span>;
                } else {
                    return (
                        <Input
                            value={props.value}
                            className={styles.input}
                            wrapClassName={styles.inputWrap}
                            infoState={!props.value ? 'error' : null}
                            errorMessage={!props.value ? 'Enter Last Name' : null}
                            onChangeText={(v: string) =>
                                handleFieldChange(props.original.encounterNumber, props.column.id, v)
                            }
                        />
                    );
                }
            },
        },
        {
            Header: 'Phone #',
            accessor: 'phone',
            minWidth: 100,
            Cell: (props: CellInfo) => {
                return (
                    <Input
                        value={props.value}
                        infoState={!hasPhoneOrEmail(props.original.encounterNumber) ? 'error' : null}
                        errorMessage={!hasPhoneOrEmail(props.original.encounterNumber) ? 'Enter Phone' : null}
                        className={styles.input}
                        wrapClassName={styles.inputWrap}
                        onChangeText={(v: string) =>
                            handleFieldChange(props.original.encounterNumber, props.column.id, v)
                        }
                    />
                );
            },
        },
        {
            Header: 'Email',
            accessor: 'email',
            minWidth: 200,
            Cell: (props: CellInfo) => {
                return (
                    <Input
                        value={props.value}
                        infoState={!hasPhoneOrEmail(props.original.encounterNumber) ? 'error' : null}
                        errorMessage={!hasPhoneOrEmail(props.original.encounterNumber) ? 'Enter Email' : null}
                        className={styles.input}
                        wrapClassName={styles.inputWrap}
                        onChangeText={(v: string) =>
                            handleFieldChange(props.original.encounterNumber, props.column.id, v)
                        }
                    />
                );
            },
        },
        {
            Header: 'Insurance',
            accessor: 'payers',
            minWidth: 150,
            style: { whiteSpace: 'unset' },
        },
        {
            Header: 'Amount',
            accessor: 'amount',
            maxWidth: 120,
            Cell: (props: CellInfo) => {
                return (
                    <Input
                        infoState={!props.value || props.value === '0' ? 'error' : null}
                        errorMessage={!props.value || props.value === '0' ? 'Must be > 0' : null}
                        value={props.value}
                        className={styles.input}
                        wrapClassName={styles.inputWrap}
                        onChangeText={(v: string) =>
                            handleFieldChange(props.original.encounterNumber, props.column.id, v)
                        }
                    />
                );
            },
        },
    ];

    useEffect(() => {
        setQualifiedData(paymentsStore.eligiblePaymentRequests);
        setUnqualifiedData(paymentsStore.ineligiblePaymentRequests);
    }, [paymentsStore.eligiblePaymentRequests, paymentsStore.ineligiblePaymentRequests]);

    useEffect(() => {
        if (props.onChange) {
            const qualifiedRequestsToCreate = qualifiedData.filter((data: any) =>
                selectedRequests.includes(data.encounterNumber),
            );
            const unqualifiedRequestsToCreate = unqualifiedData.filter((data: any) =>
                selectedRequests.includes(data.encounterNumber),
            );
            props.onChange([...qualifiedRequestsToCreate, ...unqualifiedRequestsToCreate]);
        }
    }, [selectedRequests, qualifiedData, unqualifiedData]);

    function canSelect(encounterNumber: string) {
        const qualifiedRecord = qualifiedData.find((qd: any) => qd.encounterNumber === encounterNumber);
        const unqualifiedRecord = unqualifiedData.find((qd: any) => qd.encounterNumber === encounterNumber);

        if (qualifiedRecord) {
            if (!qualifiedRecord.email && !qualifiedRecord.phone) {
                return false;
            }

            if (qualifiedRecord.amount === '0' || qualifiedRecord.amount === 0) {
                return false;
            }
        } else if (unqualifiedRecord) {
            if (!unqualifiedRecord.email && !unqualifiedRecord.phone) {
                return false;
            }

            if (unqualifiedRecord.amount === '0' || unqualifiedRecord.amount === 0) {
                return false;
            }
        }

        return true;
    }

    function hasPhoneOrEmail(encounterNumber: string) {
        const qualifiedRecord = qualifiedData.find((qd: any) => qd.encounterNumber === encounterNumber);
        const unqualifiedRecord = unqualifiedData.find((qd: any) => qd.encounterNumber === encounterNumber);
        if (qualifiedRecord) {
            if (qualifiedRecord.email || qualifiedRecord.phone) return true;
        } else if (unqualifiedRecord) {
            if (unqualifiedRecord.email || unqualifiedRecord.phone) return true;
        }

        return false;
    }

    function handleFieldChange(encounterNumber: string, fieldName: string, value: string) {
        if (qualifiedData.find((qd: any) => qd.encounterNumber === encounterNumber)) {
            const copiedQualifiedData = JSON.parse(JSON.stringify(toJS(qualifiedData)));
            const updatedIndex = copiedQualifiedData.findIndex((data: any) => data.encounterNumber === encounterNumber);
            const replacedValue = fieldName === 'amount' ? value.replace(/[^0-9]/g, '') : value;
            copiedQualifiedData[updatedIndex][fieldName] =
                replacedValue === '' && fieldName === 'amount' ? 0 : replacedValue;
            setQualifiedData(copiedQualifiedData);
        } else {
            const copiedUnqualifiedData = JSON.parse(JSON.stringify(toJS(unqualifiedData)));
            const updatedIndex = copiedUnqualifiedData.findIndex(
                (data: any) => data.encounterNumber === encounterNumber,
            );
            const replacedValue = fieldName === 'amount' ? value.replace(/[^0-9]/g, '') : value;
            copiedUnqualifiedData[updatedIndex][fieldName] =
                replacedValue === '' && fieldName === 'amount' ? 0 : replacedValue;
            setUnqualifiedData(copiedUnqualifiedData);
        }
    }

    function handleRequestSelect(encounterNumber: string) {
        if (selectedRequests.includes(encounterNumber)) {
            setSelectedRequests([...selectedRequests.filter((en: string | number) => en !== encounterNumber)]);
        } else {
            setSelectedRequests([...selectedRequests, encounterNumber]);
        }
    }

    function selectAllQualified() {
        const allQualified = qualifiedData.map((data: any) => canSelect(data.encounterNumber) && data.encounterNumber);
        setSelectedRequests([...selectedRequests, ...allQualified]);
    }

    function selectAllUnqualified() {
        const allUnqualified = unqualifiedData.map(
            (data: any) => canSelect(data.encounterNumber) && data.encounterNumber,
        );
        setSelectedRequests([...selectedRequests, ...allUnqualified]);
    }

    function cellStyles(state: any, rowInfo: any, instance: any) {
        if (rowInfo) {
            return {
                style: {
                    paddingTop: 0,
                    paddingBottom: 0,
                    paddingLeft: 14,
                    paddingRight: instance.id === 'amount' ? 7 : 0,
                    fontSize: 14,
                },
            };
        }
        return {};
    }

    return (
        <Flex direction="column">
            <div className={styles.stepTitle}>Ready to Send</div>
            <Card className={styles.cardWrap}>
                <Table
                    columns={[...qualifiedCheckboxColumn, ...columns]}
                    data={qualifiedData}
                    showPagination={false}
                    getTdProps={cellStyles}
                    getTheadThProps={cellStyles}
                />
            </Card>
            <div className={styles.stepTitle}>Unable to Send</div>
            <Card className={styles.cardWrap}>
                <Table
                    columns={[...unqualifiedCheckboxColumn, ...columns]}
                    data={unqualifiedData}
                    showPagination={false}
                    getTdProps={cellStyles}
                />
            </Card>
        </Flex>
    );
}

export default observer(ReviewPaymentRequests);
