import * as ROUTES from 'src/api/routes';
import request from 'src/api/utils';

export async function createSession(data: any) {
    const response = await request({ ...ROUTES.CREATE_TELEHEALTH_SESSION, data });
    return response;
}

export async function createSessionWithEncounter(data: any) {
    const response = await request({ ...ROUTES.CREATE_TELEHEALTH_SESSION_WITH_ENCOUNTER, data });
    return response;
}

export async function getTelehealthArchiveUrl(telehealthSessionId: number) {
    const response = await request({ ...ROUTES.GET_TELEHEALTH_ARCHIVE_URL, pathParams: { telehealthSessionId } });
    return response;
}

export async function getTelehealthSessions(params: {} = {}) {
    const response = await request({ ...ROUTES.GET_TELEHEALTH_SESSIONS, params });
    return response;
}

export async function getFilteredTelehealthSessions(params: {} = {}) {
    const response = await request({ ...ROUTES.GET_FILTERED_TELEHEALTH_SESSIONS, params });
    return { result: response.data.result || [], count: response.data.count || 0 };
}

export async function getTelehealthSession(telehealthSessionId: string) {
    return await request({ ...ROUTES.GET_TELEHEALTH_SESSION, pathParams: { telehealthSessionId } });
}

export async function getTelehealthSessionForPatient(
    telehealthSessionGuid: string,
    { dob, org }: { dob: string; org: string },
) {
    return await request({
        ...ROUTES.GET_TELEHEALTH_SESSION_FOR_PATIENT,
        pathParams: { telehealthSessionGuid },
        params: { dob, org },
    });
}
export async function getTelehealthSessionFacilityForPatient(telehealthSessionGuid: string, { org }: { org: string }) {
    return await request({
        ...ROUTES.GET_TELEHEALTH_SESSION_FACILITY_FOR_PATIENT,
        pathParams: { telehealthSessionGuid },
        params: { org },
    });
}

export async function startTelehealthSession(telehealthSessionId: string) {
    return await request({
        ...ROUTES.START_TELEHEALTH_SESSION,
        pathParams: { telehealthSessionId },
        data: { sessionRole: 'responder' },
    });
}

export async function startTelehealthSessionForPatient(
    telehealthSessionId: string,
    { dob, org }: { dob: string; org: string },
) {
    return await request({
        ...ROUTES.JOIN_TELEHEALTH_SESSION_FOR_PATIENT,
        pathParams: { telehealthSessionId },
        params: { dob, org },
    });
}

export async function cancelTelehealthSession(telehealthSessionId: string, reason: string) {
    return await request({
        ...ROUTES.CANCEL_TELEHEALTH_SESSION,
        pathParams: { telehealthSessionId },
        data: { telehealthSessionCancellationReason: reason },
    });
}

export async function pingTelehealthSession(telehealthSessionId: string) {
    return await request({ ...ROUTES.PING_TELEHEALTH_SESSION, pathParams: { telehealthSessionId } });
}

export async function endTelehealthSessionForPatient(
    telehealthSessionId: string,
    { dob, org }: { dob: string; org: string },
) {
    return await request({
        ...ROUTES.END_TELEHEALTH_SESSION_FOR_PATIENT,
        pathParams: { telehealthSessionId },
        params: { dob, org },
    });
}

export async function pingTelehealthSessionForPatient(
    telehealthSessionId: string,
    { dob, org }: { dob: string; org: string },
) {
    return await request({
        ...ROUTES.PING_TELEHEALTH_SESSION_FOR_PATIENT,
        pathParams: { telehealthSessionId },
        params: { dob, org },
    });
}

export async function endTelehealthSession(telehealthSessionId: string) {
    return await request({ ...ROUTES.END_TELEHEALTH_SESSION, pathParams: { telehealthSessionId } });
}

export async function completeTelehealthSession(telehealthSessionId: string) {
    return await request({ ...ROUTES.COMPLETE_TELEHEALTH_SESSION, pathParams: { telehealthSessionId } });
}

export async function saveTelehealthSessionNote(telehealthSessionId: string, note: string) {
    return await request({
        ...ROUTES.SAVE_TELEHEALTH_SESSION_NOTE,
        pathParams: { telehealthSessionId },
        data: { respondingUserNote: note },
    });
}

export async function resendTelehealthSessionInvite(
    telehealthSessionId: number,
    data: { patientEmail?: string; patientPhone?: string },
) {
    return await request({ ...ROUTES.RESEND_TELEHEALTH_SESSION_INVITE, pathParams: { telehealthSessionId }, data });
}
