import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { getEncounterFormPdfLink, getEncounterFormPdfLinkLegacy } from 'src/api/encounters';
import LoadingIcon from 'src/components/LoadingIcon';
import { EncounterForm, Encounter, EncounterFormPdfResponse } from 'src/utils/types';
import { ToastStoreObject } from 'src/stores/ToastStore';
import styles from './styles.module.scss';
import Flex from 'src/components/Flex';
import FeatureFlagStore from 'src/stores/FeatureFlagStore';

interface EncounterFormPdfProps {
    onErrorLoading: () => void;
    onSetData?: (d: EncounterFormPdfResponse) => void;
    enctrFormId: EncounterForm['enctrFormId'];
    facId: Encounter['facId'];
    encounterFormData?: any;
    getPdfLink: (link: string) => void;
}

function EncounterFormPdf(props: EncounterFormPdfProps) {
    const [pdfLink, setPdfLink] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const flagStore = useContext(FeatureFlagStore);

    async function getPdfData() {
        try {
            setIsLoading(true);
            let response;
            console.log('legacy pdf generation enabled', flagStore.isEnabled('legacy-pdf-generation'));
            if (flagStore.isEnabled('legacy-pdf-generation')) {
                response = await getEncounterFormPdfLinkLegacy(props.enctrFormId);
            } else {
                response = props.encounterFormData
                    ? props.encounterFormData
                    : await getEncounterFormPdfLink(props.enctrFormId, props.facId);
            }
            const encForm = props.encounterFormData
                ? props.encounterFormData
                : (response.data.encounterFormPdfData as EncounterFormPdfResponse);
            props.onSetData && props.onSetData(encForm);

            setPdfLink(response.data.pdfLink);
            props.getPdfLink(response.data.pdfLink);
            setIsLoading(false);
        } catch (error) {
            ToastStoreObject.show(`There was an error loading the form (${error}).`);
            setIsLoading(false);
            props.onErrorLoading();
        }
    }

    useEffect(() => {
        getPdfData();
    }, []);

    return (
        <div className={styles.content}>
            {isLoading ? (
                <Flex align="center" justify="center" className={styles.loading}>
                    <LoadingIcon />
                </Flex>
            ) : (
                <iframe className={styles.iFrame} height="100%" width="100%" scrolling="no" src={pdfLink}></iframe>
            )}
        </div>
    );
}

export default observer(EncounterFormPdf);
