import { useEffect, useContext, useState } from 'react';
import FeatureFlagStore from 'src/stores/FeatureFlagStore';

export default function useFeatureValue(featureName: string) {
    const flagStore = useContext(FeatureFlagStore);
    const [value, setValue] = useState(null);

    useEffect(() => {
        if (flagStore.loaded) {
            const enabled = flagStore.getValue(featureName);
            setValue(enabled);
        }
    }, [flagStore.loaded]);

    return value;
}
