import React, { useState, useEffect, useRef } from 'react';
import * as telehealthService from 'src/api/telehealth';
// @ts-ignore
import { OTPublisher, OTSubscriber, createSession, preloadScript } from 'opentok-react';
import Select from 'react-select';

import Button from 'src/components/Button';
import LoadingIcon from 'src/components/LoadingIcon';
import Input from '../Input';
import * as utils from 'src/utils';
import styles from './styles.module.scss';
import { ErrorStoreObject, ErrorTypes } from 'src/stores/ErrorStore';
import { ModalStoreObject, ModalTypes } from 'src/stores/ModalStore';
import { ToastStoreObject, ToastType } from 'src/stores/ToastStore';
import Flex from '../Flex';
import { TelehealthSession, SessionStateCodes } from 'src/utils/types';
import CallTimer from '../CallTimer';
import Video from '../Video';
import { ANALYTICS_NAMES } from 'src/utils/analytics';

export interface TelehealthSessionVideoProps {
    session: TelehealthSession;
    telehealthSessionId: string;
    onComplete: Function;
    onRefreshSession: Function;
    isPatient?: boolean;
    patientDob?: string;
    patientOrg?: string;
}

function getStartTimerDate(session: TelehealthSession) {
    const details = session.telehealthSessionDetail;
    // @ts-ignore
    const code = (session.telehealthSessionState || {}).telehealthSessionStateCode;
    if (
        code === SessionStateCodes.CONNECTION_STARTED ||
        code === SessionStateCodes.CONNECTION_INTERRUPTED ||
        code === SessionStateCodes.CONNECTION_PENDING
    ) {
        if (details && details.initiatorConnectedDttm && details.responderConnectedDttm) {
            // Both participants have connected, show the latest date
            const initiatorDate = new Date(details.initiatorConnectedDttm);
            const responderDate = new Date(details.responderConnectedDttm);
            if (initiatorDate > responderDate) {
                return initiatorDate;
            }
            return responderDate;
        }
    }
    return null;
}

type StartState =
    | 'pending'
    | 'rejoin'
    | 'starting'
    | 'ending'
    | 'completing'
    | 'cancelling'
    | 'started'
    | 'cancel'
    | 'cancelled'
    | 'ended'
    | 'completed';

export function TelehealthSessionVideoComponent(props: TelehealthSessionVideoProps) {
    const [openTokSession, setOpenTokSession] = useState(null);
    const [startState, setStartState] = useState<StartState>('pending');
    const [streamState, setStreamState] = useState({ streams: [] });
    const [cancelState, setCancelState] = useState('');
    const [startTimerDate, setStartTimerDate] = useState<Date | null>(() => getStartTimerDate(props.session));
    const [waitingTimerDate, setWaitingTimerDate] = useState<Date | null>(null);
    const pingInterval = useRef<NodeJS.Timer>(null);
    const [videoUrl, setVideoUrl] = useState(null);
    const [videoDimensions, setVideoDimensions] = useState({ width: 0, height: 0 });
    const [audioOptions, setAudioOptions] = useState([]);
    const [videoOptions, setVideoOptions] = useState([]);
    const [selectedVideoDeviceId, setSelectedVideoDeviceId] = useState(null);

    function getDevices() {
        // @ts-ignore
        const getOpentokDevices = (window.OT || {}).getDevices || (() => {});
        getOpentokDevices((err: any, devices: any[]) => {
            setAudioOptions(
                devices
                    .filter((device: any) => device.kind === 'audioInput')
                    .map((d) => ({ value: d.deviceId, label: d.label })),
            );
            const vDevices = devices
                .filter((device: any) => device.kind === 'videoInput')
                .map((d) => ({ value: d.deviceId, label: d.label }));
            if (vDevices.length > 0) {
                if (!selectedVideoDeviceId) {
                    setSelectedVideoDeviceId(vDevices[0]);
                }
                setVideoOptions(vDevices);
            }
        });
    }

    useEffect(() => {
        getDevices();

        // Make sure the ping gets removed
        return () => {
            clearInterval(pingInterval.current);
        };
    }, []);

    function onOpentokSignal(event: any) {
        if (openTokSession && event.from.id !== openTokSession.session.connection.id) {
            // This is a signal from someone else
            if (event.data === 'end') {
                if ((['rejoin', 'started'] as StartState[]).includes(startState)) {
                    ToastStoreObject.show('The other participant ended the call.', ToastType.Warn);
                }
                setStartState('ended');
                pingSession();
            }
            if (event.data === 'join') {
                if ((['pending', 'rejoin', 'started', 'starting'] as StartState[]).includes(startState)) {
                    ToastStoreObject.show('The other participant has joined the call.', ToastType.Success);
                }
                pingSession();
            }
        }
    }

    function sendOpentokSignal(data: 'end' | 'join') {
        if (openTokSession && openTokSession.session) {
            openTokSession.session.signal({ data }, (error: any) => {
                if (error) {
                    console.log('signal error', data);
                } else {
                    console.log('signal sent', data);
                }
            });
        }
    }

    useEffect(() => {
        if (openTokSession && openTokSession.session) {
            openTokSession.session.on('signal', onOpentokSignal);
        }

        return () => {
            if (openTokSession && openTokSession.session) {
                openTokSession.session.off('signal', onOpentokSignal);
                openTokSession.session.disconnect();
            }
        };
    }, [openTokSession]);

    const publisherRef = useRef(null);
    function createOpentokSession() {
        if (!openTokSession || !openTokSession.session) {
            setOpenTokSession(
                createSession({
                    apiKey: props.session.telehealthSessionDetail.opentokApiKey,
                    sessionId: props.session.telehealthSessionDetail.sessionId,
                    token: props.session.telehealthSessionDetail.responderToken,
                    onStreamsUpdated: (streams: any) => {
                        setStreamState({ streams });
                        if (streams && streams.length > 0) {
                            pingSession();
                        }
                    },
                }),
            );
        }
    }

    function pageResize() {
        const h = window.innerHeight;
        const w = window.innerWidth;
        if (props.isPatient) {
            if (w > h) {
                setVideoDimensions({ width: h * 0.9, height: h * 0.675 });
            } else {
                setVideoDimensions({ width: w * 0.9, height: w * 0.675 });
            }
        } else {
            setVideoDimensions({ width: w * 0.45, height: w * 0.3375 });
        }
    }

    useEffect(() => {
        window.addEventListener('resize', pageResize);
        pageResize();

        return () => {
            window.removeEventListener('resize', pageResize);
        };
    }, []);

    useEffect(() => {
        const newSessionState = props.session.telehealthSessionState.telehealthSessionStateCode;
        const details = props.session.telehealthSessionDetail;
        if (
            newSessionState !== SessionStateCodes.CONNECTION_ENDED &&
            newSessionState !== SessionStateCodes.SESSION_COMPLETED &&
            newSessionState !== SessionStateCodes.SESSION_CANCELED_BY_INITIATOR &&
            newSessionState !== SessionStateCodes.SESSION_CANCELED_BY_RESPONDER
        ) {
            createOpentokSession();
        }

        if (
            newSessionState === SessionStateCodes.CONNECTION_STARTED ||
            newSessionState === SessionStateCodes.CONNECTION_INTERRUPTED
        ) {
            setStartState('rejoin');
        }

        if (newSessionState === SessionStateCodes.CONNECTION_PENDING) {
            if (props.isPatient) {
                if (details.initiatorConnectedDttm) {
                    setStartState('rejoin');
                }
            } else {
                if (details.responderConnectedDttm) {
                    setStartState('rejoin');
                }
            }
        }
        if (newSessionState === SessionStateCodes.CONNECTION_ENDED) {
            setStartState('ended');
        }
        if (newSessionState === SessionStateCodes.SESSION_COMPLETED) {
            getVideoArchive();
            setStartState('completed');
        }
        if (
            newSessionState === SessionStateCodes.SESSION_CANCELED_BY_RESPONDER ||
            newSessionState === SessionStateCodes.SESSION_CANCELED_BY_INITIATOR
        ) {
            setStartState('cancelled');
        }
    }, []);

    async function getVideoArchive() {
        if (props.session.telehealthSessionDetail.archiveId) {
            const results = await telehealthService.getTelehealthArchiveUrl(props.session.telehealthSessionId);
            if (results && results.data && results.data.url) {
                setVideoUrl(results.data.url);
            }
        }
    }

    useEffect(() => {
        if (startState !== 'started') {
            clearInterval(pingInterval.current);
        }
        if (startState === 'starting' || startState === 'started') {
            if (audioOptions.length === 0) {
                getDevices();
            }
            if (startState === 'started') {
                pingInterval.current = setInterval(pingSession, 60000);
            }
        }
    }, [startState]);

    async function startSession() {
        try {
            createOpentokSession(); // Make sure this is started
            setStartState('starting');
            setWaitingTimerDate(new Date());
            if (props.isPatient) {
                const newSession = await telehealthService.startTelehealthSessionForPatient(props.telehealthSessionId, {
                    dob: props.patientDob,
                    org: props.patientOrg,
                });
                setStartTimerDate(getStartTimerDate(newSession.data || {}));
                props.onRefreshSession(newSession.data);
            } else {
                const newSession = await telehealthService.startTelehealthSession(props.telehealthSessionId);
                setStartTimerDate(getStartTimerDate(newSession.data || {}));
                props.onRefreshSession(newSession.data);
            }
            setStartState('started');

            sendOpentokSignal('join');
        } catch (e) {
            ErrorStoreObject.setError(ErrorTypes.Loading);
        }
    }

    function startCancel() {
        setStartState('cancel');
    }

    async function cancelSession() {
        try {
            setStartState('cancelling');
            await telehealthService.cancelTelehealthSession(props.telehealthSessionId, cancelState);
            setStartTimerDate(null);
            setWaitingTimerDate(null);
            ToastStoreObject.show('Successfully Canceled Telehealth Visit', ToastType.Success);
            props.onComplete();
        } catch (e) {
            setStartState('cancel');
            ToastStoreObject.show(utils.parseError(e));
        }
    }

    async function pingSession() {
        // Don't ping if not in one of these states
        if (
            !(['started', 'ending', 'ended', 'rejoin', 'completed', 'completing'] as StartState[]).includes(startState)
        ) {
            return;
        }
        if (props.isPatient) {
            const newSession = await telehealthService.pingTelehealthSessionForPatient(props.telehealthSessionId, {
                dob: props.patientDob,
                org: props.patientOrg,
            });
            setStartTimerDate(getStartTimerDate(newSession.data || {}));
            props.onRefreshSession(newSession.data);
        } else {
            const newSession = await telehealthService.pingTelehealthSession(props.telehealthSessionId);
            setStartTimerDate(getStartTimerDate(newSession.data || {}));
            props.onRefreshSession(newSession.data);
        }
    }

    function confirmEnd() {
        ModalStoreObject.showModal(ModalTypes.ConfirmationModal, {
            title: 'Are you sure you want to end this call?',
            onConfirm: () => endSession(),
            onCancel: () => ModalStoreObject.hideModal(),
            confirmButtonText: 'End Call',
        });
    }

    async function endSession() {
        try {
            sendOpentokSignal('end');
            setStartState('ending');
            ModalStoreObject.hideModal();
            if (props.isPatient) {
                await telehealthService.endTelehealthSessionForPatient(props.telehealthSessionId, {
                    dob: props.patientDob,
                    org: props.patientOrg,
                });
            } else {
                await telehealthService.endTelehealthSession(props.telehealthSessionId);
            }
            pingSession();
            setStartTimerDate(null);
            setWaitingTimerDate(null);

            setStartState('ended');
        } catch (e) {
            ErrorStoreObject.setError(ErrorTypes.Loading);
        }
    }

    function confirmComplete() {
        ModalStoreObject.showModal(ModalTypes.ConfirmationModal, {
            title: 'Are you sure you want to complete this visit?',
            onConfirm: () => completeSession(),
            onCancel: () => ModalStoreObject.hideModal(),
            confirmButtonText: 'Complete Visit',
        });
    }

    async function completeSession() {
        try {
            setStartState('completing');
            ModalStoreObject.hideModal();
            await telehealthService.completeTelehealthSession(props.telehealthSessionId);
            props.onComplete();
        } catch (e) {
            ErrorStoreObject.setError(ErrorTypes.Loading);
        }
    }

    function getVideoState() {
        switch (startState) {
            case 'pending':
                return (
                    <Flex
                        direction="column"
                        align="center"
                        justify="center"
                        className={styles.sessionContainer}
                        style={videoDimensions}
                    >
                        {props.isPatient && (
                            <div className={styles.successfullyJoined}>
                                When you are ready, click the button to join the call.
                            </div>
                        )}
                        <Flex direction="row">
                            <Button
                                data-test-id={ANALYTICS_NAMES.TelehealthSessionVideo_StartCall}
                                type="primary"
                                text="Start Call"
                                onClick={() => startSession()}
                            />
                            {!props.isPatient && (
                                <Button
                                    type="secondary"
                                    data-test-id={ANALYTICS_NAMES.TelehealthSessionVideo_Cancel}
                                    className={styles.secondaryButton}
                                    text="Cancel Telehealth Visit"
                                    onClick={() => startCancel()}
                                />
                            )}
                        </Flex>
                    </Flex>
                );
            case 'rejoin':
                return (
                    <Flex
                        direction="column"
                        align="center"
                        justify="center"
                        className={styles.sessionContainer}
                        style={videoDimensions}
                    >
                        <Flex direction="row">
                            <Button
                                data-test-id={ANALYTICS_NAMES.TelehealthSessionVideo_RejoinCall}
                                type="primary"
                                text="Rejoin Call"
                                onClick={() => startSession()}
                            />
                        </Flex>
                    </Flex>
                );
            case 'starting':
            case 'ending':
            case 'completing':
            case 'cancelling':
                return <LoadingIcon />;
            case 'started':
                return (
                    <div className={styles.videoContainer}>
                        <div className={styles.sessionContainer} style={videoDimensions}>
                            <div className={styles.publisherContainer}>
                                <OTPublisher
                                    ref={publisherRef}
                                    session={openTokSession.session}
                                    // @ts-ignore
                                    className={styles.publisherVideo}
                                />
                            </div>
                            <div className={styles.subscriberContainer}>
                                {streamState.streams.length === 0 ? (
                                    <div className={styles.waitingForSubscriber}>
                                        {props.isPatient
                                            ? 'Please standy by, we are waiting for your healthcare provider to join.'
                                            : 'Waiting for patient to join'}
                                    </div>
                                ) : null}

                                {/* Only render out a single subscriber stream, not all of them */}
                                {streamState.streams.length > 0 && (
                                    <OTSubscriber
                                        key={streamState.streams[0].id}
                                        session={openTokSession.session}
                                        stream={streamState.streams[0]}
                                        // @ts-ignore
                                        className={styles.subscriberVideo}
                                    />
                                )}
                                <CallTimer callStartedDate={startTimerDate} userWaitingDate={waitingTimerDate} />
                            </div>
                        </div>
                        <Flex
                            className={styles.controls}
                            justify={props.isPatient ? 'center' : 'between'}
                            direction={props.isPatient ? 'column' : 'row'}
                        >
                            <Flex
                                align="center"
                                justify={props.isPatient ? 'center' : 'start'}
                                style={{ paddingBottom: props.isPatient ? 20 : 0 }}
                            >
                                <Button
                                    data-test-id={ANALYTICS_NAMES.TelehealthSessionVideo_EndCall}
                                    type="primary"
                                    text="End Call"
                                    onClick={() => confirmEnd()}
                                />
                            </Flex>
                            <Flex value={1} align="center" justify={props.isPatient ? 'center' : 'end'}>
                                {videoOptions.length > 0 && (
                                    <Select
                                        value={
                                            selectedVideoDeviceId && selectedVideoDeviceId.label
                                                ? selectedVideoDeviceId
                                                : undefined
                                        }
                                        onChange={(s: any) => {
                                            if (s && s.value && (selectedVideoDeviceId || {}).value !== s.value) {
                                                if (publisherRef.current) {
                                                    const publisher = publisherRef.current.getPublisher();
                                                    let breakCount = 0;
                                                    // This is an attempt at cycling through video options to find the one that the user selected.
                                                    // Opentok doesn't have a "setVideoSource" method, only a "cycleVideo" method.
                                                    // This is a recursive function that will only run a max of 7 times (in case there's an error in cycling)
                                                    // @ts-ignore
                                                    async function tryNewCycle() {
                                                        if (breakCount > 7) return;
                                                        const selectedVideo = await publisher.cycleVideo();
                                                        if (selectedVideo.deviceId !== s.value) {
                                                            breakCount++;
                                                            tryNewCycle();
                                                            return;
                                                        }
                                                        setSelectedVideoDeviceId(
                                                            videoOptions.find(
                                                                (d) => d.deviceId === selectedVideo.deviceId,
                                                            ),
                                                        );
                                                        return;
                                                    }
                                                    tryNewCycle();
                                                }
                                            }
                                        }}
                                        isClearable={false}
                                        className={styles.deviceSelect}
                                        options={videoOptions}
                                        placeholder=""
                                        onMenuOpen={getDevices}
                                        styles={utils.styleSelectComponent()}
                                    />
                                )}
                                {audioOptions.length > 0 && audioOptions[0].label && (
                                    <Select
                                        defaultValue={{ value: 0, label: audioOptions[0].label }}
                                        onChange={(s: any) => {
                                            if (s && audioOptions[s.value] && audioOptions[s.value].value) {
                                                if (publisherRef.current) {
                                                    const publisher = publisherRef.current.getPublisher();
                                                    publisher.setAudioSource(audioOptions[s.value].value);
                                                }
                                            }
                                        }}
                                        isClearable={false}
                                        className={styles.deviceSelect}
                                        options={audioOptions}
                                        placeholder=""
                                        onMenuOpen={getDevices}
                                        styles={utils.styleSelectComponent()}
                                    />
                                )}
                            </Flex>
                        </Flex>
                    </div>
                );
            case 'cancel':
                if (props.isPatient) {
                    return null;
                }
                return (
                    <div className={styles.cancelling}>
                        <Input
                            label="Cancellation Reason"
                            multiLine={true}
                            value={cancelState}
                            onChangeText={(t: string) => setCancelState(t)}
                            placeholder="Enter a cancellation reason"
                            className={styles.textarea}
                            rows={15}
                        />
                        <Button
                            data-test-id={ANALYTICS_NAMES.TelehealthSessionVideo_CancelCall}
                            type="primary"
                            text="Cancel Telehealth Call"
                            onClick={() => cancelSession()}
                        />
                        <Button
                            data-test-id={ANALYTICS_NAMES.TelehealthSessionVideo_CancelGoBack}
                            type="secondary"
                            className={styles.secondaryButton}
                            text="Go Back"
                            onClick={() => setStartState('pending')}
                        />
                    </div>
                );
            case 'ended':
                if (props.isPatient) {
                    return (
                        <div className={styles.callEnded}>
                            <p>Your call has ended. Please close this window.</p>
                        </div>
                    );
                }
                return (
                    <Flex
                        direction="column"
                        align="center"
                        justify="center"
                        className={styles.sessionContainer}
                        style={videoDimensions}
                    >
                        <div className={styles.ended}>
                            <p>Please complete the visit below.</p>
                            <Button
                                data-test-id={ANALYTICS_NAMES.TelehealthSessionVideo_CompleteCall}
                                type="primary"
                                text="Complete Telehealth Visit"
                                onClick={() => confirmComplete()}
                            />
                        </div>
                    </Flex>
                );
            case 'completed':
                if (props.isPatient) {
                    return (
                        <div className={styles.callEnded}>
                            <p>Your call has ended. Please close this window.</p>
                        </div>
                    );
                }
                return (
                    <Flex
                        direction="column"
                        align="center"
                        justify="center"
                        className={styles.sessionContainer}
                        style={videoDimensions}
                    >
                        {videoUrl ? (
                            <Video src={videoUrl} />
                        ) : (
                            <div className={styles.ended}>
                                <p>There is no video to display</p>
                            </div>
                        )}
                    </Flex>
                );
            case 'cancelled':
                if (props.isPatient) {
                    return (
                        <div className={styles.callEnded}>
                            <p>Your call has been cancelled. Please close this window.</p>
                        </div>
                    );
                }
                return (
                    <Flex
                        direction="column"
                        align="center"
                        justify="center"
                        className={styles.sessionContainer}
                        style={videoDimensions}
                    >
                        <div className={styles.ended}>
                            <p>The call was cancelled. Reason: "{props.session.telehealthSessionCancellationReason}"</p>
                        </div>
                    </Flex>
                );
        }
    }

    return getVideoState();
}

// @ts-ignore
export default preloadScript(TelehealthSessionVideoComponent);
