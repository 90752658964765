import React from 'react';

const SvgUpButton = (props) => (
    <svg width={29} height={29} {...props} viewBox="0 0 29 29">
        <g transform="matrix(1 0 0 -1 0 29)" fill="none" fillRule="evenodd">
            <rect fill="#FBE7EE" width={29} height={29} rx={2} />
            <path
                d="M8 12l6.288 4.934c.054.042.13.066.212.066a.348.348 0 00.211-.066L21 12"
                stroke="#DA1057"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
            />
        </g>
    </svg>
);

export default SvgUpButton;
