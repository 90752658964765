import React, { useState, useEffect } from 'react';
import styles from './styles.module.scss';
import Flex from 'src/components/Flex';
import Icon from 'src/components/Icon';
import OptionDropDown, { OptionTypes } from 'src/components/OptionDropDown';
import Input, { Label } from 'src/components/Input';
import { PatientAddress, PatientPhone, PatientEmail } from 'src/stores/PatientStore';
import { ICONS } from 'src/utils/constants';
import * as validation from 'src/utils/validation';
import * as yup from 'yup';
import useYup from 'src/utils/hooks/useYup';
import * as variables from 'src/styles/variables';
import { Facility } from 'src/stores/FacilityStore';
import { ANALYTICS_NAMES } from 'src/utils/analytics';

const emailValidationScheme = yup.object().shape({
    email: validation.emailValidationYup,
});

const phoneValidationScheme = yup.object().shape({
    phone: validation.phoneValidationYup,
});

const defaultRows = {
    email: { type: '', email: '' },
    phone: { type: '', phone: '' },
    address: { stateCode: '', city: '', type: '', line1: '', zipCode: '' },
};

interface RowProps {
    onChange: Function;
    onDelete: Function;
    facId?: Facility['facId'];
}

interface EmailProps extends RowProps {
    email: PatientEmail;
}

export function EmailRow(props: EmailProps) {
    const [email, setEmail] = useState(props.email || defaultRows.email);
    const [showX, setShowX] = useState(false);

    const { errors: emailErrors } = useYup(email, emailValidationScheme, {
        validateOnChange: true,
    });

    function handleUpdate(param: string, value: any) {
        setEmail({ ...email, [param]: value });
    }

    useEffect(() => {
        props.onChange(email);
    }, [email]);

    return (
        <Flex direction="row" className={styles.rowWrap}>
            <OptionDropDown
                facId={props.facId}
                selectedValue={(props.email.type || '').toUpperCase()}
                onChange={(s: any) => handleUpdate('type', s.value)}
                type={OptionTypes.PersonEmailAddressTypes}
                className={styles.typeDropdown}
            />
            <Flex
                value={1}
                onMouseEnter={() => setShowX(true)}
                onMouseLeave={() => setShowX(false)}
                data-test-id={ANALYTICS_NAMES.AddRows_Email_Row_Wrapper}
            >
                <Input
                    className={styles.input}
                    value={email.email}
                    onChangeText={(t: string) => handleUpdate('email', t)}
                    errorMessage={email.email && emailErrors.email}
                    data-test-id={ANALYTICS_NAMES.AddRows_Email_Row_Input}
                />
                <div
                    style={{ display: showX ? 'block' : 'none' }}
                    className={styles.delete}
                    onClick={() => props.onDelete()}
                    data-test-id={ANALYTICS_NAMES.AddRows_Email_Row_Delete_Button}
                >
                    X
                </div>
            </Flex>
        </Flex>
    );
}

interface PhoneProps extends RowProps {
    phone: PatientPhone;
}

export function PhoneRow(props: PhoneProps) {
    const [phone, setPhone] = useState(props.phone || defaultRows.phone);
    const [showX, setShowX] = useState(false);
    const { errors: phoneErrors } = useYup(phone, phoneValidationScheme, {
        validateOnChange: true,
    });
    function handleUpdate(param: string, value: any) {
        setPhone({ ...phone, [param]: value });
    }

    useEffect(() => {
        props.onChange(phone);
    }, [phone]);

    return (
        <Flex direction="row" className={styles.rowWrap}>
            <OptionDropDown
                facId={props.facId}
                selectedValue={(props.phone.type || '').toUpperCase()}
                onChange={(s: any) => handleUpdate('type', s.value)}
                type={OptionTypes.PersonPhoneNumberTypes}
                className={styles.typeDropdown}
            />
            <Flex
                value={1}
                onMouseEnter={() => setShowX(true)}
                onMouseLeave={() => setShowX(false)}
                data-test-id={ANALYTICS_NAMES.AddRows_Phone_Row_Wrapper}
            >
                <Input
                    className={styles.input}
                    value={phone.phone}
                    onChangeText={(t: string) => handleUpdate('phone', t)}
                    errorMessage={phone.phone && phoneErrors.phone}
                    data-test-id={ANALYTICS_NAMES.AddRows_Phone_Row_Input}
                />
                <div
                    style={{ display: showX ? 'block' : 'none' }}
                    className={styles.delete}
                    onClick={() => props.onDelete()}
                    data-test-id={ANALYTICS_NAMES.AddRows_Phone_Row_Delete_Button}
                >
                    X
                </div>
            </Flex>
        </Flex>
    );
}
interface AddressProps extends RowProps {
    address: PatientAddress;
}

export function AddressRow(props: AddressProps) {
    const [address, setAddress] = useState(props.address || defaultRows.address);
    const [showX, setShowX] = useState(false);

    function handleUpdate(param: string, value: any) {
        setAddress({ ...address, [param]: value });
    }

    useEffect(() => {
        props.onChange(address);
    }, [address]);

    return (
        <Flex
            direction="column"
            onMouseEnter={() => setShowX(true)}
            onMouseLeave={() => setShowX(false)}
            className={styles.addressWrap}
            data-test-id={ANALYTICS_NAMES.AddRows_Address_Row_Wrapper}
        >
            <Flex direction="row" className={styles.rowWrap}>
                <Flex direction="column">
                    <Label text="TYPE" />
                    <OptionDropDown
                        facId={props.facId}
                        selectedValue={(props.address.type || '').toUpperCase()}
                        onChange={(s: any) => handleUpdate('type', s.value)}
                        type={OptionTypes.PersonAddressTypes}
                        className={styles.typeDropdown}
                    />
                </Flex>
                <Flex value={1}>
                    <Input
                        className={styles.input}
                        value={address.line1}
                        label="Address"
                        placeholder="Address"
                        onChangeText={(t: string) => handleUpdate('line1', t)}
                        data-test-id={ANALYTICS_NAMES.AddRows_Address_Row_Input}
                    />
                    <div
                        style={{ display: showX ? 'block' : 'none' }}
                        className={styles.deleteAddress}
                        onClick={() => props.onDelete()}
                        data-test-id={ANALYTICS_NAMES.AddRows_Address_Row_Delete_Button}
                    >
                        X
                    </div>
                </Flex>
            </Flex>
            <Flex value={1}>
                <Input
                    className={styles.input}
                    value={address.city}
                    label="City"
                    placeholder="City"
                    onChangeText={(t: string) => handleUpdate('city', t)}
                />
            </Flex>
            <Flex direction="row">
                <Flex value={1}>
                    <Input
                        className={styles.input}
                        value={address.stateCode}
                        label="State"
                        placeholder="State"
                        onChangeText={(t: string) => handleUpdate('stateCode', t)}
                    />
                </Flex>
                <Flex value={1} style={{ marginLeft: 5 }}>
                    <Input
                        className={styles.input}
                        value={address.zipCode}
                        label="Zip Code"
                        placeholder="Zip Code"
                        onChangeText={(t: string) => handleUpdate('zipCode', t)}
                    />
                </Flex>
            </Flex>
        </Flex>
    );
}

type RowData = (PatientEmail | PatientAddress | PatientPhone)[];

export interface AddRowsProps {
    onChange: Function;
    facId?: Facility['facId'];
    emails?: RowData;
    addresses?: RowData;
    phoneNumbers?: RowData;
    type: 'email' | 'phone' | 'address';
}

function AddRows(props: AddRowsProps) {
    const [rows, setRows] = useState(props.emails || props.phoneNumbers || props.addresses);

    useEffect(() => {
        props.onChange(rows);
    }, [rows]);

    function handleOnChange(data: any, index: number) {
        const arr = rows;
        arr[index] = data;
        setRows(arr);
    }

    function getRows() {
        if (props.emails && props.type === 'email') {
            return rows.map((e, index) => (
                <Flex key={`${index}${e.type}`} direction="column" data-test-id={ANALYTICS_NAMES.AddRows_Email_Row}>
                    <EmailRow
                        email={e}
                        facId={props.facId}
                        onChange={(email: any) => {
                            handleOnChange(email, index);
                        }}
                        onDelete={() => {
                            setRows(rows.filter((i, ind) => ind !== index));
                        }}
                    />
                </Flex>
            ));
        } else if (props.phoneNumbers && props.type === 'phone') {
            return rows.map((e, index) => (
                <Flex key={`${index}${e.type}`} direction="column" data-test-id={ANALYTICS_NAMES.AddRows_Phone_Row}>
                    <PhoneRow
                        phone={e}
                        facId={props.facId}
                        onChange={(phone: any) => {
                            handleOnChange(phone, index);
                        }}
                        onDelete={() => {
                            setRows(rows.filter((i, ind) => ind !== index));
                        }}
                    />
                </Flex>
            ));
        } else if (props.addresses && props.type === 'address') {
            return rows.map((e, index) => (
                <Flex key={`${index}${e.type}`} direction="column" data-test-id={ANALYTICS_NAMES.AddRows_Address_Row}>
                    <AddressRow
                        address={e}
                        facId={props.facId}
                        onChange={(address: any) => {
                            handleOnChange(address, index);
                        }}
                        onDelete={() => {
                            setRows(rows.filter((i, ind) => ind !== index));
                        }}
                    />
                </Flex>
            ));
        }
    }

    return (
        <div>
            {getRows()}
            <span
                onClick={() => setRows([...rows, defaultRows[props.type]])}
                className={styles.addRowWrap}
                data-test-id={ANALYTICS_NAMES.AddRows_Add_Row_Button}
            >
                <Flex direction="row">
                    <Icon name={ICONS.PlusButton} color={variables.red} className={styles.plusIcon} />
                    Add a row
                </Flex>
            </span>
        </div>
    );
}

export default AddRows;
