import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { Label } from '../Input';
import Flex from '../Flex';
import DatePicker, { DatePickerProps } from '../DatePicker';

export interface NemsisDatePickerProps extends DatePickerProps {
    onNaClick?: Function;
    naSelected?: boolean;
    nemsisLabel?: string;
    required?: boolean;
    selectedValue?: any;
}

function NemsisDatePicker(props: NemsisDatePickerProps) {
    function handleNaClick() {
        props.onNaClick && props.onNaClick();
    }
    return (
        <>
            {props.nemsisLabel ? <Label text={props.nemsisLabel} required={props.required} /> : null}

            <Flex direction="row" justify="between" align="center" className={styles.parentWrap}>
                <DatePicker
                    disabled={props.naSelected}
                    onChange={(data: any) => props.onChange(data)}
                    value={props.selectedValue}
                />
                {props.onNaClick && (
                    <div
                        onClick={handleNaClick}
                        className={classNames(styles.naWrap, { [styles.naActive]: props.naSelected })}
                    >
                        N/A
                    </div>
                )}
            </Flex>
        </>
    );
}

export default NemsisDatePicker;
