import React from 'react';

const SvgArrowSide = (props) => (
    <svg width={26} height={26} {...props} viewBox="0 0 26 26">
        <g transform="matrix(-1 0 0 1 26 0)" fill={props.color || '#FFF'} fillRule="evenodd">
            <ellipse opacity={0.1} cx={13.111} cy={13} rx={12.5} ry={13} />
            <path
                d="M16.37 12.667c0 .331-.147.647-.405.866l-5.78 4.917a.98.98 0 01-1.335-.098.904.904 0 01.07-1.292l5.055-4.3a.124.124 0 00.045-.093.124.124 0 00-.045-.095L8.92 8.273a.904.904 0 01-.07-1.292.98.98 0 011.334-.098l5.78 4.916c.257.22.405.535.406.868z"
                fillRule="nonzero"
            />
        </g>
    </svg>
);

export default SvgArrowSide;
