import React, { useState, useContext } from 'react';
import styles from './styles.module.scss';

import RoleDropDown from 'src/components/RoleDropDown';
import FacilityDropDown from 'src/components/FacilityDropDown';
import FacilityStore from 'src/stores/FacilityStore';

interface Props {
    onChangeFacilities: Function;
    onChangeRoles: Function;
}

function Step2(props: Props) {
    const [orgAdminSelected, setOrgAdminSelected] = useState(false);
    const [selectedFacilities, setSelectedFacilities] = useState([]);
    const facilityStore = useContext(FacilityStore);

    function handleFacilityChange(facilityIds: number[]) {
        props.onChangeFacilities(facilityIds);
    }

    function handleRoleChange(option: React.SetStateAction<null>) {
        let facIds = null;
        if (option && option.length > 0) {
            // @ts-ignore
            const selectedRoles = option.map((o) => o.label);
            if (selectedRoles.includes('Organization Administrator')) {
                // Org admin is selected, so dont allow choosing facilities.
                setOrgAdminSelected(true);
                facIds = facilityStore.facilitiesDropdownValues.map((fac) => fac.value as number);
                setSelectedFacilities(facIds);
            } else {
                setOrgAdminSelected(false);
            }
        } else {
            setOrgAdminSelected(false);
        }
        props.onChangeRoles(option, facIds);
    }

    return (
        <div className={styles.contentWrap}>
            <div className={styles.title}>User Roles</div>
            <div className={styles.subTitle}>
                Select the roles that you would like this user to have. You must select at least 1.
            </div>
            <div className={styles.input}>
                <RoleDropDown isMulti={true} onChange={(s: any) => handleRoleChange(s)} />
            </div>
            <div className={styles.title}>Facility Access</div>
            <div className={styles.subTitle}>Select which facilities you would like this user to have access to.</div>
            <div className={styles.input}>
                {orgAdminSelected && (
                    <div className={styles.alertText}>*Organization Administrators have access to all facilities.</div>
                )}
                <FacilityDropDown
                    selectedValue={selectedFacilities}
                    isDisabled={orgAdminSelected}
                    onChange={(s: any) => {
                        let facIds = null;
                        if (s && s.length > 0) {
                            facIds = s.map((a: any) => a.value);
                            setSelectedFacilities(facIds);
                        } else {
                            setSelectedFacilities([]);
                        }
                        handleFacilityChange(facIds);
                    }}
                    placeholder="Select Facilities"
                    isMulti={true}
                    closeMenuOnSelect={false}
                    allowSelectAll={true}
                />
            </div>
        </div>
    );
}

export default Step2;
