import React from 'react';

const SvgToolBox = (props) => (
    <svg width={17} height={16} {...props} viewBox="0 0 17 16">
        <g
            transform="translate(0 1)"
            stroke={props.color || '#FFF'}
            strokeWidth={1.05}
            fill="none"
            fillRule="evenodd"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <rect x={0.531} y={3.326} width={15.938} height={10.5} rx={1.05} />
            <path d="M11.688 3.326c0-1.74-1.428-3.15-3.188-3.15-1.76 0-3.188 1.41-3.188 3.15M.531 7.526h6.907M9.563 7.526h6.906M8.5 9.1c-.587 0-1.063-.47-1.063-1.05V7c0-.58.476-1.05 1.063-1.05S9.563 6.42 9.563 7v1.05c0 .58-.476 1.05-1.063 1.05z" />
        </g>
    </svg>
);

export default SvgToolBox;
