import {
    Customer,
    CustomerContact,
    CustomerDocument,
    CustomerSubscriptionData,
} from 'common/lib/entity/index/Customer';
import * as ROUTES from 'src/api/routes';
import { FilterQueryParams } from 'src/utils/types';
import request from './utils';

export async function getCustomers(params: FilterQueryParams = {}) {
    const response = await request({ ...ROUTES.GET_CUSTOMERS, params });

    return { result: response.data.result || [], count: response.data.count || 0 };
}

export async function getCustomer(customerId: Customer['customerId']) {
    const customer = await request({ ...ROUTES.GET_CUSTOMER, pathParams: { customerId } });

    return customer.data || {};
}

export async function getCustomerOrgs(customerId: Customer['customerId']) {
    const customerOrgs = await request({ ...ROUTES.GET_CUSTOMER_ORGS, pathParams: { customerId } });

    return customerOrgs.data || [];
}

export async function updateCustomer(
    customerId: Customer['customerId'],
    customerName: Customer['customerName'],
    subscriptionData: Partial<CustomerSubscriptionData>,
) {
    return await request({
        ...ROUTES.UPDATE_CUSTOMER,
        pathParams: { customerId },
        data: { customerName, subscriptionData },
    });
}

export async function createCustomer(
    customerName: Customer['customerName'],
    subscriptionData: Partial<CustomerSubscriptionData>,
) {
    return await request({
        ...ROUTES.CREATE_CUSTOMER,
        data: { customerName, subscriptionData },
    });
}

export async function updateCustomerStatus(
    customerId: Customer['customerId'],
    subscriptionData: Partial<CustomerSubscriptionData>,
) {
    return await request({
        ...ROUTES.UPDATE_CUSTOMER_STATUS,
        pathParams: { customerId },
        data: { subscriptionData },
    });
}

export async function addDocument(customerId: Customer['customerId'], document: Partial<CustomerDocument>) {
    return await request({ ...ROUTES.ADD_CUSTOMER_DOCUMENT, pathParams: { customerId }, data: { document } });
}

export async function updateDocument(
    customerId: Customer['customerId'],
    documentGuid: CustomerDocument['documentGuid'],
    document: Partial<CustomerDocument>,
) {
    return await request({
        ...ROUTES.UPDATE_CUSTOMER_DOCUMENT,
        pathParams: { customerId, documentGuid },
        data: { document },
    });
}

export async function addContact(
    customerId: Customer['customerId'],
    newContact: Partial<CustomerContact>,
    contactType: string,
) {
    return await request({
        ...ROUTES.ADD_CUSTOMER_CONTACT,
        pathParams: { customerId },
        data: { newContact, contactType },
    });
}

export async function updateContact(
    customerId: Customer['customerId'],
    contactGuid: CustomerContact['contactGuid'],
    updatedContact: Partial<CustomerContact>,
    contactType: string,
) {
    return await request({
        ...ROUTES.UPDATE_CUSTOMER_CONTACT,
        pathParams: { customerId, contactGuid },
        data: { updatedContact, contactType },
    });
}

export async function removeContact(
    customerId: Customer['customerId'],
    contactGuid: CustomerContact['contactGuid'],
    contactType: string,
) {
    return await request({
        ...ROUTES.REMOVE_CUSTOMER_CONTACT,
        pathParams: { customerId, contactGuid },
        data: { contactType },
    });
}
