import { IVehicleCertificationLevel, IVehicleAnnualUsage } from 'common/lib/model/nemsis3_4/demographic';
import { observable, action, makeObservable } from 'mobx';
import * as vehicleAPI from 'src/api/vehicles';
import { createContext } from 'react';

export interface Vehicle {
    vehicleId?: number;
    facilityId?: number;
    vehicleNumber: string;
    vehicleIdNumber: string;
    callSign: string;
    vehicleTypeCode: string;
    vehicleCertificationLevels: IVehicleCertificationLevel[];
    initialCost: number;
    modelYear: number;
    annualUsage: IVehicleAnnualUsage[];
    notValues?: { [key in keyof Vehicle]?: any };
    active?: boolean;
    insDttm?: Date;
    updDttm?: Date;
    audVer?: number;
}

class VehicleStore {
    @observable vehicles: Vehicle[] = [];

    constructor() {
        makeObservable(this);
    }

    @action
    async getVehicles() {
        const results = await vehicleAPI.getVehicles();
        this.vehicles = results.data;
    }

    @action
    async createVehicle(device: Vehicle) {
        const result = await vehicleAPI.createVehicle(device);
        this.vehicles = [...this.vehicles, result.data];
    }

    @action
    async updateVehicle(device: Vehicle) {
        const result = await vehicleAPI.updateVehicle(device);
    }

    @action
    async toggleActive(device: Vehicle) {
        const updatedVehicle = { ...device, active: !device.active };
        const result = await vehicleAPI.updateVehicle(updatedVehicle);
    }
}

export const VehicleStoreObject = new VehicleStore();

export default createContext(VehicleStoreObject);
