import React, { useState, useEffect, useContext } from 'react';
import classNames from 'classnames';
import NavBar from 'src/containers/NavBar';
import PageContent from 'src/components/PageContent';
import PageContainer from 'src/components/PageContainer';
import Card from 'src/components/Card';
import Flex from 'src/components/Flex';
import Button from 'src/components/Button';
import Icon from 'src/components/Icon';
import Table from 'src/components/Table';
import styles from './styles.module.scss';
import { observer } from 'mobx-react';
import * as facilitiesAPI from 'src/api/facilities';
import { CellInfo } from 'react-table';
import { ToastStoreObject } from 'src/stores/ToastStore';
import { ErrorStoreObject, ErrorTypes } from 'src/stores/ErrorStore';
import { ICONS } from 'src/utils/constants';
import * as variables from 'src/styles/variables';
import { ModalStoreObject, ModalTypes } from 'src/stores/ModalStore';
import { ANALYTICS_NAMES } from 'src/utils/analytics';
import UserStore, { OrgType, UserStoreObject } from 'src/stores/UserStore';
import { Facility } from 'src/stores/FacilityStore';
import ReferenceListStore from 'src/stores/ReferenceListStore';
import { OptionTypes } from 'src/components/OptionDropDown';
import * as utils from 'src/utils';
import NemsisErrorBanner, { NemsisErrorBannerMode } from '../NemsisErrorBanner';
import useFlag from 'src/utils/hooks/useFlag';
import Checkbox from 'src/components/Checkbox';

function Facilities() {
    const [facilityState, setFacilityState] = useState([]);
    const userStore = useContext(UserStore);
    const refListStore = useContext(ReferenceListStore);
    const [isLoading, setIsLoading] = useState(false);
    const [isFieldMed, setIsFieldMed] = useState(false);
    const [showDeactivatedFacilities, setShowDeactivatedFacilities] = useState(false);
    const nemsisEnabled = useFlag('nemsis');

    const columns = [
        {
            Header: () => <span>NAME</span>,
            accessor: 'facNm',
            minWidth: 200,
            Cell: (props: CellInfo) => <span>{props.value}</span>,
        },
        {
            Header: 'DESCRIPTION',
            accessor: 'facDesc',
            minWidth: 200,
        },
        {
            Header: 'CITY',
            accessor: 'addrCityNm',
            minWidth: 100,
        },
        {
            Header: 'STATE',
            accessor: 'addrStCd',
            minWidth: 100,
            Cell: (props: CellInfo) => <span>{props.value}</span>,
        },
        {
            Header: 'ACTIVE',
            accessor: 'actvInd',
            minWidth: 100,
            Cell: (props: CellInfo) => (
                <span className={classNames({ [styles.redText]: !props.value, [styles.greenText]: props.value })}>
                    {props.value ? 'ACTIVE' : 'INACTIVE'}
                </span>
            ),
        },
        {
            Header: 'ACTIONS',
            minWidth: 300,
            style: { paddingVertical: 0, paddingHorizontal: 20 },
            Cell: (props: CellInfo) => (
                <Flex direction="row">
                    <Button
                        data-test-id={ANALYTICS_NAMES.Facilities_Table_Edit}
                        onClick={() => {
                            showModal(props.original);
                        }}
                        isLocked={!userStore.userPermissions.canEdit.facilities}
                        type="secondary"
                        leftIcon={<Icon name={ICONS.Pencil2} color={variables.red} />}
                    />
                    {userStore.isGraphiumAdmin() ? (
                        facilityState[props.index].actvInd ? (
                            <Button
                                data-test-id={ANALYTICS_NAMES.Facilities_Table_Deactivate}
                                onClick={() => handleConfirm(props.index)}
                                type="secondary"
                                text="Deactivate"
                                isLocked={!userStore.userPermissions.canEdit.facilities}
                                className={styles.actionButtons}
                            />
                        ) : (
                            <Button
                                data-test-id={ANALYTICS_NAMES.Facilities_Table_Reactivate}
                                onClick={() => handleReactivate(props.index)}
                                type="primary"
                                text="Reactivate"
                                isLocked={!userStore.userPermissions.canEdit.facilities}
                                className={styles.actionButtons}
                            />
                        )
                    ) : null}
                </Flex>
            ),
        },
    ];

    async function loadFacilities(includeDeactivated: boolean = false) {
        try {
            setIsLoading(true);
            const facilities = await facilitiesAPI.fetchFacilities(includeDeactivated);
            setFacilityState(facilities.data);
            setIsLoading(false);
            return facilities;
        } catch (e) {
            ErrorStoreObject.setError(ErrorTypes.Loading);
        }
    }

    async function loadRefLists() {
        try {
            await refListStore.getMultipleLists([
                OptionTypes.EMSLocationType,
                OptionTypes.EMSPhoneNumberType,
                OptionTypes.ANSIStateCodes,
                OptionTypes.EMSANSICountryCodesCAMXUS,
            ]);
        } catch (error) {
            ToastStoreObject.show(utils.parseError(error));
        }
    }

    useEffect(() => {
        loadFacilities(showDeactivatedFacilities);
        loadRefLists();
        setIsFieldMed(userStore.selectedOrgType === OrgType.FIELDMED);
    }, [showDeactivatedFacilities]);

    async function handleDeactivate(index: number) {
        await facilitiesAPI.deactivateFacility(facilityState[index].facId);
        loadFacilities();
        ModalStoreObject.hideModal();
    }

    async function handleReactivate(id: number) {
        await facilitiesAPI.reactivateFacility(facilityState[id].facId);
        loadFacilities();
    }

    function handleConfirm(index: number) {
        ModalStoreObject.showModal(ModalTypes.ConfirmationModal, {
            title: 'Are you sure you would like to deactivate this facility?',
            onConfirm: () => handleDeactivate(index),
            onCancel: () => ModalStoreObject.hideModal(),
            confirmButtonText: 'Deactivate',
        });
    }

    function showModal(facility: Facility = null) {
        ModalStoreObject.showModal(ModalTypes.Facility, {
            onClose: () => ModalStoreObject.hideModal(),
            onSave: () => onSave(),
            facility,
        });
    }

    async function onSave() {
        ModalStoreObject.hideModal();
        await loadFacilities();
    }

    function handleShowDeactivatedFacilitiesChange(showDeactivatedFacilities: boolean) {
        setShowDeactivatedFacilities(showDeactivatedFacilities);
    }

    return (
        <PageContainer>
            <NavBar />
            <PageContent>
                {isFieldMed && nemsisEnabled && <NemsisErrorBanner mode={NemsisErrorBannerMode.DEM} />}
                <Flex self="stretch" align="center" justify="start">
                    <Flex className={styles.pageTitle}>FACILITIES</Flex>
                    <Flex value={1}>
                        <Checkbox
                            checked={showDeactivatedFacilities}
                            label={'Show deactivated facilities'}
                            onChange={(e) => {
                                handleShowDeactivatedFacilitiesChange(e.target.checked);
                            }}
                        />
                    </Flex>
                    {UserStoreObject.userPermissions.canCreate.facilities && (
                        <Button
                            leftIcon={<Icon className={styles.plusIcon} name={ICONS.PlusButton} />}
                            type="primary"
                            onClick={() => {
                                ModalStoreObject.showModal(ModalTypes.Facility, {
                                    onClose: () => ModalStoreObject.hideModal(),
                                    onSave,
                                });
                            }}
                            text="New Facility"
                        />
                    )}
                </Flex>
                <Card className={styles.cardWrap}>
                    <Table columns={columns} data={facilityState} isLoading={isLoading} />
                </Card>
            </PageContent>
        </PageContainer>
    );
}
export default observer(Facilities);
