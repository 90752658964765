import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import { format } from 'date-fns';
import { CellInfo, Column } from 'react-table';
import classNames from 'classnames';
import styles from 'src/containers/Reports/Macra/styles.module.scss';
import Table from 'src/components/Table';
import MacraStore from 'src/stores/MacraStore';
import { API_DATE_FORMAT } from 'src/utils/constants';

function OutcomesTab() {
    const macraStore = useContext(MacraStore);

    const outcomesColumns: Column[] = [
        {
            Header: 'DATE OF SERVICE',
            accessor: 'dateOfService',
            minWidth: 150,
            Cell: (props: CellInfo) => {
                return format(new Date(props.value), API_DATE_FORMAT);
            },
        },
        {
            Header: 'CATEGORY',
            accessor: 'category',
            minWidth: 150,
        },
        {
            Header: 'OUTCOME',
            accessor: 'description',
            minWidth: 150,
            Cell: (props: CellInfo) => {
                return <div className={styles.wrapText}>{props.value}</div>;
            },
        },
        {
            Header: 'MAJOR?',
            accessor: 'isMajor',
            minWidth: 150,
            headerClassName: classNames(styles.alignCenter, styles.tableHeader),
            Cell: (props: CellInfo) => {
                return <div className={styles.alignCenter}>{props.value ? 'YES' : 'NO'}</div>;
            },
        },
        {
            Header: 'PROVIDERS',
            accessor: 'anesProviders',
            minWidth: 200,
            Cell: (props: CellInfo) => {
                return <div className={styles.wrapText}>{props.value}</div>;
            },
        },
    ];

    return <div className={styles.tabContent}>
        <div className={styles.tableWrap}>
            <Table
                isLoading={macraStore.isLoading}
                columns={outcomesColumns}
                data={macraStore.complications}
                showPagination={false}
            />
        </div>
    </div>;
}

export default observer(OutcomesTab);