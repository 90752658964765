import React from 'react';
import { ROUTES } from 'src/utils/constants';
import { Helmet as ReactHelmet } from 'react-helmet';
import { useLocation, matchPath } from 'react-router-dom';
import { getBrandingString } from '../FeatureFlag';
import { UserStoreObject, OrgType } from 'src/stores/UserStore';

const LOCKED_VIEWPORT = <meta name="viewport" content="user-scalable=no, width=1000, initial-scale=1" />;
const SCALED_VIEWPORT = <meta name="viewport" content="width=device-width, initial-scale=1" />;

const RouteData: { [key in string]?: { scaled?: boolean; title: string } } = {
    [ROUTES.Profile]: { title: 'Profile' },
    [ROUTES.ReportsChp]: { title: 'CHP Dashboard' },
    [ROUTES.ReportsMacra]: { title: 'MACRA Dashboard' },
    [ROUTES.ReportsMacra2023]: { title: 'MACRA Dashboard' },
    [ROUTES.QcdrSettings]: { title: 'QCDR Settings' },
    [ROUTES.QcdrSettings2023]: { title: 'QCDR Settings' },
    [ROUTES.SettingsUsers]: { title: 'Users and Invitations' },
    [ROUTES.SettingsProviders]: { title: 'Providers' },
    [ROUTES.SettingsTags]: { title: 'Tags' },
    [ROUTES.SettingsTeams]: { title: 'Teams' },
    [ROUTES.SettingsFacilities]: { title: 'Facilities' },
    [ROUTES.SettingsRefListDetails]: { title: 'Reference List Details' },
    [ROUTES.SettingsValueSetDetails]: { title: 'Value Set Details' },
    [ROUTES.SettingsRefLists]: { title: 'Reference List' },
    [ROUTES.AdminAllUsers]: { title: 'Admin - All Users' },
    [ROUTES.TelehealthVisits]: { title: 'Visits' },
    [ROUTES.TelehealthWaitingRoom]: { title: 'Waiting Room' },
    [ROUTES.TelehealthSession]: { title: 'Telehealth' },
    [ROUTES.Appointments]: { title: 'Schedule' },
    [ROUTES.PatientDetails]: { title: 'Patient' },
    [ROUTES.Encounters]: { title: 'Encounters' },
    [ROUTES.Forms]: { title: 'Forms' },
    [ROUTES.Patients]: { title: 'Patients' },
    [ROUTES.AdminFlowScriptTemplates]: { title: 'Flow Script Templates' },
    [ROUTES.AdminFlowScriptTemplateDetail]: { title: 'Flow Script Template' },
    [ROUTES.AdminImportBatchTemplates]: { title: 'Import Batch Templates' },
    [ROUTES.Import]: { title: 'Import' },
    [ROUTES.ImportDetail]: { title: 'Import Details' },
    [ROUTES.ImportRecord]: { title: 'Import Record' },
    // Scale these pages
    [ROUTES.PatientVideo]: { scaled: true, title: 'Patient Telehealth' },
    [ROUTES.PatientSurvey]: { scaled: true, title: 'Patient Survey' },
    [ROUTES.ForgotPassword]: { scaled: true, title: 'Forgot Password' },
    [ROUTES.Invitation]: { scaled: true, title: 'Invitation' },
    [ROUTES.Reset]: { scaled: true, title: 'Reset Password' },
    [ROUTES.Login]: { scaled: true, title: 'Login' },
};

const Helmet = () => {
    const location = useLocation();
    const pathname = location.pathname;
    const favIcon =
        UserStoreObject.selectedOrgType === OrgType.FIELDMED ? (
            <link rel="shortcut icon" href="/fm-favicon.ico" />
        ) : (
            <link rel="shortcut icon" href="/gh-favicon.ico" />
        );
    for (const path in RouteData) {
        if (path) {
            const match = matchPath(pathname, { path, exact: true });
            if (match) {
                const data = RouteData[path];
                return data ? (
                    <ReactHelmet title={`${getBrandingString()} - ${data.title}`}>
                        {favIcon}
                        {data.scaled ? SCALED_VIEWPORT : LOCKED_VIEWPORT}
                    </ReactHelmet>
                ) : null;
            }
        }
    }
    return (
        <ReactHelmet title={getBrandingString()}>
            {favIcon}
            {LOCKED_VIEWPORT}
        </ReactHelmet>
    );
};

export default Helmet;
