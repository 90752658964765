import React, { useState, useEffect, useRef } from 'react';
import Flex from 'src/components/Flex';
import Input from 'src/components/Input';
import NemsisInput from 'src/components/NemsisInput';
import Button from 'src/components/Button';
import { format } from 'date-fns';
import { NaValues } from 'common/lib/model/nemsis3_4/demographic';
import styles from '../../styles.module.scss';

interface TimeProps {
    input: any;
    isValid: boolean;
    onChange?: Function;
    onNaChange?: Function;
    enableNotValues?: boolean;
    value?: string;
    notValue?: string;
}

const TIME_FORMAT = 'HH:mm:ss';
const TIME_REGEX = /^(?:[01]\d|2[0123]):(?:[012345]\d):(?:[012345]\d)$/;

function TimeInput(props: TimeProps) {
    const firstValueRender = useRef(true);
    const firstNotValueRender = useRef(true);
    const [timeState, setTimeState] = useState(props.value || '');
    const [notValue, setNotValue] = useState(props.notValue || '');

    useEffect(() => {
        if (timeState && timeState.match(TIME_REGEX) && !firstValueRender.current) {
            props.onChange(timeState);
        }
        firstValueRender.current = false;
    }, [timeState]);

    useEffect(() => {
        if (props.onNaChange && !firstNotValueRender.current) {
            props.onNaChange(notValue);
        }
        firstNotValueRender.current = false;
    }, [notValue]);

    const inputProps = {
        value: notValue === NaValues.NOT_APPLICABLE ? notValue : timeState,
        onChangeText: (value: string) => {
            setTimeState(value.replace(/[^0-9]/g, '').replace(/(\d{2})(\d{2})(\d{2})/, '$1:$2:$3'));
        },
        onBlur: () => {
            if (!timeState || timeState === '') return;

            const splitTime = timeState.split(':');
            const tempDate = new Date().setHours(
                parseInt(splitTime[0]) || 1,
                parseInt(splitTime[1]) || 0,
                parseInt(splitTime[2]) || 0,
            );
            setTimeState(format(tempDate, TIME_FORMAT));
        },
        maxLength: 8,
        placeholder: 'HH:MM',
        ...(props.enableNotValues
            ? { onNaClick: () => setNotValue(notValue === NaValues.NOT_APPLICABLE ? '' : NaValues.NOT_APPLICABLE) }
            : null),
    };

    return (
        <div>
            <div className={styles.label}>{props.input.title ? props.input.title[0] : ''}</div>
            <Flex align="center">
                {props.enableNotValues ? (
                    <NemsisInput {...inputProps} infoState={props.isValid ? null : 'error'} />
                ) : (
                    <Input {...inputProps} infoState={props.isValid ? null : 'error'} />
                )}
                <Button
                    text="Now"
                    type="small"
                    onClick={() => {
                        setTimeState(format(new Date(), TIME_FORMAT));
                    }}
                />
            </Flex>
        </div>
    );
}

export default TimeInput;
