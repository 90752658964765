import React, { useEffect, useState } from 'react';
import CodeEditor from 'src/components/CodeEditor';
import Flex from 'src/components/Flex';
import Accordion from 'src/components/Accordion';
import FormModelDropDown from 'src/components/FormModelDropdown';
import { FormAsset } from 'common/lib/entity/framework/FormAsset';
import Button from 'src/components/Button';
import LoadingIcon from 'src/components/LoadingIcon';
import { useParams } from 'react-router-dom';
import { ToastStoreObject, ToastType } from 'src/stores/ToastStore';
import { useFormCatalog } from 'src/queries/useFormCatalog';
import { useFormAssets } from 'src/queries/useFormAssets';
import { useUpdateFormCatalog } from 'src/queries/useUpdateFormCatalog';
import styles from './styles.module.scss';
import { useSaveFormCatalogAsVersion } from 'src/queries/useSaveFormCatalogAsVersion';
import { ModalStoreObject, ModalTypes } from 'src/stores/ModalStore';
import { FormCatalog } from 'common/lib/entity/framework/FormCatalog';
import { Label } from 'src/components/Input';

function FormDefinition() {
    const { formCatalogId } = useParams<any>();
    const { data: formCatalog } = useFormCatalog(formCatalogId);
    const { data: formAssets, isFetching: isFetchingFormAssets } = useFormAssets();
    const {
        mutate: updateFormCatalog,
        isLoading: isUpdateFormCatalogLoading,
        isSuccess: isUpdateFormCatalogSuccess,
        isError: isUpdateFormCatalogError,
        error: updateFormCatalogError,
    } = useUpdateFormCatalog(formCatalogId);
    const [editorRef, setEditorRef] = useState(null);
    const [formModelId, setFormModelId] = useState('');
    const [formContent, setFormContent] = useState('');
    const [hasChanges, setHasChanges] = useState(false);

    useEffect(() => {
        if (isUpdateFormCatalogSuccess) {
            ToastStoreObject.show('Form catalog saved successfully', ToastType.Success);
        }

        if (isUpdateFormCatalogError) {
            ToastStoreObject.show(updateFormCatalogError.message, ToastType.Error);
        }
    }, [isUpdateFormCatalogSuccess, isUpdateFormCatalogError]);

    useEffect(() => {
        setFormModelId(formCatalog.formModelId);
        setFormContent(formCatalog.formContent);
    }, [formCatalog]);

    async function handleSave() {
        if (formCatalogId) {
            await updateFormCatalog({
                formModelId,
                formContent,
                formPages: formCatalog.formPages,
            });
            setHasChanges(false);
        }
    }

    async function handlePublishAsVersion() {
        if (hasChanges) {
            ToastStoreObject.show('Please save your changes before publishing as a version', ToastType.Error);
            return;
        }

        if (formCatalogId) {
            ModalStoreObject.showModal(ModalTypes.FormCatalogVersionModal, {
                formCatalogId,
                onClose: () => ModalStoreObject.hideModal(),
                onSave: () => {
                    ModalStoreObject.hideModal();
                },
            });
        }
    }

    function hasFatalMessages(formCatalog: FormCatalog):boolean {
        const fatalMessages = formCatalog?.linterResults?.messages?.filter((message: any) => message.severity === 'fatal');
        return fatalMessages?.length > 0;
    }

    return (
        <>
            <Flex direction="row" align="center" gap={16} justify="end" className={styles.controls}>
                <Flex value={1}>
                    <div className={styles.yellow} hidden={!hasFatalMessages(formCatalog)}>This form has fatal errors. Please fix them before publishing a new version.</div>
                </Flex>
                <Button
                    type="secondary"
                    text="Publish as a Version"
                    onClick={handlePublishAsVersion}
                    disabled={hasFatalMessages(formCatalog) || isUpdateFormCatalogLoading || hasChanges}
                />
                <Button
                    type="primary"
                    text="Save"
                    onClick={handleSave}
                    isLoading={isUpdateFormCatalogLoading}
                    disabled={isUpdateFormCatalogLoading || !hasChanges}
                />
            </Flex>
            <Flex className={styles.container}>
                <CodeEditor
                    minLines={30}
                    maxLines={60}
                    mode="xml"
                    theme="xcode"
                    value={formContent}
                    onChange={(v: string) => {
                        setFormContent(v);
                        setHasChanges(true);
                    }}
                    onRefChange={(ref: any) => setEditorRef(ref)}
                />
                <aside className={styles.sidebar}>
                    <Accordion label="Model" removeExtraSpacing={true}>
                        <FormModelDropDown
                            selectedValue={formModelId}
                            onChange={(v: { label: string; value: string }) => {
                                setFormModelId(v.value);
                                setHasChanges(true);
                            }}
                        />
                    </Accordion>
                    <Accordion label="Assets" removeExtraSpacing={true}>
                        {isFetchingFormAssets ? (
                            <Flex className={styles.assetContainer} align="center" justify="center">
                                <LoadingIcon />
                            </Flex>
                        ) : (
                            formAssets.map((asset: FormAsset) => (
                                <Flex key={asset.formAssetId} className={styles.assetContainer}>
                                    <div className={styles.asset}>
                                        <div className={styles.assetName}>{asset.formAssetName}</div>
                                        <div className={styles.assetType}>
                                            {asset.formAssetType?.formAssetTypeTitle}
                                        </div>
                                    </div>
                                    <div className={styles.controls}>
                                        <Button
                                            type="small"
                                            text="Insert"
                                            onClick={() => {
                                                const editorReference = editorRef?.current?.editor;

                                                if (editorReference) {
                                                    switch (asset.formAssetType?.formAssetTypeTitle) {
                                                        case 'Image':
                                                            editorReference.insert(
                                                                `<imageAsset assetName="${asset.formAssetName}" x="" y="" width="" height="" />`,
                                                            );
                                                            break;
                                                        case 'Code':
                                                            editorReference.insert(
                                                                `<codeAsset assetName="${asset.formAssetName}" />`,
                                                            );
                                                            break;
                                                    }
                                                }
                                            }}
                                        />
                                    </div>
                                </Flex>
                            ))
                        )}
                    </Accordion>
                </aside>
            </Flex>
        </>
    );
}

export default FormDefinition;
