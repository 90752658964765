import React from 'react';
import styles from './styles.module.scss';
import Card from '../Card';
import classNames from 'classnames';
import Flex from '../Flex';
import ActivitiesContainer from './ActivitiesContainer';
import { MacraAvailableYears } from 'common/lib/model/macra/Macra';

interface InstructionsProps {
    year: MacraAvailableYears;
}

function Instructions(props: InstructionsProps) {
    return (
        <>
            <h3>INSTRUCTIONS</h3>
            <Flex className={styles.instructionCards}>
                <Card label="Step 1: TIN Settings" className={classNames(styles.cardWrap, styles.wrapRight)}>
                    <div className={styles.contentWrap}>
                        <p>
                            <span className={styles.bold}>Purpose:</span> Assign a Tax ID Number (TIN) for every
                            reportable case performed within your Organization.
                        </p>
                        <p>
                            <span className={styles.bold}>Background:</span> CMS evaluates quality for each unique TIN +
                            NPI combination. That is, an NPI may receive a unique Composite Performance Score (CPS) for
                            each TIN they use when filing claims with CMS. This step enables you to assign a unique TIN
                            for each NPI for all cases performed by your Organization.
                        </p>
                        <p>
                            <span className={styles.bold}>Instructions:</span>
                        </p>
                        <ol>
                            <li>
                                Please indicate which Facilities have cases which you would like submitted to the QCDR.
                                If a Facility is not selected, then no cases performed at this Facility will be
                                submitted to the QCDR.
                            </li>
                            <li>When applicable, add a TIN to each Facility.</li>
                            <li>
                                If all NPIs at a given Facility file claims with CMS under the same TIN, then use the
                                available radio button to assign the same TIN to all NPIs at given Facility. If not,
                                then individually add the correct TIN to each NPI at given Facility.
                            </li>
                        </ol>
                    </div>
                </Card>
                <Card label="Step 2: Reporting Settings" className={classNames(styles.cardWrap, styles.wrapRight)}>
                    <div className={styles.contentWrap}>
                        <p>
                            <span className={styles.bold}>Purpose:</span> For each TIN within your Organization,
                            indicate the NPIs for whom you would like to report cases to the QCDR.
                        </p>
                        <p>
                            <span className={styles.bold}>Background:</span> Each TIN may report as either a "Group",
                            "Individual", or "Both". Recall each NPI is defined as "MACRA Exempt" on both an individual
                            and group basis. That is, the "MACRA Exempt" criteria are applied at the NPI level (i.e. all
                            cases for an NPI) and to the TIN level (i.e. all cases for a TIN). If a given NPI is deemed
                            "MACRA Exempt" by CMS, they will not receive a penalty or a bonus. Rather, CMS will label
                            them as a "Voluntary Submitter" and while they still will receive a CPS, it will provide no
                            financial adjustment - negative or positive.
                        </p>
                        <p>
                            Each NPI's eligibility status for both Individual and Group reporting may be found at{' '}
                            <a href="https://qpp.cms.gov/participation-lookup" target="_blank">
                                https://qpp.cms.gov/participation-lookup
                            </a>
                        </p>
                        <p className={styles.italic}>Report as an Individual</p>
                        <p>
                            If reporting only as an individual, the NPI's measures and activities for the given TIN will
                            be reported to the QCDR. Payment adjustments will be based on individual NPI's Final CPS
                            derived from the 4 MIPS performance categories.
                        </p>
                        <p className={styles.italic}>Report as a Group</p>
                        <p>
                            If reporting only as a group, all NPIs' measures and activities for the given TIN will be
                            reported to the QCDR. The group’s performance data across the 4 MIPS performance categories
                            for a single TIN will be evaluated in aggregate. Each MIPS-eligible clinician in the group
                            will then receive the same payment adjustment based on the group's performance.
                        </p>
                        <p>
                            It is important to ensure you report quality data for ALL NPIs within a given TIN. For a
                            complete list of all NPIs within your TIN please check your CMS portal at
                            <a href="https://portal.cms.gov" target="_blank">
                                https://portal.cms.gov
                            </a>
                        </p>
                        <p className={styles.italic}>Report as Both an Individual and Group</p>
                        <p>
                            MIPS-eligible clinicians can report data as both an individual and as part of a group under
                            the same TIN. In this instance, the clinician will be evaluated across all 4 MIPS
                            performance categories on their individual performance and on the group’s performance, with
                            a final score calculated for each evaluation. The clinician will receive a payment
                            adjustment based on the higher of the two scores.
                        </p>
                        <p className={styles.bold}>
                            Please Note: By default, selecting the "Group" option will default to the "Both" reporting
                            option as allowed by CMS.
                        </p>
                        <p className={styles.bold}>Instructions:</p>
                        <p>
                            For each TIN, select between "Group Reporting" or "Individual Reporting" options. If
                            "Individual Reporting" option is selected for a given TIN, you must individually indicate
                            which NPIs to register with the QCDR. Any unchecked provider will NOT participate in MIPS
                            reporting via the QCDR and is at risk for receiving a maximum negative payment adjustment.
                        </p>
                    </div>
                </Card>
                <Card label="Step 3: IA Attestations" className={styles.cardWrap}>
                    <div className={styles.contentWrap}>
                        <p>
                            <span className={styles.bold}>Purpose:</span> Identify all applicable Improvement Activities
                            (IA) performed for each TIN for 90 days or more during the reporting period.
                        </p>
                        <p>
                            <span className={styles.bold}>Background:</span> The IA category accounts for 15% of the
                            Final CPS. To earn full credit in this category, participants must attest to one of the
                            following combinations of activities (each activity must be performed for 90 days or more
                            during the reporting period):
                        </p>
                        <ul>
                            <li>2 high-weighted activities</li>
                            <li>1 high-weighted activity and 2 medium-weighted activities</li>
                            <li>At least 4 medium-weighted activities</li>
                        </ul>
                        <p className={styles.bold}>Activities:</p>
                        <ActivitiesContainer year={props.year} />
                        <p className={styles.bold}>Instructions:</p>
                        <p>For each TIN within your Organization, attest to the applicable activities performed.</p>
                        <p>
                            Keep in mind the intent of the Improvement Activities section is to incentivize you to use
                            your QCDR quality data to improve patient care. CMS wants you to not only collect the
                            quality data, but also analyze it and use it to inform your decisions.
                        </p>
                    </div>
                </Card>
            </Flex>
        </>
    );
}

export default Instructions;
