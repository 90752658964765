import { OrgUser } from 'src/stores/OrgUserStore';
import { ReferenceListValue } from 'src/stores/ReferenceListStore';
import { UserStoreObject } from 'src/stores/UserStore';
import { ANALYTICS_NAMES } from './analytics';

export interface Action {
    id: string | number;
    label: string;
    isLocked?: boolean;
    'data-test-id'?: string; // Had to define this here for props from objects (like in `EventView`)
}

export function getUnvoidedFormActionsGroupName(formDefinitionType: string): string {
    return formDefinitionType === 'discrete' ? 'patientVisitsDiscreteForm' : 'patientVisits';
}

export function getActions(entityType: string, entity: any): Action[] {
    const ITEM_ACTIONS: { [key in string]: any[] } = {
        user: [
            {
                id: 'edit',
                label: 'Edit User',
                isLocked: !UserStoreObject.userPermissions.canEdit.users,
                'data-test-id': ANALYTICS_NAMES.Users_Table_Edit,
            },
            {
                id: 'teams',
                label: 'Teams',
                isLocked: !UserStoreObject.userPermissions.canView.userTeams,
                'data-test-id': ANALYTICS_NAMES.Users_Table_Teams,
            },
            {
                id: 'facilities',
                label: 'Facilities',
                isLocked: !UserStoreObject.userPermissions.canView.userFacilities,
                'data-test-id': ANALYTICS_NAMES.Users_Table_Facilities,
            },
            {
                id: 'activate',
                label: 'Activate',
                isLocked: !UserStoreObject.userPermissions.canEdit.users,
                'data-test-id': ANALYTICS_NAMES.Users_Table_Activate,
            },
        ],
        adminUser: [
            {
                id: 'view',
                label: 'View User',
                'data-test-id': ANALYTICS_NAMES.AdminUsers_View_User,
            },
            {
                id: 'activate',
                label: 'Activate',
                'data-test-id': ANALYTICS_NAMES.AdminUsers_Activate_User,
            },
            {
                id: 'checkAuth0',
                label: 'Check Auth0 Status',
            },
        ],
        referenceList: [
            {
                id: 'edit',
                label: 'Edit',
                isLocked: !UserStoreObject.userPermissions.canEdit.referenceLists,
                'data-test-id': ANALYTICS_NAMES.RefLists_Table_Edit,
            },
            {
                id: 'activate',
                label: 'Activate',
                isLocked: !UserStoreObject.userPermissions.canEdit.referenceLists,
                'data-test-id': ANALYTICS_NAMES.RefLists_Table_Activate,
            },
        ],
        referenceListValue: [
            {
                id: 'edit',
                label: 'Edit',
                isLocked: !UserStoreObject.userPermissions.canEdit.referenceListValues,
                'data-test-id': ANALYTICS_NAMES.RefListDetails_Table_Edit,
            },
            {
                id: 'facilities',
                label: 'Exclude Facilities',
                isLocked: !UserStoreObject.userPermissions.canEdit.referenceListValuesExcludeFacilities,
                'data-test-id': ANALYTICS_NAMES.RefListDetails_Table_ExcludeFacilities,
            },
            {
                id: 'activate',
                label: 'Activate',
                isLocked: !UserStoreObject.userPermissions.canEdit.referenceListValues,
                'data-test-id': ANALYTICS_NAMES.RefListDetails_Table_Activate,
            },
        ],
        valueSetDetail: [
            {
                id: 'edit',
                label: 'Edit',
                isLocked: !UserStoreObject.userPermissions.canEdit.valueSetDetails,
                'data-test-id': ANALYTICS_NAMES.ValueSetDetail_Table_Edit,
            },
            {
                id: 'activate',
                label: 'Activate',
                isLocked: !UserStoreObject.userPermissions.canEdit.valueSetDetails,
                'data-test-id': ANALYTICS_NAMES.ValueSetDetail_Table_Activate,
            },
        ],
        need: [
            {
                id: 'edit',
                label: 'Edit',
                isLocked: !UserStoreObject.userPermissions.canEdit.patientNeeds,
                'data-test-id': ANALYTICS_NAMES.PatientDetails_Needs_Table_Edit,
            },
        ],
        medication: [
            {
                id: 'edit',
                label: 'Edit',
                isLocked: !UserStoreObject.userPermissions.canEdit.patientMedications,
                'data-test-id': ANALYTICS_NAMES.PatientDetails_Medications_Table_Edit,
            },
        ],
        patientVisitsVoided: [
            // TODO: Enable this when we enable the PDF renderer to show voided forms
            // {
            //     id: 'details',
            //     label: 'View Form',
            //     'data-test-id': ANALYTICS_NAMES.PatientDetails_Visits_Table_ViewForm,
            // },
            {
                id: 'unvoid',
                label: 'Unvoid Form',
                'data-test-id': ANALYTICS_NAMES.PatientDetails_Visits_Table_UnvoidForm,
            },
        ],
        patientVisitsDiscreteForm: [
            {
                id: 'edit',
                label: 'Edit Form',
                'data-test-id': ANALYTICS_NAMES.PatientDetails_Visits_Table_EditForm,
            },
            {
                id: 'void',
                label: 'Void Form',
                'data-test-id': ANALYTICS_NAMES.PatientDetails_Visits_Table_VoidForm,
            },
        ],
        patientVisits: [
            {
                id: 'details',
                label: 'View Form',
                'data-test-id': ANALYTICS_NAMES.PatientDetails_Visits_Table_ViewForm,
            },
            {
                id: 'edit',
                label: 'Edit Form',
                'data-test-id': ANALYTICS_NAMES.PatientDetails_Visits_Table_EditForm,
            },
            {
                id: 'void',
                label: 'Void Form',
                'data-test-id': ANALYTICS_NAMES.PatientDetails_Visits_Table_VoidForm,
            },
        ],
        viewForm: [
            {
                id: 'edit',
                label: 'Edit Form',
                'data-test-id': ANALYTICS_NAMES.EncounterForm_ViewForm_EditForm,
            },
            {
                id: 'void',
                label: 'Void Form',
                'data-test-id': ANALYTICS_NAMES.EncounterForm_ViewForm_VoidForm,
            },
            {
                id: 'download',
                label: 'Download Form',
                'data-test-id': ANALYTICS_NAMES.EncounterForm_ViewForm_DownloadForm,
            },
        ],
        viewFormVoided: [
            {
                id: 'unvoid',
                label: 'Unvoid Form',
                'data-test-id': ANALYTICS_NAMES.EncounterForm_ViewForm_VoidForm,
            },
            {
                id: 'download',
                label: 'Download Form',
                'data-test-id': ANALYTICS_NAMES.EncounterForm_ViewForm_DownloadForm,
            },
        ],
        importBatches: [
            {
                id: 'assign',
                label: 'Assign',
                'data-test-id': ANALYTICS_NAMES.Import_Assign,
            },
            {
                id: 'ignore',
                label: 'Ignore',
                'data-test-id': ANALYTICS_NAMES.Import_Ignore,
            },
        ],
        paymentRequest: [
            {
                id: 'resend',
                label: 'Resend Request',
                'data-test-id': ANALYTICS_NAMES.PaymentRequest_Resend,
            },
            {
                id: 'collect',
                label: 'Collect Payment',
                'data-test-id': ANALYTICS_NAMES.PaymentRequest_Collect,
            },
            {
                id: 'cancel',
                label: 'Cancel Payment Request',
                'data-test-id': ANALYTICS_NAMES.PaymentRequest_Cancel,
            },
        ],
        payer: [
            {
                id: 'edit',
                label: 'Edit Category',
                'data-test-id': ANALYTICS_NAMES.Payer_Edit,
            },
        ],
        payerCategory: [
            {
                id: 'edit',
                label: 'Edit',
                'data-test-id': ANALYTICS_NAMES.PayerCategory_Edit,
            },
            {
                id: 'activate',
                label: 'Activate',
                'data-test-id': ANALYTICS_NAMES.PayerCategory_Activate,
            },
        ],
        paymentType: [
            {
                id: 'edit',
                label: 'Edit',
                'data-test-id': ANALYTICS_NAMES.PaymentType_Edit,
            },
            {
                id: 'editEmail',
                label: 'Edit Email Text',
                'data-test-id': ANALYTICS_NAMES.PaymentType_EditEmail,
            },
            {
                id: 'editSms',
                label: 'Edit SMS Text',
                'data-test-id': ANALYTICS_NAMES.PaymentType_EditSMS,
            },
            {
                id: 'editLanding',
                label: 'Edit Landing Page Text',
                'data-test-id': ANALYTICS_NAMES.PaymentType_EditLanding,
            },
            {
                id: 'activate',
                label: 'Activate',
                'data-test-id': ANALYTICS_NAMES.PaymentType_Activate,
            },
        ],
        paymentGateway: [
            {
                id: 'edit',
                label: 'Edit',
                'data-test-id': ANALYTICS_NAMES.PaymentGateway_Edit,
            },
            {
                id: 'activate',
                label: 'Activate',
                'data-test-id': ANALYTICS_NAMES.PaymentGateway_Activate,
            },
        ],
        vitalsConnection: [
            {
                id: 'edit',
                label: 'Edit',
                'data-test-id': ANALYTICS_NAMES.VitalsConnection_Edit,
            },
            {
                id: 'activate',
                label: 'Activate',
                'data-test-id': ANALYTICS_NAMES.VitalsConnection_Activate,
            },
        ],
        automatedDispenseSystem: [
            {
                id: 'edit',
                label: 'Edit',
                'data-test-id': ANALYTICS_NAMES.AutomatedDispenseSystem_Edit,
            },
        ],
    };
    const itemActions: Action[] = ITEM_ACTIONS[entityType];

    if (itemActions && itemActions.length) {
        const editedActions: Action[] = itemActions.filter((action) => {
            if (action.id === 'activate') {
                const activeIndex = entity.actvInd || entity.isActive || entity.active;
                action.label = activeIndex ? 'Deactivate' : 'Reactivate';
            }
            return action;
        });
        return editedActions;
    }

    return [];
}
