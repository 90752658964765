import { VitalsConnection } from 'common/lib/entity/org/VitalsConnection';
import { VitalsMeasure } from 'common/lib/entity/framework/VitalsMeasure';
import { VitalsMeasureCategory } from 'common/lib/entity/framework/VitalsMeasureCategory';
import { action, observable } from 'mobx';
import { createContext } from 'react';
import * as vitalsApi from '../api/vitals';

class VitalsStore {
    @observable vitalsConnections: VitalsConnection[] = [];
    @observable currentVitalsMeasureCategoryId: VitalsMeasure['measureCategoryId'] = 0;
    @observable vitalsMeasures: VitalsMeasure[] = [];
    @observable vitalsMeasureCategories: VitalsMeasureCategory[] = [];

    async getVitalsConnections(
        options: { includeInactive: boolean; query?: string; facilityId?: number },
        force = false,
    ) {
        if (!force && this.vitalsConnections.length > 0) {
            return;
        }

        const connections = (await vitalsApi.getVitalsConnections(options)) || {};
        this.setVitalsConnections(connections || []);
    }

    async createVitalsConnection(vitalsConnection: Partial<VitalsConnection>) {
        const newVitalsConnection = await vitalsApi.createVitalsConnection(vitalsConnection);

        return newVitalsConnection;
    }

    async updateVitalsConnection(
        connectionId: VitalsConnection['connectionId'],
        vitalsConnection: Partial<VitalsConnection>,
    ) {
        return await vitalsApi.updateVitalsConnection(connectionId, vitalsConnection);
    }

    @action
    setVitalsConnections(vitalsConnections: VitalsConnection[]) {
        this.vitalsConnections = vitalsConnections;
    }

    async deactivateVitalsConnection(connectionId: VitalsConnection['connectionId']) {
        await vitalsApi.deactivateVitalsConnection(connectionId);
    }

    async reactivateVitalsConnection(connectionId: VitalsConnection['connectionId']) {
        await vitalsApi.reactivateVitalsConnection(connectionId);
    }

    async getVitalsMeasures(measureCategoryId?: VitalsMeasure['measureCategoryId'], force = false) {
        if (!force && this.vitalsMeasures.length > 0 && measureCategoryId === this.currentVitalsMeasureCategoryId) {
            return;
        }

        const measures = await vitalsApi.getVitalsMeasures(measureCategoryId);
        this.setVitalsMeasures(measureCategoryId, measures || []);
    }

    @action
    setVitalsMeasures(measureCategoryId: VitalsMeasure['measureCategoryId'], measures: VitalsMeasure[]) {
        this.currentVitalsMeasureCategoryId = measureCategoryId;
        this.vitalsMeasures = measures;
    }

    async getVitalsMeasureCategories(force = false) {
        if (!force && this.vitalsMeasureCategories.length > 0) {
            return;
        }

        const measureCategories = await vitalsApi.getVitalsMeasureCategories();
        this.setVitalsMeasureCategories(measureCategories || []);
    }

    @action
    setVitalsMeasureCategories(measureCategories: VitalsMeasureCategory[]) {
        this.vitalsMeasureCategories = measureCategories;
    }

    async updateVitalsConnectionLatest(
        connectionId: VitalsConnection['connectionId'],
        unitIdentifier: VitalsConnection['unitIdentifier'],
    ) {
        const latest = await vitalsApi.updateVitalsConnectionLatest(connectionId, unitIdentifier);
        return latest;
    }
}

export const VitalsStoreObject = new VitalsStore();
export default createContext(VitalsStoreObject);
