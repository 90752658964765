import * as ROUTES from 'src/api/routes';
import request from 'src/api/utils';
import * as utils from 'src/utils';
import { Patient } from 'src/stores/PatientStore';
import { PatientNeed } from 'src/stores/PatientNeedStore';
import { PatientMedication } from 'src/stores/PatientMedicationStore';
import { PatientNote, PatientAttachment } from 'src/stores/PatientNoteAttachmentStore';
import { FilterQueryParams } from 'src/utils/types';

export async function getPatients(params: FilterQueryParams = {}): Promise<{ result: any[]; count: number }> {
    const response = await request({ ...ROUTES.GET_PATIENTS, params });
    return { result: response.data.result || [], count: response.data.count || 0 };
}

export async function getPatient(patientId: number) {
    const patient = await request({ ...ROUTES.GET_PATIENT, pathParams: { patientId } });
    return patient;
}

export async function createPatient(data: Patient) {
    return await request({ ...ROUTES.CREATE_PATIENT, data });
}

export async function updatePatient(patientId: Patient['patientId'], data: Patient) {
    return await request({ ...ROUTES.UPDATE_PATIENT, pathParams: { patientId }, data });
}

export async function getPatientNeeds(patientId: number, params: {} = {}) {
    const needs = await request({ ...ROUTES.GET_PATIENT_NEEDS, pathParams: { patientId }, params });
    return needs;
}

export async function getPatientMedications(patientId: number, params: {} = {}) {
    const needs = await request({ ...ROUTES.GET_PATIENT_MEDICATIONS, pathParams: { patientId }, params });
    return needs;
}

export async function createPatientNeed(patient: Patient, data: PatientNeed) {
    const need = await request({
        ...ROUTES.CREATE_PATIENT_NEED,
        pathParams: { patientId: patient.patientId },
        data: { ...data, facilityId: patient.facId },
    });
    return need;
}

export async function createPatientMedication(patient: Patient, data: PatientMedication) {
    return await request({
        ...ROUTES.CREATE_PATIENT_MEDICATION,
        pathParams: { patientId: patient.patientId },
        data: { ...data, facilityId: patient.facId },
    });
}

export async function getPatientNotes(patientId: number) {
    return await request({ ...ROUTES.GET_PATIENT_NOTES, pathParams: { patientId } });
}

export async function createPatientNote(patient: Patient, data: PatientNote) {
    return await request({
        ...ROUTES.CREATE_PATIENT_NOTE,
        pathParams: { patientId: patient.patientId },
        data: { ...data, facilityId: patient.facId },
    });
}

export async function getPatientAttachments(patientId: number) {
    return await request({ ...ROUTES.GET_PATIENT_ATTACHMENTS, pathParams: { patientId } });
}

export async function createPatientAttachment(patient: Patient, data: PatientAttachment) {
    const attachment = await request({
        ...ROUTES.CREATE_PATIENT_ATTACHMENT,
        pathParams: { patientId: patient.patientId },
        data: { ...data, facilityId: patient.facId },
    });
    return attachment;
}

export async function updatePatientAttachment(patient: Patient, data: PatientAttachment) {
    const attachment = await request({
        ...ROUTES.UPDATE_PATIENT_ATTACHMENT,
        pathParams: { patientId: patient.patientId, patientAttachmentId: data.patientAttachmentId },
        data: { ...data, facilityId: patient.facId },
    });
    return attachment;
}

export async function uploadPatientAttachment(
    params: { patient: Patient; data: PatientAttachment; file: any },
    isUpdate: boolean = false,
) {
    if (params.file) {
        // get signed url
        const response = await request({
            ...ROUTES.GET_SIGNED_URL_FOR_ATTACHMENT,
            pathParams: { patientId: params.patient.patientId },
            params: { fileName: params.data.patientAttachmentFilename, fileType: params.data.patientAttachmentType },
        });
        const url = response.data;
        const form = new FormData();
        // // Append all the fields from the server into the FormData
        for (const field in url.fields) {
            form.append(field, url.fields[field]);
        }

        // Always append the file last
        form.append('file', params.file);

        const options = {
            method: 'POST',
            body: form,
        };
        await fetch(url.url, options);
        params.data.patientAttachmentFilename = url.fields.key;
    }
    if (isUpdate) {
        return await updatePatientAttachment(params.patient, params.data);
    }
    return await createPatientAttachment(params.patient, params.data);
}

export async function getAttachmentViewUrl(patientAttachment: PatientAttachment) {
    return await request({
        ...ROUTES.GET_ATTACHMENT_VIEW_URL,
        pathParams: { patientId: patientAttachment.patientId },
        params: {
            fileName: patientAttachment.patientAttachmentFilename,
            patientAttachmentId: patientAttachment.patientAttachmentId,
        },
    });
}

export async function fetchNeedStates() {
    return await request(ROUTES.GET_NEED_STATES);
}

export async function fetchPatientStates() {
    return await request(ROUTES.GET_PATIENT_STATES);
}

export async function updatePatientNeed(patient: Patient, patientNeedId: number, data: PatientNeed) {
    const needs = await request({
        ...ROUTES.UPDATE_PATIENT_NEED,
        pathParams: { patientId: patient.patientId, patientNeedId },
        data: { ...data, facilityId: patient.facId },
    });
    return needs;
}

export async function updatePatientNote(patient: Patient, data: PatientNote) {
    const note = await request({
        ...ROUTES.UPDATE_PATIENT_NOTE,
        pathParams: { patientId: patient.patientId, patientNoteId: data.patientNoteId },
        data: { ...data, facilityId: patient.facId },
    });
    return note;
}

export async function deletePatientNote(params: {
    patientId: Patient['patientId'];
    facilityId: Patient['facId'];
    item: PatientNote;
}) {
    const note = await request({
        ...ROUTES.DELETE_PATIENT_NOTE,
        pathParams: { patientId: params.patientId, patientNoteId: params.item.patientNoteId },
        params: { facilityId: params.facilityId },
    });
    return note;
}

export async function updatePatientMedication(patient: Patient, patientMedicationId: number, data: PatientMedication) {
    const medication = await request({
        ...ROUTES.UPDATE_PATIENT_MEDICATION,
        pathParams: { patientId: patient.patientId, patientMedicationId },
        data: { ...data, facilityId: patient.facId },
    });
    return medication;
}

export async function getPatientActivity(patientId: Patient['patientId']) {
    const activity = await request({ ...ROUTES.GET_PATIENT_ACTIVITY, pathParams: { patientId } });
    return activity;
}

export async function getPatientLabs(patientId: Patient['patientId'], facilityId: Patient['facId']) {
    const labs = await request({ ...ROUTES.GET_PATIENT_LABS, pathParams: { patientId }, params: { facilityId } });
    return labs;
}

export async function getPatientVitals(patientId: Patient['patientId'], facilityId: Patient['facId']) {
    const vitals = await request({ ...ROUTES.GET_PATIENT_VITALS, pathParams: { patientId }, params: { facilityId } });
    return vitals;
}

export async function getPatientNeedStates() {
    const needStates = await request({ ...ROUTES.GET_PATIENT_NEED_STATES });
    return needStates;
}

export async function deletePatientAttachment(params: {
    patientId: Patient['patientId'];
    facilityId: Patient['facId'];
    item: PatientAttachment;
}) {
    const needStates = await request({
        ...ROUTES.DELETE_PATIENT_ATTACHMENT,
        pathParams: { patientId: params.patientId, patientAttachmentId: params.item.patientAttachmentId },
        params: { facilityId: params.facilityId },
    });
    return needStates;
}
