// Dependencies
import React, { useContext, useEffect } from 'react';
import { Switch, Route, Redirect, useHistory } from 'react-router-dom';
import { withAuth0 } from '@auth0/auth0-react';
import { observer } from 'mobx-react';
import { parse } from 'query-string';
// Utilities
import { useStartupActions } from 'src/utils/startup';
import { ROUTES } from 'src/utils/constants';
import { validateRoute } from 'src/utils/roleValidation';
// Generic components
import ErrorProvider from 'src/components/ErrorProvider';
import Flex from 'src/components/Flex';
import Helmet from 'src/components/Helmet';
import IdleModal from 'src/components/IdleModal';
import LoadingIcon from 'src/components/LoadingIcon';
import StartupLoading from 'src/components/StartupLoading';
import Toast from 'src/components/Toast';
// Data stores
import { ModalStoreObject } from 'src/stores/ModalStore';
import AuthStore from 'src/stores/AuthStore';
import FeatureFlagStore from 'src/stores/FeatureFlagStore';
import UserStore, { OrgType } from 'src/stores/UserStore';
// Containers
import AdminFlowScriptTemplates from 'src/containers/AdminFlowScriptTemplates';
import AdminFlowScriptTemplateDetail from 'src/containers/AdminFlowScriptTemplateDetail';
import AdminImportBatchTemplates from 'src/containers/AdminImportBatchTemplates';
import AdminSurveyTemplates from 'src/containers/AdminSurveyTemplates';
import AdminUsers from 'src/containers/AdminUsers';
import Appointments from 'src/containers/Appointments';
import AutomatedDispenseSystems from './containers/AutomatedDispenseSystems';
import Customers from 'src/containers/Customers';
import CustomerDetail from 'src/containers/Customers/Detail';
import Deployment from 'src/containers/FormDevelopment/FormDetail/Deployment';
import Encounters from 'src/containers/Encounters';
import Facilities from 'src/containers/Facilities';
import ForgotPassword from 'src/containers/ForgotPassword';
import FormDevelopment from 'src/containers/FormDevelopment';
import FormDetail from 'src/containers/FormDevelopment/FormDetail';
import Forms from 'src/containers/Forms';
import Import from 'src/containers/Import';
import ImportDetail from 'src/containers/Import/Detail';
import InviteAccept from 'src/containers/InviteAccept';
import Login from 'src/containers/Login';
import ModalHandler from 'src/containers/ModalHandler';
import MyProfile from 'src/containers/MyProfile';
import PasswordReset from 'src/containers/PasswordReset';
import Patients from 'src/containers/Patients';
import PatientDetails from 'src/containers/PatientDetails';
import PatientSurvey from 'src/containers/PatientSurvey';
import PatientSurveyUnsubscribe from 'src/containers/PatientSurveyUnsubscribe';
import PatientVideo from 'src/containers/PatientVideo';
import PayerCategories from 'src/containers/PayerCategories';
import Payers from 'src/containers/Payers';
import PaymentCollection from 'src/containers/PaymentCollection';
import PaymentCollectionStatus from 'src/containers/PaymentCollection/Status';
import PaymentRequestContactUnsubscribe from 'src/containers/PaymentCollection/Unsubscribe';
import PaymentGateways from 'src/containers/PaymentGateways';
import PaymentRequestImport from 'src/containers/PaymentRequests/Import';
import PaymentRequestDetail from 'src/containers/PaymentRequests/Detail';
import PaymentRequests from 'src/containers/PaymentRequests';
import PaymentTypes from 'src/containers/PaymentTypes';
import PrivateRoute from 'src/containers/PrivateRoute';
import Providers from 'src/containers/Providers';
import QcdrSettings2023 from 'src/containers/QcdrSettings/2023';
import QcdrSettings2024 from 'src/containers/QcdrSettings/2024';
import ReferenceList from 'src/containers/ReferenceList';
import ReferenceLists from 'src/containers/ReferenceLists';
import ReportsContainer from 'src/containers/ReportsContainer';
import SettingsDevices from 'src/containers/SettingsNemsis/Devices';
import SettingsNemsis from 'src/containers/SettingsNemsis';
import SettingsTransportFacilities from 'src/containers/SettingsNemsis/TransportFacilities';
import SettingsVehicles from 'src/containers/SettingsNemsis/Vehicles';
import SurveyDefinitions from 'src/containers/SurveyDefinitions';
import SurveyResults from 'src/containers/SurveyResults';
import SurveyDefinitionDetails from 'src/containers/SurveyDefinitionDetails';
import Tags from 'src/containers/Tags';
import Teams from 'src/containers/Teams';
import TelehealthSession from 'src/containers/TelehealthSession';
import TelehealthWaitingRoom from 'src/containers/TelehealthWaitingRoom';
import TelehealthVisits from 'src/containers/TelehealthVisits';
import UsersAndInvitations from 'src/containers/UsersAndInvitations';
import ValueSet from 'src/containers/ValueSet';
import ViewEncounterForm from 'src/containers/ViewEncounterForm';
import VitalsConnections from 'src/containers/VitalsConnections';

declare const pendo: any; // Pendo is loaded in index.html

interface AppProps {
    auth0: any;
}

function App(props: AppProps) {
    const authStore = useContext(AuthStore);
    const userStore = useContext(UserStore);
    const flagStore = useContext(FeatureFlagStore);
    const history = useHistory();

    useStartupActions();

    const handleLogout = () => {
        ModalStoreObject.hideModal();
        authStore.logout();
    };

    const handleIdleReset = () => {
        authStore.restartIdleTimeout();
    };

    const updatePendoLocation = (location: any) => {
        const url = `${window.location.origin}${location.pathname}`;
        if (pendo && pendo.location && pendo.location.setUrl) {
            pendo.location.setUrl(url);
        }
    };

    useEffect(() => {
        if (userStore.orgEmailAddr !== '') flagStore.loadFlags();
    }, [userStore.orgEmailAddr]);

    useEffect(() => {
        if (props.auth0.isAuthenticated && !authStore.isAuthenticated) {
            authStore.handleAuthentication(props.auth0);
        }
    }, [props.auth0.isAuthenticated]);

    useEffect(() => {
        history.listen((location) => updatePendoLocation(location));
    }, []);

    if (props.auth0.isLoading) {
        return (
            <Flex justify="center">
                <LoadingIcon />
            </Flex>
        );
    }

    return (
        <>
            <Toast />
            <IdleModal isOpen={authStore.isIdle} onLogout={handleLogout} onDismiss={handleIdleReset} />
            <ErrorProvider>
                <StartupLoading>
                    <Switch>
                        {/* Authenticated/private routes */}
                        <PrivateRoute path={ROUTES.AdminAllUsers} component={AdminUsers} />
                        <PrivateRoute
                            path={ROUTES.AdminFlowScriptTemplateDetail}
                            component={AdminFlowScriptTemplateDetail}
                        />
                        <PrivateRoute path={ROUTES.AdminFlowScriptTemplates} component={AdminFlowScriptTemplates} />
                        <PrivateRoute path={ROUTES.AdminImportBatchTemplates} component={AdminImportBatchTemplates} />
                        <PrivateRoute path={ROUTES.AdminSurveyTemplates} component={AdminSurveyTemplates} />
                        <PrivateRoute path={ROUTES.Appointments} component={Appointments} />
                        <PrivateRoute path={ROUTES.AutomatedDispenseSystems} component={AutomatedDispenseSystems} />
                        <PrivateRoute path={ROUTES.CustomerDetail} component={CustomerDetail} />
                        <PrivateRoute path={ROUTES.Customers} component={Customers} />
                        <PrivateRoute path={ROUTES.Encounters} component={Encounters} />
                        <PrivateRoute path={ROUTES.ViewEncounterForm} component={ViewEncounterForm} />
                        <PrivateRoute path={ROUTES.FormDevelopmentDeploymentDetail} component={Deployment} />
                        <PrivateRoute path={ROUTES.FormDevelopmentFormDetail} component={FormDetail} />
                        <PrivateRoute path={ROUTES.FormDevelopment} component={FormDevelopment} />
                        <PrivateRoute path={ROUTES.Forms} component={Forms} />
                        <PrivateRoute path={ROUTES.ImportDetail} component={ImportDetail} />
                        <PrivateRoute path={ROUTES.Import} component={Import} />
                        <PrivateRoute path={ROUTES.Patients} component={Patients} />
                        <PrivateRoute path={ROUTES.PatientDetails} component={PatientDetails} />
                        <PrivateRoute path={ROUTES.PayerCategories} component={PayerCategories} />
                        <PrivateRoute path={ROUTES.Payers} component={Payers} />
                        <PrivateRoute path={ROUTES.PaymentGateways} component={PaymentGateways} />
                        <PrivateRoute path={ROUTES.PaymentRequestImport} component={PaymentRequestImport} />
                        <PrivateRoute path={ROUTES.PaymentRequestDetail} component={PaymentRequestDetail} />
                        <PrivateRoute path={ROUTES.PaymentRequests} component={PaymentRequests} />
                        <PrivateRoute path={ROUTES.PaymentTypes} component={PaymentTypes} />
                        <PrivateRoute path={ROUTES.Profile} component={MyProfile} />
                        <PrivateRoute path={ROUTES.QcdrSettings2023} component={QcdrSettings2023} />
                        <PrivateRoute path={ROUTES.QcdrSettings} component={QcdrSettings2024} />
                        <PrivateRoute path={ROUTES.ReportsChp} component={ReportsContainer} />
                        <PrivateRoute path={ROUTES.ReportsMacra} component={ReportsContainer} />
                        <PrivateRoute path={ROUTES.ReportsMacra2023} component={ReportsContainer} />
                        <PrivateRoute path={ROUTES.SettingsDevices} component={SettingsDevices} />
                        <PrivateRoute path={ROUTES.SettingsFacilities} component={Facilities} />
                        <PrivateRoute path={ROUTES.SettingsNemsis} component={SettingsNemsis} />
                        <PrivateRoute path={ROUTES.SettingsProviders} component={Providers} />
                        <PrivateRoute path={ROUTES.SettingsRefListDetails} component={ReferenceList} />
                        <PrivateRoute path={ROUTES.SettingsRefLists} component={ReferenceLists} />
                        <PrivateRoute path={ROUTES.SettingsTags} component={Tags} />
                        <PrivateRoute path={ROUTES.SettingsTeams} component={Teams} />
                        <PrivateRoute
                            path={ROUTES.SettingsTransportFacilities}
                            component={SettingsTransportFacilities}
                        />
                        <PrivateRoute path={ROUTES.SettingsUsers} component={UsersAndInvitations} />
                        <PrivateRoute path={ROUTES.SettingsValueSetDetails} component={ValueSet} />
                        <PrivateRoute path={ROUTES.SettingsVehicles} component={SettingsVehicles} />
                        <PrivateRoute path={ROUTES.TelehealthSession} component={TelehealthSession} />
                        <PrivateRoute path={ROUTES.TelehealthVisits} component={TelehealthVisits} />
                        <PrivateRoute path={ROUTES.TelehealthWaitingRoom} component={TelehealthWaitingRoom} />
                        <PrivateRoute path={ROUTES.VitalsConnections} component={VitalsConnections} />
                        <PrivateRoute path={ROUTES.SurveyDefinitionDetails} component={SurveyDefinitionDetails} />
                        <PrivateRoute path={ROUTES.SurveyDefinitions} component={SurveyDefinitions} />
                        <PrivateRoute path={ROUTES.Surveys} component={SurveyResults} />
                        {/* Unauthenticated routes */}
                        <Route path={ROUTES.ForgotPassword} component={ForgotPassword} />
                        <Route path={ROUTES.Health} render={() => <div>The app is healthy!</div>} />
                        <Route path={ROUTES.Invitation} component={InviteAccept} />
                        <Route path={ROUTES.Login} component={Login} />
                        <Route path={ROUTES.PatientVideo} component={PatientVideo} />
                        <Route path={ROUTES.PatientSurveyUnsubscribe} component={PatientSurveyUnsubscribe} />
                        <Route path={ROUTES.PatientSurvey} component={PatientSurvey} />
                        <Route
                            path={ROUTES.PaymentRequestContactUnsubscribe}
                            component={PaymentRequestContactUnsubscribe}
                        />
                        <Route path={ROUTES.PaymentCollectionStatus} component={PaymentCollectionStatus} />
                        <Route path={ROUTES.PaymentCollection} component={PaymentCollection} />
                        <Route path={ROUTES.Reset} component={PasswordReset} />
                        {/* This will handle any not found urls */}
                        <Route
                            path={ROUTES.Home}
                            render={(routeProps: any) => {
                                const location = routeProps.location;
                                const queryStrings = parse(location.search);
                                const inviteKey = queryStrings.inviteKey;
                                const resetPasswordKey = queryStrings.resetPasswordKey;
                                if (inviteKey) {
                                    return <Redirect to={`${ROUTES.Invitation}${location.search}`} />;
                                }
                                if (resetPasswordKey) {
                                    return <Redirect to={`${ROUTES.Reset}${location.search}`} />;
                                }
                                if (queryStrings.login_redirect) {
                                    return <Redirect to={queryStrings.login_redirect as string} />;
                                }
                                // If the user does not have access to view the patients page, send them to their profile
                                if (userStore.selectedOrgType === OrgType.GRAPHIUM_HEALTH) {
                                    if (!validateRoute(ROUTES.ReportsMacra)) {
                                        return <Redirect to={ROUTES.getString(ROUTES.Profile)} />;
                                    }
                                    return <Redirect to={ROUTES.getString(ROUTES.ReportsMacra)} />;
                                }

                                if (!validateRoute(ROUTES.Patients)) {
                                    return <Redirect to={ROUTES.getString(ROUTES.Profile)} />;
                                }

                                return <Redirect to={ROUTES.getString(ROUTES.Patients)} />;
                            }}
                        />
                    </Switch>
                </StartupLoading>
            </ErrorProvider>
            <Helmet />
            <ModalHandler />
        </>
    );
}

export default withAuth0(observer(App));
