import React, { ChangeEvent, useContext } from 'react';
import { QcdrSettings, ActivitySettings } from 'common/lib/model/macra/QcdrSettings';
import QcdrSettingsStore, { Activity, ACTIVITIES_2023, ACTIVITIES_2024 } from 'src/stores/QcdrSettingsStore';
import Flex from '../Flex';
import styles from './styles.module.scss';
import Card from '../Card';
import { Label } from '../Input';
import { MacraAvailableYears } from 'common/lib/model/macra/Macra';
import classNames from 'classnames';

interface Props {
    year: MacraAvailableYears;
    settings: QcdrSettings['activitySettings'];
    onSettingChange: (setting: ActivitySettings) => void;
}

function ActivitySettingsStep(props: Props) {
    const qcdrSettingStore = useContext(QcdrSettingsStore);

    function handleChange(e: ChangeEvent<HTMLInputElement>, setting: ActivitySettings) {
        const name = e.target.name.split(':')[0];
        props.onSettingChange({
            ...setting,
            [name]: e.target.value === 'Y' ? true : false,
        });
    }

    function getActivities(setting: any) {
        const activities = props.year === 2023 ? ACTIVITIES_2023 : ACTIVITIES_2024;

        return activities.map((activity: Activity, index: number) => {
            const activityValue = setting[`activity${index + 1}`];
            return (
                <Flex direction="row" className={styles.rowWrap} key={index}>
                    <Flex className={styles.yesNo}>
                        <input
                            data-test-id="ActivityYesRadio"
                            type="radio"
                            title="YES"
                            name={`activity${index + 1}:${setting.tin}`}
                            onChange={(e) => handleChange(e, setting)}
                            value="Y"
                            checked={
                                setting.hasOwnProperty(`activity${index + 1}`) && activityValue !== null
                                    ? activityValue
                                    : false
                            }
                        />
                    </Flex>
                    <Flex className={styles.yesNo}>
                        <input
                            data-test-id="ActivityNoRadio"
                            type="radio"
                            title="NO"
                            name={`activity${index + 1}:${setting.tin}`}
                            onChange={(e) => handleChange(e, setting)}
                            value="N"
                            checked={
                                setting.hasOwnProperty(`activity${index + 1}`) && activityValue !== null
                                    ? !activityValue
                                    : false
                            }
                        />
                    </Flex>
                    <Flex>
                        <div
                            className={classNames(styles.weighting, {
                                [styles.high]: activity.weighting.toLowerCase() === 'high',
                                [styles.medium]: activity.weighting.toLowerCase() === 'medium',
                            })}
                        >
                            {activity.weighting.toUpperCase()}
                        </div>
                    </Flex>
                    <Flex value={1}>
                        <span>{activity.title}</span>
                    </Flex>
                </Flex>
            );
        });
    }

    return (
        <div>
            <h3>STEP 3: IA ATTESTATIONS</h3>
            <Card className={styles.attestationsDescription}>
                <p>
                    The IA category accounts for 15% of the Final CPS. To earn full credit in this category,
                    participants must attest to one of the following combinations of activities (each activity must be
                    performed for 90 days or more during the reporting period):
                </p>
                <Flex className={styles.activitiesBanner}>
                    <div>2 high-weighted activities</div>
                    <div>1 high-weighted activity and 2 medium-weighted activities</div>
                    <div>At least 4 medium-weighted activities</div>
                </Flex>
            </Card>
            {props.settings.map((setting, index) => {
                const tinSettingForThisReportingSetting = qcdrSettingStore.tinSettings.find(
                    (tinSetting) => tinSetting.groupTin === setting.tin,
                );

                if (tinSettingForThisReportingSetting?.submitForFacility) {
                    return (
                        <Card label={`TIN: ${setting.tin}`} key={setting.tin} className={styles.cardWrap}>
                            <Flex direction="column" className={styles.contentWrap}>
                                <Flex direction="row">
                                    <Flex className={styles.yesNo}>
                                        <Label text="Yes" />
                                    </Flex>
                                    <Flex className={styles.yesNo}>
                                        <Label text="No" />
                                    </Flex>
                                    <Flex value={1}></Flex>
                                </Flex>
                                {getActivities(setting)}
                            </Flex>
                        </Card>
                    );
                }
            })}
        </div>
    );
}

export default ActivitySettingsStep;
