import React, { useEffect, useState } from 'react';
import { ToastStoreObject, ToastType } from 'src/stores/ToastStore';
import { ANALYTICS_NAMES } from 'src/utils/analytics';
import Input from '../Input';
import ModalFooter from '../ModalFooter';
import ModalHeader from '../ModalHeader';
import CenterModal from '../CenterModal';
import { FormCatalog } from 'common/lib/entity/framework/FormCatalog';
import { useSaveFormCatalogAsVersion } from 'src/queries/useSaveFormCatalogAsVersion';
import styles from './styles.module.scss';

interface FormCatalogVersionModalProps {
    formCatalogId: FormCatalog['formCatalogId'];
    onClose?: Function;
    onSave?: Function;
}

function FormCatalogVersionModal(props: FormCatalogVersionModalProps) {
    const { formCatalogId, onClose, onSave } = props;
    const [formVersionName, setFormVersionName] = useState('');
    const [formVersionDescription, setFormVersionDescription] = useState('');
    const { mutate: saveFormCatalogAsVersion, isLoading, isSuccess, isError, error } = useSaveFormCatalogAsVersion(
        formCatalogId,
    );

    useEffect(() => {
        if (isSuccess) {
            ToastStoreObject.show('Form catalog version created successfully', ToastType.Success);
            onClose();
            onSave();
        }

        if (isError) {
            // @ts-ignore
            const errorMessage = error?.response?.data?.message || 'Failed to create form catalog version';
            ToastStoreObject.show(errorMessage, ToastType.Error);
        }
    }, [isSuccess, isError]);

    async function handlePublish() {
        saveFormCatalogAsVersion({
            formVersionName,
            formVersionDescription,
        });
    }

    return (
        <CenterModal isOpen={true} onClose={onClose}>
            <ModalHeader title="Publish Form Catalog as a Version" onClose={onClose} />
            <div className={styles.contentWrap}>
                <Input
                    label="Form Version Name"
                    value={formVersionName}
                    onChangeText={(v: string) => setFormVersionName(v)}
                    data-test-id={ANALYTICS_NAMES.FormCatalogVersionModal_FormVersionName}
                />
                <Input
                    label="Form Version Description"
                    value={formVersionDescription}
                    onChangeText={(v: string) => setFormVersionDescription(v)}
                    data-test-id={ANALYTICS_NAMES.FormCatalogVersionModal_FormVersionDescription}
                />
            </div>
            <ModalFooter
                primaryText="Publish"
                primaryClick={handlePublish}
                secondaryClick={() => onClose()}
                isLoading={isLoading}
            />
        </CenterModal>
    );
}

export default FormCatalogVersionModal;
