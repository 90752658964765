import { action, observable, makeObservable } from 'mobx';
import { createContext } from 'react';
import * as patientsAPI from 'src/api/patients';
import { Patient } from './PatientStore';

export interface PatientNote {
    patientNoteId?: number;
    patientId?: number;
    patientNoteType?: string;
    patientNoteText?: string;
    patientNoteDate?: string;
    patientNoteTime?: string;
    updDttm?: Date | string;
    insDttm?: Date | string;
}
export interface PatientAttachment {
    patientAttachmentId?: number;
    patientId?: number;
    patientAttachmentType?: string;
    patientAttachmentFilename?: string;
    patientAttachmentDescription?: string;
    insDttm?: Date | string;
    updDttm?: Date | string;
}

class PatientNoteAttachmentStore {
    @observable patientNotes: PatientNote[] = [];
    @observable patientAttachments: PatientAttachment[] = [];

    constructor() {
        makeObservable(this);
    }

    @action
    reset() {
        this.patientNotes = [];
        this.patientAttachments = [];
    }

    @action
    async getPatientNotes(patientId: number) {
        const notes = await patientsAPI.getPatientNotes(patientId);
        this.setPatientNotes(notes.data || []);
    }

    @action
    async getPatientAttachments(patientId: number) {
        const attachments = await patientsAPI.getPatientAttachments(patientId);
        this.setPatientAttachments(attachments.data || []);
    }

    @action
    setPatientAttachments(attachments: PatientAttachment[]) {
        this.patientAttachments = attachments;
    }

    @action
    setPatientNotes(notes: PatientNote[]) {
        this.patientNotes = notes;
    }

    @action
    async createPatientNote(patient: Patient, patientNote: PatientNote) {
        const newNote = await patientsAPI.createPatientNote(patient, patientNote);
        const newNotes = [...this.patientNotes, newNote.data];
        this.setPatientNotes(newNotes);
    }

    @action
    async updatePatientNote(patient: Patient, patientNote: PatientNote) {
        const response = await patientsAPI.updatePatientNote(patient, patientNote);
        const updatedNote = response.data;
        const newNotes = this.patientNotes.map((n: PatientNote) =>
            n.patientNoteId === updatedNote.patientNoteId ? updatedNote : n,
        );
        this.setPatientNotes(newNotes);
    }

    @action
    async deletePatientNote(params: {
        patientId: Patient['patientId'];
        facilityId: Patient['facId'];
        item: PatientNote;
    }) {
        const response = await patientsAPI.deletePatientNote(params);
        const updatedNote = response.data;
        const newNotes = this.patientNotes.filter((n: PatientNote) => n.patientNoteId !== updatedNote.patientNoteId);
        this.setPatientNotes(newNotes);
    }

    @action
    async createPatientAttachment(patient: Patient, patientAttachment: PatientAttachment, file: any) {
        const newAttachment = await patientsAPI.uploadPatientAttachment({ patient, data: patientAttachment, file });
        const newAttachments = [...this.patientAttachments, newAttachment.data];
        this.setPatientAttachments(newAttachments);
    }

    @action
    async updatePatientAttachment(patient: Patient, patientAttachment: PatientAttachment, file: any) {
        const response = await patientsAPI.uploadPatientAttachment({ patient, data: patientAttachment, file }, true);
        const updatedAttachment = response.data;
        const newAttachments = this.patientAttachments.map((a: PatientAttachment) =>
            a.patientAttachmentId === updatedAttachment.patientAttachmentId ? updatedAttachment : a,
        );
        this.setPatientAttachments(newAttachments);
    }

    @action
    async deletePatientAttachment(params: {
        patientId: Patient['patientId'];
        facilityId: Patient['facId'];
        item: PatientAttachment;
    }) {
        const response = await patientsAPI.deletePatientAttachment(params);
        const updatedAttachment = response.data;
        const newAttachments = this.patientAttachments.filter(
            (a: PatientAttachment) => a.patientAttachmentId !== updatedAttachment.patientAttachmentId,
        );
        this.setPatientAttachments(newAttachments);
    }

    @action
    async getAttachmentViewUrl(patientAttachment: PatientAttachment) {
        return await patientsAPI.getAttachmentViewUrl(patientAttachment);
    }
}

// Import this to any other store that needs to use a value from it
export const PatientNoteAttachmentStoreObject = new PatientNoteAttachmentStore();

export default createContext(PatientNoteAttachmentStoreObject);
