import React, { ReactNode } from 'react';
import ReactDOM from 'react-dom';

export interface PortalProps {
    node?: Element;
    children: ReactNode;
}

class Portal extends React.Component<PortalProps> {
    defaultNode: Element = null;

    componentWillUnmount() {
        if (this.defaultNode) {
            document.body.removeChild(this.defaultNode);
        }
        this.defaultNode = null;
    }

    render() {
        if (!this.props.node && !this.defaultNode) {
            this.defaultNode = document.createElement('div');
            document.body.appendChild(this.defaultNode);
        }
        return ReactDOM.createPortal(this.props.children, this.props.node || this.defaultNode) as JSX.Element;
    }
}

export default Portal;
