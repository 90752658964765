import React, { useState, useEffect, useContext } from 'react';
import NavBar from 'src/containers/NavBar';
import PageContent from 'src/components/PageContent';
import Button from 'src/components/Button';
import PageContainer from 'src/components/PageContainer';
import Flex from 'src/components/Flex';
import styles from './styles.module.scss';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import FilterStore from 'src/stores/FilterStore';
import * as encountersApi from 'src/api/encounters';
import * as utils from 'src/utils';
import Pagination from 'src/components/Table/Pagination';
import FilteredSearch, { FilteredSearchQuery, SubFilters, FilterOptionsItem } from 'src/components/FilteredSearch';
import useIsMount from 'src/utils/hooks/useIsMount';
import SubNav from '../SubNav';
import { FilterQueryParams, EncounterFormRaw } from 'src/utils/types';
import FormStore, { EncFormApiFilters, EncFormApiCategories, EncFormApiDisplayFields } from 'src/stores/FormStore';
import { CellInfo } from 'react-table';
import DateComponent from 'src/components/DateComponent';
import ActionMenu from 'src/components/ActionMenu';
import { ModalStoreObject, ModalTypes } from 'src/stores/ModalStore';
import Table from 'src/components/Table';
import { getFormFilters, getRequiredFormFilters, FILTER_METHODS } from 'src/utils/filters';
import { ToastStoreObject, ToastType } from 'src/stores/ToastStore';
import Gender from 'src/components/Gender';
import { OptionTypes } from 'src/components/OptionDropDown';
import LoadingIcon from 'src/components/LoadingIcon';
import CsvDownloader from 'react-csv-downloader';
import PercentageBar from 'src/components/PercentageBar';
import { ROUTES } from 'src/utils/constants';
import { useHistory } from 'react-router-dom';
import { parseError } from 'src/utils';
import { getUnvoidedFormActionsGroupName } from 'src/utils/actions';

const FILTER_PAGE = 'formsPage';

function formatApiFilters(filters: EncFormApiFilters): SubFilters {
    const formattedFilters: SubFilters = {};
    Object.keys(filters).map((k) => {
        const filtersArr = Object.keys(filters[k]).map((f) => {
            const field = filters[k][f];
            const filter: FilterOptionsItem = {
                field: {
                    name: field.id,
                    label: field.name,
                    model: k,
                },
                availableFilterTypes: field.methods,
                options: field.options ? field.options.map((o: any) => ({ label: o.name, value: o.value })) : undefined,
                parentField: 'formCategory',
                optionType: field.optionType ? (field.optionType as OptionTypes) : undefined,
            };
            return filter;
        });
        formattedFilters[k] = filtersArr;
    });
    return formattedFilters;
}

function Forms() {
    const formStore = useContext(FormStore);
    const filterStore = useContext(FilterStore);
    const [isLoading, setIsLoading] = useState(false);
    const forms = formStore.forms;
    const allForms = formStore.allForms;
    const formFilters: FilterQueryParams = filterStore[FILTER_PAGE];
    const [requiredFilters, setRequiredFilters] = useState<FilterOptionsItem[]>(() => getRequiredFormFilters());
    const [isLoadingFilters, setIsLoadingFilters] = useState(false);
    const [displayFields, setDisplayFields] = useState<EncFormApiDisplayFields | null>(null);
    const [selectedFormCategory, setSelectedFormCategory] = useState(null);
    const isMount = useIsMount();
    const history = useHistory();

    useEffect(() => {
        loadAvailableFilterOptions();
    }, []);

    async function loadAvailableFilterOptions() {
        try {
            setIsLoadingFilters(true);
            const results = await encountersApi.getEncounterFormSearchFields();
            const data = results.data as {
                categories: EncFormApiCategories;
                filters: EncFormApiFilters;
                displayFields: EncFormApiDisplayFields;
            };

            // Add the categories as a required filter
            if (data.categories && data.categories.length > 0) {
                const newRequiredFilters: FilterOptionsItem[] = getRequiredFormFilters();
                newRequiredFilters.push({
                    field: { name: 'formCategory', label: 'Form Category' },
                    availableFilterTypes: [FILTER_METHODS.Matches_Dropdown],
                    options: data.categories.map((cat) => ({ label: cat.name, value: cat.id })),
                    subFilters: formatApiFilters(data.filters),
                });
                setSelectedFormCategory(data.categories[0].id);
                setRequiredFilters(newRequiredFilters);
            }

            if (data.displayFields) {
                setDisplayFields(data.displayFields);
            }

            setIsLoadingFilters(false);
        } catch (error) {
            ToastStoreObject.show(utils.parseError(error));
            setIsLoadingFilters(false);
        }
    }

    const formColumns = [
        {
            Header: 'FORM NAME',
            accessor: 'formDefinitionHistory.form_defn_title',
            minWidth: 150,
        },
        {
            Header: 'PATIENT',
            minWidth: 90,
            Cell: (props: CellInfo) => {
                const encounter = (props.original || {}).encounter;
                return (
                    <Flex direction="column" className={styles.patientInfo}>
                        <div className={styles.bold}>
                            {utils.getPatientName({
                                patientFirstName: encounter.pat_frst_nm,
                                patientLastName: encounter.pat_last_nm,
                            })}
                        </div>
                        <div>
                            DOB: <DateComponent date={encounter.pat_dob} />
                        </div>
                        <div>
                            Gender: <Gender value={encounter.pat_gender_cd} />
                        </div>
                    </Flex>
                );
            },
        },
        {
            Header: 'ENCOUNTER',
            accessor: 'encounter',
            minWidth: 160,
            Cell: (props: CellInfo) => {
                const encounter = props.value;
                return (
                    <Flex direction="column" className={styles.patientInfo}>
                        <div className={styles.bold}>Encounter #: {encounter.enctr_no}</div>
                        <div>
                            Admit Date: <DateComponent date={encounter.admit_dt} />
                        </div>
                    </Flex>
                );
            },
        },
        {
            Header: 'CREATED',
            accessor: 'ins_dttm',
            minWidth: 125,
            Cell: (props: CellInfo) => <DateComponent date={props.value} showDateTime={true} />,
        },
        {
            Header: 'UPDATED',
            accessor: 'upd_dttm',
            minWidth: 125,
            Cell: (props: CellInfo) => <DateComponent date={props.value} showDateTime={true} />,
        },
        {
            Header: 'FORM COMPLETION',
            accessor: 'form_cmplt_pct',
            minWidth: 130,
            Cell: (props: CellInfo) => <PercentageBar value={props.value} />,
        },
        {
            Header: 'ACTIONS',
            minWidth: 200,
            style: { paddingVertical: 0, paddingHorizontal: 20 },
            Cell: (props: CellInfo) => (
                <Flex direction="row">
                    <Button
                        type="primary"
                        text="View Details"
                        onClick={() => handleViewDetailsClick(props.original)}
                        className={styles.viewDetailsButton}
                    />
                    <ActionMenu
                        entityType={
                            props.original.void_ind
                                ? 'patientVisitsVoided'
                                : getUnvoidedFormActionsGroupName(props.original.formDefinitionHistory.form_defn_typ)
                        }
                        entity={props.original}
                        onItemClick={(action: { id: string; label: string }) =>
                            handleActionClick(action, props.original)
                        }
                    />
                </Flex>
            ),
        },
    ];

    async function changeVoidEncounterForm(form: EncounterFormRaw, voidInd: boolean) {
        try {
            setIsLoading(true);
            await formStore.updateEncounterFormVoidStatus(form.enctr_form_id, voidInd);
            ToastStoreObject.show(
                `${form.formDefinitionHistory.form_defn_title} form was ${voidInd ? 'voided' : 'unvoided'}.`,
                ToastType.Success,
            );
        } catch (e) {
            ToastStoreObject.show(parseError(e), ToastType.Error);
        } finally {
            setIsLoading(false);
            ModalStoreObject.hideModal();
        }
    }

    const handleActionClick = (action: { id: string; label: string }, form: EncounterFormRaw) => {
        switch (action.id) {
            case 'details':
                history.push(
                    ROUTES.getString(ROUTES.ViewEncounterForm, null, {
                        encounterFormId: form.enctr_form_id,
                        facId: form.encounter.fac_id,
                    }),
                );
                break;

            case 'void':
                ModalStoreObject.showModal(ModalTypes.ConfirmationModal, {
                    title: 'Are you sure you want to void this form?',
                    onConfirm: () => changeVoidEncounterForm(form, true),
                    onCancel: () => ModalStoreObject.hideModal(),
                    confirmButtonText: 'Yes, Void',
                    cancelButtonText: 'No',
                });
                break;

            case 'unvoid':
                ModalStoreObject.showModal(ModalTypes.ConfirmationModal, {
                    title: 'Are you sure you want to unvoid this form?',
                    onConfirm: () => changeVoidEncounterForm(form, false),
                    onCancel: () => ModalStoreObject.hideModal(),
                    confirmButtonText: 'Yes, Unvoid',
                    cancelButtonText: 'No',
                });
                break;

            case 'edit':
                ModalStoreObject.showModal(ModalTypes.EditEncounterForm, {
                    onClose: () => {
                        ModalStoreObject.hideModal();
                    },
                    enctrId: form.enctr_id,
                    facId: form.encounter.fac_id,
                    enctrFormId: form.enctr_form_id,
                });
                break;
        }
    };

    function handleViewDetailsClick(form: EncounterFormRaw) {
        ModalStoreObject.showModal(ModalTypes.EncounterFormRawDetails, {
            onClose: () => ModalStoreObject.hideModal(),
            encounterForm: form,
            displayFields,
        });
    }

    async function loadForms() {
        try {
            setIsLoading(true);
            await formStore.getForms(formFilters);
            formStore.getAllForms(Object.assign(toJS(formFilters), { pagination: { limit: 100000 } }));
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        // Don't load on initial mount
        if (isMount) {
            return;
        }
        loadForms();
    }, [formFilters]);

    async function handleSearch({ query, fields, orStatement }: FilteredSearchQuery) {
        const updatedFilters = { ...formFilters };
        updatedFilters.pagination.page = 1;
        updatedFilters.query = query;
        updatedFilters.orStatement = orStatement;

        const formCategoryFilter = fields.find((f) => f.name === 'formCategory');
        if (formCategoryFilter && formCategoryFilter.data.value1 !== selectedFormCategory) {
            setSelectedFormCategory(formCategoryFilter.data.value1);
        }

        updatedFilters.filters = fields;
        filterStore.setFilters(FILTER_PAGE, updatedFilters);
    }

    const handlePaginationFilterChange = function(newPage: number) {
        const updatedFilters = { ...formFilters };
        updatedFilters.pagination.page = newPage;
        filterStore.setFilters(FILTER_PAGE, updatedFilters);
    };

    const formsArr = toJS(forms);
    const allFormsArr = toJS(allForms);

    function getCsvColumnHeaders() {
        const headers = displayFields
            ? [...(displayFields.general || []), ...(displayFields[selectedFormCategory] || [])].map((f) => ({
                  displayName: f.label,
                  // no dot to avoid nested property issues
                  id: `${f.table && f.table !== 'encounterForm' ? `${f.table}` : ''}${f.field}`,
              }))
            : [];

        return headers;
    }

    function getCsvData() {
        const keys = displayFields
            ? [...(displayFields.general || []), ...(displayFields[selectedFormCategory] || [])].map((f) => {
                  // with dot to access properties
                  return `${f.table && f.table !== 'encounterForm' ? `${f.table}.` : ''}${f.field}`;
              })
            : [];
        const forms = toJS(allForms);
        const rows = forms.map((form) => {
            let row = {};
            keys.forEach((key) => {
                const isNestedProperty = key.includes('.');
                const columnKey = isNestedProperty ? key.replace('.', '') : key;
                if (isNestedProperty) {
                    const nestedKey = key.split('.');
                    const key1 = nestedKey[0];
                    const key2 = nestedKey[1];
                    // @ts-ignore
                    const value = form[key1][key2] || '';
                    row = Object.assign({
                        [columnKey]: value,
                        ...row,
                    });
                } else {
                    row = Object.assign({
                        // @ts-ignore
                        [columnKey]: form[key] || '',
                        ...row,
                    });
                }
            });

            return row;
        });

        return rows;
    }

    return (
        <PageContainer>
            <NavBar />
            <SubNav type="patients" />
            <PageContent>
                <Flex direction="row" className={styles.filterWrap}>
                    <Flex value={1}>
                        {isLoadingFilters ? (
                            <Flex value={1} align="center" justify="center">
                                <LoadingIcon /> Loading Filters
                            </Flex>
                        ) : (
                            <FilteredSearch
                                initialValues={{
                                    query: formFilters.query,
                                    fields: formFilters.filters,
                                    orStatement: formFilters.orStatement,
                                }}
                                onSearch={handleSearch}
                                searchPlaceholder={'Search by Form Number'}
                                requiredFilters={requiredFilters as any}
                                filterOptions={getFormFilters() as any}
                            />
                        )}
                    </Flex>
                </Flex>

                {formStore.totalFormsCount > 0 && (
                    <Flex direction="row">
                        <Flex value={1}>
                            <div>{formStore.totalFormsCount} total results</div>
                        </Flex>
                        {allFormsArr.length > 0 ? (
                            <CsvDownloader
                                className={styles.wrapRight}
                                // @ts-ignore
                                datas={getCsvData()}
                                filename="Encounter Forms Search Results.csv"
                                columns={getCsvColumnHeaders()}
                            >
                                Export to CSV
                            </CsvDownloader>
                        ) : (
                            <LoadingIcon type="darkDot" />
                        )}
                    </Flex>
                )}
                <div className={styles.tableWrap}>
                    <div className={styles.tableTitle}>Forms</div>
                    <Table
                        columns={formColumns}
                        data={formsArr}
                        isLoading={isLoading}
                        noDataText="Filter your search to view forms"
                    />
                </div>

                {/* Custom Pagination for server side data */}
                {formsArr.length > 0 ? (
                    <Pagination
                        pages={Math.ceil(formStore.totalFormsCount / formFilters.pagination.limit) || 1}
                        page={formFilters.pagination.page - 1}
                        onPageChange={(page: number) => handlePaginationFilterChange(page + 1)}
                        showing={formStore.forms.length}
                        totalRecords={formStore.totalFormsCount}
                    />
                ) : null}
            </PageContent>
        </PageContainer>
    );
}
export default observer(Forms);
