import React from 'react';

const SvgCalendar = (props) => (
    <svg width={17} height={17} {...props} viewBox="0 0 17 17">
        <g
            stroke={props.color || '#FFF'}
            strokeWidth={1.05}
            fill="none"
            fillRule="evenodd"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <rect x={0.531} y={2.656} width={15.938} height={13.813} rx={1.05} />
            <path d="M4.781 4.25V.531M12.219 4.25V.531M3.719 10.094h3.188v3.188H3.719zM10.094 6.906v6.375" />
            <path d="M6.906 6.906h6.375v6.375H6.906zM6.906 10.094h6.375" />
        </g>
    </svg>
);

export default SvgCalendar;
