import React, { useEffect, useState } from 'react';
import { ToastStoreObject, ToastType } from 'src/stores/ToastStore';
import { ANALYTICS_NAMES } from 'src/utils/analytics';
import Input, { Label } from '../Input';
import ModalFooter from '../ModalFooter';
import ModalHeader from '../ModalHeader';
import SideModal from '../SideModal';
import { FormModel } from 'common/lib/entity/framework/FormModel';
import { useCreateFormModel } from 'src/queries/useCreateFormModel';
import { useUpdateFormModel } from 'src/queries/useUpdateFormModel';
import styles from './styles.module.scss';
import Flex from '../Flex';

interface FormModelModalProps {
    formModel?: FormModel;
    mode?: 'edit' | 'create';
    onClose?: Function;
    onSave?: Function;
}

function FormModelModal(props: FormModelModalProps) {
    const { formModel, mode, onClose, onSave } = props;
    const [modelName, setModelName] = useState(formModel?.modelName || '');
    const [modelDescription, setModelDescription] = useState(formModel?.modelDescription || '');
    const [modelContent, setModelContent] = useState(formModel?.modelContent || '');
    const [fileName, setFileName] = useState('');
    const {
        mutate: createFormModel,
        isLoading: isCreateFormModelLoading,
        isSuccess: isCreateFormModelSuccess,
        isError: isCreateFormModelError,
        error: createFormModelError,
    } = useCreateFormModel();
    const {
        mutate: updateFormModel,
        isLoading: isUpdateFormModelLoading,
        isSuccess: isUpdateFormModelSuccess,
        isError: isUpdateFormModelError,
        error: updateFormModelError,
    } = useUpdateFormModel();

    useEffect(() => {
        if (isCreateFormModelSuccess) {
            ToastStoreObject.show('Form model created successfully', ToastType.Success);
            onClose();
            onSave();
        }

        if (isCreateFormModelError) {
            // @ts-ignore
            const errorMessage = createFormModelError?.response?.data?.message || 'Failed to create form model';
            ToastStoreObject.show(errorMessage, ToastType.Error);
        }

        if (isUpdateFormModelSuccess) {
            ToastStoreObject.show('Form model updated successfully', ToastType.Success);
            onClose();
            onSave();
        }

        if (isUpdateFormModelError) {
            // @ts-ignore
            const errorMessage = updateFormModelError?.response?.data?.message || 'Failed to update form model';
            ToastStoreObject.show(errorMessage, ToastType.Error);
        }
    }, [isCreateFormModelSuccess, isCreateFormModelError, isUpdateFormModelSuccess, isUpdateFormModelError]);

    async function handleCreate() {
        createFormModel({
            modelName,
            modelDescription,
            modelContent,
        });
    }

    async function handleSave() {
        updateFormModel({
            formModelId: formModel.formModelId,
            modelName,
            modelDescription,
            modelContent,
        });
    }

    return (
        <SideModal isOpen={true} onClose={props.onClose}>
            <ModalHeader title={`${props.mode === 'edit' ? 'Edit' : 'New'} Model`} onClose={props.onClose} />
            <div className={styles.contentWrap}>
                <Input
                    label="Model Name"
                    value={modelName}
                    onChangeText={(v: string) => setModelName(v)}
                    data-test-id={ANALYTICS_NAMES.FormModelModal_ModelName}
                />
                <Input
                    label="Model Description"
                    value={modelDescription}
                    multiLine={true}
                    onChangeText={(v: string) => setModelDescription(v)}
                    data-test-id={ANALYTICS_NAMES.FormModelModal_ModelDescription}
                />
                <Label text="Model Content" className={styles.label} />
                <Flex align="center" justify="start">
                    <input
                        data-test-id={ANALYTICS_NAMES.FormModelModal_ModelContent}
                        type="file"
                        id="formModelFileInput"
                        className={styles.hidden}
                        accept="text/xml"
                        onChange={(e) => {
                            const uploadedFile = e.target.files[0];
                            if (!uploadedFile) {
                                return;
                            }

                            const reader = new FileReader();
                            reader.onload = (e) => {
                                if (e.target?.result) {
                                    setModelContent(e.target.result.toString());
                                }
                            };
                            reader.readAsText(uploadedFile);

                            setFileName(uploadedFile.name);
                        }}
                    />
                    <label htmlFor="formModelFileInput" className={styles.fileInputButton}>
                        Choose a File
                    </label>
                    <span className={styles.fileName}>{`${fileName ? fileName : 'No File Chosen'}`}</span>
                </Flex>
            </div>
            <ModalFooter
                primaryText={mode === 'edit' ? 'Save' : 'Create'}
                primaryClick={mode === 'edit' ? handleSave : handleCreate}
                secondaryClick={() => onClose()}
                isLoading={isCreateFormModelLoading || isUpdateFormModelLoading}
            />
        </SideModal>
    );
}

export default FormModelModal;
