import { AuthStoreObject } from 'src/stores/AuthStore';
import { useEffect } from 'react';
import { UserStoreObject } from 'src/stores/UserStore';

export function useStartupActions() {
    useEffect(() => {
        // Startup actions
        loggedInActions(); // Kick off the async actions

        AuthStoreObject.setupReactions();
        return () => {
            AuthStoreObject.disposeReactions();
        };
    }, []);
}

// Run these actions on startup and throw an error if there is a problem
export async function loggedInActions() {
    if (AuthStoreObject.isAuthenticated) {
        embedHubspotChatWidget();
    }
}

// Add hubspot interfaces to the global window interface
declare global {
    interface Window {
        HubSpotConversations: any;
        hsConversationsOnReady: any;
        _hsq: any;
        lib: any;
    }
}

export function embedHubspotChatWidget() {
    const script = document.createElement('script');
    script.src = '//js.hs-scripts.com/5580703.js';
    script.async = true;
    document.body.appendChild(script);

    function onWidgetReady() {
        const _hsq = (window._hsq = window._hsq || []);
        _hsq.push([
            'identify',
            {
                email:
                    UserStoreObject.orgEmailAddr !== '' ? UserStoreObject.orgEmailAddr : UserStoreObject.indexEmailAddr,
                firstname: UserStoreObject.frstNm,
                lastname: UserStoreObject.lastNm,
            },
        ]);
        _hsq.push(['trackPageView']);
    }

    if (window.HubSpotConversations) {
        onWidgetReady();
    } else {
        window.hsConversationsOnReady = [onWidgetReady];
    }
}
