import { Entity, Column, JoinColumn, OneToOne } from 'typeorm';
import { Customer } from './Customer';
import { Org } from './Org';

@Entity('customer_org', { schema: 'graphium' })
export class CustomerOrg {
    @Column('uuid', {
        primary: true,
        default: 'uuid_generate_v4()',
        name: 'customer_id',
    })
    customerId: string;

    @Column('int8', {
        primary: true,
        nullable: false,
        name: 'org_id',
    })
    orgId: number;

    @Column('boolean', {
        nullable: false,
        default: true,
        name: 'actv_ind',
    })
    isActive: boolean;

    @Column('timestamp with time zone', {
        nullable: false,
        default: 'now()',
        name: 'ins_dttm',
    })
    insDttm?: Date;

    @Column('timestamp with time zone', {
        nullable: false,
        default: 'now()',
        name: 'upd_dttm',
    })
    updDttm?: Date;

    @Column('int4', {
        nullable: false,
        default: 1,
        name: 'aud_ver',
    })
    audVer: number;

    @OneToOne(() => Org, (org) => org.customerOrg)
    org?: Org;

    @OneToOne(() => Customer, (customer) => customer.orgs)
    @JoinColumn({ name: 'customer_id' })
    customer?: Customer;
}
