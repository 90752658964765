import { Entity, Column, OneToOne } from 'typeorm';
import { Facility } from '../org/Facility';
import { CustomerOrg } from './CustomerOrg';
import { Org } from './Org';

export interface CustomerSubscriptionData {
    initialContractDate: string;
    brand: 'GRAPHIUM' | 'FIELDMED';
    customerType: CustomerType;
    customerStatus: CustomerStatus;
    isTrialCustomer: boolean;
    isDemoCustomer: boolean;
    customerAddress: CustomerAddress;
    offboardDate?: string;
    offboardReason?: OffboardReason;
    otherOffboardReason?: string;
    offboardedToCustomerId?: string | number;
    documents?: CustomerDocument[];
    primaryContacts: CustomerContact[];
    technicalContacts: CustomerContact[];
    billingContacts: CustomerContact[];
    clinicalContacts: CustomerContact[];
}

export enum OffboardReason {
    CustomerCancelled = 'CUSTOMER_CANCELLED',
    FailureToPay = 'FAILURE_TO_PAY',
    CustomerLostContract = 'CUSTOMER_LOST_CONTRACT',
    TrialCancelled = 'TRIAL_CANCELLED',
    ContractTransferred = 'CONTRACT_TRANSFERRED',
    FailureToGoLive = 'FAILURE_TO_GO_LIVE',
    Other = 'OTHER',
}

export enum CustomerStatus {
    Onboarding = 'ONBOARDING',
    Active = 'ACTIVE',
    Offboarding = 'OFFBOARDING',
    Offboarded = 'OFFBOARDED',
}

export enum CustomerType {
    AnesthesiaGroup = 'ANESTHESIA_GROUP',
    MultiSpecialty = 'MULTI_SPECIALITY_GROUP',
    AmbulatorySurgery = 'AMBULATORY_SURGERY_CENTER',
    Hospital = 'HOSPITAL',
    Billing = 'BILLING_COMPANY',
    Dental = 'DENTAL_ANESTHESIA_PRACTICE',
}

export interface CustomerAddress {
    streetAddress1: string;
    streetAddress2: string;
    city: string;
    state: string;
    postalCode: string;
}

export interface CustomerDocument {
    documentGuid?: string;
    documentType: 'BAA' | 'QCDR' | 'MSA' | 'ORDER_FORM' | 'NDA';
    documentUrl: string;
    documentTitle: string;
    documentStatus: 'PENDING' | 'COMPLETE';
    orgInternalName?: Org['orgNmIntrnl'];
    facilityId?: Facility['facId'];
    dateCompleted?: string;
}

export interface CustomerContact {
    contactGuid: string;
    firstName: string;
    lastName: string;
    title: string;
    email: string;
    phone: string;
    orgInternalName?: Org['orgNmIntrnl'];
    facilityId?: Facility['facId'];
}

@Entity('customer', { schema: 'graphium' })
export class Customer {
    @Column('uuid', {
        primary: true,
        default: 'uuid_generate_v4()',
        name: 'customer_id',
    })
    customerId: string;

    @Column('text', {
        nullable: false,
        name: 'customer_name',
    })
    customerName: string;

    @Column('jsonb', {
        nullable: true,
        name: 'subscription_data',
    })
    subscriptionData: CustomerSubscriptionData;

    @Column('boolean', {
        nullable: false,
        default: true,
        name: 'actv_ind',
    })
    isActive: boolean;

    @Column('timestamp with time zone', {
        nullable: false,
        default: 'now()',
        name: 'ins_dttm',
    })
    insDttm?: Date;

    @Column('timestamp with time zone', {
        nullable: false,
        default: 'now()',
        name: 'upd_dttm',
    })
    updDttm?: Date;

    @Column('int4', {
        nullable: false,
        default: 1,
        name: 'aud_ver',
    })
    audVer: number;

    @OneToOne(() => CustomerOrg, (org) => org.customer)
    orgs: CustomerOrg[];
}
