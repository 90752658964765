import React, { useRef, RefObject, useContext, useState, useEffect } from 'react';
import Portal from '../Portal';
import styles from './styles.module.scss';
import classNames from 'classnames';
import Flex from 'src/components/Flex';
import Card from 'src/components/Card';
import { NavLink } from 'react-router-dom';
import useClickOutside from 'src/utils/hooks/useClickOutside';
import { UserData } from 'src/stores/UserStore';
import { ANALYTICS_NAMES } from 'src/utils/analytics';
import UserStore from 'src/stores/UserStore';
import Icon from '../Icon';
import { observer } from 'mobx-react';
import OrgStore from 'src/stores/OrgStore';
import { ROUTES } from 'src/utils/constants';
import { FeatureBranding, FeatureVitals } from '../FeatureFlag';
import useFlag from 'src/utils/hooks/useFlag';

export interface SettingsPanelProps {
    isOpen: boolean;
    onClose: Function;
    parentRef: RefObject<HTMLDivElement>;
    user: UserData;
    onLogout: () => any;
}

const SettingsLink = observer(function({
    text,
    to,
    isLocked,
}: {
    text: string;
    to?: string;
    allowedRoles?: string[];
    isLocked?: boolean;
}) {
    if (!to || isLocked) {
        return (
            <div className={styles.linkTitleLocked}>
                <Flex direction="row" align="center">
                    <div className={styles.linkTextLocked}>{text}</div>
                    <Icon type="fa" name="lock" className={styles.lockIcon} />
                </Flex>
            </div>
        );
    }
    return (
        <NavLink exact={true} to={to} className={styles.linkTitle} activeClassName={styles.active}>
            <div className={styles.linkText}>{text}</div>
        </NavLink>
    );
});

function SettingsPanel(props: SettingsPanelProps) {
    const node = useRef<HTMLDivElement>();
    const userStore = useContext(UserStore);
    const orgStore = useContext(OrgStore);
    const [orgName, setOrgName] = useState('');
    const nemsisEnabled = useFlag('nemsis');
    const paymentsEnabled = useFlag('payments');
    const customersEnabled = useFlag('customers');
    const vitalsEnabled = useFlag('vitals');
    const canSaveQcdr = useFlag('macra-2024-enable-qcdr');
    const adsEnabled = useFlag('automated-dispense-systems');
    const advancedSurveysFeatureEnabled = useFlag('advanced-surveys');

    useClickOutside(() => props.onClose(), [node, props.parentRef]);

    useEffect(() => {
        const name = (orgStore.orgs.find((o) => `${o.orgId}` === `${userStore.selectedOrg.orgId}`) || {}).orgNm;
        setOrgName(name);
    }, [userStore.selectedOrg, orgStore.orgs]);

    if (!props.isOpen) {
        return null;
    }
    return (
        <Portal node={props.parentRef.current}>
            <div ref={node} className={classNames(styles.panelWrap)}>
                <div className={styles.triangle} />
                <Card elevation={4} className={styles.cardWrap}>
                    <Flex direction="row">
                        <Flex direction="column" value={2} className={styles.leftSection}>
                            <div className={styles.headerTitle}>{orgName}</div>
                            <Flex direction="row">
                                <Flex direction="column" className={styles.leftColumn}>
                                    <div className={styles.section}>
                                        <span className={styles.sectionTitle}>Organization Settings</span>
                                        {nemsisEnabled && (
                                            <FeatureBranding
                                                fieldmedContent={
                                                    <>
                                                        <SettingsLink
                                                            text="Agency"
                                                            to={ROUTES.getString(ROUTES.SettingsNemsis)}
                                                            isLocked={!userStore.userPermissions.canEdit.nemsis}
                                                        />
                                                        <SettingsLink
                                                            text="Devices"
                                                            to={ROUTES.getString(ROUTES.SettingsDevices)}
                                                            isLocked={!userStore.userPermissions.canEdit.nemsis}
                                                        />
                                                    </>
                                                }
                                                graphiumContent={null}
                                            />
                                        )}
                                        <SettingsLink
                                            text="Facilities"
                                            to={ROUTES.getString(ROUTES.SettingsFacilities)}
                                            isLocked={!userStore.userPermissions.canView.facilities}
                                        />
                                        {paymentsEnabled && (
                                            <FeatureBranding
                                                fieldmedContent={null}
                                                graphiumContent={
                                                    <>
                                                        <SettingsLink
                                                            text="Payers"
                                                            to={ROUTES.Payers}
                                                            isLocked={
                                                                !userStore.userPermissions.canView.paymentRequests
                                                            }
                                                        />
                                                        <SettingsLink
                                                            text="Payer Categories"
                                                            to={ROUTES.PayerCategories}
                                                            isLocked={
                                                                !userStore.userPermissions.canView.paymentRequests
                                                            }
                                                        />
                                                    </>
                                                }
                                            />
                                        )}
                                        {canSaveQcdr && (
                                            <FeatureBranding
                                                fieldmedContent={null}
                                                graphiumContent={
                                                    <SettingsLink
                                                        text="QCDR Settings"
                                                        to={ROUTES.getString(ROUTES.QcdrSettings)}
                                                    />
                                                }
                                            />
                                        )}
                                        {nemsisEnabled && (
                                            <FeatureBranding
                                                fieldmedContent={
                                                    <>
                                                        <SettingsLink
                                                            text="Transport Facilities"
                                                            to={ROUTES.getString(ROUTES.SettingsTransportFacilities)}
                                                            isLocked={!userStore.userPermissions.canEdit.nemsis}
                                                        />
                                                    </>
                                                }
                                                graphiumContent={null}
                                            />
                                        )}
                                        <SettingsLink
                                            text="Users and Invitations"
                                            to={ROUTES.getString(ROUTES.SettingsUsers)}
                                            isLocked={!userStore.userPermissions.canView.users}
                                        />
                                        {nemsisEnabled && (
                                            <FeatureBranding
                                                fieldmedContent={
                                                    <>
                                                        <SettingsLink
                                                            text="Vehicles"
                                                            to={ROUTES.getString(ROUTES.SettingsVehicles)}
                                                            isLocked={!userStore.userPermissions.canEdit.nemsis}
                                                        />
                                                    </>
                                                }
                                                graphiumContent={null}
                                            />
                                        )}
                                    </div>
                                    <div className={styles.section}>
                                        <span className={styles.sectionTitle}>Forms</span>
                                        <SettingsLink
                                            text="Tags"
                                            to={ROUTES.getString(ROUTES.SettingsTags)}
                                            isLocked={!userStore.userPermissions.canView.tags}
                                        />
                                    </div>
                                    <div className={styles.section}>
                                        <span className={styles.sectionTitle}>Data Entry</span>
                                        <SettingsLink text="Import" to={ROUTES.getString(ROUTES.Import)} />
                                    </div>
                                    {advancedSurveysFeatureEnabled && (
                                        <div className={styles.section}>
                                            <span className={styles.sectionTitle}>Surveys</span>
                                            <SettingsLink text="Survey Results" to={ROUTES.getString(ROUTES.Surveys)} />
                                            <SettingsLink
                                                text="Survey Definitions"
                                                to={ROUTES.getString(ROUTES.SurveyDefinitions)}
                                            />
                                        </div>
                                    )}
                                </Flex>
                                <Flex direction="column" className={styles.rightColumn}>
                                    <div className={styles.section}>
                                        <span className={styles.sectionTitle}>Facility Settings</span>
                                        {adsEnabled && (
                                            <FeatureBranding
                                                fieldmedContent={null}
                                                graphiumContent={
                                                    <SettingsLink
                                                        text="ADS"
                                                        to={ROUTES.getString(ROUTES.AutomatedDispenseSystems)}
                                                        isLocked={
                                                            !userStore.userPermissions.canView.automatedDispenseSystems
                                                        }
                                                    />
                                                }
                                            />
                                        )}
                                        <SettingsLink
                                            text="Providers"
                                            to={ROUTES.getString(ROUTES.SettingsProviders)}
                                            isLocked={!userStore.userPermissions.canView.providers}
                                        />
                                        <SettingsLink
                                            text="Reference Lists"
                                            to={ROUTES.getString(ROUTES.SettingsRefLists)}
                                            isLocked={
                                                !userStore.userPermissions.canEdit.referenceLists &&
                                                !userStore.userPermissions.canEdit.valueSetDetails
                                            }
                                        />
                                        <SettingsLink
                                            text="Teams"
                                            to={ROUTES.getString(ROUTES.SettingsTeams)}
                                            isLocked={!userStore.userPermissions.canView.teams}
                                        />
                                        {vitalsEnabled && (
                                            <FeatureVitals
                                                content={
                                                    <SettingsLink
                                                        text="Vitals Connections"
                                                        to={ROUTES.getString(ROUTES.VitalsConnections)}
                                                        isLocked={!userStore.userPermissions.canView.vitalsConnections}
                                                    />
                                                }
                                            />
                                        )}
                                    </div>
                                    {paymentsEnabled && (
                                        <FeatureBranding
                                            fieldmedContent={null}
                                            graphiumContent={
                                                <div className={styles.section}>
                                                    <span className={styles.sectionTitle}>Payments</span>
                                                    <SettingsLink
                                                        text="Payment Types"
                                                        to={ROUTES.PaymentTypes}
                                                        isLocked={!userStore.userPermissions.canView.paymentRequests}
                                                    />
                                                    <SettingsLink
                                                        text="Payment Gateways"
                                                        to={ROUTES.PaymentGateways}
                                                        isLocked={!userStore.userPermissions.canView.paymentRequests}
                                                    />
                                                </div>
                                            }
                                        />
                                    )}
                                    {customersEnabled && (
                                        <div className={styles.section}>
                                            <span className={styles.sectionTitle}>Customers</span>
                                            <SettingsLink
                                                text="Customers/Subscriptions"
                                                to={ROUTES.getString(ROUTES.Customers)}
                                            />
                                        </div>
                                    )}
                                    {userStore.adminIndicator ? (
                                        <div className={styles.section}>
                                            <span className={styles.sectionTitle}>Admin</span>
                                            <SettingsLink
                                                text="All Users"
                                                to={ROUTES.getString(ROUTES.AdminAllUsers)}
                                            />
                                            <SettingsLink
                                                text="Flow Script Templates"
                                                to={ROUTES.getString(ROUTES.AdminFlowScriptTemplates)}
                                            />
                                            <SettingsLink
                                                text="Import Batch Templates"
                                                to={ROUTES.getString(ROUTES.AdminImportBatchTemplates)}
                                            />
                                            <SettingsLink
                                                text="Form Development"
                                                to={ROUTES.getString(ROUTES.FormDevelopment)}
                                            />
                                            {advancedSurveysFeatureEnabled && (
                                                <SettingsLink
                                                    text="Survey Templates"
                                                    to={ROUTES.getString(ROUTES.AdminSurveyTemplates)}
                                                />
                                            )}
                                        </div>
                                    ) : null}
                                </Flex>
                            </Flex>
                        </Flex>
                        <Flex direction="column" className={styles.rightSection}>
                            <Flex direction="column" className={styles.headerTitle}>
                                {props.user.frstNm} {props.user.lastNm}
                                <div className={styles.headerUsername}>{props.user.usrNm}</div>
                            </Flex>
                            <Flex direction="column" className={styles.rightColumn}>
                                <div className={styles.section}>
                                    <span className={styles.sectionTitle}>User</span>
                                    <SettingsLink text="User Profile" to={ROUTES.getString(ROUTES.Profile)} />
                                    <div
                                        className={styles.linkTitle}
                                        data-test-id={ANALYTICS_NAMES.LogoutButton}
                                        onClick={props.onLogout}
                                    >
                                        <div className={styles.linkText}>Sign Out</div>
                                    </div>
                                </div>
                            </Flex>
                            <FeatureBranding
                                fieldmedContent={null}
                                graphiumContent={
                                    <Flex direction="column" className={styles.rightBottomColumn}>
                                        <div className={styles.section}>
                                            <span className={styles.sectionTitle}>Help</span>
                                            <div className={styles.linkTitle}>
                                                <div
                                                    className={styles.linkText}
                                                    onClick={() => {
                                                        window.open(ROUTES.getString(ROUTES.Help));
                                                    }}
                                                >
                                                    Knowledge Base
                                                </div>
                                            </div>
                                        </div>
                                    </Flex>
                                }
                            />
                        </Flex>
                    </Flex>
                </Card>
            </div>
        </Portal>
    );
}

export default observer(SettingsPanel);
