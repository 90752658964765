import React from 'react';

const SvgSynchronizeArrow1 = (props) => (
    <svg width={14} height={12} {...props} viewBox="0 0 14 12">
        <g
            stroke={props.color || '#FFF'}
            strokeWidth={1.035}
            fill="none"
            fillRule="evenodd"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path d="M7.625 11c2.761 0 5-2.183 5-4.875s-2.239-4.875-5-4.875-5 2.183-5 4.875V6.5" />
            <path d="M.675 4.998l2 1.5 2-1.5" />
        </g>
    </svg>
);

export default SvgSynchronizeArrow1;
