import React, { useState, useEffect } from 'react';
import styles from './styles.module.scss';
import Select from 'react-select';
import * as utils from 'src/utils';
import { Org } from 'common/lib/entity/index/Org';
import { useAllActiveOrgs } from 'src/queries/useAllActiveOrgs';

export interface OrgDropDownProps {
    onChange: Function;
    selectedValue?: string | number;
    disabled?: boolean;
}

function OrgDropDown(props: OrgDropDownProps) {
    const { data: allActiveOrgs, isFetching } = useAllActiveOrgs();
    const [options, setOptions] = useState([]);
    const [selected, setSelected] = useState(null);

    useEffect(() => {
        if (allActiveOrgs) {
            const opts = allActiveOrgs.map((org: Org) => ({
                label: org.orgNm,
                value: org.orgNmIntrnl,
            }));
            setOptions(opts);
            setSelected(opts.find((i) => i.value === props.selectedValue) || null);
        }
    }, [allActiveOrgs]);

    useEffect(() => {
        const selectedVal = options.find((i) => i.value === props.selectedValue);
        if (selectedVal) {
            setSelected(selectedVal);
        }
    }, [props.selectedValue, options]);

    return (
        <Select
            value={selected}
            onChange={(s: any) => {
                setSelected(s);
                props.onChange(s || null);
            }}
            isLoading={isFetching}
            className={styles.select}
            options={options}
            placeholder="Select Organization"
            styles={utils.styleSelectComponent()}
            isDisabled={props.disabled || isFetching}
        />
    );
}

export default OrgDropDown;
