import React, { useState, useEffect, useContext } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import NavBar from 'src/containers/NavBar';
import PageContent from 'src/components/PageContent';
import PageContainer from 'src/components/PageContainer';
import Button from 'src/components/Button';
import Card from 'src/components/Card';
import Flex from 'src/components/Flex';
import Table from 'src/components/Table';
import styles from './styles.module.scss';
import SideModal from 'src/components/SideModal';
import ReferenceListPropertyModal from 'src/components/ReferenceListPropertyModal';
import ReferenceListValueModal from 'src/components/ReferenceListValueModal';
import { CellInfo } from 'react-table';
import ModalFooter from 'src/components/ModalFooter';
import ModalHeader from 'src/components/ModalHeader';
import Icon from 'src/components/Icon';
import { ICONS, ROUTES } from 'src/utils/constants';
import { ToastStoreObject, ToastType } from 'src/stores/ToastStore';
import { ErrorStoreObject, ErrorTypes } from 'src/stores/ErrorStore';
import { parseError } from 'src/utils';
import {
    getReferenceList,
    getReferenceListValues,
    updateReferenceListProperty,
    createReferenceListValue,
    updateReferenceListValue,
    deactivateReferenceListValue,
    reactivateReferenceListValue,
    updateReferenceListValueExcludedFacilities,
} from 'src/api/referenceList';
import ReferenceListStore, {
    ReferenceList,
    ReferenceListValue,
    ReferenceListExtendedProperty,
} from 'src/stores/ReferenceListStore';
import ActionMenu from 'src/components/ActionMenu';
import CenterModal from 'src/components/CenterModal';
import FacilitySelectorModal from 'src/components/FacilitySelectorModal';
import { useHistory, useParams } from 'react-router';
import { ModalStoreObject, ModalTypes } from 'src/stores/ModalStore';
import { ANALYTICS_NAMES } from 'src/utils/analytics';
import UserStore from 'src/stores/UserStore';
import Input from 'src/components/Input';
import useDebouncedCallback from 'src/utils/hooks/useDebouncedCallback';
import Checkbox from 'src/components/Checkbox';

function ReferenceListView() {
    const history = useHistory();
    const { referenceListId: paramsReferenceListId } = useParams<any>();
    const userStore = useContext(UserStore);
    const [modalState, setModalState] = useState(false);
    const [modalType, setModalType] = useState('create');
    const [valueModalState, setValueModalState] = useState(false);
    const [valueModalType, setValueModalType] = useState('create');
    const [facilityModalState, setFacilityModalState] = useState(false);
    const [facilityModalData, setFacilityModalData] = useState<ReferenceListValue>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [referenceListData, setReferenceListData] = useState<ReferenceList>({});
    const [referenceListValuesData, setReferenceListValuesData] = useState<ReferenceListValue[]>([]);
    const [referenceListValues, setReferenceListValues] = useState<ReferenceListValue[]>([]);
    const [referenceListValueModalData, setReferenceListValueModalData] = useState<ReferenceListValue>({});
    const [referenceListPropertyModalData, setReferenceListPropertyModalData] = useState<ReferenceListExtendedProperty>(
        {},
    );
    const [editReferenceListValue, setEditReferenceListValue] = useState<ReferenceListValue>({});
    const [excludedFacilities, setExcludedFacilities] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [isSearching, setIsSearching] = useState(false);
    const [searchValues, setSearchValues] = useState([]);
    const referenceListStore = useContext(ReferenceListStore);
    const [showDeactivatedList, setShowActivatedList] = useState(false);

    const [refreshSearchResults] = useDebouncedCallback(async function (query: string) {
        if (!query) {
            setIsSearching(false);
            getReferenceListData();
            return;
        }
        setIsLoading(true);
        const values = await getReferenceListValues(paramsReferenceListId, { useId: true, query });
        const listToDisplay = filterReferenceByActiveStatus(values, showDeactivatedList);
        setIsSearching(true);
        setSearchValues(listToDisplay);
        setIsLoading(false);
    }, 300);

    async function getReferenceListData(includeDeactivated: boolean = false) {
        try {
            setIsLoading(true);
            const referenceListResponse = await getReferenceList(paramsReferenceListId, true);
            setReferenceListData(referenceListResponse);
            const referenceListValuesData = await getReferenceListValues(paramsReferenceListId, { useId: true });
            setReferenceListValuesData(referenceListValuesData);
            const listToDisplay = filterReferenceByActiveStatus(referenceListValuesData, includeDeactivated);
            setReferenceListValues(listToDisplay);
            setIsLoading(false);
        } catch (e) {
            ErrorStoreObject.setError(ErrorTypes.Loading);
        }
    }

    function filterReferenceByActiveStatus(
        referenceList: Array<ReferenceListValue>,
        includeDeactivated: boolean,
    ): Array<ReferenceListValue> {
        return includeDeactivated
            ? referenceList
            : referenceList.filter((referenceList: ReferenceListValue) => referenceList.actvInd);
    }

    function getColumns() {
        const defaultColumns = [
            {
                Header: () => <span className={styles.blackText}>LABEL</span>,
                accessor: 'listValueDisplayName',
                minWidth: 150,
                Cell: (props: CellInfo) => <span className={styles.blackText}>{props.value}</span>,
            },
            {
                Header: 'CODE',
                accessor: 'listValueCode',
                minWidth: 150,
                Cell: (props: CellInfo) => <span className={styles.blackText}>{props.value}</span>,
            },
            {
                Header: 'ACTIVE',
                accessor: 'actvInd',
                minWidth: 100,
                Cell: (props: CellInfo) => (
                    <span className={classNames({ [styles.redText]: !props.value, [styles.greenText]: props.value })}>
                        {props.value ? 'ACTIVE' : 'INACTIVE'}
                    </span>
                ),
            },
        ];

        function getPropertyContent(property: ReferenceListExtendedProperty, propertyData: ReferenceListValue) {
            const values: any = propertyData.listValueExtendedProperties || {};
            const valueString: string =
                values[property.propertyName] && values[property.propertyName] !== undefined
                    ? values[property.propertyName].toString()
                    : '';
            return property.propertyType === 'boolean' ? valueString.toUpperCase() : valueString;
        }

        const extendedProperties =
            referenceListData.listExtendedProperties &&
            referenceListData.listExtendedProperties.map((property) => {
                return {
                    Header: () => (
                        <div className={styles.propertyHeader}>
                            <div
                                className={classNames({
                                    [styles.disabled]: !property.active,
                                })}
                            >
                                {property.propertyDisplayName.toUpperCase()}
                            </div>
                            <div
                                className={classNames([styles.activate], {
                                    [styles.deactivate]: !property.active,
                                })}
                                data-test-id={ANALYTICS_NAMES.RefListDetails_PropertyActivate}
                                onClick={() => handleActivateClick(property)}
                            >
                                {!property.active ? 'X' : '✓'}
                            </div>
                        </div>
                    ),
                    sortable: false,
                    Cell: (props: CellInfo) => {
                        const propertyContent = getPropertyContent(property, props.original);
                        return (
                            <span
                                className={classNames({
                                    [styles.redText]:
                                        propertyContent.toString() === 'FALSE' && property.propertyType === 'boolean',
                                    [styles.greenText]:
                                        propertyContent.toString() === 'TRUE' && property.propertyType === 'boolean',
                                    [styles.disabled]: !property.active,
                                })}
                            >
                                {propertyContent}
                            </span>
                        );
                    },
                };
            });

        const actions = {
            Header: 'ACTIONS',
            accessor: 'actions',
            sortable: false,
            maxWidth: 200,
            style: { paddingVertical: 0, paddingHorizontal: 20 },
            Cell: (props: CellInfo) => (
                <ActionMenu
                    entityType="referenceListValue"
                    entity={props.original}
                    onItemClick={(action: any) => handleActionClick(action, props.original)}
                />
            ),
        };

        return extendedProperties ? [...defaultColumns, ...extendedProperties, actions] : [...defaultColumns, actions];
    }

    function handleBack() {
        history.push(ROUTES.getString(ROUTES.SettingsRefLists));
    }

    async function handleValueModalComplete() {
        try {
            setIsLoading(true);
            if (valueModalType === 'create') {
                await createReferenceListValue(referenceListData.listId, referenceListValueModalData);
            } else if (valueModalType === 'edit') {
                await updateReferenceListValue(editReferenceListValue.listId, editReferenceListValue.listValueId, {
                    listValueDisplayName: referenceListValueModalData.listValueDisplayName,
                    listValueDescription: referenceListValueModalData.listValueDescription,
                    listValueExtendedProperties: referenceListValueModalData.listValueExtendedProperties,
                });
            }

            await updateTable();
            setValueModalState(false);
        } catch (e) {
            ToastStoreObject.show(parseError(e), ToastType.Error);
        } finally {
            setIsLoading(false);
        }
    }

    async function handlePropertyModalComplete() {
        try {
            setIsLoading(true);
            await updateReferenceListProperty(referenceListData.listId, referenceListPropertyModalData);
            getReferenceListData();
            setModalState(false);
        } catch (e) {
            ToastStoreObject.show(parseError(e), ToastType.Error);
        } finally {
            setIsLoading(false);
        }
    }

    async function handleActivateClick(property: ReferenceListExtendedProperty) {
        const updatedProperty = Object.assign(property, {
            active: !property.active,
        });
        await updateReferenceListProperty(referenceListData.listId, updatedProperty);
        await updateTable();
    }

    async function handleActionClick(action: { id: string; label: string }, value: ReferenceListValue) {
        switch (action.id) {
            case 'edit':
                setEditReferenceListValue(value);
                setValueModalType('edit');
                setValueModalState(true);
                break;

            case 'facilities':
                setFacilityModalData(value);
                setExcludedFacilities(value.facilityDisplayExcludeList || []);
                setFacilityModalState(!facilityModalState);
                break;

            case 'activate':
                if (value.actvInd) {
                    handleConfirm(value);
                } else {
                    await reactivateReferenceListValue(value.listId, value.listValueId);
                    await updateTable();
                }

                break;
        }
    }

    async function handleDeactivate(value: ReferenceListValue) {
        await deactivateReferenceListValue(value.listId, value.listValueId);
        await updateTable();
        ModalStoreObject.hideModal();
    }

    async function handleExcludeFacilities() {
        try {
            setIsLoading(true);
            await updateReferenceListValueExcludedFacilities(
                facilityModalData.listId,
                facilityModalData.listValueId,
                excludedFacilities,
            );
            setFacilityModalState(false);
            await updateTable();
            referenceListStore.resetType(referenceListData.listName);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getReferenceListData();
    }, []);

    useEffect(() => {
        const listToDisplay = filterReferenceByActiveStatus(referenceListValuesData, showDeactivatedList);
        setReferenceListValues(listToDisplay);
    }, [showDeactivatedList]);

    function handleConfirm(value: ReferenceListValue) {
        ModalStoreObject.showModal(ModalTypes.ConfirmationModal, {
            title: 'Are you sure you would like to deactivate this value?',
            onConfirm: () => handleDeactivate(value),
            onCancel: () => ModalStoreObject.hideModal(),
            confirmButtonText: 'Deactivate',
        });
    }

    async function updateTable() {
        isSearching ? await refreshSearchResults(searchQuery) : getReferenceListData();
    }

    function handleSearchQueryChange(userInput: string) {
        setSearchQuery(userInput);
        refreshSearchResults(userInput);
    }

    function handleShowDeactivatedListChange(showDeactivatedList: boolean) {
        setShowActivatedList(showDeactivatedList);
    }

    return (
        <PageContainer>
            <NavBar />
            <PageContent>
                <Flex self="stretch" align="center" justify="start">
                    <Flex
                        value={1}
                        className={styles.backWrap}
                        onClick={handleBack}
                        data-test-id={ANALYTICS_NAMES.RefListDetails_Back}
                    >
                        <span className={styles.backArrow}>{`< `}</span>Back to Reference Lists
                    </Flex>
                </Flex>
                <Flex direction="row" align="start">
                    <Flex value={1} className={styles.searchWrap}>
                        <Input
                            className={styles.input}
                            placeholder="Search by label or code..."
                            value={searchQuery}
                            onChangeText={(userInput: string) => handleSearchQueryChange(userInput)}
                        />
                        <Checkbox
                            checked={showDeactivatedList}
                            label={'Show deactivated lists'}
                            onChange={(e) => {
                                handleShowDeactivatedListChange(e.target.checked);
                            }}
                        />
                    </Flex>
                    <Flex value={3} className={styles.newAction}>
                        <Button
                            className={styles.leftButton}
                            leftIcon={<Icon className={styles.plusIcon} name={ICONS.PlusButton} />}
                            type="primary"
                            onClick={() => {
                                setValueModalType('create');
                                setValueModalState(true);
                            }}
                            isLocked={!userStore.userPermissions.canEdit.referenceListValues}
                            text="New Value"
                            data-test-id={ANALYTICS_NAMES.RefListDetails_New}
                        />
                        <Button
                            leftIcon={<Icon className={styles.plusIcon} name={ICONS.PlusButton} />}
                            type="primary"
                            onClick={() => {
                                setModalType('create');
                                setModalState(true);
                            }}
                            isLocked={!userStore.userPermissions.canEdit.referenceListValues}
                            text="New Extended Property"
                            data-test-id={ANALYTICS_NAMES.RefListDetails_NewExtended}
                        />
                    </Flex>
                </Flex>
                <Card className={styles.cardWrap}>
                    <div className={styles.tableTitle}>{`Reference List: ${
                        referenceListData.listDisplayName || '...'
                    }`}</div>
                    <Table
                        isLoading={isLoading}
                        columns={getColumns()}
                        data={isSearching ? searchValues : referenceListValues}
                    />
                </Card>
            </PageContent>
            <SideModal isOpen={valueModalState} onClose={() => setValueModalState(false)}>
                <ModalHeader
                    title={`${valueModalType === 'create' ? 'Add' : 'Edit'} ${
                        valueModalType === 'edit' ? editReferenceListValue.listValueDisplayName || 'Value' : 'Value'
                    }`}
                    onClose={() => setValueModalState(false)}
                />
                <ReferenceListValueModal
                    referenceList={referenceListData}
                    referenceListValue={valueModalType === 'edit' ? editReferenceListValue : {}}
                    onChange={(data: ReferenceListValue) => setReferenceListValueModalData(data)}
                    mode={valueModalType}
                />
                <ModalFooter
                    primaryText={valueModalType === 'create' ? 'Create' : 'Save'}
                    primaryClick={handleValueModalComplete}
                    secondaryClick={() => setValueModalState(false)}
                    isLoading={isLoading}
                />
            </SideModal>
            <SideModal isOpen={modalState} onClose={() => setModalState(false)}>
                <ModalHeader
                    title={`${modalType === 'create' ? 'Add' : 'Edit'} Value`}
                    onClose={() => setModalState(false)}
                />
                <ReferenceListPropertyModal
                    property={{}}
                    onChange={(data: ReferenceListExtendedProperty) => setReferenceListPropertyModalData(data)}
                />
                <ModalFooter
                    primaryText={modalType === 'create' ? 'Create' : 'Save'}
                    primaryClick={handlePropertyModalComplete}
                    secondaryClick={() => setModalState(false)}
                    isLoading={isLoading}
                />
            </SideModal>
            <CenterModal isOpen={facilityModalState} onClose={() => setFacilityModalState(false)}>
                <ModalHeader title="Exclude Facilities" onClose={() => setFacilityModalState(false)} />
                <FacilitySelectorModal
                    selectedFacilities={excludedFacilities || []}
                    onChange={(facilities: any) => setExcludedFacilities(facilities)}
                />
                <ModalFooter
                    primaryText="Save"
                    primaryClick={handleExcludeFacilities}
                    secondaryClick={() => setFacilityModalState(false)}
                    isLoading={isLoading}
                />
            </CenterModal>
        </PageContainer>
    );
}
export default observer(ReferenceListView);
