import React, { useState, useEffect, useContext } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import NavBar from 'src/containers/NavBar';
import PageContent from 'src/components/PageContent';
import PageContainer from 'src/components/PageContainer';
import Button from 'src/components/Button';
import Card from 'src/components/Card';
import Flex from 'src/components/Flex';
import Table from 'src/components/Table';
import styles from './styles.module.scss';
import SideModal from 'src/components/SideModal';
import { CellInfo } from 'react-table';
import ModalFooter from 'src/components/ModalFooter';
import ModalHeader from 'src/components/ModalHeader';
import Icon from 'src/components/Icon';
import { ICONS, ROUTES } from 'src/utils/constants';
import { ToastStoreObject, ToastType } from 'src/stores/ToastStore';
import { ErrorStoreObject, ErrorTypes } from 'src/stores/ErrorStore';
import { parseError } from 'src/utils';
import ActionMenu from 'src/components/ActionMenu';
import { useHistory, useParams } from 'react-router';
import { ModalStoreObject, ModalTypes } from 'src/stores/ModalStore';
import { ANALYTICS_NAMES } from 'src/utils/analytics';
import UserStore from 'src/stores/UserStore';
import { ValueSet, ValueSetDetail } from 'src/stores/ValueSetStore';
import {
    createValueSetDetail,
    deactivateValueSetDetail,
    getValueSetById,
    reactivateValueSetDetail,
    updateValueSetDetail,
    getValueSetDetails,
} from 'src/api/valueSet';
import ValueSetDetailModal from 'src/components/ValueSetDetailModal';
import useDebouncedCallback from 'src/utils/hooks/useDebouncedCallback';
import Input from 'src/components/Input';

function ValueSetView() {
    const history = useHistory();
    const { id: paramsValueSetId } = useParams<any>();
    const userStore = useContext(UserStore);
    const [valueModalState, setValueModalState] = useState(false);
    const [valueModalType, setValueModalType] = useState('edit');
    const [isLoading, setIsLoading] = useState(false);
    const [valueSetData, setValueSetData] = useState<ValueSet>({});
    const [valueSetDetailModalData, setValueSetDetailModalData] = useState<ValueSetDetail>({});
    const [editValueSetDetail, setEditValueSetDetail] = useState<ValueSetDetail>({});
    const [isSearching, setIsSearching] = useState(false);
    const [searchDetails, setSearchDetails] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [refreshSearchResults] = useDebouncedCallback(async function(query: string) {
        if (!query) {
            await getValueSetData();
            setIsSearching(false);
            return;
        }
        setIsLoading(true);
        const details = await getValueSetDetails(valueSetData.valSetId, { query });
        setIsSearching(true);
        setSearchDetails(details || []);
        setIsLoading(false);
    }, 300);

    async function getValueSetData() {
        try {
            setIsLoading(true);
            const valueSetResponse = await getValueSetById(paramsValueSetId);
            setValueSetData(valueSetResponse);
            setIsLoading(false);
        } catch (e) {
            ErrorStoreObject.setError(ErrorTypes.Loading);
        }
    }

    useEffect(() => {
        getValueSetData();
    }, []);

    function getAttributeListKeys(attributeList: string[]) {
        return attributeList.map((attribute) => attribute.substr(0, attribute.indexOf('|')));
    }

    function getColumns() {
        if (!valueSetData || !valueSetData.attributeList || !valueSetData.details) {
            return [];
        }

        const keys = getAttributeListKeys(valueSetData.attributeList || []);
        const columns = keys.map((key) => {
            return {
                Header: () => <span className={styles.blackText}>{key}</span>,
                minWidth: 150,
                sortable: true,
                Cell: (props: CellInfo) => {
                    return <span className={styles.blackText}>{getDetailContent(key, props.original)}</span>;
                },
            };
        });

        function getDetailContent(property: string, detail: ValueSetDetail) {
            return detail.valueList && detail.valueList[property] ? detail.valueList[property].toString() : '';
        }

        const isActiveColumn = {
            Header: 'ACTIVE',
            accessor: 'actvInd',
            minWidth: 100,
            Cell: (props: CellInfo) => (
                <span className={classNames({ [styles.redText]: !props.value, [styles.greenText]: props.value })}>
                    {props.value ? 'ACTIVE' : 'INACTIVE'}
                </span>
            ),
        };

        const actions = {
            Header: 'ACTIONS',
            accessor: 'actions',
            sortable: false,
            maxWidth: 200,
            style: { paddingVertical: 0, paddingHorizontal: 20 },
            Cell: (props: CellInfo) => (
                <ActionMenu
                    entityType="valueSetDetail"
                    entity={props.original}
                    onItemClick={(action: any) => handleActionClick(action, props.original)}
                />
            ),
        };

        return [...columns, isActiveColumn, actions];
    }

    function handleBack() {
        history.push(ROUTES.getString(ROUTES.SettingsRefLists));
    }

    async function handleValueModalComplete() {
        try {
            setIsLoading(true);
            if (valueModalType === 'create') {
                await createValueSetDetail(valueSetData.valSetId, valueSetDetailModalData.valueList);
            } else if (valueModalType === 'edit') {
                await updateValueSetDetail(editValueSetDetail.valueSetDetailId, valueSetDetailModalData.valueList);
            }
            setValueModalState(false);
            await updateTable();
        } catch (e) {
            ToastStoreObject.show(parseError(e), ToastType.Error);
        } finally {
            setIsLoading(false);
        }
    }

    async function handleActionClick(action: { id: string; label: string }, value: ValueSetDetail) {
        switch (action.id) {
            case 'edit':
                setEditValueSetDetail(value);
                setValueModalType('edit');
                setValueModalState(true);
                break;

            case 'activate':
                if (value.actvInd) {
                    handleConfirm(value);
                } else {
                    await reactivateValueSetDetail(value.valueSetDetailId);
                    await updateTable();
                }

                break;
        }
    }

    async function updateTable() {
        isSearching ? await refreshSearchResults(searchValue) : await getValueSetData();
    }

    async function handleDeactivate(value: ValueSetDetail) {
        const { valueSetDetailId } = value;
        await deactivateValueSetDetail(valueSetDetailId);
        await updateTable();
        ModalStoreObject.hideModal();
    }

    function handleConfirm(value: ValueSetDetail) {
        ModalStoreObject.showModal(ModalTypes.ConfirmationModal, {
            title: 'Are you sure you would like to deactivate this value?',
            onConfirm: () => handleDeactivate(value),
            onCancel: () => ModalStoreObject.hideModal(),
            confirmButtonText: 'Deactivate',
        });
    }

    function getTableTitle(): string {
        if (valueSetData.valueSetName) {
            return `Value Set: ${valueSetData.valueSetName} ${
                valueSetData.facility ? `(${valueSetData.facility.facNm})` : ''
            }`;
        }

        return 'Working...';
    }

    function handleSearchQueryChange(userInput: string) {
        setSearchValue(userInput);
        refreshSearchResults(userInput);
    }

    return (
        <PageContainer>
            <NavBar />
            <PageContent>
                <Flex self="stretch" align="center" justify="start">
                    <Flex
                        value={1}
                        className={styles.backWrap}
                        onClick={handleBack}
                        data-test-id={ANALYTICS_NAMES.RefListDetails_Back}
                    >
                        <span className={styles.backArrow}>{`< `}</span>Back to Reference Lists
                    </Flex>
                </Flex>
                <Flex direction="row" align="start">
                    <Flex value={1} className={styles.searchWrap}>
                        <Input
                            className={styles.input}
                            placeholder="Search..."
                            value={searchValue}
                            onChangeText={(userInput: string) => handleSearchQueryChange(userInput)}
                        />
                    </Flex>
                    <Flex value={3} className={styles.newAction}>
                        <Button
                            className={styles.leftButton}
                            leftIcon={<Icon className={styles.plusIcon} name={ICONS.PlusButton} />}
                            type="primary"
                            onClick={() => {
                                setValueModalType('create');
                                setValueModalState(true);
                            }}
                            isLocked={!userStore.userPermissions.canEdit.valueSetDetails}
                            text="New Value"
                            data-test-id={ANALYTICS_NAMES.ValueSetDetail_New}
                        />
                    </Flex>
                </Flex>
                <Card className={styles.cardWrap}>
                    <div className={styles.tableTitle}>{getTableTitle()}</div>
                    <Table
                        isLoading={isLoading}
                        columns={getColumns()}
                        data={isSearching ? searchDetails : valueSetData.details || []}
                    />
                </Card>
            </PageContent>
            <SideModal isOpen={valueModalState} onClose={() => setValueModalState(false)}>
                <ModalHeader
                    title={`${valueModalType === 'create' ? 'Add' : 'Edit'} Value`}
                    onClose={() => setValueModalState(false)}
                />
                <ValueSetDetailModal
                    valueSet={valueSetData}
                    valueSetDetail={valueModalType === 'edit' ? editValueSetDetail : {}}
                    onChange={(data: ValueSetDetail) => setValueSetDetailModalData(data)}
                />
                <ModalFooter
                    primaryText={valueModalType === 'create' ? 'Create' : 'Save'}
                    primaryClick={handleValueModalComplete}
                    secondaryClick={() => setValueModalState(false)}
                    isLoading={isLoading}
                />
            </SideModal>
        </PageContainer>
    );
}
export default observer(ValueSetView);
