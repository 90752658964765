import React from 'react';

const SvgCog3 = (props) => (
    <svg width={28} height={28} {...props} viewBox="0 0 28 28">
        <g
            transform="translate(1 1)"
            stroke={props.color || '#FFF'}
            strokeWidth={2.3}
            fill="none"
            fillRule="evenodd"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path d="M11.423 2.642a2.122 2.122 0 003.15 0l1.018-1.129a2.122 2.122 0 013.692 1.531l-.078 1.517a2.121 2.121 0 002.229 2.228l1.516-.078a2.122 2.122 0 011.533 3.697l-1.128 1.018a2.122 2.122 0 000 3.15l1.128 1.019a2.122 2.122 0 01-1.53 3.695l-1.517-.078a2.123 2.123 0 00-2.229 2.228l.078 1.517a2.122 2.122 0 01-3.695 1.53l-1.018-1.128a2.122 2.122 0 00-3.15 0l-1.022 1.13a2.122 2.122 0 01-3.695-1.531l.078-1.517a2.124 2.124 0 00-2.229-2.228l-1.516.078A2.122 2.122 0 011.51 15.6l1.129-1.018a2.122 2.122 0 000-3.15L1.51 10.412a2.122 2.122 0 011.53-3.696l1.518.078a2.123 2.123 0 002.23-2.233l-.079-1.517A2.122 2.122 0 0110.4 1.517l1.023 1.125z" />
            <circle cx={12.998} cy={13.001} r={5} />
        </g>
    </svg>
);

export default SvgCog3;
