import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useParams } from 'react-router';
import PageContainer from 'src/components/PageContainer';
import NavBar from 'src/containers/NavBar';
import styles from './styles.module.scss';
import Flex from 'src/components/Flex';
import * as telehealthService from 'src/api/telehealth';
import * as encounterService from 'src/api/encounters';
import LoadingIcon from 'src/components/LoadingIcon';
import Button from 'src/components/Button';
import Input from 'src/components/Input';
import Age from 'src/components/Age';
import Gender from 'src/components/Gender';
import classNames from 'classnames';
import TabNav from 'src/components/TabNav';
import EncounterItem from 'src/components/EncounterItem';
import LoadingCover from 'src/components/LoadingCover';
import { ROUTES } from 'src/utils/constants';
import TelehealthSessionVideo from 'src/components/TelehealthSessionVideo';
import { ToastStoreObject, ToastType } from 'src/stores/ToastStore';
import * as utils from 'src/utils';
import { TelehealthSession as TelehealthSessionType, SessionStateCodes } from 'src/utils/types';
import { observer } from 'mobx-react';
import { ModalStoreObject, ModalTypes } from 'src/stores/ModalStore';
import Information from '../PatientDetails/Information';
import { DiscreteFormModal } from 'src/components/DiscreteFormModal';

function TelehealthSession() {
    const node = useRef(null);
    const history = useHistory();
    const { telehealthSessionId } = useParams<any>();
    const [isLoading, setIsLoading] = useState(true);
    const [isEncounterLoading, setIsEncounterLoading] = useState(true);
    const [isAddingForm, setIsAddingForm] = useState(false);
    const [editFormData, setEditFormData] = useState(null);
    const [isEditingForm, setIsEditingForm] = useState(false);
    const [selectedTab, setSelectedTab] = useState('info');
    const [sessionState, setSessionState] = useState<TelehealthSessionType>(null);
    const [encounterState, setEncounterState] = useState(null);
    const [noteState, setNoteState] = useState('');
    const [isNoteSaving, setIsNoteSaving] = useState(false);

    async function getSession() {
        try {
            setIsLoading(true);
            const session = await telehealthService.getTelehealthSession(telehealthSessionId);
            setSessionState(session.data || {});
            setIsLoading(false);
        } catch (e) {
            ToastStoreObject.show(utils.parseError(e));
        }
    }

    async function getEncounter() {
        try {
            setIsEncounterLoading(true);
            const encounter = await encounterService.getEncounterById(sessionState.encounterId);
            setEncounterState(encounter);
            setIsEncounterLoading(false);
        } catch (e) {
            ToastStoreObject.show(utils.parseError(e));
        }
    }

    async function saveNote() {
        try {
            setIsNoteSaving(true);
            await telehealthService.saveTelehealthSessionNote(telehealthSessionId, noteState);
            setIsNoteSaving(false);
            ToastStoreObject.show('Note successfully saved.', ToastType.Success);
        } catch (e) {
            ToastStoreObject.show(utils.parseError(e));
        }
    }

    useEffect(() => {
        getSession();
    }, []);

    function getPatientName() {
        return sessionState
            ? ` ${utils.getTeleheathPatientInfo(sessionState, 'lastName')}, ${utils.getTeleheathPatientInfo(
                  sessionState,
                  'firstName',
              )}`
            : '';
    }

    function handleTabChange(tabName: string) {
        if (tabName === 'Encounter') {
            getEncounter();
        }

        setSelectedTab(tabName);
    }

    function handleBack() {
        history.push(ROUTES.getString(ROUTES.TelehealthWaitingRoom));
    }

    async function handleResendInvite(invites: { phone: string | null; email: string | null }) {
        try {
            const data: any = {};
            if (invites.phone) {
                data.patientPhone = invites.phone;
            }
            if (invites.email) {
                data.patientEmail = invites.email;
            }
            if (data.patientPhone || data.patientEmail) {
                const updatedSession = await telehealthService.resendTelehealthSessionInvite(
                    sessionState.telehealthSessionId,
                    data,
                );
                if (updatedSession.data) {
                    setSessionState(updatedSession.data);
                }
            }
        } catch {
            ToastStoreObject.show('There was an error sending the invitation.');
        } finally {
            ModalStoreObject.hideModal();
        }
    }

    const code = sessionState && sessionState.telehealthSessionState.telehealthSessionStateCode;

    function getSelectedTab() {
        switch (selectedTab) {
            case 'Encounter':
                return (
                    <div className={styles.encounter}>
                        {isEncounterLoading ? (
                            <Flex align="center" justify="center">
                                <LoadingIcon />
                            </Flex>
                        ) : (
                            <div>
                                {isAddingForm || isEditingForm ? (
                                    <div>
                                        <div
                                            className={classNames(styles.addFormBack, styles.backWrap)}
                                            onClick={() => {
                                                getEncounter();
                                                setIsAddingForm(false);
                                                setIsEditingForm(false);
                                            }}
                                        >
                                            <span className={styles.backArrow}>{`< `}</span>Back to Encounter
                                        </div>
                                        <div ref={node} className={styles.addForm}>
                                            {isEditingForm ? (
                                                <DiscreteFormModal
                                                    enctrId={editFormData.enctrId}
                                                    facId={editFormData.facId}
                                                    enctrFormId={editFormData.enctrFormId}
                                                    onClose={() => (node.current.scrollTop = 0)}
                                                />
                                            ) : (
                                                <DiscreteFormModal
                                                    enctrId={encounterState.enctrId}
                                                    facId={encounterState.facId}
                                                    onClose={() => (node.current.scrollTop = 0)}
                                                />
                                            )}
                                        </div>
                                    </div>
                                ) : (
                                    <EncounterItem
                                        encounter={encounterState}
                                        opensFormInline={true}
                                        onAddForm={() => setIsAddingForm(true)}
                                        onEditForm={(data: any) => {
                                            setEditFormData(data);
                                            setIsEditingForm(true);
                                        }}
                                    />
                                )}
                            </div>
                        )}
                    </div>
                );
            case 'Notes':
                return (
                    <div className={styles.notes}>
                        <Input
                            label="Session Notes"
                            multiLine={true}
                            value={noteState}
                            onChangeText={(t: string) => setNoteState(t)}
                            placeholder="Enter any notes from the session"
                            className={styles.textarea}
                            rows={15}
                        />
                        {isNoteSaving ? (
                            <LoadingIcon />
                        ) : (
                            <Button
                                type="primary"
                                text="Save Notes"
                                onClick={() =>
                                    noteState !== ''
                                        ? saveNote()
                                        : ToastStoreObject.show('Please add text before saving', ToastType.Warn)
                                }
                            />
                        )}
                    </div>
                );
            default:
                if (sessionState && sessionState.encounter && sessionState.encounter.patient) {
                    return (
                        <Flex direction="column">
                            <Flex className={styles.callReason}>
                                Call Reason: {sessionState.telehealthSessionReason}
                            </Flex>
                            <Information disableEdit={true} patient={sessionState.encounter.patient} />
                        </Flex>
                    );
                } else {
                    return null;
                }
        }
    }

    return (
        <PageContainer>
            <NavBar />
            <div>
                {isLoading ? <LoadingCover style={{ top: 90 }} /> : null}
                <Flex direction="row" className={styles.header} self="stretch" align="center">
                    <Flex direction="row" align="center" value={0} className={styles.backWrap} onClick={handleBack}>
                        <span className={styles.backArrow}>{`< `}</span>Back to Waiting Room
                    </Flex>
                    <Flex direction="row" align="center" justify="end" value={1} className={styles.headerStats}>
                        <Flex>
                            Urgency:
                            <span
                                className={classNames(styles.metadataValue, {
                                    [styles.urgencyLow]: sessionState && sessionState.priorityCode === 'LOW',
                                    [styles.urgencyMedium]: sessionState && sessionState.priorityCode === 'MEDIUM',
                                    [styles.urgencyHigh]: sessionState && sessionState.priorityCode === 'HIGH',
                                    [styles.urgencyUrgent]: sessionState && sessionState.priorityCode === 'URGENT',
                                })}
                            >
                                {sessionState && sessionState.priorityCode}
                            </span>
                        </Flex>
                        <Flex
                            style={{ margin: '0 10px', cursor: 'pointer' }}
                            onClick={() => {
                                history.push(ROUTES.getString(ROUTES.PatientDetails, sessionState.encounter.patientId));
                            }}
                        >
                            Patient:
                            {<span className={styles.subtitle}>{`${getPatientName()}`}</span>}
                        </Flex>
                        <Flex style={{ marginRight: '10px' }}>
                            Age:
                            <Age
                                className={styles.metadataValue}
                                dob={sessionState ? utils.getTeleheathPatientInfo(sessionState, 'dob') : undefined}
                            />
                        </Flex>
                        <Flex>
                            <Gender
                                value={sessionState ? utils.getTeleheathPatientInfo(sessionState, 'gender') : undefined}
                            />
                        </Flex>
                        {code === SessionStateCodes.SESSION_CREATED ||
                        code === SessionStateCodes.CONNECTION_PENDING ||
                        code === SessionStateCodes.CONNECTION_INTERRUPTED ||
                        code === SessionStateCodes.CONNECTION_STARTED ? (
                            <Flex>
                                <div
                                    onClick={() => {
                                        ModalStoreObject.showModal(ModalTypes.ResendInvite, {
                                            onCancel: () => ModalStoreObject.hideModal(),
                                            title: `Resend Invitation Link`,
                                            isOpen: true,
                                            onSave: (data: { phone: string | null; email: string | null }) =>
                                                handleResendInvite(data),
                                            sessionState: sessionState,
                                        });
                                    }}
                                    className={styles.resendBtn}
                                >
                                    Resend Invitation
                                </div>
                            </Flex>
                        ) : null}
                    </Flex>
                </Flex>
                <Flex direction="row">
                    <Flex value={2} className={styles.leftSide}>
                        <div className={styles.tabs}>
                            <TabNav
                                tabs={['Info', 'Encounter']}
                                onTabClick={(tabName: string) => handleTabChange(tabName)}
                            />
                        </div>
                        {getSelectedTab()}
                    </Flex>
                    <Flex value={2} align="start" justify="center" className={styles.rightSide}>
                        {sessionState && (
                            <TelehealthSessionVideo
                                // @ts-ignore
                                session={sessionState}
                                telehealthSessionId={sessionState.telehealthSessionId}
                                onComplete={() => handleBack()}
                                onRefreshSession={(session?: TelehealthSessionType) =>
                                    session && setSessionState(session)
                                }
                            />
                        )}
                    </Flex>
                </Flex>
            </div>
        </PageContainer>
    );
}
export default observer(TelehealthSession);
