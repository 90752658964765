import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import Flex from '../Flex';
import LoadingIcon, { LoadingIconProps } from '../LoadingIcon';

export interface LoadingCoverProps {
    iconProps?: LoadingIconProps;
    className?: string;
    style?: React.CSSProperties;
}

function LoadingCover(props: LoadingCoverProps) {
    return (
        <Flex
            className={classNames(styles.loaderWrap, props.className)}
            style={props.style}
            justify="center"
            align="start"
        >
            <LoadingIcon {...(props.iconProps || {})} />
        </Flex>
    );
}

export default LoadingCover;
