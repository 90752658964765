import React, { useEffect } from 'react';
import Flex from 'src/components/Flex';
import PageContainer from 'src/components/PageContainer';
import PageContent from 'src/components/PageContent';
import NavBar from 'src/containers/NavBar';
import styles from './styles.module.scss';
import TabNav from 'src/components/TabNav';
import { useHistory, useParams } from 'react-router-dom';
import { useFormCatalog } from 'src/queries/useFormCatalog';
import Input, { Label } from 'src/components/Input';
import { FormFacilityMapping } from 'common/lib/entity/framework/FormFacilityMapping';
import { FormCatalog } from 'common/lib/entity/framework/FormCatalog';
import { useFormFacilityMapping } from 'src/queries/useFormFacilityMapping';
import FormCatalogVersionDropDown from 'src/components/FormCatalogVersionsDropDown';
import Button from 'src/components/Button';
import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer-continued';
import { usePrepareFormFacilityMappingForDeployment } from 'src/queries/usePrepareFormFacilityMappingForDeployment';
import { ToastStoreObject, ToastType } from 'src/stores/ToastStore';
import { FormDeployment } from 'common/lib/entity/framework/FormDeployment';
import { useDeployFormFacilityMapping } from 'src/queries/useDeployFormFacilityMapping';
import { ModalStoreObject, ModalTypes } from 'src/stores/ModalStore';
import { ROUTES } from 'src/utils/constants';
import CodeEditor from 'src/components/CodeEditor';
import { LintResult } from 'common/lib/util/formDefinition/linter/LintResult';
import { LintMessage } from 'common/lib/util/formDefinition/linter/LintMessage';
import { DiffCatalogFormAndFacilityFormResult } from 'common/lib/services/framework/FormFacilityMappingService';

const FORM_DEPLOYMENT_TABS = ['Generated Form', 'Linter Results', 'Difference', 'Other Changes'];

function Deployment() {
    const { formCatalogId, formFacilityMappingId } = useParams<{
        formCatalogId: FormCatalog['formCatalogId'];
        formFacilityMappingId: FormFacilityMapping['formFacilityMappingId'];
    }>();
    const history = useHistory();
    const [formCatalogVersionId, setFormCatalogVersionId] = React.useState<string>('');
    const [deploymentDescription, setDeploymentDescription] = React.useState<string>('');
    const [formDeploymentId, setFormDeploymentId] = React.useState<FormDeployment['formDeploymentId']>(null);
    const [isDeploying, setIsDeploying] = React.useState<boolean>(false);
    const [selectedTab, setSelectedTab] = React.useState(FORM_DEPLOYMENT_TABS[0]);
    const { data: formCatalog, isFetching } = useFormCatalog(formCatalogId);
    const { data: formFacilityMappingResult, isFetching: isFetchingFormFacilityMapping } = useFormFacilityMapping(
        formFacilityMappingId,
        true,
    );

    const {
        mutate: prepareFormFacilityMappingForDeployment,
        data: prepareFormFacilityMappingForDeploymentData,
        isLoading: isPrepareFormFacilityMappingForDeploymentLoading,
        isSuccess: isPrepareFormFacilityMappingForDeploymentSuccess,
        isError: isPrepareFormFacilityMappingForDeploymentError,
        error: prepareFormFacilityMappingForDeploymentError,
    } = usePrepareFormFacilityMappingForDeployment(formFacilityMappingId);
    const {
        mutate: deployFormFacilityMapping,
        isLoading: isDeployFormFacilityMappingLoading,
        isSuccess: isDeployFormFacilityMappingSuccess,
        isError: isDeployFormFacilityMappingError,
        error: deployFormFacilityMappingError,
    } = useDeployFormFacilityMapping();

    useEffect(() => {
        if (isPrepareFormFacilityMappingForDeploymentSuccess) {
            setFormDeploymentId(prepareFormFacilityMappingForDeploymentData.formDeploymentId);
            ToastStoreObject.show('Form Catalog Version Prepared for Deployment', ToastType.Success);
        }

        if (isDeployFormFacilityMappingSuccess) {
            ToastStoreObject.show('Form Catalog Version Deployed', ToastType.Success);
            history.push(ROUTES.getString(ROUTES.FormDevelopmentFormDetail, formCatalogId));
        }

        if (isPrepareFormFacilityMappingForDeploymentError) {
            ToastStoreObject.show(prepareFormFacilityMappingForDeploymentError.message, ToastType.Error);
        }

        if (isDeployFormFacilityMappingError) {
            ToastStoreObject.show(deployFormFacilityMappingError.message, ToastType.Error);
        }
    }, [
        isPrepareFormFacilityMappingForDeploymentSuccess,
        isPrepareFormFacilityMappingForDeploymentError,
        isDeployFormFacilityMappingSuccess,
        isDeployFormFacilityMappingError,
    ]);

    useEffect(() => {
        setFormDeploymentId(null);
    }, [formCatalogId, formFacilityMappingId]);

    function handleBack() {
        history.goBack();
    }

    function handlePreDeploy() {
        if (formFacilityMappingId) {
            prepareFormFacilityMappingForDeployment({
                formCatalogVersionId,
                deploymentDescription,
            });
        }
    }

    function handleCheckDeploy() {
        ModalStoreObject.showModal(ModalTypes.ConfirmationModal, {
            headerTitle: 'Deploy?',
            title:
                'Are you sure you want to deploy this Form Catalog Version? Please double check you are deploying to the correct organization and facility.',
            confirmButtonText: 'Deploy',
            onConfirm: () => {
                setIsDeploying(true);
                handleDeploy();
                ModalStoreObject.hideModal();
            },
            onCancel: () => ModalStoreObject.hideModal(),
        });
    }

    /*
    For some reason I can't seem to get annotations to work in the editor. Need to come back to this but for now
    I am just going to use a separate tab to list the linter results.
    function getAnnotationsForLintResults(linterResults: LintResult): IAnnotation[] {
        return [
            {
                row: 0,
                column: 0,
                text: 'Error: This is a test error',
                type: 'error'
            }
        ]
    }
    */

    async function handleDeploy() {
        if (formDeploymentId) {
            await deployFormFacilityMapping({
                formDeploymentId,
            });
        }
    }

    function getFaultsContent(diffResult?: DiffCatalogFormAndFacilityFormResult) {
        if (diffResult?.faults?.length > 0) {
            return (
                <div className={styles.faults}>
                    <div className={styles.label}>
                        ERROR: Found ({diffResult.faults.length}) faults, cannot deploy this form:
                    </div>
                    {diffResult?.faults?.map((fault) => {
                        return <div className={styles.fault}>{fault}</div>;
                    })}
                </div>
            );
        } else {
            return null;
        }
    }

    function hasLinterFatalMessages(formCatalog: FormCatalog): boolean {
        const fatalMessages = formCatalog?.linterResults?.messages.filter((message: any) => message.severity === 'fatal');
        return fatalMessages?.length > 0;
    }

    function predeployHasFaultsOrFatalMessages(deploymentDetails:FormDeployment["deploymentDetails"]): boolean {
        return deploymentDetails?.diffResult?.faults?.length > 0 || 
            deploymentDetails?.linterResults?.messages?.some((message: LintMessage) => message.severity === 'fatal');
    }

    function getContent() {
        switch (selectedTab) {
            case 'Generated Form':
                return (
                    <CodeEditor
                        minLines={30}
                        maxLines={60}
                        mode="xml"
                        theme="xcode"
                        value={prepareFormFacilityMappingForDeploymentData?.deploymentDetails?.formContentGenerated}
                        //annotations={getAnnotationsForLintResults(prepareFormFacilityMappingForDeploymentData?.deploymentDetails?.linterResults)}
                    />
                );
            case 'Difference':
            default:
                return (
                    <div className={styles.deployTabContent}>
                        <ReactDiffViewer
                            compareMethod={DiffMethod.WORDS_WITH_SPACE}
                            leftTitle="Current Org Form Content"
                            rightTitle="To be Deployed"
                            oldValue={formFacilityMappingResult?.formFacilityMapping.formDefinition?.formDefnCntnt}
                            newValue={
                                prepareFormFacilityMappingForDeploymentData?.deploymentDetails?.formContentGenerated
                            }
                            extraLinesSurroundingDiff={0}
                            splitView={false}
                            styles={{
                                line: {
                                    'font-size': '12px',
                                },
                            }}
                        />
                    </div>
                );
            case 'Linter Results':
                return prepareFormFacilityMappingForDeploymentData?.deploymentDetails?.linterResults.messages.map(
                    (lintMessage: LintMessage) => {
                        return (
                            <div className={styles.linterResult}>
                                <span className={styles.lintSeverity}>({lintMessage.severity})</span>
                                <span className={styles.lintRule}>{lintMessage.ruleId}</span>
                                <span className={styles.lintMessage}>{lintMessage.message}</span>
                                <span className={styles.lintPosition}>
                                    (Line: {lintMessage.line}, Col: {lintMessage.column})
                                </span>
                            </div>
                        );
                    },
                );
            case 'Other Changes':
                const diffResult = prepareFormFacilityMappingForDeploymentData?.deploymentDetails?.diffResult;
                return (
                    <div className={styles.diffResult}>
                        <div className={styles.singleLineValue}>
                            <div className={styles.label}>Form Action:</div>
                            <div className={styles.labelValue}>{diffResult?.formDefinitionAction?.toUpperCase()}</div>
                        </div>
                        <div className={styles.singleLineValue}>
                            <div className={styles.label}>Form Title Changed:</div>
                            <div className={styles.labelValue}>
                                {diffResult?.formDefinitionTitleChanged ? 'Yes' : 'No'}
                            </div>
                            {diffResult?.formDefinitionTitleChanged && (
                                <div className={styles.labelValue}>
                                    {diffResult?.oldFormDefinitionTitle} &#x21D2; {diffResult?.newFormDefinitionTitle}
                                </div>
                            )}
                        </div>
                        <div className={styles.singleLineValue}>
                            <div className={styles.label}>Form Content Changed:</div>
                            <div className={styles.labelValue}>
                                {diffResult?.formDefinitionContentChanged ? 'Yes' : 'No'}
                            </div>
                        </div>
                        <div>
                            <h2>Page Changes</h2>
                            {Object.entries(diffResult?.pageResults).map(([pageName, page]) => {
                                return (
                                    <div className={styles.pageResult}>
                                        <div className={styles.pageName}>Page: {pageName}</div>
                                        <div>Page Action: {page.pageAction}</div>
                                        <div>
                                            <span>
                                                Min Count Changed: {page.minCountChanged === true ? 'Yes' : 'No'}{' '}
                                            </span>
                                            {page.minCountChanged && (
                                                <span>
                                                    ({page.oldPageMinCount} &#x21D2; {page.newPageMinCount})
                                                </span>
                                            )}
                                        </div>
                                        <div>
                                            <span>
                                                Max Count Changed: {page.maxCountChanged === true ? 'Yes' : 'No'}{' '}
                                            </span>
                                            {page.maxCountChanged && (
                                                <span>
                                                    ({page.oldPageMaxCount} &#x21D2; {page.newPageMaxCount})
                                                </span>
                                            )}
                                        </div>
                                        <div>
                                            Background Image Changed: {page.pageBackgroundImageChanged ? 'Yes' : 'No'}{' '}
                                            {page.pageBackgroundImageChanged && (
                                                <span>
                                                    Num Pixels Changed: {page.pageBackgroundImageNumPixelsChanged}
                                                </span>
                                            )}
                                        </div>
                                        <div>Diff Image Id: {page.pageBackgroundDiffImageId}</div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                );
        }
    }

    return (
        <PageContainer>
            <NavBar />
            <PageContent>
                <Flex align="center" className={styles.detailHeader}>
                    <div className={styles.backWrap} onClick={() => handleBack()}>
                        <span className={styles.backArrow}>{`< `}</span>
                    </div>
                    <h1 className={styles.formCatalogName}>{`${formCatalog?.formName} - Deployment` || ''}</h1>
                </Flex>
                <Flex className={styles.deployContainer} gap={16}>
                    <div className={styles.deployInfo}>
                        <Flex className={styles.deployOrg}>
                            <div>
                                <Label text="Organization" className={styles.labelWrap} />
                                <div className={styles.deployText}>
                                    {formFacilityMappingResult?.formFacilityMapping?.orgName}
                                </div>
                            </div>
                            <div>
                                <Label text="Facility" className={styles.labelWrap} />
                                <div className={styles.deployText}>
                                    {formFacilityMappingResult?.formFacilityMapping?.facilityName}
                                </div>
                            </div>
                            <div>
                                <Label text="Form" className={styles.labelWrap} />
                                <div className={styles.deployText}>
                                    {formFacilityMappingResult?.formFacilityMapping?.formDefinition && (
                                        <span>
                                            {formFacilityMappingResult.formFacilityMapping.formDefinition.formDefnTitle}{' '}
                                            ({formFacilityMappingResult.formFacilityMapping.formDefinition.formDefnNm})
                                        </span>
                                    )}
                                    {formFacilityMappingResult?.formFacilityMapping?.formDefinition == null && (
                                        <span>N/A - Will be Created</span>
                                    )}
                                </div>
                            </div>
                        </Flex>
                        <Label text="Form Catalog Version to Deploy" className={styles.labelWrap} />
                        <FormCatalogVersionDropDown
                            formCatalogId={formCatalogId}
                            onChange={(v: { label: string; value: string }) => setFormCatalogVersionId(v.value)}
                            selectedValue={formCatalogVersionId}
                            disabled={formDeploymentId != null}
                        />
                        <Input
                            label="Deploy Description"
                            value={deploymentDescription}
                            onChangeText={(v: string) => setDeploymentDescription(v)}
                            disabled={formDeploymentId != null}
                        />
                    </div>
                    <Flex direction="column" align="center" justify="center" className={styles.deployControls} gap={16}>
                        <div>
                            <Button
                                type="secondary"
                                text="Prepare Deployment"
                                onClick={handlePreDeploy}
                                disabled={
                                    !formCatalogVersionId ||
                                    isFetching ||
                                    isPrepareFormFacilityMappingForDeploymentLoading ||
                                    isFetchingFormFacilityMapping ||
                                    formDeploymentId != null ||
                                    isDeploying
                                }
                                isLoading={
                                    isFetching ||
                                    isFetchingFormFacilityMapping ||
                                    isPrepareFormFacilityMappingForDeploymentLoading ||
                                    isDeploying
                                }
                                className={styles.bigButton}
                            />
                        </div>
                        <div>
                            <Button
                                type="primary"
                                text="Deploy"
                                onClick={handleCheckDeploy}
                                disabled={
                                    predeployHasFaultsOrFatalMessages(prepareFormFacilityMappingForDeploymentData?.deploymentDetails) || isFetching || 
                                    isFetchingFormFacilityMapping || !formDeploymentId || isDeploying
                                }
                                isLoading={isFetching || isFetchingFormFacilityMapping || isDeploying}
                                className={styles.bigButton}
                            />
                        </div>
                        <div className={styles.darkYellow} hidden={!hasLinterFatalMessages(formCatalog) || !predeployHasFaultsOrFatalMessages(prepareFormFacilityMappingForDeploymentData?.deploymentDetails)}>This form has fatal linter errors or faults. Please fix them before publishing a new version.</div>
                    </Flex>
                </Flex>
                {getFaultsContent(prepareFormFacilityMappingForDeploymentData?.deploymentDetails?.diffResult)}
                <div className={styles.tabContainer}>
                    <TabNav
                        tabs={FORM_DEPLOYMENT_TABS}
                        onTabClick={(tab: string) => {
                            setSelectedTab(tab);
                        }}
                    />
                </div>
                {formDeploymentId != null ? (
                    getContent()
                ) : (
                    <div className={styles.needsPreDeploy}>
                        Prepare Your Deployment to view Differences and Other Changes
                    </div>
                )}
            </PageContent>
        </PageContainer>
    );
}

export default Deployment;
