import React from 'react';

const SvgNote = (props) => (
    <svg width={33} height={33} {...props} viewBox="0 0 33 33">
        <g transform="translate(1 1)" fill="none" fillRule="evenodd">
            <circle stroke="#F1F0F0" fill="#F9F9F9" cx={15.5} cy={15.5} r={15.5} />
            <g fill="#DA1057" fillRule="nonzero">
                <path d="M17.07 20.68a.177.177 0 00-.14-.28h-6.336a.176.176 0 01-.177-.181v-8.854c0-.098.079-.178.177-.178h1.062c.098 0 .177.08.177.178v.177a.708.708 0 001.417 0v-.177c0-.098.08-.177.177-.177h1.063c.097 0 .177.079.177.177v.177a.708.708 0 001.416 0v-.177c0-.098.08-.177.177-.177h1.063c.098 0 .177.079.177.177v.177a.708.708 0 001.417 0v-.177c0-.098.079-.177.177-.177h.708c.098 0 .177.079.177.177v5.892a.177.177 0 00.303.125l1.062-1.063a.178.178 0 00.052-.125v-5.006c0-.783-.634-1.417-1.417-1.417h-.885a.176.176 0 01-.177-.177v-.886a.708.708 0 00-1.417 0v.886a.176.176 0 01-.177.177H16.26a.176.176 0 01-.177-.177v-.886a.708.708 0 10-1.416 0v.886a.176.176 0 01-.177.177h-1.063a.176.176 0 01-.177-.177v-.886a.708.708 0 00-1.417 0v.886a.176.176 0 01-.177.177h-1.24C9.635 9.77 9 10.405 9 11.188v9.208c0 .782.634 1.416 1.417 1.416h5.76c.056 0 .108-.028.14-.074l.754-1.059zM25.316 16.21a1.92 1.92 0 00-2.554.194l-4.66 4.66a.177.177 0 000 .251l2.373 2.375a.177.177 0 00.25 0l4.732-4.73a1.858 1.858 0 00-.14-2.75zM17.543 22.26a.176.176 0 00-.298.09l-.446 2.225a.354.354 0 00.418.418l2.227-.446a.177.177 0 00.09-.299l-1.99-1.989z" />
            </g>
        </g>
    </svg>
);

export default SvgNote;
