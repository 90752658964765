import React, { ReactNode } from 'react';
import classNames from 'classnames';
import Portal from '../Portal';
import styles from './styles.module.scss';
import Card from '../Card';
import Flex from '../Flex';
import useEscapeKey from 'src/utils/hooks/useEscapeKey';
import AnimatedComponent from '../AnimatedComponent';

interface Props {
    isOpen: boolean;
    children: ReactNode;
    onClose?: Function;
    cardStyle?: any;
}

function CenterModal(props: Props) {
    useEscapeKey(props.onClose);

    if (!props.isOpen) {
        return null;
    }
    return (
        <Portal>
            <AnimatedComponent type="fadeIn">
                <Flex align="center" justify="center" className={styles.overlay}>
                    <AnimatedComponent type="scaleCenter">
                        <Card elevation={4} className={classNames([styles.content, props.cardStyle])}>
                            {props.children}
                        </Card>
                    </AnimatedComponent>
                </Flex>
            </AnimatedComponent>
        </Portal>
    );
}

export default CenterModal;
