import React, { useState, useEffect } from 'react';
import styles from './styles.module.scss';
import Select from 'react-select';
import * as utils from 'src/utils';
import { Facility } from 'common/lib/entity/org/Facility';
import { useFacilitiesByOrg } from 'src/queries/useFacilitiesByOrg';
import { Org } from 'common/lib/entity/index/Org';

export interface FacilitiesByOrgDropDownProps {
    orgNameInternal: Org['orgNmIntrnl'];
    onChange: Function;
    selectedValue?: string | number;
    disabled?: boolean;
    isClearable?: boolean;
}

function FacilitiesByOrgDropDown(props: FacilitiesByOrgDropDownProps) {
    const { data: facilitiesByOrg, isFetching, refetch: getFacilitiesByOrg } = useFacilitiesByOrg(
        props.orgNameInternal,
    );
    const [options, setOptions] = useState([]);
    const [selected, setSelected] = useState(null);

    useEffect(() => {
        if (facilitiesByOrg) {
            const opts = facilitiesByOrg.map((fac: Facility) => ({
                label: fac.facNm,
                value: fac.facId,
            }));
            setOptions(opts);
            setSelected(opts.find((i) => i.value === props.selectedValue) || null);
        }
    }, [facilitiesByOrg]);

    useEffect(() => {
        const selectedVal = options.find((i) => i.value === props.selectedValue);
        if (selectedVal) {
            setSelected(selectedVal);
        }
    }, [props.selectedValue, options]);

    useEffect(() => {
        getFacilitiesByOrg();
    }, [props.orgNameInternal]);

    return (
        <Select
            value={selected}
            onChange={(s: any) => {
                setSelected(s);
                props.onChange(s || null);
            }}
            isClearable={props.isClearable}
            isLoading={isFetching}
            className={styles.select}
            options={options}
            placeholder="Select Facility"
            styles={utils.styleSelectComponent()}
            isDisabled={props.disabled || isFetching}
        />
    );
}

export default FacilitiesByOrgDropDown;
