import { CustomerType } from 'common/lib/entity/index/Customer';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import Select from 'react-select';
import { styleSelectComponent } from 'src/utils';
import styles from './styles.module.scss';

export interface CustomerTypeDropDownProps {
    onChange: Function;
    selectedValue?: CustomerType;
}

function CustomerTypeDropdown(props: CustomerTypeDropDownProps) {
    const options = mapTypeOptions();
    const [selected, setSelected] = useState(options.find((i) => i.value === props.selectedValue) || null);

    function mapTypeOptions() {
        const types = Object.values(CustomerType);

        const typeOptions = types.map((value) => {
            let label;
            switch (value) {
                case CustomerType.AmbulatorySurgery:
                    label = 'Ambulatory Surgery Center';
                    break;
                case CustomerType.AnesthesiaGroup:
                    label = 'Anesthesia Group';
                    break;
                case CustomerType.MultiSpecialty:
                    label = 'Multi-Specialty Group';
                    break;
                case CustomerType.Billing:
                    label = 'Billing Company';
                    break;
                case CustomerType.Dental:
                    label = 'Dental Anesthesia Practice';
                    break;
                case CustomerType.Hospital:
                    label = 'Hospital';
                    break;
            }
            return { value, label };
        });

        return typeOptions.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
    }

    return (
        <Select
            value={selected}
            onChange={(s: any) => {
                setSelected(s);
                props.onChange(s || null);
            }}
            isClearable={true}
            className={styles.select}
            options={options}
            placeholder="Select Type"
            styles={styleSelectComponent()}
        />
    );
}

export default observer(CustomerTypeDropdown);
