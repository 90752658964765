import React, { useState, useEffect, useContext, useRef } from 'react';
import ReactDOM from 'react-dom';
import ReactDOMServer from 'react-dom/server';
import classNames from 'classnames';
import FullCalendar from '@fullcalendar/react';
import timeGrid from '@fullcalendar/timegrid';
import dayGrid from '@fullcalendar/daygrid';
import interaction from '@fullcalendar/interaction';
import NavBar from 'src/containers/NavBar';
import Button from 'src/components/Button';
import PageContainer from 'src/components/PageContainer';
import { observer } from 'mobx-react';
import './calendar.scss';
import CalendarEvent, { TaskIcon, AppointmentIcon } from 'src/components/CalendarEvent';
import { CalendarEventInterface } from 'src/utils/types';
import EventStore, { eventStatusOptions, NO_TEAM_ID } from 'src/stores/EventStore';
import Flex from 'src/components/Flex';
import styles from './styles.module.scss';
import Select from 'react-select';
import TeamStore, { NO_TEAM_COLOR } from 'src/stores/TeamStore';
import Checkbox from 'src/components/Checkbox';
import * as constants from 'src/utils/constants';
import Icon from 'src/components/Icon';
import EventStateStore from 'src/stores/EventStateStore';
import EventDetailsModal from 'src/components/EventDetailsModal';
import { DateInput } from '@fullcalendar/core/datelib/env';
import CalendarHeader from 'src/components/CalendarHeader';
import TeamFilterItem from './TeamFilterItem/index';
import FacilityDropDown from 'src/components/FacilityDropDown';
import FacilityStore, { Facility } from 'src/stores/FacilityStore';
import { ANALYTICS_NAMES } from 'src/utils/analytics';
import * as variables from 'src/styles/variables';
import Accordion from 'src/components/Accordion';
import { format, subMonths, addMonths } from 'date-fns';
import * as utils from 'src/utils';
import { ErrorStoreObject, ErrorTypes } from 'src/stores/ErrorStore';
import LoadingCover from 'src/components/LoadingCover';
import { ModalStoreObject, ModalTypes } from 'src/stores/ModalStore';
import UserStore from 'src/stores/UserStore';
import OrgUserStore, { OrgUser } from 'src/stores/OrgUserStore';
import UserFilterItem from './UserFilterItem';

export enum CalendarViewTypes {
    Monthly = 'Monthly',
    Weekly = 'Weekly',
    Daily = 'Daily',
}

export const viewOptions: Array<{ value: CalendarViewTypes; label: string }> = [
    { value: CalendarViewTypes.Weekly, label: 'Weekly View' },
    { value: CalendarViewTypes.Monthly, label: 'Monthly View' },
    { value: CalendarViewTypes.Daily, label: 'Daily View' },
];

function getStartOfMonth(d: Date) {
    return new Date(d.getFullYear(), d.getMonth(), 1);
}
function getEndOfMonth(d: Date) {
    return new Date(d.getFullYear(), d.getMonth() + 1, 0);
}

const DATE_FILTER_MONTH_STEP = 0;
const now = new Date();
const startingFirstDay = format(
    getStartOfMonth(new Date(now.getFullYear(), now.getMonth())),
    constants.API_DATE_FORMAT,
);
const startingLastDay = format(getEndOfMonth(new Date(now.getFullYear(), now.getMonth())), constants.API_DATE_FORMAT);

function Appointments() {
    const [dayFilters, setDayFilters] = useState({
        firstDay: startingFirstDay,
        lastDay: startingLastDay,
        earliestDay: startingFirstDay,
        latestDay: startingLastDay,
    });
    const eventStore = useContext(EventStore);
    const userStore = useContext(UserStore);
    const orgUserStore = useContext(OrgUserStore);
    const eventStateStore = useContext(EventStateStore);
    const teamStore = useContext(TeamStore);
    const facilityStore = useContext(FacilityStore);
    const events = eventStore.calendarEvents;

    const teams = teamStore.teamsWithColor;

    const calendarRef = useRef(null);
    const [viewEventModal, setViewEventModal] = useState<object>(null);
    const [displayedEvents, setDisplayedEvents] = useState<Array<CalendarEventInterface>>([]);
    const [teamCounts, setTeamCounts] = useState<any>({});
    const [userCounts, setUserCounts] = useState<any>({});
    const [isLoading, setIsLoading] = useState(false);
    const [currentDays, setCurrentDays] = useState({
        firstDay: startingFirstDay,
        lastDay: startingLastDay,
    });

    async function loadMoreEvents() {
        await eventStore.getMoreEvents({ firstDay: dayFilters.firstDay, lastDay: dayFilters.lastDay });
    }

    async function loadData(facId?: Facility['facId']) {
        try {
            setIsLoading(true);
            await Promise.all([
                // Run these 2 requests at the same time
                teamStore.getTeams({ filters: { facId } }),
                eventStore.getEvents({
                    filters: { facId, firstDay: dayFilters.firstDay, lastDay: dayFilters.lastDay },
                }),
                orgUserStore.getOrgUsers(),
            ]);
            // Reset day filters (earliest/latest) if we reload data
            setDayFilters({ ...dayFilters, earliestDay: dayFilters.firstDay, latestDay: dayFilters.lastDay });
            setDisplayedEvents(filterEvents(eventStore.calendarEvents));
            eventStateStore.getEventStates();

            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            ErrorStoreObject.setError(ErrorTypes.Loading);
        }
    }

    useEffect(() => {
        if (!eventStore.selectedFacilityId) {
            changeFacility(facilityStore.defaultFacilityId);
        }
    }, [facilityStore.defaultFacilityId]);

    useEffect(() => {
        // Only load more events with the day filters when the selected facility is set
        if (eventStore.selectedFacilityId) {
            loadMoreEvents();
        }
    }, [dayFilters.firstDay, dayFilters.lastDay]); // Only reload when first/last days change

    useEffect(() => {
        setDisplayedEvents(filterEvents(events));
    }, [
        eventStore.checkedTeams,
        eventStore.checkedUsers,
        eventStore.isTasksChecked,
        eventStore.isAppointmentsChecked,
        eventStore.calendarEvents,
        eventStore.eventStatusOption,
    ]);

    function filterEvents(events: CalendarEventInterface[]) {
        return events.filter((e: CalendarEventInterface) => {
            const ghEvent = e.extendedProps.ghEvent;
            const { eventClassCode, assigneeTeamId, assigneeUsrId } = ghEvent;
            if (!eventStore.isTasksChecked && eventClassCode === constants.EventClassCode.TASK) {
                return false;
            }
            if (!eventStore.isAppointmentsChecked && eventClassCode === constants.EventClassCode.APPOINTMENT) {
                return false;
            }
            if (eventStore.eventStatusOption.value === 'Complete' && !eventStateStore.isCompleted(ghEvent)) {
                return false;
            }
            if (eventStore.eventStatusOption.value === 'Incomplete') {
                if (!eventStateStore.isOpen(ghEvent) && !eventStateStore.isRescheduled(ghEvent)) {
                    return false;
                }
            }
            if (eventStore.eventStatusOption.value === 'Canceled' && !eventStateStore.isCanceled(ghEvent)) {
                return false;
            }
            if (eventStore.eventStatusOption.value === 'Open' && !eventStateStore.isOpen(ghEvent)) {
                return false;
            }
            if (eventStore.eventStatusOption.value === 'Rescheduled' && !eventStateStore.isRescheduled(ghEvent)) {
                return false;
            }

            if (assigneeTeamId && !eventStore.checkedTeams.includes(assigneeTeamId)) {
                return false;
            } else if (!assigneeTeamId && !eventStore.checkedTeams.includes(NO_TEAM_ID)) {
                return false;
            }

            // @ts-ignore
            const actualUsrId = parseInt(assigneeUsrId);
            if (actualUsrId && !eventStore.checkedUsers.includes(actualUsrId)) {
                return false;
            }

            return true;
        });
    }

    function countTeamsAndUsers(countingEvents: CalendarEventInterface[]) {
        const teamCountsObj: any = { [NO_TEAM_ID]: 0 };
        const userCountsObj: any = {};
        // Init all team counts to 0
        teams.forEach((t) => (teamCountsObj[t.teamId] = 0));
        orgUserStore.orgUsersWithColor.forEach((u) => (userCountsObj[u.usrId] = 0));

        countingEvents.forEach((event) => {
            const teamId = event.extendedProps.ghEvent.assigneeTeamId || NO_TEAM_ID;
            const userId = event.extendedProps.ghEvent.assigneeUsrId || null;
            teamCountsObj[teamId] = (teamCountsObj[teamId] || 0) + 1;
            if (userId) userCountsObj[userId] = (userCountsObj[userId] || 0) + 1;
        });

        setTeamCounts(teamCountsObj);
        setUserCounts(userCountsObj);
    }

    useEffect(() => {
        countTeamsAndUsers(events);
    }, [eventStore.calendarEvents, teams, orgUserStore.orgUsersWithColor]);

    useEffect(() => {
        let eventsToCount = events;
        if (
            !eventStore.isTasksChecked ||
            !eventStore.isAppointmentsChecked ||
            eventStore.eventStatusOption.value !== 'All'
        ) {
            eventsToCount = displayedEvents;
        }
        countTeamsAndUsers(eventsToCount);
    }, [displayedEvents]);

    useEffect(() => {
        if (calendarRef && calendarRef.current) {
            const calendarApi = calendarRef.current.getApi();
            const value = eventStore.selectedView.value;
            if (value === CalendarViewTypes.Monthly) {
                calendarApi.changeView('dayGridMonth');
            } else if (value === CalendarViewTypes.Weekly) {
                calendarApi.changeView('timeGridWeek');
            } else if (value === CalendarViewTypes.Daily) {
                calendarApi.changeView('timeGridDay');
            }
        }
    }, [eventStore.selectedView]);

    useEffect(() => {
        if (eventStore.selectedFacilityId) {
            setDayFilters({
                firstDay: currentDays.firstDay,
                lastDay: currentDays.lastDay,
                earliestDay: currentDays.firstDay,
                latestDay: currentDays.lastDay,
            });
            loadData(eventStore.selectedFacilityId);
        }
    }, [eventStore.selectedFacilityId]);

    function changeFacility(facId: Facility['facId']) {
        if (facId && eventStore.selectedFacilityId !== facId) {
            eventStore.setFacilityId(facId);
        }
    }

    // Set the day filter for the new first/last day and then also reset the earliest/latest queried day
    function setFilterForDay({ firstDay, lastDay }: { firstDay: string; lastDay: string }) {
        const d1 = new Date(firstDay);
        const d2 = new Date(lastDay);
        const earliestDate = new Date(dayFilters.earliestDay);
        const latestDate = new Date(dayFilters.latestDay);
        setDayFilters({
            firstDay: firstDay,
            lastDay: lastDay,
            earliestDay: d1 < earliestDate ? format(d1, constants.API_DATE_FORMAT) : dayFilters.earliestDay,
            latestDay: d2 > latestDate ? format(d2, constants.API_DATE_FORMAT) : dayFilters.latestDay,
        });
    }

    return (
        <PageContainer>
            <NavBar />
            <Flex direction="row">
                <Flex value={1} className={styles.leftSide}>
                    <div className={styles.filtersLabel}>Filters</div>
                    <Accordion startOpen={true} label="Facility" removeExtraSpacing={true}>
                        <div className={styles.selectView}>
                            <FacilityDropDown
                                selectedValue={eventStore.selectedFacilityId}
                                onChange={(data: any) => changeFacility(data.value)}
                            />
                        </div>
                    </Accordion>
                    <Accordion label="Type" removeExtraSpacing={true}>
                        <Flex direction="column">
                            <Checkbox
                                data-test-id={ANALYTICS_NAMES.Appointments_FilterTasks}
                                checked={eventStore.isTasksChecked}
                                onChange={(e) => eventStore.setTasksChecked(e.target.checked)}
                                label={
                                    <Flex direction="row" className={styles.checkBoxLabel}>
                                        <Icon name={TaskIcon} className={styles.checkboxIcon} color={variables.black} />
                                        <span>Tasks</span>
                                    </Flex>
                                }
                                className={styles.typeCheckbox}
                            />
                            <Checkbox
                                data-test-id={ANALYTICS_NAMES.Appointments_FilterAppointments}
                                checked={eventStore.isAppointmentsChecked}
                                onChange={(e) => eventStore.setAppointmentsChecked(e.target.checked)}
                                label={
                                    <Flex direction="row" className={styles.checkBoxLabel}>
                                        <Icon
                                            name={AppointmentIcon}
                                            className={styles.checkboxIcon}
                                            color={variables.black}
                                        />
                                        <span>Appointments</span>
                                    </Flex>
                                }
                                className={styles.typeCheckbox}
                            />
                        </Flex>
                    </Accordion>
                    <Accordion label="Status" removeExtraSpacing={true}>
                        <div className={styles.selectView}>
                            <Select
                                data-test-id={ANALYTICS_NAMES.Appointments_Status}
                                value={eventStore.eventStatusOption}
                                className={styles.select}
                                options={eventStatusOptions}
                                onChange={(data: any) => eventStore.setEventStatusOption(data)}
                                styles={utils.styleSelectComponent()}
                            />
                        </div>
                    </Accordion>
                    <Accordion startOpen={false} label="Assigned User" removeExtraSpacing={true}>
                        <div className={styles.selectControls}>
                            <div className={styles.title}>Select:</div>
                            <Button
                                className={styles.button}
                                data-test-id={ANALYTICS_NAMES.Appointments_SelectAllUsers}
                                type="small"
                                text="All"
                                onClick={() => eventStore.selectAllUsers(orgUserStore.orgUsersWithColor)}
                            />
                            <Button
                                className={styles.button}
                                data-test-id={ANALYTICS_NAMES.Appointments_SelectNoneUsers}
                                type="secondarySmall"
                                text="None"
                                onClick={() => eventStore.deselectAllUsers()}
                            />
                        </div>
                        {orgUserStore.orgUsersWithColor.map((u: OrgUser) => {
                            return (
                                <UserFilterItem
                                    key={u.usrId}
                                    count={userCounts[u.usrId]}
                                    user={u}
                                    isChecked={eventStore.checkedUsers.includes(u.usrId)}
                                    onChange={(newIsChecked) => {
                                        if (newIsChecked) {
                                            eventStore.selectUser(u.usrId);
                                        } else {
                                            eventStore.deselectUser(u.usrId);
                                        }
                                    }}
                                />
                            );
                        })}
                    </Accordion>
                    <Accordion startOpen={true} label="Assigned Team" removeExtraSpacing={true}>
                        <div className={styles.selectControls}>
                            <div className={styles.title}>Select:</div>
                            <Button
                                className={styles.button}
                                data-test-id={ANALYTICS_NAMES.Appointments_SelectAllTeams}
                                type="small"
                                text="All"
                                onClick={() => eventStore.selectAllTeams(teams)}
                            />
                            <Button
                                className={styles.button}
                                data-test-id={ANALYTICS_NAMES.Appointments_SelectNoneTeams}
                                type="secondarySmall"
                                text="None"
                                onClick={() => eventStore.deselectAllTeams()}
                            />
                        </div>
                        {teams.map((t) => {
                            const id = t.teamId;
                            return (
                                <TeamFilterItem
                                    key={id}
                                    count={teamCounts[id]}
                                    team={t}
                                    isChecked={eventStore.checkedTeams.includes(id)}
                                    onChange={(newIsChecked) => {
                                        if (newIsChecked) {
                                            eventStore.selectTeam(id);
                                        } else {
                                            eventStore.deselectTeam(id);
                                        }
                                    }}
                                />
                            );
                        })}
                        <TeamFilterItem
                            count={teamCounts[NO_TEAM_ID]}
                            team={{ teamName: 'No Team', color: NO_TEAM_COLOR }}
                            isChecked={eventStore.checkedTeams.includes(NO_TEAM_ID)}
                            onChange={(newIsChecked) => {
                                if (newIsChecked) {
                                    eventStore.selectTeam(NO_TEAM_ID);
                                } else {
                                    eventStore.deselectTeam(NO_TEAM_ID);
                                }
                            }}
                        />
                    </Accordion>
                </Flex>
                <Flex value={3} className={styles.content} style={{ width: '80%' }}>
                    <Flex direction="row" align="center">
                        <Flex value={1}>
                            <Select
                                data-test-id={ANALYTICS_NAMES.Appointments_SelectView}
                                className={styles.viewSelectDropdown}
                                value={eventStore.selectedView}
                                options={viewOptions}
                                menuPortalTarget={document.body}
                                onChange={(data: any) => eventStore.setSelectedView(data)}
                                styles={utils.styleSelectComponent()}
                            />
                        </Flex>
                        <Flex>
                            <Button
                                type="outline"
                                data-test-id={ANALYTICS_NAMES.Appointments_CreateTask}
                                text="New Task"
                                isLocked={!userStore.userPermissions.canEdit.events}
                                leftIcon={
                                    <Icon
                                        name={constants.ICONS.PlusButton}
                                        color={variables.red}
                                        className={styles.buttonIcon}
                                    />
                                }
                                className={classNames(styles.newButton, styles.newTaskButton)}
                                onClick={() => {
                                    ModalStoreObject.showModal(ModalTypes.CreateEvent, {
                                        type: 'task',
                                        onClose: () => ModalStoreObject.hideModal(),
                                        onSave: () => ModalStoreObject.hideModal(),
                                    });
                                }}
                            />
                            <Button
                                type="primary"
                                data-test-id={ANALYTICS_NAMES.Appointments_CreateAppointment}
                                text="New Appointment"
                                isLocked={!userStore.userPermissions.canEdit.events}
                                leftIcon={<Icon name={constants.ICONS.PlusButton} className={styles.buttonIcon} />}
                                className={styles.newButton}
                                onClick={() => {
                                    ModalStoreObject.showModal(ModalTypes.CreateEvent, {
                                        type: 'appointment',
                                        onClose: () => ModalStoreObject.hideModal(),
                                        onSave: () => ModalStoreObject.hideModal(),
                                    });
                                }}
                            />
                        </Flex>
                    </Flex>
                    <div className={styles.calendarWrap}>
                        {isLoading ? <LoadingCover /> : null}
                        <FullCalendar
                            ref={calendarRef}
                            defaultView="timeGridWeek"
                            plugins={[timeGrid, dayGrid, interaction]}
                            minTime="05:00:00"
                            maxTime="22:00:00"
                            nowIndicator={true}
                            eventLimit={2}
                            height="auto"
                            header={{
                                left: 'title',
                                center: '',
                                right: 'today, prev,next',
                            }}
                            buttonText={{
                                today: 'Today',
                            }}
                            allDaySlot={false}
                            datesRender={(info) => {
                                // This gets called when the calendar is moving forward/backward
                                // Compare the dates and check if we have to make a request to get more data
                                const startingDate = info.view.activeStart;
                                const endingDate = info.view.activeEnd;
                                const firstDate = new Date(dayFilters.firstDay);
                                const lastDate = new Date(dayFilters.lastDay);
                                const earliestDate = new Date(dayFilters.earliestDay);
                                const latestDate = new Date(dayFilters.latestDay);
                                // We need to store the current earliest and latest days for facility switches
                                setCurrentDays({
                                    firstDay: format(getStartOfMonth(startingDate), constants.API_DATE_FORMAT),
                                    lastDay: format(getEndOfMonth(endingDate), constants.API_DATE_FORMAT),
                                });
                                if (startingDate < firstDate && startingDate < earliestDate) {
                                    setFilterForDay({
                                        firstDay: format(
                                            subMonths(getStartOfMonth(startingDate), DATE_FILTER_MONTH_STEP),
                                            constants.API_DATE_FORMAT,
                                        ),
                                        lastDay: format(getEndOfMonth(startingDate), constants.API_DATE_FORMAT),
                                    });
                                } else if (endingDate > lastDate && endingDate > latestDate) {
                                    setFilterForDay({
                                        firstDay: format(getStartOfMonth(endingDate), constants.API_DATE_FORMAT),
                                        lastDay: format(
                                            addMonths(getEndOfMonth(endingDate), DATE_FILTER_MONTH_STEP),
                                            constants.API_DATE_FORMAT,
                                        ),
                                    });
                                }
                            }}
                            events={displayedEvents}
                            eventClick={(info) => setViewEventModal(info.event.extendedProps.ghEvent)}
                            eventResize={(info) => console.log(info)}
                            eventRender={(info) => {
                                // Custom render for the event
                                // See: https://fullcalendar.io/docs/eventRender
                                ReactDOM.render(<CalendarEvent event={info.event} />, info.el);
                            }}
                            columnHeaderHtml={(date: DateInput) => {
                                // This will create an html string with the jsx
                                return ReactDOMServer.renderToStaticMarkup(
                                    <CalendarHeader date={date.toString()} type={eventStore.selectedView.value} />,
                                );
                            }}
                        />
                    </div>
                </Flex>
            </Flex>
            {viewEventModal ? (
                <EventDetailsModal event={viewEventModal} onClose={() => setViewEventModal(null)} />
            ) : null}
        </PageContainer>
    );
}
export default observer(Appointments);
