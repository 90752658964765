import { UseMutationResult, useMutation, useQueryClient } from 'react-query';
import { saveFormCatalogAsVersion } from 'src/api/formCatalogs';
import { FormCatalog } from 'common/lib/entity/framework/FormCatalog';
import { FormCatalogVersion } from 'common/lib/entity/framework/FormCatalogVersion';

export const saveFormCatalogAsVersionKey = 'saveFormCatalogAsVersion';

export interface UseSaveFormCatalogAsVersionInputVariables {
    formVersionName: FormCatalogVersion['formVersionName'];
    formVersionDescription: FormCatalogVersion['formVersionDescription'];
}

export const useSaveFormCatalogAsVersion = (
    formCatalogId: FormCatalog['formCatalogId'],
): UseMutationResult<
    { formCatalogVersionId: FormCatalogVersion['formCatalogVersionId'] },
    Error,
    UseSaveFormCatalogAsVersionInputVariables,
    unknown
> => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationKey: [saveFormCatalogAsVersionKey],
        mutationFn: async (variables) => {
            const { formVersionName, formVersionDescription } = variables;

            if (!formCatalogId) {
                throw new Error('formCatalogId is required');
            }

            if (!formVersionName) {
                throw new Error('formVersionName is required');
            }

            if (!formVersionDescription) {
                throw new Error('formVersionDescription is required');
            }

            return await saveFormCatalogAsVersion(formCatalogId, formVersionName, formVersionDescription);
        },
        onSuccess: async (data) => {
            queryClient.invalidateQueries({ queryKey: ['formCatalog', { formCatalogId: formCatalogId }] });
            return data;
        },
        retry: false,
    });
};
