import React, { useState, useEffect } from 'react';
import styles from './styles.module.scss';
import Select from 'react-select';
import * as utils from 'src/utils';
import { FormFacilityMapping } from 'common/lib/entity/framework/FormFacilityMapping';
import { useFormFacilityMappingFormDefinitions } from 'src/queries/useFormFacilityMappingFormDefinitions';
import { FormDefinition } from 'common/lib/entity/org/FormDefinition';

export interface FormFacilityMappingFormDefinitionsDropDownProps {
    orgNameInternal: FormFacilityMapping['orgNameInternal'];
    facilityId: FormFacilityMapping['facilityId'];
    onChange: Function;
    selectedValue?: string | number;
    disabled?: boolean;
}

function FormFacilityMappingFormDefinitionsDropDown(props: FormFacilityMappingFormDefinitionsDropDownProps) {
    const { data: formDefinitions, isFetching, refetch: getFormDefinitions } = useFormFacilityMappingFormDefinitions(
        props.orgNameInternal,
        props.facilityId,
    );
    const [options, setOptions] = useState([]);
    const [selected, setSelected] = useState(null);

    useEffect(() => {
        if (formDefinitions) {
            const opts = formDefinitions.map((formDefinition: FormDefinition) => ({
                label: formDefinition.formDefnTitle,
                value: formDefinition.formDefnId,
                formDefinitionName: formDefinition.formDefnNm
            }));
            setOptions(opts);
            setSelected(opts.find((i) => i.value === props.selectedValue) || null);
        }
    }, [formDefinitions]);

    useEffect(() => {
        const selectedVal = options.find((i) => i.value === props.selectedValue);
        if (selectedVal) {
            setSelected(selectedVal);
        }
    }, [props.selectedValue, options]);

    useEffect(() => {
        getFormDefinitions();
    }, [props.orgNameInternal, props.facilityId]);

    return (
        <Select
            value={selected}
            onChange={(s: any) => {
                setSelected(s);
                props.onChange(s || null);
            }}
            isLoading={isFetching}
            className={styles.select}
            options={options}
            placeholder="Select Form Definition"
            styles={utils.styleSelectComponent()}
            isDisabled={props.disabled || isFetching}
        />
    );
}

export default FormFacilityMappingFormDefinitionsDropDown;
