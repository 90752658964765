import React, { useState, useEffect, useContext } from 'react';
import LoadingIcon from '../LoadingIcon';
import Button from '../Button';
import Flex from '../Flex';
import DiscreteForm from '../DiscreteForm';
import { FormUtils } from 'common/lib/form-generation/util/FormUtils';
import { ExpressionUtil } from 'common/lib/form-generation/util/ExpressionUtil';
import { ValidationUtil } from 'common/lib/form-generation/util/ValidationUtil';
import { ToastStoreObject, ToastType } from 'src/stores/ToastStore';
import Select from 'react-select';
import * as utils from 'src/utils';
import { ANALYTICS_NAMES } from 'src/utils/analytics';

import * as encounterService from 'src/api/encounters';
import styles from './styles.module.scss';
import * as variables from 'src/styles/variables';
import { ModelPropertyUpdate } from 'common/lib/model/model/ModelPropertyUpdate';
import { FormDefinition } from 'src/utils/types';
import Accordion from '../Accordion';
import NemsisErrorBanner, { NemsisErrorBannerMode } from 'src/containers/NemsisErrorBanner';
import NemsisStore from 'src/stores/NemsisStore';

export interface DiscreteFormProps {
    onClose?: Function;
    enctrId: string;
    facId: number;
    enctrFormId?: number;
    patientId?: number;
}
export function DiscreteFormModal(props: DiscreteFormProps) {
    const [isLoading, setIsLoading] = useState(true);
    const [formState, setFormState] = useState('list');
    const [availableForms, setAvailableForms] = useState([]);
    const [formUtils, setFormUtils] = useState(null);
    const [validationUtil, setValidationUtil] = useState(null);
    const [validationData, setValidationData] = useState<any>({});
    const [formDefinitionData, setFormDefinitionData] = useState<any>({});
    const [formDefinitionContent, setFormDefinitionContent] = useState<any>({});
    const [modelData, setModelData] = useState<any>({});
    const [modelPropertyUpdates, setModelPropertyUpdates] = useState<ModelPropertyUpdate[]>([]);
    const [availablePages, setAvailablePages] = useState<any>([]);
    const [selectedPageAddition, setSelectedPageAddition] = useState('');

    const nemsisStore = useContext(NemsisStore);

    useEffect(() => {
        props.enctrFormId ? getEncounterForm() : getFormDefinitions(props.facId);
    }, []);

    useEffect(() => {
        if (formDefinitionData && formDefinitionData.formDef) {
            // Setup FormUtils
            const expressionUtil = new ExpressionUtil(formDefinitionData.formDef);
            const newFormUtils = new FormUtils(formDefinitionData, expressionUtil);
            const newValidationUtil = new ValidationUtil(
                formDefinitionData.formDef,
                newFormUtils,
                formDefinitionData.pages,
            );
            setFormUtils(newFormUtils);
            setValidationUtil(newValidationUtil);

            // Setup pages
            const pages = formDefinitionData.formDef.formDefinitionContent.form.pages[0].page;
            setAvailablePages(
                pages.map((page: any) => ({
                    value: page.$.name,
                    label: page.title[0],
                    isDisabled: !canAddPage(page.$.name),
                })),
            );
        }
    }, [formDefinitionData]);

    useEffect(() => {
        if (formUtils && modelPropertyUpdates.length) {
            // Update local model data
            let updatedModelData = modelData;
            modelPropertyUpdates.forEach((propertyUpdate: any) => {
                const { propertyName, jsonPointer, jsonIndex, fieldValue } = propertyUpdate;
                const propertyObject = Object.assign(
                    {},
                    { modelProperty: propertyName },
                    jsonPointer ? { jsonPointer } : null,
                    jsonIndex ? { jsonIndex } : null,
                );
                updatedModelData = formUtils.updateModelPropertyValue(propertyObject, updatedModelData, fieldValue);
            });
            setModelData(updatedModelData);
            // Update validation matrix
            setValidationData({ ...validationUtil.validate(updatedModelData) });
        }
    }, [modelPropertyUpdates]);

    async function addFormPage(pageName: string) {
        try {
            setIsLoading(true);
            await encounterService.addFormPage(props.enctrFormId, pageName);
            await getEncounterForm();
            setIsLoading(false);
        } catch (e) {
            setIsLoading(false);
            ToastStoreObject.show(utils.parseError(e));
        }
    }

    async function getFormDefinitions(facId: number) {
        try {
            setIsLoading(true);
            const formDefinitions = await encounterService.getFormDefinitions(facId);
            const filteredFormDefinitions = formDefinitions.filter(
                (definition: FormDefinition) =>
                    (definition.formDefnTyp === 'hybrid' || definition.formDefnTyp === 'discrete') &&
                    definition.actvInd,
            );
            setAvailableForms(filteredFormDefinitions);
            setIsLoading(false);
        } catch (e) {
            setIsLoading(false);
            ToastStoreObject.show('Cannot load form definitions');
        }
    }

    async function getEncounterForm() {
        try {
            setIsLoading(true);
            const formDefinition = await encounterService.getEncounterFormPdfData(props.enctrFormId, props.facId);

            if (formDefinition.data.formDefinitionType === 'paper') {
                setFormState('noPaper');
                return;
            }

            setFormDefinitionData(formDefinition.data || {});
            setFormDefinitionContent(formDefinition.data.formDef.formDefinitionContent || {});
            setModelData(formDefinition.data.modelData);
            setFormState('form');
            setIsLoading(false);
        } catch (e) {
            setIsLoading(false);
            ToastStoreObject.show('Unable to load form');
        }
    }

    async function createEncounterForm(form: any) {
        try {
            setIsLoading(true);
            const createdFormId = await encounterService.createEncounterForm({
                enctrId: props.enctrId,
                formDefnId: form.formDefnId,
                formDefnVer: form.formDefnVer,
                patientId: props.patientId,
                facId: props.facId,
                formLastOpndDttm: new Date(),
            });
            const formDefinition = await encounterService.getEncounterFormPdfData(
                createdFormId.encounterFormId,
                props.facId,
            );
            setFormDefinitionData(formDefinition.data || {});
            setFormDefinitionContent(formDefinition.data.formDef.formDefinitionContent || {});
            setModelData(formDefinition.data.modelData);
            setFormState('form');
            setIsLoading(false);
        } catch (e) {
            setIsLoading(false);
            ToastStoreObject.show('Unable to create form');
        }
    }

    async function updateEncounterForm() {
        try {
            setIsLoading(true);
            await encounterService.updateEncounterFormModel(formDefinitionData.encounterFormId, modelPropertyUpdates);
            if (isNemsisForm()) {
                await nemsisStore.validateEmsData(formDefinitionData.encounterFormId, true);
            }
            ToastStoreObject.show('Successfully saved form.', ToastType.Success);
            if (props.onClose) {
                props.onClose();
            }
            setIsLoading(false);
        } catch (e) {
            ToastStoreObject.show('Unable to save form.');
        }
    }

    function getByteArrayBytes(byteArray: string) {
        const byteArrayFile = atob(byteArray);
        const binaryLen = byteArrayFile.length;
        let bytes = new Uint8Array(binaryLen);
        for (let i = 0; i < binaryLen; i++) {
            const ascii = byteArrayFile.charCodeAt(i);
            bytes[i] = ascii;
        }
        return bytes;
    }

    function downloadFromBytes(bytes: Uint8Array, fileName: string, type: string) {
        const blob = new Blob([bytes], { type });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
    }

    async function downloadBlob(blobId: string, fileName: string, type: string) {
        try {
            const blobData = await encounterService.getEncounterFormBlob(formDefinitionData.encounterFormId, blobId);
            const cacheUrl = (blobData.data && blobData.data.cacheUrl) || '';
            const base64File = await encounterService.getEncounterFormBlobFromS3(
                import.meta.env.VITE_APP_ENV === 'local' ? cacheUrl.replace('localstack', 'localhost') : cacheUrl,
            );
            const bytes = getByteArrayBytes(base64File.data);
            downloadFromBytes(bytes, fileName, type);
        } catch (e) {
            ToastStoreObject.show('Unable to download file.');
        }
    }

    function getFormState() {
        switch (formState) {
            case 'list':
                return (
                    <Flex direction="column" className={styles.availableForms}>
                        {availableForms.length > 0 ? getFormButtons() : <div>No forms available</div>}
                    </Flex>
                );
            case 'form':
                const inputData = formDefinitionContent.form.pages[0].page;
                const valueSets = formDefinitionContent.form.valueSets;

                const formPages = formDefinitionData.pages.map((page: any, index: number) => {
                    formUtils.currentPage = page;

                    if (!inputData[index].discreteInputs) {
                        return;
                    }

                    return (
                        <Accordion
                            key={index}
                            startOpen={index === 0}
                            label={formUtils.getPageName(page.pageName)}
                            removeExtraSpacing={true}
                        >
                            <DiscreteForm
                                title={formDefinitionData.formDef.formDefinitionTitle}
                                facId={props.facId}
                                inputs={inputData}
                                model={modelData}
                                formUtils={formUtils}
                                page={index}
                                validationUtil={validationUtil}
                                validationData={validationData}
                                valueSets={valueSets}
                                onChange={(updatedModelProperties: ModelPropertyUpdate[]) => {
                                    setModelPropertyUpdates(updatedModelProperties);
                                }}
                                onDownload={(blobId: string, fileName: string, type: string) =>
                                    downloadBlob(blobId, fileName, type)
                                }
                            />
                        </Accordion>
                    );
                });

                return formPages;
        }
    }

    function getFormButtons() {
        return availableForms.map((form, index) => (
            <Button
                key={index}
                type="primary"
                text={form.formDefnTitle}
                onClick={() => {
                    createEncounterForm(form);
                }}
                className={styles.addFormButton}
                data-test-id={ANALYTICS_NAMES.DiscreteForm_AddForm}
            />
        ));
    }

    function canAddPage(pageName: string) {
        const page = formDefinitionData.formDef.formDefinitionContent.form.pages[0].page.find(
            (page: any) => page.$.name === pageName,
        );
        const maxCount = parseInt(page.$.maxCount);
        const currentCount = formDefinitionData.pages.filter((page: any) => page.pageName === pageName).length;
        return maxCount ? currentCount < maxCount : true;
    }

    function isNemsisForm() {
        return (
            formDefinitionData.formDef &&
            formDefinitionData.formDef.propMap &&
            formDefinitionData.formDef.propMap.includes('"formIsNemsisInd"=>"true"')
        );
    }

    if (formState === 'noPaper') {
        return (
            <Flex justify="center" align="center" grow={1}>
                Cannot edit paper forms
            </Flex>
        );
    }

    return isLoading ? (
        <Flex justify="center">
            <LoadingIcon />
        </Flex>
    ) : (
        <Flex direction="column" grow={1} className={styles.formContainer}>
            {formState === 'form' ? (
                <Flex align="center" className={styles.pageMenu}>
                    <div>Add Page:</div>
                    <Select
                        onChange={(s: any) => {
                            setSelectedPageAddition(s.value);
                        }}
                        isLoading={!formDefinitionData.formDef}
                        options={availablePages}
                        placeholder={'Select a Page'}
                        className={styles.pageDropdown}
                        data-test-id={ANALYTICS_NAMES.DiscreteForm_SelectPage}
                    />
                    <Button
                        type="primary"
                        text="Add"
                        disabled={selectedPageAddition === ''}
                        onClick={() => addFormPage(selectedPageAddition)}
                        data-test-id={ANALYTICS_NAMES.DiscreteForm_AddPage}
                    />
                </Flex>
            ) : null}
            {isNemsisForm() ? (
                <NemsisErrorBanner
                    encounterFormId={props.enctrFormId}
                    mode={NemsisErrorBannerMode.EMS}
                    padding={variables.padding5}
                />
            ) : null}
            <div className={styles.formState}>{getFormState()}</div>
            {formState === 'form' ? (
                <Flex justify="end" className={styles.formMenu}>
                    <Button
                        type="primary"
                        onClick={updateEncounterForm}
                        text="Save Form"
                        disabled={!modelPropertyUpdates.length}
                        data-test-id={ANALYTICS_NAMES.DiscreteForm_SaveForm}
                    />
                </Flex>
            ) : null}
        </Flex>
    );
}
