import React from 'react';

import styles from './styles.module.scss';
import * as variables from 'src/styles/variables';

import { CalculatedScorecardMetric } from 'common/lib/model/Scorecard/Scorecard';
import { Sparklines, SparklinesCurve } from 'react-sparklines';

export interface SparkLineCellProps {
    metric: CalculatedScorecardMetric;
}

export function SparklineCell({ metric }: SparkLineCellProps) {
    if (!metric) return <span></span>;
    if (metric.metricValue === null) return <div className={styles.alignCenter}>-</div>;

    const value: number = metric.metricValue * 100;
    const metricValue = Math.abs(value) % 1 > 0 ? value.toFixed(1) : value;

    return (
        <div className={styles.cellContainer}>
            <div className={styles.cellData} style={{ position: 'absolute' }}>
                <span className={styles.cellValue}>{metricValue}</span>
                <br />
                <span className={styles.cellGoal}>{metric.detail}</span>
            </div>
            <div className={styles.sparkline}>
                <Sparklines data={metric.trend} width={250} margin={5}>
                    <SparklinesCurve color={variables.blue} />
                </Sparklines>
            </div>
        </div>
    );
}
