import React, {
    ReactNode,
    useState,
    useEffect,
    useRef,
    forwardRef,
    Ref,
    FormEvent,
    MouseEvent,
    HTMLAttributes,
} from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import LoadingIcon from 'src/components/LoadingIcon';
import Flex from 'src/components/Flex';
import Icon from '../Icon';

const DEFAULT_PREVENT_TIMEOUT = 500;

export interface CustomButtonProps extends HTMLAttributes<HTMLButtonElement> {
    type?: 'primary' | 'secondary' | 'outline' | 'transparent' | 'small' | 'secondarySmall';
    disabled?: boolean;
    preventDouble?: boolean;
    preventTimeout?: number;
    isLoading?: boolean;
    className?: string;
    text?: string;
    leftIcon?: ReactNode;
    rightIcon?: ReactNode;
    name?: string;
    isLocked?: boolean;
    onClick(event: MouseEvent<HTMLElement> | FormEvent<HTMLFormElement>): any;
    'data-test-id'?: string; // Had to define this here for props from objects (like in `EventView`)
}

const CustomButton = forwardRef((props: CustomButtonProps, ref: Ref<HTMLButtonElement>) => {
    const {
        disabled,
        type,
        className,
        text,
        children,
        rightIcon,
        leftIcon,
        onClick,
        preventDouble,
        preventTimeout = DEFAULT_PREVENT_TIMEOUT,
        isLoading,
        isLocked,
        ...rest
    } = props;
    const [disableToPreventDouble, setDisableToPreventDouble] = useState(false);
    const timeout = useRef(null);

    // Use this for componentWillUnmount to clear the timeout
    useEffect(() => () => timeout.current && clearTimeout(timeout.current), []);

    const handleClick = (event: MouseEvent<HTMLElement>) => {
        if (!onClick) {
            return;
        }
        if (preventDouble === false) {
            onClick(event);
            return;
        }
        if (!disabled) {
            if (!disableToPreventDouble) {
                setDisableToPreventDouble(true);
                timeout.current = setTimeout(() => setDisableToPreventDouble(false), preventTimeout);
                onClick(event);
            }
        }
    };

    const isDisabled = disabled || disableToPreventDouble || isLocked || isLoading;
    const loading = <LoadingIcon type="dot" />;
    const content = isLoading ? loading : children || text;
    const leftIconProp = isLocked ? <Icon className={styles.lockIcon} name="lock" type="fa" /> : leftIcon;
    return (
        <button
            ref={ref}
            className={classNames(
                styles.button,
                {
                    [styles.red]: type === 'primary',
                    [styles.pink]: type === 'secondary' || type === 'secondarySmall',
                    [styles.outline]: type === 'outline',
                    [styles.transparent]: type === 'transparent',
                    [styles.small]: type === 'small' || type === 'secondarySmall',
                    [styles.disabled]: isDisabled,
                },
                className,
            )}
            disabled={isDisabled}
            {...rest}
            onClick={handleClick}
        >
            {!rightIcon && !leftIconProp ? (
                content
            ) : !isLoading ? (
                <Flex direction="row" justify="center" align="center">
                    {leftIconProp}
                    {content}
                    {rightIcon}
                </Flex>
            ) : null}
        </button>
    );
});
CustomButton.displayName = 'Button';

export default CustomButton;
