import React from 'react';

const SvgSearch = (props) => (
    <svg width={25} height={25} {...props} viewBox="0 0 25 25">
        <g
            transform="translate(-2 -2)"
            stroke={props.color || '#FFF'}
            strokeWidth={2.5}
            fill="none"
            fillRule="evenodd"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <circle transform="rotate(-23.025 12.338 12.338)" cx={12.338} cy={12.338} r={8.727} />
            <path d="M18.51 18.508l6.768 6.77" />
        </g>
    </svg>
);

export default SvgSearch;
