import React, { ReactNode } from 'react';
import Portal from '../Portal';
import styles from './styles.module.scss';
import Card from '../Card';
import Flex from '../Flex';
import useEscapeKey from 'src/utils/hooks/useEscapeKey';
import { ANALYTICS_NAMES } from 'src/utils/analytics';
import AnimatedComponent from '../AnimatedComponent';
import classNames from 'classnames';

export interface SideModalProps {
    isOpen: boolean;
    children: ReactNode;
    onClose?: Function;
    isWide?: boolean;
}

function SideModal(props: SideModalProps) {
    useEscapeKey(props.onClose);

    if (!props.isOpen) {
        return null;
    }
    return (
        <Portal>
            <AnimatedComponent type="fadeIn">
                <Flex
                    align="center"
                    justify="center"
                    className={styles.overlay}
                    data-test-id={ANALYTICS_NAMES.SideModal}
                >
                    <AnimatedComponent type="slideFromRight">
                        <Card
                            elevation={4}
                            className={classNames(styles.content, {
                                [styles.wide]: props.isWide,
                            })}
                        >
                            {props.children}
                        </Card>
                    </AnimatedComponent>
                </Flex>
            </AnimatedComponent>
        </Portal>
    );
}

export default SideModal;
