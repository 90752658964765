import { FormDefinition } from '../../model/form/FormDefinition';

const PROPERTY_EXPRESSION = 'PROPERTY_EXPRESSION';
const REFERENCE_EXPRESSION = 'REFERENCE_EXPRESSION';
const DYNAMIC_EXPRESSION = 'DYNAMIC_EXPRESSION';

// TODO: Type out expressions and context

export class ExpressionUtil {
    private _formDefinition: FormDefinition;

    constructor(formDefinition: FormDefinition) {
        this._formDefinition = formDefinition;
    }

    static isExpression(value: string) {
        return value.startsWith('{{');
    }

    static processModelExpression(expressionUtil: ExpressionUtil, value: string, expressionContext: any) {
        if (ExpressionUtil.isExpression(value)) {
            return expressionUtil.evaluateExpression(value, expressionContext);
        }
        return value;
    }

    private cleanExpression(expression: any) {
        const noBrackets = expression.substring(2, expression.length - 2);
        if (noBrackets.length === 0) {
            return '';
        }

        const firstChar = noBrackets.charAt(0);
        if (firstChar === '=' || firstChar === '@') {
            return noBrackets.substring(1);
        }

        return noBrackets;
    }

    private getExpressionType(expression: any) {
        if (expression.indexOf('{{=') === 0) {
            return PROPERTY_EXPRESSION;
        } else if (expression.indexOf('{{@') === 0) {
            return REFERENCE_EXPRESSION;
        } else if (expression.indexOf('{{') === 0) {
            return DYNAMIC_EXPRESSION;
        }

        return null;
    }

    private getArguments(expression: any) {
        const argsString = expression.substring(expression.indexOf('(') + 1, expression.lastIndexOf(')'));
        const argMap: any = {};

        if (argsString.length > 0) {
            const expressionArguments: any[] = argsString.split(
                /,(?=([^']*'[^']*')*(?![^']*'))(?=([^"]*"[^"]*")*(?![^"]*"))/,
            ).map((arg: string) => arg && arg.trim());

            expressionArguments.forEach((argument) => {
                if (!argument) {
                    return;
                }

                const separatorIndex = argument.indexOf(':');
                const name = argument.substring(0, separatorIndex);
                const value = argument.substring(separatorIndex + 1, argument.length);
                let castValue = isNaN(Number(value)) ? value.toString() : Number(value);

                if (
                    typeof castValue === 'string' &&
                    ((castValue.startsWith("'") && castValue.endsWith("'")) ||
                        (castValue.startsWith('"') && castValue.endsWith('"')))
                ) {
                    castValue = castValue.substr(1, castValue.length - 2);
                }

                argMap[name] = castValue;
            });
        }

        return argMap;
    }

    private getReferenceName(expression: any) {
        if (expression.indexOf('(') >= 0) {
            return expression.substring(0, expression.indexOf('('));
        }

        return expression;
    }

    private evaluateDynamicExpression(expression: any, context: any) {
        try {
            const model = context.model;
            const page = context.page;
            const facility = context.facility;
            const organization = context.organization;
            const item = context.item;
            const v = eval(expression);
            return v;
        } catch (error) {
            console.log(error);
            return null;
        }
    }

    private evaluateReferenceExpression(expression: any, context: any) {
        const args = this.getArguments(expression);
        const expName = this.getReferenceName(expression);
        const exp = this.getFormDefinitionExpressionByName(expName);

        if (!exp) {
            return null;
        }

        const expressionScript = `var executeExpression = function(){${exp._}}; executeExpression();`;

        try {
            const model = context.model;
            const page = context.page;
            const facility = context.facility;
            const organization = context.organization;
            const val = eval(expressionScript);
            return val;
        } catch (error) {
            return null;
        }
    }

    private getFormDefinitionExpressionByName(expressionName: string) {
        const dynamicExpressions = this._formDefinition.formDefinitionContent.form.expressions[0].dynamicExpression;
        return dynamicExpressions.find((expression: any) => expression.$.name === expressionName);
    }

    public evaluateExpression(expression: any, context: any) {
        const cleanExpression = this.cleanExpression(expression);
        const type = this.getExpressionType(expression);

        if (type === PROPERTY_EXPRESSION) {
            return this.evaluateDynamicExpression(cleanExpression, context);
        }

        if (type === REFERENCE_EXPRESSION) {
            return this.evaluateReferenceExpression(cleanExpression, context);
        }

        if (type === DYNAMIC_EXPRESSION) {
            return this.evaluateDynamicExpression(cleanExpression, context);
        }

        throw new Error('Invalid expression type');
    }
}
