import React, { useEffect, useState } from 'react';
import { ToastStoreObject, ToastType } from 'src/stores/ToastStore';
import { ANALYTICS_NAMES } from 'src/utils/analytics';
import Input, { Label } from '../Input';
import ModalFooter from '../ModalFooter';
import ModalHeader from '../ModalHeader';
import SideModal from '../SideModal';
import { FormCatalog } from 'common/lib/entity/framework/FormCatalog';
import styles from './styles.module.scss';
import TabNav from '../TabNav';
import FormModelDropDown from '../FormModelDropdown';
import { useCreateFormCatalog } from 'src/queries/useCreateFormCatalog';
import Checkbox from '../Checkbox';
import OrgDropDown from '../OrgDropDown';
import FacilitiesByOrgDropDown from '../FacilitiesByOrgDropDown';
import { useImportFormCatalog } from 'src/queries/useImportFormCatalog';
import FormFacilityMappingFormDefinitionsDropDown from '../FormFacilityMappingFormDefinitionsDropDown';
import Flex from '../Flex';

const SECTION_TABS = ['Start from Scratch', 'Import from a Facility'];
const DEFAULT_PROP_MAPS: FormPropMaps = {
    formIsAnesCaseInd: false,
    formIsBillable: false,
    formIsNemsisInd: false,
    formIsChpCaseInd: false,
    formIsEmsCaseInd: false,
};

interface FormPropMaps {
    formIsAnesCaseInd?: boolean;
    formIsBillable?: boolean;
    formIsNemsisInd?: boolean;
    formIsChpCaseInd?: boolean;
    formIsEmsCaseInd?: boolean;
}

interface FormCatalogModalProps {
    formCatalog?: FormCatalog;
    onClose?: Function;
    onSave?: Function;
}

function FormCatalogModal(props: FormCatalogModalProps) {
    const { formCatalog, onClose, onSave } = props;
    const [selectedTab, setSelectedTab] = useState(SECTION_TABS[0]);
    const [formName, setFormName] = useState(formCatalog?.formName || '');
    const [formTitle, setFormTitle] = useState(formCatalog?.formTitle || '');
    const [formDescription, setFormDescription] = useState(formCatalog?.formDescription || '');
    const [formProperties, setFormProperties] = useState<FormPropMaps>(
        formCatalog?.formProperties || DEFAULT_PROP_MAPS,
    );
    const [formModelId, setFormModelId] = useState(formCatalog?.formModelId || '');
    const [orgNameInternal, setOrgNameInternal] = useState(null);
    const [facilityId, setFacilityId] = useState(null);
    const [formDefinitionId, setFormDefinitionId] = useState(null);
    const {
        mutate: createFormCatalog,
        isLoading: isCreateFormCatalogLoading,
        isSuccess: isCreateFormCatalogSuccess,
        isError: isCreateFormCatalogError,
        error: createFormCatalogError,
    } = useCreateFormCatalog();
    const {
        mutate: importFormCatalog,
        isLoading: isImportFormCatalogLoading,
        isSuccess: isImportFormCatalogSuccess,
        isError: isImportFormCatalogError,
        error: importFormCatalogError,
    } = useImportFormCatalog();

    useEffect(() => {
        if (isCreateFormCatalogSuccess) {
            ToastStoreObject.show('Form catalog created successfully', ToastType.Success);
            onClose();
            onSave();
        }

        if (isCreateFormCatalogError) {
            // @ts-ignore
            const errorMessage = createFormCatalogError?.response?.data?.message || 'Failed to create form catalog';
            ToastStoreObject.show(errorMessage, ToastType.Error);
        }

        if (isImportFormCatalogSuccess) {
            ToastStoreObject.show('Form catalog created successfully', ToastType.Success);
            onClose();
            onSave();
        }

        if (isImportFormCatalogError) {
            // @ts-ignore
            const errorMessage = importFormCatalogError?.response?.data?.message || 'Failed to import form catalog';
            ToastStoreObject.show(errorMessage, ToastType.Error);
        }
    }, [isCreateFormCatalogSuccess, isCreateFormCatalogError, isImportFormCatalogSuccess, isImportFormCatalogError]);

    async function handleCreate() {
        if (selectedTab === SECTION_TABS[0]) {
            createFormCatalog({
                formModelId,
                formName,
                formTitle,
                formDescription,
                formProperties,
            });
        } else if (selectedTab === SECTION_TABS[1]) {
            importFormCatalog({
                formName,
                formTitle,
                formModelId,
                orgNameInternal,
                facilityId,
                formDefinitionId,
            });
        }
    }

    function getContent() {
        switch (selectedTab) {
            case SECTION_TABS[0]:
            default:
                return (
                    <>
                        <Input
                            label="Form Name"
                            value={formName}
                            onChangeText={(v: string) => setFormName(v)}
                            data-test-id={ANALYTICS_NAMES.FormCatalogModal_FormName}
                        />
                        <Input
                            label="Form Title"
                            value={formTitle}
                            onChangeText={(v: string) => setFormTitle(v)}
                            data-test-id={ANALYTICS_NAMES.FormCatalogModal_FormTitle}
                        />
                        <Input
                            label="Form Description"
                            value={formDescription}
                            multiLine={true}
                            onChangeText={(v: string) => setFormDescription(v)}
                            data-test-id={ANALYTICS_NAMES.FormCatalogModal_FormDescription}
                        />
                        <Label text="Form Model" />
                        <FormModelDropDown
                            onChange={(v: { label: string; value: string }) => setFormModelId(v.value)}
                        />
                        <Label text="Form Properties" />
                        <Flex direction="column" gap={16} className={styles.properties}>
                            <Checkbox
                                label="Is Anesthesia Case?"
                                checked={formProperties.formIsAnesCaseInd === true}
                                onChange={(e) => {
                                    setFormProperties({
                                        ...formProperties,
                                        formIsAnesCaseInd: e.target.checked,
                                    });
                                }}
                            />
                            <Checkbox
                                label="Is Billable?"
                                checked={formProperties.formIsBillable === true}
                                onChange={(e) => {
                                    setFormProperties({
                                        ...formProperties,
                                        formIsBillable: e.target.checked,
                                    });
                                }}
                            />
                            <Checkbox
                                label="Is Nemsis?"
                                checked={formProperties.formIsNemsisInd === true}
                                onChange={(e) => {
                                    setFormProperties({
                                        ...formProperties,
                                        formIsNemsisInd: e.target.checked,
                                    });
                                }}
                            />
                            <Checkbox
                                label="Is EMS Case?"
                                checked={formProperties.formIsEmsCaseInd === true}
                                onChange={(e) => {
                                    setFormProperties({
                                        ...formProperties,
                                        formIsEmsCaseInd: e.target.checked,
                                    });
                                }}
                            />
                            <Checkbox
                                label="Is CHP Case?"
                                checked={formProperties.formIsChpCaseInd === true}
                                onChange={(e) => {
                                    setFormProperties({
                                        ...formProperties,
                                        formIsChpCaseInd: e.target.checked,
                                    });
                                }}
                            />
                        </Flex>
                    </>
                );
            case SECTION_TABS[1]:
                return (
                    <>
                        <Label text="Form Model" />
                        <FormModelDropDown
                            onChange={(v: { label: string; value: string }) => setFormModelId(v.value)}
                        />
                        <Label text="Organization" />
                        <OrgDropDown
                            onChange={(o: { label: string; value: string }) => {
                                setOrgNameInternal(o.value);
                            }}
                            selectedValue={orgNameInternal}
                        />
                        <Label text="Facility" />
                        <FacilitiesByOrgDropDown
                            orgNameInternal={orgNameInternal}
                            selectedValue={facilityId}
                            onChange={(o: { label: string; value: number }) => {
                                setFacilityId(o.value);
                            }}
                            disabled={!orgNameInternal}
                        />
                        <Label text="Encounter Form" />
                        <FormFacilityMappingFormDefinitionsDropDown
                            orgNameInternal={orgNameInternal}
                            facilityId={facilityId}
                            selectedValue={formDefinitionId}
                            onChange={(o: { label: string; value: number, formDefinitionName: string }) => {
                                setFormDefinitionId(o.value);
                                setFormTitle(o.label);
                                setFormName(o.formDefinitionName);
                            }}
                            disabled={!orgNameInternal || !facilityId}
                        />
                        <Input
                            label="Form Name (e.g. anesthesia-intraop)"
                            value={formName}
                            onChangeText={(v: string) => setFormName(v)}
                            data-test-id={ANALYTICS_NAMES.FormCatalogModal_FormName}
                        />
                        <Input
                            label="Form Title (e.g. General Anesthesia)"
                            value={formTitle}
                            onChangeText={(v: string) => setFormTitle(v)}
                            data-test-id={ANALYTICS_NAMES.FormCatalogModal_FormTitle}
                        />
                    </>
                );
        }
    }

    return (
        <SideModal isOpen={true} onClose={onClose}>
            <ModalHeader title="New Form Catalog" onClose={onClose} />
            <div className={styles.tabWrap}>
                <TabNav
                    tabs={SECTION_TABS}
                    onTabClick={(tab: string) => {
                        setSelectedTab(tab);
                    }}
                />
            </div>
            <div className={styles.contentWrap}>{getContent()}</div>
            <ModalFooter
                primaryText="Create"
                primaryClick={handleCreate}
                secondaryClick={() => onClose()}
                isLoading={isCreateFormCatalogLoading || isImportFormCatalogLoading}
            />
        </SideModal>
    );
}

export default FormCatalogModal;
