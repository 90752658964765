import React from 'react';

const SvgNavigationPayment = (props) => (
    <svg width="20" height="20" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M41 12H7C6.44772 12 6 12.4477 6 13V35C6 35.5523 6.44772 36 7 36H41C41.5523 36 42 35.5523 42 35V13C42 12.4477 41.5523 12 41 12ZM7 10C5.34315 10 4 11.3431 4 13V35C4 36.6569 5.34315 38 7 38H41C42.6569 38 44 36.6569 44 35V13C44 11.3431 42.6569 10 41 10H7Z"
            fill={props.color || '#333'}
        />
        <path d="M4 16H44V24H4V16Z" fill={props.color || '#333'} />
    </svg>
);

export default SvgNavigationPayment;
