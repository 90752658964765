import { createContext } from 'react';
import { action, runInAction, observable, computed, toJS, makeObservable } from 'mobx';
import * as macraAPI from 'src/api/macra';
import {
    QcdrSettingsResult,
    QcdrSettings,
    TinSetting,
    ProviderTin,
    ReportingSetting,
    ActivitySettings,
} from 'common/lib/model/macra/QcdrSettings';
import { PqrsProvider } from 'common/lib/model/macra/PqrsProvider';
import { Facility, FacilityStoreObject } from './FacilityStore';
import { MacraAvailableYears } from 'common/lib/model/macra/Macra';
import { IdMode } from 'common/lib/model/IdMode';

export type Activity = {
    id: string;
    title: string;
    description: string;
    listData?: string[];
    weighting: string;
    category: string;
};

export const ACTIVITIES_2023: Activity[] = [
    {
        id: 'IA_EPA_1',
        title:
            "Provide 24/7 Access to MIPS Eligible Clinicians or Groups Who Have Real-Time Access to Patient's Medical Record",
        description:
            'Provide 24/7 access to MIPS eligible clinicians, groups, or care teams for advice about urgent care (e.g., MIPS eligible clinician and care team access to medical record, cross-coverage with access to medical record, or protocol- driven nurse line with access to medical record) that could include one or more of the following:',
        listData: [
            'Expanded hours in evenings and weekends with access to the patient medical record (e.g., coordinate with small practices to provide alternate hour office visits and urgent care);',
            'Use of alternatives to increase access to care team by MIPS eligible clinicians and groups, such as e-visits, phone visits, group visits, home visits and alternate locations (e.g., senior centers and assisted living centers); and/or',
            'Provision of same-day or next-day access to a MIPS eligible clinician, group or care team when needed for urgent care or transition management.',
        ],
        weighting: 'High',
        category: 'Expanded Practice Access',
    },
    {
        id: 'IA_PM_11',
        title: 'Regular Review Practices in Place on Targeted Patient Population Needs',
        description:
            "Implement regular reviews of targeted patient population needs, such as structured clinical case reviews, which include access to reports that show unique characteristics of MIPS eligible clinician's patient population, identification of underserved patients, and how clinical treatment needs are being tailored, if necessary, to address unique needs and what resources in the community have been identified as additional resources. The review should consider how structural inequities, such as racism, are influencing patterns of care and consider changes to acknowledge and address them. Reviews should stratify patient data by demographic characteristics and health related social needs to appropriately identify differences among unique populations and assess the drivers of gaps and disparities and identify interventions appropriate for the needs of the sub-populations.",
        weighting: 'Medium',
        category: 'Population Management',
    },
    {
        id: 'IA_CC_8',
        title: 'Implementation of documentation improvements for practice/process improvements',
        description:
            'Implementation of practices/processes that document care coordination activities (e.g., a documented care coordination encounter that tracks all clinical staff involved and communications from date patient is scheduled for outpatient procedure through day of procedure).',
        weighting: 'Medium',
        category: 'Care Coordination',
    },
    {
        id: 'IA_CC_15',
        title: 'PSH Care Coordination',
        description:
            'Participation in a Perioperative Surgical Home (PSH) that provides a patient-centered, physician-led, interdisciplinary, and team-based system of coordinated patient care, which coordinates care from pre-procedure assessment through the acute care episode, recovery, and post-acute care. This activity allows for reporting of strategies and processes related to care coordination of patients receiving surgical or procedural care within a PSH. The clinician must perform one or more of the following care coordination activities:',
        listData: [
            'Coordinate with care managers/navigators in preoperative clinic to plan and implementation comprehensive post discharge plan of care;',
            'Deploy perioperative clinic and care processes to reduce post-operative visits to emergency rooms;',
            'Implement evidence-informed practices and standardize care across the entire spectrum of surgical patients; or',
            'Implement processes to ensure effective communications and education of patients’ post-discharge instructions.',
        ],
        weighting: 'Medium',
        category: 'Care Coordination',
    },
    {
        id: 'IA_CC_19',
        title:
            'Tracking of clinician’s relationship to and responsibility for a patient by reporting MACRA patient relationship codes.',
        description:
            'To receive credit for this improvement activity, a MIPS eligible clinician must attest that they reported MACRA patient relationship codes (PRC) using the applicable HCPCS modifiers on 50 percent or more of their Medicare claims for a minimum of a continuous 90-day period within the performance period. Reporting the PRC modifiers enables the identification of a clinician’s relationship with, and responsibility for, a patient at the time of furnishing an item or service. See the CY 2018 PFS final rule (82 FR 53232 through 53234) for more details on these codes.',
        weighting: 'High',
        category: 'Care Coordination',
    },
    {
        id: 'IA_BE_6',
        title: 'Collection and follow-up on patient experience and satisfaction data on beneficiary engagement',
        description:
            'Collect and follow up on patient experience and satisfaction data. This activity also requires follow-up on findings of assessments, including the development and implementation of improvement plans. To fulfill the requirements of this activity, MIPS eligible clinicians can use surveys (e.g., Consumer Assessment of Healthcare Providers and Systems Survey), advisory councils, or other mechanisms. MIPS eligible clinicians may consider implementing patient surveys in multiple languages, based on the needs of their patient population.',
        weighting: 'High',
        category: 'Beneficiary Engagement',
    },
    {
        id: 'IA_BE_22',
        title: 'Improved Practices that Engage Patients Pre-Visit',
        description:
            'Implementation of workflow changes that engage patients prior to the visit, such as a pre-visit development of a shared visit agenda with the patient, or targeted pre-visit laboratory testing that will be resulted and available to the MIPS eligible clinician to review and discuss during the patient’s appointment.',
        weighting: 'Medium',
        category: 'Beneficiary Engagement',
    },
    {
        id: 'IA_PSPA_1',
        title: 'Participation in an AHRQ-listed patient safety organization.',
        description: 'Participation in an AHRQ-listed patient safety organization.',
        weighting: 'Medium',
        category: 'Patient Safety and Practice Assessment',
    },
    {
        id: 'IA_PSPA_2',
        title: 'Participation in MOC Part IV',
        description:
            'In order to receive credit for this activity, a MIPS eligible clinician must participate in Maintenance of Certification (MOC) Part IV. Maintenance of Certification (MOC) Part IV requires clinicians to perform monthly activities across practice to regularly assess performance by reviewing outcomes addressing identified areas for improvement and evaluating the results. Some examples of activities that can be completed to receive MOC Part IV credit are: the American Board of Internal Medicine (ABIM) Approved Quality Improvement (AQI) Program, National Cardiovascular Data Registry (NCDR) Clinical Quality Coach, Quality Practice Initiative Certification Program, American Board of Medical Specialties Practice Performance Improvement Module or American Society of Anesthesiologists (ASA) Simulation Education Network, for improving professional practice including participation in a local, regional or national outcomes registry or quality assessment program; specialty- specific activities including Safety Certification in Outpatient Practice Excellence (SCOPE); American Psychiatric Association (APA) Performance in Practice modules.',
        weighting: 'Medium',
        category: 'Patient Safety and Practice Assessment',
    },
    {
        id: 'IA_PSPA_7',
        title: 'Use of QCDR data for ongoing practice assessment and improvements',
        description:
            'Participation in a Qualified Clinical Data Registry (QCDR) and use of QCDR data for ongoing practice assessment and improvements in patient safety, including:',
        listData: [
            'Performance of activities that promote use of standard practices, tools, and processes for quality improvement (for example, documented preventive health efforts, like screening and vaccinations) that can be shared across MIPS eligible clinicians or groups);',
            'Use of standard questionnaires for assessing improvements in health disparities related to functional health status (for example, use of Seattle Angina Questionnaire, MD Anderson Symptom Inventory, and/or SF-12/VR-12 functional health status assessment);',
            'Use of standardized processes for screening for drivers of health, such as food security, housing stability, and transportation accessibility;',
            'Generation and use of regular feedback reports that summarize local practice patterns and treatment outcomes, including for populations that are disadvantaged and/or underserved by the healthcare system;',
            'Use of processes and tools that engage patients to improve adherence to treatment plans;',
            'Implementation of patient self-action plans;',
            'Implementation of shared clinical decision-making capabilities;',
            'Use of QCDR patient experience data to inform and advance improvements in beneficiary engagement;',
            'Promotion of collaborative learning network opportunities that are interactive;',
            'Use of supporting QCDR modules that can be incorporated into the certified EHR technology; OR',
            'Use of QCDR data for quality improvement, such as comparative analysis across specific patient populations of adverse outcomes after an outpatient surgical procedure and corrective steps to address these outcomes.',
        ],
        weighting: 'Medium',
        category: 'Patient Safety and Practice Assessment',
    },
    {
        id: 'IA_PSPA_8',
        title: 'Use of Patient Safety Tools',
        description:
            'In order to receive credit for this activity, a MIPS eligible clinician must use tools that assist specialty practices in tracking specific measures that are meaningful to their practice. Some examples of tools that could satisfy this activity are: a surgical risk calculator; evidence based protocols, such as Enhanced Recovery After Surgery (ERAS) protocols; the Centers for Disease Control (CDC) Guide for Infection Prevention for Outpatient Settings predictive algorithms; and the opiate risk tool (ORT) or similar tool.',
        weighting: 'Medium',
        category: 'Patient Safety and Practice Assessment',
    },
    {
        id: 'IA_PSPA_12',
        title: 'Participation in private payer CPIA',
        description: 'Participation in designated private payer clinical practice improvement activities.',
        weighting: 'Medium',
        category: 'Patient Safety and Practice Assessment',
    },
    {
        id: 'IA_PSPA_13',
        title: 'Participation in Joint Commission Evaluation Initiative',
        description: 'Participation in Joint Commission Ongoing Professional Practice Evaluation initiative.',
        weighting: 'Medium',
        category: 'Patient Safety and Practice Assessment',
    },
    {
        id: 'IA_PSPA_16',
        title: 'Use of decision support and standardized treatment protocols',
        description:
            'Use decision support and standardized treatment protocols to manage workflow in the team to meet patient needs.',
        weighting: 'Medium',
        category: 'Patient Safety and Practice Assessment',
    },
    {
        id: 'IA_PSPA_19',
        title:
            'Implementation of formal quality improvement methods, practice changes, or other practice improvement processes',
        description:
            'Adopt a formal model for quality improvement and create a culture in which all staff, including leadership, actively participates in improvement activities that could include one or more of the following, such as:',
        listData: [
            'Participation in multisource feedback;',
            'Train all staff in quality improvement methods;',
            'Integrate practice change/quality improvement into staff duties;',
            'Engage all staff in identifying and testing practices changes;',
            'Designate regular team meetings to review data and plan improvement cycles;',
            'Promote transparency and accelerate improvement by sharing practice level and panel level quality of care, patient experience and utilization data with staff;',
            'Promote transparency and engage patients and families by sharing practice level quality of care, patient experience and utilization data with patients and families, including activities in which clinicians act upon patient experience data;',
            'Participation in Bridges to Excellence;',
            'Participation in American Board of Medical Specialties (ABMS) Multi-Specialty Portfolio Program.',
        ],
        weighting: 'Medium',
        category: 'Patient Safety and Practice Assessment',
    },
    {
        id: 'IA_PSPA_28',
        title: 'Completion of an Accredited Safety or Quality Improvement Program',
        description:
            'Completion of an accredited performance improvement continuing medical education (CME) program that addresses performance or quality improvement according to the following criteria:',
        listData: [
            'The activity must address a quality or safety gap that is supported by a needs assessment or problem analysis, or must support the completion of such a needs assessment as part of the activity;',
            'The activity must have specific, measurable aim(s) for improvement;',
            'The activity must include interventions intended to result in improvement;',
            'The activity must include data collection and analysis of performance data to assess the impact of the interventions; and',
            'The accredited program must define meaningful clinician participation in their activity, describe the mechanism for identifying clinicians who meet the requirements, and provide participant completion information.',
            'An example of an activity that could satisfy this improvement activity is completion of an accredited continuing medical education program related to opioid analgesic risk and evaluation strategy (REMS) to address pain control (that is, acute and chronic pain).',
        ],
        weighting: 'Medium',
        category: 'Patient Safety and Practice Assessment',
    },
    {
        id: 'IA_AHE_6',
        title: 'Provide Education Opportunities for New Clinicians',
        description:
            'MIPS eligible clinicians acting as a preceptor for clinicians-in-training (such as medical residents/fellows, medical students, physician assistants, nurse practitioners, or clinical nurse specialists) and accepting such clinicians for clinical rotations in community practices in small, underserved, or rural areas.',
        weighting: 'High',
        category: 'Achieving Health Equity',
    },
    {
        id: 'IA_AHE_8',
        title: 'Create and Implement an Anti-Racism Plan',
        description:
            'Create and implement an anti-racism plan using the CMS Disparities Impact Statement or other anti-racism planning tools. The plan should include a clinic- wide review of existing tools and policies, such as value statements or clinical practice guidelines, to ensure that they include and are aligned with a commitment to anti-racism and an understanding of race as a political and social construct, not a physiological one. The plan should also identify ways in which issues and gaps identified in the review can be addressed and should include target goals and milestones for addressing prioritized issues and gaps. This may also include an assessment and drafting of an organization’s plan to prevent and address racism and/or improve language access and accessibility to ensure services are accessible and understandable for those seeking care. The MIPS eligible clinician or practice can also consider including in their plan ongoing training on anti-racism and/or other processes to support identifying explicit and implicit biases in patient care and addressing historic health inequities experienced by people of color. More information about elements of the CMS Disparities Impact Statement is detailed in the template and action plan document at: https://www.cms.gov/About- CMS/Agency-Information/OMH/Downloads/Disparities-Impact-Statement-508-rev102018.pdf',
        weighting: 'High',
        category: 'Achieving Health Equity',
    },
    {
        id: 'IA_BMH_12',
        title: 'Promoting Clinician Well-Being',
        description:
            'Develop and implement programs to support clinician well-being and resilience—for example, through relationship-building opportunities, leadership development plans, or creation of a team within a practice to address clinician well-being—using one of the following approaches:',
        listData: [
            'Completion of clinician survey on clinician well-being with subsequent implementation of an improvement plan based on the results of the survey.',
            'Completion of training regarding clinician well-being with subsequent implementation of a plan for improvement.',
        ],
        weighting: 'High',
        category: 'Behavioral and Mental Health',
    },
    {
        id: 'IA_ERP_2',
        title: 'Participation in a 60-day or greater effort to support domestic or international humanitarian needs',
        description:
            'Participation in domestic or international humanitarian volunteer work. Activities that simply involve registration are not sufficient. MIPS eligible clinicians and groups attest to domestic or international humanitarian volunteer work for a period of a continuous 60 days or greater.',
        weighting: 'High',
        category: 'Emergency Response & Preparedness',
    },
    {
        id: 'IA_ERP_4',
        title: 'Implementation of a Personal Protective Equipment (PPE) Plan',
        description:
            'Implement a plan to acquire, store, maintain, and replenish supplies of personal protective equipment (PPE) for all clinicians or other staff who are in physical proximity to patients. In accordance with guidance from the Centers for Disease Control and Prevention (CDC) the PPE plan should address:',
        listData: [
            'Conventional capacity: PPE controls that should be implemented in general infection prevention and control plans in healthcare settings, including training in proper PPE use.',
            'Contingency capacity: actions that may be used temporarily during periods of expected PPE shortages.',
            'Crisis capacity: strategies that may need to be considered during periods of known PPE shortages.',
            'The PPE plan should address all of the following types of PPE:',
            'Standard precautions (e.g., hand hygiene, prevention of needle-stick or sharps injuries, safe waste management, cleaning and disinfection of the environment)',
            'Eye protection',
            'Gowns (including coveralls or aprons) • Gloves',
            'Facemasks',
            'Respirators (including N95 respirators)',
        ],
        weighting: 'Medium',
        category: 'Emergency Response & Preparedness',
    },
];

export const ACTIVITIES_2024: Activity[] = [
    {
        id: 'IA_EPA_1',
        title:
            "Provide 24/7 Access to MIPS Eligible Clinicians or Groups Who Have Real-Time Access to Patient's Medical Record",
        description:
            'Provide 24/7 access to MIPS eligible clinicians, groups, or care teams for advice about urgent care (e.g., MIPS eligible clinician and care team access to medical record, cross-coverage with access to medical record, or protocol- driven nurse line with access to medical record) that could include one or more of the following:',
        listData: [
            'Expanded hours in evenings and weekends with access to the patient medical record (e.g., coordinate with small practices to provide alternate hour office visits and urgent care);',
            'Use of alternatives to increase access to care team by MIPS eligible clinicians and groups, such as e-visits, phone visits, group visits, home visits and alternate locations (e.g., senior centers and assisted living centers); and/or',
            'Provision of same-day or next-day access to a MIPS eligible clinician, group or care team when needed for urgent care or transition management.',
        ],
        weighting: 'High',
        category: 'Expanded Practice Access',
    },
    {
        id: 'IA_PM_11',
        title: 'Regular Review Practices in Place on Targeted Patient Population Needs',
        description:
            "Implement regular reviews of targeted patient population needs, such as structured clinical case reviews, which include access to reports that show unique characteristics of MIPS eligible clinician's patient population, identification of underserved patients, and how clinical treatment needs are being tailored, if necessary, to address unique needs and what resources in the community have been identified as additional resources. The review should consider how structural inequities, such as racism, are influencing patterns of care and consider changes to acknowledge and address them. Reviews should stratify patient data by demographic characteristics and health related social needs to appropriately identify differences among unique populations and assess the drivers of gaps and disparities and identify interventions appropriate for the needs of the sub-populations.",
        weighting: 'Medium',
        category: 'Population Management',
    },
    {
        id: 'IA_CC_8',
        title: 'Implementation of documentation improvements for practice/process improvements',
        description:
            'Implementation of practices/processes that document care coordination activities (e.g., a documented care coordination encounter that tracks all clinical staff involved and communications from date patient is scheduled for outpatient procedure through day of procedure).',
        weighting: 'Medium',
        category: 'Care Coordination',
    },
    {
        id: 'IA_CC_15',
        title: 'PSH Care Coordination',
        description:
            'Participation in a Perioperative Surgical Home (PSH) that provides a patient-centered, physician-led, interdisciplinary, and team-based system of coordinated patient care, which coordinates care from pre-procedure assessment through the acute care episode, recovery, and post-acute care. This activity allows for reporting of strategies and processes related to care coordination of patients receiving surgical or procedural care within a PSH. The clinician must perform one or more of the following care coordination activities:',
        listData: [
            'Coordinate with care managers/navigators in preoperative clinic to plan and implementation comprehensive post discharge plan of care;',
            'Deploy perioperative clinic and care processes to reduce post-operative visits to emergency rooms;',
            'Implement evidence-informed practices and standardize care across the entire spectrum of surgical patients; or',
            'Implement processes to ensure effective communications and education of patients’ post-discharge instructions.',
        ],
        weighting: 'Medium',
        category: 'Care Coordination',
    },
    {
        id: 'IA_CC_19',
        title:
            'Tracking of clinician’s relationship to and responsibility for a patient by reporting MACRA patient relationship codes.',
        description:
            'To receive credit for this improvement activity, a MIPS eligible clinician must attest that they reported MACRA patient relationship codes (PRC) using the applicable HCPCS modifiers on 50 percent or more of their Medicare claims for a minimum of a continuous 90-day period within the performance period. Reporting the PRC modifiers enables the identification of a clinician’s relationship with, and responsibility for, a patient at the time of furnishing an item or service. See the CY 2018 PFS final rule (82 FR 53232 through 53234) for more details on these codes.',
        weighting: 'High',
        category: 'Care Coordination',
    },
    {
        id: 'IA_BE_6',
        title: 'Collection and follow-up on patient experience and satisfaction data on beneficiary engagement',
        description:
            'Collect and follow up on patient experience and satisfaction data. This activity also requires follow-up on findings of assessments, including the development and implementation of improvement plans. To fulfill the requirements of this activity, MIPS eligible clinicians can use surveys (e.g., Consumer Assessment of Healthcare Providers and Systems Survey), advisory councils, or other mechanisms. MIPS eligible clinicians may consider implementing patient surveys in multiple languages, based on the needs of their patient population.',
        weighting: 'High',
        category: 'Beneficiary Engagement',
    },
    {
        id: 'IA_BE_22',
        title: 'Improved Practices that Engage Patients Pre-Visit',
        description:
            'Implementation of workflow changes that engage patients prior to the visit, such as a pre-visit development of a shared visit agenda with the patient, or targeted pre-visit laboratory testing that will be resulted and available to the MIPS eligible clinician to review and discuss during the patient’s appointment.',
        weighting: 'Medium',
        category: 'Beneficiary Engagement',
    },
    {
        id: 'IA_PSPA_1',
        title: 'Participation in an AHRQ-listed patient safety organization.',
        description: 'Participation in an AHRQ-listed patient safety organization.',
        weighting: 'Medium',
        category: 'Patient Safety and Practice Assessment',
    },
    {
        id: 'IA_PSPA_2',
        title: 'Participation in MOC Part IV',
        description:
            'In order to receive credit for this activity, a MIPS eligible clinician must participate in Maintenance of Certification (MOC) Part IV. Maintenance of Certification (MOC) Part IV requires clinicians to perform monthly activities across practice to regularly assess performance by reviewing outcomes addressing identified areas for improvement and evaluating the results. Some examples of activities that can be completed to receive MOC Part IV credit are: the American Board of Internal Medicine (ABIM) Approved Quality Improvement (AQI) Program, National Cardiovascular Data Registry (NCDR) Clinical Quality Coach, Quality Practice Initiative Certification Program, American Board of Medical Specialties Practice Performance Improvement Module or American Society of Anesthesiologists (ASA) Simulation Education Network, for improving professional practice including participation in a local, regional or national outcomes registry or quality assessment program; specialty- specific activities including Safety Certification in Outpatient Practice Excellence (SCOPE); American Psychiatric Association (APA) Performance in Practice modules.',
        weighting: 'Medium',
        category: 'Patient Safety and Practice Assessment',
    },
    {
        id: 'IA_PSPA_7',
        title: 'Use of QCDR data for ongoing practice assessment and improvements',
        description:
            'Participation in a Qualified Clinical Data Registry (QCDR) and use of QCDR data for ongoing practice assessment and improvements in patient safety, including:',
        listData: [
            'Performance of activities that promote use of standard practices, tools, and processes for quality improvement (for example, documented preventive health efforts, like screening and vaccinations) that can be shared across MIPS eligible clinicians or groups);',
            'Use of standard questionnaires for assessing improvements in health disparities related to functional health status (for example, use of Seattle Angina Questionnaire, MD Anderson Symptom Inventory, and/or SF-12/VR-12 functional health status assessment);',
            'Use of standardized processes for screening for drivers of health, such as food security, housing stability, and transportation accessibility;',
            'Generation and use of regular feedback reports that summarize local practice patterns and treatment outcomes, including for populations that are disadvantaged and/or underserved by the healthcare system;',
            'Use of processes and tools that engage patients to improve adherence to treatment plans;',
            'Implementation of patient self-action plans;',
            'Implementation of shared clinical decision-making capabilities;',
            'Use of QCDR patient experience data to inform and advance improvements in beneficiary engagement;',
            'Promotion of collaborative learning network opportunities that are interactive;',
            'Use of supporting QCDR modules that can be incorporated into the certified EHR technology; OR',
            'Use of QCDR data for quality improvement, such as comparative analysis across specific patient populations of adverse outcomes after an outpatient surgical procedure and corrective steps to address these outcomes.',
        ],
        weighting: 'Medium',
        category: 'Patient Safety and Practice Assessment',
    },
    {
        id: 'IA_PSPA_8',
        title: 'Use of Patient Safety Tools',
        description:
            'In order to receive credit for this activity, a MIPS eligible clinician must use tools that assist specialty practices in tracking specific measures that are meaningful to their practice. Some examples of tools that could satisfy this activity are: a surgical risk calculator; evidence based protocols, such as Enhanced Recovery After Surgery (ERAS) protocols; the Centers for Disease Control (CDC) Guide for Infection Prevention for Outpatient Settings predictive algorithms; and the opiate risk tool (ORT) or similar tool.',
        weighting: 'Medium',
        category: 'Patient Safety and Practice Assessment',
    },
    {
        id: 'IA_PSPA_12',
        title: 'Participation in private payer CPIA',
        description: 'Participation in designated private payer clinical practice improvement activities.',
        weighting: 'Medium',
        category: 'Patient Safety and Practice Assessment',
    },
    {
        id: 'IA_PSPA_13',
        title: 'Participation in Joint Commission Evaluation Initiative',
        description: 'Participation in Joint Commission Ongoing Professional Practice Evaluation initiative.',
        weighting: 'Medium',
        category: 'Patient Safety and Practice Assessment',
    },
    {
        id: 'IA_PSPA_16',
        title: 'Use of decision support and standardized treatment protocols',
        description:
            'Use decision support and standardized treatment protocols to manage workflow in the team to meet patient needs.',
        weighting: 'Medium',
        category: 'Patient Safety and Practice Assessment',
    },
    {
        id: 'IA_PSPA_19',
        title:
            'Implementation of formal quality improvement methods, practice changes, or other practice improvement processes',
        description:
            'Adopt a formal model for quality improvement and create a culture in which all staff, including leadership, actively participates in improvement activities that could include one or more of the following, such as:',
        listData: [
            'Participation in multisource feedback;',
            'Train all staff in quality improvement methods;',
            'Integrate practice change/quality improvement into staff duties;',
            'Engage all staff in identifying and testing practices changes;',
            'Designate regular team meetings to review data and plan improvement cycles;',
            'Promote transparency and accelerate improvement by sharing practice level and panel level quality of care, patient experience and utilization data with staff;',
            'Promote transparency and engage patients and families by sharing practice level quality of care, patient experience and utilization data with patients and families, including activities in which clinicians act upon patient experience data;',
            'Participation in Bridges to Excellence;',
            'Participation in American Board of Medical Specialties (ABMS) Multi-Specialty Portfolio Program.',
        ],
        weighting: 'Medium',
        category: 'Patient Safety and Practice Assessment',
    },
    {
        id: 'IA_PSPA_28',
        title: 'Completion of an Accredited Safety or Quality Improvement Program',
        description:
            'Completion of an accredited performance improvement continuing medical education (CME) program that addresses performance or quality improvement according to the following criteria:',
        listData: [
            'The activity must address a quality or safety gap that is supported by a needs assessment or problem analysis, or must support the completion of such a needs assessment as part of the activity;',
            'The activity must have specific, measurable aim(s) for improvement;',
            'The activity must include interventions intended to result in improvement;',
            'The activity must include data collection and analysis of performance data to assess the impact of the interventions; and',
            'The accredited program must define meaningful clinician participation in their activity, describe the mechanism for identifying clinicians who meet the requirements, and provide participant completion information.',
            'An example of an activity that could satisfy this improvement activity is completion of an accredited continuing medical education program related to opioid analgesic risk and evaluation strategy (REMS) to address pain control (that is, acute and chronic pain).',
        ],
        weighting: 'Medium',
        category: 'Patient Safety and Practice Assessment',
    },
    {
        id: 'IA_AHE_6',
        title: 'Provide Education Opportunities for New Clinicians',
        description:
            'MIPS eligible clinicians acting as a preceptor for clinicians-in-training (such as medical residents/fellows, medical students, physician assistants, nurse practitioners, or clinical nurse specialists) and accepting such clinicians for clinical rotations in community practices in small, underserved, or rural areas.',
        weighting: 'High',
        category: 'Achieving Health Equity',
    },
    {
        id: 'IA_AHE_8',
        title: 'Create and Implement an Anti-Racism Plan',
        description:
            'Create and implement an anti-racism plan using the CMS Disparities Impact Statement or other anti-racism planning tools. The plan should include a clinic- wide review of existing tools and policies, such as value statements or clinical practice guidelines, to ensure that they include and are aligned with a commitment to anti-racism and an understanding of race as a political and social construct, not a physiological one. The plan should also identify ways in which issues and gaps identified in the review can be addressed and should include target goals and milestones for addressing prioritized issues and gaps. This may also include an assessment and drafting of an organization’s plan to prevent and address racism and/or improve language access and accessibility to ensure services are accessible and understandable for those seeking care. The MIPS eligible clinician or practice can also consider including in their plan ongoing training on anti-racism and/or other processes to support identifying explicit and implicit biases in patient care and addressing historic health inequities experienced by people of color. More information about elements of the CMS Disparities Impact Statement is detailed in the template and action plan document at: https://www.cms.gov/About- CMS/Agency-Information/OMH/Downloads/Disparities-Impact-Statement-508-rev102018.pdf',
        weighting: 'High',
        category: 'Achieving Health Equity',
    },
    {
        id: 'IA_BMH_12',
        title: 'Promoting Clinician Well-Being',
        description:
            'Develop and implement programs to support clinician well-being and resilience—for example, through relationship-building opportunities, leadership development plans, or creation of a team within a practice to address clinician well-being—using one of the following approaches:',
        listData: [
            'Completion of clinician survey on clinician well-being with subsequent implementation of an improvement plan based on the results of the survey.',
            'Completion of training regarding clinician well-being with subsequent implementation of a plan for improvement.',
        ],
        weighting: 'High',
        category: 'Behavioral and Mental Health',
    },
    {
        id: 'IA_ERP_2',
        title: 'Participation in a 60-day or greater effort to support domestic or international humanitarian needs',
        description:
            'Participation in domestic or international humanitarian volunteer work. Activities that simply involve registration are not sufficient. MIPS eligible clinicians and groups attest to domestic or international humanitarian volunteer work for a period of a continuous 60 days or greater.',
        weighting: 'High',
        category: 'Emergency Response & Preparedness',
    },
    {
        id: 'IA_ERP_4',
        title: 'Implementation of a Personal Protective Equipment (PPE) Plan',
        description:
            'Implement a plan to acquire, store, maintain, and replenish supplies of personal protective equipment (PPE) for all clinicians or other staff who are in physical proximity to patients. In accordance with guidance from the Centers for Disease Control and Prevention (CDC) the PPE plan should address:',
        listData: [
            'Conventional capacity: PPE controls that should be implemented in general infection prevention and control plans in healthcare settings, including training in proper PPE use.',
            'Contingency capacity: actions that may be used temporarily during periods of expected PPE shortages.',
            'Crisis capacity: strategies that may need to be considered during periods of known PPE shortages.',
            'The PPE plan should address all of the following types of PPE:',
            'Standard precautions (e.g., hand hygiene, prevention of needle-stick or sharps injuries, safe waste management, cleaning and disinfection of the environment)',
            'Eye protection',
            'Gowns (including coveralls or aprons) • Gloves',
            'Facemasks',
            'Respirators (including N95 respirators)',
        ],
        weighting: 'Medium',
        category: 'Emergency Response & Preparedness',
    },
];

class QcdrSettingsStore {
    @observable isLoading = false;
    @observable qcdrSettings: QcdrSettings = null;
    @observable _tinSettings: QcdrSettings['tinSettings'] = null;
    @observable _reportingSettings: QcdrSettings['reportingSettings'] = null;
    @observable _activitySettings: QcdrSettings['activitySettings'] = null;
    @observable pqrsProviders: PqrsProvider[] = [];
    @observable newProvidersAdded = false;
    REPORTING_FEE: number = 180;

    constructor() {
        makeObservable(this);
    }

    @action
    reset() {
        this.isLoading = false;
        this.qcdrSettings = null;
        this._tinSettings = null;
        this._reportingSettings = null;
        this._activitySettings = null;
        this.pqrsProviders = [];
    }

    @action
    async getQcdrSettings(year: MacraAvailableYears = 2023) {
        this.isLoading = true;
        const result = await macraAPI.getQcdrSettings(year);
        const qcdrSettingsResult: QcdrSettingsResult = result.data;

        runInAction(() => {
            this.qcdrSettings = qcdrSettingsResult.qcdrSetting;
            this._tinSettings = this.qcdrSettings ? this.qcdrSettings.tinSettings : null;
            this._reportingSettings = this.qcdrSettings ? this.qcdrSettings.reportingSettings : null;
            this._activitySettings = this.qcdrSettings ? this.qcdrSettings.activitySettings : null;
            this.pqrsProviders = qcdrSettingsResult.providers
                .sort((a, b) => a.providerLastName.localeCompare(b.providerLastName))
                .map((provider) => {
                    return {
                        ...provider,
                        providerType: provider.providerType === 'MDA' ? 'PHYS' : provider.providerType,
                    };
                });
            const newProviders = this.getNewProviders();

            // Temp comment
            /*if (newProviders.length > 0) {
                this.newProvidersAdded = true;
                this.addNewProviders(newProviders);
            }*/

            this.isLoading = false;
        });
    }

    async getMacraSignature(year: number) {
        const signatureResult = await macraAPI.getMacraSignature(year);

        return signatureResult.data;
    }

    async saveMacraSignature(year: number, signature: string) {
        const signatureResult = await macraAPI.saveMacraSignature(year, signature);
    }

    getNewProviders() {
        if (!this._tinSettings) return [];
        return this._tinSettings
            .map((setting) => {
                const facProviders = this.getPqrsProvidersForFacility(setting.facilityId);

                return facProviders.filter((provider) => {
                    return !setting.providers.find((providerTin) => provider.providerNpi === providerTin.npi);
                });
            })
            .flat();
    }

    addNewProviders(newProviders: PqrsProvider[]) {
        newProviders.forEach((provider) => {
            let tinSetting = this._tinSettings.find((setting) => setting.facilityId === provider.facilityId);
            tinSetting.providers = [
                ...tinSetting.providers,
                { tin: tinSetting.applyTinToAll ? tinSetting.groupTin : null, npi: provider.providerNpi },
            ];
        });
        this._reportingSettings = null;
    }

    @action
    async saveQcdrSettings(step: number, year: MacraAvailableYears = 2023) {
        this.isLoading = true;
        const result = await macraAPI.saveQcdrSettings(year, step, {
            tinSettings: this.tinSettings,
            reportingSettings: this.reportingSettings,
            activitySettings: this.activitySettings,
        });
        this.isLoading = false;
    }

    @computed
    get pqrsProviderMap(): Map<number, PqrsProvider> {
        let providerMap: Map<number, PqrsProvider> = new Map();
        this.pqrsProviders.slice().forEach((provider, index) => {
            if (provider.providerType === 'MDA') provider.providerType = 'PHYS';
            providerMap.set(parseInt(provider.providerNpi), provider);
        });
        return providerMap;
    }

    getPqrsProvidersForFacility(facilityId: number) {
        return this.pqrsProviders.filter((provider) => provider.facilityId === facilityId);
    }

    @computed
    get tinSettings(): QcdrSettings['tinSettings'] {
        return this._tinSettings !== null ? this._tinSettings : this.generateTinSettings();
    }

    generateTinSettings(): QcdrSettings['tinSettings'] {
        return FacilityStoreObject.facilities.map((facility, index) => {
            const facProviders: ProviderTin[] = this.pqrsProviders
                .filter((provider) => {
                    return provider.facilityId === facility.facId;
                })
                .map(
                    (value): ProviderTin => {
                        return {
                            npi: value.providerNpi,
                            tin: null,
                        };
                    },
                );
            return {
                facilityId: facility.facId,
                submitForFacility: false,
                groupTin: null,
                applyTinToAll: false,
                providers: facProviders,
            };
        });
    }

    getFacIdsForTin(tin: string | number) {
        const facIds = this.tinSettings.map((setting) => setting.facilityId);
        const facilitiesInTin: number[] = [];

        for (let facId of facIds) {
            const setting = this.tinSettings.find((setting) => setting.facilityId === facId);

            if (setting.applyTinToAll && setting.groupTin === tin) facilitiesInTin.push(facId);
        }

        return FacilityStoreObject.facilities.filter((facility: Facility) => {
            return facilitiesInTin.indexOf(facility.facId) !== -1;
        });
    }

    @action
    setTinSettings(settings: TinSetting[]) {
        this._tinSettings = settings;
    }

    @computed
    get isTinSettingsValid() {
        return (
            this.tinSettings.some((setting) => {
                return setting.submitForFacility === true;
            }) &&
            this.tinSettings.some((setting) => {
                if (setting.submitForFacility === false) return false;

                return setting.providers.some((provider) => {
                    return provider.tin === null;
                });
            }) === false
        );
    }

    @computed
    get tins() {
        const allTins = this.tinSettings
            .map((setting, index) => {
                return setting.providers.map((providerTin) => {
                    return providerTin.tin;
                });
            })
            .flat()
            .filter((value) => value != null);

        return [...new Set(allTins)].sort();
    }

    @computed
    get reportingSettings(): QcdrSettings['reportingSettings'] {
        return this._reportingSettings !== null
            ? this._reportingSettings
            : this.tins.map((tin) => {
                  return {
                      tin,
                      reportingType: 'group',
                      providers: this.getProviderNpisForTin(tin).map((npi) => {
                          return {
                              npi,
                              enableReporting: true,
                          };
                      }),
                  };
              });
    }

    set reportingSettings(settings: ReportingSetting[]) {
        runInAction(() => {
            this._reportingSettings = settings;
        });
    }

    isTinReporting(tin: number | string) {
        const setting = (this._reportingSettings || []).find((item) => {
            return item.tin === tin;
        });

        return setting
            ? setting.providers
                  .map((status) => status.enableReporting)
                  .flat()
                  .includes(true)
            : true;
    }

    @computed
    get activitySettings() {
        return this._activitySettings !== null
            ? this._activitySettings
            : this.tins
                  .map((tin) => {
                      const isReporting = this.isTinReporting(tin);

                      if (isReporting) {
                          return {
                              tin,
                              activity1: null,
                              activity2: null,
                              activity3: null,
                              activity4: null,
                              activity5: null,
                              activity6: null,
                              activity7: null,
                              activity8: null,
                              activity9: null,
                              activity10: null,
                              activity11: null,
                              activity12: null,
                              activity13: null,
                              activity14: null,
                              activity15: null,
                              activity16: null,
                              activity17: null,
                              activity18: null,
                              activity19: null,
                              activity20: null,
                              activity21: null,
                          };
                      }
                  })
                  .filter((item) => item != undefined);
    }

    set activitySettings(settings: QcdrSettings['activitySettings']) {
        runInAction(() => {
            this._activitySettings = settings;
        });
    }

    @computed
    get isActivitySettingsValid() {
        return (
            this.activitySettings.some((setting) => {
                const tinSettingForThisReportingSetting = this.tinSettings.find(
                    (tinSetting) => tinSetting.groupTin === setting.tin,
                );

                if (tinSettingForThisReportingSetting?.submitForFacility === false) {
                    return false;
                }

                return (
                    setting.activity1 === null ||
                    setting.activity2 === null ||
                    setting.activity3 === null ||
                    setting.activity4 === null ||
                    setting.activity5 === null ||
                    setting.activity6 === null ||
                    setting.activity7 === null ||
                    setting.activity8 === null ||
                    setting.activity9 === null ||
                    setting.activity10 === null ||
                    setting.activity11 === null ||
                    setting.activity12 === null ||
                    setting.activity13 === null ||
                    setting.activity14 === null ||
                    setting.activity15 === null ||
                    setting.activity16 === null ||
                    setting.activity17 === null ||
                    setting.activity18 === null ||
                    setting.activity19 === null ||
                    setting.activity20 === null ||
                    setting.activity21 === null
                );
            }) === false
        );
    }
    @computed
    get reportingFee() {
        return this.reportingSettings.reduce((total, setting) => {
            const tinSettingForThisReportingSetting = this.tinSettings.find(
                (tinSetting) => tinSetting.groupTin === setting.tin,
            );
            if (tinSettingForThisReportingSetting?.submitForFacility) {
                const reportCount = setting.providers.reduce((count, provider) => {
                    return provider.enableReporting ? ++count : count;
                }, 0);
                total += reportCount * this.REPORTING_FEE;
            }
            return total;
        }, 0);
    }

    getProviderNpisForTin(tin: number | string) {
        const npis = this.tinSettings
            .map((setting) => {
                return setting.providers.filter((provider) => provider.tin === tin).map((provider) => provider.npi);
            })
            .flat();

        return [...new Set(npis)];
    }

    getProviderByNpi(npi: string) {
        return this.pqrsProviders.find((provider) => provider.providerNpi === npi);
    }

    updateTinSetting(updatedSetting: TinSetting) {
        this.setTinSettings(
            this.tinSettings.map((setting) => {
                return setting.facilityId === updatedSetting.facilityId ? updatedSetting : setting;
            }),
        );

        this.reportingSettings = null;
        this.activitySettings = null;
    }

    updateReportingSetting(updatedSetting: ReportingSetting) {
        this.reportingSettings = this.reportingSettings.map<ReportingSetting>((setting) => {
            return setting.tin === updatedSetting.tin ? updatedSetting : setting;
        });

        this.activitySettings = null;
    }

    updateActivitySetting(updatedSetting: ActivitySettings) {
        this.activitySettings = this.activitySettings.map<ActivitySettings>((setting) => {
            return setting.tin === updatedSetting.tin ? updatedSetting : setting;
        });
    }

    async getQcdrExport(params: {
        startDate: string;
        endDate: string;
        facilityId: string | number;
        currentIdMode: string;
        year: MacraAvailableYears;
    }) {
        const { startDate, endDate, facilityId, currentIdMode, year } = params;
        const url = await macraAPI.getQcdrExport({
            startDate,
            endDate,
            facilityId,
            isTin: currentIdMode === IdMode.TIN,
            year,
        });
        return url;
    }

    async downloadQcdrExport(url: string) {
        const csvData = await macraAPI.downloadQcdrExport(url);
        return csvData.data || '';
    }

    async getAqiExport(params: {
        practiceId: string;
        taxId: string;
        startDate: string;
        endDate: string;
        facilityId: string | number;
        year: MacraAvailableYears;
    }) {
        const { practiceId, taxId, startDate, endDate, facilityId, year } = params;
        const url = await macraAPI.getAqiExport({
            practiceId,
            taxId,
            startDate,
            endDate,
            facilityId,
            year,
        });
        return url;
    }

    async downloadAqiExport(url: string) {
        const xmlData = await macraAPI.downloadAqiExport(url);
        return xmlData.data || '';
    }
}

export const QcdrSettingsStoreObject = new QcdrSettingsStore();

export default createContext(QcdrSettingsStoreObject);
