import React from 'react';

const SvgAppointment = (props) => (
    <svg width={33} height={33} {...props} viewBox="0 0 33 33">
        <g transform="translate(1 1)" fill="none" fillRule="evenodd">
            <circle stroke="#F1F0F0" fill="#F9F9F9" cx={15.5} cy={15.5} r={15.5} />
            <g fill="#DA1057" fillRule="nonzero">
                <path d="M18.671 14.804a1.25 1.25 0 00-1.766 0l-4.428 4.428a.348.348 0 00-.097.18L12.062 21a.354.354 0 00.418.417l1.587-.317a.358.358 0 00.18-.097l4.43-4.428a1.25 1.25 0 000-1.766l-.006-.005z" />
                <path d="M22.23 9.125H20.28a.177.177 0 01-.177-.177v-1.24a.708.708 0 00-1.416 0v3.365a.531.531 0 11-1.063 0V9.479a.354.354 0 00-.354-.354h-4.427a.177.177 0 01-.177-.176v-1.24a.708.708 0 00-1.417 0v3.364a.531.531 0 01-1.063 0V9.479a.354.354 0 00-.354-.354H8.771c-.783 0-1.417.634-1.417 1.417v12.041c0 .783.634 1.417 1.417 1.417h13.458c.783 0 1.417-.634 1.417-1.417V10.542c0-.783-.634-1.417-1.417-1.417zm0 13.104a.354.354 0 01-.355.354H9.125a.354.354 0 01-.354-.354v-8.5c0-.195.158-.354.354-.354h12.75c.196 0 .354.159.354.354v8.5z" />
            </g>
        </g>
    </svg>
);

export default SvgAppointment;
