import React from 'react';
import styles from './styles.module.scss';
import { ResponsiveLine } from '@nivo/line';
import * as variables from 'src/styles/variables';

interface FormChartData {
    id: string | number;
    color?: string;
    data: FormChartEntry[];
}

interface FormChartEntry {
    x: number | string;
    y: number | string;
}

interface FormChartProps {
    name: string;
    color: string;
    data: FormChartData[];
}

function FormChart(props: FormChartProps) {
    function getBaseline() {
        const yValues = props.data[0].data.map((entry) => entry && Number(entry.y));
        const minValue = Math.min(...yValues);
        return minValue - minValue * 0.005;
    }

    return (
        <div className={styles.chartContainer}>
            <div className={styles.chartTitle}>{props.name.toUpperCase()}</div>
            <div className={styles.chartWrap}>
                <ResponsiveLine
                    data={props.data}
                    margin={{ top: 50, right: 60, bottom: 50, left: 60 }}
                    xScale={{ type: 'point' }}
                    yScale={{ type: 'linear', stacked: true, min: 'auto', max: 'auto' }}
                    curve="linear"
                    axisTop={null}
                    axisRight={null}
                    axisBottom={null}
                    areaBaselineValue={getBaseline()}
                    axisLeft={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: props.name,
                        legendOffset: -50,
                        legendPosition: 'middle',
                    }}
                    enableGridX={false}
                    enableGridY={false}
                    // @ts-ignore
                    colors={[variables[props.color]]}
                    lineWidth={4}
                    enablePoints={true}
                    pointSize={12}
                    // @ts-ignore
                    pointColor={variables[props.color]}
                    pointBorderWidth={3}
                    pointBorderColor={{ from: 'serieColor' }}
                    pointLabel="y"
                    pointLabelYOffset={-12}
                    enableArea={true}
                    areaOpacity={0.5}
                    useMesh={true}
                    legends={[]}
                />
            </div>
        </div>
    );
}

export default FormChart;
