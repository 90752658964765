import { useEffect } from 'react';

export default function useClickOutside(onClose: Function, nodes: React.RefObject<any>[]) {
    const handleClick = (e: any) => {
        for (const node of nodes) {
            if (!node || node.current === undefined || node.current === null) return;
            if (node.current.contains(e.target)) {
                return;
            }
        }
        onClose();
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClick);

        return () => {
            document.removeEventListener('mousedown', handleClick);
        };
    }, []);
}
