import * as ROUTES from 'src/api/routes';
import request from './utils';
import { FormModel } from 'common/lib/entity/framework/FormModel';

export async function getFormModels() {
    const result = await request({ ...ROUTES.GET_FORM_MODELS });
    return result?.data || [];
}

export async function getFormModelById(formModelId: FormModel['formModelId']) {
    const result = await request({ ...ROUTES.GET_FORM_MODEL_BY_ID, pathParams: { formModelId } });
    return result?.data || {};
}

export async function createFormModel(formModel: Partial<FormModel>): Promise<FormModel['formModelId']> {
    const formModelId = await request({ ...ROUTES.CREATE_FORM_MODEL, data: { ...formModel } });
    return formModelId?.data || '';
}

export async function updateFormModel(
    formModelId: FormModel['formModelId'],
    formModel: Partial<FormModel>,
): Promise<void> {
    await request({ ...ROUTES.UPDATE_FORM_MODEL, pathParams: { formModelId }, data: { ...formModel } });
}

export async function deleteFormModel(formModelId: FormModel['formModelId']) {
    return await request({ ...ROUTES.DELETE_FORM_MODEL, pathParams: { formModelId } });
}
