import React, { useRef, RefObject, useState, useEffect, useContext } from 'react';
import Portal from '../Portal';
import styles from './styles.module.scss';
import classNames from 'classnames';
import Flex from 'src/components/Flex';
import Card from 'src/components/Card';
import Input from 'src/components/Input';
import * as patientService from 'src/api/patients';
import Gender from 'src/components/Gender';
import Age from 'src/components/Age';
import Icon from 'src/components/Icon';
import useClickOutside from 'src/utils/hooks/useClickOutside';
import FacilityStore from 'src/stores/FacilityStore';
import useDebouncedCallback from 'src/utils/hooks/useDebouncedCallback';
import { useHistory } from 'react-router';
import LoadingIcon from '../LoadingIcon';
import { getPatientName } from 'src/utils';
import { ROUTES } from 'src/utils/constants';

export interface SearchPanelProps {
    isOpen: boolean;
    onClose: Function;
    parentRef: RefObject<HTMLDivElement>;
}

export function PatientItem(props: { item: any; onSelect: Function }) {
    const { item } = props;
    return (
        <Flex direction="row" align="center" className={styles.resultsRow} onClick={() => props.onSelect(item)}>
            <Flex align="center" justify="center" className={styles.imageCircle}>
                <Icon type="fa" name="user" className={styles.icon} />
            </Flex>
            <Flex direction="column" align="start" justify="center">
                <div className={styles.name}>{getPatientName(item)}</div>
                <Flex direction="row">
                    <Gender className={classNames(styles.genderText)} value={item.patientGenderCode} />
                    <Age className={styles.dobText} dob={item.patientDob} />
                </Flex>
            </Flex>
        </Flex>
    );
}

function SearchPanel(props: SearchPanelProps) {
    if (!props.isOpen) {
        return null;
    }

    const node = useRef<HTMLDivElement>();
    const [searchValue, setSearchValue] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [isSearching, setIsSearching] = useState(false);
    const [facilities, setFacilities] = useState([]);
    const [groupedResults, setGroupedResults] = useState([]);
    const history = useHistory();
    const facilityStore = useContext(FacilityStore);

    const [refreshSearchResults] = useDebouncedCallback(async (query: string) => {
        if (!query) {
            return;
        }
        try {
            setIsSearching(true);
            const { result } = await patientService.getPatients({ query });
            setSearchResults(result);
            setIsSearching(false);
        } catch (error) {
            setIsSearching(false);
        }
    }, 1000);

    useClickOutside(() => props.onClose(), [node, props.parentRef]);

    useEffect(() => {
        if (facilityStore.facilities.length === 0) {
            facilityStore.getFacilities();
        }
    }, []);

    function handleUpdateSearchQuery(t: string) {
        setSearchValue(t);
        refreshSearchResults(t);
    }

    useEffect(() => {
        const result = searchResults.reduce(function (r, a) {
            r[a.facId] = r[a.facId] || [];
            r[a.facId].push(a);
            return r;
        }, Object.create(null));
        setFacilities(Object.keys(result));
        setGroupedResults(result);
    }, [searchResults]);

    function handleSelect(patient: any) {
        props.onClose();
        setSearchResults([]);
        setSearchValue('');
        history.push(ROUTES.getString(ROUTES.PatientDetails, patient.patientId));
    }
    return (
        <Portal node={props.parentRef.current}>
            <div ref={node} className={classNames(styles.panelWrap)}>
                <div className={styles.triangle} />
                <Card elevation={4}>
                    <Flex direction="row" className={styles.searchWrap}>
                        <Flex value={1} className={styles.pageTitle}>
                            <div>
                                <Input
                                    autoFocus={true}
                                    className={styles.input}
                                    placeholder="Search..."
                                    value={searchValue}
                                    onChangeText={(t: string) => handleUpdateSearchQuery(t)}
                                />
                            </div>
                            {isSearching ? (
                                <Flex direction="row" align="center" justify="center" className={styles.searchLoading}>
                                    <LoadingIcon type="dot" />
                                </Flex>
                            ) : searchValue.length > 0 && searchResults.length < 1 ? (
                                <div className={styles.nullText}>No patient found</div>
                            ) : searchValue.length === 0 && searchResults.length < 1 ? (
                                <div className={styles.nullText}>Search for a patient by name</div>
                            ) : (
                                <div className={styles.resultsWrap}>
                                    {facilities.map((f: any, index) => {
                                        return (
                                            <div key={index}>
                                                <div className={styles.facilityTitle}>
                                                    {
                                                        (
                                                            (facilityStore.facilities || []).find(
                                                                (i) => i.facId === parseInt(f),
                                                            ) || { facNm: '' }
                                                        ).facNm
                                                    }
                                                </div>
                                                {groupedResults[f].map((r: any) => (
                                                    <PatientItem key={r.patientId} item={r} onSelect={handleSelect} />
                                                ))}
                                            </div>
                                        );
                                    })}
                                </div>
                            )}
                        </Flex>
                    </Flex>
                </Card>
            </div>
        </Portal>
    );
}

export default SearchPanel;
