import React from 'react';
import { useLocation } from 'react-router-dom';
import NavBar from 'src/containers/NavBar';
import SubNav from 'src/containers/SubNav';
import PageContainer from 'src/components/PageContainer';
import ReportsChp from 'src/containers/Reports/Chp';
import Macra2023 from 'src/containers/Reports/Macra/2023';
import Macra2024 from 'src/containers/Reports/Macra/2024';

function ReportsContainer() {
    const location = useLocation();

    function getCurrentReport() {
        if (location.pathname.includes('chp')) {
            return <ReportsChp />;
        } else if (location.pathname.includes('macra/2023')) {
            return <Macra2023 />;
        } else if (location.pathname.includes('macra')) {
            return <Macra2024 />;
        }
    }

    return (
        <PageContainer>
            <NavBar />
            <SubNav type="reports" />
            {getCurrentReport()}
        </PageContainer>
    );
}

export default ReportsContainer;
