import React from 'react';

const SvgVisit = (props) => (
    <svg width={33} height={33} {...props} viewBox="0 0 33 33">
        <g transform="translate(1 1)" fill="none" fillRule="evenodd">
            <circle stroke="#F1F0F0" fill="#F9F9F9" cx={15.5} cy={15.5} r={15.5} />
            <g fill="#DA1057" fillRule="nonzero">
                <path d="M18.075 14.424l-1.03.009c-.095 0-.171.08-.171.177v3.014a.35.35 0 01-.344.354h-1.71a.358.358 0 01-.351-.362v-3.003a.175.175 0 00-.172-.177H13.27a.176.176 0 01-.163-.109.179.179 0 01.038-.192l2.39-2.409a.175.175 0 01.247 0l2.416 2.396a.18.18 0 01.039.193.175.175 0 01-.162.109z" />
                <path d="M18.938 7h-6.875C10.922 7 10 7.951 10 9.125v12.75c0 1.174.923 2.125 2.063 2.125h6.874c1.14 0 2.063-.951 2.063-2.125V9.125C21 7.951 20.077 7 18.937 7zm.687 13.458a.35.35 0 01-.344.355H11.72a.35.35 0 01-.344-.355V9.48a.35.35 0 01.344-.354h7.562a.35.35 0 01.344.354v10.98z" />
            </g>
        </g>
    </svg>
);

export default SvgVisit;
