import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router';
import styles from './styles.module.scss';
import Flex from 'src/components/Flex';
import * as surveyService from 'src/api/survey';
import LoadingCover from 'src/components/LoadingCover';
import Button from 'src/components/Button';
import { ToastStoreObject } from 'src/stores/ToastStore';
import fieldMedLogo from 'src/assets/images/fieldmed-logo.svg';
import graphiumLogo from 'src/assets/images/graphium-logo.svg';
import * as utils from 'src/utils';
import { SurveyRequestUnauth } from 'common/lib/model/surveys/Survey';
import { parse } from 'query-string';
import * as variables from 'src/styles/variables';
import { FeatureBranding } from 'src/components/FeatureFlag';

function PatientSurveyUnsubscribe() {
    const location = useLocation();

    const queryStrings = parse(location.search);
    const patientSurveyRequestGuid = queryStrings.guid as string;

    const [isLoading, setIsLoading] = useState(false);
    const [surveyRequest, setSurveyRequest] = useState<SurveyRequestUnauth | null>(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [view, setView] = useState<'ask' | 'unsubscribed' | 'not_unsubscribed'>('ask');

    useEffect(() => {
        getSurvey();
    }, []);

    async function getSurvey() {
        try {
            setIsLoading(true);
            const results = await surveyService.getSurveyRequestAndFacility(patientSurveyRequestGuid);
            setSurveyRequest(results.data || {});
            setIsLoading(false);
        } catch (e) {
            const errorStr = utils.parseError(e);
            ToastStoreObject.show(errorStr);
            setErrorMessage(errorStr);
            setIsLoading(false);
        }
    }

    async function handleUnsubscribe() {
        try {
            setIsLoading(true);
            await surveyService.unsubscribeSurveyRequest(patientSurveyRequestGuid);
            setView('unsubscribed');
            setIsLoading(false);
        } catch (e) {
            const errorStr = utils.parseError(e);
            ToastStoreObject.show(errorStr);
            setErrorMessage(errorStr);
            setIsLoading(false);
        }
    }

    return (
        <Flex direction="column" align="center" justify="center" className={styles.pageWrap}>
            <Flex className={styles.contentWrap} direction="column" align="center" justify="center">
                <Flex
                    direction="row"
                    align="center"
                    value={1}
                    self="stretch"
                    justify="center"
                    className={styles.logoHeader}
                >
                    <FeatureBranding
                        fieldmedContent={<img src={fieldMedLogo} className={styles.fieldMedLogo} />}
                        graphiumContent={<img src={graphiumLogo} className={styles.fieldMedLogo} />}
                    />
                </Flex>
                {isLoading ? (
                    <LoadingCover />
                ) : !surveyRequest || !surveyRequest.surveyRequestContactInfo.email ? (
                    <div className={styles.message}>
                        {errorMessage || 'Oops, could not find the email address to unsubscribe.'}
                    </div>
                ) : view === 'ask' ? (
                    <>
                        <div className={styles.message}>
                            Are you sure you want to unsubscribe "{surveyRequest.surveyRequestContactInfo.email}" from
                            this survey email?
                            <Flex direction="row" align="center" justify="center">
                                <Button
                                    onClick={() => setView('not_unsubscribed')}
                                    text="No"
                                    type="secondary"
                                    className={styles.noButton}
                                />
                                <Button
                                    onClick={handleUnsubscribe}
                                    text="Yes"
                                    type="primary"
                                    className={styles.yesButton}
                                />
                            </Flex>
                        </div>
                    </>
                ) : view === 'unsubscribed' ? (
                    <div className={styles.message}>You have been unsubscribed from this survey.</div>
                ) : (
                    <div className={styles.message}>You will not be unsubscribed from this survey</div>
                )}
            </Flex>
        </Flex>
    );
}
export default PatientSurveyUnsubscribe;
