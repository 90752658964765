import { UseQueryResult, useQuery } from 'react-query';
import { getAllActiveOrgs } from 'src/api/organization';
import { UserStoreObject } from 'src/stores/UserStore';
import { Org } from 'common/lib/entity/index/Org';

export const allActiveOrgsKey = 'allActiveOrgs';

export const useAllActiveOrgs = (): UseQueryResult<Org[]> => {
    return useQuery({
        queryKey: [allActiveOrgsKey],
        queryFn: async () => {
            return await getAllActiveOrgs();
        },
        enabled: UserStoreObject.isGraphiumAdmin(),
        initialData: [],
    });
};
