import React, { useContext, useEffect, useState } from 'react';
import { ToastStoreObject } from 'src/stores/ToastStore';
import { ANALYTICS_NAMES } from 'src/utils/analytics';
import * as macraAPI from 'src/api/macra';
import Input, { Label } from '../Input';
import LoadingIcon from '../LoadingIcon';
import ModalFooter from '../ModalFooter';
import ModalHeader from '../ModalHeader';
import SideModal from '../SideModal';
import styles from './styles.module.scss';
import MacraStore from 'src/stores/MacraStore';
import FacilityStore, { Facility } from 'src/stores/FacilityStore';
import QcdrSettingsStore from 'src/stores/QcdrSettingsStore';

const PRACTICE_ID_REGEX = /^[0-9]{5}$/;
const TAX_ID_REGEX = /^[0-9]{9}$/;

interface MacraAQIExportModalProps {
    onClose?: Function;
    onSave?: Function;
}

function MacraAQIExportModal(props: MacraAQIExportModalProps) {
    const [isLoading, setIsLoading] = useState(true);
    const [practiceId, setPracticeId] = useState('');
    const [taxId, setTaxId] = useState('');
    const [facilityName, setFacilityName] = useState<string>(null);
    const macraStore = useContext(MacraStore);
    const facilityStore = useContext(FacilityStore);
    const qcdrSettingsStore = useContext(QcdrSettingsStore);
    const { startDate, endDate, selectedId, currentYear } = macraStore;

    useEffect(() => {
        fetchOptions();
    }, []);

    useEffect(() => {
        const selectedFacility = facilityStore.facilities.find((facility: Facility) => facility.facId === selectedId);

        if (selectedFacility) {
            setFacilityName(selectedFacility.facNm);
        }
    }, [selectedId]);

    async function fetchOptions() {
        setIsLoading(true);

        try {
            const options = await macraAPI.getAqiOptions();

            if (options.practiceId) {
                setPracticeId(options.practiceId.optVal);
            }

            if (options.taxId) {
                setTaxId(options.taxId.optVal);
            }
        } finally {
            setIsLoading(false);
        }
    }

    async function handleExport() {
        if (practiceId === '' || !practiceId.match(PRACTICE_ID_REGEX) || taxId === '' || !taxId.match(TAX_ID_REGEX)) {
            ToastStoreObject.show('Please enter a valid Practice ID and Tax ID before exporting.');
            return;
        }

        const { xmlUrl } = await qcdrSettingsStore.getAqiExport({
            practiceId,
            taxId,
            startDate,
            endDate,
            facilityId: selectedId,
            year: currentYear,
        });
        const xmlData = await qcdrSettingsStore.downloadAqiExport(xmlUrl.replace('localstack', 'localhost'));
        const fileName = xmlUrl.split('/').pop().split('#')[0].split('?')[0];
        downloadFromBytes(xmlData, fileName, 'text/xml');
    }

    function downloadFromBytes(bytes: Uint8Array, fileName: string, type: string) {
        const blob = new Blob([bytes], { type });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
    }

    return (
        <SideModal isOpen={true} onClose={props.onClose}>
            <ModalHeader title="AQI Export" onClose={props.onClose} />
            {isLoading ? (
                <div className={styles.loadingContentWrap}>
                    <LoadingIcon />
                </div>
            ) : (
                <div className={styles.contentWrap}>
                    <div className={styles.metadata}>
                        <div className={styles.item}>
                            <div className={styles.title}>Facility</div>
                            <div className={styles.text}>{facilityName}</div>
                        </div>
                    </div>
                    <div className={styles.metadata}>
                        <div className={styles.item}>
                            <div className={styles.title}>Start</div>
                            <div className={styles.text}>{startDate}</div>
                        </div>
                        <div className={styles.item}>
                            <div className={styles.title}>End</div>
                            <div className={styles.text}>{endDate}</div>
                        </div>
                    </div>
                    <Input
                        label="Practice ID"
                        value={practiceId}
                        onChangeText={(v: string) => setPracticeId(v.trim())}
                        infoState={practiceId.match(PRACTICE_ID_REGEX) ? null : 'error'}
                        errorMessage={!practiceId.match(PRACTICE_ID_REGEX) && 'Please enter a 5 digit number'}
                        data-test-id={ANALYTICS_NAMES.MacraAQIExportModal_PracticeID}
                    />
                    <Input
                        label="Tax ID"
                        value={taxId}
                        onChangeText={(v: string) => setTaxId(v.trim())}
                        infoState={taxId.match(TAX_ID_REGEX) ? null : 'error'}
                        errorMessage={!taxId.match(TAX_ID_REGEX) && 'Please enter a 9 digit number'}
                        data-test-id={ANALYTICS_NAMES.MacraAQIExportModal_TaxID}
                    />
                </div>
            )}
            <ModalFooter
                primaryText="Export"
                primaryClick={handleExport}
                primaryProps={{ disabled: !practiceId || practiceId === '' || !taxId || taxId === '' }}
                secondaryClick={() => props.onClose()}
                isLoading={isLoading}
            />
        </SideModal>
    );
}

export default MacraAQIExportModal;
