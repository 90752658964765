import React, { useContext, useState, useEffect } from 'react';
import Flex from 'src/components/Flex';
import styles from './styles.module.scss';
import {
    IAgencyInformation,
    IAgencyServiceArea,
    NaValues,
    IAgencyConfiguration,
    IAgencyCustomResult,
    AgencyStateCode,
} from 'common/lib/model/nemsis3_4/demographic';
import NemsisInput from 'src/components/NemsisInput';
import { OptionTypes } from 'src/components/OptionDropDown';
import NemsisOptionDropDown from 'src/components/NemsisOptionDropDown';
import NemsisCreatable from 'src/components/NemsisCreatable';
import Button from 'src/components/Button';
import OrgStore from 'src/stores/OrgStore';
import * as organizationAPI from 'src/api/organization';
import { getDropdownValue } from 'src/utils/nemsis';
import { ModalStoreObject, ModalTypes } from 'src/stores/ModalStore';
import { ToastStoreObject } from 'src/stores/ToastStore';
import * as utils from 'src/utils';
import ReferenceListStore from 'src/stores/ReferenceListStore';
import { observer } from 'mobx-react';
import NemsisErrorBanner, { NemsisErrorBannerMode } from '../NemsisErrorBanner';
import useFeatureValue from 'src/utils/hooks/useFeatureValue';
import { NemsisMode } from 'common/lib/model/NemsisMode';
import NemsisStore from 'src/stores/NemsisStore';

const defaultAgencyServiceArea: IAgencyServiceArea = {
    areaStateCode: '',
    areaCountyCodes: [],
    censusTractCodes: [],
    zipCodes: [],
};

const defaultAnnualStatistics: IAgencyInformation['annualStatistics'][0] = {
    annualBillableCalls: null,
    annualCallCenterVolume: null,
    annualDispatchVolume: null,
    annualPatientContactVolume: null,
    annualPatientTransportVolume: null,
    areaPopulation: null,
    areaSize: null,
    calendarYear: null,
};

const defaultInfo: IAgencyInformation = {
    uniqueStateId: '',
    agencyNumber: '',
    agencyName: '',
    agencyStateCode: '',
    agencyServiceAreas: [{ ...defaultAgencyServiceArea }],
    primaryServiceTypeCode: '',
    otherServiceTypeCodes: [],
    levelOfServiceCode: '',
    organizationStatusCode: '',
    organizationTypeCode: '',
    taxStatusCode: '',
    annualStatistics: [{ ...defaultAnnualStatistics }],
    agencyTimeZoneCode: '',
    agencyDaylightSavingsTimeUseCode: '',
    nationalProviderIds: [],
    fireDepartmentIds: [],
    notValues: {
        agencyServiceAreas: [{}],
        annualStatistics: [{}],
    },
};

function AgencyInformation(props: { onRefresh: Function }) {
    const orgStore = useContext(OrgStore);
    const nemsisStore = useContext(NemsisStore);
    const referenceListStore = useContext(ReferenceListStore);
    const nemsisMode = useFeatureValue('nemsis_mode');
    const [agencyState, setAgencyState] = useState(null);

    const [data, setData] = useState<IAgencyInformation>(() =>
        orgStore.currentOrgDbOrg && orgStore.currentOrgDbOrg.agencyInfo
            ? { ...utils.clone(defaultInfo), ...orgStore.currentOrgDbOrg.agencyInfo }
            : { ...utils.clone(defaultInfo) },
    );

    const [configData, setConfigData] = useState<IAgencyConfiguration[]>(() =>
        orgStore.currentOrgDbOrg && orgStore.currentOrgDbOrg.agencyConfigurations
            ? [...orgStore.currentOrgDbOrg.agencyConfigurations]
            : [],
    );

    const [customData, setCustomData] = useState<IAgencyCustomResult[]>(() =>
        orgStore.currentOrgDbOrg && orgStore.currentOrgDbOrg.agencyCustomResults
            ? [...orgStore.currentOrgDbOrg.agencyCustomResults]
            : [],
    );

    useEffect(() => {
        setAgencyState(data.agencyStateCode);
    }, [data.agencyStateCode]);

    function handleNaChange(property: keyof IAgencyInformation, subProperty?: string, index?: number) {
        const newData = { ...data };
        if (subProperty) {
            // Make sure everything is defaulted all the way down
            if (!data.notValues) {
                newData.notValues = { [property]: [] };
            }
            if (!data.notValues[property]) {
                newData.notValues = { ...data.notValues, [property]: [] };
            }
            if (!data.notValues[property][index]) {
                newData.notValues[property][index] = {};
            }

            newData.notValues[property][index][subProperty] = data.notValues[property][index][subProperty]
                ? undefined
                : NaValues.NOT_APPLICABLE;
        } else {
            newData.notValues[property] =
                data.notValues && data.notValues[property] ? undefined : NaValues.NOT_APPLICABLE;
        }

        setData(newData);
    }

    function handleCustomNaChange(elementId: string, index: number) {
        const exists = customData.findIndex((item) => item.correlationId === index.toString()) > -1;

        if (exists) {
            setCustomData(
                customData.map((item) => {
                    return item.customElementIdReferenced === elementId && item.correlationId === index.toString()
                        ? ({
                              ...item,
                              notValue: item.notValue === NaValues.NOT_APPLICABLE ? null : NaValues.NOT_APPLICABLE,
                          } as IAgencyCustomResult)
                        : item;
                }),
            );
        } else {
            setCustomData([
                ...customData,
                {
                    customDataElementResult: null,
                    customElementIdReferenced: elementId,
                    correlationId: index.toString(),
                    notValue: NaValues.NOT_APPLICABLE,
                },
            ]);
        }
    }

    const updateInput = (param: keyof IAgencyInformation) => (t: string) => setData({ ...data, [param]: t });

    function updateNestedInput({
        param,
        subParam,
        index,
        value,
    }: {
        param: keyof IAgencyInformation;
        subParam: string;
        index: number;
        value: any;
    }) {
        const newData: any = { ...data };
        if (!newData[param]) {
            newData[param] = [];
        }
        if (!newData[param][index]) {
            newData[param][index] = {};
        }
        newData[param][index][subParam] = value;
        setData(newData);
    }

    function updateCustomInput<T>({
        param,
        nested,
        index,
        value,
    }: {
        param: keyof T;
        nested?: boolean;
        index?: number;
        value: IAgencyCustomResult;
    }) {
        const newCustomData: any = { ...customData };

        if (nested) {
            if (!newCustomData[param]) {
                newCustomData[param] = [];
            }

            const exists =
                newCustomData[param].findIndex((item: IAgencyCustomResult) => item.correlationId === index.toString()) >
                -1;

            newCustomData[param] = exists
                ? newCustomData[param].map((item: IAgencyCustomResult) => {
                      return item.correlationId === index.toString() ? value : item;
                  })
                : [...newCustomData[param], value];
        } else {
            newCustomData[param] = value;
        }

        setCustomData(newCustomData);
    }

    function getNotValBool(parentProp: keyof IAgencyInformation, childProp?: string, index?: number) {
        if (childProp) {
            return !!(
                data &&
                data.notValues &&
                data.notValues[parentProp] &&
                data.notValues[parentProp][index] &&
                data.notValues[parentProp][index][childProp]
            );
        }
        return !!(data && data.notValues && data.notValues[parentProp]);
    }

    function handleRemoveRow(parentProp: keyof IAgencyInformation, index: number) {
        const currentData = { ...data };
        // if service area, remove corresponding configuration
        if (parentProp === 'agencyServiceAreas') {
            const row = currentData[parentProp][index];
            const filteredConfigData = configData.filter(
                (config) => config.certificationLicensureState !== row.areaStateCode,
            );
            setConfigData(filteredConfigData);
        }

        if (parentProp === 'annualStatistics') {
            if (nemsisMode === NemsisMode.PRE_TEST && agencyState === AgencyStateCode.AL) {
                // remove custom values
                setCustomData(
                    customData
                        .filter(
                            (item) =>
                                item.customElementIdReferenced === 'ALdAgency.001' &&
                                item.correlationId !== index.toString(),
                        )
                        .map((item) => {
                            return parseInt(item.correlationId) < index
                                ? item
                                : {
                                      ...item,
                                      correlationId: (parseInt(item.correlationId) - 1).toString(),
                                  };
                        }),
                );
            }
        }

        // @ts-ignore
        (currentData[parentProp] || []).splice(index, 1);
        // remove any not values
        (currentData.notValues[parentProp] || []).splice(index, 1);

        setData(currentData);
    }

    function handleAddRow(property: keyof IAgencyInformation) {
        const currentData = { ...data };
        if (property === 'agencyServiceAreas') {
            currentData[property].push({ ...defaultAgencyServiceArea });
            // add an empty object to track indexes of not values correctly
            currentData.notValues[property] = [...currentData.notValues[property], {}];
        }
        if (property === 'annualStatistics') {
            currentData[property].push({ ...defaultAnnualStatistics });
            // add an empty object to track indexes of not values correctly
            currentData.notValues[property] = [...currentData.notValues[property], {}];
        }
        setData(currentData);
    }

    function renderTopLevelInput({
        key,
        label,
        isNa,
    }: {
        key: keyof IAgencyInformation;
        label: string;
        isNa?: boolean;
    }) {
        return (
            <NemsisInput
                nemsisLabel={label}
                value={getNotValBool(key) ? NaValues.NOT_APPLICABLE : (data[key] as string)}
                onChangeText={updateInput(key)}
                {...(isNa
                    ? {
                          onNaClick: () => handleNaChange(key),
                          naSelected: getNotValBool(key),
                      }
                    : {})}
            />
        );
    }

    function renderDropDown({
        key,
        label,
        type,
        isMulti,
        isNa,
        nested,
        virtualize = false,
    }: {
        key: keyof IAgencyInformation;
        label: string;
        type: OptionTypes;
        isMulti?: boolean;
        isNa?: boolean;
        nested?: { index: number; row: any; key: string };
        virtualize?: boolean;
    }) {
        const value = nested ? nested.row[nested.key] : data[key];
        return (
            <NemsisOptionDropDown
                nemsisLabel={label}
                isMulti={isMulti}
                selectedValue={
                    getNotValBool(key, nested && nested.key, nested && nested.index) ? NaValues.NOT_APPLICABLE : value
                }
                onChange={(d: any) => {
                    const val = getDropdownValue(d, isMulti ? [] : undefined);
                    if (nested) {
                        return updateNestedInput({ param: key, subParam: nested.key, index: nested.index, value: val });
                    }
                    return setData({ ...data, [key]: val });
                }}
                {...(isNa
                    ? {
                          onNaClick: () => handleNaChange(key, nested && nested.key, nested && nested.index),
                          naSelected: getNotValBool(key, nested && nested.key, nested && nested.index),
                      }
                    : {})}
                type={type}
                virtualize={virtualize}
            />
        );
    }

    function renderCreatable({
        key,
        label,
        isNa,
        nested,
        validation,
    }: {
        key: keyof IAgencyInformation;
        label: string;
        isNa?: boolean;
        nested?: { index: number; row: any; key: string };
        validation?: 'NPI';
    }) {
        const values = nested ? nested.row[nested.key] : data[key];
        return (
            <NemsisCreatable
                nemsisLabel={label}
                selectedValues={getNotValBool(key, nested && nested.key, nested && nested.index) ? undefined : values}
                onChange={(d: any) => {
                    if (nested) {
                        return updateNestedInput({ param: key, subParam: nested.key, index: nested.index, value: d });
                    }

                    return setData({ ...data, [key]: d });
                }}
                placeholder="(Enter Multiple)"
                {...(isNa
                    ? {
                          naSelected: getNotValBool(key, nested && nested.key, nested && nested.index),
                          onNaClick: () => handleNaChange(key, nested && nested.key, nested && nested.index),
                      }
                    : {})}
                {...(validation
                    ? {
                          validation: validation,
                      }
                    : {})}
            />
        );
    }

    async function handleSave() {
        try {
            await saveData(data, configData, customData);
            props.onRefresh();
        } catch (error) {
            ToastStoreObject.show(utils.parseError(error));
        }
    }

    async function saveData(
        agencyInfo: IAgencyInformation,
        agencyConfigurations: IAgencyConfiguration[],
        agencyCustomResults: IAgencyCustomResult[],
    ) {
        try {
            await organizationAPI.updateAgencyData({ agencyInfo, agencyConfigurations, agencyCustomResults });
            await nemsisStore.validateDemData(true);
        } catch (error) {
            ToastStoreObject.show(utils.parseError(error));
        }
    }

    function handleConfigure(serviceArea: IAgencyServiceArea, configuration: IAgencyConfiguration) {
        ModalStoreObject.showModal(ModalTypes.AgencyConfigurationModal, {
            onClose: () => ModalStoreObject.hideModal(),
            onSave: handleConfigSave,
            serviceArea,
            configuration,
        });
    }

    async function handleConfigSave(newConfig: IAgencyConfiguration) {
        const existingIndex = configData.findIndex(
            (config) => config.certificationLicensureState === newConfig.certificationLicensureState,
        );

        const newConfigData =
            existingIndex > -1
                ? configData.map((config, index) => {
                      return index === existingIndex ? newConfig : config;
                  })
                : [...configData, newConfig];

        setConfigData(newConfigData);

        await saveData(data, newConfigData, customData);
        props.onRefresh();
    }

    const handleActionClick = (
        action: { id: string; label: string },
        serviceArea: IAgencyServiceArea,
        index: number,
    ) => {
        switch (action.id) {
            case 'configure':
                const state = data.agencyServiceAreas[index].areaStateCode;
                const config = configData
                    ? configData.find((config) => config.certificationLicensureState === state)
                    : null;
                handleConfigure(serviceArea, config);
                break;

            case 'remove':
                handleRemoveRow('agencyServiceAreas', index);
                break;
        }
    };

    const handleConfigureClick = (serviceArea: IAgencyServiceArea, index: number) => {
        const state = data.agencyServiceAreas[index].areaStateCode;
        const config = configData ? configData.find((config) => config.certificationLicensureState === state) : null;
        handleConfigure(serviceArea, config);
    };

    const handleRemoveServiceAreaClick = (index: number) => {
        handleRemoveRow('agencyServiceAreas', index);
    };

    function getCountyDropdownOptions(value: string) {
        const hasDropdownValues = referenceListStore.filteredReferenceListDropdownValues.has(
            `${OptionTypes.ANSICountyCodes}-${value}`,
        );

        if (!hasDropdownValues) {
            referenceListStore.getFilteredData(OptionTypes.ANSICountyCodes, {
                filterName: 'stateAnsiCode',
                filterValue: value,
            });
        }

        return (
            referenceListStore.filteredReferenceListDropdownValues.get(`${OptionTypes.ANSICountyCodes}-${value}`) || []
        );
    }

    function getCustomValue(result: IAgencyCustomResult) {
        if (!result) return '';
        return result.notValue === NaValues.NOT_APPLICABLE ? result.notValue : result.customDataElementResult;
    }

    return (
        <div className={styles.tabContent}>
            <NemsisErrorBanner mode={NemsisErrorBannerMode.DEM} />

            <Flex direction="row">
                <Flex value={1} className={styles.pageTitle}>
                    AGENCY INFORMATION
                </Flex>
                <Flex value={1} justify="end">
                    <Button type="primary" text="Save" onClick={handleSave} />
                </Flex>
            </Flex>

            <Flex direction="column" className={styles.form}>
                <Flex direction="row" justify="between" className={styles.formRow}>
                    <Flex value={1}>
                        {renderTopLevelInput({
                            key: 'uniqueStateId',
                            label: 'Unique State ID',
                        })}
                    </Flex>
                    <Flex value={0.1} />
                    <Flex value={1}>{renderTopLevelInput({ key: 'agencyNumber', label: 'Agency Number' })}</Flex>
                </Flex>
                <Flex direction="row" justify="between" className={styles.formRow}>
                    <Flex value={1}>
                        {renderTopLevelInput({ key: 'agencyName', label: 'Agency Name', isNa: true })}
                    </Flex>
                    <Flex value={0.1} />
                    <Flex value={1}>
                        {renderDropDown({ key: 'agencyStateCode', label: 'State', type: OptionTypes.ANSIStateCodes })}
                    </Flex>
                </Flex>
                <Flex direction="row" justify="between" className={styles.formRow}>
                    <Flex value={1}>
                        {renderDropDown({
                            key: 'primaryServiceTypeCode',
                            label: 'Primary Type of Service',
                            type: OptionTypes.EMSTypeOfService,
                        })}
                    </Flex>
                    <Flex value={0.1} />
                    <Flex value={1}>
                        {renderDropDown({
                            key: 'otherServiceTypeCodes',
                            label: 'Other Types of Service',
                            isNa: true,
                            isMulti: true,
                            type: OptionTypes.EMSTypeOfService,
                        })}
                    </Flex>
                </Flex>
                <Flex direction="row" justify="between" className={styles.formRow}>
                    <Flex value={1}>
                        {renderDropDown({
                            key: 'levelOfServiceCode',
                            label: 'Level of Service',
                            type: OptionTypes.EMSStateCertificationLicensureLevels,
                        })}
                    </Flex>
                    <Flex value={0.1} />
                    <Flex value={1}>
                        {renderDropDown({
                            key: 'taxStatusCode',
                            label: 'Tax Status',
                            type: OptionTypes.EMSAgencyOrganizationalTaxStatus,
                        })}
                    </Flex>
                </Flex>
                <Flex direction="row" justify="between" className={styles.formRow}>
                    <Flex value={1}>
                        {renderDropDown({
                            key: 'organizationStatusCode',
                            label: 'Organizational Status',
                            type: OptionTypes.EMSOrganizationStatus,
                        })}
                    </Flex>
                    <Flex value={0.1} />
                    <Flex value={1}>
                        {renderDropDown({
                            key: 'organizationTypeCode',
                            label: 'Organization Type',
                            type: OptionTypes.EMSOrganizationalType,
                        })}
                    </Flex>
                </Flex>
                <Flex direction="row" justify="between" className={styles.formRow}>
                    <Flex value={1}>
                        {renderDropDown({
                            key: 'agencyTimeZoneCode',
                            label: 'Time Zone',
                            type: OptionTypes.EMSAgencyTimeZone,
                        })}
                    </Flex>
                    <Flex value={0.1} />
                    <Flex value={1}>
                        {renderDropDown({
                            key: 'agencyDaylightSavingsTimeUseCode',
                            label: 'Daylight Savings Time Use',
                            type: OptionTypes.EMSYesNoValues,
                        })}
                    </Flex>
                </Flex>
                <Flex direction="row" justify="between" className={styles.formRow}>
                    <Flex value={1}>
                        {renderCreatable({
                            key: 'nationalProviderIds',
                            label: 'National Provider Identifiers',
                            isNa: true,
                            validation: 'NPI',
                        })}
                    </Flex>
                    <Flex value={0.1} />
                    <Flex value={1}>
                        {renderCreatable({
                            key: 'fireDepartmentIds',
                            label: 'Fire Department ID Numbers',
                            isNa: true,
                        })}
                    </Flex>
                </Flex>

                <Flex direction="column" className={styles.newRow}>
                    <span className={styles.heading}>Service Areas</span>
                    {(data.agencyServiceAreas || []).map((s, index) => (
                        <Flex
                            key={`${s.areaStateCode}${index}`}
                            direction="column"
                            justify="between"
                            className={styles.formGroup}
                        >
                            <Flex value={1} direction="row">
                                <Flex value={1}>
                                    <NemsisOptionDropDown
                                        nemsisLabel="State"
                                        selectedValue={s.areaStateCode}
                                        onChange={(d: any) => {
                                            const val = getDropdownValue(d, undefined);
                                            updateNestedInput({
                                                param: 'agencyServiceAreas',
                                                subParam: 'areaStateCode',
                                                index,
                                                value: val,
                                            });
                                            updateNestedInput({
                                                param: 'agencyServiceAreas',
                                                subParam: 'areaCountyCodes',
                                                index,
                                                value: [],
                                            });
                                            referenceListStore.getFilteredData(OptionTypes.ANSICountyCodes, {
                                                filterName: 'stateAnsiCode',
                                                filterValue: val,
                                            });
                                        }}
                                        type={OptionTypes.ANSIStateCodes}
                                    />
                                </Flex>
                                <Flex value={0.1} />
                                <Flex value={1}>
                                    <NemsisOptionDropDown
                                        nemsisLabel="Counties"
                                        selectedValue={s.areaCountyCodes}
                                        onChange={(d: any) => {
                                            const val = getDropdownValue(d, []);
                                            return updateNestedInput({
                                                param: 'agencyServiceAreas',
                                                subParam: 'areaCountyCodes',
                                                index,
                                                value: val,
                                            });
                                        }}
                                        type={OptionTypes.ANSICountyCodes}
                                        isMulti={true}
                                        isDisabled={s.areaStateCode === ''}
                                        options={getCountyDropdownOptions(s.areaStateCode)}
                                    />
                                </Flex>
                                <Flex value={0.1} />
                                <Flex value={0}>
                                    <div className={styles.serviceAreaButtonWrap}>
                                        <Flex direction="row">
                                            <Button
                                                onClick={() => handleConfigureClick(s, index)}
                                                type="secondary"
                                                text="Configure"
                                                className={styles.actionButtons}
                                            />
                                            <Button
                                                onClick={() => {
                                                    handleRemoveServiceAreaClick(index);
                                                }}
                                                type="primary"
                                                text="Remove"
                                                className={styles.actionButtons}
                                            />
                                        </Flex>
                                    </div>
                                </Flex>
                            </Flex>
                            <Flex value={1} direction="column">
                                <Flex value={1}>
                                    {renderCreatable({
                                        key: 'agencyServiceAreas',
                                        label: 'Census Tracts',
                                        nested: { row: s, key: 'censusTractCodes', index },
                                        isNa: true,
                                    })}
                                </Flex>
                                <Flex value={0.1} />
                                <Flex value={1}>
                                    {renderCreatable({
                                        key: 'agencyServiceAreas',
                                        label: 'Zip Codes',
                                        nested: { row: s, key: 'zipCodes', index },
                                        isNa: true,
                                    })}
                                </Flex>
                            </Flex>
                        </Flex>
                    ))}
                    <Button
                        type="transparent"
                        text="+ Add Another Service Area"
                        onClick={() => handleAddRow('agencyServiceAreas')}
                        className={styles.addUnderline}
                    />
                </Flex>
                <Flex direction="column" className={styles.newRow}>
                    <span className={styles.heading}>Statistical Data</span>
                    {(data.annualStatistics || []).map((s, index) => (
                        <Flex key={`${s.calendarYear}${index}`} direction="column" className={styles.formGroup}>
                            <Flex direction="row" justify="between" align="center" className={styles.formRow}>
                                <Flex value={1}>
                                    {renderDropDown({
                                        key: 'annualStatistics',
                                        label: 'Year',
                                        type: OptionTypes.PreviousYears,
                                        nested: { row: s, key: 'calendarYear', index },
                                    })}
                                </Flex>
                                <Flex value={0.1} />
                                <Flex value={1}>
                                    <NemsisInput
                                        nemsisLabel="Primary Service Area Size"
                                        onNaClick={() => handleNaChange('annualStatistics', 'areaSize', index)}
                                        type="number"
                                        naSelected={getNotValBool('annualStatistics', 'areaSize', index)}
                                        value={
                                            getNotValBool('annualStatistics', 'areaSize', index)
                                                ? NaValues.NOT_APPLICABLE
                                                : s.areaSize
                                        }
                                        onChangeText={(text: string) =>
                                            updateNestedInput({
                                                param: 'annualStatistics',
                                                subParam: 'areaSize',
                                                index,
                                                value: text,
                                            })
                                        }
                                    />
                                </Flex>
                                <Flex value={0.1} />
                                <Flex value={1}>
                                    <NemsisInput
                                        nemsisLabel="Service Area Population"
                                        onNaClick={() => handleNaChange('annualStatistics', 'areaPopulation', index)}
                                        type="number"
                                        naSelected={getNotValBool('annualStatistics', 'areaPopulation', index)}
                                        value={
                                            getNotValBool('annualStatistics', 'areaPopulation', index)
                                                ? NaValues.NOT_APPLICABLE
                                                : s.areaPopulation
                                        }
                                        onChangeText={(text: string) =>
                                            updateNestedInput({
                                                param: 'annualStatistics',
                                                subParam: 'areaPopulation',
                                                index,
                                                value: text,
                                            })
                                        }
                                    />
                                </Flex>
                                {nemsisMode === NemsisMode.PRE_TEST && agencyState === AgencyStateCode.AL && (
                                    <>
                                        <Flex value={0.1} />
                                        <Flex value={1}>
                                            <NemsisInput
                                                nemsisLabel="Canceled Calls per Year"
                                                type="number"
                                                onNaClick={() => {
                                                    handleCustomNaChange('ALdAgency.001', index);
                                                }}
                                                naSelected={
                                                    ((customData.find(
                                                        (item) =>
                                                            item.customElementIdReferenced === 'ALdAgency.001' &&
                                                            item.correlationId === index.toString(),
                                                    ) || {}) as IAgencyCustomResult).notValue ===
                                                    NaValues.NOT_APPLICABLE
                                                }
                                                value={getCustomValue(
                                                    customData.find(
                                                        (item) =>
                                                            item.customElementIdReferenced === 'ALdAgency.001' &&
                                                            item.correlationId === index.toString(),
                                                    ),
                                                )}
                                                onChangeText={(text: string) => {
                                                    setCustomData([
                                                        ...customData.filter(
                                                            (item) =>
                                                                item.customElementIdReferenced !== 'ALdAgency.001' &&
                                                                item.correlationId !== index.toString(),
                                                        ),
                                                        {
                                                            customDataElementResult: parseInt(text),
                                                            customElementIdReferenced: 'ALdAgency.001',
                                                            correlationId: index.toString(),
                                                            notValue: null,
                                                        },
                                                    ]);
                                                }}
                                            />
                                        </Flex>
                                    </>
                                )}
                            </Flex>

                            <Flex direction="row" justify="between" align="center" className={styles.formRow}>
                                <Flex value={1}>
                                    <NemsisInput
                                        nemsisLabel="Call Center Volume"
                                        type="number"
                                        onNaClick={() =>
                                            handleNaChange('annualStatistics', 'annualCallCenterVolume', index)
                                        }
                                        naSelected={getNotValBool('annualStatistics', 'annualCallCenterVolume', index)}
                                        value={
                                            getNotValBool('annualStatistics', 'annualCallCenterVolume', index)
                                                ? NaValues.NOT_APPLICABLE
                                                : s.annualCallCenterVolume
                                        }
                                        onChangeText={(text: string) =>
                                            updateNestedInput({
                                                param: 'annualStatistics',
                                                subParam: 'annualCallCenterVolume',
                                                index,
                                                value: text,
                                            })
                                        }
                                    />
                                </Flex>
                                <Flex value={0.1} />
                                <Flex value={1}>
                                    <NemsisInput
                                        nemsisLabel="Dispatch Volume"
                                        type="number"
                                        onNaClick={() =>
                                            handleNaChange('annualStatistics', 'annualDispatchVolume', index)
                                        }
                                        naSelected={getNotValBool('annualStatistics', 'annualDispatchVolume', index)}
                                        value={
                                            getNotValBool('annualStatistics', 'annualDispatchVolume', index)
                                                ? NaValues.NOT_APPLICABLE
                                                : s.annualDispatchVolume
                                        }
                                        onChangeText={(text: string) =>
                                            updateNestedInput({
                                                param: 'annualStatistics',
                                                subParam: 'annualDispatchVolume',
                                                index,
                                                value: text,
                                            })
                                        }
                                    />
                                </Flex>
                                <Flex value={0.1} />
                                <Flex value={1}>
                                    <NemsisInput
                                        nemsisLabel="Patient Transport Volume"
                                        type="number"
                                        onNaClick={() =>
                                            handleNaChange('annualStatistics', 'annualPatientTransportVolume', index)
                                        }
                                        naSelected={getNotValBool(
                                            'annualStatistics',
                                            'annualPatientTransportVolume',
                                            index,
                                        )}
                                        value={
                                            getNotValBool('annualStatistics', 'annualPatientTransportVolume', index)
                                                ? NaValues.NOT_APPLICABLE
                                                : s.annualPatientTransportVolume
                                        }
                                        onChangeText={(text: string) =>
                                            updateNestedInput({
                                                param: 'annualStatistics',
                                                subParam: 'annualPatientTransportVolume',
                                                index,
                                                value: text,
                                            })
                                        }
                                    />
                                </Flex>
                                <Flex value={0.1} />
                                <Flex value={1}>
                                    <NemsisInput
                                        nemsisLabel="Patient Contact Volume"
                                        type="number"
                                        onNaClick={() =>
                                            handleNaChange('annualStatistics', 'annualPatientContactVolume', index)
                                        }
                                        naSelected={getNotValBool(
                                            'annualStatistics',
                                            'annualPatientContactVolume',
                                            index,
                                        )}
                                        value={
                                            getNotValBool('annualStatistics', 'annualPatientContactVolume', index)
                                                ? NaValues.NOT_APPLICABLE
                                                : s.annualPatientContactVolume
                                        }
                                        onChangeText={(text: string) =>
                                            updateNestedInput({
                                                param: 'annualStatistics',
                                                subParam: 'annualPatientContactVolume',
                                                index,
                                                value: text,
                                            })
                                        }
                                    />
                                </Flex>
                                <Flex value={0.1} />
                                <Flex value={1}>
                                    <NemsisInput
                                        nemsisLabel="Billable Calls"
                                        type="number"
                                        value={
                                            getNotValBool('annualStatistics', 'annualBillableCalls', index)
                                                ? NaValues.NOT_APPLICABLE
                                                : s.annualBillableCalls
                                        }
                                        onChangeText={(text: string) =>
                                            updateNestedInput({
                                                param: 'annualStatistics',
                                                subParam: 'annualBillableCalls',
                                                index,
                                                value: text,
                                            })
                                        }
                                    />
                                </Flex>
                                <Flex value={0.1} />
                                <Flex value={0.7} align="end" justify="end">
                                    <Button
                                        type="primary"
                                        text="Remove"
                                        onClick={() => handleRemoveRow('annualStatistics', index)}
                                    />
                                </Flex>
                            </Flex>
                        </Flex>
                    ))}
                    <Button
                        type="transparent"
                        text="+ Add Data for a New Statistical Year"
                        onClick={() => handleAddRow('annualStatistics')}
                        className={styles.addUnderline}
                    />
                </Flex>
            </Flex>
        </div>
    );
}

export default observer(AgencyInformation);
