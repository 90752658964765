import React, { ChangeEvent, ReactNode } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

interface CheckboxProps extends React.HtmlHTMLAttributes<HTMLInputElement> {
    className?: string;
    checkboxClassName?: string;
    checked: boolean;
    label?: ReactNode;
    disabled?: boolean;
    infoState?: 'error' | 'warning';
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const Checkbox = ({ className, checkboxClassName, checked, label, disabled = false, ...props }: CheckboxProps) => {
    const checkbox = (
        <div className={classNames(styles.container, className)}>
            <input
                type="checkbox"
                checked={checked}
                className={styles.hiddenInput}
                onChange={props.onChange}
                disabled={disabled ? disabled : null}
                {...props}
            />
            <div
                className={classNames(styles.styledCheckbox, checkboxClassName, {
                    [styles.checked]: checked,
                    [styles.disabled]: disabled,
                    [styles.error]: props.infoState === 'error',
                })}
            >
                <svg className={styles.icon} viewBox="0 0 24 24">
                    <polyline points="20 6 9 17 4 12" />
                </svg>
            </div>
        </div>
    );
    if (label) {
        return (
            <label className={styles.labelWrap}>
                {checkbox}
                {label}
            </label>
        );
    } else {
        return <label className={styles.labelWrap}>{checkbox}</label>;
    }
};

export default Checkbox;
