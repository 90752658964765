import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import { CellInfo, Column } from 'react-table';
import { SparklineCell } from 'src/components/CellRenderers/SparklineCell';
import Table from 'src/components/Table';
import styles from 'src/containers/Reports/Macra/styles.module.scss';
import ScorecardStore from 'src/stores/ScorecardStore';
import MacraStore from 'src/stores/MacraStore';

function QualityTab() {
    const scorecardStore = useContext(ScorecardStore);
    const macraStore = useContext(MacraStore);

    const qualityColumns: Column[] = [
        {
            Header: 'FACILITY',
            accessor: 'facilityName',
            minWidth: 150,
        },
        {
            Header: 'HANDOFF PROTOCOL USED',
            accessor: 'quality:handoffprotocol',
            width: 250,
            headerClassName: styles.tableHeader,
            Cell: (props: CellInfo) => <SparklineCell metric={props.value} />,
        },
        {
            Header: 'POSTOP PAIN CONTROL',
            accessor: 'quality:postoppain',
            width: 250,
            headerClassName: styles.tableHeader,
            Cell: (props: CellInfo) => <SparklineCell metric={props.value} />,
        },
        {
            Header: 'NORMOTHERMIA',
            accessor: 'quality:normothermia',
            width: 250,
            headerClassName: styles.tableHeader,
            Cell: (props: CellInfo) => <SparklineCell metric={props.value} />,
        },
        {
            Header: 'SAFETY CHECKLIST USED',
            accessor: 'quality:safetychecklist',
            width: 250,
            headerClassName: styles.tableHeader,
            Cell: (props: CellInfo) => <SparklineCell metric={props.value} />,
        },
        {
            Header: 'CURRENT MEDS DOCUMENTED',
            accessor: 'quality:currentmedsdocumented',
            width: 250,
            headerClassName: styles.tableHeader,
            Cell: (props: CellInfo) => <SparklineCell metric={props.value} />,
        },
    ];

    return <div className={styles.tabContent}>
        <div className={styles.tableWrap}>
            <Table
                isLoading={scorecardStore.isLoading}
                columns={qualityColumns}
                data={scorecardStore.qualityData(
                    macraStore.selectedId,
                    macraStore.currentIdMode,
                )}
                showPagination={false}
            />
        </div>
    </div>;
}

export default observer(QualityTab);