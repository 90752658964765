import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import AuthStore from 'src/stores/AuthStore';
import styles from './styles.module.scss';
import UserStore from 'src/stores/UserStore';
import OrgStore from 'src/stores/OrgStore';
import ErrorStore, { ErrorTypes } from 'src/stores/ErrorStore';
import FacilityStore from 'src/stores/FacilityStore';
import AnalyticsStore from 'src/stores/AnalyticsStore';
import Flex from '../Flex';
import { getUserStartupData } from 'src/api/auth';
import { OrgOpt } from 'src/utils/types';
import { randomIntFromInterval } from 'src/utils';
import { useLocation } from 'react-router-dom';
import FeatureFlagStore from 'src/stores/FeatureFlagStore';

interface LocationState {
    from?: {
        pathname: string;
    };
}

const StartupActionsScreen = observer(() => {
    const authStore = useContext(AuthStore);
    const userStore = useContext(UserStore);
    const orgStore = useContext(OrgStore);
    const errorStore = useContext(ErrorStore);
    const facilityStore = useContext(FacilityStore);
    const analyticsStore = useContext(AnalyticsStore);
    const flagStore = useContext(FeatureFlagStore);
    const [loadingBarWidth, setLoadingBarWidth] = useState(0);
    const [loadingText, setLoadingText] = useState(
        authStore.isLoadingStartupData === 'orgswitch'
            ? 'Retrieving your organization'
            : 'Retrieving your organizations',
    );
    const location = useLocation<LocationState>();

    function incLoadingBar() {
        if (loadingBarWidth < 100) {
            setLoadingBarWidth(loadingBarWidth + 1);
        } else {
            setLoadingBarWidth(100);
        }
    }

    useEffect(() => {
        if (loadingBarWidth < 100) {
            if (loadingBarWidth === 25) {
                setLoadingText('Loading your experience');
            } else if (loadingBarWidth === 50) {
                setLoadingText('Almost there');
            }
            const progressInterval = setInterval(incLoadingBar, randomIntFromInterval(150, 300));
            return () => clearInterval(progressInterval);
        }
    }, [loadingBarWidth]);

    function done() {
        setLoadingBarWidth(100);
        setTimeout(() => {
            authStore.setLoadingStartupData(null);
        }, randomIntFromInterval(500, 1000));
    }

    async function loadAll() {
        let loginOrgData: any = { orgId: userStore.selectedOrg.orgId };
        if (authStore.isLoadingStartupData === 'login') {
            // If we're logging in and coming from a specific route, try to pull out the org name internal from the route
            if (location.state && location.state.from && location.state.from.pathname) {
                const previousOrgNm = location.state.from.pathname.split('/')[1];
                if (previousOrgNm && previousOrgNm !== 'undefined') {
                    // If we pass up an orgNmIntrnl that the user doesn't have access to, we will choose an org for them
                    loginOrgData = { orgNmIntrnl: previousOrgNm };
                }
            }
        }
        const results: any = await getUserStartupData(loginOrgData);
        const {
            telehealthOptions,
            vitalsOptions,
            analyticsOptions,
            orgTypes,
            orgUser,
            facilities,
            selectedOrg,
            organizations,
        } = results.data;
        const selectedOrgOption = orgTypes.find((o: OrgOpt) => selectedOrg.orgId === o.orgId);
        const telehealthEnabled = telehealthOptions.some(
            (o: OrgOpt) => selectedOrg.orgId === o.orgId && o.optVal === 'true',
        );
        const vitalsEnabled = vitalsOptions.some((o: OrgOpt) => selectedOrg.orgId === o.orgId && o.optVal === 'true');
        const analyticsEnabled = analyticsOptions.some(
            (o: OrgOpt) => selectedOrg.orgId === o.orgId && o.optVal === 'true',
        );
        userStore.setOrgType((selectedOrgOption || {}).optVal);
        userStore.setOrgData(orgUser);
        userStore.setUserData({
            selectedOrg,
            selectedOrgTelehealthEnabled: telehealthEnabled,
            selectedOrgVitalsEnabled: vitalsEnabled,
            selectedOrgAnalyticsEnabled: analyticsEnabled,
        });
        orgStore.setOrgsForUser(organizations);
        orgStore.setOrgTypes(orgTypes);
        orgStore.setOrgTelehealthOptions(telehealthOptions);
        orgStore.setOrgVitalsOptions(vitalsOptions);
        orgStore.setOrgAnalyticsOptions(analyticsOptions);
        facilityStore.setFacilities(facilities);

        await flagStore.listFlags();

        // initialize analytics
        analyticsStore.initialize();
    }

    async function load() {
        try {
            // If we are reloading and have the data that's needed, only check to see if we need to make these specific calls again
            if (authStore.isLoadingStartupData === 'load' && userStore.selectedOrgType && userStore.selectedOrg.orgId) {
                if (orgStore.orgs.length === 0) {
                    await orgStore.getOrgsForUser(true);
                }
                if (facilityStore.facilities.length === 0) {
                    await facilityStore.getFacilities(true);
                }
            } else {
                // If we're in the orgswitch, or login startup, just try to reload everything
                await loadAll();
            }
        } catch (e) {
            errorStore.setError(ErrorTypes.Startup);
        } finally {
            done();
        }
    }

    useEffect(() => {
        if (authStore.accessToken) {
            console.log('starting load with access token', authStore.accessToken);
            load();
        }
    }, [authStore.accessToken]);

    return (
        <div className={styles.wrapper}>
            <div className={styles.skeletonBox} style={{ width: '100%', height: 100 }}></div>
            <Flex self="stretch" align="center" justify="center" direction="column" className={styles.loadingText}>
                <div className={styles.barWrap}>
                    <div className={styles.barInner} style={{ width: `${loadingBarWidth}%` }}></div>
                </div>
                {loadingText}
            </Flex>
        </div>
    );
});

function StartupLoading({ children }: { children: React.ReactNode }) {
    const authStore = useContext(AuthStore);

    if (authStore.isLoadingStartupData && authStore.isAuthenticated) {
        return <StartupActionsScreen />;
    }
    return <>{children}</>;
}

export default observer(StartupLoading);
