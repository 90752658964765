import * as ROUTES from 'src/api/routes';
import request from 'src/api/utils';
import * as utils from 'src/utils';

export async function fetchRoles() {
    const roles = await request(ROUTES.GET_ROLES);
    return roles;
}

export async function updateRole(roleId: number, data: object) {
    const cleanedData = utils.removeNull(data);
    return await request({ ...ROUTES.UPDATE_ROLE, pathParams: { roleId }, data: cleanedData });
}

export async function createRole(data: object) {
    const cleanedData = utils.removeNull(data);
    return await request({ ...ROUTES.CREATE_ROLE, data: cleanedData });
}
