import { useEffect, useRef } from 'react';

/**
 * Keep track of whether it's the first mount or not.
 *
 * @export
 * @returns
 */
export default function useIsMount() {
    const isMountRef = useRef(true);
    useEffect(() => {
        isMountRef.current = false;
    }, []);
    return isMountRef.current;
}
