import React, { useState, useRef, useMemo, useContext } from 'react';
import styles from './styles.module.scss';
import Flex from 'src/components/Flex';
import CenterModal from 'src/components/CenterModal';
import Button from 'src/components/Button';
import ModalHeader from 'src/components/ModalHeader';
import ModalFooter from 'src/components/ModalFooter';
import { TelehealthSession as TelehealthSessionType } from 'src/utils/types';
import Input from '../Input';
import UserStore from 'src/stores/UserStore';
import Checkbox from '../Checkbox';
import { copyTextToClipboard } from 'src/utils/dependentUtils';

interface ResendTelehealthInviteModalProps {
    title?: string;
    onSave: Function;
    onCancel: Function;
    confirmButtonText?: string;
    cancelButtonText?: string;
    isLoading?: boolean;
    sessionState?: TelehealthSessionType;
}

const ResendTelehealthInviteModal = (props: ResendTelehealthInviteModalProps) => {
    const userStore = useContext(UserStore);
    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState(props.sessionState.telehealthSessionDetail.patientEmail || '');
    const [phone, setPhone] = useState(props.sessionState.telehealthSessionDetail.patientPhone || '');
    const [isEmailChecked, setIsEmailChecked] = useState(false);
    const [isPhoneChecked, setIsPhoneChecked] = useState(false);
    const linkRef = useRef(null);
    const linkText = useMemo(() => getLinkText(props.sessionState, userStore.selectedOrg.orgNmIntrnl), [
        props.sessionState,
        userStore.selectedOrg,
    ]);

    function getLinkText(session?: TelehealthSessionType, orgNameInternal?: string) {
        if (!session) {
            return '';
        }
        return `${window.location.origin}/${orgNameInternal}/patient-telehealth?guid=${session.telehealthSessionGuid}`;
    }

    async function handleSave() {
        try {
            setIsLoading(true);
            const data = {
                phone: isPhoneChecked ? phone : null,
                email: isEmailChecked ? email : null,
            };
            await props.onSave(data);
        } finally {
            setIsLoading(false);
        }
    }
    return (
        <CenterModal isOpen={true} onClose={props.onCancel}>
            <div className={styles.idleModal}>
                <ModalHeader title={props.title ? props.title : 'Resend Invitation Link'} />
                <Flex direction="column" className={styles.modalContent} align="start" self="stretch">
                    <Flex direction="row">
                        <Flex value={1} align="center" className={styles.link}>
                            <span ref={linkRef} className={styles.linkText} title={linkText}>
                                {linkText}
                            </span>
                        </Flex>
                        <Button type="secondary" text="Copy Link" onClick={() => copyTextToClipboard(linkText)} />
                    </Flex>
                    <Flex
                        value={1}
                        align="start"
                        direction="column"
                        justify="center"
                        self="stretch"
                        className={styles.inputWrap}
                    >
                        <Flex direction="row" align="center" self="stretch">
                            <Checkbox checked={isEmailChecked} onChange={(e) => setIsEmailChecked(e.target.checked)} />
                            <Input
                                wrapClassName={styles.inputContainer}
                                className={styles.input}
                                placeholder={'Patient Email Address'}
                                value={email}
                                onChangeText={(t: string) => setEmail(t)}
                            />
                        </Flex>
                        <Flex direction="row" align="center" self="stretch">
                            <Checkbox checked={isPhoneChecked} onChange={(e) => setIsPhoneChecked(e.target.checked)} />
                            <Input
                                wrapClassName={styles.inputContainer}
                                className={styles.input}
                                placeholder={'Patient Phone Number'}
                                value={phone}
                                onChangeText={(t: string) => setPhone(t)}
                            />
                        </Flex>
                    </Flex>
                </Flex>
                <ModalFooter
                    primaryText={props.confirmButtonText ? props.confirmButtonText : 'Send'}
                    primaryClick={handleSave}
                    secondaryText={props.cancelButtonText ? props.cancelButtonText : 'Cancel'}
                    secondaryClick={props.onCancel}
                    primaryProps={{ disabled: !isPhoneChecked && !isEmailChecked }}
                    isLoading={isLoading}
                />
            </div>
        </CenterModal>
    );
};

export default ResendTelehealthInviteModal;
