import orderBy from 'lodash/orderBy';
import { FlowScriptHistory, FlowScriptTemplateHistory } from './types';

export function getFlowScriptHistoryVersion(
    histories: FlowScriptHistory[],
    version: FlowScriptHistory['flowScriptVersion'],
): FlowScriptHistory {
    return (histories || []).find((h) => h.flowScriptVersion === version);
}

export function getFlowScriptTemplateHistoryVersion(
    histories: FlowScriptTemplateHistory[],
    version: FlowScriptTemplateHistory['flowScriptTemplateVersion'],
): FlowScriptTemplateHistory {
    return (histories || []).find((h) => h.flowScriptTemplateVersion === version);
}

function getOrderedFlowScriptHistories(histories: FlowScriptHistory[]): FlowScriptHistory[] {
    return orderBy(histories || [], ['flowScriptVersion'], ['desc']);
}
export function getLatestFlowScriptHistory(histories: FlowScriptHistory[]): FlowScriptHistory {
    return getOrderedFlowScriptHistories(histories)[0];
}

function getOrderedFlowScriptTemplateHistories(histories: FlowScriptTemplateHistory[]): FlowScriptTemplateHistory[] {
    return orderBy(histories || [], ['flowScriptTemplateVersion'], ['desc']);
}
export function getLatestFlowScriptTemplateHistory(histories: FlowScriptTemplateHistory[]): FlowScriptTemplateHistory {
    return getOrderedFlowScriptTemplateHistories(histories)[0];
}
