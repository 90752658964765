import { useContext, useState, useEffect } from 'react';
import OrgStore from 'src/stores/OrgStore';

export function useAgencyState() {
    const orgStore = useContext(OrgStore);
    const [value, setValue] = useState(null);

    if (!orgStore.currentOrgDbOrg) orgStore.getOrgDbOrg();

    useEffect(() => {
        if (orgStore.currentOrgDbOrg
            && orgStore.currentOrgDbOrg.agencyInfo
            && orgStore.currentOrgDbOrg.agencyInfo.agencyStateCode) {
            setValue(orgStore.currentOrgDbOrg.agencyInfo.agencyStateCode);
        }
    }, [orgStore.currentOrgDbOrg]);

    return value;
}
