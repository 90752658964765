import React, { useContext, useEffect, useState } from 'react';
import styles from './styles.module.scss';
import Flex from 'src/components/Flex';
import Button from 'src/components/Button';
import { observer } from 'mobx-react';
import PageContainer from 'src/components/PageContainer';
import NavBar from '../NavBar';
import PageContent from 'src/components/PageContent';
import Table from 'src/components/Table';
import TransportFacilityStore, { TransportFacility } from 'src/stores/TransportFacilityStore';
import { ModalStoreObject, ModalTypes } from 'src/stores/ModalStore';
import ReferenceListStore from 'src/stores/ReferenceListStore';
import { OptionTypes } from 'src/components/OptionDropDown';
import LoadingCover from 'src/components/LoadingCover';
import { ToastStoreObject } from 'src/stores/ToastStore';
import * as utils from 'src/utils';
import * as variables from 'src/styles/variables';
import Icon from 'src/components/Icon';
import { ICONS } from 'src/utils/constants';
import NemsisErrorBanner, { NemsisErrorBannerMode } from '../NemsisErrorBanner';
import NemsisStore from 'src/stores/NemsisStore';
import { useAgencyCustomResults } from 'src/utils/hooks/useAgencyCustomResults';
import TableActiveCol from 'src/components/TableActiveCol';
import { parseError } from 'src/utils';

function SettingsTransportFacilities() {
    const transportFacilityStore = useContext(TransportFacilityStore);
    const refListStore = useContext(ReferenceListStore);
    const nemsisStore = useContext(NemsisStore);
    const [isLoading, setIsLoading] = useState(false);
    const [customResults, setCustomResults, saveCustomResults] = useAgencyCustomResults();

    useEffect(() => {
        loadTransportFacilityData({ initial: true });
    }, []);

    async function loadTransportFacilityData(options: { initial?: boolean } = {}) {
        try {
            setIsLoading(true);
            await Promise.all([
                transportFacilityStore.getTransportFacilities(),
                ...(options.initial ? [loadRefLists()] : []),
            ]);
        } catch (e) {
            ToastStoreObject.show(utils.parseError(e));
        } finally {
            setIsLoading(false);
        }
    }

    async function loadRefLists() {
        try {
            await refListStore.getMultipleLists([
                OptionTypes.EMSPhoneNumberType,
                OptionTypes.EMSTypeOfFacility,
                OptionTypes.EMSHospitalDesignation,
                OptionTypes.ANSIStateCodes,
                OptionTypes.EMSANSICountryCodesCAMXUS,
            ]);
        } catch (error) {
            ToastStoreObject.show(utils.parseError(error));
        }
    }

    function handleAdd() {
        showModal();
    }

    function handleEdit(transportFacility: TransportFacility) {
        showModal(transportFacility);
    }

    function showModal(transportFacility: TransportFacility = null) {
        ModalStoreObject.showModal(ModalTypes.TransportFacilityModal, {
            onClose: () => ModalStoreObject.hideModal(),
            onSave: () => onSave(),
            transportFacility,
        });
    }

    async function toggleActive(transportFacility: TransportFacility) {
        try {
            await transportFacilityStore.toggleActive(transportFacility);
            await nemsisStore.validateDemData(true);
        } catch (error: any) {
            ToastStoreObject.show(parseError(error));
        } finally {
            ModalStoreObject.hideModal();
            await loadTransportFacilityData();
        }
    }

    async function onSave() {
        try {
            await loadTransportFacilityData();
        } catch (e) {
            ToastStoreObject.show(utils.parseError(e));
        } finally {
            ModalStoreObject.hideModal();
        }
    }

    function handleConfirm(transportFacility: TransportFacility) {
        ModalStoreObject.showModal(ModalTypes.ConfirmationModal, {
            title: `Are you sure you would like to deactivate this transport facility?`,
            onConfirm: () => toggleActive(transportFacility),
            onCancel: () => ModalStoreObject.hideModal(),
            confirmButtonText: 'Deactivate',
        });
    }

    function getFacilityTypeLabel(transportFacility: TransportFacility) {
        const refList = refListStore.referenceListValues[OptionTypes.EMSTypeOfFacility];

        if (!refList) return null;

        const facTypeRefValue = refList.find((value) => value.listValueCode === transportFacility.facilityTypeCode);

        return facTypeRefValue ? facTypeRefValue.listValueDisplayName : null;
    }

    function getCityStateLabel(transportFacility: TransportFacility) {
        const refList = refListStore.referenceListValues[OptionTypes.ANSIStateCodes];

        if (!refList) return null;

        const stateRefValue = refList.find((value) => value.listValueCode === transportFacility.stateCode);

        if (transportFacility.cityName && stateRefValue)
            return `${transportFacility.cityName}, ${stateRefValue.listValueDisplayName}`;
        else if (transportFacility.cityName) return transportFacility.cityName;
        else if (stateRefValue) return stateRefValue.listValueDisplayName;
        else return null;
    }

    return (
        <PageContainer>
            <NavBar />
            <PageContent>
                <NemsisErrorBanner mode={NemsisErrorBannerMode.DEM} />
                <Flex self="stretch" align="center" justify="start">
                    <Flex value={1} className={styles.pageTitle}>
                        TRANSPORT FACILITIES
                    </Flex>
                    <Button
                        leftIcon={<Icon className={styles.plusIcon} name={ICONS.PlusButton} />}
                        type="primary"
                        text="New Transport Facility"
                        onClick={handleAdd}
                    />
                </Flex>

                <Table
                    className={styles.tableWrap}
                    columns={[
                        {
                            Header: 'Code',
                            accessor: 'facilityLocationCode',
                            minWidth: 160,
                            Cell: (cellProps) => {
                                const transportFacility = cellProps.original as TransportFacility;
                                return <span>{transportFacility.facilityLocationCode || '-'}</span>;
                            },
                        },
                        {
                            Header: 'Name',
                            accessor: 'facilityName',
                            minWidth: 250,
                            Cell: (cellProps) => {
                                const transportFacility = cellProps.original as TransportFacility;
                                return <span>{transportFacility.facilityName || '-'}</span>;
                            },
                        },
                        {
                            Header: 'Type',
                            accessor: 'facilityTypeCode',
                            minWidth: 200,
                            Cell: (cellProps) => {
                                const transportFacility = cellProps.original as TransportFacility;
                                return <span>{getFacilityTypeLabel(transportFacility) || '-'}</span>;
                            },
                        },
                        {
                            Header: 'City/State',
                            accessor: 'cityCode',
                            minWidth: 250,
                            Cell: (cellProps) => {
                                const transportFacility = cellProps.original as TransportFacility;
                                return <span>{getCityStateLabel(transportFacility) || '-'}</span>;
                            },
                        },
                        {
                            Header: 'Status',
                            accessor: 'active',
                            minWidth: 100,
                            Cell: (cellProps) => {
                                const transportFacility = cellProps.original as TransportFacility;
                                return <TableActiveCol isActive={transportFacility.active} />;
                            },
                        },
                        {
                            Header: 'ACTIONS',
                            accessor: 'actions',
                            minWidth: 300,
                            style: { paddingVertical: 0, paddingHorizontal: 20 },
                            Cell: (cellProps) => {
                                const transportFacility = cellProps.original as TransportFacility;
                                return (
                                    <Flex direction="row">
                                        <Button
                                            data-test-id='TransportFacilities_Table_Edit'
                                            onClick={() => {
                                                handleEdit(transportFacility);
                                            }}
                                            type="secondary"
                                            leftIcon={<Icon name={ICONS.Pencil2} color={variables.red} />}
                                        />
                                        {cellProps.original.active ? (
                                            <Button
                                                data-test-id='TransportFacilities_Table_Deactivate'
                                                onClick={() => handleConfirm(transportFacility)}
                                                type="secondary"
                                                text="Deactivate"
                                                className={styles.actionButtons}
                                            />
                                        ) : (
                                            <Button
                                                data-test-id='TransportFacilities_Table_Reactivate'
                                                onClick={() => toggleActive(transportFacility)}
                                                type="primary"
                                                text="Reactivate"
                                                className={styles.actionButtons}
                                            />
                                        )}
                                    </Flex>
                                );
                            },
                        },
                    ]}
                    data={transportFacilityStore.transportFacilities || []}
                />
                {isLoading ? <LoadingCover style={{ top: 90 }} /> : null}
            </PageContent>
        </PageContainer>
    );
}

export default observer(SettingsTransportFacilities);
