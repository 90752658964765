import React from 'react';
import styles from './styles.module.scss';

function PercentageBar({ value }: { value: number }) {
    const val = value
        ? Math.min(100, value * 100)
              .toFixed(1)
              .replace('.0', '')
        : 0;
    return (
        <div className={styles.percentWrapper} title={`${value || '0'}`}>
            <div className={styles.valueWrapper}>
                <div
                    className={styles.percentComplete}
                    style={{
                        width: `${val}%`,
                    }}
                ></div>
            </div>
            <div className={styles.fixedValue}>{val}%</div>
        </div>
    );
}

export default PercentageBar;
