import React, { useState, useContext } from 'react';
import styles from './styles.module.scss';
import Flex from '../../components/Flex';
import Icon from '../../components/Icon';
import { observer } from 'mobx-react';
import { NavLink, match } from 'react-router-dom';
import * as H from 'history';
import { ICONS, ROUTES } from 'src/utils/constants';
import * as variables from 'src/styles/variables';
import UserStore, { OrgType } from 'src/stores/UserStore';

type SubNavTypes = 'patients' | 'reports';

type SubNavLink = {
    icon: string;
    text: string;
    to: string;
    isActive?: <Params extends { [K in keyof Params]?: string }>(match: match<Params>, location: H.Location) => boolean;
};

interface SubNavProps {
    type: SubNavTypes;
}

function SubNav({ type }: SubNavProps) {
    const [isActiveNav, setIsActiveNav] = useState<any>({});
    const [isHovering, setIsHovering] = useState('');
    const userStore = useContext(UserStore);
    const userOrgType = userStore.selectedOrgType;

    function getSubnavLinks(type: SubNavTypes, orgType: OrgType): SubNavLink[] {
        const isAnalyticsEnabled =
            userStore.selectedOrgAnalyticsEnabled && userStore.userPermissions?.canView?.advancedAnalytics;
        const graphiumReports = isAnalyticsEnabled
            ? [
                  {
                      icon: ICONS.GraphStatsCircle,
                      text: 'MACRA Compliance',
                      to: ROUTES.getString(ROUTES.ReportsMacra),
                  },
                  {
                      icon: ICONS.NewWindow,
                      text: 'Advanced Analytics',
                      to:
                          import.meta.env.VITE_APP_ENV === 'pro'
                              ? ROUTES.getString(ROUTES.AdvancedAnalyticsProd)
                              : ROUTES.getString(ROUTES.AdvancedAnalyticsDev),
                      external: true,
                  },
              ]
            : [
                  {
                      icon: ICONS.GraphStatsCircle,
                      text: 'MACRA Compliance',
                      to: ROUTES.getString(ROUTES.ReportsMacra),
                  },
              ];
        const links = {
            patients: [
                ...(orgType === 'FieldMed'
                    ? [
                          { icon: ICONS.ToolBox, text: 'Patients', to: ROUTES.getString(ROUTES.Patients) },
                          { icon: ICONS.LaptopHeart, text: 'Encounters', to: ROUTES.getString(ROUTES.Encounters) },
                          { icon: ICONS.NotesBookText, text: 'Forms', to: ROUTES.getString(ROUTES.Forms) },
                      ]
                    : [
                          { icon: ICONS.LaptopHeart, text: 'Encounters', to: ROUTES.getString(ROUTES.Encounters) },
                          { icon: ICONS.NotesBookText, text: 'Forms', to: ROUTES.getString(ROUTES.Forms) },
                      ]),
            ],
            reports: [
                ...(orgType === 'FieldMed'
                    ? [{ icon: ICONS.Calendar, text: 'CHP Dashboard', to: ROUTES.getString(ROUTES.ReportsChp) }]
                    : []),
                ...(orgType === 'GraphiumHealth' ? graphiumReports : []),
            ],
        };
        return links[type];
    }

    const links = getSubnavLinks(type, userOrgType);

    return (
        <Flex direction="row" align="center" justify="center" className={styles.subnav}>
            {links.map((l) => (
                <Flex key={l.to} direction="row" align="center">
                    <NavLink
                        data-test-id={`NavLink_${l.text}`}
                        exact={!l.isActive}
                        isActive={
                            l.isActive
                                ? (match, location) => {
                                      const res = l.isActive(match, location);
                                      if (isActiveNav[l.text] === res) {
                                          return res;
                                      } else {
                                          setIsActiveNav({ ...isActiveNav, [l.text]: res });
                                          return res;
                                      }
                                  }
                                : (match) => {
                                      if (!match) {
                                          if (isActiveNav[l.text] === false) {
                                              return false;
                                          } else {
                                              setIsActiveNav({ ...isActiveNav, [l.text]: false });
                                              return false;
                                          }
                                      } else {
                                          if (isActiveNav[l.text] === true) {
                                              return true;
                                          } else {
                                              setIsActiveNav({ ...isActiveNav, [l.text]: true });
                                              return true;
                                          }
                                      }
                                  }
                        }
                        to={l.to.includes('https://') ? { pathname: l.to } : l.to}
                        className={isActiveNav[l.text] ? styles.active : styles.link}
                        onMouseEnter={() => setIsHovering(l.to)}
                        onMouseLeave={() => setIsHovering('')}
                        target={l.to.includes('https://') ? '_blank' : ''}
                    >
                        <Icon
                            name={l.icon}
                            color={isActiveNav[l.text] || isHovering === l.to ? variables.red : variables.darkGrey}
                            className={styles.icon}
                        />
                        <span className={styles.text}>{l.text}</span>
                    </NavLink>
                </Flex>
            ))}
        </Flex>
    );
}

export default observer(SubNav);
