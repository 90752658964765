import React, { useState } from 'react';
import styles from './styles.module.scss';
import Flex from 'src/components/Flex';
import CenterModal from 'src/components/CenterModal';
import ModalHeader from 'src/components/ModalHeader';
import ModalFooter from 'src/components/ModalFooter';
import Input from '../Input';

interface CancelReasonModalProps {
    onSave: Function;
    onClose: Function;
}

const CancelReasonModal = (props: CancelReasonModalProps) => {
    const [cancelReason, setCancelReason] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    async function handleSave() {
        try {
            setIsLoading(true);
            await props.onSave(cancelReason);
        } finally {
            setIsLoading(false);
        }
    }
    return (
        <CenterModal isOpen={true} onClose={props.onClose}>
            <div className={styles.idleModal}>
                <ModalHeader title={'Please enter a cancellation reason'} />
                <Flex direction="column" className={styles.modalContent} align="center" self="stretch">
                    <Flex value={1} align="center" justify="center" self="stretch">
                        <Input
                            className={styles.input}
                            label={'Cancellation Reason'}
                            value={cancelReason}
                            onChangeText={(t: string) => setCancelReason(t)}
                        />
                    </Flex>
                </Flex>
                <ModalFooter
                    primaryText={'Cancel Visit'}
                    primaryClick={handleSave}
                    secondaryText={'Go Back'}
                    secondaryClick={props.onClose}
                    isLoading={isLoading}
                />
            </div>
        </CenterModal>
    );
};

export default CancelReasonModal;
