import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import Button from 'src/components/Button';
import Icon from 'src/components/Icon';
import { ICONS } from 'src/utils/constants';
import * as variables from 'src/styles/variables';
import Flex from '../Flex';
import { ANALYTICS_NAMES } from 'src/utils/analytics';

export interface PaginationProps {
    pages: number;
    page: number;
    onPageChange: Function;
    totalRecords?: number;
    showing?: number;
}

function filterPages(visiblePages: number[], totalPages: number) {
    return visiblePages.filter((page) => page <= totalPages);
}

function getVisiblePages(page: number, total: number) {
    const maxPages = 5;
    const startingArr = new Array(maxPages).fill(0).map((_, i) => i + 1);
    if (total < maxPages + 1) {
        return filterPages(startingArr, total);
    } else {
        if (page % maxPages >= 0 && page > maxPages - 2 && page + 3 < total) {
            return [page - 2, page - 1, page, page + 1, page + 2];
        } else if (page % maxPages >= 0 && page > maxPages - 2 && page + 3 >= total) {
            return [total - 4, total - 3, total - 2, total - 1, total];
        } else {
            return startingArr;
        }
    }
}

const Pagination = ({ pages, page, onPageChange, totalRecords, showing }: PaginationProps) => {
    const [visiblePages, setVisiblePages] = useState<number[]>(getVisiblePages(null, pages));
    const activePage = page + 1;

    useEffect(() => {
        setVisiblePages(getVisiblePages(null, pages));
    }, [pages]);

    const changePage = (pageParam: number) => {
        if (pageParam === activePage) {
            return;
        }

        const visiblePages = getVisiblePages(pageParam, pages);

        setVisiblePages(filterPages(visiblePages, pages));

        onPageChange(pageParam - 1);
    };

    return (
        <Flex
            direction="column"
            align="center"
            justify="center"
            className={styles.paginationContainer}
            data-test-id={ANALYTICS_NAMES.Pagination_Container}
        >
            <Flex direction="row" justify="center" className={styles.paginationContent}>
                <Button
                    preventDouble={false}
                    type="transparent"
                    className={styles.pageButton}
                    onClick={() => {
                        if (activePage === 1) return;
                        changePage(activePage - 1);
                    }}
                    disabled={activePage === 1}
                    data-test-id={ANALYTICS_NAMES.Pagination_Previous}
                >
                    <Icon name={ICONS.Pagination} color={variables.red} className={styles.rotateIcon} />
                </Button>
                {visiblePages.map((visiblePage: number) => (
                    <Button
                        preventDouble={false}
                        type="transparent"
                        key={visiblePage}
                        className={classNames(styles.pageButton, {
                            [styles.pageButtonActive]: activePage === visiblePage,
                        })}
                        onClick={() => changePage(visiblePage)}
                        data-test-id={`${ANALYTICS_NAMES.Pagination_Page}_${visiblePage}`}
                    >
                        {visiblePage}
                    </Button>
                ))}
                <Button
                    preventDouble={false}
                    type="transparent"
                    className={styles.pageButton}
                    onClick={() => {
                        if (activePage === pages) return;
                        changePage(activePage + 1);
                    }}
                    disabled={activePage === pages}
                    data-test-id={ANALYTICS_NAMES.Pagination_Next}
                >
                    <Icon name={ICONS.Pagination} color={variables.red} />
                </Button>
            </Flex>
            {totalRecords > 0 ? (
                <div className={styles.totalWrap}>
                    {showing ? `Displaying ${showing} of ${totalRecords}` : `Total: ${totalRecords}`}
                </div>
            ) : null}
        </Flex>
    );
};

export default Pagination;
