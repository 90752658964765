import { Role } from 'src/stores/RoleStore';
import { observable, action, makeObservable } from 'mobx';
import { createContext } from 'react';
import * as organizationAPI from 'src/api/organization';
import * as utils from 'src/utils';
import * as variables from 'src/styles/variables';

export interface OrgUser {
    usrId?: number;
    actvInd?: boolean;
    audVer?: number;
    emailAddr?: string;
    frstNm?: string;
    lastNm?: string;
    insDttm?: string;
    prvrId?: number;
    updDttm?: string;
    usrNm?: string;
    roles?: Role[];
    orgs?: {}[];
}

export interface OrgUserWithColor extends OrgUser {
    color?: { active: string; inactive: string };
}

const OrgUserColors = [
    { active: variables.calendar1, inactive: variables.calendarInactive1 },
    { active: variables.calendar2, inactive: variables.calendarInactive2 },
    { active: variables.calendar3, inactive: variables.calendarInactive3 },
    { active: variables.calendar4, inactive: variables.calendarInactive4 },
    { active: variables.calendar5, inactive: variables.calendarInactive5 },
    { active: variables.calendar6, inactive: variables.calendarInactive6 },
    { active: variables.calendar7, inactive: variables.calendarInactive7 },
    { active: variables.calendar8, inactive: variables.calendarInactive8 },
    { active: variables.calendar9, inactive: variables.calendarInactive9 },
];

class OrgUserStore {
    @observable orgUsers: OrgUser[] = [];
    @observable orgUsersWithColor: OrgUserWithColor[] = [];
    @observable orgUsersDropdownValues: { value: string | number; label: string }[] = [];

    constructor() {
        makeObservable(this);
    }

    @action
    reset() {
        this.orgUsers = [];
        this.orgUsersWithColor = [];
        this.orgUsersDropdownValues = [];
    }

    @action
    async getOrgUsers(force = false) {
        if (force || this.orgUsers.length === 0) {
            const orgUsers = await organizationAPI.getUsers();
            this.setOrgUsers(orgUsers);
        }
    }

    @action
    setOrgUsers(orgUsers: OrgUser[]) {
        this.orgUsers = orgUsers;
        this.orgUsersWithColor = orgUsers.map((t, i) => ({ ...t, color: OrgUserColors[i % OrgUserColors.length] }));
        this.orgUsersDropdownValues = utils.formatSelectOptions(
            orgUsers.filter((u) => u.actvInd),
            {
                valueKey: 'usrId',
                getLabel: (o: OrgUser) => `${o.frstNm} ${o.lastNm}`,
            },
        );
    }
}

// Import this to any other store that needs to use a value from it
export const OrgUserStoreObject = new OrgUserStore();

export default createContext(OrgUserStoreObject);
