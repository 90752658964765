import React, { useState, useEffect } from 'react';
import styles from './styles.module.scss';
import * as utils from 'src/utils';
import Input, { Label } from 'src/components/Input';
import * as surveyService from 'src/api/survey';
import Button from 'src/components/Button';
import Select from 'react-select';
import { observer } from 'mobx-react';
import {
    SurveyStateCodes,
    SurveyField,
    FieldTypes,
    SurveyResponse,
    SurveyUnauth,
} from 'common/lib/model/surveys/Survey';
import { ToastStoreObject, ToastType } from 'src/stores/ToastStore';
import Flex from '../Flex';
import useDebouncedCallback from 'src/utils/hooks/useDebouncedCallback';

export interface SurveyProps {
    survey: SurveyUnauth;
}

export function SurveyInput({
    field,
    onUpdate,
    value,
    isReadOnly,
}: {
    field: SurveyField;
    onUpdate: Function;
    value: any;
    isReadOnly?: boolean;
}) {
    switch (field.type) {
        case FieldTypes.DROPDOWN:
            return (
                <div className={styles.inputRow}>
                    <Label text={field.title} className={styles.questionTitle} />
                    <Select
                        isDisabled={isReadOnly}
                        isClearable={false}
                        isMulti={false}
                        value={value}
                        styles={utils.styleSelectComponent()}
                        onChange={(v: any) => onUpdate(v.value)}
                        options={field.options}
                        placeholder="Select an answer"
                    />
                </div>
            );
        case FieldTypes.SCALE:
            return (
                <div className={styles.inputRow}>
                    <Label text={field.title} className={styles.questionTitle} />
                    <form>
                        <div className={styles.radioWrap}>
                            {field.options.map((option) => {
                                return (
                                    <div key={option.value} className={styles.radioBtn}>
                                        <label>
                                            <input
                                                disabled={isReadOnly}
                                                type="radio"
                                                value={option.value}
                                                checked={parseInt(value) === option.value}
                                                onChange={(e) => onUpdate(parseInt(e.target.value))}
                                                className={styles.radioLabel}
                                            />
                                            {option.label}
                                        </label>
                                    </div>
                                );
                            })}
                        </div>
                    </form>
                </div>
            );
        case FieldTypes.TEXT:
            return (
                <div className={styles.inputRow}>
                    <Input
                        disabled={isReadOnly}
                        label={field.title}
                        value={value}
                        onChangeText={(v: string) => onUpdate(v)}
                        placeholder="Enter your answer"
                    />
                </div>
            );
        case FieldTypes.NUMBER:
            return (
                <div className={styles.inputRow}>
                    <Input
                        disabled={isReadOnly}
                        label={field.title}
                        value={value}
                        type="number"
                        onChangeText={(v: number) => onUpdate(v)}
                        placeholder="Enter your answer"
                    />
                </div>
            );
        default:
            return <div></div>;
    }
}

function SurveyComponent(props: SurveyProps) {
    const [surveyResponses, setSurveyResponses] = useState<SurveyResponse>(props.survey.responseData || {});
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState('');

    // Debounce the update call
    const [handleUpdateSurvey, cancelUpdateDebounce] = useDebouncedCallback(async function (response: SurveyResponse) {
        try {
            await surveyService.updateSurveyForPatient(props.survey.surveyGuid, response);
        } catch (error: any) {
            ToastStoreObject.show(error?.message, ToastType.Error);
        }
    }, 1000);

    useEffect(() => {
        checkIsValid();
    }, [props.survey]);

    async function checkIsValid() {
        if (props.survey.surveyStateCode === SurveyStateCodes.SURVEY_CANCELLED) {
            setMessage('This Survey has been cancelled.');
        }
        if (props.survey.surveyStateCode === SurveyStateCodes.SURVEY_COMPLETED) {
            setMessage('This Survey has already been completed.');
        }
    }

    function handleUpdateResponses(fieldName: string, value: any) {
        const newResponse: SurveyResponse = { ...surveyResponses, [fieldName]: value };
        setSurveyResponses(newResponse);
        handleUpdateSurvey(newResponse);
    }

    async function handleSubmitSurvey() {
        try {
            setIsLoading(true);
            cancelUpdateDebounce();
            await surveyService.submitSurveyForPatient(props.survey.surveyGuid, surveyResponses);
            setMessage('Thanks for submitting your survey!');
            ToastStoreObject.show('Survey submitted!', ToastType.Success);
        } catch (error: any) {
            ToastStoreObject.show(error?.message, ToastType.Error);
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <div className={styles.contentWrap}>
            {message ? (
                <div className={styles.message}>{message}</div>
            ) : (
                <>
                    {props.survey.surveyDefinitionContent.fields.map((field: SurveyField) => (
                        <SurveyInput
                            key={field.id}
                            field={field}
                            value={surveyResponses[field.name]}
                            onUpdate={(value: any) => handleUpdateResponses(field.name, value)}
                        />
                    ))}
                    <Flex justify="center" self="stretch">
                        <Button type="primary" text="Submit" onClick={handleSubmitSurvey} isLoading={isLoading} />
                    </Flex>
                </>
            )}
        </div>
    );
}

export default observer(SurveyComponent);
