import { OffboardReason } from 'common/lib/entity/index/Customer';
import { observer } from 'mobx-react';
import React from 'react';
import { useState } from 'react';
import Select from 'react-select';
import { styleSelectComponent } from 'src/utils';
import styles from '../styles.module.scss';

interface OffboardReasonDropdownProps {
    onChange: Function;
    selectedValue?: OffboardReason;
}

function OffboardReasonDropdown(props: OffboardReasonDropdownProps) {
    const options = mapOffboardReasonOptions();
    const [selected, setSelected] = useState(options.find((i) => i.value === props.selectedValue) || null);

    function mapOffboardReasonOptions() {
        const reasons = Object.values(OffboardReason);
        const reasonOptions = reasons.map((value) => {
            let label;
            switch (value) {
                case OffboardReason.ContractTransferred:
                    label = 'Contact Transferred';
                    break;
                case OffboardReason.CustomerCancelled:
                    label = 'Customer Cancelled';
                    break;
                case OffboardReason.CustomerLostContract:
                    label = 'Customer Lost Contract';
                    break;
                case OffboardReason.FailureToGoLive:
                    label = 'Failure to Go Live';
                    break;
                case OffboardReason.FailureToPay:
                    label = 'Failure to Pay';
                    break;
                case OffboardReason.Other:
                    label = 'Other';
                    break;
                case OffboardReason.TrialCancelled:
                    label = 'Trial Cancelled';
                    break;
            }

            return { label, value };
        });

        return reasonOptions.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
    }

    return (
        <Select
            value={selected}
            onChange={(s: any) => {
                setSelected(s);
                props.onChange(s || null);
            }}
            isClearable={true}
            className={styles.select}
            options={options}
            placeholder="Select Offboard Reason"
            styles={styleSelectComponent()}
        />
    );
}

export default observer(OffboardReasonDropdown);
