import React from 'react';
import Flex from 'src/components/Flex';
import { PatientAddress } from 'src/stores/PatientStore';

interface AddressProps extends React.HTMLAttributes<HTMLSpanElement> {
    address?: PatientAddress;
}
const Address = ({ address }: AddressProps) =>
    !address ? null : (
        <Flex direction="column">
            <span>Type: {address.type}</span>
            <span>{address.line1}</span>
            <span>
                {address.city}, {address.stateCode}
            </span>
            <span>{address.zipCode}</span>
        </Flex>
    );

export default Address;
