import React from 'react';

const SvgTaskText = (props) => (
    <svg width={15} height={20} {...props} viewBox="0 0 15 20">
        <g
            transform="translate(1 1)"
            stroke={props.color || '#FFF'}
            strokeWidth={1.2}
            fill="none"
            fillRule="evenodd"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path d="M7.22 3.936H2.974v-2.25c0-.621.38-1.125.85-1.125H6.37c.47 0 .85.504.85 1.125v2.25z" />
            <path d="M4.672 15.186H1.274c-.469 0-.85-.504-.85-1.125V2.811c0-.621.381-1.125.85-1.125h1.7M7.22 1.686h1.7c.469 0 .849.504.849 1.125v3.375" />
            <path d="M4.672 12.936H2.124v-9H8.07v2.248" />
            <rect x={6.371} y={8.436} width={6.796} height={9} rx={1.2} />
            <path d="M8.07 10.686h3.398M8.07 12.936h3.398M8.07 15.186h1.274" />
        </g>
    </svg>
);

export default SvgTaskText;
