import React, { useState, useContext } from 'react';
import styles from '../../styles.module.scss';
import CustomerStore from 'src/stores/CustomerStore';
import FacilityStore from 'src/stores/FacilityStore';
import Flex from 'src/components/Flex';
import Button from 'src/components/Button';
import Card from 'src/components/Card';
import Table from 'src/components/Table';
import StatusBadge, { StatusBadgeColors } from 'src/components/StatusBadge';
import { CellInfo } from 'react-table';
import { ModalStoreObject, ModalTypes } from 'src/stores/ModalStore';
import { observer } from 'mobx-react';

function Documents() {
    const customerStore = useContext(CustomerStore);
    const facilityStore = useContext(FacilityStore);
    const [isLoading, setIsLoading] = useState(false);

    function handleAdd() {
        ModalStoreObject.showModal(ModalTypes.CustomerDocumentModal, {
            onClose: () => ModalStoreObject.hideModal(),
            onSave: () => customerStore.getCustomer(customerStore?.customer?.customerId, true),
            customer: customerStore?.customer,
        });
    }

    const columns = [
        {
            Header: 'Name',
            accessor: 'documentTitle',
        },
        {
            Header: 'Type',
            accessor: 'documentType',
            Cell: (props: CellInfo) => {
                return <span>{customerStore.getReadableDocumentType(props.value)}</span>;
            },
        },
        {
            Header: 'Status',
            accessor: 'documentStatus',
            minWidth: 50,
            Cell: (props: CellInfo) => {
                return (
                    <StatusBadge
                        status={
                            props.value
                                ? props.value.toLowerCase() === 'pending'
                                    ? StatusBadgeColors.Yellow
                                    : StatusBadgeColors.Green
                                : StatusBadgeColors.Grey
                        }
                        text={props.value}
                    />
                );
            },
        },
        {
            Header: 'Date Completed',
            accessor: 'dateCompleted',
            minWidth: 80,
        },
        {
            Header: 'Facility (Optional)',
            accessor: 'facilityId',
            minWidth: 80,
            Cell: (props: CellInfo) => {
                const fac = facilityStore.findFacility(parseInt(props.original.facilityId));
                return <span>{fac ? fac.facNm : ''}</span>;
            },
        },
        {
            Header: 'Actions',
            accessor: 'actions',
            minWidth: 50,
            Cell: (props: CellInfo) => (
                <Flex>
                    {props.original.documentUrl && (
                        <Button
                            type="small"
                            className={styles.leftButton}
                            text="View"
                            onClick={() =>
                                props.original.documentUrl && window.open(props.original.documentUrl, '_blank')
                            }
                        />
                    )}
                    <Button
                        type="small"
                        text="Edit"
                        onClick={() => {
                            ModalStoreObject.showModal(ModalTypes.CustomerDocumentModal, {
                                mode: 'edit',
                                onClose: () => ModalStoreObject.hideModal(),
                                onSave: () => customerStore.getCustomer(customerStore?.customer?.customerId, true),
                                customer: customerStore?.customer,
                                document: props.original,
                            });
                        }}
                    />
                </Flex>
            ),
        },
    ];

    return (
        <>
            <Flex className={styles.filterWrap}>
                <Flex value={1} justify="end">
                    <Button type="secondary" text="Add Document" onClick={handleAdd} />
                </Flex>
            </Flex>
            <Card className={styles.cardWrap}>
                <div className={styles.tableTitle}>Documents</div>
                <Table isLoading={isLoading} columns={columns} data={customerStore.documents} />
            </Card>
        </>
    );
}

export default observer(Documents);
