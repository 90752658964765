import { FacilityPrimaryUse, MacraDataEntryServicer } from 'common/lib/model/facility/FacilitySubscriptionData';
import { observer } from 'mobx-react';
import React from 'react';
import { useState } from 'react';
import Select from 'react-select';
import { styleSelectComponent } from 'src/utils';
import styles from '../styles.module.scss';

interface DataEntryServicerDropdownProps {
    onChange: Function;
    selectedValue?: MacraDataEntryServicer;
}

function DataEntryServicerDropdown(props: DataEntryServicerDropdownProps) {
    const options = mapDataEntryServicerOptions();
    const [selected, setSelected] = useState(options.find((i) => i.value === props.selectedValue) || null);

    function mapDataEntryServicerOptions() {
        const servicers = Object.values(MacraDataEntryServicer);
        const servicerOptions = servicers.map((value) => {
            let label;
            switch (value) {
                case MacraDataEntryServicer.Graphium:
                    label = 'Graphium';
                    break;
                case MacraDataEntryServicer.Self:
                    label = 'Self';
                    break;
            }

            return { label, value };
        });

        return servicerOptions.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
    }

    return (
        <Select
            value={selected}
            onChange={(s: any) => {
                setSelected(s);
                props.onChange(s || null);
            }}
            isClearable={true}
            className={styles.select}
            options={options}
            placeholder="Select Data Entry Servicer"
            styles={styleSelectComponent()}
        />
    );
}

export default observer(DataEntryServicerDropdown);
