import React from 'react';

const SvgGraphStatsCircle = (props) => (
    <svg width={19} height={20} {...props} viewBox="0 0 19 19">
        <g
            transform="translate(1 1)"
            stroke={props.color || '#FFF'}
            strokeWidth={1.2}
            fill="none"
            fillRule="evenodd"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <ellipse cx={8.727} cy={9} rx={8.182} ry={8.438} />
            <path d="M2.727 9.563H4.91l1.644-4.238a.545.545 0 01.516-.351.546.546 0 01.502.373l2.394 6.994a.549.549 0 00.465.372c.212.02.416-.09.522-.28l1.593-2.87h2.182" />
        </g>
    </svg>
);

export default SvgGraphStatsCircle;
