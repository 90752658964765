import React from 'react';
import styles from './styles.module.scss';
import { observer } from 'mobx-react';
import Accordion from 'src/components/Accordion';
import Flex from 'src/components/Flex';
import { Patient } from 'src/stores/PatientStore';
import PatientInfo from '../PatientInfo/index';
import ContactInfo from '../ContactInfo/index';
import OtherInfo from '../OtherInfo/index';

interface AccordionItemProps {
    label: React.ReactNode;
    value: React.ReactNode;
}
export function AccordionItem(props: AccordionItemProps) {
    return (
        <Flex direction="column" className={styles.accordionLabelWrap}>
            <span className={styles.accordionLabel}>{props.label}</span>
            <span className={styles.accordionValue}>{props.value}</span>
        </Flex>
    );
}

interface InformationProps {
    patient: Patient;
    disableEdit?: boolean;
}
function Information(props: InformationProps) {
    return (
        <div className={styles.container}>
            <PatientInfo patient={props.patient} disableEdit={props.disableEdit} />
            <ContactInfo patient={props.patient} disableEdit={props.disableEdit} />
            <OtherInfo patient={props.patient} disableEdit={props.disableEdit} />
        </div>
    );
}

export default observer(Information);
