import request from 'src/api/utils';
import * as ROUTES from 'src/api/routes';
import { VitalsConnection } from 'common/lib/entity/org/VitalsConnection';
import { VitalsMeasure } from 'common/lib/entity/framework/VitalsMeasure';

export async function getVitalsConnections(params?: { includeInactive: boolean; query?: string; facilityId?: number }) {
    const result = await request({
        ...ROUTES.GET_VITALS_CONNECTIONS,
        params: { ...params },
    });

    return result.data;
}

export async function createVitalsConnection(data: Partial<VitalsConnection>) {
    return await request({ ...ROUTES.CREATE_VITALS_CONNECTION, data: { ...data } });
}

export async function getVitalsConnection(vitalsConnectionId: VitalsConnection['connectionId']) {
    const vitalsConnection = await request({
        ...ROUTES.GET_VITALS_CONNECTION,
        pathParams: { vitalsConnectionId },
    });

    return vitalsConnection.data || {};
}

export async function updateVitalsConnection(
    vitalsConnectionId: VitalsConnection['connectionId'],
    vitalsConnection: Partial<VitalsConnection>,
) {
    return await request({
        ...ROUTES.UPDATE_VITALS_CONNECTION,
        pathParams: { vitalsConnectionId },
        data: { ...vitalsConnection },
    });
}

export async function deactivateVitalsConnection(connectionId: VitalsConnection['connectionId']) {
    return await request({ ...ROUTES.DEACTIVATE_VITALS_CONNECTION, pathParams: { connectionId } });
}

export async function reactivateVitalsConnection(connectionId: VitalsConnection['connectionId']) {
    return await request({ ...ROUTES.REACTIVATE_VITALS_CONNECTION, pathParams: { connectionId } });
}

export async function getVitalsMeasures(measureCategoryId: VitalsMeasure['measureCategoryId']) {
    const measures = await request({ ...ROUTES.GET_VITALS_MEASURES, params: { measureCategoryId } });
    return measures.data || [];
}

export async function getVitalsMeasureCategories() {
    const measureCategories = await request({ ...ROUTES.GET_VITALS_MEASURE_CATEGORIES });
    return measureCategories.data || [];
}

export async function updateVitalsConnectionLatest(
    connectionId: VitalsConnection['connectionId'],
    unitIdentifier: VitalsConnection['unitIdentifier'],
) {
    const latest = await request({
        ...ROUTES.GET_VITALS_CONNECTION,
        pathParams: { connectionId },
        params: { isUpdatingLatest: true, unitIdentifier: unitIdentifier },
    });

    return latest.data || {};
}
