import { observable, action, makeObservable } from 'mobx';
import { createContext } from 'react';
import * as rolesAPI from 'src/api/roles';
import * as utils from 'src/utils';

export interface Role {
    roleId: number;
    roleNm?: string;
    roleDesc?: string;
    actvInd?: boolean;
}

class RoleStore {
    @observable roles: Role[] = [];
    @observable rolesDropdownValues: { value: string | number; label: string }[] = [];

    constructor() {
        makeObservable(this);
    }

    @action
    reset() {
        this.roles = [];
        this.rolesDropdownValues = [];
    }

    @action
    async getRoles(force = false) {
        if (!force && this.roles.length > 0) {
            return;
        }
        const roles = await rolesAPI.fetchRoles();
        this.setRoles(roles.data || []);
    }

    @action
    setRoles(roles: Role[]) {
        this.roles = roles;
        this.rolesDropdownValues = utils.formatSelectOptions(roles, { valueKey: 'roleId', labelKey: 'roleDesc' });
    }
}

// Import this to any other store that needs to use a value from it
export const RoleStoreObject = new RoleStore();

export default createContext(RoleStoreObject);
