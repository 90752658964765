import React, { useState } from 'react';
import CenterModal from 'src/components/CenterModal';
import { Event } from 'src/utils/types';
import EventView from './EventView/index';
import CancelView from './CancelView/index';
import RescheduleView from './RescheduleView/index';
import EditView from './EditView/index';
import { Patient } from 'src/stores/PatientStore';

export interface EventDetailsModalProps {
    event: Event;
    onClose: Function;
    onChangeComplete?: Function;
    patient?: Patient;
}
export interface ViewProps extends EventDetailsModalProps {
    onChangeView: Function;
}

export enum EventDetailViews {
    EVENT = 'EVENT',
    CANCEL = 'CANCEL',
    RESCHEDULE = 'RESCHEDULE',
    EDIT = 'EDIT',
}

function EventDetailsModal(props: EventDetailsModalProps) {
    const [view, setView] = useState<EventDetailViews>(EventDetailViews.EVENT);
    let content;
    if (view === EventDetailViews.EVENT) {
        content = <EventView {...props} onChangeView={setView} />;
    } else if (view === EventDetailViews.CANCEL) {
        content = <CancelView {...props} onChangeView={setView} />;
    } else if (view === EventDetailViews.RESCHEDULE) {
        content = <RescheduleView {...props} onChangeView={setView} />;
    } else if (view === EventDetailViews.EDIT) {
        content = <EditView {...props} onChangeView={setView} />;
    }
    return (
        <CenterModal isOpen={true} onClose={props.onClose}>
            {content}
        </CenterModal>
    );
}

export default EventDetailsModal;
