import React, { useState, useContext } from 'react';
import styles from './styles.module.scss';
import SideModal from 'src/components/SideModal';
import Input, { Label } from 'src/components/Input';
import ModalHeader from 'src/components/ModalHeader';
import * as utils from 'src/utils';
import { createFlowScriptTemplate, updateFlowScriptTemplate } from 'src/api/admin';
import ModalFooter from '../ModalFooter';
import { ToastStoreObject } from 'src/stores/ToastStore';
import AdminFlowScriptTypesDropDown from '../AdminFlowScriptTypesDropDown';
import { FlowScriptTemplate } from 'src/utils/types';
import { ANALYTICS_NAMES } from 'src/utils/analytics';

export interface AdminFlowScriptTemplateProps {
    onClose: Function;
    onSave?: Function;
    isEdit?: boolean;
    flowScriptTemplate?: FlowScriptTemplate;
}
function AdminFlowScriptTemplateModal(props: AdminFlowScriptTemplateProps) {
    const [isLoading, setIsLoading] = useState(false);
    const [title, setTitle] = useState(
        (props.flowScriptTemplate && props.flowScriptTemplate.flowScriptTemplateTitle) || '',
    );
    const [description, setDescription] = useState(
        (props.flowScriptTemplate && props.flowScriptTemplate.flowScriptTemplateDescription) || '',
    );
    const [type, setType] = useState('');

    async function createTemplate() {
        try {
            setIsLoading(true);
            await createFlowScriptTemplate({
                // @ts-ignore
                flowScriptTypeCode: type,
                flowScriptTemplateTitle: title,
                flowScriptTemplateDescription: description,
            });
            setIsLoading(false);

            if (props.onSave) {
                props.onSave();
            }
        } catch (error) {
            ToastStoreObject.show(utils.parseError(error));
        }
    }

    async function updateTemplate() {
        try {
            setIsLoading(true);
            await updateFlowScriptTemplate(props.flowScriptTemplate.flowScriptTemplateGuid, {
                flowScriptTemplateTitle: title,
                flowScriptTemplateDescription: description,
            });
            setIsLoading(false);

            if (props.onSave) {
                props.onSave();
            }
        } catch (error) {
            ToastStoreObject.show(utils.parseError(error));
        }
    }

    return (
        <SideModal isOpen={true} onClose={props.onClose}>
            <ModalHeader title={props.isEdit ? 'Edit Template' : 'Create Template'} onClose={props.onClose} />
            <div className={styles.contentWrap}>
                {!props.isEdit ? (
                    <div>
                        <Label text="Type" />
                        <AdminFlowScriptTypesDropDown
                            onChange={(s: any) => setType(s.value)}
                            data-test-id={ANALYTICS_NAMES.FlowScriptTemplateModal_Type}
                        />
                    </div>
                ) : null}
                <Input
                    className={styles.input}
                    label="Title"
                    value={title}
                    onChangeText={(t: string) => setTitle(t)}
                    data-test-id={ANALYTICS_NAMES.FlowScriptTemplateModal_Title}
                />
                <Input
                    className={styles.input}
                    label="Description"
                    value={description}
                    onChangeText={(t: string) => setDescription(t)}
                    data-test-id={ANALYTICS_NAMES.FlowScriptTemplateModal_Description}
                />
            </div>
            <ModalFooter
                primaryText={props.isEdit ? 'Save' : 'Create'}
                primaryClick={() => (props.isEdit ? updateTemplate() : createTemplate())}
                secondaryClick={() => {
                    props.onClose();
                }}
                isLoading={isLoading}
            />
        </SideModal>
    );
}

export default AdminFlowScriptTemplateModal;
