import React, { useState, useEffect } from 'react';
import Table from 'src/components/Table';
import Flex from 'src/components/Flex';
import Accordian from 'src/components/Accordion';
import { AVAILABLE_REPORTS } from 'common/lib/util/constants';
import { getReport } from 'src/api/reports';
import FacilityDropDown from 'src/components/FacilityDropDown';
import { ResponsiveBar } from '@nivo/bar';
import { ResponsivePie } from '@nivo/pie';
import * as variables from 'src/styles/variables';
import classNames from 'classnames';
import { format } from 'date-fns';
import * as utils from 'src/utils';
import styles from './styles.module.scss';
import Select from 'react-select';
import DatePicker from 'src/components/DatePicker';
import Icon from 'src/components/Icon';
import { ICONS, API_DATE_FORMAT } from 'src/utils/constants';
import { ANALYTICS_NAMES } from 'src/utils/analytics';
import { ToastStoreObject } from 'src/stores/ToastStore';
import LoadingIcon from 'src/components/LoadingIcon';

const BAR_COLORS = [variables.red, variables.blue, variables.lightRed, variables.lightBlue, variables.lightGrey];

function Reports() {
    const currentDate = new Date();
    const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
    const [facility, setFacility] = useState(1);
    const [currentReport, setCurrentReport] = useState(null);
    const [currentReportData, setCurrentReportData] = useState([]);
    const [currentView, setCurrentView] = useState({ label: 'Column', value: 'column' });
    const [startDate, setStartDate] = useState(format(firstDayOfMonth, API_DATE_FORMAT));
    const [endDate, setEndDate] = useState(format(lastDayOfMonth, API_DATE_FORMAT));
    const [isLoading, setIsLoading] = useState(false);
    const [isEmpty, setIsEmpty] = useState(false);

    useEffect(() => {
        if (currentReport) {
            handleReportClick(currentReport);
        }
    }, [facility, startDate, endDate]);

    async function handleReportClick(id: string) {
        try {
            setIsLoading(true);
            const reportData = await getReport(id, {
                facilityId: facility,
                fromDate: startDate,
                toDate: endDate,
            });
            if (reportData.length === 0) {
                setIsEmpty(true);
            } else {
                setIsEmpty(false);
            }
            setCurrentReportData(reportData);
            setCurrentReport(id);
            setIsLoading(false);
        } catch (e) {
            ToastStoreObject.show(utils.parseError(e));
            setIsLoading(false);
        }
    }

    function getMenu() {
        return AVAILABLE_REPORTS.map((reportCategory, categoryIndex) => {
            const subMenu = reportCategory.reports.map((report, reportIndex) => (
                <div
                    key={reportIndex}
                    className={classNames([styles.reportLink], {
                        [styles.reportLinkDisabled]: report.disabled,
                        [styles.active]: currentReport === report.id,
                    })}
                    onClick={() => handleReportClick(report.id)}
                >
                    {report.name}
                </div>
            ));

            return (
                <Accordian key={categoryIndex} label={reportCategory.name}>
                    <div className={styles.subMenu}>{subMenu}</div>
                </Accordian>
            );
        });
    }

    function getCategoryInfo() {
        return AVAILABLE_REPORTS.find((category) => category.reports.find((report) => report.id === currentReport));
    }

    function getReportInfo() {
        const category = getCategoryInfo();
        return category.reports.find((report) => report.id === currentReport);
    }

    function getColumns(): any[] {
        const COLUMN_NAMES = {
            facilityId: 'Facility',
            facilityName: 'Facility Name',
            patientRace: 'Race',
            patientCount: 'Count',
            patientGender: 'Gender',
            patientStateTitle: 'State',
            patientEthnicity: 'Ethnicity',
            patientNeedStateTitle: 'State',
            patientNeedCount: 'Count',
            patientNeedType: 'Type',
            patientNeedTypeCount: 'Count',
            medicationName: 'Name',
            medicationCount: 'Count',
            activeDuration: 'Duration',
            activeDurationSort: 'Sort',
            ageYearsSort: 'Sort',
            patientAgeYears: 'Age',
            taskType: 'Type',
            taskCount: 'Count',
            teamName: 'Name',
            eventCount: 'Count',
            visitType: 'Type',
            visitCount: 'Count',
            serviceRequestType: 'Service Request Type',
            incidentCount: 'Count',
            averageDurationMinutes: 'Minutes',
            averageResponseMinutes: 'Minutes',
            dispatchReason: 'Dispatch Reason',
            unitNumber: 'Unit Number',
            totalMiles: 'Miles',
            transported: 'Transported',
            incidentType: 'Incident Type',
        };

        const reportDataSet = currentReportData && currentReportData[0];

        return reportDataSet
            ? Object.keys(reportDataSet).map((key) => {
                  return Object.assign(
                      {
                          Header: (COLUMN_NAMES as any)[key] || key,
                          accessor: key,
                      },
                      key === 'facilityId' ? { maxWidth: 100 } : null,
                  );
              })
            : [];
    }

    function getViewOptions() {
        return getReportInfo().chartTypes.map((type) => {
            return { value: type, label: type.charAt(0).toUpperCase() + type.slice(1) };
        });
    }

    function getChartKeys() {
        const countKeys = [
            'patientCount',
            'patientNeedCount',
            'patientNeedTypeCount',
            'medicationCount',
            'taskCount',
            'eventCount',
            'visitCount',
            'incidentCount',
            'averageDurationMinutes',
            'averageResponseMinutes',
            'totalMiles',
        ];
        const fieldKeys = [
            'patientStateTitle',
            'patientRace',
            'patientEthnicity',
            'patientGender',
            'patientNeedStateTitle',
            'patientNeedType',
            'medicationName',
            'activeDuration',
            'patientAgeYears',
            'taskType',
            'teamName',
            'visitType',
            'serviceRequestType',
            'dispatchReason',
            'unitNumber',
            'transported',
            'incidentType',
        ];

        const currentKeys = Object.keys(currentReportData[0] || {});

        return {
            count: countKeys.find((key) => currentKeys.indexOf(key) > -1),
            field: fieldKeys.find((key) => currentKeys.indexOf(key) > -1),
        };
    }

    function getBarChartData() {
        const keysToUse = getChartKeys();

        return currentReportData.map((reportData, index) => {
            return {
                value: reportData[keysToUse.field],
                count: parseInt(reportData[keysToUse.count]),
                color: BAR_COLORS[index % 4],
            };
        });
    }

    function getPieChartData() {
        const keysToUse = getChartKeys();

        return currentReportData.map((reportData) => {
            return {
                id: reportData[keysToUse.field],
                label: reportData[keysToUse.field],
                value: parseInt(reportData[keysToUse.count]),
            };
        });
    }

    function getView() {
        if (isLoading) {
            return (
                <Flex align="center" justify="center">
                    <LoadingIcon />
                </Flex>
            );
        }

        if (isEmpty) {
            return (
                <Flex align="center" justify="center">
                    No report data found.
                </Flex>
            );
        }

        if (currentView.value === 'column') {
            const data = getBarChartData();
            return (
                <div className={styles.view} data-test-id={ANALYTICS_NAMES.Report_Column}>
                    <ResponsiveBar
                        data={data}
                        keys={['count']}
                        indexBy="value"
                        margin={{ top: 50, right: 60, bottom: 50, left: 60 }}
                        padding={0.3}
                        colors={[variables.blue]}
                        axisTop={null}
                        axisRight={null}
                        axisBottom={{
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                        }}
                        axisLeft={{
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                        }}
                        labelSkipWidth={12}
                        labelSkipHeight={12}
                        labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                        animate={true}
                        motionStiffness={90}
                        motionDamping={15}
                    />
                </div>
            );
        }

        if (currentView.value === 'pie') {
            const pieData = getPieChartData();

            return (
                <div className={styles.view} data-test-id={ANALYTICS_NAMES.Report_Pie}>
                    <ResponsivePie
                        data={pieData}
                        margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                        innerRadius={0.5}
                        padAngle={0.7}
                        cornerRadius={3}
                        colors={BAR_COLORS}
                        borderWidth={1}
                        borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
                        arcLabelsSkipAngle={10}
                        arcLinkLabelsTextOffset={6}
                        arcLabelsTextColor="#333333"
                        arcLinkLabelsOffset={0}
                        arcLinkLabelsDiagonalLength={16}
                        arcLinkLabelsThickness={1}
                        arcLinkLabelsColor={{ from: 'color' }}
                        arcLinkLabelsSkipAngle={10}
                        arcLinkLabelsTextColor="#333333"
                        legends={[
                            {
                                anchor: 'top-left',
                                direction: 'column',
                                translateY: 56,
                                itemWidth: 100,
                                itemHeight: 24,
                                itemTextColor: '#999',
                                symbolSize: 18,
                                symbolShape: 'circle',
                                effects: [
                                    {
                                        on: 'hover',
                                        style: {
                                            itemTextColor: '#000',
                                        },
                                    },
                                ],
                            },
                        ]}
                    />
                </div>
            );
        }

        return (
            <Table
                data-test-id={ANALYTICS_NAMES.Report_Table}
                isLoading={false}
                columns={getColumns()}
                data={currentReportData}
            />
        );
    }

    return (
        <div>
            <Flex direction="row">
                <Flex value={1} className={styles.leftSide} style={{ width: '20%' }}>
                    <div className={styles.facilitySelector}>
                        <FacilityDropDown selectedValue={facility} onChange={(data: any) => setFacility(data.value)} />
                    </div>
                    {getMenu()}
                </Flex>
                <Flex value={3} className={styles.rightSide} style={{ width: '75%' }}>
                    <div className={styles.reportContent}>
                        {currentReport ? (
                            <>
                                <Flex direction="row" className={styles.reportFilters} align="center">
                                    <Flex className={styles.viewDropdown}>
                                        <div>View: </div>
                                        <Select
                                            className={styles.filterDropdown}
                                            data-test-id={ANALYTICS_NAMES.Report_ViewSelector}
                                            value={currentView}
                                            options={getViewOptions()}
                                            onChange={(value: any) => setCurrentView(value)}
                                        />
                                    </Flex>
                                    {getReportInfo().requiredParams &&
                                    getReportInfo().requiredParams.includes('fromDate') ? (
                                        <Flex className={styles.dateDropdown}>
                                            <div className={styles.dateTitle}>Start Date: </div>
                                            <DatePicker
                                                onlyPast={true}
                                                value={startDate}
                                                onChange={(date: any) => {
                                                    if (date) {
                                                        const value = format(date, API_DATE_FORMAT);
                                                        setStartDate(value);
                                                    }
                                                }}
                                            />
                                        </Flex>
                                    ) : null}
                                    {getReportInfo().requiredParams &&
                                    getReportInfo().requiredParams.includes('toDate') ? (
                                        <Flex className={styles.dateDropdown}>
                                            <div className={styles.dateTitle}>End Date: </div>
                                            <DatePicker
                                                onlyPast={true}
                                                value={endDate}
                                                onChange={(date: any) => {
                                                    if (date) {
                                                        const value = format(date, API_DATE_FORMAT);
                                                        if (new Date(startDate) > new Date(value)) {
                                                            setEndDate(startDate);
                                                        } else {
                                                            setEndDate(value);
                                                        }
                                                    }
                                                }}
                                            />
                                        </Flex>
                                    ) : null}
                                    <Flex grow={1} justify="end" className={styles.currentReport}>
                                        {currentReport ? `${getCategoryInfo().name} - ${getReportInfo().name}` : null}
                                    </Flex>
                                </Flex>
                                {getView()}
                            </>
                        ) : (
                            <Flex align="center" justify="center" className={styles.empty}>
                                <Icon name={ICONS.NavigationMenu2} color={variables.grey} size={60} />
                                <div className={styles.emptyText}>Please select a report</div>
                            </Flex>
                        )}
                    </div>
                </Flex>
            </Flex>
        </div>
    );
}

export default Reports;
