import React, { useState, useContext } from 'react';
import styles from 'src/containers/Reports/Macra/styles.module.scss';
import Flex from 'src/components/Flex';
import CenterModal from 'src/components/CenterModal';
import ModalHeader from 'src/components/ModalHeader';
import Input from 'src/components/Input';
import ModalFooter from 'src/components/ModalFooter';
import { ToastStoreObject } from 'src/stores/ToastStore';
import * as utils from 'src/utils';
import QcdrSettingsStore, { ACTIVITIES_2023, ACTIVITIES_2024, Activity } from 'src/stores/QcdrSettingsStore';
import { MacraAvailableYears } from 'common/lib/model/macra/Macra';
import { observer } from 'mobx-react';

export interface SubmitToQcdrModalProps {
    year: MacraAvailableYears;
    onClose: Function;
    onSave: Function;
}

function SubmitToQcdrModal(props: SubmitToQcdrModalProps) {
    const [signature, setSignature] = useState(null);
    const qcdrSettingsStore = useContext(QcdrSettingsStore);
    const activities = props.year === 2023 ? ACTIVITIES_2023 : ACTIVITIES_2024;

    async function handleSubmit() {
        try {
            await qcdrSettingsStore.saveMacraSignature(props.year, signature);
            props.onSave();
            props.onClose();
        } catch (error) {
            ToastStoreObject.show(utils.parseError(error));
        }
    }

    function getSummary() {
        const summary = qcdrSettingsStore.reportingSettings
            ? qcdrSettingsStore.reportingSettings.map(
                  (setting) =>
                      `TIN: ${setting.tin} (${
                          setting.providers.filter((item) => item.enableReporting).length
                      } providers) - ${setting.reportingType === 'group' ? 'Group Reporting' : 'Individual Reporting'}`,
              )
            : [];
        return summary;
    }

    function getActivitySummary() {
        let activityMap = new Map([
            ['activity1', false],
            ['activity2', false],
            ['activity3', false],
            ['activity4', false],
            ['activity5', false],
            ['activity6', false],
            ['activity7', false],
            ['activity8', false],
            ['activity9', false],
            ['activity10', false],
            ['activity11', false],
            ['activity12', false],
            ['activity13', false],
            ['activity14', false],
            ['activity15', false],
            ['activity16', false],
            ['activity17', false],
            ['activity18', false],
            ['activity19', false],
            ['activity20', false],
            ['activity21', false],
        ]);

        if (qcdrSettingsStore.activitySettings) {
            qcdrSettingsStore.activitySettings.forEach((setting) => {
                if (setting.activity1 === true) activityMap.set(`activity1`, true);
                if (setting.activity2 === true) activityMap.set(`activity2`, true);
                if (setting.activity3 === true) activityMap.set(`activity3`, true);
                if (setting.activity4 === true) activityMap.set(`activity4`, true);
                if (setting.activity5 === true) activityMap.set(`activity5`, true);
                if (setting.activity6 === true) activityMap.set(`activity6`, true);
                if (setting.activity7 === true) activityMap.set(`activity7`, true);
                if (setting.activity8 === true) activityMap.set(`activity8`, true);
                if (setting.activity9 === true) activityMap.set(`activity9`, true);
                if (setting.activity10 === true) activityMap.set(`activity10`, true);
                if (setting.activity11 === true) activityMap.set(`activity11`, true);
                if (setting.activity12 === true) activityMap.set(`activity12`, true);
                if (setting.activity13 === true) activityMap.set(`activity13`, true);
                if (setting.activity14 === true) activityMap.set(`activity14`, true);
                if (setting.activity15 === true) activityMap.set(`activity15`, true);
                if (setting.activity16 === true) activityMap.set(`activity16`, true);
                if (setting.activity17 === true) activityMap.set(`activity17`, true);
                if (setting.activity18 === true) activityMap.set(`activity18`, true);
                if (setting.activity19 === true) activityMap.set(`activity19`, true);
                if (setting.activity20 === true) activityMap.set(`activity20`, true);
                if (setting.activity21 === true) activityMap.set(`activity21`, true);
            });
        }

        const activitySummary = activities.map((activity: Activity, index: number) => {
            if (activityMap.get(`activity${index}`) === true) {
                return activity.title;
            }
        });
        return activitySummary;
    }

    return (
        <CenterModal isOpen={true} onClose={() => props.onClose()}>
            <ModalHeader title={`Submit ${props.year} MACRA Data`} />
            <div className={styles.submitModalContent}>
                <Flex direction="row">
                    <Flex direction="column" value={1}>
                        <p>
                            <span className={styles.bold}>I.</span> The undersigned representative of Participant
                            represents and warrants that he or she is authorized to agree to the terms listed below on
                            behalf of the Participant and that all professional members of the group or the duly elected
                            or appointed decision-makers for the group have consented to the submission of {props.year}{' '}
                            MACRA Performance Data to ABG QCDR via Graphium Health.
                        </p>
                        <p>
                            <span className={styles.bold}>II.</span> Participant has reviewed, signed, and returned the
                            QCDR Agreement and the QCDR Power of Attorney.
                        </p>
                        <p>
                            <span className={styles.bold}>III.</span> Data received from participating providers are
                            true and accurate to the best of their knowledge.
                        </p>
                        <p>
                            <span className={styles.bold}>IV.</span> Participant represents and warrants that all
                            reporting Eligible Providers at each Facility of the Organization are assigned their correct
                            National Provider Identification (NPI), and furthermore, each of these Eligible Providers is
                            assigned the correct Tax Identification Number (TIN). Participant is aware that CMS assigns
                            penalties and bonuses to concatenated NPI + TIN numbers. Therefore, the TIN assigned in
                            Graphium MACRA Settings for each reporting Eligible Provider MUST be the same TIN used on
                            their Medicare Part B claims. Failure to correctly assign an Eligible Provider's combined
                            NPI + TIN could lead to penalties and loss of bonus.
                        </p>
                        <p>
                            <span className={styles.bold}>V.</span> Participant authorizes Graphium Health to act on
                            Participant's behalf in the selection of QCDR Measures and Improvement Activities, as well
                            as in the submission of outcome, process, denominator, and numerator data for Participant's
                            reporting Eligible Providers.
                        </p>
                        <p>
                            <span className={styles.bold}>VI.</span> A data validation plan is in place to ensure the
                            integrity of the measure outcomes submitted.
                        </p>
                        <p>
                            <span className={styles.bold}>VII.</span> Participant has reported on at least 70% of CMS
                            required eligible patients, from all payers, as required by CMS (§414.1340) for the 12-month
                            reporting period of calendar year {props.year}.
                        </p>
                        <p>
                            <span className={styles.bold}>VIII.</span> Participant represents and warrants that it has
                            taken all reasonable steps to avoid the submission of duplicative Performance Data to
                            Graphium Health.
                        </p>
                        <p>
                            <span className={styles.bold}>IX.</span> Participant hereby authorizes Graphium Health to
                            submit participating providers' data for the 12-month reporting period of calendar year{' '}
                            {props.year}
                            to ABG QCDR in order to submit to CMS for {props.year} MACRA Compliance.
                        </p>
                        <p className={styles.bold}>
                            By typing your name below you acknowledge agreement with these terms on behalf of your
                            Organization.
                        </p>
                        <Input
                            placeholder="Type full name e.g. John Smith"
                            value={signature}
                            onChangeText={(t: string) => {
                                setSignature(t);
                            }}
                        />
                    </Flex>
                    <Flex value={0.1}></Flex>
                    <Flex direction="column" value={0.4}>
                        <h2>Summary</h2>
                        <div style={{ whiteSpace: 'normal' }}>
                            <ul>
                                {getSummary().map((item, index) => (
                                    <li key={index}>{item}</li>
                                ))}
                            </ul>
                        </div>
                        <h2>Activities</h2>
                        <div style={{ whiteSpace: 'normal' }}>
                            <ul>
                                {getActivitySummary().map((item, index) => (
                                    <li key={index}>{item}</li>
                                ))}
                            </ul>
                        </div>
                    </Flex>
                </Flex>
            </div>
            <ModalFooter
                primaryText="Submit"
                primaryClick={handleSubmit}
                primaryProps={{
                    disabled: signature === null || signature === '',
                }}
                secondaryClick={() => {
                    props.onClose();
                }}
            />
        </CenterModal>
    );
}

export default observer(SubmitToQcdrModal);
