import React from 'react';
import styles from './styles.module.scss';
import Flex from 'src/components/Flex';
import CenterModal from 'src/components/CenterModal';
import ModalHeader from 'src/components/ModalHeader';
import ModalFooter from 'src/components/ModalFooter';

interface ConfirmationModalProps {
    headerTitle?: string;
    title?: string;
    onConfirm: Function;
    onCancel: Function;
    confirmButtonText?: string;
    cancelButtonText?: string;
    isLoading?: boolean;
}

const ConfirmationModal = (props: ConfirmationModalProps) => (
    <CenterModal isOpen={true} onClose={props.onCancel}>
        <div className={styles.idleModal}>
            <ModalHeader title={props.headerTitle ? props.headerTitle : 'Are you sure?'} />
            <Flex direction="column" className={styles.modalContent} align="center" self="stretch">
                <Flex value={1} align="center" justify="center">
                    <p>{props.title ? props.title : 'Are you sure you would like to do this?'}</p>
                </Flex>
            </Flex>
            <ModalFooter
                primaryText={props.confirmButtonText ? props.confirmButtonText : 'Confirm'}
                primaryClick={props.onConfirm}
                secondaryText={props.cancelButtonText ? props.cancelButtonText : 'Cancel'}
                secondaryClick={props.onCancel}
                isLoading={props.isLoading}
            />
        </div>
    </CenterModal>
);

export default ConfirmationModal;
