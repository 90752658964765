import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styles from 'src/containers/Reports/Macra/styles.module.scss';
import { observer } from 'mobx-react';
import { ROUTES } from 'src/utils/constants';
import MacraStore from 'src/stores/MacraStore';
import QcdrSettingsStore from 'src/stores/QcdrSettingsStore';
import ScorecardStore from 'src/stores/ScorecardStore';
import FeatureFlagStore from 'src/stores/FeatureFlagStore';
import PageContent from 'src/components/PageContent';
import MacraTitleBar from 'src/containers/Reports/Macra/MacraTitleBar';
import MacraControls from 'src/containers/Reports/Macra/MacraControls';
import MacraComplianceOverview from 'src/containers/Reports/Macra/MacraComplianceOverview';
import MacraMeasures from 'src/containers/Reports/Macra/MacraMeasures';
import MacraComplianceDetails from 'src/containers/Reports/Macra/MacraComplianceDetails';

function Macra2024() {
    const macraStore = useContext(MacraStore);
    const qcdrSettingsStore = useContext(QcdrSettingsStore);
    const scorecardStore = useContext(ScorecardStore);
    const featureFlagStore = useContext(FeatureFlagStore);
    const history = useHistory();

    const MACRA_YEAR = 2024;

    if (!featureFlagStore.isEnabled('macra-2024')) {
        history.push(ROUTES.getString(ROUTES.ReportsMacra2023));
    }

    useEffect(() => {
        macraStore.setMacraYear(MACRA_YEAR);
        macraStore.getMacraData();
        qcdrSettingsStore.getQcdrSettings(MACRA_YEAR);
    }, []);

    useEffect(() => {
        scorecardStore.getScorecardOrgMetrics(
            macraStore.selectedId,
            macraStore.startDate,
            macraStore.endDate,
            macraStore.currentIdMode,
        );
    }, [macraStore.selectedId, macraStore.startDate, macraStore.endDate, macraStore.currentIdMode]);

    return (
        <PageContent>
            <div className={styles.macraReports}>
                <MacraTitleBar year={MACRA_YEAR} />
                <MacraControls year={MACRA_YEAR} />
                <MacraComplianceOverview />
                <MacraMeasures />
                <MacraComplianceDetails />
            </div>
        </PageContent>
    );
}

export default observer(Macra2024);
