import React, { forwardRef } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

export interface TagProps extends React.HTMLAttributes<HTMLSpanElement> {
    color: 'red' | 'blue' | 'green' | 'alert' | 'white';
    text: string;
}

const Tag = forwardRef(({ className, color, text, ...rest }: TagProps, ref: React.Ref<HTMLSpanElement>) => (
    <span
        {...rest}
        ref={ref}
        className={classNames(styles.tag, className, {
            [styles.red]: color === 'red',
            [styles.blue]: color === 'blue',
            [styles.green]: color === 'green',
            [styles.white]: color === 'white',
            [styles.alert]: color === 'alert',
        })}
    >
        {text.toUpperCase()}
    </span>
));
Tag.displayName = 'Tag';

export default Tag;
