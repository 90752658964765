import * as ROUTES from 'src/api/routes';
import request from 'src/api/utils';
import { Device } from 'src/stores/DeviceStore';

export async function getDevices() {
    return await request({ ...ROUTES.GET_DEVICES });
}

export async function createDevice(data: Device) {
    return await request({ ...ROUTES.CREATE_DEVICE, data });
}

export async function updateDevice(data: Device) {
    return await request({ ...ROUTES.UPDATE_DEVICE, pathParams: { deviceId: data.deviceId }, data });
}
