import React, { useContext, useEffect, useState } from 'react';
import styles from './styles.module.scss';
import Input, { Label } from 'src/components/Input';
import { PaymentGateway, PaymentGatewayType } from 'src/stores/PaymentsStore';
import SideModal from 'src/components/SideModal';
import ModalHeader from 'src/components/ModalHeader';
import ModalFooter from 'src/components/ModalFooter';
import Checkbox from 'src/components/Checkbox';
import Flex from 'src/components/Flex';
import { ANALYTICS_NAMES } from 'src/utils/analytics';
import PaymentGatewayTypeDropDown from 'src/components/PaymentGatewayTypeDropDown';
import PaymentsStore from 'src/stores/PaymentsStore';
import { ToastStoreObject } from 'src/stores/ToastStore';
import { parseError } from 'src/utils';
import { observer } from 'mobx-react';

interface PaymentGatewayModalProps {
    paymentGateway?: PaymentGateway;
    mode?: string;
    onClose?: Function;
    onSave?: Function;
}

const VALID_GATEWAY_CONFIG_KEYS = ['apiKey', 'secretKey', 'testMode'];

function PaymentGatewayModal(props: PaymentGatewayModalProps) {
    const DEFAULT_PAYMENT_GATEWAY_CONFIG = {
        apiKey: '',
        secretKey: '',
        testMode: false,
    };

    const paymentsStore = useContext(PaymentsStore);
    const [isLoading, setIsLoading] = useState(false);
    const [currentFocus, setCurrentFocus] = useState(null);
    const [title, setTitle] = useState<PaymentGateway['paymentGatewayTitle']>(
        props.paymentGateway ? props.paymentGateway.paymentGatewayTitle : '',
    );
    const [description, setDescription] = useState<PaymentGateway['paymentGatewayDescription']>(
        props.paymentGateway ? props.paymentGateway.paymentGatewayDescription : '',
    );
    const [gatewayType, setGatewayType] = useState<PaymentGateway['paymentGatewayTypeId']>(
        props.paymentGateway ? props.paymentGateway.paymentGatewayTypeId : null,
    );
    const [gatewayConfig, setGatewayConfig] = useState<PaymentGateway['paymentGatewayConfig']>(
        props.paymentGateway && props.mode === 'edit'
            ? props.paymentGateway.paymentGatewayConfig
            : DEFAULT_PAYMENT_GATEWAY_CONFIG,
    );

    async function handleSave() {
        setIsLoading(true);

        try {
            if (props.mode === 'edit') {
                await paymentsStore.updatePaymentGateway(props.paymentGateway.paymentGatewayId, {
                    paymentGatewayTitle: title,
                    paymentGatewayDescription: description,
                    paymentGatewayTypeId: gatewayType,
                    paymentGatewayConfig: gatewayConfig,
                });
            } else {
                await paymentsStore.createPaymentGateway({
                    paymentGatewayTitle: title,
                    paymentGatewayDescription: description,
                    paymentGatewayTypeId: gatewayType,
                    paymentGatewayConfig: gatewayConfig,
                });
            }
        } catch (e: any) {
            ToastStoreObject.show(parseError(e));
        } finally {
            if (props.onSave) {
                props.onSave();
            }
            if (props.onClose) {
                props.onClose();
            }
            setIsLoading(false);
        }
    }

    function setConfigValue(key: string, value: string | boolean) {
        if (key && value !== undefined) {
            if (!VALID_GATEWAY_CONFIG_KEYS.includes(key)) {
                return;
            }

            const updatedConfig = JSON.parse(
                JSON.stringify(
                    Object.assign(gatewayConfig, {
                        [key]: value,
                    }),
                ),
            );

            setGatewayConfig(updatedConfig);
        }
    }

    function getConfigInputs() {
        if (!gatewayType) {
            return;
        }

        const currentGatewayType = paymentsStore.paymentGatewayTypes.find(
            (pgt: PaymentGatewayType) => pgt.paymentGatewayTypeId === gatewayType,
        );

        switch (currentGatewayType.paymentGatewayTypeCode) {
            case 'STRIPE':
                return (
                    <>
                        <Flex align="center">
                            <Input
                                label="Publishable Key"
                                value={gatewayConfig.apiKey}
                                onFocus={() => setCurrentFocus('publishableKey')}
                                onBlur={() => setCurrentFocus(null)}
                                type={currentFocus === 'publishableKey' ? 'input' : 'password'}
                                onChangeText={(v: string) => setConfigValue('apiKey', v)}
                                data-test-id={ANALYTICS_NAMES.PaymentGatewayModal_StripeApiKey}
                            />
                            <div className={styles.apiTestMode}>
                                <Checkbox
                                    label="Test Mode?"
                                    checked={gatewayConfig.testMode}
                                    onChange={(e) => setConfigValue('testMode', e.target.checked)}
                                    data-test-id={ANALYTICS_NAMES.PaymentGatewayModal_StripeTestMode}
                                />
                            </div>
                        </Flex>
                        <Input
                            label="Secret Key"
                            value={gatewayConfig.secretKey}
                            onFocus={() => setCurrentFocus('secretKey')}
                            onBlur={() => setCurrentFocus(null)}
                            type={currentFocus === 'secretKey' ? 'input' : 'password'}
                            onChangeText={(v: string) => setConfigValue('secretKey', v)}
                            data-test-id={ANALYTICS_NAMES.PaymentGatewayModal_StripeSecretKey}
                        />
                    </>
                );
            default:
                return;
        }
    }

    return (
        <SideModal isOpen={true} onClose={props.onClose}>
            <ModalHeader
                title={`${props.mode === 'edit' ? 'Edit' : 'Create'} Payment Gateway`}
                onClose={props.onClose}
            />
            <div className={styles.contentWrap}>
                <Input
                    label="Title"
                    value={title}
                    onChangeText={(v: string) => setTitle(v)}
                    data-test-id={ANALYTICS_NAMES.PaymentGatewayModal_Title}
                />
                <Input
                    label="Description"
                    value={description}
                    onChangeText={(v: string) => setDescription(v)}
                    data-test-id={ANALYTICS_NAMES.PaymentGatewayModal_Description}
                />
                <>
                    <Label text="Type" />
                    <PaymentGatewayTypeDropDown
                        onChange={(pg: any) => setGatewayType(pg.value)}
                        selectedValue={gatewayType}
                        data-test-id={ANALYTICS_NAMES.PaymentGatewayModal_Type}
                    />
                </>
                {getConfigInputs()}
            </div>
            <ModalFooter
                primaryText={props.mode === 'edit' ? 'Save' : 'Create'}
                primaryClick={handleSave}
                secondaryClick={() => props.onClose()}
                isLoading={isLoading}
            />
        </SideModal>
    );
}

export default observer(PaymentGatewayModal);
