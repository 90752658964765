import React, { useState, useEffect, useContext } from 'react';
import styles from './styles.module.scss';
import Select from 'react-select';
import PatientStateStore from 'src/stores/PatientStateStore';
import { observer } from 'mobx-react';
import * as utils from 'src/utils';
import useIsOptionsLoading from 'src/utils/hooks/useIsOptionsLoading';

export interface PatientStateDropDownProps {
    onChange: Function;
    selected?: number;
    allowEmpty?: boolean;
    selectedValue?: any;
    defaultValue?: any;
    isMulti?: boolean;
    isNormalColors?: boolean;
    placeholder?: string;
    isDisabled?: boolean;
    onBeforeChange?: Function;
}

function PatientStateDropDown(props: PatientStateDropDownProps) {
    const patientStateStore = useContext(PatientStateStore);
    const [selected, setSelected] = useState(null);

    const options = patientStateStore.patientStatesDropdownValues;
    const isLoading = useIsOptionsLoading(options);

    useEffect(() => {
        patientStateStore.getPatientStates();
    }, []);
    useEffect(() => {
        let selectedVal;
        let selectedVals;
        if (props.isMulti && utils.isArray(props.selectedValue)) {
            selectedVals = props.selectedValue.map((v: any) => options.find((i) => i.value === v));
            setSelected(selectedVals);
        } else {
            selectedVal = options.find((i) => i.value === props.selectedValue);
            if (selectedVal) {
                if (props.isMulti && !utils.isArray(selectedVal)) {
                    selectedVal = [selectedVal];
                }
                setSelected(selectedVal);
            }
        }
    }, [props.selectedValue, options]);

    useEffect(() => {
        if (!props.defaultValue) return;
        let selectedVal;
        selectedVal = options.find((i) => i.value === props.defaultValue);
        if (selectedVal) {
            if (props.isMulti && !utils.isArray(selectedVal)) {
                selectedVal = [selectedVal];
            }
            setSelected(selectedVal);
            props.onChange(selectedVal);
        }
    }, [props.defaultValue, options]);

    function getColor(selected: any) {
        if (!selected) return '#24a7e7';
        if (selected.value === 1) {
            return '#ffbb42';
        } else if (selected.value === 2) {
            return '#ff1111';
        } else if (selected.value === 3) {
            return '#ff1111';
        } else if (selected.value === 4) {
            return '#5abc86';
        } else if (selected.value === 5) {
            return '#ff1111';
        } else if (selected.value === 6) {
            return '#ff1111';
        } else if (selected.value === 7) {
            return '#24a7e7';
        } else {
            return '#5abc86';
        }
    }

    function hexToRgbA(hex: string) {
        let c: any;
        const opacity = 0.1;
        if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
            c = hex.substring(1).split('');
            if (c.length == 3) {
                c = [c[0], c[0], c[1], c[1], c[2], c[2]];
            }
            c = '0x' + c.join('');
            return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + `,${opacity})`;
        }
    }

    const selectProps: any = {
        value: props.selected ? props.selected : selected,
        onChange: async (s: any) => {
            if (props.onBeforeChange) {
                const shouldChange = await props.onBeforeChange(s);
                if (shouldChange) {
                    setSelected(s);
                    props.onChange(s || (props.isMulti ? [] : null));
                }
            } else {
                setSelected(s);
                props.onChange(s || (props.isMulti ? [] : null));
            }
        },
        isLoading,
        isDisabled: props.isDisabled,
        isClearable: props.allowEmpty,
        className: styles.select,
        options: options,
        placeholder: props.placeholder ? props.placeholder : 'Select Status',
        onMenuOpen: () => {
            patientStateStore.getPatientStates();
        },
        isMulti: props.isMulti,
        closeMenuOnSelect: true,
        styles: {
            ...utils.styleSelectComponent(),
            dropdownIndicator: (provided: any) => {
                const color = getColor(selected);
                if (!props.isMulti && !props.isNormalColors) {
                    return { ...provided, color };
                } else {
                    return { ...provided, color: '#182c49', fontSize: 12, marginLeft: 0 };
                }
            },
            control: (provided: any) => {
                const backgroundColor = hexToRgbA(getColor(selected));

                if (!props.isMulti && !props.isNormalColors) {
                    return { ...provided, borderWidth: 0, backgroundColor };
                } else {
                    return { ...provided, borderColor: '#f1f0f0', paddingTop: 2, paddingBottom: 2 };
                }
            },
            singleValue: (provided: any) => {
                const color = getColor(selected);
                if (!props.isMulti && !props.isNormalColors) {
                    return { ...provided, color, fontWeight: 500, fontSize: 14 };
                } else {
                    return { ...provided, fontSize: 14, color: '#182c49', fontWeight: 500 };
                }
            },
        },
    };

    if (!props.isMulti && !props.isNormalColors) {
        selectProps.menuPortalTarget = document.body;
    }

    return <Select {...selectProps} />;
}

export default observer(PatientStateDropDown);
