import React, { useState } from 'react';
import { observer } from 'mobx-react';

import PageContainer from 'src/components/PageContainer';
import Flex from 'src/components/Flex';
import Button from 'src/components/Button';
import * as passwordService from 'src/api/passwords';
import { ToastStoreObject } from 'src/stores/ToastStore';
import { parseError } from 'src/utils';

import styles from './styles.module.scss';
import Input from 'src/components/Input';
import { ANALYTICS_NAMES } from 'src/utils/analytics';

function ForgotPassword() {
    const [username, setUsername] = useState('');
    const [requestSent, setRequestSent] = useState(false);
    const [submitting, setSubmitting] = useState(false); // to disable button when reset password key requested

    const handleRequestClick = async () => {
        setSubmitting(true);
        try {
            await passwordService.requestPasswordResetByUserName(username);
            setRequestSent(true);
        } catch (e) {
            ToastStoreObject.show(parseError(e));
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <PageContainer allowScaling={true}>
            <div className={styles.header}></div>
            <Flex
                direction="column"
                self="stretch"
                value={1}
                align="center"
                justify="center"
                className={styles.loginWrap}
            >
                <div className={styles.heading}>Forgot Password</div>

                {!requestSent && (
                    <>
                        <div className={styles.subHeading}>
                            To request a password reset, please enter your username below.
                        </div>
                        <Input
                            className={styles.input}
                            label="Username"
                            value={username}
                            onChangeText={(t: string) => setUsername(t)}
                        />

                        <Flex self="stretch">
                            <Flex value={1} className={styles.rightButton}>
                                <Button
                                    data-test-id={ANALYTICS_NAMES.ForgotPassword_Reset}
                                    onClick={handleRequestClick}
                                    type="primary"
                                    text="Request Reset"
                                    className={styles.submitButton}
                                    disabled={username.trim() === '' || submitting}
                                />
                            </Flex>
                        </Flex>
                    </>
                )}
                {requestSent && (
                    <div className={styles.subHeading}>
                        Password reset request received. If the user exists, an email will be sent. Please check your
                        email for password reset instructions.
                    </div>
                )}
            </Flex>
        </PageContainer>
    );
}

export default observer(ForgotPassword);
