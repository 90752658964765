import { UseMutationResult, useMutation, useQueryClient } from 'react-query';
import { updateFormAsset } from 'src/api/formAssets';
import { FormAsset } from 'common/lib/entity/framework/FormAsset';
import { formAssetsKey } from './useFormAssets';
import { formAssetKey } from './useFormAsset';

export const updateFormAssetKey = 'updateFormAsset';

export interface UseUpdateFormAssetInputVariables {
    formAssetId: FormAsset['formAssetId'];
    formAssetName?: FormAsset['formAssetName'];
    formAssetDescription?: FormAsset['formAssetDescription'];
    formAssetContent?: FormAsset['formAssetContent'];
    isGlobal?: FormAsset['isGlobal'];
    orgNameInternal?: FormAsset['orgNameInternal'];
    facilityId?: FormAsset['facilityId'];
}

export const useUpdateFormAsset = (): UseMutationResult<void, Error, UseUpdateFormAssetInputVariables, unknown> => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationKey: [updateFormAssetKey],
        mutationFn: async (variables) => {
            const {
                formAssetId,
                formAssetDescription,
                formAssetContent,
            } = variables;

            if (!formAssetId) {
                throw new Error('Form asset id is required');
            }

            return await updateFormAsset(formAssetId, {
                formAssetId,
                formAssetDescription,
                formAssetContent,
            });
        },
        retry: false,
        onSuccess: async (data) => {
            queryClient.invalidateQueries({ queryKey: [formAssetKey] });
            queryClient.invalidateQueries({ queryKey: [formAssetsKey] });

            return data;
        },
    });
};
