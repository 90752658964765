import { UserStoreObject } from 'src/stores/UserStore';
import { FieldTypes } from 'common/lib/model/surveys/Survey';

export const EventClassCode = { TASK: 'TASK', APPOINTMENT: 'APPOINTMENT' };

export const DISPLAY_DATE_FORMAT = 'M/d/yyyy';
export const DISPLAY_TIME_FORMAT = 'h:mma';
export const API_DATE_FORMAT = 'yyyy-MM-dd';
export const APPLICATION_NAME = 'FieldMed';

export const ROUTES = {
    Home: '/',
    AdminAllUsers: '/admin/all-users',
    AdminFlowScriptTemplates: '/admin/flow-script-templates',
    AdminFlowScriptTemplateDetail: '/admin/flow-script-templates/:flowScriptTemplateGuid',
    AdminImportBatchTemplates: '/admin/import-batch-templates',
    AdminSurveyTemplates: '/admin/survey-templates',
    AdminScheduledJobs: '/admin/scheduled-jobs',
    AdminScheduledJobsDetail: '/admin/scheduled-jobs/:scheduledJobGuid',
    AdvancedAnalyticsDev: 'https://analytics.dev.graphiumemr.com/',
    AdvancedAnalyticsProd: 'https://analytics.graphiumemr.com/',
    Customers: '/customers',
    CustomerDetail: '/customers/:customerId',
    ForgotPassword: '/forgotpassword',
    Health: '/ping',
    Help: 'https://help.graphiumhealth.com/',
    Import: '/:orgnm/import',
    ImportRecord: '/:orgnm/import/record/:recordId',
    ImportDetail: '/:orgnm/import/:importId',
    Invitation: '/invitation',
    Reset: '/reset',
    Login: '/login',
    Profile: '/:orgnm/profile',
    ReportsChp: '/:orgnm/reports/chp',
    ReportsMacra: '/:orgnm/reports/macra',
    ReportsMacra2023: '/:orgnm/reports/macra/2023',
    QcdrSettings: '/:orgnm/settings/qcdr',
    QcdrSettings2023: '/:orgnm/settings/qcdr/2023',
    SettingsUsers: '/:orgnm/settings/users',
    SettingsProviders: '/:orgnm/settings/providers',
    SettingsTags: '/:orgnm/settings/tags',
    SettingsTeams: '/:orgnm/settings/teams',
    SettingsFacilities: '/:orgnm/settings/facilities',
    SettingsNemsis: '/:orgnm/settings/demographic',
    SettingsDevices: '/:orgnm/settings/devices',
    SettingsVehicles: '/:orgnm/settings/vehicles',
    SettingsTransportFacilities: '/:orgnm/settings/transport-facilities',
    SettingsRefListDetails: '/:orgnm/settings/reference-lists/:referenceListId',
    SettingsRefLists: '/:orgnm/settings/reference-lists',
    SettingsValueSetDetails: '/:orgnm/settings/value-sets/:id',
    Surveys: '/:orgnm/survey-results',
    SurveyDefinitions: '/:orgnm/survey-definitions',
    SurveyDefinitionDetails: '/:orgnm/survey-definitions/:surveyDefinitionId',
    TelehealthVisits: '/:orgnm/telehealth/visits',
    TelehealthWaitingRoom: '/:orgnm/telehealth/waitingroom',
    TelehealthSession: '/:orgnm/telehealth/visit/:telehealthSessionId',
    PatientVideo: '/:orgnm/patient-telehealth',
    Appointments: '/:orgnm/appointments',
    Patients: '/:orgnm/patients/view',
    Forms: '/:orgnm/patients/forms',
    ViewEncounterForm: '/:orgnm/patients/forms/:facId/:encounterFormId',
    Encounters: '/:orgnm/patients/encounters',
    PatientDetails: '/:orgnm/patients/patient/:patientId',
    PatientSurvey: '/survey',
    PatientSurveyUnsubscribe: '/survey/unsubscribe',
    PayerCategories: '/:orgnm/payers/categories',
    Payers: '/:orgnm/payers',
    PaymentCollection: '/payment/:paymentRequestContactGuid',
    PaymentCollectionStatus: '/payment/:paymentRequestContactGuid/status',
    PaymentGateways: '/:orgnm/payment-gateways',
    PaymentRequests: '/:orgnm/payment-requests',
    PaymentRequestDetail: '/:orgnm/payment-requests/:paymentRequestId',
    PaymentRequestImport: '/:orgnm/payment-requests/import',
    PaymentTypes: '/:orgnm/payment-types',
    PaymentRequestContactUnsubscribe: '/payment/unsubscribe',
    VitalsConnections: '/:orgnm/vitals-connections',
    AutomatedDispenseSystems: '/:orgnm/ads',
    FormDevelopment: '/form-development',
    FormDevelopmentFormDetail: '/form-development/form/:formCatalogId',
    FormDevelopmentModelDetail: '/form-development/model/:formModelId',
    FormDevelopmentDeploymentDetail: '/form-development/form/:formCatalogId/deploy/:formFacilityMappingId',
    getString(r: string, id?: string | number, more?: { [key in string]: string | number }) {
        let str = r;
        if (str.includes('/:orgnm')) {
            str = str.replace(':orgnm', UserStoreObject.selectedOrg.orgNmIntrnl);
        }
        if (id) {
            str = str
                .split('/')
                .map((s) => (s.includes(':') ? `${id}` : s))
                .join('/');
        }
        if (more) {
            str = str
                .split('/')
                .map((s) => (s.includes(':') && more[s.substr(1)] ? `${more[s.substr(1)]}` : s))
                .join('/');
        }
        return str;
    },
};

export const ATTACHMENT_MIME_TYPES = '.xlsx,.xls,image/*,.doc,.docx,.ppt,.pptx,.txt,.pdf,.csv,.rtf';

export const ICONS = {
    Appointment: 'Appointment',
    AppWindowUser: 'AppWindowUser',
    Arrow: 'Arrow',
    ArrowSide: 'ArrowSide',
    Assign: 'Assign',
    AttachmentIcon: 'AttachmentIcon',
    Attachment: 'Attachment',
    Back: 'Back',
    Bell: 'Bell',
    Bin: 'Bin',
    Calendar: 'Calendar',
    CalendarCheck: 'CalendarCheck',
    Check: 'Check',
    Check1: 'Check1',
    CheckCircle: 'CheckCircle',
    Close: 'Close',
    CloseCopy: 'CloseCopy',
    Cog3: 'Cog3',
    Details: 'Details',
    DotMenu: 'DotMenu',
    Download: 'Download',
    Dropdown: 'Dropdown',
    Due: 'Due',
    Edit: 'Edit',
    FilterArrow: 'FilterArrow',
    FontSize: 'FontSize',
    GraphStatsCircle: 'GraphStatsCircle',
    IconspaceHospital: 'IconspaceHospital',
    LaptopHeart: 'LaptopHeart',
    LightModeNight: 'LightModeNight',
    Link: 'Link',
    Location: 'Location',
    LoveItSearch: 'LoveItSearch',
    MapMarker: 'MapMarker',
    MobilePhone2: 'MobilePhone2',
    MultipleUsersAdd: 'MultipleUsersAdd',
    NavigationMenu2: 'NavigationMenu2',
    NavigationNextAlternate: 'NavigationNextAlternate',
    NavigationPayment: 'NavigationPayment',
    Need: 'Need',
    NewWindow: 'NewWindow',
    Note: 'Note',
    NotesBookText: 'NotesBookText',
    Pagination: 'Pagination',
    PatientVisit: 'PatientVisit',
    Pencil2: 'Pencil2',
    PictureStackHuman: 'PictureStackHuman',
    Pin: 'Pin',
    PlusButton: 'PlusButton',
    Search: 'Search',
    Shape: 'Shape',
    SidebarInfo: 'SidebarInfo',
    SingleManCircle: 'SingleManCircle',
    SingleNeutralActionsAddress: 'SingleNeutralActionsAddress',
    SynchronizeArrow1: 'SynchronizeArrow1',
    TableDropdown: 'TableDropdown',
    Task: 'Task',
    TaskText: 'TaskText',
    TimeClockCircle1Alternate: 'TimeClockCircle1Alternate',
    ToolBox: 'ToolBox',
    UpButton: 'UpButton',
    UrgencyUrgent: 'UrgencyUrgent',
    UrgencyHigh: 'UrgencyHigh',
    UrgencyMedium: 'UrgencyMedium',
    UrgencyLow: 'UrgencyLow',
    Visit: 'Visit',
    Standing: 'Standing',
    Sitting: 'Sitting',
    Laying: 'Laying',
};

export const MACRA_SURVEY_SCHEMA = {
    fields: [
        {
            id: 1,
            name: 'overallPatientSatisfaction',
            title: 'I was satisfied with my overall anesthesia experience. (1-5)',
            type: FieldTypes.SCALE,
            options: [
                { value: 1, label: 'Disagree very much (1)' },
                { value: 2, label: 'Disagree slightly (2)' },
                { value: 3, label: 'Neutral (3)' },
                { value: 4, label: 'Agree slightly (4)' },
                { value: 5, label: 'Agree very much (5)' },
            ],
        },
        {
            id: 2,
            name: 'questionsPriorAnes',
            title: 'Before anesthesia, I was able to ask the anesthesia practitioner the questions I wanted. (1-5)',
            type: FieldTypes.SCALE,
            options: [
                { value: 1, label: 'Disagree very much (1)' },
                { value: 2, label: 'Disagree slightly (2)' },
                { value: 3, label: 'Neutral (3)' },
                { value: 4, label: 'Agree slightly (4)' },
                { value: 5, label: 'Agree very much (5)' },
            ],
        },
        {
            id: 3,
            name: 'understandableAnesInfo',
            title: 'The information given to me by the anesthesia practitioners was understandable. (1-5)',
            type: FieldTypes.SCALE,
            options: [
                { value: 1, label: 'Disagree very much (1)' },
                { value: 2, label: 'Disagree slightly (2)' },
                { value: 3, label: 'Neutral (3)' },
                { value: 4, label: 'Agree slightly (4)' },
                { value: 5, label: 'Agree very much (5)' },
            ],
        },
        {
            id: 4,
            name: 'afterPainMngmt',
            title: 'After anesthesia, I was satisfied with my pain management. (1-5)',
            type: FieldTypes.SCALE,
            options: [
                { value: 1, label: 'Disagree very much (1)' },
                { value: 2, label: 'Disagree slightly (2)' },
                { value: 3, label: 'Neutral (3)' },
                { value: 4, label: 'Agree slightly (4)' },
                { value: 5, label: 'Agree very much (5)' },
            ],
        },
        {
            id: 5,
            name: 'afterNauseaVomit',
            title: 'After anesthesia, I would rate my nausea and vomiting as well controlled.  (1-5)',
            type: FieldTypes.SCALE,
            options: [
                { value: 1, label: 'Disagree very much (1)' },
                { value: 2, label: 'Disagree slightly (2)' },
                { value: 3, label: 'Neutral (3)' },
                { value: 4, label: 'Agree slightly (4)' },
                { value: 5, label: 'Agree very much (5)' },
            ],
        },
        {
            id: 6,
            name: 'dschInstructions',
            title: 'The anesthesia discharge instructions provided to me were easy to understand and follow. (1-5)',
            type: FieldTypes.SCALE,
            options: [
                { value: 1, label: 'Disagree very much (1)' },
                { value: 2, label: 'Disagree slightly (2)' },
                { value: 3, label: 'Neutral (3)' },
                { value: 4, label: 'Agree slightly (4)' },
                { value: 5, label: 'Agree very much (5)' },
            ],
        },
        {
            id: 7,
            name: 'transferredInd',
            title: 'Were you transferred to a different facility after discharge?',
            type: FieldTypes.SCALE,
            options: [
                { value: 1, label: 'Yes' },
                { value: 2, label: 'No' },
            ],
        },
    ],
};
