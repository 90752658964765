import React from 'react';

const SvgAppWindowUser = (props) => (
    <svg width={16} height={16} {...props} viewBox="0 0 16 16">
        <g
            transform="translate(1 1)"
            stroke={props.color || '#FFF'}
            fill="none"
            fillRule="evenodd"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path
                d="M6.708 11.958h-5.25a1.167 1.167 0 01-1.166-1.166V1.458C.292.814.814.292 1.458.292h11.084c.644 0 1.166.522 1.166 1.166v4.084"
                strokeWidth={1.2}
            />
            <path d="M2.333 1.607a.146.146 0 110 .291.146.146 0 010-.291" strokeWidth={0.6} />
            <path d="M.292 3.21h13.416" strokeWidth={1.2} />
            <path
                d="M4.083 1.607a.146.146 0 110 .291.146.146 0 010-.291M5.833 1.607a.146.146 0 11-.146.143c0-.08.066-.146.146-.146"
                strokeWidth={0.6}
            />
            <path d="M13.708 13.708a2.917 2.917 0 10-5.833 0h5.833z" strokeWidth={1.2} />
            <circle strokeWidth={1.2} cx={10.792} cy={8.169} r={1.75} />
            <path d="M4.083 5.544H7M4.083 8.169h1.75" strokeWidth={1.2} />
            <path
                d="M2.333 5.398a.146.146 0 110 .292.146.146 0 010-.292M2.333 8.023a.146.146 0 110 .292.146.146 0 010-.292"
                strokeWidth={0.6}
            />
        </g>
    </svg>
);

export default SvgAppWindowUser;
