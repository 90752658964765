import React, { useState } from 'react';
import styles from './styles.module.scss';
import Input, { Label } from 'src/components/Input';
import Checkbox from 'src/components/Checkbox';
import { useEffect } from 'react';
import { ReferenceListValue, ReferenceList } from 'src/stores/ReferenceListStore';

interface Props {
    referenceList: ReferenceList;
    referenceListValue?: ReferenceListValue;
    mode: string;
    onChange: Function;
}

function ReferenceListValueModal(props: Props) {
    const [valueLabel, setValueLabel] = useState(props.referenceListValue.listValueDisplayName || '');
    const [valueCode, setValueCode] = useState(props.referenceListValue.listValueCode || '');
    const [valueDescription, setValueDescription] = useState(props.referenceListValue.listValueDescription || '');
    const [valueExtendedProperties, setValueExtendedProperties] = useState<any>(
        props.referenceListValue.listValueExtendedProperties || {},
    );

    useEffect(() => {
        props.onChange({
            listValueDisplayName: valueLabel,
            listValueCode: valueCode,
            listValueDescription: valueDescription,
            listValueExtendedProperties: valueExtendedProperties,
        });
    }, [valueLabel, valueCode, valueDescription, valueExtendedProperties]);

    function handleExtendedPropertyChange(propertyName: string, value: string) {
        const updatedValue = { ...valueExtendedProperties, [propertyName]: value };
        setValueExtendedProperties(updatedValue);
    }

    function getExtendedPropertyFields() {
        const extendedProperties = props.referenceList.listExtendedProperties || [];
        return extendedProperties.map((property, index) => {
            const propertyValue = valueExtendedProperties[property.propertyName];

            if (property.propertyType === 'string' || property.propertyType === 'number') {
                return (
                    <Input
                        key={index}
                        type={property.propertyType === 'number' ? property.propertyType : 'text'}
                        step={0.01}
                        label={property.propertyDisplayName}
                        value={propertyValue}
                        onChangeText={(value: string) => handleExtendedPropertyChange(property.propertyName, value)}
                    />
                );
            } else if (property.propertyType === 'boolean') {
                return (
                    <div key={index} className={styles.labelWrap}>
                        <Label text={property.propertyDisplayName} />
                        <Checkbox
                            checked={propertyValue}
                            onChange={(e: any) => handleExtendedPropertyChange(property.propertyName, e.target.checked)}
                            label={property.propertyDescription}
                            className={styles.checkbox}
                        />
                    </div>
                );
            }
        });
    }

    return (
        <div className={styles.contentWrap}>
            <Input label="Label" value={valueLabel} onChangeText={(value: string) => setValueLabel(value)} />
            <Input
                label="Code"
                disabled={props.mode === 'edit' ? true : false}
                value={valueCode}
                onChangeText={(value: string) => setValueCode(value.toUpperCase())}
            />
            <Input
                label="Description"
                value={valueDescription}
                onChangeText={(value: string) => setValueDescription(value)}
            />
            {getExtendedPropertyFields()}
        </div>
    );
}

export default ReferenceListValueModal;
