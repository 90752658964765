import React, { useEffect, useState, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import CustomerStore from 'src/stores/CustomerStore';
import Flex from 'src/components/Flex';
import PageContainer from 'src/components/PageContainer';
import PageContent from 'src/components/PageContent';
import NavBar from 'src/containers/NavBar';
import styles from '../styles.module.scss';
import { ToastStoreObject } from 'src/stores/ToastStore';
import { parseError } from 'src/utils';
import LoadingIcon from 'src/components/LoadingIcon';
import TabNav from 'src/components/TabNav';
import StatusBadge from 'src/components/StatusBadge';
import CustomerInformation from './Tabs/CustomerInformation';
import Documents from './Tabs/Documents';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import CustomerContacts from './Tabs/CustomerContacts';
import OrgsAndFacilities from './Tabs/OrgsAndFacilities';
import { getStatusColor } from 'src/containers/Customers';
import { ROUTES } from 'src/utils/constants';

function CustomerDetail() {
    const history = useHistory();
    const customerStore = useContext(CustomerStore);
    const [isLoading, setIsLoading] = useState(true);
    const DETAIL_TABS = [
        'Customer Information',
        `Orgs (${getOrgCount()}) / Facilities (${getFacilityCount()})`,
        'Documents',
        'Contacts',
    ];
    const [selectedTab, setSelectedTab] = useState(DETAIL_TABS[0]);
    const { customerId } = useParams<any>();

    useEffect(() => {
        getCustomer();
    }, []);

    async function getCustomer() {
        setIsLoading(true);

        try {
            await customerStore.getCustomer(customerId);
            await customerStore.getCustomerOrgs(customerId, true);
        } catch (err) {
            ToastStoreObject.show(parseError(err));
        } finally {
            setIsLoading(false);
        }
    }

    function getOrgCount() {
        return customerStore.customerOrgs?.length ?? '0';
    }

    function getFacilityCount() {
        return customerStore.customerOrgs?.length
            ? customerStore.customerOrgs?.reduce((count, org: any) => {
                  return count + (org.facilities?.length || 0);
              }, 0)
            : 0;
    }

    function getTabContent() {
        switch (selectedTab) {
            case 'Customer Information':
                return <CustomerInformation />;
            case 'Documents':
                return <Documents />;
            case 'Contacts':
                return <CustomerContacts />;
            default:
                return <OrgsAndFacilities />;
        }
    }

    function handleBack() {
        history.push(ROUTES.getString(ROUTES.Customers));
    }

    return (
        <PageContainer>
            <NavBar />
            <PageContent>
                {isLoading && (
                    <div className={styles.overlay}>
                        <LoadingIcon />
                    </div>
                )}
                <div className={classNames(styles.detailContainer, { [styles.isBlurred]: isLoading })}>
                    <Flex align="center" className={styles.detailHeader}>
                        <div className={styles.backWrap} onClick={() => handleBack()}>
                            <span className={styles.backArrow}>{`< `}</span>
                        </div>
                        <h1 className={styles.customerName}>{customerStore.customer?.customerName}</h1>
                        <StatusBadge
                            status={getStatusColor(customerStore.customerStatus)}
                            text={customerStore.customerStatus}
                        />
                    </Flex>
                    <TabNav tabs={DETAIL_TABS} onTabClick={(navItem: string) => setSelectedTab(navItem)} />
                    <Flex className={styles.detailTabContent} direction="column">
                        {getTabContent()}
                    </Flex>
                </div>
            </PageContent>
        </PageContainer>
    );
}

export default observer(CustomerDetail);
