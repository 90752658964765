import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import Icon from 'src/components/Icon';
import Flex from 'src/components/Flex';
import Button, { CustomButtonProps } from 'src/components/Button';
import * as variables from 'src/styles/variables';

export interface QuickActionProps extends CustomButtonProps {
    iconName: string;
    text?: string;
    isLocked?: boolean;
}

const QuickAction = ({ iconName, text, className, isLocked, ...rest }: QuickActionProps) => (
    <Flex direction="column" align="center" justify="center">
        <Button
            leftIcon={<Icon name={iconName} color={variables.red} className={styles.quickActionIcon} />}
            {...rest}
            disabled={isLocked}
            className={classNames(className, styles.quickAction)}
        />
        {text ? <span className={styles.quickActionText}>{text}</span> : null}
    </Flex>
);

export default QuickAction;
