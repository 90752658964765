import React, { useState, useEffect, useContext, useRef, useMemo } from 'react';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import Table from 'src/components/Table';
import { CellInfo } from 'react-table';
import NavBar from 'src/containers/NavBar';
import PageContainer from 'src/components/PageContainer';
import Flex from 'src/components/Flex';
import styles from './styles.module.scss';
import { observer } from 'mobx-react';
import Button from 'src/components/Button';
import { useParams, useLocation, useHistory } from 'react-router';
import LoadingCover from 'src/components/LoadingCover';
import TabNav from 'src/components/TabNav';
import * as adminService from 'src/api/admin';
import { ScheduledJob, FlowScriptHistory, FlowScript } from 'src/utils/types';
import {
    getFlowScriptHistoryVersion,
    getLatestFlowScriptHistory,
    getFlowScriptTemplateHistoryVersion,
} from 'src/utils/flowScripts';
import Input, { Label } from 'src/components/Input';
import Card from 'src/components/Card';
import DateComponent from 'src/components/DateComponent';
import CodeEditor from 'src/components/CodeEditor';
import ActionMenu from 'src/components/ActionMenu';
import Tag from 'src/components/Tag';
import { ModalStoreObject, ModalTypes } from 'src/stores/ModalStore';
import AdminJobSchedulesDropDown from 'src/components/AdminJobSchedulesDropDown';
import AdminFlowScriptTemplatesDropDown from 'src/components/AdminFlowScriptTemplatesDropDown';
import { exists } from 'src/utils';

type TabTypes = 'JOB' | 'ACTIVITY' | 'SCRIPT' | 'VERSIONS';
const TABS: { [key in TabTypes]: string } = {
    JOB: 'Scheduled Job',
    ACTIVITY: 'Activity',
    SCRIPT: 'Flow Script',
    VERSIONS: 'Versions',
};

function FieldView({ label, value }: { label: React.ReactNode; value: React.ReactNode }) {
    return (
        <Flex direction="column" className={styles.fieldLabelWrap}>
            <span className={styles.fieldLabel}>{label}</span>
            <span className={styles.fieldValue}>{value}</span>
        </Flex>
    );
}

export function ViewOnlyCodeEditor({ value }: { value: string }) {
    return (
        <div style={{ width: '75vw', padding: 25 }}>
            <CodeEditor readOnly={true} value={value} name="modal-ace-editor" />
        </div>
    );
}

interface TabParams {
    scheduledJob: ScheduledJob;
    onRefresh: Function;
    onChangeTab: (s: TabTypes) => any;
    publishedHistory: FlowScriptHistory;
    latestHistory: FlowScriptHistory;
}

function JobDetailsTab({ scheduledJob, onRefresh, publishedHistory, latestHistory }: TabParams) {
    const [isEditing, setIsEditing] = useState(false);
    const [updateData, setUpdateData] = useState<Partial<ScheduledJob>>({});

    const updateInput = (param: string) => (t: string | number) => setUpdateData({ ...updateData, [param]: t });

    async function handleSave() {
        if (!isEmpty(updateData)) {
            await adminService.updateScheduledJob(scheduledJob.scheduledJobGuid, updateData);
            onRefresh();
        }

        setIsEditing(false);
        setUpdateData({});
    }

    async function changeActiveStatus(newVal: boolean) {
        await adminService.updateScheduledJob(scheduledJob.scheduledJobGuid, {
            active: newVal,
        });
        onRefresh();
    }

    return (
        <div className={styles.tabContent}>
            <Flex direction="row" align="center">
                <Flex value={1} />
                <Flex direction="row" align="center">
                    {isEditing ? (
                        <Button type="primary" onClick={handleSave} text="Save" />
                    ) : (
                        <Button type="primary" preventDouble={false} onClick={() => setIsEditing(true)} text="Edit" />
                    )}

                    <Button
                        type="secondary"
                        onClick={() => changeActiveStatus(!scheduledJob.active)}
                        text={scheduledJob.active ? 'Set Job to Inactive' : 'Set Job to Active'}
                    />
                </Flex>
            </Flex>

            <Flex direction="row">
                <Flex value={0.75}>
                    <FieldView
                        label="Title"
                        value={
                            isEditing ? (
                                <Input
                                    value={updateData.scheduledJobTitle || scheduledJob.scheduledJobTitle}
                                    onChangeText={updateInput('scheduledJobTitle')}
                                />
                            ) : (
                                scheduledJob.scheduledJobTitle
                            )
                        }
                    />
                    <FieldView
                        label="Description"
                        value={
                            isEditing ? (
                                <Input
                                    value={updateData.scheduledJobDescription || scheduledJob.scheduledJobDescription}
                                    onChangeText={updateInput('scheduledJobDescription')}
                                />
                            ) : (
                                scheduledJob.scheduledJobDescription
                            )
                        }
                    />
                    <FieldView
                        label="Org Name Internal"
                        value={
                            isEditing ? (
                                <Input
                                    value={updateData.orgNameInternal || scheduledJob.orgNameInternal}
                                    onChangeText={updateInput('orgNameInternal')}
                                />
                            ) : (
                                scheduledJob.orgNameInternal
                            )
                        }
                    />
                    <FieldView
                        label="Job Schedule"
                        value={
                            isEditing ? (
                                <AdminJobSchedulesDropDown
                                    selectedValue={updateData.jobScheduleId || scheduledJob.jobScheduleId}
                                    onChange={(s: any) => updateInput('jobScheduleId')(s ? s.value : null)}
                                />
                            ) : (
                                `${scheduledJob.jobSchedule.jobScheduleTitle} (${scheduledJob.jobSchedule.jobScheduleCode})`
                            )
                        }
                    />
                    <FieldView label="Active" value={scheduledJob.active ? 'Yes' : 'No'} />
                </Flex>
                <Flex value={1}>
                    <FieldView label="Flow Script GUID" value={scheduledJob.flowScript.flowScriptGuid} />

                    <FieldView label="Published Flow Script Title" value={publishedHistory.flowScriptTitle} />
                    <FieldView
                        label="Published Flow Script Description"
                        value={publishedHistory.flowScriptDescription}
                    />
                    <Flex direction="row">
                        <Flex value={1}>
                            <FieldView label="Published Version" value={scheduledJob.flowScript.publishedVersion} />
                        </Flex>
                        <Flex value={1}>
                            {latestHistory.flowScriptVersion !== publishedHistory.flowScriptVersion ? (
                                <FieldView label="Draft Version" value={latestHistory.flowScriptVersion} />
                            ) : null}
                        </Flex>
                    </Flex>
                    <Flex direction="row">
                        <Flex value={1}>
                            <FieldView
                                label="Published Flow Script Timeout"
                                value={`${publishedHistory.timeoutSeconds} seconds`}
                            />
                        </Flex>
                        <Flex value={1}>
                            <FieldView label="Published Flow Script Max Retries" value={publishedHistory.maxRetries} />
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
        </div>
    );
}

function getScriptContent(history: FlowScriptHistory) {
    if (history.flowScriptTemplateGuid) {
        const publishedTemplate = getFlowScriptTemplateHistoryVersion(
            history.flowScriptTemplate.histories,
            history.flowScriptTemplate.publishedVersion,
        );
        return publishedTemplate.scriptContent;
    }
    return history.scriptContent;
}

function FlowScriptTab({ scheduledJob, onRefresh, publishedHistory, latestHistory }: TabParams) {
    const [viewing, setViewing] = useState<'latest' | 'published'>('latest');
    const [isEditing, setIsEditing] = useState(false);
    const [updateData, setUpdateData] = useState<Partial<FlowScript & FlowScriptHistory>>({});
    const history: FlowScriptHistory = viewing === 'latest' ? latestHistory : publishedHistory;

    const [script, setScript] = useState(() => getScriptContent(history));
    const [hasScriptChanged, setHasScriptChanged] = useState(false);
    useEffect(() => {
        if (viewing === 'latest') {
            setScript(getScriptContent(latestHistory));
            setHasScriptChanged(false);
        } else if (viewing === 'published') {
            setScript(getScriptContent(publishedHistory));
            setHasScriptChanged(false);
            setIsEditing(false);
            setUpdateData({});
        }
    }, [viewing]);

    const updateInput = (param: string) => (t: string) => setUpdateData({ ...updateData, [param]: t });

    async function handleSave() {
        if (hasScriptChanged || !isEmpty(updateData)) {
            await adminService.updateFlowScript(scheduledJob.flowScript.flowScriptGuid, {
                ...updateData,
                scriptContent: hasScriptChanged ? script : undefined,
            });
            onRefresh();
        }

        setIsEditing(false);
        setUpdateData({});
    }

    async function publishLatestVersion() {
        await adminService.updateFlowScript(scheduledJob.flowScript.flowScriptGuid, {
            publishedVersion: latestHistory.flowScriptVersion,
        });
        onRefresh();
    }

    let isCodeReadOnly = isEditing ? false : true;
    if (isEditing) {
        if (exists(updateData.flowScriptTemplateGuid)) {
            isCodeReadOnly = !!updateData.flowScriptTemplateGuid;
        } else if (history.flowScriptTemplateGuid) {
            isCodeReadOnly = true;
        }
    }

    const isDraft = latestHistory.flowScriptVersion !== publishedHistory.flowScriptVersion;

    return (
        <div className={styles.tabContent}>
            <Flex direction="row" align="center">
                <Flex value={1}>
                    <div>
                        Viewing Version {history.flowScriptVersion}
                        {viewing === 'latest' && isDraft && <Tag color="red" text="DRAFT" />}
                        {viewing === 'published' && <Tag color="green" text="PUBLISHED" />}
                    </div>
                </Flex>
                <Flex direction="row" align="center">
                    {isEditing && (
                        <Button
                            type="primary"
                            onClick={handleSave}
                            text="Save"
                            disabled={!hasScriptChanged && isEmpty(updateData)}
                        />
                    )}
                    <Button
                        type="primary"
                        preventDouble={false}
                        onClick={() => {
                            setViewing('latest');
                            if (isEditing) {
                                setUpdateData({});
                            }
                            setIsEditing(!isEditing);
                        }}
                        text={isEditing ? 'Cancel Editing' : 'Edit'}
                    />
                    {isDraft && (
                        <Button
                            type="secondary"
                            onClick={publishLatestVersion}
                            text={`Publish Version ${latestHistory.flowScriptVersion}`}
                        />
                    )}
                    <Button
                        type="primary"
                        preventDouble={false}
                        onClick={() => setViewing(viewing === 'latest' ? 'published' : 'latest')}
                        text={viewing === 'latest' ? 'Show Published Version' : 'Show Latest Version'}
                    />
                </Flex>
            </Flex>
            <Flex direction="row">
                <Flex value={1}>
                    <FieldView
                        label="Title"
                        value={
                            isEditing ? (
                                <Input
                                    value={updateData.flowScriptTitle || history.flowScriptTitle}
                                    onChangeText={updateInput('flowScriptTitle')}
                                />
                            ) : (
                                history.flowScriptTitle
                            )
                        }
                    />
                    <FieldView
                        label="Description"
                        value={
                            isEditing ? (
                                <Input
                                    value={updateData.flowScriptDescription || history.flowScriptDescription}
                                    onChangeText={updateInput('flowScriptDescription')}
                                />
                            ) : (
                                history.flowScriptDescription
                            )
                        }
                    />
                </Flex>
                <Flex value={1}>
                    <FieldView
                        label="Timeout"
                        value={
                            isEditing ? (
                                <Input
                                    type="number"
                                    value={updateData.timeoutSeconds || history.timeoutSeconds}
                                    onChangeText={updateInput('timeoutSeconds')}
                                />
                            ) : (
                                history.timeoutSeconds
                            )
                        }
                    />
                    <FieldView
                        label="Max Retries"
                        value={
                            isEditing ? (
                                <Input
                                    type="number"
                                    value={updateData.maxRetries || history.maxRetries}
                                    onChangeText={updateInput('maxRetries')}
                                />
                            ) : (
                                history.maxRetries
                            )
                        }
                    />
                </Flex>
                <Flex value={1}>
                    <FieldView label="Published Version" value={publishedHistory.flowScriptVersion} />
                    <FieldView label="Latest Version" value={latestHistory.flowScriptVersion} />
                </Flex>
            </Flex>

            <FieldView
                label="Flow Script Template"
                value={
                    isEditing ? (
                        <AdminFlowScriptTemplatesDropDown
                            selectedValue={updateData.flowScriptTemplateGuid || history.flowScriptTemplateGuid}
                            allowEmpty={true}
                            showScriptModal={true}
                            onChange={(s: any) =>
                                setUpdateData({ ...updateData, flowScriptTemplateGuid: s ? s.value : null })
                            }
                        />
                    ) : (
                        history.flowScriptTemplateGuid || '-'
                    )
                }
            />

            <Label text={`Flow Script Code ${isCodeReadOnly ? '(Read Only)' : ''}`} />

            <CodeEditor
                value={script}
                readOnly={isCodeReadOnly}
                onChange={(v) => {
                    setScript(v);
                    setHasScriptChanged(true);
                }}
            />
        </div>
    );
}

function ActivityTab({ scheduledJob, onRefresh }: TabParams) {
    const columns = [
        {
            Header: 'Run Id',
            accessor: 'scheduledJobRunGuid',
        },
        {
            Header: 'Job State',
            accessor: 'jobState.scheduledJobStateTitle',
        },
        {
            Header: 'Created',
            accessor: 'createdAt',
            minWidth: 150,
            Cell: (props: CellInfo) => <DateComponent date={props.value} showDateTime={true} />,
        },
        // {
        //     Header: 'ACTIONS',
        //     accessor: 'actions',
        //     sortable: false,
        //     style: { paddingVertical: 0, paddingHorizontal: 20 },
        //     Cell: (props: CellInfo) => (
        //         <div className={styles.actionWrap}>
        //             <ActionMenu
        //                 actions={[
        //                     { id: 'view', label: 'View Script' },
        //                     props.original.flowScriptVersion !== publishedHistory.flowScriptVersion && {
        //                         id: 'publish',
        //                         label: 'Publish',
        //                     },
        //                 ]}
        //                 onItemClick={async (action: { id: string; label: string }) => {
        //                     if (action.id === 'view') {
        //                         ModalStoreObject.showModal(ModalTypes.ViewContent, {
        //                             onClose: () => ModalStoreObject.hideModal(),
        //                             title: props.original.flowScriptTitle,
        //                             staticContent: <ViewOnlyCodeEditor value={props.original.scriptContent} />,
        //                         });
        //                     } else if (action.id === 'publish') {
        //                         ModalStoreObject.showModal(ModalTypes.ConfirmationModal, {
        //                             title: `Are you sure you want publish version ${props.original.flowScriptVersion}?`,
        //                             onConfirm: async () => {
        //                                 await adminService.updateFlowScript(scheduledJob.flowScript.flowScriptGuid, {
        //                                     publishedVersion: props.original.flowScriptVersion,
        //                                 });
        //                                 onRefresh();
        //                                 ModalStoreObject.hideModal();
        //                             },
        //                             onCancel: () => ModalStoreObject.hideModal(),
        //                             confirmButtonText: 'Yes, Publish',
        //                             cancelButtonText: 'No',
        //                         });
        //                     }
        //                 }}
        //             />
        //         </div>
        //     ),
        // },
    ];

    return (
        <div className={styles.tabContent}>
            Total Job Run Count: {scheduledJob.scheduledJobRuns.length}
            <Card className={styles.cardWrap}>
                <Table columns={columns} data={scheduledJob.scheduledJobRuns} showPagination={false} />
            </Card>
        </div>
    );
}

function VersionsTab({ scheduledJob, onRefresh, publishedHistory, latestHistory }: TabParams) {
    const columns = [
        {
            Header: 'Id',
            accessor: 'flowScriptHistoryId',
            minWidth: 50,
        },
        {
            Header: 'Version',
            accessor: 'flowScriptVersion',
            minWidth: 100,
        },
        {
            Header: 'Title',
            accessor: 'flowScriptTitle',
            minWidth: 150,
        },
        {
            Header: 'Description',
            accessor: 'flowScriptDescription',
            minWidth: 150,
        },
        {
            Header: 'Timeout',
            accessor: 'timeoutSeconds',
            minWidth: 100,
        },
        {
            Header: 'Max Retries',
            accessor: 'maxRetries',
        },
        {
            Header: 'Created',
            accessor: 'createdAt',
            minWidth: 150,
            Cell: (props: CellInfo) => <DateComponent date={props.value} showDateTime={true} />,
        },
        {
            Header: 'Status',
            accessor: 'flowScriptVersion',
            minWidth: 150,
            Cell: (props: CellInfo) => {
                let text = '-';
                if (
                    latestHistory.flowScriptVersion === props.value &&
                    latestHistory.flowScriptVersion !== publishedHistory.flowScriptVersion
                ) {
                    text = 'DRAFT';
                } else if (publishedHistory.flowScriptVersion === props.value) {
                    text = 'PUBLISHED';
                }
                return <span>{text}</span>;
            },
        },
        {
            Header: 'Template',
            accessor: 'flowScriptTemplateGuid',
        },
        {
            Header: 'ACTIONS',
            accessor: 'actions',
            sortable: false,
            style: { paddingVertical: 0, paddingHorizontal: 20 },
            Cell: (props: CellInfo) => (
                <div className={styles.actionWrap}>
                    <ActionMenu
                        actions={[
                            { id: 'view', label: 'View Script' },
                            props.original.flowScriptVersion !== publishedHistory.flowScriptVersion && {
                                id: 'publish',
                                label: 'Publish',
                            },
                        ]}
                        onItemClick={async (action: { id: string; label: string }) => {
                            if (action.id === 'view') {
                                ModalStoreObject.showModal(ModalTypes.ViewContent, {
                                    onClose: () => ModalStoreObject.hideModal(),
                                    title: props.original.flowScriptTitle,
                                    staticContent: <ViewOnlyCodeEditor value={getScriptContent(props.original)} />,
                                });
                            } else if (action.id === 'publish') {
                                ModalStoreObject.showModal(ModalTypes.ConfirmationModal, {
                                    title: `Are you sure you want publish version ${props.original.flowScriptVersion}?`,
                                    onConfirm: async () => {
                                        await adminService.updateFlowScript(scheduledJob.flowScript.flowScriptGuid, {
                                            publishedVersion: props.original.flowScriptVersion,
                                        });
                                        onRefresh();
                                        ModalStoreObject.hideModal();
                                    },
                                    onCancel: () => ModalStoreObject.hideModal(),
                                    confirmButtonText: 'Yes, Publish',
                                    cancelButtonText: 'No',
                                });
                            }
                        }}
                    />
                </div>
            ),
        },
    ];

    return (
        <div className={styles.tabContent}>
            Total Versions Count: {scheduledJob.flowScript.flowScriptHistories.length}
            <Card className={styles.cardWrap}>
                <Table columns={columns} data={scheduledJob.flowScript.flowScriptHistories} showPagination={false} />
            </Card>
        </div>
    );
}

function AdminScheduledJobsDetail() {
    const [isLoading, setIsLoading] = useState(false);
    const scheduledJobGuid = useParams<{ scheduledJobGuid: string }>().scheduledJobGuid;
    const [scheduledJob, setScheduledJob] = useState<ScheduledJob>(null);
    const location = useLocation();
    const history = useHistory();
    const locState = location.state;

    // useEffect(() => {
    //     // Load up on a certain tab by using history.push('', { startTab: # })
    //     if (locState && locState.startTab in PatientTabs) {
    //         setSelectedTab(locState.startTab);
    //         // Make sure we replace the history state after we have handled it
    //         history.replace(location.pathname, { ...locState, startTab: undefined });
    //     }
    // }, [locState]);

    const { publishedHistory, latestHistory } = useMemo(() => {
        if (!scheduledJob) {
            return {};
        }
        return {
            publishedHistory: getFlowScriptHistoryVersion(
                scheduledJob.flowScript.flowScriptHistories,
                scheduledJob.flowScript.publishedVersion,
            ),
            latestHistory: getLatestFlowScriptHistory(scheduledJob.flowScript.flowScriptHistories),
        };
    }, [scheduledJob]);

    async function fetchScheduledJob() {
        try {
            setIsLoading(true);
            const results = await adminService.getScheduledJob(scheduledJobGuid);
            setScheduledJob(results);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        fetchScheduledJob();
    }, [scheduledJobGuid]);

    const [pageState, setPageState] = useState<string>(TABS.JOB);

    // useEffect(() => {
    //     if (pageState === 'invitations') {
    //         invitationsRef.current.openInvitationsModal();
    //         tabRef.current.setTab('Invitations');
    //         setIsOpeningInvitationModal(false);
    //     }
    // }, [pageState]);

    const tabRef = useRef(null);
    function handleTabClick(tab: TabTypes) {
        setPageState(tab);
    }

    function renderContent(): any {
        const params: TabParams = {
            scheduledJob,
            onRefresh: fetchScheduledJob,
            onChangeTab: handleTabClick,
            publishedHistory,
            latestHistory,
        };
        switch (pageState) {
            case TABS.ACTIVITY:
                return <ActivityTab {...params} />;
            case TABS.JOB:
                return <JobDetailsTab {...params} />;
            case TABS.SCRIPT:
                return <FlowScriptTab {...params} />;
            case TABS.VERSIONS:
                return <VersionsTab {...params} />;
            default:
                return null;
        }
    }

    return (
        <PageContainer>
            <NavBar />
            <div className={styles.relative}>
                {isLoading ? <LoadingCover style={{ top: 50 }} /> : null}
                <Flex direction="row" className={styles.header} self="stretch" align="center">
                    <Button type="outline" onClick={() => history.goBack()} text="Go back" />
                    <Flex direction="column" justify="center" value={1} className={styles.headerStats}>
                        {scheduledJob && (
                            <>
                                <Flex>Job Title: {scheduledJob.scheduledJobTitle}</Flex>
                                <Flex>Job Description: {scheduledJob.scheduledJobDescription}</Flex>
                            </>
                        )}
                    </Flex>
                    <Flex direction="row" align="center">
                        {scheduledJob && (
                            <>
                                <Flex>Job Active: {scheduledJob.active ? 'ACTIVE' : 'INACTIVE'}</Flex>
                            </>
                        )}
                        <Button type="primary" onClick={fetchScheduledJob} text="Refresh Job" />
                    </Flex>
                </Flex>
                <Flex direction="row">
                    <Flex value={1} className={styles.informationContainer}>
                        <TabNav
                            tabs={['Scheduled Job', 'Activity', 'Flow Script', 'Versions']}
                            onTabClick={handleTabClick}
                            ref={tabRef}
                        />
                        {!scheduledJob ? <div>Loading </div> : renderContent()}
                    </Flex>
                </Flex>
            </div>
        </PageContainer>
    );
}
export default observer(AdminScheduledJobsDetail);
