import { observable, action, makeObservable } from 'mobx';
import { createContext } from 'react';
import Storage from 'src/utils/storage';
import { FilterQueryParams } from 'src/utils/types';
import { autoSave } from 'src/utils/mobxCache';

type PageNames =
    | 'patientsPage'
    | 'adminUsersPage'
    | 'providersPage'
    | 'encountersPage'
    | 'telehealthPage'
    | 'formsPage'
    | 'importPage'
    | 'importBatchDetailsPage'
    | 'referenceListsPage'
    | 'paymentRequestsPage'
    | 'payersPage'
    | 'customersPage'
    | 'invitationsPage';

const DEFAULT_LIMIT = 25;
export type PaginationQuery = { page: number; limit: number };

const defaultFilters = {
    patients: {
        filters: [] as any[],
        query: '',
        orStatement: false,
        pagination: { page: 1, limit: DEFAULT_LIMIT },
    },
    providers: {
        query: '',
        facility: [] as any,
        pagination: { page: 1, limit: DEFAULT_LIMIT },
    },
    adminUsers: {
        pagination: { page: 1, limit: DEFAULT_LIMIT },
    },
    encounters: {
        pagination: { page: 1, limit: DEFAULT_LIMIT },
        filters: [] as any[],
        orStatement: false,
        query: '',
    },
    forms: {
        pagination: { page: 1, limit: DEFAULT_LIMIT },
        filters: [] as any[],
        orStatement: false,
        query: '',
    },
    telehealth: {
        facility: [] as any[],
        pagination: { page: 1, limit: DEFAULT_LIMIT },
        filters: [] as any[],
        orStatement: false,
        query: '',
    },
    import: {
        pagination: { page: 1, limit: DEFAULT_LIMIT },
        filters: [] as any[],
        orStatement: false,
        query: '',
    },
    importBatchDetailsPage: {
        pagination: { page: 1, limit: 500 },
        filters: [] as any[],
        orStatement: false,
        query: '',
    },
    referenceLists: {
        filters: [] as any[],
        query: '',
        orStatement: false,
        pagination: { page: 1, limit: DEFAULT_LIMIT },
    },
    paymentRequests: {
        filters: [] as any[],
        query: '',
        orStatement: false,
        pagination: { page: 1, limit: DEFAULT_LIMIT },
    },
    payers: {
        filters: [] as any[],
        query: '',
        orStatement: false,
        pagination: { page: 1, limit: DEFAULT_LIMIT },
    },
    customers: {
        filters: [] as any[],
        query: '',
        orStatement: false,
        pagination: { page: 1, limit: DEFAULT_LIMIT },
    },
    invitations: {
        pagination: { page: 1, limit: DEFAULT_LIMIT },
    },
};

const name = 'FilterStore';
const cachedValues = [
    'patientsPage',
    'providersPage',
    'adminUsersPage',
    'encountersPage',
    'formsPage',
    'telehealthPage',
    'importPage',
    'importBatchDetailsPage',
    'referenceListsPage',
    'paymentRequestsPage',
    'customersPage',
    'invitationsPage',
];

class FilterStore {
    @observable patientsPage: FilterQueryParams = defaultFilters.patients;
    @observable providersPage: { facility: any[]; pagination: PaginationQuery; query: string } =
        defaultFilters.providers;
    @observable adminUsersPage: { pagination: PaginationQuery } = defaultFilters.adminUsers;
    @observable encountersPage: FilterQueryParams = defaultFilters.encounters;
    @observable formsPage: FilterQueryParams = defaultFilters.forms;
    @observable telehealthPage: FilterQueryParams & { facility: any[] } = defaultFilters.telehealth;
    @observable importPage: FilterQueryParams = defaultFilters.import;
    @observable importBatchDetailsPage: FilterQueryParams = defaultFilters.importBatchDetailsPage;
    @observable referenceListsPage: FilterQueryParams = defaultFilters.referenceLists;
    @observable paymentRequestsPage: FilterQueryParams = defaultFilters.paymentRequests;
    @observable payersPage: FilterQueryParams = defaultFilters.payers;
    @observable customersPage: FilterQueryParams = defaultFilters.customers;
    @observable invitationsPage: FilterQueryParams = defaultFilters.invitations;

    constructor() {
        makeObservable(this);
        this.populateFilters();
        autoSave(this, name, cachedValues);
    }

    @action
    reset() {
        this.patientsPage = defaultFilters.patients;
        this.providersPage = defaultFilters.providers;
        this.adminUsersPage = defaultFilters.adminUsers;
        this.encountersPage = defaultFilters.encounters;
        this.formsPage = defaultFilters.forms;
        this.telehealthPage = defaultFilters.telehealth;
        this.importPage = defaultFilters.import;
        this.importBatchDetailsPage = defaultFilters.importBatchDetailsPage;
        this.referenceListsPage = defaultFilters.referenceLists;
        this.paymentRequestsPage = defaultFilters.paymentRequests;
        this.payersPage = defaultFilters.payers;
        this.customersPage = defaultFilters.customers;
        this.invitationsPage = defaultFilters.invitations;
        Storage.remove('pageFilters');
    }

    @action
    setFilters(page: PageNames, filters: any) {
        this[page] = filters;
        this.saveFilters();
    }

    @action
    saveFilters() {
        const saveObj = {
            providersPage: this.providersPage,
        };
        Storage.set('pageFilters', saveObj);
    }

    populateFilters() {
        const pageFilters = Storage.get('pageFilters') || {};
        if (pageFilters.patientsPage) {
            this.patientsPage = {
                ...pageFilters.patientsPage,
                // Always start back on page 1
                pagination: this.patientsPage.pagination,
            };
        }
        if (pageFilters.encountersPage) {
            this.encountersPage = {
                ...pageFilters.encountersPage,
                // Always start back on page 1
                pagination: this.encountersPage.pagination,
            };
        }
        if (pageFilters.formsPage) {
            this.formsPage = {
                ...pageFilters.formsPage,
                // Always start back on page 1
                pagination: this.formsPage.pagination,
            };
        }
        if (pageFilters.providersPage) {
            this.providersPage = {
                ...pageFilters.providersPage,
                // Always start back on page 1
                pagination: this.providersPage.pagination,
            };
        }
        if (pageFilters.telehealthPage) {
            this.telehealthPage = {
                ...pageFilters.telehealthPage,
                pagingation: this.providersPage.pagination,
            };
        }
        if (pageFilters.importPage) {
            this.importPage = {
                ...pageFilters.importPage,
                // Always start back on page 1
                pagination: this.importPage.pagination,
            };
        }
        if (pageFilters.importBatchDetailsPage) {
            this.importPage = {
                ...pageFilters.importBatchDetailsPage,
                pagination: this.importBatchDetailsPage.pagination,
            };
        }
        if (pageFilters.referenceListsPage) {
            this.importPage = {
                ...pageFilters.referenceListsPage,
                pagination: this.referenceListsPage.pagination,
            };
        }
        if (pageFilters.paymentRequestsPage) {
            this.paymentRequestsPage = {
                ...pageFilters.paymentRequestsPage,
                pagination: this.paymentRequestsPage.pagination,
            };
        }
        if (pageFilters.payersPage) {
            this.payersPage = {
                ...pageFilters.payersPage,
                pagination: this.payersPage.pagination,
            };
        }
        if (pageFilters.customersPage) {
            this.customersPage = {
                ...pageFilters.customersPage,
                pagination: this.customersPage.pagination,
            };
        }
        if (pageFilters.invitationsPage) {
            this.invitationsPage = {
                ...pageFilters.invitationsPage,
                pagination: this.invitationsPage.pagination,
            };
        }
    }
}

// Import this to any other store that needs to use a value from it
export const FilterStoreObject = new FilterStore();

export default createContext(FilterStoreObject);
