import React from 'react';

const SvgCheck1 = (props) => (
    <svg width={14} height={13} {...props} viewBox="0 0 14 14">
        <path
            d="M13.635.152c-.393-.261-.94-.178-1.221.187L4.227 10.945 1.494 8.403a.921.921 0 00-.845-.21.846.846 0 00-.62.575c-.08.28.006.58.227.785l3.462 3.216a.93.93 0 00.69.228.909.909 0 00.64-.329l8.788-11.381c.282-.365.192-.873-.201-1.135z"
            fill={props.color || '#FFF'}
            fillRule="nonzero"
            opacity={0.5}
        />
    </svg>
);

export default SvgCheck1;
