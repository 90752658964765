import React from 'react';

interface VideoProps extends React.HTMLAttributes<HTMLVideoElement> {
    src: React.SourceHTMLAttributes<HTMLSourceElement>['src'];
}
const Video = (props: VideoProps) => {
    return (
        <video
            controls={true}
            style={{ width: '100%' }}
            controlsList="nodownload"
            onContextMenu={(e) => {
                e.preventDefault();
                return false;
            }}
            {...props}
        >
            <source src={props.src} type="video/mp4" />
            Sorry, your browser doesn't support embedded videos.
        </video>
    );
};

export default Video;
