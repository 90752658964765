import { Form } from '../../model/form/Form';
import { ExpressionUtil } from './ExpressionUtil';
import * as pointer from 'jsonpointer';

export class FormUtils {
    private _formData: Form;
    private _expressionUtil: ExpressionUtil;
    private _currentPage: any;
    private _modelContextByPageId: any = {};
    private _pageContextByPageId: any = {};
    private _facilityContextByPageId: any = {};
    private _organizationContextByPageId: any = {};

    constructor(formData: Form, expressionUtil: ExpressionUtil) {
        this._formData = formData;
        this._expressionUtil = expressionUtil;
    }

    get currentPage() {
        return this._currentPage;
    }

    set currentPage(page: any) {
        this._currentPage = page;
        this.refreshExpressionContexts();
    }

    private refreshExpressionContexts() {
        const orderedPages = this.getOrderedPages();

        const pageContext = {
            name: this._currentPage.pageName,
            number: -1,
            instanceCount: -1,
        };

        const modelContext: any = {
            detail: {},
            page: {},
        };

        // Primary model property data, not page details.
        for (let propertyName of Object.keys(this._formData.modelData.primaryFormData)) {
            modelContext[propertyName] = this._formData.modelData.primaryFormData[propertyName];
        }

        // Setup the page details maps for populating the model.page.|pageName|
        const pageDetailsObjectMap: any = {};

        // First setup the page instances, so values can be referenced by page.
        orderedPages.forEach((page, index) => {
            if (!modelContext.page.hasOwnProperty(page.pageName)) {
                modelContext.page[page.pageName] = new Array();
            }

            const pageInstanceDetails = {};
            pageDetailsObjectMap[page.pageId.toString()] = pageInstanceDetails;

            modelContext.page[page.pageName].push(pageInstanceDetails);

            if (page.pageName === this._currentPage.pageName && page.pageId === this._currentPage.pageId) {
                pageContext.number = index + 1;
                const instanceCount = orderedPages.indexOf(page) - this.firstPageIndex(orderedPages, page.pageName) + 1;
                pageContext.instanceCount = instanceCount;
            }
        });

        // Populate the form details objects.
        let pageDetailsObject: any = {};

        this._formData.modelData.formDetails.forEach((pd: any) => {
            // First setup the details for the current page.
            if (this._currentPage.pageId && pd.pageId && pd.pageId === this._currentPage.pageId) {
                if (!modelContext.detail.hasOwnProperty(pd.propertyName)) {
                    modelContext.detail[pd.propertyName] = new Array();
                    modelContext.detail[pd.propertyName][pd.propertySequence] = pd.propertyValue;
                }
            }

            // Then setup the details for the model.page.|pageName|, details.
            pageDetailsObject = pageDetailsObjectMap[pd.pageId.toString()];
            if (!pageDetailsObject.hasOwnProperty(pd.propertyName)) {
                pageDetailsObject[pd.propertyName] = new Array();
                pageDetailsObject[pd.propertyName][pd.propertySequence] = pd.propertyValue;
            }
        });

        this._modelContextByPageId[this._currentPage.pageId.toString()] = modelContext;
        this._pageContextByPageId[this._currentPage.pageId.toString()] = pageContext;
        this._facilityContextByPageId[this._currentPage.pageId.toString()] = this._formData.facility;
        this._organizationContextByPageId[this._currentPage.pageId.toString()] = this._formData.organization;
    }

    private firstPageIndex(pages: any[], pageName: string) {
        return pages.findIndex((page) => page.pageName === pageName);
    }

    public getPageDefinitionByName(pageName: string) {
        const pages: any[] = this._formData.formDef.formDefinitionContent.form.pages[0].page;
        return pages.find((page) => page.$.name === pageName);
    }

    public getOrderedPages() {
        const orderedPages = this._formData.pages.concat();
        orderedPages.sort((a, b) => {
            if (a.pageName === b.pageName) {
                const aDate = new Date(a.createTime);
                const bDate = new Date(b.createTime);
                return aDate.getTime() - bDate.getTime();
            }

            const apd = this.getPageDefinitionByName(a.pageName);
            const bpd = this.getPageDefinitionByName(b.pageName);

            if (!apd || !bpd) {
                return 0;
            }

            return apd.$.order - bpd.$.order;
        });

        return orderedPages;
    }

    public evaluateFormDefinitionExpression(pageId: string, value: string, item?: { [key: string]: string | number }) {
        const context = {
            page: this._pageContextByPageId[pageId.toString()],
            model: this._modelContextByPageId[pageId.toString()],
            facility: this._facilityContextByPageId[pageId.toString()],
            organization: this._organizationContextByPageId[pageId.toString()],
            item: item,
        };

        return ExpressionUtil.processModelExpression(this._expressionUtil, value, context);
    }

    private evaluatePropertyValueNodeAsBoolean(parentNode: any, pageId: string) {
        const val = this.evaluatePropertyValueNode(parentNode, pageId);

        // Basically, when casting a property as a boolean, anything but 'false', false, or 0 returns true.
        return val !== null && val != undefined && val !== 'false' && val !== 0;
    }

    private evaluatePropertyValueNode(parentNode: any, pageId: string) {
        if (parentNode[0].modelMapPropertyValue !== undefined) {
            const modelMapKeyProperty = parentNode[0].modelMapPropertyValue[0].$.modelMapKeyProperty;
            const modelMapValueProperty = parentNode[0].modelMapPropertyValue[0].$.modelMapValueProperty;
            const key = parentNode[0].modelMapPropertyValue[0].$.key;
            return this.getModelMapValue(modelMapKeyProperty, modelMapValueProperty, key);
        } else if (parentNode[0].modelPropertyValue) {
            return this.getModelValue(
                pageId,
                parentNode[0].modelPropertyValue[0].$.modelProperty,
                parentNode[0].modelPropertyValue[0].$.sequenceNumber,
            );
        } else if (parentNode[0].isPropertySetValue) {
            return (
                this.getModelValue(
                    pageId,
                    parentNode[0].modelPropertyValue[0].$.modelProperty,
                    parentNode[0].modelPropertyValue[0].$.sequenceNumber,
                ) !== null
            );
        }
        return null;
    }

    private getModelValue(pageId: string, modelProperty: string, sequenceNumber: number) {
        if (!isNaN(Number(sequenceNumber))) {
            const detailFound = this._formData.modelData.formDetails.find(
                (detail: any) =>
                    detail.pageId === pageId &&
                    detail.propertyName === modelProperty &&
                    detail.propertySequence === sequenceNumber,
            );
            if (detailFound) return detailFound;

            const bitmapFound = this._formData.modelData.formBitmaps.find(
                (bitmap: any) =>
                    bitmap.pageId === pageId &&
                    bitmap.propertyName === modelProperty &&
                    bitmap.propertySequence === Number(sequenceNumber),
            );
            if (bitmapFound) return bitmapFound;
        } else {
            if (this._formData.modelData.primaryFormData.hasOwnProperty(modelProperty)) {
                return this._formData.modelData.primaryFormData[modelProperty];
            }
        }

        return null;
    }

    private getModelMapValue(modelMapKeyProperty: string, modelMapValueProperty: string, key: string) {
        const keyDetail = this.getModelMapKeyDetail(modelMapKeyProperty, key);
        if (keyDetail) {
            const valueDetail = this.getModelMapValueDetail(modelMapValueProperty, keyDetail.propertySequence);
            if (valueDetail) {
                return valueDetail.propertyValue;
            }
        }

        return null;
    }

    private getModelMapKeyDetail(modelMapKeyPropertyName: string, modelMapKey: string) {
        return this._formData.modelData.formDetails.find(
            (detail: any) => detail.propertyName === modelMapKeyPropertyName && detail.propertyValue === modelMapKey,
        );
    }

    private getModelMapValueDetail(modelMapValuePropertyName: string, modelMapValueSequenceNumber: number) {
        return this._formData.modelData.formDetails.find(
            (detail: any) =>
                detail.propertyName === modelMapValuePropertyName &&
                detail.propertySequence === modelMapValueSequenceNumber,
        );
    }

    public getRendererVisibility(renderer: any, pageId: string) {
        if (typeof renderer.$.visible !== 'undefined') {
            return this.evaluateFormDefinitionExpression(pageId, renderer.$.visible);
        } else if (renderer.visible) {
            return this.evaluatePropertyValueNodeAsBoolean(renderer.visible, pageId);
        }
        return true;
    }

    public getBitmapPropertyValue(pageId: string, modelProperty: string, sequenceNumber: number) {
        return this.getModelValue(pageId, modelProperty, sequenceNumber);
    }

    public getModelPropertyValue(modelPropertyObject: any, modelData: any, isTime?: boolean) {
        if (!modelPropertyObject) return;

        const { modelProperty, timeModelProperty, jsonIndex, jsonPointer, timeJsonIndex, timeJsonPointer } =
            modelPropertyObject;
        const { primaryFormData } = modelData;

        if (!primaryFormData[modelProperty]) return primaryFormData[modelProperty];

        if (jsonIndex) {
            const objectValue =
                primaryFormData[isTime ? timeModelProperty : modelProperty][isTime ? timeJsonIndex : jsonIndex];
            const pointerValue = isTime ? timeJsonPointer : jsonPointer;
            if (pointer.get(objectValue, pointerValue)) {
                return pointer.get(objectValue, pointerValue);
            }
        }

        if (jsonPointer) {
            const objectValue = primaryFormData[isTime ? timeModelProperty : modelProperty];
            const pointerValue = isTime ? timeJsonPointer : jsonPointer;
            if (primaryFormData[isTime ? timeModelProperty : modelProperty] && pointer.get(objectValue, pointerValue)) {
                return pointer.get(objectValue, pointerValue);
            }

            return '';
        }

        return isTime ? primaryFormData[timeModelProperty] : primaryFormData[modelProperty];
    }

    public updateModelPropertyValue(modelPropertyObject: any, modelData: any, newValue: any, isTime?: boolean) {
        const { modelProperty, timeModelProperty } = modelPropertyObject;
        const { primaryFormData } = modelData;

        if (!primaryFormData.hasOwnProperty(modelProperty)) return modelData;

        if (modelPropertyObject.jsonIndex) {
            const modelObjectData = primaryFormData[isTime ? timeModelProperty : modelProperty] || [];
            const objectValue =
                modelObjectData[isTime ? modelPropertyObject.timeJsonIndex : modelPropertyObject.jsonIndex] || {};
            const pointerValue = isTime ? modelPropertyObject.timeJsonPointer : modelPropertyObject.jsonPointer;
            // Set the pointer/index in the objectValue
            pointer.set(objectValue, pointerValue, newValue);

            // Set the index in the array to the updated objectValue
            modelObjectData[isTime ? modelPropertyObject.timeJsonIndex : modelPropertyObject.jsonIndex] = objectValue;

            // Setup the new primaryFormData
            const updatedPrimaryFormData = Object.assign(primaryFormData, {
                [isTime ? timeModelProperty : modelProperty]: modelObjectData,
            });
            const updatedModelData = Object.assign(modelData, { primaryFormData: updatedPrimaryFormData });

            return updatedModelData;
        }

        if (modelPropertyObject.jsonPointer) {
            const objectValue = primaryFormData[isTime ? timeModelProperty : modelProperty] || {};
            const pointerValue = isTime ? modelPropertyObject.timeJsonPointer : modelPropertyObject.jsonPointer;
            if (primaryFormData[isTime ? timeModelProperty : modelProperty]) {
                pointer.set(objectValue, pointerValue, newValue);
            }

            // Setup the new primaryFormData
            const updatedPrimaryFormData = Object.assign(primaryFormData, {
                [isTime ? timeModelProperty : modelProperty]: objectValue,
            });
            const updatedModelData = Object.assign(modelData, { primaryFormData: updatedPrimaryFormData });

            return updatedModelData;
        }

        if (isTime) {
            primaryFormData[timeModelProperty] = newValue;
        } else {
            primaryFormData[modelProperty] = newValue;
        }

        return modelData;
    }

    public getPageName(pageId: string) {
        const page = this._formData.formDef.formDefinitionContent.form.pages[0].page.find(
            (page: any) => page.$.name === pageId,
        );
        return page.title[0];
    }
}
