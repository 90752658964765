import React, { useContext } from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { observer } from 'mobx-react';
import styles from 'src/containers/Reports/Macra/styles.module.scss';
import Flex from 'src/components/Flex';
import Card from 'src/components/Card';
import LoadingIcon from 'src/components/LoadingIcon';
import MacraStore from 'src/stores/MacraStore';
import { getDailyCaseCountData, BAR_COLORS2 } from 'src/containers/Reports/Macra/utils';

function MacraComplianceOverview() {
    const macraStore = useContext(MacraStore);

    function getDailyCaseCountBarChartLabel(id: string | number) {
        switch (id) {
            case 'admissible':
                return 'Admissible';
            case 'nonAdmissible':
                return 'Non-Admissible';
            case 'pendingQcdrCalculation':
                return 'Pending QCDR Calculation';
            default:
                return id;
        }
    }

    function getDailyCaseCountBarChart() {
        const chartData = getDailyCaseCountData();
        const xTicks = chartData
            .filter((value, index) => {
                return index % 2;
            })
            .map((data) => {
                return data.date;
            });

        return (
            <div className={styles.view}>
                <ResponsiveBar
                    data={chartData}
                    keys={['Admissible', 'Non-Admissible', 'Pending QCDR Calculation']}
                    indexBy="date"
                    minValue={0}
                    margin={{ top: 50, right: 60, bottom: 50, left: 60 }}
                    padding={0.3}
                    colors={BAR_COLORS2}
                    axisTop={null}
                    axisRight={null}
                    axisBottom={{
                        tickSize: 10,
                        tickPadding: 5,
                        tickRotation: -45,
                        tickValues: xTicks,
                    }}
                    axisLeft={{
                        tickSize: 10,
                        tickPadding: 5,
                        tickRotation: 0,
                    }}
                    label={(d) => `${d.value}`}
                    labelSkipWidth={12}
                    labelSkipHeight={10}
                    labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                    animate={true}
                    motionStiffness={90}
                    motionDamping={15}
                    tooltip={({ id, value, color, data }) => (
                        <div>
                            {data.date}
                            <br />
                            <strong style={{ color }}>
                                {getDailyCaseCountBarChartLabel(id)}: {value}
                            </strong>
                        </div>
                    )}
                    legends={[
                        {
                            dataFrom: 'keys',
                            anchor: 'top-right',
                            direction: 'column',
                            justify: false,
                            translateX: 0,
                            translateY: -50,
                            itemsSpacing: 2,
                            itemWidth: 125,
                            itemHeight: 15,
                            itemDirection: 'left-to-right',
                            itemOpacity: 0.85,
                            symbolSize: 15,
                            effects: [
                                {
                                    on: 'hover',
                                    style: {
                                        itemOpacity: 1,
                                    },
                                },
                            ],
                        },
                    ]}
                />
            </div>
        );
    }

    return <Flex direction="column">
        <h2>Projected Compliance Overview</h2>
        <Card className={styles.cardWrap}>
            <Flex direction="column" align="center">
                <h3>Total Case Count by Date ({macraStore.dailyCaseCountData.totalCases})</h3>
            </Flex>
            {macraStore.isLoading ? (
                <Flex direction="row" align="center" justify="center" className={styles.loading}>
                    <LoadingIcon type="dot" />
                </Flex>
            ) : (
                <Flex align="stretch">{getDailyCaseCountBarChart()}</Flex>
            )}
        </Card>
    </Flex>;
}

export default observer(MacraComplianceOverview);