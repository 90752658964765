import * as ROUTES from 'src/api/routes';
import request from 'src/api/utils';

export async function validateDemData() {
    return await request({ ...ROUTES.VALIDATE_NEMSIS_DEM_DATA });
}

export async function validateEmsData(encounterFormId: number) {
    return await request({ ...ROUTES.VALIDATE_NEMSIS_EMS_DATA, pathParams: { encounterFormId } });
}

export async function getEmsDataValidation(encounterFormId: number) {
    return await request({ ...ROUTES.GET_NEMSIS_EMS_VALIDATION, pathParams: { encounterFormId } });
}

export async function submitDemData() {
    return await request({ ...ROUTES.SUBMIT_DEM_NEMSIS_DATA });
}

export async function submitUpdatedEmsData(facId: number) {
    return await request({ ...ROUTES.SUBMIT_EMS_NEMSIS_DATA, params: { facId } });
}

export async function submitEmsData(encounterFormId: number) {
    return await request({ ...ROUTES.SUBMIT_SINGLE_EMS_NEMSIS_DATA, pathParams: { encounterFormId } });
}
