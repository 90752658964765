import React, { useState, useEffect, useRef } from 'react';
import Icon from 'src/components/Icon';
import { ICONS } from 'src/utils/constants';
import { RepeatableBitmapUpdate } from 'common/lib/model/model/RepeatableBitmapUpdate';
import styles from '../../styles.module.scss';
import { ANALYTICS_NAMES } from 'src/utils/analytics';

interface CameraProps {
    input: any;
    value?: any;
    onChange?: Function;
}

function Camera(props: CameraProps) {
    const [value, setValue] = useState<RepeatableBitmapUpdate>(null);
    const fileRef = useRef();

    useEffect(() => {
        if (value) {
            props.onChange(value);
        }
    }, [value]);

    function handleFileSelect(selectedFiles: FileList) {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(selectedFiles[0]);
        fileReader.onload = (e: any) => {
            const image = new Image();
            image.src = e.target.result;
            image.onload = (loadedImage: any) => {
                const { width, height, src } = loadedImage && loadedImage.target;
                const base64Data = src.replace(/^data:image\/(png|jpg|jpeg|gif|jpe);base64,/, '');
                setValue({
                    width,
                    height,
                    scale: 1,
                    encodeType: 'image/png',
                    bitmapContent: base64Data,
                });
            };
        };
    }

    function getImageContent() {
        if (!props.value) return null;

        const { bitmapContent, encodingType } = props.value;

        if (bitmapContent && encodingType) {
            const imageSource = `data:${encodingType};base64,${bitmapContent}`;
            return (
                <div>
                    <img src={imageSource} />
                </div>
            );
        }
    }

    return (
        <div>
            <div className={styles.label}>{props.input.title ? props.input.title[0] : ''}</div>
            <div className={styles.cameraContent}>
                {getImageContent()}
                <input
                    className={styles.cameraSelector}
                    ref={fileRef}
                    type="file"
                    accept="image/*"
                    onChange={(e: any) => handleFileSelect(e.target.files)}
                    id="cameraSelector"
                    name="cameraSelector"
                    data-test-id={ANALYTICS_NAMES.DiscreteForm_CameraInput}
                />
                <label htmlFor="cameraSelector" className={styles.cameraLabel}>
                    <Icon name={ICONS.PictureStackHuman} className={styles.cameraIcon} /> Choose a File
                </label>
            </div>
        </div>
    );
}

export default Camera;
