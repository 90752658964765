import React, { useState, useEffect, useContext } from 'react';
import * as utils from 'src/utils';
import NavBar from 'src/containers/NavBar';
import PageContent from 'src/components/PageContent';
import PageContainer from 'src/components/PageContainer';
import Flex from 'src/components/Flex';
import Table from 'src/components/Table';
import styles from './styles.module.scss';
import { observer } from 'mobx-react';
import Age from 'src/components/Age';
import DetailsButton from 'src/components/DetailsButton';
import { CellInfo } from 'react-table';
import Gender from 'src/components/Gender';
import Button from 'src/components/Button';
import Icon from 'src/components/Icon';
import * as patientService from 'src/api/patients';
import PatientStore from 'src/stores/PatientStore';
import PatientStateDropDown from 'src/components/PatientStateDropDown';
import useIsMount from 'src/utils/hooks/useIsMount';
import { ICONS, ROUTES } from 'src/utils/constants';
import FilterStore from 'src/stores/FilterStore';
import Pagination from 'src/components/Table/Pagination';
import { useHistory } from 'react-router';
import { ModalStoreObject, ModalTypes } from 'src/stores/ModalStore';
import { ANALYTICS_NAMES } from 'src/utils/analytics';
import UserStore from 'src/stores/UserStore';
import SubNav from '../SubNav';
import FilteredSearch, { FilteredSearchQuery } from 'src/components/FilteredSearch';
import { FilterQueryParams } from 'src/utils/types';
import PatientStateStore from 'src/stores/PatientStateStore';
import { getRequiredPatientFilters, getPatientFilters } from 'src/utils/filters';
import FacilityStore from 'src/stores/FacilityStore';

const FILTER_PAGE = 'patientsPage';

function Patients() {
    const history = useHistory();
    const isMount = useIsMount();
    const [isLoading, setIsLoading] = useState(false);
    const [isUpdateLoading, setIsUpdateLoading] = useState(false);
    const filterStore = useContext(FilterStore);
    const facilityStore = useContext(FacilityStore);
    const userStore = useContext(UserStore);
    const patientStateStore = useContext(PatientStateStore);
    const patientFilters: FilterQueryParams = filterStore[FILTER_PAGE];
    // const [selectedPreMadeFilters, setSelectedPreMadeFilters] = useState([]);
    const patientStore = useContext(PatientStore);

    const columns = [
        {
            Header: 'ID',
            accessor: 'patientNumber',
            minWidth: 130,
            Cell: (props: CellInfo) => <span>{props.value}</span>,
        },
        {
            Header: 'FIRST NAME',
            accessor: 'patientFirstName',
            minWidth: 175,
            Cell: (props: CellInfo) => <span>{props.value}</span>,
        },
        {
            Header: 'LAST NAME',
            accessor: 'patientLastName',
            minWidth: 175,
            Cell: (props: CellInfo) => <span>{props.value}</span>,
        },
        {
            Header: 'GENDER',
            accessor: 'patientGenderCode',
            minWidth: 150,
            Cell: (props: CellInfo) => <Gender value={props.value} />,
        },
        {
            Header: 'AGE',
            accessor: 'patientDob',
            minWidth: 100,
            Cell: (props: CellInfo) => <Age dob={props.value} />,
        },
        {
            Header: 'STATUS',
            accessor: 'patientStateId',
            minWidth: 150,
            Cell: (props: CellInfo) => (
                <PatientStateDropDown
                    isDisabled={!userStore.userPermissions.canEdit.patientDetails}
                    selectedValue={props.value}
                    onBeforeChange={(option: { value: any }) => {
                        return new Promise((resolve) => {
                            ModalStoreObject.showModal(ModalTypes.ConfirmationModal, {
                                title: "Are you sure you would like to change the patient's status?",
                                onConfirm: () => {
                                    resolve(true);
                                    handleUpdateStatus({ id: props.original.patientId, value: option.value });
                                },
                                onCancel: () => {
                                    resolve(false);
                                    ModalStoreObject.hideModal();
                                },
                                confirmButtonText: 'Confirm',
                                isLoading: isUpdateLoading,
                            });
                        });
                    }}
                    onChange={() => {}}
                />
            ),
        },
        {
            Header: 'ACTIONS',
            minWidth: 200,
            style: { paddingVertical: 0, paddingHorizontal: 20 },
            Cell: (props: CellInfo) => (
                <Flex direction="row">
                    <DetailsButton
                        onClick={() => {
                            history.push(ROUTES.getString(ROUTES.PatientDetails, props.original.patientId));
                        }}
                        isLocked={!userStore.userPermissions.canView.patients}
                        data-test-id={ANALYTICS_NAMES.Patients_Details}
                    />
                </Flex>
            ),
        },
    ];

    async function handleUpdateStatus(params: { id: any; value: any }) {
        try {
            setIsUpdateLoading(true);
            await patientService.updatePatient(params.id, { patientStateId: params.value });
            loadPatients();
            ModalStoreObject.hideModal();
        } finally {
            setIsUpdateLoading(false);
        }
    }

    async function loadPatients() {
        try {
            setIsLoading(true);
            await patientStore.getPatients(patientFilters);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        patientStateStore.getPatientStates();
    }, []);

    useEffect(() => {
        // Don't load on initial mount
        if (isMount) {
            return;
        }
        loadPatients();
    }, [patientFilters]);

    const handlePaginationFilterChange = function(newPage: number) {
        const updatedFilters = { ...filterStore[FILTER_PAGE] };
        updatedFilters.pagination.page = newPage;
        filterStore.setFilters(FILTER_PAGE, updatedFilters);
    };

    function handleCreatePatient() {
        const facilityFilter = filterStore[FILTER_PAGE].filters.find((f) => f.name === 'facId');
        const facilityId =
            facilityFilter && facilityFilter.data && facilityFilter.data.value1
                ? facilityFilter.data.value1
                : facilityStore.defaultFacilityId;

        ModalStoreObject.showModal(ModalTypes.CreatePatient, {
            onClose: () => ModalStoreObject.hideModal(),
            onSave: () => ModalStoreObject.hideModal(),
            facId: facilityId,
        });
    }

    async function handleSearch({ query, fields, orStatement }: FilteredSearchQuery) {
        const updatedFilters = { ...patientFilters };
        updatedFilters.pagination.page = 1;
        updatedFilters.query = query;
        updatedFilters.orStatement = orStatement;

        // TODO: Enable this when setting up pre made filters
        // determine if searching by custom query or by premade filters
        // const searchingPreMade =
        //     (((fields || []).length === 1 && fields[0].data.value1 === null && fields[0].data.value2 === null) ||
        //         (fields || []).length === 0) &&
        //     selectedPreMadeFilters.length > 0;

        // if (searchingPreMade) {
        //     // handle Premade
        //     let preMadeFilters: any[] = [];
        //     if (selectedPreMadeFilters.includes('Active Encounters')) {
        //         preMadeFilters = [
        //             ...preMadeFilters,
        //             // {
        //             //     name: 'enctrStatCd',
        //             //     method: FILTER_METHODS.Equals_Text,
        //             //     data: { value1: 'ACTIVE', value2: null },
        //             // },
        //         ];
        //     }
        //     updatedFilters.filters = preMadeFilters;
        // } else {
        //     updatedFilters.filters = fields;
        // }
        updatedFilters.filters = fields;

        filterStore.setFilters(FILTER_PAGE, updatedFilters);
    }

    return (
        <PageContainer>
            <NavBar />
            <SubNav type="patients" />
            <PageContent>
                <Flex direction="row" className={styles.filterWrap}>
                    <Flex value={1}>
                        <FilteredSearch
                            initialValues={{
                                query: patientFilters.query,
                                fields: patientFilters.filters,
                                orStatement: patientFilters.orStatement,
                            }}
                            onSearch={handleSearch}
                            searchPlaceholder={'Search by Patient Name'}
                            requiredFilters={getRequiredPatientFilters() as any}
                            filterOptions={getPatientFilters()}
                        />
                    </Flex>

                    <Button
                        leftIcon={<Icon className={styles.plusIcon} name={ICONS.PlusButton} />}
                        type="primary"
                        onClick={handleCreatePatient}
                        text="New Patient"
                        isLocked={!userStore.userPermissions.canCreate.patients}
                        disabled={isLoading}
                        className={styles.createPatientBtn}
                        data-test-id={ANALYTICS_NAMES.Patients_Create}
                    />
                </Flex>
                <div className={styles.tableWrap}>
                    <div className={styles.tableTitle}>Patients</div>
                    <Table
                        columns={columns}
                        isLoading={isLoading}
                        data={patientStore.patients}
                        showPagination={false}
                        noDataText="Filter your search to view patients"
                        pageSize={filterStore[FILTER_PAGE].pagination.limit}
                    />
                </div>
                {/* Custom Pagination for server side data */}
                <Pagination
                    pages={Math.ceil(patientStore.totalPatientsCount / filterStore[FILTER_PAGE].pagination.limit) || 1}
                    page={filterStore[FILTER_PAGE].pagination.page - 1}
                    onPageChange={(page: number) => handlePaginationFilterChange(page + 1)}
                    showing={patientStore.patients.length}
                    totalRecords={patientStore.totalPatientsCount}
                />
            </PageContent>
        </PageContainer>
    );
}
export default observer(Patients);
