import React, { useContext, useEffect } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import Flex from 'src/components/Flex';
import { observer } from 'mobx-react';
import PageContainer from 'src/components/PageContainer';
import NavBar from '../NavBar';
import PageContent from 'src/components/PageContent';
import Button from 'src/components/Button';
import Icon from 'src/components/Icon';
import { ICONS, ROUTES } from 'src/utils/constants';
import Card from 'src/components/Card';
import Table from 'src/components/Table';
import { ToastStoreObject } from 'src/stores/ToastStore';
import { parseError } from 'src/utils';
import SurveyDefinitionStore from 'src/stores/SurveyDefinitionStore';
import { CellInfo } from 'react-table';
import * as variables from 'src/styles/variables';
import { useHistory } from 'react-router-dom';

function SurveyDefinitions() {
    const surveyDefinitionStore = useContext(SurveyDefinitionStore);
    const history = useHistory();
    useEffect(() => {
        getSurveyDefinitions();
    }, []);

    async function getSurveyDefinitions() {
        try {
            await surveyDefinitionStore.getSurveyDefinitions();
        } catch (err) {
            ToastStoreObject.show(parseError(err));
        }
    }

    function getPublishedSurvey(props: CellInfo) {
        const publishedVersion = props.value[0].publishedVersion;
        const histories = props.value[0].surveyDefinitionHistory;

        return histories.find((history: any) => history.surveyDefinitionHistoryVer == publishedVersion);
    }

    const columns = [
        {
            Header: () => <span>TITLE</span>,
            accessor: 'surveyDefinition',
            style: { paddingVertical: 16 },
            minWidth: 354,
            Cell: (props: CellInfo) => (
                <span>{getPublishedSurvey(props)?.surveyDefinitionTitle || 'NO PUBLISHED SURVEY'}</span>
            ),
        },
        {
            Header: 'STATUS',
            accessor: 'surveyDefinition',
            minWidth: 386.67,
            Cell: (props: CellInfo) => (
                <span
                    className={classNames({
                        [styles.greenText]: props.value[0].publishedVersion,
                    })}
                >
                    {// DRAFT state if publishedVersion is null
                    props.value[0].publishedVersion ? 'PUBLISHED' : 'DRAFT'}
                </span>
            ),
        },
        {
            Header: 'LAST UPDATE',
            accessor: 'surveyDefinition',
            minWidth: 386.67,
            Cell: (props: CellInfo) => <span>{props.value[0].updatedAt.slice(0, 10)}</span>,
        },
        {
            Header: 'ACTIONS',
            accessor: 'surveyDefinition',
            minWidth: 120,
            Cell: (props: CellInfo) => (
                <Flex direction="row">
                    <Button
                        onClick={() => history.push(ROUTES.getString(ROUTES.SurveyDefinitionDetails, props.value[0].surveyDefinitionId.toString()))}
                        type="secondary"
                        leftIcon={<Icon name={ICONS.Pencil2} color={variables.red} />}
                    />
                </Flex>
            ),
        },
    ];

    return (
        <PageContainer>
            <NavBar />
            <PageContent>
                <Flex self="stretch" align="center" justify="start">
                    <Flex value={1} className={styles.pageTitle}>
                        Survey Definitions
                    </Flex>
                    <Button
                        leftIcon={<Icon className={styles.plusIcon} name={ICONS.PlusButton} />}
                        type="primary"
                        onClick={null}
                        text="New Survey Definition"
                    />
                </Flex>

                {/* showing table */}
                <Card className={styles.cardWrap}>
                    <Table columns={columns} data={surveyDefinitionStore.surveyDefinitions} showPagination={false} />
                </Card>
            </PageContent>
        </PageContainer>
    );
}

export default observer(SurveyDefinitions);
