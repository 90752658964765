import React, { useState, useContext } from 'react';
import styles from './styles.module.scss';
import VehicleStore, { Vehicle } from 'src/stores/VehicleStore';
import SideModal from 'src/components/SideModal';
import ModalHeader from 'src/components/ModalHeader';
import ModalFooter from 'src/components/ModalFooter';
import { ToastStoreObject } from 'src/stores/ToastStore';
import * as utils from 'src/utils';
import { OptionTypes } from 'src/components/OptionDropDown';
import NemsisOptionDropDown from 'src/components/NemsisOptionDropDown';
import { getDropdownValue } from 'src/utils/nemsis';
import { NaValues, IVehicleCertificationLevel, IVehicleAnnualUsage } from 'common/lib/model/nemsis3_4/demographic';
import Flex from 'src/components/Flex';
import { Label } from 'src/components/Input';
import FacilityDropDown from 'src/components/FacilityDropDown';
import NemsisInput from 'src/components/NemsisInput';
import Button from 'src/components/Button';
import OrgStore from 'src/stores/OrgStore';
import NemsisStore from 'src/stores/NemsisStore';

export interface VehicleModalProps {
    onClose: Function;
    onSave: Function;
    vehicle?: Vehicle;
}

const defaultVehicle: Vehicle = {
    vehicleNumber: '',
    vehicleIdNumber: '',
    callSign: '',
    vehicleTypeCode: '',
    vehicleCertificationLevels: [],
    initialCost: null,
    modelYear: null,
    annualUsage: [],
    notValues: {
        vehicleCertificationLevels: [{}],
        annualUsage: [{}],
    },
};

const defaultVehicleCertification: IVehicleCertificationLevel = {
    licensureLevelCode: '',
    personnelCountNormal911Ambulance: null,
    personnelCountNormal911NonTransport: null,
    personnelCountNormalMedicalNon911: null,
};

const defaultAnnualUsage: IVehicleAnnualUsage = {
    distanceUnit: '',
    vehicleHours: null,
    vehicleMilesKilometers: null,
    yearAccrued: null,
};

export function VehicleModal(props: VehicleModalProps) {
    const [data, setData] = useState<Vehicle>(props.vehicle || utils.clone(defaultVehicle));
    const isEdit = !!props.vehicle;

    const vehicleStore = useContext(VehicleStore);
    const nemsisStore = useContext(NemsisStore);

    const updateInput = (param: keyof Vehicle) => (t: string) => setData({ ...data, [param]: t });

    function updateNestedInput({
        param,
        subParam,
        index,
        value,
    }: {
        param: keyof Vehicle;
        subParam: string;
        index: number;
        value: any;
    }) {
        const newData: any = { ...data };
        if (!newData[param]) {
            newData[param] = [];
        }
        if (!newData[param][index]) {
            newData[param][index] = {};
        }
        newData[param][index][subParam] = value;
        setData(newData);
    }

    function renderTopLevelInput({ key, label }: { key: keyof Vehicle; label: string }) {
        return <NemsisInput nemsisLabel={label} value={data[key] as string} onChangeText={updateInput(key)} />;
    }

    function renderTopLevelNaInput({ key, label }: { key: keyof Vehicle; label: string }) {
        return (
            <NemsisInput
                nemsisLabel={label}
                value={getNotValBool(key) ? NaValues.NOT_APPLICABLE : (data[key] as string)}
                onChangeText={updateInput(key)}
                onNaClick={() => handleNaChange(key)}
                naSelected={getNotValBool(key)}
            />
        );
    }

    function renderDropDown({
        key,
        label,
        type,
        isMulti,
        isNa,
        nested,
    }: {
        key: keyof Vehicle;
        label: string;
        type: OptionTypes;
        isMulti?: boolean;
        isNa?: boolean;
        nested?: { index: number; row: any; key: string };
    }) {
        const value = nested ? nested.row[nested.key] : data[key];
        return (
            <NemsisOptionDropDown
                nemsisLabel={label}
                isMulti={isMulti}
                selectedValue={
                    getNotValBool(key, nested && nested.key, nested && nested.index) ? NaValues.NOT_APPLICABLE : value
                }
                onChange={(d: any) => {
                    const val = getDropdownValue(d, isMulti ? [] : undefined);
                    if (nested) {
                        return updateNestedInput({ param: key, subParam: nested.key, index: nested.index, value: val });
                    }
                    return setData({ ...data, [key]: val });
                }}
                {...(isNa
                    ? {
                          onNaClick: () => handleNaChange(key, nested && nested.key, nested && nested.index),
                          naSelected: getNotValBool(key, nested && nested.key, nested && nested.index),
                      }
                    : {})}
                type={type}
            />
        );
    }

    function handleNaChange(property: keyof Vehicle, subProperty?: string, index?: number) {
        const newData = { ...data };
        if (subProperty) {
            // Make sure everything is defaulted all the way down
            if (!data.notValues) {
                newData.notValues = { [property]: [] };
            }
            if (!data.notValues[property]) {
                newData.notValues = { ...data.notValues, [property]: [] };
            }
            if (!data.notValues[property][index]) {
                newData.notValues[property][index] = {};
            }

            newData.notValues[property][index][subProperty] = data.notValues[property][index][subProperty]
                ? undefined
                : NaValues.NOT_APPLICABLE;
        } else {
            newData.notValues[property] =
                data.notValues && data.notValues[property] ? undefined : NaValues.NOT_APPLICABLE;
        }
        setData(newData);
    }

    function getNotValBool(parentProp: keyof Vehicle, childProp?: string, index?: number) {
        if (childProp) {
            return !!(
                data &&
                data.notValues &&
                data.notValues[parentProp] &&
                data.notValues[parentProp][index] &&
                data.notValues[parentProp][index][childProp]
            );
        }
        return !!(data && data.notValues && data.notValues[parentProp]);
    }

    async function handleSave() {
        try {
            if (isEdit) {
                await vehicleStore.updateVehicle(data);
            } else {
                await vehicleStore.createVehicle(data);
            }

            props.onSave();
            props.onClose();
            await nemsisStore.validateDemData(true);
        } catch (error) {
            ToastStoreObject.show(utils.parseError(error));
        }
    }

    function handleAddRow(property: keyof Vehicle) {
        const currentData = { ...data };

        if (property === 'vehicleCertificationLevels') {
            currentData[property].push({ ...defaultVehicleCertification });
            // add an empty object to track indexes of not values correctly
            currentData.notValues[property] = [...currentData.notValues[property], {}];
        }
        if (property === 'annualUsage') {
            currentData[property].push({ ...defaultAnnualUsage });
            // add an empty object to track indexes of not values correctly
            currentData.notValues[property] = [...currentData.notValues[property], {}];
        }

        setData(currentData);
    }

    function handleRemoveRow(parentProp: keyof Vehicle, index: number) {
        const currentData = { ...data };
        // @ts-ignore
        (currentData[parentProp] || []).splice(index, 1);
        // remove any not values
        (currentData.notValues[parentProp] || []).splice(index, 1);
        setData(currentData);
    }

    return (
        <SideModal isOpen={true} onClose={() => props.onClose()}>
            <ModalHeader title={isEdit ? 'Edit Vehicle' : 'Create Vehicle'} onClose={props.onClose} />
            <div className={styles.modalContent}>
                <Flex direction="column" className={styles.form}>
                    <Flex direction="row" justify="between" className={styles.formRow}>
                        <Flex value={1}>
                            {renderDropDown({
                                key: 'vehicleTypeCode',
                                label: 'Vehicle Type',
                                type: OptionTypes.EMSVehicleType,
                                isNa: true,
                            })}
                        </Flex>
                        <Flex value={0.1}></Flex>
                        <Flex value={1}>
                            <Label text="Facility" required={false} />
                            <FacilityDropDown
                                isDefaultEmpty={true}
                                allowEmpty={true}
                                selectedValue={data.facilityId || null}
                                onChange={(s: any) => {
                                    setData({ ...data, facilityId: s ? s.value : null });
                                }}
                            />
                        </Flex>
                    </Flex>
                    <Flex direction="row" justify="between" className={styles.formRow}>
                        <Flex value={1}>{renderTopLevelNaInput({ key: 'vehicleNumber', label: 'Unit Number' })}</Flex>
                        <Flex value={0.1}></Flex>
                        <Flex value={1}>{renderTopLevelInput({ key: 'vehicleIdNumber', label: 'Vehicle ID' })}</Flex>
                        <Flex value={0.1}></Flex>
                        <Flex value={1}>{renderTopLevelInput({ key: 'callSign', label: 'Call Sign' })}</Flex>
                    </Flex>
                    <Flex direction="row" justify="between" className={styles.formRow}>
                        <Flex value={1}>{renderTopLevelInput({ key: 'initialCost', label: 'Initial Cost' })}</Flex>
                        <Flex value={0.1}></Flex>
                        <Flex value={1}>{renderTopLevelNaInput({ key: 'modelYear', label: 'Model Year' })}</Flex>
                    </Flex>
                    <Flex direction="row" justify="between" className={styles.formRow}>
                        <Flex value={1} direction="column" className={styles.newRow}>
                            <span className={styles.heading}>Certification Levels</span>
                            {(data.vehicleCertificationLevels || []).map((c, index) => (
                                <Flex
                                    key={`${c.licensureLevelCode}${index}`}
                                    direction="column"
                                    className={styles.formRow}
                                >
                                    <Flex direction="row" justify="between" align="center" className={styles.formRow}>
                                        <Flex value={1}>
                                            {renderDropDown({
                                                key: 'vehicleCertificationLevels',
                                                label: 'Certification Level',
                                                type: OptionTypes.EMSStateCertificationLicensureLevels,
                                                nested: { row: c, key: 'licensureLevelCode', index },
                                            })}
                                        </Flex>
                                    </Flex>
                                    <Flex direction="row" justify="between" align="center" className={styles.formRow}>
                                        <Flex value={1}>
                                            <NemsisInput
                                                nemsisLabel="Normal 911"
                                                type="number"
                                                value={c.personnelCountNormal911Ambulance}
                                                onChangeText={(text: string) =>
                                                    updateNestedInput({
                                                        param: 'vehicleCertificationLevels',
                                                        subParam: 'personnelCountNormal911Ambulance',
                                                        index,
                                                        value: text,
                                                    })
                                                }
                                            />
                                        </Flex>
                                        <Flex value={0.1}></Flex>
                                        <Flex value={1}>
                                            <NemsisInput
                                                nemsisLabel="Norm. 911/Non Transp."
                                                type="number"
                                                value={c.personnelCountNormal911NonTransport}
                                                onChangeText={(text: string) =>
                                                    updateNestedInput({
                                                        param: 'vehicleCertificationLevels',
                                                        subParam: 'personnelCountNormal911NonTransport',
                                                        index,
                                                        value: text,
                                                    })
                                                }
                                            />
                                        </Flex>
                                        <Flex value={0.1}></Flex>
                                        <Flex value={1}>
                                            <NemsisInput
                                                nemsisLabel="Norm. Med (Non-911)"
                                                type="number"
                                                value={c.personnelCountNormalMedicalNon911}
                                                onChangeText={(text: string) =>
                                                    updateNestedInput({
                                                        param: 'vehicleCertificationLevels',
                                                        subParam: 'personnelCountNormalMedicalNon911',
                                                        index,
                                                        value: text,
                                                    })
                                                }
                                            />
                                        </Flex>
                                        <Flex value={0.7} align="end" justify="end">
                                            <Button
                                                type="primary"
                                                text="Remove"
                                                onClick={() => handleRemoveRow('vehicleCertificationLevels', index)}
                                            />
                                        </Flex>
                                    </Flex>
                                </Flex>
                            ))}
                            <Button
                                type="transparent"
                                text="+ Add Certification Level"
                                onClick={() => handleAddRow('vehicleCertificationLevels')}
                                className={styles.addUnderline}
                            />
                        </Flex>
                    </Flex>
                    <Flex direction="row" justify="between" className={styles.formRow}>
                        <Flex value={1} direction="column" className={styles.newRow}>
                            <span className={styles.heading}>Annual Hours/Miles</span>
                            {(data.annualUsage || []).map((c, index) => (
                                <Flex key={`${c.yearAccrued}${index}`} direction="column" className={styles.formRow}>
                                    <Flex direction="row" justify="between" align="center" className={styles.formRow}>
                                        <Flex value={1}>
                                            {renderDropDown({
                                                key: 'annualUsage',
                                                label: 'Year',
                                                type: OptionTypes.PreviousYears,
                                                nested: { row: c, key: 'yearAccrued', index },
                                            })}
                                        </Flex>
                                        <Flex value={0.1}></Flex>
                                        <Flex value={1}>
                                            <NemsisInput
                                                nemsisLabel="Vehicle Hours"
                                                type="number"
                                                value={c.vehicleHours}
                                                onChangeText={(text: string) =>
                                                    updateNestedInput({
                                                        param: 'annualUsage',
                                                        subParam: 'vehicleHours',
                                                        index,
                                                        value: text,
                                                    })
                                                }
                                            />
                                        </Flex>
                                        <Flex value={0.1}></Flex>
                                        <Flex value={1}>
                                            <NemsisInput
                                                nemsisLabel="Vehicle Miles"
                                                type="number"
                                                value={c.vehicleMilesKilometers}
                                                onChangeText={(text: string) =>
                                                    updateNestedInput({
                                                        param: 'annualUsage',
                                                        subParam: 'vehicleMilesKilometers',
                                                        index,
                                                        value: text,
                                                    })
                                                }
                                            />
                                        </Flex>
                                        <Flex value={0.1}></Flex>
                                        <Flex value={1}>
                                            {renderDropDown({
                                                key: 'annualUsage',
                                                label: 'Distance Unit',
                                                type: OptionTypes.EMSDistanceUnits,
                                                nested: { row: c, key: 'distanceUnit', index },
                                            })}
                                        </Flex>
                                        <Flex value={0.7} align="end" justify="end">
                                            <Button
                                                type="primary"
                                                text="Remove"
                                                onClick={() => handleRemoveRow('annualUsage', index)}
                                            />
                                        </Flex>
                                    </Flex>
                                </Flex>
                            ))}
                            <Button
                                type="transparent"
                                text="+ Add Annual Usage"
                                onClick={() => handleAddRow('annualUsage')}
                                className={styles.addUnderline}
                            />
                        </Flex>
                    </Flex>
                </Flex>
            </div>
            <ModalFooter
                primaryText={isEdit ? 'Save' : 'Create'}
                primaryClick={handleSave}
                secondaryClick={() => {
                    setData(utils.clone(defaultVehicle));
                    props.onClose();
                }}
            />
        </SideModal>
    );
}
