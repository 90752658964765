import { VitalsMeasure } from 'common/lib/entity/framework/VitalsMeasure';
import { VitalsMeasureCategory } from 'common/lib/entity/framework/VitalsMeasureCategory';
import { VitalsConnection } from 'common/lib/entity/org/VitalsConnection';
import { observer } from 'mobx-react';
import React, { useContext, useEffect, useState } from 'react';
import { ToastStoreObject, ToastType } from 'src/stores/ToastStore';
import VitalsStore from 'src/stores/VitalsStore';
import { parseError } from 'src/utils';
import { ANALYTICS_NAMES } from 'src/utils/analytics';
import { API_DATE_FORMAT } from 'src/utils/constants';
import FacilityDropDown from '../FacilityDropDown';
import Input, { Label } from '../Input';
import LoadingIcon from '../LoadingIcon';
import ModalFooter from '../ModalFooter';
import ModalHeader from '../ModalHeader';
import SideModal from '../SideModal';
import VitalsMeasureDropdown from '../VitalsMeasureDropdown';
import styles from './styles.module.scss';

interface VitalsConnectionModalProps {
    vitalsConnection?: VitalsConnection;
    mode?: string;
    onClose?: Function;
    onSave?: Function;
}

function VitalsConnectionModal(props: VitalsConnectionModalProps) {
    const vitalsStore = useContext(VitalsStore);
    const [isLoading, setIsLoading] = useState(false);
    const [vitalsConnection, setVitalsConnection] = useState<Partial<VitalsConnection>>(
        props.vitalsConnection ? props.vitalsConnection : {},
    );
    const [unitIdentifier, setUnitIdentifier] = useState<VitalsConnection['unitIdentifier']>(
        vitalsConnection ? vitalsConnection.unitIdentifier : '',
    );
    const [unitVendorName, setUnitVendorName] = useState<VitalsConnection['unitVendorName']>('NEXIMATIC');
    const [displayName, setDisplayName] = useState<VitalsConnection['displayName']>(
        vitalsConnection ? vitalsConnection.displayName : '',
    );
    const [facilityId, setFacilityId] = useState<VitalsConnection['facilityId']>(
        vitalsConnection ? vitalsConnection.facilityId : null,
    );
    const [connectionNotes, setConnectionNotes] = useState<VitalsConnection['connectionNotes']>(
        vitalsConnection ? vitalsConnection.connectionNotes : null,
    );
    const [measureCategoryDefaults, setMeasureCategoryDefaults] = useState<VitalsConnection['measureCategoryDefaults']>(
        vitalsConnection ? vitalsConnection.measureCategoryDefaults : {},
    );

    useEffect(() => {
        if (isEditing()) {
            updateVitalsConnectionLatest();
        }
    }, []);

    async function updateVitalsConnectionLatest() {
        if (vitalsConnection && vitalsConnection.connectionId && vitalsConnection.unitIdentifier) {
            setIsLoading(true);

            try {
                const latest = await vitalsStore.updateVitalsConnectionLatest(
                    vitalsConnection.connectionId,
                    vitalsConnection.unitIdentifier,
                );
                setVitalsConnection(latest);
            } catch (e) {
                ToastStoreObject.show(parseError(e));
            } finally {
                setIsLoading(false);
            }
        }
    }

    function isEditing(): boolean {
        return props.mode === 'edit';
    }

    function handleDefaultMeasureChange(
        measureCategoryCode: VitalsMeasureCategory['measureCategoryCode'],
        measureSelection: { value: VitalsMeasure['measureCode']; label: VitalsMeasure['measureTitle'] },
    ) {
        if (measureSelection && measureSelection.value) {
            const updatedDefaultMeasure = Object.assign(measureCategoryDefaults || {}, {
                [measureCategoryCode]: {
                    measureCode: measureSelection.value,
                    measureTitle: measureSelection.label,
                },
            });
            setMeasureCategoryDefaults(updatedDefaultMeasure);
        } else {
            const { [measureCategoryCode]: value, ...updatedDefaultMeasure } = measureCategoryDefaults;
            setMeasureCategoryDefaults(updatedDefaultMeasure);
        }
    }

    function getDefaultMeasures() {
        return (
            <>
                <div className={styles.title}>Measure Defaults</div>
                {vitalsStore.vitalsMeasureCategories.map((category: VitalsMeasureCategory) => (
                    <>
                        <Label text={category.measureCategoryTitle} />
                        <VitalsMeasureDropdown
                            selectedValue={
                                measureCategoryDefaults &&
                                measureCategoryDefaults[category.measureCategoryCode] &&
                                measureCategoryDefaults[category.measureCategoryCode].measureCode
                            }
                            measureCategoryId={category.measureCategoryId}
                            onChange={(e: any) => handleDefaultMeasureChange(category.measureCategoryCode, e)}
                        />
                    </>
                ))}
            </>
        );
    }

    function getLatestInfo() {
        if (isEditing) {
            return (
                <>
                    <div className={styles.title}>Latest Information</div>
                    <div className={styles.latest}>
                        {vitalsConnection && vitalsConnection.dataLastReceivedAt && (
                            <div className={styles.latestItem}>
                                <div className={styles.latestTitle}>Data Last Received</div>
                                <div className={styles.latestValue}>
                                    {vitalsConnection.dataLastReceivedAt.toString() || 'N/A'}
                                </div>
                            </div>
                        )}
                        {vitalsConnection && vitalsConnection.latestWifiSignalStrength && (
                            <div className={styles.latestItem}>
                                <div className={styles.latestTitle}>Latest Wifi Signal Strength</div>
                                <div className={styles.latestValue}>
                                    {vitalsConnection.latestWifiSignalStrength || 'N/A'}
                                </div>
                            </div>
                        )}
                    </div>
                </>
            );
        }

        return;
    }

    async function handleSave() {
        setIsLoading(true);

        try {
            if (isEditing()) {
                await vitalsStore.updateVitalsConnection(props.vitalsConnection.connectionId, {
                    displayName,
                    facilityId,
                    connectionNotes,
                    measureCategoryDefaults,
                    unitVendorName: 'NEXIMATIC', // Default to Neximatic for now. Can change this when we decide to add UI around this.
                });
            } else {
                await vitalsStore.createVitalsConnection({
                    unitIdentifier,
                    displayName,
                    facilityId,
                    connectionNotes,
                    isActive: false,
                    measureCategoryDefaults,
                    unitVendorName: 'NEXIMATIC', // Default to Neximatic for now. Can change this when we decide to add UI around this.
                });
            }
            ToastStoreObject.show(`${displayName} has been ${isEditing() ? 'updated' : 'added'}`, ToastType.Success);
            if (props.onSave) {
                props.onSave();
            }
            if (props.onClose) {
                props.onClose();
            }
        } catch (e: any) {
            ToastStoreObject.show(parseError(e));
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <SideModal isOpen={true} onClose={props.onClose}>
            <ModalHeader title={`${isEditing() ? 'Edit' : 'Create'} Vitals Connection`} onClose={props.onClose} />
            {isLoading ? (
                <div className={styles.loadingContentWrap}>
                    <LoadingIcon />
                </div>
            ) : (
                <div className={styles.contentWrap}>
                    <Input
                        label="Unit Identifier" // RIP: Idenfitifer
                        value={unitIdentifier}
                        disabled={props.mode === 'edit'}
                        onChangeText={(v: string) => setUnitIdentifier(v.trim())}
                        data-test-id={ANALYTICS_NAMES.VitalsConnectionModal_UnitIdentifier}
                    />
                    <Input
                        label="Unit Vendor Name"
                        value={unitVendorName}
                        disabled={true}
                        data-test-id={ANALYTICS_NAMES.VitalsConnectionModal_UnitVendorName}
                    />
                    <Input
                        label="Display Name"
                        value={displayName}
                        onChangeText={(v: string) => setDisplayName(v)}
                        data-test-id={ANALYTICS_NAMES.VitalsConnectionModal_DisplayName}
                    />
                    <>
                        <Label text="Facility" />
                        <FacilityDropDown
                            onChange={(f: any) => setFacilityId(f.value)}
                            selectedValue={facilityId}
                            data-test-id={ANALYTICS_NAMES.VitalsConnectionModal_FacilityId}
                        />
                    </>
                    <Input
                        label="Connection Notes"
                        value={connectionNotes}
                        onChangeText={(v: string) => setConnectionNotes(v)}
                        data-test-id={ANALYTICS_NAMES.VitalsConnectionModal_ConnectionNotes}
                    />
                    {getDefaultMeasures()}
                    {getLatestInfo()}
                </div>
            )}
            <ModalFooter
                primaryText={isEditing() ? 'Save' : 'Create'}
                primaryClick={handleSave}
                secondaryClick={() => props.onClose()}
                isLoading={isLoading}
            />
        </SideModal>
    );
}

export default observer(VitalsConnectionModal);
