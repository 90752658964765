import { observable, action, makeObservable } from 'mobx';
import { createContext } from 'react';
import Toast from 'src/components/Toast';

const TOAST_LENGTH = 5000;

export enum ToastType {
    'Error',
    'Success',
    'Warn',
}
class ToastStore {
    @observable message: string;
    @observable toastType: ToastType;
    @observable visible: boolean = false;
    toastTimeout: NodeJS.Timeout;

    constructor() {
        makeObservable(this);
    }

    @action
    show(message: string, type: ToastType = ToastType.Error) {
        this.toastType = type;
        this.message = message;
        this.visible = true;
        clearTimeout(this.toastTimeout);
        this.toastTimeout = setTimeout(() => this.hide(), TOAST_LENGTH);
    };

    @action
    hide() {
        this.visible = false;
        clearTimeout(this.toastTimeout);
    };
}

// Import this to any other store that needs to use a value from it
export const ToastStoreObject = new ToastStore();

export default createContext(ToastStoreObject);
