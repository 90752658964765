import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';

import ModalFooter from 'src/components/ModalFooter';
import ModalHeader from 'src/components/ModalHeader';
import SideModal from 'src/components/SideModal';
import Button from 'src/components/Button';
import FlatfileImporter from '@flatfile/adapter';
import Input, { Label } from '../Input';
import Flex from '../Flex';
import LoadingIcon from '../LoadingIcon';
import FacilityDropDown from '../FacilityDropDown';
import { UserStoreObject } from 'src/stores/UserStore';
import { ToastStoreObject } from 'src/stores/ToastStore';
import * as utils from 'src/utils';

import { ImportBatchStoreObject } from 'src/stores/ImportBatchStore';
import { createImportBatch } from 'src/api/importBatch';
import { ImportBatch, ImportBatchTemplate } from 'src/utils/types';
import styles from './styles.module.scss';
import * as variables from 'src/styles/variables';
import { ErrorStoreObject, ErrorTypes } from 'src/stores/ErrorStore';
import { toJS } from 'mobx';
import { ICONS } from 'src/utils/constants';
import Icon from '../Icon';
import { ANALYTICS_NAMES } from 'src/utils/analytics';

export interface CreateImportModalProps {
    onClose: Function;
}

function CreateImportModal(props: CreateImportModalProps) {
    const [isLoading, setIsLoading] = useState(false);
    const [modalState, setModalState] = useState<'template' | 'import'>('template');
    const [selectedTemplate, setSelectedTemplate] = useState<ImportBatchTemplate>(null);
    const [title, setTitle] = useState<ImportBatch['importBatchTitle']>('');
    const [facilityId, setFacilityId] = useState<ImportBatch['facilityId']>(null);
    const [importer, setImporter] = useState<FlatfileImporter>(null);

    useEffect(() => {
        getTemplates();
    }, []);

    useEffect(() => {
        if (selectedTemplate) {
            const targetDataModel = toJS(selectedTemplate.targetDataModel);
            const formattedDataModel = targetDataModel.map((data: any) => {
                const defaultField = {
                    key: data.key,
                    label: data.label,
                };

                if (data.validators) {
                    const validators = data.validators.map((validator: { regex?: string; validate: string }) => {
                        if (validator.regex) {
                            return Object.assign(validator, { regex: validator.regex });
                        }

                        return validator;
                    });
                    return Object.assign(defaultField, { validators });
                }

                return defaultField;
            });

            const flatfileKey: string = import.meta.env.VITE_FLATFILE_KEY.toString();
            const flatfileImporter = new FlatfileImporter(flatfileKey, {
                type: selectedTemplate.importBatchTemplateTitle,
                fields: formattedDataModel,
                devMode: import.meta.env.VITE_APP_ENV !== 'pro' ? true : false,
                styleOverrides: {
                    primaryButtonColor: variables.red,
                    secondaryButtonColor: variables.lightGrey4,
                    secondaryButtonFontColor: variables.black,
                    linkColor: variables.red,
                    fontFamily: variables.fontFamily,
                },
            });
            flatfileImporter.setCustomer({ userId: UserStoreObject.orgUsrId.toString() });
            flatfileImporter.addListener('close', () => {
                setFacilityId(null);
                setIsLoading(false);
            });

            setImporter(flatfileImporter);
        }
    }, [selectedTemplate]);

    async function getTemplates() {
        try {
            setIsLoading(true);
            await ImportBatchStoreObject.getImportBatchTemplates(true, true);
            setIsLoading(false);
        } catch (e) {
            ErrorStoreObject.setError(ErrorTypes.Loading);
        }
    }

    async function handleCreate(records: any[], fileName: string) {
        try {
            setIsLoading(true);
            await createImportBatch({
                importBatchTemplateGuid: selectedTemplate.importBatchTemplateGuid,
                importBatchTitle: title ? title : fileName,
                importBatchRecords: records,
                facilityId,
            });
            setIsLoading(false);
            props.onClose(true);
        } catch (e) {
            ToastStoreObject.show(utils.parseError(e));
        }
    }

    async function handleFlatfileLaunch() {
        if (importer) {
            setIsLoading(true);

            const results = await importer.requestDataFromUser();
            if (results) {
                const flatfileRecords = results.validData;
                const flatfileFileName = results.fileName
                    ? results.fileName.substr(0, results.fileName.lastIndexOf('.'))
                    : 'Untitled Import';

                if (flatfileRecords.length) {
                    handleCreate(flatfileRecords, flatfileFileName);
                    importer.close();
                } else {
                    setIsLoading(false);
                    ToastStoreObject.show('No records found, please retry and ensure valid records are imported.');
                }
            }
        }
    }

    function getTemplateState() {
        if (isLoading) {
            return (
                <Flex align="center" justify="center">
                    <LoadingIcon />
                </Flex>
            );
        }

        if (modalState === 'import') {
            return getTemplateImport();
        }

        return getTemplateButtons();
    }

    function getTemplateButtons() {
        return ImportBatchStoreObject.importBatchTemplates.map((template: ImportBatchTemplate, index: number) => (
            <div className={styles.templateContainer}>
                <div className={styles.importTitle}>{template.importBatchTemplateTitle}</div>
                <div className={styles.importDescription}>{template.importBatchTemplateDescription}</div>
                <div className={styles.button}>
                    <Button
                        key={index}
                        type="small"
                        text="Select"
                        data-test-id={ANALYTICS_NAMES.CreateImportModal_Template}
                        onClick={() => {
                            setSelectedTemplate(template);
                            setModalState('import');
                        }}
                    />
                </div>
            </div>
        ));
    }

    function getTemplateImport() {
        return (
            <div>
                <div className={styles.labelWrap}>
                    <Label text="Facility" />
                </div>
                <FacilityDropDown
                    isDefaultEmpty={true}
                    allowEmpty={true}
                    onChange={(v: any) => setFacilityId(v ? v.value : null)}
                    data-test-id={ANALYTICS_NAMES.CreateImportModal_Facility}
                />
                <Input
                    label="Title (If left blank, file name will be used)"
                    value={title}
                    onChangeText={(v: string) => setTitle(v)}
                    data-test-id={ANALYTICS_NAMES.CreateImportModal_Title}
                />
                <Flex justify="end" align="center" className={styles.downloadTemplate}>
                    <Icon name={ICONS.Download} size={18} color={variables.red} />
                    <a
                        href={`https://help.graphiumhealth.com/how-to-download-a-csv-template-for-imports`}
                        target="_blank"
                    >
                        <div className={styles.templateText}>Download CSV Template</div>
                    </a>
                </Flex>
            </div>
        );
    }

    return (
        <SideModal isOpen={true}>
            <ModalHeader title="Create an Import Batch" onClose={() => props.onClose()} />
            <div className={styles.contentWrap}>{getTemplateState()}</div>
            <ModalFooter
                primaryText="Create"
                primaryClick={handleFlatfileLaunch}
                secondaryClick={() => props.onClose()}
                primaryProps={{ disabled: !facilityId ? true : false }}
            />
        </SideModal>
    );
}

export default observer(CreateImportModal);
