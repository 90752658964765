import React, { useContext, useEffect, useState } from 'react';
import styles from './styles.module.scss';
import Input, { Label } from 'src/components/Input';
import { Payer } from 'src/stores/PaymentsStore';
import PayerCategoryDropDown from 'src/components/PayerCategoryDropDown';
import { ANALYTICS_NAMES } from 'src/utils/analytics';
import SideModal from '../SideModal';
import ModalHeader from '../ModalHeader';
import ModalFooter from '../ModalFooter';
import PaymentsStore from 'src/stores/PaymentsStore';
import { ToastStoreObject, ToastType } from 'src/stores/ToastStore';
import { parseError } from 'src/utils';

interface PayerModalProps {
    payer: Payer;
    mode?: string;
    onClose?: Function;
    onSave?: Function;
}

function PayerModal(props: PayerModalProps) {
    const paymentsStore = useContext(PaymentsStore);
    const [name, setName] = useState(props.payer ? props.payer.payerName : '');
    const [payerCategory, setPayerCategory] = useState(props.payer ? props.payer.payerCategoryId : null);
    const [isLoading, setIsLoading] = useState(false);

    async function handleSave() {
        setIsLoading(true);

        try {
            if (props.mode === 'edit') {
                await paymentsStore.updatePayer(props.payer.payerId, { payerCategoryId: payerCategory });
                ToastStoreObject.show(`${name} has been updated`, ToastType.Success);
            } else {
                await paymentsStore.createPayer({
                    payerName: name,
                    payerCategoryId: payerCategory,
                });
                ToastStoreObject.show(`New payer "${name}" has been created`, ToastType.Success);
            }
        } catch (e) {
            ToastStoreObject.show(parseError(e));
        } finally {
            if (props.onSave) {
                props.onSave();
            }
            if (props.onClose) {
                props.onClose();
            }
            setIsLoading(false);
        }
    }

    return (
        <SideModal isOpen={true} onClose={props.onClose}>
            <ModalHeader
                title={`${props.mode === 'edit' ? `Edit ${name}` : 'Create Payer'}`}
                onClose={() => props.onClose()}
            />
            <div className={styles.contentWrap}>
                <Input
                    label="Payer Name"
                    disabled={props.mode === 'edit'}
                    value={name}
                    onChangeText={(value: string) => setName(value)}
                />
                <>
                    <Label text="Category" />
                    <PayerCategoryDropDown
                        onChange={(category: any) => {
                            setPayerCategory(category.value);
                        }}
                        selectedValue={payerCategory}
                        data-test-id={ANALYTICS_NAMES.PayerModal_Category}
                        useCategoryIds={true}
                    />
                </>
            </div>
            <ModalFooter
                primaryText={props.mode === 'edit' ? 'Save' : 'Create'}
                primaryClick={handleSave}
                secondaryClick={() => props.onClose()}
                isLoading={isLoading}
            />
        </SideModal>
    );
}

export default PayerModal;
