import React from 'react';
import ModalHeader from 'src/components/ModalHeader';
import ModalFooter, { ModalFooterProps } from '../ModalFooter';
import CenterModal from '../CenterModal';

export interface ViewContentModalProps {
    staticContent: React.ReactNode;
    onClose: Function;
    title?: string;
    footerProps?: Partial<ModalFooterProps>;
}
export function ViewContentModal(props: ViewContentModalProps) {
    return (
        <CenterModal isOpen={true} onClose={() => props.onClose()}>
            <ModalHeader title={props.title} onClose={() => props.onClose()} />
            {props.staticContent}
            <ModalFooter
                primaryText="Ok"
                primaryClick={() => props.onClose()}
                showSecondary={false}
                {...props.footerProps}
            />
        </CenterModal>
    );
}

export default ViewContentModal;
