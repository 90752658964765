import React, { useState, useContext, useEffect, useRef } from 'react';
import PageContainer from 'src/components/PageContainer';
import NavBar from 'src/containers/NavBar';
import styles from './styles.module.scss';
import PageContent from 'src/components/PageContent';
import Flex from 'src/components/Flex';
import Table from 'src/components/Table';
import Icon from 'src/components/Icon';
import Gender from 'src/components/Gender';
import Button from 'src/components/Button';
import { ICONS, ROUTES } from 'src/utils/constants';
import { CellInfo } from 'react-table';
import * as telehealthService from 'src/api/telehealth';
import FilterStore from 'src/stores/FilterStore';
import Age from 'src/components/Age';
import { ErrorStoreObject, ErrorTypes } from 'src/stores/ErrorStore';
import { useHistory } from 'react-router';
import { ModalStoreObject, ModalTypes } from 'src/stores/ModalStore';
import * as utils from 'src/utils';
import DateComponent from 'src/components/DateComponent';
import FacilityDropDown from 'src/components/FacilityDropDown';
import { TelehealthSession, SessionStateCodes } from 'src/utils/types';
import { ToastStoreObject, ToastType } from 'src/stores/ToastStore';
import TabNav from 'src/components/TabNav';
import { observer } from 'mobx-react';
import { ANALYTICS_NAMES } from 'src/utils/analytics';

const FILTER_PAGE = 'telehealthPage';

function TelehealthWaitingRoom() {
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(true);
    const [sessionState, setSessionState] = useState([]);
    const [allSessions, setAllSessions] = useState([]);
    const filterStore = useContext(FilterStore);
    const [completeVisitLoading, setCompleteVisitLoading] = useState(false);
    const pollInterval = useRef<any>(null);
    const tabRef = useRef(null);
    const telehealthFilters: any = filterStore[FILTER_PAGE];

    const columns = [
        {
            Header: 'URGENCY',
            accessor: 'priorityCode',
            minWidth: 75,
            sortMethod: (a: string, b: string) => {
                if (a === b) return 0;
                if (a === 'URGENT') return 1;
                if (a === 'HIGH' && (b === 'MEDIUM' || b === 'LOW')) return 1;
                if (a === 'MEDIUM' && b === 'LOW') return 1;
                return -1;
            },
            Cell: (props: CellInfo) => {
                switch (props.value) {
                    case 'URGENT':
                        return <Icon title="Urgent" name={ICONS.UrgencyUrgent} />;
                    case 'HIGH':
                        return <Icon title="High" name={ICONS.UrgencyHigh} />;
                    case 'MEDIUM':
                        return <Icon title="Medium" name={ICONS.UrgencyMedium} />;
                    case 'LOW':
                        return <Icon title="Low" name={ICONS.UrgencyLow} />;
                    default:
                        return <Icon title="Medium" name={ICONS.UrgencyMedium} />;
                }
            },
        },
        {
            Header: 'STATUS',
            accessor: 'telehealthSessionState',
            minWidth: 160,
            Cell: (props: CellInfo) => {
                return (
                    <span>
                        {utils.getTelehealthStatusExplanation(
                            (props.value || {}).telehealthSessionStateCode,
                            props.original.telehealthSessionDetail,
                        )}
                    </span>
                );
            },
        },
        {
            Header: 'PATIENT',
            minWidth: 150,
            Cell: (props: CellInfo) => (
                <Flex direction="row">
                    <span>{`${utils.getTeleheathPatientInfo(
                        props.original,
                        'lastName',
                    )}, ${utils.getTeleheathPatientInfo(props.original, 'firstName')}`}</span>
                    <Age
                        style={{ marginLeft: 5, marginRight: 5 }}
                        dob={utils.getTeleheathPatientInfo(props.original, 'dob')}
                    />
                    <Gender value={utils.getTeleheathPatientInfo(props.original, 'gender')} />
                </Flex>
            ),
        },
        {
            Header: 'CREATED ON',
            accessor: 'createTime',
            minWidth: 125,
            Cell: (props: CellInfo) => <DateComponent date={props.value} showDateTime={true} />,
        },
        {
            Header: 'REASON',
            accessor: 'telehealthSessionReason',
            minWidth: 250,
            style: { whiteSpace: 'unset' },
            Cell: (props: CellInfo) => <div style={{ padding: '10px 0' }}>{props.value}</div>,
        },
        {
            Header: 'ACTIONS',
            minWidth: 300,
            style: { paddingVertical: 0, paddingHorizontal: 20 },
            Cell: (props: CellInfo) => {
                const stateCode = ((props.original || {}).telehealthSessionState || {}).telehealthSessionStateCode;
                const canCancel = ![
                    SessionStateCodes.SESSION_CANCELED_BY_INITIATOR,
                    SessionStateCodes.SESSION_CANCELED_BY_RESPONDER,
                    SessionStateCodes.CONNECTION_STARTED,
                    SessionStateCodes.CONNECTION_INTERRUPTED,
                    SessionStateCodes.CONNECTION_ENDED,
                    SessionStateCodes.SESSION_COMPLETED,
                ].includes(stateCode);
                const canComplete = [
                    SessionStateCodes.CONNECTION_STARTED,
                    SessionStateCodes.CONNECTION_INTERRUPTED,
                    SessionStateCodes.CONNECTION_ENDED,
                ].includes(stateCode);
                return (
                    <Flex direction="row">
                        <Button
                            type="primary"
                            text="Open"
                            onClick={() =>
                                history.push(
                                    ROUTES.getString(ROUTES.TelehealthSession, props.original.telehealthSessionId),
                                )
                            }
                            className={styles.cancelBtn}
                        />
                        {canCancel ? (
                            <Button
                                type="secondary"
                                text="Cancel Visit"
                                onClick={() => cancelSession(props.original.telehealthSessionId)}
                                className={styles.cancelBtn}
                                data-test-id={ANALYTICS_NAMES.Telehealth_CancelVisit}
                            />
                        ) : null}
                        {canComplete ? (
                            <Button
                                type="secondary"
                                text="Complete Visit"
                                isLoading={completeVisitLoading}
                                onClick={async () => {
                                    try {
                                        setCompleteVisitLoading(true);
                                        await telehealthService.completeTelehealthSession(
                                            props.original.telehealthSessionId,
                                        );
                                    } catch {
                                        ToastStoreObject.show('There was an error completing the visit.');
                                        setCompleteVisitLoading(false);
                                    } finally {
                                        setCompleteVisitLoading(false);
                                    }
                                }}
                                className={styles.cancelBtn}
                                data-test-id={ANALYTICS_NAMES.Telehealth_CompleteVisit}
                            />
                        ) : null}
                    </Flex>
                );
            },
        },
    ];

    async function cancelSession(telehealthSessionId: TelehealthSession['telehealthSessionId']) {
        ModalStoreObject.showModal(ModalTypes.CancelReason, {
            onClose: () => {
                ModalStoreObject.hideModal();
            },
            onSave: async (cancelReason: string) => {
                try {
                    await telehealthService.cancelTelehealthSession(telehealthSessionId.toString(), cancelReason);
                    ToastStoreObject.show('Successfully canceled Telehealth Visit', ToastType.Success);
                    getSessions();
                } catch (e) {
                    ToastStoreObject.show(utils.parseError(e));
                } finally {
                    ModalStoreObject.hideModal();
                }
            },
        });
    }

    async function getSessions(noLoadingState = false) {
        try {
            if (!noLoadingState) {
                setIsLoading(true);
            }
            const sessions = await telehealthService.getTelehealthSessions(telehealthFilters);
            const sessionsToSet = sessions.data || [];
            setAllSessions(sessionsToSet);
            if (filterStore[FILTER_PAGE].facility) {
                setSessionState(
                    sessionsToSet.filter((s: TelehealthSession) =>
                        filterStore[FILTER_PAGE].facility.includes(s.encounter.facId),
                    ),
                );
            }
            setIsLoading(false);
        } catch (e) {
            ErrorStoreObject.setError(ErrorTypes.Loading);
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getSessions();
        pollInterval.current = setInterval(() => getSessions(true), 10000);
        return () => {
            clearInterval(pollInterval.current);
        };
    }, []);

    const handleFilterChange = function(type: string, filterVals: { label: string; value: string }[]) {
        let newFilters: any[] = [];
        if (
            filterVals &&
            filterVals.length !== 0 &&
            filterVals[0] &&
            filterVals[0].value !== undefined &&
            filterVals[0].value !== null
        ) {
            newFilters = filterVals.map((f) => f.value);
        }
        const updatedFilters = { ...filterStore[FILTER_PAGE] };
        if (type === 'facility') {
            updatedFilters.facility = newFilters;
        }

        filterStore.setFilters(FILTER_PAGE, updatedFilters);
        const newSessionState = allSessions.filter((s) => updatedFilters.facility.includes(s.encounter.facId));
        setSessionState(newSessionState);
    };

    const facilityId = filterStore[FILTER_PAGE].facility[0];

    return (
        <PageContainer>
            <NavBar />
            <PageContent>
                <div className={styles.tabContainer}>
                    <TabNav
                        tabs={['Waiting Room', 'Telehealth Visits']}
                        onTabClick={(tab: string) => {
                            if (tab === 'Waiting Room') {
                                return;
                            } else {
                                history.push(ROUTES.getString(ROUTES.TelehealthVisits));
                            }
                        }}
                        selectedTabIndex={0}
                        ref={tabRef}
                    />
                </div>
                <Flex self="stretch" align="center" justify="start" className={styles.headerWrap}>
                    <Flex value={1} className={styles.filterWrap}>
                        <div style={{ maxWidth: 400 }}>
                            <FacilityDropDown
                                selectedValue={facilityId}
                                onChange={(filters: any) => handleFilterChange('facility', [filters])}
                                placeholder="Filter by Facility"
                            />
                        </div>
                    </Flex>

                    <Button
                        type="secondary"
                        onClick={() => {
                            ModalStoreObject.showModal(ModalTypes.Encounter, {
                                onClose: () => {
                                    ModalStoreObject.hideModal();
                                },
                                title: `Create a new Telehealth Visit`,
                                isOpen: true,
                                onSave: async () => {
                                    ModalStoreObject.hideModal();
                                    getSessions();
                                },
                                isAdHoc: true,
                                isTelehealth: true,
                                facId: filterStore[FILTER_PAGE].facility[0],
                            });
                        }}
                        text="Invite Patient"
                        style={{ marginRight: 10 }}
                    />
                    <Button
                        type="primary"
                        onClick={async () => {
                            getSessions();
                        }}
                        text="Refresh"
                    />
                </Flex>
                <div>
                    <div className={styles.tableWrap}>
                        <div className={styles.tableTitle}>Telehealth Waiting Room</div>
                        <Table
                            isLoading={isLoading}
                            columns={columns}
                            noDataText={'No Telehealth Visits Found'}
                            data={sessionState}
                            showPagination={false}
                            defaultSorted={[
                                {
                                    id: 'telehealthSessionState',
                                    desc: true,
                                },
                                {
                                    id: 'createTime',
                                    desc: true,
                                },
                            ]}
                        />
                    </div>
                </div>
            </PageContent>
        </PageContainer>
    );
}
export default observer(TelehealthWaitingRoom);
