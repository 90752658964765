import React, { useContext, useEffect, useRef, useState } from 'react';
import Flex from 'src/components/Flex';
import * as variables from 'src/styles/variables';
import Button from 'src/components/Button';
import classNames from 'classnames';
import CsvDownloader from 'react-csv-downloader';
import styles from 'src/containers/Reports/Macra/styles.module.scss';
import { ModalStoreObject, ModalTypes } from 'src/stores/ModalStore';
import { SurveyDetailModal } from 'src/containers/Reports/Macra/Modals/SurveyDetailModal';
import Table from 'src/components/Table';
import Input from 'src/components/Input';
import { ICONS } from 'src/utils/constants';
import { SurveyDefinitionContent } from 'common/lib/model/surveys/Survey';
import { isNumber } from 'src/utils';
import { CellInfo, Column, FinalState } from 'react-table';
import MacraStore from 'src/stores/MacraStore';
import { PercentageDetailCell } from 'src/components/CellRenderers/PercentageDetailCell';
import { observer } from 'mobx-react';

function PercentSurveys(props: { total: number; value: number }) {
    if (!isNumber(props.value) || !isNumber(props.total)) {
        return <Flex direction="column">-</Flex>;
    }
    const percent = props.total > 0 ? props.value / props.total : 0;
    return <PercentageDetailCell data={{ value: percent, n: props.value, d: props.total }} />;
}

function PatientSurveyTab() {
    const macraStore = useContext(MacraStore);
    const surveyTable = useRef(null);

    const [isLoading, setIsLoading] = useState(false);
    const [patientSurveyColumns, setPatientSurveyColumns] = useState([]);
    const [patientSurveyTableFilter, setPatientSurveyTableFilter] = useState('');
    const [csvHeaders, setCsvHeaders] = useState(null);
    const [csvData, setCsvData] = useState(null);

    useEffect(() => {
        loadProviderSurveys();
    }, [macraStore.selectedId, macraStore.startDate, macraStore.endDate]);

    useEffect(() => {
        if (
            macraStore.providerSurveys &&
            macraStore.providerSurveys.surveyDefinitionContent &&
            patientSurveyColumns.length === 0
        ) {
            setPatientSurveyColumns(getSurveyColumns(macraStore.providerSurveys.surveyDefinitionContent));
        }
    }, [macraStore.providerSurveys]);

    useEffect(() => {
        updateCsvData();
    }, [macraStore.byProviderSurveyData, patientSurveyTableFilter]);

    function updateCsvData() {
        if (!surveyTable.current) return;

        const { columns, sortedData } = surveyTable.current.getResolvedState() as FinalState;

        const headers = columns.map((column) => ({
            displayName: typeof column.Header === 'function' ? column.id || '-' : column.Header,
            id: column.id ? column.id : column.accessor,
        }));

        setCsvHeaders(headers);
        setCsvData(sortedData);
    }

    async function loadProviderSurveys() {
        try {
            setIsLoading(true);
            await macraStore.getProviderSurveys();
        } finally {
            setIsLoading(false);
        }
    }

    function getSurveyColumns(surveyDefinitionContent: SurveyDefinitionContent) {
        const columnArr: Column<any>[] = [
            {
                Header: 'PROVIDER',
                accessor: 'provider',
                minWidth: 150,
                headerClassName: styles.tableHeader,
                className: styles.tableFontSize,
            },
            {
                Header: 'SURVEYS',
                accessor: 'total',
                minWidth: 75,
                headerClassName: classNames(styles.tableHeader, styles.alignCenter),
                className: styles.tableFontSize,
                Cell: (props: CellInfo) => <div style={{ textAlign: 'center' }}>{props.value}</div>,
            },
            {
                Header: '% OPENED',
                id: 'totalOpened',
                accessor: (row) =>
                    Math.round(isNumber(row.total) && row.total > 0 ? ((row.totalOpened || 0) / row.total) * 100 : 0),
                minWidth: 125,
                headerClassName: classNames(styles.tableHeader, styles.alignCenter),
                className: styles.tableFontSize,
                Cell: (props: CellInfo) => (
                    <PercentSurveys value={props.original.totalOpened} total={props.original.total} />
                ),
            },
            {
                Header: '% STARTED',
                id: 'totalStarted',
                accessor: (row) =>
                    Math.round(isNumber(row.total) && row.total > 0 ? ((row.totalStarted || 0) / row.total) * 100 : 0),
                minWidth: 125,
                headerClassName: classNames(styles.tableHeader, styles.alignCenter),
                className: styles.tableFontSize,
                Cell: (props: CellInfo) => (
                    <PercentSurveys value={props.original.totalStarted} total={props.original.total} />
                ),
            },
            {
                Header: '% COMPLETED',
                id: 'totalCompleted',
                accessor: (row) =>
                    Math.round(
                        isNumber(row.total) && row.total > 0 ? ((row.totalCompleted || 0) / row.total) * 100 : 0,
                    ),
                minWidth: 125,
                headerClassName: classNames(styles.tableHeader, styles.alignCenter),
                className: styles.tableFontSize,
                Cell: (props: CellInfo) => (
                    <PercentSurveys value={props.original.totalCompleted} total={props.original.total} />
                ),
            },
        ];

        const fields = surveyDefinitionContent.fields || [];
        fields.forEach((f, index) => {
            const isYesNo =
                f.options &&
                f.options.length === 2 &&
                f.options[0].label === 'Yes' &&
                f.options[1].label === 'No' &&
                f.options[0].value === 1 &&
                f.options[1].value === 2;

            columnArr.push({
                Header: () => <span title={f.title}>QUESTION {index + 1}</span>,
                id: `Q${f.id}`,
                accessor: (row) => row.data[index],
                minWidth: 125,
                headerClassName: classNames(styles.tableHeader, styles.alignCenter),
                className: classNames(styles.tableFontSize, styles.alignCenter),
                Cell: (props: CellInfo) => {
                    // val could be a string in which case the < and > values will not get hit
                    const val = props.original.data[index];
                    if (isYesNo) {
                        return <span title={val}>{val >= 1.5 ? 'Leans No' : val < 1.5 ? 'Leans Yes' : '-'}</span>;
                    }
                    return (
                        <span title={val} className={classNames({ [styles.red]: val < 4, [styles.green]: val >= 4 })}>
                            {val}
                        </span>
                    );
                },
            });
        });

        return columnArr;
    }

    return (
        <div className={styles.tabContent}>
            {macraStore.providerSurveys && (
                <Flex direction="row" align="center" justify="end">
                    <Button
                        type="outline"
                        text="Survey Details"
                        onClick={() => {
                            const survey = (macraStore.providerSurveys.surveys || [])[0];
                            let title = '';
                            if (
                                survey &&
                                survey.surveyDefinitionHistory &&
                                survey.surveyDefinitionHistory.surveyDefinitionTitle
                            ) {
                                title = survey.surveyDefinitionHistory.surveyDefinitionTitle;
                            }
                            ModalStoreObject.showModal(ModalTypes.ViewContent, {
                                onClose: () => ModalStoreObject.hideModal(),
                                staticContent: (
                                    <SurveyDetailModal
                                        title={title}
                                        surveyDefinitionContent={macraStore.providerSurveys.surveyDefinitionContent}
                                    />
                                ),
                                title: `Survey Details`,
                            });
                        }}
                        className={styles.surveyDetailsBtn}
                    />
                    {csvData && csvData.length > 0 ? (
                        <CsvDownloader
                            className={styles.wrapRight}
                            filename={'SurveysByProviderCSV.csv'}
                            datas={csvData}
                            columns={csvHeaders}
                        >
                            <Button onClick={null}> Download CSV</Button>
                        </CsvDownloader>
                    ) : null}
                    <Input
                        icon={ICONS.Search}
                        iconColor={variables.grey}
                        iconSize={20}
                        placeholder="Filter Table"
                        value={patientSurveyTableFilter}
                        onChangeText={(text: string) => {
                            setPatientSurveyTableFilter(text);
                        }}
                    />
                </Flex>
            )}
            <div className={styles.tableWrap}>
                <Table
                    ref={surveyTable}
                    isLoading={isLoading}
                    columns={patientSurveyColumns}
                    data={macraStore.byProviderSurveyData}
                    showPagination={true}
                    globalFilter={patientSurveyTableFilter}
                />
            </div>
        </div>
    );
}

export default observer(PatientSurveyTab);
