import React, { useState } from 'react';
import styles from './styles.module.scss';
import Input, { Label } from 'src/components/Input';
import Checkbox from 'src/components/Checkbox';
import { useEffect } from 'react';
import { ValueSet, ValueSetDetail } from 'src/stores/ValueSetStore';

interface Props {
    valueSet: ValueSet;
    valueSetDetail?: ValueSetDetail;
    onChange: Function;
}

function ValueSetDetailModal(props: Props) {
    const [values, setValues] = useState<any>(props.valueSetDetail?.valueList || {});

    useEffect(() => {
        props.onChange({
            valueList: values,
        });
    }, [values]);

    function getAttributes(attributes: string[]) {
        return attributes?.map((attribute) => {
            const attributeWithType = attribute.split('|');
            return {
                name: attributeWithType[0],
                type: attributeWithType[1],
            };
        });
    }

    function handleValueChange(propertyName: string, value: string | number | boolean) {
        const updatedValue = typeof value === 'boolean' ? value.toString() : value;
        const updatedValues = { ...values, [propertyName]: updatedValue };

        setValues(updatedValues);
    }

    function getBooleanValue(values: any, attributeName: string): boolean {
        if (!values || !values[attributeName]) {
            values[attributeName] = 'false';
        }

        return values[attributeName].toLowerCase() === 'true';
    }

    function attributeTypeIsNumber(attributeType: string) {
        const lowerCaseType = attributeType.toLowerCase();

        return lowerCaseType === 'bigint' || lowerCaseType === 'int';
    }

    function attributeTypeIsString(attributeType: string) {
        const lowerCaseType = attributeType.toLowerCase();

        return lowerCaseType === 'varchar' || lowerCaseType === 'text';
    }

    function attributeTypeIsBoolean(attributeType: string) {
        const lowerCaseType = attributeType.toLowerCase();

        return lowerCaseType === 'boolean';  
    }

    function getValueSetDetailFields() {
        const attributes = getAttributes(props.valueSet.attributeList || []);

        const valueList = attributes.map((attribute, index) => {
            const { type, name } = attribute;
            const attributeValue = attributeTypeIsBoolean(type)
                ? getBooleanValue(values, name)
                : values[name]  || '';
            if (attributeTypeIsString(type) || attributeTypeIsNumber(type)) {
                return (
                    <Input
                        key={index}
                        type={attributeTypeIsNumber(type) ? 'number' : 'text'}
                        step={0.01}
                        label={name}
                        value={attributeValue}
                        onChangeText={(value: string) => handleValueChange(name, value)}
                    />
                );
            } else if (attributeTypeIsBoolean(type)) {
                return (
                    <div key={index} className={styles.labelWrap}>
                        <Label text={name} />
                        <Checkbox
                            checked={attributeValue}
                            onChange={(e: any) => handleValueChange(name, e.target.checked)}
                            label={name}
                            className={styles.checkbox}
                        />
                    </div>
                );
            }
        });

        return valueList;
    }

    return <div className={styles.contentWrap}>{getValueSetDetailFields()}</div>;
}

export default ValueSetDetailModal;
