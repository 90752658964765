import { observable, action, makeObservable } from 'mobx';
import { SurveyTemplate } from 'common/lib/entity/framework/SurveyTemplate';
import * as surveyTemplateService from 'src/api/surveyTemplate';
import { createContext } from 'react';

class SurveyStore {
    @observable surveyTemplates: SurveyTemplate[] = [];
    @observable totalSurveyTemplateCount: number = 0;

    constructor() {
        makeObservable(this);
    }

    @action
    reset() {
        this.surveyTemplates = [];
    }

    @action
    async getSurveyTemplates(force = false) {
        if (!force && this.surveyTemplates.length > 0) {
            return;
        }

        const { result, count } = await surveyTemplateService.getSurveyTemplates();
        this.setSurveyTemplates(result || [], count);
    }

    @action
    setSurveyTemplates(surveyTemplates: SurveyTemplate[], total: number = 0) {
        this.surveyTemplates = surveyTemplates;
        this.totalSurveyTemplateCount = total;
    }
}
export const SurveyStoreObject = new SurveyStore();

export default createContext(SurveyStoreObject);