import { FacilityPrimaryUse } from 'common/lib/model/facility/FacilitySubscriptionData';
import { observer } from 'mobx-react';
import React from 'react';
import { useState } from 'react';
import Select from 'react-select';
import { styleSelectComponent } from 'src/utils';
import styles from '../styles.module.scss';

interface PrimaryUseDropdownProps {
    onChange: Function;
    selectedValue?: FacilityPrimaryUse;
}

function PrimaryUseDropdown(props: PrimaryUseDropdownProps) {
    const options = mapPrimaryUseOptions();
    const [selected, setSelected] = useState(options.find((i) => i.value === props.selectedValue) || null);

    function mapPrimaryUseOptions() {
        const uses = Object.values(FacilityPrimaryUse);
        const useOptions = uses.map((value) => {
            let label;
            switch (value) {
                case FacilityPrimaryUse.AnesthesiaEmr:
                    label = 'Anesthesia EMR';
                    break;
                case FacilityPrimaryUse.Macra:
                    label = 'MACRA';
                    break;
                case FacilityPrimaryUse.ChargeCapture:
                    label = 'Charge Capture';
                    break;
                case FacilityPrimaryUse.CHP:
                    label = 'CHP';
                    break;
                case FacilityPrimaryUse.EMS:
                    label = 'EMS';
                    break;
            }

            return { label, value };
        });

        return useOptions.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
    }

    return (
        <Select
            value={selected}
            onChange={(s: any) => {
                setSelected(s);
                props.onChange(s || null);
            }}
            isClearable={true}
            className={styles.select}
            options={options}
            placeholder="Select Primary Use Case"
            styles={styleSelectComponent()}
        />
    );
}

export default observer(PrimaryUseDropdown);
