import { PaymentTypeConfig } from 'common/lib/model/payment/paymentTypeConfig';
import { observable, action, makeObservable } from 'mobx';
import { createContext } from 'react';
import * as paymentsService from 'src/api/payments';
import { formatSelectOptions } from 'src/utils';
import { Encounter, FilterQueryParams } from 'src/utils/types';
import { Facility } from './FacilityStore';

export interface PaymentRequest {
    paymentRequestGuid: string;
    paymentRequestStateId: number;
    paymentTypeId: number | string;
    relatedEncounterId: string | number;
    orgName: string;
    facilityId: number | string;
    payerName: string;
    serviceDate: Date | string;
    paymentRequestAmount: number;
    cancelReasonText: string;
    paymentRequestStateHistory: object[]; //TODO: interface?
    createdAt: Date | string;
    updatedAt: Date | string;
    auditVersion: number;
    state?: PaymentRequestState;
    paymentType?: PaymentType;
    contacts?: PaymentRequestContact[];
    transactions?: PaymentTransaction[];
}

export interface FilteredPaymentRequest {
    paymentRequestGuid: string;
    procedureDate: Date | string;
    facility: string;
    encounterNumber: string;
    name: string;
    payer: string;
    payerCategory: string;
    amount: string | number;
    status: string;
}

export interface PaymentRequestState {
    paymentRequestStateId: number | string;
    paymentRequestStateCode: string;
    paymentRequestStateTitle: string;
    paymentRequestStateDescription: string;
    isActive: boolean;
    createdAt: Date | string;
    updatedAt: Date | string;
    auditVersion: number;
}

export interface PaymentType {
    paymentTypeId: number;
    orgName: string;
    paymentGatewayId: number | string;
    paymentTypeCode: string;
    paymentTypeTitle: string;
    paymentTypeDescription: string;
    paymentTypeConfig: PaymentTypeConfig;
    paymentTypeConfigHistory: object[];
    isActive: boolean;
    isOrgWide: boolean;
    facilityIds?: number[] | string[];
    createdAt: Date | string;
    updatedAt: Date | string;
    auditVersion: number;
    gateway?: PaymentGateway;
}

export interface PaymentRequestContact {
    paymentRequestContactGuid: string;
    paymentRequestGuid: string;
    paymentRequestContactStateId: number;
    contactType: string;
    contactInfo: string;
    contactStateHistory: object[]; //TODO: interface?
    createdAt: Date | string;
    updatedAt: Date | string;
    auditVersion: number;
    state: PaymentRequestContactState;
    paymentRequest?: PaymentRequest;
    transactions?: PaymentTransaction[];
}

export interface PaymentRequestContactState {
    paymentRequestContactStateId: number;
    paymentRequestContactStateCode: string;
    paymentRequestContactStateTitle: string;
    paymentRequestContactStateDescription: string;
    isActive: boolean;
    createdAt: Date | string;
    updatedAt: Date | string;
    auditVersion: number;
}

export interface PaymentGateway {
    paymentGatewayId: number | string;
    paymentGatewayTypeId: number | string;
    orgName: string;
    paymentGatewayCode?: string;
    paymentGatewayTitle: string;
    paymentGatewayDescription: string;
    paymentGatewayConfig?: {
        apiKey?: string;
        secretKey?: string;
        testMode?: boolean;
    };
    paymentGatewayConfigHistory?: {
        apiKey?: string;
        secretKey?: string;
        testMode?: boolean;
    }[];
    isActive: boolean;
    createdAt: Date | string;
    updatedAt: Date | string;
    auditVersion: number;
}

export interface PaymentGatewayType {
    paymentGatewayTypeId: number;
    paymentGatewayTypeCode: string;
    paymentGatewayTypeTitle: string;
    paymentGatewayTypeDescription: string;
    isActive: boolean;
    createdAt: Date | string;
    updatedAt: Date | string;
    auditVersion: number;
}

export interface PaymentTransaction {
    paymentTransactionId: number;
    paymentTransactionStateId: number;
    paymentRequestContactGuid: string;
    paymentGatewayId: string;
    paymentTransactionStateHistory: object[]; //TODO: interface?
    paymentGatewayRequestData: any;
    paymentGatewayResponseData: any;
    paymentAmountCollected: string | number;
    createdAt: Date | string;
    updatedAt: Date | string;
    auditVersion: number;
}

export interface PaymentTransactionState {
    paymentTransactionStateId: number;
    paymentTransactionStateCode: string;
    paymentTransactionStateTitle: string;
    paymentTransactionStateDescription: string;
    isActive: boolean;
    createdAt: Date | string;
    updatedAt: Date | string;
    auditVersion: number;
}

export interface Payer {
    payerId?: number | string;
    payerCategoryId?: number;
    payerName?: string;
    createdAt?: Date | string;
    updatedAt?: Date | string;
    auditVersion?: number;
    category?: PayerCategory;
}

export interface PayerCategory {
    payerCategoryId: number;
    payerCategoryCode: string;
    payerCategoryTitle: string;
    payerCategoryDescription: string;
    isActive: boolean;
    createdAt: Date | string;
    updatedAt: Date | string;
    auditVersion: number;
}

export interface PaymentRequestData {
    encounterId: string;
    encounterNumber: string;
    patientFirstName: string;
    patientLastName: string;
    email: string;
    phone: string;
    payers: string;
    payerCategories: string;
    amount: number;
}

export interface PaymentRequestReviewData {
    eligiblePaymentRequests: PaymentRequestData[];
    ineligiblePaymentRequests: PaymentRequestData[];
}

class PaymentsStore {
    @observable paymentRequests: PaymentRequest[] = [];
    @observable totalPaymentRequestCount: number = 0;
    @observable paymentRequest: {
        paymentRequest?: PaymentRequest;
        encounter?: Encounter;
        payer?: Payer;
        amountDue?: number | string;
        amountPaid?: number | string;
    } = {};
    @observable paymentRequestStates: PaymentRequestState[] = [];
    @observable paymentRequestStatesDropDownValues: { value: string | number; label: string }[] = [];
    @observable paymentRequestContactStates: PaymentRequestContactState[] = [];
    @observable paymentRequestContactStatesDropDownValues: { value: string | number; label: string }[] = [];
    @observable paymentTransactionStates: PaymentTransactionState[] = [];
    @observable paymentTransactionStatesDropDownValues: { value: string | number; label: string }[] = [];
    @observable paymentTypes: PaymentType[] = [];
    @observable paymentTypesDropDownValues: { value: string | number; label: string }[] = [];
    @observable paymentGateways: PaymentGateway[] = [];
    @observable paymentGatewaysDropDownValues: { value: string | number; label: string }[] = [];
    @observable paymentGatewayTypes: PaymentGatewayType[] = [];
    @observable paymentGatewayTypesDropDownValues: { value: string | number; label: string }[] = [];
    @observable uncategorizedPayerNames: string[] = [];
    @observable eligiblePaymentRequests: PaymentRequestData[] = [];
    @observable ineligiblePaymentRequests: PaymentRequestData[] = [];
    @observable payers: Payer[] = [];
    @observable payerCategories: PayerCategory[] = [];
    @observable payerCategoriesDropDownValues: { value: string | number; label: string }[] = [];
    @observable payerCategoriesDropDownValuesWithId: { value: string | number; label: string }[] = [];
    @observable totalPayersCount: number = 0;

    constructor() {
        makeObservable(this);
    }

    @action
    reset() {
        this.paymentRequests = [];
        this.totalPaymentRequestCount = 0;
        this.paymentRequestStates = [];
        this.paymentRequestStatesDropDownValues = [];
        this.paymentTypes = [];
        this.paymentTypesDropDownValues = [];
        this.paymentGateways = [];
        this.paymentGatewaysDropDownValues = [];
        this.paymentGatewayTypes = [];
        this.paymentGatewayTypesDropDownValues = [];
        this.uncategorizedPayerNames = [];
        this.eligiblePaymentRequests = [];
        this.ineligiblePaymentRequests = [];
        this.payers = [];
        this.totalPayersCount = 0;
        this.payerCategories = [];
        this.payerCategoriesDropDownValues = [];
        this.payerCategoriesDropDownValuesWithId = [];
    }

    async getPaymentRequests(params: FilterQueryParams = {}, force = false) {
        if (!force && this.paymentRequests.length > 0) {
            return;
        }

        const { result, count } = await paymentsService.getPaymentRequests(params);
        this.setPaymentRequests(result || [], count);
    }

    @action
    setPaymentRequests(paymentRequests: PaymentRequest[], total: number = 0) {
        this.paymentRequests = paymentRequests;
        this.totalPaymentRequestCount = total;
    }

    async getPaymentRequest(
        paymentRequestGuid: PaymentRequest['paymentRequestGuid'],
        options?: {
            includePaymentType?: boolean;
            includeGateway?: boolean;
            includeContacts?: boolean;
            includeTransactions?: boolean;
        },
    ) {
        const paymentRequest = await paymentsService.getPaymentRequest(paymentRequestGuid, options);
        this.setPaymentRequest(paymentRequest);
        return paymentRequest;
    }

    @action
    setPaymentRequest(paymentRequest: { paymentRequest: PaymentRequest; encounter: Encounter; payer: Payer }) {
        this.paymentRequest = paymentRequest;
    }

    async createPaymentIntent(paymentRequestContactGuid: PaymentRequestContact['paymentRequestContactGuid']) {
        const clientSecret = await paymentsService.createPaymentIntent(paymentRequestContactGuid);
        return clientSecret;
    }

    async createPaymentTransaction(
        paymentRequestGuid: PaymentRequest['paymentRequestGuid'],
        data: {
            paymentIntent: string;
            paymentRequestContactGuid: PaymentRequestContact['paymentRequestContactGuid'];
            paymentGatewayId: PaymentGateway['paymentGatewayId'];
            paymentGatewayRequestData: PaymentTransaction['paymentGatewayRequestData'];
        },
    ) {
        await paymentsService.createPaymentTransaction(paymentRequestGuid, data);
    }

    async getPaymentTypes(options?: { includeInactive?: boolean; force?: boolean; facilityId?: number }) {
        const { includeInactive, force, facilityId } = options || {};
        if (!force && this.paymentTypes.length > 0) {
            return;
        }
        const paymentTypes = await paymentsService.getPaymentTypes(includeInactive, facilityId);
        this.setPaymentTypes(paymentTypes || []);
    }

    @action
    setPaymentTypes(paymentTypes: PaymentType[]) {
        this.paymentTypes = paymentTypes;
        this.paymentTypesDropDownValues = formatSelectOptions(paymentTypes, {
            valueKey: 'paymentTypeId',
            labelKey: 'paymentTypeTitle',
        });
    }

    async createPaymentType(paymentType: Partial<PaymentType>) {
        const newPaymentType = await paymentsService.createPaymentType(paymentType);
        return newPaymentType;
    }

    async updatePaymentType(paymentTypeId: PaymentType['paymentTypeId'], paymentType: Partial<PaymentType>) {
        const updatedPaymentType = await paymentsService.updatePaymentType(paymentTypeId, paymentType);
        return updatedPaymentType;
    }

    async updatePaymentTypeEmailText(paymentTypeId: PaymentType['paymentTypeId'], updatedText: string) {
        const updatedPaymentType = await paymentsService.updatePaymentTypeEmailText(paymentTypeId, updatedText);
        return updatedPaymentType;
    }

    async updatePaymentTypeSmsText(paymentTypeId: PaymentType['paymentTypeId'], updatedText: string) {
        const updatedPaymentType = await paymentsService.updatePaymentTypeSmsText(paymentTypeId, updatedText);
        return updatedPaymentType;
    }

    async updatePaymentTypeLandingPageText(paymentTypeId: PaymentType['paymentTypeId'], updatedText: string) {
        const updatedPaymentType = await paymentsService.updatePaymentTypeLandingPageText(paymentTypeId, updatedText);
        return updatedPaymentType;
    }

    async deactivatePaymentType(paymentTypeId: PaymentType['paymentTypeId']) {
        await paymentsService.deactivatePaymentType(paymentTypeId);
    }

    async reactivatePaymentType(paymentTypeId: PaymentType['paymentTypeId']) {
        await paymentsService.reactivatePaymentType(paymentTypeId);
    }

    async getPaymentGateways(includeInactive = false, force = false) {
        if (!force && this.paymentGateways.length > 0) {
            return;
        }
        const paymentGateways = await paymentsService.getPaymentGateways(includeInactive);
        this.setPaymentGateways(paymentGateways || []);
    }

    @action
    setPaymentGateways(paymentGateways: PaymentGateway[]) {
        this.paymentGateways = paymentGateways;
        this.paymentGatewaysDropDownValues = formatSelectOptions(paymentGateways, {
            valueKey: 'paymentGatewayId',
            labelKey: 'paymentGatewayTitle',
        });
    }

    async createPaymentGateway(paymentGateway: Partial<PaymentGateway>) {
        const newPaymentGateway = await paymentsService.createPaymentGateway(paymentGateway);
        return newPaymentGateway;
    }

    async updatePaymentGateway(
        paymentGatewayId: PaymentGateway['paymentGatewayId'],
        paymentGateway: Partial<PaymentGateway>,
    ) {
        const updatedPaymentGateway = await paymentsService.updatePaymentGateway(paymentGatewayId, paymentGateway);
        return updatedPaymentGateway;
    }

    async deactivatePaymentGateway(paymentGatewayId: PaymentGateway['paymentGatewayId']) {
        await paymentsService.deactivatePaymentGateway(paymentGatewayId);
    }

    async reactivatePaymentGateway(paymentGatewayId: PaymentGateway['paymentGatewayId']) {
        await paymentsService.reactivatePaymentGateway(paymentGatewayId);
    }

    async getPaymentGatewayTypes(force = false) {
        if (!force && this.paymentGatewayTypes.length > 0) {
            return;
        }
        const paymentGatewayTypes = await paymentsService.getPaymentGatewayTypes();
        this.setPaymentGatewayTypes(paymentGatewayTypes || []);
    }

    @action
    setPaymentGatewayTypes(paymentGatewayTypes: PaymentGatewayType[]) {
        this.paymentGatewayTypes = paymentGatewayTypes;
        this.paymentGatewayTypesDropDownValues = formatSelectOptions(paymentGatewayTypes, {
            valueKey: 'paymentGatewayTypeId',
            labelKey: 'paymentGatewayTypeTitle',
        });
    }

    async getPaymentRequestStates(force = false) {
        if (!force && this.paymentRequestStates.length > 0) {
            return;
        }
        const paymentRequestStates = await paymentsService.getPaymentRequestStates();
        this.setPaymentRequestStates(paymentRequestStates || []);
    }

    @action
    setPaymentRequestStates(paymentRequestStates: PaymentRequestState[]) {
        this.paymentRequestStates = paymentRequestStates;
        this.paymentRequestStatesDropDownValues = formatSelectOptions(paymentRequestStates, {
            valueKey: 'paymentRequestStateId',
            labelKey: 'paymentRequestStateTitle',
        });
    }

    async getPaymentRequestContactStates(force = false) {
        if (!force && this.paymentRequestContactStates.length > 0) {
            return;
        }
        const paymentRequestContactStates = await paymentsService.getPaymentRequestContactStates();
        this.setPaymentRequestContactStates(paymentRequestContactStates || []);
    }

    @action
    setPaymentRequestContactStates(paymentRequestContactStates: PaymentRequestContactState[]) {
        this.paymentRequestContactStates = paymentRequestContactStates;
        this.paymentRequestContactStatesDropDownValues = formatSelectOptions(paymentRequestContactStates, {
            valueKey: 'paymentRequestContactStateId',
            labelKey: 'paymentRequestContactStateTitle',
        });
    }

    async getPaymentTransactionStates(force = false) {
        if (!force && this.paymentTransactionStates.length > 0) {
            return;
        }
        const paymentTransactionStates = await paymentsService.getPaymentTransactionStates();
        this.setPaymentTransactionStates(paymentTransactionStates || []);
    }

    @action
    setPaymentTransactionStates(paymentTransactionStates: PaymentTransactionState[]) {
        this.paymentTransactionStates = paymentTransactionStates;
        this.paymentTransactionStatesDropDownValues = formatSelectOptions(paymentTransactionStates, {
            valueKey: 'paymentTransactionStateId',
            labelKey: 'paymentTransactionStateTitle',
        });
    }

    // TODO: Use type from Scott
    async submitImportEncounters(data: {
        facilityId: Facility['facId'];
        paymentTypeId: PaymentType['paymentTypeId'];
        importEncounters: any;
    }) {
        const { facilityId, importEncounters, paymentTypeId } = data;
        const importData = await paymentsService.submitImportEncounters(facilityId, paymentTypeId, importEncounters);
        this.setUncategorizedPayerNames(importData.uncategorizedPayerNames);
    }

    async submitImportEncounterNumbers(data: {
        facilityId: Facility['facId'];
        paymentTypeId: PaymentType['paymentTypeId'];
        encounterNumbers: string[];
        procedureDate: Date | string;
    }) {
        const { facilityId, encounterNumbers, paymentTypeId, procedureDate } = data;
        const importData = await paymentsService.submitImportEncounterNumbers(
            facilityId,
            paymentTypeId,
            encounterNumbers,
            procedureDate,
        );
        this.setUncategorizedPayerNames(importData.uncategorizedPayerNames);
    }

    @action
    setUncategorizedPayerNames(importData: any) {
        this.uncategorizedPayerNames = importData;
    }

    async submitProvidersReview(data: {
        facilityId: Facility['facId'];
        importEncounters?: any[];
        encounterNumbers?: string[];
        procedureDate?: Date | string;
        newPayers: Partial<Payer>[];
        paymentTypeId: PaymentType['paymentTypeId'];
    }) {
        const { facilityId, importEncounters, encounterNumbers, procedureDate, newPayers, paymentTypeId } = data;
        const reviewData = await paymentsService.submitPayersReview({
            facilityId,
            importEncounters,
            encounterNumbers,
            procedureDate,
            newPayers,
            paymentTypeId,
        });
        this.setPaymentRequestReviewData(reviewData);
    }

    @action
    setPaymentRequestReviewData(reviewData: PaymentRequestReviewData) {
        this.eligiblePaymentRequests = reviewData.eligiblePaymentRequests;
        this.ineligiblePaymentRequests = reviewData.ineligiblePaymentRequests;
    }

    async submitPaymentRequests(data: {
        facilityId: Facility['facId'];
        newPayers: Partial<Payer>[];
        newPaymentRequests: Partial<PaymentRequest>[];
        paymentTypeId: PaymentType['paymentTypeId'];
    }) {
        const { facilityId, newPayers, newPaymentRequests, paymentTypeId } = data;
        return await paymentsService.submitPaymentRequests({
            facilityId,
            newPayers,
            newPaymentRequests,
            paymentTypeId,
        });
    }

    @action
    async getPayers(params?: FilterQueryParams) {
        const { result, count } = await paymentsService.getPayers(params);
        this.setPayers(result || [], count);
    }

    @action
    setPayers(payers: Payer[], total: number = 0) {
        this.payers = payers;
        this.totalPayersCount = total;
    }

    async createPayer(payer: Partial<Payer>) {
        const newPayer = await paymentsService.createPayer(payer);
        return newPayer;
    }

    async updatePayer(payerId: Payer['payerId'], payer: Partial<Payer>) {
        const updatedPayer = await paymentsService.updatePayer(payerId, payer);
        return updatedPayer;
    }

    async getPayerCategories(includeInactive = false, force = false) {
        if (!force && this.payerCategories.length > 0) {
            return;
        }
        const payerCategories = await paymentsService.getPayerCategories(includeInactive);
        this.setPayerCategories(payerCategories);
    }

    @action
    setPayerCategories(payerCategories: PayerCategory[]) {
        this.payerCategories = payerCategories;
        this.payerCategoriesDropDownValues = formatSelectOptions(payerCategories, {
            valueKey: 'payerCategoryCode',
            labelKey: 'payerCategoryTitle',
        });
        this.payerCategoriesDropDownValuesWithId = formatSelectOptions(payerCategories, {
            valueKey: 'payerCategoryId',
            labelKey: 'payerCategoryTitle',
        });
    }

    async createPayerCategory(data: {
        payerCategoryTitle: PayerCategory['payerCategoryTitle'];
        payerCategoryDescription: PayerCategory['payerCategoryDescription'];
        payerCategoryCode: PayerCategory['payerCategoryCode'];
    }) {
        await paymentsService.createPayerCategory(data);
    }

    async updatePayerCategory(
        payerCategoryId: PayerCategory['payerCategoryId'],
        data: {
            payerCategoryTitle: PayerCategory['payerCategoryTitle'];
            payerCategoryDescription: PayerCategory['payerCategoryDescription'];
        },
    ) {
        const updatedPayerCategory = await paymentsService.updatePayerCategory(payerCategoryId, data);

        return updatedPayerCategory;
    }

    async deactivatePayerCategory(payerCategoryId: PayerCategory['payerCategoryId']) {
        await paymentsService.deactivatePayerCategory(payerCategoryId);
    }

    async reactivatePayerCategory(payerCategoryId: PayerCategory['payerCategoryId']) {
        await paymentsService.reactivatePayerCategory(payerCategoryId);
    }

    async resendContacts(paymentRequestContactGuids: PaymentRequestContact['paymentRequestContactGuid'][]) {
        await paymentsService.resendContacts(paymentRequestContactGuids);
    }

    async collectPayment(
        paymentRequestGuid: PaymentRequest['paymentRequestGuid'],
        options: {
            paymentAmountCollected: PaymentRequest['paymentRequestAmount'];
            markAsCompleted?: boolean;
            paymentDate?: string | Date;
            paymentNote?: string;
        },
    ) {
        await paymentsService.collectPayment(paymentRequestGuid, options);
    }

    async cancelPaymentRequest(paymentRequestGuid: PaymentRequest['paymentRequestGuid'], cancelReasonText: string) {
        await paymentsService.cancelPaymentRequest(paymentRequestGuid, cancelReasonText);
    }

    async addPaymentRequestContact(
        paymentRequestGuid: PaymentRequest['paymentRequestGuid'],
        type: 'SMS' | 'EMAIL',
        contactInfo: string,
    ) {
        await paymentsService.createPaymentRequestContact(paymentRequestGuid, type, contactInfo);
    }

    async unsubscribePaymentRequestContact(
        paymentRequestContactGuid: PaymentRequestContact['paymentRequestContactGuid'],
    ) {
        await paymentsService.unsubscribePaymentRequestContact(paymentRequestContactGuid);
    }

    async updatePaymentRequestAmount(paymentRequestGuid: PaymentRequest['paymentRequestGuid'], newAmount: number) {
        await paymentsService.updatePaymentRequestAmount(paymentRequestGuid, newAmount);
    }
}

// Import this to any other store that needs to use a value from it
export const PaymentsStoreObject = new PaymentsStore();

export default createContext(PaymentsStoreObject);
