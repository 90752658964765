import * as yup from 'yup';
const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
export const emailRx =
    /[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/;

export const passwordValidationYup = yup
    .string()
    .min(10, 'Password must contain atleast 10 characters')
    .matches(/[A-Z]/, 'Password must contain at least 1 upper case letter')
    .matches(/[a-z]/, 'Password must contain at least 1 lower case letter')
    .matches(/\d/, 'Password must contain at least 1 number')
    .test('Has Symbol', 'Password must contain a symbol', (value) => {
        const symbols = '~!@#$%^&*()-_=+[{]}\\|;:\'",<.>/?';
        for (let i = 0; i < symbols.length; i++) {
            if (value.indexOf(symbols.charAt(i)) >= 0) {
                return true;
            }
        }
        return false;
    })
    .required('Password is Required');

export const usernameValidationYup = yup
    .string()
    .max(15, 'Username must contain less than 15 characters')
    .test('Doesnt Have Symbol', 'Username cannot contain a symbol', (value) => {
        const symbols = '~!@#$%^&*()-=+[{]}\\|;:\'",<.>/?';
        for (let i = 0; i < symbols.length; i++) {
            if (value.indexOf(symbols.charAt(i)) >= 0) {
                return false;
            }
        }
        return true;
    })
    .required('Username is Required');

export const emailValidationYup = yup.string().email('Please enter a valid email address');
export const phoneValidationYup = yup.string().matches(phoneRegExp, 'Phone number is not valid');
