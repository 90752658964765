import { observable, action, makeObservable } from 'mobx';
import { createContext } from 'react';
import * as tagsAPI from 'src/api/tags';

export interface Tag {
    tagId?: number;
    catgNm?: string;
    tagNm?: string;
    tagDesc?: string;
    actvInd?: boolean;
}

class TagStore {
    @observable tags: Tag[] = [];

    constructor() {
        makeObservable(this);
    }

    @action
    reset() {
        this.tags = [];
    }

    @action
    async getTags() {
        const results = await tagsAPI.fetchTags();
        const data = results.data || [];
        this.setTags(data);
        return data;
    }

    @action
    setTags(tags: Tag[]) {
        this.tags = tags;
    }
}

// Import this to any other store that needs to use a value from it
export const TagStoreObject = new TagStore();

export default createContext(TagStoreObject);
