import { observable, action, makeObservable } from 'mobx';
import { createContext } from 'react';

import * as nemsisApi from 'src/api/nemsis';

class NemsisStore {
    @observable demValidationData: string[] = null;
    @observable emsValidationData: Map<number, string[]> = new Map();

    constructor() {
        makeObservable(this);
    }

    @action
    reset() {
        this.demValidationData = null;
        this.emsValidationData.clear();
    }

    @action
    async validateDemData(force: boolean = false) {
        if (!force && this.demValidationData) {
            return;
        }
        const result = await nemsisApi.validateDemData();
        this.demValidationData = result.data || null;
    }

    @action
    async validateEmsData(encounterFormId: number, force: boolean = false) {
        if (!force && this.emsValidationData) {
            return;
        }
        const result = await nemsisApi.validateEmsData(encounterFormId);
        this.emsValidationData.set(encounterFormId, result.data || null);
    }

    @action
    async getEmsValidationData(encounterFormId: number) {
        if (this.emsValidationData.has(encounterFormId) && this.emsValidationData.get(encounterFormId) !== null) {
            return this.emsValidationData.get(encounterFormId);
        } else {
            const result = await nemsisApi.getEmsDataValidation(encounterFormId);
            this.emsValidationData.set(encounterFormId, result.data || null);

            return result.data;
        }
    }

    async submitDemData() {
        await nemsisApi.submitDemData();
    }

    async submitUpdatedEmsData(facId: number) {
        await nemsisApi.submitUpdatedEmsData(facId);
    }

    async submitSingleEmsData(encounterFormId: number) {
        await nemsisApi.submitEmsData(encounterFormId);
    }
}

export const NemsisStoreObject = new NemsisStore();

export default createContext(NemsisStoreObject);
