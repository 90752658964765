import { UseQueryResult, useQuery } from 'react-query';
import { getFormCatalogs } from 'src/api/formCatalogs';
import { FormCatalog } from 'common/lib/entity/framework/FormCatalog';
import { UserStoreObject } from 'src/stores/UserStore';

export const formCatalogsKey = 'formCatalogs';

export const useFormCatalogs = (): UseQueryResult<FormCatalog[]> => {
    return useQuery({
        queryKey: [formCatalogsKey],
        queryFn: async () => {
            return await getFormCatalogs();
        },
        enabled: UserStoreObject.isGraphiumAdmin(),
        initialData: [],
    });
};
