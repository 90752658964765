import { FormCatalog, FormCatalogPage } from 'common/lib/entity/framework/FormCatalog';
import React from 'react';
import LoadingIcon from 'src/components/LoadingIcon';
import styles from '../styles.module.scss';
import { useFormCatalogPageUrl } from 'src/queries/useFormCatalogPageUrl';

function FormCatalogPageImage(props: {
    formCatalogId: FormCatalog['formCatalogId'];
    pageId: FormCatalogPage['pageId'];
}) {
    const { formCatalogId, pageId } = props;

    if (!formCatalogId || !pageId) {
        return null;
    }

    const { data: formCatalogPageUrl, isFetching } = useFormCatalogPageUrl(formCatalogId, pageId);

    return isFetching ? (
        <LoadingIcon />
    ) : (
        <div className={styles.image} style={{ backgroundImage: `url(${formCatalogPageUrl})` }}></div>
    );
}

export default FormCatalogPageImage;
