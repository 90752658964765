import React from 'react';
import styles from './styles.module.scss';

interface Props {
    invitationData: any;
}

function Step3(props: Props) {
    return (
        <div className={styles.contentWrap}>
            <div className={styles.title}>Your invite is ready to send</div>
            <div className={styles.subTitle}>
                You are inviting the following users:{' '}
                {props.invitationData.emails.map(
                    (i: string, index: number) => `${i}${props.invitationData.emails.length === index + 1 ? '' : ', '}`,
                )}
            </div>
        </div>
    );
}

export default Step3;
