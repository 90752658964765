import React, { useState, useContext, useEffect } from 'react';
import styles from './styles.module.scss';
import Select from 'react-select';
import { observer } from 'mobx-react';
import { OrgUser } from 'src/stores/OrgUserStore';
import FacilityDropDown from 'src/components/FacilityDropDown';
import { Label } from 'src/components/Input';
import TeamStore, { Team } from 'src/stores/TeamStore';
import { getUserTeams } from 'src/api/organization';
import { formatSelectOptions } from 'src/utils';
import * as utils from 'src/utils';

export interface UpdateTeamsModalProps {
    user: OrgUser;
    onChange: Function;
}

interface Option {
    value?: any;
    label?: any;
}

function UpdateTeamsModal(props: UpdateTeamsModalProps) {
    const teamStore = useContext(TeamStore);

    const [selectedFacility, setSelectedFacility] = useState<Option>({});
    const [facilityTeams, setFacilityTeams] = useState<any[]>([]);
    const [userTeams, setUserTeams] = useState<any[]>([]);
    const [userLoadingState, setUserLoadingState] = useState('init');

    // When facility changes, get the user's current teams for that facility
    useEffect(() => {
        if (selectedFacility && selectedFacility.value) {
            fetchUserTeams();
        }
    }, [selectedFacility]);

    // When teams change, call onChange
    useEffect(() => {
        const teamIds: string[] = userTeams && userTeams.length ? userTeams.map((team) => team.value) : [];
        props.onChange({ facility: selectedFacility.value, teams: teamIds });
    }, [userTeams]);

    async function fetchUserTeams() {
        setUserLoadingState('loading');

        await getTeams();

        // Set the available teams for a facility
        setFacilityTeams(formatSelectOptions(teamStore.teams.filter(team => team.actvInd), { valueKey: 'teamId', labelKey: 'teamName' }));

        // Set the current users teams for a facility
        const currentTeams = await getUserTeams(props.user.usrId, selectedFacility.value);
        const filteredCurrentTeams = teamStore.teams.filter((team) =>
            currentTeams.data.find((currentTeam: any) => currentTeam.teamId === team.teamId),
        );

        setUserTeams(formatSelectOptions(filteredCurrentTeams, { valueKey: 'teamId', labelKey: 'teamName' }));

        setUserLoadingState('loaded');
    }

    async function getTeams() {
        await teamStore.getTeams({ filters: { facId: selectedFacility.value } });
    }

    const handleTeamSelect = (value: any) => {
        setUserTeams(value || []);
    };

    return (
        <div className={styles.contentWrap}>
            <Label text="Facility" />
            <FacilityDropDown onChange={(s: any) => setSelectedFacility(s)} />
            {userLoadingState === 'loaded' ? (
                <>
                    <Label text="Teams" />
                    <Select
                        value={userTeams}
                        placeholder="Select Team/s"
                        onChange={(s: any) => handleTeamSelect(s)}
                        isMulti={true}
                        options={facilityTeams}
                        closeMenuOnSelect={false}
                        styles={utils.styleSelectComponent(true)}
                        menuPortalTarget={document.body}
                    />
                </>
            ) : userLoadingState === 'loading' ? (
                "Retrieving user's teams."
            ) : null}
        </div>
    );
}

export default UpdateTeamsModal;
