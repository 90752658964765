import React from 'react';

const SvgFontSize = (props) => (
    <svg width={21} height={18} {...props} viewBox="0 0 21 18">
        <g
            stroke={props.color || '#FFF'}
            strokeWidth={1.5}
            fill="none"
            fillRule="evenodd"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path d="M13.65 16.968L8.484 1.495a.397.397 0 00-.375-.273c-.169 0-.32.11-.374.273L2.583 16.968M4.47 11.301h7.289M1.396 16.968h2.39M12.463 16.968h2.391M18.813 16.968L15.547 7.19a.397.397 0 00-.374-.273c-.17 0-.32.11-.374.273l-1.495 4.488M14.395 12.92h3.067M18.034 16.968h1.57" />
        </g>
    </svg>
);

export default SvgFontSize;
