import React from 'react';

const Upload = (props) => (
    <svg version="1.1" width={props.width || 64} height={props.height || 64} viewBox="0 0 512.056 512.056">
        <g>
            <g>
                <g>
                    <path fill={props.color || '#000'} d="M426.635,188.224C402.969,93.946,307.358,36.704,213.08,60.37C139.404,78.865,85.907,142.542,80.395,218.303
                        C28.082,226.93-7.333,276.331,1.294,328.644c7.669,46.507,47.967,80.566,95.101,80.379h80v-32h-80c-35.346,0-64-28.654-64-64
                        c0-35.346,28.654-64,64-64c8.837,0,16-7.163,16-16c-0.08-79.529,64.327-144.065,143.856-144.144
                        c68.844-0.069,128.107,48.601,141.424,116.144c1.315,6.744,6.788,11.896,13.6,12.8c43.742,6.229,74.151,46.738,67.923,90.479
                        c-5.593,39.278-39.129,68.523-78.803,68.721h-64v32h64c61.856-0.187,111.848-50.483,111.66-112.339
                        C511.899,245.194,476.655,200.443,426.635,188.224z"/>
                    <path fill={props.color || '#000'} d="M245.035,253.664l-64,64l22.56,22.56l36.8-36.64v153.44h32v-153.44l36.64,36.64l22.56-22.56l-64-64
                        C261.354,247.46,251.276,247.46,245.035,253.664z"/>
                </g>
            </g>
        </g>
    </svg>
);

export default Upload;
