import React, { useState, useEffect } from 'react';
import styles from './styles.module.scss';
import Select from 'react-select';
import * as utils from 'src/utils';
import { useFormModels } from 'src/queries/useFormModels';
import { FormModel } from 'common/lib/entity/framework/FormModel';

export interface FormModelDropDownProps {
    onChange: Function;
    selectedValue?: string | number;
}

function FormModelDropDown(props: FormModelDropDownProps) {
    const { data: formModels, isFetching } = useFormModels();
    const [options, setOptions] = useState([]);
    const [selected, setSelected] = useState(null);

    useEffect(() => {
        if (formModels) {
            const opts = formModels.map((model: FormModel) => ({
                label: model.modelName,
                value: model.formModelId,
            }));
            setOptions(opts);
        }
    }, [formModels]);

    useEffect(() => {
        const selectedVal = options.find((i) => i.value === props.selectedValue);
        if (selectedVal) {
            setSelected(selectedVal);
        }
    }, [props.selectedValue, options]);

    return (
        <Select
            value={selected}
            onChange={(s: any) => {
                setSelected(s);
                props.onChange(s || null);
            }}
            isLoading={isFetching}
            className={styles.select}
            options={options}
            placeholder="Select Model"
            styles={utils.styleSelectComponent()}
        />
    );
}

export default FormModelDropDown;
