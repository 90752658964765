import React, { useState, useEffect, useContext } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import NavBar from 'src/containers/NavBar';
import PageContent from 'src/components/PageContent';
import PageContainer from 'src/components/PageContainer';
import Button from 'src/components/Button';
import Card from 'src/components/Card';
import Flex from 'src/components/Flex';
import Table from 'src/components/Table';
import styles from './styles.module.scss';
import SideModal from 'src/components/SideModal';
import { CellInfo } from 'react-table';
import ModalFooter from 'src/components/ModalFooter';
import ModalHeader from 'src/components/ModalHeader';
import { ErrorStoreObject, ErrorTypes } from 'src/stores/ErrorStore';
import {
    getReferenceLists,
    createReferenceList,
    updateReferenceList,
    deactivateReferenceList,
    reactivateReferenceList,
} from 'src/api/referenceList';
import { Facility, ReferenceList } from 'src/stores/ReferenceListStore';
import Icon from 'src/components/Icon';
import { ICONS, ROUTES } from 'src/utils/constants';
import ReferenceListModal from 'src/components/ReferenceListModal';
import ActionMenu from 'src/components/ActionMenu';
import { useHistory } from 'react-router';
import { ModalStoreObject, ModalTypes } from 'src/stores/ModalStore';
import { ANALYTICS_NAMES } from 'src/utils/analytics';
import UserStore from 'src/stores/UserStore';
import { ToastStoreObject } from 'src/stores/ToastStore';
import * as utils from 'src/utils';
import FacilityDropDown from 'src/components/FacilityDropDown';
import Input from 'src/components/Input';
import useDebouncedCallback from 'src/utils/hooks/useDebouncedCallback';
import Checkbox from 'src/components/Checkbox';

function ReferenceLists() {
    const history = useHistory();
    const userStore = useContext(UserStore);
    const includeValueSets = userStore.selectedOrgIsGraphiumHealthOrg();
    const [isSearching, setIsSearching] = useState(false);
    const [searchReferenceLists, setSearchReferenceLists] = useState([]);

    const [refreshSearchResults] = useDebouncedCallback(async function(query: string) {
        if (!query) {
            setIsSearching(false);
            return;
        }
        setIsLoading(true);
        const referenceLists = await getReferenceLists(includeValueSets, { query, searchFacilityIds });
        const listsToDisplay = filterReferenceListsByActiveStatus(referenceLists, showDeactivatedLists);
        setIsSearching(true);
        setSearchReferenceLists(listsToDisplay);
        setIsLoading(false);
    }, 300);


    function getListName(list: ReferenceList) {
        return list.listId ? list.listName : 'VALUE SET: ' + list.valueSetName;
    }

    const nameAndDescriptionColumns = [
        {
            Header: () => <span className={styles.blackText}>LIST NAME</span>,
            // id and accessor modified for value sets
            id: 'listId' || 'valSetId',
            accessor: (row: ReferenceList) => row.listDisplayName || row.valueSetName,
            minWidth: 150,
            Cell: (props: CellInfo) => <span className={styles.blackText}>{getListName(props.original)}</span>,
        },
        {
            Header: 'LIST DESCRIPTION',
            // id and accessor modified for value sets
            id: 'listId' || 'valSetId',
            accessor: (row: ReferenceList) => row.listDescription || row.valueSetDesc,
            minWidth: 150,
            Cell: (props: CellInfo) => <span className={styles.blackText}>{props.value}</span>,
        },
    ];
        const facilityColumn =
        // value sets only
        {
            Header: 'FACILITY',
            id: 'facility',
            accessor: (row: ReferenceList) => row.facility?.facNm || '',
            minWidth: 100,
            Cell: (props: CellInfo) => <span className={styles.blackText}>{props.value}</span>,
        };
        const activeAndActionColumns = [
        {
            Header: 'ACTIVE',
            accessor: 'actvInd', // this is the same property name for both
            minWidth: 100,
            Cell: (props: CellInfo) => (
                <span className={classNames({ [styles.redText]: !props.value, [styles.greenText]: props.value })}>
                    {props.value ? 'ACTIVE' : 'INACTIVE'}
                </span>
            ),
        },
        {
            Header: 'ACTIONS',
            accessor: 'actions',
            sortable: false,
            style: { paddingVertical: 0, paddingHorizontal: 20 },
            Cell: (props: CellInfo) =>
                props.original.listId ? (
                    <div className={styles.actionWrap}>
                        <Button
                            onClick={() => {
                                history.push(ROUTES.getString(ROUTES.SettingsRefListDetails, props.original.listId));
                            }}
                            className={styles.leftButton}
                            type="secondary"
                            isLocked={!userStore.userPermissions.canView.referenceListValues}
                            text="View Details"
                            data-test-id={ANALYTICS_NAMES.RefLists_Table_Details}
                        />
                        <ActionMenu
                            entityType="referenceList"
                            entity={props.original}
                            onItemClick={(action: { id: string; label: string }) =>
                                handleActionClick(action, props.original)
                            }
                        />
                    </div>
                ) : (
                    <div className={styles.actionWrap}>
                        <Button
                            onClick={() => {
                                history.push(ROUTES.getString(ROUTES.SettingsValueSetDetails, props.original.valSetId));
                            }}
                            className={styles.leftButton}
                            type="secondary"
                            isLocked={!userStore.userPermissions.canView.valueSets}
                            text="View Details"
                            data-test-id={ANALYTICS_NAMES.Value_Sets_Table_Info}
                        />
                    </div>
                ),
        },];
    
    const columns = includeValueSets
        ? [...nameAndDescriptionColumns, facilityColumn, ...activeAndActionColumns]
        : [...nameAndDescriptionColumns, ...activeAndActionColumns];

    const [modalState, setModalState] = useState(false);
    const [modalType, setModalType] = useState('create');
    const [modalData, setModalData] = useState<ReferenceList>({});
    const [isLoading, setIsLoading] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [referenceLists, setReferenceLists] = useState([]);
    const [referenceListModalData, setReferenceListModalData] = useState<ReferenceList>({});
    const [searchFacilityIds, setSearchFacilityIds] = useState([]);
    const [showDeactivatedLists, setShowActivatedLists] = useState(false);

    async function getReferenceListData(includeDeactivated: boolean = false) {
        try {
            setIsLoading(true);
            const referenceLists = await getReferenceLists(includeValueSets);
            const listsToDisplay = filterReferenceListsByActiveStatus(referenceLists, includeDeactivated);
            setReferenceLists(listsToDisplay);
            setIsLoading(false);
        } catch (e) {
            ErrorStoreObject.setError(ErrorTypes.Loading);
        }
    }
    
    function filterReferenceListsByActiveStatus(referenceLists: Array<ReferenceList>, includeDeactivated: boolean): Array<ReferenceList> {
        return includeDeactivated
            ? referenceLists
            : referenceLists.filter((referenceLists: ReferenceList) => {
                  return referenceLists.actvInd;
              });
    }

    useEffect(() => {
        getReferenceListData();
    }, []);

    async function handleModalComplete() {
        try {
            setIsLoading(true);
            if (modalType === 'create') {
                await createReferenceList(referenceListModalData);
            } else {
                await updateReferenceList(modalData.listId, referenceListModalData);
            }
            setModalState(false);
            await updateTable();
        } catch (e) {
            ToastStoreObject.show(utils.parseError(e));
        } finally {
            setIsLoading(false);
        }
    }

    async function updateTable() {
        isSearching
            ? await refreshSearchResults(searchValue)
            : await getReferenceListData();
    }

    async function handleActionClick(action: { id: string; label: string }, referenceList: ReferenceList) {
        try {
            switch (action.id) {
                case 'edit':
                    setModalData(referenceList);
                    setModalType('edit');
                    setModalState(true);
                    break;

                case 'activate':
                    if (referenceList.actvInd) {
                        handleConfirm(referenceList);
                    } else {
                        await reactivateReferenceList(referenceList.listId);
                        await updateTable();
                    }
                    break;
            }
        } catch (e) {
            ToastStoreObject.show(utils.parseError(e));
        }
    }

    async function handleDeactivate(referenceList: ReferenceList) {
        try {
            await deactivateReferenceList(referenceList.listId);
            await updateTable();
            ModalStoreObject.hideModal();
        } catch (e) {
            ToastStoreObject.show(utils.parseError(e));
        }
    }

    function handleConfirm(referenceList: ReferenceList) {
        ModalStoreObject.showModal(ModalTypes.ConfirmationModal, {
            title: 'Are you sure you would like to deactivate this reference list?',
            onConfirm: () => handleDeactivate(referenceList),
            onCancel: () => ModalStoreObject.hideModal(),
            confirmButtonText: 'Deactivate',
        });
    }

    function handleSearchFacilityChange(facilities: any[]) {
        const searchFacilityIds: number[] = facilities.map(f => f.value);
        setSearchFacilityIds(searchFacilityIds);
        refreshSearchResults(searchValue); 
    }

    function handleUpdateSearchQuery(t: string) {
        setSearchValue(t);
        refreshSearchResults(t); 
    }

    function handleShowDeactivatedListsChange(showDeactivatedLists: boolean) {
        setShowActivatedLists(showDeactivatedLists);
        getReferenceListData(showDeactivatedLists);
        handleUpdateSearchQuery(searchValue);
    }

    return (
        <PageContainer>
            <NavBar />
            <PageContent>
                <Flex self="stretch" align="center" justify="start">
                    <Flex value={1} className={styles.searchWrap}>
                        <Input
                            className={styles.input}
                            placeholder="Search..."
                            value={searchValue}
                            onChangeText={(userInput: string) => handleUpdateSearchQuery(userInput)}
                        />
                    </Flex>
                    <Flex value={3} className={styles.newWrap}>
                        <Button
                            className={styles.leftButton}
                            leftIcon={<Icon className={styles.plusIcon} name={ICONS.PlusButton} />}
                            type="primary"
                            onClick={() => setModalState(true)}
                            isLocked={!userStore.userPermissions.canEdit.referenceLists}
                            text="New Reference List"
                            data-test-id={ANALYTICS_NAMES.RefLists_Create}
                        />
                    </Flex>
                </Flex>
                {referenceLists && (
                    <Flex direction="row" align="start">
                        <Flex value={1}>
                            <FacilityDropDown
                                onChange={(e: any) => handleSearchFacilityChange(e)}
                                isMulti={true}
                                allowEmpty={true}
                                isDefaultEmpty={true}
                                placeholder={'Filter value sets by facility'}
                            />
                            <div className={styles.cardWrap}>
                                <Checkbox
                                    checked={showDeactivatedLists}
                                    label={'Show deactivated lists'}
                                    onChange={(e) => {
                                        handleShowDeactivatedListsChange(e.target.checked);
                                    }}
                                />
                            </div>
                        </Flex>
                        <Flex value={3}></Flex>
                    </Flex>               
                )}
                <Card className={styles.cardWrap}>
                    <div className={styles.tableTitle}>Reference Lists</div>
                    <Table isLoading={isLoading} columns={columns} data={isSearching ? searchReferenceLists : referenceLists} />
                </Card>
            </PageContent>
            <SideModal isOpen={modalState}>
                <ModalHeader
                    title={`${modalType === 'create' ? 'New' : 'Edit'} ${(modalData && modalData.listDisplayName) ||
                        'Reference List'}`}
                    onClose={() => {
                        setModalData({});
                        setModalState(false);
                    }}
                />
                <ReferenceListModal
                    referenceList={modalData}
                    mode={modalType}
                    onChange={(data: ReferenceList) => setReferenceListModalData(data)}
                />
                <ModalFooter
                    primaryText={modalType === 'create' ? 'Create' : 'Save'}
                    primaryClick={handleModalComplete}
                    secondaryClick={() => {
                        setModalData({});
                        setModalState(false);
                    }}
                    isLoading={isLoading}
                />
            </SideModal>
        </PageContainer>
    );
}
export default observer(ReferenceLists);
