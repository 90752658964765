import React, { useState, useEffect, useContext } from 'react';
import styles from './styles.module.scss';
import Select from 'react-select';
import PaymentsStore from 'src/stores/PaymentsStore';
import { observer } from 'mobx-react';
import { styleSelectComponent } from 'src/utils';
import useIsOptionsLoading from 'src/utils/hooks/useIsOptionsLoading';

export interface PayerCategoryDropDownProps {
    onChange: Function;
    selectedValue?: number;
    menuPortalTarget?: HTMLElement;
    useCategoryIds?: boolean;
}

function PayerCategoryDropDown(props: PayerCategoryDropDownProps) {
    const paymentsStore = useContext(PaymentsStore);
    const options = props.useCategoryIds
        ? paymentsStore.payerCategoriesDropDownValuesWithId
        : paymentsStore.payerCategoriesDropDownValues;
    const [selected, setSelected] = useState(options.find((i) => i.value === props.selectedValue) || null);
    const isLoading = useIsOptionsLoading(options);

    useEffect(() => {
        paymentsStore.getPayerCategories(false);
    }, []);

    useEffect(() => {
        const selectedVal = options.find((i) => i.value === props.selectedValue);
        if (selectedVal) {
            setSelected(selectedVal);
        }
    }, [props.selectedValue, options]);

    return (
        <Select
            value={selected}
            onChange={(s: any) => {
                setSelected(s);
                props.onChange(s || null);
            }}
            isLoading={isLoading}
            className={styles.select}
            options={options}
            placeholder="Select Category"
            styles={styleSelectComponent()}
            menuPortalTarget={props.menuPortalTarget}
        />
    );
}

export default observer(PayerCategoryDropDown);
