import React from 'react';
import classNames from 'classnames';
import { isToday, format, parseISO } from 'date-fns';
import Flex from 'src/components/Flex';
import styles from './styles.module.scss';
import { CalendarViewTypes } from 'src/containers/Appointments';

interface Props {
    type: CalendarViewTypes;
    date: string;
}

function CalendarHeader(props: Props) {
    //   const value = selectedView.value;
    const isTodayValue = isToday(new Date(props.date));
    if (props.type === CalendarViewTypes.Weekly) {
        return (
            <Flex direction="column" align="center" justify="center" className={styles.weeklyHeader}>
                <Flex
                    align="center"
                    className={classNames(styles.weeklyDayString, {
                        [styles.activeWeeklyDayString]: isTodayValue,
                    })}
                >
                    {format(new Date(props.date), 'EEE').toUpperCase()}
                </Flex>
                <Flex
                    align="center"
                    justify="center"
                    value={1}
                    className={classNames(styles.weeklyDayNumber, {
                        [styles.activeWeeklyDayNumber]: isTodayValue,
                    })}
                >
                    {format(new Date(props.date), 'd')}
                </Flex>
            </Flex>
        );
    }
    if (props.type === CalendarViewTypes.Monthly) {
        return (
            <Flex direction="column" align="center" justify="center" className={styles.monthlyHeader}>
                <Flex align="center" className={styles.monthlyDay}>
                    {format(new Date(props.date), 'EEEE').toUpperCase()}
                </Flex>
            </Flex>
        );
    }

    return (
        <Flex direction="column" align="center" justify="center" className={styles.dailyHeader}>
            <Flex
                align="center"
                className={classNames(styles.dailyDay, {
                    [styles.activeDailyDay]: isTodayValue,
                })}
            >
                {format(new Date(props.date), 'EEEE').toUpperCase()}
            </Flex>
        </Flex>
    );
}

export default CalendarHeader;
