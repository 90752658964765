import { Customer } from 'common/lib/entity/index/Customer';
import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import Select from 'react-select';
import CustomerStore from 'src/stores/CustomerStore';
import { styleSelectComponent } from 'src/utils';
import useIsOptionsLoading from 'src/utils/hooks/useIsOptionsLoading';
import styles from '../styles.module.scss';

interface OffboardedToCustomerDropdownProps {
    onChange: Function;
    selectedValue?: Customer['customerId'];
}

function OffboardedToCustomerDropdown(props: OffboardedToCustomerDropdownProps) {
    const customerStore = useContext(CustomerStore);
    const options = mapCustomerOptions();
    const [selected, setSelected] = useState(options.find((i) => i.value === props.selectedValue) || null);
    const isLoading = useIsOptionsLoading(options);

    useEffect(() => {
        customerStore.getCustomers({}, false);
    }, []);

    useEffect(() => {
        const selectedVal = options.find((i) => i.value === props.selectedValue);
        if (selectedVal) {
            setSelected(selectedVal);
        }
    }, [props.selectedValue, options]);

    function mapCustomerOptions() {
        return customerStore.customers.map((c) => {
            return { value: c.customerId, label: c.customerName };
        });
    }

    return (
        <Select
            value={selected}
            onChange={(s: any) => {
                setSelected(s);
                props.onChange(s || null);
            }}
            isClearable={true}
            className={styles.select}
            options={options}
            placeholder="Select Customer"
            styles={styleSelectComponent()}
            isLoading={isLoading}
        />
    );
}

export default observer(OffboardedToCustomerDropdown);
