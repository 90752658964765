import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import classNames from 'classnames';
import { format as formatDateFns } from 'date-fns';
import './timepicker.scss';
import styles from './styles.module.scss';
import { InputProps } from '../Input';
import { getTimeDate } from 'src/utils';

export interface TimePickerProps {
    onChange: (t: string) => any;
    time: string;
    minuteStep?: number;
    disabled?: boolean;
    infoState?: InputProps['infoState'];
}

const TimePicker = ({ time, onChange, disabled, infoState, minuteStep = 15 }: TimePickerProps) => {
    const selectedVal = getTimeDate(time);
    const [selected, setSelected] = useState(selectedVal);
    useEffect(() => {
        setSelected(getTimeDate(time));
    }, [time]);
    function handleSelect(date: any) {
        const formattedTime = formatDateFns(date, 'HH:mm');
        setSelected(date);
        onChange(formattedTime);
    }
    return (
        <div
            className={classNames(styles.inputWrap, {
                [styles.disabled]: disabled,
                [styles.error]: infoState === 'error',
                [styles.warning]: infoState === 'warning',
            })}
        >
            <DatePicker
                placeholderText="Select Time"
                selected={selected}
                disabled={disabled}
                onChange={handleSelect}
                showTimeSelect={true}
                showTimeSelectOnly={true}
                timeIntervals={minuteStep}
                timeCaption="Time"
                dateFormat="h:mm aa"
            />
        </div>
    );
};

export default TimePicker;
