import { UseQueryResult, useQuery } from 'react-query';
import { getFormAssets } from 'src/api/formAssets';
import { UserStoreObject } from 'src/stores/UserStore';
import { FormAsset } from 'common/lib/entity/framework/FormAsset';

export const formAssetsKey = 'formAssets';

export const useFormAssets = (): UseQueryResult<FormAsset[]> => {
    return useQuery({
        queryKey: [formAssetsKey],
        queryFn: async () => {
            return await getFormAssets();
        },
        enabled: UserStoreObject.isGraphiumAdmin(),
        initialData: [],
    });
};
