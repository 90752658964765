import React from 'react';

const SvgPin = (props) => (
    <svg width={19} height={19} {...props} viewBox="0 0 19 19">
        <g
            stroke={props.color || '#FFF'}
            strokeWidth={1.2}
            fill="none"
            fillRule="evenodd"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path d="M3.918 6.953a1.187 1.187 0 00-.238 1.865l6.502 6.502a1.187 1.187 0 001.866-.237c.77-1.325 1-2.896.64-4.386l5.17-3.29a1.187 1.187 0 00.202-1.84L13.435.94a1.188 1.188 0 00-1.842.202l-3.29 5.17a5.955 5.955 0 00-4.385.641zM6.93 12.07L.593 18.405" />
        </g>
    </svg>
);

export default SvgPin;
