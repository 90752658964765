import React from 'react';

const SvgMapMarker = (props) => (
    <svg width={10} height={13} {...props} viewBox="0 0 10 13">
        <g fill="none" fillRule="evenodd">
            <path
                d="M5 0c2.761 0 5 2.183 5 4.875 0 2.081-2.987 6.04-4.343 7.733A.841.841 0 015 12.92a.841.841 0 01-.657-.312C2.987 10.917 0 6.956 0 4.875 0 2.183 2.239 0 5 0z"
                fill="#182C49"
            />
            <path
                d="M5 3.413c-.828 0-1.5.654-1.5 1.462 0 .808.672 1.463 1.5 1.463s1.5-.655 1.5-1.463S5.828 3.413 5 3.413"
                stroke="#FFF"
                strokeWidth={1.275}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
    </svg>
);

export default SvgMapMarker;
