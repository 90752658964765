import React from 'react';

const SvgArrow = (props) => (
    <svg width={10} height={5} {...props} viewBox="0 0 10 5">
        <path
            d="M1 1l3.87 3.158c.033.027.08.042.13.042s.097-.015.13-.042L9 1"
            stroke={props.color || '#FFF'}
            strokeWidth={1.6}
            fill="none"
            fillRule="evenodd"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default SvgArrow;
