import React, { useState, useContext } from 'react';
import classNames from 'classnames';

import Button from 'src/components/Button';
import Icon from 'src/components/Icon';
import Card from 'src/components/Card';
import Flex from 'src/components/Flex';
import Table from 'src/components/Table';
import styles from './styles.module.scss';
import { observer } from 'mobx-react';
import SideModal from 'src/components/SideModal';
import { useEffect } from 'react';
import EditUser from 'src/components/EditUser';
import { OrgUser } from 'src/stores/OrgUserStore';
import { Role } from 'src/stores/RoleStore';
import * as organizationService from 'src/api/organization';
import { CellInfo } from 'react-table';
import CenterModal from 'src/components/CenterModal';
import UpdateTeamsModal from 'src/components/UpdateTeamsModal';
import FacilitySelectorModal from 'src/components/FacilitySelectorModal';
import ModalFooter from 'src/components/ModalFooter';
import ModalHeader from 'src/components/ModalHeader';
import Input from 'src/components/Input';
import ActionMenu from 'src/components/ActionMenu';
import { ToastStoreObject } from 'src/stores/ToastStore';
import { ErrorStoreObject, ErrorTypes } from 'src/stores/ErrorStore';
import { parseError } from 'src/utils';
import { ICONS } from 'src/utils/constants';
import useDebouncedCallback from 'src/utils/hooks/useDebouncedCallback';
import { ModalStoreObject, ModalTypes } from 'src/stores/ModalStore';
import { ANALYTICS_NAMES } from 'src/utils/analytics';
import UserStore from 'src/stores/UserStore';
import Checkbox from 'src/components/Checkbox';

interface EditTeamData {
    facility?: number;
    teams?: string[];
}

interface UsersProps {
    onInvite: Function;
}

function Users(props: UsersProps) {
    const columns = [
        {
            Header: () => <span>FIRST NAME</span>,
            accessor: 'frstNm',
            minWidth: 150,
            Cell: (props: CellInfo) => <span>{props.value}</span>,
        },
        {
            Header: 'LAST NAME',
            accessor: 'lastNm',
            minWidth: 150,
            Cell: (props: CellInfo) => <span>{props.value}</span>,
        },
        {
            Header: 'USERNAME',
            accessor: 'usrNm',
            minWidth: 150,
        },
        {
            Header: 'EMAIL ADDRESS',
            accessor: 'emailAddr',
            minWidth: 300,
            Cell: (props: CellInfo) => <span>{props.value}</span>,
        },
        {
            Header: 'ROLES',
            accessor: 'roles',
            minWidth: 250,
            Cell: (props: CellInfo) => getUserRoles(props.original.roles),
        },
        {
            Header: 'ACTIVE',
            accessor: 'actvInd',
            minWidth: 100,
            Cell: (props: CellInfo) => (
                <span className={classNames({ [styles.redText]: !props.value, [styles.greenText]: props.value })}>
                    {props.value ? 'ACTIVE' : 'INACTIVE'}
                </span>
            ),
        },
        {
            Header: 'ACTIONS',
            accessor: 'actions',
            style: { paddingVertical: 0, paddingHorizontal: 20 },
            Cell: (props: CellInfo) => (
                <ActionMenu
                    entityType="user"
                    entity={props.original}
                    onItemClick={(action: { id: string; label: string }) => handleActionClick(action, props.original)}
                />
            ),
        },
    ];

    const [modalState, setModalState] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [teamModalState, setTeamModalState] = useState(false);
    const [facilitiesModalState, setFacilitiesModalState] = useState(false);
    const [facilitiesModalData, setFacilitiesModalData] = useState([]);
    const [facilitiesLoading, setFacilitiesLoading] = useState(false);
    const [editUserState, setEditUserState] = useState<OrgUser>({});
    const [editTeamState, setEditTeamState] = useState<EditTeamData>({});
    const [userState, setUserState] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [isSearching, setIsSearching] = useState(false);
    const [searchUsers, setSearchUsers] = useState([]);
    const [showDeactivatedUsers, setShowDeactivatedUsers] = useState(false);
    const userStore = useContext(UserStore);

    const [refreshSearchResults] = useDebouncedCallback(async function(query: string) {
        if (!query) {
            setIsSearching(false);
            return;
        }
        setIsLoading(true);
        const users = await organizationService.getUsers({ query });
        const usersToDisplay = filterUsersByActiveStatus(users, showDeactivatedUsers);
        setIsSearching(true);
        setSearchUsers(usersToDisplay);
        setIsLoading(false);
    }, 300);

    async function getUsers(includeDeactivated: boolean = false) {
        try {
            setIsLoading(true);
            const users = await organizationService.getUsers();
            const usersToDisplay = filterUsersByActiveStatus(users, includeDeactivated);
            setUserState(usersToDisplay);
            setIsLoading(false);
        } catch (e) {
            ErrorStoreObject.setError(ErrorTypes.Loading);
        }
    }

    function filterUsersByActiveStatus(users: Array<OrgUser>, includeDeactivated: boolean): Array<OrgUser> {
        return includeDeactivated
            ? users
            : users.filter((user: OrgUser) => {
                  return user.actvInd;
              });
    }

    async function getFacilities(userId: number) {
        try {
            setFacilitiesLoading(true);
            setFacilitiesModalData([]);
            const facilities = await organizationService.getUserFacilities(userId);
            setFacilitiesModalData(facilities.data.map((facility: any) => facility.actvInd && facility.facId));
            setFacilitiesLoading(false);
        } catch (e) {
            ErrorStoreObject.setError(ErrorTypes.Loading);
        }
    }

    useEffect(() => {
        getUsers();
    }, []);

    const openEditModal = (user: OrgUser) => {
        setEditUserState(user);
        setModalState(true);
    };

    const openTeamsModal = (user: OrgUser) => {
        setEditUserState(user);
        setTeamModalState(true);
    };

    const openFacilitiesModal = (user: OrgUser) => {
        getFacilities(user.usrId);
        setEditUserState(user);
        setFacilitiesModalState(true);
    };

    const handleUserChange = (userData: object) => {
        setEditUserState(Object.assign(editUserState, userData));
    };

    const handleUserEdit = async () => {
        try {
            setIsLoading(true);
            const userId = editUserState.usrId;

            await organizationService.editUser(userId, {
                firstName: editUserState.frstNm,
                lastName: editUserState.lastNm,
                roles: editUserState.roles,
            });

            setModalState(false);
            getUsers();
        } catch (e) {
            ToastStoreObject.show(parseError(e));
        } finally {
            setIsLoading(false);
        }
    };

    const updateUserActiveState = async (id: number, active: boolean) => {
        try {
            if (active) {
                handleConfirm(id);
            } else {
                await organizationService.reactivateUser(id);
                getUsers(showDeactivatedUsers);
            }
        } catch (e) {
            ToastStoreObject.show(parseError(e));
        }
    };

    const handleUserTeamChange = (data: any) => {
        setEditTeamState(data);
    };

    const handleUserTeamUpdate = async () => {
        try {
            setIsLoading(true);
            await organizationService.updateUserTeams(editUserState.usrId, editTeamState.facility, {
                teamIds: editTeamState.teams,
            });

            setTeamModalState(false);
        } catch (e) {
            ToastStoreObject.show(parseError(e));
        } finally {
            setIsLoading(false);
        }
    };

    const handleActionClick = (action: { id: string; label: string }, user: OrgUser) => {
        switch (action.id) {
            case 'edit':
                openEditModal(user);
                break;

            case 'teams':
                openTeamsModal(user);
                break;

            case 'facilities':
                openFacilitiesModal(user);
                break;

            case 'activate':
                updateUserActiveState(user.usrId, user.actvInd);
                break;
        }
    };

    const getUserRoles = (roles: Role[]) => {
        const roleDescriptions: string = roles.map((r) => r.roleDesc).join(', ');
        return <span>{roleDescriptions}</span>;
    };

    function handleUpdateSearchQuery(t: string) {
        setSearchValue(t);
        refreshSearchResults(t);
    }

    async function handleDeactivate(id: number) {
        await organizationService.deactivateUser(id);
        getUsers(showDeactivatedUsers);
        ModalStoreObject.hideModal();
    }

    async function handleUserFacilitiesUpdate() {
        try {
            setIsLoading(true);
            setFacilitiesModalState(false);
            await organizationService.updateUserFacilities(editUserState.usrId, facilitiesModalData);
        } catch (e) {
            ToastStoreObject.show(parseError(e));
        } finally {
            setIsLoading(false);
        }
    }

    function handleConfirm(id: number) {
        ModalStoreObject.showModal(ModalTypes.ConfirmationModal, {
            title: 'Are you sure you would like to deactivate this user?',
            onConfirm: () => handleDeactivate(id),
            onCancel: () => ModalStoreObject.hideModal(),
            confirmButtonText: 'Deactivate',
        });
    }

    function handleShowDeactivatedUsersChange(showDeactivatedUsers: boolean) {
        setShowDeactivatedUsers(showDeactivatedUsers);
        getUsers(showDeactivatedUsers);
        handleUpdateSearchQuery(searchValue);
    }

    const editUserName =
        editUserState && editUserState.frstNm ? `${editUserState.frstNm} ${editUserState.lastNm}` : 'User';

    return (
        <>
            <Flex self="stretch" align="center" justify="center">
                <div className={styles.searchWrap}>
                    <Input
                        className={styles.input}
                        placeholder="Search..."
                        value={searchValue}
                        onChangeText={(t: string) => handleUpdateSearchQuery(t)}
                    />
                </div>
                <Button
                    leftIcon={<Icon className={styles.plusIcon} name={ICONS.PlusButton} />}
                    type="primary"
                    onClick={() => props.onInvite()}
                    text="New Invitation"
                    isLocked={!userStore.userPermissions.canEdit.invitations}
                    data-test-id={ANALYTICS_NAMES.Users_CreateInvitation}
                />
            </Flex>
            <Flex self="stretch">
                <div className={styles.cardWrap}>
                    <Checkbox
                        checked={showDeactivatedUsers}
                        label={'Show deactivated users'}
                        onChange={(e) => {
                            handleShowDeactivatedUsersChange(e.target.checked);
                        }}
                        data-test-id={ANALYTICS_NAMES.Users_Show_Deactivated}
                    />
                </div>
            </Flex>
            <Card className={styles.cardWrap}>
                <div className={styles.tableTitle}>Users</div>
                <Table isLoading={isLoading} columns={columns} data={isSearching ? searchUsers : userState} />
            </Card>
            <SideModal isOpen={modalState} onClose={() => setModalState(false)}>
                <ModalHeader title={`Edit ${editUserName}`} onClose={() => setModalState(false)} />
                <EditUser user={editUserState} onChange={(userData: object) => handleUserChange(userData)} />
                <ModalFooter
                    primaryText="Save"
                    primaryClick={handleUserEdit}
                    secondaryClick={() => setModalState(false)}
                    isLoading={isLoading}
                />
            </SideModal>
            <CenterModal isOpen={teamModalState} onClose={() => setTeamModalState(false)}>
                <ModalHeader title={`Add/Remove ${editUserName} from Teams`} onClose={() => setTeamModalState(false)} />
                <UpdateTeamsModal user={editUserState} onChange={(s: any) => handleUserTeamChange(s)} />
                <ModalFooter
                    primaryText="Update"
                    primaryClick={handleUserTeamUpdate}
                    secondaryClick={() => setTeamModalState(false)}
                    isLoading={isLoading}
                />
            </CenterModal>
            <CenterModal isOpen={facilitiesModalState} onClose={() => setFacilitiesModalState(false)}>
                <ModalHeader
                    title={`Add/Remove ${editUserName} from Facilities`}
                    onClose={() => setFacilitiesModalState(false)}
                />
                {facilitiesLoading ? (
                    <div className={styles.loadingFacilities}>Loading User's Facilities...</div>
                ) : (
                    <FacilitySelectorModal
                        selectedFacilities={facilitiesModalData}
                        onChange={(s: any) => setFacilitiesModalData(s)}
                    />
                )}
                <ModalFooter
                    primaryText="Update"
                    primaryClick={handleUserFacilitiesUpdate}
                    secondaryClick={() => setFacilitiesModalState(false)}
                />
            </CenterModal>
        </>
    );
}
export default observer(Users);
