import { Org } from 'common/lib/entity/index/Org';
import { Facility } from 'common/lib/entity/org/Facility';
import { FacilitySubscriptionData } from 'common/lib/model/facility/FacilitySubscriptionData';
import * as ROUTES from 'src/api/routes';
import request from 'src/api/utils';
import * as utils from 'src/utils';

export async function fetchFacilities(includeInactive: boolean) {
    const facilities = await request({ ...ROUTES.GET_FACILITIES, params: { includeInactive } });
    return facilities;
}

export async function fetchFacilitiesByOrg(orgNmIntrnl: Org['orgNmIntrnl']): Promise<Facility[]> {
    const response = await request({ ...ROUTES.GET_FACILITIES_BY_ORG, pathParams: { org: orgNmIntrnl } });
    return response?.data || [];
}

export async function deactivateFacility(facId: number) {
    return await request({ ...ROUTES.DEACTIVATE_FACILITY, pathParams: { facilityId: facId } });
}

export async function reactivateFacility(facId: number) {
    return await request({
        ...ROUTES.UPDATE_FACILITY,
        pathParams: { facilityId: facId },
        data: { facId, actvInd: true },
    });
}

export async function updateFacility(facId: number, data: object) {
    const cleanedData = utils.removeNull(data);
    return await request({ ...ROUTES.UPDATE_FACILITY, pathParams: { facilityId: facId }, data: cleanedData });
}

export async function createFacility(data: object) {
    const cleanedData = utils.removeNull(data);
    return await request({ ...ROUTES.CREATE_FACILITY, data: cleanedData });
}

export async function updateFacilitySubscriptionData(
    facilityId: number,
    orgId: string | number,
    subscriptionData: FacilitySubscriptionData,
) {
    return await request({
        ...ROUTES.UPDATE_FACILITY_SUBSCRIPTION_DATA,
        pathParams: { facilityId },
        data: { orgId, subscriptionData },
    });
}
