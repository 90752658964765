import { FormDefinition } from '../../model/form/FormDefinition';
import { FormUtils } from './FormUtils';

export class ValidationUtil {
    private _formDefinition: FormDefinition;
    private _formUtils: FormUtils;
    private _pages: any;
    public validationMatrix: { [key: string]: boolean } = {};

    constructor(formDefinition: FormDefinition, formUtils: FormUtils, pages: any) {
        this._formDefinition = formDefinition;
        this._formUtils = formUtils;
        this._pages = pages;
    }

    public validate(modelData: any) {
        const pages = this._formDefinition.formDefinitionContent.form.pages[0].page;

        if (!pages || !pages.length) return;

        // Loop through each page
        pages.forEach((page: any) => {
            if (!page.discreteInputs) return;

            const pageData = this._pages.find((_page: any) => _page.pageName === page.$.name);
            const pageId = pageData && pageData.pageId;
            const discreteInputs = page.discreteInputs[0];
            const sections = discreteInputs.section;

            if (!sections || !sections.length) return;

            // Loop through each section in the page
            sections.forEach((section: any) => {
                const inputs = section.input;
                const inputGroups = section.inputGroup;

                const validateInput = (input: any) => {
                    const inputName = input.$.name;
                    const validators = input.validators;

                    if (!validators || !validators.length) return;

                    // Loop through each validator
                    validators.forEach((validator: any) => {
                        const validatorType = Object.keys(validator)[0];
                        const modelObject = validator[validatorType][0].$;
                        const value = this._formUtils.getModelPropertyValue(modelObject, modelData);

                        switch (validatorType) {
                            case 'notNull':
                                const isValid = value != null && value != '';

                                this.validationMatrix[inputName] = isValid;
                                break;

                            case 'expression':
                                // If the page doesn't exist, but it's a required field, return false
                                if (!pageId) {
                                    this.validationMatrix[inputName] = false;
                                } else {
                                    const expression = modelObject.expression;
                                    const expressionValue = this._formUtils.evaluateFormDefinitionExpression(
                                        pageId,
                                        expression,
                                    );

                                    this.validationMatrix[inputName] = expressionValue;
                                }
                                break;
                        }
                    });
                };

                if (inputs && inputs.length) {
                    // Loop through each input
                    inputs.forEach((input: any) => {
                        validateInput(input);
                    });
                }

                // Loop through input groups
                if (inputGroups && inputGroups.length) {
                    inputGroups.forEach((inputGroup: any) => {
                        const inputs = inputGroup.input || [];

                        inputs.forEach((input: any) => {
                            validateInput(input);
                        });
                    });
                }
            });
        });

        return this.validationMatrix;
    }

    public getFormCompletionPercentage() {
        if (!this.validationMatrix) return 0;

        const validationCount = Object.keys(this.validationMatrix).length;
        const validationFalseCount = Object.values(this.validationMatrix).filter((value: boolean) => !value).length;

        if (validationFalseCount === 0) return 1;
        if (!validationCount) return 0;

        return parseFloat(((validationCount - validationFalseCount) / validationCount).toFixed(2));
    }
}
