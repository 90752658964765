import { UseMutationResult, useMutation, useQueryClient } from 'react-query';
import { deployFormFacilityMapping } from 'src/api/formFacilityMappings';
import { FormCatalog } from 'common/lib/entity/framework/FormCatalog';
import { FormDeployment } from 'common/lib/entity/framework/FormDeployment';
import { formFacilityMappingKey } from './useFormFacilityMapping';

export const deployFormFacilityMappingKey = 'deployFormFacilityMapping';

export interface UseDeployFormFacilityMappingInputVariables {
    formDeploymentId: FormDeployment['formDeploymentId'];
}

export const useDeployFormFacilityMapping = (): UseMutationResult<
    void,
    Error,
    UseDeployFormFacilityMappingInputVariables,
    unknown
> => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationKey: [deployFormFacilityMappingKey],
        mutationFn: async (variables) => {
            const { formDeploymentId } = variables;

            if (!formDeploymentId) {
                throw new Error('formDeploymentId is required');
            }

            return await deployFormFacilityMapping(formDeploymentId);
        },
        onSuccess: async (data) => {
            queryClient.invalidateQueries({ queryKey: [formFacilityMappingKey] });
            return data;
        },
        retry: false,
    });
};
