import React from 'react';

const SvgLaptopHeart = (props) => (
    <svg width={24} height={23} {...props} viewBox="0 0 24 23">
        <g stroke={props.color || '#FFF'} strokeWidth={1.54} fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
            <path d="M17.282 21.305l-4.758-4.964a2.815 2.815 0 01-.527-3.249h0a2.815 2.815 0 014.508-.731l.777.777.777-.777a2.815 2.815 0 014.509.731h0a2.815 2.815 0 01-.528 3.249l-4.758 4.964zM20.074 9.379V2.556c0-.856-.695-1.55-1.551-1.55H5.807c-.856 0-1.55.694-1.55 1.55v10.545M9.839 16.822H6.232a4.914 4.914 0 01-4.767-3.721H9.84" />
        </g>
    </svg>
);

export default SvgLaptopHeart;
