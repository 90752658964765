import * as ROUTES from 'src/api/routes';
import request from 'src/api/utils';

export async function requestPasswordResetByUserName(username: string) {
    return await request({ ...ROUTES.REQUEST_PASSWORD_RESET_BY_USERNAME, pathParams: { username } });
}

export async function requestPasswordResetByUserId(userId: string) {
    return await request({ ...ROUTES.REQUEST_PASSWORD_RESET_BY_USERID, pathParams: { userId } });
}

export async function executePasswordReset(username: string, resetPasswordKey: string, newPassword: string) {
    return await request({
        ...ROUTES.EXECUTE_PASSWORD_RESET,
        pathParams: { username, resetPasswordKey },
        data: { newPassword },
    });
}
