import React, { useEffect, useState } from 'react';
import { CellInfo } from 'react-table';
import Button from 'src/components/Button';
import Flex from 'src/components/Flex';
import Icon from 'src/components/Icon';
import { ICONS } from 'src/utils/constants';
import styles from './styles.module.scss';
import Card from 'src/components/Card';
import Table from 'src/components/Table';
import DateComponent from 'src/components/DateComponent';
import { ModalStoreObject, ModalTypes } from 'src/stores/ModalStore';
import { useFormAssets } from 'src/queries/useFormAssets';
import Input, { Label } from 'src/components/Input';
import CodeEditor from 'src/components/CodeEditor';
import FormAssetImage from './FormAssetImage';
import { ANALYTICS_NAMES } from 'src/utils/analytics';
import Checkbox from 'src/components/Checkbox';
import OrgDropDown from 'src/components/OrgDropDown';
import FacilitiesByOrgDropDown from 'src/components/FacilitiesByOrgDropDown';
import { useUpdateFormAsset } from 'src/queries/useUpdateFormAsset';
import { ToastStoreObject, ToastType } from 'src/stores/ToastStore';
import { FormAsset } from 'common/lib/entity/framework/FormAsset';

function Library() {
    const [selectedAsset, setSelectedAsset] = useState(null);
    const [formAssetName, setFormAssetName] = useState('');
    const [formAssetDescription, setFormAssetDescription] = useState('');
    const [formAssetContent, setFormAssetContent] = useState('');
    const [isGlobal, setIsGlobal] = useState(false);
    const [orgNameInternal, setOrgNameInternal] = useState('');
    const [facilityId, setFacilityId] = useState<number>(null);
    const [fileName, setFileName] = useState('');
    const [isChanged, setIsChanged] = useState(false);
    const { data: formAssets, isFetching } = useFormAssets();
    const {
        mutate: updateFormAsset,
        isLoading: isUpdateFormAssetLoading,
        isSuccess: isUpdateFormAssetSuccess,
        isError: isUpdateFormAssetError,
        error: updateFormAssetError,
    } = useUpdateFormAsset();

    useEffect(() => {
        if (selectedAsset) {
            setFormAssetName(selectedAsset.formAssetName);
            setFormAssetDescription(selectedAsset.formAssetDescription);
            setFormAssetContent(selectedAsset.formAssetContent);
            setFileName('');
            setIsGlobal(selectedAsset.isGlobal);
            setOrgNameInternal(selectedAsset.orgNameInternal);
            setFacilityId(selectedAsset.facilityId);
        }
    }, [selectedAsset]);

    useEffect(() => {
        if (isGlobal) {
            setOrgNameInternal('');
            setFacilityId(null);
        }
    }, [isGlobal]);

    useEffect(() => {
        if (isUpdateFormAssetSuccess) {
            ToastStoreObject.show('Form asset updated successfully', ToastType.Success);
        }

        if (isUpdateFormAssetError) {
            ToastStoreObject.show(updateFormAssetError.message, ToastType.Error);
        }
    }, [isUpdateFormAssetSuccess, isUpdateFormAssetError]);

    const columns = [
        {
            Header: 'Name',
            accessor: 'formAssetName',
            Cell: (props: CellInfo) => <span>{props.value}</span>,
        },
        {
            Header: 'Type',
            accessor: 'formAssetType.formAssetTypeTitle',
        },
        {
            Header: 'Scope',
            Cell: (props: CellInfo) => <span>{getAssetScopeLabel(props.original)}</span>,
        },
        {
            Header: 'Updated',
            accessor: 'updatedAt',
            maxWidth: 200,
            Cell: (props: CellInfo) => (
                <span>
                    <DateComponent date={props.value} />
                </span>
            ),
        },
        {
            Header: 'Actions',
            maxWidth: 150,
            style: { paddingVertical: 0, paddingHorizontal: 20 },
            Cell: (props: CellInfo) => (
                <Flex gap={8}>
                    <Button
                        type="small"
                        text="View »"
                        data-test-id={ANALYTICS_NAMES.FormAssets_ViewAsset}
                        onClick={() => setSelectedAsset(props.original)}
                    />
                </Flex>
            ),
        },
    ];

    function getAssetScopeLabel(formAsset:FormAsset) {
        if(formAsset.isGlobal) {
            return 'Global';
        }
        else {
            if(formAsset.facilityId) {
                return `${formAsset.orgNameInternal} (${formAsset.facilityId})`;
            }
            else {
                return formAsset.orgNameInternal;
            }
        }
    }

    function getAssetContent() {
        const assetTypeCode = selectedAsset.formAssetType.formAssetTypeCode;

        switch (assetTypeCode) {
            case 'CODE':
                return (
                    <>
                        <Label text="Code" className={styles.labelWrapWithTop} />
                        <CodeEditor
                            mode="xml"
                            theme="xcode"
                            value={formAssetContent}
                            onChange={(v: string) => {
                                setIsChanged(true);
                                setFormAssetContent(v);
                            }}
                        />
                    </>
                );
            case 'IMAGE':
                return (
                    <>
                        <Label text="Image" className={styles.labelWrap} />
                        <FormAssetImage selectedAsset={selectedAsset} inlineImage={formAssetContent} />
                        <div className={styles.replaceImage}>
                            <Label text="Replace Image" className={styles.labelWrap} />
                            <Flex align="center" justify="start">
                                <input
                                    type="file"
                                    id="formAssetsPageFileInput"
                                    className={styles.hidden}
                                    accept="image/png,image/jpeg,image/jpg"
                                    onChange={(e) => {
                                        const uploadedFile = e.target.files[0];
                                        if (!uploadedFile) {
                                            return;
                                        }

                                        const reader = new FileReader();
                                        reader.onload = (e) => {
                                            if (e.target?.result) {
                                                setFormAssetContent(e.target.result.toString());
                                            }
                                        };
                                        reader.readAsDataURL(uploadedFile);

                                        setFileName(uploadedFile.name);
                                        setIsChanged(true);
                                    }}
                                />
                                <label htmlFor="formAssetsPageFileInput" className={styles.fileInputButton}>
                                    Choose a File
                                </label>
                                <span className={styles.fileName}>{`${fileName ? fileName : 'No File Chosen'}`}</span>
                            </Flex>
                        </div>
                    </>
                );
        }
    }

    function handleSave() {
        updateFormAsset({
            formAssetId: selectedAsset.formAssetId,
            formAssetDescription,
            formAssetContent,
        });
    }

    return (
        <>
            <Flex direction="row" justify="end">
                <Button
                    leftIcon={<Icon className={styles.plusIcon} name={ICONS.PlusButton} />}
                    type="primary"
                    onClick={() => {
                        ModalStoreObject.showModal(ModalTypes.FormAssetModal, {
                            onClose: () => ModalStoreObject.hideModal(),
                            onSave: () => ModalStoreObject.hideModal(),
                        });
                    }}
                    text="New Asset"
                />
            </Flex>
            <Flex direction="row" gap={24}>
                <Card className={styles.cardWrap}>
                    <Table
                        isLoading={isFetching && formAssets.length === 0}
                        columns={columns}
                        data={formAssets}
                        showPagination={false}
                    />
                </Card>
                <Card className={styles.cardWrap}>
                    {selectedAsset ? (
                        <>
                            <Flex className={styles.header}>
                                <div className={styles.title}>{selectedAsset.formAssetName}</div>
                                <Flex className={styles.globalCheckbox}>
                                    <Checkbox
                                        checkboxClassName={styles.customCheckbox}
                                        checked={isGlobal}
                                        label="Is Global?"
                                        disabled={true}
                                        onChange={(e) => setIsGlobal(e.target.checked)}
                                    />
                                </Flex>
                            </Flex>
                            {!isGlobal && (
                                <Flex gap={16} className={styles.header}>
                                    <div className={styles.organization}>
                                        <OrgDropDown
                                            onChange={(o: { label: string; value: string }) =>
                                                setOrgNameInternal(o.value)
                                            }
                                            disabled={true}
                                            selectedValue={orgNameInternal}
                                        />
                                    </div>
                                    {orgNameInternal && (
                                        <div className={styles.facility}>
                                            <FacilitiesByOrgDropDown
                                                orgNameInternal={orgNameInternal}
                                                selectedValue={facilityId}
                                                disabled={true}
                                                onChange={(o: { label: string; value: number }) =>
                                                    setFacilityId(o.value)
                                                }
                                            />
                                        </div>
                                    )}
                                </Flex>
                            )}
                            <div className={styles.content}>
                                <Input
                                    label="Name"
                                    value={formAssetName}
                                    disabled={true}
                                    onChangeText={(v: string) => {
                                        setIsChanged(true);
                                        setFormAssetName(v);
                                    }}
                                />
                                <Input
                                    label="Description"
                                    value={formAssetDescription}
                                    onChangeText={(v: string) => {
                                        setIsChanged(true);
                                        setFormAssetDescription(v);
                                    }}
                                />
                                {getAssetContent()}
                                <Flex justify="end" className={styles.controls}>
                                    <Button
                                        type="primary"
                                        onClick={() => handleSave()}
                                        text="Save"
                                        disabled={!isChanged}
                                        isLoading={isUpdateFormAssetLoading}
                                    />
                                </Flex>
                            </div>
                        </>
                    ) : (
                        <div className={styles.empty}>Select an asset to view</div>
                    )}
                </Card>
            </Flex>
        </>
    );
}

export default Library;
