import * as ROUTES from 'src/api/routes';
import request from 'src/api/utils';
import { SurveyResponse } from 'common/lib/model/surveys/Survey';

export async function getSurveyForPatient(surveyRequestGuid: string) {
    return await request({ ...ROUTES.GET_SURVEY_FOR_PATIENT, pathParams: { surveyRequestGuid } });
}
export async function getSurveyByEncFormId(encounterFormId: string | number) {
    return await request({ ...ROUTES.GET_SURVEY_BY_ENC_FORM_ID, pathParams: { encounterFormId } });
}
export async function getSurveyFacilityForPatient(surveyGuid: string) {
    return await request({ ...ROUTES.GET_SURVEY_FACILITY_FOR_PATIENT, pathParams: { surveyGuid } });
}
export async function submitSurveyForPatient(surveyGuid: string, responseData: SurveyResponse) {
    return await request({ ...ROUTES.SUBMIT_SURVEY, pathParams: { surveyGuid }, data: { responseData } });
}
export async function updateSurveyForPatient(surveyGuid: string, responseData: SurveyResponse) {
    return await request({ ...ROUTES.UPDATE_SURVEY, pathParams: { surveyGuid }, data: { responseData } });
}
export async function getSurveyRequestAndFacility(surveyRequestGuid: string) {
    return await request({ ...ROUTES.GET_SURVEY_REQUEST_AND_FACILITY, pathParams: { surveyRequestGuid } });
}
export async function unsubscribeSurveyRequest(surveyRequestGuid: string) {
    return await request({ ...ROUTES.UNSUBSCRIBE_SURVEY, pathParams: { surveyRequestGuid } });
}
