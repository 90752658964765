import React, { useContext } from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { observer } from 'mobx-react';
import styles from 'src/containers/Reports/Macra/styles.module.scss';
import Flex from 'src/components/Flex';
import Card from 'src/components/Card';
import Button from 'src/components/Button';
import LoadingIcon from 'src/components/LoadingIcon';
import MacraStore from 'src/stores/MacraStore';
import * as variables from 'src/styles/variables';

function MacraMeasures() {
    const macraStore = useContext(MacraStore);

    function getMeasureMeansBarChartData() {
        return macraStore.measureMeans.map((data, index) => {
            // If both the numerator and demoninator are 0, return false
            if (data.numerator === 0 && data.denominator === 0) {
                return {
                    value: 0,
                    label: data.measureDefinition.label,
                };
            }

            return {
                value: Math.round(data.mean * 100),
                label: data.measureDefinition.label,
                numerator: data.numerator,
                denominator: data.denominator,
            };
        });
    }

    function getMeasureMeansBarChart() {
        const measureChartData = getMeasureMeansBarChartData();
        return (
            <div className={styles.view}>
                <ResponsiveBar
                    data={measureChartData}
                    indexBy="label"
                    minValue={0}
                    maxValue={100}
                    margin={{ top: 50, right: 60, bottom: 50, left: 60 }}
                    padding={0.3}
                    colors={[variables.blue]}
                    axisTop={null}
                    axisRight={null}
                    axisBottom={{
                        tickSize: 10,
                        tickPadding: 5,
                        tickRotation: 0,
                    }}
                    axisLeft={{
                        tickSize: 10,
                        tickPadding: 5,
                        tickRotation: 0,
                        tickValues: 3,
                    }}
                    label={(d) => `${d.value}%`}
                    labelSkipWidth={12}
                    labelSkipHeight={12}
                    labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                    animate={true}
                    motionStiffness={90}
                    motionDamping={15}
                    tooltip={({ id, value, color, data }) => (
                        <div>
                            {data.label}
                            <br />
                            <strong style={{ color }}>
                                {value}% ({data.numerator as string} / {data.denominator as string})
                            </strong>
                        </div>
                    )}
                />
            </div>
        );
    }

    return (
        <Flex direction="column">
            <Card className={styles.cardWrap}>
                <Flex direction="column" align="center">
                    <h3>Performance Met by Measure</h3>
                </Flex>
                {macraStore.isLoading ? (
                    <Flex direction="row" align="center" justify="center" className={styles.loading}>
                        <LoadingIcon type="dot" />
                    </Flex>
                ) : (
                    <Flex align="stretch">{getMeasureMeansBarChart()}</Flex>
                )}

                <Flex direction="column" align="center">
                    <Button
                        type="outline"
                        text="Measure Definitions"
                        onClick={(e) => {
                            window.open('https://help.graphiumhealth.com/quality-measures-table-of-contents', '_blank');
                        }}
                    />
                </Flex>
            </Card>
        </Flex>
    );
}

export default observer(MacraMeasures);
