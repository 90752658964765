import React, { useState, useEffect, useContext } from 'react';
import styles from './styles.module.scss';
import Select from 'react-select';
import { observer } from 'mobx-react';
import * as utils from 'src/utils';
import useIsOptionsLoading from 'src/utils/hooks/useIsOptionsLoading';
import AdminStore from 'src/stores/AdminStore';

export interface AdminJobSchedulesDropDownProps {
    onChange: Function;
    selectedValue?: string | number;
    allowEmpty?: boolean;
}

function AdminJobSchedulesDropDown(props: AdminJobSchedulesDropDownProps) {
    const adminStore = useContext(AdminStore);
    const [selected, setSelected] = useState(null);

    const options = adminStore.jobScheduleDropdownValues;
    const isLoading = useIsOptionsLoading(options);

    useEffect(() => {
        adminStore.getJobSchedules();
    }, []);

    useEffect(() => {
        const selectedVal = options.find((i) => i.value === props.selectedValue);
        if (selectedVal) {
            setSelected(selectedVal);
        }
    }, [props.selectedValue, options]);

    return (
        <Select
            value={selected}
            onChange={(s: any) => {
                setSelected(s);
                props.onChange(s || null);
            }}
            isLoading={isLoading}
            className={styles.select}
            isClearable={props.allowEmpty}
            options={options}
            placeholder="Select Job Schedule"
            onMenuOpen={() => {
                // Make sure it refetches when the menu is opened in case there was an initial load issue
                adminStore.getJobSchedules();
            }}
            styles={utils.styleSelectComponent()}
        />
    );
}

export default observer(AdminJobSchedulesDropDown);
