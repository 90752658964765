import React, { ReactNode } from 'react';
import styles from './styles.module.scss';
import { ANALYTICS_NAMES } from 'src/utils/analytics';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

interface Props {
    type: 'slideFromRight' | 'scaleCenter' | 'fadeIn';
    children: ReactNode;
}

function getClassName(type: Props['type']) {
    if (type === 'slideFromRight') {
        return { main: styles.slideFromRight, active: styles.slideFromRightActive };
    } else if (type === 'scaleCenter') {
        return { main: styles.scaleCenter, active: styles.scaleCenterActive };
    } else if (type === 'fadeIn') {
        return { main: styles.fadeIn, active: styles.fadeInActive };
    } else {
        return { main: undefined, active: undefined };
    }
}

function AnimatedComponent(props: Props) {
    return (
        <TransitionGroup>
            <CSSTransition
                addEndListener={() => {}}
                classNames={{
                    enter: undefined,
                    exit: styles.exit, // Exit animations on modals do not work because entire cssTransitionGroup gets unmounted.
                    exitActive: styles.exitActive,
                    appear: getClassName(props.type).main,
                    appearActive: getClassName(props.type).active,
                }}
                appear={true}
                timeout={{ enter: 300, exit: 300 }}
                data-test-id={ANALYTICS_NAMES.AnimatedComponent_Transition_Group}
            >
                {props.children}
            </CSSTransition>
        </TransitionGroup>
    );
}

export default AnimatedComponent;
