import { Customer } from 'common/lib/entity/index/Customer';
import {
    IAgencyConfiguration,
    IAgencyContact,
    IAgencyInformation,
    IAgencyCustomResult,
} from 'common/lib/model/nemsis3_4/demographic';
import { NemsisValidationResult } from 'common/lib/model/NemsisValidationResult';
import { observable, action, makeObservable } from 'mobx';
import { createContext } from 'react';
import * as organizationAPI from 'src/api/organization';
import Storage from 'src/utils/storage';
import { OrgOpt } from 'src/utils/types';

export interface Organization {
    orgId?: number;
    orgNm?: string;
    orgNmIntrnl?: string;
    actvInd?: boolean;
    insDttm?: string;
    updDttm?: string;
    audVer?: number;
    customerOrg?: {
        customer?: Customer;
    };
}

export interface OrganizationOrgDB {
    orgId?: number;
    orgNm?: string;
    orgNmIntrnl?: string;
    agencyInfo?: IAgencyInformation;
    agencyContacts?: IAgencyContact[];
    agencyConfigurations?: IAgencyConfiguration[];
    agencyCustomConfigurations: any;
    agencyCustomResults: IAgencyCustomResult[];
    actvInd?: boolean;
    insDttm?: string;
    updDttm?: string;
    audVer?: number;
}

class OrgStore {
    @observable orgs: Organization[] = [];
    @observable orgTypes: OrgOpt[] = [];
    @observable orgTelehealthOptions: OrgOpt[] = [];
    @observable orgVitalsOptions: OrgOpt[] = [];
    @observable orgAnalyticsOptions: OrgOpt[] = [];
    @observable currentOrgDbOrg: OrganizationOrgDB = null;
    isOrgDbOrgLoading: boolean = false;

    constructor() {
        makeObservable(this);
        this.populateData();
    }

    @action
    reset() {
        this.setOrgsForUser([]);
        this.setOrgTypes([]);
        this.setOrgTelehealthOptions([]);
        this.setOrgVitalsOptions([]);
        this.setOrgAnalyticsOptions([]);
    }

    @action
    async getOrgDbOrg() {
        if (this.isOrgDbOrgLoading) return;

        this.isOrgDbOrgLoading = true;
        const results = await organizationAPI.getOrgDbOrg();
        this.currentOrgDbOrg = results.data;
        this.isOrgDbOrgLoading = false;
    }

    @action
    async getOrgsForUser(force = false) {
        // We already have the orgs loaded, don't fetch again
        if (!force && this.orgs.length > 0) {
            return;
        }
        const orgs = await organizationAPI.getOrgsForUser();
        this.setOrgsForUser(orgs.data || []);
    }

    @action
    setOrgsForUser(orgs: Organization[]) {
        this.orgs = orgs;
        Storage.set('organizations', this.orgs);
    }

    @action
    setOrgTypes(orgTypes: OrgOpt[]) {
        this.orgTypes = orgTypes;
        Storage.set('orgTypes', this.orgTypes);
    }

    @action
    setOrgTelehealthOptions(orgTelehealthOptions: OrgOpt[]) {
        this.orgTelehealthOptions = orgTelehealthOptions;
        Storage.set('orgTelehealthOptions', this.orgTelehealthOptions);
    }

    @action
    setOrgVitalsOptions(orgVitalsOptions: OrgOpt[]) {
        this.orgVitalsOptions = orgVitalsOptions;
        Storage.set('orgVitalsOptions', this.orgVitalsOptions);
    }

    @action
    setOrgAnalyticsOptions(orgAnalyticsOptions: OrgOpt[]) {
        this.orgAnalyticsOptions = orgAnalyticsOptions;
        Storage.set('orgAnalyticsOptions', this.orgAnalyticsOptions);
    }

    populateData() {
        const orgs = Storage.get('organizations');
        if (orgs && Array.isArray(orgs)) {
            this.orgs = orgs;
        }
        const orgTypes = Storage.get('orgTypes');
        if (orgTypes && Array.isArray(orgTypes)) {
            this.orgTypes = orgTypes;
        }
        const orgTelehealthOptions = Storage.get('orgTelehealthOptions');
        if (orgTelehealthOptions && Array.isArray(orgTelehealthOptions)) {
            this.orgTelehealthOptions = orgTelehealthOptions;
        }
        const orgVitalsOptions = Storage.get('orgVitalsOptions');
        if (orgVitalsOptions && Array.isArray(orgVitalsOptions)) {
            this.orgVitalsOptions = orgVitalsOptions;
        }
        const orgAnalyticsOptions = Storage.get('orgAnalyticsOptions');
        if (orgAnalyticsOptions && Array.isArray(orgAnalyticsOptions)) {
            this.orgAnalyticsOptions = orgAnalyticsOptions;
        }
    }
}

// Import this to any other store that needs to use a value from it
export const OrgStoreObject = new OrgStore();

export default createContext(OrgStoreObject);
