import React, { useContext } from 'react';
import ErrorStore, { ErrorTypes } from 'src/stores/ErrorStore';
import LoadingError from '../LoadingError';
import { observer } from 'mobx-react';
import { useRouteChange } from 'src/utils/hooks/useReactRouter';
import AuthStore from 'src/stores/AuthStore';

function ErrorProvider({ children }: { children: React.ReactNode }) {
    const errorStore = useContext(ErrorStore);
    const authStore = useContext(AuthStore);
    useRouteChange(() => {
        const isStartupError = errorStore.errors[ErrorTypes.Startup];
        if (errorStore.errors[ErrorTypes.Loading] || isStartupError) {
            errorStore.clearAllErrors();

            // If there was a startup error and the user is trying to navigate away, reload the startup API calls
            if (isStartupError) {
                authStore.setLoadingStartupData('load');
            }
        }
    }, []);

    if (errorStore.errors[ErrorTypes.Loading]) {
        return <LoadingError />;
    }
    if (errorStore.errors[ErrorTypes.Startup]) {
        return <LoadingError text="There was an issue connecting to this service. Please try again later." />;
    }
    return <>{children}</>;
}

export default observer(ErrorProvider);
