import React from 'react';

const SvgDotMenu = (props) => (
    <svg width={4} height={16} {...props} viewBox="0 0 4 16">
        <path
            d="M2 12a2 2 0 110 4 2 2 0 010-4zm0-6a2 2 0 110 4 2 2 0 010-4zm0-6a2 2 0 110 4 2 2 0 010-4z"
            fill={props.color || '#FFF'}
            fillRule="evenodd"
        />
    </svg>
);

export default SvgDotMenu;
