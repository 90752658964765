import React, { useState, useRef } from 'react';
import styles from './styles.module.scss';
import Button from 'src/components/Button';
import Portal from 'src/components/Portal';
import { getActions, Action } from 'src/utils/actions';
import { OrgUser } from 'src/stores/OrgUserStore';
import useClickOutside from 'src/utils/hooks/useClickOutside';
import { ReferenceListValue } from 'src/stores/ReferenceListStore';
import Icon from 'src/components/Icon';
import { ICONS } from 'src/utils/constants';
import * as variables from 'src/styles/variables';
import { ANALYTICS_NAMES } from 'src/utils/analytics';
import classNames from 'classnames';

const MENU_PADDING = 4;

interface MenuProps {
    entityType?: string;
    entity?: OrgUser | ReferenceListValue;
    onItemClick: Function;
    actions?: Action[];
}

function ActionMenu(props: MenuProps) {
    const node = useRef(null);
    const portalNode = useRef(null);
    const [isOpen, setIsOpen] = useState(false);
    const [boundingRect, setBoundingRect] = useState({ top: 0, left: 0, height: 0 });

    useClickOutside(() => setIsOpen(false), [node, portalNode]);

    function toggle(mouseEvent: any) {
        if (
            isOpen &&
            (node.current.contains(mouseEvent.target) ||
                (portalNode.current && portalNode.current.contains(mouseEvent.target)))
        )
            return;

        setIsOpen(!isOpen);
        setBoundingRect(node.current.getBoundingClientRect());
    }

    function handleItemClick(action: object) {
        props.onItemClick(action);
        setIsOpen(false);
    }

    function getItemActions() {
        const actions = props.actions || getActions(props.entityType, props.entity);
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

        return (
            <Portal data-test-id={ANALYTICS_NAMES.ActionMenu_Portal}>
                <div
                    ref={portalNode}
                    className={styles.actionWindow}
                    style={{
                        top: boundingRect.top + boundingRect.height + scrollTop + MENU_PADDING,
                        left: boundingRect.left,
                    }}
                >
                    {actions.filter(Boolean).map((action, index) => (
                        <div
                            onClick={action.isLocked ? undefined : () => handleItemClick(action)}
                            className={
                                action.isLocked ? classNames([styles.locked, styles.actionItem]) : styles.actionItem
                            }
                            key={index}
                            data-test-id={action['data-test-id']}
                        >
                            {action.isLocked ? <Icon className={styles.lockIcon} name="lock" type="fa" /> : null}
                            {action.label}
                        </div>
                    ))}
                </div>
            </Portal>
        );
    }

    return (
        <div ref={node}>
            <div className={styles.icon}>
                <Button
                    preventDouble={false}
                    onClick={(e) => toggle(e)}
                    type="secondary"
                    leftIcon={<Icon name={ICONS.Cog3} color={variables.red} size={25} />}
                    data-test-id={ANALYTICS_NAMES.ActionMenu_Gear}
                />
            </div>
            {isOpen ? getItemActions() : null}
        </div>
    );
}

export default ActionMenu;
