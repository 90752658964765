import React, { useEffect, useState } from 'react';
import Flex from 'src/components/Flex';
import styles from './styles.module.scss';
import * as variables from 'src/styles/variables';
import FlatfileImporter from '@flatfile/adapter';
import { UserStoreObject } from 'src/stores/UserStore';
import { ToastStoreObject } from 'src/stores/ToastStore';
import LoadingIcon from 'src/components/LoadingIcon';
import Button from 'src/components/Button';

interface ImportProps {
    onChange?: Function;
    onClose?: Function;
}

function Import(props: ImportProps) {
    const [isLoading, setIsLoading] = useState(true);
    const [importer, setImporter] = useState<FlatfileImporter>(null);
    const [records, setRecords] = useState(null);

    useEffect(() => {
        if (props.onChange) {
            props.onChange(records);
        }
    }, [records]);

    useEffect(() => {
        const flatfileKey: string = import.meta.env.VITE_FLATFILE_KEY.toString();
        const flatfileImporter = new FlatfileImporter(flatfileKey, {
            type: 'PaymentRequestImport',
            title: 'Import Payment Requests',
            fields: [
                {
                    key: 'encounterNumber',
                    label: 'Encounter Number',
                    validators: [{ validate: 'required' }, { validate: 'unique' }],
                },
                {
                    key: 'procedureDate',
                    label: 'Procedure Date',
                    validators: [
                        { validate: 'required' },
                        {
                            error: 'Procedure Date should be in YYYY-MM-DD format',
                            regex: '^\\d{4}\\-(0[1-9]|1[012])\\-(0[1-9]|[12][0-9]|3[01])$',
                            validate: 'regex_matches',
                        },
                    ],
                },
                {
                    key: 'firstName',
                    label: 'Patient First Name',
                },
                {
                    key: 'lastName',
                    label: 'Patient Last Name',
                },
                {
                    key: 'email',
                    label: 'Patient Email',
                    validators: [
                        { validate: 'unique' },
                        {
                            error: 'Patient Email should be in the correct format.',
                            regex: '^[^\\s@]+@[^\\s@]+\\.[^\\s@]+$',
                            validate: 'regex_matches',
                        },
                    ],
                },
                {
                    key: 'phone',
                    label: 'Patient Phone',
                    validators: [
                        { validate: 'unique' },
                        {
                            error: 'Patient Phone should be in the correct format.',
                            regex: '^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}$',
                            validate: 'regex_matches',
                        },
                    ],
                },
                {
                    key: 'payers',
                    label: 'Payer Name',
                },
            ],
            devMode: import.meta.env.VITE_APP_ENV !== 'pro' ? true : false,
            styleOverrides: {
                primaryButtonColor: variables.red,
                secondaryButtonColor: variables.lightGrey4,
                secondaryButtonFontColor: variables.black,
                linkColor: variables.red,
                fontFamily: variables.fontFamily,
            },
        });
        flatfileImporter.setCustomer({ userId: UserStoreObject.orgUsrId.toString() });
        flatfileImporter.addListener('close', () => {
            setIsLoading(false);
        });

        setImporter(flatfileImporter);
    }, []);

    useEffect(() => {
        handleUpload();
    }, [importer]);

    async function handleUpload() {
        if (importer) {
            const results = await importer.requestDataFromUser();
            if (results) {
                const flatfileRecords = results.validData;

                if (flatfileRecords.length) {
                    setRecords(flatfileRecords);
                    importer.close();
                } else {
                    ToastStoreObject.show('No records found, please retry and ensure valid records are imported.');
                }
            }
        }
    }

    return (
        <Flex align="center" justify="center" className={styles.bigLoader}>
            {!isLoading ? <Button type="primary" onClick={handleUpload} text="Open Importer" /> : <LoadingIcon />}
        </Flex>
    );
}

export default Import;
