import React, { useState, useContext, useCallback } from 'react';
import NavBar from 'src/containers/NavBar';
import PageContent from 'src/components/PageContent';
import PageContainer from 'src/components/PageContainer';
import Flex from 'src/components/Flex';
import styles from './styles.module.scss';
import * as variables from 'src/styles/variables';
import { observer } from 'mobx-react';
import FormStore from 'src/stores/FormStore';
import ActionMenu from 'src/components/ActionMenu';
import { ModalStoreObject, ModalTypes } from 'src/stores/ModalStore';
import EncounterFormPdf from '../EncounterFormPdf';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { EncounterFormPdfResponse } from 'src/utils/types';
import { ToastStoreObject, ToastType } from 'src/stores/ToastStore';
import Icon from 'src/components/Icon';
import { ICONS, ROUTES } from 'src/utils/constants';
import axios from 'axios';
import { parseError } from 'src/utils';

function ViewEncounterForm() {
    const formStore = useContext(FormStore);
    const history = useHistory();
    const params = useParams<{ facId: string; encounterFormId: string }>();
    const facId = parseInt(params.facId);
    const encounterFormId = parseInt(params.encounterFormId);
    const [encounterFormData, setEncounterFormData] = useState<EncounterFormPdfResponse>(null);
    const [pdfLink, setPdfLink] = useState('');
    const getPdfLink = useCallback((pdfLink) => {
        setPdfLink(pdfLink);
    }, []);
    async function changeVoidEncounterForm(voidInd: boolean) {
        try {
            await formStore.updateEncounterFormVoidStatus(encounterFormData.encounterFormId, voidInd);
            ToastStoreObject.show(
                `${encounterFormData.formDef.formDefinitionTitle} form was ${voidInd ? 'voided' : 'unvoided'}.`,
                ToastType.Success,
            );
            history.push(
                ROUTES.getString(ROUTES.Forms, null, {
                    orgNm: encounterFormData.organization.organizationNameInternal,
                }),
            );
        } catch (e) {
            ToastStoreObject.show(parseError(e), ToastType.Error);
        } finally {
            ModalStoreObject.hideModal();
        }
    }

    const handleActionClick = (action: { id: string; label: string }) => {
        switch (action.id) {
            case 'void':
                ModalStoreObject.showModal(ModalTypes.ConfirmationModal, {
                    title: 'Are you sure you want to void this form?',
                    onConfirm: () => changeVoidEncounterForm(true),
                    onCancel: () => ModalStoreObject.hideModal(),
                    confirmButtonText: 'Yes, Void',
                    cancelButtonText: 'No',
                });
                break;
            case 'unvoid':
                ModalStoreObject.showModal(ModalTypes.ConfirmationModal, {
                    title: 'Are you sure you want to unvoid this form?',
                    onConfirm: () => changeVoidEncounterForm(false),
                    onCancel: () => ModalStoreObject.hideModal(),
                    confirmButtonText: 'Yes, Unvoid',
                    cancelButtonText: 'No',
                });
                break;
            case 'edit':
                ModalStoreObject.showModal(ModalTypes.EditEncounterForm, {
                    onClose: async () => {
                        ModalStoreObject.hideModal();
                        const updatedForm = await formStore.getEncounterForm(encounterFormId, facId);
                        setEncounterFormData(updatedForm.data || {});
                    },
                    enctrId: encounterFormData.encounterId,
                    facId: facId,
                    enctrFormId: encounterFormData.encounterFormId,
                });
                break;
            case 'download':
                downloadPdf();
                break;
        }
    };

    function downloadPdf() {
        axios({
            url: pdfLink,
            method: 'GET',
            responseType: 'blob', // important
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', getFileName());
            document.body.appendChild(link);
            link.click();
        });
    }

    function getFileName() {
        let filename = 'download';
        if (
            encounterFormData &&
            encounterFormData.formDef &&
            encounterFormData.formDef.formDefinitionTitle &&
            encounterFormData.encounterFormId
        ) {
            filename = `${encounterFormData.formDef.formDefinitionTitle} - ${encounterFormData.encounterFormId}`;
        }

        return `${filename}.pdf`;
    }

    function handleErrorLoading() {
        ToastStoreObject.show('There was an error loading this form', ToastType.Error);
        history.goBack();
    }

    // Prevent bad urls from getting to this page
    if (Number.isNaN(encounterFormId) || Number.isNaN(facId)) {
        <Redirect to={{ pathname: ROUTES.Home }} />;
    }

    return (
        <PageContainer>
            <NavBar />
            <Flex align="center" className={styles.backWrap} onClick={() => history.goBack()}>
                <Flex direction="row" align="center" className={styles.backContainer}>
                    <Icon name={ICONS.ArrowSide} color={variables.grey} />
                    <span className={styles.backText}>Back</span>
                </Flex>
            </Flex>
            <PageContent>
                {encounterFormData && (
                    <Flex direction="row" align="center">
                        <Flex value={1} className={styles.title}>
                            {encounterFormData.formDef.formDefinitionTitle || '-'}
                        </Flex>
                        <ActionMenu
                            entityType={encounterFormData.voided ? 'viewFormVoided' : 'viewForm'}
                            onItemClick={handleActionClick}
                        />
                    </Flex>
                )}
                <EncounterFormPdf
                    enctrFormId={encounterFormId}
                    onErrorLoading={handleErrorLoading}
                    facId={facId}
                    onSetData={setEncounterFormData}
                    getPdfLink={getPdfLink}
                    encounterFormData={encounterFormData || null}
                />
            </PageContent>
        </PageContainer>
    );
}
export default observer(ViewEncounterForm);
