import { Customer, CustomerContact } from 'common/lib/entity/index/Customer';
import { ContactType } from 'common/lib/model/CustomerContactType';
import React, { useContext, useEffect, useState } from 'react';
import Select from 'react-select';
import CustomerStore from 'src/stores/CustomerStore';
import { ToastStoreObject } from 'src/stores/ToastStore';
import { parseError, styleSelectComponent } from 'src/utils';
import { ANALYTICS_NAMES } from 'src/utils/analytics';
import Input, { Label } from '../Input';
import ModalFooter from '../ModalFooter';
import ModalHeader from '../ModalHeader';
import SideModal from '../SideModal';
import styles from './styles.module.scss';
import { EMAIL_REGX, PHONE_REGX, getAutoFormatPhone } from 'common/lib/util/phone';

interface CustomerContactModalProps {
    customer: Customer;
    contact: CustomerContact;
    contactType?: string;
    mode?: string;
    onClose?: Function;
    onSave?: Function;
}

function CustomerContactModal(props: CustomerContactModalProps) {
    const [contact, setContact] = useState(props.contact || {});
    const [firstName, setFirstName] = useState(props.contact?.firstName || '');
    const [lastName, setLastName] = useState(props.contact?.lastName || '');
    const [title, setTitle] = useState(props.contact?.title || '');
    const [phone, setPhone] = useState(props.contact?.phone || '');
    const [email, setEmail] = useState(props.contact?.email || '');
    const [orgInternalName, setOrgInternalName] = useState(props.contact?.orgInternalName || '');
    const [facilityId, setFacilityId] = useState(props.contact?.facilityId || '');
    const [isLoading, setIsLoading] = useState(false);
    const customerStore = useContext(CustomerStore);

    useEffect(() => {
        setContact(
            Object.assign(contact, {
                firstName,
                lastName,
                title,
                phone,
                email,
                orgInternalName,
                facilityId,
            }),
        );
    }, [firstName, lastName, title, phone, email, orgInternalName, facilityId]);

    function showFacilityDropdown(): boolean {
        return props.contactType === ContactType.Clinical || props.contactType === ContactType.Technical;
    }


    async function handleSave() {
        setIsLoading(true);

        if (!contact) {
            return;
        }

        try {
            if (props.mode === 'edit') {
                await customerStore.updateCustomerContact(
                    props.customer.customerId,
                    props.contact.contactGuid,
                    contact,
                    props.contactType,
                );
            } else {
                await customerStore.addCustomerContact(props.customer.customerId, contact, props.contactType);
            }
            if (props.onSave) {
                props.onSave();
            }
            if (props.onClose) {
                props.onClose();
            }
        } catch (e) {
            ToastStoreObject.show(parseError(e));
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <SideModal isOpen={true} onClose={props.onClose}>
            <ModalHeader
                title={`${props.mode === 'edit' ? `Edit Contact` : `New ${props.contactType} Contact`}`}
                onClose={() => props.onClose()}
            />
            <div className={styles.contentWrap}>
                <Input
                    label="First Name"
                    data-test-id={ANALYTICS_NAMES.CustomerContactModal_FirstName}
                    value={firstName}
                    onChangeText={(value: string) => setFirstName(value)}
                />
                <Input
                    label="Last Name"
                    data-test-id={ANALYTICS_NAMES.CustomerContactModal_LastName}
                    value={lastName}
                    onChangeText={(value: string) => setLastName(value)}
                />
                <Input
                    label="Title"
                    data-test-id={ANALYTICS_NAMES.CustomerContactModal_Title}
                    value={title}
                    onChangeText={(value: string) => setTitle(value)}
                />
                <Input
                    label="Phone"
                    data-test-id={ANALYTICS_NAMES.CustomerContactModal_Phone}
                    value={phone.trim()}
                    onChangeText={(value: string) => setPhone(getAutoFormatPhone(value))}
                    errorMessage= {PHONE_REGX.test(phone) ? "": "Please type a valid phone number."}
                    infoState={PHONE_REGX.test(phone) ? null : "error"}
                />
                <Input
                    label="Email"
                    data-test-id={ANALYTICS_NAMES.CustomerContactModal_Email}
                    value={email.trim()}
                    onChangeText={(value: string) => setEmail(value)}
                    errorMessage= {EMAIL_REGX.test(email) ? "" : "Please type a valid email address."}
                    infoState= {EMAIL_REGX.test(email) ? null : "error"}
                />
                {showFacilityDropdown() && (
                    <>
                        <Label text="Facility" />
                        <Select
                            isClearable={true}
                            value={customerStore.customerFacilityOptions.find(
                                (option: any) => option.value === `${orgInternalName}-${facilityId}`,
                            )}
                            onChange={(s: any) => {
                                if(s){
                                    const splitValue = s.value.split('-');
                                setOrgInternalName(splitValue[0]);
                                setFacilityId(splitValue[1]);
                                }
                                else {
                                    setOrgInternalName('');
                                    setFacilityId('');
                                }
                            }}
                            isLoading={isLoading}
                            className={styles.select}
                            options={customerStore.customerFacilityOptions}
                            placeholder="Select Facility"
                            styles={styleSelectComponent()}
                            data-test-id={ANALYTICS_NAMES.CustomerContactModal_Facility}
                        />
                    </>
                )}
            </div>
            <ModalFooter
                primaryText={props.mode === 'edit' ? 'Save' : 'Create'}
                primaryClick={handleSave}
                secondaryClick={() => props.onClose()}
                isLoading={isLoading}
            />
        </SideModal>
    );
}

export default CustomerContactModal;
