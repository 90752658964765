import * as ROUTES from 'src/api/routes';
import request from 'src/api/utils';
import { Vehicle } from 'src/stores/VehicleStore';

export async function getVehicles() {
    return await request({ ...ROUTES.GET_VEHICLES });
}

export async function createVehicle(data: Vehicle) {
    return await request({ ...ROUTES.CREATE_VEHICLE, data });
}

export async function updateVehicle(data: Vehicle) {
    return await request({ ...ROUTES.UPDATE_VEHICLE, pathParams: { vehicleId: data.vehicleId }, data });
}
