// See documentation here: https://github.com/usereact/use-yup
import { useState, useEffect } from 'react';

import { Schema } from 'yup';

type Values = {
    [field: string]: any;
};

type ValidationErrors<T> = {
    [K in keyof T]?: T[K] extends object ? ValidationErrors<T[K]> : string;
};

type ValidationResult<T> = {
    errors: ValidationErrors<T>;
    isValid: boolean;
};

type UseYupOptions = {
    validateOnChange?: boolean;
};

function useYup<T extends Values>(values: T, validationSchema: Schema<any>, options: UseYupOptions = {}) {
    const [errors, setErrors] = useState<ValidationErrors<T>>({});
    const isValid = Object.keys(errors).length === 0;

    const validate = () =>
        validationSchema
            .validate(values, { abortEarly: false })
            .then(() => {
                return {};
            })
            .catch((error: any) => {
                return yupToValidationErrors<T>(error);
            })
            .then((newErrors) => {
                setErrors(newErrors);
                return {
                    errors: newErrors,
                    isValid: Object.keys(newErrors).length === 0,
                };
            });

    useEffect(() => {
        options.validateOnChange && validate();
    }, [values, validationSchema]);

    return {
        validate,
        errors,
        isValid,
    };
}

/**
 * Transform Yup errors to a ValidationErrors object
 */
function yupToValidationErrors<T extends Values>(yupError: any): ValidationErrors<T> {
    const errors: any = {};
    if (yupError.inner.length === 0) {
        updateIn(errors, yupError.path, yupError.message);
        return errors;
    }
    for (const err of yupError.inner) {
        updateIn(errors, err.path, err.message);
    }
    return errors;
}

function updateIn(obj: any, path: string, value: any): any {
    const pathArray = path.split('.');
    let destinationObject = obj;
    for (let i = 0; i < pathArray.length - 1; i++) {
        if (pathArray[i] in destinationObject === false) {
            destinationObject[pathArray[i]] = {};
        }
        destinationObject = destinationObject[pathArray[i]];
    }
    destinationObject[pathArray[pathArray.length - 1]] = value;
}

export default useYup;
