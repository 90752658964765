export enum RoleTypes {
    GRAPHIUM_ADMIN = 'GRAPHIUM_ADMIN',
    ORG_ADMIN = 'ORG_ADMIN',
    FACILITY_ADMIN = 'FACILITY_ADMIN',
    BILLING_ADMIN = 'BILLING_ADMIN',
    BUSINESS_ANALYST = 'BUSINESS_ANALYST',
    PROVIDER = 'PROVIDER',
    CREDENTIALING_STAFF = 'CREDENTIALING_STAFF',
    PROVIDER_ADMIN = 'PROVIDER_ADMIN',
    SCHEDULER = 'SCHEDULER',
    DATA_ENTRY_ADMIN = 'DATA_ENTRY_ADMIN',
    DATA_ENTRY_SUPERVISOR = 'DATA_ENTRY_SUPERVISOR',
    DATA_ENTRY_CLERK = 'DATA_ENTRY_CLERK',
    ADVANCED_ANALYTICS_ADMIN = 'ADVANCED_ANALYTICS_ADMIN',
    ADVANCED_ANALYTICS_VIEWER = 'ADVANCED_ANALYTICS_VIEWER',
}

export const API_DATE_FORMAT = 'yyyy-MM-dd';

export const IANA_TIME_ZONE_MAP = {
    'US/Eastern': 'America/New_York', // Worst time zone
    'US/Central': 'America/Chicago', // Best time zone
    'US/Mountain': 'America/Denver', // Who even uses this?
    'US/Pacific': 'America/Los_Angeles', // LOL SPORTS
};

export const REFERENCE_LIST_PROPERTY_TYPES = [
    {
        value: 'string',
        label: 'Text',
    },
    {
        value: 'number',
        label: 'Number',
    },
    {
        value: 'boolean',
        label: 'Checkbox',
    },
];

interface Report {
    id: string;
    name: string;
    disabled?: boolean;
    chartTypes: string[];
    requiredParams?: string[];
}

interface ReportCategory {
    id: string;
    name: string;
    reports: Report[];
}

export const AVAILABLE_REPORTS: ReportCategory[] = [
    {
        id: 'patientPopulation',
        name: 'Patient Population',
        reports: [
            {
                id: 'population-status',
                name: 'All Patients by Status',
                chartTypes: ['table', 'column', 'pie'],
            },
            {
                id: 'enrollment-duration',
                name: 'Active Patients by Enrollment Duration',
                chartTypes: ['table', 'column', 'pie'],
            },
            {
                id: 'population-race',
                name: 'Active Patients by Race',
                chartTypes: ['table', 'column', 'pie'],
            },
            {
                id: 'population-ethnicity',
                name: 'Active Patients by Ethnicity',
                chartTypes: ['table', 'column', 'pie'],
            },
            {
                id: 'population-age',
                name: 'Active Patients by Age',
                chartTypes: ['table', 'column', 'pie'],
            },
            {
                id: 'population-gender',
                name: 'Active Patients by Gender',
                chartTypes: ['table', 'column', 'pie'],
            },
        ],
    },
    {
        id: 'patientNeeds',
        name: 'Patient Needs',
        reports: [
            {
                id: 'needs-status',
                name: 'All Needs by Status',
                chartTypes: ['table', 'column', 'pie'],
            },
            {
                id: 'all-by-type',
                name: 'All Needs by Type',
                chartTypes: ['table', 'column', 'pie'],
            },
            {
                id: 'open-by-type',
                name: 'Open Needs by Status',
                chartTypes: ['table', 'column', 'pie'],
            },
        ],
    },
    {
        id: 'patientMedications',
        name: 'Patient Medications',
        reports: [
            {
                id: 'medications-by-common-name',
                name: 'Active Medications by Common Name',
                chartTypes: ['table', 'column', 'pie'],
            },
            {
                id: 'medications-by-brand-name',
                name: 'Active Medications by Brand Name',
                chartTypes: ['table', 'column', 'pie'],
            },
            {
                id: 'opioids-by-common-name',
                name: 'Active Opioids by Common Name',
                chartTypes: ['table', 'column', 'pie'],
            },
            {
                id: 'opioids-by-brand-name',
                name: 'Active Opioids by Brand Name',
                chartTypes: ['table', 'column', 'pie'],
            },
        ],
    },
    {
        id: 'visits',
        name: 'Visits',
        reports: [
            {
                id: 'visit-type',
                name: 'Total Visits by Type',
                requiredParams: ['fromDate', 'toDate'],
                chartTypes: ['table', 'column', 'pie'],
            },
            {
                id: 'team-minutes',
                name: 'Total Visit Time by Type',
                chartTypes: ['table', 'column', 'pie'],
            },
            {
                id: 'team-average',
                name: 'Average Visit Duration by Type',
                chartTypes: ['table', 'column', 'line'],
            },
        ],
    },
    {
        id: 'scheduling',
        name: 'Scheduling',
        reports: [
            {
                id: 'scheduling-status',
                name: 'All Tasks by Status',
                chartTypes: ['table', 'column', 'pie'],
            },
            {
                id: 'tasks-by-team',
                name: 'Completed Tasks by Team',
                requiredParams: ['fromDate', 'toDate'],
                chartTypes: ['table', 'column', 'pie'],
            },
            {
                id: 'tasks-by-type',
                name: 'Completed Tasks by Type',
                requiredParams: ['fromDate', 'toDate'],
                chartTypes: ['table', 'column', 'pie'],
            },
            {
                id: 'team-utilization',
                name: 'Team Utilization',
                chartTypes: ['table', 'column', 'heat'],
            },
        ],
    },
    /*{
        id: 'emsIncidents',
        name: 'EMS Incidents',
        reports: [
            {
                id: 'ems-total-incidents-by-run-type',
                name: 'Total Incidents By Run Type',
                requiredParams: ['fromDate', 'toDate'],
                chartTypes: ['table', 'column', 'pie'],
            },
            {
                id: 'ems-average-incident-duration-by-run-type',
                name: 'Average Incident Duration By Run Type',
                requiredParams: ['fromDate', 'toDate'],
                chartTypes: ['table', 'column'],
            },
            {
                id: 'ems-average-incident-response-time',
                name: 'Average Incident Response Time (Dispatch to Scene)',
                requiredParams: ['fromDate', 'toDate'],
                chartTypes: ['table', 'column'],
            },
            {
                id: 'ems-total-incidents-by-dispatch-reason',
                name: 'Total Incidents By Dispatch Reason',
                requiredParams: ['fromDate', 'toDate'],
                chartTypes: ['table', 'column', 'pie'],
            },
            {
                id: 'ems-total-cardiac-arrest-incidents',
                name: 'Total Cardiac Arrest Incidents',
                requiredParams: ['fromDate', 'toDate'],
                chartTypes: ['table', 'pie'],
            },
            {
                id: 'ems-total-incidents-by-transported',
                name: 'Total Incidents By Transport',
                requiredParams: ['fromDate', 'toDate'],
                chartTypes: ['table', 'column', 'pie'],
            },
            {
                id: 'ems-average-miles-travelled-by-unit',
                name: 'Average Miles Travelled By Unit',
                requiredParams: ['fromDate', 'toDate'],
                chartTypes: ['table', 'column', 'pie'],
            },
            {
                id: 'ems-total-stroke-incidents',
                name: 'Total Stroke Incidents',
                requiredParams: ['fromDate', 'toDate'],
                chartTypes: ['table', 'column', 'pie'],
            },
            {
                id: 'ems-total-stemi-incidents',
                name: 'Total STEMI Incidents',
                requiredParams: ['fromDate', 'toDate'],
                chartTypes: ['table', 'column', 'pie'],
            },
            {
                id: 'ems-average-incident-response-time-cardiac-cpr',
                name: 'Average Incident Response Time (Cardiac CPR Initiation)',
                requiredParams: ['fromDate', 'toDate'],
                chartTypes: ['table', 'column'],
            },
            {
                id: 'ems-total-incidents-by-incident-type',
                name: 'Total Incidents By Incident Type',
                requiredParams: ['fromDate', 'toDate'],
                chartTypes: ['table', 'column', 'pie'],
            },
        ],
    },*/
];

export const VALID_EVENT_TYPES: { [key in string]: { entityKeys: string[]; dataKeys: string[] } } = {
    INVITE_CREATED: {
        entityKeys: ['inviteId'],
        dataKeys: [],
    },
    INVITE_RESENT: {
        entityKeys: ['inviteId'],
        dataKeys: [],
    },
    INVITE_ACCEPTED: {
        entityKeys: ['inviteId'],
        dataKeys: [],
    },
    INVITE_CANCELED: {
        entityKeys: ['inviteId'],
        dataKeys: [],
    },
    USER_PASSWORD_RESET_REQUESTED: {
        entityKeys: ['userId'],
        dataKeys: [],
    },
    USER_PASSWORD_RESET: {
        entityKeys: ['userId'],
        dataKeys: [],
    },
    USER_LOGGED_IN: {
        entityKeys: [],
        dataKeys: [],
    },
    USER_SELECTED_ORG: {
        entityKeys: [],
        dataKeys: [],
    },
    USER_LOGIN_FAILED: {
        entityKeys: [],
        dataKeys: ['userName'],
    },
    USER_CREATED: {
        entityKeys: ['userId'],
        dataKeys: [],
    },
    USER_UPDATED: {
        entityKeys: ['userId'],
        dataKeys: ['propertyUpdates'],
    },
    USER_DEACTIVATED: {
        entityKeys: ['userId'],
        dataKeys: [],
    },
    USER_REACTIVATED: {
        entityKeys: ['userId'],
        dataKeys: [],
    },
    PATIENT_CREATED: {
        entityKeys: ['facilityId', 'patientId'],
        dataKeys: [],
    },
    PATIENT_STATUS_CHANGED: {
        entityKeys: ['facilityId', 'patientId'],
        dataKeys: ['statusFrom', 'statusTo'],
    },
    PATIENT_UPDATED: {
        entityKeys: ['facilityId', 'patientId'],
        dataKeys: ['propertyUpdates'],
    },
    PATIENT_ACCESSED: {
        entityKeys: ['facilityId', 'patientId'],
        dataKeys: [],
    },
    PATIENT_SEARCH_PERFORMED: {
        entityKeys: ['facilityId'],
        dataKeys: ['searchFilters'],
    },
    APPOINTMENT_CREATED: {
        entityKeys: ['facilityId', 'patientId', 'eventId'],
        dataKeys: [],
    },
    APPOINTMENT_UPDATED: {
        entityKeys: ['facilityId', 'patientId', 'eventId'],
        dataKeys: ['propertyUpdates'],
    },
    APPOINTMENT_STATUS_CHANGED: {
        entityKeys: ['facilityId', 'patientId', 'eventId'],
        dataKeys: ['statusFrom', 'statusTo'],
    },
    APPOINTMENT_ACCESSED: {
        entityKeys: ['facilityId', 'patientId', 'eventId'],
        dataKeys: [],
    },
    TASK_CREATED: {
        entityKeys: ['facilityId', 'patientId', 'eventId'],
        dataKeys: [],
    },
    TASK_UPDATED: {
        entityKeys: ['facilityId', 'patientId', 'eventId'],
        dataKeys: ['propertyUpdates'],
    },
    TASK_STATUS_CHANGED: {
        entityKeys: ['facilityId', 'patientId', 'eventId'],
        dataKeys: ['statusFrom', 'statusTo'],
    },
    TASK_ACCESSED: {
        entityKeys: ['facilityId', 'patientId', 'eventId'],
        dataKeys: [],
    },
    PATIENT_NEED_CREATED: {
        entityKeys: ['facilityId', 'patientId', 'patientNeedId'],
        dataKeys: [],
    },
    PATIENT_NEED_UPDATED: {
        entityKeys: ['facilityId', 'patientId', 'patientNeedId'],
        dataKeys: ['propertyUpdates'],
    },
    PATIENT_NEED_STATUS_CHANGED: {
        entityKeys: ['facilityId', 'patientId', 'patientNeedId'],
        dataKeys: ['statusFrom', 'statusTo'],
    },
    PATIENT_ATTACHMENT_CREATED: {
        entityKeys: ['facilityId', 'patientId', 'patientAttachmentId'],
        dataKeys: [],
    },
    PATIENT_ATTACHMENT_UPDATED: {
        entityKeys: ['facilityId', 'patientId', 'patientAttachmentId'],
        dataKeys: ['propertyUpdates'],
    },
    PATIENT_ATTACHMENT_REMOVED: {
        entityKeys: ['facilityId', 'patientId', 'patientAttachmentId'],
        dataKeys: [],
    },
    PATIENT_ATTACHMENT_ACCESSED: {
        entityKeys: ['patientId', 'patientAttachmentId'],
        dataKeys: [],
    },
    PATIENT_NOTE_CREATED: {
        entityKeys: ['facilityId', 'patientId', 'patientNoteId'],
        dataKeys: [],
    },
    PATIENT_NOTE_UPDATED: {
        entityKeys: ['facilityId', 'patientId', 'patientNoteId'],
        dataKeys: ['propertyUpdates'],
    },
    PATIENT_NOTE_REMOVED: {
        entityKeys: ['facilityId', 'patientId', 'patientNoteId'],
        dataKeys: [],
    },
    ENCTR_CREATED: {
        entityKeys: ['facilityId', 'encounterId', 'patientId'],
        dataKeys: [],
    },
    ENCTR_UPDATED: {
        entityKeys: ['facilityId', 'encounterId', 'patientId'],
        dataKeys: ['propertyUpdates'],
    },
    ENCTR_ACCESSED: {
        entityKeys: ['facilityId', 'encounterId', 'patientId'],
        dataKeys: [],
    },
    ENCTR_SEARCH_PERFORMED: {
        entityKeys: ['facilityId'],
        dataKeys: ['searchFilters'],
    },
    ENCOUNTERS_SEARCHED: {
        entityKeys: [
            'facilityId',
            'patientNameQuery',
            'encounterNumberQuery',
            'patientMrnQuery',
            'pageNumber',
            'resultsPerPage',
        ],
        dataKeys: [],
    },
    ENCOUNTERS_SEARCHED_BY_DATE: {
        entityKeys: ['facilityId', 'date'],
        dataKeys: [],
    },
    ENCTR_FORM_SEARCH_PERFORMED: {
        entityKeys: [],
        dataKeys: [],
    },
    ENCTR_FORM_CREATED: {
        entityKeys: ['facilityId', 'encounterId', 'patientId', 'encounterFormId'],
        dataKeys: [],
    },
    ENCTR_FORM_UPDATED: {
        entityKeys: ['facilityId', 'encounterId', 'patientId', 'encounterFormId'],
        dataKeys: ['propertyUpdates'],
    },
    ENCTR_FORM_ACCESSED: {
        entityKeys: ['facilityId', 'encounterId', 'patientId', 'encounterFormId'],
        dataKeys: [],
    },
    ENCTR_FORM_VOIDED: {
        entityKeys: ['facilityId', 'encounterId', 'patientId', 'encounterFormId'],
        dataKeys: [],
    },
    ENCTR_FORM_UNVOIDED: {
        entityKeys: ['facilityId', 'encounterId', 'patientId', 'encounterFormId'],
        dataKeys: [],
    },
    REFERENCE_LIST_CREATED: {
        entityKeys: ['referenceListId'],
        dataKeys: [],
    },
    REFERENCE_LIST_UPDATED: {
        entityKeys: ['referenceListId'],
        dataKeys: ['propertyUpdates'],
    },
    REFERENCE_LIST_DEACTIVATED: {
        entityKeys: ['referenceListId'],
        dataKeys: [],
    },
    REFERENCE_LIST_REACTIVATED: {
        entityKeys: ['referenceListId'],
        dataKeys: [],
    },
    REFERENCE_LIST_VALUE_CREATED: {
        entityKeys: ['referenceListId', 'referenceListValueId'],
        dataKeys: [],
    },
    REFERENCE_LIST_VALUE_UPDATED: {
        entityKeys: ['referenceListId', 'referenceListValueId'],
        dataKeys: ['propertyUpdates'],
    },
    REFERENCE_LIST_VALUE_DEACTIVATED: {
        entityKeys: ['referenceListId', 'referenceListValueId'],
        dataKeys: [],
    },
    REFERENCE_LIST_VALUE_REACTIVATED: {
        entityKeys: ['referenceListId', 'referenceListValueId'],
        dataKeys: [],
    },
    VALSET_DETAIL_CREATED: {
        entityKeys: ['valueSetDetailId'],
        dataKeys: [],
    },
    VALSET_DETAIL_UPDATED: {
        entityKeys: ['valueSetDetailId'],
        dataKeys: ['propertyUpdates'],
    },
    VALSET_DETAIL_DEACTIVATED: {
        entityKeys: ['valueSetDetailId'],
        dataKeys: [],
    },
    VALSET_DETAIL_REACTIVATED: {
        entityKeys: ['valueSetDetailId'],
        dataKeys: [],
    },
    PATIENT_MEDICATION_CREATED: {
        entityKeys: ['patientId', 'patientMedicationId', 'facilityId'],
        dataKeys: [],
    },
    PATIENT_MEDICATION_UPDATED: {
        entityKeys: ['patientId', 'patientMedicationId', 'facilityId'],
        dataKeys: ['propertyUpdates'],
    },
    TAG_CREATED: {
        entityKeys: ['tagId'],
        dataKeys: [],
    },
    TAG_UPDATED: {
        entityKeys: ['tagId'],
        dataKeys: ['propertyUpdates'],
    },
    USER_GRANT_FACILITY: {
        entityKeys: ['userId', 'facilityIds'],
        dataKeys: [],
    },
    USER_DEACTIVATE_FACILITY: {
        entityKeys: ['userId', 'facilityIds'],
        dataKeys: [],
    },
    USER_REACTIVATE_FACILITY: {
        entityKeys: ['userId', 'facilityIds'],
        dataKeys: [],
    },
    MOBILE_SYNC_PERFORMED: {
        entityKeys: ['facilityId'],
        dataKeys: ['syncParameters', 'patientList'],
    },
    ENCTR_FORM_MODEL_UPDATED: {
        entityKeys: ['facilityId', 'patientId', 'encounterId', 'encounterFormId'],
        dataKeys: ['modelUpdates'],
    },
    USER_PREFERENCE_CREATED: {
        entityKeys: ['userPrefId', 'userId'],
        dataKeys: [],
    },
    USER_PREFERENCE_UPDATED: {
        entityKeys: ['userPrefId', 'userId'],
        dataKeys: [],
    },
    USER_PREFERENCES_ACCESSED: {
        entityKeys: ['userId'],
        dataKeys: [],
    },
    TELEHEALTH_SESSION_CREATED: {
        entityKeys: ['facilityId', 'patientId', 'encounterId', 'encounterFormId', 'telehealthSessionId'],
        dataKeys: [],
    },
    TELEHEALTH_SESSION_UPDATED: {
        entityKeys: ['facilityId', 'patientId', 'encounterId', 'encounterFormId', 'telehealthSessionId'],
        dataKeys: [],
    },
    TELEHEALTH_SESSION_OPENED: {
        entityKeys: ['facilityId', 'patientId', 'encounterId', 'encounterFormId', 'telehealthSessionId'],
        dataKeys: [],
    },
    TELEHEALTH_SESSION_RECORDING_VIEWED: {
        entityKeys: ['facilityId', 'patientId', 'encounterId', 'encounterFormId', 'telehealthSessionId'],
        dataKeys: [],
    },
    TELEHEALTH_SESSION_RECORDING_DOWNLOADED: {
        entityKeys: ['facilityId', 'patientId', 'encounterId', 'encounterFormId', 'telehealthSessionId'],
        dataKeys: [],
    },
    TELEHEALTH_SESSION_COMPLETED: {
        entityKeys: ['facilityId', 'patientId', 'encounterId', 'encounterFormId', 'telehealthSessionId'],
        dataKeys: [],
    },
    TELEHEALTH_SESSION_PATIENT_AUTHENTICATE_ATTEMPT: {
        entityKeys: ['facilityId', 'patientId', 'encounterId', 'encounterFormId', 'telehealthSessionId'],
        dataKeys: ['isSuccessful'],
    },
    TELEHEALTH_SESSION_CANCELLED: {
        entityKeys: ['facilityId', 'patientId', 'encounterId', 'encounterFormId', 'telehealthSessionId'],
        dataKeys: [],
    },
    TELEHEALTH_CALL_JOINED: {
        entityKeys: ['facilityId', 'patientId', 'encounterId', 'encounterFormId', 'telehealthSessionId', 'orgUserId'],
        dataKeys: [],
    },
    TELEHEALTH_CALL_ENDED: {
        entityKeys: ['facilityId', 'patientId', 'encounterId', 'encounterFormId', 'telehealthSessionId', 'orgUserId'],
        dataKeys: [],
    },
    TELEHEALTH_INVITE_SENT: {
        entityKeys: ['facilityId', 'patientId', 'encounterId', 'encounterFormId', 'telehealthSessionId'],
        dataKeys: ['emailAddress', 'phoneNumber'],
    },
    PAYER_CATEGORY_CREATED: {
        entityKeys: ['payerCategoryId'],
        dataKeys: [],
    },
    PAYER_CATEGORY_UPDATED: {
        entityKeys: ['payerCategoryId'],
        dataKeys: ['propertyUpdates'],
    },
    PAYER_CATEGORY_REACTIVATED: {
        entityKeys: ['payerCategoryId'],
        dataKeys: [],
    },
    PAYER_CATEGORY_DEACTIVATED: {
        entityKeys: ['payerCategoryId'],
        dataKeys: [],
    },
    PAYER_CREATED: {
        entityKeys: ['payerId', 'payerCategoryId'],
        dataKeys: [],
    },
    PAYER_UPDATED: {
        entityKeys: ['payerId', 'payerCategoryId'],
        dataKeys: ['propertyUpdates'],
    },
    VITALS_CONNECTION_CREATED: {
        entityKeys: ['facilityId', 'connectionId'],
        dataKeys: ['unitIdentifier'],
    },
    VITALS_CONNECTION_UPDATED: {
        entityKeys: ['facilityId', 'connectionId'],
        dataKeys: ['propertyUpdates'],
    },
    VITALS_CONNECTION_DEACTIVATED: {
        entityKeys: ['facilityId', 'connectionId'],
        dataKeys: [],
    },
    VITALS_CONNECTION_REACTIVATED: {
        entityKeys: ['facilityId', 'connectionId'],
        dataKeys: [],
    },
    // Framework
    FRAMEWORK_SURVEY_CREATED: {
        entityKeys: ['surveyGuid'],
        dataKeys: [],
    },
    FRAMEWORK_SURVEY_STATE_CHANGE: {
        entityKeys: ['surveyGuid', 'surveyStateId'],
        dataKeys: [],
    },
    FRAMEWORK_SURVEY_REQUEST_STATE_CHANGE: {
        entityKeys: ['surveyRequestGuid', 'surveyRequestStateId'],
        dataKeys: [],
    },
    FRAMEWORK_SURVEY_REQUEST_UNSUBSCRIBED: {
        entityKeys: ['surveyRequestGuid'],
        dataKeys: [],
    },
    FRAMEWORK_SURVEY_COMPLETED: {
        entityKeys: ['surveyGuid'],
        dataKeys: [],
    },
    FRAMEWORK_SURVEY_REQUEST_CREATED: {
        entityKeys: ['surveyGuid', 'surveyRequestGuid'],
        dataKeys: [],
    },
    // Payments
    PAYMENT_TYPE_CREATED: {
        entityKeys: ['paymentTypeId', 'orgNameInternal'],
        dataKeys: [],
    },
    PAYMENT_TYPE_UPDATED: {
        entityKeys: ['paymentTypeId', 'orgNameInternal'],
        dataKeys: ['propertyUpdates'],
    },
    PAYMENT_TYPE_REACTIVATED: {
        entityKeys: ['paymentTypeId', 'orgNameInternal'],
        dataKeys: [],
    },
    PAYMENT_TYPE_DEACTIVATED: {
        entityKeys: ['paymentTypeId', 'orgNameInternal'],
        dataKeys: [],
    },
    PAYMENT_GATEWAY_CREATED: {
        entityKeys: ['paymentGatewayId', 'orgNameInternal'],
        dataKeys: [],
    },
    PAYMENT_GATEWAY_UPDATED: {
        entityKeys: ['paymentGatewayId', 'orgNameInternal'],
        dataKeys: ['propertyUpdates'],
    },
    PAYMENT_GATEWAY_REACTIVATED: {
        entityKeys: ['paymentGatewayId', 'orgNameInternal'],
        dataKeys: [],
    },
    PAYMENT_GATEWAY_DEACTIVATED: {
        entityKeys: ['paymentGatewayId', 'orgNameInternal'],
        dataKeys: [],
    },
    PAYMENT_REQUEST_CREATED: {
        entityKeys: ['paymentRequestGuid', 'paymentTypeId', 'orgNameInternal', 'facilityId', 'encounterId'],
        dataKeys: [],
    },
    PAYMENT_REQUEST_UPDATED: {
        entityKeys: ['paymentRequestGuid', 'paymentTypeId', 'orgNameInternal', 'facilityId', 'encounterId'],
        dataKeys: ['propertyUpdates'],
    },
    PAYMENT_REQUEST_STATE_CHANGED: {
        entityKeys: ['paymentRequestGuid', 'paymentTypeId', 'orgNameInternal', 'facilityId', 'encounterId'],
        dataKeys: ['statusFrom', 'statusTo'],
    },
    PAYMENT_REQUEST_CONTACT_CREATED: {
        entityKeys: ['paymentRequestContactGuid', 'paymentRequestGuid'],
        dataKeys: ['contactType', 'contactInfo'],
    },
    PAYMENT_REQUEST_CONTACT_UPDATED: {
        entityKeys: ['paymentRequestContactGuid', 'paymentRequestGuid'],
        dataKeys: ['contactType', 'contactInfo'],
    },
    PAYMENT_REQUEST_CONTACT_STATE_CHANGED: {
        entityKeys: ['paymentRequestContactGuid', 'paymentRequestGuid'],
        dataKeys: ['statusFrom', 'statusTo'],
    },
    PAYMENT_TRANSACTION_CREATED: {
        entityKeys: ['paymentTransactionId', 'paymentRequestGuid', 'paymentRequestContactGuid', 'paymentGatewayId'],
        dataKeys: [],
    },
    PAYMENT_TRANSACTION_UPDATED: {
        entityKeys: ['paymentTransactionId', 'paymentRequestGuid', 'paymentRequestContactGuid', 'paymentGatewayId'],
        dataKeys: ['propertyUpdates'],
    },
    PAYMENT_TRANSACTION_STATE_CHANGED: {
        entityKeys: ['paymentTransactionId', 'paymentRequestGuid', 'paymentRequestContactGuid', 'paymentGatewayId'],
        dataKeys: ['statusFrom', 'statusTo'],
    },
    IMPORT_BATCH_CREATED: {
        entityKeys: ['importBatchGuid', 'importBatchTemplateGuid', 'orgNameInternal', 'facilityId'],
        dataKeys: ['recordCount', 'scriptExecutionAttemptCount'],
    },
    FACILITY_UPDATED: {
        entityKeys: ['facId'],
        dataKeys: ['propertyUpdates'],
    },
    FACILITY_DEACTIVATED: {
        entityKeys: ['facId'],
        dataKeys: [],
    },
    FACILITY_REACTIVATED: {
        entityKeys: ['facId'],
        dataKeys: [],
    },
    CUSTOMER_UPDATED: {
        entityKeys: ['customerId', 'customerName'],
        dataKeys: ['propertyUpdates'],
    },
    CUSTOMER_CREATED: {
        entityKeys: ['customerName'],
        dataKeys: [],
    },
    CUSTOMER_STATUS_UPDATED: {
        entityKeys: ['customerId', 'customerName'],
        dataKeys: ['propertyUpdates'],
    },
    CUSTOMER_CONTACT_CREATED: {
        entityKeys: ['customerId', 'customerName', 'contactGuid'],
        dataKeys: [],
    },
    CUSTOMER_CONTACT_UPDATED: {
        entityKeys: ['customerId', 'customerName', 'contactGuid'],
        dataKeys: ['propertyUpdates'],
    },
    CUSTOMER_CONTACT_REMOVED: {
        entityKeys: ['customerId', 'customerName', 'contactType'],
        dataKeys: [],
    },
    ADS_UPDATED: {
        entityKeys: ['systemId', 'facilityId'],
        dataKeys: ['propertyUpdates'],
    },
    USER_PREFERENCE_ACCESSED: {
        entityKeys: ['userId', 'preferenceName'],
        dataKeys: [],
    },
    ENCOUNTER_FORM_VOIDED: {
        entityKeys: ['encounterFormId'],
        dataKeys: [],
    },
    ENCTR_FORM_OPENED: {
        entityKeys: ['encounterId', 'encounterFormId', 'formLastOpndDttm'],
        dataKeys: [],
    },
    STROKE_ADDED: {
        entityKeys: ['encounterFormId', 'pageId'],
        dataKeys: [],
    },
    MOBILE_LOGIN: {
        entityKeys: ['loginType'],
        dataKeys: [],
    },
    PRINT_FORM: {
        entityKeys: ['encounterFormId'],
        dataKeys: [],
    },
    SURVEY_TEMPLATE_CREATED:
    {
        entityKeys: ['surveyTemplateName'],
        dataKeys: [],
    },
    SURVEY_TEMPLATE_UPDATED:
    {
        entityKeys: ['surveyTemplateVersionId', 'surveyTemplateName'],
        dataKeys: ['propertyUpdates'],
    },
    SURVEY_DEFINITION_QUESTION_INSERTED: {
        entityKeys: ['surveyDefinitionHistoryId', 'surveyDefinitionTitle'],
        dataKeys: ['propertyUpdates'],
    },
    SURVEY_DEFINITION_QUESTION_ARCHIVED: {
        entityKeys: ['surveyDefinitionHistoryId', 'surveyDefinitionTitle'],
        dataKeys: ['propertyUpdates'],
    },
    SURVEY_DEFINITION_QUESTION_UNARCHIVED: {
        entityKeys: ['surveyDefinitionHistoryId', 'surveyDefinitionTitle'],
        dataKeys: ['propertyUpdates'],
    },
    SURVEY_DEFINITION_QUESTION_MODIFIED: {
        entityKeys: ['surveyDefinitionHistoryId', 'surveyDefinitionTitle'],
        dataKeys: ['propertyUpdates'],
    },
    SURVEY_TEMPLATE_QUESTION_UNARCHIVED: {
        entityKeys: ['surveyTemplateVersionId', 'surveyTemplateName'],
        dataKeys: ['propertyUpdates'],
    },
    SURVEY_TEMPLATE_QUESTION_ARCHIVED: {
        entityKeys: ['surveyTemplateVersionId', 'surveyTemplateName'],
        dataKeys: ['propertyUpdates'],
    },
    SURVEY_TEMPLATE_QUESTION_CREATED: {
        entityKeys: ['surveyTemplateVersionId', 'surveyTemplateName'],
        dataKeys: ['propertyUpdates'],
    },
    SURVEY_TEMPLATE_QUESTION_UPDATED: {
        entityKeys: ['surveyTemplateVersionId', 'surveyTemplateName'],
        dataKeys: ['propertyUpdates'],
    },
    SURVEY_TEMPLATE_UNPUBLISHED: {
        entityKeys: ['surveyTemplateVersionId', 'surveyTemplateName'],
        dataKeys: ['propertyUpdates'],
    },
    SURVEY_TEMPLATE_PUBLISHED: {
        entityKeys: ['surveyTemplateVersionId', 'surveyTemplateName'],
        dataKeys: ['propertyUpdates'],
    },
    SURVEY_DEFINITION_CREATED: {
        entityKeys: ['surveyDefinitionId', 'surveyDefinitionCode'],
        dataKeys: [],
    },
    SURVEY_DEFINITION_HISTORY_CREATED: {
        entityKeys: ['surveyDefinitionHistoryId', 'surveyDefinitionTitle'],
        dataKeys: [],
    },
    SURVEY_DEFINITION_ORG_CREATED: {
        entityKeys: ['surveyDefinitionOrgId', 'orgInternalName'],
        dataKeys: [],
    },
    SURVEY_DEFINITION_DUPLICATED: {
        entityKeys: ['surveyDefinitionId', 'surveyDefinitionCode'],
        dataKeys: [],
    },
    SURVEY_DEFINITION_HISTORY_DUPLICATED: {
        entityKeys: ['surveyDefinitionHistoryId', 'surveyDefinitionTitle'],
        dataKeys: [],
    },
    SURVEY_DEFINITION_ORG_DUPLICATED: {
        entityKeys: ['surveyDefinitionOrgId', 'orgInternalName'],
        dataKeys: [],
    },
};
