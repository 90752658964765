import React, { useState, useEffect, useContext } from 'react';
import styles from './styles.module.scss';
import { format } from 'date-fns';
import { observer } from 'mobx-react';
import DateComponent from 'src/components/DateComponent';
import Age from 'src/components/Age';
import PatientStore, { Patient } from 'src/stores/PatientStore';
import { AccordionItem } from '../Information/index';
import Input from 'src/components/Input';
import Accordion from 'src/components/Accordion';
import Button from 'src/components/Button';
import QuickAction from 'src/components/QuickAction';
import DatePicker from 'src/components/DatePicker';
import OptionDropDown, { OptionTypes } from 'src/components/OptionDropDown';
import Flex from 'src/components/Flex';
import { ICONS, API_DATE_FORMAT } from 'src/utils/constants';
import { ANALYTICS_NAMES } from 'src/utils/analytics';
import * as utils from 'src/utils';
import UserStore from 'src/stores/UserStore';
import ReferenceListItemDisplay from 'src/components/ReferenceListItemDisplay';
import { RefListTypes } from 'src/stores/ReferenceListStore';

interface InformationProps {
    patient: Patient;
    disableEdit?: boolean;
}
function PatientInfo(props: InformationProps) {
    const [isEditing, setIsEditing] = useState(false);
    const [SSNVisible, setSSNVisible] = useState(false);
    const [patient, setPatient] = useState<Patient>({});
    const patientStore = useContext(PatientStore);
    const userStore = useContext(UserStore);

    useEffect(() => {
        resetPatient();
    }, [props.patient]);

    function resetPatient() {
        setPatient({
            patientFirstName: props.patient.patientFirstName || '',
            patientLastName: props.patient.patientLastName || '',
            patientMiddleName: props.patient.patientMiddleName || '',
            patientGenderCode: props.patient.patientGenderCode || '',
            patientDob: props.patient.patientDob || null,
            patientRaceCode: props.patient.patientRaceCode || '',
            patientEthnicityCode: props.patient.patientEthnicityCode || '',
            patientPrimaryLanguageCode: props.patient.patientPrimaryLanguageCode || '',
            patientReferralSource: props.patient.patientReferralSource || '',
            patientReferralReason: props.patient.patientReferralReason || '',
            patientHasInsuranceInd: utils.isBool(props.patient.patientHasInsuranceInd)
                ? props.patient.patientHasInsuranceInd
                : null,
            patientDoNotResuscitateInd: utils.isBool(props.patient.patientDoNotResuscitateInd)
                ? props.patient.patientDoNotResuscitateInd
                : null,
            patientSsn: props.patient.patientSsn || '',
            militaryServiceStatusCode: props.patient.militaryServiceStatusCode || '',
            militaryServiceBranchCode: props.patient.militaryServiceBranchCode || '',
            militaryRecordOnFileInd: utils.isBool(props.patient.militaryRecordOnFileInd)
                ? props.patient.militaryRecordOnFileInd
                : null,
            militaryDischargeStatusCode: props.patient.militaryDischargeStatusCode || '',
            militaryRelatedDisabilityInd: utils.isBool(props.patient.militaryRelatedDisabilityInd)
                ? props.patient.militaryRelatedDisabilityInd
                : null,
        });
    }

    const updateInput = (param: string) => (t: string) => setPatient({ ...patient, [param]: t });
    function handleUpdateSsn(num: any) {
        let val = num.replace(/\D/g, ''); // remove non digits
        if (val.length > 9) return;
        let newVal = '';
        if (val.length > 3 && val.length < 6) {
            newVal += val.substr(0, 3) + '-';
            val = val.substr(3);
        }
        if (val.length > 5) {
            newVal += val.substr(0, 3) + '-';
            newVal += val.substr(3, 2) + '-';
            val = val.substr(5);
        }
        newVal += val;

        setPatient({ ...patient, ['patientSsn']: newVal });
    }
    return (
        <Accordion
            label="Patient Information"
            startOpen={true}
            isEditing={isEditing}
            rightAction={
                isEditing ? (
                    <Flex direction="row">
                        <Button
                            onClick={() => {
                                setIsEditing(false);
                                resetPatient();
                            }}
                            className={styles.cancelBtn}
                            text="Cancel"
                            data-test-id={ANALYTICS_NAMES.PatientDetails_PatientInfo_Cancel}
                        />
                        <Button
                            onClick={async () => {
                                setIsEditing(false);
                                await patientStore.updatePatient(props.patient.patientId, patient);
                                await patientStore.getActivePatient(props.patient.patientId);
                            }}
                            className={styles.saveBtn}
                            text="Save"
                            data-test-id={ANALYTICS_NAMES.PatientDetails_PatientInfo_Save}
                        />
                    </Flex>
                ) : props.disableEdit ? null : (
                    <QuickAction
                        className={styles.editIcon}
                        iconName={ICONS.Edit}
                        isLocked={!userStore.userPermissions.canEdit.patientDetails}
                        onClick={() => setIsEditing(true)}
                        data-test-id={ANALYTICS_NAMES.PatientDetails_PatientInfo_Edit}
                    />
                )
            }
            bottomAction={
                isEditing ? (
                    <Flex direction="row" align="center" justify="end" value={1}>
                        <Button
                            onClick={() => {
                                setIsEditing(false);
                                resetPatient();
                            }}
                            className={styles.cancelBtn}
                            text="Cancel"
                            data-test-id={ANALYTICS_NAMES.PatientDetails_PatientInfo_Cancel}
                        />
                        <Button
                            onClick={async () => {
                                setIsEditing(false);
                                await patientStore.updatePatient(props.patient.patientId, patient);
                                await patientStore.getActivePatient(props.patient.patientId);
                            }}
                            className={styles.saveBtn}
                            text="Save"
                            data-test-id={ANALYTICS_NAMES.PatientDetails_PatientInfo_Save}
                        />
                    </Flex>
                ) : null
            }
        >
            <AccordionItem
                label="First Name"
                value={
                    isEditing ? (
                        <Input value={patient.patientFirstName} onChangeText={updateInput('patientFirstName')} />
                    ) : (
                        patient.patientFirstName
                    )
                }
            />
            <AccordionItem
                label="Last Name"
                value={
                    isEditing ? (
                        <Input value={patient.patientLastName} onChangeText={updateInput('patientLastName')} />
                    ) : (
                        patient.patientLastName
                    )
                }
            />
            <AccordionItem
                label="Middle Name"
                value={
                    isEditing ? (
                        <Input value={patient.patientMiddleName} onChangeText={updateInput('patientMiddleName')} />
                    ) : (
                        patient.patientMiddleName
                    )
                }
            />
            <AccordionItem
                label="Gender"
                value={
                    isEditing ? (
                        <OptionDropDown
                            facId={props.patient.facId}
                            type={OptionTypes.Gender}
                            onChange={(data: any) => setPatient({ ...patient, patientGenderCode: data.value })}
                            selectedValue={patient.patientGenderCode}
                        />
                    ) : (
                        <ReferenceListItemDisplay type={RefListTypes.Gender} value={patient.patientGenderCode} />
                    )
                }
            />
            <AccordionItem label="Age" value={<Age dob={patient.patientDob} />} />
            <AccordionItem
                label="Date of Birth"
                value={
                    isEditing ? (
                        <DatePicker
                            onlyPast={true}
                            value={patient.patientDob}
                            onChange={(date) => {
                                if (date) {
                                    const value = format(date, API_DATE_FORMAT);
                                    setPatient({ ...patient, patientDob: value });
                                }
                            }}
                        />
                    ) : (
                        <DateComponent date={patient.patientDob} />
                    )
                }
            />
            <AccordionItem
                label="Race Code"
                value={
                    isEditing ? (
                        <OptionDropDown
                            facId={props.patient.facId}
                            type={OptionTypes.Race}
                            onChange={(data: any) => setPatient({ ...patient, patientRaceCode: data.value })}
                            selectedValue={patient.patientRaceCode}
                        />
                    ) : (
                        <ReferenceListItemDisplay type={RefListTypes.Race} value={patient.patientRaceCode} />
                    )
                }
            />
            <AccordionItem
                label="Ethnicity Code"
                value={
                    isEditing ? (
                        <OptionDropDown
                            facId={props.patient.facId}
                            type={OptionTypes.Ethnicity}
                            onChange={(data: any) => setPatient({ ...patient, patientEthnicityCode: data.value })}
                            selectedValue={patient.patientEthnicityCode}
                        />
                    ) : (
                        <ReferenceListItemDisplay type={RefListTypes.Ethnicity} value={patient.patientEthnicityCode} />
                    )
                }
            />
            <AccordionItem
                label="Primary Language Code"
                value={
                    isEditing ? (
                        <OptionDropDown
                            facId={props.patient.facId}
                            type={OptionTypes.Language}
                            onChange={(data: any) => setPatient({ ...patient, patientPrimaryLanguageCode: data.value })}
                            selectedValue={patient.patientPrimaryLanguageCode}
                        />
                    ) : (
                        <ReferenceListItemDisplay
                            type={RefListTypes.Language}
                            value={patient.patientPrimaryLanguageCode}
                        />
                    )
                }
            />
            <AccordionItem
                label="Referral Source"
                value={
                    isEditing ? (
                        <Input
                            value={patient.patientReferralSource}
                            onChangeText={updateInput('patientReferralSource')}
                        />
                    ) : (
                        patient.patientReferralSource
                    )
                }
            />
            <AccordionItem
                label="Referral Reason"
                value={
                    isEditing ? (
                        <Input
                            value={patient.patientReferralReason}
                            onChangeText={updateInput('patientReferralReason')}
                        />
                    ) : (
                        patient.patientReferralReason
                    )
                }
            />
            <AccordionItem
                label="Has Insurance?"
                value={
                    isEditing ? (
                        <OptionDropDown
                            type={OptionTypes.yesNo}
                            onChange={(data: any) => setPatient({ ...patient, patientHasInsuranceInd: data.value })}
                            selectedValue={patient.patientHasInsuranceInd}
                        />
                    ) : patient.patientHasInsuranceInd === null ? (
                        '-'
                    ) : patient.patientHasInsuranceInd ? (
                        'Yes'
                    ) : (
                        'No'
                    )
                }
            />
            <AccordionItem
                label="Has DNR?"
                value={
                    isEditing ? (
                        <OptionDropDown
                            type={OptionTypes.yesNo}
                            onChange={(data: any) => setPatient({ ...patient, patientDoNotResuscitateInd: data.value })}
                            selectedValue={patient.patientDoNotResuscitateInd}
                        />
                    ) : patient.patientDoNotResuscitateInd === null ? (
                        '-'
                    ) : patient.patientDoNotResuscitateInd ? (
                        'Yes'
                    ) : (
                        'No'
                    )
                }
            />
            <AccordionItem
                label="Military Service Status"
                value={
                    isEditing ? (
                        <OptionDropDown
                            facId={props.patient.facId}
                            type={OptionTypes.MilitaryServiceStatusCodes}
                            onChange={(data: any) => setPatient({ ...patient, militaryServiceStatusCode: data.value })}
                            selectedValue={patient.militaryServiceStatusCode}
                        />
                    ) : (
                        <ReferenceListItemDisplay
                            type={RefListTypes.MilitaryServiceStatusCodes}
                            value={patient.militaryServiceStatusCode}
                        />
                    )
                }
            />
            <AccordionItem
                label="Military Service Branch"
                value={
                    isEditing ? (
                        <OptionDropDown
                            facId={props.patient.facId}
                            type={OptionTypes.MilitaryServiceBranches}
                            onChange={(data: any) => setPatient({ ...patient, militaryServiceBranchCode: data.value })}
                            selectedValue={patient.militaryServiceBranchCode}
                        />
                    ) : (
                        <ReferenceListItemDisplay
                            type={RefListTypes.MilitaryServiceBranches}
                            value={patient.militaryServiceBranchCode}
                        />
                    )
                }
            />
            <AccordionItem
                label="Military Discharge Status"
                value={
                    isEditing ? (
                        <OptionDropDown
                            facId={props.patient.facId}
                            type={OptionTypes.MilitaryDischargeStatusCodes}
                            onChange={(data: any) =>
                                setPatient({ ...patient, militaryDischargeStatusCode: data.value })
                            }
                            selectedValue={patient.militaryDischargeStatusCode}
                        />
                    ) : (
                        <ReferenceListItemDisplay
                            type={RefListTypes.MilitaryDischargeStatusCodes}
                            value={patient.militaryDischargeStatusCode}
                        />
                    )
                }
            />
            <AccordionItem
                label="Military record on file?"
                value={
                    isEditing ? (
                        <OptionDropDown
                            type={OptionTypes.yesNo}
                            onChange={(data: any) => setPatient({ ...patient, militaryRecordOnFileInd: data.value })}
                            selectedValue={patient.militaryRecordOnFileInd}
                        />
                    ) : patient.militaryRecordOnFileInd === null ? (
                        '-'
                    ) : patient.militaryRecordOnFileInd ? (
                        'Yes'
                    ) : (
                        'No'
                    )
                }
            />
            <AccordionItem
                label="Military related disability?"
                value={
                    isEditing ? (
                        <OptionDropDown
                            type={OptionTypes.yesNo}
                            onChange={(data: any) =>
                                setPatient({ ...patient, militaryRelatedDisabilityInd: data.value })
                            }
                            selectedValue={patient.militaryRelatedDisabilityInd}
                        />
                    ) : patient.militaryRelatedDisabilityInd === null ? (
                        '-'
                    ) : patient.militaryRelatedDisabilityInd ? (
                        'Yes'
                    ) : (
                        'No'
                    )
                }
            />
            <AccordionItem
                label="SSN"
                value={
                    isEditing ? (
                        <Input value={patient.patientSsn} onChangeText={(t: any) => handleUpdateSsn(t)} />
                    ) : (
                        <div
                            style={{ cursor: 'pointer' }}
                            onClick={() => setSSNVisible(!SSNVisible)}
                            data-test-id={ANALYTICS_NAMES.PatientDetails_PatientInfo_ViewSSN}
                        >
                            {SSNVisible && patient.patientSsn ? patient.patientSsn : '*** ** ****'}
                        </div>
                    )
                }
            />
        </Accordion>
    );
}

export default observer(PatientInfo);
