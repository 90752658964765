import React, { useState, useEffect } from 'react';
import styles from './styles.module.scss';
import Select from 'react-select';
import * as utils from 'src/utils';

interface Props {
    options: { value: any; label: string }[];
    selectedValue?: any;
    onUpdate: Function;
    placeholder?: string;
}

function FilterComponent(props: Props) {
    const [values, setValues] = useState(null);

    useEffect(() => {
        if (!props.options) return;
        let selectedVal;
        let selectedVals;
        if (utils.isArray(props.selectedValue)) {
            selectedVals = props.selectedValue.map((v: any) => props.options.find((i) => i.value === v));
            setValues(selectedVals);
        } else {
            selectedVal = props.options.find((i) => i.value === props.selectedValue);
            if (selectedVal) {
                selectedVal = [selectedVal];
                setValues(selectedVal);
            }
        }
    }, [props.selectedValue, props.options]);

    function handleChange(option: any) {
        setValues(option);
        props.onUpdate(option);
    }

    return (
        <Select
            value={values}
            onChange={(s: any) => {
                handleChange(s || []);
            }}
            options={props.options}
            placeholder={props.placeholder ? props.placeholder : 'Select Filter'}
            closeMenuOnSelect={true}
            isMulti={true}
            styles={utils.styleSelectComponent()}
        />
    );
}

export default FilterComponent;
