import React, { useState, useContext } from 'react';
import styles from './styles.module.scss';
import SideModal from 'src/components/SideModal';
import { format } from 'date-fns';
import Input, { Label } from 'src/components/Input';
import FacilityDropDown from 'src/components/FacilityDropDown';
import { Patient } from 'src/stores/PatientStore';
import * as patientService from 'src/api/patients';
import ModalHeader from 'src/components/ModalHeader';
import ModalFooter from 'src/components/ModalFooter';
import OptionDropDown, { OptionTypes } from 'src/components/OptionDropDown';
import DatePicker from 'src/components/DatePicker';
import PatientStateDropDown from 'src/components/PatientStateDropDown';
import { useHistory } from 'react-router';
import { API_DATE_FORMAT, ROUTES } from 'src/utils/constants';
import PatientStateStore from 'src/stores/PatientStateStore';

export interface CreatePatientProps {
    onClose: Function;
    facId?: number;
}

function CreatePatientModal(props: CreatePatientProps) {
    const defaultPatient: Patient = {
        facId: props.facId,
        patientFirstName: '',
        patientLastName: '',
        patientStateId: null,
        patientGenderCode: '',
        patientDob: null,
        patientReferralSource: '',
    };
    const [patient, setPatient] = useState(defaultPatient);
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();
    const patientStateStore = useContext(PatientStateStore);

    const updateInput = (param: string) => (t: string) => setPatient({ ...patient, [param]: t });
    const updateSelect = (option: { value: any; label: any }, param: string) =>
        setPatient({ ...patient, [param]: option.value });

    async function createPatient() {
        try {
            setIsLoading(true);
            const newPatient = await patientService.createPatient(patient);
            setPatient(defaultPatient);
            props.onClose();
            history.push(ROUTES.getString(ROUTES.PatientDetails, newPatient.data.patientId));
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <SideModal isOpen={true} onClose={props.onClose}>
            <ModalHeader title="New Patient" onClose={props.onClose} />
            <div className={styles.inviteModalContentWrap}>
                <Label text="FACILITY" />
                <div className={styles.select}>
                    <FacilityDropDown selectedValue={patient.facId} onChange={(s: any) => updateSelect(s, 'facId')} />
                </div>
                <Input
                    className={styles.input}
                    label="First Name"
                    value={patient.patientFirstName}
                    onChangeText={updateInput('patientFirstName')}
                />
                <Input
                    className={styles.input}
                    label="Last Name"
                    value={patient.patientLastName}
                    onChangeText={updateInput('patientLastName')}
                />
                <div>
                    <Label text="GENDER" />
                    <OptionDropDown
                        facId={patient.facId}
                        type={OptionTypes.Gender}
                        onChange={(s: any) => updateSelect(s, 'patientGenderCode')}
                        selectedValue={patient.patientGenderCode}
                    />
                </div>
                <div>
                    <Label text="DOB" />
                    <DatePicker
                        onlyPast={true}
                        value={patient.patientDob}
                        onChange={(date) => {
                            if (date) {
                                const value = format(new Date(date), API_DATE_FORMAT);
                                setPatient({ ...patient, patientDob: value });
                            }
                        }}
                    />
                </div>
                <Input
                    className={styles.input}
                    label="Referred By"
                    value={patient.patientReferralSource}
                    onChangeText={updateInput('patientReferralSource')}
                />
                <div>
                    <Label text="PATIENT STATUS" />
                    <PatientStateDropDown
                        defaultValue={
                            (patientStateStore.activePatientStates.find((s) => s.patientStateCode === 'ACTIVE') || {})
                                .patientStateId
                        }
                        isNormalColors={true}
                        selectedValue={patient.patientStateId}
                        onChange={(s: any) => updateSelect(s, 'patientStateId')}
                    />
                </div>
            </div>
            <ModalFooter
                primaryText="Create Patient"
                primaryClick={createPatient}
                secondaryClick={() => {
                    setPatient(defaultPatient);
                    props.onClose();
                }}
                isLoading={isLoading}
                primaryProps={{
                    disabled:
                        !patient ||
                        !patient.facId ||
                        !patient.patientFirstName ||
                        !patient.patientLastName ||
                        !patient.patientStateId,
                }}
            />
        </SideModal>
    );
}

export default CreatePatientModal;
