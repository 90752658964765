import React, { useContext, useState, useEffect } from 'react';
import Flex from 'src/components/Flex';
import PageContainer from 'src/components/PageContainer';
import PageContent from 'src/components/PageContent';
import Button from 'src/components/Button';
import NavBar from 'src/containers/NavBar';
import Card from 'src/components/Card';
import styles from './styles.module.scss';
import Table from 'src/components/Table';
import FilteredSearch, { FilteredSearchQuery } from 'src/components/FilteredSearch';
import FilterStore from 'src/stores/FilterStore';
import FeatureFlagStore from 'src/stores/FeatureFlagStore';
import { FilterQueryParams } from 'src/utils/types';
import { getPayersFilters } from 'src/utils/filters';
import Icon from 'src/components/Icon';
import { ICONS } from 'src/utils/constants';
import { CellInfo } from 'react-table';
import DateComponent from 'src/components/DateComponent';
import PaymentsStore, { Payer, PayerCategory } from 'src/stores/PaymentsStore';
import ActionMenu from 'src/components/ActionMenu';
import { ModalStoreObject, ModalTypes } from 'src/stores/ModalStore';
import { observer } from 'mobx-react';
import Pagination from 'src/components/Table/Pagination';
import { ToastStoreObject, ToastType } from 'src/stores/ToastStore';
import { parseError } from 'src/utils';
import { ANALYTICS_NAMES } from 'src/utils/analytics';

const FILTER_PAGE = 'payersPage';

function Payers() {
    const filterStore = useContext(FilterStore);
    const paymentsStore = useContext(PaymentsStore);
    const payerFilters: FilterQueryParams = filterStore[FILTER_PAGE];
    const featureFlagStore = useContext(FeatureFlagStore);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        paymentsStore.getPayerCategories();
    }, []);
    useEffect(() => {
        loadPayers(payerFilters);
    }, [payerFilters]);

    const handlePaginationFilterChange = function(newPage: number) {
        const updatedFilters = { ...filterStore[FILTER_PAGE] };
        updatedFilters.pagination.page = newPage;
        filterStore.setFilters(FILTER_PAGE, updatedFilters);
    };

    async function handleSearch({ query, fields }: FilteredSearchQuery) {
        const updatedFilters = { ...payerFilters };
        updatedFilters.pagination.page = 1;
        updatedFilters.query = query;
        updatedFilters.filters = fields;

        filterStore.setFilters(FILTER_PAGE, updatedFilters);
    }

    async function loadPayers(params?: FilterQueryParams) {
        try {
            setIsLoading(true);
            await paymentsStore.getPayers(params);
        } catch (e) {
            ToastStoreObject.show(parseError(e));
        } finally {
            setIsLoading(false);
        }
    }

    const columns = [
        {
            Header: 'PAYER NAME',
            accessor: 'payerName',
            minWidth: 150,
        },
        {
            Header: 'CATEGORY',
            accessor: 'payerCategoryId',
            minWidth: 100,
            Cell: (props: CellInfo) => {
                const category = paymentsStore.payerCategories.find(
                    (category: PayerCategory) => category.payerCategoryId === props.value,
                );
                return <span>{category ? category.payerCategoryTitle : ''}</span>;
            },
        },
        {
            Header: 'CREATED',
            accessor: 'createdAt',
            Cell: (props: CellInfo) => {
                return <DateComponent date={props.value} />;
            },
        },
        {
            Header: 'UPDATED',
            accessor: 'updatedAt',
            Cell: (props: CellInfo) => {
                return <DateComponent date={props.value} />;
            },
        },
        {
            Header: 'ACTIONS',
            accessor: 'actions',
            maxWidth: 125,
            Cell: (props: CellInfo) => (
                <ActionMenu
                    entityType="payer"
                    entity={props.original}
                    onItemClick={(action: { id: string; label: string }) => handleActionClick(action, props.original)}
                />
            ),
        },
    ];

    function handleActionClick(action: { id: string; label: string }, item: Payer) {
        switch (action.id) {
            case 'edit':
                ModalStoreObject.showModal(ModalTypes.PayerModal, {
                    onClose: () => ModalStoreObject.hideModal(),
                    onSave: () => loadPayers(payerFilters),
                    payer: item,
                    mode: 'edit',
                });
                break;
        }
    }

    function handleCreate() {
        ModalStoreObject.showModal(ModalTypes.PayerModal, {
            onClose: () => ModalStoreObject.hideModal(),
            onSave: () => {
                loadPayers();
                ModalStoreObject.hideModal();
            },
        });
    }

    return (
        <PageContainer>
            <NavBar />
            <PageContent>
                <Flex direction="row" className={styles.filterWrap}>
                    <Flex value={1}>
                        <FilteredSearch
                            initialValues={{
                                query: payerFilters.query,
                                fields: payerFilters.filters,
                            }}
                            onSearch={handleSearch}
                            searchPlaceholder={'Search by payer name'}
                            filterOptions={getPayersFilters() as any}
                            hideOrStatementSwitch={true}
                        />
                    </Flex>
                    <Flex direction="row" justify="end" className={styles.filterWrap}>
                        {featureFlagStore.isEnabled('payments') ? (
                            <Button
                                leftIcon={<Icon className={styles.plusIcon} name={ICONS.PlusButton} />}
                                type="primary"
                                onClick={handleCreate}
                                text="Create Payer"
                                disabled={isLoading}
                                className={styles.createPayerBtn}
                                data-test-id={ANALYTICS_NAMES.Payer_Create}
                            />
                        ) : null}
                    </Flex>
                </Flex>
                <Card className={styles.cardWrap}>
                    <div className={styles.tableTitle}>Payers</div>
                    <Table
                        columns={columns}
                        isLoading={isLoading}
                        data={paymentsStore.payers}
                        showPagination={false}
                        noDataText="No results"
                        pageSize={filterStore[FILTER_PAGE].pagination.limit}
                    />
                </Card>
                <Pagination
                    pages={Math.ceil(paymentsStore.totalPayersCount / filterStore[FILTER_PAGE].pagination.limit) || 1}
                    page={filterStore[FILTER_PAGE].pagination.page - 1}
                    onPageChange={(page: number) => handlePaginationFilterChange(page + 1)}
                    showing={paymentsStore.payers.length}
                    totalRecords={paymentsStore.totalPayersCount}
                />
            </PageContent>
        </PageContainer>
    );
}

export default observer(Payers);
