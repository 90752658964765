import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router';
import styles from './styles.module.scss';
import Flex from 'src/components/Flex';
import * as telehealthService from 'src/api/telehealth';
import LoadingCover from 'src/components/LoadingCover';
import TelehealthSessionVideo from 'src/components/TelehealthSessionVideo';
import { ToastStoreObject } from 'src/stores/ToastStore';
import fieldMedLogo from 'src/assets/images/fieldmed-logo.svg';
import graphiumLogo from 'src/assets/images/graphium-logo.svg';
import * as utils from 'src/utils';
import { parse } from 'query-string';
import Button from 'src/components/Button';
import { TelehealthSession } from 'src/utils/types';
import * as variables from 'src/styles/variables';
import { FeatureBranding } from 'src/components/FeatureFlag';
import { ANALYTICS_NAMES } from 'src/utils/analytics';

function formatDob(dob: string) {
    let str = dob.replace(/[^0-9]/gi, '');
    str = str.substr(0, 4) + '-' + str.substr(4, 2) + '-' + str.substr(6, 2);
    return str;
}
function PatientVideo() {
    const location = useLocation();

    const queryStrings = parse(location.search);
    const telehealthSessionGuid = queryStrings.guid as string;
    const org = useParams<{ orgnm: string }>().orgnm;

    const [isLoading, setIsLoading] = useState(false);
    const [sessionState, setSessionState] = useState<TelehealthSession | null>(null);
    const [dob, setDob] = useState('');
    const [facility, setFacility] = useState('');

    useEffect(() => {
        getFacility();
    }, []);

    async function getFacility() {
        try {
            const results = await telehealthService.getTelehealthSessionFacilityForPatient(telehealthSessionGuid, {
                org,
            });
            setFacility((results.data || {}).facilityName);
        } catch (e) {
            ToastStoreObject.show(utils.parseError(e));
        }
    }

    async function getSession() {
        try {
            setIsLoading(true);
            const session = await telehealthService.getTelehealthSessionForPatient(telehealthSessionGuid, {
                dob: formatDob(dob),
                org,
            });
            setSessionState(session.data || {});
            setIsLoading(false);
        } catch (e) {
            ToastStoreObject.show(utils.parseError(e));
            setIsLoading(false);
        }
    }

    return (
        <Flex
            direction="column"
            align="center"
            justify="center"
            style={{ height: '100vh', backgroundColor: variables.offWhite }}
        >
            <Flex className={styles.contentWrap} direction="column" align="center" justify="center">
                <Flex
                    direction="row"
                    align="center"
                    value={1}
                    self="stretch"
                    justify="center"
                    className={styles.logoHeader}
                >
                    <FeatureBranding
                        fieldmedContent={<img src={fieldMedLogo} className={styles.fieldMedLogo} />}
                        graphiumContent={<img src={graphiumLogo} className={styles.fieldMedLogo} />}
                    />
                </Flex>
                {isLoading ? <LoadingCover /> : <div />}
                {!sessionState ? (
                    <Flex direction="column" align="center" justify="center" style={{ width: 250, paddingTop: 20 }}>
                        <div className={styles.enterDob}>
                            Please enter your date of birth to join this telehealth visit
                            {facility ? ` by ${facility}.` : ''}
                        </div>
                        <input
                            data-test-id={ANALYTICS_NAMES.PatientVideo_Date_Input}
                            type="date"
                            value={dob}
                            onChange={(e) => setDob(e.target.value)}
                        />
                        <Flex
                            self="stretch"
                            align="center"
                            justify="center"
                            style={{ marginTop: 15, marginBottom: 20 }}
                        >
                            <Button
                                data-test-id={ANALYTICS_NAMES.PatientVideo_Submit_Button}
                                onClick={getSession}
                                disabled={!dob}
                                type="primary"
                                text="Submit"
                            />
                        </Flex>
                    </Flex>
                ) : (
                    <Flex direction="row">
                        <TelehealthSessionVideo
                            // @ts-ignore
                            session={sessionState}
                            telehealthSessionId={sessionState.telehealthSessionId}
                            onComplete={() => {}}
                            isPatient={true}
                            patientDob={formatDob(dob)}
                            patientOrg={org}
                            onRefreshSession={(session?: TelehealthSession) => session && setSessionState(session)}
                        />
                    </Flex>
                )}
            </Flex>
        </Flex>
    );
}
export default PatientVideo;
