import React from 'react';
import Icon from 'src/components/Icon';
import Button, { CustomButtonProps } from 'src/components/Button';
import styles from './styles.module.scss';
import { ICONS } from 'src/utils/constants';
import * as variables from 'src/styles/variables';

const DetailsButton = (props: CustomButtonProps) => (
    <Button
        type="secondary"
        text="Details"
        rightIcon={<Icon name={ICONS.Arrow} color={variables.red} className={styles.icon} />}
        className={styles.button}
        {...props}
    />
);

export default DetailsButton;
