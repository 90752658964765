import { UseMutationResult, useMutation, useQueryClient } from 'react-query';
import { updateFormCatalogPage } from 'src/api/formCatalogs';
import { FormCatalog, FormCatalogPage } from 'common/lib/entity/framework/FormCatalog';
import { formCatalogKey } from './useFormCatalog';
import { formCatalogPageUrlKey } from './useFormCatalogPageUrl';

export const updateFormCatalogPageKey = 'updateFormCatalogPage';

export interface UseUpdateFormCatalogPageInputVariables {
    pageId: FormCatalogPage['pageId'];
    minCount?: FormCatalogPage['minCount'];
    maxCount?: FormCatalogPage['maxCount'];
    pageImage?: FormCatalogPage['originalPageImage'];
}

export const useUpdateFormCatalogPage = (
    formCatalogId: FormCatalog['formCatalogId'],
): UseMutationResult<void, Error, UseUpdateFormCatalogPageInputVariables, unknown> => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationKey: [updateFormCatalogPageKey],
        mutationFn: async (variables) => {
            const { pageId, minCount, maxCount, pageImage } = variables;

            if (!formCatalogId) {
                throw new Error('formCatalogId is required');
            }

            if (!pageId) {
                throw new Error('pageId is required');
            }

            if (minCount != null && isNaN(minCount)) {
                throw new Error('Min count must be a number');
            }

            if (maxCount != null && isNaN(maxCount)) {
                throw new Error('Max count must be a number');
            }

            if (minCount > maxCount) {
                throw new Error('Min count must be less than or equal to max count');
            }

            return await updateFormCatalogPage({
                formCatalogId,
                pageId,
                minCount,
                maxCount,
                pageImage,
            });
        },
        onSuccess: async (data) => {
            queryClient.invalidateQueries({ queryKey: [formCatalogKey, { formCatalogId }] });
            queryClient.invalidateQueries({
                queryKey: [formCatalogPageUrlKey, { formCatalogId }],
            });
            return data;
        },
        retry: false,
    });
};
