import React from 'react';

interface Props extends React.HTMLAttributes<HTMLSpanElement> {
    size?: number;
}
const Dot = ({ size, ...rest }: Props) => (
    <span {...rest} style={size ? { fontSize: size } : undefined}>
        &middot;
    </span>
);

export default Dot;
