import React, { useState, useEffect, useRef, useContext } from 'react';
import { observer } from 'mobx-react';
import Select from 'react-select';
import NemsisOptionDropDown from 'src/components/NemsisOptionDropDown';
import * as providerService from 'src/api/providers';
import * as valueSetService from 'src/api/valueSet';
import * as referenceListService from 'src/api/referenceList';
import * as transportFacilityService from 'src/api/transportFacility';
import styles from '../../styles.module.scss';
import { FormUtils } from 'common/lib/form-generation/util/FormUtils';
import { Provider } from 'src/stores/ProviderStore';
import { ReferenceListValue, RefListTypes } from 'src/stores/ReferenceListStore';
import * as variables from 'src/styles/variables';
import { ANALYTICS_NAMES } from 'src/utils/analytics';
import { NaValues } from 'common/lib/model/nemsis3_4/demographic';
import { TransportFacility } from 'src/stores/TransportFacilityStore';
import ReferenceListStore from 'src/stores/ReferenceListStore';

interface ListProps {
    input: any;
    facId: number;
    formUtils: FormUtils;
    onChange?: Function;
    onNaChange?: Function;
    isValid?: boolean;
    isMulti?: boolean;
    value?: any;
    notValue?: string;
    valueSets?: any;
    enableNotValues?: boolean;
    visibleProperty?: string;
}

function List(props: ListProps) {
    const firstValueRender = useRef(true);
    const firstNotValueRender = useRef(true);
    const [value, setValue] = useState(props.value);
    const [selectedValue, setSelectedValue] = useState(null);
    const [notValue, setNotValue] = useState(props.notValue || '');
    const [options, setOptions] = useState([]);
    const referenceListStore = useContext(ReferenceListStore);

    useEffect(() => {
        getListData();
    }, []);

    useEffect(() => {
        getListData();
    }, [props.visibleProperty]);

    useEffect(() => {
        if (options.length) {
            const actualValue =
                props.isMulti && Array.isArray(props.value)
                    ? options.filter((option: any) =>
                          props.value.find((v: any) =>
                              v[getPersistedJsonPropertyName()]
                                  ? v[getPersistedJsonPropertyName()] === option.value
                                  : v.value === option.value,
                          ),
                      )
                    : options.find((option: any) => option.value === props.value);

            setSelectedValue(actualValue);
        }
    }, [props.value, options]);

    useEffect(() => {
        if (props.onChange && !firstValueRender.current) {
            props.onChange(value);
            setSelectedValue(value);
        }
        firstValueRender.current = false;
    }, [value]);

    useEffect(() => {
        if (props.onNaChange && !firstNotValueRender.current) {
            props.onNaChange(notValue);
        }
        firstNotValueRender.current = false;
    }, [notValue]);

    function getInputMethod() {
        return props.input.inputMethod;
    }

    function getList() {
        return getInputMethod()[0].list[0];
    }

    function getPersistedItemAttribute() {
        return getList().$.persistedItemAttribute;
    }

    function getPersistedJsonPropertyName() {
        return getList().$.persistedJsonPropertyName;
    }

    function getValueSetType() {
        return getList().$.valueSetType;
    }

    function getLabelExpression() {
        return getList().$.labelExpression;
    }

    function getLabelAttribute() {
        return getList().$.labelAttribute;
    }

    function getValueSetName() {
        return getList().$.valueSetName;
    }

    function getLocalValueSet() {
        let actualValueSet: any = {};

        props.valueSets.forEach((valueSet: any) => {
            actualValueSet = valueSet.valueSet.find(
                (loopedValueSet: any) => loopedValueSet.$.name === getValueSetName(),
            );
        });

        return actualValueSet;
    }

    async function getListData() {
        const valueSetType = getValueSetType();
        const pageId = props.formUtils.getOrderedPages()[0].pageId;
        switch (valueSetType) {
            case 'LOCAL':
                const localValueSet = getLocalValueSet();
                if (localValueSet) {
                    const valueSetDetails = localValueSet.detail;
                    const valueSetOptions = valueSetDetails.map((value: any) => {
                        const item: any =
                            value && value.$
                                ? {
                                      Code: value.$.Code,
                                      Description: value.$.Description,
                                  }
                                : {};

                        return {
                            label: value.$[getLabelAttribute()],
                            value: value.$[getPersistedItemAttribute()],
                            item,
                        };
                    });
                    setOptions(valueSetOptions);
                }
                break;
            case 'REMOTE':
                const category = getList().$.valueSetCategoryName;
                const valueSetName = getList().$.valueSetName;
                const remoteValueSet = await valueSetService.getValueSet({
                    category,
                    name: valueSetName,
                    facId: props.facId,
                });
                const remoteOptions = remoteValueSet.map((value: any) => {
                    let labelValue = value[getPersistedItemAttribute()];

                    if (getLabelAttribute()) {
                        labelValue = value[getLabelAttribute()];
                    } else if (getLabelExpression()) {
                        labelValue = props.formUtils.evaluateFormDefinitionExpression(
                            pageId,
                            getLabelExpression(),
                            value,
                        );
                    }

                    return {
                        label: labelValue,
                        value: value[getPersistedItemAttribute()],
                        item: value,
                    };
                });
                setOptions(remoteOptions);
                break;
            case 'REFERENCE_LIST':
                const remoteValueSetName: RefListTypes = getValueSetName();

                if (!remoteValueSetName) return;
                const slim =
                    remoteValueSetName === RefListTypes.RxNormCodes || remoteValueSetName === RefListTypes.ZipCodes;

                if (
                    !referenceListStore.referenceListValues[remoteValueSetName] &&
                    !slim &&
                    remoteValueSetName !== RefListTypes.ANSICityCodes
                ) {
                    await referenceListStore.getData(remoteValueSetName, true);
                }
                // TODO: Remove this hack and use either filterExpression or replace the ANSICityCodes with search inputs that hit a service to return actual city codes
                let slimResults;
                if (remoteValueSetName === RefListTypes.ANSICityCodes) {
                    await referenceListStore.getFilteredData(remoteValueSetName, {
                        filterName: 'stateAnsiCode',
                        filterValue: props.visibleProperty,
                    });
                } else if (slim) {
                    slimResults = await referenceListService.getReferenceListValues(remoteValueSetName, { slim });
                }
                const results =
                    slimResults && slimResults.data
                        ? slimResults.data || []
                        : remoteValueSetName === RefListTypes.ANSICityCodes
                        ? referenceListStore.filteredReferenceListValues.get(
                              `${remoteValueSetName}-${props.visibleProperty}`,
                          ) || []
                        : referenceListStore.referenceListValues[remoteValueSetName] || [];
                const referenceListOptions = results.map((value: ReferenceListValue) => {
                    const item: any = {
                        Code: value.listValueCode,
                        DisplayName: value.listValueDisplayName,
                        Description: value.listValueDescription,
                    };

                    const labelValue = getLabelAttribute()
                        ? item[getLabelAttribute()]
                        : props.formUtils.evaluateFormDefinitionExpression(pageId, getLabelExpression(), item);

                    return {
                        label: labelValue,
                        value: item[getPersistedItemAttribute()],
                        item: item,
                    };
                });
                setOptions(referenceListOptions);
                break;
            case 'PROVIDERS':
                const { result } = await providerService.getProviders({ filters: { facility: [props.facId] } });
                const providerOptions = result.map((provider: Provider) => {
                    const item: any = {
                        FirstName: provider.frstNm,
                        LastName: provider.lastNm,
                        NationalProviderID: provider.natlPrvrId,
                        LocalProviderID: provider.localPrvrId,
                        ProviderType: provider.prvrTyp,
                        Specialty: provider.spclty,
                        ProviderID: provider.prvrId,
                    };
                    const labelValue = getLabelAttribute()
                        ? item[getLabelAttribute()]
                        : props.formUtils.evaluateFormDefinitionExpression(pageId, getLabelExpression(), item);
                    return {
                        label: labelValue,
                        value: item[getPersistedItemAttribute()],
                        item: item,
                    };
                });
                setOptions(providerOptions);
                break;
            case 'TRANSPORT_FACILITIES':
                const transportFacilitiesData = await transportFacilityService.getTransportFacilities();
                const transportFacilities = transportFacilitiesData.data || [];
                const transportFacilitiesOptions = transportFacilities.map((transportFacility: TransportFacility) => {
                    const item: any = {
                        FacilityName: transportFacility.facilityName,
                        FacilityLocationCode: transportFacility.facilityLocationCode,
                        CityCode: transportFacility.cityCode,
                        StateCode: transportFacility.stateCode,
                        CountryCode: transportFacility.countryCode,
                    };
                    const labelValue = getLabelAttribute()
                        ? item[getLabelAttribute()]
                        : props.formUtils.evaluateFormDefinitionExpression(pageId, getLabelExpression(), item);
                    return {
                        label: labelValue,
                        value: item[getPersistedItemAttribute()],
                        item: item,
                    };
                });
                setOptions(transportFacilitiesOptions);
                break;
        }
    }

    const listStyles = {
        control: (base: any, state: any) =>
            props.isValid
                ? { ...base }
                : {
                      ...base,
                      borderColor: variables.brightRed,
                      '&:hover': {
                          borderColor: variables.brightRed,
                      },
                  },
    };

    const listProps = {
        className: styles.inputWrap,
        options: options,
        styles: listStyles,
        isMulti: (notValue !== NaValues.NOT_APPLICABLE && props.isMulti) || false,
        isClearable: true,
        onChange: (value: any) => {
            setValue(value);
        },
        ['data-test-id']: ANALYTICS_NAMES.DiscreteForm_ListInput,
        ...(props.enableNotValues
            ? {
                  onNaClick: () => setNotValue(notValue === NaValues.NOT_APPLICABLE ? '' : NaValues.NOT_APPLICABLE),
                  selectedValue: Array.isArray(selectedValue)
                      ? selectedValue.map((sv: any) => sv.value)
                      : selectedValue && selectedValue.item && selectedValue.item[getPersistedItemAttribute()],
                  naSelected: notValue === NaValues.NOT_APPLICABLE,
              }
            : {
                  value: selectedValue,
              }),
    };

    return (
        <div>
            <div className={styles.label}>{props.input.title ? props.input.title[0] : ''}</div>
            {props.enableNotValues ? <NemsisOptionDropDown {...listProps} /> : <Select {...listProps} />}
        </div>
    );
}

export default observer(List);
