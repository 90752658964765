import React, { useContext, useState } from 'react';
import EventStore from 'src/stores/EventStore';
import Input, { Label } from 'src/components/Input';
import ModalHeader from 'src/components/ModalHeader';
import ModalFooter from 'src/components/ModalFooter';
import { ViewProps, EventDetailViews } from '../index';
import styles from '../styles.module.scss';
import OptionDropDown, { OptionTypes } from 'src/components/OptionDropDown';
import { ModalTypes, ModalStoreObject } from 'src/stores/ModalStore';

export default function CancelView(props: ViewProps) {
    const [reasonType, setReasonType] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [reason, setReason] = useState('');
    const isTask = props.event.eventClassCode === 'TASK';
    const eventStore = useContext(EventStore);

    async function save() {
        if (!reasonType) {
            return;
        }
        try {
            setIsLoading(true);
            await eventStore.cancelEvent(props.event.eventId, { reason, type: reasonType });
            setIsLoading(false);
            props.onChangeComplete && props.onChangeComplete();
            props.onClose();
            ModalStoreObject.hideModal();
        } catch (error) {
            setIsLoading(false);
        }
    }

    function handleConfirm() {
        ModalStoreObject.showModal(ModalTypes.ConfirmationModal, {
            title: `Are you sure you want to cancel this ${isTask ? 'Task' : 'Appointment'}?`,
            onConfirm: save,
            onCancel: () => ModalStoreObject.hideModal(),
            cancelButtonText: 'No',
            confirmButtonText: 'Yes',
        });
    }

    return (
        <div className={styles.content}>
            <ModalHeader title="Cancellation Reason" onClose={props.onClose} />
            <div className={styles.mainContent}>
                <Label text="Reason" required={true} />
                <OptionDropDown
                    facId={props.event.facId}
                    type={
                        isTask ? OptionTypes.TaskCancelationReasonTypes : OptionTypes.AppointmentCancelationReasonTypes
                    }
                    onChange={(data: any) => setReasonType(data.value)}
                />
                <Input
                    label="Notes"
                    multiLine={true}
                    value={reason}
                    onChangeText={(t: string) => setReason(t)}
                    placeholder="Enter any notes on why you are cancelling"
                    className={styles.textarea}
                    rows={5}
                />
            </div>
            <ModalFooter
                secondaryText="Go Back"
                primaryProps={{ disabled: !reasonType }}
                primaryText="Done"
                isLoading={isLoading}
                secondaryClick={() => props.onChangeView(EventDetailViews.EVENT)}
                primaryClick={() => {
                    if (!reasonType) {
                        return;
                    }
                    handleConfirm();
                }}
            />
        </div>
    );
}
