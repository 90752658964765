import React, { ReactNode } from 'react';
import Flex from 'src/components/Flex';
import Button, { CustomButtonProps } from 'src/components/Button';
import styles from './styles.module.scss';
import { ANALYTICS_NAMES } from 'src/utils/analytics';

export interface ModalFooterProps {
    primaryText?: string;
    secondaryText?: string;
    primaryClick?: Function;
    secondaryClick?: Function;
    primaryProps?: Partial<CustomButtonProps>;
    secondaryProps?: Partial<CustomButtonProps>;
    children?: ReactNode;
    isLoading?: boolean;
    showSecondary?: boolean;
}

export default function ModalFooter(props: ModalFooterProps) {
    return (
        <Flex direction="row" className={styles.modalFooter} self="stretch" align="end" justify="end">
            {props.children || (
                <>
                    {props.showSecondary === false ? null : (
                        <Flex value={1} align="center" justify="start" className={styles.secondaryWrap}>
                            <Button
                                type="outline"
                                {...(props.secondaryProps || {})}
                                text={props.secondaryText || 'Cancel'}
                                onClick={() => props.secondaryClick()}
                                data-test-id={ANALYTICS_NAMES.ModalFooter_Cancel}
                            />
                        </Flex>
                    )}
                    <Flex justify="end" align="center">
                        <Button
                            type="primary"
                            {...(props.primaryProps || {})}
                            text={props.primaryText || 'Save'}
                            onClick={() => props.primaryClick()}
                            isLoading={props.isLoading}
                            data-test-id={ANALYTICS_NAMES.ModalFooter_Submit}
                        />
                    </Flex>
                </>
            )}
        </Flex>
    );
}
