import React, { useState, useEffect } from 'react';
import styles from './styles.module.scss';
import * as patientService from 'src/api/patients';
import * as utils from 'src/utils';
import AsyncSelect from 'react-select/async';
import { Patient } from 'src/stores/PatientStore';
import { Facility } from 'src/stores/FacilityStore';
import { FILTER_METHODS } from 'src/utils/filters';

export interface PatientDropDownProps {
    onChange: Function;
    facId: Facility['facId'] | Facility['facId'][];
    allowEmpty?: boolean;
    selectedValue?: number;
    isDisabled?: boolean;
}

function formatPatients(arr: Patient[]) {
    return utils.formatSelectOptions(arr, {
        valueKey: 'patientId',
        getLabel: (i: Patient) => utils.getPatientName(i),
    });
}

function PatientDropDown(props: PatientDropDownProps) {
    const [selected, setSelected] = useState(null);
    const [searchResults, setSearchResults] = useState([]);

    // Get the patient that is filled in
    useEffect(() => {
        async function loadPatient() {
            if (props.selectedValue) {
                const results = await patientService.getPatient(props.selectedValue);
                if (results && results.data) {
                    const formattedPatient = formatPatients([results.data])[0];
                    setSelected(formattedPatient);
                }
            }
        }
        loadPatient();
    }, [props.selectedValue]);

    const refreshSearchResults = async function(query: string) {
        if (!query) {
            return [];
        }
        const { result } = await patientService.getPatients({
            query,
            filters: [
                {
                    name: 'facId',
                    method: FILTER_METHODS.One_Of_Dropdown,
                    data: { value1: utils.isArray(props.facId) ? props.facId : [props.facId] },
                },
            ],
        });
        return result;
    };

    function handleChange(s: any) {
        setSelected(s);
        if (!s) {
            props.onChange();
            return;
        }
        const patient = (searchResults || []).find((i) => i.patientId === s.value);
        props.onChange(patient);
    }

    async function loadOptions(inputValue: string, callback: Function) {
        const data = await refreshSearchResults(inputValue);
        setSearchResults(data);
        const formattedValues = formatPatients(data);
        callback(formattedValues);
    }
    return (
        <AsyncSelect
            loadOptions={utils.debounce(loadOptions, 500)}
            value={selected}
            onChange={handleChange}
            isDisabled={props.isDisabled}
            className={styles.select}
            isClearable={props.allowEmpty}
            placeholder="Select Patient"
            noOptionsMessage={({ inputValue }: any) =>
                !inputValue ? 'Start typing to find a patient' : 'No Patients found'
            }
            styles={utils.styleSelectComponent()}
        />
    );
}

export default PatientDropDown;
