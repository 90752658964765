import React from 'react';

const SvgAssign = (props) => (
    <svg width={20} height={20} {...props} viewBox="0 0 20 20">
        <g
            transform="translate(1)"
            stroke={props.color || '#FFF'}
            strokeWidth={1.2}
            fill="none"
            fillRule="evenodd"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <circle cx={4.156} cy={2.969} r={2.375} />
            <path d="M4.156 6.531v4.75M5.938 18.406l.593-5.937H7.72v-2.375a3.562 3.562 0 00-7.125 0v2.375H1.78l.594 5.937h3.563zM18.406 5.344a4.75 4.75 0 11-4.75-4.75" />
            <path d="M18.406 1.78l-4.33 4.33a.593.593 0 01-.84 0l-1.361-1.36" />
        </g>
    </svg>
);

export default SvgAssign;
