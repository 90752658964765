import React from 'react';
import { CellInfo } from 'react-table';
import Button from 'src/components/Button';
import Flex from 'src/components/Flex';
import Icon from 'src/components/Icon';
import { ICONS, ROUTES } from 'src/utils/constants';
import * as variables from 'src/styles/variables';
import { useHistory } from 'react-router-dom';
import styles from './styles.module.scss';
import Card from 'src/components/Card';
import Table from 'src/components/Table';
import { useFormModels } from 'src/queries/useFormModels';
import DateComponent from 'src/components/DateComponent';
import { ModalStoreObject, ModalTypes } from 'src/stores/ModalStore';

function Models() {
    const history = useHistory();
    const { data: formModels, isFetching } = useFormModels();

    const columns = [
        {
            Header: 'Model',
            accessor: 'modelName',
        },
        {
            Header: 'Created',
            accessor: 'createdAt',
            maxWidth: 200,
            Cell: (props: CellInfo) => (
                <span>
                    <DateComponent date={props.value} />
                </span>
            ),
        },
        {
            Header: 'Updated',
            accessor: 'updatedAt',
            maxWidth: 200,
            Cell: (props: CellInfo) => (
                <span>
                    <DateComponent date={props.value} />
                </span>
            ),
        },
        {
            Header: 'Actions',
            maxWidth: 150,
            style: { paddingVertical: 0, paddingHorizontal: 20 },
            Cell: (props: CellInfo) => (
                <Flex gap={8}>
                    <Button
                        onClick={() => {
                            ModalStoreObject.showModal(ModalTypes.FormModelModal, {
                                mode: 'edit',
                                formModel: props.original,
                                onClose: () => ModalStoreObject.hideModal(),
                                onSave: () => ModalStoreObject.hideModal(),
                            });
                        }}
                        type="secondarySmall"
                        leftIcon={<Icon name={ICONS.Pencil2} color={variables.red} />}
                    />
                </Flex>
            ),
        },
    ];

    return (
        <>
            <Flex direction="row" justify="end">
                <Button
                    leftIcon={<Icon className={styles.plusIcon} name={ICONS.PlusButton} />}
                    type="primary"
                    onClick={() => {
                        ModalStoreObject.showModal(ModalTypes.FormModelModal, {
                            onClose: () => ModalStoreObject.hideModal(),
                            onSave: () => ModalStoreObject.hideModal(),
                        });
                    }}
                    text="New Model"
                />
            </Flex>
            <Card className={styles.cardWrap}>
                <Table
                    isLoading={isFetching && formModels.length === 0}
                    columns={columns}
                    data={formModels}
                    showPagination={false}
                />
            </Card>
        </>
    );
}

export default Models;
