import React, { useState, useContext, useEffect } from 'react';
import styles from './styles.module.scss';
import Flex from 'src/components/Flex';
import CenterModal from 'src/components/CenterModal';
import ModalHeader from 'src/components/ModalHeader';
import ModalFooter from 'src/components/ModalFooter';
import Input, { Label } from '../Input';
import Checkbox from '../Checkbox';
import PaymentsStore, { PaymentRequest } from 'src/stores/PaymentsStore';
import { ToastStoreObject } from 'src/stores/ToastStore';
import { parseError } from 'src/utils';
import LoadingIcon from '../LoadingIcon';
import DatePicker from '../DatePicker';
import { formatNumberToCurrency } from 'common/lib/util/number';

interface CollectPaymentModalProps {
    onSave: Function;
    onCancel: Function;
    paymentRequestGuid: PaymentRequest['paymentRequestGuid'];
}

const CollectPaymentModal = (props: CollectPaymentModalProps) => {
    const paymentsStore = useContext(PaymentsStore);
    const [isLoading, setIsLoading] = useState(false);
    const [requestData, setRequestData] = useState(null);
    const [paymentRequest, setPaymentRequest] = useState(null);
    const [paymentAmountCollected, setPaymentAmountCollected] = useState<PaymentRequest['paymentRequestAmount']>(0);
    const [amountDue, setAmountDue] = useState(null);
    const [amountPaid, setAmountPaid] = useState(null);
    const [markAsCompleted, setMarkAsCompleted] = useState(false);
    const [paymentDate, setPaymentDate] = useState(null);
    const [paymentNote, setPaymentNote] = useState('');

    useEffect(() => {
        retrievePaymentRequest();
    }, []);

    useEffect(() => {
        if (requestData) {
            setPaymentRequest(requestData.paymentRequest);
            setAmountDue(Math.max(0, requestData.amountDue));
            setPaymentAmountCollected(Math.max(0, requestData.amountDue));
            setAmountPaid(requestData.amountPaid);
            setIsLoading(false);
        }
    }, [requestData]);

    async function retrievePaymentRequest() {
        setIsLoading(true);

        try {
            const pr = await paymentsStore.getPaymentRequest(props.paymentRequestGuid, {
                includeContacts: true,
                includeTransactions: true,
            });
            setRequestData(pr);
        } catch (e) {
            ToastStoreObject.show(parseError(e));
        }
    }

    async function handleSend() {
        if (!paymentDate) {
            ToastStoreObject.show('Please select a date before submitting a payment');
            return;
        }

        setIsLoading(true);
        try {
            await paymentsStore.collectPayment(props.paymentRequestGuid, {
                paymentAmountCollected,
                markAsCompleted,
                paymentDate,
                paymentNote,
            });
            props.onSave();
        } catch (e) {
            ToastStoreObject.show(parseError(e));
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <CenterModal isOpen={true} onClose={props.onCancel}>
            <div>
                <ModalHeader title="Collect Payment" />
                {isLoading ? (
                    <Flex justify="center">
                        <LoadingIcon />
                    </Flex>
                ) : (
                    <Flex direction="column" className={styles.modalContent} align="start" self="stretch">
                        <Flex direction="row" className={styles.amountContainer}>
                            <Flex direction="column" justify="center" className={styles.amountRequested}>
                                <div className={styles.title}>Amount Requested</div>
                                <div className={styles.amount}>
                                    {paymentRequest && formatNumberToCurrency(paymentRequest.paymentRequestAmount)}
                                </div>
                            </Flex>
                            <Flex direction="column" justify="center" className={styles.amountRequested}>
                                <div className={styles.title}>Amount Due</div>
                                <div className={styles.amount}>{formatNumberToCurrency(amountDue || 0)}</div>
                            </Flex>
                            <Flex direction="column" justify="center" className={styles.amountRequested}>
                                <div className={styles.title}>Amount Paid</div>
                                <div className={styles.amount}>{formatNumberToCurrency(amountPaid || 0)}</div>
                            </Flex>
                        </Flex>
                        <Flex align="center" justify="center">
                            <div className={styles.amountCollected}>
                                <Input
                                    type="number"
                                    label="Amount Collected"
                                    value={paymentAmountCollected}
                                    onChangeText={(v: number) => setPaymentAmountCollected(v)}
                                />
                            </div>
                            <div className={styles.markAsCompleted}>
                                <Checkbox
                                    checked={markAsCompleted}
                                    label="Mark as complete?"
                                    onChange={(e) => setMarkAsCompleted(e.target.checked)}
                                />
                            </div>
                        </Flex>
                        <Label text="Payment Date" />
                        <DatePicker value={paymentDate} onChange={(d: any) => setPaymentDate(d)} />
                        <Input label="Remarks" value={paymentNote} onChangeText={(v: string) => setPaymentNote(v)} />
                    </Flex>
                )}
                <ModalFooter
                    primaryText="Send"
                    primaryClick={handleSend}
                    secondaryText="Cancel"
                    secondaryClick={props.onCancel}
                    primaryProps={{ disabled: paymentAmountCollected <= 0 }}
                    isLoading={isLoading}
                />
            </div>
        </CenterModal>
    );
};

export default CollectPaymentModal;
