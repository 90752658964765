import { action, observable, makeObservable, runInAction } from 'mobx';
import { createContext } from 'react';
import * as encountersAPI from 'src/api/encounters';
import { FilterQueryParams, EncounterForm } from 'src/utils/types';
import { OptionTypes } from 'src/components/OptionDropDown';
import { Facility } from './FacilityStore';

export type EncFormApiFilters = {
    [key in string]: {
        [key2 in string]: {
            id: string;
            name: string;
            methods: string[];
            optionType?: OptionTypes;
            options?: { id: string | number; name: string; value: string | number }[];
        };
    };
};

export type EncFormApiDisplayFields = {
    [key in string]: DisplayField[];
};

export type DisplayField = {
    field: string;
    table?: string | null;
    label: string;
};
export type EncFormApiCategories = { name: string; id: string }[];
class FormStore {
    @observable forms: EncounterForm[] = [];
    @observable allForms: EncounterForm[] = [];
    @observable totalFormsCount: number = 0;

    constructor() {
        makeObservable(this);
    }

    @action
    reset() {
        this.forms = [];
        this.totalFormsCount = 0;
    }

    @action
    async getForms(params: FilterQueryParams = {}) {
        const { result, count } = await encountersAPI.getEncounterForms(params);
        this.setForms(result || [], count);
    }

    @action
    setForms(forms: EncounterForm[], total: number = 0) {
        this.forms = forms;
        this.totalFormsCount = total;
    }

    @action
    async getAllForms(params: FilterQueryParams = {}) {
        const cacheUrl = await encountersAPI.getAllEncounterForms(params);
        const { result, count } = await encountersAPI.getAllEncounterFormsFromS3(cacheUrl);
        this.setAllForms(result || []);
    }

    @action
    setAllForms(forms: EncounterForm[]) {
        this.allForms = forms;
    }

    @action
    async updateEncounterFormVoidStatus(encounterFormId: number, isVoid: boolean) {
        await encountersAPI.updateEncounterForm(encounterFormId, { voidInd: isVoid });
        // @ts-ignore
        const updatedFormInStore = this.forms.find((f) => f.enctr_form_id == encounterFormId);
        if (updatedFormInStore) {
            runInAction(() => {
                // @ts-ignore
                updatedFormInStore.void_ind = isVoid;
                // @ts-ignore
                updatedFormInStore.upd_dttm = new Date().toISOString();
            });
        }
    }

    @action
    async getEncounterForm(encounterFormId: number, facId: Facility['facId']) {
        const encounterForm = await encountersAPI.getEncounterFormPdfData(encounterFormId, facId);
        return encounterForm;
    }
}

// Import this to any other store that needs to use a value from it
export const FormStoreObject = new FormStore();

export default createContext(FormStoreObject);
