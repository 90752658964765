import React, { ReactElement } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import Flex from 'src/components/Flex';
import { Label } from 'src/components/Input';

interface Props {
    steps: ReactElement[];
    activeIndex: number;
    labels?: string[];
    removePadding?: boolean;
    hideProgressBar?: boolean;
}

function MultiStep(props: Props) {
    function renderProgress() {
        const stepCount = (props.steps || []).length;
        const progress = props.activeIndex === 0 ? 0 : props.activeIndex / (stepCount - 1);

        function getLabel(index: number) {
            return props.labels !== undefined && props.labels.length > index
                ? props.labels[index]
                : `Step ${index + 1}`;
        }

        return (
            <Flex direction="column" self="stretch">
                <Flex align="center" self="stretch" className={classNames(styles.progressWrap)}>
                    <div className={styles.blueBar} style={{ width: `${progress * 100}%` }}></div>
                    <div
                        className={styles.dot}
                        style={{ left: progress === 0 ? 0 : `calc(${progress * 100}% - 8px)` }}
                    ></div>
                </Flex>
                <Flex direction="row" justify="between" className={styles.labelWrap}>
                    {props.steps.map((step, index) => (
                        <Label
                            key={index}
                            text={getLabel(index)}
                            className={classNames({ [styles.blackText]: props.activeIndex === index })}
                        />
                    ))}
                </Flex>
            </Flex>
        );
    }

    return (
        <div>
            <div className={!props.removePadding ? styles.contentWrap : null}>
                {!props.hideProgressBar ? renderProgress() : null}
                {props.steps[props.activeIndex]}
            </div>
        </div>
    );
}

export default MultiStep;
