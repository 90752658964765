import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import Appointment from '../../assets/icons/icons/Appointment';
import AppWindowUser from '../../assets/icons/icons/AppWindowUser';
import Arrow from '../../assets/icons/icons/Arrow';
import ArrowSide from '../../assets/icons/icons/ArrowSide';
import Assign from '../../assets/icons/icons/Assign';
import Attachment from '../../assets/icons/icons/Attachment';
import AttachmentIcon from '../../assets/icons/icons/AttachmentIcon';
import Back from '../../assets/icons/icons/Back';
import Bell from '../../assets/icons/icons/Bell';
import Bin from '../../assets/icons/icons/Bin';
import Calendar from '../../assets/icons/icons/Calendar';
import CalendarCheck from '../../assets/icons/icons/CalendarCheck';
import Check from '../../assets/icons/icons/Check';
import Check1 from '../../assets/icons/icons/Check1';
import CheckCircle from '../../assets/icons/icons/CheckCircle';
import Close from '../../assets/icons/icons/Close';
import CloseCopy from '../../assets/icons/icons/CloseCopy';
import Cog3 from '../../assets/icons/icons/Cog3';
import Details from '../../assets/icons/icons/Details';
import DotMenu from '../../assets/icons/icons/DotMenu';
import Download from '../../assets/icons/icons/Download';
import Dropdown from '../../assets/icons/icons/Dropdown';
import Due from '../../assets/icons/icons/Due';
import Edit from '../../assets/icons/icons/Edit';
import FilterArrow from '../../assets/icons/icons/FilterArrow';
import FontSize from '../../assets/icons/icons/FontSize';
import GraphStatsCircle from '../../assets/icons/icons/GraphStatsCircle';
import IconspaceHospital from '../../assets/icons/icons/IconspaceHospital';
import LaptopHeart from '../../assets/icons/icons/LaptopHeart';
import LightModeNight from '../../assets/icons/icons/LightModeNight';
import Link from '../../assets/icons/icons/Link';
import Location from '../../assets/icons/icons/Location';
import LoveItSearch from '../../assets/icons/icons/LoveItSearch';
import MapMarker from '../../assets/icons/icons/MapMarker';
import MobilePhone2 from '../../assets/icons/icons/MobilePhone2';
import MultipleUsersAdd from '../../assets/icons/icons/MultipleUsersAdd';
import NavigationMenu2 from '../../assets/icons/icons/NavigationMenu2';
import NavigationNextAlternate from '../../assets/icons/icons/NavigationNextAlternate';
import NavigationPayment from '../../assets/icons/icons/NavigationPayment';
import Need from '../../assets/icons/icons/Need';
import NewWindow from '../../assets/icons/icons/NewWindow';
import Note from '../../assets/icons/icons/Note';
import NotesBookText from '../../assets/icons/icons/NotesBookText';
import Pagination from '../../assets/icons/icons/Pagination';
import PatientVisit from '../../assets/icons/icons/PatientVisit';
import Pencil2 from '../../assets/icons/icons/Pencil2';
import PictureStackHuman from '../../assets/icons/icons/PictureStackHuman';
import Pin from '../../assets/icons/icons/Pin';
import PlusButton from '../../assets/icons/icons/PlusButton';
import Search from '../../assets/icons/icons/Search';
import Shape from '../../assets/icons/icons/Shape';
import SidebarInfo from '../../assets/icons/icons/SidebarInfo';
import SingleManCircle from '../../assets/icons/icons/SingleManCircle';
import SingleNeutralActionsAddress from '../../assets/icons/icons/SingleNeutralActionsAddress';
import SynchronizeArrow1 from '../../assets/icons/icons/SynchronizeArrow1';
import TableDropdown from '../../assets/icons/icons/TableDropdown';
import Task from '../../assets/icons/icons/Task';
import TaskText from '../../assets/icons/icons/TaskText';
import TimeClockCircle1Alternate from '../../assets/icons/icons/TimeClockCircle1Alternate';
import ToolBox from '../../assets/icons/icons/ToolBox';
import UpButton from '../../assets/icons/icons/UpButton';
import UrgencyUrgent from '../../assets/icons/icons/UrgencyUrgent';
import UrgencyHigh from '../../assets/icons/icons/UrgencyHigh';
import UrgencyMedium from '../../assets/icons/icons/UrgencyMedium';
import UrgencyLow from '../../assets/icons/icons/UrgencyLow';
import Visit from '../../assets/icons/icons/Visit';
import Standing from '../../assets/icons/icons/Standing';
import Sitting from '../../assets/icons/icons/Sitting';
import Laying from '../../assets/icons/icons/Laying';
import Clipboard from '../../assets/icons/icons/Clipboard';
import Upload from '../../assets/icons/icons/Upload';
import Flex from 'src/components/Flex';

// See https://fontawesome.com/icons for icons
export interface IconProps extends React.HTMLAttributes<HTMLSpanElement> {
    name: string;
    type?: 'fa' | 'custom';
    className?: string;
    onClick?: (event: React.MouseEvent<HTMLElement>) => any;
    spin?: boolean;
    size?: number;
    color?: string;
}

function Icon({ name, className, color, type, size, spin, ...rest }: IconProps) {
    function getComponent() {
        const customProps: any = {};
        if (size) {
            customProps.width = size;
            customProps.height = size;
        }
        if (color) {
            customProps.color = color;
        }
        switch (name) {
            case 'Appointment':
                return <Appointment {...customProps} />;
            case 'AppWindowUser':
                return <AppWindowUser {...customProps} />;
            case 'Arrow':
                return <Arrow {...customProps} />;
            case 'ArrowSide':
                return <ArrowSide {...customProps} />;
            case 'Assign':
                return <Assign {...customProps} />;
            case 'Attachment':
                return <Attachment {...customProps} />;
            case 'AttachmentIcon':
                return <AttachmentIcon {...customProps} />;
            case 'Back':
                return <Back {...customProps} />;
            case 'Bell':
                return <Bell {...customProps} />;
            case 'Bin':
                return <Bin {...customProps} />;
            case 'Calendar':
                return <Calendar {...customProps} />;
            case 'CalendarCheck':
                return <CalendarCheck {...customProps} />;
            case 'Check':
                return <Check {...customProps} />;
            case 'Check1':
                return <Check1 {...customProps} />;
            case 'CheckCircle':
                return <CheckCircle {...customProps} />;
            case 'Close':
                return <Close {...customProps} />;
            case 'CloseCopy':
                return <CloseCopy {...customProps} />;
            case 'Cog3':
                return <Cog3 {...customProps} />;
            case 'Details':
                return <Details {...customProps} />;
            case 'DotMenu':
                return <DotMenu {...customProps} />;
            case 'Download':
                return <Download {...customProps} />;
            case 'Dropdown':
                return <Dropdown {...customProps} />;
            case 'Due':
                return <Due {...customProps} />;
            case 'Edit':
                return <Edit {...customProps} />;
            case 'FilterArrow':
                return <FilterArrow {...customProps} />;
            case 'FontSize':
                return <FontSize {...customProps} />;
            case 'GraphStatsCircle':
                return <GraphStatsCircle {...customProps} />;
            case 'IconspaceHospital':
                return <IconspaceHospital {...customProps} />;
            case 'LaptopHeart':
                return <LaptopHeart {...customProps} />;
            case 'LightModeNight':
                return <LightModeNight {...customProps} />;
            case 'Link':
                return <Link {...customProps} />;
            case 'Location':
                return <Location {...customProps} />;
            case 'LoveItSearch':
                return <LoveItSearch {...customProps} />;
            case 'MapMarker':
                return <MapMarker {...customProps} />;
            case 'MobilePhone2':
                return <MobilePhone2 {...customProps} />;
            case 'MultipleUsersAdd':
                return <MultipleUsersAdd {...customProps} />;
            case 'NavigationMenu2':
                return <NavigationMenu2 {...customProps} />;
            case 'NavigationNextAlternate':
                return <NavigationNextAlternate {...customProps} />;
            case 'NavigationPayment':
                return <NavigationPayment {...customProps} />;
            case 'Need':
                return <Need {...customProps} />;
            case 'NewWindow':
                return <NewWindow {...customProps} />;
            case 'Note':
                return <Note {...customProps} />;
            case 'NotesBookText':
                return <NotesBookText {...customProps} />;
            case 'Pagination':
                return <Pagination {...customProps} />;
            case 'PatientVisit':
                return <PatientVisit {...customProps} />;
            case 'Pencil2':
                return <Pencil2 {...customProps} />;
            case 'PictureStackHuman':
                return <PictureStackHuman {...customProps} />;
            case 'Pin':
                return <Pin {...customProps} />;
            case 'PlusButton':
                return <PlusButton {...customProps} />;
            case 'Search':
                return <Search {...customProps} />;
            case 'Shape':
                return <Shape {...customProps} />;
            case 'SidebarInfo':
                return <SidebarInfo {...customProps} />;
            case 'SingleManCircle':
                return <SingleManCircle {...customProps} />;
            case 'SingleNeutralActionsAddress':
                return <SingleNeutralActionsAddress {...customProps} />;
            case 'SynchronizeArrow1':
                return <SynchronizeArrow1 {...customProps} />;
            case 'TableDropdown':
                return <TableDropdown {...customProps} />;
            case 'Task':
                return <Task {...customProps} />;
            case 'TaskText':
                return <TaskText {...customProps} />;
            case 'TimeClockCircle1Alternate':
                return <TimeClockCircle1Alternate {...customProps} />;
            case 'ToolBox':
                return <ToolBox {...customProps} />;
            case 'UpButton':
                return <UpButton {...customProps} />;
            case 'UrgencyUrgent':
                return <UrgencyUrgent {...customProps} />;
            case 'UrgencyHigh':
                return <UrgencyHigh {...customProps} />;
            case 'UrgencyMedium':
                return <UrgencyMedium {...customProps} />;
            case 'UrgencyLow':
                return <UrgencyLow {...customProps} />;
            case 'Visit':
                return <Visit {...customProps} />;
            case 'Standing':
                return <Standing {...customProps} />;
            case 'Sitting':
                return <Sitting {...customProps} />;
            case 'Laying':
                return <Laying {...customProps} />;
            case 'Clipboard':
                return <Clipboard {...customProps} />;
            case 'Upload':
                return <Upload {...customProps} />;
            default:
                return <Visit {...customProps} />;
            // return null;
        }
    }

    if (type === 'fa') {
        return (
            <span
                {...rest}
                className={classNames(
                    `fas fa-${name}`,
                    styles.icon,
                    {
                        pointer: !!rest.onClick,
                        'fa-spin': spin,
                    },
                    className,
                )}
            />
        );
    } else {
        const { onClick } = rest;
        return (
            <Flex
                align="center"
                justify="center"
                className={classNames(styles.icon, className, onClick ? styles.pointerCursor : null)}
                {...rest}
            >
                {getComponent()}
            </Flex>
        );
    }
}

export default Icon;
