import React, { useState, useEffect, useContext } from 'react';
import classNames from 'classnames';

import NavBar from 'src/containers/NavBar';
import PageContent from 'src/components/PageContent';
import PageContainer from 'src/components/PageContainer';
import Card from 'src/components/Card';
import Flex from 'src/components/Flex';
import Button from 'src/components/Button';
import Icon from 'src/components/Icon';
import Table from 'src/components/Table';
import styles from './styles.module.scss';
import { observer } from 'mobx-react';
import SideModal from 'src/components/SideModal';
import TeamModal from 'src/components/TeamModal';
import { CellInfo } from 'react-table';
import TeamStore, { Team } from 'src/stores/TeamStore';
import * as teamService from 'src/api/teams';
import ModalFooter from 'src/components/ModalFooter';
import ModalHeader from 'src/components/ModalHeader';
import { ToastStoreObject } from 'src/stores/ToastStore';
import { ErrorStoreObject, ErrorTypes } from 'src/stores/ErrorStore';
import { parseError } from 'src/utils';
import FacilityDropDown from 'src/components/FacilityDropDown';
import { ICONS } from 'src/utils/constants';
import * as variables from 'src/styles/variables';
import CenterModal from 'src/components/CenterModal';
import { ModalStoreObject, ModalTypes } from 'src/stores/ModalStore';
import { ANALYTICS_NAMES } from 'src/utils/analytics';
import UserStore from 'src/stores/UserStore';

function Teams() {
    const teamStore = useContext(TeamStore);
    const userStore = useContext(UserStore);

    const columns = [
        {
            Header: () => <span>TEAM NAME</span>,
            accessor: 'teamName',
            minWidth: 150,
            Cell: (props: CellInfo) => <span>{props.value}</span>,
        },
        {
            Header: 'DESCRIPTION',
            accessor: 'teamDescription',
            minWidth: 250,
        },
        {
            Header: 'ACTIVE',
            accessor: 'actvInd',
            minWidth: 100,
            Cell: (props: CellInfo) => (
                <span className={classNames({ [styles.redText]: !props.value, [styles.greenText]: props.value })}>
                    {props.value ? 'ACTIVE' : 'INACTIVE'}
                </span>
            ),
        },
        {
            Header: 'ACTIONS',
            accessor: 'actvInd',
            minWidth: 300,
            style: { paddingVertical: 0, paddingHorizontal: 20 },
            Cell: (props: CellInfo) => (
                <Flex direction="row">
                    <Button
                        onClick={() => {
                            setModalType('edit');
                            setModalState(true);
                            setEditTeamState(props.original);
                        }}
                        isLocked={!userStore.userPermissions.canEdit.teams}
                        type="secondary"
                        leftIcon={<Icon name={ICONS.Pencil2} color={variables.red} />}
                        data-test-id={ANALYTICS_NAMES.Teams_Table_Edit}
                    />
                    <Button
                        type="secondary"
                        text="Users"
                        onClick={() => {
                            setUserModalData(props.original);
                            setUserModalState(true);
                        }}
                        className={styles.usersButton}
                        data-test-id={ANALYTICS_NAMES.Teams_Table_ViewUsers}
                    />
                    <Button
                        onClick={() => updateActiveStatus(props.original.actvInd, props.original.teamId)}
                        type={props.original.actvInd ? 'secondary' : 'primary'}
                        text={props.original.actvInd ? 'Deactivate' : 'Reactivate'}
                        className={styles.redOutline}
                        isLocked={!userStore.userPermissions.canEdit.teams}
                        data-test-id={
                            props.original.actvInd
                                ? ANALYTICS_NAMES.Teams_Table_Deactivate
                                : ANALYTICS_NAMES.Teams_Table_Reactivate
                        }
                    />
                </Flex>
            ),
        },
    ];

    const userColumns = [
        {
            Header: 'FIRST NAME',
            accessor: 'frstNm',
            minWidth: 150,
        },
        {
            Header: 'LAST NAME',
            accessor: 'lastNm',
            minWidth: 150,
        },
        {
            Header: 'ACTIVE',
            accessor: 'actvInd',
            minWidth: 100,
            Cell: (props: CellInfo) => (
                <span className={classNames({ [styles.redText]: !props.value, [styles.greenText]: props.value })}>
                    {props.value ? 'ACTIVE' : 'INACTIVE'}
                </span>
            ),
        },
    ];

    const [modalState, setModalState] = useState(false);
    const [userModalState, setUserModalState] = useState(false);
    const [userModalData, setUserModalData] = useState<Team>({});
    const [modalType, setModalType] = useState('create');
    const [teamModalData, setTeamModalData] = useState<Team>({});
    const [editTeamState, setEditTeamState] = useState<Team>({});
    const [facilityId, setFacilityId] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    async function getTeams() {
        try {
            setIsLoading(true);
            await teamStore.getTeams({ filters: { facId: facilityId } }, true);
            setIsLoading(false);
        } catch (e) {
            ErrorStoreObject.setError(ErrorTypes.Loading);
        }
    }

    useEffect(() => {
        if (facilityId) {
            getTeams();
        }
    }, [facilityId]);

    const handleTeamSubmit = async () => {
        try {
            setIsLoading(true);

            if (modalType === 'create') {
                await teamService.createTeam(teamModalData);
            } else if (modalType === 'edit') {
                await teamService.updateTeam(teamModalData.teamId, teamModalData);
            }

            setModalState(false);
            getTeams();
        } catch (e) {
            ToastStoreObject.show(parseError(e));
        } finally {
            setIsLoading(false);
        }
    };

    const updateActiveStatus = async (isActive: boolean, teamId: number) => {
        try {
            if (isActive) {
                handleConfirm(teamId);
            } else {
                await teamService.reactivateTeam(teamId);
                getTeams();
            }
        } catch (e) {
            ToastStoreObject.show(parseError(e));
        }
    };

    async function handleDeactivate(teamId: number) {
        await teamService.deactivateTeam(teamId);
        getTeams();
        ModalStoreObject.hideModal();
    }

    function handleConfirm(teamId: number) {
        ModalStoreObject.showModal(ModalTypes.ConfirmationModal, {
            title: 'Are you sure you would like to deactivate this Team?',
            onConfirm: () => handleDeactivate(teamId),
            onCancel: () => ModalStoreObject.hideModal(),
            confirmButtonText: 'Deactivate',
        });
    }

    return (
        <PageContainer>
            <NavBar />
            <PageContent>
                <Flex self="stretch" align="center" justify="start" className={styles.headerWrap}>
                    <Flex value={1} className={styles.filterWrap}>
                        <FacilityDropDown
                            onChange={(filters: any) => setFacilityId(filters.value)}
                            placeholder="Filter by Facility"
                        />
                    </Flex>
                </Flex>
                <Flex self="stretch" align="center" justify="start">
                    <Flex value={1} className={styles.pageTitle}>
                        TEAMS
                    </Flex>
                    <Button
                        leftIcon={<Icon className={styles.plusIcon} name={ICONS.PlusButton} />}
                        type="primary"
                        onClick={() => {
                            setModalType('create');
                            setModalState(true);
                        }}
                        isLocked={!userStore.userPermissions.canEdit.teams}
                        text="New Team"
                        data-test-id={ANALYTICS_NAMES.Teams_Create}
                    />
                </Flex>
                <Card className={styles.cardWrap}>
                    <Table isLoading={isLoading} columns={columns} data={teamStore.teams} />
                </Card>
            </PageContent>
            <SideModal isOpen={modalState} onClose={() => setModalState(false)}>
                <ModalHeader
                    title={
                        modalType === 'create'
                            ? 'New Team'
                            : `Edit ${(editTeamState && editTeamState.teamName) || 'Team'}`
                    }
                    onClose={() => setModalState(false)}
                />
                <TeamModal
                    facilityId={facilityId}
                    team={modalType === 'edit' ? editTeamState : {}}
                    onChange={(data: object) => setTeamModalData(data)}
                />
                <ModalFooter
                    isLoading={isLoading}
                    primaryText={modalType === 'create' ? 'Create' : 'Save'}
                    primaryClick={handleTeamSubmit}
                    secondaryClick={() => setModalState(false)}
                />
            </SideModal>
            <CenterModal isOpen={userModalState} onClose={() => setUserModalState(false)}>
                <ModalHeader title="Users" onClose={() => setUserModalState(false)} />
                <div className={styles.usersTable}>
                    <Table
                        columns={userColumns}
                        data={userModalData.users && userModalData.users.filter((user: any) => user !== null)}
                    />
                </div>
                <ModalFooter primaryText="OK" showSecondary={false} primaryClick={() => setUserModalState(false)} />
            </CenterModal>
        </PageContainer>
    );
}
export default observer(Teams);
