import { UseQueryResult, useQuery } from 'react-query';
import { getFormFacilityMappings } from 'src/api/formFacilityMappings';
import { UserStoreObject } from 'src/stores/UserStore';
import { FormFacilityMapping } from 'common/lib/entity/framework/FormFacilityMapping';

export const formFacilityMappingsKey = 'formFacilityMappings';

export const useFormFacilityMappings = (): UseQueryResult<FormFacilityMapping[]> => {
    return useQuery({
        queryKey: [formFacilityMappingsKey],
        queryFn: async () => {
            return await getFormFacilityMappings();
        },
        enabled: UserStoreObject.isGraphiumAdmin(),
        initialData: [],
    });
};
