import React, { useState } from 'react';
import PageContent from 'src/components/PageContent';
import NavBar from 'src/containers/NavBar';
import PageContainer from 'src/components/PageContainer';
import TabNav from 'src/components/TabNav';
import styles from './styles.module.scss';
import Flex from 'src/components/Flex';
import { useHistory, useParams } from 'react-router-dom';
import { ROUTES } from 'src/utils/constants';
import FormDefinition from './FormDefinition';
import GeneratedFormDefinition from './GeneratedFormDefinition';
import { useFormCatalog } from 'src/queries/useFormCatalog';
import Pages from './Pages';
import Versions from './Versions';
import FacilityMappings from './FacilityMappings';
import { LintMessage } from 'common/lib/util/formDefinition/linter/LintMessage';
import LoadingIcon from 'src/components/LoadingIcon';

const FORM_DETAIL_TABS = ['Form Definition', 'Generated Form Definition', 'Form Errors / Warnings', 'Pages', 'Versions', 'Facility Mappings'];

function FormDetail() {
    const history = useHistory();
    const { formCatalogId } = useParams<any>();
    const { data: formCatalog, isFetching } = useFormCatalog(formCatalogId);
    const [selectedTab, setSelectedTab] = useState('Form Definition');

    function getContent() {
        switch (selectedTab) {
            case 'Form Definition':
            default:
                return <FormDefinition />;
            case 'Generated Form Definition':
                return <GeneratedFormDefinition />;
            case 'Form Errors / Warnings':
                return formCatalog.linterResults.messages.map(
                    (lintMessage: LintMessage) => {
                        return (
                            <div className={styles.linterResult}>
                                <span className={styles.lintSeverity}>({lintMessage.severity})</span>
                                <span className={styles.lintRule}>{lintMessage.ruleId}</span>
                                <span className={styles.lintMessage}>{lintMessage.message}</span>
                                <span className={styles.lintPosition}>
                                    (Line: {lintMessage.line}, Col: {lintMessage.column})
                                </span>
                            </div>
                        );
                    },
                );
            case 'Pages':
                return <Pages />;
            case 'Versions':
                return <Versions />;
            case 'Facility Mappings':
                return <FacilityMappings />;
        }
    }

    function handleBack() {
        history.push(ROUTES.getString(ROUTES.FormDevelopment));
    }

    function getFormTitleHeader(formCatalog: any) {
        return formCatalog ? `${formCatalog.formTitle} (${formCatalog.formName})` : null;
    }

    return (
        <PageContainer>
            <NavBar />
            <PageContent>
                {isFetching && !formCatalog?.formCatalogId ? (
                    <div className={styles.loading}>
                        <LoadingIcon />
                    </div>
                ) : (
                    <>
                        <Flex align="center" className={styles.detailHeader}>
                            <div className={styles.backWrap} onClick={() => handleBack()}>
                                <span className={styles.backArrow}>{`< `}</span>
                            </div>
                            <h1 className={styles.formCatalogName}>{formCatalog?.formName || ''}</h1>
                        </Flex>
                        <div className={styles.tabContainer}>
                            <TabNav
                                tabs={FORM_DETAIL_TABS}
                                onTabClick={(tab: string) => {
                                    setSelectedTab(tab);
                                }}
                            />
                        </div>
                        {getContent()}
                    </>
                )}
            </PageContent>
        </PageContainer>
    );
}

export default FormDetail;
