import { UseQueryResult, useQuery } from 'react-query';
import { getFormCatalogById } from 'src/api/formCatalogs';
import { UserStoreObject } from 'src/stores/UserStore';
import { FormCatalog } from 'common/lib/entity/framework/FormCatalog';

export const formCatalogKey = 'formCatalog';

export const useFormCatalog = (formCatalogId: FormCatalog['formCatalogId']): UseQueryResult<FormCatalog> => {
    return useQuery({
        queryKey: [formCatalogKey, { formCatalogId }],
        queryFn: async () => {
            return await getFormCatalogById(formCatalogId);
        },
        enabled: formCatalogId && UserStoreObject.isGraphiumAdmin(),
        initialData: [],
    });
};
