import { UseMutationResult, useMutation, useQueryClient } from 'react-query';
import { importFormCatalog } from 'src/api/formCatalogs';
import { FormCatalog } from 'common/lib/entity/framework/FormCatalog';
import { formCatalogsKey } from './useFormCatalogs';
import { formCatalogKey } from './useFormCatalog';
import { FormDefinition } from 'common/lib/entity/org/FormDefinition';

export const importFormCatalogKey = 'importFormCatalog';

export interface UseImportFormCatalogInputVariables {
    formName: FormCatalog['formName'];
    formTitle: FormCatalog['formTitle'];
    formModelId: FormCatalog['formModelId'];
    orgNameInternal: string;
    facilityId: FormDefinition['facId'];
    formDefinitionId: FormDefinition['formDefnId'];
}

export const useImportFormCatalog = (): UseMutationResult<
    FormCatalog['formCatalogId'],
    Error,
    UseImportFormCatalogInputVariables,
    unknown
> => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationKey: [importFormCatalogKey],
        mutationFn: async (variables) => {
            const { formName, formTitle, formModelId, orgNameInternal, facilityId, formDefinitionId } = variables;

            if (!formName) {
                throw new Error('Form name is required');
            }

            if (!formTitle) {
                throw new Error('Form title is required');
            }

            if (!formModelId) {
                throw new Error('Form model id is required');
            }

            if (!orgNameInternal) {
                throw new Error('Org name internal is required');
            }

            if (!facilityId) {
                throw new Error('Facility id is required');
            }

            if (!formDefinitionId) {
                throw new Error('Form definition id is required');
            }

            return await importFormCatalog({
                formName,
                formTitle,
                formModelId,
                orgNameInternal,
                facilityId,
                formDefinitionId,
            });
        },
        retry: false,
        onSuccess: async (data) => {
            await queryClient.invalidateQueries({ queryKey: [formCatalogsKey] });
            await queryClient.invalidateQueries({ queryKey: [formCatalogKey] });

            return data;
        },
    });
};
