import React from 'react';
import styles from './styles.module.scss';
import Flex from 'src/components/Flex';
import { observer } from 'mobx-react';
import PageContainer from 'src/components/PageContainer';
import NavBar from '../NavBar';
import PageContent from 'src/components/PageContent';

function SurveyResults() {

    return (
        <PageContainer>
            <NavBar />
            <PageContent>
                <Flex self="stretch" align="center" justify="start">
                    <Flex value={1} className={styles.pageTitle}>
                        Survey Results
                    </Flex>
                </Flex>
            </PageContent>
        </PageContainer>
    );
}

export default observer(SurveyResults);