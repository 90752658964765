import React, { useState, useEffect, useContext } from 'react';
import { observer } from 'mobx-react';
import { parse } from 'query-string';
import * as yup from 'yup';
import { useHistory, useLocation } from 'react-router';
import AuthStore from 'src/stores/AuthStore';
import UserStore from 'src/stores/UserStore';
import PageContainer from 'src/components/PageContainer';
import Flex from 'src/components/Flex';
import Button from 'src/components/Button';
import * as InvitationsAPI from 'src/api/invitations';
import Checkbox from 'src/components/Checkbox';
import styles from './styles.module.scss';
import Input from 'src/components/Input';
import OptionDropDown, { OptionTypes } from 'src/components/OptionDropDown';
import { ToastStoreObject } from 'src/stores/ToastStore';
import { parseError } from 'src/utils';
import * as validation from 'src/utils/validation';
import useYup from 'src/utils/hooks/useYup';
import { ROUTES } from 'src/utils/constants';
import LoadingCover from 'src/components/LoadingCover';
import { ANALYTICS_NAMES } from 'src/utils/analytics';
import OrgStore from 'src/stores/OrgStore';
import { getBrandingString } from 'src/components/FeatureFlag';

const createUserValidationScheme = yup.object().shape({
    username: validation.usernameValidationYup,
    firstName: yup.string().required('First name is required'),
    lastName: yup.string().required('Last name is required'),
    password: validation.passwordValidationYup,
    confirmPassword: yup
        .string()
        .oneOf([yup.ref('password'), null], "Passwords don't match")
        .required('Confirm Password is required'),
});

function InviteAccept() {
    const authStore = useContext(AuthStore);
    const userStore = useContext(UserStore);
    const orgStore = useContext(OrgStore);
    const history = useHistory();
    const location = useLocation();

    const queryStrings = parse(location.search);
    const [createUser, setCreateUser] = useState({
        username: '',
        firstName: '',
        lastName: '',
        password: '',
        confirmPassword: '',
    });
    const [type, setType] = useState('create');
    const [orgName, setOrgName] = useState('');
    const [existingAccount, setExistingAccount] = useState(false);
    const [activeInvite, setActiveInvite] = useState<'loading' | 'invalid' | 'valid'>('loading');
    const [showPasswordValue, setShowPasswordValue] = useState(false);

    const isCreateNewUser = type === 'create' && !authStore.isAuthenticated && !existingAccount;
    const inviteKey = queryStrings.inviteKey;

    const { errors: createUserErrors, isValid: isValidCreateUser } = useYup(createUser, createUserValidationScheme, {
        validateOnChange: true,
    });

    useEffect(() => {
        async function getOrgName() {
            try {
                const orgResult = await InvitationsAPI.getOrgNameFromInvite(inviteKey as string);
                if (!orgResult.data) {
                    setActiveInvite('invalid');
                } else {
                    setOrgName(orgResult.data.name);
                    if (orgResult.data.hasExistingAccount) {
                        setExistingAccount(true);
                        setType('login');
                    }
                    setActiveInvite('valid');
                }
            } catch (error) {
                setActiveInvite('invalid');
            }
        }
        getOrgName();
    }, []);

    async function acceptInviteNewUser() {
        try {
            const data = createUser;
            await InvitationsAPI.acceptInviteNewUser(inviteKey as string, data);
            // Send new user to the login page after their invitation is accepted
            history.replace(ROUTES.getString(ROUTES.Login));
        } catch (e) {
            ToastStoreObject.show(parseError(e));
        }
    }

    async function acceptInviteExistingUser() {
        try {
            if (authStore.isAuthenticated) {
                if (!userStore.indexUsrId) {
                    await userStore.getMyProfileUser();
                }
                await InvitationsAPI.acceptInviteExistingUser(inviteKey as string, userStore.indexUsrId);
                await orgStore.getOrgsForUser(true);
                history.replace(ROUTES.getString(ROUTES.Home));
            } else {
                history.push(ROUTES.getString(ROUTES.Login), { from: location });
            }
        } catch (e) {
            ToastStoreObject.show(parseError(e));
        }
    }

    const updateInput = (param: string) => (t: string) => {
        if (param === 'username') {
            setCreateUser({ ...createUser, [param]: t.replace(/\s/g, '') });
        } else {
            setCreateUser({ ...createUser, [param]: t });
        }
    };

    return (
        <PageContainer allowScaling={true}>
            <div className={styles.header}></div>
            <Flex
                direction="column"
                self="stretch"
                value={1}
                align="center"
                justify="center"
                className={styles.loginWrap}
            >
                <div>
                    {activeInvite === 'loading' ? (
                        <div style={{ position: 'relative' }}>
                            <LoadingCover />
                        </div>
                    ) : activeInvite === 'invalid' ? (
                        <>
                            <div className={styles.heading}>Invite Not Valid</div>
                            <div className={styles.subHeading}>
                                This invitation has either expired, been deleted, or is not a valid invite key. Please
                                contact your administrator for assistance.
                            </div>
                        </>
                    ) : activeInvite === 'valid' ? (
                        <>
                            <div className={styles.heading}>New Invitation</div>
                            <div className={styles.subHeading}>
                                You have been invited to join {orgName}.{' '}
                                {authStore.isAuthenticated
                                    ? `You are currently logged in as ${userStore.usrNm}. Accept this invite as ${userStore.usrNm} or logout`
                                    : existingAccount
                                    ? `The invitation email address already has an account. Please login below.`
                                    : `Please select if you already have a ${getBrandingString()} account, or if you need to create a new one.`}
                            </div>
                            {!authStore.isAuthenticated && !existingAccount && (
                                <OptionDropDown
                                    selectedValue={type}
                                    type={OptionTypes.AccountOptions}
                                    onChange={(option: any) => setType(option.value)}
                                />
                            )}
                            {isCreateNewUser ? (
                                <>
                                    <Input
                                        className={styles.input}
                                        label="Username"
                                        value={createUser.username}
                                        onChangeText={updateInput('username')}
                                        errorMessage={createUserErrors.username}
                                    />
                                    <div className={styles.passwordExplanation}>
                                        Username maximum 15 characters, letters and numbers only. No symbols other than
                                        underscore '_'
                                    </div>
                                    <Input
                                        className={styles.input}
                                        label="First Name"
                                        value={createUser.firstName}
                                        onChangeText={updateInput('firstName')}
                                        errorMessage={createUserErrors.firstName}
                                    />
                                    <Input
                                        className={styles.input}
                                        label="Last Name"
                                        value={createUser.lastName}
                                        onChangeText={updateInput('lastName')}
                                        errorMessage={createUserErrors.lastName}
                                    />
                                    <Input
                                        className={styles.input}
                                        label="Password"
                                        value={createUser.password}
                                        type={showPasswordValue ? 'text' : 'password'}
                                        onChangeText={updateInput('password')}
                                        errorMessage={createUserErrors.password}
                                    />
                                    <div className={styles.passwordExplanation}>
                                        Password must contain atleast 10 characters, 1 number, 1 upper case letter, 1
                                        lower case letter, and a symbol.
                                    </div>
                                    <Input
                                        className={styles.input}
                                        label="Confirm Password"
                                        value={createUser.confirmPassword}
                                        type={showPasswordValue ? 'text' : 'password'}
                                        onChangeText={updateInput('confirmPassword')}
                                        errorMessage={createUserErrors.confirmPassword}
                                    />
                                    <div className={styles.showPassword}>
                                        <Checkbox
                                            checked={showPasswordValue}
                                            onChange={() => setShowPasswordValue(!showPasswordValue)}
                                            label="Show password?"
                                        />
                                    </div>
                                </>
                            ) : null}
                            <Flex self="stretch">
                                <Flex value={1}>
                                    <Button
                                        disabled={isCreateNewUser && !isValidCreateUser}
                                        onClick={() => {
                                            if (isCreateNewUser) {
                                                acceptInviteNewUser();
                                            } else {
                                                acceptInviteExistingUser();
                                            }
                                        }}
                                        type="primary"
                                        text={
                                            isCreateNewUser
                                                ? 'Create Account'
                                                : authStore.isAuthenticated
                                                ? `Accept Invite as ${userStore.usrNm}`
                                                : 'Login to Accept Invite'
                                        }
                                        className={styles.submitButton}
                                        data-test-id={
                                            type === 'create'
                                                ? ANALYTICS_NAMES.InviteAccept_Create
                                                : ANALYTICS_NAMES.InviteAccept_Login
                                        }
                                    />
                                    {authStore.isAuthenticated ? (
                                        <Button
                                            onClick={() => authStore.logout()}
                                            type="transparent"
                                            text="Logout"
                                            className={styles.logoutBtn}
                                        />
                                    ) : null}
                                </Flex>
                            </Flex>
                        </>
                    ) : null}
                </div>
            </Flex>
        </PageContainer>
    );
}

export default observer(InviteAccept);
