import React, { useContext, useState } from 'react';
import { format } from 'date-fns';
import Flex from 'src/components/Flex';
import EventStore from 'src/stores/EventStore';
import Input, { Label } from 'src/components/Input';
import DatePicker from 'src/components/DatePicker';
import TimePicker from 'src/components/TimePicker';
import PatientDropDown from 'src/components/PatientDropDown';
import { Patient } from 'src/stores/PatientStore';
import ModalHeader from 'src/components/ModalHeader';
import ModalFooter from 'src/components/ModalFooter';
import * as utils from 'src/utils';
import { ViewProps, EventDetailViews } from '../index';
import styles from '../styles.module.scss';
import EventAssigneeDropDown from 'src/components/EventAssigneeDropDown';
import OptionDropDown, { OptionTypes } from 'src/components/OptionDropDown';
import { API_DATE_FORMAT } from 'src/utils/constants';
import { ToastStoreObject } from 'src/stores/ToastStore';

export default function EditView(props: ViewProps) {
    const { event } = props;
    const [isLoading, setIsLoading] = useState(false);
    const isTask = event.eventClassCode === 'TASK';
    const [state, setState] = useState({
        eventName: event.eventName,
        eventDescription: event.eventDescription,
        eventTypeCode: event.eventTypeCode,
        eventLocation: event.eventLocation,
        eventStartDate: event.eventStartDate,
        eventStartTime: event.eventStartTime,
        eventEndDate: event.eventEndDate,
        eventEndTime: event.eventEndTime,
        assigneeTeamId: event.assigneeTeamId,
        assigneeUsrId: event.assigneeUsrId,
        patientId: event.patientId,
        facId: event.facId,
    });
    const eventStore = useContext(EventStore);
    async function save() {
        try {
            if (!state.eventName) {
                ToastStoreObject.show('Event name is required');
                return;
            }
            setIsLoading(true);
            await eventStore.updateEvent(event.eventId, state);
            setIsLoading(false);
            props.onChangeComplete && props.onChangeComplete();
            props.onClose();
        } catch (error) {
            ToastStoreObject.show(utils.parseError(error));
            setIsLoading(false);
        }
    }
    return (
        <div className={styles.content}>
            <ModalHeader
                title={`Edit ${isTask ? 'Task' : 'Appointment'} (${event.eventName})${
                    props.patient ? ` for ${utils.getPatientName(props.patient)}` : ''
                }`}
                onClose={props.onClose}
            />
            <div className={styles.mainContent}>
                {/* <div>
                    <Label text="Facility" required={true} />
                    <FacilityDropDown
                        selectedValue={state.facId}
                        onChange={(s: any) => setState({ ...state, facId: s.value })}
                    />
                </div> */}
                <div>
                    <Label text="Assignee User or Team" required={true} />
                    <EventAssigneeDropDown
                        facId={state.facId}
                        selectedAssigneeType={state.assigneeTeamId ? 'team' : 'user'}
                        selectedValue={state.assigneeUsrId || state.assigneeTeamId}
                        onChange={(s: any, assigneeType: 'team' | 'user') =>
                            setState({
                                ...state,
                                [assigneeType === 'team' ? 'assigneeTeamId' : 'assigneeUsrId']: s.value,
                                [assigneeType !== 'team' ? 'assigneeTeamId' : 'assigneeUsrId']: null,
                            })
                        }
                    />
                </div>
                <Input
                    className={styles.input}
                    label="Event Name"
                    required={true}
                    value={state.eventName}
                    onChangeText={(t: string) => setState({ ...state, eventName: t })}
                />
                <Input
                    className={styles.input}
                    label="Event Description"
                    value={state.eventDescription}
                    onChangeText={(t: string) => setState({ ...state, eventDescription: t })}
                />

                <div>
                    <Label text="Type" required={true} />
                    <OptionDropDown
                        facId={state.facId}
                        selectedValue={state.eventTypeCode}
                        type={isTask ? OptionTypes.TaskTypes : OptionTypes.AppointmentTypes}
                        onChange={(data: any) => setState({ ...state, eventTypeCode: data.value })}
                    />
                </div>
                {/* If we are on the patient details screen, don't show the patient as an editable field */}
                {props.patient ? null : (
                    <div>
                        <Label text="Patient" required={!isTask} />
                        <PatientDropDown
                            facId={state.facId}
                            selectedValue={state.patientId}
                            onChange={(patient: Patient) =>
                                setState({ ...state, patientId: patient ? patient.patientId : undefined })
                            }
                        />
                    </div>
                )}
                {!isTask ? (
                    <Input
                        className={styles.input}
                        label="Event Location"
                        value={state.eventLocation}
                        onChangeText={(t: string) => setState({ ...state, eventLocation: t })}
                    />
                ) : null}
                <div>
                    <Label text="Time/Date" />
                    <DatePicker
                        value={state.eventStartDate}
                        onChange={(date) => {
                            if (date) {
                                const value = format(new Date(date), API_DATE_FORMAT);
                                setState({ ...state, eventStartDate: value, eventEndDate: value });
                            }
                        }}
                    />
                    <Flex direction="row" align="center">
                        <Flex value={1}>
                            <TimePicker
                                onChange={(t: string) => setState({ ...state, eventStartTime: t })}
                                time={state.eventStartTime}
                            />
                        </Flex>
                        <div className={styles.dash}>{' - '}</div>
                        <Flex value={1}>
                            <TimePicker
                                onChange={(t: string) => setState({ ...state, eventEndTime: t })}
                                time={state.eventEndTime}
                            />
                        </Flex>
                    </Flex>
                </div>
            </div>
            <ModalFooter
                secondaryText="Go Back"
                primaryText="Done"
                secondaryClick={() => props.onChangeView(EventDetailViews.EVENT)}
                primaryClick={save}
                isLoading={isLoading}
            />
        </div>
    );
}
