import { createContext } from 'react';
import { observable, action, makeObservable } from 'mobx';
import * as deviceAPI from 'src/api/devices';

export interface Device {
    deviceId?: number;
    facilityId?: number;
    serialNumber: string;
    nameOrId: string;
    deviceTypeCode: string[];
    manufacturer: string;
    modelNumber: string;
    purchaseDate: string;
    active?: boolean;
    insDttm?: Date;
    updDttm?: Date;
    audVer?: number;
}

class DeviceStore {
    @observable devices: Device[] = [];

    constructor() {
        makeObservable(this);
    }

    @action
    async getDevices() {
        const results = await deviceAPI.getDevices();
        this.devices = results.data;
    }

    @action
    async createDevice(device: Device) {
        const result = await deviceAPI.createDevice(device);
        this.devices = [...this.devices, result.data];
    }

    @action
    async updateDevice(device: Device) {
        const result = await deviceAPI.updateDevice(device);
    }

    @action
    async toggleActive(device: Device) {
        const updatedDevice = { ...device, active: !device.active };
        const result = await deviceAPI.updateDevice(updatedDevice);
    }
}

export const DeviceStoreObject = new DeviceStore();

export default createContext(DeviceStoreObject);
