import React, { useContext, useState, useEffect } from 'react';
import styles from './styles.module.scss';
import { observer } from 'mobx-react';
import Flex from 'src/components/Flex';
import OrgStore from 'src/stores/OrgStore';
import Button from 'src/components/Button';
import { ToastStoreObject } from 'src/stores/ToastStore';
import * as utils from 'src/utils';
import NemsisStore from 'src/stores/NemsisStore';
import FacilitySelectorModal from 'src/components/FacilitySelectorModal';
import FacilityModal from 'src/components/FacilityModal';
import FacilityDropDown from 'src/components/FacilityDropDown';
import Input from 'src/components/Input';

function NemsisSubmission() {
    const nemsisStore = useContext(NemsisStore);
    const [isDemBtnDisabled, setIsDemBtnDisabled] = useState(true);
    const [facId, setFacId] = useState(1);
    const [formId, setFormId] = useState(null);

    useEffect(() => {
        if (nemsisStore.demValidationData) setIsDemBtnDisabled(nemsisStore.demValidationData.length > 0);
    }, [nemsisStore.demValidationData]);

    async function submitDemData() {
        try {
            await nemsisStore.submitDemData();
        } catch (e) {
            ToastStoreObject.show('Error Submitting Demographic data to NEMSIS.');
        }
    }

    async function submitUpdatedEmsData() {
        try {
            await nemsisStore.submitUpdatedEmsData(facId);
        } catch (e) {
            ToastStoreObject.show('Error Submitting Updated EMS data to NEMSIS.');
        }
    }

    async function submitSingleEmsData() {
        try {
            await nemsisStore.submitSingleEmsData(formId);
        } catch (e) {
            ToastStoreObject.show('Error Submitting Single EMS data to NEMSIS.');
        }
    }

    return (
        <div className={styles.tabContent}>
            <Flex direction="row">
                <Flex value={1} className={styles.pageTitle}>
                    NEMSIS SUBMISSION
                </Flex>
            </Flex>
            <Flex direction="row" className={styles.form}>
                <Flex value={1}>
                    <Button type="primary" text="Submit Demographic Data" onClick={submitDemData} />
                </Flex>
            </Flex>
            <Flex direction="row" className={styles.form}>
                <Flex value={0.1}>
                    <FacilityDropDown
                        onChange={(s: any) => {
                            setFacId(s.value);
                        }}
                        selectedValue={facId}
                    />
                </Flex>
                <Flex value={0.05} />

                <Flex value={1}>
                    <Button type="primary" text="Submit Updated EMS Data" onClick={submitUpdatedEmsData} />
                </Flex>
            </Flex>
            <Flex direction="row" className={styles.form}>
                <Flex value={0.1}>
                    <Input onChangeText={(t: string) => setFormId(t)} />
                </Flex>
                <Flex value={0.05} />

                <Flex value={1}>
                    <Button type="primary" text="Submit EMS Data For Form Id" onClick={submitSingleEmsData} />
                </Flex>
            </Flex>
        </div>
    );
}

export default observer(NemsisSubmission);
