import { FacilityStatus, FacilityType } from 'common/lib/model/facility/FacilitySubscriptionData';
import { observer } from 'mobx-react';
import React, { useContext, useState } from 'react';
import { CellInfo } from 'react-table';
import Button from 'src/components/Button';
import Card from 'src/components/Card';
import Flex from 'src/components/Flex';
import StatusBadge, { StatusBadgeColors } from 'src/components/StatusBadge';
import Table from 'src/components/Table';
import CustomerStore from 'src/stores/CustomerStore';
import { ModalStoreObject, ModalTypes } from 'src/stores/ModalStore';
import styles from '../../styles.module.scss';

export function getFacilityTypeLabel(type: FacilityType) {
    switch (type) {
        case FacilityType.AscEye:
            return 'ASC - Eye';
        case FacilityType.AscGeneral:
            return 'ASC - General';
        case FacilityType.AscGi:
            return 'ASC - GI';
        case FacilityType.DentalOffice:
            return 'Dental Office';
        case FacilityType.Hospital:
            return 'Hospital';
        case FacilityType.PainClinic:
            return 'Pain Clinic';
    }
}

function OrgsAndFacilities() {
    const customerStore = useContext(CustomerStore);
    const [isLoading, setIsLoading] = useState(false);

    async function reloadOrgs() {
        setIsLoading(true);
        await customerStore.getCustomerOrgs(customerStore.customer.customerId, true);
        setIsLoading(false);
    }

    function getStatus(status: FacilityStatus) {
        switch (status) {
            case FacilityStatus.Active:
                return StatusBadgeColors.Green;
            case FacilityStatus.Offboarded:
                return StatusBadgeColors.Red;
            case FacilityStatus.Offboarding:
                return StatusBadgeColors.Yellow;
            case FacilityStatus.Onboarding:
                return StatusBadgeColors.Grey;
        }
    }

    const columns = [
        {
            Header: 'Facility',
            accessor: 'facNm',
        },
        {
            Header: 'Type',
            accessor: 'subscriptionData.facilityType',
            Cell: (props: CellInfo) => {
                return <span>{getFacilityTypeLabel(props.value)}</span>;
            },
        },
        {
            Header: 'Order Date',
            accessor: 'subscriptionData.orderDate',
        },
        {
            Header: 'Status',
            accessor: 'subscriptionData.facilityStatus',
            minWidth: 50,
            Cell: (props: CellInfo) => {
                const isTestFacility = props.original.testFacInd;
                return isTestFacility ? (
                    <StatusBadge text="TEST" status={StatusBadgeColors.Yellow} />
                ) : (
                    <StatusBadge text={props.value} status={getStatus(props.value)} />
                );
            },
        },
        {
            Header: 'Primary Use Case',
            accessor: 'subscriptionData.primaryUse',
        },
        {
            Header: 'MACRA (Data Entry)',
            accessor: 'subscriptionData.macraFormDataEntryServicer',
        },
        {
            Header: 'Actions',
            accessor: 'actions',
            minWidth: 50,
            Cell: (props: CellInfo) => (
                <Flex>
                    <Button
                        type="small"
                        text="Edit"
                        onClick={() => {
                            ModalStoreObject.showModal(ModalTypes.FacilitySubscriptionDataModal, {
                                mode: 'edit',
                                facility: props.original,
                                onClose: () => ModalStoreObject.hideModal(),
                                onSave: () => customerStore.getCustomerOrgs(customerStore?.customer?.customerId, true),
                            });
                        }}
                    />
                </Flex>
            ),
        },
    ];

    return (
        <>
            <Flex justify="end">
                <Button
                    type="secondary"
                    text="Add Org"
                    disabled={isLoading}
                    onClick={() =>
                        ModalStoreObject.showModal(ModalTypes.CreateOrganizationModal, {
                            customerId: customerStore.customer.customerId,
                            onClose: () => {
                                setIsLoading(true);
                                ModalStoreObject.hideModal();
                            },
                            onSave: reloadOrgs,
                        })
                    }
                />
            </Flex>
            {customerStore.customerOrgs.map((org: any, index) => {
                return (
                    <Card className={styles.cardWrap} key={index}>
                        <div className={styles.tableTitle}>{org.orgNm}</div>
                        <Table columns={columns} isLoading={isLoading} data={org.facilities} />
                    </Card>
                );
            })}
        </>
    );
}

export default observer(OrgsAndFacilities);
