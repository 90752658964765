import React, { useState, useContext, useEffect } from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames';
import Flex from 'src/components/Flex/index';
import { observer } from 'mobx-react';
import { CellInfo } from 'react-table';
import Button from 'src/components/Button';
import EventStore from 'src/stores/EventStore';
import Icon from 'src/components/Icon';
import DateComponent from 'src/components/DateComponent';
import DetailsButton from 'src/components/DetailsButton';
import { ICONS } from 'src/utils/constants';
import Table from 'src/components/Table';
import { toJS } from 'mobx';
import EventDetailsModal from 'src/components/EventDetailsModal';
import { ModalStoreObject, ModalTypes } from 'src/stores/ModalStore';
import { Patient } from 'src/stores/PatientStore';
import { ANALYTICS_NAMES } from 'src/utils/analytics';
import UserStore from 'src/stores/UserStore';
import { getPatientName } from 'src/utils';

export interface AppointmentProps {
    patientId: Patient['patientId'];
    patient: Patient;
}

export function StyledCell(props: CellInfo) {
    return (
        <span
            className={classNames({
                [styles.rowCompleted]: props.original.eventState.eventStateCode === 'COMPLETED',
                [styles.rowOpen]: props.original.eventState.eventStateCode === 'OPEN',
                [styles.rowRescheduled]: props.original.eventState.eventStateCode === 'RESCHEDULED',
                [styles.rowCanceled]: props.original.eventState.eventStateCode === 'CANCELED',
            })}
        >
            {props.value}
        </span>
    );
}

function Appointments(props: AppointmentProps) {
    const [isLoading, setIsLoading] = useState(false);
    const [patientEvents, setPatientEvents] = useState([]);
    const [eventModalOpen, setEventModalOpen] = useState(false);
    const [activeEvent, setActiveEvent] = useState(null);
    const eventStore = useContext(EventStore);
    const userStore = useContext(UserStore);
    const columns = [
        {
            Header: 'APPOINTMENT NAME',
            accessor: 'eventName',
            minWidth: 150,
            Cell: StyledCell,
        },
        {
            Header: 'DESCRIPTION',
            accessor: 'eventDescription',
            minWidth: 150,
            Cell: StyledCell,
        },
        {
            Header: 'STATUS',
            accessor: 'eventState.eventStateTitle',
            minWidth: 100,
            Cell: StyledCell,
        },
        {
            Header: 'DATE',
            accessor: 'eventStartDate',
            minWidth: 80,
            Cell: (props: CellInfo) => <DateComponent date={props.value} />,
        },
        {
            Header: 'TIME',
            accessor: 'eventStartTime',
            minWidth: 80,
            Cell: (props: CellInfo) => <DateComponent isTime={true} date={props.value} />,
        },
        {
            Header: 'ACTIONS',
            accessor: 'eventStateId',
            minWidth: 100,
            style: { paddingVertical: 0, paddingHorizontal: 20 },
            Cell: (props: CellInfo) => {
                return (
                    <Flex direction="row">
                        <DetailsButton
                            onClick={() => {
                                setActiveEvent(props.original);
                                setEventModalOpen(true);
                            }}
                            isLocked={!userStore.userPermissions.canView.patientEvents}
                            data-test-id={ANALYTICS_NAMES.PatientDetails_Appointments_Table_Details}
                        />
                    </Flex>
                );
            },
        },
    ];

    async function loadAppointments() {
        try {
            setIsLoading(true);
            await eventStore.getEvents({
                filters: { facId: props.patient.facId, patientId: props.patientId, type: 'APPOINTMENT' },
            });
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        loadAppointments();
    }, []);

    useEffect(() => {
        setPatientEvents(eventStore.patientAppointments);
    }, [eventStore.patientAppointments]);

    return (
        <div className={styles.container}>
            <Flex self="stretch" align="center" justify="start" className={styles.headerWrap}>
                <Flex value={1} className={styles.filterWrap}></Flex>
                <Flex value={2} justify="end">
                    <Button
                        leftIcon={<Icon className={styles.plusIcon} name={ICONS.PlusButton} />}
                        type="primary"
                        onClick={() => {
                            setActiveEvent(null);
                            ModalStoreObject.showModal(ModalTypes.CreateEvent, {
                                type: 'appointment',
                                title: `New Appointment for ${getPatientName(props.patient)}`,
                                patientId: props.patientId,
                                patient: props.patient,
                                facilityId: props.patient.facId,
                                onClose: () => ModalStoreObject.hideModal(),
                                onSave: () => {
                                    ModalStoreObject.hideModal();
                                    loadAppointments();
                                },
                            });
                        }}
                        isLocked={!userStore.userPermissions.canEdit.patientEvents}
                        text="Create Appointment"
                        data-test-id={ANALYTICS_NAMES.PatientDetails_Appointments_Create}
                    />
                </Flex>
            </Flex>
            <div className={styles.tableWrap}>
                <div className={styles.tableTitle}>Patient Appointments</div>
                <Table columns={columns} data={toJS(patientEvents)} isLoading={isLoading} />
            </div>
            {eventModalOpen ? (
                <EventDetailsModal
                    patient={props.patient}
                    event={activeEvent}
                    onClose={() => setEventModalOpen(false)}
                    onChangeComplete={() => loadAppointments()}
                />
            ) : null}
        </div>
    );
}

export default observer(Appointments);
