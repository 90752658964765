import { Customer, CustomerAddress, CustomerSubscriptionData, CustomerType } from 'common/lib/entity/index/Customer';
import { observer } from 'mobx-react';
import React, { useContext, useState } from 'react';
import CustomerStore from 'src/stores/CustomerStore';
import { ToastStoreObject, ToastType } from 'src/stores/ToastStore';
import { parseError } from 'src/utils';
import { ANALYTICS_NAMES } from 'src/utils/analytics';
import Input from '../Input';
import LoadingIcon from '../LoadingIcon';
import ModalFooter from '../ModalFooter';
import ModalHeader from '../ModalHeader';
import SideModal from '../SideModal';
import CustomerTypeDropdown from './CustomerTypeDropdown';
import styles from './styles.module.scss';

interface CustomerModalProps {
    customer?: Customer;
    mode?: string;
    onClose?: Function;
    onSave?: Function;
}

function CustomerModal(props: CustomerModalProps) {
    const customerStore = useContext(CustomerStore);

    const [isLoading, setIsLoading] = useState(false);
    const [customer, setCustomer] = useState<Partial<Customer>>(props.customer ? props.customer : {});
    const [customerName, setCustomerName] = useState<Customer['customerName']>(customer ? customer.customerName : '');
    const [customerType, setCustomerType] = useState<CustomerSubscriptionData['customerType']>(
        customer ? customer?.subscriptionData?.customerType : null,
    );
    const [streetAddress1, setStreetAddress1] = useState<CustomerAddress['streetAddress1']>(
        customer ? customer?.subscriptionData?.customerAddress?.streetAddress1 : '',
    );
    const [streetAddress2, setStreetAddress2] = useState<CustomerAddress['streetAddress2']>(
        customer ? customer?.subscriptionData?.customerAddress?.streetAddress2 : '',
    );
    const [city, setCity] = useState<CustomerAddress['city']>(
        customer ? customer?.subscriptionData?.customerAddress?.city : '',
    );
    const [state, setState] = useState<CustomerAddress['state']>(
        customer ? customer?.subscriptionData?.customerAddress?.state : '',
    );
    const [postalCode, setPostalCode] = useState<CustomerAddress['postalCode']>(
        customer ? customer?.subscriptionData?.customerAddress?.postalCode : '',
    );

    async function handleSave() {
        setIsLoading(true);
        try {
            await customerStore.updateCustomer(props.customer.customerId, customerName, {
                customerType,
                customerAddress: {
                    streetAddress1,
                    streetAddress2,
                    city,
                    state,
                    postalCode,
                },
            });
            if (props.onSave) {
                props.onSave();
            }
            if (props.onClose) {
                props.onClose();
            }
            ToastStoreObject.show(`${customerName} has been updated`, ToastType.Success);
        } catch (e: any) {
            ToastStoreObject.show(parseError(e));
        } finally {
            setIsLoading(false);
        }
    }

    async function handleCreate() {
        setIsLoading(true);
        try {
            await customerStore.createCustomer(customerName, {
                customerType,
                customerAddress: {
                    streetAddress1,
                    streetAddress2,
                    city,
                    state,
                    postalCode,
                },
            });
            if (props.onSave) {
                props.onSave();
            }
            if (props.onClose) {
                props.onClose();
            }
            ToastStoreObject.show(`${customerName} has been created`, ToastType.Success);
        } catch (e: any) {
            ToastStoreObject.show(parseError(e));
        } finally {
            setIsLoading(false);
        }
    }

    function handleCustomerTypeChange(typeSelection: { value: CustomerType; label: string }) {
        setCustomerType(typeSelection.value);
    }

    return (
        <SideModal isOpen={true} onClose={props.onClose}>
            <ModalHeader title={`${props.mode === 'edit' ? 'Edit' : 'New'} Customer`} onClose={props.onClose} />
            {isLoading ? (
                <div className={styles.loadingContentWrap}>
                    <LoadingIcon />
                </div>
            ) : (
                <div className={styles.contentWrap}>
                    <Input
                        label="Customer Name"
                        value={customerName}
                        onChangeText={(v: string) => setCustomerName(v)}
                        data-test-id={ANALYTICS_NAMES.CustomerModal_CustomerName}
                    />
                    <div className={styles.title}>Customer Type</div>
                    <CustomerTypeDropdown
                        selectedValue={customerType}
                        onChange={(e: any) => handleCustomerTypeChange(e)}
                        data-test-id={ANALYTICS_NAMES.CustomerModal_CustomerType}
                    />
                    <div className={styles.title}>Customer Address</div>
                    <Input
                        label="Street Address 1"
                        value={streetAddress1}
                        onChangeText={(v: string) => setStreetAddress1(v)}
                        data-test-id={ANALYTICS_NAMES.CustomerModal_CustomerStreetAddress1}
                    />
                    <Input
                        label="Street Address 2"
                        value={streetAddress2}
                        onChangeText={(v: string) => setStreetAddress2(v)}
                        data-test-id={ANALYTICS_NAMES.CustomerModal_CustomerStreetAddress2}
                    />
                    <Input
                        label="City"
                        value={city}
                        onChangeText={(v: string) => setCity(v)}
                        data-test-id={ANALYTICS_NAMES.CustomerModal_CustomerCity}
                    />
                    <Input
                        label="State"
                        value={state}
                        onChangeText={(v: string) => setState(v)}
                        data-test-id={ANALYTICS_NAMES.CustomerModal_CustomerState}
                    />
                    <Input
                        label="Postal Code"
                        value={postalCode}
                        onChangeText={(v: string) => setPostalCode(v)}
                        data-test-id={ANALYTICS_NAMES.CustomerModal_CustomerPostalCode}
                    />
                </div>
            )}
            <ModalFooter
                primaryText={props.mode === 'edit' ? 'Save' : 'Create'}
                primaryClick={props.mode === 'edit' ? handleSave : handleCreate}
                secondaryClick={() => props.onClose()}
                isLoading={isLoading}
            />
        </SideModal>
    );
}

export default CustomerModal;
