import React, { useContext, useEffect } from 'react';
import { TinSetting, QcdrSettings, ProviderTin } from 'common/lib/model/macra/QcdrSettings';
import FacilityStore from 'src/stores/FacilityStore';
import Checkbox from '../Checkbox';
import Flex from '../Flex';
import Table from '../Table';
import QcdrSettingsStore from 'src/stores/QcdrSettingsStore';
import { CellInfo } from 'react-table';
import Input from '../Input';
import Card from '../Card';
import styles from './styles.module.scss';
import { ToastStoreObject, ToastType } from 'src/stores/ToastStore';

export interface TinSettingsProps {
    settings: QcdrSettings['tinSettings'];
    onSettingChange: (setting: TinSetting) => void;
}

function TinSettingsStep(props: TinSettingsProps) {
    const facilityStore = useContext(FacilityStore);
    const qcdrSettingsStore = useContext(QcdrSettingsStore);

    useEffect(() => {
        if (qcdrSettingsStore.newProvidersAdded)
            ToastStoreObject.show(
                'Providers have been added since last save.  Please review and re-save.',
                ToastType.Warn,
            );
    }, [qcdrSettingsStore.newProvidersAdded]);

    return (
        <div>
            <h3>STEP 1: TIN SETTINGS</h3>
            {props.settings.map(
                (setting, index) =>
                    facilityStore.findFacility(setting.facilityId) && (
                        <Card
                            label={facilityStore.findFacility(setting.facilityId).facNm}
                            className={styles.cardWrap}
                            key={`${setting.facilityId}-${index}`}
                        >
                            <Flex direction="column">
                                <Flex direction="column" className={styles.contentWrap}>
                                    <Checkbox
                                        data-test-id="SubmitCasesChkBox"
                                        checked={setting.submitForFacility}
                                        label="Submit cases for this facility"
                                        onChange={(e) => {
                                            props.onSettingChange({
                                                ...setting,
                                                submitForFacility: e.target.checked,
                                            } as TinSetting);
                                        }}
                                    />
                                    {setting.submitForFacility && (
                                        <>
                                            <Flex direction="column" value={1} className={styles.inputWrap}>
                                                <Checkbox
                                                    data-test-id="UseGroupTinChkBox"
                                                    checked={setting.applyTinToAll}
                                                    label="All Providers use the same Billing TIN?"
                                                    onChange={(e) => {
                                                        props.onSettingChange({
                                                            ...setting,
                                                            applyTinToAll: e.target.checked,
                                                            providers: setting.providers.map((provider) => {
                                                                return { ...provider, tin: setting.groupTin };
                                                            }),
                                                        });
                                                    }}
                                                />
                                                {setting.applyTinToAll && (
                                                    <div className={styles.inputWrap}>
                                                        <Input
                                                            data-test-id="GroupTinInput"
                                                            label="Group TIN"
                                                            value={setting.groupTin}
                                                            maxLength={9}
                                                            onChangeText={(value: string) => {
                                                                props.onSettingChange({
                                                                    ...setting,
                                                                    groupTin:
                                                                        Number.isNaN(parseFloat(value)) === false
                                                                            ? value.replace(/\D/g, '')
                                                                            : null,
                                                                    providers: setting.providers.map((providerTin) => {
                                                                        return {
                                                                            ...providerTin,
                                                                            tin:
                                                                                Number.isNaN(parseFloat(value)) ===
                                                                                false
                                                                                    ? value.replace(/\D/g, '')
                                                                                    : null,
                                                                        };
                                                                    }),
                                                                });
                                                            }}
                                                        />
                                                    </div>
                                                )}
                                            </Flex>
                                            <Table
                                                className={styles.tableWrap}
                                                key={'table_' + index}
                                                isLoading={false}
                                                columns={[
                                                    {
                                                        Header: 'ProviderName',
                                                        accessor: 'providerLastName',
                                                        minWidth: 150,
                                                        Cell: (props: CellInfo) => (
                                                            <span>
                                                                {props.original.providerLastName},{' '}
                                                                {props.original.providerFirstName}
                                                            </span>
                                                        ),
                                                    },
                                                    {
                                                        Header: 'NPI',
                                                        accessor: 'providerNpi',
                                                        minWidth: 150,
                                                    },
                                                    {
                                                        Header: 'TYPE',
                                                        accessor: 'providerType',
                                                        minWidth: 150,
                                                    },
                                                    {
                                                        Header: 'ACTIVE',
                                                        accessor: 'providerIsActive',
                                                        minWidth: 150,
                                                        Cell: (props: CellInfo) => (
                                                            <span>{props.value ? 'Yes' : 'No'}</span>
                                                        ),
                                                    },
                                                    {
                                                        Header: 'CASE COUNT',
                                                        accessor: 'providerCaseCount',
                                                        minWidth: 150,
                                                    },
                                                    {
                                                        Header: 'TIN',
                                                        accessor: 'providerCaseCount',
                                                        minWidth: 150,
                                                        Cell: (cellProps: CellInfo) => {
                                                            const currentProviderTin = setting.providers.find(
                                                                (providerTin) =>
                                                                    providerTin.npi === cellProps.original.providerNpi,
                                                            );

                                                            return (
                                                                <Input
                                                                    data-test-id="ProviderTinInput"
                                                                    value={
                                                                        currentProviderTin && currentProviderTin.tin
                                                                            ? currentProviderTin.tin.toString()
                                                                            : ''
                                                                    }
                                                                    maxLength={9}
                                                                    disabled={setting.applyTinToAll}
                                                                    onChangeText={(value: string) => {
                                                                        props.onSettingChange({
                                                                            ...setting,
                                                                            providers: setting.providers.map(
                                                                                (providerTin) => {
                                                                                    return providerTin.npi ===
                                                                                        cellProps.original.providerNpi
                                                                                        ? ({
                                                                                              ...providerTin,
                                                                                              tin: value
                                                                                                  ? value.replace(
                                                                                                        /\D/g,
                                                                                                        '',
                                                                                                    )
                                                                                                  : null,
                                                                                          } as ProviderTin)
                                                                                        : providerTin;
                                                                                },
                                                                            ),
                                                                        });
                                                                    }}
                                                                />
                                                            );
                                                        },
                                                    },
                                                ]}
                                                data={qcdrSettingsStore.getPqrsProvidersForFacility(setting.facilityId)}
                                            />
                                        </>
                                    )}
                                </Flex>
                            </Flex>
                        </Card>
                    ),
            )}
        </div>
    );
}

export default TinSettingsStep;
