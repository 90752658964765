import React, { useEffect, useState } from 'react';
import { ToastStoreObject, ToastType } from 'src/stores/ToastStore';
import Input, { Label } from '../Input';
import ModalFooter from '../ModalFooter';
import ModalHeader from '../ModalHeader';
import SideModal from '../SideModal';
import { FormCatalogOverrides, FormCatalogPageOverride, FormFacilityMapping } from 'common/lib/entity/framework/FormFacilityMapping';
import OrgDropDown from 'src/components/OrgDropDown';
import FacilitiesByOrgDropDown from 'src/components/FacilitiesByOrgDropDown';
import { useCreateFormFacilityMapping } from 'src/queries/useCreateFormFacilityMapping';
import { useUpdateFormFacilityMapping } from 'src/queries/useUpdateFormFacilityMapping';
import styles from './styles.module.scss';
import FormFacilityMappingFormDefinitionsDropDown from '../FormFacilityMappingFormDefinitionsDropDown';
import { FormCatalog, FormCatalogPage } from 'common/lib/entity/framework/FormCatalog';
import TabNav from '../TabNav';
import Flex from '../Flex';
import Checkbox from '../Checkbox';

interface FormFacilityMappingModalProps {
    formCatalog: FormCatalog;
    formFacilityMapping?: FormFacilityMapping;
    onClose?: Function;
    onSave?: Function;
}

function FormFacilityMappingModal(props: FormFacilityMappingModalProps) {
    const { formCatalog, formFacilityMapping, onClose, onSave } = props;
    const { formCatalogId, formTitle: formCatalogTitle, formPages } = formCatalog || {};
    const [currentTab, setCurrentTab] = useState('Create a new form');
    const [orgNameInternal, setOrgNameInternal] = useState(formFacilityMapping?.orgNameInternal || '');
    const [orgName, setOrgName] = useState(formFacilityMapping?.orgName || '');
    const [facilityId, setFacilityId] = useState(formFacilityMapping?.facilityId || null);
    const [facilityName, setFacilityName] = useState(formFacilityMapping?.facilityName || '');
    const [formDefinitionId, setFormDefinitionId] = useState(formFacilityMapping?.formDefinitionId || null);
    const [formDefinitionName, setFormDefinitionName] = useState(formFacilityMapping?.formDefinitionName || '');
    const [formOverrides, setFormOverrides] = useState<FormCatalogOverrides>(formFacilityMapping?.formOverrides || { overrideFormDefinitionTitle:false, formDefinitionTitle:null, pageOverrides: [] });

    const {
        mutate: createFormFacilityMapping,
        isLoading: isCreateFormFacilityMappingLoading,
        isSuccess: isCreateFormFacilityMappingSuccess,
        isError: isCreateFormFacilityMappingError,
        error: createFormFacilityMappingError,
    } = useCreateFormFacilityMapping(formCatalogId);
    const {
        mutate: updateFormFacilityMapping,
        isLoading: isUpdateFormFacilityMappingLoading,
        isSuccess: isUpdateFormFacilityMappingSuccess,
        isError: isUpdateFormFacilityMappingError,
        error: updateFormFacilityMappingError,
    } = useUpdateFormFacilityMapping(formFacilityMapping?.formFacilityMappingId);

    useEffect(() => {
        if (isCreateFormFacilityMappingSuccess) {
            ToastStoreObject.show('Form facility mapping created successfully', ToastType.Success);
            onClose();
            onSave();
        }

        if (isCreateFormFacilityMappingError) {
            // @ts-ignore
            const errorMessage = createFormFacilityMappingError?.response?.data?.message || 'Failed to create form facility mapping';
            ToastStoreObject.show(errorMessage, ToastType.Error);
        }

        if (isUpdateFormFacilityMappingSuccess) {
            ToastStoreObject.show('Form facility mapping updated successfully', ToastType.Success);
            onClose();
            onSave();
        }

        if (isUpdateFormFacilityMappingError) {
            // @ts-ignore
            const errorMessage = updateFormFacilityMappingError?.response?.data?.message || 'Failed to update form facility mapping';
            ToastStoreObject.show(errorMessage, ToastType.Error);
        }
    }, [
        isCreateFormFacilityMappingSuccess,
        isCreateFormFacilityMappingError,
        isUpdateFormFacilityMappingSuccess,
        isUpdateFormFacilityMappingError,
    ]);

    async function handleCreate() {
        if (currentTab === 'Create a new form') {
            createFormFacilityMapping({
                orgNameInternal,
                orgName,
                facilityId,
                facilityName,
                formDefinitionName,
                formOverrides,
            });
        } else {
            createFormFacilityMapping({
                orgNameInternal,
                orgName,
                facilityId,
                facilityName,
                formDefinitionId,
                formDefinitionName,
                formOverrides,
            });
        }
    }

    async function handleUpdate() {
        if (formFacilityMapping) {
            updateFormFacilityMapping({
                formOverrides,
            });
        }
    }

    function getTabContent() {
        switch (currentTab) {
            case 'Create a new form':
                return (
                    <div className={styles.tabContent}>
                        <Input label="Form Name" value={formDefinitionName} onChangeText={setFormDefinitionName} />
                    </div>
                );
            case 'Map to existing form':
                return (
                    <div className={styles.tabContent}>
                        <Label text="Form Definition" className={styles.label} />
                        {orgNameInternal && facilityId ? (
                            <FormFacilityMappingFormDefinitionsDropDown
                                orgNameInternal={orgNameInternal}
                                facilityId={facilityId}
                                selectedValue={facilityId}
                                onChange={(o: { label: string; value: number }) => {
                                    setFormDefinitionId(o.value);
                                    setFormDefinitionName(o.label);
                                }}
                                disabled={formFacilityMapping != null}
                            />
                        ) : (
                            <span>Please select an organization and facility</span>
                        )}
                    </div>
                );
        }
    }

    function updateFormTitleOverride(enabled:boolean, value: string) {
        const updatedFormOverrides:FormCatalogOverrides = { ...formOverrides };
        updatedFormOverrides.overrideFormDefinitionTitle = enabled;
        updatedFormOverrides.formDefinitionTitle = enabled === true ? value : null;
        if(!updatedFormOverrides.pageOverrides) updatedFormOverrides.pageOverrides = [];

        setFormOverrides(updatedFormOverrides);
    }

    function updateMinCountOverride(pageId: FormCatalogPage['pageId'], enableOverride:boolean, value: number) {
        const updatedFormOverrides:FormCatalogOverrides = { pageOverrides: [] };
        updatedFormOverrides.overrideFormDefinitionTitle = formOverrides.overrideFormDefinitionTitle;
        updatedFormOverrides.formDefinitionTitle = formOverrides.formDefinitionTitle;

        let updatedOverride = false;

        updatedFormOverrides.pageOverrides = (formOverrides.pageOverrides || []).map((o: FormCatalogPageOverride) => {
            if(o.formCatalogPageId === pageId) {
                o.overrideMinCount = enableOverride;
                o.minCount = enableOverride ? value : null;
                updatedOverride = true;
            }
            return o;
        });

        if(!updatedOverride) {
            updatedFormOverrides.pageOverrides.push({
                formCatalogPageId: pageId,
                overrideMinCount: enableOverride,
                minCount: enableOverride ? value : null
            });
        }

        setFormOverrides(updatedFormOverrides);
    }

    function updateMaxCountOverride(pageId: FormCatalogPage['pageId'], enableOverride:boolean, value: number) {
        const updatedFormOverrides:FormCatalogOverrides = { pageOverrides: [] };
        updatedFormOverrides.overrideFormDefinitionTitle = formOverrides.overrideFormDefinitionTitle;
        updatedFormOverrides.formDefinitionTitle = formOverrides.formDefinitionTitle;

        let updatedOverride = false;

        updatedFormOverrides.pageOverrides = (formOverrides.pageOverrides || []).map((o: FormCatalogPageOverride) => {
            if(o.formCatalogPageId === pageId) {
                o.overrideMaxCount = enableOverride;
                o.maxCount = enableOverride ? value : null;
                updatedOverride = true;
            }
            return o;
        });

        if(!updatedOverride) {
            updatedFormOverrides.pageOverrides.push({
                formCatalogPageId: pageId,
                overrideMaxCount: enableOverride,
                maxCount: enableOverride ? value : null
            });
        }

        setFormOverrides(updatedFormOverrides);
    }

    function getFormOverrideTitle(overrides: FormCatalogOverrides) {
        return overrides?.formDefinitionTitle || formCatalogTitle;
    }

    return (
        <SideModal isOpen={true} onClose={onClose}>
            <ModalHeader
                title={`${formFacilityMapping != null ? 'Edit' : 'New'} Form Facility Mapping`}
                onClose={onClose}
            />
            <div className={styles.contentWrap}>
                {!formFacilityMapping && (
                    <>
                        <Label text="Organization" className={styles.label} />
                        <OrgDropDown
                            onChange={(o: { label: string; value: string }) => {
                                setOrgNameInternal(o.value);
                                setOrgName(o.label);
                            }}
                            selectedValue={orgNameInternal}
                            disabled={formFacilityMapping != null}
                        />
                    </>
                )}
                {!formFacilityMapping && orgNameInternal && (
                    <>
                        <Label text="Facility" className={styles.label} />
                        <FacilitiesByOrgDropDown
                            orgNameInternal={orgNameInternal}
                            selectedValue={facilityId}
                            onChange={(o: { label: string; value: number }) => {
                                setFacilityId(o.value);
                                setFacilityName(o.label);
                            }}
                            disabled={formFacilityMapping != null}
                        />
                    </>
                )}
                {!formFacilityMapping && orgNameInternal && facilityId && (
                    <div className={styles.tabContainer}>
                        <TabNav
                            tabs={['Create a new form', 'Map to existing form']}
                            onTabClick={(v: string) => setCurrentTab(v)}
                        />
                        {getTabContent()}
                        <div className={styles.separator}></div>
                    </div>
                )}
                {orgNameInternal && facilityId && (
                    <>
                        <Label text="Form Overrides" />
                        <Flex className={styles.overrides} gap={8} align="center">
                                <Checkbox
                                    label="Form Definition Title"
                                    checked={formOverrides != null && formOverrides.overrideFormDefinitionTitle === true}
                                    onChange={(e) => {
                                        updateFormTitleOverride(e.target.checked, formCatalog.formTitle);
                                    }}
                                />
                                <Input
                                    type="text"
                                    value={getFormOverrideTitle(formOverrides)}
                                    disabled={formOverrides?.overrideFormDefinitionTitle !== true}
                                    className={styles.formTitle}
                                    onChangeText={(v: any) => {
                                        updateFormTitleOverride(true, v);
                                    }}
                                />
                        </Flex>
                        {formPages?.map((page: FormCatalogPage) => {
                            const formOverride = formOverrides?.pageOverrides?.find((o: FormCatalogPageOverride) => o.formCatalogPageId === page.pageId);
                            const currentPage = formCatalog?.formPages?.find((p: FormCatalogPage) => p.pageId === page.pageId);

                            return (
                                <Flex className={styles.overrides} align="center">
                                    <div className={styles.pageName}>{page.pageName}</div>
                                    <Flex className={styles.inputs} gap={8}>
                                        <Flex className={styles.min} gap={8}>
                                            <Checkbox
                                                label="Min"
                                                checked={formOverride != null && formOverride.overrideMinCount === true}
                                                onChange={(e) =>
                                                    updateMinCountOverride(
                                                        page.pageId,
                                                        e.target.checked,
                                                        currentPage.minCount
                                                    )
                                                }
                                            />
                                            <Input
                                                type="number"
                                                value={formOverride == null || formOverride.overrideMinCount !== true ? currentPage?.minCount : formOverride?.minCount}
                                                disabled={formOverride == null || formOverride.overrideMinCount !== true}
                                                className={styles.minMax}
                                                onChangeText={(v: any) => {
                                                    if(v >= 0) {
                                                        updateMinCountOverride(page.pageId, true, v);
                                                    }
                                                }}
                                            />
                                        </Flex>
                                        <Flex className={styles.max} gap={8}>
                                            <Checkbox
                                                label="Max"
                                                checked={formOverride != null && formOverride.overrideMaxCount === true}
                                                onChange={(e) =>
                                                    updateMaxCountOverride(
                                                        page.pageId,
                                                        e.target.checked,
                                                        currentPage.maxCount
                                                    )
                                                }
                                            />
                                            <Input
                                                type="number"
                                                value={formOverride == null || formOverride.overrideMaxCount !== true ? currentPage?.maxCount : formOverride?.maxCount}
                                                disabled={formOverride == null || formOverride.overrideMaxCount !== true}
                                                className={styles.minMax}
                                                onChangeText={(v: any) => {

                                                    if (v === '' || v == -1) {
                                                        updateMaxCountOverride(page.pageId, true, null);
                                                    }

                                                    if (!isNaN(v) && v >= 0) {
                                                        updateMaxCountOverride(page.pageId, true, v);
                                                    }
                                                }}
                                            />
                                        </Flex>
                                    </Flex>
                                </Flex>
                            );
                        })}
                    </>
                )}
            </div>
            <ModalFooter
                primaryText={formFacilityMapping != null ? 'Save' : 'Create'}
                primaryClick={formFacilityMapping != null ? handleUpdate : handleCreate}
                secondaryClick={() => onClose()}
                isLoading={isCreateFormFacilityMappingLoading || isUpdateFormFacilityMappingLoading}
            />
        </SideModal>
    );
}

export default FormFacilityMappingModal;
