import React, { useState, useEffect, useContext } from 'react';
import styles from './styles.module.scss';
import Select from 'react-select';
import { observer } from 'mobx-react';
import * as utils from 'src/utils';
import useIsOptionsLoading from 'src/utils/hooks/useIsOptionsLoading';
import AdminStore from 'src/stores/AdminStore';
import { ModalStoreObject, ModalTypes } from 'src/stores/ModalStore';
import { ViewOnlyCodeEditor } from 'src/containers/AdminScheduledJobsDetail';
import Button from 'src/components/Button';
import { getFlowScriptTemplateHistoryVersion } from 'src/utils/flowScripts';

export interface AdminFlowScriptTemplatesDropDownProps {
    onChange: Function;
    selectedValue?: string | number;
    allowEmpty?: boolean;
    showScriptModal?: boolean;
}

function AdminFlowScriptTemplatesDropDown(props: AdminFlowScriptTemplatesDropDownProps) {
    const adminStore = useContext(AdminStore);
    const [selected, setSelected] = useState(null);
    const [selectedScript, setSelectedScript] = useState(null);

    const options = adminStore.flowScriptTemplateDropdownValues;
    const isLoading = useIsOptionsLoading(options);

    useEffect(() => {
        adminStore.getFlowScriptTemplates();
    }, []);

    useEffect(() => {
        const selectedVal = options.find((i) => i.value === props.selectedValue);
        if (selectedVal) {
            setSelected(selectedVal);
        }
    }, [props.selectedValue, options]);

    useEffect(() => {
        if (props.showScriptModal && selected && selected.value) {
            const template = adminStore.flowScriptTemplates.find((t) => t.flowScriptTemplateGuid === selected.value);
            if (template) {
                const published = getFlowScriptTemplateHistoryVersion(template.histories, template.publishedVersion);
                if (published.scriptContent) {
                    setSelectedScript(published.scriptContent);
                }
            }
        }
    }, [props.showScriptModal, selected, adminStore.flowScriptTemplates]);

    return (
        <>
            <Select
                value={selected}
                onChange={(s: any) => {
                    setSelected(s);
                    props.onChange(s || null);
                }}
                isLoading={isLoading}
                className={styles.select}
                isClearable={props.allowEmpty}
                options={options}
                placeholder="Select Template"
                onMenuOpen={() => {
                    // Make sure it refetches when the menu is opened in case there was an initial load issue
                    adminStore.getFlowScriptTemplates();
                }}
                styles={utils.styleSelectComponent()}
            />
            {props.showScriptModal && selectedScript && (
                <Button
                    type="transparent"
                    onClick={() => {
                        ModalStoreObject.showModal(ModalTypes.ViewContent, {
                            onClose: () => ModalStoreObject.hideModal(),
                            title: 'Template Flow Script',
                            staticContent: <ViewOnlyCodeEditor value={selectedScript} />,
                        });
                    }}
                    text="View Template Script"
                />
            )}
        </>
    );
}

export default observer(AdminFlowScriptTemplatesDropDown);
