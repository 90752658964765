import React, { useState, useContext, useEffect } from 'react';
import styles from './styles.module.scss';
import SideModal from 'src/components/SideModal';
import Input, { Label } from 'src/components/Input';
import ModalHeader from 'src/components/ModalHeader';
import OptionDropDown, { OptionTypes } from '../OptionDropDown';
import * as utils from 'src/utils';
import ModalFooter from '../ModalFooter';
import { ToastStoreObject } from 'src/stores/ToastStore';
import PatientMedicationStore, { PatientMedication } from 'src/stores/PatientMedicationStore';
import { Facility } from 'src/stores/FacilityStore';
import DatePicker from '../DatePicker';
import { format } from 'date-fns';
import { API_DATE_FORMAT, ROUTES } from 'src/utils/constants';
import { ANALYTICS_NAMES } from 'src/utils/analytics';
import * as adminService from 'src/api/admin';
import { ScheduledJob, FlowScript, FlowScriptHistory, FlowScriptTemplate } from 'src/utils/types';
import { useHistory } from 'react-router-dom';
import AdminJobSchedulesDropDown from '../AdminJobSchedulesDropDown';
import AdminFlowScriptTemplatesDropDown from '../AdminFlowScriptTemplatesDropDown';

export interface CreateScheduledJobModalProps {
    onClose: Function;
}
export function CreateScheduledJobModal(props: CreateScheduledJobModalProps) {
    const history = useHistory();

    const defaultScheduledJob: Partial<ScheduledJob & FlowScript & FlowScriptHistory & FlowScriptTemplate> = {
        jobScheduleId: null,
        orgNameInternal: 'org1002',
        scheduledJobTitle: 'Job Title',
        scheduledJobDescription: 'Job Description',
        active: false,
        flowScriptTemplateGuid: '',
        flowScriptTitle: 'FS Title',
        flowScriptDescription: 'FS Description',
        scriptContent: '',
        configParams: '',
        configValues: '',
        timeoutSeconds: null,
        maxRetries: null,
        templateVersionAlias: '',
        defaultHandler: '',
    };

    const [createData, setCreateData] = useState(defaultScheduledJob);
    const [isLoading, setIsLoading] = useState(false);

    const updateInput = (param: string) => (t: string) => setCreateData({ ...createData, [param]: t });

    async function createScheduledJob() {
        try {
            setIsLoading(true);
            const createdJob: ScheduledJob = await adminService.createScheduledJob(createData);
            props.onClose();
            history.push(ROUTES.getString(ROUTES.AdminScheduledJobsDetail, createdJob.scheduledJobGuid));
        } catch (error) {
            ToastStoreObject.show(utils.parseError(error));
        } finally {
            setIsLoading(false);
            setCreateData(defaultScheduledJob);
        }
    }

    return (
        <SideModal isOpen={true} onClose={() => props.onClose()}>
            <ModalHeader title="Create a Scheduled Job" onClose={() => props.onClose()} />
            <div className={styles.inviteModalContentWrap}>
                {/* TODO: Drop down for job schedules */}
                <div>
                    <Label text="Job Schedule" />
                    <AdminJobSchedulesDropDown
                        selectedValue={createData.jobScheduleId}
                        onChange={(s: any) => setCreateData({ ...createData, jobScheduleId: s ? s.value : null })}
                    />
                </div>
                <div>
                    <Label text="Flow Script Template" />
                    <AdminFlowScriptTemplatesDropDown
                        selectedValue={createData.flowScriptTemplateGuid}
                        allowEmpty={true}
                        onChange={(s: any) =>
                            setCreateData({ ...createData, flowScriptTemplateGuid: s ? s.value : null })
                        }
                    />
                </div>
                <Input
                    className={styles.input}
                    label="Org Name Internal"
                    value={createData.orgNameInternal}
                    onChangeText={updateInput('orgNameInternal')}
                />
                <Input
                    className={styles.input}
                    label="Job Title"
                    value={createData.scheduledJobTitle}
                    onChangeText={updateInput('scheduledJobTitle')}
                />
                <Input
                    className={styles.input}
                    label="Job Description"
                    value={createData.scheduledJobDescription}
                    onChangeText={updateInput('scheduledJobDescription')}
                />
                <Input
                    className={styles.input}
                    label="Flow Script Title"
                    value={createData.flowScriptTitle}
                    onChangeText={updateInput('flowScriptTitle')}
                />
                <Input
                    className={styles.input}
                    label="Flow Script Description"
                    value={createData.flowScriptDescription}
                    onChangeText={updateInput('flowScriptDescription')}
                />
            </div>
            <ModalFooter
                primaryText="Create"
                primaryClick={createScheduledJob}
                secondaryClick={() => {
                    props.onClose();
                    setCreateData(defaultScheduledJob);
                }}
                isLoading={isLoading}
            />
        </SideModal>
    );
}

export default CreateScheduledJobModal;
