import { ToastStoreObject, ToastType } from 'src/stores/ToastStore';
import { ROUTES } from './constants';

export function copyTextToClipboard(text: string) {
    const textArea = document.createElement('textarea');
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = '0';
    textArea.style.left = '0';
    textArea.style.position = 'fixed';

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
        document.execCommand('copy');
    } catch (err) {}

    document.body.removeChild(textArea);
    ToastStoreObject.show('Copied Link!', ToastType.Success);
}

export function getUserInvitationURL({
    orgNameInternal,
    inviteKey,
    email,
}: {
    orgNameInternal: string;
    inviteKey: string;
    email: string;
}) {
    return `${import.meta.env.VITE_APP_ENV === 'local' ? `http://${window.location.host}` : `https://${window.location.host}`}${
        ROUTES.Invitation
    }?org=${orgNameInternal}&inviteKey=${inviteKey}&email=${email}`;
}
