import React, { useState } from 'react';
import styles from './styles.module.scss';
import Input from 'src/components/Input';
import { useEffect } from 'react';
import { ReferenceList } from 'src/stores/ReferenceListStore';

interface Props {
    referenceList: ReferenceList;
    mode: string;
    onChange: Function;
}

function ReferenceListModal(props: Props) {
    const [listName, setListName] = useState(props.referenceList.listName || '');
    const [listDisplayName, setListDisplayName] = useState(props.referenceList.listDisplayName || '');
    const [listDescription, setListDescription] = useState(props.referenceList.listDescription || '');

    useEffect(() => {
        props.onChange({
            listName,
            listDisplayName,
            listDescription,
        });
    }, [listName, listDisplayName, listDescription]);

    return (
        <div className={styles.contentWrap}>
            <Input
                label="Name"
                disabled={props.mode === 'edit' ? true : false}
                value={listName}
                onChangeText={(value: string) => setListName(value)}
            />
            <Input
                label="Display Name"
                value={listDisplayName}
                onChangeText={(value: string) => setListDisplayName(value)}
            />
            <Input
                label="Description"
                value={listDescription}
                onChangeText={(value: string) => setListDescription(value)}
            />
        </div>
    );
}

export default ReferenceListModal;
