import React, { forwardRef } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

export interface CardProps extends React.HTMLAttributes<HTMLDivElement> {
    elevation?: 0 | 1 | 2 | 3 | 4 | 5;
    label?: React.ReactNode;
    subLabel?: React.ReactNode;
}

const Card = forwardRef(
    ({ children, elevation, className, label, subLabel, ...rest }: CardProps, ref: React.Ref<HTMLDivElement>) => {
        return (
            <div
                {...rest}
                ref={ref}
                className={classNames(
                    styles.card,
                    {
                        [styles.elevation1]: elevation === undefined || elevation === 1,
                        [styles.elevation2]: elevation === 2,
                        [styles.elevation3]: elevation === 3,
                        [styles.elevation4]: elevation === 4,
                        [styles.elevation5]: elevation === 5,
                    },
                    className,
                )}
            >
                {label ? (
                    <div className={styles.labelContainer}>
                        <div className={styles.label}>{label}</div>
                        {subLabel ? <div className={styles.subLabel}>{subLabel}</div> : null}
                    </div>
                ) : null}
                {children}
            </div>
        );
    },
);
Card.displayName = 'Card';

export default Card;
