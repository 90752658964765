import React, { useContext, useState, useEffect } from 'react';
import Flex from 'src/components/Flex';
import PageContainer from 'src/components/PageContainer';
import PageContent from 'src/components/PageContent';
import Button from 'src/components/Button';
import NavBar from 'src/containers/NavBar';
import Card from 'src/components/Card';
import styles from './styles.module.scss';
import Table from 'src/components/Table';
import FeatureFlagStore from 'src/stores/FeatureFlagStore';
import Icon from 'src/components/Icon';
import { ICONS } from 'src/utils/constants';
import { CellInfo } from 'react-table';
import DateComponent from 'src/components/DateComponent';
import PaymentsStore, { PaymentGateway, PaymentType } from 'src/stores/PaymentsStore';
import ActionMenu from 'src/components/ActionMenu';
import { ModalStoreObject, ModalTypes } from 'src/stores/ModalStore';
import TableActiveCol from 'src/components/TableActiveCol';
import { ANALYTICS_NAMES } from 'src/utils/analytics';
import { observer } from 'mobx-react';
import EditTextContent from './EditTextContent';
import { ToastStoreObject } from 'src/stores/ToastStore';
import { parseError } from 'src/utils';

function PaymentTypes() {
    const paymentsStore = useContext(PaymentsStore);
    const featureFlagStore = useContext(FeatureFlagStore);
    const [isLoading, setIsLoading] = useState(false);
    const [editId, setEditId] = useState<PaymentType['paymentTypeId']>(null);
    const [editType, setEditType] = useState<'sms' | 'email' | 'landing'>('email');
    const [editValue, setEditValue] = useState('');
    const [editModalOpen, setEditModalOpen] = useState(false);

    useEffect(() => {
        getPaymentTypeData(true);
    }, []);

    const columns = [
        {
            Header: 'NAME',
            accessor: 'paymentTypeTitle',
        },
        {
            Header: 'DESCRIPTION',
            accessor: 'paymentTypeDescription',
        },
        {
            Header: 'GATEWAY',
            accessor: 'paymentGatewayId',
            Cell: (props: CellInfo) => {
                const gateway = paymentsStore.paymentGateways.find(
                    (pg: PaymentGateway) => pg.paymentGatewayId == props.value,
                );
                return <span>{gateway && gateway.paymentGatewayTitle}</span>;
            },
        },
        {
            Header: 'CREATED',
            accessor: 'createdAt',
            minWidth: 75,
            Cell: (props: CellInfo) => {
                return <DateComponent date={props.value} />;
            },
        },
        {
            Header: 'UPDATED',
            accessor: 'updatedAt',
            minWidth: 75,
            Cell: (props: CellInfo) => {
                return <DateComponent date={props.value} />;
            },
        },
        {
            Header: 'ACTIVE',
            accessor: 'isActive',
            minWidth: 50,
            Cell: (props: CellInfo) => <TableActiveCol isActive={props.value && props.value.toString() === 'true'} />,
        },
        {
            Header: 'ACTIONS',
            accessor: 'actions',
            maxWidth: 125,
            Cell: (props: CellInfo) => (
                <ActionMenu
                    entityType="paymentType"
                    entity={props.original}
                    onItemClick={(action: { id: string; label: string }) => handleActionClick(action, props.original)}
                />
            ),
        },
    ];

    async function getPaymentTypeData(force: boolean = false) {
        setIsLoading(true);

        try {
            await paymentsStore.getPaymentTypes({ includeInactive: true, force });
            await paymentsStore.getPaymentGateways(false, force);
            await paymentsStore.getPayerCategories(false, force);
        } catch (e) {
            ToastStoreObject.show(parseError(e));
        } finally {
            setIsLoading(false);
        }
    }

    function handleActionClick(action: { id: string; label: string }, item: PaymentType) {
        switch (action.id) {
            case 'edit':
                ModalStoreObject.showModal(ModalTypes.PaymentTypeModal, {
                    onClose: () => ModalStoreObject.hideModal(),
                    onSave: () => getPaymentTypeData(true),
                    paymentType: item,
                    payerCategories: paymentsStore.payerCategories,
                    mode: 'edit',
                });
                break;
            case 'editEmail':
                setEditId(item.paymentTypeId);
                setEditType('email');
                setEditValue(item.paymentTypeConfig && item.paymentTypeConfig.emailText);
                setEditModalOpen(true);
                break;
            case 'editSms':
                setEditId(item.paymentTypeId);
                setEditType('sms');
                setEditValue(item.paymentTypeConfig && item.paymentTypeConfig.smsText);
                setEditModalOpen(true);
                break;
            case 'editLanding':
                setEditId(item.paymentTypeId);
                setEditType('landing');
                setEditValue(item.paymentTypeConfig && item.paymentTypeConfig.landingPageText);
                setEditModalOpen(true);
                break;
            case 'activate':
                if (item.isActive) {
                    ModalStoreObject.showModal(ModalTypes.ConfirmationModal, {
                        title: 'Are you sure you would like to deactivate this payment type?',
                        onConfirm: () => handleDeactivate(item.paymentTypeId),
                        onCancel: () => ModalStoreObject.hideModal(),
                        confirmButtonText: 'Deactivate',
                    });
                } else {
                    handleReactivate(item.paymentTypeId);
                }
                break;
        }
    }

    function handleCreate() {
        ModalStoreObject.showModal(ModalTypes.PaymentTypeModal, {
            onClose: () => ModalStoreObject.hideModal(),
            onSave: () => getPaymentTypeData(true),
            payerCategories: paymentsStore.payerCategories,
        });
    }

    async function handleDeactivate(paymentTypeId: PaymentType['paymentTypeId']) {
        setIsLoading(true);
        ModalStoreObject.hideModal();

        try {
            await paymentsStore.deactivatePaymentType(paymentTypeId);
            getPaymentTypeData(true);
        } catch (e) {
            ToastStoreObject.show(parseError(e));
        } finally {
            setIsLoading(false);
        }
    }

    async function handleReactivate(paymentTypeId: PaymentType['paymentTypeId']) {
        setIsLoading(true);
        ModalStoreObject.hideModal();

        try {
            await paymentsStore.reactivatePaymentType(paymentTypeId);
            getPaymentTypeData(true);
        } catch (e) {
            ToastStoreObject.show(parseError(e));
        } finally {
            setIsLoading(false);
        }
    }

    async function handleEditSave() {
        setIsLoading(true);
        setEditModalOpen(false);

        try {
            switch (editType) {
                case 'email':
                    await paymentsStore.updatePaymentTypeEmailText(editId, editValue);
                    break;
                case 'sms':
                    await paymentsStore.updatePaymentTypeSmsText(editId, editValue);
                    break;
                case 'landing':
                    await paymentsStore.updatePaymentTypeLandingPageText(editId, editValue);
                    break;
            }
        } catch (e) {
            ToastStoreObject.show(parseError(e));
        } finally {
            await getPaymentTypeData(true);
            setIsLoading(false);
        }
    }

    return (
        <PageContainer>
            <NavBar />
            <PageContent>
                <Flex direction="row" justify="end" className={styles.filterWrap}>
                    {featureFlagStore.isEnabled('payments') ? (
                        <Button
                            leftIcon={<Icon className={styles.plusIcon} name={ICONS.PlusButton} />}
                            type="primary"
                            onClick={() => handleCreate()}
                            text="Create Payment Type"
                            className={styles.createButton}
                            data-test-id={ANALYTICS_NAMES.PaymentType_Create}
                        />
                    ) : null}
                </Flex>
                <Card className={styles.cardWrap}>
                    <div className={styles.tableTitle}>Payment Types</div>
                    <Table
                        isLoading={isLoading}
                        columns={columns}
                        data={paymentsStore.paymentTypes}
                        showPagination={false}
                    />
                </Card>
            </PageContent>
            <EditTextContent
                type={editType}
                value={editValue}
                isOpen={editModalOpen}
                onClose={() => setEditModalOpen(false)}
                onChange={(v: string) => setEditValue(v)}
                onSave={() => handleEditSave()}
            />
        </PageContainer>
    );
}

export default observer(PaymentTypes);
