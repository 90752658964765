import React, { useEffect, useState, useContext } from 'react';
import NavBar from 'src/containers/NavBar';
import PageContent from 'src/components/PageContent';
import PageContainer from 'src/components/PageContainer';
import Card from 'src/components/Card';
import Flex from 'src/components/Flex';
import Table from 'src/components/Table';
import styles from './styles.module.scss';
import { CellInfo } from 'react-table';
import Button from 'src/components/Button';
import { ToastStoreObject } from 'src/stores/ToastStore';
import { parseError } from 'src/utils';
import SurveyStore from 'src/stores/SurveyStore';
import { ANALYTICS_NAMES } from 'src/utils/analytics';
import { observer } from 'mobx-react';
import { SurveyTemplate, SurveyStatus } from 'common/lib/entity/framework/SurveyTemplate';
import StatusBadge, { StatusBadgeColors } from 'src/components/StatusBadge';

export function getStatusColor(status: SurveyTemplate['surveyTemplateStateCode']) {
    switch (status) {
        case SurveyStatus.Published:
            return StatusBadgeColors.Green;
        case SurveyStatus.Draft:
            return StatusBadgeColors.Yellow;
        case SurveyStatus.Unpublished:
            return StatusBadgeColors.Red;
    }
}

function AdminSurveyTemplates() {
    const surveyStore = useContext(SurveyStore);

    useEffect(() => {
        getTemplates();
    }, []);

    async function getTemplates(force: boolean = false) {
        if (force || !surveyStore.surveyTemplates.length) {
            setIsLoading(true);
        }

        try {
            await surveyStore.getSurveyTemplates(true);
        } catch (err) {
            ToastStoreObject.show(parseError(err));
        } finally {
            setIsLoading(false);
        }
    }
    const columns = [
        {
            Header: 'TITLE',
            minWidth: 150,
            accessor: 'surveyTemplateName',
        },
        {
            Header: 'STATUS',
            minWidth: 75,
            accessor: 'surveyTemplateStateCode',
            Cell: (props: CellInfo) => {
                return <StatusBadge status={getStatusColor(props.value)} text={props.value} />;
            },
        },
        {
            Header: 'LAST UPDATE',
            minWidth: 75,
            accessor: 'updatedAt',
        },
        {
            Header: 'ACTIONS',
            accessor: 'actions',
            sortable: false,
            minWidth: 50,
            style: { paddingVertical: 0, paddingHorizontal: 20 },
            Cell: (props: CellInfo) => (
                <div className={styles.actionWrap}>
                    <Button
                        onClick={() => {
                            throw ('Function not yet implemented.')
                        }}

                        type="secondarySmall"
                        text="Edit"
                    />
                </div>
            ),
        },
    ];

    const [isLoading, setIsLoading] = useState(false);
    const [templates, setTemplates] = useState<any[]>([]);

    return (
        <PageContainer>
            <NavBar />
            <PageContent>
                <Flex direction="row" self="stretch" align="center" justify="start">
                    <div className={styles.pageTitle}>Survey Templates</div>
                    <Flex value={1} />
                    <Button
                        type="primary"
                        onClick={() => {
                            throw ('Function not yet implemented.')
                        }}
                        text="New Template"
                    />
                </Flex>
                <Card className={styles.cardWrap}>
                    <Table isLoading={isLoading} columns={columns} data={surveyStore.surveyTemplates} showPagination={false} />
                </Card>
            </PageContent>
        </PageContainer>
    );
}
export default observer(AdminSurveyTemplates);
