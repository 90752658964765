import { AutomatedDispenseSystem } from 'common/lib/entity/org/AutomatedDispenseSystem';
import { action, observable } from 'mobx';
import { createContext } from 'react';
import { number } from 'yup';
import * as adsApi from '../api/automatedDispenseSystem';

class AutomatedDispenseSystemStore {
    @observable automatedDispenseSystems: AutomatedDispenseSystem[] = [];

    async getAutomatedDispenseSystems(
        options: { includeInactive: boolean; query?: string; facilityId?: number },
        force = false,
    ) {
        if (!force && this.automatedDispenseSystems.length > 0) {
            return;
        }

        const connections = (await adsApi.getAutomatedDispenseSystems(options)) || {};
        this.setAutomatedDispenseSystems(connections || []);
    }

    async updateAutomatedDispenseSystem(systemId: number, adsUpdates: Partial<AutomatedDispenseSystem>) {
        return await adsApi.updateAutomatedDispenseSystem(systemId, adsUpdates);
    }

    @action
    reset() {
        this.automatedDispenseSystems = [];
    }

    @action
    setAutomatedDispenseSystems(systems: AutomatedDispenseSystem[]) {
        this.automatedDispenseSystems = systems;
    }
}

export const AdsStoreObject = new AutomatedDispenseSystemStore();
export default createContext(AdsStoreObject);
