import React from 'react';
import ModalHeader from 'src/components/ModalHeader';
import ModalFooter from '../ModalFooter';
import CenterModal from '../CenterModal';
import { EncFormApiDisplayFields } from 'src/stores/FormStore';
import { EncounterFormRaw } from 'src/utils/types';
import { isString, isArray } from 'src/utils';
import DateComponent from '../DateComponent';
import Table from '../Table';
import { CellInfo } from 'react-table';

export interface EncounterFormDetailsModalProps {
    encounterForm: EncounterFormRaw;
    displayFields: EncFormApiDisplayFields;
    onClose: Function;
}
function isDateStr(value: string) {
    return (
        value &&
        isString(value) &&
        (value.length === 24 || value.length === 20) &&
        value[10] === 'T' &&
        value.endsWith('Z')
    );
}
export function EncounterFormDetailsModal(props: EncounterFormDetailsModalProps) {
    const fieldsToDisplay = [
        ...props.displayFields['general'],
        ...props.displayFields[props.encounterForm.formCategory],
    ];

    const formColumns = [
        {
            Header: 'FIELD',
            minWidth: 150,
            accessor: 'label',
        },
        {
            Header: 'VALUE',
            minWidth: 150,
            Cell: (cellProps: CellInfo) => {
                const field = cellProps.original;
                const value =
                    field.table === 'encounterForm'
                        ? (props.encounterForm as any)[field.field] ? (props.encounterForm as any)[field.field].toString() : (props.encounterForm as any)[field.field]
                        : (props.encounterForm as any)[field.table][field.field] ? (props.encounterForm as any)[field.table][field.field].toString() : (props.encounterForm as any)[field.table][field.field];
                if (isDateStr(value)) {
                    return <DateComponent date={value} />;
                } else if (isArray(value)) {
                    return value.join(',');
                } else {
                    return value;
                }
            },
        },
    ];

    return (
        <CenterModal isOpen={true} onClose={() => props.onClose()}>
            <ModalHeader
                title={props.encounterForm.formDefinitionHistory.form_defn_title}
                onClose={() => props.onClose()}
            />
            <Table columns={formColumns} data={fieldsToDisplay} />
            <ModalFooter primaryText="Close" primaryClick={() => props.onClose()} showSecondary={false} />
        </CenterModal>
    );
}

export default EncounterFormDetailsModal;
