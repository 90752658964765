import * as ROUTES from 'src/api/routes';
import request from 'src/api/utils';

export async function getValueSet(params: {} = {}) {
    const valueSet = await request({ ...ROUTES.GET_VALUE_SET, params: { ...params } });
    return valueSet.data || {};
}

export async function getValueSetById(valueSetId: number) {
    const valueSet = await request({ ...ROUTES.GET_VALUE_SET_BY_ID, pathParams: { valueSetId } });
    return valueSet.data || {};
}

export async function deactivateValueSetDetail(valueSetDetailId: number) {
    return await request({
        ...ROUTES.DEACTIVATE_VALUE_SET_DETAIL,
        pathParams: { valueSetDetailId },
    });
}

export async function reactivateValueSetDetail(valueSetDetailId: number) {
    return await request({
        ...ROUTES.REACTIVATE_VALUE_SET_DETAIL,
        pathParams: { valueSetDetailId },
    });
}

export async function updateValueSetDetail(valueSetDetailId: number, valueList: any) {
    return await request({
        ...ROUTES.UPDATE_VALUE_SET_DETAIL,
        pathParams: { valueSetDetailId },
        data: { valueList },
    });
}

export async function createValueSetDetail(valueSetId: number, valueSetDetail: any) {
    return await request({
        ...ROUTES.CREATE_VALUE_SET_DETAIL,
        pathParams: { valueSetId },
        data: { valueList: valueSetDetail },
    });
}

export async function getValueSetDetails(valueSetId: number, params?: { query?: string }) {
    const details = await request({
        ...ROUTES.GET_VALUE_SET_DETAILS,
        pathParams: { valueSetId },
        params: { ...params },
    });

    return details.data || [];
}
