import React from 'react';
import styles from './styles.module.scss';
import Flex from 'src/components/Flex';
import CenterModal from 'src/components/CenterModal';
import ModalFooter from 'src/components/ModalFooter';
import Video from '../Video';

interface VideoModalProps {
    onClose: Function;
    url: string;
}

const VideoModal = (props: VideoModalProps) => {
    return (
        <CenterModal isOpen={true} onClose={props.onClose} cardStyle={styles.videoCard}>
            <div className={styles.idleModal}>
                <Flex direction="column" className={styles.modalContent} align="center" self="stretch">
                    <Flex value={1} align="center" justify="center" self="stretch">
                        <Video src={props.url} />
                    </Flex>
                </Flex>
                <ModalFooter primaryText={'Close'} primaryClick={props.onClose} showSecondary={false} />
            </div>
        </CenterModal>
    );
};

export default VideoModal;
