import React, { useState, useContext } from 'react';
import styles from './styles.module.scss';
import SideModal from 'src/components/SideModal';
import Flex from 'src/components/Flex';
import Button from 'src/components/Button';
import ModalHeader from 'src/components/ModalHeader';
import { OptionTypes } from 'src/components/OptionDropDown';
import * as utils from 'src/utils';
import ModalFooter from 'src/components/ModalFooter';
import {
    IAgencyContact,
    NaValues,
    IAgencyServiceArea,
    IAgencyConfiguration,
    IAgencyCertificationLevelProcedure,
    IAgencyCertificationLevelMedication,
} from 'common/lib/model/nemsis3_4/demographic';
import OrgStore from 'src/stores/OrgStore';
import NemsisInput from 'src/components/NemsisInput';
import NemsisOptionDropDown from 'src/components/NemsisOptionDropDown';
import { getDropdownValue } from 'src/utils/nemsis';
import NemsisCreatable from 'src/components/NemsisCreatable';

export interface AgencyConfigurationModalProps {
    onClose: Function;
    onSave: Function;
    serviceArea?: IAgencyServiceArea;
    configuration?: IAgencyConfiguration;
}

const defaultAgencyContactEmail: IAgencyContact['emailAddresses'][0] = {
    emailAddress: '',
    typeCode: '',
};

const defaultAgencyContactPhone: IAgencyContact['phoneNumbers'][0] = {
    phoneNumber: '',
    typeCode: '',
};

const defaultCertificationLevelProcedure: IAgencyCertificationLevelProcedure = {
    certificationLevelCode: '',
    procedureCodes: [],
};

const defaultCertificationLevelMedication: IAgencyCertificationLevelMedication = {
    certificationLevelCode: '',
    medicationCodes: [],
};

const defaultConfiguration: IAgencyConfiguration = {
    certificationLicensureState: '',
    certificationLicensureLevels: [],
    permittedProcedures: [],
    permittedMedications: [],
    permittedProtocols: [],
    certificationLevelProcedures: [{ ...defaultCertificationLevelProcedure }],
    certificationLevelMedications: [{ ...defaultCertificationLevelMedication }],
    agencyProtocolCodes: [],
    agencySpecialityServiceCapabilityCodes: [],
    billingStatusCode: '',
    emdProvidedToAgencyCode: '',
    emdVendor: [],
    patientMonitoringCapabilityCodes: [],
    crewCallSigns: [],
    dispatchCenterNameOrId: [],
    notValues: {
        certificationLevelProcedures: [{}],
        certificationLevelMedications: [{}],
    },
};

export function AgencyConfigurationModal(props: AgencyConfigurationModalProps) {
    const [data, setData] = useState<IAgencyConfiguration>(
        props.configuration || {
            ...utils.clone(defaultConfiguration),
            certificationLicensureState: props.serviceArea.areaStateCode,
        },
    );
    const isEdit = !!props.configuration;

    const orgStore = useContext(OrgStore);

    function handleNaChange(property: keyof IAgencyConfiguration, subProperty?: string, index?: number) {
        const newData = { ...data };
        if (subProperty) {
            // Make sure everything is defaulted all the way down
            if (!data.notValues) {
                newData.notValues = { [property]: [] };
            }
            if (!data.notValues[property]) {
                newData.notValues = { ...data.notValues, [property]: [] };
            }
            if (!data.notValues[property][index]) {
                newData.notValues[property][index] = {};
            }

            newData.notValues[property][index][subProperty] = data.notValues[property][index][subProperty]
                ? undefined
                : NaValues.NOT_APPLICABLE;
        } else {
            newData.notValues[property] =
                data.notValues && data.notValues[property] ? undefined : NaValues.NOT_APPLICABLE;
        }
        setData(newData);
    }

    const updateInput = (param: keyof IAgencyConfiguration) => (t: string) => setData({ ...data, [param]: t });

    function updateNestedInput({
        param,
        subParam,
        index,
        value,
    }: {
        param: keyof IAgencyConfiguration;
        subParam: string;
        index: number;
        value: any;
    }) {
        const newData: any = { ...data };
        if (!newData[param]) {
            newData[param] = [];
        }
        if (!newData[param][index]) {
            newData[param][index] = {};
        }
        newData[param][index][subParam] = value;
        setData(newData);
    }

    function getNotValBool(parentProp: keyof IAgencyConfiguration, childProp?: string, index?: number) {
        if (childProp) {
            return !!(
                data &&
                data.notValues &&
                data.notValues[parentProp] &&
                data.notValues[parentProp][index] &&
                data.notValues[parentProp][index][childProp]
            );
        }
        return !!(data && data.notValues && data.notValues[parentProp]);
    }

    function handleRemoveRow(parentProp: keyof IAgencyConfiguration, index: number) {
        const currentData = { ...data };
        // @ts-ignore
        (currentData[parentProp] || []).splice(index, 1);
        // remove any not values
        (currentData.notValues[parentProp] || []).splice(index, 1);
        setData(currentData);
    }

    function handleAddRow(property: keyof IAgencyConfiguration) {
        const currentData = { ...data };
        if (property === 'certificationLevelProcedures') {
            currentData[property].push({ ...defaultCertificationLevelProcedure });
            // add an empty object to track indexes of not values correctly
            currentData.notValues[property] = [...currentData.notValues[property], {}];
        }

        if (property === 'certificationLevelMedications') {
            currentData[property].push({ ...defaultCertificationLevelMedication });
            // add an empty object to track indexes of not values correctly
            currentData.notValues[property] = [...currentData.notValues[property], {}];
        }

        setData(currentData);
    }

    function renderTopLevelNaInput({ key, label }: { key: keyof IAgencyConfiguration; label: string }) {
        return (
            <NemsisInput
                nemsisLabel={label}
                value={getNotValBool(key) ? NaValues.NOT_APPLICABLE : (data[key] as string)}
                onChangeText={updateInput(key)}
                onNaClick={() => handleNaChange(key)}
                naSelected={getNotValBool(key)}
            />
        );
    }

    function renderTopLevelInput({ key, label }: { key: keyof IAgencyConfiguration; label: string }) {
        return (
            <NemsisInput
                nemsisLabel={label}
                value={getNotValBool(key) ? NaValues.NOT_APPLICABLE : (data[key] as string)}
                onChangeText={updateInput(key)}
            />
        );
    }

    function renderDropDown({
        key,
        label,
        type,
        isMulti,
        isNa,
        nested,
        disabled,
    }: {
        key: keyof IAgencyConfiguration;
        label: string;
        type: OptionTypes;
        isMulti?: boolean;
        isNa?: boolean;
        nested?: { index: number; row: any; key: string };
        disabled?: boolean;
    }) {
        const value = nested ? nested.row[nested.key] : data[key];
        return (
            <NemsisOptionDropDown
                isDisabled={disabled}
                nemsisLabel={label}
                isMulti={isMulti}
                selectedValue={
                    getNotValBool(key, nested && nested.key, nested && nested.index) ? NaValues.NOT_APPLICABLE : value
                }
                onChange={(d: any) => {
                    const val = getDropdownValue(d, isMulti ? [] : undefined);
                    if (nested) {
                        return updateNestedInput({ param: key, subParam: nested.key, index: nested.index, value: val });
                    }
                    return setData({ ...data, [key]: val });
                }}
                {...(isNa
                    ? {
                          onNaClick: () => handleNaChange(key, nested && nested.key, nested && nested.index),
                          naSelected: getNotValBool(key, nested && nested.key, nested && nested.index),
                      }
                    : {})}
                type={type}
            />
        );
    }

    function renderCreatable({
        key,
        label,
        isNa,
        nested,
        validation,
    }: {
        key: keyof IAgencyConfiguration;
        label: string;
        isNa?: boolean;
        nested?: { index: number; row: any; key: string };
        validation?: 'NPI';
    }) {
        const values = nested ? nested.row[nested.key] : data[key];
        return (
            <NemsisCreatable
                nemsisLabel={label}
                selectedValues={getNotValBool(key, nested && nested.key, nested && nested.index) ? undefined : values}
                onChange={(d: any) => {
                    if (nested) {
                        return updateNestedInput({ param: key, subParam: nested.key, index: nested.index, value: d });
                    }

                    return setData({ ...data, [key]: d });
                }}
                placeholder="(Enter Multiple)"
                {...(isNa
                    ? {
                          naSelected: getNotValBool(key, nested && nested.key, nested && nested.index),
                          onNaClick: () => handleNaChange(key, nested && nested.key, nested && nested.index),
                      }
                    : {})}
                {...(validation
                    ? {
                          validation: validation,
                      }
                    : {})}
            />
        );
    }

    async function handleSave() {
        props.onSave(data);
        props.onClose();
    }

    return (
        <SideModal isOpen={true} onClose={() => props.onClose()}>
            <ModalHeader title={isEdit ? `Edit Configuration` : 'Create Configuration'} onClose={props.onClose} />
            <div className={styles.modalContent}>
                <Flex direction="column" className={styles.form}>
                    <Flex direction="row" justify="between" className={styles.formRow}>
                        <Flex value={1}>
                            {renderDropDown({
                                key: 'certificationLicensureState',
                                label: 'State',
                                type: OptionTypes.ANSIStateCodes,
                                disabled: true,
                            })}
                        </Flex>
                    </Flex>
                    <Flex direction="row" justify="between" className={styles.formRow}>
                        <Flex value={1}>
                            {renderDropDown({
                                key: 'certificationLicensureLevels',
                                label: 'License Levels',
                                type: OptionTypes.EMSLicensureLevels,
                                isMulti: true,
                            })}
                        </Flex>
                    </Flex>
                    <Flex direction="row" justify="between" className={styles.formRow}>
                        <Flex value={1}>
                            {renderDropDown({
                                key: 'permittedProcedures',
                                label: 'Permitted Procedures',
                                type: OptionTypes.EMSProceduresPermittedByState,
                                isMulti: true,
                                isNa: true,
                            })}
                        </Flex>
                    </Flex>
                    <Flex direction="row" justify="between" className={styles.formRow}>
                        <Flex value={1}>
                            {renderDropDown({
                                key: 'permittedMedications',
                                label: 'Permitted Medications',
                                type: OptionTypes.EMSMedicationsPermittedByState,
                                isMulti: true,
                                isNa: true,
                            })}
                        </Flex>
                    </Flex>
                    <Flex direction="row" justify="between" className={styles.formRow}>
                        <Flex value={1}>
                            {renderDropDown({
                                key: 'permittedProtocols',
                                label: 'Permitted Protocols',
                                type: OptionTypes.EMSProtocolsUsed,
                                isMulti: true,
                                isNa: true,
                            })}
                        </Flex>
                    </Flex>
                    <Flex direction="row" justify="between" className={styles.formRow}>
                        <Flex value={1} direction="column" className={styles.newRow}>
                            <span className={styles.heading}>Certification Level Procedures & Medications</span>
                            {(data.certificationLevelProcedures || []).map((s, index) => (
                                <Flex
                                    key={`${index}`}
                                    direction="column"
                                    justify="between"
                                    align="stretch"
                                    className={styles.formRow}
                                >
                                    <Flex value={1}>
                                        <NemsisOptionDropDown
                                            nemsisLabel="Certification Level"
                                            selectedValue={s.certificationLevelCode}
                                            onChange={(d: any) => {
                                                const val = getDropdownValue(d, []);
                                                const newData: IAgencyConfiguration = { ...data };

                                                newData.certificationLevelProcedures[
                                                    index
                                                ].certificationLevelCode = val;
                                                newData.certificationLevelMedications[
                                                    index
                                                ].certificationLevelCode = val;

                                                return setData(newData);
                                            }}
                                            type={OptionTypes.EMSStateCertificationLicensureLevels}
                                        />
                                    </Flex>
                                    <Flex value={1}>
                                        <NemsisOptionDropDown
                                            nemsisLabel="Procedures"
                                            isMulti={true}
                                            selectedValue={s.procedureCodes}
                                            onChange={(d: any) => {
                                                const val = getDropdownValue(d, []);
                                                const newData: IAgencyConfiguration = { ...data };

                                                newData.certificationLevelProcedures[index].procedureCodes = val;

                                                return setData(newData);
                                            }}
                                            type={OptionTypes.EMSAgencyProcedures}
                                        />
                                    </Flex>
                                    <Flex value={1}>
                                        <NemsisOptionDropDown
                                            nemsisLabel="Medications"
                                            isMulti={true}
                                            selectedValue={data.certificationLevelMedications[index].medicationCodes}
                                            onChange={(d: any) => {
                                                const val = getDropdownValue(d, []);
                                                const newData: IAgencyConfiguration = { ...data };

                                                newData.certificationLevelMedications[index].medicationCodes = val;

                                                return setData(newData);
                                            }}
                                            type={OptionTypes.EMSAgencyMedications}
                                        />
                                    </Flex>
                                    <Flex value={1} align="end" justify="end">
                                        <Button
                                            type="primary"
                                            text="Remove"
                                            onClick={() => {
                                                handleRemoveRow('certificationLevelProcedures', index);
                                                handleRemoveRow('certificationLevelMedications', index);
                                            }}
                                        />
                                    </Flex>
                                </Flex>
                            ))}
                            <Button
                                type="transparent"
                                text="+ Add Certification"
                                onClick={() => {
                                    handleAddRow('certificationLevelProcedures');
                                    handleAddRow('certificationLevelMedications');
                                }}
                                className={styles.addUnderline}
                            />
                        </Flex>
                    </Flex>
                    <Flex direction="row" justify="between" className={styles.formRow}>
                        <Flex value={1}>
                            {renderDropDown({
                                key: 'agencyProtocolCodes',
                                label: 'Protocols Used',
                                type: OptionTypes.EMSProtocolsUsed,
                                isMulti: true,
                            })}
                        </Flex>
                    </Flex>
                    <Flex direction="row" justify="between" className={styles.formRow}>
                        <Flex value={1}>
                            {renderDropDown({
                                key: 'agencySpecialityServiceCapabilityCodes',
                                label: 'Speciality Services',
                                type: OptionTypes.EMSAgencySpecialtyServiceCapability,
                                isMulti: true,
                            })}
                        </Flex>
                    </Flex>
                    <Flex direction="row" justify="between" className={styles.formRow}>
                        <Flex value={1}>
                            {renderDropDown({
                                key: 'billingStatusCode',
                                label: 'Billing?',
                                type: OptionTypes.EMSYesNoValues,
                            })}
                        </Flex>
                    </Flex>
                    <Flex direction="row" justify="between" className={styles.formRow}>
                        <Flex value={1}>
                            {renderDropDown({
                                key: 'emdProvidedToAgencyCode',
                                label: 'EMD Provided',
                                type: OptionTypes.EMSEMDtoAgencyServiceArea,
                            })}
                        </Flex>
                    </Flex>
                    <Flex direction="row" justify="between" className={styles.formRow}>
                        <Flex value={1}>
                            {renderCreatable({
                                key: 'emdVendor',
                                label: 'EMD Vendor',
                                isNa: true,
                            })}
                        </Flex>
                    </Flex>
                    <Flex direction="row" justify="between" className={styles.formRow}>
                        <Flex value={1}>
                            {renderDropDown({
                                key: 'patientMonitoringCapabilityCodes',
                                label: 'Patient Monitoring',
                                type: OptionTypes.EMSPatientMonitoringCapability,
                                isMulti: true,
                            })}
                        </Flex>
                    </Flex>
                    <Flex direction="row" justify="between" className={styles.formRow}>
                        <Flex value={1}>
                            <NemsisCreatable
                                nemsisLabel="Crew Call Signs"
                                selectedValues={data.crewCallSigns}
                                onChange={(d: any) => setData({ ...data, crewCallSigns: d })}
                                placeholder="(Enter Multiple)"
                            />
                        </Flex>
                    </Flex>
                    <Flex direction="row" justify="between" className={styles.formRow}>
                        <Flex value={1}>
                            {
                                <NemsisCreatable
                                    nemsisLabel="Dispatch Center Name/ID"
                                    selectedValues={data.dispatchCenterNameOrId}
                                    onChange={(d: any) => setData({ ...data, dispatchCenterNameOrId: d })}
                                    placeholder="(Enter Multiple)"
                                />
                            }
                        </Flex>
                    </Flex>
                </Flex>
            </div>
            <ModalFooter
                primaryText={isEdit ? 'Save' : 'Create'}
                primaryClick={handleSave}
                secondaryClick={() => {
                    setData(utils.clone(defaultConfiguration));
                    props.onClose();
                }}
            />
        </SideModal>
    );
}

export default AgencyConfigurationModal;
