import { Customer, CustomerStatus, CustomerSubscriptionData, OffboardReason } from 'common/lib/entity/index/Customer';
import { format } from 'date-fns';
import { observer } from 'mobx-react';
import React, { useContext, useState } from 'react';
import CustomerStore from 'src/stores/CustomerStore';
import { ToastStoreObject, ToastType } from 'src/stores/ToastStore';
import { parseError } from 'src/utils';
import { ANALYTICS_NAMES } from 'src/utils/analytics';
import { API_DATE_FORMAT } from 'src/utils/constants';
import Checkbox from '../Checkbox';
import DatePicker from '../DatePicker';
import Input, { Label } from '../Input';
import LoadingIcon from '../LoadingIcon';
import ModalFooter from '../ModalFooter';
import ModalHeader from '../ModalHeader';
import SideModal from '../SideModal';
import CustomerStatusDropdown from './CustomerStatusDropdown';
import OffboardedToCustomerDropdown from './OffboardedToCustomerDropdown';
import OffboardReasonDropdown from './OffboardReasonDropdown';
import styles from './styles.module.scss';

interface CustomerStatusModalProps {
    customer?: Customer;
    onClose?: Function;
    onSave?: Function;
}

function CustomerStatusModal(props: CustomerStatusModalProps) {
    const customerStore = useContext(CustomerStore);

    const [isLoading, setIsLoading] = useState(false);
    const [customer, setCustomer] = useState<Partial<Customer>>(props.customer ? props.customer : {});
    const [initialContractDate, setInitialContractDate] = useState<CustomerSubscriptionData['initialContractDate']>(
        customer ? customer?.subscriptionData?.initialContractDate : null,
    );
    const [customerStatus, setCustomerStatus] = useState<CustomerSubscriptionData['customerStatus']>(
        customer ? customer?.subscriptionData?.customerStatus : null,
    );
    const [isTrialCustomer, setIsTrialCustomer] = useState<CustomerSubscriptionData['isTrialCustomer']>(
        customer ? customer?.subscriptionData?.isTrialCustomer : false,
    );
    const [isDemoCustomer, setIsDemoCustomer] = useState<CustomerSubscriptionData['isDemoCustomer']>(
        customer ? customer?.subscriptionData?.isDemoCustomer : null,
    );
    const [offboardDate, setOffboardDate] = useState<CustomerSubscriptionData['offboardDate']>(
        customer ? customer?.subscriptionData?.offboardDate : null,
    );
    const [offboardReason, setOffboardReason] = useState<CustomerSubscriptionData['offboardReason']>(
        customer ? customer?.subscriptionData?.offboardReason : null,
    );
    const [otherOffboardReason, setOtherOffboardReason] = useState<CustomerSubscriptionData['otherOffboardReason']>(
        customer ? customer?.subscriptionData?.otherOffboardReason : null,
    );
    const [offboardedToCustomerId, setOffboardedToCustomerId] = useState<
        CustomerSubscriptionData['offboardedToCustomerId']
    >(customer ? customer?.subscriptionData?.offboardedToCustomerId : null);

    async function handleSave() {
        setIsLoading(true);
        try {
            await customerStore.updateCustomerStatus(props.customer.customerId, {
                initialContractDate,
                customerStatus,
                isTrialCustomer,
                isDemoCustomer,
                offboardDate,
                offboardReason,
                otherOffboardReason,
                offboardedToCustomerId,
            });
            if (props.onSave) {
                props.onSave();
            }
            if (props.onClose) {
                props.onClose();
            }
            ToastStoreObject.show(`${customer.customerName} has been updated`, ToastType.Success);
        } catch (e: any) {
            ToastStoreObject.show(parseError(e));
        } finally {
            setIsLoading(false);
        }
    }

    function handleCustomerStatusChange(statusSelection: { value: CustomerStatus; label: string }) {
        setCustomerStatus(statusSelection.value);
    }

    function handleOffboardReasonChange(offBoardReasonSelection: { value: OffboardReason; label: string }) {
        setOffboardReason(offBoardReasonSelection.value);
    }

    function handleOffboardedToCustomerChange(offBoardToCustomerSelection: { value: OffboardReason; label: string }) {
        setOffboardedToCustomerId(offBoardToCustomerSelection.value);
    }

    function showOffboardingOptions(): boolean {
        return customerStatus === CustomerStatus.Offboarded || customerStatus === CustomerStatus.Offboarding;
    }

    return (
        <SideModal isOpen={true} onClose={props.onClose}>
            <ModalHeader title={'Edit Customer Status'} onClose={props.onClose} />
            {isLoading ? (
                <div className={styles.loadingContentWrap}>
                    <LoadingIcon />
                </div>
            ) : (
                <div className={styles.contentWrap}>
                    <>
                        <Label text="Initial Contract Date" />
                        <DatePicker
                            value={initialContractDate}
                            onChange={(date) => {
                                if (date) {
                                    const value = format(date, API_DATE_FORMAT);
                                    setInitialContractDate(value);
                                }
                            }}
                            data-test-id={ANALYTICS_NAMES.CustomerStatusModal_InitialContractDate}
                        />
                    </>
                    <>
                        <Label text="Customer Status" />
                        <CustomerStatusDropdown
                            selectedValue={customerStatus}
                            onChange={(e: any) => handleCustomerStatusChange(e)}
                            data-test-id={ANALYTICS_NAMES.CustomerStatusModal_CustomerStatus}
                        />
                    </>
                    {showOffboardingOptions() && (
                        <>
                            <>
                                <Label text="Offboard Reason" />
                                <OffboardReasonDropdown
                                    selectedValue={offboardReason}
                                    onChange={(e: any) => handleOffboardReasonChange(e)}
                                    data-test-id={ANALYTICS_NAMES.CustomerStatusModal_OffboardReason}
                                />
                            </>
                            {offboardReason === OffboardReason.Other && (
                                <Input
                                    label="Other Offboard Reason"
                                    value={otherOffboardReason}
                                    onChangeText={(v: string) => setOtherOffboardReason(v)}
                                    data-test-id={ANALYTICS_NAMES.CustomerModal_OtherOffboardReason}
                                />
                            )}
                            {offboardReason === OffboardReason.ContractTransferred && (
                                <>
                                    <Label text="Customer Transferred To" />
                                    <OffboardedToCustomerDropdown
                                        selectedValue={offboardedToCustomerId as string}
                                        onChange={(e: any) => handleOffboardedToCustomerChange(e)}
                                        data-test-id={ANALYTICS_NAMES.CustomerStatusModal_OffboardedToCustomer}
                                    />
                                </>
                            )}
                            <>
                                <Label text="Offboard Date" />
                                <DatePicker
                                    value={offboardDate}
                                    onChange={(date) => {
                                        if (date) {
                                            const value = format(date, API_DATE_FORMAT);
                                            setOffboardDate(value);
                                        }
                                    }}
                                    data-test-id={ANALYTICS_NAMES.CustomerStatusModal_OffboardDate}
                                />
                            </>
                        </>
                    )}
                    <Checkbox
                        checked={isTrialCustomer}
                        onChange={() => {
                            if (isTrialCustomer === false) {
                                setIsTrialCustomer(true);
                            } else {
                                setIsTrialCustomer(false);
                            }
                        }}
                        label={'Trial Customer?'}
                        className={styles.checkbox}
                        data-test-id={ANALYTICS_NAMES.CustomerStatusModal_IsTrialCustomer}
                    />
                    <Checkbox
                        checked={isDemoCustomer}
                        onChange={() => {
                            if (isDemoCustomer === false) {
                                setIsDemoCustomer(true);
                            } else {
                                setIsDemoCustomer(false);
                            }
                        }}
                        label={'Demo Customer?'}
                        className={styles.checkbox}
                        data-test-id={ANALYTICS_NAMES.CustomerStatusModal_IsDemoCustomer}
                    />
                </div>
            )}
            <ModalFooter
                primaryText={'Save'}
                primaryClick={handleSave}
                secondaryClick={() => props.onClose()}
                isLoading={isLoading}
            />
        </SideModal>
    );
}

export default observer(CustomerStatusModal);
