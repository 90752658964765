import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { observer } from 'mobx-react';
import { getPatientActivity } from 'src/api/patients';
import { format } from 'date-fns';
import Card from 'src/components/Card';
import { ICONS, DISPLAY_DATE_FORMAT, DISPLAY_TIME_FORMAT } from 'src/utils/constants';
import Icon from 'src/components/Icon';
import * as variables from 'src/styles/variables';
import { EventStateStoreObject } from 'src/stores/EventStateStore';
import { PatientStateStoreObject } from 'src/stores/PatientStateStore';
import { PatientNeedStoreObject } from 'src/stores/PatientNeedStore';
import Select from 'react-select';
import { Patient } from 'src/stores/PatientStore';

export interface ActivityProps {
    patientId: Patient['patientId'];
    patient: Patient;
}

interface AuditObject {
    auditTimestamp: number;
    entityKeys: {
        patientId: number;
        facilityId: number;
    };
    eventData: {
        [key: string]: string;
    };
    eventType: string;
    indexUserId: string;
    firstName: string;
    lastName: string;
    orgInternalName: string;
    orgUserId: string;
    source: {
        os: string;
        app: string;
        browser: string;
        ipAddress: string;
        appVersion: string;
    };
    userName: string;
}

interface AuditEntry {
    auditObject: AuditObject;
    auditTimestamp: string;
}

const ACTIVITY_TITLES: { [key: string]: string } = {
    appointment: 'Appointment',
    task: 'Task',
    patient: 'Patient',
    patientNeed: 'Patient Need',
    patientAttachment: 'Patient Attachment',
    patientNote: 'Patient Note',
    patientMedication: 'Patient Medication',
    encounter: 'Encounter',
    encounterForm: 'EncounterForm',
    tag: 'Tag',
    telehealthSession: 'Telehealth Session',
};

const ACTIVITY_ICONS = {
    appointment: ICONS.Appointment,
    task: ICONS.Task,
    patient: ICONS.PatientVisit,
    patientNeed: ICONS.Need,
    patientAttachment: ICONS.Attachment,
    patientNote: ICONS.Note,
    patientMedication: ICONS.Note,
    encounter: ICONS.Visit,
    encounterForm: ICONS.Visit,
    tag: ICONS.Note,
    telehealthSession: ICONS.Note,
};

const ACTIVITY_NAMES = {
    appointment: 'an appointment',
    task: 'a task',
    patient: 'the patient',
    patientNeed: 'a need',
    patientAttachment: 'an attachment',
    patientNote: 'a note',
    patientMedication: 'a medication',
    encounter: 'an encounter',
    encounterForm: 'an encounter form',
    tag: 'a tag',
    telehealthSession: 'a telehealth session',
};

const ACTIVITY_VERBS = {
    created: 'created',
    updated: 'updated',
    statusChanged: 'changed the status of',
    accessed: 'accessed',
    removed: 'removed',
    voided: 'voided',
    unvoided: 'unvoided',
    completed: 'completed',
    cancelled: 'cancelled',
    callEnded: 'call ended',
};

const ACTIVITY_TYPES = [
    {
        id: 'APPOINTMENT_CREATED',
        title: ACTIVITY_TITLES['appointment'],
        icon: ACTIVITY_ICONS['appointment'],
        type: ACTIVITY_NAMES['appointment'],
        verb: ACTIVITY_VERBS['created'],
    },
    {
        id: 'APPOINTMENT_UPDATED',
        title: ACTIVITY_TITLES['appointment'],
        icon: ACTIVITY_ICONS['appointment'],
        type: ACTIVITY_NAMES['appointment'],
        verb: ACTIVITY_VERBS['updated'],
    },
    {
        id: 'APPOINTMENT_STATUS_CHANGED',
        title: ACTIVITY_TITLES['appointment'],
        icon: ACTIVITY_ICONS['appointment'],
        type: ACTIVITY_NAMES['appointment'],
        verb: ACTIVITY_VERBS['statusChanged'],
    },
    {
        id: 'APPOINTMENT_ACCESSED',
        title: ACTIVITY_TITLES['appointment'],
        icon: ACTIVITY_ICONS['appointment'],
        type: ACTIVITY_NAMES['appointment'],
        verb: ACTIVITY_VERBS['accessed'],
    },
    {
        id: 'TASK_CREATED',
        title: ACTIVITY_TITLES['task'],
        icon: ACTIVITY_ICONS['task'],
        type: ACTIVITY_NAMES['task'],
        verb: ACTIVITY_VERBS['created'],
    },
    {
        id: 'TASK_UPDATED',
        title: ACTIVITY_TITLES['task'],
        icon: ACTIVITY_ICONS['task'],
        type: ACTIVITY_NAMES['task'],
        verb: ACTIVITY_VERBS['updated'],
    },
    {
        id: 'TASK_STATUS_CHANGED',
        title: ACTIVITY_TITLES['task'],
        icon: ACTIVITY_ICONS['task'],
        type: ACTIVITY_NAMES['task'],
        verb: ACTIVITY_VERBS['statusChanged'],
    },
    {
        id: 'TASK_ACCESSED',
        title: ACTIVITY_TITLES['task'],
        icon: ACTIVITY_ICONS['task'],
        type: ACTIVITY_NAMES['task'],
        verb: ACTIVITY_VERBS['accessed'],
    },
    {
        id: 'PATIENT_NEED_CREATED',
        title: ACTIVITY_TITLES['patientNeed'],
        icon: ACTIVITY_ICONS['patientNeed'],
        type: ACTIVITY_NAMES['patientNeed'],
        verb: ACTIVITY_VERBS['created'],
    },
    {
        id: 'PATIENT_NEED_UPDATED',
        title: ACTIVITY_TITLES['patientNeed'],
        icon: ACTIVITY_ICONS['patientNeed'],
        type: ACTIVITY_NAMES['patientNeed'],
        verb: ACTIVITY_VERBS['updated'],
    },
    {
        id: 'PATIENT_NEED_STATUS_CHANGED',
        title: ACTIVITY_TITLES['patientNeed'],
        icon: ACTIVITY_ICONS['patientNeed'],
        type: ACTIVITY_NAMES['patientNeed'],
        verb: ACTIVITY_VERBS['statusChanged'],
    },
    {
        id: 'PATIENT_ATTACHMENT_CREATED',
        title: ACTIVITY_TITLES['patientAttachment'],
        icon: ACTIVITY_ICONS['patientAttachment'],
        type: ACTIVITY_NAMES['patientAttachment'],
        verb: ACTIVITY_VERBS['created'],
    },
    {
        id: 'PATIENT_ATTACHMENT_UPDATED',
        title: ACTIVITY_TITLES['patientAttachment'],
        icon: ACTIVITY_ICONS['patientAttachment'],
        type: ACTIVITY_NAMES['patientAttachment'],
        verb: ACTIVITY_VERBS['updated'],
    },
    {
        id: 'PATIENT_ATTACHMENT_REMOVED',
        title: ACTIVITY_TITLES['patientAttachment'],
        icon: ACTIVITY_ICONS['patientAttachment'],
        type: ACTIVITY_NAMES['patientAttachment'],
        verb: ACTIVITY_VERBS['removed'],
    },
    {
        id: 'PATIENT_NOTE_CREATED',
        title: ACTIVITY_TITLES['patientNote'],
        icon: ACTIVITY_ICONS['patientNote'],
        type: ACTIVITY_NAMES['patientNote'],
        verb: ACTIVITY_VERBS['created'],
    },
    {
        id: 'PATIENT_NOTE_UPDATED',
        title: ACTIVITY_TITLES['patientNote'],
        icon: ACTIVITY_ICONS['patientNote'],
        type: ACTIVITY_NAMES['patientNote'],
        verb: ACTIVITY_VERBS['updated'],
    },
    {
        id: 'PATIENT_NOTE_REMOVED',
        title: ACTIVITY_TITLES['patientNote'],
        icon: ACTIVITY_ICONS['patientNote'],
        type: ACTIVITY_NAMES['patientNote'],
        verb: ACTIVITY_VERBS['removed'],
    },
    {
        id: 'PATIENT_CREATED',
        title: ACTIVITY_TITLES['patient'],
        icon: ACTIVITY_ICONS['patient'],
        type: ACTIVITY_NAMES['patient'],
        verb: ACTIVITY_VERBS['created'],
    },
    {
        id: 'PATIENT_STATUS_CHANGED',
        title: ACTIVITY_TITLES['patient'],
        icon: ACTIVITY_ICONS['patient'],
        type: ACTIVITY_NAMES['patient'],
        verb: ACTIVITY_VERBS['statusChanged'],
    },
    {
        id: 'PATIENT_UPDATED',
        title: ACTIVITY_TITLES['patient'],
        icon: ACTIVITY_ICONS['patient'],
        type: ACTIVITY_NAMES['patient'],
        verb: ACTIVITY_VERBS['updated'],
    },
    /*{
        id: 'PATIENT_ACCESSED',
        title: ACTIVITY_TITLES['patient'],
        icon: ACTIVITY_ICONS['patient'],
        type: ACTIVITY_NAMES['patient'],
        verb: ACTIVITY_VERBS['accessed'],
    },*/
    {
        id: 'PATIENT_MEDICATION_CREATED',
        title: ACTIVITY_TITLES['patientMedication'],
        icon: ACTIVITY_ICONS['patientMedication'],
        type: ACTIVITY_NAMES['patientMedication'],
        verb: ACTIVITY_VERBS['created'],
    },
    {
        id: 'PATIENT_MEDICATION_UPDATED',
        title: ACTIVITY_TITLES['patientMedication'],
        icon: ACTIVITY_ICONS['patientMedication'],
        type: ACTIVITY_NAMES['patientMedication'],
        verb: ACTIVITY_VERBS['updated'],
    },
    {
        id: 'ENCTR_CREATED',
        title: ACTIVITY_TITLES['encounter'],
        icon: ACTIVITY_ICONS['encounter'],
        type: ACTIVITY_NAMES['encounter'],
        verb: ACTIVITY_VERBS['created'],
    },
    {
        id: 'ENCTR_UPDATED',
        title: ACTIVITY_TITLES['encounter'],
        icon: ACTIVITY_ICONS['encounter'],
        type: ACTIVITY_NAMES['encounter'],
        verb: ACTIVITY_VERBS['updated'],
    },
    {
        id: 'ENCTR_ACCESSED',
        title: ACTIVITY_TITLES['encounter'],
        icon: ACTIVITY_ICONS['encounter'],
        type: ACTIVITY_NAMES['encounter'],
        verb: ACTIVITY_VERBS['accessed'],
    },
    {
        id: 'ENCTR_FORM_CREATED',
        title: ACTIVITY_TITLES['encounterForm'],
        icon: ACTIVITY_ICONS['encounterForm'],
        type: ACTIVITY_NAMES['encounterForm'],
        verb: ACTIVITY_VERBS['created'],
    },
    {
        id: 'ENCTR_FORM_UPDATED',
        title: ACTIVITY_TITLES['encounterForm'],
        icon: ACTIVITY_ICONS['encounterForm'],
        type: ACTIVITY_NAMES['encounterForm'],
        verb: ACTIVITY_VERBS['updated'],
    },
    {
        id: 'ENCTR_FORM_MODEL_UPDATED',
        title: ACTIVITY_TITLES['encounterForm'],
        icon: ACTIVITY_ICONS['encounterForm'],
        type: ACTIVITY_NAMES['encounterForm'],
        verb: ACTIVITY_VERBS['updated'],
    },
    {
        id: 'ENCTR_FORM_ACCESSED',
        title: ACTIVITY_TITLES['encounterForm'],
        icon: ACTIVITY_ICONS['encounterForm'],
        type: ACTIVITY_NAMES['encounterForm'],
        verb: ACTIVITY_VERBS['accessed'],
    },
    {
        id: 'ENCTR_FORM_VOIDED',
        title: ACTIVITY_TITLES['encounterForm'],
        icon: ACTIVITY_ICONS['encounterForm'],
        type: ACTIVITY_NAMES['encounterForm'],
        verb: ACTIVITY_VERBS['voided'],
    },
    {
        id: 'ENCTR_FORM_UNVOIDED',
        title: ACTIVITY_TITLES['encounterForm'],
        icon: ACTIVITY_ICONS['encounterForm'],
        type: ACTIVITY_NAMES['encounterForm'],
        verb: ACTIVITY_VERBS['unvoided'],
    },
    {
        id: 'TAG_CREATED',
        title: ACTIVITY_TITLES['tag'],
        icon: ACTIVITY_ICONS['tag'],
        type: ACTIVITY_NAMES['tag'],
        verb: ACTIVITY_VERBS['created'],
    },
    {
        id: 'TAG_UPDATED',
        title: ACTIVITY_TITLES['tag'],
        icon: ACTIVITY_ICONS['tag'],
        type: ACTIVITY_NAMES['tag'],
        verb: ACTIVITY_VERBS['updated'],
    },
    {
        id: 'TELEHEALTH_SESSION_CREATED',
        title: ACTIVITY_TITLES['telehealthSession'],
        icon: ACTIVITY_ICONS['telehealthSession'],
        type: ACTIVITY_NAMES['telehealthSession'],
        verb: ACTIVITY_VERBS['created'],
    },
    {
        id: 'TELEHEALTH_SESSION_UPDATED',
        title: ACTIVITY_TITLES['telehealthSession'],
        icon: ACTIVITY_ICONS['telehealthSession'],
        type: ACTIVITY_NAMES['telehealthSession'],
        verb: ACTIVITY_VERBS['updated'],
    },
    {
        id: 'TELEHEALTH_SESSION_COMPLETED',
        title: ACTIVITY_TITLES['telehealthSession'],
        icon: ACTIVITY_ICONS['telehealthSession'],
        type: ACTIVITY_NAMES['telehealthSession'],
        verb: ACTIVITY_VERBS['completed'],
    },
    {
        id: 'TELEHEALTH_SESSION_CANCELLED',
        title: ACTIVITY_TITLES['telehealthSession'],
        icon: ACTIVITY_ICONS['telehealthSession'],
        type: ACTIVITY_NAMES['telehealthSession'],
        verb: ACTIVITY_VERBS['cancelled'],
    },
    {
        id: 'TELEHEALTH_CALL_ENDED',
        title: ACTIVITY_TITLES['telehealthSession'],
        icon: ACTIVITY_ICONS['telehealthSession'],
        type: ACTIVITY_NAMES['telehealthSession'],
        verb: ACTIVITY_VERBS['callEnded'],
    },
];

function getStatusText(type: string, statusFrom: string | number, statusTo: string | number) {
    let statusFromText, statusToText;

    if (type === 'TASK_STATUS_CHANGED' || type === 'APPOINTMENT_STATUS_CHANGED') {
        const eventStatesObject = EventStateStoreObject.eventStatesObject;
        statusFromText = Object.keys(eventStatesObject).find(
            (key) => eventStatesObject[key].eventStateId === statusFrom,
        );
        statusToText = Object.keys(eventStatesObject).find((key) => eventStatesObject[key].eventStateId === statusTo);
    }

    if (type === 'PATIENT_NEED_STATUS_CHANGED') {
        statusFromText =
            (PatientNeedStoreObject.needStates.find((state) => state.patientNeedStateId === statusFrom) &&
                PatientNeedStoreObject.needStates.find((state) => state.patientNeedStateId === statusFrom)
                    .patientNeedStateTitle) ||
            'Unknown';
        statusToText =
            (PatientNeedStoreObject.needStates.find((state) => state.patientNeedStateId === statusTo) &&
                PatientNeedStoreObject.needStates.find((state) => state.patientNeedStateId === statusTo)
                    .patientNeedStateTitle) ||
            'Unknown';
    }

    if (type === 'PATIENT_STATUS_CHANGED') {
        statusFromText =
            (PatientStateStoreObject.patientStates.find((state) => state.patientStateId === statusFrom) &&
                PatientStateStoreObject.patientStates.find((state) => state.patientStateId === statusFrom)
                    .patientStateTitle) ||
            'Unknown';
        statusToText =
            (PatientStateStoreObject.patientStates.find((state) => state.patientStateId === statusTo) &&
                PatientStateStoreObject.patientStates.find((state) => state.patientStateId === statusTo)
                    .patientStateTitle) ||
            'Unknown';
    }

    return `from ${statusFromText} to ${statusToText}`;
}

function Activity(props: ActivityProps) {
    useEffect(() => {
        getPatientNeedStates();
        getPatientStates();
        getActivity();
    }, []);

    const [entries, setEntries] = useState<AuditEntry[]>([]);
    const [currentFilters, setCurrentFilters] = useState([]);

    async function getActivity() {
        const activity = await getPatientActivity(props.patientId);
        setEntries(activity.data || []);
    }

    async function getPatientStates() {
        await PatientStateStoreObject.getPatientStates();
    }

    async function getPatientNeedStates() {
        await PatientNeedStoreObject.getPatientNeedStates();
    }

    function parseActivityFeed() {
        const activities = entries.map((entry, index) => {
            const auditObject = entry.auditObject;
            const activityType = ACTIVITY_TYPES.find((type) => type.id === auditObject.eventType);

            if (!activityType) {
                return;
            }

            if (
                currentFilters &&
                currentFilters.length &&
                !currentFilters.find((filter) => filter.label === activityType.title)
            ) {
                return;
            }
            const name =
                auditObject.firstName && auditObject.lastName
                    ? auditObject.firstName + ' ' + auditObject.lastName
                    : auditObject.userName;

            const statusMetadata =
                auditObject.eventType.includes('STATUS_CHANGED') &&
                auditObject.eventData &&
                auditObject.eventData.statusFrom &&
                auditObject.eventData.statusTo
                    ? getStatusText(
                          auditObject.eventType,
                          auditObject.eventData.statusFrom,
                          auditObject.eventData.statusTo,
                      )
                    : null;
            const string = `${name} ${activityType.verb} ${activityType.type} ${statusMetadata ? statusMetadata : ''}`;

            return (
                <Card
                    label={
                        <div className={styles.titleContainer}>
                            <Icon
                                name={activityType.icon}
                                size={20}
                                color={variables.red}
                                className={styles.titleIcon}
                            />
                            {activityType.title}
                        </div>
                    }
                    subLabel={format(new Date(entry.auditTimestamp), `${DISPLAY_DATE_FORMAT} - ${DISPLAY_TIME_FORMAT}`)}
                    key={index}
                    elevation={5}
                    className={styles.entry}
                >
                    <div className={styles.entryContent}>{string}</div>
                </Card>
            );
        });

        return activities;
    }

    function getFilterOptions() {
        const keys = Object.keys(ACTIVITY_TITLES);
        const options = keys.map((title: string) => ({ label: ACTIVITY_TITLES[title], value: title }));
        return options;
    }
    return (
        <div className={styles.container}>
            {entries.length ? (
                <>
                    <div className={styles.filters}>
                        <Select
                            value={currentFilters}
                            onChange={(s: any) => setCurrentFilters(s)}
                            options={getFilterOptions()}
                            placeholder="Select filter/s"
                            isMulti={true}
                        />
                    </div>
                    {parseActivityFeed()}
                </>
            ) : (
                <div className={styles.empty}>There has been no activity on this patient yet.</div>
            )}
        </div>
    );
}

export default observer(Activity);
