import React, { useEffect, useState, useRef } from 'react';

import CenterModal from 'src/components/CenterModal';

import styles from './styles.module.scss';
import Flex from 'src/components/Flex';
import ModalHeader from 'src/components/ModalHeader';
import ModalFooter from 'src/components/ModalFooter';
import { useInterval } from 'src/utils/hooks/useInterval';

export interface IdleModalProps {
    isOpen: boolean;
    onLogout(): void;
    onDismiss(): void;
}

function IdleModal(props: IdleModalProps) {
    const COUNTDOWN_DURATION: number = 60;
    const [seconds, setSeconds] = useState(COUNTDOWN_DURATION);
    const countDownInterval = useRef<NodeJS.Timer>(null);

    useInterval(() => {
        if (props.isOpen && seconds > 0) {
            setSeconds(seconds - 1);
        } else if (props.isOpen) {
            handleLogout();
        }
    }, 1000);

    const resetCountdown = () => {
        setSeconds(COUNTDOWN_DURATION);
    };

    const handleLogout = () => {
        clearInterval(countDownInterval.current);
        resetCountdown();
        props.onLogout();
    };

    const handleDismiss = () => {
        clearInterval(countDownInterval.current);
        resetCountdown();
        props.onDismiss();
    };

    return (
        <CenterModal isOpen={props.isOpen} onClose={handleDismiss}>
            <div className={styles.idleModal}>
                <ModalHeader title="You still there?" />
                <Flex direction="column" className={styles.modalContent} align="center" self="stretch">
                    <Flex value={1} align="center" justify="center">
                        <p>
                            Would you like to continue your session? For security purposes, you'll be logged out if no
                            action is taken.
                        </p>
                    </Flex>
                    <Flex value={1} align="center" justify="center">
                        <h1 className={styles.countDown}>{seconds}</h1>
                    </Flex>
                </Flex>
                <ModalFooter
                    primaryText="I'm here!"
                    primaryClick={handleDismiss}
                    secondaryText="Log Me Out"
                    secondaryClick={handleLogout}
                />
            </div>
        </CenterModal>
    );
}

export default IdleModal;
