import React, { useEffect, useState } from 'react';
import { ToastStoreObject, ToastType } from 'src/stores/ToastStore';
import { ANALYTICS_NAMES } from 'src/utils/analytics';
import Input, { Label } from '../Input';
import ModalFooter from '../ModalFooter';
import ModalHeader from '../ModalHeader';
import SideModal from '../SideModal';
import { FormCatalog, FormCatalogPage } from 'common/lib/entity/framework/FormCatalog';
import styles from './styles.module.scss';
import { useAddFormCatalogPage } from 'src/queries/useAddFormCatalogPage';
import Flex from '../Flex';

interface FormCatalogPageModalProps {
    formCatalogId: FormCatalog['formCatalogId'];
    formPage?: FormCatalogPage;
    onClose?: Function;
    onSave?: Function;
}

function FormCatalogPageModal(props: FormCatalogPageModalProps) {
    const { formCatalogId, formPage, onClose, onSave } = props;
    const [pageName, setPageName] = useState(formPage?.pageName || '');
    const [minCount, setMinCount] = useState(formPage?.minCount || null);
    const [maxCount, setMaxCount] = useState(formPage?.maxCount || null);
    const [pageImage, setPageImage] = useState(formPage?.originalPageImage || '');
    const [fileName, setFileName] = useState('');
    const {
        mutate: addFormCatalogPage,
        isLoading: isAddFormCatalogPageLoading,
        isSuccess: isAddFormCatalogPageSuccess,
        isError: isAddFormCatalogPageError,
        error: addFormCatalogPageError,
    } = useAddFormCatalogPage(formCatalogId);

    useEffect(() => {
        if (isAddFormCatalogPageSuccess) {
            ToastStoreObject.show('Form catalog created successfully', ToastType.Success);
            onClose();
            onSave();
        }

        if (isAddFormCatalogPageError) {
            // @ts-ignore
            const errorMessage = addFormCatalogPageError?.response?.data?.message || 'Failed to create form catalog';
            ToastStoreObject.show(errorMessage, ToastType.Error);
        }
    }, [isAddFormCatalogPageSuccess, isAddFormCatalogPageError]);

    async function handleCreate() {
        addFormCatalogPage({
            pageName,
            minCount,
            maxCount,
            pageImage,
        });
    }

    return (
        <SideModal isOpen={true} onClose={onClose}>
            <ModalHeader title="New Form Catalog Page" onClose={onClose} />
            <div className={styles.contentWrap}>
                <Input
                    label="Page Name"
                    value={pageName}
                    onChangeText={(v: string) => setPageName(v)}
                    data-test-id={ANALYTICS_NAMES.FormCatalogPageModal_PageName}
                />
                <Input
                    type="number"
                    label="Min Count"
                    value={minCount}
                    onChangeText={(v: number) => setMinCount(v)}
                    data-test-id={ANALYTICS_NAMES.FormCatalogPageModal_MinCount}
                />
                <Input
                    type="number"
                    label="Max Count"
                    value={maxCount}
                    onChangeText={(v: number) => setMaxCount(v)}
                    data-test-id={ANALYTICS_NAMES.FormCatalogPageModal_MaxCount}
                />
                <Label text="Page Image" className={styles.label} />
                <Flex align="center" justify="start">
                    <input
                        type="file"
                        id="modalFileInput"
                        className={styles.hidden}
                        accept="image/png,image/jpeg,image/jpg"
                        onChange={(e) => {
                            const uploadedFile = e.target.files[0];
                            if (!uploadedFile) {
                                return;
                            }

                            const reader = new FileReader();
                            reader.onload = (e) => {
                                if (e.target?.result) {
                                    setPageImage(e.target.result.toString());
                                }
                            };
                            reader.readAsDataURL(uploadedFile);

                            setFileName(uploadedFile.name);
                        }}
                    />
                    <label htmlFor="modalFileInput" className={styles.fileInputButton}>
                        Choose a File
                    </label>
                    <span className={styles.fileName}>{`${fileName ? fileName : 'No File Chosen'}`}</span>
                </Flex>
            </div>
            <ModalFooter
                primaryText="Create"
                primaryClick={handleCreate}
                secondaryClick={() => onClose()}
                isLoading={isAddFormCatalogPageLoading}
            />
        </SideModal>
    );
}

export default FormCatalogPageModal;
