import React, { useState, useContext, ReactNode } from 'react';
import styles from './styles.module.scss';
import SideModal from 'src/components/SideModal';
import Input from 'src/components/Input';
import ModalHeader from 'src/components/ModalHeader';
import Flex from 'src/components/Flex';
import * as utils from 'src/utils';
import ModalFooter from '../ModalFooter';
import { ToastStoreObject } from 'src/stores/ToastStore';
import PatientNoteAttachmentStore, { PatientAttachment } from 'src/stores/PatientNoteAttachmentStore';
import { ATTACHMENT_MIME_TYPES } from 'src/utils/constants';
import { ANALYTICS_NAMES } from 'src/utils/analytics';

interface CreateAttachmentProps {
    onClose: Function;
    onSave: Function;
    patientId: number;
    title?: ReactNode;
    facilityId: number;
    item?: PatientAttachment;
}
export function CreateAttachmentModal(props: CreateAttachmentProps) {
    const defaultAttachment = {
        patientAttachmentFilename: '',
        patientAttachmentDescription: '',
        patientAttachmentType: '',
    };
    const patientNoteAttachmentStore = useContext(PatientNoteAttachmentStore);
    const [isLoading, setIsLoading] = useState(false);
    const [attachment, setAttachment] = useState<PatientAttachment>(props.item || defaultAttachment);
    const [file, setFile] = useState(null);

    async function createAttachment() {
        try {
            setIsLoading(true);
            if (props.item) {
                await patientNoteAttachmentStore.updatePatientAttachment(
                    { patientId: props.patientId, facId: props.facilityId },
                    attachment,
                    file,
                );
            } else {
                await patientNoteAttachmentStore.createPatientAttachment(
                    { patientId: props.patientId, facId: props.facilityId },
                    attachment,
                    file,
                );
            }
            props.onSave();
            setAttachment(defaultAttachment);
        } catch (error) {
            ToastStoreObject.show(utils.parseError(error));
        } finally {
            setIsLoading(false);
        }
    }
    const updateInput = (param: string) => (t: string) => setAttachment({ ...attachment, [param]: t });
    return (
        <SideModal isOpen={true} onClose={() => props.onClose()}>
            <ModalHeader title={props.title ? props.title : 'Create an Attachment'} onClose={() => props.onClose()} />
            <div className={styles.inviteModalContentWrap}>
                <Input
                    data-test-id={ANALYTICS_NAMES.CreateAttachmentModal_Input_Description}
                    className={styles.input}
                    label="Patient Attachment Description"
                    value={attachment.patientAttachmentDescription}
                    onChangeText={updateInput('patientAttachmentDescription')}
                />
                <Flex align="center" justify="start">
                    <input
                        data-test-id={ANALYTICS_NAMES.CreateAttachmentModal_Input_File}
                        type="file"
                        id="fileInput"
                        className={styles.hidden}
                        accept={ATTACHMENT_MIME_TYPES}
                        onChange={(e) => {
                            const uploadedFile = e.target.files[0];
                            if (!uploadedFile) {
                                return;
                            }
                            setFile(uploadedFile);
                            setAttachment({
                                ...attachment,
                                patientAttachmentFilename: uploadedFile.name,
                                patientAttachmentType: uploadedFile.type,
                            });
                        }}
                    />
                    <label htmlFor="fileInput" className={styles.fileInputButton}>
                        Choose a File
                    </label>
                    <span className={styles.fileName}>
                        {attachment.patientAttachmentFilename ? attachment.patientAttachmentFilename : 'No File Chosen'}
                    </span>
                </Flex>
            </div>
            <ModalFooter
                primaryText={props.item ? 'Save' : 'Create'}
                primaryClick={createAttachment}
                secondaryClick={() => {
                    setAttachment(defaultAttachment);
                    props.onClose();
                }}
                isLoading={isLoading}
            />
        </SideModal>
    );
}

export default CreateAttachmentModal;
