import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import Icon from 'src/components/Icon';
import { ANALYTICS_NAMES } from 'src/utils/analytics';
import Flex from 'src/components/Flex';
import { ICONS } from 'src/utils/constants';
import * as variables from 'src/styles/variables';
interface AccordionProps extends React.HTMLAttributes<HTMLDivElement> {
    label: React.ReactNode;
    startOpen?: boolean;
    onToggle?: (e: boolean) => any;
    rightAction?: React.ReactNode;
    bottomAction?: React.ReactNode;
    isEditing?: boolean;
    removeExtraSpacing?: boolean;
    removeExtraPadding?: boolean;
}

const Accordion: React.SFC<AccordionProps> = ({
    children,
    className,
    label,
    startOpen = false,
    onToggle,
    rightAction,
    isEditing,
    bottomAction,
    removeExtraSpacing,
    removeExtraPadding,
    ...rest
}) => {
    const [isOpen, setIsOpen] = useState(startOpen);

    useEffect(() => {
        if (isEditing) {
            setIsOpen(true);
        }
    }, [isEditing]);

    return (
        <div {...rest} className={classNames(styles.container, className)}>
            <Flex direction="row" align="start" justify="center">
                <Flex
                    value={1}
                    onClick={() => {
                        if (isEditing) return;
                        const newValue = !isOpen;
                        setIsOpen(newValue);
                        onToggle && onToggle(newValue);
                    }}
                    className={classNames(styles.labelWrap, { [styles.labelWrapShortPadding]: removeExtraPadding })}
                    direction="row"
                    align="center"
                    data-test-id={ANALYTICS_NAMES.Accordion_Toggle}
                >
                    <Flex justify="center" value={removeExtraPadding ? 0.6 : 1} className={styles.arrow}>
                        <Icon
                            name={ICONS.Arrow}
                            color={variables.blue}
                            className={isOpen ? styles.iconUp : styles.iconDown}
                        />
                    </Flex>
                    <Flex value={8}>
                        <span className={styles.label}>{label}</span>
                    </Flex>
                </Flex>
                <Flex align="center" justify="center" self="stretch">
                    {rightAction}
                </Flex>
            </Flex>
            {isOpen ? (
                <Flex className={styles.childrenWrap} data-test-id={ANALYTICS_NAMES.Accordion_Open}>
                    {removeExtraSpacing ? null : (
                        <Flex data-test-id={ANALYTICS_NAMES.Accordion_Remove_Spacing} value={1}></Flex>
                    )}
                    <Flex value={8}>{children}</Flex>
                </Flex>
            ) : null}
            <Flex align="center" justify="center" self="stretch">
                {bottomAction}
            </Flex>
        </div>
    );
};

export default Accordion;
