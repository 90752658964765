import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

interface PageContentProps extends React.HTMLAttributes<HTMLDivElement> {}

const PageContent: React.SFC<PageContentProps> = ({ children, className, ...rest }) => {
    return (
        <div {...rest} className={classNames(styles.content, className)}>
            {children}
        </div>
    );
};

export default PageContent;
