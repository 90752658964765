import {
    ScorecardMetricSample,
    CalculatedScorecardMetric,
    ScorecardFacilitySetting,
} from 'common/lib/model/Scorecard/Scorecard';
import { Facility } from 'src/stores/FacilityStore';

type ParsedFacilityMetricData = {
    facilityName: string;
    facilityId: number;
} & {
    [key: string]: CalculatedScorecardMetric;
};

export function parseMetricsResults(
    calculatedMetrics: CalculatedScorecardMetric[],
    facilities: Facility[],
    facilitySettings: ScorecardFacilitySetting[],
    enforceRollup: boolean = true,
) {
    let parsed = calculatedMetrics.reduce(
        (facilityMap: Map<number, ParsedFacilityMetricData>, metric: CalculatedScorecardMetric) => {
            let facilityObj = facilityMap.has(metric.facilityId)
                ? facilityMap.get(metric.facilityId)
                : {
                      facilityName: metric.facilityName,
                      facilityId: metric.facilityId,
                  };

            facilityMap.set(metric.facilityId, {
                ...facilityObj,
                [metric.metricDefinition.name]: metric,
            } as ParsedFacilityMetricData);
            return facilityMap;
        },
        new Map<number, ParsedFacilityMetricData>(),
    );
    const facilityMetricValues = [...parsed.values()];
    const facilitiesToShow = facilities.reduce((acc: Map<number, ParsedFacilityMetricData>, facility: Facility) => {
        const facilitySetting = facilitySettings.find((fs) => {
            return fs.facilityId == facility.facId;
        });
        // If this facility is to be shown, lets add it to our accumulator;
        if (
            !facilitySetting ||
            !enforceRollup ||
            (facilitySetting.showInScoreCard !== false && facilitySetting.rollupToFacility == null)
        ) {
            const metrics = facilityMetricValues.find((fm: any) => {
                return fm.facilityId == facility.facId;
            });
            acc.set(
                facility.facId,
                metrics
                    ? metrics
                    : ({
                          facilityName: facility.facNm,
                          facilityId: facility.facId,
                      } as ParsedFacilityMetricData),
            );
        }
        return acc;
    }, new Map<number, ParsedFacilityMetricData>());

    return [...facilitiesToShow.values()].sort((a, b) => a.facilityName.localeCompare(b.facilityName));
}

export function calculateAgeGroups(samples: ScorecardMetricSample[]) {
    const ageTotals = samples.reduce((total: any, current: ScorecardMetricSample) => {
        const ageData = JSON.parse(current.ageFrequencyDistribution);
        const data = ageData.reduce((total: any, current: any) => {
            total['age_0_17'] = current['age_0_17'] + (total['age_0_17'] || 0);
            total['age_18_29'] = current['age_18_29'] + (total['age_18_29'] || 0);
            total['age_30_39'] = current['age_30_39'] + (total['age_30_39'] || 0);
            total['age_40_49'] = current['age_40_49'] + (total['age_40_49'] || 0);
            total['age_50_59'] = current['age_50_59'] + (total['age_50_59'] || 0);
            total['age_60_69'] = current['age_60_69'] + (total['age_60_69'] || 0);
            total['age_70_plus'] = current['age_70_plus'] + (total['age_70_plus'] || 0);
            total['age_unknown'] = current['age_unknown'] + (total['age_unknown'] || 0);

            return total;
        }, {});

        total['age_0_17'] = data['age_0_17'] + (total['age_0_17'] || 0);
        total['age_18_29'] = data['age_18_29'] + (total['age_18_29'] || 0);
        total['age_30_39'] = data['age_30_39'] + (total['age_30_39'] || 0);
        total['age_40_49'] = data['age_40_49'] + (total['age_40_49'] || 0);
        total['age_50_59'] = data['age_50_59'] + (total['age_50_59'] || 0);
        total['age_60_69'] = data['age_60_69'] + (total['age_60_69'] || 0);
        total['age_70_plus'] = data['age_70_plus'] + (total['age_70_plus'] || 0);
        total['age_unknown'] = data['age_unknown'] + (total['age_unknown'] || 0);

        return total;
    }, {});

    return [
        { label: '0-17', value: ageTotals.age_0_17 },
        { label: '18-29', value: ageTotals.age_18_29 },
        { label: '30-39', value: ageTotals.age_30_39 },
        { label: '40-49', value: ageTotals.age_40_49 },
        { label: '50-59', value: ageTotals.age_50_59 },
        { label: '60-69', value: ageTotals.age_60_69 },
        { label: '70+', value: ageTotals.age_70_plus },
        { label: 'Unknown', value: ageTotals.age_unknown },
    ];
}

export function calculateASACounts(samples: ScorecardMetricSample[]) {
    const asaTotals = samples.reduce((total: any, current: ScorecardMetricSample) => {
        const asaData = JSON.parse(current.asaFrequencyDistribution);
        const data = asaData.reduce((total: any, current: any) => {
            total['asa_1'] = current['asa_1'] + (total['asa_1'] || 0);
            total['asa_1e'] = current['asa_1e'] + (total['asa_1e'] || 0);
            total['asa_2'] = current['asa_2'] + (total['asa_2'] || 0);
            total['asa_2e'] = current['asa_2e'] + (total['asa_2e'] || 0);
            total['asa_3'] = current['asa_3'] + (total['asa_3'] || 0);
            total['asa_3e'] = current['asa_3e'] + (total['asa_3e'] || 0);
            total['asa_4'] = current['asa_4'] + (total['asa_4'] || 0);
            total['asa_4e'] = current['asa_4e'] + (total['asa_4e'] || 0);
            total['asa_5'] = current['asa_5'] + (total['asa_5'] || 0);
            total['asa_5e'] = current['asa_5e'] + (total['asa_5e'] || 0);
            total['asa_6'] = current['asa_6'] + (total['asa_6'] || 0);
            total['asa_6e'] = current['asa_6e'] + (total['asa_6e'] || 0);
            total['asa_unknown'] = current['asa_unknown'] + (total['asa_unknown'] || 0);

            return total;
        }, {});

        total['asa_1'] = data['asa_1'] + (total['asa_1'] || 0);
        total['asa_1e'] = data['asa_1e'] + (total['asa_1e'] || 0);
        total['asa_2'] = data['asa_2'] + (total['asa_2'] || 0);
        total['asa_2e'] = data['asa_2e'] + (total['asa_2e'] || 0);
        total['asa_3'] = data['asa_3'] + (total['asa_3'] || 0);
        total['asa_3e'] = data['asa_3e'] + (total['asa_3e'] || 0);
        total['asa_4'] = data['asa_4'] + (total['asa_4'] || 0);
        total['asa_4e'] = data['asa_4e'] + (total['asa_4e'] || 0);
        total['asa_5'] = data['asa_5'] + (total['asa_5'] || 0);
        total['asa_5e'] = data['asa_5e'] + (total['asa_5e'] || 0);
        total['asa_6'] = data['asa_6'] + (total['asa_6'] || 0);
        total['asa_6e'] = data['asa_6e'] + (total['asa_6e'] || 0);
        total['asa_unknown'] = data['asa_unknown'] || 0 + (total['asa_unknown'] || 0);

        return total;
    }, {});

    return [
        { label: 'ASA 1', emergent: asaTotals.asa_1e, nonEmergent: asaTotals.asa_1 },
        { label: 'ASA 2', emergent: asaTotals.asa_2e, nonEmergent: asaTotals.asa_2 },
        { label: 'ASA 3', emergent: asaTotals.asa_3e, nonEmergent: asaTotals.asa_3 },
        { label: 'ASA 4', emergent: asaTotals.asa_4e, nonEmergent: asaTotals.asa_4 },
        { label: 'ASA 5', emergent: asaTotals.asa_5e, nonEmergent: asaTotals.asa_5 },
        { label: 'ASA 6', emergent: asaTotals.asa_6e, nonEmergent: asaTotals.asa_6 },
        { label: 'Unknown', nonEmergent: asaTotals.asa_unknown },
    ];
}

export function calculateEmergentCounts(samples: ScorecardMetricSample[]) {
    const emergentTotals = samples.reduce((total: any, current: ScorecardMetricSample) => {
        const asaData = JSON.parse(current.asaFrequencyDistribution);
        const data = asaData.reduce((total: any, current: any) => {
            total['asa_1'] = current['asa_1'] + (total['asa_1'] || 0);
            total['asa_1e'] = current['asa_1e'] + (total['asa_1e'] || 0);
            total['asa_2'] = current['asa_2'] + (total['asa_2'] || 0);
            total['asa_2e'] = current['asa_2e'] + (total['asa_2e'] || 0);
            total['asa_3'] = current['asa_3'] + (total['asa_3'] || 0);
            total['asa_3e'] = current['asa_3e'] + (total['asa_3e'] || 0);
            total['asa_4'] = current['asa_4'] + (total['asa_4'] || 0);
            total['asa_4e'] = current['asa_4e'] + (total['asa_4e'] || 0);
            total['asa_5'] = current['asa_5'] + (total['asa_5'] || 0);
            total['asa_5e'] = current['asa_5e'] + (total['asa_5e'] || 0);
            total['asa_6'] = current['asa_6'] + (total['asa_6'] || 0);
            total['asa_6e'] = current['asa_6e'] + (total['asa_6e'] || 0);
            total['asa_unknown'] = current['asa_unknown'] + (total['asa_unknown'] || 0);

            return total;
        }, {});

        total['asa_1'] = data['asa_1'] + (total['asa_1'] || 0);
        total['asa_1e'] = data['asa_1e'] + (total['asa_1e'] || 0);
        total['asa_2'] = data['asa_2'] + (total['asa_2'] || 0);
        total['asa_2e'] = data['asa_2e'] + (total['asa_2e'] || 0);
        total['asa_3'] = data['asa_3'] + (total['asa_3'] || 0);
        total['asa_3e'] = data['asa_3e'] + (total['asa_3e'] || 0);
        total['asa_4'] = data['asa_4'] + (total['asa_4'] || 0);
        total['asa_4e'] = data['asa_4e'] + (total['asa_4e'] || 0);
        total['asa_5'] = data['asa_5'] + (total['asa_5'] || 0);
        total['asa_5e'] = data['asa_5e'] + (total['asa_5e'] || 0);
        total['asa_6'] = data['asa_6'] + (total['asa_6'] || 0);
        total['asa_6e'] = data['asa_6e'] + (total['asa_6e'] || 0);
        total['asa_unknown'] = data['asa_unknown'] || 0 + (total['asa_unknown'] || 0);

        return total;
    }, {});
    const nonEmergent =
        emergentTotals.asa_1 +
        emergentTotals.asa_2 +
        emergentTotals.asa_3 +
        emergentTotals.asa_4 +
        emergentTotals.asa_5 +
        emergentTotals.asa_6;

    const emergent =
        emergentTotals.asa_1e +
        emergentTotals.asa_2e +
        emergentTotals.asa_3e +
        emergentTotals.asa_4e +
        emergentTotals.asa_5e +
        emergentTotals.asa_6e;

    return [
        { label: 'Non-Emergent', value: nonEmergent },
        { label: 'Emergent', value: emergent },
        { label: 'Unknown', value: emergentTotals.asa_unknown },
    ];
}

export function calculateGenderGroups(samples: ScorecardMetricSample[]) {
    const genderTotals = samples.reduce((total: any, current: ScorecardMetricSample) => {
        const genderData = JSON.parse(current.genderFrequencyDistribution);
        const data = genderData.reduce((total: any, current: any) => {
            total['male'] = current['male'] + (total['male'] || 0);
            total['female'] = current['female'] + (total['female'] || 0);
            total['unknown'] = current['unknown'] + (total['unknown'] || 0);
            return total;
        }, {});

        total['male'] = data['male'] + (total['male'] || 0);
        total['female'] = data['female'] + (total['female'] || 0);
        total['unknown'] = data['unknown'] + (total['unknown'] || 0);

        return total;
    }, {});

    return [
        { label: 'Male', value: genderTotals.male },
        { label: 'Female', value: genderTotals.female },
        { label: 'Unknown', value: genderTotals.unknown },
    ];
}

export function calculateInVsOutPatientGroups(samples: ScorecardMetricSample[]) {
    const statusTotals = samples.reduce((total: any, current: ScorecardMetricSample) => {
        total['Inpatient'] = current.inpatientPatientCount + (total['Inpatient'] || 0);
        total['Outpatient'] = current.ambulatoryPatientCount + (total['Outpatient'] || 0);

        return total;
    }, {});

    return [
        { label: 'Inpatient', value: statusTotals['Inpatient'] },
        { label: 'Outpatient', value: statusTotals['Outpatient'] },
    ];
}

export function calculateAdmissionTypeGroups(samples: ScorecardMetricSample[]) {
    const typeTotals = samples.reduce((total: any, current: ScorecardMetricSample) => {
        total['PACU'] = current.pacuAdmissionCount + (total['PACU'] || 0);
        total['ICU'] = current.icuAdmissionCount + (total['ICU'] || 0);

        return total;
    }, {});

    return [{ label: 'PACU', value: typeTotals['PACU'] }, { label: 'ICU', value: typeTotals['ICU'] }];
}
