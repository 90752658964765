import { UseQueryResult, useQuery } from 'react-query';
import { getFormCatalogPageUrl } from 'src/api/formCatalogs';
import { UserStoreObject } from 'src/stores/UserStore';
import { FormCatalog, FormCatalogPage } from 'common/lib/entity/framework/FormCatalog';

export const formCatalogPageUrlKey = 'formCatalogPageUrl';

export const useFormCatalogPageUrl = (
    formCatalogId: FormCatalog['formCatalogId'],
    pageId: FormCatalogPage['pageId'],
): UseQueryResult<string> => {
    return useQuery({
        queryKey: [formCatalogPageUrlKey, { formCatalogId, pageId }],
        queryFn: async () => {
            const { pageUrl } = await getFormCatalogPageUrl(formCatalogId, pageId);
            return pageUrl || '';
        },
        enabled: formCatalogId && pageId && UserStoreObject.isGraphiumAdmin(),
        initialData: [],
    });
};
