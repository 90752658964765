import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import { getQuarter, parseISO } from 'date-fns';
import { observer } from 'mobx-react';
import styles from 'src/containers/Reports/Macra/styles.module.scss';
import Flex from 'src/components/Flex';
import Card from 'src/components/Card';
import FacilityDropDown from 'src/components/FacilityDropDown';
import UserStore from 'src/stores/UserStore';
import QcdrSettingsStore from 'src/stores/QcdrSettingsStore';
import MacraStore from 'src/stores/MacraStore';
import { DateRangeType } from 'common/lib/model/DateRangeType';
import { ValueMode } from 'common/lib/util/Macra';
import { styleSelectComponent } from 'src/utils';
import { ROUTES } from 'src/utils/constants';
import { getValidMacraYears } from 'src/containers/Reports/Macra/utils';
import { MacraAvailableYears } from 'common/lib/model/macra/Macra';
import classNames from 'classnames';

interface MacraControlsProps {
    year: MacraAvailableYears;
}

function MacraControls(props: MacraControlsProps) {
    const userStore = useContext(UserStore);
    const qcdrSettingsStore = useContext(QcdrSettingsStore);
    const macraStore = useContext(MacraStore);
    const history = useHistory();

    return (
        <Flex direction="column">
            <Flex direction="row" className={styles.wrapBottom}>
                <Flex value={1}>
                    <Card>
                        <Flex align="start" self="stretch" className={styles.filterWrap}>
                            <div className={styles.selectWrap}>
                                <FacilityDropDown
                                    prependOptions={
                                        userStore.userPermissions.canView.tins &&
                                        qcdrSettingsStore.tinSettings
                                            .map((setting) => {
                                                return setting.applyTinToAll ? setting.groupTin : null;
                                            })
                                            .filter((tin, index, array) => {
                                                return tin !== null && array.indexOf(tin) === index;
                                            })
                                            .map((tin) => {
                                                return { label: `TIN: ${tin}`, value: `TIN: ${tin}` };
                                            })
                                    }
                                    selectedValue={macraStore.selectedId}
                                    onChange={(s: any) => {
                                        macraStore.setSelectedId(s.value);
                                    }}
                                />
                            </div>
                            <div className={classNames(styles.selectWrap, styles.smallSelect)}>
                                <Select
                                    value={macraStore.dateOptions.find((o) => {
                                        const value =
                                            macraStore.dateType === DateRangeType.Quarter
                                                ? `Q${getQuarter(parseISO(macraStore.startDate))}`
                                                : macraStore.startDate;

                                        return o.value === value;
                                    })}
                                    onChange={(s: any) => {
                                        macraStore.setStartDate(s.value);
                                    }}
                                    className={styles.select}
                                    options={macraStore.dateOptions}
                                    placeholder="Select Date"
                                    styles={styleSelectComponent()}
                                />
                            </div>
                            <div className={styles.selectWrap}>
                                <Select
                                    value={macraStore.valueModeOptions.find((o) => o.value === macraStore.valueMode)}
                                    onChange={(s: any) => {
                                        macraStore.valueMode =
                                            s.value === ValueMode.Actual ? ValueMode.Actual : ValueMode.Projected;
                                    }}
                                    className={styles.select}
                                    options={macraStore.valueModeOptions}
                                    styles={styleSelectComponent()}
                                />
                            </div>
                        </Flex>
                    </Card>
                </Flex>
                <Flex value={0.25} className={styles.yearContainer}>
                    <Card>
                        <Flex align="center" self="stretch" direction="row" className={styles.filterWrap}>
                            <div>Year:</div>
                            <div className={styles.selectWrap}>
                                <Select
                                    value={{ value: props.year, label: props.year }}
                                    onChange={(s: any) => {
                                        if (s.value === 2023) {
                                            history.push(ROUTES.getString(ROUTES.ReportsMacra2023));
                                        } else if (s.value === 2024) {
                                            history.push(ROUTES.getString(ROUTES.ReportsMacra));
                                        }
                                    }}
                                    className={styles.select}
                                    styles={styleSelectComponent()}
                                    options={getValidMacraYears().map((year: any) => ({ value: year, label: year }))}
                                    placeholder="Select a Year"
                                />
                            </div>
                        </Flex>
                    </Card>
                </Flex>
            </Flex>
        </Flex>
    );
}

export default observer(MacraControls);
