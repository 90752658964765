import * as ROUTES from 'src/api/routes';
import request from 'src/api/utils';

export async function createReferenceList(params: {} = {}) {
    const referenceList = await request({ ...ROUTES.CREATE_REFERENCE_LIST, data: { ...params } });
    return referenceList;
}

export async function updateReferenceList(referenceListId: string | number, params: {} = {}) {
    const referenceList = await request({
        ...ROUTES.UPDATE_REFERENCE_LIST,
        pathParams: { referenceListId },
        data: { ...params },
    });
    return referenceList;
}

export async function deactivateReferenceList(referenceListId: string | number) {
    return await request({ ...ROUTES.DEACTIVATE_REFERENCE_LIST, pathParams: { referenceListId } });
}

export async function reactivateReferenceList(referenceListId: string | number) {
    return await request({ ...ROUTES.REACTIVATE_REFERENCE_LIST, pathParams: { referenceListId } });
}

export async function getReferenceLists(
    includeValueSets: boolean,
    params?: { query?: string; searchFacilityIds?: number[] },
): Promise<Array<any>> {
    const response = await request({ ...ROUTES.GET_REFERENCE_LISTS, params: { includeValueSets, ...params } });
    return response.data || [];
}

export async function getReferenceListsWithValues(params?: { listNames?: string[] }) {
    const referenceLists = await request({ ...ROUTES.GET_REFERENCE_LISTS_WITH_VALUES, params: { ...params } });
    return referenceLists;
}

export async function getReferenceList(
    referenceList: string | number,
    useId: boolean = false,
    withValues: boolean = false,
) {
    const response = await request({
        ...ROUTES.GET_REFERENCE_LIST,
        pathParams: { referenceList },
        params: { useId, withValues },
    });
    return response.data || {};
}

export async function getReferenceListValues(referenceList: string | number, params: {} = {}) {
    const values = await request({
        ...ROUTES.GET_REFERENCE_LIST_VALUES,
        pathParams: { referenceList },
        params: { ...params },
    });
    return values.data || [];
}

export async function createReferenceListValue(referenceListId: string | number, params: {} = {}) {
    return await request({
        ...ROUTES.CREATE_REFERENCE_LIST_VALUE,
        pathParams: { referenceListId },
        data: { ...params },
    });
}

export async function updateReferenceListValue(
    referenceListId: string | number,
    referenceListValueId: string | number,
    params: {} = {},
) {
    return await request({
        ...ROUTES.UPDATE_REFERENCE_LIST_VALUE,
        pathParams: { referenceListId, referenceListValueId },
        data: { ...params },
    });
}

export async function deactivateReferenceListValue(
    referenceListId: string | number,
    referenceListValueId: string | number,
) {
    return await request({
        ...ROUTES.DEACTIVATE_REFERENCE_LIST_VALUE,
        pathParams: { referenceListId, referenceListValueId },
    });
}

export async function reactivateReferenceListValue(
    referenceListId: string | number,
    referenceListValueId: string | number,
) {
    return await request({
        ...ROUTES.REACTIVATE_REFERENCE_LIST_VALUE,
        pathParams: { referenceListId, referenceListValueId },
    });
}

export async function updateReferenceListValueExcludedFacilities(
    referenceListId: string | number,
    referenceListValueId: string | number,
    excludedFacilities: number[],
) {
    return await request({
        ...ROUTES.UPDATE_REFERENCE_LIST_VALUE_EXCLUDED_FACILITIES,
        pathParams: { referenceListId, referenceListValueId },
        data: { excludedFacilities },
    });
}

export async function updateReferenceListProperty(referenceListId: number, params: {} = {}) {
    return await request({
        ...ROUTES.UPDATE_REFERENCE_LIST_PROPERTY,
        pathParams: { referenceListId },
        data: { propertyData: { ...params } },
    });
}
