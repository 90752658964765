import { ContactType } from 'common/lib/model/CustomerContactType';
import React, { useContext } from 'react';
import { CellInfo } from 'react-table';
import Button from 'src/components/Button';
import Card from 'src/components/Card';
import Flex from 'src/components/Flex';
import Table from 'src/components/Table';
import CustomerStore from 'src/stores/CustomerStore';
import { ModalStoreObject, ModalTypes } from 'src/stores/ModalStore';
import styles from '../../styles.module.scss';
import FacilityStore from 'src/stores/FacilityStore';
import { CustomerContact } from 'common/lib/entity/index/Customer';
import { ToastStoreObject } from 'src/stores/ToastStore';
import { parseError } from 'src/utils';

function CustomerContacts() {
    const customerStore = useContext(CustomerStore);
    const facilityStore = useContext(FacilityStore);
    const columns = [
        {
            Header: 'NAME',
            id: 'lastName',
            accessor: (c: any) => `${c.lastName}, ${c.firstName}`,
            Cell: (props: CellInfo) => {
                return <span>{props.value}</span>;
            },
        },
        {
            Header: 'TITLE',
            accessor: 'title',
            minWidth: 50,
            Cell: (props: CellInfo) => {
                return <span>{props.value}</span>;
            },
        },
        {
            Header: 'PHONE',
            accessor: 'phone',
            Cell: (props: CellInfo) => {
                return <span>{props.value}</span>;
            },
        },
        {
            Header: 'EMAIL',
            accessor: 'email',
            minWidth: 80,
            Cell: (props: CellInfo) => {
                return (
                    <span>
                        <a href={`mailto:${props.value}`}>{props.value}</a>
                    </span>
                );
            },
        },
    ];

    const facilityCell = {
        Header: 'Facility (Optional)',
        accessor: 'facilityId',
        minWidth: 80,
        Cell: (props: CellInfo) => {
            const { orgInternalName, facilityId } = props.original;
            const customerOrg =
                customerStore.customerOrgs.find((org: any) => org.orgNmIntrnl === orgInternalName) || {};
            const facility = customerOrg?.facilities?.find(
                (fac: any) => fac.facId.toString() === facilityId.toString(),
            );
            return <span>{facility ? facility.facNm : ''}</span>;
        },
    };

    function getActionsCell(contactType: string) {
        const cell = {
            Header: 'Actions',
            accessor: 'actions',
            minWidth: 50,
            Cell: (props: CellInfo) => (
                <Flex>
                    <Button
                        type="small"
                        text="Edit"
                        className={styles.leftButton}
                        onClick={() => {
                            ModalStoreObject.showModal(ModalTypes.CustomerContactModal, {
                                mode: 'edit',
                                onClose: () => ModalStoreObject.hideModal(),
                                onSave: () => customerStore.getCustomer(customerStore?.customer?.customerId),
                                customer: customerStore?.customer,
                                contact: props.original,
                                contactType,
                            });
                        }}
                    />
                    <Button
                        type="small"
                        text="Remove"
                        onClick={() => {
                            ModalStoreObject.showModal(ModalTypes.ConfirmationModal, {
                                title: `Are you sure you would like to remove ${props.original.firstName} ${props.original.lastName}`,
                                onConfirm: () => handleRemoveContact(props.original.contactGuid, contactType),
                                onCancel: () => ModalStoreObject.hideModal(),
                                confirmButtonText: 'Remove',
                            });
                        }}
                    />
                </Flex>
            ),
        };

        return cell;
    }

    async function handleRemoveContact(contactGuid: CustomerContact['contactGuid'], contactType: string) {
        ModalStoreObject.hideModal();
        try {
            await customerStore.removeCustomerContact(customerStore.customer?.customerId, contactGuid, contactType);
            customerStore.getCustomer(customerStore?.customer?.customerId);
        } catch (e: any) {
            ToastStoreObject.show(parseError(e));
        }
    }

    function handleAdd(contactType: string) {
        ModalStoreObject.showModal(ModalTypes.CustomerContactModal, {
            onClose: () => ModalStoreObject.hideModal(),
            onSave: () => customerStore.getCustomer(customerStore?.customer?.customerId, true),
            customer: customerStore?.customer,
            contactType,
        });
    }

    return (
        <>
            <Flex className={styles.contactSectionTitle} self="stretch" align="center">
                <h2>Primary Contacts</h2>
            </Flex>
            <Flex>
                <div className={styles.contentWrap}>
                    <p>
                        This is the main person(s) Graphium Health will be working with at your organization. Is the
                        "Graphium Health Champion" at your organization who is primarily responsible for making Graphium
                        Health a success. Is the first person we will contact when we have questions or need direction
                        in regard to your organization or account. Is a designated spokesperson for the other POC's
                        defined below, or can at least put us in contact with the other POC's listed below when
                        necessary.
                    </p>
                </div>
            </Flex>
            <Flex className={styles.filterWrap}>
                <Flex value={1} justify="end">
                    <Button
                        type="secondary"
                        text="Add Primary Contact"
                        onClick={() => handleAdd(ContactType.Primary)}
                    />
                </Flex>
            </Flex>
            <Card className={styles.cardWrap}>
                <Table
                    columns={columns.concat(getActionsCell(ContactType.Primary))}
                    data={customerStore.primaryContacts}
                    showPagination={false}
                />
            </Card>
            <Flex className={styles.contactSectionTitle} self="stretch" align="center">
                <h2>Clinical Contacts</h2>
            </Flex>
            <Flex>
                <div className={styles.contentWrap}>
                    <p>
                        The person at your organization with the clinical expertise to understand the pain points
                        Graphium Health is solving. A maximum of two can be listed, but one is preferred. This person:
                    </p>
                    <ul>
                        <li>Is the sole decision-maker when making any form-related decisions.</li>
                        <li>
                            Has a full understanding of the clinical workflow at your organization and is in charge of
                            making Graphium fit into that workflow.
                        </li>
                        <li>Can be one of the two Main POC's if this person also shares those responsibilities.</li>
                    </ul>
                </div>
            </Flex>
            <Flex className={styles.filterWrap}>
                <Flex value={1} justify="end">
                    <Button
                        type="secondary"
                        text="Add Clinical Contact"
                        onClick={() => handleAdd(ContactType.Clinical)}
                    />
                </Flex>
            </Flex>
            <Card className={styles.cardWrap}>
                <Table
                    columns={columns.concat(facilityCell).concat(getActionsCell(ContactType.Clinical))}
                    data={customerStore.clinicalContacts}
                    showPagination={false}
                />
            </Card>
            <Flex className={styles.contactSectionTitle} direction="row" align="start">
                <h2>Technical Contacts</h2>
            </Flex>
            <Flex>
                <div className={styles.contentWrap}>
                    <p>
                        This is the main person(s) at your organization with the technical expertise necessary to
                        understand and support the technical implementation. Multiple may be listed, including those who
                        represent technical ownership at peer facilities, or who are third-party and/or vendor contacts
                        who will function in this role. This person:
                    </p>
                    <ul>
                        <li>
                            Is our main contact when dealing with integration, installation, network connectivity,
                            mobile device management, printing, and/or other technical issues.
                        </li>
                        <li>Has a full understanding of any integration-related workflows for your organization.</li>
                        <li></li>
                        <li></li>
                    </ul>
                    <p>IF the facility has vitals integration, this person will be responsible for:</p>
                    <ul>
                        <li>Completing the vitals site survey of anesthesia machines.</li>
                        <li>Installing the vitals transmitters on each anesthesia machine.</li>
                        <li>Supporting the vitals transmitters after go-live.</li>
                    </ul>
                </div>
            </Flex>
            <Flex className={styles.filterWrap}>
                <Flex value={1} justify="end">
                    <Button
                        type="secondary"
                        text="Add Technical Contact"
                        onClick={() => handleAdd(ContactType.Technical)}
                    />
                </Flex>
            </Flex>
            <Card className={styles.cardWrap}>
                <Table
                    columns={columns.concat(facilityCell).concat(getActionsCell(ContactType.Technical))}
                    data={customerStore.technicalContacts}
                    showPagination={false}
                />
            </Card>
            <Flex className={styles.contactSectionTitle} direction="row" align="start">
                <h2>Billing Contacts</h2>
            </Flex>
            <Flex>
                <div className={styles.contentWrap}>
                    <p>
                        This is the main person(s) at your organization with the expertise necessary to understand and
                        support the billing integration with Graphium Health. Multiple may be listed, including those
                        who represent other peer facilities, or who are third-party and/or vendor contacts who will
                        function in this role. This person:
                    </p>

                    <ul>
                        <li>
                            Our main contact when dealing with any billing integration, installation or maintenance
                            issues.
                        </li>
                        <li>Will likely be working with the Main POC's to facilitate annual MACRA-related tasks.</li>
                        <li>Is your organization's main contact for anesthesia-related patient billing.</li>
                    </ul>
                </div>
            </Flex>
            <Flex value={1} justify="end">
                <Button type="secondary" text="Add Billing Contact" onClick={() => handleAdd(ContactType.Billing)} />
            </Flex>
            <Card className={styles.cardWrap}>
                <Table
                    columns={columns.concat(getActionsCell(ContactType.Billing))}
                    data={customerStore.billingContacts}
                    showPagination={false}
                />
            </Card>
        </>
    );
}

export default CustomerContacts;
