import React, { useState, useContext, useEffect } from 'react';
import styles from './styles.module.scss';
import TransportFacilityStore, { TransportFacility } from 'src/stores/TransportFacilityStore';
import SideModal from 'src/components/SideModal';
import ModalHeader from 'src/components/ModalHeader';
import ModalFooter from 'src/components/ModalFooter';
import { ToastStoreObject } from 'src/stores/ToastStore';
import * as utils from 'src/utils';
import { OptionTypes } from 'src/components/OptionDropDown';
import NemsisOptionDropDown from 'src/components/NemsisOptionDropDown';
import { getDropdownValue } from 'src/utils/nemsis';
import { NaValues, ITransportFacilityPhone, AgencyStateCode } from 'common/lib/model/nemsis3_4/demographic';
import Flex from 'src/components/Flex';
import NemsisInput from 'src/components/NemsisInput';
import NemsisCreatable from 'src/components/NemsisCreatable';
import Button from 'src/components/Button';
import ReferenceListStore, { SelectComponent } from 'src/stores/ReferenceListStore';
import { observer } from 'mobx-react';
import NemsisStore from 'src/stores/NemsisStore';
import useFlag from 'src/utils/hooks/useFlag';
import useFeatureValue from 'src/utils/hooks/useFeatureValue';
import { useAgencyState } from 'src/utils/hooks/useAgencyState';
import { useAgencyCustomResults } from 'src/utils/hooks/useAgencyCustomResults';
import { NemsisMode } from 'common/lib/model/NemsisMode';

export interface TransportFacilityModalProps {
    onClose: Function;
    onSave: Function;
    transportFacility?: TransportFacility;
}

const defaultTransportFacility: TransportFacility = {
    facilityTypeCode: '',
    facilityName: '',
    facilityLocationCode: '',
    hospitalDesignationCodes: [],
    facilityNpis: [],
    suiteRoomApartment: '',
    address1: '',
    address2: '',
    cityCode: '',
    cityName: '',
    stateCode: '',
    zipCode: '',
    countyCode: '',
    countryCode: '',
    gpsLocation: '',
    nationalGridCoordinates: '',
    phoneNumbers: [],
    notValues: {
        facilityNpis: [{}],
        phoneNumbers: [{}],
    },
};

const defaultTranportFacilityPhone: ITransportFacilityPhone = {
    phoneNumber: '',
    typeCode: '',
};

function TransportFacilityModal(props: TransportFacilityModalProps) {
    const [data, setData] = useState<TransportFacility>(
        props.transportFacility || utils.clone(defaultTransportFacility),
    );
    const isEdit = !!props.transportFacility;
    const referenceListStore = useContext(ReferenceListStore);
    const nemsisStore = useContext(NemsisStore);
    const nemsisMode = useFeatureValue('nemsis_mode');
    const agencyState = useAgencyState();
    const [customResults, setCustomResults, saveCustomResults] = useAgencyCustomResults();

    const transportFacilityStore = useContext(TransportFacilityStore);

    useEffect(() => {
        if (data.stateCode === '') return;
        referenceListStore.getFilteredData(OptionTypes.ANSICityCodes, {
            filterName: 'stateAnsiCode',
            filterValue: data.stateCode,
        });

        referenceListStore.getFilteredData(OptionTypes.ANSICountyCodes, {
            filterName: 'stateAnsiCode',
            filterValue: data.stateCode,
        });
    }, [data.stateCode]);

    const updateInput = (param: keyof TransportFacility) => (t: string) => setData({ ...data, [param]: t });

    function updateNestedInput({
        param,
        subParam = null,
        index,
        value,
    }: {
        param: keyof TransportFacility;
        subParam?: string;
        index: number;
        value: any;
    }) {
        const newData: any = { ...data };

        if (!newData[param]) {
            newData[param] = [];
        }
        if (!newData[param][index]) {
            newData[param][index] = {};
        }

        if (subParam) newData[param][index][subParam] = value;
        else newData[param][index] = value;

        setData(newData);
    }

    function renderTopLevelInput({ key, label }: { key: keyof TransportFacility; label: string }) {
        return <NemsisInput nemsisLabel={label} value={data[key] as string} onChangeText={updateInput(key)} />;
    }

    function renderDropDown({
        key,
        label,
        type,
        isMulti,
        isNa,
        nested,
        virtualize = false,
        options,
        disabled,
    }: {
        key: keyof TransportFacility;
        label: string;
        type: OptionTypes;
        isMulti?: boolean;
        isNa?: boolean;
        nested?: { index: number; row: any; key: string };
        virtualize?: boolean;
        options?: SelectComponent[];
        disabled?: boolean;
    }) {
        return (
            <NemsisOptionDropDown
                nemsisLabel={label}
                isMulti={isMulti}
                selectedValue={nested ? nested.row[nested.key] : data[key]}
                onChange={(d: any) => {
                    const val = getDropdownValue(d, isMulti ? [] : undefined);
                    if (nested) {
                        return updateNestedInput({ param: key, subParam: nested.key, index: nested.index, value: val });
                    }

                    return setData({ ...data, [key]: val });
                }}
                {...(isNa
                    ? {
                          onNaClick: () => handleNaChange(key, nested && nested.key, nested && nested.index),
                          naSelected: getNotValBool(key, nested && nested.key, nested && nested.index),
                      }
                    : {})}
                type={type}
                virtualize={virtualize}
                isDisabled={disabled}
                options={options || null}
            />
        );
    }

    function renderCreatable({
        key,
        label,
        isNa,
        nested,
        validation,
    }: {
        key: keyof TransportFacility;
        label: string;
        isNa?: boolean;
        nested?: { index: number; row: any; key: string };
        validation?: 'NPI';
    }) {
        return (
            <NemsisCreatable
                nemsisLabel={label}
                selectedValues={nested ? nested.row[nested.key] : data[key]}
                onChange={(d: any) => {
                    if (nested) {
                        return updateNestedInput({ param: key, subParam: nested.key, index: nested.index, value: d });
                    }

                    return setData({ ...data, [key]: d });
                }}
                placeholder="(Enter Multiple)"
                {...(isNa
                    ? {
                          naSelected: getNotValBool(key, nested && nested.key, nested && nested.index),
                          onNaClick: () => handleNaChange(key, nested && nested.key, nested && nested.index),
                      }
                    : {})}
                {...(validation
                    ? {
                          validation: validation,
                      }
                    : {})}
            />
        );
    }

    function handleNaChange(property: keyof TransportFacility, subProperty?: string, index?: number) {
        const newData = { ...data };
        if (subProperty) {
            // Make sure everything is defaulted all the way down
            if (!data.notValues) {
                newData.notValues = { [property]: [] };
            }
            if (!data.notValues[property]) {
                newData.notValues = { ...data.notValues, [property]: [] };
            }
            if (!data.notValues[property][index]) {
                newData.notValues[property][index] = {};
            }

            newData.notValues[property][index][subProperty] = data.notValues[property][index][subProperty]
                ? undefined
                : NaValues.NOT_APPLICABLE;
        } else {
            newData.notValues[property] =
                data.notValues && data.notValues[property] ? undefined : NaValues.NOT_APPLICABLE;
        }
        setData(newData);
    }

    function getNotValBool(parentProp: keyof TransportFacility, childProp?: string, index?: number) {
        if (childProp) {
            return !!(
                data &&
                data.notValues &&
                data.notValues[parentProp] &&
                data.notValues[parentProp][index] &&
                data.notValues[parentProp][index][childProp]
            );
        }
        return !!(data && data.notValues && data.notValues[parentProp]);
    }

    async function handleSave() {
        try {
            if (isEdit) {
                await transportFacilityStore.updateTransportFacility(data);
            } else {
                await transportFacilityStore.createTransportFacility(data);
            }
            props.onSave();
            props.onClose();
            await saveCustomResults();
            await nemsisStore.validateDemData(true);
        } catch (error) {
            ToastStoreObject.show(utils.parseError(error));
        }
    }

    function handleAddRow(property: keyof TransportFacility) {
        const currentData = { ...data };

        if (property === 'facilityNpis') {
            currentData[property].push(null);
            // add an empty object to track indexes of not values correctly
            currentData.notValues[property] = [...currentData.notValues[property], {}];
        }

        if (property === 'phoneNumbers') {
            currentData[property].push({ ...defaultTranportFacilityPhone });
            // add an empty object to track indexes of not values correctly
            currentData.notValues[property] = [...currentData.notValues[property], {}];
        }

        setData(currentData);
    }

    function handleRemoveRow(parentProp: keyof TransportFacility, index: number) {
        const currentData = { ...data };
        // @ts-ignore
        (currentData[parentProp] || []).splice(index, 1);
        // remove any not values
        (currentData.notValues[parentProp] || []).splice(index, 1);
        setData(currentData);
    }

    function getLatitudeLongitude(): { latitude: string; longitude: string } {
        if (data.gpsLocation && data.gpsLocation.length > 0) {
            const splitData = data.gpsLocation.split(',');

            return {
                latitude: splitData[0] || '',
                longitude: splitData[1] || '',
            };
        } else {
            return {
                latitude: '',
                longitude: '',
            };
        }
    }

    function getCustomResult(id: string) {
        return (customResults || []).find((item) => {
            return item.customElementIdReferenced === 'dFacility.01' && item.correlationId === id;
        });
    }

    function filterCustomResult(elementId: string, correlationId: string) {
        return (customResults || []).filter((item) => {
            return !(item.customElementIdReferenced === elementId && item.correlationId === correlationId);
        });
    }

    function handleFacilityTypeChange(selected: SelectComponent) {
        if (
            nemsisMode === NemsisMode.PRE_TEST &&
            agencyState === AgencyStateCode.AR &&
            selected.value === `GH1701037`
        ) {
            const result = getCustomResult('GH1701037');
            const updatedResult = result
                ? {
                      ...result,
                      metaData: {
                          ...result.metaData,
                          transportFacIds: [...result.metaData.transportFacIds, data.transportFacilityId],
                      },
                  }
                : {
                      customDataElementResult: selected.value.toString(),
                      customElementIdReferenced: 'dFacility.01',
                      correlationId: 'GH1701037',
                      notValue: null,
                      metaData: {
                          transportFacIds: [data.transportFacilityId.toString()],
                      },
                  };
            setCustomResults([...filterCustomResult('dFacility.01', 'GH1701037'), updatedResult]);
            setData({ ...data, facilityTypeCode: 'GH1701009' });
        } else {
            const result = getCustomResult('GH1701037');
            let updatedResult;
            if (result && result.metaData.transportFacIds.indexOf(data.transportFacilityId) > -1) {
                updatedResult = {
                    ...result,
                    metaData: {
                        ...result.metaData,
                        transportFacIds: [
                            ...result.metaData.transportFacIds.filter((item: any) => item !== data.transportFacilityId),
                        ],
                    },
                };

                if (updatedResult.metaData.transportFacIds.length === 0) {
                    setCustomResults([...filterCustomResult('dFacility.01', 'GH1701037')]);
                } else {
                    setCustomResults([...filterCustomResult('dFacility.01', 'GH1701037'), updatedResult]);
                }
            }

            setData({ ...data, facilityTypeCode: selected.value.toString() });
        }
    }

    function getSelectedFacilityTypeValue() {
        const customResult = getCustomResult('GH1701037');
        return customResult && customResult.metaData.transportFacIds.indexOf(data.transportFacilityId) > -1
            ? customResult.customDataElementResult
            : data.facilityTypeCode;
    }

    return (
        <SideModal isOpen={true} onClose={() => props.onClose()}>
            <ModalHeader
                title={isEdit ? 'Edit Transport Facility' : 'Create Transport Facility'}
                onClose={props.onClose}
            />
            <div className={styles.modalContent}>
                <Flex direction="column" className={styles.form}>
                    <Flex direction="row" justify="between" className={styles.formRow}>
                        <Flex value={1}>
                            <NemsisOptionDropDown
                                nemsisLabel={'Facility Type'}
                                selectedValue={getSelectedFacilityTypeValue()}
                                onChange={(d: any) => {
                                    handleFacilityTypeChange(d);
                                }}
                                type={OptionTypes.EMSTypeOfFacility}
                            />
                        </Flex>
                    </Flex>
                    <Flex direction="row" justify="between" className={styles.formRow}>
                        <Flex value={1}>{renderTopLevelInput({ key: 'facilityName', label: 'Facility Name' })}</Flex>
                        <Flex value={0.1}></Flex>
                        <Flex value={1}>
                            {renderTopLevelInput({ key: 'facilityLocationCode', label: 'Location Code' })}
                        </Flex>
                    </Flex>
                    <Flex direction="row" justify="between" className={styles.formRow}>
                        <Flex value={1}>
                            {renderDropDown({
                                key: 'hospitalDesignationCodes',
                                label: 'Hospital Designations',
                                type: OptionTypes.EMSHospitalDesignation,
                                isMulti: true,
                            })}
                        </Flex>
                    </Flex>
                    <Flex direction="row" justify="between" className={styles.formRow}>
                        <Flex value={1}>
                            {renderCreatable({
                                key: 'facilityNpis',
                                label: 'National Provider Identifiers',
                                validation: 'NPI',
                            })}
                        </Flex>
                    </Flex>

                    <Flex direction="row" justify="between" className={styles.formRow}>
                        <span className={styles.heading}>Location</span>
                    </Flex>
                    <Flex direction="row" justify="between" className={styles.formRow}>
                        <Flex value={1}>{renderTopLevelInput({ key: 'address1', label: 'Street Address' })}</Flex>
                    </Flex>
                    <Flex direction="row" justify="between" className={styles.formRow}>
                        <Flex value={1}>{renderTopLevelInput({ key: 'address2', label: 'Street Address 2' })}</Flex>
                    </Flex>
                    <Flex direction="row" justify="between" className={styles.formRow}>
                        <Flex value={1}>
                            {renderTopLevelInput({ key: 'suiteRoomApartment', label: 'Room/Suite/Apt.' })}
                        </Flex>
                    </Flex>
                    <Flex direction="row" justify="between" className={styles.formRow}>
                        <Flex value={1}>
                            <NemsisOptionDropDown
                                nemsisLabel="State"
                                selectedValue={data['stateCode']}
                                onChange={(d: any) => {
                                    const val = getDropdownValue(d, undefined);
                                    return setData({ ...data, stateCode: val, countyCode: null });
                                }}
                                type={OptionTypes.ANSIStateCodes}
                            />
                        </Flex>
                        <Flex value={0.1}></Flex>
                        <Flex value={1}>
                            {renderDropDown({
                                key: 'cityCode',
                                label: 'City',
                                type: OptionTypes.ANSICityCodes,
                                virtualize: true,
                                disabled: data.stateCode === '',
                                options:
                                    referenceListStore.filteredReferenceListDropdownValues.get(
                                        `${OptionTypes.ANSICityCodes}-${data.stateCode}`,
                                    ) || [],
                            })}
                        </Flex>
                        <Flex value={0.1}></Flex>
                        <Flex value={1}>{renderTopLevelInput({ key: 'zipCode', label: 'Zip Code' })}</Flex>
                    </Flex>
                    <Flex direction="row" justify="between" className={styles.formRow}>
                        <Flex value={1}>
                            <NemsisOptionDropDown
                                nemsisLabel="County"
                                selectedValue={data['countyCode']}
                                onChange={(d: any) => {
                                    const val = getDropdownValue(d, undefined);
                                    return setData({ ...data, countyCode: val });
                                }}
                                type={OptionTypes.ANSICountyCodes}
                                isDisabled={data['stateCode'] === ''}
                                options={
                                    referenceListStore.filteredReferenceListDropdownValues.get(
                                        `${OptionTypes.ANSICountyCodes}-${data.stateCode}`,
                                    ) || []
                                }
                            />
                        </Flex>
                        <Flex value={0.1}></Flex>
                        <Flex value={1}>
                            {renderDropDown({
                                key: 'countryCode',
                                label: 'Country',
                                type: OptionTypes.EMSANSICountryCodesCAMXUS,
                            })}
                        </Flex>
                    </Flex>
                    <Flex direction="row" justify="between" className={styles.formRow}>
                        <Flex value={1}>
                            <NemsisInput
                                nemsisLabel="Latitude"
                                type="string"
                                value={getLatitudeLongitude().latitude}
                                onChangeText={(text: string) => {
                                    let newData = { ...data };
                                    let gps = getLatitudeLongitude();
                                    newData.gpsLocation = `${text}, ${gps.longitude}`;
                                    setData(newData);
                                }}
                            />
                        </Flex>
                        <Flex value={0.1}></Flex>
                        <Flex value={1}>
                            <NemsisInput
                                nemsisLabel="Longitude"
                                type="string"
                                value={getLatitudeLongitude().longitude}
                                onChangeText={(text: string) => {
                                    let newData = { ...data };
                                    let gps = getLatitudeLongitude();
                                    newData.gpsLocation = `${gps.latitude},${text}`;
                                    setData(newData);
                                }}
                            />
                        </Flex>
                    </Flex>
                    <Flex direction="row" justify="between" className={styles.formRow}>
                        <Flex value={1}>
                            {renderTopLevelInput({
                                key: 'nationalGridCoordinates',
                                label: 'US National Grid Coordinates',
                            })}
                        </Flex>
                    </Flex>
                    <Flex direction="row" justify="between" className={styles.formRow}>
                        <Flex value={1} direction="column" className={styles.newRow}>
                            <span className={styles.heading}>Phone Numbers</span>
                            {(data.phoneNumbers || []).map((pn, index) => (
                                <Flex
                                    key={`pn${index}`}
                                    direction="row"
                                    justify="between"
                                    align="center"
                                    className={styles.formRow}
                                >
                                    <Flex value={1}>
                                        {renderDropDown({
                                            key: 'phoneNumbers',
                                            label: 'Type',
                                            type: OptionTypes.EMSPhoneNumberType,
                                            nested: { row: pn, key: 'typeCode', index },
                                        })}
                                    </Flex>
                                    <Flex value={0.1}></Flex>
                                    <Flex value={1}>
                                        <NemsisInput
                                            key={`pninput${index}`}
                                            nemsisLabel="Phone Number"
                                            type="string"
                                            value={pn.phoneNumber}
                                            onChangeText={(text: string) => {
                                                updateNestedInput({
                                                    param: 'phoneNumbers',
                                                    subParam: 'phoneNumber',
                                                    index,
                                                    value: text,
                                                });
                                            }}
                                        />
                                    </Flex>

                                    <Flex value={0.7} align="end" justify="end">
                                        <Button
                                            type="primary"
                                            text="Remove"
                                            onClick={() => handleRemoveRow('phoneNumbers', index)}
                                        />
                                    </Flex>
                                </Flex>
                            ))}
                            <Button
                                type="transparent"
                                text="+ Add Phone Number"
                                onClick={() => handleAddRow('phoneNumbers')}
                                className={styles.addUnderline}
                            />
                        </Flex>
                    </Flex>
                </Flex>
            </div>
            <ModalFooter
                primaryText={isEdit ? 'Save' : 'Create'}
                primaryClick={handleSave}
                secondaryClick={() => {
                    setData(utils.clone(defaultTransportFacility));
                    props.onClose();
                }}
            />
        </SideModal>
    );
}

export default observer(TransportFacilityModal);
