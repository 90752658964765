import React, { useRef, useState } from 'react';
import { ImportBatch, ImportBatchRecordState } from 'src/utils/types';
import Flex from 'src/components/Flex';
import Tooltip from 'src/components/Tooltip';
import styles from './styles.module.scss';

interface BatchRecordState {
    importBatchGuid: ImportBatch['importBatchGuid'],
    importBatchRecordStateCode: ImportBatchRecordState['importBatchRecordStateCode'],
    importBatchRecordStateTitle: ImportBatchRecordState['importBatchRecordStateTitle'],
    importBatchTitle: ImportBatch['importBatchTitle'],
    recordCount: number,
}

interface RecordStatusProps {
    batchRecordStates: BatchRecordState[]
}

function RecordStatus(props: RecordStatusProps) {
    const redRef = useRef(null);
    const yellowRef = useRef(null);
    const greenRef = useRef(null);
    const grayRef = useRef(null);
    const [tooltipRef, setTooltipRef] = useState(redRef);
    const [tooltipIsOpen, setTooltipIsOpen] = useState(false);
    const [tooltipText, setTooltipText] = useState('');

    function updateTooltip(refValue: string, tooltipTitle: string) {
        switch (refValue) {
            case 'red':
                setTooltipRef(redRef);
                break;
            case 'yellow':
                setTooltipRef(yellowRef);
                break;
            case 'green':
                setTooltipRef(greenRef);
                break;
            case 'gray':
                setTooltipRef(grayRef);
                break;
        }

        setTooltipText(tooltipTitle);
        setTooltipIsOpen(true);
    }

    function getBadges() {
        return props.batchRecordStates.map((state: BatchRecordState, index: number) => {
            switch (state.importBatchRecordStateCode) { 
                // Red states
                case 'NO_MATCH':
                case 'MATCH_ERROR':
                case 'PROCESSING_ERROR':
                case 'PROCESSING_MATCH_ERROR':
                    return <div ref={redRef} key={index} className={styles.statusRed} onMouseOver={() => updateTooltip('red', state.importBatchRecordStateTitle)} onMouseOut={() => setTooltipIsOpen(false)}>{state.recordCount}</div>;
                // Yellow states
                case 'PENDING_SEARCH':
                case 'SEARCHING':
                case 'PENDING_PROCESSING':
                case 'PROCESSING':
                    return <div ref={yellowRef} key={index} className={styles.statusYellow} onMouseOver={() => updateTooltip('yellow', state.importBatchRecordStateTitle)} onMouseOut={() => setTooltipIsOpen(false)}>{state.recordCount}</div>;
                // Green states
                case 'MATCH':
                case 'ENTITY_CREATED':
                case 'ENTITY_UPDATED':
                    return <div ref={greenRef} key={index} className={styles.statusGreen} onMouseOver={() => updateTooltip('green', state.importBatchRecordStateTitle)} onMouseOut={() => setTooltipIsOpen(false)}>{state.recordCount}</div>;
                // Gray states
                case 'IGNORED':
                default:
                    return <div ref={grayRef} key={index} className={styles.statusGray} onMouseOver={() => updateTooltip('gray', state.importBatchRecordStateTitle)} onMouseOut={() => setTooltipIsOpen(false)}>{state.recordCount}</div>;
            }
        });
    }

    return <Flex>
        {getBadges()}
        <Tooltip element={tooltipRef} isOpen={tooltipIsOpen} direction="top">
            <div className={styles.tooltip}>{tooltipText}</div>
        </Tooltip>
    </Flex>;
};

export default RecordStatus;