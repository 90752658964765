import { useEffect } from 'react';

export default function useEscapeKey(onPress?: Function) {
    const handlePress = (e: any) => {
        if (e.keyCode === 27 || e.key === 'Escape') {
            //Do whatever when esc is pressed
            if (onPress) {
                onPress();
            }
        }
    };

    useEffect(() => {
        document.addEventListener('keydown', handlePress);

        return () => {
            document.removeEventListener('keydown', handlePress);
        };
    }, []);
}
