import { UseMutationResult, useMutation, useQueryClient } from 'react-query';
import { addFormCatalogPage } from 'src/api/formCatalogs';
import { FormCatalog, FormCatalogPage } from 'common/lib/entity/framework/FormCatalog';
import { formCatalogKey } from './useFormCatalog';

export const addFormCatalogPageKey = 'addFormCatalogPage';

export interface UseAddFormCatalogPageInputVariables {
    pageName: FormCatalogPage['pageName'];
    minCount: FormCatalogPage['minCount'];
    maxCount: FormCatalogPage['maxCount'];
    pageImage: FormCatalogPage['originalPageImage'];
}

export const useAddFormCatalogPage = (
    formCatalogId: FormCatalog['formCatalogId'],
): UseMutationResult<{ pageId: FormCatalogPage['pageId'] }, Error, UseAddFormCatalogPageInputVariables, unknown> => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationKey: [addFormCatalogPageKey],
        mutationFn: async (variables) => {
            const { pageName, minCount, maxCount, pageImage } = variables;

            if (!pageName ?? pageName.trim() === '') {
                throw new Error('Page name is required');
            }

            if (!pageImage ?? pageImage.trim() === '') {
                throw new Error('Page image is required');
            }

            if (minCount != null && isNaN(minCount)) {
                throw new Error('Min count must be a number');
            }

            if (maxCount != null && isNaN(maxCount)) {
                throw new Error('Max count must be a number');
            }

            if (minCount > maxCount) {
                throw new Error('Min count must be less than or equal to max count');
            }

            return await addFormCatalogPage(formCatalogId, {
                pageName,
                minCount,
                maxCount,
                originalPageImage: pageImage,
            });
        },
        onSuccess: async (data) => {
            queryClient.invalidateQueries({ queryKey: [formCatalogKey, { formCatalogId }] });
            return data;
        },
        retry: false,
    });
};
