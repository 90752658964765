import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { CellInfo } from 'react-table';
import CustomerStore from 'src/stores/CustomerStore';
import UserStore from 'src/stores/UserStore';
import Button from 'src/components/Button';
import Card from 'src/components/Card';
import FilteredSearch, { FilteredSearchQuery } from 'src/components/FilteredSearch';
import Flex from 'src/components/Flex';
import PageContainer from 'src/components/PageContainer';
import PageContent from 'src/components/PageContent';
import Table from 'src/components/Table';
import NavBar from 'src/containers/NavBar';
import { ICONS, ROUTES } from 'src/utils/constants';
import styles from './styles.module.scss';
import { ToastStoreObject } from 'src/stores/ToastStore';
import { parseError } from 'src/utils';
import { getCustomerFilters } from 'src/utils/filters';
import { FilterQueryParams } from 'src/utils/types';
import FilterStore from 'src/stores/FilterStore';
import useIsMount from 'src/utils/hooks/useIsMount';
import Pagination from 'src/components/Table/Pagination';
import { observer } from 'mobx-react';
import { CustomerStatus, CustomerSubscriptionData } from 'common/lib/entity/index/Customer';
import StatusBadge, { StatusBadgeColors } from 'src/components/StatusBadge';
import Icon from 'src/components/Icon';
import { ModalStoreObject, ModalTypes } from 'src/stores/ModalStore';
import useFlag from 'src/utils/hooks/useFlag';

const FILTER_PAGE = 'customersPage';

export function getStatusColor(status: CustomerSubscriptionData['customerStatus']) {
    switch (status) {
        case CustomerStatus.Active:
            return StatusBadgeColors.Green;
        case CustomerStatus.Onboarding:
        case CustomerStatus.Offboarding:
            return StatusBadgeColors.Yellow;
        case CustomerStatus.Offboarded:
            return StatusBadgeColors.Red;
    }
}

function Customers() {
    const [isLoading, setIsLoading] = useState(false);
    const userStore = useContext(UserStore);
    const customerStore = useContext(CustomerStore);
    const history = useHistory();
    const filterStore = useContext(FilterStore);
    const customerFilters: FilterQueryParams = filterStore[FILTER_PAGE];
    const isMount = useIsMount();
    const orgCreationEnabled = useFlag('customers-create-organization');

    useEffect(() => {
        getCustomers(true);
    }, []);

    useEffect(() => {
        if (isMount) {
            return;
        }
        getCustomers(true);
    }, [customerFilters]);

    async function getCustomers(force: boolean = false) {
        setIsLoading(true);

        try {
            await customerStore.getCustomers(customerFilters, force);
        } catch (err) {
            ToastStoreObject.show(parseError(err));
        } finally {
            setIsLoading(false);
        }
    }

    function handleSearch({ query, fields }: FilteredSearchQuery) {
        const updatedFilters = { ...customerFilters };
        updatedFilters.pagination.page = 1;
        updatedFilters.query = query;
        updatedFilters.filters = fields;

        filterStore.setFilters(FILTER_PAGE, updatedFilters);
    }

    function handlePaginationFilterChange(newPage: number) {
        const updatedFilters = { ...customerFilters };
        updatedFilters.pagination.page = newPage;
        filterStore.setFilters(FILTER_PAGE, updatedFilters);
    }

    const columns = [
        {
            Header: 'Customer',
            accessor: 'customerName',
            Cell: (props: CellInfo) => {
                const isDemoCustomer = props.original.subscriptionData?.isDemoCustomer;
                const isTrialCustomer = props.original.subscriptionData?.isTrialCustomer;
                return (
                    <Flex>
                        <div className={styles.customerName}>{props.value}</div>
                        {isDemoCustomer && <StatusBadge status={StatusBadgeColors.Yellow} text="Demo" />}
                        {isTrialCustomer && <StatusBadge status={StatusBadgeColors.Green} text="Trial" />}
                    </Flex>
                );
            },
        },
        {
            Header: 'Org Count',
            accessor: 'orgs',
            minWidth: 50,
            Cell: (props: CellInfo) => {
                return <span>{props.value ? props.value.length : ''}</span>;
            },
        },
        {
            Header: 'Initial Contract Date',
            accessor: 'subscriptionData.initialContractDate',
            Cell: (props: CellInfo) => {
                return <span>{props.value}</span>;
            },
        },
        {
            Header: 'Status',
            accessor: 'subscriptionData.customerStatus',
            minWidth: 80,
            Cell: (props: CellInfo) => {
                return <StatusBadge status={getStatusColor(props.value)} text={props.value} />;
            },
        },
        {
            Header: 'Actions',
            accessor: 'actions',
            minWidth: 50,
            Cell: (props: CellInfo) => (
                <Flex>
                    <Button
                        type="small"
                        text="Details"
                        onClick={() => history.push(ROUTES.getString(ROUTES.CustomerDetail, props.original.customerId))}
                    />
                </Flex>
            ),
        },
    ];

    function createCustomer() {
        ModalStoreObject.showModal(ModalTypes.CustomerModal, {
            onClose: () => ModalStoreObject.hideModal(),
            onSave: () => {
                getCustomers(true);
            },
        });
    }

    return (
        <PageContainer>
            <NavBar />
            <PageContent>
                <Flex direction="row" className={styles.filterWrap}>
                    <Flex value={1}>
                        <FilteredSearch
                            initialValues={{
                                query: customerFilters.query,
                                fields: customerFilters.filters,
                            }}
                            onSearch={handleSearch}
                            searchPlaceholder={'Search by customer name'}
                            filterOptions={getCustomerFilters()}
                            hideOrStatementSwitch={true}
                        />
                    </Flex>
                    {orgCreationEnabled && (
                        <Button
                            leftIcon={<Icon className={styles.plusIcon} name={ICONS.PlusButton} />}
                            type="primary"
                            onClick={createCustomer}
                            className={styles.newButton}
                            text="New Customer"
                        />
                    )}
                </Flex>
                <Card className={styles.cardWrap}>
                    <div className={styles.tableTitle}>Customers</div>
                    <Table
                        isLoading={isLoading}
                        columns={columns}
                        data={customerStore.customers}
                        showPagination={false}
                    />
                </Card>
                <Pagination
                    pages={Math.ceil(customerStore.totalCustomerCount / filterStore[FILTER_PAGE].pagination.limit) || 1}
                    page={filterStore[FILTER_PAGE].pagination.page - 1}
                    onPageChange={(page: number) => handlePaginationFilterChange(page + 1)}
                    showing={customerStore.customers.length}
                    totalRecords={customerStore.totalCustomerCount}
                />
            </PageContent>
        </PageContainer>
    );
}

export default observer(Customers);
