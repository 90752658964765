import React, { useState, useContext, useEffect, useRef } from 'react';
import PageContainer from 'src/components/PageContainer';
import NavBar from 'src/containers/NavBar';
import styles from './styles.module.scss';
import PageContent from 'src/components/PageContent';
import Flex from 'src/components/Flex';
import Table from 'src/components/Table';
import Icon from 'src/components/Icon';
import Gender from 'src/components/Gender';
import Button from 'src/components/Button';
import { ICONS, ROUTES } from 'src/utils/constants';
import { CellInfo } from 'react-table';
import * as telehealthService from 'src/api/telehealth';
import FilterStore from 'src/stores/FilterStore';
import Age from 'src/components/Age';
import { useHistory } from 'react-router';
import { ModalStoreObject, ModalTypes } from 'src/stores/ModalStore';
import * as utils from 'src/utils';
import DateComponent from 'src/components/DateComponent';
import FacilityDropDown from 'src/components/FacilityDropDown';
import { TelehealthSession, SessionStateCodes } from 'src/utils/types';
import { ToastStoreObject, ToastType } from 'src/stores/ToastStore';
import TabNav from 'src/components/TabNav';
import FilteredSearch, { FilteredSearchQuery } from 'src/components/FilteredSearch';
import Pagination from 'src/components/Table/Pagination';
import TelehealthStore from 'src/stores/TelehealthStore';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import OrgUserStore from 'src/stores/OrgUserStore';
import { endOfDay, startOfDay } from 'date-fns';
import { FILTER_METHODS, getTelehealthFilters } from 'src/utils/filters';
import { ANALYTICS_NAMES } from 'src/utils/analytics';

const FILTER_PAGE = 'telehealthPage';

function TelehealthVisits() {
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(true);
    const filterStore = useContext(FilterStore);
    const orgUserStore = useContext(OrgUserStore);
    const [completeVisitLoading, setCompleteVisitLoading] = useState(false);
    const tabRef = useRef(null);
    const telehealthFilters: any = filterStore[FILTER_PAGE];

    const telehealthStore = useContext(TelehealthStore);

    useEffect(() => {
        // This is needed to load the org users for filtering the results
        orgUserStore.getOrgUsers();
    }, []);
    const telehealthArr = toJS(telehealthStore.telehealthVisits);

    const columns = [
        {
            Header: 'URGENCY',
            accessor: 'priorityCode',
            minWidth: 75,
            sortMethod: (a: string, b: string) => {
                if (a === b) return 0;
                if (a === 'URGENT') return 1;
                if (a === 'HIGH' && (b === 'MEDIUM' || b === 'LOW')) return 1;
                if (a === 'MEDIUM' && b === 'LOW') return 1;
                return -1;
            },
            Cell: (props: CellInfo) => {
                switch (props.value) {
                    case 'URGENT':
                        return <Icon title="Urgent" name={ICONS.UrgencyUrgent} />;
                    case 'HIGH':
                        return <Icon title="High" name={ICONS.UrgencyHigh} />;
                    case 'MEDIUM':
                        return <Icon title="Medium" name={ICONS.UrgencyMedium} />;
                    case 'LOW':
                        return <Icon title="Low" name={ICONS.UrgencyLow} />;
                    default:
                        return <Icon title="Medium" name={ICONS.UrgencyMedium} />;
                }
            },
        },
        {
            Header: 'STATUS',
            accessor: 'telehealthSessionState',
            minWidth: 160,
            Cell: (props: CellInfo) => {
                return (
                    <span>
                        {utils.getTelehealthStatusExplanation(
                            (props.value || {}).telehealthSessionStateCode,
                            props.original.telehealthSessionDetail,
                        )}
                    </span>
                );
            },
        },
        {
            Header: 'PATIENT',
            minWidth: 150,
            Cell: (props: CellInfo) => (
                <Flex direction="row">
                    <span>{`${utils.getTeleheathPatientInfo(
                        props.original,
                        'lastName',
                    )}, ${utils.getTeleheathPatientInfo(props.original, 'firstName')}`}</span>
                    <Age
                        style={{ marginLeft: 5, marginRight: 5 }}
                        dob={utils.getTeleheathPatientInfo(props.original, 'dob')}
                    />
                    <Gender value={utils.getTeleheathPatientInfo(props.original, 'gender')} />
                </Flex>
            ),
        },
        {
            Header: 'CREATED ON',
            accessor: 'createTime',
            minWidth: 125,
            Cell: (props: CellInfo) => <DateComponent date={props.value} showDateTime={true} />,
        },
        {
            Header: 'REASON',
            accessor: 'telehealthSessionReason',
            minWidth: 250,
            style: { whiteSpace: 'unset' },
            Cell: (props: CellInfo) => <div style={{ padding: '10px 0' }}>{props.value}</div>,
        },
        {
            Header: 'ACTIONS',
            minWidth: 300,
            style: { paddingVertical: 0, paddingHorizontal: 20 },
            Cell: (props: CellInfo) => {
                const stateCode = ((props.original || {}).telehealthSessionState || {}).telehealthSessionStateCode;
                const canCancel = ![
                    SessionStateCodes.SESSION_CANCELED_BY_INITIATOR,
                    SessionStateCodes.SESSION_CANCELED_BY_RESPONDER,
                    SessionStateCodes.CONNECTION_STARTED,
                    SessionStateCodes.CONNECTION_INTERRUPTED,
                    SessionStateCodes.CONNECTION_ENDED,
                    SessionStateCodes.SESSION_COMPLETED,
                ].includes(stateCode);
                const canComplete = [
                    SessionStateCodes.CONNECTION_STARTED,
                    SessionStateCodes.CONNECTION_INTERRUPTED,
                    SessionStateCodes.CONNECTION_ENDED,
                ].includes(stateCode);
                return (
                    <Flex direction="row">
                        <Button
                            type="primary"
                            text="Open"
                            onClick={() =>
                                history.push(
                                    ROUTES.getString(ROUTES.TelehealthSession, props.original.telehealthSessionId),
                                )
                            }
                            className={styles.cancelBtn}
                        />
                        {canCancel ? (
                            <Button
                                type="secondary"
                                text="Cancel Visit"
                                onClick={() => cancelSession(props.original.telehealthSessionId)}
                                className={styles.cancelBtn}
                                data-test-id={ANALYTICS_NAMES.Telehealth_CancelVisit}
                            />
                        ) : null}
                        {canComplete ? (
                            <Button
                                type="secondary"
                                text="Complete Visit"
                                isLoading={completeVisitLoading}
                                onClick={async () => {
                                    try {
                                        setCompleteVisitLoading(true);
                                        await telehealthService.completeTelehealthSession(
                                            props.original.telehealthSessionId,
                                        );
                                        loadTelehealthVisits();
                                    } catch {
                                        ToastStoreObject.show('There was an error completing the visit.');
                                        setCompleteVisitLoading(false);
                                    } finally {
                                        setCompleteVisitLoading(false);
                                    }
                                }}
                                className={styles.cancelBtn}
                                data-test-id={ANALYTICS_NAMES.Telehealth_CompleteVisit}
                            />
                        ) : null}
                    </Flex>
                );
            },
        },
    ];

    async function cancelSession(telehealthSessionId: TelehealthSession['telehealthSessionId']) {
        ModalStoreObject.showModal(ModalTypes.CancelReason, {
            onClose: () => {
                ModalStoreObject.hideModal();
            },
            onSave: async (cancelReason: string) => {
                if (!cancelReason) {
                    ToastStoreObject.show('A cancellation reason is required', ToastType.Error);
                } else {
                    try {
                        await telehealthService.cancelTelehealthSession(telehealthSessionId.toString(), cancelReason);
                        ToastStoreObject.show('Successfully canceled Telehealth Visit', ToastType.Success);
                        loadTelehealthVisits();
                    } catch (e) {
                        ToastStoreObject.show(utils.parseError(e));
                    } finally {
                        ModalStoreObject.hideModal();
                    }
                }
            },
        });
    }

    const handleFilterChange = function(type: string, filterVals: { label: string; value: string }[]) {
        let newFilters: any[] = [];
        if (
            filterVals &&
            filterVals.length !== 0 &&
            filterVals[0] &&
            filterVals[0].value !== undefined &&
            filterVals[0].value !== null
        ) {
            newFilters = filterVals.map((f) => f.value);
        }
        const updatedFilters = { ...filterStore[FILTER_PAGE] };
        if (type === 'facility') {
            updatedFilters.facility = newFilters;
        }

        filterStore.setFilters(FILTER_PAGE, updatedFilters);
    };

    const facilityId = filterStore[FILTER_PAGE].facility[0];

    async function handleSearch({ query, fields, orStatement }: FilteredSearchQuery) {
        const updatedFilters = { ...telehealthFilters };
        updatedFilters.pagination.page = 1;
        updatedFilters.query = query;
        updatedFilters.orStatement = orStatement;

        updatedFilters.filters = fields;

        filterStore.setFilters(FILTER_PAGE, updatedFilters);
    }

    const handlePaginationFilterChange = function(newPage: number) {
        const updatedFilters = { ...telehealthFilters };
        updatedFilters.pagination.page = newPage;
        filterStore.setFilters(FILTER_PAGE, updatedFilters);
    };

    async function loadTelehealthVisits() {
        try {
            setIsLoading(true);
            const currentFilters = telehealthFilters;
            const fields = currentFilters.filters;
            const hasCreateTimeFilter = fields.find((f: any) => f.name === 'createTime');
            if (hasCreateTimeFilter) {
                const newFields = fields.map((m: any) => {
                    if (m.name !== 'createTime') return m;
                    if (m.method === FILTER_METHODS.Less_Than) {
                        m.data.value1 = startOfDay(m.data.value1); // convert to 12:01 am
                    }
                    if (m.method === FILTER_METHODS.Greater_Than) {
                        m.data.value1 = endOfDay(m.data.value1); // convert to 11:59 pm
                    }
                    if (m.method === FILTER_METHODS.Between_Date) {
                        m.data.value1 = startOfDay(m.data.value1); // convert to 12:01 am
                        m.data.value2 = endOfDay(m.data.value2); // convert to 11:59 pm
                    }
                    if (m.method === FILTER_METHODS.Equals_Date) {
                        m.method = FILTER_METHODS.Between_Date;
                        m.data.value1 = startOfDay(m.data.value1); // convert to 12:01 am
                        m.data.value2 = endOfDay(m.data.value1); // convert to 11:59 pm
                    }
                    return m;
                });
                currentFilters.filters = newFields;
            }
            await telehealthStore.getTelehealthVisits(currentFilters);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        loadTelehealthVisits();
    }, [telehealthFilters]);

    return (
        <PageContainer>
            <NavBar />
            <PageContent>
                <div className={styles.tabContainer}>
                    <TabNav
                        tabs={['Waiting Room', 'Telehealth Visits']}
                        onTabClick={(tab: string) => {
                            if (tab === 'Waiting Room') {
                                history.push(ROUTES.getString(ROUTES.TelehealthWaitingRoom));
                            } else {
                                return;
                            }
                        }}
                        selectedTabIndex={1}
                        ref={tabRef}
                    />
                </div>
                <Flex self="stretch" align="center" justify="start" className={styles.headerWrap}>
                    <Flex value={1} className={styles.filterWrap}>
                        <div style={{ maxWidth: 400 }}>
                            <FacilityDropDown
                                selectedValue={facilityId}
                                onChange={(filters: any) => handleFilterChange('facility', [filters])}
                                placeholder="Filter by Facility"
                            />
                        </div>
                    </Flex>

                    <Button
                        type="secondary"
                        onClick={() => {
                            ModalStoreObject.showModal(ModalTypes.Encounter, {
                                onClose: () => {
                                    ModalStoreObject.hideModal();
                                },
                                title: `Create a new Telehealth Visit`,
                                isOpen: true,
                                onSave: async () => {
                                    ModalStoreObject.hideModal();
                                    loadTelehealthVisits();
                                },
                                isAdHoc: true,
                                isTelehealth: true,
                                facId: filterStore[FILTER_PAGE].facility[0],
                            });
                        }}
                        text="Invite Patient"
                        style={{ marginRight: 10 }}
                    />
                    <Button type="primary" onClick={loadTelehealthVisits} text="Refresh" />
                </Flex>
                <div>
                    <Flex direction="row" className={styles.filterSearchWrap}>
                        <Flex value={1}>
                            <FilteredSearch
                                initialValues={{
                                    query: telehealthFilters.query,
                                    fields: telehealthFilters.filters,
                                    orStatement: telehealthFilters.orStatement,
                                }}
                                onSearch={handleSearch}
                                searchPlaceholder={'Search by Reason or Patient Name'}
                                filterOptions={getTelehealthFilters()}
                            />
                        </Flex>
                    </Flex>
                    <div className={styles.tableWrap}>
                        <div className={styles.tableTitle}>Telehealth Visits</div>
                        <Table
                            isLoading={isLoading}
                            columns={columns}
                            noDataText={'No Telehealth Visits Found'}
                            data={telehealthArr}
                            showPagination={false}
                            defaultSorted={[
                                {
                                    id: 'telehealthSessionState',
                                    desc: true,
                                },
                                {
                                    id: 'createTime',
                                    desc: true,
                                },
                            ]}
                        />
                    </div>
                    {/* Custom Pagination for server side data */}
                    {telehealthArr.length > 0 ? (
                        <Pagination
                            pages={
                                Math.ceil(
                                    telehealthStore.totalTelehealthVisitsCount / telehealthFilters.pagination.limit,
                                ) || 1
                            }
                            page={telehealthFilters.pagination.page - 1}
                            onPageChange={(page: number) => handlePaginationFilterChange(page + 1)}
                            showing={telehealthStore.telehealthVisits.length}
                            totalRecords={telehealthStore.totalTelehealthVisitsCount}
                        />
                    ) : null}
                </div>
            </PageContent>
        </PageContainer>
    );
}
export default observer(TelehealthVisits);
