import * as ROUTES from 'src/api/routes';
import request from 'src/api/utils';
import {} from 'src/stores/EncounterStore';
import { ModelPropertyUpdate } from 'common/lib/model/model/ModelPropertyUpdate';
import { FilterQueryParams, Event, Encounter, EncounterForm } from 'src/utils/types';

export async function getPatientEncounters(patientId: Encounter['patientId'], params: {} = {}) {
    const patients = await request({ ...ROUTES.GET_PATIENT_ENCOUNTERS, pathParams: { patientId }, params });
    return patients;
}

export async function getAllEncounters(params: FilterQueryParams = {}): Promise<{ result: any[]; count: number }> {
    const response = await request({ ...ROUTES.GET_ALL_ENCOUNTERS, params });
    return { result: response.data.result || [], count: response.data.count || 0 };
}

export async function getEncounterById(encounterId: Encounter['enctrId']) {
    const response = await request({ ...ROUTES.GET_ENCOUNTER, pathParams: { encounterId } });
    return response.data || {};
}

export async function createEncounter(data: Encounter, completeEventId?: Event['eventId']) {
    return await request({ ...ROUTES.CREATE_ENCOUNTER, params: { completeEventId }, data });
}

export async function createEncounterWithPatient(data: Encounter, completeEventId?: Event['eventId']) {
    return await request({ ...ROUTES.CREATE_ENCOUNTER_WITH_PATIENT, params: { completeEventId }, data });
}

export async function updateEncounter(encounterId: Encounter['enctrId'], data: Encounter) {
    return await request({ ...ROUTES.UPDATE_ENCOUNTER, pathParams: { encounterId }, data });
}

export async function updateEncounterForm(encounterFormId: EncounterForm['enctrFormId'], data: EncounterForm) {
    return await request({ ...ROUTES.UPDATE_ENCOUNTER_FORM, pathParams: { encounterFormId }, data });
}

export async function getEncounterFormPdfData(
    encounterFormId: EncounterForm['enctrFormId'],
    facId: Encounter['facId'],
) {
    return await request({ ...ROUTES.GET_ENCOUNTER_FORM_PDF_DATA, pathParams: { encounterFormId }, params: { facId } });
}

export async function getEncounterFormPdfLink(
    encounterFormId: EncounterForm['enctrFormId'],
    facId: Encounter['facId'],
    returnPdfLinkOnly?: boolean,
) {
    return await request({
        ...ROUTES.GET_ENCOUNTER_FORM_PDF_LINK,
        pathParams: { encounterFormId },
        params: { facId, returnPdfLinkOnly },
    });
}

export async function getEncounterFormPdfLinkLegacy(encounterFormId: EncounterForm['enctrFormId']) {
    return await request({
        ...ROUTES.GET_ENCOUNTER_FORM_PDF_LINK_LEGACY,
        pathParams: { encounterFormId },
    });
}

export async function getPageContent(formDefnPageHistId: number, facId: Encounter['facId']) {
    const pageContent = await request({
        ...ROUTES.GET_PAGE_CONTENT,
        pathParams: { formDefnPageHistId },
        params: { facId },
    });
    return pageContent.data || '';
}

export async function getEncounterForms(params: FilterQueryParams = {}): Promise<{ result: any[]; count: number }> {
    const response = await request({ ...ROUTES.GET_ENCOUNTER_FORMS, params });
    return { result: response.data.result || [], count: response.data.count || 0 };
}

export async function getAllEncounterForms(params: FilterQueryParams = {}) {
    const response = await request({ ...ROUTES.GET_BULK_ENCOUNTER_FORMS, params });
    return response.data.cacheUrl || '';
}

export async function getAllEncounterFormsFromS3(url: string): Promise<{ result: any[]; count: number }> {
    const response: any = await request({ url, method: 'get', anonymous: true, external: true });
    return { result: response.data.result || [], count: response.data.count || 0 };
}

export async function getFormDefinitions(facId: number) {
    const formDefinitions = await request({ ...ROUTES.GET_FORM_DEFINITIONS, params: { facilityId: facId } });
    return formDefinitions.data || [];
}

export async function getEncounterFormSearchFields() {
    return await request({
        ...ROUTES.GET_ENCOUNTER_FORM_SEARCH_FIELDS,
    });
}

export async function createEncounterForm(params: {
    enctrId: string;
    formDefnId: number;
    formDefnVer: number;
    patientId: number;
    facId: number;
    formLastOpndDttm: Date;
}) {
    const { enctrId, formDefnId, formDefnVer, patientId, facId, formLastOpndDttm } = params;
    const createdForm = await request({
        ...ROUTES.CREATE_ENCOUNTER_FORM,
        data: { enctrId, formDefnId, formDefnVer, patientId, formLastOpndDttm },
        params: { facId },
    });
    return createdForm.data || {};
}

export async function updateEncounterFormModel(encounterFormId: number, modelPropertyUpdates: ModelPropertyUpdate[]) {
    return await request({
        ...ROUTES.UPDATE_ENCOUNTER_FORM_MODEL,
        pathParams: { encounterFormId },
        data: modelPropertyUpdates,
    });
}

export async function addFormPage(enctrFormId: number, pageNm: string) {
    return await request({ ...ROUTES.CREATE_FORM_PAGE, data: { enctrFormId, pageNm } });
}

export async function getEncounterFormBlob(encounterFormId: number, blobId: string) {
    return await request({ ...ROUTES.GET_ENCOUNTER_FORM_BLOB, pathParams: { encounterFormId, blobId } });
}

export async function getEncounterFormBlobFromS3(url: string) {
    const response = await request({ url, method: 'get', anonymous: true, external: true });
    return response;
}
