import React, { useContext, useRef, useState, useEffect } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import Flex from '../../components/Flex';
import Icon from '../../components/Icon';
import { observer } from 'mobx-react';
import { NavLink } from 'react-router-dom';
import AuthStore from 'src/stores/AuthStore';
import UserStore from 'src/stores/UserStore';
import SettingsPanel from 'src/components/SettingsPanel';
import SearchPanel from 'src/components/SearchPanel';
import fieldMedLogo from 'src/assets/images/fieldmed-logo.svg';
import graphiumLogo from 'src/assets/images/graphium-logo.svg';
import { useRouteChange } from 'src/utils/hooks/useReactRouter';
import { ANALYTICS_NAMES } from 'src/utils/analytics';
import { ICONS, ROUTES } from 'src/utils/constants';
import * as variables from 'src/styles/variables';
import OrgStore from 'src/stores/OrgStore';
import OrgSelectPanel from 'src/components/OrgSelectPanel';
import { FeatureBranding, FeatureTelehealth } from 'src/components/FeatureFlag';
import { withAuth0 } from '@auth0/auth0-react';
import useFlag from 'src/utils/hooks/useFlag';

export function NavElement({
    link,
    name,
    icon,
    isLocked,
}: {
    link: string;
    name: string;
    icon: string;
    isLocked: boolean;
}) {
    const [isActive, setIsActive] = useState(false);
    return (
        <NavLink
            onClick={isLocked ? (e) => e.preventDefault() : undefined}
            to={link}
            isActive={(match, location) => {
                if (match || (location || { pathname: '/' }).pathname.includes(link.split('/')[2])) {
                    setIsActive(true);
                    return true;
                } else {
                    setIsActive(false);
                    return false;
                }
            }}
            className={isActive ? styles.active : styles.navLink}
        >
            <Flex direction="row" align="center" justify="center">
                {isLocked ? (
                    <Icon name="lock" type="fa" className={styles.linkIcon} color={variables.darkGrey} />
                ) : (
                    <Icon
                        name={icon}
                        className={styles.linkIcon}
                        color={isActive ? variables.white : variables.darkGrey}
                    />
                )}
                {name}
            </Flex>
        </NavLink>
    );
}

interface NavBarProps {
    auth0: any;
}

function NavBar(props: NavBarProps) {
    const switchOrgRef = useRef<HTMLDivElement>(null);
    const settingsRef = useRef<HTMLDivElement>(null);
    const searchRef = useRef<HTMLDivElement>(null);
    const [switchOrgOpen, setSwitchOrgOpen] = useState(false);
    const [settingsOpen, setSettingsOpen] = useState(false);
    const [searchOpen, setSearchOpen] = useState(false);
    const [orgName, setOrgName] = useState('');
    const authStore = useContext(AuthStore);
    const userStore = useContext(UserStore);
    const orgStore = useContext(OrgStore);
    const paymentsEnabled = useFlag('payments');

    useRouteChange(() => {
        setSearchOpen(false);
        setSettingsOpen(false);
        setSwitchOrgOpen(false);
    }, []);

    const handleLogout = () => {
        authStore.logout();
    };

    useEffect(() => {
        orgStore.getOrgsForUser();
    }, []);
    useEffect(() => {
        const name = (orgStore.orgs.find((o) => `${o.orgId}` === `${userStore.selectedOrg.orgId}`) || {}).orgNm;
        setOrgName(name);
    }, [userStore.selectedOrg, orgStore.orgs]);

    return (
        <Flex align="center" justify="center" className={styles.topnav}>
            <Flex className={styles.container}>
                <Flex value={1} direction="row">
                    <Flex value={1} direction="row" align="center" justify="center" className={styles.graphiumLogo}>
                        <NavLink exact={true} to={ROUTES.Home} className={styles.homeLink}>
                            <FeatureBranding
                                fieldmedContent={<img src={fieldMedLogo} className={styles.fieldMedLogo} />}
                                graphiumContent={<img src={graphiumLogo} className={styles.fieldMedLogo} />}
                            />
                        </NavLink>
                    </Flex>
                    <Flex
                        value={1.2}
                        align="center"
                        justify="center"
                        ref={switchOrgRef}
                        className={styles.orgLogo}
                        onClick={() => {
                            if (orgStore.orgs.length > 1) {
                                setSwitchOrgOpen(!switchOrgOpen);
                            }
                        }}
                        data-test-id={ANALYTICS_NAMES.Nav_OrgSwitch}
                    >
                        <span>{orgName}</span>
                        {orgStore.orgs.length > 1 ? <Icon name={ICONS.Arrow} className={styles.downArrow} /> : null}
                    </Flex>
                </Flex>
                <Flex value={2} align="center" justify="center">
                    <FeatureBranding
                        fieldmedContent={
                            <NavElement
                                link={ROUTES.getString(ROUTES.Patients)}
                                name="Patients"
                                icon={ICONS.AppWindowUser}
                                isLocked={!userStore.userPermissions.canView.patients}
                            />
                        }
                        graphiumContent={
                            <NavElement
                                link={ROUTES.getString(ROUTES.Encounters)}
                                name="Patients"
                                icon={ICONS.AppWindowUser}
                                isLocked={!userStore.userPermissions.canView.patients}
                            />
                        }
                    />
                    <FeatureBranding
                        fieldmedContent={
                            <NavElement
                                link={ROUTES.getString(ROUTES.Appointments)}
                                name="Scheduling"
                                icon={ICONS.Calendar}
                                isLocked={false}
                            />
                        }
                        graphiumContent={null}
                    />
                    <FeatureBranding
                        fieldmedContent={
                            <NavElement
                                link={ROUTES.getString(ROUTES.ReportsChp)}
                                name="Reports"
                                icon={ICONS.NavigationMenu2}
                                isLocked={!userStore.userPermissions.canView.reports}
                            />
                        }
                        graphiumContent={
                            <NavElement
                                link={ROUTES.getString(ROUTES.ReportsMacra)}
                                name="Reports"
                                icon={ICONS.NavigationMenu2}
                                isLocked={!userStore.userPermissions.canView.reports}
                            />
                        }
                    />
                    <FeatureTelehealth
                        content={
                            <NavElement
                                link={ROUTES.getString(ROUTES.TelehealthWaitingRoom)}
                                name="Telehealth"
                                icon={ICONS.GraphStatsCircle}
                                isLocked={false}
                            />
                        }
                    />
                    <FeatureBranding
                        fieldmedContent={null}
                        graphiumContent={
                            paymentsEnabled ? (
                                <NavElement
                                    link={ROUTES.getString(ROUTES.PaymentRequests)}
                                    name="Payment Requests"
                                    icon={ICONS.NavigationPayment}
                                    isLocked={!userStore.userPermissions.canView.paymentRequests}
                                />
                            ) : null
                        }
                    />
                </Flex>
                <Flex value={1} direction="row" className={styles.accountWrap} align="center" justify="end">
                    <FeatureBranding
                        fieldmedContent={
                            userStore.userPermissions.canView.patients ? (
                                <div
                                    ref={searchRef}
                                    onClick={() => setSearchOpen(!searchOpen)}
                                    className={classNames(styles.search, searchOpen ? styles.activeSettings : null)}
                                    data-test-id={ANALYTICS_NAMES.Nav_Search}
                                >
                                    <Icon name={ICONS.Search} className={classNames(styles.navIcon)} />
                                </div>
                            ) : null
                        }
                        graphiumContent={null}
                    />
                    <div
                        ref={settingsRef}
                        onClick={() => setSettingsOpen(!settingsOpen)}
                        className={classNames(styles.settings, settingsOpen ? styles.activeSettings : null)}
                        data-test-id={ANALYTICS_NAMES.Nav_Settings}
                    >
                        <Icon name={ICONS.Cog3} className={styles.navIcon} />
                    </div>
                    Hi, {userStore.frstNm}!
                </Flex>
            </Flex>
            <OrgSelectPanel parentRef={switchOrgRef} onClose={() => setSwitchOrgOpen(false)} isOpen={switchOrgOpen} />

            <SettingsPanel
                user={userStore}
                parentRef={settingsRef}
                onClose={() => setSettingsOpen(false)}
                isOpen={settingsOpen}
                onLogout={handleLogout}
            />

            <SearchPanel parentRef={searchRef} onClose={() => setSearchOpen(false)} isOpen={searchOpen} />
        </Flex>
    );
}

export default withAuth0(observer(NavBar));
