import { VitalsConnection } from 'common/lib/entity/org/VitalsConnection';
import React, { useContext, useEffect, useState } from 'react';
import { CellInfo } from 'react-table';
import ActionMenu from 'src/components/ActionMenu';
import Button from 'src/components/Button';
import Card from 'src/components/Card';
import FacilityDropDown from 'src/components/FacilityDropDown';
import Flex from 'src/components/Flex';
import Icon from 'src/components/Icon';
import Input from 'src/components/Input';
import PageContainer from 'src/components/PageContainer';
import PageContent from 'src/components/PageContent';
import Table from 'src/components/Table';
import TableActiveCol from 'src/components/TableActiveCol';
import { ModalStoreObject, ModalTypes } from 'src/stores/ModalStore';
import { ToastStoreObject } from 'src/stores/ToastStore';
import VitalsStore from 'src/stores/VitalsStore';
import { parseError } from 'src/utils';
import { ANALYTICS_NAMES } from 'src/utils/analytics';
import { ICONS } from 'src/utils/constants';
import useDebouncedCallback from 'src/utils/hooks/useDebouncedCallback';
import NavBar from '../NavBar';
import styles from './styles.module.scss';
import UserStore from 'src/stores/UserStore';

function VitalsConnections() {
    const [isLoading, setIsLoading] = useState(false);
    const [searchFacilityId, setSearchFacilityId] = useState(null);
    const vitalsStore = useContext(VitalsStore);
    const userStore = useContext(UserStore);
    const [searchValue, setSearchValue] = useState('');
    const [refreshSearchResults] = useDebouncedCallback(async (query: string, facilityId: number) => {
        setIsLoading(true);
        await getVitalsConnections({ query, facilityId }, true);
        setIsLoading(false);
    }, 300);

    useEffect(() => {
        getVitalsConnections({}, true);
    }, []);

    const columns = [
        {
            Header: 'ID',
            accessor: 'unitIdentifier',
        },
        {
            Header: 'NAME',
            accessor: 'displayName',
        },
        {
            Header: 'VENDOR',
            accessor: 'unitVendorName',
        },
        {
            Header: 'FACILITY',
            id: 'facility',
            accessor: (row: VitalsConnection) => row.facility?.facNm || '',
            minWidth: 100,
            Cell: (props: CellInfo) => <span className={styles.blackText}>{props.value}</span>,
        },
        {
            Header: 'ACTIVE',
            accessor: 'isActive',
            minWidth: 50,
            Cell: (props: CellInfo) => <TableActiveCol isActive={props.value && props.value.toString() === 'true'} />,
        },
        {
            Header: 'ACTIONS',
            accessor: 'actions',
            maxWidth: 125,
            Cell: (props: CellInfo) =>
                userStore.userPermissions.canEdit.vitalsConnections ? (
                    <ActionMenu
                        entityType="vitalsConnection"
                        entity={props.original}
                        onItemClick={(action: { id: string; label: string }) =>
                            handleActionClick(action, props.original)
                        }
                    />
                ) : null,
        },
    ];

    async function getVitalsConnections(options: { query?: string; facilityId?: number }, force: boolean = false) {
        setIsLoading(true);

        try {
            await vitalsStore.getVitalsConnections({ includeInactive: true, ...options }, force);
            await vitalsStore.getVitalsMeasures();
            await vitalsStore.getVitalsMeasureCategories();
        } catch (e: any) {
            ToastStoreObject.show(parseError(e));
        } finally {
            setIsLoading(false);
        }
    }

    function handleSearchQueryChange(userInput: string) {
        setSearchValue(userInput);
        refreshSearchResults(userInput, searchFacilityId);
    }

    function handleSearchFacilityChange(facilityId: number) {
        setSearchFacilityId(facilityId);
        refreshSearchResults(searchValue, facilityId);
    }

    function handleCreate() {
        ModalStoreObject.showModal(ModalTypes.VitalsConnectionModal, {
            onClose: () => ModalStoreObject.hideModal(),
            onSave: () => getVitalsConnections({ query: searchValue, facilityId: searchFacilityId }, true),
        });
    }

    function handleActionClick(action: { id: string; label: string }, item: VitalsConnection) {
        switch (action.id) {
            case 'edit':
                ModalStoreObject.showModal(ModalTypes.VitalsConnectionModal, {
                    onClose: () => ModalStoreObject.hideModal(),
                    onSave: () => getVitalsConnections({ query: searchValue, facilityId: searchFacilityId }, true),
                    vitalsConnection: item,
                    mode: 'edit',
                });
                break;
            case 'activate':
                if (item.isActive) {
                    ModalStoreObject.showModal(ModalTypes.ConfirmationModal, {
                        title: 'Are you sure you would like to deactivate this vitals connection?',
                        onConfirm: () => handleDeactivate(item.connectionId),
                        onCancel: () => ModalStoreObject.hideModal(),
                        confirmButtonText: 'Deactivate',
                    });
                } else {
                    handleReactivate(item.connectionId);
                }
                break;
        }
    }

    async function handleDeactivate(connectionId: VitalsConnection['connectionId']) {
        ModalStoreObject.hideModal();
        try {
            await vitalsStore.deactivateVitalsConnection(connectionId);
            getVitalsConnections({ query: searchValue, facilityId: searchFacilityId }, true);
        } catch (e: any) {
            ToastStoreObject.show(parseError(e));
        }
    }

    async function handleReactivate(connectionId: VitalsConnection['connectionId']) {
        ModalStoreObject.hideModal();
        try {
            await vitalsStore.reactivateVitalsConnection(connectionId);
            getVitalsConnections({ query: searchValue, facilityId: searchFacilityId }, true);
        } catch (e: any) {
            ToastStoreObject.show(parseError(e));
        }
    }

    return (
        <PageContainer>
            <NavBar />
            <PageContent>
                <Flex self="stretch" align="center" justify="start">
                    <Flex value={1} className={styles.searchWrap}>
                        <Input
                            className={styles.input}
                            placeholder="Search..."
                            value={searchValue}
                            onChangeText={(userInput: string) => handleSearchQueryChange(userInput)}
                        />
                    </Flex>
                    <Flex value={1}>
                        <FacilityDropDown
                            onChange={(e: any) => handleSearchFacilityChange((e || {}).value)}
                            isMulti={false}
                            allowEmpty={true}
                            isDefaultEmpty={true}
                            placeholder={'Filter by facility'}
                        />
                    </Flex>
                    <Flex value={1}></Flex>
                    <Flex value={1} direction="row" justify="end" className={styles.filterWrap}>
                        <Button
                            leftIcon={<Icon className={styles.plusIcon} name={ICONS.PlusButton} />}
                            type="primary"
                            onClick={() => handleCreate()}
                            isLocked={!userStore.userPermissions.canCreate.vitalsConnections}
                            text="Create Vitals Connection"
                            className={styles.createButton}
                            data-test-id={ANALYTICS_NAMES.VitalsConnection_Create}
                        />
                    </Flex>
                </Flex>
                <Card className={styles.cardWrap}>
                    <div className={styles.tableTitle}>Vitals Connections</div>
                    <Table isLoading={isLoading} columns={columns} data={vitalsStore.vitalsConnections} />
                </Card>
            </PageContent>
        </PageContainer>
    );
}

export default VitalsConnections;
