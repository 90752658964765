import React, { useState, useContext, useEffect } from 'react';
import Flex from 'src/components/Flex';
import Button from 'src/components/Button';
import PaymentsStore, { PaymentGateway, PaymentRequest, PaymentRequestState } from 'src/stores/PaymentsStore';
import LoadingIcon from 'src/components/LoadingIcon';
import { Elements, PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { ToastStoreObject } from 'src/stores/ToastStore';
import { ROUTES } from 'src/utils/constants';
import { useParams } from 'react-router';
import { formatNumberToCurrency } from 'common/lib/util/number';
import graphiumLogo from 'public/assets/graphium-logo-light-background.svg';
import premierLogo from 'public/assets/organizationLogos/premier.webp';
import { parseError } from 'src/utils';

interface CheckoutFormProps {
    clientSecret: string;
    paymentRequest: PaymentRequest;
    paymentGatewayId: PaymentGateway['paymentGatewayId'];
    paymentIntent: string;
    amountDue: number;
}

function CheckoutForm(props: CheckoutFormProps) {
    const stripe = useStripe();
    const elements = useElements();
    const paymentsStore = useContext(PaymentsStore);
    const { paymentRequestContactGuid } = useParams<{ paymentRequestContactGuid: string }>();
    const [isLoading, setIsLoading] = useState(true);

    async function handleSubmit() {
        try {
            if (!stripe || !elements) {
                return;
            }

            const { paymentGatewayId, paymentIntent } = props;

            await paymentsStore.createPaymentTransaction(props.paymentRequest.paymentRequestGuid, {
                paymentGatewayId,
                paymentRequestContactGuid,
                paymentIntent,
                paymentGatewayRequestData: { clientSecret: props.clientSecret },
            });

            const { error } = await stripe.confirmPayment({
                elements,
                confirmParams: {
                    return_url: `${window.location.origin}${ROUTES.getString(
                        ROUTES.PaymentCollectionStatus,
                        paymentRequestContactGuid,
                    )}`,
                },
            });

            if (error) {
                ToastStoreObject.show(error.message);
            }
        } catch (err: any) {
            ToastStoreObject.show(parseError(err));
        }
    }

    return (
        <>
            {isLoading && (
                <Flex className={styles.paymentLoadingContainer} align="center" justify="center">
                    <LoadingIcon />
                </Flex>
            )}
            <div className={classNames({ [styles.cardElementContainer]: !isLoading })}>
                <PaymentElement id="payment-element" onReady={() => setIsLoading(false)} />
            </div>
            <Button
                className={styles.payButton}
                type="primary"
                text={`Pay ${formatNumberToCurrency(props.amountDue)}`}
                onClick={() => handleSubmit()}
            />
        </>
    );
}

function PaymentCollection() {
    const [payState, setPayState] = useState('loading');
    const [clientSecret, setClientSecret] = useState('');
    const [paymentRequest, setPaymentRequest] = useState(null);
    const [landingPageText, setLandingPageText] = useState('');
    const [facilityName, setFacilityName] = useState('');
    const [paymentGatewayId, setPaymentGatewayId] = useState('');
    const [paymentIntent, setPaymentIntent] = useState('');
    const [amountDue, setAmountDue] = useState(0);
    const [stripePromise, setStripePromise] = useState(null);

    const { paymentRequestContactGuid } = useParams<{ paymentRequestContactGuid: string }>();

    const paymentsStore = useContext(PaymentsStore);

    useEffect(() => {
        getClientSecret();
    }, []);

    async function getClientSecret() {
        try {
            const intent = await paymentsStore.createPaymentIntent(paymentRequestContactGuid);
            setClientSecret(intent.clientSecret);
            setPaymentRequest(intent.paymentRequest);
            setLandingPageText(intent.landingPageText);
            setFacilityName(intent.facilityName);
            setPaymentGatewayId(intent.paymentGateway.paymentGatewayId);
            setPaymentIntent(intent.paymentIntent);
            setAmountDue(intent.amountDue);

            if (!stripePromise) {
                const { paymentGatewayConfig } = intent.paymentGateway;
                setStripePromise(loadStripe(paymentGatewayConfig['apiKey']));
            }

            const currentState = intent.paymentRequest && intent.paymentRequest.state;

            if (currentState) {
                switch (currentState.paymentRequestStateCode) {
                    case 'REQUEST_COMPLETED':
                        setPayState('completed');
                        break;
                    case 'REQUEST_CANCELLED':
                        setPayState('cancelled');
                        break;
                    default:
                        setPayState('init');
                }
            } else {
                setPayState('error');
            }
        } catch (e: any) {
            setPayState('error');
        }
    }

    function getLogo() {
        if (!paymentRequest || !paymentRequest.orgName) {
            return graphiumLogo;
        }

        switch (paymentRequest.orgName) {
            case 'pams2002':
                return premierLogo;
            default:
                return graphiumLogo;
        }
    }

    function getPaymentRequestAmount() {
        if (amountDue) {
            return formatNumberToCurrency(amountDue);
        } else {
            setPayState('error');
        }
    }

    function getPayStateTitle(payState: string) {
        switch (payState) {
            case 'error':
                return 'Error';
            case 'completed':
                return 'Payment Completed';
            case 'cancelled':
                return 'Payment Cancelled';
        }
    }

    function getPayStateText(payState: string) {
        switch (payState) {
            case 'error':
                return 'There was an error loading your payment request. Please try again later.';
            case 'completed':
                return 'This payment has already been completed.';
            case 'cancelled':
                return 'This payment has been cancelled.';
        }
    }

    function getContent() {
        switch (payState) {
            case 'loading':
                return (
                    <Flex align="center" justify="center">
                        <LoadingIcon />
                    </Flex>
                );
            case 'error':
            case 'completed':
            case 'cancelled':
                return (
                    <>
                        <Flex className={styles.innerContainer} direction="column">
                            <div className={styles.title}>{getPayStateTitle(payState)}</div>
                            <div className={styles.text}>{getPayStateText(payState)}</div>
                            {payState === 'error' && (
                                <Button
                                    className={styles.payButton}
                                    type="primary"
                                    text="Refresh"
                                    onClick={getClientSecret}
                                />
                            )}
                        </Flex>
                    </>
                );
            case 'init':
                return (
                    <>
                        {landingPageText !== '' ? (
                            <Flex className={styles.innerContainer} direction="column">
                                <div className={styles.title}>Description</div>
                                <div className={styles.text}>{landingPageText}</div>
                            </Flex>
                        ) : null}
                        <Flex className={styles.innerContainer} direction="column">
                            <div className={styles.title}>Payment Summary</div>
                            <Flex className={styles.item}>
                                <div className={styles.itemName}>Amount</div>
                                <div className={styles.itemValue}>{getPaymentRequestAmount()}</div>
                            </Flex>
                            <Flex className={styles.item}>
                                <div className={styles.itemName}>Facility</div>
                                <div className={styles.itemValue}>{facilityName}</div>
                            </Flex>
                        </Flex>
                        <Button
                            className={styles.payButton}
                            type="primary"
                            text="Pay Now"
                            onClick={() => setPayState('payment')}
                        />
                    </>
                );
            case 'payment':
                return (
                    <Elements stripe={stripePromise} options={{ clientSecret }}>
                        <Flex className={styles.innerContainer} direction="column">
                            <div className={styles.title}>Enter Your Credit Card Information</div>
                            {clientSecret && (
                                <CheckoutForm
                                    clientSecret={clientSecret}
                                    paymentRequest={paymentRequest}
                                    paymentIntent={paymentIntent}
                                    paymentGatewayId={paymentGatewayId}
                                    amountDue={amountDue}
                                />
                            )}
                        </Flex>
                    </Elements>
                );
        }
    }

    return (
        <Flex align="center" direction="column" className={styles.pageContainer}>
            <Flex className={styles.paymentContainer} direction="column">
                {paymentRequest && (
                    <div className={styles.logo}>
                        <div className={styles.logoContainer}>
                            <img src={getLogo()} />
                        </div>
                    </div>
                )}
                {getContent()}
            </Flex>
            <div className={styles.copyright}>
                &copy; {`${new Date().getFullYear()} Graphium Health`}, All rights reserved
            </div>
        </Flex>
    );
}

export default PaymentCollection;
