import React, { MutableRefObject, useEffect, useRef } from 'react';
import styles from './styles.module.scss';
import AceEditor, { IAceEditorProps } from 'react-ace';
import 'ace-builds/src-noconflict/mode-typescript';
import 'ace-builds/src-noconflict/mode-xml';
import 'ace-builds/src-noconflict/theme-tomorrow';
import 'ace-builds/src-noconflict/theme-xcode';

interface CodeEditorProps extends IAceEditorProps {
    onRefChange?: (ref: MutableRefObject<any>) => void;
}

function CodeEditor(props: CodeEditorProps) {
    const actualRef = useRef<any>(null);

    useEffect(() => {
        if (props.onRefChange) {
            props.onRefChange(actualRef);
        }
    }, [actualRef]);

    return (
        <AceEditor
            className={styles.editor}
            mode={props.mode || 'typescript'}
            theme={props.theme || 'tomorrow'}
            minLines={15}
            width="100%"
            maxLines={Infinity}
            highlightActiveLine={false}
            wrapEnabled={true}
            editorProps={{ $blockScrolling: true }}
            setOptions={{ useWorker: false }}
            ref={actualRef}
            data-test-id="CodeEditor"
            {...props}
        />
    );
}

export default CodeEditor;
