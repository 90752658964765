import { MacraAvailableYears } from 'common/lib/model/macra/Macra';
import React from 'react';
import Accordion from 'src/components/Accordion';
import classNames from 'classnames';
import { Activity, ACTIVITIES_2023, ACTIVITIES_2024 } from 'src/stores/QcdrSettingsStore';
import styles from './styles.module.scss';

interface ActivitiesContainerProps {
    year: MacraAvailableYears;
}

function ActivitiesContainer(props: ActivitiesContainerProps) {
    const activities = props.year === 2023 ? ACTIVITIES_2023 : ACTIVITIES_2024;

    function formatListData(listData: string[]) {
        return (
            <ul>
                {listData.map((data: string, index: number) => (
                    <li key={index}>{data}</li>
                ))}
            </ul>
        );
    }

    return (
        <div>
            {activities.map((activity: Activity, index: number) => {
                const { id, title, description, listData, weighting, category } = activity;

                return (
                    <Accordion key={index} label={title} removeExtraSpacing={true} removeExtraPadding={true}>
                        <div className={styles.activity}>
                            <p>{description}</p>
                            {listData && formatListData(listData)}
                            <div className={styles.activityMetadata}>
                                <div className={styles.metadataContainer}>
                                    <div className={styles.metadataHeader}>ID</div>
                                    <div className={styles.metadataContent}>{id}</div>
                                </div>
                                <div className={styles.metadataContainer}>
                                    <div className={styles.metadataHeader}>Weighting</div>
                                    <div className={styles.metadataContent}>{weighting}</div>
                                </div>
                                <div className={classNames(styles.metadataContainer, styles.metadataSubCategory)}>
                                    <div className={styles.metadataHeader}>Sub-Category</div>
                                    <div className={styles.metadataContent}>{category}</div>
                                </div>
                            </div>
                        </div>
                    </Accordion>
                );
            })}
        </div>
    );
}

export default ActivitiesContainer;
