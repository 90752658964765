import * as ROUTES from 'src/api/routes';
import request from 'src/api/utils';

export type ScorecardQueryParams = {
    id: string | number;
    startDate: string;
    endDate: string;
    isTin?: boolean;
    enforceRollup?: boolean;
};

export async function getScorecardOrgMetrics(params: ScorecardQueryParams) {
    const response = await request({ ...ROUTES.SCORECARD_GET_ORG_METRICS, params });
    return response;
}
