import React from 'react';

const SvgNavigationNextAlternate = (props) => (
    <svg width={17} height={13} {...props} viewBox="0 0 17 13">
        <g fill={props.color || '#FFF'} fillRule="nonzero">
            <path d="M16.788 5.207a.732.732 0 000-1.022l-2.48-2.524a.698.698 0 00-.765-.152.722.722 0 00-.439.658V3.43a.182.182 0 01-.051.127.175.175 0 01-.126.053H8.854C7.29 3.613 6.023 4.905 6.021 6.5v1.444c0 .599.476 1.084 1.062 1.084.587 0 1.063-.485 1.063-1.084V6.5c0-.399.317-.722.708-.722h4.073c.047 0 .092.019.126.052.033.034.051.08.051.128v1.264c0 .293.173.556.438.668.265.112.57.05.772-.157l2.474-2.526z" />
            <path d="M11.333 6.861a.715.715 0 00-.708.722v3.25c0 .2-.159.361-.354.361h-8.5a.358.358 0 01-.354-.36V2.166c0-.2.158-.361.354-.361h8.854c0 .398.317.722.708.722a.715.715 0 00.709-.722c0-.798-.635-1.445-1.417-1.445H1.417C.634.361 0 1.008 0 1.806v9.388c0 .798.634 1.445 1.417 1.445h9.208c.782 0 1.417-.647 1.417-1.445v-3.61a.715.715 0 00-.709-.723z" />
        </g>
    </svg>
);

export default SvgNavigationNextAlternate;
