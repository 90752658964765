import { observable, action, makeObservable } from 'mobx';
import { createContext } from 'react';

export enum ErrorTypes {
    Loading = 'Loading',
    Startup = 'Startup',
}

class ErrorStore {
    @observable errors: { [key in ErrorTypes]?: boolean } = {};

    constructor() {
        makeObservable(this);
    }

    @action
    setError(type: ErrorTypes) {
        this.errors[type] = true;
    }

    @action
    clearError(type: ErrorTypes) {
        this.errors[type] = false;
    }

    @action
    clearAllErrors() {
        this.errors[ErrorTypes.Loading] = false;
        this.errors[ErrorTypes.Startup] = false;
    }
}

// Import this to any other store that needs to use a value from it
export const ErrorStoreObject = new ErrorStore();

export default createContext(ErrorStoreObject);
