import { FacilityStatus } from 'common/lib/model/facility/FacilitySubscriptionData';
import React from 'react';
import { useState } from 'react';
import Select from 'react-select';
import styles from '../styles.module.scss';
import { styleSelectComponent } from 'src/utils';
import { observer } from 'mobx-react';

interface FacilityStatusDropdownProps {
    onChange: Function;
    selectedValue?: FacilityStatus;
}

function FacilityStatusDropdown(props: FacilityStatusDropdownProps) {
    const options = mapStatusOptions();
    const [selected, setSelected] = useState(options.find((i) => i.value === props.selectedValue) || null);

    function mapStatusOptions() {
        const types = Object.values(FacilityStatus);
        const typeOptions = types.map((value) => {
            let label;
            switch (value) {
                case FacilityStatus.Active:
                    label = 'Active';
                    break;
                case FacilityStatus.Offboarded:
                    label = 'Offboarded';
                    break;
                case FacilityStatus.Offboarding:
                    label = 'Offboarding';
                    break;
                case FacilityStatus.Onboarding:
                    label = 'Onboarding';
                    break;
            }

            return { label, value };
        });

        return typeOptions.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
    }

    return (
        <Select
            value={selected}
            onChange={(s: any) => {
                setSelected(s);
                props.onChange(s || null);
            }}
            isClearable={true}
            className={styles.select}
            options={options}
            placeholder="Select Facility Status"
            styles={styleSelectComponent()}
        />
    );
}

export default observer(FacilityStatusDropdown);
