import React from 'react';

const UrgencyMedium = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.52 13.61" width={18} height={14} {...props}>
        <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
                <path fill="#ffbb42" d="M18.35,12.92a1,1,0,0,0-.28-1.39l-8-5.3a1.6,1.6,0,0,0-1.66,0l-8,5.3a1,1,0,0,0-.28,1.39l.16.25a1,1,0,0,0,1.39.28L8.43,9a1.6,1.6,0,0,1,1.66,0l6.71,4.46a1,1,0,0,0,1.38-.28Z"/>
                <path fill="#ffbb42" d="M18.35,6.92a1,1,0,0,0-.28-1.39l-8-5.3a1.6,1.6,0,0,0-1.66,0l-8,5.3A1,1,0,0,0,.17,6.92l.16.25a1,1,0,0,0,1.39.28L8.43,3a1.6,1.6,0,0,1,1.66,0L16.8,7.45a1,1,0,0,0,1.38-.28Z"/>
            </g>
        </g>
    </svg>
);

export default UrgencyMedium;
