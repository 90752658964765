import React, { useState, useContext, useEffect } from 'react';
import NavBar from 'src/containers/NavBar';
import PageContent from 'src/components/PageContent';
import PageContainer from 'src/components/PageContainer';
import Card from 'src/components/Card';
import Flex from 'src/components/Flex';
import Table from 'src/components/Table';
import styles from './styles.module.scss';
import { CellInfo } from 'react-table';
import Input from 'src/components/Input';
import Button from 'src/components/Button';
import { useHistory } from 'react-router-dom';
import { ErrorStoreObject, ErrorTypes } from 'src/stores/ErrorStore';
import TableActiveCol from 'src/components/TableActiveCol';
import { getFlowScriptTemplates, updateFlowScriptTemplate } from 'src/api/admin';
import { ModalStoreObject, ModalTypes } from 'src/stores/ModalStore';
import { AdminStoreObject } from 'src/stores/AdminStore';
import { FlowScriptType, FlowScriptTemplate } from 'src/utils/types';
import { ROUTES } from 'src/utils/constants';
import { ANALYTICS_NAMES } from 'src/utils/analytics';

function AdminFlowScriptTemplates() {
    const history = useHistory();
    const columns = [
        {
            Header: 'TITLE',
            accessor: 'flowScriptTemplateTitle',
            minWidth: 150,
        },
        {
            Header: 'DESCRIPTION',
            accessor: 'flowScriptTemplateDescription',
            minWidth: 200,
        },
        {
            Header: 'TYPE',
            accessor: 'flowScriptTypeId',
            minWidth: 150,
            Cell: (props: CellInfo) => {
                const type: FlowScriptType = AdminStoreObject.flowScriptTypes.find(
                    (t: FlowScriptType) => t.flowScriptTypeId === props.value,
                );
                return <span>{type ? type.flowScriptTypeTitle : ''}</span>;
            },
        },
        {
            Header: 'IS GLOBAL?',
            accessor: 'systemGlobal',
            Cell: (props: CellInfo) => <TableActiveCol isActive={props.value} activeText="YES" inactiveText="NO" />,
        },
        {
            Header: 'PUBLISHED VERSION',
            accessor: 'publishedVersion',
        },
        {
            Header: 'ACTIVE',
            accessor: 'active',
            minWidth: 75,
            Cell: (props: CellInfo) => <TableActiveCol isActive={props.value} />,
        },
        {
            Header: 'ACTIONS',
            accessor: 'actions',
            sortable: false,
            minWidth: 200,
            style: { paddingVertical: 0, paddingHorizontal: 20 },
            Cell: (props: CellInfo) => (
                <div className={styles.actionWrap}>
                    <Button
                        onClick={() => {
                            history.push(
                                ROUTES.getString(
                                    ROUTES.AdminFlowScriptTemplateDetail,
                                    props.original.flowScriptTemplateGuid,
                                ),
                            );
                        }}
                        className={styles.leftButton}
                        type="small"
                        text="View Details"
                        data-test-id={ANALYTICS_NAMES.FlowScriptTemplates_ViewDetails}
                    />
                    <Button
                        onClick={() => handleActivate(props.original)}
                        className={styles.leftButton}
                        type="secondarySmall"
                        text={props.original.active ? 'Deactivate' : 'Reactivate'}
                        data-test-id={ANALYTICS_NAMES.FlowScriptTemplates_Activation}
                    />
                    <Button
                        onClick={() => {
                            ModalStoreObject.showModal(ModalTypes.CreateFlowScriptTemplate, {
                                onClose: () => ModalStoreObject.hideModal(),
                                onSave: () => {
                                    retrieveTemplates();
                                    ModalStoreObject.hideModal();
                                },
                                isEdit: true,
                                flowScriptTemplate: props.original,
                            });
                        }}
                        type="secondarySmall"
                        text="Edit"
                        data-test-id={ANALYTICS_NAMES.FlowScriptTemplates_Edit}
                    />
                </div>
            ),
        },
    ];

    const [isLoading, setIsLoading] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [templates, setTemplates] = useState<any[]>([]);

    async function retrieveTemplates() {
        try {
            setIsLoading(true);
            await AdminStoreObject.getFlowScriptTypes();
            const templateData = await getFlowScriptTemplates();
            setTemplates(templateData);
            setIsLoading(false);
        } catch (e) {
            ErrorStoreObject.setError(ErrorTypes.Loading);
        }
    }
    useEffect(() => {
        retrieveTemplates();
    }, []);

    function createFlowScriptTemplate() {
        ModalStoreObject.showModal(ModalTypes.CreateFlowScriptTemplate, {
            onClose: () => ModalStoreObject.hideModal(),
            onSave: () => {
                retrieveTemplates();
                ModalStoreObject.hideModal();
            },
        });
    }

    function handleActivate(flowScriptTemplate: FlowScriptTemplate) {
        if (flowScriptTemplate.active) {
            ModalStoreObject.showModal(ModalTypes.ConfirmationModal, {
                title: 'Are you sure you would like to deactivate this template?',
                onConfirm: () => handleDeactivate(flowScriptTemplate),
                onCancel: () => ModalStoreObject.hideModal(),
                confirmButtonText: 'Deactivate',
            });
        } else {
            handleReactivate(flowScriptTemplate);
        }
    }

    async function handleDeactivate(flowScriptTemplate: FlowScriptTemplate) {
        await updateFlowScriptTemplate(flowScriptTemplate.flowScriptTemplateGuid, {
            active: false,
        });
        retrieveTemplates();
        ModalStoreObject.hideModal();
    }

    async function handleReactivate(flowScriptTemplate: FlowScriptTemplate) {
        await updateFlowScriptTemplate(flowScriptTemplate.flowScriptTemplateGuid, {
            active: true,
        });
        retrieveTemplates();
        ModalStoreObject.hideModal();
    }

    return (
        <PageContainer>
            <NavBar />
            <PageContent>
                <Flex direction="row" self="stretch" align="center" justify="start">
                    <Flex value={1} />
                    <Button
                        type="primary"
                        onClick={createFlowScriptTemplate}
                        text="Create Template"
                        data-test-id={ANALYTICS_NAMES.FlowScriptTemplates_CreateTemplate}
                    />
                </Flex>
                <Card className={styles.cardWrap}>
                    <div className={styles.tableTitle}>Flow Script Templates</div>
                    <Table isLoading={isLoading} columns={columns} data={templates} showPagination={false} />
                </Card>
            </PageContent>
        </PageContainer>
    );
}
export default AdminFlowScriptTemplates;
