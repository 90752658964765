import { observable, action, toJS, makeObservable } from 'mobx';
import { createContext } from 'react';
import uniqBy from 'lodash/uniqBy';
import * as eventsAPI from 'src/api/events';
import { EventStateStoreObject, EventStateCodes } from './EventStateStore';
import { Facility, FacilityStoreObject } from './FacilityStore';
import { CalendarEventInterface, Event } from 'src/utils/types';
import { autoSave } from 'src/utils/mobxCache';
import { Team } from 'src/stores/TeamStore';
import { OrgUser } from './OrgUserStore';

export function formatEventsForCalendar(arr: Event[]): CalendarEventInterface[] {
    return arr.map((a) => {
        const newEvent: CalendarEventInterface = {
            title: a.eventName,
            start: new Date(`${a.eventStartDate}T${a.eventStartTime}`),
            end: new Date(`${a.eventEndDate}T${a.eventEndTime}`),
            extendedProps: {
                ghEvent: a,
            },
        };
        return newEvent;
    });
}

type FetchEventsParams = {
    filters: {
        facId?: Facility['facId'];
        firstDay?: string;
        lastDay?: string;
        patientId?: number;
        type?: 'APPOINTMENT' | 'TASK';
    };
};

type EventStatusOption = {
    value: 'All' | 'Complete' | 'Incomplete' | 'Canceled' | 'Rescheduled' | 'Open',
    label: 'All' | 'Complete' | 'Incomplete' | 'Canceled' | 'Rescheduled' | 'Open',
}

type SelectedView = {
    value: CalendarViewTypes; 
    label: string;
}

export const eventStatusOptions: EventStatusOption[] = [
    { value: 'All', label: 'All' },
    { value: 'Complete', label: 'Complete' },
    { value: 'Incomplete', label: 'Incomplete' },
    { value: 'Canceled', label: 'Canceled' },
    { value: 'Rescheduled', label: 'Rescheduled' },
    { value: 'Open', label: 'Open' },
];

enum CalendarViewTypes {
    Monthly = 'Monthly',
    Weekly = 'Weekly',
    Daily = 'Daily',
}

const viewOptions: Array<{ value: CalendarViewTypes; label: string }> = [
    { value: CalendarViewTypes.Weekly, label: 'Weekly View' },
    { value: CalendarViewTypes.Monthly, label: 'Monthly View' },
    { value: CalendarViewTypes.Daily, label: 'Daily View' },
];

export const NO_TEAM_ID = 'noteam';

const name = 'EventStore';
const cachedValues = [
    'selectedFacilityId', 
    'isTasksChecked', 
    'isAppointmentsChecked', 
    'eventStatusOption', 
    'checkedTeams',
    'checkedUsers',
    'selectedView'
];

class EventStore {
    // This is the facility id to be used across event API calls
    @observable selectedFacilityId: Facility['facId'] = 0;
    @observable isTasksChecked: boolean = true;
    @observable isAppointmentsChecked: boolean = true;
    @observable eventStatusOption: EventStatusOption = eventStatusOptions[0];
    @observable checkedTeams: (number | string)[] = [NO_TEAM_ID];
    @observable checkedUsers: (number)[] = [];
    @observable selectedView: SelectedView = viewOptions[0];
    @observable events: Event[] = [];
    @observable patientTasks: Event[] = [];
    @observable patientAppointments: Event[] = [];
    @observable calendarEvents: CalendarEventInterface[] = [];

    constructor() {
        makeObservable(this);
        autoSave(this, name, cachedValues);
    }

    @action
    reset() {
        this.selectedFacilityId = undefined;
        this.events = [];
        this.patientTasks = [];
        this.patientAppointments = [];
        this.calendarEvents = [];
    }

    async fetchEvents(params: FetchEventsParams = { filters: {} }) {
        const queryParams = params;
        // If the current facility is not set, set it
        if (!this.selectedFacilityId && FacilityStoreObject.defaultFacilityId) {
            this.setFacilityId(FacilityStoreObject.defaultFacilityId);
        }
        if (!queryParams.filters.facId) {
            queryParams.filters.facId = this.selectedFacilityId;
        }
        const results = await eventsAPI.fetchEvents(queryParams);
        return results.data || [];
    }

    @action
    setFacilityId(id: Facility['facId']) {
        this.selectedFacilityId = id;
    }

    @action
    setTasksChecked(value: boolean) {
        this.isTasksChecked = value;
    }

    @action
    setAppointmentsChecked(value: boolean) {
        this.isAppointmentsChecked = value;
    }

    @action
    setEventStatusOption(value: EventStatusOption) {
        this.eventStatusOption = value;
    }

    @action
    selectTeam(value: number | string) {
        this.checkedTeams = [...this.checkedTeams, value];
    }

    @action
    deselectTeam(value: number | string) {
        this.checkedTeams = this.checkedTeams.filter((team: number | string) => team !== value);
    }

    @action
    selectAllTeams(teams: Team[]) {
        this.checkedTeams = [...teams.map((t: Team) => t.teamId), NO_TEAM_ID];
    }

    @action
    deselectAllTeams() {
        this.checkedTeams = [];
    }

    @action
    selectUser(value: number) {
        this.checkedUsers = [...this.checkedUsers, value];
    }

    @action
    deselectUser(value: number) {
        this.checkedUsers = this.checkedUsers.filter((user: number | string) => user !== value);
    }

    @action
    selectAllUsers(users: OrgUser[]) {
        this.checkedUsers = users.map((u: OrgUser) => u.usrId);
    }

    @action
    deselectAllUsers() {
        this.checkedUsers = [];
    }

    @action
    setSelectedView(value: SelectedView) {
        this.selectedView = value;
    }

    @action
    async getEvents(params: FetchEventsParams = { filters: {} }) {
        const data = await this.fetchEvents(params);
        if (params && params.filters && params.filters.patientId) {
            if (params.filters.type === 'TASK') {
                this.setPatientTasks(data);
            } else if (params.filters.type === 'APPOINTMENT') {
                this.setPatientAppointments(data);
            }
        } else {
            this.setEvents(data);
        }
    }

    @action
    async getMoreEvents(dateParams: {
        firstDay: FetchEventsParams['filters']['firstDay'];
        lastDay: FetchEventsParams['filters']['lastDay'];
    }) {
        const data = await this.fetchEvents({ filters: dateParams });
        const newEvents = uniqBy([].concat(toJS(this.events), data), 'eventId'); // Make sure there a no duplicates in case of overlapping from/to dates
        this.setEvents(newEvents);
    }

    @action
    async updateEvent(eventId: Event['eventId'], data: object) {
        const response = await eventsAPI.updateEvent(eventId, data);
        const updatedEvent = response.data;
        const newEvents = this.events.map((e: Event) => (e.eventId === updatedEvent.eventId ? updatedEvent : e));
        this.setEvents(newEvents);
    }

    @action
    async cancelEvent(eventId: Event['eventId'], data: { reason: string; type: string }) {
        const canceledId = EventStateStoreObject.eventStatesObject[EventStateCodes.CANCELED].eventStateId;

        await this.updateEvent(eventId, {
            cancelReasonType: data.type,
            cancelReasonText: data.reason,
            eventStateId: canceledId,
        });
    }

    @action
    async rescheduleEvent(
        eventId: Event['eventId'],
        data: { eventStartTime?: string; eventEndTime?: string; eventStartDate?: string; eventEndDate?: string },
    ) {
        const response = await eventsAPI.rescheduleEvent(eventId, data);
        // Get the original event and the new one and add them to state
        const updatedOriginal = response.data.updatedOriginal;
        const newEvent = response.data.newEvent;
        const newEvents = [...this.events, newEvent].map((e: Event) =>
            e.eventId === updatedOriginal.eventId ? updatedOriginal : e,
        );
        this.setEvents(newEvents);
    }

    @action
    async completeEvent(eventId: Event['eventId']) {
        const completedId = EventStateStoreObject.eventStatesObject[EventStateCodes.COMPLETED].eventStateId;

        await this.updateEvent(eventId, {
            eventStateId: completedId,
        });
    }

    @action
    async createEvent(event: any) {
        const newEvent = await eventsAPI.createEvent(event);
        const newEvents = [...this.events, newEvent.data]; // Add the new event and then set them
        this.setEvents(newEvents);
    }

    @action
    setEvents(events: Event[]) {
        const uniqEvents = uniqBy(events, 'eventId'); // Make sure there a no duplicates
        this.events = uniqEvents;
        this.calendarEvents = formatEventsForCalendar(uniqEvents);
    }

    @action
    setPatientTasks(patientEvents: Event[]) {
        this.patientTasks = patientEvents;
    }

    @action
    setPatientAppointments(patientEvents: Event[]) {
        this.patientAppointments = patientEvents;
    }
}

// Import this to any other store that needs to use a value from it
export const EventStoreObject = new EventStore();

export default createContext(EventStoreObject);
