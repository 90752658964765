import React, { useState, useEffect } from 'react';
import Icon from 'src/components/Icon';
import Button from 'src/components/Button';
import { ICONS } from 'src/utils/constants';
import AnimateHeight from 'react-animate-height';
import classNames from 'classnames';
import styles from '../../styles.module.scss';
import * as variables from 'src/styles/variables';

interface InputGroupProps {
    value: string;
    inputGroup: any;
    inputs: any;
    isGrid?: boolean;
    isRepeating?: boolean;
    onChange?: Function;
    onAdd?: Function;
}

function InputGroup(props: InputGroupProps) {
    const [isOpen, setIsOpen] = useState(true);
    const [isValid, setIsValid] = useState(true);
    const isRepeating = props.isRepeating;

    useEffect(() => {
        const validArray =
            props.inputs &&
            props.inputs.map &&
            props.inputs.map((input: any) => {
                if (input) {
                    return input.props.isValid;
                }

                return null;
            });

        setIsValid(props.isGrid ? true : !validArray.includes(false));
    }, [props.inputs]);

    function getInputs() {
        return props.inputs;
    }

    return (
        <div style={{ width: '100%' }}>
            <div className={styles.label}>{props.inputs && props.inputs.title ? props.inputs.title[0] : ''}</div>
            <div
                className={classNames(styles.groupContainer, {
                    [styles.open]: isOpen,
                    [styles.invalid]: !isValid,
                })}
            >
                <div className={styles.groupHeader} onClick={() => setIsOpen(!isOpen)}>
                    <div className={styles.value}>{props.value}</div>
                    <div className={styles.arrow}>
                        <Icon name={ICONS.Arrow} color={variables.darkBlue} />
                    </div>
                </div>
                <AnimateHeight duration={500} height={isOpen ? 'auto' : 0}>
                    <div className={styles.groupContent}>
                        {getInputs()}
                        {isRepeating ? (
                            <Button
                                type="transparent"
                                text={`+ Add Another ${
                                    props.inputGroup.groupItemNameSingular
                                        ? props.inputGroup.groupItemNameSingular[0]
                                        : ''
                                }`}
                                onClick={() => props.onAdd()}
                                className={styles.addButton}
                            />
                        ) : null}
                    </div>
                </AnimateHeight>
            </div>
        </div>
    );
}

export default InputGroup;
