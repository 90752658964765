import React, { useState } from 'react';
import classNames from 'classnames';
import { DayPickerInputProps, DayModifiers } from 'react-day-picker';
import DPI from 'react-day-picker/DayPickerInput';
import './daypicker.scss';
import { format as formatDateFns, parseISO } from 'date-fns';
import styles from './styles.module.scss';
import 'react-datepicker/dist/react-datepicker.css';
import { Label, InputProps } from 'src/components/Input';
import { DISPLAY_DATE_FORMAT } from 'src/utils/constants';
import * as utils from 'src/utils';
import { ANALYTICS_NAMES } from 'src/utils/analytics';

const currentYear = new Date().getFullYear();
const fromMonth = new Date(currentYear - 100, 0);

const pastYears: number[] = [];
const allYears: number[] = [];
for (let i = currentYear - 100; i <= currentYear; i++) {
    pastYears.push(i);
}
for (let i = currentYear - 100; i <= currentYear + 5; i++) {
    allYears.push(i);
}

export interface MonthYearProps {
    date?: any;
    localeUtils?: any;
    onChange?: any;
    onlyPast?: boolean;
}

export const MonthYearSelector = ({ date, localeUtils, onChange, onlyPast }: MonthYearProps) => {
    const months = localeUtils.getMonths();

    const handleChange = function handleChange(e: any) {
        const { year, month } = e.target.form;
        onChange(new Date(year.value, month.value));
    };

    const years = onlyPast ? pastYears : allYears;

    return (
        <form className="DayPicker-Caption">
            <select name="month" onChange={handleChange} value={date.getMonth()}>
                {months.map((month: any, i: string | number | string[]) => (
                    <option key={month} value={i}>
                        {month}
                    </option>
                ))}
            </select>
            <select name="year" onChange={handleChange} value={date.getFullYear()}>
                {years.map((year) => (
                    <option key={year} value={year}>
                        {year}
                    </option>
                ))}
            </select>
        </form>
    );
};

export interface DatePickerProps extends Partial<DayPickerInputProps> {
    label?: InputProps['label'];
    infoState?: InputProps['infoState'];
    onChange?: (d: any) => void;
    disabled?: boolean;
    format?: string;
    onlyPast?: boolean;
    inputClassName?: string;
    wrapClassName?: string;
    required?: boolean;
}

const DatePicker = ({
    label,
    infoState,
    disabled,
    value,
    onChange,
    format = DISPLAY_DATE_FORMAT,
    onlyPast,
    inputClassName,
    wrapClassName,
    required,
}: DatePickerProps) => {
    const selectedVal = typeof value === 'string' ? parseISO(value) : value || undefined;
    const [month, setMonth] = useState(selectedVal);
    const [selected, setSelected] = useState(selectedVal);
    const [isValid, setIsValid] = useState(true);

    // @ts-ignore
    const DayPickerInput = DPI.__esModule ? DPI.default : DPI; // Workaround for ESM build

    function handleYearMonthChange(month: any) {
        setMonth(month);
    }
    function handleSelect(day: Date, dayModifiers: DayModifiers, dayPickerInput: any) {
        if (day) {
            setIsValid(true);
        } else if (!dayPickerInput.getInput().value) {
            // It's valid if the input value is an empty string
            setIsValid(true);
        } else {
            setIsValid(false);
        }
        setSelected(day);
        onChange(day);
    }

    return (
        <div className={classNames(styles.wrap, wrapClassName)}>
            {label ? <Label data-test-id={ANALYTICS_NAMES.DatePicker_Label} text={label} required={required} /> : null}
            <div
                className={classNames(
                    styles.inputWrap,
                    {
                        [styles.disabled]: disabled,
                        [styles.error]: !isValid || infoState === 'error',
                        [styles.warning]: infoState === 'warning',
                    },
                    inputClassName,
                )}
                data-test-id={ANALYTICS_NAMES.DatePicker_Input_Wrap}
            >
                <DayPickerInput
                    formatDate={(d: Date) => (d ? formatDateFns(d, format) : '')}
                    parseDate={(str: string | number) => {
                        const parsedStr = str.toString();
                        if (utils.validateDate(parsedStr)) {
                            return new Date(parsedStr);
                        }
                    }}
                    format={format}
                    value={selectedVal}
                    inputProps={{ readOnly: disabled }}
                    onDayChange={handleSelect}
                    placeholder={format ? format.toUpperCase() : 'MM/DD/YYYY'}
                    keepFocus={false}
                    dayPickerProps={{
                        month: month,
                        selectedDays: selected,
                        fromMonth: fromMonth,
                        captionElement: ({ date, localeUtils }: any) => (
                            <MonthYearSelector
                                onlyPast={onlyPast}
                                date={date}
                                localeUtils={localeUtils}
                                onChange={handleYearMonthChange}
                            />
                        ),
                    }}
                />
            </div>
        </div>
    );
};

export default DatePicker;
