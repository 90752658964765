import { FacilitySubscriptionData } from 'common/lib/model/facility/FacilitySubscriptionData';
import { observable, action, makeObservable } from 'mobx';
import { createContext } from 'react';
import * as facilitiesAPI from 'src/api/facilities';
import * as utils from 'src/utils';
import Storage from 'src/utils/storage';

export interface Facility {
    facId?: number;
    orgId?: number | string;
    facNm?: string;
    facDesc?: string;
    addrLn1?: string;
    addrLn2?: string;
    addrCityNm?: string;
    addrStCd?: string;
    addrZipCd?: string;
    phNoMain?: string;
    actvInd?: boolean;
    facNmIntrnl?: string;
    testFacInd?: boolean;
    audVer?: number;
    insDttm?: Date | string;
    updDttm?: Date | string;
    facilityTypeCode?: string;
    facilityNumber?: string;
    gpsLocation?: string;
    nationalGridCoordinates?: string;
    cityCode?: string;
    stateCode?: string;
    countyCode?: string;
    countryCode?: string;
    facilityPhones?: FacilityPhoneNumber[];
    subscriptionData?: FacilitySubscriptionData;
    facilityTimeZone?: 'US/Central' | 'US/Eastern' | 'US/Mountain' | 'US/Pacific';
}

export interface FacilityPhoneNumber {
    typeCode: string;
    phoneNumber: string;
}

class FacilityStore {
    @observable defaultFacilityId: Facility['facId'];
    @observable facilities: Facility[] = [];
    @observable facilitiesDropdownValues: { value: string | number; label: string }[] = [];

    constructor() {
        makeObservable(this);
        this.populateData();
    }

    @action
    reset() {
        this.defaultFacilityId = undefined;
        this.facilities = [];
        this.facilitiesDropdownValues = [];
        Storage.remove('facilities');
    }

    @action
    async getFacilities(force: boolean = false) {
        if (!force && this.facilities.length > 0) {
            return;
        }
        const facilities = await facilitiesAPI.fetchFacilities(false);
        this.setFacilities(facilities.data || []);
    }

    @action
    setFacilities(facilities: Facility[]) {
        if (!this.defaultFacilityId && facilities[0]) {
            this.defaultFacilityId = facilities[0].facId;
        }
        this.facilities = facilities;
        this.facilitiesDropdownValues = utils.formatSelectOptions(
            facilities.filter((facility) => facility.actvInd),
            {
                valueKey: 'facId',
                labelKey: 'facNm',
            },
        );
        this.save();
    }

    @action
    async createFacility(facility: Facility) {
        return await facilitiesAPI.createFacility(facility);
    }

    @action
    async updateFacility(facilityId: number, facility: Facility) {
        return await facilitiesAPI.updateFacility(facilityId, facility);
    }

    async updateFacilitySubscriptionData(
        facilityId: number,
        orgId: string | number,
        subscriptionData: FacilitySubscriptionData,
    ) {
        return await facilitiesAPI.updateFacilitySubscriptionData(facilityId, orgId, subscriptionData);
    }

    // Local storage
    populateData() {
        const facilities = Storage.get('facilities');
        if (facilities && facilities.length > 0) {
            this.setFacilities(facilities);
        }
    }

    save() {
        Storage.set('facilities', this.facilities);
    }

    findFacility(facilityId: number) {
        return this.facilities.find((facility) => {
            return facility.facId === facilityId;
        });
    }
}

// Import this to any other store that needs to use a value from it
export const FacilityStoreObject = new FacilityStore();

export default createContext(FacilityStoreObject);
