import React from 'react';
import Flex from 'src/components/Flex';
import { SurveyInput } from 'src/components/Survey';
import { SurveyDefinitionContent } from 'common/lib/model/surveys/Survey';
import styles from 'src/containers/Reports/Macra/styles.module.scss';

export function SurveyDetailModal({
    title,
    surveyDefinitionContent,
}: {
    title: string;
    surveyDefinitionContent: SurveyDefinitionContent;
}) {
    return (
        <div className={styles.definitionModal}>
            <Flex direction="column">
                <h2>{title}</h2>
                {surveyDefinitionContent.fields.map((field, index) => (
                    <div key={field.id} className={styles.modalTabContent2}>
                        <h2>Q{index + 1}</h2>
                        <SurveyInput key={field.id} field={field} onUpdate={() => {}} isReadOnly={true} value={null} />
                    </div>
                ))}
            </Flex>
        </div>
    );
}
