import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

export enum StatusBadgeColors {
    Red = 'red',
    Green = 'green',
    Yellow = 'yellow',
    Grey = 'grey',
}

interface StatusBadgeProps {
    status?: StatusBadgeColors;
    text: string | number;
}

function StatusBadge(props: StatusBadgeProps) {
    const status = props.status || StatusBadgeColors.Grey;
    return (
        <div
            className={classNames(styles.statusBar, {
                [styles.red]: status === StatusBadgeColors.Red,
                [styles.yellow]: status === StatusBadgeColors.Yellow,
                [styles.green]: status === StatusBadgeColors.Green,
                [styles.grey]: status === StatusBadgeColors.Grey,
            })}
        >
            {props.text || 'N/A'}
        </div>
    );
}

export default StatusBadge;
