import React, { useEffect, useState } from 'react';
import { AbgQcdrMeasure, MacraAvailableYears } from 'common/lib/model/macra/Macra';
import { measureDefinitions2023, measureDefinitions2024 } from 'common/lib/util/Macra';
import Flex from 'src/components/Flex';
import { format } from 'date-fns';
import { API_DATE_FORMAT, DISPLAY_TIME_FORMAT } from 'src/utils/constants';
import Table from 'src/components/Table';
import { Column, CellInfo } from 'react-table';
import TabNav from 'src/components/TabNav';
import styles from 'src/containers/Reports/Macra/styles.module.scss';
import { CasesTableRowData } from 'common/lib/model/macra/MacraYear';
import { Survey, SurveyDefinitionContent, SurveyField, FieldTypes } from 'common/lib/model/surveys/Survey';
import DateComponent from 'src/components/DateComponent';
import { getSurveyByEncFormId } from 'src/api/survey';

export interface ModalMeasuresTableData {
    measureName: string;
    qualityDataCodes: string;
    measureResponseCodes: string;
    orObsCodes: string;
    unspecifiedObsCodes: string;
    missingFields: string;
}

function getSurveyAnswer(field: SurveyField, answer: any) {
    if (!answer) {
        return '-';
    }
    switch (field.type) {
        case FieldTypes.DROPDOWN:
        case FieldTypes.SCALE:
            const option = (field.options || []).find((o) => o.value === answer);
            if (option) {
                return option.label;
            }
            return '-';

        case FieldTypes.TEXT:
        case FieldTypes.NUMBER:
            return answer;
        default:
            return '-';
    }
}

export function CaseDetailModal({ rowData, year }: { rowData: CasesTableRowData; year: MacraAvailableYears }) {
    const [caseData, setCaseData] = useState(rowData.case);
    const [evalResult, setEvalResult] = useState(rowData.evalResult);
    const [selectedTab, setSelectedTab] = useState('Case Information');
    const [patientSurvey, setPatientSurvey] = useState<Survey | null>(null);

    useEffect(() => {
        loadPatientSurvey();
    }, []);

    async function loadPatientSurvey() {
        try {
            const results = await getSurveyByEncFormId(rowData.case.encounterFormId);
            setPatientSurvey(results.data);
        } catch (error) {}
    }

    const measuresTableColumns: Column[] = [
        {
            Header: 'MEASURE',
            accessor: 'measureName',
            minWidth: 60,
            headerClassName: styles.tableHeader,
            className: styles.tableFontSize,
        },
        {
            Header: 'QUALITY DATA CODES',
            accessor: 'qualityDataCodes',
            minWidth: 110,
            headerClassName: styles.tableHeader,
            className: styles.tableFontSize,
        },
        {
            Header: 'MEASURE RESPONSE CODES',
            accessor: 'measureResponseCodes',
            minWidth: 110,
            headerClassName: styles.tableHeader,
            className: styles.tableFontSize,
        },
        {
            Header: 'OR OBS CODES',
            accessor: 'orObsCodes',
            minWidth: 100,
            headerClassName: styles.tableHeader,
            className: styles.tableFontSize,
        },
        {
            Header: 'UNSP OBS CODES',
            accessor: 'unspecifiedObsCodes',
            minWidth: 100,
            headerClassName: styles.tableHeader,
            className: styles.tableFontSize,
        },
        {
            Header: 'MISSING FIELDS',
            accessor: 'missingFields',
            minWidth: 100,
            headerClassName: styles.tableHeader,
            className: styles.tableFontSize,
            Cell: (props: CellInfo) => <div className={styles.wrapText}>{props.value}</div>,
        },
    ];

    function getMissingFields() {
        const missingFields = evalResult.measures.reduce((array, curr) => {
            return array.concat(curr.missingFields);
        }, []);
        return [...new Set(missingFields).values()].sort().join(', ');
    }

    const measureDefinitions: AbgQcdrMeasure[] = year === 2023 ? measureDefinitions2023 : measureDefinitions2024;
    const measuresTableData: ModalMeasuresTableData[] =
        caseData.qcdrEvalResult && caseData.qcdrEvalResult.measures
            ? measureDefinitions.map((measureDef: AbgQcdrMeasure) => {
                  const measureData: any =
                      caseData.qcdrEvalResult.measures.find((measure) => measure.name === measureDef.name) || {};
                  const { name, qualDataCds, measRespCds, orObsCodes, unspObsCodes, missingFields } = measureData;
                  return {
                      measureName: name,
                      qualityDataCodes: qualDataCds ? qualDataCds.join(', ') : '',
                      measureResponseCodes: measRespCds ? measRespCds.join(', ') : '',
                      orObsCodes: orObsCodes ? orObsCodes.join(', ') : '',
                      unspecifiedObsCodes: unspObsCodes ? unspObsCodes.join(', ') : '',
                      missingFields: missingFields ? missingFields.join(', ') : '',
                  };
              })
            : [];

    function handleTabChange(tabName: string) {
        setSelectedTab(tabName);
    }

    function getSelectedTab() {
        switch (selectedTab) {
            case 'Case Information':
                return (
                    <div className={styles.tabContent}>
                        <h2>Case Information</h2>
                        <Flex direction="row">
                            <Flex align="stretch" value={1} className={styles.modalContentWrap}>
                                Encounter Number:
                            </Flex>
                            <Flex align="stretch" value={2}>
                                {caseData.encounterNumber}
                            </Flex>
                        </Flex>
                        <Flex direction="row">
                            <Flex align="stretch" value={1} className={styles.modalContentWrap}>
                                Encounter Form ID:
                            </Flex>
                            <Flex align="stretch" value={2}>
                                {caseData.encounterFormId}
                            </Flex>
                        </Flex>
                        <Flex direction="row">
                            <Flex align="stretch" value={1} className={styles.modalContentWrap}>
                                Form Completion %:
                            </Flex>
                            <Flex align="stretch" value={2}>
                                {Math.round(parseFloat(caseData.formCompletePct) * 100)}%
                            </Flex>
                        </Flex>
                        <Flex direction="row">
                            <Flex align="stretch" value={1} className={styles.modalContentWrap}>
                                Date of Service:
                            </Flex>
                            <Flex align="stretch" value={2}>
                                {format(new Date(caseData.dos), API_DATE_FORMAT)}
                            </Flex>
                        </Flex>
                        <Flex direction="row">
                            <Flex align="stretch" value={1} className={styles.modalContentWrap}>
                                Anesthesia Start:
                            </Flex>
                            <Flex align="stretch" value={2}>
                                {format(new Date(caseData.anesStartDate), `${API_DATE_FORMAT} ${DISPLAY_TIME_FORMAT}`)}
                            </Flex>
                        </Flex>
                        <Flex direction="row">
                            <Flex align="stretch" value={1} className={styles.modalContentWrap}>
                                Anesthesia End:
                            </Flex>
                            <Flex align="stretch" value={2}>
                                {format(new Date(caseData.anesEndDate), `${API_DATE_FORMAT} ${DISPLAY_TIME_FORMAT}`)}
                            </Flex>
                        </Flex>
                        <Flex direction="row">
                            <Flex align="stretch" value={1} className={styles.modalContentWrap}>
                                Anesthesia Providers:
                            </Flex>
                            <Flex align="stretch" value={2}>
                                {caseData.anesProviders.map((provider) => {
                                    return provider.providerName;
                                })}
                            </Flex>
                        </Flex>
                        <Flex direction="row">
                            <Flex align="stretch" value={1} className={styles.modalContentWrap}>
                                Surgeon:
                            </Flex>
                            <Flex align="stretch" value={2}>
                                {caseData.surgeonProvider ? caseData.surgeonProvider.providerName : null}
                            </Flex>
                        </Flex>
                    </div>
                );
            case 'QCDR Eval Result':
                return (
                    <div className={styles.tabContent}>
                        <h2>QCDR Eval Result</h2>
                        <Flex direction="row">
                            <Flex align="stretch" value={1} className={styles.modalContentWrap}>
                                Admissible:
                            </Flex>
                            <Flex align="stretch" value={2}>
                                {evalResult.admissible ? 'YES' : 'NO'}
                            </Flex>
                        </Flex>
                        <Flex direction="row">
                            <Flex align="stretch" value={1} className={styles.modalContentWrap}>
                                Errors:
                            </Flex>
                            <Flex align="stretch" value={2}>
                                {evalResult.errors.join(', ')}
                            </Flex>
                        </Flex>
                        <Flex direction="row">
                            <Flex align="stretch" value={1} className={styles.modalContentWrap}>
                                All Missing Fields:
                            </Flex>
                            <Flex align="stretch" value={2}>
                                {getMissingFields()}
                            </Flex>
                        </Flex>
                        <Flex direction="row">
                            <Flex align="stretch" value={1} className={styles.modalContentWrap}>
                                QCDR Evaluated At:
                            </Flex>
                            <Flex align="stretch" value={2}>
                                {format(
                                    new Date(evalResult.evalTimestamp),
                                    `${API_DATE_FORMAT} ${DISPLAY_TIME_FORMAT}`,
                                )}
                            </Flex>
                        </Flex>
                        <Flex direction="row">
                            <Flex align="stretch" value={1} className={styles.modalContentWrap}>
                                QCDR Engine Version:
                            </Flex>
                            <Flex align="stretch" value={2}>
                                {evalResult.qcdrVersion}
                            </Flex>
                        </Flex>
                    </div>
                );
            case 'Measure Results (Actual)':
                return (
                    <div className={styles.tabContent}>
                        <h2>Measure Results (Actual)</h2>
                        <Table
                            isLoading={false}
                            columns={measuresTableColumns}
                            data={measuresTableData}
                            showPagination={false}
                        />
                    </div>
                );
            case 'Patient Survey':
                return (
                    <div className={styles.tabContent}>
                        <h2>Patient Survey Response</h2>
                        {!patientSurvey ? (
                            <h3>No patient survey loaded.</h3>
                        ) : (
                            <>
                                <Flex direction="row">
                                    <Flex align="stretch" value={1} className={styles.modalContentWrapSurveyLabel}>
                                        Survey Guid
                                    </Flex>
                                    <Flex align="stretch" value={1}>
                                        {patientSurvey.surveyGuid}
                                    </Flex>
                                </Flex>
                                <Flex direction="row">
                                    <Flex align="stretch" value={1} className={styles.modalContentWrapSurveyLabel}>
                                        Survey Status
                                    </Flex>
                                    <Flex align="stretch" value={1}>
                                        {patientSurvey.surveyState.surveyStateTitle}
                                    </Flex>
                                </Flex>
                                <Flex direction="row">
                                    <Flex align="stretch" value={1} className={styles.modalContentWrapSurveyLabel}>
                                        Survey Created
                                    </Flex>
                                    <Flex align="stretch" value={1}>
                                        <DateComponent date={patientSurvey.createdAt} showDateTime={true} />
                                    </Flex>
                                </Flex>
                                <Flex direction="row">
                                    <Flex align="stretch" value={1} className={styles.modalContentWrapSurveyLabel}>
                                        Survey Last Updated
                                    </Flex>
                                    <Flex align="stretch" value={1}>
                                        <DateComponent date={patientSurvey.updatedAt} showDateTime={true} />
                                    </Flex>
                                </Flex>
                                {(
                                    (patientSurvey.surveyDefinitionHistory
                                        .surveyDefinitionContent as SurveyDefinitionContent).fields || []
                                ).map((f, index) => (
                                    <Flex key={f.id} direction="row">
                                        <Flex align="stretch" value={1} className={styles.modalContentWrapSurveyLabel}>
                                            Q{index + 1}. {f.title}
                                        </Flex>
                                        <Flex align="stretch" value={1}>
                                            {getSurveyAnswer(f, patientSurvey.responseData[f.name])}
                                        </Flex>
                                    </Flex>
                                ))}
                            </>
                        )}
                    </div>
                );
        }
    }

    return (
        <div className={styles.caseDetailModal}>
            <TabNav
                tabs={['Case Information', 'QCDR Eval Result', 'Measure Results (Actual)', 'Patient Survey']}
                onTabClick={(tabName: string) => handleTabChange(tabName)}
            />
            {getSelectedTab()}
        </div>
    );
}
