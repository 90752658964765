import React, { useState, useEffect, useContext } from 'react';
import styles from './styles.module.scss';
import Select from 'react-select';
import { observer } from 'mobx-react';
import OrgUserStore from 'src/stores/OrgUserStore';
import TeamStore from 'src/stores/TeamStore';
import { Facility } from 'src/stores/FacilityStore';
import * as utils from 'src/utils';
import useIsOptionsLoading from 'src/utils/hooks/useIsOptionsLoading';

export interface EventAssigneeDropDownProps {
    onChange: Function;
    selectedValue?: number;
    selectedAssigneeType?: 'user' | 'team';
    allowEmpty?: boolean;
    facId?: Facility['facId'];
}

function EventAssigneeDropDown(props: EventAssigneeDropDownProps) {
    const orgUserStore = useContext(OrgUserStore);
    const [selected, setSelected] = useState(null);

    const teamStore = useContext(TeamStore);

    const isLoadingTeams = useIsOptionsLoading(teamStore.teamDropDownValues);
    const isLoadingUsers = useIsOptionsLoading(orgUserStore.orgUsersDropdownValues);

    useEffect(() => {
        orgUserStore.getOrgUsers();
        teamStore.getTeams({ filters: { facId: props.facId } });
    }, [props.facId]);

    useEffect(() => {
        if (props.selectedValue) {
            // Select either a user or a team depending on which gets matched
            if (props.selectedAssigneeType === 'user') {
                const selectedValUser = orgUserStore.orgUsersDropdownValues.find(
                    (i) => `${i.value}` === `${props.selectedValue}`,
                );
                if (selectedValUser) {
                    if (selectedValUser !== selected) {
                        setSelected(selectedValUser);
                    }
                }
            }
            if (props.selectedAssigneeType === 'team') {
                const selectedValTeam = teamStore.teamDropDownValues.find(
                    (i) => `${i.value}` === `${props.selectedValue}`,
                );
                if (selectedValTeam) {
                    setSelected(selectedValTeam);
                }
            }
        }
    }, [props.selectedValue, teamStore.teamDropDownValues, orgUserStore.orgUsersDropdownValues]);

    return (
        <Select
            value={selected}
            onChange={(s: any) => {
                setSelected(s);
                let assigneeType = 'user';
                if (teamStore.teamDropDownValues.find((i) => i && `${i.label}` === `${s.label}`)) {
                    assigneeType = 'team';
                }

                props.onChange(s || { value: null }, assigneeType);
            }}
            isLoading={isLoadingTeams || isLoadingUsers}
            className={styles.select}
            isClearable={props.allowEmpty}
            options={[
                { label: 'Teams', options: teamStore.teamDropDownValues },
                {
                    label: 'Users',
                    options: orgUserStore.orgUsersDropdownValues,
                },
            ]}
            placeholder="Select User or Team"
            styles={utils.styleSelectComponent()}
        />
    );
}

export default observer(EventAssigneeDropDown);
