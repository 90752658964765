import { observable, action, makeObservable } from 'mobx';
import { createContext } from 'react';
import * as adminService from 'src/api/admin';
import * as utils from 'src/utils';
import { JobSchedule, FlowScriptTemplate, FlowScriptType } from 'src/utils/types';
import { getLatestFlowScriptTemplateHistory } from 'src/utils/flowScripts';
class AdminStore {
    @observable jobSchedules: JobSchedule[] = [];
    @observable jobScheduleDropdownValues: { value: string | number; label: string }[] = [];
    @observable flowScriptTemplates: FlowScriptTemplate[] = [];
    @observable flowScriptTemplateDropdownValues: { value: string | number; label: string }[] = [];
    @observable flowScriptTypes: FlowScriptType[] = [];
    @observable flowScriptTypeDropdownValues: { value: string | number; label: string }[] = [];

    constructor() {
        makeObservable(this);
    }

    @action
    reset() {
        this.jobSchedules = [];
        this.jobScheduleDropdownValues = [];
        this.flowScriptTemplates = [];
        this.flowScriptTemplateDropdownValues = [];
    }

    @action
    async getJobSchedules(force: boolean = false) {
        if (!force && this.jobSchedules.length > 0) {
            return;
        }
        const jobSchedules = await adminService.getJobSchedules();
        this.jobSchedules = jobSchedules;
        this.jobScheduleDropdownValues = utils.formatSelectOptions(jobSchedules, {
            valueKey: 'jobScheduleId',
            getLabel: (j: JobSchedule) => `${j.jobScheduleTitle} (${j.jobScheduleCode})`,
        });
    }

    @action
    async getFlowScriptTemplates(force: boolean = false) {
        if (!force && this.flowScriptTemplates.length > 0) {
            return;
        }
        const flowScriptTemplates = await adminService.getFlowScriptTemplates();
        this.flowScriptTemplates = flowScriptTemplates;
        this.flowScriptTemplateDropdownValues = utils.formatSelectOptions(flowScriptTemplates, {
            valueKey: 'flowScriptTemplateGuid',
            getLabel: (f: FlowScriptTemplate) => f.flowScriptTemplateTitle,
        });
    }

    @action
    async getFlowScriptTypes(force: boolean = false) {
        if (!force && this.flowScriptTypes.length > 0) {
            return;
        }
        const flowScriptTypes = await adminService.getFlowScriptTypes();
        this.flowScriptTypes = flowScriptTypes;
        this.flowScriptTypeDropdownValues = utils.formatSelectOptions(flowScriptTypes, {
            valueKey: 'flowScriptTypeCode',
            getLabel: (f: FlowScriptType) => f.flowScriptTypeTitle
        });
    }
}

// Import this to any other store that needs to use a value from it
export const AdminStoreObject = new AdminStore();

export default createContext(AdminStoreObject);
