import React, { useState, useEffect, useRef } from 'react';
import Icon from 'src/components/Icon';
import Flex from 'src/components/Flex';
import { ICONS } from 'src/utils/constants';
import { RepeatableBitmapUpdate } from 'common/lib/model/model/RepeatableBitmapUpdate';
import { ANALYTICS_NAMES } from 'src/utils/analytics';
import styles from '../../styles.module.scss';
import * as variables from 'src/styles/variables';

interface FileInputProps {
    input: any;
    value?: any;
    acceptTypes?: any;
    onChange?: Function;
    onSave?: Function;
}

interface FileInputValue {
    blobId: string;
    fileName: string;
    fileSize?: number;
    type?: string;
}

function FileInput(props: FileInputProps) {
    const [value, setValue] = useState<FileInputValue>(props.value || null);
    const fileRef = useRef();
    const firstValueRender = useRef(true);

    useEffect(() => {
        if (value && !firstValueRender.current) {
            props.onChange(value);
        }
        firstValueRender.current = false;
    }, [value]);

    function handleFileSelect(selectedFiles: FileList) {
        const fileReader = new FileReader();
        fileReader.readAsBinaryString(selectedFiles[0]);
        fileReader.onload = (e: any) => {
            const { name, size, type } = selectedFiles[0];
            setValue({
                fileName: name,
                blobId: btoa(fileReader.result as string),
                fileSize: size,
                type,
            });
        };
    }

    const inputId = `${props.input.$.name}-${new Date().getTime()}`;

    return (
        <div>
            <div className={styles.label}>{props.input.title ? props.input.title[0] : ''}</div>
            <Flex align="center">
                <div className={styles.fileContent}>
                    <input
                        className={styles.fileSelector}
                        ref={fileRef}
                        type="file"
                        accept={props.acceptTypes || 'image/*'}
                        onChange={(e: any) => handleFileSelect(e.target.files)}
                        id={inputId}
                        name="fileSelector"
                        data-test-id={ANALYTICS_NAMES.DiscreteForm_FileInput}
                    />
                    <label htmlFor={inputId} className={styles.fileLabel}>
                        <Icon name={ICONS.AttachmentIcon} className={styles.fileIcon} /> Choose a File
                    </label>
                </div>
                <div className={styles.fileName}>{value && value.fileName ? value.fileName : null}</div>
                {value && value.fileName ? (
                    <div
                        className={styles.fileDownload}
                        onClick={() => props.onSave && props.onSave(value.blobId, value.fileName, value.type)}
                    >
                        <Icon name={ICONS.Download} color={variables.white} size={18} />
                    </div>
                ) : null}
            </Flex>
        </div>
    );
}

export default FileInput;
