import React, { useState, useEffect, useContext, useRef } from 'react';
import classNames from 'classnames';

import NavBar from 'src/containers/NavBar';
import PageContainer from 'src/components/PageContainer';
import Flex from 'src/components/Flex';
import styles from './styles.module.scss';
import { observer } from 'mobx-react';
import * as patientService from 'src/api/patients';
import DateComponent from 'src/components/DateComponent';
import Age from 'src/components/Age';
import Gender from 'src/components/Gender';
import Activity from 'src/containers/PatientDetails/Activity';
import Visits from './Visits/index';
import Tasks from './Tasks/index';
import Needs from './Needs/index';
import FormCharts from './FormCharts/index';
import Notes from './Notes/index';
import Information from './Information/index';
import Tag from 'src/components/Tag';
import QuickAction from 'src/components/QuickAction';
import PatientStore, { Patient } from 'src/stores/PatientStore';
import { ICONS } from 'src/utils/constants';
import Medications from './Medications';
import Tooltip from 'src/components/Tooltip';
import Icon from 'src/components/Icon';
import { useParams, useLocation, useHistory } from 'react-router';
import LoadingCover from 'src/components/LoadingCover';
import Appointments from './Appointments';
import { ModalStoreObject, ModalTypes } from 'src/stores/ModalStore';
import { ANALYTICS_NAMES } from 'src/utils/analytics';
import UserStore from 'src/stores/UserStore';
import { getPatientName, getPatientPhone, parseError } from 'src/utils';
import PatientStateDropDown from 'src/components/PatientStateDropDown';
import { ToastStoreObject } from 'src/stores/ToastStore';

export interface TabItemProps {
    label: React.ReactNode;
    isActive: boolean;
    onClick: () => void;
    isLocked?: boolean;
}

interface LocationState {
    startTab: any;
}

export function TabItem({ isActive, onClick, label, isLocked, ...rest }: TabItemProps) {
    return (
        <Flex
            {...rest}
            className={classNames(styles.informationNavLink, {
                [styles.activeTab]: isActive,
                [styles.lockedTab]: isLocked,
            })}
            onClick={!isLocked ? onClick : undefined}
        >
            <Flex direction="row">
                {isLocked ? <Icon type="fa" name="lock" className={styles.lockIcon} /> : null}
                {label}
            </Flex>
        </Flex>
    );
}

export enum PatientTabs {
    ACTIVITY,
    VISITS,
    TASKS,
    NEEDS,
    VITALS,
    LABS,
    NOTES,
    MEDICATIONS,
    APPOINTMENTS,
}

function PatientDetails() {
    const [selectedTab, setSelectedTab] = useState<PatientTabs>(PatientTabs.VISITS);
    const [isLoading, setIsLoading] = useState(false);
    const [tooltipIsOpen, setTooltipIsOpen] = useState(false);
    const dnrRef = useRef<HTMLDivElement>(null);
    const paramsPatientIdInt = parseInt(useParams<{ patientId: string }>().patientId);
    const patientStore = useContext(PatientStore);
    const userStore = useContext(UserStore);
    const [patient, setPatient] = useState(patientStore.activePatient);
    const location = useLocation<LocationState>();
    const history = useHistory();
    const locState = location.state;
    useEffect(() => {
        setPatient(patientStore.activePatient);
    }, [patientStore.activePatient]);
    useEffect(() => {
        // Load up on a certain tab by using history.push('', { startTab: # })
        if (locState && locState.startTab in PatientTabs) {
            setSelectedTab(locState.startTab);
            // Make sure we replace the history state after we have handled it
            history.replace(location.pathname, { ...locState, startTab: undefined });
        }
    }, [locState]);

    useEffect(() => {
        async function fetchPatient() {
            try {
                setIsLoading(true);
                await patientStore.getActivePatient(paramsPatientIdInt);
            } finally {
                setIsLoading(false);
            }
        }
        fetchPatient();
    }, [paramsPatientIdInt]);

    function handleQuickAction(type: string) {
        switch (type) {
            case 'needs':
                ModalStoreObject.showModal(ModalTypes.PatientNeed, {
                    title: `Create a Need for ${getPatientName(patient)}`,
                    patientId: paramsPatientIdInt,
                    facilityId: patient.facId,
                    onClose: () => ModalStoreObject.hideModal(),
                    onSave: () => {
                        ModalStoreObject.hideModal();
                        setSelectedTab(PatientTabs.NEEDS);
                    },
                });
                break;
            case 'notes':
                ModalStoreObject.showModal(ModalTypes.PatientNote, {
                    title: `Create a Note for ${getPatientName(patient)}`,
                    patientId: paramsPatientIdInt,
                    facilityId: patient.facId,
                    onClose: () => ModalStoreObject.hideModal(),
                    onSave: () => {
                        ModalStoreObject.hideModal();
                        setSelectedTab(PatientTabs.NOTES);
                    },
                });
                break;
            case 'attachments':
                ModalStoreObject.showModal(ModalTypes.PatientAttachment, {
                    title: `Create an Attachment for ${getPatientName(patient)}`,
                    patientId: paramsPatientIdInt,
                    facilityId: patient.facId,
                    onClose: () => ModalStoreObject.hideModal(),
                    onSave: () => {
                        ModalStoreObject.hideModal();
                        setSelectedTab(PatientTabs.NOTES);
                    },
                });
                break;
            case 'tasks':
                ModalStoreObject.showModal(ModalTypes.CreateEvent, {
                    type: 'task',
                    title: `New Task for ${getPatientName(patient)}`,
                    patientId: paramsPatientIdInt,
                    patient: patient,
                    facilityId: patient.facId,
                    onClose: () => ModalStoreObject.hideModal(),
                    onSave: () => {
                        ModalStoreObject.hideModal();
                        // If you're already on this tab, we should somehow refresh the tab so that it loads the data again after `onSave`
                        setSelectedTab(PatientTabs.TASKS);
                    },
                });
                break;
            case 'appointments':
                ModalStoreObject.showModal(ModalTypes.CreateEvent, {
                    type: 'appointment',
                    title: `New Appointment for ${getPatientName(patient)}`,
                    patientId: paramsPatientIdInt,
                    patient: patient,
                    facilityId: patient.facId,
                    onClose: () => ModalStoreObject.hideModal(),
                    onSave: () => {
                        ModalStoreObject.hideModal();
                        setSelectedTab(PatientTabs.APPOINTMENTS);
                    },
                });
                break;
            case 'visit':
                ModalStoreObject.showModal(ModalTypes.Encounter, {
                    title: `New Visit for ${getPatientName(patient)}`,
                    onClose: () => ModalStoreObject.hideModal(),
                    isOpen: true,
                    onSave: async () => {
                        ModalStoreObject.hideModal();
                        setSelectedTab(PatientTabs.VISITS);
                    },
                    patientId: paramsPatientIdInt,
                    facId: patient.facId,
                    patient: patient,
                });
                break;
        }
    }

    async function handleUpdateStatus(params: { id: any; value: any }) {
        try {
            await patientService.updatePatient(params.id, { patientStateId: params.value });
            ModalStoreObject.hideModal();
        } catch (error) {
            ToastStoreObject.show(parseError(error));
        }
    }

    function renderContent() {
        switch (selectedTab) {
            case PatientTabs.ACTIVITY:
                return <Activity patientId={paramsPatientIdInt} patient={patient} />;
            case PatientTabs.VISITS:
                return <Visits patientId={paramsPatientIdInt} patient={patient} />;
            case PatientTabs.TASKS:
                return <Tasks patientId={paramsPatientIdInt} patient={patient} />;
            case PatientTabs.APPOINTMENTS:
                return <Appointments patientId={paramsPatientIdInt} patient={patient} />;
            case PatientTabs.NEEDS:
                return <Needs patientId={paramsPatientIdInt} patient={patient} />;
            case PatientTabs.VITALS:
                return (
                    <FormCharts mode="vitals" patientId={paramsPatientIdInt} patient={patient} facId={patient.facId} />
                );
            case PatientTabs.LABS:
                return (
                    <FormCharts mode="labs" patientId={paramsPatientIdInt} patient={patient} facId={patient.facId} />
                );
            case PatientTabs.NOTES:
                return <Notes patientId={paramsPatientIdInt} patient={patient} />;
            case PatientTabs.MEDICATIONS:
                return <Medications patientId={paramsPatientIdInt} patient={patient} />;
        }
    }

    return (
        <PageContainer>
            <NavBar />
            <div className={styles.relative}>
                {isLoading ? <LoadingCover style={{ top: 50 }} /> : null}
                <Flex direction="row" className={styles.header} self="stretch" align="center">
                    <Flex direction="row" align="center" value={1} className={styles.headerStats}>
                        Patient:{' '}
                        <span className={styles.blueText} data-test-id={ANALYTICS_NAMES.PatientDetails_PatientName}>
                            {getPatientName(patient)}
                        </span>
                        {patient.patientDoNotResuscitateInd ? (
                            <div
                                ref={dnrRef}
                                onMouseOver={() => {
                                    setTooltipIsOpen(true);
                                }}
                                onMouseOut={() => setTooltipIsOpen(false)}
                            >
                                <Tag color="alert" text="DNR" className={styles.dnrTag} />
                                <Icon type="fa" name="question-circle" className={styles.help} />
                            </div>
                        ) : null}
                    </Flex>
                    <Flex className={styles.headerStats} style={{ minWidth: 200 }} align="center" justify="center">
                        Status:
                        <Flex style={{ marginLeft: 15, width: '100%' }} self="stretch">
                            <PatientStateDropDown
                                isDisabled={!userStore.userPermissions.canEdit.patientDetails}
                                selectedValue={patient.patientStateId}
                                onBeforeChange={(option: { value: any }) => {
                                    return new Promise((resolve) => {
                                        ModalStoreObject.showModal(ModalTypes.ConfirmationModal, {
                                            title: "Are you sure you would like to change the patient's status?",
                                            onConfirm: () => {
                                                resolve(true);
                                                handleUpdateStatus({ id: patient.patientId, value: option.value });
                                            },
                                            onCancel: () => {
                                                resolve(false);
                                                ModalStoreObject.hideModal();
                                            },
                                            confirmButtonText: 'Confirm',
                                        });
                                    });
                                }}
                                onChange={() => {}}
                            />
                        </Flex>
                    </Flex>
                    <Flex className={styles.headerStats}>
                        Patient Phone Number:{' '}
                        <span className={styles.blueText}>{getPatientPhone(patient.patientPhones) || '-'}</span>
                    </Flex>
                    <Flex className={styles.headerStats}>
                        Gender: <Gender className={styles.blueText} value={patient.patientGenderCode} />
                    </Flex>
                    <Flex className={styles.headerStats}>
                        Age: <Age dob={patient.patientDob} className={styles.blueText} />
                    </Flex>
                    <Flex className={classNames(styles.headerStats, styles.noMargin)}>
                        DOB: <DateComponent date={patient.patientDob} className={styles.blueText} />
                    </Flex>
                </Flex>
                <Flex direction="row">
                    <Flex value={1} className={styles.leftSide}>
                        <Flex direction="row" align="center" justify="center" className={styles.quickActionWrap}>
                            <QuickAction
                                onClick={() => handleQuickAction('notes')}
                                iconName={ICONS.Note}
                                text="Note"
                                data-test-id={ANALYTICS_NAMES.PatientDetails_QuickActions_Note}
                                isLocked={!userStore.userPermissions.canEdit.patientNotes}
                            />
                            <QuickAction
                                onClick={() => handleQuickAction('visit')}
                                iconName={ICONS.Visit}
                                text="Encounter"
                                data-test-id={ANALYTICS_NAMES.PatientDetails_QuickActions_Visit}
                                isLocked={!userStore.userPermissions.canEdit.patientEncounters}
                            />
                            <QuickAction
                                onClick={() => handleQuickAction('needs')}
                                iconName={ICONS.Need}
                                text="Need"
                                data-test-id={ANALYTICS_NAMES.PatientDetails_QuickActions_Note}
                                isLocked={!userStore.userPermissions.canEdit.patientNeeds}
                            />
                        </Flex>
                        <Flex direction="row" align="center" justify="center" className={styles.quickActionWrap}>
                            <QuickAction
                                onClick={() => handleQuickAction('appointments')}
                                iconName={ICONS.Appointment}
                                text="Appointment"
                                data-test-id={ANALYTICS_NAMES.PatientDetails_QuickActions_Appointment}
                                isLocked={!userStore.userPermissions.canEdit.patientEvents}
                            />
                            <QuickAction
                                onClick={() => handleQuickAction('attachments')}
                                iconName={ICONS.Attachment}
                                text="Attachment"
                                data-test-id={ANALYTICS_NAMES.PatientDetails_QuickActions_Attachment}
                                isLocked={!userStore.userPermissions.canEdit.patientNotes}
                            />
                            <QuickAction
                                onClick={() => handleQuickAction('tasks')}
                                iconName={ICONS.Task}
                                text="Task"
                                data-test-id={ANALYTICS_NAMES.PatientDetails_QuickActions_Task}
                                isLocked={!userStore.userPermissions.canEdit.patientEvents}
                            />
                        </Flex>
                        <Information patient={patient} />
                    </Flex>
                    <Flex value={3} className={styles.informationContainer} style={{ width: '75%' }}>
                        <Flex className={styles.informationNavWrap} direction="row">
                            <TabItem
                                isActive={selectedTab === PatientTabs.VISITS}
                                onClick={() => setSelectedTab(PatientTabs.VISITS)}
                                label="Encounters"
                                data-test-id={ANALYTICS_NAMES.PatientDetails_Tabs_Visits}
                                isLocked={!userStore.userPermissions.canView.patientEncounters}
                            />
                            <TabItem
                                isActive={selectedTab === PatientTabs.TASKS}
                                onClick={() => setSelectedTab(PatientTabs.TASKS)}
                                label="Tasks"
                                data-test-id={ANALYTICS_NAMES.PatientDetails_Tabs_Tasks}
                                isLocked={!userStore.userPermissions.canView.patientTasks}
                            />
                            <TabItem
                                isActive={selectedTab === PatientTabs.APPOINTMENTS}
                                onClick={() => setSelectedTab(PatientTabs.APPOINTMENTS)}
                                label="Appointments"
                                data-test-id={ANALYTICS_NAMES.PatientDetails_Tabs_Appointments}
                                isLocked={!userStore.userPermissions.canView.patientEvents}
                            />
                            <TabItem
                                isActive={selectedTab === PatientTabs.NEEDS}
                                onClick={() => setSelectedTab(PatientTabs.NEEDS)}
                                label="Needs"
                                data-test-id={ANALYTICS_NAMES.PatientDetails_Tabs_Needs}
                                isLocked={!userStore.userPermissions.canView.patientNeeds}
                            />
                            <TabItem
                                isActive={selectedTab === PatientTabs.NOTES}
                                onClick={() => setSelectedTab(PatientTabs.NOTES)}
                                label="Notes/Attachments"
                                data-test-id={ANALYTICS_NAMES.PatientDetails_Tabs_NotesAttachments}
                                isLocked={!userStore.userPermissions.canView.patientNotes}
                            />
                            <TabItem
                                isActive={selectedTab === PatientTabs.VITALS}
                                onClick={() => setSelectedTab(PatientTabs.VITALS)}
                                label="Vitals"
                                data-test-id={ANALYTICS_NAMES.PatientDetails_Tabs_Vitals}
                                isLocked={!userStore.userPermissions.canView.patientVitals}
                            />
                            <TabItem
                                isActive={selectedTab === PatientTabs.LABS}
                                onClick={() => setSelectedTab(PatientTabs.LABS)}
                                label="Labs"
                                data-test-id={ANALYTICS_NAMES.PatientDetails_Tabs_Labs}
                                isLocked={!userStore.userPermissions.canView.patientLabs}
                            />
                            <TabItem
                                isActive={selectedTab === PatientTabs.MEDICATIONS}
                                onClick={() => setSelectedTab(PatientTabs.MEDICATIONS)}
                                label="Medications"
                                data-test-id={ANALYTICS_NAMES.PatientDetails_Tabs_Medications}
                                isLocked={!userStore.userPermissions.canView.patientMedications}
                            />
                            <TabItem
                                isActive={selectedTab === PatientTabs.ACTIVITY}
                                onClick={() => setSelectedTab(PatientTabs.ACTIVITY)}
                                label="Activity"
                                data-test-id={ANALYTICS_NAMES.PatientDetails_Tabs_Activity}
                                isLocked={!userStore.userPermissions.canView.patientActivity}
                            />
                        </Flex>
                        {renderContent()}
                    </Flex>
                </Flex>
            </div>
            <Tooltip element={dnrRef} isOpen={tooltipIsOpen} direction="top">
                <div className={styles.tooltip}>This patient has a Do Not Resuscitate on record</div>
            </Tooltip>
        </PageContainer>
    );
}
export default observer(PatientDetails);
