import { useEffect, DependencyList } from 'react';
import { LocationState, LocationListener } from 'history';
import { useHistory } from 'react-router';

export function useRouteChange(func: LocationListener<LocationState>, deps: DependencyList) {
    const history = useHistory();
    useEffect(() => {
        return history.listen(func);
    }, deps);
}
