import React from 'react';
import { ComplianceDetails } from 'common/lib/model/macra/MacraYear';
import classNames from 'classnames';

import styles from './styles.module.scss';

export interface PercentageDetailCellProps {
    data: ComplianceDetails;
    showBgState?: boolean;
}

export function PercentageDetailCell({ data, showBgState = false }: PercentageDetailCellProps) {
    if (typeof data.value === 'string') return <div>{Math.round(parseInt(data.value) * 100) + '%'}</div>;
    if (data.value === null) return <div style={{ textAlign: 'center' }}>-</div>;
    const displayValue = isNaN(data.value) ? '-' : Math.round(data.value * 100) + '%';
    const colorStyle = showBgState ? (data.value >= 1 || displayValue === '-' ? styles.green : styles.red) : '';
    return (
        <div className={styles.cellContainer}>
            <div className={classNames(styles.cellData, colorStyle)}>
                <span className={styles.cellValue}>{displayValue}</span>
                <br />
                <span className={styles.cellGoal}>
                    ({data.n || 0} / {data.d || 0})
                </span>
            </div>
        </div>
    );
}
