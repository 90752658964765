import { observable, action, makeObservable } from 'mobx';
import { createContext } from 'react';
import { UserStoreObject } from './UserStore';
import { FeatureFlagStoreObject } from './FeatureFlagStore';
import { User as Index_User } from 'common/lib/entity/index/User';
import { Customer, CustomerStatus, CustomerSubscriptionData, CustomerType } from 'common/lib/entity/index/Customer';
import { FacilitySubscriptionData } from 'common/lib/model/facility/FacilitySubscriptionData';
import { Org } from 'common/lib/entity/index/Org';
import { OrgUser } from 'common/lib/entity/index/OrgUser';
import { Facility } from 'common/lib/entity/org/Facility';
import { FacilityStoreObject } from './FacilityStore';
import { Facility as FacilityStoreFacility } from 'src/stores/FacilityStore'; // This sucks

declare const pendo: any; // Pendo is loaded in index.html

interface PendoData {
    visitor: PendoVisitorData;
    account: PendoAccountData;
}

interface PendoVisitorData {
    id: Index_User['usrId'];
    email: Index_User['emailAddr'];
    username: Index_User['usrNm'];
    name: Index_User['frstNm'] | Index_User['lastNm'];
    roles: string[];
    features: string[];
    environment: string;
    graphiumEmployeeInd: boolean;
    graphiumAdminInd: boolean;
}

interface PendoAccountData {
    id: Customer['customerId'];
    name: Customer['customerName'];
    brand: CustomerSubscriptionData['brand'];
    customerType: CustomerSubscriptionData['customerType'];
    customerStatus: CustomerSubscriptionData['customerStatus'];
    isTrialCustomer: CustomerSubscriptionData['isTrialCustomer'];
    isDemoCustomer: CustomerSubscriptionData['isDemoCustomer'];
    orgId: Org['orgId'];
    orgInternalName: Org['orgNmIntrnl'];
    orgName: Org['orgNm'];
    orgUserId: OrgUser['usrId'];
    orgEmailAddress: string;
    facilityId?: Facility['facId'];
    facilityInternalName?: Facility['facNmIntrnl'];
    facilityName?: Facility['facNm'];
    facilityType?: FacilitySubscriptionData['facilityType'];
    facilityStatus?: FacilitySubscriptionData['facilityStatus'];
    primaryUseCase?: FacilitySubscriptionData['primaryUse'];
    primaryCaseCaptureMode?: FacilitySubscriptionData['primaryCaseCaptureMode'];
    isReportingMacra?: FacilitySubscriptionData['isReportingMacra'];
    macraFormDataEntry?: FacilitySubscriptionData['macraFormDataEntry'];
    macraFormDataEntryServicer?: FacilitySubscriptionData['macraFormDataEntryServicer'];
    hasFacilityReportingMacra?: boolean;
}

class AnalyticsStore {
    @observable analyticsData: PendoData;

    constructor() {
        makeObservable(this);
    }

    @action
    reset() {}

    @action
    initialize() {
        const { customer } = UserStoreObject.selectedOrg?.customerOrg;

        if (!UserStoreObject.indexUsrId || !customer) {
            return;
        }

        const { subscriptionData } = customer;

        const hasFacilityReportingMacra = FacilityStoreObject.facilities.filter(
            (facility: FacilityStoreFacility) => facility?.subscriptionData?.isReportingMacra,
        );

        this.analyticsData = {
            visitor: {
                id: UserStoreObject.indexUsrId,
                email: UserStoreObject.indexEmailAddr,
                username: UserStoreObject.usrNm,
                name: `${UserStoreObject.frstNm} ${UserStoreObject.lastNm}`,
                roles: UserStoreObject.roleNames,
                features: FeatureFlagStoreObject.enabledFeatures,
                environment: import.meta.env.VITE_APP_ENV.toString(),
                graphiumEmployeeInd: UserStoreObject.employeeIndicator,
                graphiumAdminInd: UserStoreObject.adminIndicator,
            },
            account: {
                id: customer.customerId,
                name: customer.customerName,
                brand: subscriptionData.brand || 'GRAPHIUM',
                customerType: subscriptionData.customerType || CustomerType.AnesthesiaGroup,
                customerStatus: subscriptionData.customerStatus || CustomerStatus.Active,
                isTrialCustomer: subscriptionData.isTrialCustomer || false,
                isDemoCustomer: subscriptionData.isDemoCustomer || false,
                orgId: UserStoreObject.selectedOrg.orgId,
                orgInternalName: UserStoreObject.selectedOrg.orgNmIntrnl,
                orgName: UserStoreObject.selectedOrg.orgNm,
                orgUserId: UserStoreObject.orgUsrId,
                orgEmailAddress: UserStoreObject.orgEmailAddr,
                hasFacilityReportingMacra: hasFacilityReportingMacra.length > 0,
            },
        };

        if (import.meta.env.VITE_APP_ENV !== 'local' && pendo && pendo.initialize) {
            pendo.initialize(this.analyticsData);
        }
    }
}

// Import this to any other store that needs to use a value from it
export const AnalyticsStoreObject = new AnalyticsStore();

export default createContext(AnalyticsStoreObject);
