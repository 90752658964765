import React from 'react';
import { CellInfo } from 'react-table';
import Button from 'src/components/Button';
import Flex from 'src/components/Flex';
import Icon from 'src/components/Icon';
import { ICONS, ROUTES } from 'src/utils/constants';
import { useHistory } from 'react-router-dom';
import styles from './styles.module.scss';
import Card from 'src/components/Card';
import Table from 'src/components/Table';
import { useFormCatalogs } from 'src/queries/useFormCatalogs';
import DateComponent from 'src/components/DateComponent';
import { ModalStoreObject, ModalTypes } from 'src/stores/ModalStore';
import LoadingIcon from 'src/components/LoadingIcon';

function Forms() {
    const history = useHistory();
    const { data: formCatalogs, isFetching } = useFormCatalogs();

    const columns = [
        {
            Header: 'Form',
            accessor: 'formTitle',
        },
        /*{
            Header: '# of Facilities',
            accessor: 'formFacilityMappings',
            Cell: (props: CellInfo) => <span>{props.value.length ?? 0}</span>,
        },*/
        {
            Header: 'Updated',
            accessor: 'updatedAt',
            maxWidth: 200,
            Cell: (props: CellInfo) => (
                <span>
                    <DateComponent date={props.value} />
                </span>
            ),
        },
        {
            Header: 'Actions',
            maxWidth: 150,
            style: { paddingVertical: 0, paddingHorizontal: 20 },
            Cell: (props: CellInfo) => (
                <Flex gap={8}>
                    <Button
                        type="small"
                        text="Details"
                        onClick={() =>
                            history.push(
                                ROUTES.getString(ROUTES.FormDevelopmentFormDetail, props.original.formCatalogId),
                            )
                        }
                    />
                </Flex>
            ),
        },
    ];

    if (isFetching && formCatalogs.length === 0) {
        return (
            <div className={styles.loading}>
                <LoadingIcon />
            </div>
        );
    }

    return (
        <>
            <Flex direction="row" justify="end">
                <Button
                    leftIcon={<Icon className={styles.plusIcon} name={ICONS.PlusButton} />}
                    type="primary"
                    onClick={() => {
                        ModalStoreObject.showModal(ModalTypes.FormCatalogModal, {
                            onClose: () => ModalStoreObject.hideModal(),
                            onSave: () => ModalStoreObject.hideModal(),
                        });
                    }}
                    text="New Form"
                />
            </Flex>
            <Card className={styles.cardWrap}>
                <Table
                    isLoading={isFetching && formCatalogs.length === 0}
                    columns={columns}
                    data={formCatalogs}
                    showPagination={false}
                />
            </Card>
        </>
    );
}

export default Forms;
